import React, { useState, useRef, forwardRef } from 'react'
import { Button, Menu, makeStyles, Box } from '@material-ui/core'
import { NumericalRangeOption } from './types'
import NumericalRangePicker, { NumericalRange } from '../../NumericalRangePicker'
import MenuHeader from '../../MenuHeader'
import FilterChip, { FilterChipText, FilterChipProps } from './FilterChip'
import { createNumberFormatter } from '../../../utils/number-format'
import { primary } from '../../../loudcrowd-theme'

type Ref = HTMLDivElement

const useStyles = makeStyles({
  inlineButton: {
    color: primary[500],
    fontSize: 20,
    fontWeight: 400,
    textDecoration: 'underline',
    padding: 0,
    marginBottom: 4,
    minWidth: 0,
    textAlign: 'left',
    height: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
})

interface NumericalRangeFilterChipProps {
  option: NumericalRangeOption
  value: NumericalRange
  onDelete?(): void
  onSelectValue(newValue: NumericalRange): void
  filterChipProps?: FilterChipProps
  editable?: boolean
}

const equalValueText = (
  value: number,
  numberFormatter: ReturnType<typeof createNumberFormatter>,
  displayVariant: NumericalRangeOption['displayVariant'],
) => {
  if (displayVariant === 'inline') {
    return `is ${numberFormatter(value)} follower${value === 1 ? '' : 's'}`
  } else {
    return (
      <>
        <FilterChipText text="is" />
        <FilterChipText bold text={`${numberFormatter(value)}`} />
      </>
    )
  }
}

export function NumericalRangeFilterChip({
  option,
  value,
  onDelete,
  onSelectValue,
  filterChipProps = {},
  editable = true,
}: NumericalRangeFilterChipProps): React.ReactElement {
  const format = option.numberFormat || 'number'
  const numberFormatter = createNumberFormatter({ format })
  const [rangeMenuOpen, setRangeMenuOpen] = useState(false)
  const classes = useStyles()
  const chipRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  let text: React.ReactElement | string
  if (value.gte !== undefined && value.lte !== undefined) {
    if (value.gte === value.lte) {
      text = equalValueText(value.gte, numberFormatter, option?.displayVariant)
    } else {
      if (option?.displayVariant === 'inline') {
        text = `have between ${numberFormatter(value.gte)} and ${numberFormatter(value.lte)} followers`
      } else {
        text = (
          <>
            <FilterChipText text="between" />
            <FilterChipText bold text={`${numberFormatter(value.gte)}`} />
            <FilterChipText text="and" />
            <FilterChipText bold text={`${numberFormatter(value.lte)}`} />
          </>
        )
      }
    }
  } else if (value.eq !== undefined) {
    text = equalValueText(value.eq, numberFormatter, option?.displayVariant)
  } else if (value.gt !== undefined) {
    if (option?.displayVariant === 'inline') {
      if (value.gt === 0) {
        text = `has followers`
      } else {
        text = `have more than ${numberFormatter(value.gt)} followers`
      }
    } else {
      text = (
        <>
          <FilterChipText text="more than" />
          <FilterChipText bold text={`${numberFormatter(value.gt)}`} />
        </>
      )
    }
  } else if (value.gte !== undefined) {
    if (option?.displayVariant === 'inline') {
      text = `have at least ${numberFormatter(value.gte)} followers`
    } else {
      text = (
        <>
          <FilterChipText bold text={`${numberFormatter(value.gte)}`} />
          <FilterChipText text="or more" />
        </>
      )
    }
  } else if (value.lt !== undefined) {
    if (option?.displayVariant === 'inline') {
      text = `have less than ${numberFormatter(value.lt)} followers`
    } else {
      text = (
        <>
          <FilterChipText text="less than" />
          <FilterChipText bold text={`${numberFormatter(value.lt)}`} />
        </>
      )
    }
  } else if (value.lte !== undefined) {
    if (option?.displayVariant === 'inline') {
      text = `have up to ${numberFormatter(value.lte)} followers`
    } else {
      text = (
        <>
          <FilterChipText bold text={`${numberFormatter(value.lte)}`} />
          <FilterChipText text="or less" />
        </>
      )
    }
  } else {
    if (option?.displayVariant === 'inline') {
      text = 'invalid range'
    } else {
      text = <FilterChipText text="Invalid Range" />
    }
  }

  function handleNumericalRangeSelected(selectedRange: NumericalRange): void {
    onSelectValue(selectedRange)
    setRangeMenuOpen(false)
  }

  return (
    <>
      {option?.displayVariant === 'inline' ? (
        <Button
          className={classes.inlineButton}
          variant="text"
          onClick={editable ? () => setRangeMenuOpen(true) : undefined}
          ref={buttonRef}
        >
          {text}
        </Button>
      ) : (
        <FilterChip
          onClick={editable ? () => setRangeMenuOpen(true) : undefined}
          ref={chipRef}
          onDelete={onDelete}
          {...filterChipProps}
        >
          <>
            <FilterChipText bold text={option.label} />
            {text}
          </>
        </FilterChip>
      )}
      <Menu
        open={rangeMenuOpen}
        anchorEl={option?.displayVariant === 'inline' ? buttonRef.current : chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setRangeMenuOpen(false)}
      >
        <MenuHeader title={option.label} />
        <NumericalRangePicker
          onChange={handleNumericalRangeSelected}
          value={value}
          {...option}
          clearButtonProps={{
            variant: 'text',
            color: 'primary',
          }}
        />
      </Menu>
    </>
  )
}

interface NumericalRangeFilterMenuProps {
  option: NumericalRangeOption
  onSelectValue(newValue: NumericalRange): void
  onBack(): void
}

const NumericalRangeFilterMenu = forwardRef<Ref, NumericalRangeFilterMenuProps>(
  ({ option, onSelectValue, onBack }: NumericalRangeFilterMenuProps, ref): React.ReactElement => {
    function handleChangeRange(newRange: NumericalRange): void {
      onSelectValue(newRange)
    }
    return (
      <Box pb={2}>
        <MenuHeader title={option.label} onClickBack={onBack} />
        <NumericalRangePicker
          value={null}
          onChange={handleChangeRange}
          {...option}
          clearButtonProps={{
            variant: 'text',
            color: 'primary',
          }}
        />
      </Box>
    )
  },
)

NumericalRangeFilterMenu.displayName = 'NumericalRangeFilterMenu'
export default NumericalRangeFilterMenu
