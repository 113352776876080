import React, { useEffect, useState } from 'react'
import { Box, Button, Paper, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { CampaignDetailRouteParams } from '../../routes'
import { useParams } from 'react-router-dom'
import {
  AttributionLandedEnum,
  PaymentDayEnum,
  PaymentPeriodEnum,
  ProgramTypeEnum,
  TremendousIntegration,
  EcommDiscountCodeTypeEnum,
  IntegrationCapabilityEnum,
  IntegrationType,
} from '../../../gql-global'
import { useCampaignSettingsQuery, CampaignSettingsDocument } from './operations/campaign-settings.generated'
import ShoppableAmbassadorCampaignSettings from './ShoppableAmbassadorCampaignSettings'
import { useCampaignSettingsUserInfoQuery } from './operations/campaign-settings-user-info.generated'
import AllTypesCampaignSettings from './AllTypesCampaignSettings'
import { useUpdateCampaignSettingsMutation } from './operations/update-campaign-settings.generated'
import { useToast } from '../../../components/Alert/ToastProvider'
import { Skeleton } from '@material-ui/lab'
import { useCampaignUserInfoQuery } from '../operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../../utils/social-account'
import DiscountGenerationSettings from './DiscountGenerationSettings'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'
import theme from '../../../loudcrowd-theme'
import { ReactComponent as InfoIcon } from '../../../icons/information-bordered.svg'
import { isTypeName } from '../../../types/utility'
import ShoppableAmbassadorPayoutSettings from './CommissionPayoutSettings'
import * as yup from 'yup'

const DEFAULT_ATTRIBUTION_WINDOW = 15
const DEFAULT_ORDER_MATURATION_PERIOD = 2

const useStyles = makeStyles(() =>
  createStyles({
    infoIcon: {
      fill: theme.palette.primary.main,
    },
  }),
)

export interface ProgramSettingsFields {
  startDate: Date
  attributionWindow: number
  attributionType: AttributionLandedEnum
  storeIntegrationId: string
  ambassadorBaseUrl: string
  ambassadorUgcApprovedOnly: boolean
  showOutOfStockProducts: boolean
  hideSalesPrice: boolean
  postingRulesUrl: string
  ecommCustomFieldIds: string[]
  currencyCode: string
  requiresVerification: boolean
  requiresApproval: boolean
  productPickerCollectionIds: string[]
  signupFormUrl: string
  payoutPeriod: PaymentPeriodEnum
  payoutDay: PaymentDayEnum
  payoutCustomDay: number | null
  payoutOrderMaturationPeriodDays: number
  payoutIntegrationId: string | null
  tremendousCampaignId: string | null
  autoCommissionsEnabled: boolean
  commissionsIncludeShipping: boolean
  commissionsIncludeTax: boolean
}

const schema = yup
  .object({
    payoutPeriod: yup.string(),
    payoutDay: yup.string(),
    payoutCustomDay: yup.number().nullable(),
    payoutOrderMaturationPeriodDays: yup.number(),
    payoutIntegrationId: yup.string().nullable(),
    tremendousCampaignId: yup.string().nullable(),
  })
  .test({
    test: function (values: ProgramSettingsFields) {
      if (!values.payoutPeriod) {
        return this.createError({ message: 'Payout period is required', path: 'payoutPeriod' })
      }

      if (!values.payoutDay) {
        return this.createError({ message: 'Payout day is required', path: 'payoutDay' })
      }

      if (typeof values.payoutOrderMaturationPeriodDays !== 'number') {
        return this.createError({ message: 'Order wait time is required', path: 'payoutOrderMaturationPeriodDays' })
      }

      if (values.payoutOrderMaturationPeriodDays < 0 || values.payoutOrderMaturationPeriodDays > 60) {
        return this.createError({
          message: 'Order wait time must be between 0 and 60 days',
          path: 'payoutOrderMaturationPeriodDays',
        })
      }

      return true
    },
  })

export interface CampaignSettingsCustomFieldType {
  [id: string]: string
}

function CampaignSettings(): React.ReactElement {
  const { showToast } = useToast()
  const { id } = useParams<CampaignDetailRouteParams>()

  const { data: userData } = useCampaignUserInfoQuery()

  const socialPlatform = selectedSocialPlatform(userData)

  const {
    data,
    loading,
    refetch: refetchCampaignSettings,
  } = useCampaignSettingsQuery({
    skip: !socialPlatform,
    variables: {
      id: id,
      platform: socialPlatform!,
    },
  })

  const { data: userInfo } = useCampaignSettingsUserInfoQuery()
  const [existingEcommCustomFields, setExistingEcommCustomFields] = useState<CampaignSettingsCustomFieldType>({})
  const [discountSettingsOpen, setDiscountSettingsOpen] = useState<Record<EcommDiscountCodeTypeEnum, boolean>>({
    [EcommDiscountCodeTypeEnum.Affiliate]: false,
    [EcommDiscountCodeTypeEnum.Automatic]: false,
  })

  const handleDiscountSettingsOpen = (type: EcommDiscountCodeTypeEnum, value: boolean) =>
    setDiscountSettingsOpen(prev => ({
      ...prev,
      [type]: value,
    }))

  const classes = useStyles()

  const program = data?.campaign?.program
  const accountIntegrations = userInfo?.whoami?.account?.integrations
  const isLoudcrowdInternal = userInfo?.whoami?.email?.endsWith('@loudcrowd.com')

  useEffect(() => {
    if (!data?.campaign?.program?.ecommCustomFields) return

    const existingEcommCustomFields: CampaignSettingsCustomFieldType = {}
    data.campaign.program.ecommCustomFields.forEach(customField => {
      existingEcommCustomFields[customField.id] = customField.title
    })

    setExistingEcommCustomFields(existingEcommCustomFields)
  }, [data?.campaign?.program?.ecommCustomFields])

  const [updateCampaignSettings] = useUpdateCampaignSettingsMutation({
    onError: () => {
      showToast({
        title: 'Error: Update Program Settings',
        message: 'Something went wrong when updating this program, please try again.',
        severity: 'error',
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: 'Program settings updated',
        severity: 'success',
      })
    },
  })

  const initPayoutIntegrationId =
    !!program?.programPayoutSettings?.paymentIntegration &&
    isTypeName(program.programPayoutSettings.paymentIntegration, 'TremendousIntegration')
      ? program?.programPayoutSettings?.paymentIntegration.id
      : null

  const payoutIntegrations = !!userInfo?.whoami?.account?.integrations
    ? (userInfo.whoami.account.integrations
        .map(integration => (isTypeName(integration, 'TremendousIntegration') ? integration : null))
        .filter(integration => !!integration) as TremendousIntegration[])
    : []

  const initValues: ProgramSettingsFields = {
    startDate: data?.campaign?.startAt ?? new Date(),
    attributionWindow: program?.attributionWindow ?? DEFAULT_ATTRIBUTION_WINDOW,
    attributionType:
      program && program.attributionLandedType.toString() === AttributionLandedEnum.First.toString()
        ? AttributionLandedEnum.First
        : AttributionLandedEnum.Last,
    storeIntegrationId: program?.ecommIntegration?.id ?? 'none',
    ambassadorBaseUrl: program?.ambassadorBaseUrl ?? '',
    ambassadorUgcApprovedOnly: program?.ambassadorUgcApprovedOnly ?? false,
    showOutOfStockProducts: program?.showOutOfStockProducts ?? false,
    hideSalesPrice: program?.hideSalesPrice ?? false,
    signupFormUrl: program?.signupFormUrl ?? '',
    postingRulesUrl: program?.postingRulesUrl ?? '',
    requiresVerification: data?.campaign?.requiresVerification ?? false,
    currencyCode: program?.currencyCode ?? '',
    requiresApproval: !(data?.campaign?.program?.autoApproved || false),
    ecommCustomFieldIds: Object.keys(existingEcommCustomFields).sort(),
    productPickerCollectionIds:
      data?.campaign?.program?.productPickerCollections?.map(
        productPickerCollection => productPickerCollection.collection.id,
      ) || [],
    payoutPeriod: program?.programPayoutSettings?.period ?? PaymentPeriodEnum.Monthly,
    payoutDay: program?.programPayoutSettings?.day ?? PaymentDayEnum.FirstOfMonth,
    payoutCustomDay: program?.programPayoutSettings?.customDay ?? null,
    payoutOrderMaturationPeriodDays:
      program?.programPayoutSettings?.orderMaturationPeriodDays ?? DEFAULT_ORDER_MATURATION_PERIOD,
    payoutIntegrationId: initPayoutIntegrationId ?? null,
    tremendousCampaignId: program?.programPayoutSettings?.tremendousCampaign?.code ?? null,
    autoCommissionsEnabled: program?.programPayoutSettings?.enabled ?? false,
    commissionsIncludeShipping: program?.programPayoutSettings?.includeShipping ?? true,
    commissionsIncludeTax: program?.programPayoutSettings?.includeTax ?? true,
  }

  async function onSubmitForm(values: ProgramSettingsFields) {
    const commissionsSettings = {
      period: values.payoutPeriod,
      day: values.payoutDay,
      orderMaturationPeriodDays: values.payoutOrderMaturationPeriodDays,
      payoutIntegrationId: values.payoutIntegrationId!,
      tremendousCampaignId: values.tremendousCampaignId!,
      enabled: values.autoCommissionsEnabled,
      includeShipping: values.commissionsIncludeShipping,
      includeTax: values.commissionsIncludeTax,
    }

    await updateCampaignSettings({
      variables: {
        campaignId: id,
        data: {
          startDate: values.startDate,
          attributionWindow: values.attributionWindow,
          attributionType: values.attributionType,
          storeIntegrationId: values.storeIntegrationId !== 'none' ? values.storeIntegrationId : null,
          ambassadorBaseUrl: values.ambassadorBaseUrl || null,
          ambassadorUgcApprovedOnly: values.ambassadorUgcApprovedOnly,
          showOutOfStockProducts: values.showOutOfStockProducts,
          hideSalesPrice: values.hideSalesPrice,
          postingRulesUrl: values.postingRulesUrl || null,
          autoApproved: !values.requiresApproval,
          requiresVerification: values.requiresVerification,
          ecommCustomFieldIds: values.ecommCustomFieldIds,
          productPickerCollectionIds: values.productPickerCollectionIds,
          signupFormUrl: values.signupFormUrl || null,
          payoutSettings: program?.programType === ProgramTypeEnum.ShoppableAmbassador ? commissionsSettings : null,
        },
      },
      refetchQueries: [
        {
          query: CampaignSettingsDocument,
          variables: {
            id: id,
            platform: socialPlatform!,
          },
        },
      ],
    })
  }

  const affiliateDiscountCode = data?.campaign?.program?.ecommDiscountCodes?.find(
    discountCode => discountCode.codeType === EcommDiscountCodeTypeEnum.Affiliate,
  )

  const automaticDiscount = data?.campaign?.program?.ecommDiscountCodes?.find(
    discountCode => discountCode.codeType === EcommDiscountCodeTypeEnum.Automatic,
  )

  const hasCreateAffiliateCodesCapability = !!program?.ecommIntegration?.capabilities?.find(
    c => c === IntegrationCapabilityEnum.CreateAffiliateCodes,
  )

  const hasCreateAutomaticDiscountsCapability = !!program?.ecommIntegration?.capabilities?.find(
    c => c === IntegrationCapabilityEnum.CreateAutomaticDiscounts,
  )

  // We need to hide the title for coupon code and the tooltip if...
  //  The program is using Commerce Cloud
  //  and This is not an @loudcrowd account
  //  and ecomm discount code is undefined
  // or The program is using Custom Store
  const hideDiscountTitleAndTooltip =
    (program?.ecommIntegration?.integrationType === IntegrationType.CommerceCloud &&
      !isLoudcrowdInternal &&
      !affiliateDiscountCode) ||
    program?.ecommIntegration?.integrationType === IntegrationType.CustomStore

  return (
    <Paper>
      <Box p={4}>
        {loading && (
          <Box>
            <Box p={2}>
              <Skeleton component="span" height={32} width="100%" />
            </Box>
            <Box p={2}>
              <Skeleton component="span" height={32} width="100%" />
            </Box>
            <Box p={2}>
              <Skeleton component="span" height={32} width="100%" />
            </Box>
          </Box>
        )}
        {!loading && (
          <Formik<ProgramSettingsFields>
            initialValues={initValues}
            onSubmit={onSubmitForm}
            validationSchema={schema}
            enableReinitialize
          >
            {props => (
              <Form>
                <AllTypesCampaignSettings
                  editMode={isLoudcrowdInternal}
                  integrations={accountIntegrations}
                  isIntegrationEditable={!affiliateDiscountCode && !automaticDiscount}
                />
                {program?.programType === ProgramTypeEnum.ShoppableAmbassador && (
                  <>
                    {!hideDiscountTitleAndTooltip && (
                      <Box display="flex">
                        <Typography variant="h5">
                          {program?.ecommIntegration?.integrationType === IntegrationType.CommerceCloud
                            ? 'Coupon'
                            : 'Discount'}{' '}
                          Settings
                        </Typography>
                        <Tooltip
                          placement="top"
                          title={
                            <Box display="flex" flexDirection="column">
                              <Box>
                                Affiliate codes: program members can use to give their follows a discount when they
                                purchase from your store.
                              </Box>
                              {hasCreateAutomaticDiscountsCapability && (
                                <Box>
                                  Codeless discounts: are automatically applied to the cart when a customer clicks on an
                                  ambassador's link.
                                </Box>
                              )}
                            </Box>
                          }
                        >
                          <Box ml={2} display="flex" alignItems="center">
                            <InfoIcon width={26} height={26} className={classes.infoIcon} />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}

                    <Box mt={4} mb={6} display="flex">
                      {hasCreateAffiliateCodesCapability &&
                        (program?.ecommIntegration?.integrationType === IntegrationType.CommerceCloud
                          ? isLoudcrowdInternal
                          : true) && (
                          <Button
                            onClick={() => handleDiscountSettingsOpen(EcommDiscountCodeTypeEnum.Affiliate, true)}
                            variant="outlined"
                            startIcon={<PlusIcon width={16} />}
                            color="primary"
                            disabled={!!affiliateDiscountCode}
                          >
                            Add{' '}
                            {program?.ecommIntegration?.integrationType === IntegrationType.CommerceCloud
                              ? 'coupon code'
                              : 'discount'}
                          </Button>
                        )}

                      {hasCreateAutomaticDiscountsCapability && (
                        <Button
                          onClick={() => handleDiscountSettingsOpen(EcommDiscountCodeTypeEnum.Automatic, true)}
                          variant="outlined"
                          startIcon={<PlusIcon width={16} />}
                          color="primary"
                          style={{ marginLeft: theme.spacing(2) }}
                          disabled={!!automaticDiscount}
                        >
                          Add link discount
                        </Button>
                      )}
                    </Box>
                    {hasCreateAffiliateCodesCapability && (
                      <DiscountGenerationSettings
                        programId={program.id}
                        campaignId={data?.campaign?.id.toString()}
                        ecommDiscountCode={affiliateDiscountCode}
                        discountType={EcommDiscountCodeTypeEnum.Affiliate}
                        isOpen={discountSettingsOpen[EcommDiscountCodeTypeEnum.Affiliate]}
                        handleOpen={state => handleDiscountSettingsOpen(EcommDiscountCodeTypeEnum.Affiliate, state)}
                      />
                    )}
                    {hasCreateAutomaticDiscountsCapability && (
                      <DiscountGenerationSettings
                        programId={program.id}
                        campaignId={data?.campaign?.id.toString()}
                        ecommDiscountCode={automaticDiscount}
                        discountType={EcommDiscountCodeTypeEnum.Automatic}
                        isOpen={discountSettingsOpen[EcommDiscountCodeTypeEnum.Automatic]}
                        handleOpen={state => handleDiscountSettingsOpen(EcommDiscountCodeTypeEnum.Automatic, state)}
                      />
                    )}
                    <ShoppableAmbassadorPayoutSettings
                      payoutIntegrations={payoutIntegrations}
                      isSet={!!program.programPayoutSettings}
                      program={data?.campaign?.program!}
                      refreshAfterMutation={async () => {
                        await refetchCampaignSettings({
                          id: id,
                          platform: socialPlatform!,
                        })
                      }}
                    />
                    <ShoppableAmbassadorCampaignSettings
                      campaignId={id}
                      productPickerCollections={data?.campaign?.program?.productPickerCollections || []}
                    />
                  </>
                )}
                <Box width="100%" display="flex" justifyContent="flex-end" pr={4} pb={6}>
                  <Button type="submit" color="primary" disabled={!(props.dirty && props.isValid)} variant="contained">
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </Paper>
  )
}

export default CampaignSettings
