import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMessageAutomationMutationVariables = Types.Exact<{
  automationId: Types.Scalars['ID'];
  enabled?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateMessageAutomationMutation = { __typename: 'Mutations', updateAutomation?: { __typename: 'UpdateAutomation', automation?: { __typename: 'Automation', id: string, enabled: boolean } | null } | null };


export const UpdateMessageAutomationDocument = gql`
    mutation UpdateMessageAutomation($automationId: ID!, $enabled: Boolean) {
  updateAutomation(id: $automationId, enabled: $enabled) {
    automation {
      id
      enabled
    }
  }
}
    `;
export type UpdateMessageAutomationMutationFn = Apollo.MutationFunction<UpdateMessageAutomationMutation, UpdateMessageAutomationMutationVariables>;

/**
 * __useUpdateMessageAutomationMutation__
 *
 * To run a mutation, you first call `useUpdateMessageAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageAutomationMutation, { data, loading, error }] = useUpdateMessageAutomationMutation({
 *   variables: {
 *      automationId: // value for 'automationId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateMessageAutomationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageAutomationMutation, UpdateMessageAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageAutomationMutation, UpdateMessageAutomationMutationVariables>(UpdateMessageAutomationDocument, options);
      }
export type UpdateMessageAutomationMutationHookResult = ReturnType<typeof useUpdateMessageAutomationMutation>;
export type UpdateMessageAutomationMutationResult = Apollo.MutationResult<UpdateMessageAutomationMutation>;
export type UpdateMessageAutomationMutationOptions = Apollo.BaseMutationOptions<UpdateMessageAutomationMutation, UpdateMessageAutomationMutationVariables>;