import React, { useState, useRef } from 'react'
import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  ExpansionPanel,
  ExpansionPanelDetails,
  Button,
  Card,
  CardActionArea,
  CardActions,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardContent,
} from '@material-ui/core'
import { format } from 'date-fns'
import { SettingsRewardRowFragment } from './operations/settings-reward-row.generated'
import { Skeleton } from '@material-ui/lab'
import { CodesLevel } from '../../gql-global'
import { ReactComponent as DangerIcon } from '../../icons/danger-icon.svg'
import { ReactComponent as WarningIcon } from '../../icons/warning-icon.svg'
import { ReactComponent as GiftLogo } from '../../icons/gift.svg'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up_minor.svg'
import { secondary } from '../../loudcrowd-theme'
import { CAMPAIGN_ROUTE } from '../../campaign/routes'
import { MESSAGE_ROUTE } from '../../message/routes'
import { numberFormat } from '../../utils/number-format'
import IntegrationSentWith from '../../components/IntegrationSentWith'
import { getIntegrationBrandColor } from '../../utils/rewards'
import RewardRowCollectionMenu from './RewardRowCollectionMenu'
import { CHALLENGES_ROUTE } from '../../challenges/routes'

interface StyledProps {
  brandColor: string | undefined
  hasNotification: boolean
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
      borderRadius: theme.spacing(2),
      position: 'relative',
      overflow: 'hidden',
    },
    gift: {
      fill: (props: StyledProps) => (props.brandColor ? props.brandColor : 'none'),
      position: 'absolute',
      bottom: 0,
      right: 76,
    },
    title: {
      fontSize: 20,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 500,
    },
    cardContent: {
      flexDirection: 'column',
      position: 'relative',
      height: 130,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(10),
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: 20,
        backgroundColor: (props: StyledProps) => (props.brandColor ? props.brandColor : 'none'),
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
      },
    },
  }),
)

interface RewardRowProps {
  reward?: SettingsRewardRowFragment
  loading?: boolean
  onEdit?(reward: SettingsRewardRowFragment): void
  onUpload?(reward: SettingsRewardRowFragment): void
  uploadProcessing?: boolean
  canDeleteCodes?: boolean
  onDeleteCodes?(reward: SettingsRewardRowFragment): void
}

function existsInCampaignSet(set: Set<{ id: number; name: string }>, id: number): boolean {
  set.forEach(item => {
    if (item.id === id) {
      return true
    }
  })
  return false
}

const RewardRowV2: React.FC<RewardRowProps> = ({
  reward,
  onEdit,
  onUpload,
  onDeleteCodes,
  loading = false,
  uploadProcessing = false,
  canDeleteCodes = false,
}) => {
  const { name, stats, hasDiscountCode, integration } = reward || {}

  const [openRow, setOpenRow] = useState(false)
  const [openRewardWays, setRewardWays] = useState(false)
  const [deleteCodesDialogOpen, setDeleteCodesDialogOpen] = useState(false)
  const anchorEl = useRef(null)

  const brandColor = getIntegrationBrandColor(integration?.integrationType)
  const showCodeLeft = stats?.codesLevel === CodesLevel.Low || stats?.codesLevel === CodesLevel.Empty
  const hasUploadCodes = !!(hasDiscountCode && onUpload && reward)
  const hasNotification = hasUploadCodes && showCodeLeft

  const classes = useStyles({ brandColor, hasNotification: hasNotification || hasUploadCodes, isLoading: loading })

  const campaigns = new Set<{ id: number; name: string }>()
  reward?.tiers?.forEach(t => {
    if (t?.program) {
      const campaignId = t.program.campaign?.id
      if (campaignId) {
        if (!existsInCampaignSet(campaigns, campaignId)) {
          campaigns.add({ id: campaignId, name: t.program.name })
        }
      }
    }
  })

  function handleClickRemoveCodes() {
    setDeleteCodesDialogOpen(false)
    if (onDeleteCodes && reward) {
      onDeleteCodes(reward)
    }
  }

  const getColorStatus = (): string | undefined => {
    if (stats?.codesLevel === CodesLevel.Empty) {
      return 'error.dark'
    }
    if (stats?.codesLevel === CodesLevel.Low) {
      return 'warning.dark'
    }

    return undefined
  }

  const getLowNoCodeMessage = (): string | undefined => {
    const codeUsage = `${stats?.codesRemaining}/${stats?.codesInCurrentBatch}`
    if (!stats?.codesRemaining || stats?.codesLevel === CodesLevel.Empty) {
      return `No codes left (${codeUsage})`
    }
    if (stats?.codesLevel === CodesLevel.Low) {
      return `Low Code ${codeUsage}`
    }

    return undefined
  }

  const getCodesLeftMessage = (): string => {
    return `${stats?.codesRemaining?.toString()} of ${stats?.codesInCurrentBatch?.toString()} codes left (${
      stats?.codesRemaining && stats?.codesInCurrentBatch
        ? numberFormat((stats.codesRemaining / stats?.codesInCurrentBatch) * 100, {
            format: 'percent',
          })
        : '0%'
    })`
  }

  const lowNoCodeMessage = getLowNoCodeMessage()
  const codeColorStatus = getColorStatus()
  const codesLeftMessage = getCodesLeftMessage()

  const rewardWaysTotal = campaigns.size + (reward?.challenges?.length || 0) + (reward?.messageTemplates?.length || 0)
  let linkSingleRewardWay = undefined
  const getUsedXWays = (): string => {
    if (rewardWaysTotal === 0) {
      return ''
    }

    if (rewardWaysTotal === 1) {
      if (reward?.messageTemplates?.length === 1) {
        linkSingleRewardWay = `/messages`
        return `Used in ${reward?.messageTemplates[0]?.name} Message Template`
      }
      if (campaigns.size === 1) {
        linkSingleRewardWay = `/program/${campaigns.values().next().value.id}/program-activity`
        return `Used in ${campaigns.values().next().value.name} Program`
      }
      if (reward?.challenges?.length === 1) {
        linkSingleRewardWay = `/challenges`
        return `Used in ${reward?.challenges[0]?.name} Challenge`
      }
    }

    return `Used in ${rewardWaysTotal} ways`
  }

  const usedXWays = getUsedXWays()

  const deliveryMethod =
    reward && reward?.deliveryMethod
      ? reward.deliveryMethod
      : reward?.isDmReward
      ? 'Instagram DM'
      : reward && reward?.integration?.deliveryMethods && reward?.integration?.deliveryMethods.length > 0
      ? reward?.integration?.deliveryMethods?.join(', ')
      : 'Manual'

  const hasRemoveCodes = !!(stats?.codesRemaining && canDeleteCodes && onDeleteCodes)
  const displayCodesLeft = hasUploadCodes || stats?.lastBatchAt || hasRemoveCodes
  const showNotification = hasNotification && lowNoCodeMessage

  const preventEventBubbling = (callback: () => void) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      callback()
    }
  }

  if (loading) {
    return (
      <Card className={classes.row} data-testid="reward-row-loading">
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center" mb={1} mt={2} width="100%">
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" mb={1} width="100%">
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" mb={1} width="100%">
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" mb={1} width="100%">
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Card className={classes.row}>
        <ExpansionPanel expanded={openRow} data-testid="expansion-panel">
          <Box
            position="relative"
            borderBottom={openRow ? 2 : undefined}
            style={{
              borderColor: secondary[400],
              borderBottomLeftRadius: openRow ? 20 : undefined,
            }}
          >
            <CardActionArea onClick={() => setOpenRow(!openRow)} component="div" className={classes.cardContent}>
              <Box position="absolute" right={0} top={0}>
                <IntegrationSentWith integrationType={reward?.integration?.integrationType} />
              </Box>
              {(showNotification || hasUploadCodes) && (
                <Box display="flex" flexDirection="row">
                  {showNotification && (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        fontSize="body1.fontSize"
                        lineHeight="body1.lineHeight"
                        fontWeight={500}
                        color={codeColorStatus}
                        marginRight={1}
                      >
                        {stats?.codesLevel === CodesLevel.Empty && <DangerIcon style={{ marginBottom: 2 }} />}
                        {stats?.codesLevel === CodesLevel.Low && <WarningIcon style={{ marginBottom: 2 }} />}{' '}
                        <Box marginLeft={1}>{lowNoCodeMessage}</Box>
                      </Box>

                      <Button
                        style={{
                          textDecoration: 'underline',
                          padding: 0,
                          height: 22,
                          marginTop: 2,
                          marginLeft: showNotification ? 17 : undefined,
                        }}
                        onClick={preventEventBubbling(() => onUpload(reward))}
                        disabled={uploadProcessing}
                        variant="text"
                        color="primary"
                        data-testid="upload-codes"
                      >
                        {uploadProcessing ? 'Uploading Codes...' : 'Upload Codes'}
                      </Button>
                    </>
                  )}
                </Box>
              )}
              {name && <Typography className={classes.title}>{name}</Typography>}
              <GiftLogo className={classes.gift} fill={brandColor} />
              <CardActions
                style={{
                  padding: 0,
                }}
              >
                {!!rewardWaysTotal && (
                  <>
                    <Button
                      ref={anchorEl}
                      onClick={preventEventBubbling(() => setRewardWays(!openRewardWays))}
                      variant="text"
                      color="secondary"
                      href={linkSingleRewardWay}
                      style={{
                        height: 22,
                        padding: 0,
                      }}
                    >
                      <Typography variant="button" style={{ marginRight: 10 }}>
                        {usedXWays}
                      </Typography>
                      <>
                        {rewardWaysTotal > 1 && (
                          <>
                            {openRewardWays ? (
                              <ChevronUpIcon height={20} width={20} />
                            ) : (
                              <ChevronDownIcon height={20} width={20} />
                            )}
                          </>
                        )}
                      </>
                    </Button>
                  </>
                )}
              </CardActions>
            </CardActionArea>
          </Box>
          <ExpansionPanelDetails
            style={{
              alignItems: 'flex-start',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              padding: '0',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{
                padding: '18px 16px 18px 55px',
              }}
            >
              <Box display="flex" flexDirection="column">
                {reward?.emailTemplateName && (
                  <Box mr={7} minWidth={250} display="flex" alignItems="center" mb={4}>
                    <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                      Email Template Name:
                    </Typography>
                    <Typography variant="body2">{reward?.emailTemplateName}</Typography>
                  </Box>
                )}
                {reward?.emailTemplateVariableName && (
                  <Box mr={7} minWidth={250} display="flex" alignItems="center" mb={4}>
                    <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                      Discount Code Field Name:
                    </Typography>
                    <Typography variant="body2">{reward?.emailTemplateVariableName}</Typography>
                  </Box>
                )}
                {reward?.tierId && (
                  <Box mr={7} minWidth={250} display="flex" alignItems="center" mb={4}>
                    <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                      Loyalty Tier Id:
                    </Typography>
                    <Typography variant="body2">{reward?.tierId}</Typography>
                  </Box>
                )}
                {reward?.templateId && (
                  <Box mr={7} minWidth={250} display="flex" alignItems="center" mb={4}>
                    <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                      Template Id:
                    </Typography>
                    <Typography variant="body2">{reward?.templateId}</Typography>
                  </Box>
                )}
                {reward?.eventId && (
                  <Box mr={7} minWidth={250} display="flex" alignItems="center" mb={4}>
                    <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                      Event Id:
                    </Typography>
                    <Typography variant="body2">{reward?.eventId}</Typography>
                  </Box>
                )}
                {reward?.value && (
                  <Box mr={7} minWidth={250} display="flex" alignItems="center" mb={4}>
                    <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                      Value:
                    </Typography>
                    <Typography variant="body2">{reward?.value}</Typography>
                  </Box>
                )}
                <Box mr={7} minWidth={250} display="flex" alignItems="center">
                  <Typography variant="subtitle1" color="secondary" style={{ marginRight: 8 }}>
                    Delivery Method:
                  </Typography>
                  <Typography noWrap variant="body2">
                    {deliveryMethod}
                  </Typography>
                </Box>
              </Box>
              {onEdit && reward && (
                <Button variant="text" color="secondary" onClick={() => onEdit(reward)} data-testid="edit-reward">
                  Edit Reward
                </Button>
              )}
            </Box>
            {displayCodesLeft && (
              <Box
                style={{
                  borderTop: `2px solid ${secondary[400]}`,
                  padding: '26px 16px 28px 58px',
                }}
                display="flex"
                alignItems="center"
                width="100%"
              >
                <Box display="flex" flexDirection="column" width="100%">
                  <Box
                    fontSize="subtitle1.fontSize"
                    lineHeight="subtitle1.lineHeight"
                    color="secondary.main"
                    fontWeight={600}
                    mb={!!stats?.lastBatchAt ? 1 : 0}
                  >
                    {codesLeftMessage}
                  </Box>

                  {stats?.lastBatchAt && (
                    <Box fontSize={12} lineHeight={1.5} color="secondary.main" fontWeight={600}>
                      Last Upload: {format(stats.lastBatchAt, 'MMMM d, yyyy')}
                    </Box>
                  )}
                </Box>
                {(hasUploadCodes || hasRemoveCodes) && (
                  <Box display="flex" width="100%" justifyContent="flex-end">
                    {hasUploadCodes && (
                      <Button
                        onClick={() => onUpload(reward)}
                        disabled={uploadProcessing}
                        variant="text"
                        color="primary"
                      >
                        {uploadProcessing ? 'Uploading Codes...' : 'Upload Codes'}
                      </Button>
                    )}
                    {hasRemoveCodes && (
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => setDeleteCodesDialogOpen(true)}
                        data-testid="delete-code"
                      >
                        Remove codes
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
      {rewardWaysTotal > 1 && (
        <Menu anchorEl={anchorEl.current} keepMounted open={openRewardWays} onClose={() => setRewardWays(false)}>
          <RewardRowCollectionMenu
            collection={Array.from(campaigns)}
            label="Programs"
            route={CAMPAIGN_ROUTE.path}
            includesId
          />
          <RewardRowCollectionMenu collection={reward?.messageTemplates} label="Messages" route={MESSAGE_ROUTE.path} />
          <RewardRowCollectionMenu collection={reward?.challenges} label="Challenges" route={CHALLENGES_ROUTE.path} />
        </Menu>
      )}
      {reward && onDeleteCodes && (
        <Dialog open={deleteCodesDialogOpen} onClose={() => setDeleteCodesDialogOpen(false)}>
          <DialogTitle>Remove Reward Codes?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to remove codes for reward {reward.name}? This cannot be undone!
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteCodesDialogOpen(false)
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={handleClickRemoveCodes} color="primary">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default RewardRowV2
