import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserIntercomDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserIntercomDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, account?: { __typename: 'AccountType', id: number, socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> | null } | null } | null };


export const UserIntercomDataDocument = gql`
    query UserIntercomData {
  whoami {
    id
    email
    account {
      id
      socialAccounts {
        id
      }
    }
  }
}
    `;

/**
 * __useUserIntercomDataQuery__
 *
 * To run a query within a React component, call `useUserIntercomDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIntercomDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIntercomDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIntercomDataQuery(baseOptions?: Apollo.QueryHookOptions<UserIntercomDataQuery, UserIntercomDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIntercomDataQuery, UserIntercomDataQueryVariables>(UserIntercomDataDocument, options);
      }
export function useUserIntercomDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIntercomDataQuery, UserIntercomDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIntercomDataQuery, UserIntercomDataQueryVariables>(UserIntercomDataDocument, options);
        }
export type UserIntercomDataQueryHookResult = ReturnType<typeof useUserIntercomDataQuery>;
export type UserIntercomDataLazyQueryHookResult = ReturnType<typeof useUserIntercomDataLazyQuery>;
export type UserIntercomDataQueryResult = Apollo.QueryResult<UserIntercomDataQuery, UserIntercomDataQueryVariables>;