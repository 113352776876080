import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { MessageFilterFragmentFragmentDoc } from './message-template-filter-fragment.generated';
export type MessageTemplateFragmentFragment = { __typename: 'MessageTemplate', id: string, name: string, template: string, skipSendMessage: Types.SkipMessageTemplateActionEnum, skipActionTimeBuffer?: number | null, customerTriggerQuantity?: number | null, automation?: { __typename: 'Automation', id: string, enabled: boolean, trigger: { __typename: 'MentionedTrigger', id: string, filter?: { __typename: 'ContentFilterType', id: string, name: string, entity: Types.FilterEntity, isSystem: boolean, value?: { __typename: 'ContentFilterValueType', followerCount?: { __typename: 'IntegerRangeFilter', gte?: number | null, lte?: number | null } | null, segments?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, campaigns?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, biography?: { __typename: 'KeywordsFilter', any?: Array<string> | null, none?: Array<string> | null } | null } | null } | null }, action: { __typename: 'AutomationAction', id: string } } | null, stats?: { __typename: 'MessageTemplateStats', totalRecipients: number } | null, reward?: { __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesInCurrentBatch?: number | null, codesRemaining?: number | null, codesLevel?: Types.CodesLevel | null } } | null };

export const MessageTemplateFragmentFragmentDoc = gql`
    fragment MessageTemplateFragment on MessageTemplate {
  id
  name
  template
  skipSendMessage
  skipActionTimeBuffer
  customerTriggerQuantity
  automation {
    id
    enabled
    trigger {
      ... on MentionedTrigger {
        id
        filter {
          id
          ... on ContentFilterType {
            ...MessageFilterFragment
          }
        }
      }
    }
    action {
      id
    }
  }
  stats(where: $statsWhere) {
    totalRecipients
  }
  reward {
    id
    name
    stats {
      codesInCurrentBatch
      codesRemaining
      codesLevel
    }
  }
}
    ${MessageFilterFragmentFragmentDoc}`;