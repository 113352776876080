import React from 'react'
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg'
import { ReactComponent as TikTokIcon } from '../../icons/tiktok_logo_outline.svg'
import { ReactComponent as SpeakerIcon } from '../../icons/speaker.svg'
import { ReactComponent as SmileyIcon } from '../../icons/smiley.svg'
import { ReactComponent as BillfoldIcon } from '../../icons/billfold.svg'
import { ReactComponent as DollarIcon } from '../../icons/dollar-sign.svg'
import { ReactComponent as ShoppingBagIcon } from '../../icons/shopping-bag.svg'
import { ReactComponent as CustomerIcon } from '../../icons/customer.svg'
import { ReactComponent as LTVIcon } from '../../icons/ltv.svg'
import { ReactComponent as CommissionIcon } from '../../icons/commission.svg'
import { ReactComponent as RoaIcon } from '../../icons/roas.svg'
import { ReactComponent as PageVisitsIcon } from '../../icons/page-visits.svg'
import { ReactComponent as ConversionRateIcon } from '../../icons/conversion-rate.svg'

class IconMap {
  instagram = InstagramIcon
  tiktok = TikTokIcon
  speaker = SpeakerIcon
  smiley = SmileyIcon
  billfold = BillfoldIcon
  dollar = DollarIcon
  shopbag = ShoppingBagIcon
  customer = CustomerIcon
  ltv = LTVIcon
  commission = CommissionIcon
  roa = RoaIcon
  pageVisits = PageVisitsIcon
  conversion = ConversionRateIcon
}

export type IconType = keyof IconMap

const iconMap = new IconMap()

const MetricIcon: React.FC<{ icon: IconType; width?: number; height?: number; className?: string }> = ({
  icon,
  width,
  height,
  className,
}) => {
  const Icon = iconMap[icon]
  return <Icon width={width || 20} height={height || 20} className={className} />
}

export default MetricIcon
