import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSegmentMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type CreateSegmentMutation = { __typename: 'Mutations', createSegment?: { __typename: 'CreateSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, name: string, customerCount?: number | null, account?: { __typename: 'AccountType', id: number } | null } | null } | null };


export const CreateSegmentDocument = gql`
    mutation CreateSegment($name: String!) {
  createSegment(name: $name) {
    ok
    segment {
      id
      name
      customerCount
      account {
        id
      }
    }
  }
}
    `;
export type CreateSegmentMutationFn = Apollo.MutationFunction<CreateSegmentMutation, CreateSegmentMutationVariables>;

/**
 * __useCreateSegmentMutation__
 *
 * To run a mutation, you first call `useCreateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentMutation, { data, loading, error }] = useCreateSegmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSegmentMutation, CreateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSegmentMutation, CreateSegmentMutationVariables>(CreateSegmentDocument, options);
      }
export type CreateSegmentMutationHookResult = ReturnType<typeof useCreateSegmentMutation>;
export type CreateSegmentMutationResult = Apollo.MutationResult<CreateSegmentMutation>;
export type CreateSegmentMutationOptions = Apollo.BaseMutationOptions<CreateSegmentMutation, CreateSegmentMutationVariables>;