import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import theme from '../loudcrowd-theme'
import { CustomerDetailsFragment } from './operations/customer-details.generated'
import { numberFormat } from '../utils/number-format'
import {
  CurrencyCodeEnum,
  PaymentPeriod,
  PaymentPeriodEnum,
  ProgramPayoutSettingsType,
  ProgramTypeEnum,
} from '../gql-global'
import { useAmbassadorStatsLazyQuery } from './operations/ambassador-stats.generated'
import { SelectionList } from '../components/SelectionPicker/SelectionList'
import { ReactComponent as ChevronDownMinorIcon } from '../icons/chevron-down_minor.svg'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { subDays, startOfMonth, subMinutes } from 'date-fns'
import {
  formatUTCDateToClientLocalTime,
  paymentPeriodsDateFormat,
  paymentPeriodsNextPayoutFormat,
} from '../utils/date-format'
import { getPaymentPeriodEndDate } from '../utils/date-range-helper'
import useHasFeature from '../hooks/useHasFeature'

type PaymentPeriodSelectOption = {
  label: string
  value: Partial<PaymentPeriod>
}

interface CustomerDetailStatsProps {
  socialAccountId: string
  customer?: CustomerDetailsFragment | null
  isShoppableAmbassador: boolean
}

const CustomerDetailStats = ({
  socialAccountId,
  customer,
  isShoppableAmbassador,
}: CustomerDetailStatsProps): JSX.Element => {
  /**
   * Queries
   */
  const [executeAmbassadorStatsQuery, ambassadorStatsQueryResults] = useAmbassadorStatsLazyQuery()

  /**
   * States
   */
  const [availablePaymentPeriods, setAvailablePaymentPeriodArr] = useState<PaymentPeriodSelectOption[]>([])
  const [selectedPaymentPeriods, setSelectedPaymentPeriods] = useState<PaymentPeriodSelectOption[]>([])
  const [currentPaymentPeriod, setCurrentPaymentPeriod] = useState<PaymentPeriodSelectOption | null>(null)
  const [currencyCode, setCurrencyCode] = useState<CurrencyCodeEnum>(CurrencyCodeEnum.Usd)
  const [nextPaymentDate, setNextPaymentDate] = useState<Date | null>()
  const [programPayoutSettings, setProgramPayoutSettings] = useState<Partial<ProgramPayoutSettingsType> | null>()
  const [selectedEcommIntegration, setSelectedEcommIntegration] = useState<{ id: string; displayName: string }>()

  /**
   * Effects
   */

  const { hasFeature: hasLineItemCalculationsFeature } = useHasFeature('hasLineItemCalculations')

  // Execute initial queries - grab timeseries data for stats by period & get current period pending/awaiting approval data
  useEffect(() => {
    // don't hit the api with empty values for socialAccountId or customerId
    if (!socialAccountId || !customer?.id) return

    const program = customer?.programParticipants
      .filter(pp => pp.program.programType === ProgramTypeEnum.ShoppableAmbassador && pp.program?.ecommIntegration?.id === selectedEcommIntegration?.id)
      .sort(
        (a, b) =>
          (b.program.programPayoutSettings?.orderMaturationPeriodDays || 0) -
          (a.program.programPayoutSettings?.orderMaturationPeriodDays || 0),
      )[0]?.program

    setNextPaymentDate(program?.nextPaymentDate)
    setCurrencyCode(program?.currencyCode || CurrencyCodeEnum.Usd)
    setProgramPayoutSettings(program?.programPayoutSettings)

    const paymentPeriodInterval = program?.programPayoutSettings?.period || PaymentPeriodEnum.Monthly
    const maturationPeriodDays = program?.programPayoutSettings?.orderMaturationPeriodDays || 0

    const periodPaymentDate = getPaymentPeriodEndDate(paymentPeriodInterval, 0)
    const periodEndDate = getPaymentPeriodEndDate(paymentPeriodInterval, maturationPeriodDays)
    const periodStartDate = subDays(startOfMonth(new Date()), maturationPeriodDays)
    periodStartDate.setUTCHours(0, 0, 0, 0)

    const currentPaymentPeriod = {
      label: paymentPeriodsDateFormat(periodPaymentDate, 'long'),
      value: {
        id: 'current',
        endAt: periodEndDate,
        startAt: periodStartDate,
        paymentAt: periodPaymentDate,
      },
    }
    setCurrentPaymentPeriod(currentPaymentPeriod)
    setSelectedPaymentPeriods([currentPaymentPeriod])

    if (customer?.paymentPeriods) {
      setAvailablePaymentPeriodArr([
        currentPaymentPeriod,
        ...customer.paymentPeriods.map(paymentPeriod => ({
          label: paymentPeriodsDateFormat(paymentPeriod.paymentAt, 'long'),
          value: paymentPeriod,
        })),
      ])
    }

    // skip in selected ecomm integration id is not set
    if (!!selectedEcommIntegration?.id) {
      void executeAmbassadorStatsQuery({
        variables: {
          socialAccountId: socialAccountId,
          customerId: customer?.id.toString() || '',
          pendingStatsRangeAt: [{ lt: currentPaymentPeriod.value.endAt, gte: currentPaymentPeriod.value.startAt }],
          currencyCode: program?.currencyCode || CurrencyCodeEnum.Usd,
          allowLineItemCalculations: hasLineItemCalculationsFeature,
          ...(!!programPayoutSettings?.orderMaturationPeriodDays
            ? { orderMaturationDate: subDays(new Date(), programPayoutSettings.orderMaturationPeriodDays) }
            : {}),
          integrationId: selectedEcommIntegration.id,
        },
      })
    }
  }, [customer, executeAmbassadorStatsQuery, socialAccountId, programPayoutSettings, hasLineItemCalculationsFeature, selectedEcommIntegration])

  // When dropdown filtering is used, aggregate data and set stats state
  useEffect(() => {
    if (!socialAccountId || !customer?.id || !selectedEcommIntegration?.id) return

    void executeAmbassadorStatsQuery({
      variables: {
        socialAccountId: socialAccountId,
        customerId: customer?.id.toString() || '',
        pendingStatsRangeAt: selectedPaymentPeriods.map(paymentPeriod => ({
          gte: paymentPeriod.value.startAt,
          lt: paymentPeriod.value.endAt,
        })),
        allowLineItemCalculations: hasLineItemCalculationsFeature,
        currencyCode: currencyCode,
        ...(!!programPayoutSettings?.orderMaturationPeriodDays
          ? { orderMaturationDate: subDays(new Date(), programPayoutSettings.orderMaturationPeriodDays) }
          : {}),
        integrationId: selectedEcommIntegration.id,
      },
    })
  }, [
    customer,
    executeAmbassadorStatsQuery,
    socialAccountId,
    programPayoutSettings,
    selectedPaymentPeriods,
    currencyCode,
    hasLineItemCalculationsFeature,
    selectedEcommIntegration,
  ])
  const loading = ambassadorStatsQueryResults.loading
  const statsData = ambassadorStatsQueryResults.data?.socialAccount?.customer
  const defaultPeriodSelected =
    selectedPaymentPeriods.length === 1 && selectedPaymentPeriods[0] === currentPaymentPeriod

  const ecommIntegrations = useMemo(() => {
    const integrations: Set<{ id: string; displayName: string }> = new Set()
    customer?.programParticipants.forEach(pp => {
      if (pp.program.ecommIntegration) {
        integrations.add({
          id: pp.program.ecommIntegration.id,
          displayName: pp.program.ecommIntegration.displayName || pp.program.ecommIntegration.name,
        })
      }
    })
    return integrations
  }, [customer?.programParticipants])

  useEffect(() => {
    if (ecommIntegrations.size > 0) {
      setSelectedEcommIntegration(Array.from(ecommIntegrations)[0])
    }
  }, [ecommIntegrations, selectedEcommIntegration, setSelectedEcommIntegration])

  function handleChangeEcommIntegrationForStats(id: string) {
    // handle change of ecomm integration for stats
    const selectedEcommIntegration = Array.from(ecommIntegrations).find(ecommIntegration => ecommIntegration.id === id)
    setSelectedEcommIntegration(selectedEcommIntegration)
  }

  return (
    <>
      {isShoppableAmbassador && (
        <>
          <Box display="flex" flexWrap="nowrap" mt={6} px={3} width={'100%'}>
            <Box flex={1} ml={2} mr={5}>
              <Typography color="secondary" variant="body2">
                Total Referred Revenue
              </Typography>
              <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                {loading && <Skeleton component="span" />}
                {!loading &&
                  numberFormat(statsData?.totalAmbassadorStats?.revenueApproved || 0, {
                    format: 'currency',
                    currencyCode: currencyCode,
                  })}
              </Typography>
            </Box>
            <Box flex={1} ml={2} mr={5}>
              <Typography color="secondary" variant="body2">
                Total Referred Orders
              </Typography>
              <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                {loading && <Skeleton component="span" />}
                {!loading && numberFormat(statsData?.totalAmbassadorStats?.ordersApproved || 0)}
              </Typography>
            </Box>
            <Box flex={1} ml={2} mr={5}>
              <Typography color="secondary" variant="body2">
                Commissions Earned
              </Typography>
              <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                {loading && <Skeleton component="span" />}
                {!loading &&
                  numberFormat(
                    (statsData?.totalAmbassadorStats?.commissionsPaid || 0) +
                    (statsData?.totalAmbassadorStats?.commissionsPending || 0),
                    { format: 'currency', currencyCode: currencyCode },
                  )}
              </Typography>
            </Box>
          </Box>
          <Box
            mt={6}
            pb={3}
            pt={4}
            px={3}
            width={'100%'}
            maxWidth={'567px'}
            style={{ backgroundColor: theme.palette.secondary.light, borderRadius: '5px' }}
          >
            <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
              {ecommIntegrations.size > 1 && (
                <>
                  <CustomerDetailStatsEcommIntegrationFilter
                    ecommIntegrations={Array.from(ecommIntegrations)}
                    selectedEcommIntegrationId={selectedEcommIntegration?.id}
                    setSelectedEcommIntegration={(id: string) => handleChangeEcommIntegrationForStats(id)}
                  />
                  <Box mr={3} />
                </>
              )}
              <CustomerDetailStatsPaymentPeriodFilter
                availablePaymentPeriods={availablePaymentPeriods}
                setSelectedPaymentPeriods={setSelectedPaymentPeriods}
                currentPaymentPeriod={currentPaymentPeriod}
              />
            </Box>
            <Box ml={2} mt={-2}>
              <Typography variant="caption" style={{ marginLeft: 8, fontStyle: 'italic', color: '#7C7CA4' }}>
                {defaultPeriodSelected && (
                  <>
                    <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
                      Period ends{' '}
                      {!!currentPaymentPeriod.value.endAt &&
                        formatUTCDateToClientLocalTime(subMinutes(currentPaymentPeriod.value.endAt, 1))}
                    </Typography>
                  </>
                )}
                {!defaultPeriodSelected && selectedPaymentPeriods.length === 1 && (
                  <>
                    <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
                      Approved orders for
                      {` ${paymentPeriodsDateFormat(
                        selectedPaymentPeriods[0]?.value.startAt || new Date(),
                      )} - ${paymentPeriodsDateFormat(selectedPaymentPeriods[0]?.value.endAt || new Date())}`}
                    </Typography>
                  </>
                )}
              </Typography>
            </Box>
            <Box display="flex" flexWrap="nowrap">
              <Box flex={1} ml={2}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box flex={1} ml={2}>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }} variant="body1">
                      {loading && <Skeleton component="span" />}
                      {!loading &&
                        `${numberFormat(statsData?.filteredAmbassadorStats?.revenueApproved || 0, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })} revenue`}
                    </Typography>
                  </Box>
                  <Box flex={1} ml={2}>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="caption">
                      {!loading &&
                        `${numberFormat(statsData?.filteredAmbassadorStats?.revenueAwaitingApproval || 0, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })} awaiting approval`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box flex={1} ml={2}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box flex={1} ml={2}>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }} variant="body1">
                      {loading && <Skeleton component="span" />}
                      {!loading && `${numberFormat(statsData?.filteredAmbassadorStats?.ordersApproved || 0)} orders`}
                    </Typography>
                  </Box>
                  <Box flex={1} ml={2}>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="caption">
                      {!loading &&
                        `${numberFormat(
                          statsData?.filteredAmbassadorStats?.ordersAwaitingApproval || 0,
                        )} awaiting approval`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box flex={1.5} ml={2}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  {selectedPaymentPeriods.length === 1 && selectedPaymentPeriods[0] === currentPaymentPeriod ? (
                    <Box display="flex" flexDirection="row" flexWrap="wrap" ml={2} alignItems={'center'}>
                      <Box flex={1}>
                        <Typography
                          style={{
                            marginTop: theme.spacing(1),
                            fontWeight: 500,
                            fontSize: 15,
                            marginRight: 5,
                            textWrap: 'nowrap',
                          }}
                          variant="body1"
                        >
                          {loading && <Skeleton component="span" />}
                          {!loading &&
                            nextPaymentDate &&
                            `+${numberFormat(statsData?.filteredAmbassadorStats?.commissionsPending || 0, {
                              format: 'currency',
                              currencyCode: currencyCode,
                            })}`}
                        </Typography>
                      </Box>
                      <Box flex={1}>
                        <Typography
                          style={{
                            textWrap: 'nowrap',
                            marginTop: theme.spacing(1),
                            fontWeight: 400,
                            fontSize: 12,
                            fontStyle: 'italic',
                          }}
                          variant="body1"
                        >
                          {loading && <Skeleton component="span" />}
                          {!loading &&
                            nextPaymentDate &&
                            `Next Payout ${paymentPeriodsNextPayoutFormat(nextPaymentDate)}`}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography
                        style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }}
                        variant="body1"
                      >
                        {loading && <Skeleton component="span" />}
                        {!loading &&
                          `${numberFormat(statsData?.filteredAmbassadorStats?.commissionsPaid || 0, {
                            format: 'currency',
                            currencyCode: currencyCode,
                          })} paid`}
                      </Typography>
                      <Typography
                        style={{ marginTop: theme.spacing(1), fontWeight: 400, fontSize: 13, fontStyle: 'italic' }}
                        variant="body1"
                      >
                        {loading && <Skeleton component="span" />}
                        {!loading &&
                          nextPaymentDate &&
                          !!statsData?.filteredAmbassadorStats?.commissionsPending &&
                          statsData?.filteredAmbassadorStats?.commissionsPending > 0 &&
                          `+${numberFormat(statsData?.filteredAmbassadorStats?.commissionsPending, {
                            format: 'currency',
                            currencyCode: currencyCode,
                          })} Next Payout ${paymentPeriodsNextPayoutFormat(nextPaymentDate)}`}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: theme.spacing(1),
                          fontWeight: 400,
                          fontSize: 13,
                          fontStyle: 'italic',
                          color: theme.palette.error.dark,
                        }}
                        variant="body1"
                      >
                        {loading && <Skeleton component="span" />}
                        {!loading &&
                          !!statsData?.filteredAmbassadorStats?.commissionsOwed &&
                          statsData?.filteredAmbassadorStats?.commissionsOwed > 0 &&
                          `+${numberFormat(statsData?.filteredAmbassadorStats?.commissionsOwed, {
                            format: 'currency',
                            currencyCode: currencyCode,
                          })} Owed`}
                      </Typography>
                    </>
                  )}
                  <Box flex={1} ml={2}>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="caption">
                      {!loading &&
                        `${numberFormat(statsData?.filteredAmbassadorStats?.commissionsAwaitingApproval || 0, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })} awaiting approval`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default CustomerDetailStats

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
      border: '1px solid',
      borderRadius: 100,
      padding: '0px 10px',
      cursor: 'pointer',
      height: '27px',
      fontWeight: 500,
      justifyContent: 'start',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    buttonText: {
      // width: '87%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 500,
    },
    chevronIcon: {
      marginLeft: '3px',
      width: '17px',
    },
    placeholder: {
      display: 'none',
    },
    select: {
      '& .MuiSelect-select': {
        color: theme.palette.secondary.main,
        opacity: 0.65,
      },
    },
    menu: {
      '& .MuiMenu-paper': {
        minWidth: '250px !important',
        height: '375px',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        marginTop: '50px',
      },
      '& .MuiList-root': {
        height: '215px',
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        padding: 0,
      },
      '& ul': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    search: {
      width: '100%',
    },
  }),
)

interface CustomerDetailStatsEcommIntegrationFilterProps {
  ecommIntegrations: { id: string; displayName: string }[]
  selectedEcommIntegrationId?: string | null
  setSelectedEcommIntegration: (value: string) => void
}

const CustomerDetailStatsEcommIntegrationFilter = ({
  ecommIntegrations,
  selectedEcommIntegrationId = null,
  setSelectedEcommIntegration,
}: CustomerDetailStatsEcommIntegrationFilterProps) => {
  const classes = useStyles()
  const filterRef = useRef(null)

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <Button
        ref={filterRef}
        className={classes.button}
        onClick={() => setMenuOpen(!menuOpen)}
        endIcon={<ChevronDownMinorIcon className={classes.chevronIcon} />}
      >
        <Typography className={classes.buttonText} variant="body2">
          {selectedEcommIntegrationId
            ? ecommIntegrations.find(ecommIntegration => ecommIntegration.id === selectedEcommIntegrationId)
              ?.displayName
            : 'All Integrations'}
        </Typography>
      </Button>
      <Menu
        className={classes.menu}
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false)
        }}
        anchorEl={filterRef.current}
      >
        <Box style={{ width: '100%', textAlign: 'center' }} mb={2}>
          <Typography style={{ fontSize: '.8rem', fontWeight: 600, margin: '10px 0' }}>Ecommerce Store</Typography>
          <Divider />
        </Box>
        {ecommIntegrations.map(ecommIntegration => (
          <MenuItem
            key={ecommIntegration.id}
            value={ecommIntegration.id}
            selected={selectedEcommIntegrationId === ecommIntegration.id}
            onClick={() => {
              setMenuOpen(false)
              setSelectedEcommIntegration(ecommIntegration.id)
            }}
          >
            {ecommIntegration.displayName}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

interface CustomerDetailStatsPaymentPeriodFilterProps {
  availablePaymentPeriods: PaymentPeriodSelectOption[]
  setSelectedPaymentPeriods: React.Dispatch<React.SetStateAction<PaymentPeriodSelectOption[]>>
  currentPaymentPeriod: PaymentPeriodSelectOption | null
}

const CustomerDetailStatsPaymentPeriodFilter = ({
  availablePaymentPeriods,
  setSelectedPaymentPeriods,
  currentPaymentPeriod,
}: CustomerDetailStatsPaymentPeriodFilterProps) => {
  const defaultDateRangeLabel = 'Current Period'
  const classes = useStyles()
  const filterRef = useRef(null)

  /**
   * States
   */
  const [menuOpen, setMenuOpen] = useState(false)
  const [selectionListIds, setSelectionListIds] = useState<ReadonlySet<string>>()

  const [filteredPaymentPeriods, setFilteredPaymentPeriods] =
    useState<PaymentPeriodSelectOption[]>(availablePaymentPeriods)
  const [dateFilterLabel, setDateFilterLabel] = useState(defaultDateRangeLabel)
  const [searchText, setSearchText] = useState<string>('')

  /**
   * Effect
   */
  useEffect(() => {
    if (searchText === '') setFilteredPaymentPeriods(availablePaymentPeriods)
    const lowerSearchText = (searchText || '').toLowerCase()
    setFilteredPaymentPeriods(
      availablePaymentPeriods.filter(paymentPeriod => paymentPeriod.label.toLowerCase().includes(lowerSearchText)),
    )
  }, [searchText, availablePaymentPeriods])

  useEffect(() => {
    if (selectionListIds?.size === 0) {
      setSelectedPaymentPeriods(currentPaymentPeriod ? [currentPaymentPeriod] : [])
      setDateFilterLabel(defaultDateRangeLabel)
      return
    }
    let selectedPaymentPeriods = availablePaymentPeriods.filter(
      paymentPeriod => paymentPeriod.value.id && selectionListIds?.has(paymentPeriod.value.id),
    )

    if (selectedPaymentPeriods.length === 1) {
      setDateFilterLabel(selectedPaymentPeriods[0]?.label || '')
    } else if (selectedPaymentPeriods.length > 1) {
      setDateFilterLabel('Multiple payment periods')
    } else {
      setDateFilterLabel(defaultDateRangeLabel)
    }
    setSelectedPaymentPeriods(selectedPaymentPeriods)
  }, [selectionListIds, availablePaymentPeriods, currentPaymentPeriod, setSelectedPaymentPeriods])

  useEffect(() => {
    setFilteredPaymentPeriods(availablePaymentPeriods)
    if (currentPaymentPeriod?.value.id) {
      setSelectionListIds(new Set([currentPaymentPeriod.value.id]))
    }
  }, [availablePaymentPeriods, currentPaymentPeriod])

  return (
    <>
      <Button
        className={classes.button}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        ref={filterRef}
      >
        {dateFilterLabel} <ChevronDownMinorIcon className={classes.chevronIcon} />
      </Button>
      <Menu
        className={classes.menu}
        placeholder="Current Period"
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false)
        }}
        anchorEl={filterRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        style={{ flex: 1, width: '100%' }}
      >
        <Box style={{ width: '100%', textAlign: 'center' }} mb={2}>
          <Typography style={{ fontSize: '.8rem', fontWeight: 600, margin: '10px 0' }}>Payment Dates</Typography>
          <Divider />
        </Box>
        <Box paddingX={2} width={'100%'}>
          <OutlinedInput
            type="text"
            className={classes.search}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            fullWidth
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon width={16} height={16} />
              </InputAdornment>
            }
          />
          {filteredPaymentPeriods.length === 0 && (
            <Typography style={{ paddingTop: '20px', paddingLeft: '10px', position: 'absolute' }}>
              No payment periods to display
            </Typography>
          )}
        </Box>
        <SelectionList
          options={filteredPaymentPeriods.map(paymentPeriod => ({
            id: paymentPeriod.value.id ?? '',
            label: paymentPeriod.label,
          }))}
          selectedOptions={selectionListIds}
          onChangeOptions={selectedValues => {
            setSelectionListIds(selectedValues)
            setMenuOpen(false)
          }}
          toggleReset={false}
          onReset={() => {
            setSearchText('')
            setDateFilterLabel(defaultDateRangeLabel)
            return new Set<string>()
          }}
          styles={{ width: '100%' }}
        />
      </Menu>
    </>
  )
}
