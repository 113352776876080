import { useReducer, Dispatch, Reducer } from 'react'

export type SelectionState<T = number> = ReadonlySet<T> | 'ALL' | null

export type SelectionAction<T = number> =
  | { type: 'selectAll' }
  | { type: 'select'; id: T | T[] }
  | { type: 'deselect'; id: T | T[]; idsVisible: T[] }
  | { type: 'reset' }
  | { type: 'toggleSelectAll' }

function selectionReducer<T = number>(state: SelectionState<T>, action: SelectionAction<T>): SelectionState<T> {
  switch (action.type) {
    case 'selectAll':
      return 'ALL'
    case 'select': {
      if (state === 'ALL') {
        return state
      }
      const ids = Array.isArray(action.id) ? action.id : [action.id]
      if (!state) {
        return new Set(ids)
      }
      const newSet = new Set(state)
      for (const i of ids) {
        newSet.add(i)
      }
      return newSet
    }
    case 'deselect': {
      const ids = Array.isArray(action.id) ? action.id : [action.id]
      if (!state) {
        return null
      }
      let newSet = new Set<T>()
      if (state === 'ALL') {
        // TODO this is weird, if we are selecteding by all
        // and deselect one, then it goes to select all visible minus the deselected one
        newSet = new Set(action.idsVisible)
      } else {
        newSet = new Set(state)
      }
      for (const i of ids) {
        newSet.delete(i)
      }
      if (newSet.size === 0) {
        return null
      }
      return newSet
    }
    case 'reset':
      return null
    case 'toggleSelectAll':
      return state !== 'ALL' ? 'ALL' : null
  }
}

function useSelectionState<T = number>(): [SelectionState<T>, Dispatch<SelectionAction<T>>] {
  return useReducer<Reducer<SelectionState<T>, SelectionAction<T>>>(selectionReducer, null)
}

export default useSelectionState
