import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import {
  IntegrationRow_RefersionIntegration_Fragment,
  IntegrationRow_ImpactIntegration_Fragment,
} from './operations/integration-row.generated'
import { Formik, Field, Form, FieldArray } from 'formik'
import { TextField, Select } from 'formik-material-ui'
import * as yup from 'yup'
import { IntegrationType } from '../gql-global'
import { isApolloError } from '@apollo/client'
import { ReactComponent as TrashIcon } from '../icons/trash_can.svg'

// Base schema shared by all integration types
const baseSchema = {
  name: yup.string().trim().required('Integration name is required.'),
  integrationType: yup.string().required('Integration type is required'),
}

// Refersion-specific schema
const refersionSchema = yup.object().shape({
  ...baseSchema,
  key: yup.string().trim().required('Refersion key is required.'),
  igUsernameFields: yup
    .array()
    .of(yup.string().trim().required('username field cannot be empty'))
    .min(1)
    .ensure()
    .compact()
    .required('Username field id required.'),
  ttUsernameFields: yup.array().of(yup.string().trim().required('username field cannot be empty')).compact(),
})

// Impact-specific schema
const impactSchema = yup.object().shape({
  ...baseSchema,
  accountSid: yup.string().trim().required('Account SID is required.'),
  accessToken: yup.string().trim().required('Access Token is required.'),
  campaignId: yup.string().trim().required('Campaign ID is required.'),
  linkTextMessage: yup.string().trim().required('Link Text Message is required.'),
  campaignRedirectDomain: yup
    .string()
    .trim()
    .url('Must be a valid URL')
    .required('Campaign Redirect Domain is required.'),
})

// Type definitions for each integration
interface BaseIntegrationFields {
  name: string
  integrationType: IntegrationType
}

interface RefersionFields extends BaseIntegrationFields {
  key: string
  igUsernameFields: string[]
  ttUsernameFields: string[]
}

interface ImpactFields extends BaseIntegrationFields {
  accountSid: string
  accessToken: string
  campaignId: string
  linkTextMessage: string
  campaignRedirectDomain: string
}

export type AddEditCustomerIntegrationFormFields = RefersionFields | ImpactFields

interface AddEditCustomerIntegrationProps {
  open: boolean
  onCancel(): void
  onSubmit(values: AddEditCustomerIntegrationFormFields): Promise<void>
  existing?: IntegrationRow_RefersionIntegration_Fragment | IntegrationRow_ImpactIntegration_Fragment
  error?: Error | null
}

const platformNameMap: Record<IntegrationType.Refersion | IntegrationType.Impact, string> = {
  [IntegrationType.Refersion]: 'Refersion',
  [IntegrationType.Impact]: 'Impact',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(5),
    },
    select: {
      width: '100%',
    },
    igUsernameField: {
      width: '100%',
    },
  }),
)

function AddEditCustomerIntegration({
  open,
  onCancel,
  existing,
  onSubmit,
  error,
}: AddEditCustomerIntegrationProps): React.ReactElement {
  const styles = useStyles()
  const initialType = existing?.integrationType || IntegrationType.Refersion

  const getInitialValues = useCallback(
    (type: IntegrationType) => {
      if (existing) return existing

      const base = {
        name: '',
        integrationType: type,
      }

      if (type === IntegrationType.Refersion) {
        return {
          ...base,
          key: '',
          igUsernameFields: [''],
          ttUsernameFields: [''],
        }
      } else {
        return {
          ...base,
          accountSid: '',
          accessToken: '',
          campaignId: '',
          linkTextMessage: '',
          campaignRedirectDomain: '',
        }
      }
    },
    [existing],
  )

  const sanitizeValues = useCallback(
    (values: Readonly<AddEditCustomerIntegrationFormFields>): AddEditCustomerIntegrationFormFields => {
      const baseValues = {
        ...values,
        name: values.name.trim(),
      }

      if (values.integrationType === IntegrationType.Refersion) {
        const refValues = values as RefersionFields
        return {
          ...baseValues,
          key: refValues.key.trim(),
          igUsernameFields: refValues.igUsernameFields.map(f => f.trim()).filter(f => !!f),
          ttUsernameFields: refValues.ttUsernameFields.map(f => f.trim()).filter(f => !!f),
        } as RefersionFields
      } else {
        const impactValues = values as ImpactFields
        return {
          ...baseValues,
          accountSid: impactValues.accountSid.trim(),
          accessToken: impactValues.accessToken.trim(),
          campaignId: impactValues.campaignId.trim(),
          linkTextMessage: impactValues.linkTextMessage.trim(),
          campaignRedirectDomain: impactValues.campaignRedirectDomain.trim(),
        } as ImpactFields
      }
    },
    [],
  )

  const errorMessage =
    (error &&
      (isApolloError(error) && error.graphQLErrors.some(e => e.extensions?.code === 'DUPLICATE_KEY')
        ? 'CRM Integration with that name already exists'
        : `Error ${!!existing ? 'editing' : 'creating'} this CRM Integration`)) ||
    ''

  async function handleSubmit(values: AddEditCustomerIntegrationFormFields): Promise<void> {
    await onSubmit(sanitizeValues(values))
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <Formik
        initialValues={getInitialValues(initialType)}
        enableReinitialize
        validate={values => {
          try {
            const schema = values.integrationType === IntegrationType.Refersion ? refersionSchema : impactSchema
            schema.validateSync(values, { abortEarly: false })
            return {}
          } catch (err) {
            if (err instanceof yup.ValidationError) {
              const errors: Record<string, string> = {}
              err.inner.forEach(e => {
                if (e.path) {
                  errors[e.path] = e.message
                }
              })
              return errors
            }
            return {}
          }
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => (
          <Form noValidate>
            <DialogTitle>{!!existing ? 'Edit' : 'Add'} Integration</DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" minWidth={300}>
                <Field
                  className={`${styles.field} ${styles.select}`}
                  component={Select}
                  label="Platform"
                  name="integrationType"
                  disabled={!!existing}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    const newType = e.target.value as IntegrationType
                    setValues(getInitialValues(newType), true)
                  }}
                >
                  <MenuItem value={IntegrationType.Refersion}>{platformNameMap[IntegrationType.Refersion]}</MenuItem>
                  <MenuItem value={IntegrationType.Impact}>{platformNameMap[IntegrationType.Impact]}</MenuItem>
                </Field>
                <Field className={styles.field} component={TextField} label="Name" name="name" />

                {values.integrationType === IntegrationType.Refersion ? (
                  <>
                    <Field className={styles.field} component={TextField} label="API Key" name="key" />
                    <FieldArray
                      name="igUsernameFields"
                      render={({ form, remove, push }) => (
                        <Box mb={5}>
                          {form.values.igUsernameFields?.map((_: unknown, i: number) => (
                            <Box mb={2} key={i}>
                              <Field
                                className={styles.igUsernameField}
                                component={TextField}
                                label="Instagram Username Field ID"
                                name={`igUsernameFields[${i}]`}
                                InputProps={{
                                  endAdornment:
                                    i > 0 ? (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => remove(i)} edge="end">
                                          <TrashIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : undefined,
                                }}
                              />
                            </Box>
                          ))}
                          <Button onClick={() => push('')}>Add Username Field</Button>
                        </Box>
                      )}
                    />
                    <FieldArray
                      name="ttUsernameFields"
                      render={({ form, remove, push }) => (
                        <Box mb={5}>
                          {form.values.ttUsernameFields?.map((_: unknown, i: number) => (
                            <Box mb={2} key={i}>
                              <Field
                                className={styles.igUsernameField}
                                component={TextField}
                                label="TikTok Username Field ID"
                                name={`ttUsernameFields[${i}]`}
                                InputProps={{
                                  endAdornment:
                                    i > 0 ? (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => remove(i)} edge="end">
                                          <TrashIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : undefined,
                                }}
                              />
                            </Box>
                          ))}
                          <Button onClick={() => push('')}>Add TikTok Username Field</Button>
                        </Box>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Field className={styles.field} component={TextField} label="Account SID" name="accountSid" />
                    <Field className={styles.field} component={TextField} label="Access Token" name="accessToken" />
                    <Field className={styles.field} component={TextField} label="Campaign ID" name="campaignId" />
                    <Field
                      className={styles.field}
                      component={TextField}
                      label="Link Text Message"
                      name="linkTextMessage"
                    />
                    <Field
                      className={styles.field}
                      component={TextField}
                      label="Campaign Redirect Domain"
                      name="campaignRedirectDomain"
                    />
                  </>
                )}
              </Box>
              {errorMessage}
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddEditCustomerIntegration
