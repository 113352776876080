import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCampaignSettingsMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  data: Types.CampaignSettingsInput;
}>;


export type UpdateCampaignSettingsMutation = { __typename: 'Mutations', updateCampaignSettings?: { __typename: 'UpdateCampaignSettings', ok: boolean, campaign?: { __typename: 'CampaignType', id: number } | null } | null };


export const UpdateCampaignSettingsDocument = gql`
    mutation updateCampaignSettings($campaignId: ID!, $data: CampaignSettingsInput!) {
  updateCampaignSettings(campaignId: $campaignId, data: $data) {
    ok
    campaign {
      id
    }
  }
}
    `;
export type UpdateCampaignSettingsMutationFn = Apollo.MutationFunction<UpdateCampaignSettingsMutation, UpdateCampaignSettingsMutationVariables>;

/**
 * __useUpdateCampaignSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignSettingsMutation, { data, loading, error }] = useUpdateCampaignSettingsMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCampaignSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignSettingsMutation, UpdateCampaignSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignSettingsMutation, UpdateCampaignSettingsMutationVariables>(UpdateCampaignSettingsDocument, options);
      }
export type UpdateCampaignSettingsMutationHookResult = ReturnType<typeof useUpdateCampaignSettingsMutation>;
export type UpdateCampaignSettingsMutationResult = Apollo.MutationResult<UpdateCampaignSettingsMutation>;
export type UpdateCampaignSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignSettingsMutation, UpdateCampaignSettingsMutationVariables>;