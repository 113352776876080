import React, { useState } from 'react'
import { addDays, differenceInDays, startOfDay, subYears } from 'date-fns'
import { useParams } from 'react-router-dom'
import { Typography, Box, Grid, Paper, makeStyles, Theme, createStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  DateRangeFilter,
  DateRangeFilterType,
  DateRangeFilterUnits,
  ParticipantStatus,
  TimeDimension,
  DateTimeRangeFilter,
  IgMediaPostType,
  OrdersInput,
  OrdersIncludeEnum,
  LtvBreakdown,
  SocialPlatformEnum,
  IntegrationCapabilityEnum,
} from '../../gql-global'
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker'
import Metric from '../../components/metric/Metric'
import { CampaignDetailRouteParams } from '../routes'
import {
  DateRange,
  getPreviousRangeDates,
  realizedDateRangeFromFilter,
  subtractDateRange,
} from '../../utils/date-range-helper'
import ContainerError from '../../components/ContainerError'
import BarChart from '../../components/charts/BarChart'
import { useCampaignStatsQuery } from './operations/campaign-stats.generated'
import RecentSignupsWidget from './RecentSignupsWidget'
import TopPostsWidget from './TopPostsWidget'
import RewardsWidget from './RewardsWidget'
import { useCampaignQuery } from './operations/campaign.generated'
import TopCustomersWidget from './TopCustomersWidget'
import SeeAllLink from '../../components/SeeAllLink'
import { encodeFilterParams } from '../../content/use-filter-params'
import { CONTENT_ROUTE } from '../../content/routes'
import CampaignDashboardOverviewBlock from './CampaignDashboardOverviewBlock'
import { useQueryCampaignOrderStatsQuery } from './operations/query-overview-dashboard.generated'
import CampaignDashboardEcommStats from './CampaignDashboardEcommStats'
import { useQueryCampaignLtvBreakdownQuery } from './operations/query-ltv-breakdown.generated'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'

const RECENT_SIGNUP_ROWS = 6

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoveredLink: {
      '&:hover .hoverLink': {
        color: theme.palette.primary.main,
      },
    },
  }),
)

function constructOrdersInput(dateRange: DateRange | undefined | null, includePreMembership: boolean): OrdersInput {
  if (dateRange) {
    return {
      include: includePreMembership ? OrdersIncludeEnum.Allmember : OrdersIncludeEnum.Member,
      rangeAt: dateRange,
    }
  }
  return {
    include: includePreMembership ? OrdersIncludeEnum.Allmember : OrdersIncludeEnum.Member,
  }
}

function allTimeDateRange(startAt: Date): DateRange {
  return {
    gte: startOfDay(startAt),
    lt: addDays(startOfDay(new Date()), 1),
  }
}

function CampaignDashboard(): React.ReactElement {
  const classes = useStyles()
  const { id } = useParams<CampaignDetailRouteParams>()
  const [dateRangeFilter, setDateRangeFilter] = useState<DateTimeRangeFilter | null>(null)
  const lastThirtyDateRangeFilter: DateTimeRangeFilter = {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  }
  const handleOnChangeDateRangeFilter = (date: DateTimeRangeFilter | DateRangeFilter) =>
    setDateRangeFilter(date as DateTimeRangeFilter)
  const dateRange = dateRangeFilter && realizedDateRangeFromFilter(dateRangeFilter)
  const previousDateRange = dateRangeFilter
    ? getPreviousRangeDates(dateRangeFilter)
    : getPreviousRangeDates(lastThirtyDateRangeFilter)
  const dateRangeLength = dateRange && differenceInDays(dateRange.lt, dateRange.gte)
  const timeDimension =
    (dateRangeLength !== null &&
      (dateRangeLength < 60 ? TimeDimension.Day : dateRangeLength < 320 ? TimeDimension.Week : TimeDimension.Month)) ||
    null

  const { data: userData } = useCampaignUserInfoQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const socialPlatform = selectedSocialPlatform(userData)

  const { data: campaignData } = useCampaignQuery({
    skip: !socialPlatform,
    variables: { id: id, platform: socialPlatform! },
  })

  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    campaignId: {
      any: [id],
    },
    socialAccountId: { any: [selectedSocialAccountId || ''] },
  }

  const allIntegrations = userData?.whoami?.account?.integrations || []
  const hasEcommAuthed = allIntegrations.some(integration =>
    integration.capabilities.includes(IntegrationCapabilityEnum.ImportOrders),
  )
  const hasKeepUnattributedOrders = campaignData?.campaign?.program?.ecommIntegration?.keepUnattributedOrders ?? true

  const hasLTVFeature = campaignData?.campaign?.program?.ltvActive || false

  const {
    data: statsData,
    error: statsError,
    loading: statsLoading,
  } = useCampaignStatsQuery({
    skip: !selectedSocialAccountId || !socialPlatform,
    variables: {
      campaignId: id,
      socialAccountFilter: { any: [selectedSocialAccountId || ''] },
      participantStatus: { any: [ParticipantStatus.Approved] },
      dateRange: dateRange,
      previousDateRange: previousDateRange,
      includePrevious: true,
      includeLastThirty: !dateRangeFilter,
      lastThirtyDateRange: realizedDateRangeFromFilter(lastThirtyDateRangeFilter),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      timeDimension,
      recentSignupLimit: RECENT_SIGNUP_ROWS,
      participantDateRange:
        dateRange && campaignData?.campaign?.startAt
          ? { lt: dateRange?.lt, gte: campaignData?.campaign?.startAt }
          : undefined,
      activeStoryMentionsWhere,
      platform: socialPlatform!,
    },
  })

  const [includePreMembership, setIncludePreMembership] = useState(true)

  let orderStatsRange = dateRange
  if (!orderStatsRange) {
    if (campaignData?.campaign?.startAt) {
      orderStatsRange = allTimeDateRange(campaignData.campaign.startAt)
    }
  }

  const previousAllTimeRangeFilter: DateTimeRangeFilter | undefined = orderStatsRange
    ? {
        __typename: 'AbsoluteDateTimeRangeFilter',
        rangeType: DateRangeFilterType.Absolute,
        gte: orderStatsRange.gte,
        lt: orderStatsRange.lt,
      }
    : undefined
  const orderStatsPreviousRange = previousAllTimeRangeFilter
    ? getPreviousRangeDates(previousAllTimeRangeFilter)
    : undefined

  const ordersInput = constructOrdersInput(dateRange, includePreMembership)
  const previousOrdersInput = constructOrdersInput(orderStatsPreviousRange, includePreMembership)

  const {
    data: orderStatsData,
    loading: orderStatsLoading,
    error: orderStatsError,
    networkStatus: orderStatsNetwork,
  } = useQueryCampaignOrderStatsQuery({
    skip: !hasEcommAuthed,
    variables: {
      campaignId: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      currentOrdersInput: ordersInput,
      previousOrdersInput: previousOrdersInput,
    },
  })

  const metricsRangeFilter: DateTimeRangeFilter = {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Years,
    value: 3,
  }

  const metricsDateRange = dateRange
    ? {
        gte: subYears(dateRange.lt, 3),
        lt: dateRange.lt,
      }
    : realizedDateRangeFromFilter(metricsRangeFilter)

  const rangeDifference = orderStatsRange ? differenceInDays(orderStatsRange.lt, orderStatsRange.gte) : 0
  const previousMetricsDateRange = subtractDateRange(metricsDateRange, rangeDifference)

  const { data: orderMetricsData, loading: orderMetricsLoading } = useQueryCampaignLtvBreakdownQuery({
    skip: !hasLTVFeature || !hasEcommAuthed,
    variables: {
      campaignId: id,
      currentRangeAt: metricsDateRange,
      previousRangeAt: previousMetricsDateRange,
    },
  })

  const isIGSocialAccount = socialPlatform === SocialPlatformEnum.Instagram
  const stats = statsData?.campaign?.mentionStats
  const previousStats = statsData?.campaign?.previousMentionStats
  const lastThirtyStats = statsData?.campaign?.lastThirtyMentionStats
  const participantCount = statsData?.campaign?.participantStats?.count
  const participantTotal = statsData?.campaign?.allTimeParticipantStats?.count
  const participantStatsTimeseries = statsData?.campaign?.participantStatsTimeseries
  const recentSignups = statsData?.campaign?.participants?.results
  const firstPoint = participantStatsTimeseries?.[0]
  const dateDiff = firstPoint && differenceInDays(firstPoint.endDate, firstPoint.startDate)
  const chartTimeUnit = dateDiff && (dateDiff <= 1 ? 'Daily' : dateDiff <= 7 ? 'Weekly' : 'Monthly')
  const participantComparisonCountText = participantCount === participantTotal ? 'All' : `${participantTotal} total`
  const dashboardLink = (
    <SeeAllLink
      to={{
        pathname: `${CONTENT_ROUTE.path}`,
        search: `?${encodeFilterParams({
          postedAt: dateRangeFilter
            ? dateRangeFilter
            : !campaignData?.campaign?.startAt
            ? undefined
            : {
                __typename: 'AbsoluteDateTimeRangeFilter',
                rangeType: DateRangeFilterType.Absolute,
                gte: campaignData?.campaign?.startAt,
              },
          campaigns: { any: [id] },
        })}`,
      }}
      variant="icon"
    />
  )

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={8}>
        <Box fontSize="1.125rem" lineHeight={1.5} color="secondary.main">
          {statsLoading && <Skeleton width={200} />}
          {participantCount !== undefined && (
            <Box display="flex" alignItems="baseline">
              <Box mr={2} display="flex" alignItems="baseline">
                <Typography variant="body1" color="textPrimary">
                  Showing data for
                </Typography>
              </Box>
              <Typography variant="h5" color="textPrimary">
                {participantCount.toLocaleString()} members ({participantComparisonCountText})
              </Typography>
            </Box>
          )}
        </Box>
        <DateRangePicker selectedDateRange={dateRangeFilter} onChange={handleOnChangeDateRangeFilter} includeAllTime />
      </Box>
      {!statsError && (
        <>
          <Grid container spacing={9}>
            <Grid item xs={6}>
              <Paper className={classes.hoveredLink}>
                <Metric
                  dateRangeFilter={dateRangeFilter || lastThirtyDateRangeFilter}
                  hasCompare
                  size="lg"
                  metricType={isIGSocialAccount ? 'igPosts' : 'ttPosts'}
                  previousMetric={previousStats?.posts}
                  metric={stats?.posts}
                  loading={statsLoading}
                  link={dashboardLink}
                  overridingMetricForComparison={!dateRangeFilter ? lastThirtyStats?.posts : null}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.hoveredLink}>
                <Metric
                  dateRangeFilter={dateRangeFilter || lastThirtyDateRangeFilter}
                  hasCompare
                  size="lg"
                  metricType="engagement"
                  previousMetric={previousStats?.engagement}
                  metric={stats?.engagement}
                  loading={statsLoading}
                  link={dashboardLink}
                  overridingMetricForComparison={!dateRangeFilter ? lastThirtyStats?.engagement : null}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.hoveredLink}>
                <Metric
                  dateRangeFilter={dateRangeFilter || lastThirtyDateRangeFilter}
                  hasCompare
                  size="lg"
                  metricType="impressions"
                  previousMetric={previousStats?.impressions}
                  metric={stats?.impressions}
                  loading={statsLoading}
                  link={dashboardLink}
                  overridingMetricForComparison={!dateRangeFilter ? lastThirtyStats?.impressions : null}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.hoveredLink}>
                <Metric
                  dateRangeFilter={dateRangeFilter || lastThirtyDateRangeFilter}
                  hasCompare
                  size="lg"
                  metricType="emv"
                  previousMetric={previousStats?.emv}
                  metric={stats?.emv}
                  loading={statsLoading}
                  link={dashboardLink}
                  overridingMetricForComparison={!dateRangeFilter ? lastThirtyStats?.emv : null}
                />
              </Paper>
            </Grid>
          </Grid>
          {hasLTVFeature && hasEcommAuthed && (
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <CampaignDashboardEcommStats
                  dateRangeFilter={dateRangeFilter || previousAllTimeRangeFilter}
                  data={
                    !!orderMetricsData?.campaign
                      ? {
                          previousLtvBreakdown: orderMetricsData.campaign.previousLtvBreakdown as LtvBreakdown,
                          currentLtvBreakdown: orderMetricsData.campaign.currentLtvBreakdown as LtvBreakdown,
                        }
                      : {}
                  }
                  statsLoading={orderMetricsLoading}
                  currencyCode={campaignData?.campaign?.program?.currencyCode?.valueOf()}
                />
              </Grid>
            </Grid>
          )}
          {hasEcommAuthed && hasKeepUnattributedOrders && (
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <CampaignDashboardOverviewBlock
                  orderStatsData={orderStatsData}
                  orderStatsError={orderStatsError}
                  orderStatsLoading={orderStatsLoading}
                  orderStatsNetwork={orderStatsNetwork}
                  includePreMembership={includePreMembership}
                  setIncludePreMembership={setIncludePreMembership}
                  dateRangeFilter={dateRangeFilter || previousAllTimeRangeFilter}
                  startAt={campaignData?.campaign?.startAt}
                  currencyCode={campaignData?.campaign?.program?.currencyCode?.valueOf()}
                />
              </Grid>
            </Grid>
          )}
          <Box mt={11}>
            <Grid container spacing={9}>
              <Grid item xs={6}>
                <Box mb={5} fontSize="1.125rem" fontWeight="fontWeightBold" lineHeight="1.5">
                  Sign-ups
                </Box>
                <Paper>
                  <Box padding={8}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography variant="subtitle1">{chartTimeUnit} Sign-ups</Typography>
                      <Typography variant="body2">Total Sign-ups: {participantCount}</Typography>
                    </Box>
                    <Box height={279} mt={8}>
                      <BarChart label="Sign-ups" dataKey="count" data={participantStatsTimeseries || []} />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <RecentSignupsWidget
                  signups={recentSignups}
                  loading={statsLoading}
                  error={!!statsError}
                  rows={RECENT_SIGNUP_ROWS}
                  campaignId={id}
                  isIGSocialAccount={isIGSocialAccount}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {statsError && <ContainerError text="Could not load statistics for campaign." />}
      <Box mt={11}>
        <RewardsWidget campaignId={id} dateRangeFilter={dateRangeFilter} />
      </Box>
      <Box mt={11}>
        <TopPostsWidget campaignId={id} dateRangeFilter={dateRangeFilter} />
      </Box>
      <Box mt={11}>
        <TopCustomersWidget campaignId={id} dateRangeFilter={dateRangeFilter} />
      </Box>
    </div>
  )
}

export default CampaignDashboard
