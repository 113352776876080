import React, { useEffect, useState } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { Redirect } from 'react-router-dom'
import { INTEGRATIONS_ROUTE } from '../integrations/routes'
import { useAuth0 } from '@auth0/auth0-react'

const GoogleAuth: React.FC = () => {
  // Google sends query params with this route when redirecting for OAuth2
  const [googleAuthParams] = useQueryParams({
    state: StringParam,
    code: StringParam,
    scope: StringParam,
  })

  const { getAccessTokenSilently } = useAuth0()

  const [doRedirect, setDoRedirect] = useState<boolean>(false)
  const { state, code, scope } = googleAuthParams
  useEffect(() => {
    if (!(state && code && scope)) return
    const postIntegration = async () => {
      const authToken = getAccessTokenSilently()
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/google_oauth_integration`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${await authToken}`,
        },
        body: JSON.stringify({
          state,
          code,
          scope,
        }),
      })
      if (!response.ok) throw response
      setDoRedirect(true)
    }

    postIntegration()
  }, [getAccessTokenSilently, state, code, scope])

  // TODO: something so a toast is displayed that says success or failure depending on the result?
  return doRedirect ? <Redirect to={INTEGRATIONS_ROUTE.path} /> : <div>Processing Google integration</div>
}

export default GoogleAuth
