import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Button, createStyles, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { Field, useFormikContext } from 'formik'
import {
  EcommCustomerSegmentType,
  EcommDiscountAppliesToEnum,
  EcommDiscountClassEnum,
  EcommDiscountCodeFormatEnum,
  EcommDiscountCodeTypeEnum,
  EcommDiscountCodeValueTypeEnum,
  EcommDiscountCustomerEligibilityEnum,
  IntegrationType,
} from '../../../gql-global'
import { ReactComponent as NoMediaImage } from '../../../images/no-media.svg'
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg'
import ProductCollectionBrandPickerMenu, { OptionType, PickerTypeEnum } from './ProductCollectionBrandPickerMenu'
import {
  DISCOUNT_CLASS_SELECTION_VALUES,
  DISCOUNT_CODE_FORMAT,
  DISCOUNT_PURCHASE_TYPE,
  SHOPIFY_DISCOUNT_APPLIES_TO_ITEM_TYPE,
  SALESFORCE_DISCOUNT_APPLIES_TO_ITEM_TYPE,
} from './constants'
import EcommCustomerSegmentPickerMenu from './EcommCustomerSegmentPickerMenu'
import useDiscountCodePicker from './hooks/DiscountCodePickerHook'
import useHasFeature from '../../../hooks/useHasFeature'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowX: 'hidden',
    },
    field: {
      marginTop: theme.spacing(5),
    },
    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    checkboxLabel: {
      marginLeft: -9,
      marginTop: theme.spacing(5),
    },
    underCheckboxLabel: {
      marginLeft: -9,
    },
    image: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(2),
      padding: 5,
    },
    crossIcon: {
      cursor: 'pointer',
    },
    helpText: {
      marginLeft: 26,
      marginTop: -3,
      fontSize: 13,
      color: theme.palette.secondary.main,
    },
  }),
)

type DiscountGenerationFormProps = {
  campaignId?: string
  discountType: EcommDiscountCodeTypeEnum
  integrationType?: IntegrationType
}

function DiscountGenerationForm({
  campaignId,
  discountType,
  integrationType,
}: DiscountGenerationFormProps): ReactElement {
  const classes = useStyles()
  const selectRef = useRef<HTMLButtonElement>(null)
  const customerSegmentSelectRef = useRef<HTMLButtonElement>(null)
  const { hasFeature: hasOrderDiscountCombineFeature } = useHasFeature('orderDiscountCombine')

  const { values, setFieldValue } = useFormikContext<{
    codeFormat: EcommDiscountCodeFormatEnum
    discountClass: EcommDiscountClassEnum
    sellsSubscriptions: boolean
    appliesTo: EcommDiscountAppliesToEnum
    selectedItems?: OptionType[]
    valueType: EcommDiscountCodeValueTypeEnum
    useUsageLimit: boolean
    customerEligibility: EcommDiscountCustomerEligibilityEnum
    selectedCustomerSegments?: EcommCustomerSegmentType[]
  }>()

  const {
    states: {
      openPicker: openProductCollectionPicker,
      setOpenPicker: setOpenProductCollectionPicker,
      selectedItems,
      setSelectedItems,
    },
    events: {
      handleOnAdd: handleOnAddProductCollectionPicker,
      handleRemoveItem: handleRemoveItemProductCollectionPicker,
    },
  } = useDiscountCodePicker<OptionType>('selectedItems', setFieldValue, values.selectedItems || [])

  const {
    states: {
      openPicker: openCustomerSegmentPicker,
      setOpenPicker: setOpenCustomerSegmentPicker,
      selectedItems: selectedCustomerSegments,
    },
    events: { handleOnAdd: handleOnAddCustomerSegmentPicker, handleRemoveItem: handleRemoveItemCustomerSegmentPicker },
  } = useDiscountCodePicker<EcommCustomerSegmentType>(
    'selectedCustomerSegments',
    setFieldValue,
    values.selectedCustomerSegments || [],
  )

  const [currentAppliedSelection, setCurrentAppliedSelection] = useState('')

  useEffect(() => {
    setSelectedItems(values.selectedItems || [])
    // Need to update selectedItems only when values.appliesTo changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.appliesTo])

  const handleOnPickerCancel = () => {
    const rollbackSelection =
      values.appliesTo === EcommDiscountAppliesToEnum.Collection
        ? EcommDiscountAppliesToEnum.Product
        : EcommDiscountAppliesToEnum.Collection
    setFieldValue('appliesTo', currentAppliedSelection !== values.appliesTo ? rollbackSelection : values.appliesTo)
    setOpenProductCollectionPicker(false)
  }

  const handleOnPickerOpen = (appliesTo: EcommDiscountAppliesToEnum) => {
    setCurrentAppliedSelection(appliesTo)
    setFieldValue('appliesTo', appliesTo)
    setOpenProductCollectionPicker(!!appliesTo)
  }

  const DISCOUNT_APPLIES_TO_ITEM_TYPE =
    integrationType === IntegrationType.CommerceCloud
      ? SALESFORCE_DISCOUNT_APPLIES_TO_ITEM_TYPE
      : SHOPIFY_DISCOUNT_APPLIES_TO_ITEM_TYPE

  return (
    <Box className={classes.container}>
      {discountType === EcommDiscountCodeTypeEnum.Affiliate && (
        <Field
          select
          fullWidth
          name="codeFormat"
          label="Code format"
          className={classes.field}
          component={TextField}
          value={values.codeFormat}
          helperText={
            values.codeFormat === EcommDiscountCodeFormatEnum.FirstLastName
              ? "Each code is a unique combination of the member's first name, plus initials of last name."
              : "Each code is a member's unique Instagram or TikTok username. We use Instagram preferentially if a member has both accounts."
          }
        >
          {DISCOUNT_CODE_FORMAT.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      )}
      <Field
        select
        fullWidth
        name="discountClass"
        label="Type of discount"
        className={classes.field}
        component={TextField}
        value={values.discountClass}
      >
        {DISCOUNT_CLASS_SELECTION_VALUES.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
      {values.discountClass === EcommDiscountClassEnum.Product && (
        <>
          <Field
            select
            fullWidth
            name="appliesTo"
            label="Applies to"
            innerRef={selectRef}
            className={classes.field}
            component={TextField}
            onClick={(event: React.ChangeEvent<{ value: EcommDiscountAppliesToEnum }>) => {
              if (event.target.value) {
                handleOnPickerOpen(event.target.value)
              }
            }}
          >
            {DISCOUNT_APPLIES_TO_ITEM_TYPE.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
          <ProductCollectionBrandPickerMenu
            campaignId={campaignId}
            open={openProductCollectionPicker}
            anchorEl={selectRef.current}
            onCancel={handleOnPickerCancel}
            onAdd={handleOnAddProductCollectionPicker}
            pickerType={values.appliesTo as unknown as PickerTypeEnum}
            selectedItems={selectedItems}
            integrationType={integrationType}
          />
          {!!values.appliesTo && (
            <Box pt={5}>
              <Button variant="outlined" color="primary" onClick={() => handleOnPickerOpen(values.appliesTo)}>
                Select{' '}
                {values.appliesTo === EcommDiscountAppliesToEnum.Collection
                  ? integrationType === IntegrationType.CommerceCloud
                    ? 'Category'
                    : 'Collection'
                  : 'Product'}
              </Button>
            </Box>
          )}
          {!!selectedItems.length && (
            <Box>
              <Box display="flex" mt={2} flexDirection="column" maxHeight={190} overflow="auto">
                {selectedItems?.map(item => (
                  <Box key={item?.id} display="flex" alignItems="center" py={1}>
                    {item?.imageSrc ? (
                      <img className={classes.image} src={item.imageSrc} alt="preview" />
                    ) : (
                      <NoMediaImage className={classes.image} />
                    )}
                    {item?.title}
                    <Box flex={1} display="flex" justifyContent="end" pr={3}>
                      <CrossIcon
                        width={12}
                        height={12}
                        className={classes.crossIcon}
                        onClick={() => handleRemoveItemProductCollectionPicker(item.id)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
      {!!values.discountClass && (
        <Box display="flex" gridGap={20}>
          <Field
            select
            fullWidth
            name="valueType"
            label="Discount value"
            className={classes.field}
            component={TextField}
          >
            <MenuItem value={EcommDiscountCodeValueTypeEnum.Percentage}>Percentage</MenuItem>
            <MenuItem value={EcommDiscountCodeValueTypeEnum.DiscountAmount}>Fixed Amount</MenuItem>
          </Field>
          <Field fullWidth name="value" label="&nbsp;" className={classes.field} component={TextField} />
        </Box>
      )}
      {!!values.discountClass && discountType === EcommDiscountCodeTypeEnum.Affiliate && (
        <>
          <Field
            select
            fullWidth
            name="customerEligibility"
            label="Customer eligibility"
            className={classes.field}
            component={TextField}
            innerRef={customerSegmentSelectRef}
            onClick={(event: React.ChangeEvent<{ value: EcommDiscountCustomerEligibilityEnum }>) => {
              if (event.target.value === EcommDiscountCustomerEligibilityEnum.SpecificCustomerSegments) {
                setOpenCustomerSegmentPicker(true)
              }
            }}
          >
            <MenuItem value={EcommDiscountCustomerEligibilityEnum.AllCustomers}>All Customers</MenuItem>
            <MenuItem value={EcommDiscountCustomerEligibilityEnum.SpecificCustomerSegments}>
              Specific Customer Segments
            </MenuItem>
          </Field>
          <EcommCustomerSegmentPickerMenu
            campaignId={campaignId}
            open={openCustomerSegmentPicker}
            onCancel={() => setOpenCustomerSegmentPicker(false)}
            anchorEl={customerSegmentSelectRef.current}
            selectedSegments={selectedCustomerSegments || []}
            onAdd={handleOnAddCustomerSegmentPicker}
          />
          {values.customerEligibility === EcommDiscountCustomerEligibilityEnum.SpecificCustomerSegments && (
            <Box pt={5}>
              <Button variant="outlined" color="primary" onClick={() => setOpenCustomerSegmentPicker(true)}>
                Select Customer Segments
              </Button>
            </Box>
          )}
          {!!selectedCustomerSegments.length &&
            values.customerEligibility === EcommDiscountCustomerEligibilityEnum.SpecificCustomerSegments && (
              <Box>
                <Box display="flex" mt={2} flexDirection="column" maxHeight={190} overflow="auto">
                  {selectedCustomerSegments?.map(item => (
                    <Box key={item?.id} display="flex" alignItems="center" py={1}>
                      {item?.name}
                      <Box flex={1} display="flex" justifyContent="end" pr={3}>
                        <CrossIcon
                          width={12}
                          height={12}
                          className={classes.crossIcon}
                          onClick={() => handleRemoveItemCustomerSegmentPicker(item.id)}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
        </>
      )}
      {!!values.discountClass && values.sellsSubscriptions && (
        <Field
          select
          fullWidth
          name="purchaseType"
          label="Type of purchase"
          className={classes.field}
          component={TextField}
        >
          {DISCOUNT_PURCHASE_TYPE.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      )}
      {discountType === EcommDiscountCodeTypeEnum.Affiliate && (
        <Box display="flex" flexDirection="column" mt={5}>
          <Field
            name="useUsageLimit"
            component={CheckboxWithLabel}
            type="checkbox"
            className={classes.checkbox}
            Label={{
              label: 'Limit number of times each code can be used in total',
              className: classes.underCheckboxLabel,
            }}
          />
          {values.useUsageLimit && (
            <Box mb={5}>
              <Field name="usageLimit" component={TextField} label="Usage limit" />
            </Box>
          )}
          <Field
            name="appliesOncePerCustomer"
            component={CheckboxWithLabel}
            type="checkbox"
            className={classes.checkbox}
            Label={{
              label: 'Limit to one use per customer',
              className: classes.underCheckboxLabel,
            }}
          />
        </Box>
      )}
      {!!values.discountClass && (
        <Field
          name="combineWithFreeShippingDiscounts"
          component={CheckboxWithLabel}
          type="checkbox"
          className={classes.checkbox}
          Label={{ label: 'Combine with free shipping discounts', className: classes.checkboxLabel }}
        />
      )}
      <Field
        name="combineWithOtherProductDiscounts"
        component={CheckboxWithLabel}
        type="checkbox"
        className={classes.checkbox}
        Label={{ label: 'Combine with other product discounts', className: classes.underCheckboxLabel }}
      />
      {values.discountClass === EcommDiscountClassEnum.Product &&
        values.valueType === EcommDiscountCodeValueTypeEnum.DiscountAmount && (
          <>
            <Field
              name="appliesOnEachItem"
              component={CheckboxWithLabel}
              type="checkbox"
              className={classes.checkbox}
              Label={{ label: 'Only apply discount once per order', className: classes.underCheckboxLabel }}
            />
            <Typography className={classes.helpText}>
              If unchecked, the discount will be applied to each eligible item in the order.
            </Typography>
          </>
        )}

      {!!values.discountClass && hasOrderDiscountCombineFeature && (
        <Field
          name="combineWithOrderDiscounts"
          component={CheckboxWithLabel}
          type="checkbox"
          className={classes.checkbox}
          Label={{ label: 'Combine with other order discounts', className: classes.underCheckboxLabel }}
        />
      )}
      {discountType === EcommDiscountCodeTypeEnum.Automatic && (
        <>
          <Field
            name="overrideMessage"
            component={CheckboxWithLabel}
            type="checkbox"
            className={classes.checkbox}
            Label={{
              label: "Display the name of the ambassador's affiliate code",
              className: classes.underCheckboxLabel,
            }}
          />
          <Field
            name="appliesOnFirstTimePurchase"
            component={CheckboxWithLabel}
            type="checkbox"
            className={classes.checkbox}
            Label={{
              label: 'Apply to first time customers only',
              className: classes.underCheckboxLabel,
            }}
          />
        </>
      )}
    </Box>
  )
}

export default DiscountGenerationForm
