import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { CampaignRowFragmentDoc } from './campaign-row.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCampaignMutationVariables = Types.Exact<{
  campaign: Types.CreateCampaignInput;
  platform: Types.SocialPlatformEnum;
  socialAccountId: Types.Scalars['ID'];
}>;


export type CreateCampaignMutation = { __typename: 'Mutations', createCampaign?: { __typename: 'CreateCampaign', ok: boolean, campaign?: { __typename: 'CampaignType', id: number, isActive: boolean, startAt: Date, program?: { __typename: 'ProgramType', id: string, name: string, currencyCode?: Types.CurrencyCodeEnum | null } | null, mentionStats?: { __typename: 'CampaignMentionStats', posts: number, engagement: number } | null, participantStats?: { __typename: 'ParticipantStats', count: number } | null } | null } | null };


export const CreateCampaignDocument = gql`
    mutation CreateCampaign($campaign: CreateCampaignInput!, $platform: SocialPlatformEnum!, $socialAccountId: ID!) {
  createCampaign(campaign: $campaign) {
    ok
    campaign {
      ...CampaignRow
    }
  }
}
    ${CampaignRowFragmentDoc}`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      campaign: // value for 'campaign'
 *      platform: // value for 'platform'
 *      socialAccountId: // value for 'socialAccountId'
 *   },
 * });
 */
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<CreateCampaignMutation, CreateCampaignMutationVariables>;