import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { SkipMessageTemplateActionEnum } from '../../gql-global'

const timeBufferOptions = ['last24hours', 'last7days', 'last30days', 'ever'] as const

const schema = yup.object({
  followerCount: yup
    .object({
      gte: yup.number().notRequired(),
      lte: yup.number().notRequired(),
    })
    .notRequired(),
  segments: yup
    .object({
      any: yup
        .array()
        .of(yup.string().trim())
        .notRequired(),
      none: yup
        .array()
        .of(yup.string().trim())
        .notRequired(),
    })
    .notRequired(),
  campaigns: yup
    .object({
      any: yup
        .array()
        .of(yup.string().trim())
        .notRequired(),
      none: yup
        .array()
        .of(yup.string().trim())
        .notRequired(),
    })
    .notRequired(),
  biography: yup
    .object({
      any: yup
        .array()
        .of(yup.string().trim())
        .notRequired(),
      none: yup
        .array()
        .of(yup.string().trim())
        .notRequired(),
    })
    .notRequired(),
  customerTriggerQuantity: yup.number().required(),
  skipCustomers: yup.boolean(),
  skipSendMessage: yup
    .string()
    .oneOf([
      SkipMessageTemplateActionEnum.Never,
      SkipMessageTemplateActionEnum.AnyTemplate,
      SkipMessageTemplateActionEnum.SameTemplate,
    ]),
  skipActionTimeBuffer: yup.string().oneOf([...timeBufferOptions]),
})

export const filterFields = ['followerCount', 'segments', 'campaigns', 'biography'] as const

export type AutomationFormFields = yup.InferType<typeof schema> & {
  skipActionTimeBuffer: SkipActionTimeBufferType
  skipSendMessage: SkipSendMessageType
  who: 'anyone' | 'specificCustomers'
}

export const defaultFilterFields = filterFields.reduce((a, v) => ({ ...a, [v]: undefined }), {})
export const initialAutomationForm: AutomationFormFields = {
  ...defaultFilterFields,
  who: 'anyone',
  customerTriggerQuantity: 1,
  skipCustomers: false,
  skipSendMessage: SkipMessageTemplateActionEnum.SameTemplate,
  skipActionTimeBuffer: 'last24hours',
}

export function hasFilters(fields: AutomationFormFields): boolean {
  return filterFields.some(f => fields[f] !== undefined)
}

export type SkipActionTimeBufferType = typeof timeBufferOptions[number]

type SkipSendMessageType =
  | SkipMessageTemplateActionEnum.Never
  | SkipMessageTemplateActionEnum.AnyTemplate
  | SkipMessageTemplateActionEnum.SameTemplate

interface AutomationBuilderContextProps {
  children: React.ReactNode
  automationFormValues: AutomationFormFields
  onSubmit(values: AutomationFormFields): void
}

export function AutomationBuilderContext({
  children,
  automationFormValues,
  onSubmit,
}: AutomationBuilderContextProps): JSX.Element {
  const handleOnSubmit = (values: AutomationFormFields): void => {
    let formValues = values
    if (values.skipCustomers === false) {
      formValues = {
        ...values,
        skipActionTimeBuffer: 'ever',
        skipSendMessage: SkipMessageTemplateActionEnum.Never,
      }
    }
    onSubmit(formValues)
  }
  return (
    <Formik<AutomationFormFields>
      initialValues={automationFormValues}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
    >
      {children}
    </Formik>
  )
}
