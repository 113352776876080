import React from 'react'
import { PopoverProps, Dialog, Popover } from '@material-ui/core'

const LabelMenuWrapper: React.FC<{
  variant: 'popover' | 'dialog' | 'div'
  open: boolean
  onClose: PopoverProps['onClose']
  anchorEl?: PopoverProps['anchorEl']
  style?: React.CSSProperties
}> = props => {
  const { variant, anchorEl, style, ...rest } = props
  if (variant === 'dialog') {
    return <Dialog {...rest} PaperProps={{style: style}} />
  }
  if (variant === 'div') {
    return <div {...rest} style={style} />
  }
  return (
    <Popover
      {...rest}
      PaperProps={{style: style}}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    />
  )
}

export default LabelMenuWrapper
