import { SocialPlatformEnum } from '../gql-global'
import { isTypeName } from '../types/utility'

type UserDataPartial = {
  __typename: 'Query'
  whoami?: {
    preferences: { id: string; selectedSocialAccountId?: string | null }
    account?: {
      socialAccounts?: Array<
        { __typename: 'IGSocialAccount'; id: string } | { __typename: 'TTSocialAccount'; id: string }
      > | null
    } | null
  } | null
}

export function selectedSocialPlatform(userData: UserDataPartial | undefined): SocialPlatformEnum | undefined {
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId
  const selectedSocialAccount = userData?.whoami?.account?.socialAccounts?.find(sa => sa.id === selectedSocialAccountId)

  return selectedSocialAccount
    ? isTypeName(selectedSocialAccount, 'IGSocialAccount')
      ? SocialPlatformEnum.Instagram
      : SocialPlatformEnum.Tiktok
    : undefined
}
