import { createStyles, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import theme from '../loudcrowd-theme'

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontSize: '12px',
      color: theme.palette.primary.dark,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '8px',
    },
  }),
)

interface SelectedProgramsPreviewProps {
  selected: Array<string>
}

function SelectedProgramsPreview({ selected }: SelectedProgramsPreviewProps): React.ReactElement {
  const classes = useStyles()
  return <Typography className={classes.text}>{selected.length > 1 && selected.join(', ')}</Typography>
}

export default SelectedProgramsPreview
