import React, { FC, useState, useRef } from 'react'
import SplitButton, { OptionType } from '../../../components/SplitButton'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Menu } from '@material-ui/core'
import { ProgramTierGroupStatus } from '../../../gql-global'
import { useProgramActivityBuilder } from './ProgramActivityBuilderProvider'

export interface BuilderCriteriaSubmitParams {
  status?: ProgramTierGroupStatus
  startAt?: MaterialUiPickersDate
}

interface SaveBuilderCriteriaButtonProps {
  onSaveBuilderCriteria: (params: BuilderCriteriaSubmitParams) => void
  isEditing?: boolean
  programTierGroupStatus?: ProgramTierGroupStatus | null
}

const SaveBuilderCriteriaButton: FC<SaveBuilderCriteriaButtonProps> = ({
  onSaveBuilderCriteria,
  programTierGroupStatus,
  isEditing,
}) => {
  const { isFormValid } = useProgramActivityBuilder()
  const isArchived = programTierGroupStatus === ProgramTierGroupStatus.Archived
  const isPaused = programTierGroupStatus === ProgramTierGroupStatus.Paused
  const { needsArchiveAndCreateNew } = useProgramActivityBuilder()

  const getActiveLabel = () => {
    if (needsArchiveAndCreateNew && programTierGroupStatus !== ProgramTierGroupStatus.Draft) {
      return 'Archive and Create New'
    }
    if (isPaused || isArchived) {
      return 'Re-launch now'
    }
    if (programTierGroupStatus === ProgramTierGroupStatus.Active) {
      return 'Save'
    }
    return 'Launch now'
  }

  const options = [
    { value: ProgramTierGroupStatus.Draft, label: 'Save as draft', disabled: isEditing },
    { value: ProgramTierGroupStatus.Active, label: getActiveLabel() },
    { value: ProgramTierGroupStatus.Active, label: 'Schedule start date', disabled: isEditing && !isPaused },
    { value: ProgramTierGroupStatus.Paused, label: 'Pause', disabled: !isEditing || isPaused },
    { value: ProgramTierGroupStatus.Archived, label: 'Archive', disabled: !isEditing },
  ]
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleOnClick = (option?: OptionType) => {
    switch (option?.value) {
      case ProgramTierGroupStatus.Draft:
      case ProgramTierGroupStatus.Paused:
        onSaveBuilderCriteria({ status: option.value })
        break
      case ProgramTierGroupStatus.Active:
        if (option?.label.includes('Schedule')) {
          // Schedule start date
          setOpenDatePicker(true)
        } else {
          onSaveBuilderCriteria({ status: ProgramTierGroupStatus.Active, startAt: new Date() })
        }
        break
      case ProgramTierGroupStatus.Archived:
        onSaveBuilderCriteria({ status: ProgramTierGroupStatus.Archived })
        break
    }
  }

  const handleOnDateSelected = (startDate?: MaterialUiPickersDate) => {
    setOpenDatePicker(false)
    onSaveBuilderCriteria({ status: ProgramTierGroupStatus.Active, startAt: startDate })
  }

  return (
    <div ref={anchorRef}>
      <SplitButton
        options={options.filter(o => !o.disabled)}
        onClick={handleOnClick}
        disabled={!isFormValid()}
        showDefaultItemOnList={!isEditing}
        showDropdownHandler={!isArchived}
      />
      <Menu
        open={openDatePicker}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setOpenDatePicker(false)}
      >
        <DatePicker
          onChange={date => handleOnDateSelected(date)}
          variant="static"
          format="MM/dd/yyyy"
          margin="none"
          disablePast
          value={{}}
          disableToolbar
        />
      </Menu>
    </div>
  )
}

export default SaveBuilderCriteriaButton
