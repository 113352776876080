export const paymentPeriodsDateFormat = (date: Date, format: 'long' | 'short' = 'short') => {
  date.setUTCHours(0, 0, 0, 0)
  return `${date.toLocaleString('en-us', { month: format })} ${date.getDate()}, ${date.getFullYear()}`
}

export const paymentPeriodsNextPayoutFormat = (date?: Date) => {
  if (!date) return ''
  date.setUTCHours(0, 0, 0, 0)
  return `${date.getMonth() + 1}/${date.getDate()}`
}

export const formatUTCDateToClientLocalTime = (
  date?: Date,
  dateTimeFormatOptions: Intl.DateTimeFormatOptions = {},
  locale = navigator.language,
): string => {
  if (!date) return ''
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
    ...dateTimeFormatOptions,
  }
  return date.toLocaleDateString(locale, options)
}

export const roundToGreaterHalfHour = (date: Date): Date => {
  const minutes = date.getMinutes()
  const roundedMinutes = Math.ceil(minutes / 30) * 30
  date.setMinutes(roundedMinutes)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}
