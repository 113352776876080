import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendUserEmailMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ResendUserEmailMutation = { __typename: 'Mutations', resendUserEmail?: { __typename: 'ResendUserEmail', ok?: boolean | null } | null };


export const ResendUserEmailDocument = gql`
    mutation ResendUserEmail {
  resendUserEmail {
    ok
  }
}
    `;
export type ResendUserEmailMutationFn = Apollo.MutationFunction<ResendUserEmailMutation, ResendUserEmailMutationVariables>;

/**
 * __useResendUserEmailMutation__
 *
 * To run a mutation, you first call `useResendUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserEmailMutation, { data, loading, error }] = useResendUserEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserEmailMutation, ResendUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendUserEmailMutation, ResendUserEmailMutationVariables>(ResendUserEmailDocument, options);
      }
export type ResendUserEmailMutationHookResult = ReturnType<typeof useResendUserEmailMutation>;
export type ResendUserEmailMutationResult = Apollo.MutationResult<ResendUserEmailMutation>;
export type ResendUserEmailMutationOptions = Apollo.BaseMutationOptions<ResendUserEmailMutation, ResendUserEmailMutationVariables>;