import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthFbUserMutationVariables = Types.Exact<{
  code?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AuthFbUserMutation = { __typename: 'Mutations', authFbBusiness?: { __typename: 'AuthFBBusiness', ok: boolean, fbUser?: { __typename: 'FBUser', id: string, availableIgAccounts?: Array<{ __typename: 'IGAccount', platformId: string, profilePictureUrl?: string | null, username: string }> | null } | null } | null };


export const AuthFbUserDocument = gql`
    mutation AuthFBUser($code: String, $token: String) {
  authFbBusiness(code: $code, token: $token) {
    fbUser {
      availableIgAccounts {
        platformId
        profilePictureUrl
        username
      }
      id
    }
    ok
  }
}
    `;
export type AuthFbUserMutationFn = Apollo.MutationFunction<AuthFbUserMutation, AuthFbUserMutationVariables>;

/**
 * __useAuthFbUserMutation__
 *
 * To run a mutation, you first call `useAuthFbUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthFbUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authFbUserMutation, { data, loading, error }] = useAuthFbUserMutation({
 *   variables: {
 *      code: // value for 'code'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAuthFbUserMutation(baseOptions?: Apollo.MutationHookOptions<AuthFbUserMutation, AuthFbUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthFbUserMutation, AuthFbUserMutationVariables>(AuthFbUserDocument, options);
      }
export type AuthFbUserMutationHookResult = ReturnType<typeof useAuthFbUserMutation>;
export type AuthFbUserMutationResult = Apollo.MutationResult<AuthFbUserMutation>;
export type AuthFbUserMutationOptions = Apollo.BaseMutationOptions<AuthFbUserMutation, AuthFbUserMutationVariables>;