import React from 'react'
import { Box } from '@material-ui/core'
import { realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import {
  DateTimeRangeFilter,
  IgMediaPostType,
  ParticipantSort,
  SocialPlatformEnum,
  SortDirection,
} from '../../gql-global'
import { useCampaignTopCustomersQuery } from './operations/campaign-top-customers.generated'
import ContainerError from '../../components/ContainerError'
import CustomerTable from '../../components/CustomerTable/CustomerTable'
import { encodeFilterParams } from './use-filter-params-members'
import { useDateRangeRef } from '../../hooks/useDateRangeRef'
import { CAMPAIGN_ROUTE } from '../routes'
import useSortParam, { encodeSortParam } from './use-sort-param'
import SeeAllLink from '../../components/SeeAllLink'
import useSortDirParam from '../../customer/use-sort-dir-param'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'

interface TopCustomersWidgetProps {
  dateRangeFilter?: DateTimeRangeFilter | null
  campaignId: string
}

const LIMIT = 10

function TopCustomersWidget({ dateRangeFilter, campaignId }: TopCustomersWidgetProps): React.ReactElement {
  const [sort = ParticipantSort.Impressions, setSort] = useSortParam()
  const [sortDir = SortDirection.Desc, setSortDir] = useSortDirParam()
  const dateRange = dateRangeFilter && realizedDateRangeFromFilter(dateRangeFilter)
  const reffedDateRange = useDateRangeRef(dateRange || null)
  const dateRangeParameter = reffedDateRange ? { postedAt: reffedDateRange } : undefined

  const { data: userData } = useCampaignUserInfoQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const mentionsWhere = {
    ...dateRangeParameter,
    campaignId: { any: [campaignId] },
    socialAccountId: { any: [selectedSocialAccountId || ''] },
  }
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    campaignId: {
      any: [campaignId],
    },
    socialAccountId: { any: [selectedSocialAccountId || ''] },
  }

  const socialPlatform = selectedSocialPlatform(userData)

  const { data, loading, error } = useCampaignTopCustomersQuery({
    skip: !selectedSocialAccountId || !socialPlatform,
    variables: {
      campaignId: campaignId,
      mentionsWhere,
      activeStoryMentionsWhere,
      sortBy: sort,
      sortDirection: sortDir,
      limit: LIMIT,
      platform: socialPlatform!,
    },
  })
  if (!loading && error) {
    return (
      <Box display="flex" justifyContent="center">
        <ContainerError text="Could not load customers" />
      </Box>
    )
  }
  const list = data?.campaign?.participants?.results || []
  const isIGSocialAccount = socialPlatform === SocialPlatformEnum.Instagram
  return (
    <>
      <Box mb={5} display="flex" alignItems="center">
        <Box fontSize="1.125rem" fontWeight="fontWeightBold" lineHeight="1.5">
          Top Members
        </Box>
        <SeeAllLink
          variant="text"
          to={{
            pathname: `${CAMPAIGN_ROUTE.path}/${campaignId}/members`,
            search: `?${encodeFilterParams({
              postedAt: dateRangeFilter || undefined,
            })}&${encodeSortParam(ParticipantSort.Impressions)}`,
          }}
        />
      </Box>
      <CustomerTable
        isIGSocialAccount={isIGSocialAccount}
        hasCampaigns={true}
        entity="participant"
        list={list}
        ready={!loading}
        sort={sort}
        sortDir={sortDir}
        emptyText="No members found"
        setSort={(x: ParticipantSort) => {
          if (x === sort) {
            const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
            setSortDir(sortDirection)
          }
          setSort(x)
        }}
        useCustomerPageLinks={true}
      />
    </>
  )
}

export default TopCustomersWidget
