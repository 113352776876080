import React from 'react'
import { Chip, ChipProps, createStyles, makeStyles, Theme } from '@material-ui/core'
import { ReactComponent as LabelIcon } from '../../icons/label.svg'
import { ReactComponent as EyeIcon } from '../../icons/eye.svg'

interface LabelChipProps<T extends string | number> extends ChipProps {
  labelId?: T | null
  isRejectionReason?: boolean
  onDeleteCallback?(labelId: T): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      height: 32,
      marginRight: 4,
      paddingLeft: 16,
      color: theme.palette.secondary.dark,
    },
    error: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
      '& svg': {
        color: theme.palette.error.dark,
      },
    },
    default: {
      '& svg': {
        color: theme.palette.secondary.dark,
      },
      color: theme.palette.secondary.dark,
    },
  }),
)

function LabelChip<T extends string | number>({
  labelId,
  isRejectionReason = false,
  onDeleteCallback,
  ...rest
}: LabelChipProps<T>): React.ReactElement {
  const classes = useStyles()
  const handleDeleteClick = (): void => {
    if (labelId && onDeleteCallback) onDeleteCallback(labelId)
  }
  const rejectionReasonColorClass = isRejectionReason ? classes.error : classes.default

  return (
    <Chip
      className={`${classes.chip} ${rejectionReasonColorClass}`}
      icon={isRejectionReason ? <EyeIcon width={18} height={18} /> : <LabelIcon width={12} height={12} />}
      onDelete={onDeleteCallback && (() => handleDeleteClick())}
      {...rest}
    />
  )
}

export default LabelChip
