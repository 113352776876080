import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Link,
  Box,
  LinkProps,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'
import { Color, ColorPicker, createColor } from 'material-ui-color'
import { ReactComponent as ExternalIcon } from '../../icons/external.svg'
import { ReactComponent as CopyIcon } from '../../icons/copy.svg'
import { ReactComponent as EditIcon } from '../../icons/edit_pencil.svg'

interface HostLinkProps {
  url?: string | null
  onClose?(): void
  lcId?: string | null
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogBox: {
      display: 'flex',
      width: 400,
      height: 500,
      flexDirection: 'column',
      marginTop: 0,
    },
    dialogFields: {
      marginTop: 10,
    },
    colorPickerDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 25,
    },
  }),
)

function HostLink({ url, onClose, lcId }: HostLinkProps): React.ReactElement {
  const classes = useStyles()
  const [editDialogue, setEditDialogue] = useState<boolean>(false)
  const [buttonText, setButtonText] = useState<string>('Join Now')
  const [buttonColor, setButtonColor] = useState<Color>(createColor('blue'))
  const [buttonTextColor, setButtonTextColor] = useState<Color>(createColor('white'))
  const [description, setDescription] = useState<string | null>(null)

  const programScript = `<script type="text/javascript" src="https://pub.loudcrowd.com/launch.js?hid=${lcId}"></script></div>` // Script to be copied
  let addedAttributes =
    '<div id="lc-signup-container"' +
    ` data-lc-button-text="${buttonText}"` +
    ` data-lc-button-text-color="${buttonTextColor.css?.backgroundColor?.valueOf()}"` +
    ` data-lc-button-bg-color="${buttonColor.css?.backgroundColor?.valueOf()}"` // Adding all the required attributes
  addedAttributes = description ? addedAttributes + ` data-lc-description="${description}">` : addedAttributes + '>'
  const revisedScript = addedAttributes + programScript

  function handleClick() {
    setEditDialogue(true)
    if (onClose) {
      onClose()
    }
  }

  function handleButtonColorChange(color: Color | string) {
    const newColor = typeof color === 'string' ? createColor(color) : color
    setButtonColor(newColor)
  }

  const linkProps: LinkProps = url
    ? {
        href: `https://${url}`,
        target: '_blank',
        rel: 'noopener noreferrer',
        onClick() {
          if (onClose) {
            onClose()
          }
        },
      }
    : {
        onClick: handleClick,
      }

  return (
    <>
      <Link variant="body2" color="secondary" underline="hover" {...linkProps}>
        <Box display="inline-flex" alignItems="center">
          {url || 'Edit embed script'}{' '}
          <Box ml={1}>{!!url ? <ExternalIcon /> : <EditIcon width={14} height={14} />}</Box>
        </Box>
      </Link>
      <Dialog open={editDialogue} onClose={() => setEditDialogue(false)}>
        <DialogTitle style={{ marginTop: 15 }}>Get Embedded Script</DialogTitle>
        <DialogContent className={classes.dialogBox}>
          <TextField
            label="Button Text"
            variant="standard"
            value={buttonText}
            fullWidth
            onChange={e => setButtonText(e.target.value)}
            className={classes.dialogFields}
          />
          <div className={classes.colorPickerDiv}>
            <div>
              <Typography color="primary">Button Color</Typography>
            </div>
            <div style={{ paddingLeft: 46 }}>
              <ColorPicker onChange={handleButtonColorChange} value={buttonColor} />
            </div>
          </div>
          <div className={classes.colorPickerDiv}>
            <div>
              <Typography color="primary">Text Color</Typography>
            </div>
            <div style={{ paddingLeft: 65 }}>
              <ColorPicker onChange={color => setButtonTextColor(color)} value={buttonTextColor?.value} />
            </div>
          </div>
          <TextField
            className={classes.dialogFields}
            label="Description"
            variant="standard"
            value={description || ''}
            onChange={e => setDescription(e.target.value)}
            fullWidth
            multiline
            helperText="Add the description for the popup that opens up when this button is clicked"
          />
          <Box display="flex" flexDirection="column" alignItems="center" marginTop={10}>
            <div>
              <Typography color="primary">Preview</Typography>
            </div>
            <div style={{ paddingTop: 25 }}>
              <Button
                style={{
                  backgroundColor: buttonColor.css?.backgroundColor?.valueOf(), // Getting the updated background Color
                  color: buttonTextColor.css?.backgroundColor?.valueOf(), // Getting the updated text color
                }}
                disabled
                variant="contained"
              >
                {buttonText}
              </Button>
            </div>
          </Box>
        </DialogContent>
        <DialogActions style={{ marginBottom: 15, marginRight: 10 }}>
          <Button
            variant="contained"
            startIcon={<CopyIcon width={15} height={15} />}
            onClick={() => navigator.clipboard.writeText(revisedScript)}
          >
            Copy Embedded Script
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default HostLink
