declare global {
  interface Window {
    fbAsyncInit: () => void
  }
}

const id = 'facebook-jssdk'

/**
 * A class to manage users with facebook login, pull facebook data used by
 * LoudCrowd to sync their instagram business accounts. Use this class
 * to initialize the facebook sdk and open up a facebook login popup.
 *
 * example usage:
 * ```ts
 * import facebookSDK from 'lc-facebook-sdk'
 *
 * facebookSDK.init('a23j4kj23').then(() => {
 *   showFacebookLoginButton()
 * })
 *
 *
 * ```
 */
let loaded = false
class FacebookSDK {
  /**
   * Initialize the sdk and query facebook for current login status.
   * Note: this function is async
   *
   * @param appId - facebook app id
   * @returns a promise the will resolve with the current users facebook login status once the sdk is initialized
   */
  init(appId: string): Promise<facebook.StatusResponse> {
    if (loaded) {
      return this.fbLoginStatus()
    } else {
      return new Promise(resolve => {
        window.fbAsyncInit = () => {
          FB.init({
            appId,
            xfbml: false,
            status: true,
            version: 'v17.0',
          })
          loaded = true
          this.fbLoginStatus().then(resolve)
        }
        this.loadScript()
      })
    }
  }

  async authForBusiness(
    configId: string,
    authType: facebook.LoginOptions['auth_type'] = undefined,
  ): Promise<facebook.StatusResponse> {
    return await this.fbLogin({
      config_id: configId,
      auth_type: authType,
    })
  }

  private loadScript() {
    if (document.getElementById(id)) return
    const js = document.createElement('script')
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js'
    const fjs = document.getElementsByTagName('script')[0] || document.body.lastChild
    const parent = fjs?.parentNode || document.body
    parent.insertBefore(js, fjs)
  }

  private fbLogin(options: facebook.LoginOptions): Promise<facebook.StatusResponse> {
    return new Promise(resolve => {
      FB.login(resolve, options)
    })
  }

  private fbLoginStatus(): Promise<facebook.StatusResponse> {
    return new Promise(resolve => {
      FB.getLoginStatus(resolve)
    })
  }

  private fbApi<T>(url: string, method?: 'get' | 'post' | 'delete'): Promise<T> {
    return new Promise((resolve, reject) => {
      const callback = (response: T & { error: Error }): void => {
        if (response.error) {
          reject(response.error)
        } else {
          resolve(response)
        }
      }

      if (method) {
        FB.api(url, method, {}, callback)
      } else {
        FB.api(url, callback)
      }
    })
  }

  private async getInstagramIds(): Promise<(string | undefined)[]> {
    const accountsResponse = await this.fbApi<{ data: { instagram_business_account?: { id: string } }[] }>(
      '/me/accounts?fields=instagram_business_account',
    )
    return accountsResponse.data.map(i => i.instagram_business_account?.id)
  }

  async getInstagramAccounts(): Promise<{ platformId: string; username: string; profilePictureUrl: string }[]> {
    const igIds = await this.getInstagramIds()
    const response = await Promise.all(
      igIds
        .filter(Boolean)
        .map(id =>
          this.fbApi<{ id: string; username: string; profile_picture_url: string }>(
            `/${id}?fields=id,username,profile_picture_url`,
          ),
        ),
    )
    return response.map(r => ({ platformId: r.id, username: r.username, profilePictureUrl: r.profile_picture_url }))
  }
}

const FacebookSDKInstance = new FacebookSDK()
export default FacebookSDKInstance
