import React from 'react'
import { Box } from '@material-ui/core'
import { numberFormat } from '../../utils/number-format'
import { prevDateRangeText } from '../../utils/date-range-helper'
import { DateRangeFilter, DateTimeRangeFilter } from '../../gql-global'

interface DiffTextProps {
  num: number
  prev: number
  dateRangeFilter: DateRangeFilter | DateTimeRangeFilter
}

const DiffText: React.FC<DiffTextProps> = ({ num, prev, dateRangeFilter }) => {
  const diff = num - prev
  const usePercent = prev !== 0 || (num === 0 && prev === 0)
  const numFormat = usePercent ? 'percent' : 'number'
  const diffPercent = (diff / prev) * 100 || 0
  const final = usePercent ? diffPercent : diff
  let color: string | undefined = undefined
  if (diff < 0) color = 'error.dark'
  if (diff > 0) color = 'success.main'
  return (
    <>
      <Box component="span" color={color}>
        {numberFormat(final, { format: numFormat, forceSign: true })}
      </Box>{' '}
      {prevDateRangeText(dateRangeFilter)}
    </>
  )
}

export default DiffText
