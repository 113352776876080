import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMentionLabelBulkMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  mentionIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  socialAccountId?: Types.InputMaybe<Types.Scalars['ID']>;
  where?: Types.InputMaybe<Types.MentionFilterInput>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.MentionSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type CreateMentionLabelBulkMutation = { __typename: 'Mutations', createLabel?: { __typename: 'CreateLabel', ok?: boolean | null, label?: { __typename: 'LabelType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null, mentions?: Array<{ __typename: 'IGMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null } | { __typename: 'TTMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null }> | null } | null };


export const CreateMentionLabelBulkDocument = gql`
    mutation CreateMentionLabelBulk($name: String!, $mentionIds: [ID!], $socialAccountId: ID, $where: MentionFilterInput, $limit: Int, $sortBy: MentionSort, $sortDirection: SortDirection) {
  createLabel(
    name: $name
    mentionIds: $mentionIds
    socialAccountId: $socialAccountId
    where: $where
    limit: $limit
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    ok
    label {
      id
      name
      account {
        id
      }
    }
    mentions {
      id
      labels {
        id
      }
    }
  }
}
    `;
export type CreateMentionLabelBulkMutationFn = Apollo.MutationFunction<CreateMentionLabelBulkMutation, CreateMentionLabelBulkMutationVariables>;

/**
 * __useCreateMentionLabelBulkMutation__
 *
 * To run a mutation, you first call `useCreateMentionLabelBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMentionLabelBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMentionLabelBulkMutation, { data, loading, error }] = useCreateMentionLabelBulkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      mentionIds: // value for 'mentionIds'
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useCreateMentionLabelBulkMutation(baseOptions?: Apollo.MutationHookOptions<CreateMentionLabelBulkMutation, CreateMentionLabelBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMentionLabelBulkMutation, CreateMentionLabelBulkMutationVariables>(CreateMentionLabelBulkDocument, options);
      }
export type CreateMentionLabelBulkMutationHookResult = ReturnType<typeof useCreateMentionLabelBulkMutation>;
export type CreateMentionLabelBulkMutationResult = Apollo.MutationResult<CreateMentionLabelBulkMutation>;
export type CreateMentionLabelBulkMutationOptions = Apollo.BaseMutationOptions<CreateMentionLabelBulkMutation, CreateMentionLabelBulkMutationVariables>;