import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabelUserInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LabelUserInfoQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, account?: { __typename: 'AccountType', id: number } | null } | null };


export const LabelUserInfoDocument = gql`
    query LabelUserInfo {
  whoami {
    id
    roles {
      id
      name
    }
    account {
      id
    }
  }
}
    `;

/**
 * __useLabelUserInfoQuery__
 *
 * To run a query within a React component, call `useLabelUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useLabelUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<LabelUserInfoQuery, LabelUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabelUserInfoQuery, LabelUserInfoQueryVariables>(LabelUserInfoDocument, options);
      }
export function useLabelUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabelUserInfoQuery, LabelUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabelUserInfoQuery, LabelUserInfoQueryVariables>(LabelUserInfoDocument, options);
        }
export type LabelUserInfoQueryHookResult = ReturnType<typeof useLabelUserInfoQuery>;
export type LabelUserInfoLazyQueryHookResult = ReturnType<typeof useLabelUserInfoLazyQuery>;
export type LabelUserInfoQueryResult = Apollo.QueryResult<LabelUserInfoQuery, LabelUserInfoQueryVariables>;