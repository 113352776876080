import React, { Fragment, ReactElement } from 'react'
import { Box, Tabs, Tab, makeStyles, Theme, createStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(({ palette: { primary, common, text } }: Theme) =>
  createStyles({
    tabsWrapper: {
      width: 60,
      marginTop: 18,
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  }),
)

interface StyledTabProps {
  svgColor?: string
  backgroundColor?: string
}

const useTabStyles = makeStyles(({ palette: { primary, common, text } }: Theme) =>
  createStyles({
    tab: {
      minWidth: 60,
      padding: 0,
      margin: '10px 0',
      color: (props: StyledTabProps) => props.svgColor || primary.main,
      '& svg': {
        fill: (props: StyledTabProps) => props.svgColor || primary.main,
      },
      '&.Mui-selected': {
        background: (props: StyledTabProps) => props.backgroundColor || primary.main,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        color: common.white,
        '& svg': {
          fill: common.white,
        },
      },
      '&.Mui-disabled': {
        color: text.disabled,
        pointerEvents: 'auto',
        '& svg': {
          fill: text.disabled,
        },
      },
      '&.MuiTab-root': {
        opacity: 1,
      },
    },
  }),
)

export interface TabProps {
  icon: ReactElement
  isSelectedIcon?: ReactElement
  label?: string
  content: ReactElement
  disabled?: boolean
  disabledReason?: ReactElement | string
  svgColor?: string
  backgroundColor?: string
}

function CustomTab({
  icon,
  label,
  disabled,
  disabledReason,
  svgColor,
  backgroundColor,
  ...rest
}: Omit<TabProps, 'content' | 'isSelectedIcon'>): React.ReactElement {
  const tabClasses = useTabStyles({ svgColor: svgColor, backgroundColor: backgroundColor })
  return (
    <Tab
      label={label}
      icon={
        disabled ? (
          <Tooltip title={disabledReason || ''} placement="right-end">
            {icon}
          </Tooltip>
        ) : (
          icon
        )
      }
      className={tabClasses.tab}
      disabled={disabled}
      {...rest}
    />
  )
}

interface PostDetailsTabsProps {
  orientation?: 'vertical' | 'horizontal'
  tabs: Array<TabProps>
}

const PostDetailsTabs = (props: PostDetailsTabsProps): ReactElement => {
  const classes = useStyles()
  const { tabs, orientation } = props

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box display="flex">
      <Tabs orientation={orientation} value={value} onChange={handleChange} className={classes.tabsWrapper}>
        {tabs.map(({ label, icon, isSelectedIcon, disabled, disabledReason, svgColor, backgroundColor }, index) => {
          return (
            <CustomTab
              key={index}
              label={label}
              icon={value === index ? isSelectedIcon || icon : icon}
              disabled={disabled}
              disabledReason={disabledReason}
              svgColor={svgColor}
              backgroundColor={backgroundColor}
            />
          )
        })}
      </Tabs>
      <Box role="tabpanel">
        {tabs.map(({ content }, index) => value === index && <Fragment key={index}>{content}</Fragment>)}
      </Box>
    </Box>
  )
}

export default PostDetailsTabs
