import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignProgramActivitiesQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  where?: Types.InputMaybe<Types.ProgramActivityFilterInput>;
}>;


export type CampaignProgramActivitiesQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, latestProgramActivityStartDate?: Date | null, programTierGroups?: Array<{ __typename: 'ProgramTierGroupType', id: string, membersGifted?: number | null, rewardsSent?: number | null, startAt?: Date | null, status?: Types.ProgramTierGroupStatus | null, updatedAt?: Date | null, tiers?: Array<{ __typename: 'ProgramTierType', fulfillmentLimit?: number | null, id: string, mentionTypes?: Array<Types.MentionTypes> | null, name?: string | null, postTypes?: Array<Types.IgMediaPostType> | null, triggerBuffer?: Types.ProgramTierBuffer | null, triggerQuantity?: number | null, triggerType?: Types.ProgramTierTriggerType | null, reward?: { __typename: 'RewardType', id: number, name?: string | null } | null, socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> | null }> | null }> | null } | null } | null, whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number } | null } | null };


export const CampaignProgramActivitiesDocument = gql`
    query CampaignProgramActivities($campaignId: ID!, $where: ProgramActivityFilterInput) {
  campaign(id: $campaignId) {
    id
    program {
      id
      latestProgramActivityStartDate
      programTierGroups(where: $where) {
        id
        membersGifted(where: $where)
        rewardsSent(where: $where)
        startAt
        status
        tiers {
          fulfillmentLimit
          id
          mentionTypes
          name
          postTypes
          reward {
            id
            name
          }
          socialAccounts {
            id
          }
          triggerBuffer
          triggerQuantity
          triggerType
        }
        updatedAt
      }
    }
  }
  whoami {
    account {
      id
    }
    id
  }
}
    `;

/**
 * __useCampaignProgramActivitiesQuery__
 *
 * To run a query within a React component, call `useCampaignProgramActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignProgramActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignProgramActivitiesQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCampaignProgramActivitiesQuery(baseOptions: Apollo.QueryHookOptions<CampaignProgramActivitiesQuery, CampaignProgramActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignProgramActivitiesQuery, CampaignProgramActivitiesQueryVariables>(CampaignProgramActivitiesDocument, options);
      }
export function useCampaignProgramActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignProgramActivitiesQuery, CampaignProgramActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignProgramActivitiesQuery, CampaignProgramActivitiesQueryVariables>(CampaignProgramActivitiesDocument, options);
        }
export type CampaignProgramActivitiesQueryHookResult = ReturnType<typeof useCampaignProgramActivitiesQuery>;
export type CampaignProgramActivitiesLazyQueryHookResult = ReturnType<typeof useCampaignProgramActivitiesLazyQuery>;
export type CampaignProgramActivitiesQueryResult = Apollo.QueryResult<CampaignProgramActivitiesQuery, CampaignProgramActivitiesQueryVariables>;