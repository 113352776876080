import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestFulfillmentAccountMutationVariables = Types.Exact<{
  data: Types.TestFulfillmentInput;
}>;


export type TestFulfillmentAccountMutation = { __typename: 'Mutations', testFulfillment?: { __typename: 'TestFulfillment', ok: boolean, result?: any | null } | null };


export const TestFulfillmentAccountDocument = gql`
    mutation TestFulfillmentAccount($data: TestFulfillmentInput!) {
  testFulfillment(data: $data) {
    ok
    result
  }
}
    `;
export type TestFulfillmentAccountMutationFn = Apollo.MutationFunction<TestFulfillmentAccountMutation, TestFulfillmentAccountMutationVariables>;

/**
 * __useTestFulfillmentAccountMutation__
 *
 * To run a mutation, you first call `useTestFulfillmentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestFulfillmentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testFulfillmentAccountMutation, { data, loading, error }] = useTestFulfillmentAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTestFulfillmentAccountMutation(baseOptions?: Apollo.MutationHookOptions<TestFulfillmentAccountMutation, TestFulfillmentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestFulfillmentAccountMutation, TestFulfillmentAccountMutationVariables>(TestFulfillmentAccountDocument, options);
      }
export type TestFulfillmentAccountMutationHookResult = ReturnType<typeof useTestFulfillmentAccountMutation>;
export type TestFulfillmentAccountMutationResult = Apollo.MutationResult<TestFulfillmentAccountMutation>;
export type TestFulfillmentAccountMutationOptions = Apollo.BaseMutationOptions<TestFulfillmentAccountMutation, TestFulfillmentAccountMutationVariables>;