import { FetchResult } from '@apollo/client/link/core'
import { SocialAccountIdsDocument, SocialAccountIdsQuery } from '../queries/operations/social-account-ids.generated'
import { ApolloCache, Reference } from '@apollo/client'
import { SegmentSort, SegmentType, LabelType, AccountType, SocialAccount } from '../gql-global'

type Segment = Pick<SegmentType, 'id' | '__typename'> & {
  account?: Pick<AccountType, '__typename' | 'id'> | null
}
type Label = Pick<LabelType, 'id' | '__typename'> & { account?: Pick<AccountType, '__typename' | 'id'> | null }

type SegmentData = { ok?: boolean | null; segment?: Segment | null }
type LabelData = { ok?: boolean | null; label?: Label | null }

type Mutations =
  | { createSegment?: SegmentData | null }
  | { addCustomerSegment?: SegmentData | null }
  | { deleteSegment?: SegmentData | null }
  | { createLabel?: LabelData | null }
  | { addMentionLabel?: LabelData | null }
  | { deleteLabel?: LabelData | null }
  | { addParticipantSegment?: SegmentData | null }
  | { createParticipantSegment?: SegmentData | null }

type Action = 'create' | 'add' | 'delete'

function getAction(mutation: Mutations): { action: Action; ok: boolean; data: Segment | Label } {
  let data: Segment | Label | null | undefined = null
  let ok: boolean | null | undefined = false
  let action: Action = 'create'
  if ('createSegment' in mutation) {
    action = 'create'
    data = mutation.createSegment?.segment
    ok = mutation.createSegment?.ok
  }
  if ('createLabel' in mutation) {
    action = 'create'
    data = mutation.createLabel?.label
    ok = mutation.createLabel?.ok
  }
  if ('deleteSegment' in mutation) {
    action = 'delete'
    data = mutation.deleteSegment?.segment
    ok = mutation.deleteSegment?.ok
  }
  if ('deleteLabel' in mutation) {
    action = 'delete'
    data = mutation.deleteLabel?.label
    ok = mutation.deleteLabel?.ok
  }
  if ('addCustomerSegment' in mutation) {
    action = 'add'
    data = mutation.addCustomerSegment?.segment
    ok = mutation.addCustomerSegment?.ok
  }
  if ('addMentionLabel' in mutation) {
    action = 'add'
    data = mutation.addMentionLabel?.label
    ok = mutation.addMentionLabel?.ok
  }
  if ('addParticipantSegment' in mutation) {
    action = 'add'
    data = mutation.addParticipantSegment?.segment
    ok = mutation.addParticipantSegment?.ok
  }
  if ('createParticipantSegment' in mutation) {
    action = 'create'
    data = mutation.createParticipantSegment?.segment
    ok = mutation.createParticipantSegment?.ok
  }
  if (!data) {
    throw new Error('cannot update cache')
  }
  return { action, data, ok: ok || false }
}

type PagedType = { results: (Segment | Label | Reference)[] }

// updates the account labels/segments list
// depending on what action was taken on them
// delete => remove from cache
// create or add to thing => add or more to top of list if sorted that way
function labelsCacheUpdate<T>(cache: ApolloCache<T>, { data }: FetchResult<Mutations>): void {
  if (!data) return
  const action = getAction(data)
  if (!action.ok) return
  if (!action.data) return
  if (action.action === 'add' || action.action === 'create') {
    const account = action.data.account
    if (!account) return
    const cacheId = cache.identify(account)
    const fieldName = action.data.__typename === 'SegmentType' ? 'segments' : 'labels'
    cache.modify({
      id: cacheId,
      fields: {
        [fieldName]: function(value: PagedType | null | undefined, { DELETE, storeFieldName, readField, toReference }) {
          if (!value?.results) return value
          if (storeFieldName.includes(SegmentSort.RecentlyUsed) || !storeFieldName.includes('sortBy')) {
            const newResults = [toReference(action.data), ...value.results]
            const existingIndex = newResults.findIndex((v, i) => i > 0 && readField('id', v) === action.data.id)
            if (existingIndex > -1) {
              newResults.splice(existingIndex, 1)
            }
            return { ...value, results: newResults }
          }
          return DELETE
        },
      },
    })
  } else {
    cache.evict({ id: cache.identify(action.data) })
    cache.gc()
  }
}

const segmentDependentFields = ['stats', 'mentionStatsTimeseries', 'customers'] as const

type SocialField = keyof Pick<SocialAccount, typeof segmentDependentFields[number]>

function clearSegmentStatsCache<T, D>(
  existing: T,
  { storeFieldName, DELETE }: { storeFieldName: string; DELETE: D },
): T | D {
  if (storeFieldName.includes('segments')) {
    return DELETE
  }
  return existing
}

type ClearFieldsOptions = {
  // fields to clear
  fields: readonly SocialField[]
  // also clear top level mentions cache
  mentionsToo: boolean
}

// clear analytics/stats fields that filter by segment
// this can be used to invalidate cache for things that
// are filtering by segments, useful for when adding/removing
// a segment from a customer.
export function clearSocialFieldsFilteringBySegments<T>(
  cache: ApolloCache<T>,
  options: ClearFieldsOptions = {
    fields: segmentDependentFields,
    mentionsToo: true,
  },
): void {
  let results: SocialAccountIdsQuery | null
  try {
    results = cache.readQuery<SocialAccountIdsQuery>({ query: SocialAccountIdsDocument })
  } catch {
    // fails if social accounts arent in cache, if they arent in
    // cache then we dont need to invalid their cache
    return
  }
  const modifyFields = options.fields.reduce((a, v) => {
    return {
      ...a,
      [v]: clearSegmentStatsCache,
    }
  }, {})

  for (const socialAccount of results?.socialAccounts || []) {
    const cacheId = cache.identify(socialAccount)
    cache.modify({ id: cacheId, fields: modifyFields })
  }
  if (options.mentionsToo) {
    cache.evict({ fieldName: 'mentions' })
  }
  cache.gc()
}

export default labelsCacheUpdate
