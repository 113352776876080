import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Typography, makeStyles, Link, Theme, createStyles } from '@material-ui/core'
import { NetworkStatus } from '@apollo/client'

import { DateRange } from '../utils/date-range-helper'
import { encodeFilterParams } from '../content/use-filter-params'
import {
  MentionStatus,
  MentionSort,
  DateTimeRangeFilter,
  IgMediaPostType,
  AnySegmentCampaignFilterInput,
  ChallengesFilterInput,
} from '../gql-global'
import { useDashboardTopPostsQuery } from './operations/query-dashboard-top-posts.generated'
import { CONTENT_ROUTE } from '../content/routes'
import { encodeSortParam } from '../content/use-sort-param'
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../components/ContainerError'
import ConnectedPostDetailModal, { useOpenPostParam } from '../components/ConnectedPostDetailModal'
import { ReactComponent as NoPostsImage } from '../images/no-posts.svg'
import PostList from '../content/PostList'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import { MentionTypes } from '../content/constants'
import { makeMentionTypesWhere, postStatusArrayFilterFromSelection } from './utils'
import useHasFeature from '../hooks/useHasFeature'

export interface DashboardTopPostBlockProps {
  socialAccountId?: string | null | undefined
  realizedDateRange: DateRange
  dateRangeFilter: DateTimeRangeFilter
  selectedSegments?: AccountLabel[] | null
  selectedCampaigns?: AccountLabel[] | null
  selectedMentionTypes: Set<MentionTypes> | null
  selectedPostTypes: Set<IgMediaPostType> | null
  selectedPostStatus: Set<MentionStatus> | null
  selectedChallenges?: ChallengesFilterInput | null
  hasCampaigns?: boolean
  isInstagramAccount?: boolean | null
  includePremembership: boolean
  segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    seeAll: {
      marginLeft: theme.spacing(3),
    },
  }),
)

const TOP_POST_SORT_BY = MentionSort.Impressions

const DashboardTopPostBlock: React.FC<DashboardTopPostBlockProps> = ({
  hasCampaigns = false,
  socialAccountId,
  realizedDateRange,
  dateRangeFilter,
  selectedSegments = null,
  selectedCampaigns = null,
  selectedPostTypes = null,
  selectedChallenges = null,
  selectedMentionTypes,
  selectedPostStatus,
  isInstagramAccount,
  includePremembership,
  segmentCampaignFilter,
}) => {
  const classes = useStyles()
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
  }
  const { loading, data, error, networkStatus } = useDashboardTopPostsQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      socialAccountId: socialAccountId || '',
      sortBy: TOP_POST_SORT_BY,
      where: {
        tagStatus: { any: postStatusArrayFilterFromSelection(selectedPostStatus) },
        postedAt: realizedDateRange,
        segments: selectedSegments?.length ? { all: [selectedSegments.map(s => s.id.toString())] } : null,
        campaigns: selectedCampaigns?.length ? { any: selectedCampaigns.map(c => c.id.toString()) } : null,
        segmentsCampaigns: segmentCampaignFilter?.length ? { all: segmentCampaignFilter } : undefined,
        postType: selectedPostTypes?.size ? { any: Array.from(selectedPostTypes) } : undefined,
        ...makeMentionTypesWhere(selectedMentionTypes, isInstagramAccount ?? false, hasTikTokHashtags),
        expiredStories: false,
        unavailableMedia: false,
        includePremembership,
        challengeIds: selectedChallenges || undefined,
      },
      activeStoryMentionsWhere,
    },
  })
  const [, setOpenPostParam] = useOpenPostParam()

  const ready = !loading && !!socialAccountId && networkStatus !== NetworkStatus.refetch
  const posts = data?.mentions?.results
  return (
    <Box>
      <Box marginBottom="29px" display="flex" alignItems="center">
        <Typography variant="h6">Top Posts</Typography>
        <Link
          variant="subtitle2"
          className={classes.seeAll}
          component={RouterLink}
          to={{
            pathname: CONTENT_ROUTE.path,
            search: `?${encodeFilterParams({
              postedAt: dateRangeFilter,
              segments: selectedSegments?.length ? { all: [selectedSegments.map(s => s.id.toString())] } : undefined,
              campaigns: selectedCampaigns?.length ? { any: selectedCampaigns.map(c => c.id.toString()) } : undefined,
              tagStatus: selectedPostStatus || undefined,
              postType: selectedPostTypes || undefined,
              mentionType: selectedMentionTypes || undefined,
              expiredStories: false,
              unavailableMedia: false,
              ...(selectedChallenges?.any?.length && { challengeIds: selectedChallenges }),
            })}&${encodeSortParam(TOP_POST_SORT_BY)}`,
          }}
        >
          <Box display="inline-flex" alignItems="center">
            See all
            <Box display="inline" ml={2}>
              <ArrowIcon width={12} height={12} transform="rotate(-90)" />
            </Box>
          </Box>
        </Link>
      </Box>
      <PostList loading={!ready} hasCampaigns={hasCampaigns} posts={posts} onClickPost={setOpenPostParam} max={4} />
      {ready && posts?.length === 0 && (
        <Box display="flex" justifyContent="center">
          <ContainerEmptyState image={NoPostsImage} text="No posts for this dashboard" />
        </Box>
      )}
      {ready && error && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Could not load posts" />
        </Box>
      )}
      <ConnectedPostDetailModal />
    </Box>
  )
}

export default DashboardTopPostBlock
