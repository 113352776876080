import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useUserIntercomDataQuery } from './operations/user-intercom-data.generated'

function useIntercomOnPage(page = ''): void {
  const { boot } = useIntercom()
  const { data } = useUserIntercomDataQuery()

  const email = data?.whoami?.email
  const socialAccounts = data?.whoami?.account?.socialAccounts
  useEffect(() => {
    if (boot && email) {
      boot({
        email: email,
        customAttributes: {
          page: page,
          count_social_account: (socialAccounts && socialAccounts.length) || 0,
        },
      })
    }
  }, [boot, page, email, socialAccounts])
}

export default useIntercomOnPage
