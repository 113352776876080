import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertProgramEcommDiscountCodeMutationVariables = Types.Exact<{
  data: Types.EcommDiscountCodeInput;
  programId: Types.Scalars['ID'];
}>;


export type UpsertProgramEcommDiscountCodeMutation = { __typename: 'Mutations', upsertProgramEcommDiscountCode?: { __typename: 'UpsertProgramEcommDiscountCode', ok: boolean, ecommDiscountCode?: { __typename: 'EcommDiscountCodeType', id: string } | null } | null };


export const UpsertProgramEcommDiscountCodeDocument = gql`
    mutation UpsertProgramEcommDiscountCode($data: EcommDiscountCodeInput!, $programId: ID!) {
  upsertProgramEcommDiscountCode(data: $data, programId: $programId) {
    ecommDiscountCode {
      id
    }
    ok
  }
}
    `;
export type UpsertProgramEcommDiscountCodeMutationFn = Apollo.MutationFunction<UpsertProgramEcommDiscountCodeMutation, UpsertProgramEcommDiscountCodeMutationVariables>;

/**
 * __useUpsertProgramEcommDiscountCodeMutation__
 *
 * To run a mutation, you first call `useUpsertProgramEcommDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProgramEcommDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProgramEcommDiscountCodeMutation, { data, loading, error }] = useUpsertProgramEcommDiscountCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useUpsertProgramEcommDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProgramEcommDiscountCodeMutation, UpsertProgramEcommDiscountCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertProgramEcommDiscountCodeMutation, UpsertProgramEcommDiscountCodeMutationVariables>(UpsertProgramEcommDiscountCodeDocument, options);
      }
export type UpsertProgramEcommDiscountCodeMutationHookResult = ReturnType<typeof useUpsertProgramEcommDiscountCodeMutation>;
export type UpsertProgramEcommDiscountCodeMutationResult = Apollo.MutationResult<UpsertProgramEcommDiscountCodeMutation>;
export type UpsertProgramEcommDiscountCodeMutationOptions = Apollo.BaseMutationOptions<UpsertProgramEcommDiscountCodeMutation, UpsertProgramEcommDiscountCodeMutationVariables>;