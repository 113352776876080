import React from 'react'
import { useCampaignTopPostsQuery } from './operations/campaign-top-posts.generated'
import { realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import {
  DateTimeRangeFilter,
  DateRangeFilterType,
  MentionStatus,
  MentionFilterInput,
  MentionSort,
  IgMediaPostType,
} from '../../gql-global'
import ContainerEmptyState from '../../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../../components/ContainerError'
import ConnectedPostDetailModal, { useOpenPostParam } from '../../components/ConnectedPostDetailModal'
import { ReactComponent as NoPostsImage } from '../../images/no-posts.svg'
import PostList from '../../content/PostList'
import { Box } from '@material-ui/core'
import SeeAllLink from '../../components/SeeAllLink'
import { CONTENT_ROUTE } from '../../content/routes'
import { encodeFilterParams } from '../../content/use-filter-params'
import { encodeSortParam } from '../../content/use-sort-param'
import { useCampaignQuery } from './operations/campaign.generated'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'

interface TopPostsWidgetProps {
  dateRangeFilter?: DateTimeRangeFilter | null
  campaignId: string
}

const SORT_BY = MentionSort.Impressions
const LIMIT = 4

function TopPostsWidget({ dateRangeFilter, campaignId }: TopPostsWidgetProps): React.ReactElement {
  const dateRange = dateRangeFilter && realizedDateRangeFromFilter(dateRangeFilter)
  const { data: userData } = useCampaignUserInfoQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const socialPlatform = selectedSocialPlatform(userData)

  const where: MentionFilterInput = {
    postedAt: dateRange,
    tagStatus: { any: [MentionStatus.Verified, MentionStatus.Unverified] },
    expiredStories: false,
    unavailableMedia: false,
    socialAccountId: selectedSocialAccountId ? { any: [selectedSocialAccountId] } : undefined,
  }
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    campaignId: {
      any: [campaignId],
    },
    socialAccountId: selectedSocialAccountId ? { any: [selectedSocialAccountId] } : undefined,
  }
  const { data, loading, error } = useCampaignTopPostsQuery({
    skip: !selectedSocialAccountId,
    variables: {
      campaignId: campaignId,
      where,
      sortBy: SORT_BY,
      limit: LIMIT,
      activeStoryMentionsWhere,
    },
  })

  const { data: campaignData } = useCampaignQuery({
    skip: !socialPlatform,
    variables: { id: campaignId, platform: socialPlatform! },
  })
  const [, setOpenPostParam] = useOpenPostParam()

  const posts = data?.campaign?.mentions?.results
  return (
    <>
      <Box mb={5} fontSize="1.125rem" fontWeight="fontWeightBold" lineHeight="1.5">
        Top Posts
        <SeeAllLink
          to={{
            pathname: `${CONTENT_ROUTE.path}`,
            search: `?${encodeFilterParams({
              postedAt: dateRangeFilter
                ? dateRangeFilter
                : !campaignData?.campaign?.startAt
                ? undefined
                : {
                    __typename: 'AbsoluteDateTimeRangeFilter',
                    rangeType: DateRangeFilterType.Absolute,
                    gte: campaignData?.campaign?.startAt,
                  },
              campaigns: { any: [campaignId] },
              expiredStories: false,
              unavailableMedia: false,
            })}&${encodeSortParam(MentionSort.Impressions)}`,
          }}
          variant="text"
        />
      </Box>
      <PostList loading={loading} hasCampaigns={true} posts={posts} onClickPost={setOpenPostParam} max={LIMIT} />
      {!loading && error && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Could not load posts" />
        </Box>
      )}
      {!loading && !posts?.length && (
        <Box display="flex" justifyContent="center">
          <ContainerEmptyState image={NoPostsImage} text="No posts yet for this campaign." />
        </Box>
      )}
      <ConnectedPostDetailModal />
    </>
  )
}

export default TopPostsWidget
