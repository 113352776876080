import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignProgramCollectionsQueryVariables = Types.Exact<{
  activeFilter?: Types.InputMaybe<Types.Scalars['Boolean']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  id: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CampaignProgramCollectionsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, collections?: Array<{ __typename: 'CollectionType', id: string, imageSrc?: string | null, title: string }> | null } | null } | null };


export const CampaignProgramCollectionsDocument = gql`
    query CampaignProgramCollections($activeFilter: Boolean, $cursor: String, $id: ID!, $limit: Int, $searchText: String) {
  campaign(id: $id) {
    id
    program {
      collections(
        activeFilter: $activeFilter
        cursor: $cursor
        limit: $limit
        searchText: $searchText
      ) {
        id
        imageSrc
        title
      }
      id
    }
  }
}
    `;

/**
 * __useCampaignProgramCollectionsQuery__
 *
 * To run a query within a React component, call `useCampaignProgramCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignProgramCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignProgramCollectionsQuery({
 *   variables: {
 *      activeFilter: // value for 'activeFilter'
 *      cursor: // value for 'cursor'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useCampaignProgramCollectionsQuery(baseOptions: Apollo.QueryHookOptions<CampaignProgramCollectionsQuery, CampaignProgramCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignProgramCollectionsQuery, CampaignProgramCollectionsQueryVariables>(CampaignProgramCollectionsDocument, options);
      }
export function useCampaignProgramCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignProgramCollectionsQuery, CampaignProgramCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignProgramCollectionsQuery, CampaignProgramCollectionsQueryVariables>(CampaignProgramCollectionsDocument, options);
        }
export type CampaignProgramCollectionsQueryHookResult = ReturnType<typeof useCampaignProgramCollectionsQuery>;
export type CampaignProgramCollectionsLazyQueryHookResult = ReturnType<typeof useCampaignProgramCollectionsLazyQuery>;
export type CampaignProgramCollectionsQueryResult = Apollo.QueryResult<CampaignProgramCollectionsQuery, CampaignProgramCollectionsQueryVariables>;