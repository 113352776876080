import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { CampaignRowFragmentDoc } from './campaign-row.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignsQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  platform: Types.SocialPlatformEnum;
}>;


export type CampaignsQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, isActive: boolean, startAt: Date, program?: { __typename: 'ProgramType', id: string, name: string, currencyCode?: Types.CurrencyCodeEnum | null } | null, mentionStats?: { __typename: 'CampaignMentionStats', posts: number, engagement: number } | null, participantStats?: { __typename: 'ParticipantStats', count: number } | null }> | null } | { __typename: 'TTSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, isActive: boolean, startAt: Date, program?: { __typename: 'ProgramType', id: string, name: string, currencyCode?: Types.CurrencyCodeEnum | null } | null, mentionStats?: { __typename: 'CampaignMentionStats', posts: number, engagement: number } | null, participantStats?: { __typename: 'ParticipantStats', count: number } | null }> | null } | null };


export const CampaignsDocument = gql`
    query Campaigns($socialAccountId: ID!, $platform: SocialPlatformEnum!) {
  socialAccount(id: $socialAccountId) {
    id
    campaigns {
      ...CampaignRow
    }
  }
}
    ${CampaignRowFragmentDoc}`;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;