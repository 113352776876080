import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SentMessagesActivityByMentionQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  mentionId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SentMessagesActivityByMentionQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, sentMessages: { __typename: 'PagedMessages', total: number, results: Array<{ __typename: 'Message', id: string, messageText: string, createdAt: Date, sentBy?: { __typename: 'UserType', id: number } | null, fulfillment?: { __typename: 'FulfillmentType', id: string, reward?: { __typename: 'RewardType', id: number, name?: string | null } | null } | null }> } } | { __typename: 'TTSocialAccount', id: string } | null };


export const SentMessagesActivityByMentionDocument = gql`
    query SentMessagesActivityByMention($socialAccountId: ID!, $mentionId: ID!, $limit: Int) {
  socialAccount(id: $socialAccountId) {
    id
    ... on IGSocialAccount {
      sentMessages(limit: $limit, where: {mentionId: $mentionId}) {
        total
        results {
          id
          messageText
          createdAt
          sentBy {
            id
          }
          fulfillment {
            id
            reward {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSentMessagesActivityByMentionQuery__
 *
 * To run a query within a React component, call `useSentMessagesActivityByMentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentMessagesActivityByMentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentMessagesActivityByMentionQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      mentionId: // value for 'mentionId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSentMessagesActivityByMentionQuery(baseOptions: Apollo.QueryHookOptions<SentMessagesActivityByMentionQuery, SentMessagesActivityByMentionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SentMessagesActivityByMentionQuery, SentMessagesActivityByMentionQueryVariables>(SentMessagesActivityByMentionDocument, options);
      }
export function useSentMessagesActivityByMentionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SentMessagesActivityByMentionQuery, SentMessagesActivityByMentionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SentMessagesActivityByMentionQuery, SentMessagesActivityByMentionQueryVariables>(SentMessagesActivityByMentionDocument, options);
        }
export type SentMessagesActivityByMentionQueryHookResult = ReturnType<typeof useSentMessagesActivityByMentionQuery>;
export type SentMessagesActivityByMentionLazyQueryHookResult = ReturnType<typeof useSentMessagesActivityByMentionLazyQuery>;
export type SentMessagesActivityByMentionQueryResult = Apollo.QueryResult<SentMessagesActivityByMentionQuery, SentMessagesActivityByMentionQueryVariables>;