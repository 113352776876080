import { Box, Dialog } from '@material-ui/core'
import React from 'react'
import ChallengeRewardPicker from '../../components/ChallengeRewardPicker'
import { RewardRowFragment } from '../../components/RewardList/operations/rewards.generated'

interface CampaignPickWinnerDialogProps {
  open: boolean
  onClose(): void
  challengeId: string
  onSendReward(reward: RewardRowFragment, challengeId: string, offline: boolean): Promise<void>
}

export default function CampaignPickWinnerDialog({
  open,
  onClose,
  challengeId,
  onSendReward,
}: CampaignPickWinnerDialogProps): React.ReactElement {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <Box ml={4} width={520} height={600}>
        <ChallengeRewardPicker width={520} height={600} challenges={[challengeId || '']} onSendReward={onSendReward} />
      </Box>
    </Dialog>
  )
}
