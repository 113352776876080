import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tabs,
  Tab,
  Theme,
  Typography,
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { AddChallengeFormFields } from './AddEditChallengeDialog'
import { ReactComponent as Arrow } from '../icons/arrow.svg'
import { ReactComponent as PartyIcon } from '../icons/party_popper.svg'
import { ReactComponent as MagicWand } from '../icons/magic_wand.svg'
import { ReactComponent as MegaphoneIcon } from '../icons/megaphone.svg'
import { ReactComponent as MemoIcon } from '../icons/memo.svg'
import { ReactComponent as TrophyIcon } from '../icons/colot-trophy.svg'
import { format } from 'date-fns'
import { ChallengeRewardCriteriaEnum, ChallengeStatusEnum } from '../gql-global'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import DraftBrief from './painted-door/DraftBrief'
import ParticipantMessages from './painted-door/ParticipantMessages'
import PickWinners from './painted-door/PickWinners'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
    },
    title: {
      fontSize: 32,
      fontWeight: 500,
      paddingTop: '24px',
      paddingLeft: '24px',
    },
    icon: {
      paddingTop: '16px',
    },
    listContainer: {
      marginLeft: '-16px',
      marginTop: '-12px',
    },
    listItem: {
      paddingTop: '12px',
    },
    button: {},
    backContainer: {
      display: 'flex',
      cursor: 'pointer',
      marginBottom: 15,
    },
    arrow: {
      color: theme.palette.primary.main,
      rotate: '90deg',
      marginTop: '7px',
      marginRight: '8px',
    },
    backText: {
      color: theme.palette.primary.main,
    },
    subheader: {
      marginTop: 15,
      fontWeight: 500,
    },
    tab: {
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: 4,
      width: 200,
      height: 150,
      '&.Mui-selected': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '& .MuiTab-wrapper': {
        lineHeight: 1.4,
        '& > *:first-child': {
          marginBottom: 15,
        },
      },
    },
    tabContainer: {
      '& .MuiTabs-flexContainer': {
        gap: '20px',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    dialogActions: {
      justifyContent: 'flex-start',
      padding: '25px 0 0 25px',
      '& button': {
        minWidth: 100,
      },
    },
  }),
)

interface PaintedDoorContentProps {
  onClose(): void
  onEdit(): void
  rewards: AccountLabel[]
  currentStatus?: ChallengeStatusEnum
}

function PaintedDoorContent({ onClose, onEdit, rewards, currentStatus }: PaintedDoorContentProps): React.ReactElement {
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState<number>(0)
  const { values } = useFormikContext<AddChallengeFormFields>()

  const specialCriteriaRewards = rewards.filter(reward => {
    return values.rewards?.find(
      selected =>
        selected.rewardId === reward.id.toString() &&
        selected.winningCriteria !== ChallengeRewardCriteriaEnum.Participating,
    )
  })

  const startDate = values.dates?.gte
  const endDate = values.dates?.lt

  const isCompleted = currentStatus === ChallengeStatusEnum.Completed

  return (
    <Box p={12}>
      <DialogTitle disableTypography>
        <Box className={classes.backContainer} onClick={onEdit} width={50}>
          <Arrow className={classes.arrow} />
          <Typography className={classes.backText}>Back</Typography>
        </Box>
        {startDate && endDate && (
          <Box className={classes.titleContainer}>
            <PartyIcon className={classes.icon} />
            <Typography className={classes.title}>You're ready to go!</Typography>
          </Box>
        )}
        {(!startDate || !endDate) && (
          <Box className={classes.titleContainer}>
            <MagicWand className={classes.icon} />
            <Typography className={classes.title}>You're almost ready!</Typography>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" maxHeight="60vh" overflow="visible">
          <ul className={classes.listContainer}>
            {startDate && endDate && (
              <>
                <li className={classes.listItem}>
                  Your challenge will launch automatically on {format(startDate, 'MMM d')}.
                </li>
                {values.rewards
                  ?.filter(r => r.winningCriteria === ChallengeRewardCriteriaEnum.Participating)
                  .map(r => {
                    const rewardName = rewards.find(reward => reward.id.toString() === r.rewardId.toString())?.name
                    return (
                      <li className={classes.listItem} key={r.rewardId}>
                        {values.additionalCriteria?.hasAdditionalCriteria
                          ? `Participants will receive ${rewardName} when posts are approved for this challenge.`
                          : `Participants will receive ${rewardName} immediately when above post criteria are met.`}
                      </li>
                    )
                  })}
                <li className={classes.listItem}>
                  You'll get email reminders on your start date {format(startDate, 'MMM d')} and end date{' '}
                  {format(endDate, 'MMM d')}.
                </li>
              </>
            )}
            {(!startDate || !endDate) && (
              <li className={classes.listItem}>You'll need to input start and end dates before you can launch.</li>
            )}
            <li className={classes.listItem}>
              You can review your UGC and select winners from the Challenge page, or by filtering for the challenge on
              the Content and CRM pages.
            </li>
          </ul>

          <Typography variant="subtitle1" className={classes.subheader}>
            What else can we help you with?
          </Typography>
          <Box mt={3} mb={5}>
            <Tabs
              value={activeTab}
              className={classes.tabContainer}
              onChange={(event, tabIndex) => setActiveTab(tabIndex)}
              aria-label="icon label tabs example"
            >
              <Tab className={classes.tab} icon={<MegaphoneIcon />} label="Draft your brief" />
              <Tab className={classes.tab} icon={<MemoIcon />} label="Set up messages to participants" />
              {!!specialCriteriaRewards.length && (
                <Tab className={classes.tab} icon={<TrophyIcon />} label="Help me pick winners" />
              )}
            </Tabs>
            <Box mt={5}>
              {activeTab === 0 && <DraftBrief disabled={isCompleted} />}
              {activeTab === 1 && <ParticipantMessages disabled={isCompleted} />}
              {activeTab === 2 && <PickWinners rewards={specialCriteriaRewards} disabled={isCompleted} />}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Finish
        </Button>
      </DialogActions>
    </Box>
  )
}

export default PaintedDoorContent
