import React from 'react'
import { Typography, MenuItem, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

interface RewardRowCollectionMenuProps {
  collection?: Array<{ id: string | number; name: string } | null> | null
  label: string
  route: string
  includesId?: boolean
}

const RewardRowCollectionMenu: React.FC<RewardRowCollectionMenuProps> = ({ collection, label, route, includesId }) => {
  return (
    <>
      {!!collection?.length && (
        <>
          <MenuItem key={`${collection[0]?.id || ''}-header`}>
            <Typography variant="subtitle1" color="textPrimary">
              {label}
            </Typography>
          </MenuItem>
          {collection.map(item => (
            <Link
              key={item?.id}
              variant="body2"
              color="secondary"
              to={includesId ? `${route}/${item?.id}` : route}
              component={RouterLink}
              rel="noopener noreferrer"
            >
              <MenuItem key={item?.id}>
                <Typography variant="body2">{item?.name}</Typography>
              </MenuItem>
            </Link>
          ))}
        </>
      )}
    </>
  )
}

export default RewardRowCollectionMenu
