import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFulfillmentAccountMutationVariables = Types.Exact<{
  fulfillmentAccountId: Types.Scalars['ID'];
  key: Types.Scalars['String'];
  accountType: Types.Scalars['String'];
  fromEmail?: Types.InputMaybe<Types.Scalars['String']>;
  fromName?: Types.InputMaybe<Types.Scalars['String']>;
  guid?: Types.InputMaybe<Types.Scalars['String']>;
  apiUrl?: Types.InputMaybe<Types.Scalars['String']>;
  apiSecret?: Types.InputMaybe<Types.Scalars['String']>;
  appId?: Types.InputMaybe<Types.Scalars['String']>;
  smsConsentList?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateFulfillmentAccountMutation = { __typename: 'Mutations', updateFulfillmentAccount?: { __typename: 'UpdateFulfillmentAccount', ok?: boolean | null, fulfillmentAccount?: { __typename: 'FulfillAccountType', id: number, key?: string | null, fromEmail?: string | null, fromName?: string | null, guid?: string | null, apiUrl?: string | null, apiSecret?: string | null, accountType: Types.FulfillmentAccountType, lastPush?: Date | null, smsConsentList?: string | null } | null } | null };


export const UpdateFulfillmentAccountDocument = gql`
    mutation UpdateFulfillmentAccount($fulfillmentAccountId: ID!, $key: String!, $accountType: String!, $fromEmail: String, $fromName: String, $guid: String, $apiUrl: String, $apiSecret: String, $appId: String, $smsConsentList: String) {
  updateFulfillmentAccount(
    fulfillmentAccountId: $fulfillmentAccountId
    key: $key
    accountType: $accountType
    fromEmail: $fromEmail
    fromName: $fromName
    guid: $guid
    apiUrl: $apiUrl
    apiSecret: $apiSecret
    appId: $appId
    smsConsentList: $smsConsentList
  ) {
    ok
    fulfillmentAccount {
      id
      key
      fromEmail
      fromName
      guid
      apiUrl
      apiSecret
      accountType
      lastPush
      smsConsentList
    }
  }
}
    `;
export type UpdateFulfillmentAccountMutationFn = Apollo.MutationFunction<UpdateFulfillmentAccountMutation, UpdateFulfillmentAccountMutationVariables>;

/**
 * __useUpdateFulfillmentAccountMutation__
 *
 * To run a mutation, you first call `useUpdateFulfillmentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFulfillmentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentAccountMutation, { data, loading, error }] = useUpdateFulfillmentAccountMutation({
 *   variables: {
 *      fulfillmentAccountId: // value for 'fulfillmentAccountId'
 *      key: // value for 'key'
 *      accountType: // value for 'accountType'
 *      fromEmail: // value for 'fromEmail'
 *      fromName: // value for 'fromName'
 *      guid: // value for 'guid'
 *      apiUrl: // value for 'apiUrl'
 *      apiSecret: // value for 'apiSecret'
 *      appId: // value for 'appId'
 *      smsConsentList: // value for 'smsConsentList'
 *   },
 * });
 */
export function useUpdateFulfillmentAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFulfillmentAccountMutation, UpdateFulfillmentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFulfillmentAccountMutation, UpdateFulfillmentAccountMutationVariables>(UpdateFulfillmentAccountDocument, options);
      }
export type UpdateFulfillmentAccountMutationHookResult = ReturnType<typeof useUpdateFulfillmentAccountMutation>;
export type UpdateFulfillmentAccountMutationResult = Apollo.MutationResult<UpdateFulfillmentAccountMutation>;
export type UpdateFulfillmentAccountMutationOptions = Apollo.BaseMutationOptions<UpdateFulfillmentAccountMutation, UpdateFulfillmentAccountMutationVariables>;