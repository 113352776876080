import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 300,
    },
  }),
)

export interface AddSegmentProps {
  open: boolean
  handleClose: () => void
  handleSave: (name: string) => void
}

const validate = (name: string): boolean => {
  if (!name || name.trim() === '') {
    return false
  }
  return true
}

const AddSegment: React.FC<AddSegmentProps> = ({ open, handleClose, handleSave }) => {
  const classes = useStyles()
  const [name, setName] = useState('')

  useEffect(() => {
    setName('')
  }, [open])

  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <DialogTitle>Add Segment</DialogTitle>
      <>
        <DialogContent style={{ paddingTop: 0 }}>
          <TextField
            label="Name"
            type="name"
            value={name}
            onChange={({ target: { value } }) => setName(value)}
            className={classes.formControl}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleSave(name)} color="primary" disabled={!validate(name)}>
            Save
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default AddSegment
