import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignProgramProductsQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  id: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CampaignProgramProductsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, products?: Array<{ __typename: 'Product', id: string, imageSrc?: string | null, title?: string | null }> | null } | null } | null };


export const CampaignProgramProductsDocument = gql`
    query CampaignProgramProducts($cursor: String, $id: ID!, $limit: Int, $searchText: String) {
  campaign(id: $id) {
    id
    program {
      id
      products(cursor: $cursor, limit: $limit, searchText: $searchText) {
        id
        imageSrc
        title
      }
    }
  }
}
    `;

/**
 * __useCampaignProgramProductsQuery__
 *
 * To run a query within a React component, call `useCampaignProgramProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignProgramProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignProgramProductsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useCampaignProgramProductsQuery(baseOptions: Apollo.QueryHookOptions<CampaignProgramProductsQuery, CampaignProgramProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignProgramProductsQuery, CampaignProgramProductsQueryVariables>(CampaignProgramProductsDocument, options);
      }
export function useCampaignProgramProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignProgramProductsQuery, CampaignProgramProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignProgramProductsQuery, CampaignProgramProductsQueryVariables>(CampaignProgramProductsDocument, options);
        }
export type CampaignProgramProductsQueryHookResult = ReturnType<typeof useCampaignProgramProductsQuery>;
export type CampaignProgramProductsLazyQueryHookResult = ReturnType<typeof useCampaignProgramProductsLazyQuery>;
export type CampaignProgramProductsQueryResult = Apollo.QueryResult<CampaignProgramProductsQuery, CampaignProgramProductsQueryVariables>;