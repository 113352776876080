export default class SquarePolynomialModel {
  b0: number
  b1: number
  b2: number

  constructor() {
    this.b0 = 0
    this.b1 = 0
    this.b2 = 0
  }

  fit(xValues: number[], yValues: number[]): void {
    if (xValues.length !== yValues.length) {
      throw Error('Invalid shape of X and Y')
    }

    let count = 0
    let sumX = 0
    let sumX2 = 0
    let sumX3 = 0
    let sumX4 = 0
    let sumY = 0
    let sumXY = 0
    let sumX2Y = 0

    for (let i = 0; i < xValues.length; i++) {
      const x = xValues[i] || 0
      const y = yValues[i] || 0

      count++
      sumX += x
      sumX2 += x * x
      sumX3 += x * x * x
      sumX4 += x * x * x * x
      sumY += y
      sumXY += x * y
      sumX2Y += x * x * y
    }

    const det =
      count * sumX2 * sumX4 -
      count * sumX3 * sumX3 -
      sumX * sumX * sumX4 +
      2 * sumX * sumX2 * sumX3 -
      sumX2 * sumX2 * sumX2
    this.b0 =
      (sumX * sumX2Y * sumX3 -
        sumX * sumX4 * sumXY -
        sumX2 * sumX2 * sumX2Y +
        sumX2 * sumX3 * sumXY +
        sumX2 * sumX4 * sumY -
        sumX3 * sumX3 * sumY) /
      det
    this.b1 =
      (-count * sumX2Y * sumX3 +
        count * sumX4 * sumXY +
        sumX * sumX2 * sumX2Y -
        sumX * sumX4 * sumY -
        sumX2 * sumX2 * sumXY +
        sumX2 * sumX3 * sumY) /
      det
    this.b2 =
      (sumY * sumX * sumX3 -
        sumY * sumX2 * sumX2 -
        sumXY * count * sumX3 +
        sumXY * sumX2 * sumX -
        sumX2Y * sumX * sumX +
        sumX2Y * count * sumX2) /
      det
  }

  predict(x: number): number {
    return this.b0 + x * this.b1 + x * x * this.b2
  }
}
