import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSegmentForSingleCustomerMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  customerId: Types.Scalars['ID'];
}>;


export type CreateSegmentForSingleCustomerMutation = { __typename: 'Mutations', createSegment?: { __typename: 'CreateSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null, customers?: Array<{ __typename: 'CustomerType', id: number, segments?: Array<{ __typename: 'SegmentType', id: number, name: string }> | null }> | null } | null };


export const CreateSegmentForSingleCustomerDocument = gql`
    mutation CreateSegmentForSingleCustomer($name: String!, $customerId: ID!) {
  createSegment(name: $name, customerIds: [$customerId], limit: 1) {
    ok
    segment {
      id
      name
      account {
        id
      }
    }
    customers {
      id
      segments {
        id
        name
      }
    }
  }
}
    `;
export type CreateSegmentForSingleCustomerMutationFn = Apollo.MutationFunction<CreateSegmentForSingleCustomerMutation, CreateSegmentForSingleCustomerMutationVariables>;

/**
 * __useCreateSegmentForSingleCustomerMutation__
 *
 * To run a mutation, you first call `useCreateSegmentForSingleCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentForSingleCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentForSingleCustomerMutation, { data, loading, error }] = useCreateSegmentForSingleCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCreateSegmentForSingleCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSegmentForSingleCustomerMutation, CreateSegmentForSingleCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSegmentForSingleCustomerMutation, CreateSegmentForSingleCustomerMutationVariables>(CreateSegmentForSingleCustomerDocument, options);
      }
export type CreateSegmentForSingleCustomerMutationHookResult = ReturnType<typeof useCreateSegmentForSingleCustomerMutation>;
export type CreateSegmentForSingleCustomerMutationResult = Apollo.MutationResult<CreateSegmentForSingleCustomerMutation>;
export type CreateSegmentForSingleCustomerMutationOptions = Apollo.BaseMutationOptions<CreateSegmentForSingleCustomerMutation, CreateSegmentForSingleCustomerMutationVariables>;