import React from 'react'
import { SingleSelectionOption } from '../lists/ListFilters/types'
import { MenuItem, MenuList, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  list: {
    maxHeight: 300,
    overflowY: 'scroll',
  },
})

interface SingleSelectFilterMenuProps {
  option: SingleSelectionOption
  onSelectValue(newValue: string): void
  className?: string
}

function SingleSelectFilterMenu({ option, onSelectValue, className }: SingleSelectFilterMenuProps): React.ReactElement {
  const classes = useStyles()
  return (
    <MenuList className={className ? className : classes.list}>
      {option.selectionOptions.map(o => (
        <MenuItem value={o.id} key={o.id} onClick={() => onSelectValue(o.id.toString())}>
          {o.label}
        </MenuItem>
      ))}
    </MenuList>
  )
}

export default SingleSelectFilterMenu
