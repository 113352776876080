import { FulfillmentAccountType } from '../gql-global'

export const EMAIL_ACCOUNT_TYPES: FulfillmentAccountType[] = [
  FulfillmentAccountType.Mandrill,
  FulfillmentAccountType.Sendinblue,
  FulfillmentAccountType.Klaviyo,
]

export const INTEGRATIONS_NAME_MAP: Record<FulfillmentAccountType, string> = {
  [FulfillmentAccountType.Mandrill]: 'Mandrill',
  [FulfillmentAccountType.Yotpo]: 'Yotpo',
  [FulfillmentAccountType.Growave]: 'Growave',
  [FulfillmentAccountType.Zaius]: 'Zaius',
  [FulfillmentAccountType.Sendinblue]: 'SendInBlue',
  [FulfillmentAccountType.Webhook]: 'Webhook',
  [FulfillmentAccountType.Klaviyo]: 'Klaviyo',
  [FulfillmentAccountType.Emarsys]: 'Emarsys',
  [FulfillmentAccountType.Braze]: 'Braze',
  [FulfillmentAccountType.LoyaltyLion]: 'Loyalty Lion',
  [FulfillmentAccountType.Smile]: 'Smile',
  [FulfillmentAccountType.Tremendous]: 'Tremendous',
  [FulfillmentAccountType.Ometria]: 'Ometria',
  [FulfillmentAccountType.Bluecore]: 'Bluecore',
}

export const PLATFORM_ITEMS = [
  { name: 'Mandrill', value: FulfillmentAccountType.Mandrill.toString() },
  { name: 'Zaius', value: FulfillmentAccountType.Zaius.toString() },
  { name: 'SendInBlue', value: FulfillmentAccountType.Sendinblue.toString() },
  { name: 'Yotpo', value: FulfillmentAccountType.Yotpo.toString() },
  { name: 'Webhook', value: FulfillmentAccountType.Webhook.toString() },
  { name: 'Growave', value: FulfillmentAccountType.Growave.toString() },
  { name: 'Klaviyo', value: FulfillmentAccountType.Klaviyo.toString() },
  { name: 'Emarsys', value: FulfillmentAccountType.Emarsys.toString() },
  { name: 'Braze', value: FulfillmentAccountType.Braze.toString() },
  { name: 'Loyalty Lion', value: FulfillmentAccountType.LoyaltyLion.toString() },
  { name: 'Smile', value: FulfillmentAccountType.Smile.toString() },
  { name: 'Tremendous', value: FulfillmentAccountType.Tremendous.toString() },
  { name: 'Ometria', value: FulfillmentAccountType.Ometria.toString() },
  { name: 'Bluecore', value: FulfillmentAccountType.Bluecore.toString() },
]

export const TREMENDOUS_OAUTH_BASE = `https://${
  window.location.origin === 'https://app.loudcrowd.com' ? 'www.' : 'testflight.'
}tremendous.com/oauth/authorize?client_id=${
  process.env.REACT_APP_TREMENDOUS_CLIENT_ID
}&response_type=code&scope=default team_management`
