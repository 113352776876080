import { PresignedUrlAndFields } from '../gql-global'

export async function uploadFileToS3(presignedUrl: PresignedUrlAndFields, file: File): Promise<Response> {
  const formData = new FormData()
  presignedUrl.fields.forEach(({ key, value }) => {
    formData.append(key, value)
  })

  // Actual file has to be appended last.
  formData.append('file', file)

  return await fetch(presignedUrl.url, {
    method: 'POST',
    body: formData,
    mode: 'no-cors',
  })
}
