import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Box, BoxProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
    },
  }),
)

const TableRow: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.row}>
      <Box display="flex" py={5} px={7} alignItems="center">
        {children}
      </Box>
    </Paper>
  )
}

export const TableRowCell: React.FC<BoxProps> = ({ children, className, style, ...rest }) => (
  <Box className={className} flex="1" color="secondary.dark" lineHeight={2} style={style} {...rest}>
    {children}
  </Box>
)

export default TableRow
