import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link, Box, Button, createStyles, makeStyles } from '@material-ui/core'
import { ChallengeStatusEnum, CustomerSort, MentionSort } from '../../gql-global'

import { CONTENT_ROUTE } from '../../content/routes'
import { encodeFilterParams as contentEncodeFilterParams } from '../../content/use-filter-params'
import { encodeSortParam as contentEncodeSortParam } from '../../content/use-sort-param'
import { encodeFilterParams as customerEncodeFilterParams } from '../../customer/use-filter-params'
import { encodeSortParam as customerEncodeSortParam } from '../../customer/use-sort-param'
import { CUSTOMER_ROUTE } from '../../customer/routes'

import { ReactComponent as PencilIcon } from '../../icons/edit_pencil.svg'
import { ReactComponent as ParticipantIcon } from '../../icons/people-outlined.svg'
import { ReactComponent as VariantIcon } from '../../icons/variant_major_monotone.svg'
import { ReactComponent as PlayButtonIcon } from '../../icons/play-button.svg'
import { ChallengeFragment } from '../operations/challenge.generated'

const useStyles = makeStyles(() =>
  createStyles({
    ctaButton: {
      borderRadius: 8,
      height: 32,
      fontWeight: 400,
      fontSize: 14,
      width: '100%',
    },
  }),
)

interface ChallengeCardMainProps {
  challenge: ChallengeFragment
  onEdit: (challenge: ChallengeFragment) => void
  onToggle: (challenge: ChallengeFragment) => void
}

function ChallengeCardCallsToAction({ challenge, onEdit, onToggle }: ChallengeCardMainProps): React.ReactElement {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" mt={7}>
      {(challenge.currentStatus === ChallengeStatusEnum.Draft ||
        challenge.currentStatus === ChallengeStatusEnum.Upcoming) && (
        <Box width={245}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onEdit(challenge)}
            className={classes.ctaButton}
            startIcon={<PencilIcon width={16} height={16} />}
          >
            Edit
          </Button>
        </Box>
      )}
      {challenge.currentStatus === ChallengeStatusEnum.Paused && (
        // TODO: implement this action when we can resume a challenge
        <Box width={245}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.ctaButton}
            startIcon={<PlayButtonIcon width={18} height={18} />}
            onClick={() => {
              onToggle(challenge)
            }}
          >
            Resume
          </Button>
        </Box>
      )}
      {(challenge.currentStatus === ChallengeStatusEnum.Completed ||
        challenge.currentStatus === ChallengeStatusEnum.Live) && (
        <>
          <Box width={109}>
            <Link
              variant="subtitle2"
              component={RouterLink}
              to={{
                pathname: CONTENT_ROUTE.path,
                search: `?${contentEncodeFilterParams({
                  challengeIds: { any: [challenge.id] },
                })}&${contentEncodeSortParam(MentionSort.Impressions)}`,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.ctaButton}
                startIcon={<VariantIcon height={16} width={16} />}
              >
                Content
              </Button>
            </Link>
          </Box>
          <Box mr={2} />
          <Box width={130}>
            <Link
              variant="subtitle2"
              component={RouterLink}
              to={{
                pathname: CUSTOMER_ROUTE.path,
                search: `?${customerEncodeFilterParams({
                  challengeIds: { any: [challenge.id] },
                })}&${customerEncodeSortParam(CustomerSort.Impressions)}`,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                className={classes.ctaButton}
                startIcon={<ParticipantIcon width={32} height={32} />}
              >
                Participants
              </Button>
            </Link>
          </Box>
        </>
      )}
    </Box>
  )
}

export default ChallengeCardCallsToAction
