import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReviewProgramQueryVariables = Types.Exact<{
  programId: Types.Scalars['ID'];
}>;


export type ReviewProgramQuery = { __typename: 'Query', program?: { __typename: 'ProgramType', id: string, name: string, hosts?: Array<{ __typename: 'OwnedProgramHost', id: string, url?: string | null }> | null, account?: { __typename: 'AccountType', id: number } | null, contentRules?: { __typename: 'ProgramContentRulesType', id: string, goodExampleUrl?: string | null, badExampleUrl?: string | null, tagLimit?: number | null, tagType?: Types.ProgramRulesTagTypeRuleEnum | null, storiesStatusRule?: Types.ProgramRulesStoriesStatusRuleEnum | null, requiredHashtags?: Array<string> | null, miscRules?: string | null, whenInDoubt?: Types.MentionStatus | null, brandPhoto?: string | null, skipStories: boolean } | null, campaign?: { __typename: 'CampaignType', id: number, startAt: Date, isActive: boolean } | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }> } | null };


export const ReviewProgramDocument = gql`
    query ReviewProgram($programId: ID!) {
  program(id: $programId) {
    id
    name
    hosts {
      id
      url
    }
    account {
      id
    }
    contentRules {
      id
      goodExampleUrl
      badExampleUrl
      tagLimit
      tagType
      storiesStatusRule
      requiredHashtags
      miscRules
      whenInDoubt
      brandPhoto
      skipStories
    }
    campaign {
      id
      startAt
      isActive
    }
    socialAccounts {
      id
      socialUser {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useReviewProgramQuery__
 *
 * To run a query within a React component, call `useReviewProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useReviewProgramQuery(baseOptions: Apollo.QueryHookOptions<ReviewProgramQuery, ReviewProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewProgramQuery, ReviewProgramQueryVariables>(ReviewProgramDocument, options);
      }
export function useReviewProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewProgramQuery, ReviewProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewProgramQuery, ReviewProgramQueryVariables>(ReviewProgramDocument, options);
        }
export type ReviewProgramQueryHookResult = ReturnType<typeof useReviewProgramQuery>;
export type ReviewProgramLazyQueryHookResult = ReturnType<typeof useReviewProgramLazyQuery>;
export type ReviewProgramQueryResult = Apollo.QueryResult<ReviewProgramQuery, ReviewProgramQueryVariables>;