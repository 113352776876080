import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageTemplatesDataQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type MessageTemplatesDataQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, messageTemplates?: { __typename: 'PagedMessageTemplate', results: Array<{ __typename: 'MessageTemplate', id: string, name: string }> } | null } | { __typename: 'TTSocialAccount', id: string } | null };


export const MessageTemplatesDataDocument = gql`
    query MessageTemplatesData($socialAccountId: ID!, $limit: Int) {
  socialAccount(id: $socialAccountId) {
    id
    ... on IGSocialAccount {
      messageTemplates(limit: $limit, sortBy: RECENTLY_USED, sortDirection: DESC) {
        results {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMessageTemplatesDataQuery__
 *
 * To run a query within a React component, call `useMessageTemplatesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplatesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplatesDataQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMessageTemplatesDataQuery(baseOptions: Apollo.QueryHookOptions<MessageTemplatesDataQuery, MessageTemplatesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageTemplatesDataQuery, MessageTemplatesDataQueryVariables>(MessageTemplatesDataDocument, options);
      }
export function useMessageTemplatesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageTemplatesDataQuery, MessageTemplatesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageTemplatesDataQuery, MessageTemplatesDataQueryVariables>(MessageTemplatesDataDocument, options);
        }
export type MessageTemplatesDataQueryHookResult = ReturnType<typeof useMessageTemplatesDataQuery>;
export type MessageTemplatesDataLazyQueryHookResult = ReturnType<typeof useMessageTemplatesDataLazyQuery>;
export type MessageTemplatesDataQueryResult = Apollo.QueryResult<MessageTemplatesDataQuery, MessageTemplatesDataQueryVariables>;