import {
  DateTimeRangeFilter,
  MentionStatus,
  LabelsFilter,
  MediaTypeEnum,
  IgMediaPostType,
  DateInclusionExclusionFilterInput,
  KeywordsFilterInput,
  MessageSendingMethodsFilterInput,
} from '../gql-global'

import {
  useQueryParams,
  encodeQueryParams,
  NumberParam,
  stringify,
  StringParam,
  DecodedValueMap,
  BooleanParam,
} from 'use-query-params'
import { createEnumSetParam } from '../utils/url-param-utils'
import { CAPTION_SEARCH_PARAM_KEY, captionParam } from './use-caption-param'
import { HASHTAG_SEARCH_PARAM_KEY, hashtagParam } from './use-hashtag-param'
import { NumericalRange } from '../components/NumericalRangePicker'
import { MentionTypes, MentionTypesMap } from './constants'
import {
  getDateInclusionExclusionFiltersToParams,
  getDateFilterParamsConfig,
  getDateInclusionExclusionParamsToFilters,
  getDateParamsToFilters,
  cleanAllAnyNoneFilter,
  getDateRangeFilterToParams,
} from '../utils/filter-params'
import { MESSAGE_KEYWORDS_SEARCH_PARAM_KEY } from '../message/use-message-keywords-param'

export interface Filters {
  filterId?: string
  postedAt?: DateTimeRangeFilter
  tagStatus?: Set<MentionStatus>
  challengeMediaApproval?: Set<MentionStatus>
  followerCount?: NumericalRange
  engagementRate?: NumericalRange
  impressions?: NumericalRange
  captionKeywords?: string[]
  hashtagKeywords?: string[]
  labels?: Omit<LabelsFilter, '__typename'>
  segments?: Omit<LabelsFilter, '__typename'>
  campaigns?: Omit<LabelsFilter, '__typename'>
  challengeIds?: Omit<LabelsFilter, '__typename'>
  postCount?: NumericalRange
  mentionType?: Set<MentionTypes>
  campaignId?: string
  mediaType?: Set<MediaTypeEnum>
  postType?: Set<IgMediaPostType>
  unavailableMedia?: boolean
  expiredStories?: boolean
  messageTemplate?: Omit<LabelsFilter, '__typename'>
  messageDate?: Omit<DateInclusionExclusionFilterInput, '__typename'>
  messageKeywords?: KeywordsFilterInput
  messageSendingMethod?: MessageSendingMethodsFilterInput
}

const filterParamConfig = {
  filterId: StringParam,
  [CAPTION_SEARCH_PARAM_KEY]: captionParam,
  [HASHTAG_SEARCH_PARAM_KEY]: hashtagParam,
  ...getDateFilterParamsConfig('postedAt', ''),
  tagStatus: createEnumSetParam(MentionStatus),
  challengeMediaApproval: createEnumSetParam(MentionStatus),
  mentionType: createEnumSetParam(MentionTypesMap),
  followerCountGte: NumberParam,
  followerCountLte: NumberParam,
  engagementRateGte: NumberParam,
  engagementRateLte: NumberParam,
  impressionsGte: NumberParam,
  impressionsLte: NumberParam,
  postCountGte: NumberParam,
  postCountLte: NumberParam,
  labels: StringParam,
  segments: StringParam,
  campaigns: StringParam,
  challengeIds: StringParam,
  mediaType: createEnumSetParam(MediaTypeEnum),
  postType: createEnumSetParam(IgMediaPostType),
  unavailableMedia: BooleanParam,
  expiredStories: BooleanParam,
  messageTemplate: StringParam,
  ...getDateFilterParamsConfig('messageDate', 'exclude'),
  ...getDateFilterParamsConfig('messageDate', 'require'),
  [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: StringParam,
  messageSendingMethod: StringParam,
}

type ContentParams = Partial<DecodedValueMap<typeof filterParamConfig>>

function paramsToFilters(params: ContentParams): Filters {
  const {
    tagStatus,
    challengeMediaApproval,
    followerCountLte,
    followerCountGte,
    engagementRateLte,
    engagementRateGte,
    impressionsGte,
    impressionsLte,
    postCountGte,
    postCountLte,
    labels,
    segments,
    campaigns,
    challengeIds,
    filterId,
    mentionType,
    mediaType,
    postType,
    unavailableMedia,
    expiredStories,
    messageTemplate,
    messageKeywords,
    messageSendingMethod,
  } = params
  const labelsJson = labels ? JSON.parse(labels) : undefined
  const segmentsJson = segments ? JSON.parse(segments) : undefined
  const campaignsJson = campaigns ? JSON.parse(campaigns) : undefined
  const challengeIdsJson = challengeIds ? JSON.parse(challengeIds) : undefined
  const messageTemplateJson = messageTemplate ? JSON.parse(messageTemplate) : undefined
  const messageKeywordsJson = messageKeywords ? JSON.parse(messageKeywords) : undefined

  const messageSendingMethodJson = messageSendingMethod ? JSON.parse(messageSendingMethod) : undefined
  return {
    filterId,
    postedAt: getDateParamsToFilters('postedAt', '', params),
    tagStatus,
    challengeMediaApproval,
    mentionType,
    captionKeywords: params[CAPTION_SEARCH_PARAM_KEY],
    hashtagKeywords: params[HASHTAG_SEARCH_PARAM_KEY],
    followerCount:
      followerCountGte !== undefined || followerCountLte !== undefined
        ? { lte: followerCountLte, gte: followerCountGte }
        : undefined,
    engagementRate:
      engagementRateGte !== undefined || engagementRateLte !== undefined
        ? { lte: engagementRateLte, gte: engagementRateGte }
        : undefined,
    impressions:
      impressionsGte !== undefined || impressionsLte !== undefined
        ? { lte: impressionsLte, gte: impressionsGte }
        : undefined,
    postCount:
      postCountGte !== undefined || postCountLte !== undefined ? { lte: postCountLte, gte: postCountGte } : undefined,
    labels:
      labelsJson && (labelsJson.all || labelsJson.none)
        ? {
            all: labelsJson?.all ? (labelsJson.all as string[][]) : undefined,
            none: labelsJson?.none ? (labelsJson?.none as string[]) : undefined,
          }
        : undefined,
    segments:
      segmentsJson && (segmentsJson.all || segmentsJson.none)
        ? {
            all: segmentsJson?.all ? (segmentsJson.all as string[][]) : undefined,
            none: segmentsJson?.none ? (segmentsJson?.none as string[]) : undefined,
          }
        : undefined,
    campaigns:
      campaignsJson && campaignsJson.any
        ? {
            any: campaignsJson?.any || undefined,
          }
        : undefined,
    challengeIds:
      challengeIdsJson && challengeIdsJson.any
        ? {
            any: challengeIdsJson?.any || undefined,
          }
        : undefined,
    mediaType: mediaType,
    postType: postType,
    unavailableMedia: unavailableMedia,
    expiredStories: expiredStories,
    messageTemplate:
      messageTemplateJson && (messageTemplateJson.all || messageTemplateJson.none)
        ? {
            all: messageTemplateJson?.all ? (messageTemplateJson.all as string[][]) : undefined,
            none: messageTemplateJson?.none ? (messageTemplateJson?.none as string[]) : undefined,
          }
        : undefined,
    messageDate: getDateInclusionExclusionParamsToFilters('messageDate', params),
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: cleanAllAnyNoneFilter(messageKeywordsJson),
    messageSendingMethod: cleanAllAnyNoneFilter(messageSendingMethodJson),
  }
}

function filtersToParams(filters: Filters): ContentParams {
  // The campaignId filter doesn't exist on FE anymore but still need to support
  // existing saved filters that may have campaignId on them
  const filterCampaignId = filters.campaignId
  const filterCampaigns = filters.campaigns?.any
  let campaigns = undefined

  if (filterCampaigns && !filterCampaignId) {
    campaigns = filterCampaigns
  } else if (filterCampaigns && filterCampaignId) {
    campaigns = filterCampaigns.concat([filterCampaignId])
  } else if (!filterCampaigns && filterCampaignId) {
    campaigns = [filterCampaignId]
  }

  const campaignsFilter = campaigns ? { any: campaigns } : undefined
  const challengeIdsFilter = filters.challengeIds?.any ? { any: filters.challengeIds?.any } : undefined
  return {
    ...getDateRangeFilterToParams('postedAt', '', filters.postedAt),
    ...getDateInclusionExclusionFiltersToParams('messageDate', filters.messageDate),
    filterId: filters.filterId,
    tagStatus: filters.tagStatus,
    challengeMediaApproval: filters.challengeMediaApproval,
    [CAPTION_SEARCH_PARAM_KEY]: filters.captionKeywords,
    [HASHTAG_SEARCH_PARAM_KEY]: filters.hashtagKeywords,
    followerCountGte: filters.followerCount?.gte,
    followerCountLte: filters.followerCount?.lte,
    engagementRateGte: filters.engagementRate?.gte,
    engagementRateLte: filters.engagementRate?.lte,
    impressionsGte: filters.impressions?.gte,
    impressionsLte: filters.impressions?.lte,
    postCountGte: filters.postCount?.gte,
    postCountLte: filters.postCount?.lte,
    labels: JSON.stringify(filters.labels),
    segments: JSON.stringify(cleanAllAnyNoneFilter(filters.segments)),
    campaigns: JSON.stringify(campaignsFilter),
    challengeIds: JSON.stringify(challengeIdsFilter),
    mentionType: filters.mentionType,
    mediaType: filters.mediaType,
    postType: filters.postType,
    unavailableMedia: filters.unavailableMedia,
    expiredStories: filters.expiredStories,
    messageTemplate: JSON.stringify(filters.messageTemplate),
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: JSON.stringify(
      cleanAllAnyNoneFilter(filters[MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]),
    ),
    messageSendingMethod: JSON.stringify(cleanAllAnyNoneFilter(filters.messageSendingMethod)),
  }
}

/*
 * encodes content filter parameters into a query string
 * params: optional object of filter parameters
 * { tagStatus: ['UNVERIFIED', 'VERIFIED'] }
 */
export const encodeFilterParams = (filters: Partial<Filters>): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  return {
    filters: paramsToFilters(filterParams),
    setFilters: (f?: Filters) => {
      return setFilterParams(f ? filtersToParams(f) : {}, f ? 'replaceIn' : 'replace')
    },
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
