import React, { useState } from 'react'
import { useFormikContext, Form } from 'formik'
import { makeStyles, Box, Button, Divider, Typography, Checkbox } from '@material-ui/core'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import { secondary } from '../../loudcrowd-theme'
import {
  NumericalRangeOption,
  Options,
  SingleMenuOption,
  ChipsOption,
  KeywordsChipsOption,
} from '../../components/lists/ListFilters/types'
import TextFilters from '../../components/lists/ListFilters/TextFilters'
import { useMessageAutomationDataQuery } from '../operations/query-message-automation-data.generated'
import { useMessageAutomationUserDataQuery } from '../operations/query-messaage-automation-user-data.generated'
import { NumericalFilter } from '../../components/lists/ListFilters/NumericalFilter'
import { SkipMessageTemplateActionEnum } from '../../gql-global'
import { AutomationFormFields, defaultFilterFields, hasFilters } from './automationSchema'

interface AutomationBuilderProps {
  onBack(): void
}

const useStyles = makeStyles(theme => ({
  builderText: {
    fontSize: '1.25rem',
    lineHeight: '2.5rem',
    wordBreak: 'break-word',
  },
  grayText: {
    color: secondary[500],
  },
  divider: {
    marginTop: theme.spacing(5),
  },
}))

type CustomerTypeFilter = {
  type: 'anyone' | 'specific'
}

type SkipSendMessageFilterType = {
  type: SkipMessageTemplateActionEnum.SameTemplate | SkipMessageTemplateActionEnum.AnyTemplate
}

type SkipActionTimeBufferType = {
  type: 'last24hours' | 'last7days' | 'last30days' | 'ever'
}

export const skipSendMessageSelectionOptions = [
  {
    label: 'this message',
    id: SkipMessageTemplateActionEnum.SameTemplate,
  },
  {
    label: 'any automated message',
    id: SkipMessageTemplateActionEnum.AnyTemplate,
  },
]

export const skipCustomerMessageTimeFrameSelectionOptions = [
  {
    label: 'last 24 hours',
    id: 'last24hours',
  },
  {
    label: 'last 7 days',
    id: 'last7days',
  },
  {
    label: 'last 30 days',
    id: 'last30days',
  },
  {
    label: 'ever',
    id: 'ever',
  },
]

function AutomationBuilder({ onBack }: AutomationBuilderProps): React.ReactElement {
  const classes = useStyles()
  const { data } = useMessageAutomationDataQuery()
  const socialAccountId = data?.whoami?.preferences.selectedSocialAccountId
  const { data: userData } = useMessageAutomationUserDataQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
    },
  })
  const { values: formFields, setValues: setFields, setFieldValue } = useFormikContext<AutomationFormFields>()
  const isEmpty = !hasFilters(formFields)
  const [selectedCustomerType, setSelectedCustomerType] = useState<{
    type: 'anyone' | 'specific'
  }>(
    isEmpty
      ? {
          type: 'anyone',
        }
      : {
          type: 'specific',
        },
  )

  const accountCampaigns =
    (userData?.socialAccount?.campaigns &&
      (userData?.socialAccount?.campaigns).map(c => {
        return { id: c.id, name: c.program?.name || '' }
      })) ||
    []

  const customerTypeOptions = [
    {
      name: 'type',
      type: 'singleMenu',
      label: 'Customer Type',
      selectionOptions: [
        {
          label: 'anyone',
          id: 'anyone',
        },
        {
          label: 'specific customers',
          id: 'specific',
        },
      ],
      displayVariant: 'inline',
    } as SingleMenuOption,
  ]

  const skipSendMessageOptions = [
    {
      name: 'type',
      type: 'singleMenu',
      label: 'Message type',
      selectionOptions: skipSendMessageSelectionOptions,
      displayVariant: 'inline',
    } as SingleMenuOption,
  ]

  const skipCustomerMessageTimeFrame = [
    {
      name: 'type',
      type: 'singleMenu',
      label: 'Message type',
      selectionOptions: skipCustomerMessageTimeFrameSelectionOptions,
      displayVariant: 'inline',
    } as SingleMenuOption,
  ]

  const criteriaOptions: Options =
    selectedCustomerType.type === 'anyone'
      ? []
      : [
          {
            name: 'followerCount',
            type: 'numericalRange',
            label: 'Follower Count',
            min: 0,
            max: 1_000_000_000,
            clearable: true,
            displayVariant: 'inline',
            presets: [
              { lte: 1000 },
              { gte: 1000, lte: 10_000 },
              { gte: 10_000, lte: 1_000_000 },
              { gte: 100_000, lte: 10_000_000 },
              { gte: 1_000_000 },
            ],
          } as NumericalRangeOption,
          {
            name: 'segments',
            type: 'chips',
            label: 'Segments',
            entity: 'segment',
            isContainer: true,
            selectionOptions: data?.whoami?.account?.segments?.results || [],
            displayVariant: 'inline',
          } as ChipsOption,
          {
            name: 'campaigns',
            type: 'chips',
            label: 'Programs',
            entity: 'program',
            isContainer: true,
            selectionOptions: accountCampaigns,
            displayVariant: 'inline',
          } as ChipsOption,
          {
            name: 'biography',
            type: 'keywordsChips',
            label: 'Creator Bio',
            displayVariant: 'inline',
            textLabel: 'bio',
          } as KeywordsChipsOption,
        ]

  const handleSelectCustomerType = (optionCustomerType: CustomerTypeFilter): void => {
    setSelectedCustomerType(optionCustomerType)
    if (optionCustomerType.type === 'anyone') {
      setFields({
        ...formFields,
        ...defaultFilterFields,
        who: 'anyone',
        customerTriggerQuantity: 1,
      })
    } else {
      setFields({
        ...formFields,
        who: 'specificCustomers',
      })
    }
  }

  const handleSkipCustomers = (): void => {
    setFields({
      ...formFields,
      skipCustomers: !formFields.skipCustomers,
    })
  }
  const handleSkipSendMessageFilter = (skipSendMessageFilter: SkipSendMessageFilterType): void => {
    setFields({
      ...formFields,
      skipSendMessage: skipSendMessageFilter.type,
    })
  }

  const handleSkipActionTimeBuffer = (skipActionTimeBuffer: SkipActionTimeBufferType): void => {
    setFields({
      ...formFields,
      skipActionTimeBuffer: skipActionTimeBuffer.type,
    })
  }

  const disableNextButton = isEmpty && selectedCustomerType.type === 'specific'

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1}>
      <Form noValidate>
        <Box maxWidth={560}>
          <Box fontSize="1.5rem" lineHeight={1.5} mb={12}>
            Who will get this message?
          </Box>
          <Box className={classes.builderText}>
            Send a DM reply to{' '}
            <TextFilters
              onChangeFilters={handleSelectCustomerType}
              options={customerTypeOptions}
              filters={selectedCustomerType}
            />{' '}
            {selectedCustomerType.type === 'specific' && 'who '}
            <TextFilters onChangeFilters={setFields} options={criteriaOptions} filters={formFields} />
            whenever they mention @{userData?.socialAccount?.socialUser?.username} in{' '}
            <NumericalFilter
              onSelectValue={value => setFieldValue('customerTriggerQuantity', value, false)}
              value={formFields.customerTriggerQuantity}
              label="Story Count"
              min={1}
              defaultValue={1}
              onDelete={() => setFieldValue('customerTriggerQuantity', 1, false)}
              subLabel="stories"
            />{' '}
            instagram {formFields.customerTriggerQuantity === 1 ? 'story' : 'stories'}
          </Box>
          <Divider className={classes.divider} />
          <Box display="flex" paddingTop={3}>
            <Box display="flex">
              <Checkbox
                checked={formFields.skipCustomers}
                onClick={handleSkipCustomers}
                style={{ display: 'flex', alignItems: 'baseline' }}
              />
            </Box>
            <Box paddingTop={1}>
              <Typography variant="body2">
                Skip customers who have already received{' '}
                <TextFilters
                  options={skipSendMessageOptions}
                  filters={{ type: formFields.skipSendMessage }}
                  onChangeFilters={handleSkipSendMessageFilter}
                />
                {formFields.skipActionTimeBuffer !== 'ever' && 'in the '}
                <TextFilters
                  options={skipCustomerMessageTimeFrame}
                  filters={{ type: formFields.skipActionTimeBuffer }}
                  onChangeFilters={handleSkipActionTimeBuffer}
                />
              </Typography>
              <Typography variant="body2" style={{ color: secondary[500] }}>
                Prevents people from getting too many messages and rewards
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button color="primary" startIcon={<ArrowIcon transform="rotate(90)" />} onClick={onBack}>
              Back
            </Button>
            <Button color="primary" type="submit" variant="contained" disabled={disableNextButton}>
              Next
            </Button>
          </Box>
        </Box>
      </Form>
    </Box>
  )
}

export default AutomationBuilder
