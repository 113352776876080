import React from 'react'

export const UpsellModalStateContext = React.createContext<ModalStateProps>({
  isOpen: false,
})
export const UpsellModalUpdaterContext = React.createContext<(props: ModalStateProps) => void>(() => null)

export type Limits = 'USERS' | 'SEGMENTS' | 'LABELS' | 'POSTS' | 'SOCIAL_ACCOUNTS'
export type ModalContext =
  | { reason: 'LIMIT'; limit: Limits }
  | { reason: 'BILLING' }
  | { reason: 'USER_TRIAL' }
  | { reason: 'CAMPAIGNS' }
  | { reason: 'MESSAGING_STORY_REPLIES' }

export interface ModalStateProps {
  isOpen: boolean
  modalProps?: {
    context?: ModalContext
    onCancel?(e: Object, context?: ModalContext): void
  }
}
