import React from 'react'
import { IntegrationType } from '../../gql-global'

import { ReactComponent as BrazeLogo } from '../../icons/integration/braze.svg'
import { ReactComponent as EmarsysLogo } from '../../icons/integration/emarsys.svg'
import { ReactComponent as GrowaveLogo } from '../../icons/integration/growwave.svg'
import { ReactComponent as KlaviyoLogo } from '../../icons/integration/klaviyo.svg'
import { ReactComponent as LoyaltyLionLogo } from '../../icons/integration/loyalty-lion.svg'
import { ReactComponent as MandrillLogo } from '../../icons/integration/mandrill.svg'
import { ReactComponent as OptimizelyLogo } from '../../icons/integration/optimizely.svg'
import { ReactComponent as SendInBlueLogo } from '../../icons/integration/send-in-blue.svg'
import { ReactComponent as ShopifyLogo } from '../../icons/integration/shopify.svg'
import { ReactComponent as SmileLogo } from '../../icons/integration/smile.svg'
import { ReactComponent as TremendousLogo } from '../../icons/integration/tremendous.svg'
import { ReactComponent as YotpoLogo } from '../../icons/integration/yotpo.svg'
import { ReactComponent as LoudCrowdLogo } from '../../icons/integration/loudcrowd.svg'
import { ReactComponent as OmetriaLogo } from '../../icons/integration/ometria.svg'
import { ReactComponent as BluecoreLogo } from '../../icons/integration/bluecore.svg'

interface IntegrationLogoProps {
  integrationType: IntegrationType
  className: string
}

function IntegrationLogo(props: IntegrationLogoProps): React.ReactElement {
  const { integrationType, ...rest } = props
  switch (props.integrationType) {
    case IntegrationType.Braze:
      return <BrazeLogo {...rest} />
    case IntegrationType.Emarsys:
      return <EmarsysLogo {...rest} />
    case IntegrationType.Growave:
      return <GrowaveLogo {...rest} />
    case IntegrationType.Klaviyo:
      return <KlaviyoLogo {...rest} />
    case IntegrationType.LoyaltyLion:
      return <LoyaltyLionLogo {...rest} />
    case IntegrationType.Mandrill:
      return <MandrillLogo {...rest} />
    case IntegrationType.Zaius:
      return <OptimizelyLogo {...rest} />
    case IntegrationType.Sendinblue:
      return <SendInBlueLogo {...rest} />
    case IntegrationType.Shopify:
      return <ShopifyLogo {...rest} />
    case IntegrationType.Smile:
      return <SmileLogo {...rest} />
    case IntegrationType.Tremendous:
      return <TremendousLogo {...rest} />
    case IntegrationType.Yotpo:
      return <YotpoLogo {...rest} />
    case IntegrationType.Webhook:
      return <LoudCrowdLogo {...rest} />
    case IntegrationType.Ometria:
      return <OmetriaLogo {...rest} />
    case IntegrationType.Bluecore:
      return <BluecoreLogo {...rest} />
    default:
      return <React.Fragment />
  }
}

export default IntegrationLogo
