import React, { useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { MenuItem, Box, Divider, Button, Typography } from '@material-ui/core'
import { dateRangeLabel } from '../../utils/date-range-helper'
import { DateRangeFilter, DateRangeFilterType, DateTimeRangeFilter } from '../../gql-global'
import { BAKED_FILTERS, CUSTOM_BAKED_FILTERS, INACTIVE_SINCE_FILTERS, BAKED_FILTERS_WITH_HOURS } from './baked-filters'
import MenuHeader from '../MenuHeader'
import AbsoluteDateRangePicker, { DateRangeModel } from './AbsoluteDateRangePicker'
import { startOfDay, addDays, subDays, format } from 'date-fns'
import { createTypeNamePredicate } from '../../types/utility'
import { DateTextField } from '../DateTextField/DateTextField'

const isAbsDateTimeRange = createTypeNamePredicate('AbsoluteDateTimeRangeFilter')
const isAbsDateRange = createTypeNamePredicate('AbsoluteDateRangeFilter')
const defaultDate = new Date(1900, 1, 1)

export interface DateRangePickerMenuFlowProps {
  onChangeDateRange(newDateRange: DateRangeFilter | DateTimeRangeFilter | null): void
  dateRange: DateRangeFilter | DateTimeRangeFilter | null
  title?: string
  onQuit?(): void
  includeAllTime?: boolean
  // inactiveFilter is custom for customer's that are inactive for filter
  entity?: 'inactiveFilter' | 'normal' | 'custom' | 'normalPlusHours'
}

type MenuFlowState = 'menu' | 'between' | 'after' | 'before'
function defaultSelectionState(dateRange: DateRangeFilter | DateTimeRangeFilter | null): MenuFlowState {
  if (dateRange && (isAbsDateTimeRange(dateRange) || isAbsDateRange(dateRange))) {
    return dateRange?.lt?.getTime()
      ? dateRange?.gte?.getTime() !== defaultDate.getTime()
        ? 'between'
        : 'before'
      : 'after'
  }
  return 'menu'
}

function defaultAbsoluteDateRangeValue(
  dateRange: DateRangeFilter | DateTimeRangeFilter | null,
  flowState: MenuFlowState,
): DateRangeModel {
  if (dateRange && (isAbsDateTimeRange(dateRange) || isAbsDateRange(dateRange))) {
    const isDefaultGteDate = dateRange.gte.getTime() === defaultDate.getTime()
    if (flowState === 'between') {
      return {
        gte: isDefaultGteDate ? subDays(dateRange?.lt || new Date(), 1) : dateRange.gte,
        lt: dateRange?.lt,
      }
    } else if (flowState === 'before') {
      return {
        gte: null,
        lt: isDefaultGteDate ? dateRange?.lt : dateRange?.gte,
      }
    } else if (flowState === 'after') {
      return {
        gte: isDefaultGteDate ? dateRange?.lt : dateRange?.gte,
        lt: null,
      }
    }
  }
  return {}
}

function isAbsDateRangeValid<T extends 'between' | 'after' | 'before'>(
  d: DateRangeModel,
  s: T,
): d is T extends 'between' ? { gte: Date; lt: Date } : { gte: Date; lt?: null } {
  return s === 'after' ? !!d.gte : s === 'between' ? !!d.gte && !!d.lt && d.gte < d.lt : !!d.lt
}

const DateRangePickerMenuFlow: React.FC<DateRangePickerMenuFlowProps> = ({
  onChangeDateRange,
  dateRange,
  title,
  onQuit,
  entity = 'normal',
  includeAllTime = false,
}) => {
  const [selectionState, setSelectionState] = useState<MenuFlowState>(defaultSelectionState(dateRange))
  const [currentCustomDateField, setCurrentCustomDateField] = useState<'AFTER_DATE' | 'BEFORE_DATE' | null>()
  const [absoluteDateRange, setAbsoluteDateRange] = useState<DateRangeModel>(
    defaultAbsoluteDateRangeValue(dateRange, selectionState),
  )

  // handle date text field change
  const handleDateTextFieldChange = (field: 'gte' | 'lt') => (date?: Date) => {
    if (date) {
      setAbsoluteDateRange({
        ...absoluteDateRange,
        [field]: field === 'gte' ? addDays(date, 1) : date,
      })
    }
  }

  function handleApply(): void {
    if (selectionState === 'menu') return
    if (isAbsDateRangeValid(absoluteDateRange, selectionState)) {
      onChangeDateRange({
        __typename: entity === 'custom' ? 'AbsoluteDateRangeFilter' : 'AbsoluteDateTimeRangeFilter',
        rangeType: DateRangeFilterType.Absolute,
        gte: absoluteDateRange.gte ? startOfDay(absoluteDateRange.gte) : defaultDate,
        lt: absoluteDateRange.lt ? startOfDay(absoluteDateRange.lt) : absoluteDateRange.lt,
      })
    }
  }

  let fixedFilters = BAKED_FILTERS
  if (entity === 'inactiveFilter') {
    fixedFilters = INACTIVE_SINCE_FILTERS
  } else if (entity === 'custom') {
    fixedFilters = CUSTOM_BAKED_FILTERS
  } else if (entity === 'normalPlusHours') {
    fixedFilters = BAKED_FILTERS_WITH_HOURS
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') handleApply()
  }

  switch (selectionState) {
    case 'menu':
      return (
        <>
          {title && <MenuHeader title={title} onClickBack={onQuit} />}
          {fixedFilters.map((m, i) => (
            <MenuItem key={i} onClick={() => onChangeDateRange(m)}>
              {dateRangeLabel(m, entity === 'inactiveFilter')}
            </MenuItem>
          ))}
          <MenuItem onClick={() => setSelectionState('after')}>
            {entity === 'normal' || entity === 'normalPlusHours' || entity === 'custom' ? 'After t' : 'T'}his date
          </MenuItem>
          {entity === 'custom' && <MenuItem onClick={() => setSelectionState('before')}>Before this Date</MenuItem>}
          {(entity === 'normal' || entity === 'normalPlusHours' || entity === 'custom') && (
            <MenuItem onClick={() => setSelectionState('between')}>Between</MenuItem>
          )}
          {includeAllTime && <MenuItem onClick={() => onChangeDateRange(null)}>All Time</MenuItem>}
        </>
      )
    case 'after':
      const afterViewDate = absoluteDateRange.gte ? subDays(absoluteDateRange.gte, 1) : null
      return (
        <div>
          <MenuHeader
            title={`${title ? `${title}  ` : ''}${
              entity === 'normal' || entity === 'normalPlusHours' || entity === 'custom' ? 'After t' : 'T'
            }his date`}
            onClickBack={() => setSelectionState('menu')}
          />
          <Box display="flex" flexDirection="row" mr={5} ml={5} mt={4} justifyContent="center" alignItems="center">
            <Box mx={3}>
              <Typography variant="caption">AFTER</Typography>
            </Box>
            <DateTextField
              id="after-date-input"
              autoFocus
              value={absoluteDateRange.gte ? format(subDays(absoluteDateRange.gte, 1), 'MMM d, yyyy') : ''}
              onFocus={() => setCurrentCustomDateField('AFTER_DATE')}
              selected={currentCustomDateField === 'AFTER_DATE'}
              onChange={handleDateTextFieldChange('gte')}
              onKeyDown={handleOnKeyDown}
            />
          </Box>
          <DatePicker
            initialFocusedDate={afterViewDate}
            onChange={date => date && setAbsoluteDateRange({ gte: addDays(date, 1) })}
            variant="static"
            format="MM/dd/yyyy"
            margin="normal"
            disableFuture
            value={afterViewDate}
            disableToolbar
          />
          <Box mt={2}>
            <Divider />
            <Box px={5} pt={2} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={handleApply}
                disabled={!isAbsDateRangeValid(absoluteDateRange, selectionState)}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </div>
      )
    case 'between':
      return (
        <div>
          <MenuHeader title={`${title ? `${title} ` : ''}Between`} onClickBack={() => setSelectionState('menu')} />
          <AbsoluteDateRangePicker onChange={setAbsoluteDateRange} dateRange={absoluteDateRange} />
          <Box mt={2}>
            <Divider />
            <Box px={5} pt={2} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={handleApply}
                disabled={!isAbsDateRangeValid(absoluteDateRange, selectionState)}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </div>
      )
    case 'before':
      const beforeViewDate = absoluteDateRange.lt ? absoluteDateRange.lt : null
      return (
        <div>
          <MenuHeader
            title={`${title ? `${title}  ` : ''}${
              entity === 'normal' || entity === 'normalPlusHours' || entity === 'custom' ? 'Before t' : 'T'
            }his date`}
            onClickBack={() => setSelectionState('menu')}
          />
          <Box display="flex" flexDirection="row" mr={5} ml={5} mt={4} justifyContent="center" alignItems="center">
            <Box mx={3}>
              <Typography variant="caption">BEFORE</Typography>
            </Box>
            <DateTextField
              id="before-date-input"
              autoFocus
              value={absoluteDateRange.lt ? format(absoluteDateRange.lt, 'MMM d, yyyy') : ''}
              onFocus={() => setCurrentCustomDateField('BEFORE_DATE')}
              selected={currentCustomDateField === 'BEFORE_DATE'}
              onChange={handleDateTextFieldChange('lt')}
              onKeyDown={handleOnKeyDown}
            />
          </Box>
          <DatePicker
            initialFocusedDate={beforeViewDate}
            onChange={date => date && setAbsoluteDateRange({ lt: date })}
            variant="static"
            format="MM/dd/yyyy"
            margin="normal"
            disableFuture
            value={beforeViewDate}
            disableToolbar
          />
          <Box mt={2}>
            <Divider />
            <Box px={5} pt={2} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={handleApply}
                disabled={!isAbsDateRangeValid(absoluteDateRange, selectionState)}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </div>
      )
  }
}

export default DateRangePickerMenuFlow
