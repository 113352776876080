import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { CustomerDetailsFragment } from './operations/customer-details.generated'
import LabelChip from '../components/labelChip/LabelChip'
import { ReactComponent as ConfettiIcon } from '../icons/confetti_major_monotone.svg'
import { ReactComponent as DollarSignIcon } from '../icons/dollar-sign.svg'
import { ReactComponent as ZigZagIcon } from '../icons/zig-zag.svg'
import { ReactComponent as CrossIcon } from '../icons/cross.svg'
import { ProgramTypeEnum } from '../gql-global'
import { useRemoveParticipantMutation } from '../campaign/campaign-detail/operations/remove-participant.generated'
import { CustomerQueryVariables } from './operations/customer.generated'
import { useToast } from '../components/Alert/ToastProvider'
import { CustomerRefetchType } from './CustomerDetail'

type ProgramParticipantType = NonNullable<CustomerDetailsFragment['programParticipants']>[0]

interface CustomerDetailProgramsProps {
  customer: CustomerDetailsFragment | null | undefined
  username: string | undefined
  customerQueryVariables: CustomerQueryVariables
  customerRefetch: CustomerRefetchType
}
const useCustomerDetailProgramsStyles = makeStyles(() =>
  createStyles({
    customerDetailProgramsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 10,
    },
  }),
)
const CustomerDetailPrograms = ({ customer, username, customerRefetch }: CustomerDetailProgramsProps): JSX.Element => {
  const styles = useCustomerDetailProgramsStyles()
  const [programDialogState, setProgramDialogState] = useState<{
    open: boolean
    programParticipant: ProgramParticipantType | null
  }>({
    open: false,
    programParticipant: null,
  })

  const openProgramDialog = (programParticipant: ProgramParticipantType) => {
    setProgramDialogState({ open: true, programParticipant })
  }

  return (
    <>
      <Box className={styles.customerDetailProgramsContainer}>
        {customer?.programParticipants?.map(programParticipant => (
          <Box mt={1} mr={1} key={programParticipant.id}>
            <ProgramLabelChip programParticipant={programParticipant} openProgramDialog={openProgramDialog} />
          </Box>
        ))}
      </Box>
      <CustomerDetailProgramDialog
        programParticipant={programDialogState.programParticipant}
        username={username}
        customerRefetch={customerRefetch}
        open={programDialogState.open}
        onClose={() => {
          setProgramDialogState({ open: false, programParticipant: null })
        }}
      />
    </>
  )
}

interface ProgramLabelChipProps {
  programParticipant: CustomerDetailsFragment['programParticipants'][0]
  openProgramDialog: (programParticipant: ProgramParticipantType) => void
}
const useProgramLabelChipStyles = makeStyles((theme: Theme) =>
  createStyles({
    allCustomerIcon: {
      color: theme.palette.purple.dark,
      marginLeft: 3,
    },
    allCustomerLabel: {
      backgroundColor: theme.palette.purple.light,
      color: theme.palette.purple.dark,
    },
    ambassadorIcon: {
      color: theme.palette.success.dark,
      marginLeft: 3,
    },
    ambassadorLabel: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.dark,
    },
    shoppableAmbassadorIcon: {
      color: '#204F94',
      marginLeft: 3,
    },
    shoppableAmbassadorLabel: {
      backgroundColor: '#E7F2FF',
      color: '#204F94',
    },
  }),
)
const ProgramLabelChip = ({ programParticipant, openProgramDialog }: ProgramLabelChipProps): JSX.Element => {
  const styles = useProgramLabelChipStyles()

  switch (programParticipant.program.programType) {
    case ProgramTypeEnum.AllCustomer:
      return (
        <LabelChip
          labelId={programParticipant.id}
          label={<ProgramLabelValue programParticipant={programParticipant} onRemoveClick={openProgramDialog} />}
          icon={<ConfettiIcon width={12} height={12} className={styles.allCustomerIcon} />}
          className={styles.allCustomerLabel}
        />
      )
    case ProgramTypeEnum.Ambassador:
      return (
        <LabelChip
          labelId={programParticipant.id}
          label={<ProgramLabelValue programParticipant={programParticipant} onRemoveClick={openProgramDialog} />}
          icon={<ConfettiIcon width={12} height={12} className={styles.ambassadorIcon} />}
          className={styles.ambassadorLabel}
        />
      )
    case ProgramTypeEnum.ShoppableAmbassador:
      return (
        <LabelChip
          labelId={programParticipant.id}
          label={<ProgramLabelValue programParticipant={programParticipant} onRemoveClick={openProgramDialog} />}
          icon={<DollarSignIcon width={12} height={12} className={styles.shoppableAmbassadorIcon} />}
          className={styles.shoppableAmbassadorLabel}
        />
      )
    default:
      return (
        <LabelChip
          labelId={programParticipant.id}
          label={<ProgramLabelValue programParticipant={programParticipant} onRemoveClick={openProgramDialog} />}
          icon={
            <Box
              width={16}
              height={16}
              bgcolor="primary.main"
              borderRadius={8}
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <ZigZagIcon color="white" width={12} height={12} />
            </Box>
          }
        />
      )
  }
}

interface ProgramLabelValueProps {
  programParticipant: ProgramParticipantType
  onRemoveClick: (programParticipant: ProgramParticipantType) => void
}
const useProgramLabelValueStyles = makeStyles(() =>
  createStyles({
    labelValueContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    crossIcon: {
      marginLeft: '10px',
      cursor: 'pointer',
    },
  }),
)
const ProgramLabelValue = ({ programParticipant, onRemoveClick }: ProgramLabelValueProps): JSX.Element => {
  const styles = useProgramLabelValueStyles()
  return (
    <Box className={styles.labelValueContainer}>
      {programParticipant.program.name}
      <Box className={styles.crossIcon}>
        <CrossIcon
          onClick={() => {
            onRemoveClick(programParticipant)
          }}
          width={8}
          height={8}
        />
      </Box>
    </Box>
  )
}

interface CustomerDetailProgramDialogProps {
  open: boolean
  onClose: () => void
  programParticipant: ProgramParticipantType | null
  username: string | undefined
  customerRefetch: CustomerRefetchType
}
const CustomerDetailProgramDialog = ({
  open,
  onClose,
  programParticipant,
  username,
  customerRefetch,
}: CustomerDetailProgramDialogProps): JSX.Element => {
  const { showToast } = useToast()
  const [removeParticipant] = useRemoveParticipantMutation({
    onCompleted: () => customerRefetch(),
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove from program?</DialogTitle>
      <DialogContent style={{ width: 400, marginBottom: '20px' }}>
        <span>
          Are you sure you want to remove {username} from <b>{programParticipant?.program?.name}</b>
        </span>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={() => {
            if (programParticipant?.id) {
              removeParticipant({ variables: { participantId: programParticipant.id.toString() } })
                .then(() => {
                  showToast({
                    title: 'Success: Removed from program',
                    message: `Removed member ${username} from ${programParticipant.program.name}`,
                    severity: 'success',
                    autoHideDuration: 5000,
                  })
                })
                .catch(() => {
                  showToast({
                    title: 'Error: Failed to remove from program',
                    message: `Failed to remove member ${username} from ${programParticipant.program.name}`,
                    severity: 'error',
                    autoHideDuration: 5000,
                  })
                })
            }
            onClose()
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomerDetailPrograms
