import React, { useState } from 'react'
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Box,
  Divider,
  Button,
  makeStyles,
  List,
} from '@material-ui/core'

interface SelectionListProps<T> {
  options: readonly { id: T; label: string }[]
  selectedOptions?: ReadonlySet<T>
  toggleReset?: boolean
  onChangeOptions(newOptions: ReadonlySet<T>): void
  onReset?(): Set<T>
  isInvalid?: (value: ReadonlySet<T>) => boolean
  selectionRequired?: boolean
  styles?: React.CSSProperties
}

const useStyles = makeStyles({
  optionText: {
    margin: 0,
  },
  option: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: '40px',
    cursor: 'pointer',
  },
  optionIcon: {
    minWidth: 0,
  },
  container: { minWidth: 225 },
  list: {
    maxHeight: 500,
    overflowY: 'auto',
  },
})

function toggleSetValue<T>(set: ReadonlySet<T>, value: T): Set<T> {
  const newSet = new Set(set)
  if (set.has(value)) {
    newSet.delete(value)
  } else {
    newSet.add(value)
  }
  return newSet
}

export function SelectionList<T extends { toString(): string }>({
  options,
  selectedOptions = new Set(),
  toggleReset,
  onChangeOptions,
  onReset,
  isInvalid,
  selectionRequired,
  styles,
}: SelectionListProps<T>): React.ReactElement {
  const classes = useStyles()
  const [selectedValues, setSelectedValues] = useState(selectedOptions)

  function handleOptionSelected(optionId: T): void {
    const newSet = toggleSetValue(selectedValues, optionId)
    setSelectedValues(newSet)
  }

  function handleApply(): void {
    onChangeOptions(selectedValues)
  }

  function handleOnReset(): void {
    if (!onReset) return
    const resettedSelectionValues = onReset()
    setSelectedValues(resettedSelectionValues)
  }

  const disableApply = (selectionRequired && !selectedValues.size) || (!!isInvalid && isInvalid(selectedValues))

  return (
    <Box mt={2} className={classes.container} style={styles}>
      <List className={classes.list}>
        {options.map(o => (
          <ListItem key={o.id.toString()} className={classes.option} onClick={() => handleOptionSelected(o.id)}>
            <ListItemIcon className={classes.optionIcon}>
              <Checkbox size="small" edge="start" checked={selectedValues.has(o.id)} tabIndex={-1} />
            </ListItemIcon>
            <ListItemText classes={{ root: classes.optionText }} primary={o.label} />
          </ListItem>
        ))}
      </List>
      <Box mt={2}>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent={onReset ? 'space-between' : 'flex-end'}
          flex="0 0 auto"
          p={2}
        >
          {onReset && (
            <Button color="secondary" onClick={handleOnReset}>
              {toggleReset ? `${Array.from(selectedValues).length ? 'Unselect' : 'Select'} All` : 'Reset'}
            </Button>
          )}
          <Button color="primary" variant="contained" onClick={handleApply} disabled={disableApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
