import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelectedMentionTypeFilterMutationVariables = Types.Exact<{
  selectedMentionTypeFilter?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type UpdateSelectedMentionTypeFilterMutation = { __typename: 'Mutations', updateSelectedMentionTypeFilter?: Array<string | null> | null };


export const UpdateSelectedMentionTypeFilterDocument = gql`
    mutation UpdateSelectedMentionTypeFilter($selectedMentionTypeFilter: [String!]) {
  updateSelectedMentionTypeFilter(
    selectedMentionTypeFilter: $selectedMentionTypeFilter
  ) @client
}
    `;
export type UpdateSelectedMentionTypeFilterMutationFn = Apollo.MutationFunction<UpdateSelectedMentionTypeFilterMutation, UpdateSelectedMentionTypeFilterMutationVariables>;

/**
 * __useUpdateSelectedMentionTypeFilterMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedMentionTypeFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedMentionTypeFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedMentionTypeFilterMutation, { data, loading, error }] = useUpdateSelectedMentionTypeFilterMutation({
 *   variables: {
 *      selectedMentionTypeFilter: // value for 'selectedMentionTypeFilter'
 *   },
 * });
 */
export function useUpdateSelectedMentionTypeFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectedMentionTypeFilterMutation, UpdateSelectedMentionTypeFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectedMentionTypeFilterMutation, UpdateSelectedMentionTypeFilterMutationVariables>(UpdateSelectedMentionTypeFilterDocument, options);
      }
export type UpdateSelectedMentionTypeFilterMutationHookResult = ReturnType<typeof useUpdateSelectedMentionTypeFilterMutation>;
export type UpdateSelectedMentionTypeFilterMutationResult = Apollo.MutationResult<UpdateSelectedMentionTypeFilterMutation>;
export type UpdateSelectedMentionTypeFilterMutationOptions = Apollo.BaseMutationOptions<UpdateSelectedMentionTypeFilterMutation, UpdateSelectedMentionTypeFilterMutationVariables>;