import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTikTokSocialAccountMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  ttSecUid: Types.Scalars['String'];
  username: Types.Scalars['String'];
  followerCount: Types.Scalars['Int'];
  imageUrl: Types.Scalars['String'];
}>;


export type UpdateTikTokSocialAccountMutation = { __typename: 'Mutations', updateTikTokSocialAccount?: { __typename: 'UpdateTikTokSocialAccount', ok: boolean, ttSocialAccount?: { __typename: 'TTSocialAccount', id: string, platformId?: string | null, expiresAt?: Date | null, lastPull?: Date | null, socialUser: { __typename: 'TTUser', id: string, ttSecUid?: string | null, username: string } } | null } | null };


export const UpdateTikTokSocialAccountDocument = gql`
    mutation UpdateTikTokSocialAccount($accessToken: String!, $ttSecUid: String!, $username: String!, $followerCount: Int!, $imageUrl: String!) {
  updateTikTokSocialAccount(
    accessToken: $accessToken
    ttSecUid: $ttSecUid
    username: $username
    followerCount: $followerCount
    imageUrl: $imageUrl
  ) {
    ok
    ttSocialAccount {
      id
      platformId
      expiresAt
      lastPull
      socialUser {
        id
        ttSecUid
        username
      }
    }
  }
}
    `;
export type UpdateTikTokSocialAccountMutationFn = Apollo.MutationFunction<UpdateTikTokSocialAccountMutation, UpdateTikTokSocialAccountMutationVariables>;

/**
 * __useUpdateTikTokSocialAccountMutation__
 *
 * To run a mutation, you first call `useUpdateTikTokSocialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTikTokSocialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTikTokSocialAccountMutation, { data, loading, error }] = useUpdateTikTokSocialAccountMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      ttSecUid: // value for 'ttSecUid'
 *      username: // value for 'username'
 *      followerCount: // value for 'followerCount'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useUpdateTikTokSocialAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTikTokSocialAccountMutation, UpdateTikTokSocialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTikTokSocialAccountMutation, UpdateTikTokSocialAccountMutationVariables>(UpdateTikTokSocialAccountDocument, options);
      }
export type UpdateTikTokSocialAccountMutationHookResult = ReturnType<typeof useUpdateTikTokSocialAccountMutation>;
export type UpdateTikTokSocialAccountMutationResult = Apollo.MutationResult<UpdateTikTokSocialAccountMutation>;
export type UpdateTikTokSocialAccountMutationOptions = Apollo.BaseMutationOptions<UpdateTikTokSocialAccountMutation, UpdateTikTokSocialAccountMutationVariables>;