import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LookupIgMediaQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type LookupIgMediaQuery = { __typename: 'Query', igMedia?: { __typename: 'IGMedia', id: string, caption?: string | null, lcMediaUrl?: string | null, lcThumbnailUrl?: string | null, poster: { __typename: 'IGUser', id: string, username: string } } | null };


export const LookupIgMediaDocument = gql`
    query lookupIgMedia($id: ID!) {
  igMedia(id: $id) {
    id
    caption
    lcMediaUrl
    lcThumbnailUrl
    poster {
      id
      username
    }
  }
}
    `;

/**
 * __useLookupIgMediaQuery__
 *
 * To run a query within a React component, call `useLookupIgMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupIgMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupIgMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLookupIgMediaQuery(baseOptions: Apollo.QueryHookOptions<LookupIgMediaQuery, LookupIgMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupIgMediaQuery, LookupIgMediaQueryVariables>(LookupIgMediaDocument, options);
      }
export function useLookupIgMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupIgMediaQuery, LookupIgMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupIgMediaQuery, LookupIgMediaQueryVariables>(LookupIgMediaDocument, options);
        }
export type LookupIgMediaQueryHookResult = ReturnType<typeof useLookupIgMediaQuery>;
export type LookupIgMediaLazyQueryHookResult = ReturnType<typeof useLookupIgMediaLazyQuery>;
export type LookupIgMediaQueryResult = Apollo.QueryResult<LookupIgMediaQuery, LookupIgMediaQueryVariables>;