import React, { useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { IntegrationRow_ShopifyIntegration_Fragment } from './operations/integration-row.generated'
import { IntegrationType, ShopifyQueryParamsInput } from '../gql-global'
import { useCreateShopifyIntegrationMutation } from './operations/create-shopify-integration.generated'
import { StringParam, useQueryParams } from 'use-query-params'
import { isApolloError } from '@apollo/client'
import { INTEGRATIONS_ROUTE } from './routes'
import { useToast } from '../components/Alert/ToastProvider'

interface ShopifyCallbackProps {
  accountId?: string | null
  integrations: IntegrationRow_ShopifyIntegration_Fragment[]
}

function ShopifyCallback({ accountId, integrations }: ShopifyCallbackProps): React.ReactElement | null {
  const [authDone, setAuthDone] = useState(false)
  const { showToast } = useToast()

  const [createShopifyIntegration] = useCreateShopifyIntegrationMutation({
    update(cache, { data }): void {
      const customerIntegration = data?.createShopifyIntegration?.integration
      if (!customerIntegration) {
        return
      }
      cache.modify({
        id: cache.identify({ __typename: 'AccountType', id: accountId }),
        fields: {
          integrations(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
  })

  // Shopify sends query params on callback after successfully authing with store
  const [shopifyQuery] = useQueryParams({
    hmac: StringParam,
    host: StringParam,
    code: StringParam,
    state: StringParam,
    shop: StringParam,
    timestamp: StringParam,
  })

  // Try to create/update shopify integration once we have shop name and callback query params
  const handleShopifySuccessCallback = useCallback(
    (shopName: string, queryParams: ShopifyQueryParamsInput): void => {
      const existingShopifyIntegration = integrations.find(
        integration => integration.integrationType === IntegrationType.Shopify && integration.name === shopName,
      )

      if (!existingShopifyIntegration) {
        createShopifyIntegration({
          variables: {
            shopName,
            queryParams,
          },
        })
          .catch(error => {
            console.log({ error })
            const errorMessage =
              (error &&
                (isApolloError(error) && error.graphQLErrors.some(e => e.extensions?.code === 'DUPLICATE_KEY')
                  ? 'Ecommerce Integration with that name already exists'
                  : `There was an error while adding this integration. Please try again.`)) ||
              ''
            showToast({
              title: 'Error Adding Shopify Integration',
              message: errorMessage,
              severity: 'error',
              autoHideDuration: 5000,
            })
          })
          .finally(() => setAuthDone(true))
      }
      // TODO: Add updateIntegration logic
    },
    [createShopifyIntegration, integrations, showToast],
  )

  useEffect(() => {
    const { hmac, host, code, state, shop, timestamp } = shopifyQuery
    if (hmac && host && code && state && shop && timestamp) {
      const shopName = shop.replace(/.myshopify.com/, '')
      handleShopifySuccessCallback(shopName, { hmac, host, code, state, shop, timestamp })
    }
  }, [shopifyQuery, handleShopifySuccessCallback])

  return authDone ? <Redirect to={INTEGRATIONS_ROUTE.path} push={false} /> : null
}

export default ShopifyCallback
