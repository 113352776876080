import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

interface ListCountProps {
  loading?: boolean
  count?: number
  units: string
}

const ListCount: React.FC<ListCountProps> = ({ loading, count, units }) => {
  return (
    <Typography display="block" variant="body2" color="secondary">
      {loading && <Skeleton width={80} component="span" />}
      {!loading && (
        <>
          <span>Showing</span>
          <Box component="b" color="text.primary" ml={2}>
            {count?.toLocaleString()} {units}
          </Box>
        </>
      )}
    </Typography>
  )
}

export default ListCount
