/*
 * this function strips anything other than letters, numbers, and the hashtag symbol
 * then it splits them and returns an array of the keywords
 */
export function parseCaptionKeywords(searchText: string): string[] {
  const keywords = searchText
    .replace(/[^a-zA-Z0-9#]/gi, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .split(/\s+/g)
  return keywords
}
