import React from 'react'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
    maxWidth: '100%',
  },
  field: {
    width: '100%',
  },
  input: {
    padding: 10,
    flexWrap: 'wrap',
  },
}))

type Props = {
  chips: string[]
  onChange: (value: string[]) => void
  inputText: string
  setInputText: (value: string) => void
  allowSpaces: boolean
  allowQuotes: boolean
  prependChar?: string
  placeholder?: string
}

const ChipsInput: React.FC<Props> = props => {
  const classes = useStyles()
  const { chips, placeholder, allowSpaces, allowQuotes, inputText, prependChar, setInputText, onChange } = props

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const triggerKeys = ['Enter', ',', ...(allowSpaces ? [] : [' '])]

    if (triggerKeys.includes(event.key) && inputText) {
      onChange(Array.from(new Set([...chips, inputText])))
      setInputText('')
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(`[,${allowSpaces ? '' : ' '}${allowQuotes ? '' : '"\''}]`)
    const value = event.target.value.replace(regex, '')
    setInputText(`${prependChar ?? ''}${value.replace(new RegExp(prependChar ?? '', 'g'), '')}`)
  }

  const handleDelete = (item: string) => () => {
    onChange(chips.filter(chip => chip !== item))
    setInputText('')
  }

  return (
    <TextField
      variant="outlined"
      className={classes.field}
      onKeyDown={handleOnKeyDown}
      InputProps={{
        value: inputText,
        startAdornment: chips?.map(item => (
          <Chip key={item} label={item} title={item} className={classes.chip} onDelete={handleDelete(item)} />
        )),
        className: classes.input,
        onChange: handleChange,
        placeholder: placeholder ?? 'Type here...',
      }}
    />
  )
}

export default ChipsInput
