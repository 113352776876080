import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

// Shamelessly stolen from https://github.com/nzbin/three-dots/blob/master/dist/three-dots.css
// license: https://github.com/nzbin/three-dots/blob/master/LICENSE
/**
 * ==============================================
 * Dot Bricks
 * ==============================================
 */

const boxArrayToString = (arr: (string | number)[][]): string => arr.map(a => a.join(' ')).join(', ')

const useStyles = makeStyles((theme: Theme) => {
  const dotColor = theme.palette.info.dark
  const width = 24
  const dotWidth = width / 3
  const dotHeight = width / 3
  const dotRadius = dotWidth / 2

  const dotBgColor = dotColor
  const dotBeforeColor = dotColor
  const dotAfterColor = dotColor

  const spacing = dotWidth + dotWidth * 0.6
  const topPos = spacing / 2
  const leftPos = -9999
  const x1 = -leftPos - topPos
  const x2 = -leftPos - topPos + spacing
  const y1 = 0
  const y2 = -spacing
  return {
    root: {
      position: 'relative',
      top: topPos,
      left: leftPos,

      width: dotWidth,
      height: dotHeight,
      borderRadius: dotRadius,
      backgroundColor: dotBgColor,
      color: dotColor,
      boxShadow: boxArrayToString([
        [`${x1}px`, `${y2}px`, 0, 0, dotBeforeColor],
        [`${x1}px`, `${y1}px`, 0, 0, dotColor],
        [`${x2}px`, `${y1}px`, 0, 0, dotAfterColor],
      ]),
      animation: '$dotBricks 2s infinite ease',
    },

    '@keyframes dotBricks': {
      '0%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },

      '8.333%': {
        boxShadow: boxArrayToString([
          [`${x2}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },

      '16.667%': {
        boxShadow: boxArrayToString([
          [`${x2}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y2}px`, 0, 0, dotColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },

      '25%': {
        boxShadow: boxArrayToString([
          [`${x2}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y2}px`, 0, 0, dotColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },

      '33.333%': {
        boxShadow: boxArrayToString([
          [`${x2}px`, `${y1}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y2}px`, 0, 0, dotColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },

      '41.667%': {
        boxShadow: boxArrayToString([
          [`${x2}px`, `${y1}px`, 0, 0, dotBeforeColor],
          [`${x2}px`, `${y2}px`, 0, 0, dotColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },

      '50,%': {
        boxShadow: boxArrayToString([
          [`${x2}px`, `${y1}px`, 0, 0, dotBeforeColor],
          [`${x2}px`, `${y2}px`, 0, 0, dotColor],
          [`${x1}px`, `${y2}px`, 0, 0, dotAfterColor],
        ]),
      },

      '58.333%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y1}px`, 0, 0, dotBeforeColor],
          [`${x2}px`, `${y2}px`, 0, 0, dotColor],
          [`${x1}px`, `${y2}px`, 0, 0, dotAfterColor],
        ]),
      },

      '66.666%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y1}px`, 0, 0, dotBeforeColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotColor],
          [`${x1}px`, `${y2}px`, 0, 0, dotAfterColor],
        ]),
      },

      '75%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y1}px`, 0, 0, dotBeforeColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotColor],
          [`${x2}px`, `${y2}px`, 0, 0, dotAfterColor],
        ]),
      },

      '83.333%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotColor],
          [`${x2}px`, `${y2}px`, 0, 0, dotAfterColor],
        ]),
      },

      '91.667%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotColor],
          [`${x2}px`, `${y2}px`, 0, 0, dotAfterColor],
        ]),
      },

      '10,0,%': {
        boxShadow: boxArrayToString([
          [`${x1}px`, `${y2}px`, 0, 0, dotBeforeColor],
          [`${x1}px`, `${y1}px`, 0, 0, dotColor],
          [`${x2}px`, `${y1}px`, 0, 0, dotAfterColor],
        ]),
      },
    },
  }
})

const DancingDots: React.FC = () => {
  const classes = useStyles()
  return <div className={classes.root} />
}

export default DancingDots
