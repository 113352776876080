import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { createStyles, Link, makeStyles, Theme } from '@material-ui/core'
import { CONTENT_ROUTE } from '../../content/routes'
import { encodeFilterParams } from '../../content/use-filter-params'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlightedText: {
      backgroundColor: theme.palette.primary.light,
    },
    root: {
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
    },
  }),
)

export interface CaptionProps {
  text: string
  platform?: 'instagram' | 'tiktok'
  highlightedText?: string
}

function mentionURL(username: string, platform: 'instagram' | 'tiktok'): string {
  if (platform === 'tiktok') {
    return `https://www.tiktok.com/@${username}`
  }
  return `https://www.instagram.com/${username}/`
}

function Caption({ text, highlightedText, platform = 'instagram' }: CaptionProps): React.ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {text.split(/(#[^~`!@#$%^&*()\-+={}[\]:;"'<>?,./|\\\s]+|@[a-zA-Z_.]+[a-zA-Z_]+|\b)/).map((s: string, i) => {
        if (s.startsWith('@')) {
          return (
            <Link key={i} target="_blank" rel="noopener noreferrer" href={mentionURL(s.substring(1), platform)}>
              {s}
            </Link>
          )
        } else if (s.startsWith('#')) {
          return (
            <Link
              key={i}
              component={RouterLink}
              to={{
                pathname: CONTENT_ROUTE.path,
                search: `?${encodeFilterParams({ hashtagKeywords: [s.substring(1)] })}`,
              }}
            >
              {s}
            </Link>
          )
        }
        if (highlightedText && s.match(new RegExp(highlightedText, 'i'))) {
          return (
            <span key={i} className={classes.highlightedText}>
              {s}
            </span>
          )
        }

        return <React.Fragment key={i}>{s}</React.Fragment>
      })}
    </div>
  )
}

export default Caption
