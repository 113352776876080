import React, { useRef } from 'react'
import { Box, Card, CardContent, Typography, makeStyles, Divider, Link } from '@material-ui/core'
import squiggle from '../images/squiggle.png'
import { useResendUserEmailMutation } from './operations/resend-user-email.generated'
import { primary } from '../loudcrowd-theme'
import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '../components/Alert/ToastProvider'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto 0',
  },
  card: {
    padding: 20,
    width: 548,
  },
  logo: {
    marginLeft: 1,
  },
  textContainer: {
    margin: '20px 0 30px 0',
  },
  title: {
    marginBottom: 5,
    fontSize: 32,
    fontWeight: 600,
  },
  email: {
    fontWeight: 'bold',
  },
  divider: {},
  linkContainer: {
    marginTop: 15,
  },
  text: {
    margin: 0,
  },
  resendEmail: {
    color: primary[600],
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

function Auth0ConfirmEmail(): React.ReactElement {
  const classes = useStyles()
  const { showToast } = useToast()

  const { user: auth0User } = useAuth0()
  const [resentUserEmail] = useResendUserEmailMutation()

  const userEmail = auth0User?.email

  async function handleResendEmail() {
    try {
      await resentUserEmail()
      showToast({
        title: 'Success: Email Resent',
        message: `Sent email to ${userEmail}`,
        severity: 'success',
        autoHideDuration: 5000,
      })
    } catch {
      showToast({
        title: 'Uh Oh! It looks like we had a problem resending the email.',
        message: 'Please try resending that again.',
        severity: 'error',
      })
    }
  }

  const resendButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <div className={classes.root}>
      {userEmail && (
        <Card className={classes.card}>
          <CardContent>
            <Box>
              <img className={classes.logo} src={squiggle} alt="LoudCrowd Logo" />
            </Box>
            <Box className={classes.textContainer}>
              <Typography className={classes.title} variant="h3">
                Check your email.
              </Typography>
              <Typography variant="body2" component="p">
                Confirm your email address with a link we sent to:
              </Typography>
              <Typography variant="body2" component="p" className={classes.email}>
                {userEmail}
              </Typography>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.linkContainer}>
              <Link
                component="button"
                className={classes.resendEmail}
                ref={resendButtonRef}
                onClick={handleResendEmail}
              >
                Resend Email
              </Link>
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default Auth0ConfirmEmail
