import React from 'react'
import { Box, createStyles, makeStyles, Typography, TextField } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { AddChallengeFormFields } from '../AddEditChallengeDialog'
import { primary } from '../../loudcrowd-theme'
import { AccountLabel } from '../../components/LabelMenu/LabelMenu'

const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      fontSize: 14,
      marginTop: 10,
      display: 'block',
      color: primary[600],
    },
    textContainer: {
      '& textarea': {
        lineHeight: 1.5,
        fontSize: 14,
      },
    },
  }),
)

interface PickWinnersProps {
  rewards: AccountLabel[]
  disabled: boolean
}

function PickWinners({ rewards, disabled }: PickWinnersProps): React.ReactElement {
  const classes = useStyles()
  const { values, setFieldValue } = useFormikContext<AddChallengeFormFields>()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    reward: { id: number },
    key: number,
  ) => {
    setFieldValue(`paintedDoor.winningInstructions.${key}.instructions`, event.target.value)
    if (!values.paintedDoor?.winningInstructions?.[key]?.rewardId) {
      setFieldValue(`paintedDoor.winningInstructions.${key}.rewardId`, reward.id.toString())
    }
  }

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Help me pick winners
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        We will help automate the process of choosing your winners. Please provide the winning criteria for each reward
        below.
      </Typography>
      {rewards.map((reward, key) => (
        <Box my={3} position="relative" className={classes.textContainer} key={key}>
          <Typography variant="caption">{reward.name}</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={values.paintedDoor?.winningInstructions?.[key]?.instructions}
            placeholder={`Criteria for ${reward.name}`}
            onChange={event => handleChange(event, reward, key)}
            disabled={disabled}
          />
        </Box>
      ))}
    </Box>
  )
}

export default PickWinners
