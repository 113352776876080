import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { TableRowParticipantFragmentDoc } from '../../../components/CustomerTable/operations/table-row-participant.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignMembersQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  where?: Types.InputMaybe<Types.ParticipantsInput>;
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  mentionsWhere: Types.CustomerMentionStatsFilters;
  activeStoryMentionsWhere: Types.CustomerMentionStatsFilters;
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  pendingWhere?: Types.InputMaybe<Types.ParticipantsInput>;
}>;


export type CampaignMembersQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> } | null, participants?: { __typename: 'PagedParticipants', total: number, cursor?: string | null, results: Array<{ __typename: 'ProgramParticipantType', id: number, createdAt: Date, approvedAt?: Date | null, status: Types.ParticipantStatus, customer: { __typename: 'CustomerType', id: number, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null, igUser?: { __typename: 'IGUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, mentionStats?: { __typename: 'CustomerMentionStats', avgEngagementRate: number, impressions: number, maxPostDate?: Date | null, postCount: number } | null, ttUser?: { __typename: 'TTUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string } | null } }> } | null, applicantStats?: { __typename: 'ParticipantStats', count: number } | null } | null };


export const CampaignMembersDocument = gql`
    query CampaignMembers($campaignId: ID!, $where: ParticipantsInput, $limit: Int!, $cursor: String, $mentionsWhere: CustomerMentionStatsFilters!, $activeStoryMentionsWhere: CustomerMentionStatsFilters!, $sortBy: ParticipantSort, $sortDirection: SortDirection, $pendingWhere: ParticipantsInput) {
  campaign(id: $campaignId) {
    id
    program {
      id
      socialAccounts {
        id
      }
    }
    participants(
      where: $where
      mentionsWhere: $mentionsWhere
      limit: $limit
      cursor: $cursor
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      total
      cursor
      results {
        id
        ...TableRowParticipant
      }
    }
    applicantStats: participantStats(
      where: $pendingWhere
      mentionsWhere: $mentionsWhere
    ) {
      count
    }
  }
}
    ${TableRowParticipantFragmentDoc}`;

/**
 * __useCampaignMembersQuery__
 *
 * To run a query within a React component, call `useCampaignMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignMembersQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      activeStoryMentionsWhere: // value for 'activeStoryMentionsWhere'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      pendingWhere: // value for 'pendingWhere'
 *   },
 * });
 */
export function useCampaignMembersQuery(baseOptions: Apollo.QueryHookOptions<CampaignMembersQuery, CampaignMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignMembersQuery, CampaignMembersQueryVariables>(CampaignMembersDocument, options);
      }
export function useCampaignMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignMembersQuery, CampaignMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignMembersQuery, CampaignMembersQueryVariables>(CampaignMembersDocument, options);
        }
export type CampaignMembersQueryHookResult = ReturnType<typeof useCampaignMembersQuery>;
export type CampaignMembersLazyQueryHookResult = ReturnType<typeof useCampaignMembersLazyQuery>;
export type CampaignMembersQueryResult = Apollo.QueryResult<CampaignMembersQuery, CampaignMembersQueryVariables>;