import React, { useEffect, useRef } from 'react'
import { Box, Button, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ContainerError from '../components/ContainerError'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import { ReactComponent as NoPostsImage } from '../images/no-posts.svg'
import { useChallengeListUserDataQuery } from './operations/challenge-list-user-data.generated'
import { ChallengeListDocument, useChallengeListQuery } from './operations/challenge-list.generated'
import ChallengeCard from './challenge-card/ChallengeCard'
import { useToast } from '../components/Alert/ToastProvider'
import { useDeleteChallengeMutation } from './operations/delete-challenge.generated'
import { useDuplicateChallengeMutation } from './operations/duplicate-challenge.generated'
import { useTogglePauseChallengeMutation } from './operations/toggle-challenge.generated'
import { NetworkStatus } from '@apollo/client'
import { NumberParam, useQueryParam } from 'use-query-params'
import { clearChallengesAndSavedFilters } from './utils/challenge-utils'
import { ChallengeFragment } from './operations/challenge.generated'

const cardWidth = 327
const gapSpacing = 6
const PAGE_SIZE = 9

interface ChallengeListProps {
  onEditChallenge: (challenge: ChallengeFragment) => void
}

function ChallengeList(props: ChallengeListProps): React.ReactElement {
  const { onEditChallenge } = props

  const { data: userData, loading: userLoading, error: userError } = useChallengeListUserDataQuery()
  const socialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId || ''

  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)

  const { data, loading, error, fetchMore, networkStatus } = useChallengeListQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    variables: {
      socialAccountId: socialAccountId || '',
      limit: limitRef.current,
    },
  })

  const theme = useTheme()
  const { showToast } = useToast()
  const gap = theme.spacing(gapSpacing)

  const challengeList = data?.socialAccount?.challenges?.results ? data?.socialAccount?.challenges?.results : []
  const hasErrors = userError || error
  const isLoading = userLoading || loading
  const isErrorState = hasErrors && !isLoading
  const isReadyState = !hasErrors && !(isLoading && networkStatus !== NetworkStatus.fetchMore)
  const isLoadingState = !hasErrors && isLoading && networkStatus !== NetworkStatus.fetchMore
  const isLoadingNewPage = !hasErrors && isLoading && networkStatus === NetworkStatus.fetchMore
  const noChallengeData = isReadyState && !challengeList?.length
  const hasChallengeData = !noChallengeData

  const newViewingCount = data?.socialAccount?.challenges?.results.length || limitRef.current
  useEffect(() => setViewing((Math.ceil(newViewingCount / PAGE_SIZE) || 1) * PAGE_SIZE), [newViewingCount, setViewing])

  const loadMoreChallenges = (): void => {
    const nextCursor = data?.socialAccount?.challenges?.cursor
    if (nextCursor) {
      void fetchMore({
        variables: {
          socialAccountId: socialAccountId || '',
          cursor: nextCursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  const [deleteChallenge] = useDeleteChallengeMutation({
    refetchQueries: [
      {
        query: ChallengeListDocument,
        variables: {
          socialAccountId: socialAccountId || '',
          limit: data?.socialAccount?.challenges?.results?.length || limitRef.current,
        },
      },
    ],
  })

  function handleOnDelete(challenge: ChallengeFragment): void {
    deleteChallenge({
      variables: {
        id: challenge.id,
      },
      update(cache, { data }) {
        if (!data?.deleteChallenge?.ok) {
          return
        }
        const cacheId = cache.identify(challenge)
        cache.evict({ id: cacheId })
        cache.gc()

        const campaignIds: number[] = []
        const programs = challenge.programs || []
        for (let i = 0; i < programs.length; i++) {
          const campaignId = programs[i]?.campaign?.id
          if (campaignId !== undefined) {
            campaignIds.push(campaignId)
          }
        }
        clearChallengesAndSavedFilters(
          cache,
          campaignIds,
          challenge.socialAccounts.map(s => s.id),
        )
      },
    }).catch(e => {
      showToast({
        title: 'Error: Deleting Challenge',
        message: 'Something went wrong when deleting this challenge, please try again. ' + e,
        severity: 'error',
      })
    })
  }

  const [duplicateChallenge] = useDuplicateChallengeMutation({
    refetchQueries: [
      {
        query: ChallengeListDocument,
        variables: {
          socialAccountId: socialAccountId || '',
          limit: data?.socialAccount?.challenges?.results?.length
            ? Math.ceil(data?.socialAccount?.challenges?.results?.length / PAGE_SIZE) * PAGE_SIZE
            : limitRef.current,
        },
      },
    ],
  })

  function handleOnDuplicate(challenge: ChallengeFragment): void {
    duplicateChallenge({
      variables: {
        id: challenge.id,
      },
      update(cache, { data }) {
        const campaignIds: number[] = []
        const programs = data?.duplicateChallenge?.challenge?.programs || []
        for (let i = 0; i < programs.length; i++) {
          const campaignId = programs[i]?.campaign?.id
          if (campaignId !== undefined) {
            campaignIds.push(campaignId)
          }
        }
        clearChallengesAndSavedFilters(
          cache,
          campaignIds,
          data?.duplicateChallenge?.challenge?.socialAccounts.map(s => s.id),
        )
      },
    }).catch(e => {
      showToast({
        title: 'Error: Duplicating Challenge',
        message: 'Something went wrong when duplicating this challenge, please try again. ' + e,
        severity: 'error',
      })
    })
  }

  const [toggleChallenge] = useTogglePauseChallengeMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ChallengeListDocument,
        variables: {
          socialAccountId: socialAccountId || '',
          limit: data?.socialAccount?.challenges?.results?.length || limitRef.current,
        },
      },
    ],
  })

  async function handleOnToggle(challenge: ChallengeFragment): Promise<void> {
    const ldquote = String.fromCharCode(8220)
    const rdquote = String.fromCharCode(8221)
    try {
      const response = await toggleChallenge({
        variables: {
          id: challenge.id,
        },
      })
      const challengeName = response.data?.togglePauseChallenge?.challenge?.name
      const updatedStatus = response.data?.togglePauseChallenge?.challenge?.currentStatus
      showToast({
        title: 'Success: Updated Challenge Status',
        message: `${ldquote}${challengeName}${rdquote} is now ${updatedStatus}`,
        severity: 'success',
      })
    } catch (e) {
      showToast({
        title: 'Error: Updating Challenge Status',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    }
  }
  return (
    <>
      {/* Errors */}
      {isErrorState && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Sorry, we had a problem loading challenges." />
        </Box>
      )}

      {/* Empty query list */}
      {noChallengeData && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <ContainerEmptyState image={NoPostsImage} text={'No challenges found for this social account'} />
        </Box>
      )}

      {/* Successful query */}
      <Box
        display="grid"
        gridGap={gap}
        gridTemplateColumns={`repeat(auto-fit, ${cardWidth}px)`}
        justifyContent="flex-start"
      >
        {hasChallengeData &&
          challengeList.map(challenge => (
            <ChallengeCard
              key={challenge.id}
              challenge={challenge}
              onDelete={handleOnDelete}
              onDuplicate={handleOnDuplicate}
              onEdit={onEditChallenge}
              onToggle={handleOnToggle}
            />
          ))}
      </Box>
      {!isLoadingState && data?.socialAccount?.challenges?.cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8} mr={40}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={loadMoreChallenges}
            disabled={networkStatus === NetworkStatus.fetchMore}
          >
            Load more
          </Button>
        </Box>
      )}

      {/* Loading */}
      {(isLoadingState || isLoadingNewPage) && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Skeleton height={42} width={327} />
            <Skeleton height={42} width={327} />
            <Skeleton height={42} width={327} />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Skeleton height={42} width={327} />
            <Skeleton height={42} width={327} />
            <Skeleton height={42} width={327} />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Skeleton height={42} width={327} />
            <Skeleton height={42} width={327} />
            <Skeleton height={42} width={327} />
          </Box>
        </>
      )}
    </>
  )
}

export default ChallengeList
