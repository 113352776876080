import React from 'react'
import { Tabs, TabsProps } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

function RouterTabs(props: Omit<TabsProps, 'value'>): React.ReactElement {
  const location = useLocation()
  return <Tabs value={location.pathname} {...props} />
}

export default RouterTabs
