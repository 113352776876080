import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ADMIN_ACCOUNTS_ROUTE, ADMIN_ACCOUNT_USERS_ROUTE } from '../routes'
import withAuthorization from '../../withAuthorization'
import useTitle from '../../utils/use-title'
import UserManagement from '../../settings/users/UserManagement'
import AccountList from './AccountList'

function AccountManagement(): React.ReactElement {
  useTitle('Admin - Accounts')
  return (
    <Switch>
      <Route component={AccountList} path={ADMIN_ACCOUNTS_ROUTE.path} exact />
      <Route component={UserManagement} path={ADMIN_ACCOUNT_USERS_ROUTE.path} />
    </Switch>
  )
}

export default withAuthorization(ADMIN_ACCOUNTS_ROUTE)(AccountManagement)
