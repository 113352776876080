import React from 'react'
import { Box, Card, CardContent, Typography, makeStyles, Divider } from '@material-ui/core'
import squiggle from '../images/squiggle.png'
import { primary } from '../loudcrowd-theme'
import { Redirect, Link } from 'react-router-dom'
import { AUTH0_FORGOT_PASSWORD_ROUTE, AUTH0_LOGIN_ROUTE } from './routes'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto 0',
  },
  card: {
    padding: 20,
    width: 548,
  },
  logo: {
    marginLeft: 1,
  },
  textContainer: {
    margin: '20px 0 30px 0',
  },
  infoContainer: {
    width: '65%',
  },
  title: {
    marginBottom: 5,
    fontSize: 32,
    fontWeight: 600,
  },
  email: {
    fontWeight: 'bold',
  },
  divider: {},
  linkContainer: {
    marginTop: 15,
  },
  text: {
    margin: 0,
  },
  redirect: {
    color: primary[600],
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

interface checkEmailProps {
  location: {
    state: {
      email?: string
    }
  }
}

function Auth0ForgotPasswordEmail(props: checkEmailProps): React.ReactElement {
  const classes = useStyles()
  const forgotPasswordEmail = props.location?.state?.email

  if (!forgotPasswordEmail) {
    return <Redirect to={AUTH0_FORGOT_PASSWORD_ROUTE.path} />
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Box>
            <img className={classes.logo} src={squiggle} alt="LoudCrowd Logo" />
          </Box>
          <Box className={classes.textContainer}>
            <Typography className={classes.title} variant="h3">
              Check your email.
            </Typography>
            <Box className={classes.infoContainer}>
              <Typography variant="body2" component="span">
                We sent an email to{' '}
              </Typography>
              <Typography variant="body2" component="span" className={classes.email}>
                {forgotPasswordEmail}
              </Typography>
              <Typography variant="body2" component="span">
                {' '}
                with a link to restore your password.
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.linkContainer}>
            <Link to={AUTH0_LOGIN_ROUTE.path}>Return to Log in.</Link>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}

export default Auth0ForgotPasswordEmail
