import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignStatsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  socialAccountFilter: Types.IdFilterInput;
  platform: Types.SocialPlatformEnum;
  timezone: Types.Scalars['String'];
  timeDimension?: Types.InputMaybe<Types.TimeDimension>;
  dateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
  previousDateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
  participantDateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
  participantStatus?: Types.InputMaybe<Types.ParticipantStatusFilterInput>;
  includePrevious: Types.Scalars['Boolean'];
  includeLastThirty: Types.Scalars['Boolean'];
  lastThirtyDateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
  recentSignupLimit: Types.Scalars['Int'];
  activeStoryMentionsWhere: Types.CustomerMentionStatsFilters;
}>;


export type CampaignStatsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, mentionStats?: { __typename: 'CampaignMentionStats', posts: number, impressions: number, engagement: number, emv: number } | null, previousMentionStats?: { __typename: 'CampaignMentionStats', posts: number, impressions: number, engagement: number, emv: number } | null, lastThirtyMentionStats?: { __typename: 'CampaignMentionStats', posts: number, impressions: number, engagement: number, emv: number } | null, participantStats?: { __typename: 'ParticipantStats', count: number } | null, allTimeParticipantStats?: { __typename: 'ParticipantStats', count: number } | null, participantStatsTimeseries?: Array<{ __typename: 'ParticipantStatsTimeseries', startDate: Date, endDate: Date, count: number }> | null, participants?: { __typename: 'PagedParticipants', total: number, cursor?: string | null, results: Array<{ __typename: 'ProgramParticipantType', id: number, createdAt: Date, approvedAt?: Date | null, customer: { __typename: 'CustomerType', id: number, igUser?: { __typename: 'IGUser', id: string, username: string, followerCount?: number | null, avatarUrl?: string | null, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, ttUser?: { __typename: 'TTUser', id: string, username: string, followerCount?: number | null, avatarUrl?: string | null } | null, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null } }> } | null } | null };


export const CampaignStatsDocument = gql`
    query CampaignStats($campaignId: ID!, $socialAccountFilter: IdFilterInput!, $platform: SocialPlatformEnum!, $timezone: String!, $timeDimension: TimeDimension, $dateRange: DateTimeFilterInput, $previousDateRange: DateTimeFilterInput, $participantDateRange: DateTimeFilterInput, $participantStatus: ParticipantStatusFilterInput, $includePrevious: Boolean!, $includeLastThirty: Boolean!, $lastThirtyDateRange: DateTimeFilterInput, $recentSignupLimit: Int!, $activeStoryMentionsWhere: CustomerMentionStatsFilters!) {
  campaign(id: $campaignId) {
    id
    mentionStats(
      where: {postedAt: $dateRange, socialAccountId: $socialAccountFilter}
    ) {
      posts
      impressions
      engagement
      emv
    }
    previousMentionStats: mentionStats(
      where: {postedAt: $previousDateRange, socialAccountId: $socialAccountFilter}
    ) @include(if: $includePrevious) {
      posts
      impressions
      engagement
      emv
    }
    lastThirtyMentionStats: mentionStats(
      where: {postedAt: $lastThirtyDateRange, socialAccountId: $socialAccountFilter}
    ) @include(if: $includeLastThirty) {
      posts
      impressions
      engagement
      emv
    }
    participantStats(
      where: {approvedAt: $participantDateRange, status: $participantStatus, platform: $platform}
    ) {
      count
    }
    allTimeParticipantStats: participantStats(
      where: {status: $participantStatus, platform: $platform}
    ) {
      count
    }
    participantStatsTimeseries(
      where: {approvedAt: $dateRange, status: $participantStatus, platform: $platform}
      timezone: $timezone
      timeDimension: $timeDimension
    ) {
      startDate
      endDate
      count
    }
    participants(
      limit: $recentSignupLimit
      sortDirection: DESC
      sortBy: APPROVED_AT
      where: {status: $participantStatus, platform: $platform}
    ) {
      total
      cursor
      results {
        id
        createdAt
        approvedAt
        customer {
          id
          igUser {
            id
            username
            followerCount
            avatarUrl
            storiesIgSocialAccounts {
              id
              fbUsers {
                id
                isValid
              }
            }
          }
          ttUser {
            id
            username
            followerCount
            avatarUrl
          }
          activeStoryMentionsStats: mentionStats(where: $activeStoryMentionsWhere) {
            postCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignStatsQuery__
 *
 * To run a query within a React component, call `useCampaignStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignStatsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      socialAccountFilter: // value for 'socialAccountFilter'
 *      platform: // value for 'platform'
 *      timezone: // value for 'timezone'
 *      timeDimension: // value for 'timeDimension'
 *      dateRange: // value for 'dateRange'
 *      previousDateRange: // value for 'previousDateRange'
 *      participantDateRange: // value for 'participantDateRange'
 *      participantStatus: // value for 'participantStatus'
 *      includePrevious: // value for 'includePrevious'
 *      includeLastThirty: // value for 'includeLastThirty'
 *      lastThirtyDateRange: // value for 'lastThirtyDateRange'
 *      recentSignupLimit: // value for 'recentSignupLimit'
 *      activeStoryMentionsWhere: // value for 'activeStoryMentionsWhere'
 *   },
 * });
 */
export function useCampaignStatsQuery(baseOptions: Apollo.QueryHookOptions<CampaignStatsQuery, CampaignStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignStatsQuery, CampaignStatsQueryVariables>(CampaignStatsDocument, options);
      }
export function useCampaignStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignStatsQuery, CampaignStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignStatsQuery, CampaignStatsQueryVariables>(CampaignStatsDocument, options);
        }
export type CampaignStatsQueryHookResult = ReturnType<typeof useCampaignStatsQuery>;
export type CampaignStatsLazyQueryHookResult = ReturnType<typeof useCampaignStatsLazyQuery>;
export type CampaignStatsQueryResult = Apollo.QueryResult<CampaignStatsQuery, CampaignStatsQueryVariables>;