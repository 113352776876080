import React from 'react'
import { Filters } from './use-filter-params-members'
import { CustomerFilterType, IntegerRangeFilter } from '../../gql-global'
import SavedFilterBar from '../../components/SavedFilterBar'
import { useDeleteFilterMutation } from '../../mutations/operations/delete-filter.generated'
import {
  CampaignUserInfoQuery,
  CampaignUserInfoQueryVariables,
  CampaignUserInfoDocument,
} from './operations/campaign-user-info.generated'
import produce from 'immer'
import { isTypeName } from '../../types/utility'
import { makeMentionTypesWhere, filterResponseMentionTypesToSet } from '../../dashboard/utils'
import useHasFeature from '../../hooks/useHasFeature'
import { cleanAllAnyNoneFilter, dateInclusionExclusionSaveFilter } from '../../utils/filter-params'
import { useToast } from '../../components/Alert/ToastProvider'
import { dateTimeRangeFilterToInput } from '../../utils/date-range-helper'
import { useCreateCustomerFilterMutation } from '../../customer/operations/create-customer-filter.generated'

interface CampaignMembersSavedFilterBarProps {
  filters: Filters
  setFilters: (f: Filters) => void
  accountId?: number | null
  campaignId: string
  accountFilters?: CustomerFilterType[] | null
  isInstagramAccount: boolean
  setSaveFilterDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  saveFilterDialogOpen: boolean
}

type CustomerFilterTypeKey = keyof CustomerFilterType['value']

function convertSavedFilterToFilters(savedFilter: CustomerFilterType, isInstagramAccount: boolean): Filters {
  let filters: Filters = { filterId: savedFilter.id }
  const mentionType = filterResponseMentionTypesToSet(savedFilter.value, isInstagramAccount)

  const parseRangeFieldValues = (fieldKey: CustomerFilterTypeKey) => {
    const fieldValue = savedFilter.value?.[fieldKey] as IntegerRangeFilter | undefined
    return fieldValue
      ? {
          gte: fieldValue.gte || undefined,
          lte: fieldValue.lte || undefined,
        }
      : undefined
  }

  if (savedFilter.value) {
    // check if campaign filters in saved filter. Don't process filter if invalid
    filters = {
      ...filters,
      postType: savedFilter.value.postType?.any ? new Set(savedFilter.value.postType.any) : undefined,
      postCount: parseRangeFieldValues('postCount' as CustomerFilterTypeKey),
      segments: savedFilter.value.segments || undefined,
      labels: savedFilter.value.labels || undefined,
      impressions: parseRangeFieldValues('impressions' as CustomerFilterTypeKey),
      avgEngagementRate: parseRangeFieldValues('avgEngagementRate' as CustomerFilterTypeKey),
      followerCount: parseRangeFieldValues('followerCount' as CustomerFilterTypeKey),
      approvedAt: savedFilter.value.approvedAt || undefined,
      dateRange: savedFilter.value.dateRange || undefined,
      postedAt: savedFilter.value.postedAt || undefined,
      paymentPeriods: savedFilter.value.paymentPeriods?.any ? { any: savedFilter.value.paymentPeriods.any } : undefined,
      minPostedAt: savedFilter.value.minPostedAt || undefined,
      maxPostedAt: savedFilter.value.maxPostedAt || undefined,
      usernameKeywords: savedFilter.value.username?.keywords || undefined,
      emailKeywords: savedFilter.value.email?.keywords || undefined,
      tagStatus: new Set(savedFilter.value?.tagStatus?.any || []),
      challengeMediaApproval: new Set(savedFilter.value?.challengeMediaApproval?.any || []),
      mediaType: new Set(savedFilter.value.mediaType?.any || []),
      rewards: new Set(savedFilter.value.rewards?.any || []),
      notesKeywords: savedFilter.value.notes?.keywords || [],
      noteCategories: new Set(savedFilter.value.noteCategories?.any || []),
      noteCreatedAt: savedFilter.value.noteCreatedAt || undefined,
      mentionType: mentionType.size > 0 ? mentionType : undefined,
      customFields: savedFilter.value.customFields || undefined,
      biographyKeywords: savedFilter.value.biography?.all?.map(k => k[0]).filter((k): k is string => !!k) || undefined,
      messageTemplate: savedFilter.value.messageTemplate || undefined,
      messageDate: savedFilter.value.messageDate || undefined,
      messageKeywords: savedFilter.value.messageKeywords || undefined,
      messageSendingMethod: savedFilter.value.messageSendingMethod || undefined,
      challengeIds: savedFilter.value?.challengeIds?.any ? { any: savedFilter.value?.challengeIds?.any } : undefined,
      commissionTiers: savedFilter.value.commissionTiers || undefined,
      commissionRate: parseRangeFieldValues('commissionRate' as CustomerFilterTypeKey),
      referredSalesRevenue: parseRangeFieldValues('referredSalesRevenue' as CustomerFilterTypeKey),
      referredOrders: parseRangeFieldValues('referredOrders' as CustomerFilterTypeKey),
      commissionsEarned: parseRangeFieldValues('commissionsEarned' as CustomerFilterTypeKey),
      linkViews: parseRangeFieldValues('linkViews' as CustomerFilterTypeKey),
      conversionRate: parseRangeFieldValues('conversionRate' as CustomerFilterTypeKey),
    }
  }
  return filters
}

const CampaignMemberSavedFilterBar: React.FC<CampaignMembersSavedFilterBarProps> = ({
  filters,
  accountFilters,
  campaignId,
  setFilters,
  isInstagramAccount,
  setSaveFilterDialogOpen,
  saveFilterDialogOpen = false,
}) => {
  const selectedFilter = accountFilters?.find(f => f.id === filters.filterId) || null
  const [deleteFilter] = useDeleteFilterMutation()
  const [createFilter] = useCreateCustomerFilterMutation()
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')
  const { showToast } = useToast()

  function handleSelectFilter(id: string): void {
    const filter = accountFilters?.find(f => f.id === id)
    if (!filter) return
    setFilters(convertSavedFilterToFilters(filter, isInstagramAccount))
  }

  function handleSaveFilter(saveFilterName: string): void {
    const dateRangeFilter = dateTimeRangeFilterToInput(filters.dateRange)
    const postedAtFilter = dateTimeRangeFilterToInput(filters.postedAt)
    const maxPostedAtFilter = dateTimeRangeFilterToInput(filters.maxPostedAt)
    const minPostedAtFilter = dateTimeRangeFilterToInput(filters.minPostedAt)
    const approvedAtFilter = dateTimeRangeFilterToInput(filters.approvedAt)
    const noteCreatedAtFilter = dateTimeRangeFilterToInput(filters.noteCreatedAt)

    createFilter({
      variables: {
        name: saveFilterName,
        value: {
          postCount: filters.postCount,
          postType: filters.postType ? { any: Array.from(filters.postType) } : undefined,
          segments: filters.segments
            ? { all: filters.segments?.all || undefined, none: filters.segments.none || undefined }
            : undefined,
          labels: filters.labels
            ? { all: filters.labels?.all || undefined, none: filters.labels.none || undefined }
            : undefined,
          impressions: filters.impressions,
          avgEngagementRate: filters.avgEngagementRate,
          followerCount: filters.followerCount,
          approvedAt: approvedAtFilter,
          postedAt: postedAtFilter,
          dateRange: dateRangeFilter,
          paymentPeriods: filters.paymentPeriods,
          minPostedAt: minPostedAtFilter,
          maxPostedAt: maxPostedAtFilter,
          username: filters.usernameKeywords ? { keywords: filters.usernameKeywords } : undefined,
          email: filters.emailKeywords ? { keywords: filters.emailKeywords } : undefined,
          tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : null,
          challengeMediaApproval: filters.challengeMediaApproval?.size
            ? { any: Array.from(filters.challengeMediaApproval) }
            : null,
          mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
          rewards: filters.rewards?.size ? { any: Array.from(filters.rewards) } : undefined,
          notes: filters.notesKeywords ? { keywords: filters.notesKeywords } : undefined,
          noteCategories: filters.noteCategories?.size ? { any: Array.from(filters.noteCategories) } : undefined,
          noteCreatedAt: noteCreatedAtFilter,
          ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
          customFields: filters.customFields,
          biography: filters.biographyKeywords ? { all: filters.biographyKeywords.map(k => [k]) } : undefined,
          messageTemplate: filters.messageTemplate
            ? { all: filters.messageTemplate?.all || undefined, none: filters.messageTemplate.none || undefined }
            : undefined,
          messageDate: dateInclusionExclusionSaveFilter(filters.messageDate),
          messageKeywords: filters.messageKeywords,
          messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
          campaigns: {
            any: [campaignId],
          },
          challengeIds: filters.challengeIds?.any ? { any: filters.challengeIds.any } : undefined,
          commissionTiers: cleanAllAnyNoneFilter(filters.commissionTiers),
          commissionRate: filters.commissionRate,
          referredSalesRevenue: filters.referredSalesRevenue,
          referredOrders: filters.referredOrders,
          commissionsEarned: filters.commissionsEarned,
          linkViews: filters.linkViews,
          conversionRate: filters.conversionRate,
        },
      },
      update(proxy, { data }): void {
        const filter = data?.createFilter?.filter
        if (!filter || !isTypeName(filter, 'CustomerFilterType')) {
          return
        }
        const cachedQuery = proxy.readQuery<CampaignUserInfoQuery, CampaignUserInfoQueryVariables>({
          query: CampaignUserInfoDocument,
        })
        if (!cachedQuery?.whoami?.account?.customerFilters) {
          return
        }
        const newQueryResults = produce(cachedQuery, draft => {
          draft.whoami?.account?.customerFilters?.unshift(filter)
        })
        proxy.writeQuery<CampaignUserInfoQuery, CampaignUserInfoQueryVariables>({
          query: CampaignUserInfoDocument,
          data: newQueryResults,
        })
      },
    })
      .then(response => {
        const newFilter = response.data?.createFilter?.filter
        if (newFilter && isTypeName(newFilter, 'CustomerFilterType')) {
          setFilters(convertSavedFilterToFilters(newFilter, isInstagramAccount))
        }
      })
      .catch(() => {
        showToast({
          title: 'Error: Create Saved Search',
          message: `Something went wrong when saving this set of search filters, please try again`,
          severity: 'error',
        })
      })
  }

  function handleDeleteFilter(id: string): void {
    deleteFilter({
      variables: {
        filterId: id,
      },
      update(proxy, { data }): void {
        const filterId = data?.deleteFilter?.filter?.id
        if (!filterId) {
          return
        }

        const cachedQuery = proxy.readQuery<CampaignUserInfoQuery, CampaignUserInfoQueryVariables>({
          query: CampaignUserInfoDocument,
        })
        if (!cachedQuery?.whoami?.account?.customerFilters) {
          return
        }
        const newQueryResults = produce(cachedQuery, draft => {
          const filters = draft.whoami?.account?.customerFilters
          if (!filters) {
            return
          }
          const filterIndex = filters.findIndex(f => f.id === filterId)
          if (filterIndex > -1) {
            filters.splice(filterIndex, 1)
          }
        })

        proxy.writeQuery<CampaignUserInfoQuery, CampaignUserInfoQueryVariables>({
          query: CampaignUserInfoDocument,
          data: newQueryResults,
        })
      },
    })
      .then(data => {
        showToast({
          severity: 'success',
          title: 'Success: Filter Deleted',
          message: `The search ${data.data?.deleteFilter?.filter?.name} was deleted.`,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Deleting Filter',
          message: 'Something went wrong while deleting this filter. Please try again.',
        })
      })
  }

  return (
    <SavedFilterBar
      selectedFilter={selectedFilter}
      defaultText="All Members"
      filters={accountFilters || []}
      saveFilterDialogOpen={saveFilterDialogOpen}
      onDeleteFilter={handleDeleteFilter}
      onSelectSavedFilter={handleSelectFilter}
      onCreateFilter={handleSaveFilter}
      setSaveFilterDialogOpen={setSaveFilterDialogOpen}
    />
  )
}

export default CampaignMemberSavedFilterBar
