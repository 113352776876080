import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QueryCampaignLtvBreakdownQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  currentRangeAt?: Types.InputMaybe<Types.DateTimeFilterInput>;
  previousRangeAt?: Types.InputMaybe<Types.DateTimeFilterInput>;
}>;


export type QueryCampaignLtvBreakdownQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, currentLtvBreakdown: { __typename: 'LtvBreakdown', ltvAfter: number, ltvBefore: number, ltvNonmember: number }, previousLtvBreakdown: { __typename: 'LtvBreakdown', ltvAfter: number } } | null };


export const QueryCampaignLtvBreakdownDocument = gql`
    query QueryCampaignLtvBreakdown($campaignId: ID!, $currentRangeAt: DateTimeFilterInput, $previousRangeAt: DateTimeFilterInput) {
  campaign(id: $campaignId) {
    id
    currentLtvBreakdown: ltvBreakdown(where: {rangeAt: $currentRangeAt}) {
      ltvAfter
      ltvBefore
      ltvNonmember
    }
    previousLtvBreakdown: ltvBreakdown(where: {rangeAt: $previousRangeAt}) {
      ltvAfter
    }
  }
}
    `;

/**
 * __useQueryCampaignLtvBreakdownQuery__
 *
 * To run a query within a React component, call `useQueryCampaignLtvBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCampaignLtvBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCampaignLtvBreakdownQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      currentRangeAt: // value for 'currentRangeAt'
 *      previousRangeAt: // value for 'previousRangeAt'
 *   },
 * });
 */
export function useQueryCampaignLtvBreakdownQuery(baseOptions: Apollo.QueryHookOptions<QueryCampaignLtvBreakdownQuery, QueryCampaignLtvBreakdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryCampaignLtvBreakdownQuery, QueryCampaignLtvBreakdownQueryVariables>(QueryCampaignLtvBreakdownDocument, options);
      }
export function useQueryCampaignLtvBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryCampaignLtvBreakdownQuery, QueryCampaignLtvBreakdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryCampaignLtvBreakdownQuery, QueryCampaignLtvBreakdownQueryVariables>(QueryCampaignLtvBreakdownDocument, options);
        }
export type QueryCampaignLtvBreakdownQueryHookResult = ReturnType<typeof useQueryCampaignLtvBreakdownQuery>;
export type QueryCampaignLtvBreakdownLazyQueryHookResult = ReturnType<typeof useQueryCampaignLtvBreakdownLazyQuery>;
export type QueryCampaignLtvBreakdownQueryResult = Apollo.QueryResult<QueryCampaignLtvBreakdownQuery, QueryCampaignLtvBreakdownQueryVariables>;