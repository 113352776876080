import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { useAddTeamMemberMutation } from './operations/add-team-member.generated'
import { useHistory } from 'react-router-dom'
import { useCurrentUserInfoQuery } from './operations/current-user-info.generated'
import { UserRoleType } from '../gql-global'
import { RESOURCES_ROUTE } from './routes'
import { useToast } from '../components/Alert/ToastProvider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: { fontWeight: 600 },
    field: {
      marginTop: theme.spacing(5),
      '& .MuiOutlinedInput-input': {
        height: 40,
      },
    },
    roundedRectangleButton: {
      borderRadius: 4,
      marginTop: theme.spacing(5),
    },
  }),
)

const schema = yup.object({
  currentUserEmail: yup.string().trim(),
  email1: yup.string().trim(),
  email2: yup.string().trim(),
})

export type AddTeamMembersFormFields = yup.InferType<typeof schema>

const defaultFields: AddTeamMembersFormFields = {
  currentUserEmail: '',
  email1: '',
  email2: '',
}

function AddTeamMembers(): React.ReactElement {
  const styles = useStyles()
  const history = useHistory()

  const { data: currentUserData, loading: currentUserDataIsLoading } = useCurrentUserInfoQuery()
  const [addTeamMember, { loading: addTeamMemberIsLoading }] = useAddTeamMemberMutation()
  const { showToast } = useToast()

  async function onSubmit(fields: AddTeamMembersFormFields) {
    const emails = []
    if (!!fields.email1.length) {
      emails.push(fields.email1)
    }
    if (!!fields.email2.length) {
      emails.push(fields.email2)
    }
    if (currentUserData?.whoami?.account?.id) {
      await Promise.all(
        emails.map(async email => {
          await addTeamMember({
            variables: {
              accountId: currentUserData?.whoami?.account?.id.toString() || '',
              email: email,
              roles: [UserRoleType.Main],
            },
          })
            .then(data => {
              showToast({
                severity: 'success',
                title: 'Success, created user:',
                message: data.data?.createUser?.user?.email || '',
              })
            })
            .catch(() => {
              showToast({ title: `Uh Oh! Was unable to add ${email}.`, message: '' })
            })
        }),
      )
      history.push(RESOURCES_ROUTE.path)
    }
  }

  const updatedFields = currentUserData?.whoami?.email
    ? { ...defaultFields, currentUserEmail: currentUserData.whoami.email }
    : defaultFields

  return (
    <Box width={484} margin="auto">
      <Formik<AddTeamMembersFormFields>
        initialValues={updatedFields}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form noValidate>
          <Box display="flex" flexDirection="column" alignItems="center" pt={27}>
            <Typography className={styles.bold} variant="h4">
              Invite your team
            </Typography>
            <Box mt={2} mb={6.5}>
              <Typography variant="body1">Invite your team to help drive Customer Led Growth.</Typography>
            </Box>
            <Field
              className={styles.field}
              variant="outlined"
              component={TextField}
              name="currentUserEmail"
              fullWidth
              disabled
            />
            <Field
              className={styles.field}
              variant="outlined"
              component={TextField}
              name="email1"
              fullWidth
              placeholder="Co-worker's Email"
            />
            <Field
              className={styles.field}
              variant="outlined"
              component={TextField}
              name="email2"
              fullWidth
              placeholder="Co-worker's Email"
            />
            <Button
              disabled={currentUserDataIsLoading || addTeamMemberIsLoading}
              className={styles.roundedRectangleButton}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              Save and Continue
            </Button>
            <Box mt={8}>
              <Link variant="subtitle2" component={RouterLink} to={RESOURCES_ROUTE.path} color="primary">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography>Skip this step</Typography>
                </Box>
              </Link>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}

export default AddTeamMembers
