import { COUNTRIES_LIST } from '../components/CustomField/countries'
import { STATES_LIST } from '../components/CustomField/states'
import { ParentChildOption } from '../components/lists/ListFilters/types'
import { CustomFieldEnumType } from '../gql-global'
import { CustomFieldFilterFragment } from './operations/custom-field-filter.generated'

const TEXT_FILTERS = [
  CustomFieldEnumType.Tiktok,
  CustomFieldEnumType.Instagram,
  CustomFieldEnumType.Text,
  CustomFieldEnumType.FirstName,
  CustomFieldEnumType.LastName,
]
const stateOptions = STATES_LIST.map(s => ({ id: s, label: s }))
const countryOptions = COUNTRIES_LIST.map(c => ({ id: c, label: c }))
function mapCustomField(customField: CustomFieldFilterFragment): ParentChildOption | null {
  if (TEXT_FILTERS.includes(customField.type)) {
    return {
      name: customField.id,
      type: 'keywords',
      label: customField.title,
      isCustomField: true,
      useChips: true,
      allowSpaces: true,
    }
  } else if (customField.type === CustomFieldEnumType.Select && customField.options) {
    return {
      name: customField.id,
      type: 'selection',
      label: customField.title,
      isCustomField: true,
      selectionOptions: customField.options.map(o => ({ id: o, label: o })),
    }
  } else if (customField.type === CustomFieldEnumType.State) {
    return {
      name: customField.id,
      type: 'selection',
      label: customField.title,
      isCustomField: true,
      selectionOptions: stateOptions,
    }
  } else if (customField.type === CustomFieldEnumType.Country) {
    return {
      name: customField.id,
      type: 'selection',
      label: customField.title,
      isCustomField: true,
      selectionOptions: countryOptions,
    }
  } else if (customField.type === CustomFieldEnumType.Date) {
    return {
      name: customField.id,
      type: 'dateRange',
      label: customField.title,
      isCustomField: true,
      entity: 'custom',
    }
  } else if (customField.type === CustomFieldEnumType.City) {
    return {
      name: customField.id,
      type: 'keywords',
      label: customField.title,
      isCustomField: true,
      useChips: true,
      operator: 'or',
    }
  } else if (customField.type === CustomFieldEnumType.Checkbox || customField.type === CustomFieldEnumType.SmsConsent) {
    return {
      name: customField.id,
      type: 'selection',
      label: customField.title,
      isCustomField: true,
      selectionOptions: [
        { id: 'true', label: 'True' },
        { id: 'false', label: 'False' },
      ],
    }
  }
  return null
}

export function createCustomFieldFilters(accountCustomFields: CustomFieldFilterFragment[]): ParentChildOption[] {
  return accountCustomFields.map(mapCustomField).filter((c): c is ParentChildOption => c !== null)
}
