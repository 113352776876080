import React from 'react'
import { ConcreteEventType } from './activity-types'
import { Box, Typography } from '@material-ui/core'
import ActivityListItemContent from './ActivityListItemContent'
import { ReactComponent as SegmentIcon } from '../icons/label.svg'
import LabelChip from '../components/labelChip'
import { CUSTOMER_ROUTE } from './routes'
import { encodeFilterParams } from './use-filter-params'
import { isTypeName } from '../types/utility'

interface SegmentActivityListItemProps {
  activity: ConcreteEventType<'AddSegmentCustomerEvent' | 'RemoveSegmentCustomerEvent'>
}

function SegmentActivityListItem({ activity }: SegmentActivityListItemProps): React.ReactElement {
  const actionType = isTypeName(activity, 'AddSegmentCustomerEvent') ? 'Added' : 'Removed'
  return (
    <ActivityListItemContent
      eventAt={activity.eventAt}
      actorName={activity.actor?.email || 'LoudCrowd'}
      action={
        !activity.segment.deletedAt
          ? {
              text: 'View customers in segment',
              to: {
                pathname: CUSTOMER_ROUTE.path,
                search: `?${encodeFilterParams({
                  segments: { all: [[activity.segment.id.toString()]] },
                })}`,
              },
            }
          : undefined
      }
      media={
        <Box m={4} p={3} width={48} height={48} borderRadius={24} bgcolor="warning.light" color="warning.main">
          <SegmentIcon width={24} height={24} />
        </Box>
      }
      text={
        <>
          <Typography variant="subtitle1" display="inline">
            {actionType} segment
          </Typography>
          <Box ml={1} display="inline">
            <LabelChip label={activity.segment.name} />
          </Box>
        </>
      }
    />
  )
}

export default SegmentActivityListItem
