import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  platform: Types.SocialPlatformEnum;
}>;


export type CampaignQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', endAt?: Date | null, id: number, startAt: Date, applicantStats?: { __typename: 'ParticipantStats', count: number } | null, program?: { __typename: 'ProgramType', autoApproved: boolean, currencyCode?: Types.CurrencyCodeEnum | null, id: string, ltvActive: boolean, name: string, programType?: Types.ProgramTypeEnum | null, ecommIntegration?: { __typename: 'CommerceCloudIntegration', id: string, keepUnattributedOrders?: boolean | null } | { __typename: 'CustomStoreIntegration', id: string, keepUnattributedOrders?: boolean | null } | { __typename: 'ShopifyIntegration', id: string, keepUnattributedOrders?: boolean | null } | null, hosts?: Array<{ __typename: 'OwnedProgramHost', id: string, lcId: string, url?: string | null }> | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> } | null } | null, whoami?: { __typename: 'UserType', id: number, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null } | null };


export const CampaignDocument = gql`
    query Campaign($id: ID!, $platform: SocialPlatformEnum!) {
  campaign(id: $id) {
    applicantStats: participantStats(
      where: {status: {any: [PENDING]}, platform: $platform}
    ) {
      count
    }
    endAt
    id
    program {
      autoApproved
      currencyCode
      ecommIntegration {
        ... on Node {
          id
        }
        ... on EcommStore {
          keepUnattributedOrders
        }
      }
      hosts {
        id
        lcId
        url
      }
      id
      ltvActive
      name
      programType
      socialAccounts {
        id
      }
    }
    startAt
  }
  whoami {
    id
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;