import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountLabelsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy: Types.LabelSort;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  where?: Types.InputMaybe<Types.LabelFilterInput>;
}>;


export type AccountLabelsQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, labels?: { __typename: 'PagedLabelType', cursor?: string | null, results: Array<{ __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string }> } | null, organization: { __typename: 'Organization', id: string, labelLimit: { __typename: 'LabelLimit', hasHitLimit: boolean, isUnlimited: boolean, limit?: number | null, used: number } } } | null };


export const AccountLabelsDocument = gql`
    query AccountLabels($accountId: ID!, $cursor: String, $limit: Int, $sortBy: LabelSort!, $sortDirection: SortDirection, $where: LabelFilterInput) {
  account(id: $accountId) {
    id
    labels(
      cursor: $cursor
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
      where: $where
    ) {
      cursor
      results {
        id
        isRejectionReason
        name
      }
    }
    organization {
      id
      labelLimit {
        hasHitLimit
        isUnlimited
        limit
        used
      }
    }
  }
}
    `;

/**
 * __useAccountLabelsQuery__
 *
 * To run a query within a React component, call `useAccountLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLabelsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAccountLabelsQuery(baseOptions: Apollo.QueryHookOptions<AccountLabelsQuery, AccountLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountLabelsQuery, AccountLabelsQueryVariables>(AccountLabelsDocument, options);
      }
export function useAccountLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountLabelsQuery, AccountLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountLabelsQuery, AccountLabelsQueryVariables>(AccountLabelsDocument, options);
        }
export type AccountLabelsQueryHookResult = ReturnType<typeof useAccountLabelsQuery>;
export type AccountLabelsLazyQueryHookResult = ReturnType<typeof useAccountLabelsLazyQuery>;
export type AccountLabelsQueryResult = Apollo.QueryResult<AccountLabelsQuery, AccountLabelsQueryVariables>;