import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  InputAdornment,
  OutlinedInput,
  makeStyles,
  createStyles,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Tooltip,
  MenuProps,
  Link, Typography,
} from '@material-ui/core'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as LabelIcon } from '../../icons/label.svg'
import { ReactComponent as EyeIcon } from '../../icons/eye.svg'
import { ReactComponent as PencilIcon } from '../../icons/edit_pencil.svg'
import { ReactComponent as CheckIcon } from '../../icons/checkmark.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { ReactComponent as QuestionMarkIcon } from '../../icons/question-mark_major_monotone.svg'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import { primary } from '../../loudcrowd-theme'
import EditLabel from './EditLabel'
import LabelMenuWrapper from './LabelMenuWrapper'

export interface AccountLabel<T extends string | number = number> {
  id: T
  name: string
  isRejectionReason?: boolean
}

export type LabelMenuActions = 'update' | 'delete'

type BaseLabelMenuProps<T extends string | number> = {
  entity: string
  seeAllLink?: string
  open: boolean
  title?: string
  labels: AccountLabel<T>[]
  selectedLabelIds?: Set<T> | 'ALL' | 'PROGRAM'
  onSelect(id: T): void
  onCancel(): void
  variant?: 'popover' | 'dialog'
  anchorEl?: MenuProps['anchorEl']
  editable?: boolean
  noListIcons?: boolean
  style?: React.CSSProperties
}

type ReadOnlyLabelMenuProps<T extends string | number> = BaseLabelMenuProps<T> & { editable: false }

type EditableLabelMenuProps<T extends string | number> = BaseLabelMenuProps<T> & {
  onCreate?(name: string): void
  onUpdate(id: T, name: string): void
  onDelete(id: T): void
  hasHitLimit: boolean
  editable: true
  allowedActions: LabelMenuActions[]
  extraCreateItem?: string
}

type LabelMenuProps<T extends string | number> = ReadOnlyLabelMenuProps<T> | EditableLabelMenuProps<T>

const useStyles = makeStyles(theme =>
  createStyles({
    search: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: '150%',
    },
    searchInput: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: 21,
    },
    listSubheader: {
      textTransform: 'capitalize',
      backgroundColor: 'white',
    },
    listContainer: {
      maxHeight: 360,
      overflow: 'scroll',
    },
    listIcon: {
      minWidth: 28,
      minHeight: 28,
      justifyContent: 'center',
      alignItems: 'center',
      color: 'inherit',
      marginRight: 12,
    },
    listIconEdit: {
      opacity: 0,
      // sync showing edit icon w/ list item backgroundColor transition on hover
      transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shortest }),

      '&:hover': {
        backgroundColor: primary[200],
        borderRadius: 4,
      },
    },
    listItemButton: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,

        '& $listIconEdit': {
          opacity: 1,
        },
      },
    },
    addButton: {
      color: theme.palette.primary.main,
      paddingLeft: 16,
      height: 40,
      borderRadius: 4,
    },
  }),
)

function isEditableLabelMenu<T extends string | number>(p: LabelMenuProps<T>): p is EditableLabelMenuProps<T> {
  return !!p.editable
}

function LabelMenu<T extends string | number>(props: LabelMenuProps<T>): React.ReactElement {
  const { entity, seeAllLink, labels, open, onSelect, onCancel } = props
  const selectedLabelIds = props.selectedLabelIds || new Set<T>()
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const [shownLabels, setShownLabels] = useState(labels)
  const [editingLabel, setEditingLabel] = useState<AccountLabel<T> | null>(null)
  const isOpenRef = useRef(open)
  useEffect(() => {
    let filtered = labels
    const lowerSearchText = searchText.toLowerCase()
    if (searchText) {
      filtered = labels
        .map(l => ({
          ...l,
          index: l?.name?.toLowerCase().indexOf(lowerSearchText),
        }))
        .filter(l => l.index >= 0)
        .sort(l => l.index)
    }
    setShownLabels(filtered)
  }, [labels, searchText])
  useEffect(() => {
    if (isOpenRef.current !== open) {
      isOpenRef.current = open
      if (open) {
        setSearchText('')
      }
    }
  }, [open])
  const handleEditIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, label: AccountLabel<T>): void => {
    e.stopPropagation()
    setEditingLabel(label)
  }
  const handleClose = (): void => {
    setEditingLabel(null)
    onCancel()
  }
  const showAddLabel =
    searchText && isEditableLabelMenu(props) && props.onCreate && !shownLabels.some(l => l.name === searchText)
  const canUpdate = isEditableLabelMenu(props) && props.allowedActions.includes('update')

  function renderListIcon(label: AccountLabel<T>, selectedLabelIds: Set<T> | 'ALL' | 'PROGRAM') {
    if (selectedLabelIds !== 'ALL' && selectedLabelIds !== 'PROGRAM' && selectedLabelIds.has(label.id)) {
      return <CheckIcon width={12} />
    }
    if (!!label.isRejectionReason) {
      return <EyeIcon width={20} height={20} />
    }
    return <LabelIcon width={16} />
  }

  return (
    <LabelMenuWrapper
      open={open}
      variant={props.variant || 'popover'}
      anchorEl={props.variant !== 'dialog' ? props.anchorEl : undefined}
      onClose={handleClose}
      style={props.style}
    >
      {editingLabel && (
        <Box>
          <EditLabel
            id={editingLabel.id}
            name={editingLabel.name}
            labels={labels}
            canDelete={isEditableLabelMenu(props) && props.allowedActions.includes('delete')}
            onCancel={() => setEditingLabel(null)}
            onSave={(id, name) => {
              if (!isEditableLabelMenu(props)) return
              props.onUpdate(id, name)
              setEditingLabel(null)
            }}
            onDelete={id => {
              if (!isEditableLabelMenu(props)) return
              props.onDelete(id)
              setEditingLabel(null)
            }}
          />
        </Box>
      )}
      {!editingLabel && (
        <Box width={392}>
          {seeAllLink && (
            <Box display="flex" justifyContent="flex-end" mr={2} mb={1}>
              <Link
                variant="body2"
                color="primary"
                component={RouterLink}
                to={{
                  pathname: seeAllLink,
                }}
              >
                <Box display="inline-flex" alignItems="center">
                  See all
                  <Box display="inline" ml={2}>
                    <ArrowIcon width={12} height={12} transform="rotate(-90)" />
                  </Box>
                </Box>
              </Link>
            </Box>
          )}
          {props.title && <Typography variant="h6" style={{margin: '10px 10px'}}>{props.title}</Typography>}
          <Box marginX={5}>
            <OutlinedInput
              classes={{ root: classes.search, input: classes.searchInput }}
              type="text"
              autoFocus
              fullWidth
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon width={16} height={16} />
                </InputAdornment>
              }
            />
          </Box>
          <List
            className={classes.listContainer}
            subheader={
              <ListSubheader className={classes.listSubheader}>
                {!searchText && 'Recent '}
                {entity}s
              </ListSubheader>
            }
          >
            {shownLabels.map(l => (
              <ListItem
                key={l.id}
                button
                onClick={() =>
                  !(selectedLabelIds !== 'ALL' && selectedLabelIds !== 'PROGRAM' && selectedLabelIds.has(l.id)) &&
                  onSelect(l.id)
                }
                classes={{ button: classes.listItemButton }}
              >
                {!props.noListIcons && (
                  <ListItemIcon className={classes.listIcon}>{renderListIcon(l, selectedLabelIds)}</ListItemIcon>
                )}
                <ListItemText primary={l.name} />
                {canUpdate && (
                  <ListItemIcon className={`${classes.listIcon} ${classes.listIconEdit}`}>
                    <PencilIcon width={16} onClick={e => handleEditIconClick(e, l)} />
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
            {isEditableLabelMenu(props) && props.extraCreateItem && (
                <ListItem
                    button
                    disabled={props.hasHitLimit}
                    className={classes.addButton}
                    classes={{ button: classes.listItemButton }}
                    onClick={() => isEditableLabelMenu(props) && props.onCreate && props.onCreate(searchText)}
                >
                  <ListItemText primary={props.extraCreateItem} />
                  {props.hasHitLimit && (
                      <ListItemSecondaryAction>
                        <Tooltip title={`Account cannot create more ${entity}s. Contact support to increase limit.`}>
                          <QuestionMarkIcon width={13} />
                        </Tooltip>
                      </ListItemSecondaryAction>
                  )}
                </ListItem>
            )}
            {!(isEditableLabelMenu(props) && props.extraCreateItem) && !shownLabels.length && (
              <ListItem>
                {isEditableLabelMenu(props) && !searchText
                  ? `No ${entity}s, type to add a new one.`
                  : `No matching ${entity}s.`}
              </ListItem>
            )}
            {showAddLabel && isEditableLabelMenu(props) && !props.extraCreateItem && (
              <>
                <Divider />
                <Box px={5} mt={2}>
                  <ListItem
                    button
                    disabled={props.hasHitLimit}
                    className={classes.addButton}
                    classes={{ button: classes.listItemButton }}
                    onClick={() => isEditableLabelMenu(props) && props.onCreate && props.onCreate(searchText)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <PlusIcon width={13} />
                    </ListItemIcon>
                    <ListItemText primary={searchText} primaryTypographyProps={{ variant: 'subtitle2' }} />
                    {props.hasHitLimit && (
                      <ListItemSecondaryAction>
                        <Tooltip title={`Account cannot create more ${entity}s. Contact support to increase limit.`}>
                          <QuestionMarkIcon width={13} />
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Box>
              </>
            )}
          </List>
        </Box>
      )}
    </LabelMenuWrapper>
  )
}

export default LabelMenu
