import {
  EcommDiscountAppliesToEnum,
  EcommDiscountClassEnum,
  EcommDiscountCodeFormatEnum,
  EcommDiscountPurchaseTypeEnum,
} from '../../../gql-global'

export const DISCOUNT_CODE_FORMAT = [
  { value: EcommDiscountCodeFormatEnum.FirstLastName, label: 'First & Last Name' },
  { value: EcommDiscountCodeFormatEnum.SocialUsername, label: 'Social Username' },
]

export const DISCOUNT_CLASS_SELECTION_VALUES = [
  { value: EcommDiscountClassEnum.Product, label: 'Product Discount (Amount off product)' },
  { value: EcommDiscountClassEnum.Order, label: 'Order Discount (Amount off order)' },
]

export const SHOPIFY_DISCOUNT_APPLIES_TO_ITEM_TYPE = [
  { value: EcommDiscountAppliesToEnum.Product, label: 'Specific Products' },
  { value: EcommDiscountAppliesToEnum.Collection, label: 'Specific Collections' },
]

export const SALESFORCE_DISCOUNT_APPLIES_TO_ITEM_TYPE = [
  { value: EcommDiscountAppliesToEnum.Product, label: 'Specific Products' },
  { value: EcommDiscountAppliesToEnum.Collection, label: 'Specific Categories' },
]

export const DISCOUNT_PURCHASE_TYPE = [
  { value: EcommDiscountPurchaseTypeEnum.OneTimePurchase, label: 'One Time Purchase' },
  { value: EcommDiscountPurchaseTypeEnum.Subscription, label: 'Subscription' },
  { value: EcommDiscountPurchaseTypeEnum.Both, label: 'Both' },
]
