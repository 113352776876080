import React, { ReactElement, useRef, useState } from 'react'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { Box, Button, createStyles, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginLeft: 11,
    },
  }),
)

interface ChallengeSelectorPropsType {
  challenges?: Array<{ id: string; name: string }>
  onSelectChallenge(challenge: { id: string; name: string }): void
}

const ChallengeSelector = ({ challenges, onSelectChallenge }: ChallengeSelectorPropsType): ReactElement => {
  const classes = useStyles()
  const statusButton = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  function handleChangeStatus(challenge: { id: string; name: string }) {
    onSelectChallenge(challenge)
    setOpen(false)
  }

  return (
    <Box className={classes.container}>
      <Button
        color="primary"
        ref={statusButton}
        onClick={() => setOpen(true)}
        endIcon={<ChevronDownIcon height={16} width={16} />}
      >
        <Typography variant="subtitle2">Select challenge</Typography>
      </Button>
      <Menu
        open={open}
        anchorEl={statusButton.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setOpen(false)}
      >
        {challenges?.map(challenge => (
          <MenuItem key={challenge.id} onClick={() => handleChangeStatus(challenge)}>
            <Typography variant="subtitle2" color="primary">
              {challenge.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default ChallengeSelector
