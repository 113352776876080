import { Box, Button, Collapse, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import RewardList from '../RewardList/RewardList'
import React, { useState } from 'react'
import { RewardRowFragment } from '../RewardList/operations/rewards.generated'
import { rewardDeliveryMethodCaption, sendRewardAndHandleResult } from '../../utils/rewards'
import { LOADING_BATCH_SIZE as ACTIVITIES_BATCH_SIZE } from '../../customer/CustomerDetailActivityFeed'
import { PostDetails_IgMention_Fragment, PostDetails_TtMention_Fragment } from './operations/post-details.generated'
import { useSendRewardMutation } from '../../customer/operations/send-reward.generated'
import { usePostUserRewardsHistoryQuery } from './operations/post-user-rewards-history.generated'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up_minor.svg'
import { differenceInMinutes, format } from 'date-fns'
import { ReactComponent as PresentIcon } from '../../icons/present.svg'
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { useToast } from '../Alert/ToastProvider'

interface PostDetailsRewardTabProps {
  selectedPost?: PostDetails_IgMention_Fragment | PostDetails_TtMention_Fragment | null
  socialAccountId?: string | null
}

const useStyles = makeStyles(({ palette: { primary, secondary } }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 480,
      height: 680,
      paddingTop: 28,
      overflow: 'hidden',
      alignItems: 'flex-start',
      '& .MuiCollapse-entered': {
        display: 'flex',
        flex: 'auto',
        height: '100% !important', // MuiCollapse adds height styling on the element, so we need to override it
        '& .MuiButton-contained': {
          opacity: 1,
          transition: 'opacity 0.2s',
        },
      },
      '& .MuiCollapse-wrapper': {
        width: '100%',
      },
      '& .MuiButton-contained': {
        opacity: 0,
        transition: 'opacity 0.2s',
      },
    },
    toggleButton: {
      color: secondary.main,
      fontSize: 16,
      '& svg': {
        color: primary.main,
      },
    },
    skeleton: {
      padding: '25px 15px',
      marginTop: -35,
    },
  }),
)

export default function PostDetailsRewardTab({
  selectedPost,
  socialAccountId,
}: PostDetailsRewardTabProps): React.ReactElement {
  const customerId = selectedPost?.media.poster.customer?.id?.toString() ?? ''
  const mediaId = selectedPost?.media.id
  const { showToast } = useToast()
  const [sendReward] = useSendRewardMutation()
  const { data } = usePostUserRewardsHistoryQuery({
    skip: !customerId || !socialAccountId,
    variables: { id: customerId, socialAccountId: socialAccountId ?? '', mediaId: mediaId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  const classes = useStyles()
  const [viewingHistory, setViewingHistory] = useState<boolean>(false)

  const rewardsHistoryCount = data?.socialAccount?.customer?.events?.results.length ?? 0

  async function handleSendReward(reward: RewardRowFragment, offline: boolean): Promise<void> {
    if (!customerId) {
      showToast({
        title: 'Error: Sending Reward',
        message: "Can't send reward to the user from this content",
        severity: 'error',
      })
      return
    }

    await sendRewardAndHandleResult(
      reward,
      sendReward,
      {
        rewardId: reward.id.toString(),
        customerId: customerId,
        mediaId: mediaId,
        offlineDelivery: offline,
        socialAccountId: socialAccountId || '',
        limit: ACTIVITIES_BATCH_SIZE,
      },
      offline,
      showToast,
    )
  }

  const toggleViewingHistory = () => setViewingHistory(value => !value)

  return (
    <Box position="relative" className={classes.wrapper}>
      {rewardsHistoryCount > 0 && (
        <Button
          onClick={toggleViewingHistory}
          className={classes.toggleButton}
          endIcon={!viewingHistory ? <ChevronDownIcon width={20} /> : <ChevronUpIcon width={20} />}
        >
          View reward history
        </Button>
      )}
      <Box px={4} width="100%" height="100%">
        <Collapse in={viewingHistory}>
          <Box height="80%" style={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            {data?.socialAccount?.customer?.events?.results.reduce(
              (result: React.ReactElement[], e): React.ReactElement[] => {
                // Because of the query we are making, this won't ever actually happen, we are just doing
                // it so TypeScript can narrow the types
                if (e.__typename !== 'FulfilledTierCustomerEvent' || !e.fulfillment?.reward) return result
                const reward = e.fulfillment.reward
                const eventAtCaption =
                  differenceInMinutes(new Date(), e.eventAt) > 5 ? `${format(e.eventAt, 'P haaa')}` : 'Just now'

                const reward_icon = e.fulfillment.challenge?.id ? (
                  <TrophyIcon width={20} height={20} style={{ margin: '0 20px 0 20px' }} />
                ) : (
                  <PresentIcon width={20} height={20} style={{ margin: '0 20px 0 20px' }} />
                )
                const postfixReward = !!e.fulfillment?.rewardDiscountCode?.code
                  ? ` - ${e.fulfillment?.rewardDiscountCode?.code}`
                  : ''
                result.push(
                  <Box mt={4}>
                    <Typography variant="caption" display="block" color="secondary">
                      Reward sent at {eventAtCaption}
                    </Typography>
                    <Typography variant="caption" display="block" color="secondary">
                      Delivery Method: {rewardDeliveryMethodCaption(reward)}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: '#f3f3f3',
                        minHeight: '50px',
                        width: '90%',
                        borderRadius: '10px',
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        alignItems: 'center',
                      }}
                    >
                      {reward_icon}
                      <Typography variant="body1" style={{ fontWeight: 600 }}>
                        {reward.name}
                        {postfixReward}
                      </Typography>
                    </div>
                  </Box>,
                )
                return result
              },
              [],
            )}
          </Box>
          <Box display="flex" height="100px" alignItems="center" marginLeft="20%">
            <Button color="primary" onClick={toggleViewingHistory}>
              <Box display="flex" alignItems="center">
                <Box component="span" mr={2} lineHeight={0}>
                  <PlusIcon width={20} />
                </Box>
                <Typography variant="subtitle1">Send another reward</Typography>
              </Box>
            </Button>
          </Box>
        </Collapse>
        <Collapse in={!viewingHistory}>
          {rewardsHistoryCount > 0 && (
            <Box display="flex" mb={4} justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color="secondary">
                {rewardsHistoryCount} other rewards
              </Typography>
            </Box>
          )}
          <Box height={rewardsHistoryCount > 0 ? '85%' : '95%'}>
            <RewardList onSendReward={handleSendReward} canCopyCode canAutoDeliver />
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}
