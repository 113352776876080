import { Route } from '../types/route'

export const ADMIN_ROUTE = new Route('/admin', ['ADMIN', 'OWNER'])
export const ADMIN_CUSTOMER_UPLOAD_ROUTE = ADMIN_ROUTE.subroute('/customer_upload', ['ADMIN', 'OWNER'])
export const ADMIN_ACCOUNTS_ROUTE = ADMIN_ROUTE.subroute('/accounts', ['ADMIN'])
export const ADMIN_ACCOUNT_USERS_ROUTE = ADMIN_ACCOUNTS_ROUTE.subroute('/:id/users', ['ADMIN'])
export const ADMIN_ADD_STORY_MENTION = ADMIN_ROUTE.subroute('/add-segment', ['ADMIN'])
export const ADMIN_FEATURE_OVERRIDE = ADMIN_ROUTE.subroute('/feature-override', ['ADMIN'])
export const ADMIN_SEND_MONTHLY_EMAIL = ADMIN_ROUTE.subroute('/send-monthly-email', ['ADMIN'])
export const ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS = ADMIN_ROUTE.subroute('/tiktok-social-account-hashtags', ['ADMIN'])
export const ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNT = ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS.subroute('/:id', ['ADMIN'])
