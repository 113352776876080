import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LocationDescriptor } from 'history'
import { Box, Button, Paper, Tooltip, Zoom } from '@material-ui/core'
import { ReactComponent as ClockIcon } from '../icons/clock.svg'
import { formatDistanceToNow, format, isAfter, addMinutes } from 'date-fns'

export interface ActivityListItemContentProps {
  media: React.ReactNode
  text: React.ReactNode | string
  eventAt: Date
  actorName?: string
  action?: { text: string } & ({ onAction(): void } | { to: LocationDescriptor })
}

type Ref = React.Ref<unknown>

const ActivityListItemContentInner = forwardRef<Ref, ActivityListItemContentProps>(
  ({ media, text, eventAt, actorName, action }, ref) => (
    <Paper ref={ref}>
      <Box py={3} px={5} display="flex" alignItems="center">
        <Box width={80} height={80}>
          {media}
        </Box>
        <Box ml={6} flexGrow={1}>
          <Box display="flex" alignItems="center">
            {text}
          </Box>
          <Box display="flex" color="secondary.main" alignItems="center">
            <ClockIcon width={12} height={12} />
            <Box ml={1}>
              <Tooltip title={format(eventAt, 'PPp')} placement="top-start">
                <span>{formatDistanceToNow(eventAt, { addSuffix: true })}</span>
              </Tooltip>
              {actorName && ` by ${actorName.includes('@loudcrowd.com') ? 'LoudCrowd' : actorName}`}
            </Box>
          </Box>
        </Box>
        {action && 'to' in action && (
          <Button color="primary" component={RouterLink} to={action.to}>
            {action.text}
          </Button>
        )}
        {action && 'onAction' in action && (
          <Button color="primary" onClick={action.onAction}>
            {action.text}
          </Button>
        )}
      </Box>
    </Paper>
  ),
)

function ActivityListItemContent(props: ActivityListItemContentProps): React.ReactElement {
  const isNow = isAfter(addMinutes(props.eventAt, 1), new Date())

  if (isNow) {
    return (
      <Box flex>
        <Zoom in={isNow} style={{ transitionDelay: isNow ? '500ms' : '0ms' }}>
          <Paper elevation={4}>
            <ActivityListItemContentInner {...props} />
          </Paper>
        </Zoom>
      </Box>
    )
  }
  return <ActivityListItemContentInner {...props} />
}

export default ActivityListItemContent
