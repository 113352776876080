import React, { useState } from 'react'
import { Box, Button, Dialog, Checkbox, DialogActions, DialogTitle, FormControlLabel } from '@material-ui/core'
import UploadRewardCodes from './UploadRewardCodes'
import { SettingsRewardRowFragment } from './operations/settings-reward-row.generated'
import { UploadedFile } from '../../global'

interface UploadRewardCodesDialogProps {
  accountId: number | null | undefined
  reward: SettingsRewardRowFragment | null
  open: boolean
  onClose: () => void
  onSave: (id: number, bucketKey: string, reenableMessages: boolean) => void
}

type TemplateType = NonNullable<NonNullable<SettingsRewardRowFragment['messageTemplates']>[number]>

const UploadRewardCodesDialog: React.FC<UploadRewardCodesDialogProps> = ({
  open,
  onClose,
  onSave,
  reward,
  accountId,
}) => {
  const [selectedFile, setSelectedFile] = useState<UploadedFile | null>(null)
  const [reenable, setReenable] = useState(false)
  const codesRemaining = reward?.stats.codesRemaining
  const disabledAutoMessages = reward?.messageTemplates?.filter(
    (t: TemplateType | null): t is TemplateType => !!t?.automation && !t.automation.enabled,
  )
  const disabledAutoMessageNames = disabledAutoMessages?.map(t => t.name).join('", "')
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload reward codes</DialogTitle>

      <Box mx={9} my={6}>
        <UploadRewardCodes accountId={accountId || null} onSelectFile={(file: UploadedFile) => setSelectedFile(file)} />
        {disabledAutoMessageNames && disabledAutoMessages && codesRemaining === 0 && (
          <Box mt={2} width={348}>
            <FormControlLabel
              control={<Checkbox checked={reenable} onChange={v => setReenable(v.target.checked)} name="reenable" />}
              label={`Turn on disabled auto message${
                disabledAutoMessages.length > 1 ? 's' : ''
              }: "${disabledAutoMessageNames}"`}
            />
          </Box>
        )}
      </Box>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          color="secondary"
        >
          Cancel
        </Button>
        {reward && (
          <Button
            onClick={() => {
              onSave(reward.id, selectedFile?.bucketKey || '', reenable)
            }}
            color="primary"
            disabled={!selectedFile?.bucketKey}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default UploadRewardCodesDialog
