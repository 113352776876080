import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportCampaignActivityQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.EventsInput>;
}>;


export type ExportCampaignActivityQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, events?: { __typename: 'PagedCampaignEventType', csvUrl?: string | null } | null } | null };


export const ExportCampaignActivityDocument = gql`
    query ExportCampaignActivity($campaignId: ID!, $limit: Int, $cursor: String, $where: EventsInput) {
  campaign(id: $campaignId) {
    id
    events(limit: $limit, cursor: $cursor, where: $where) {
      csvUrl
    }
  }
}
    `;

/**
 * __useExportCampaignActivityQuery__
 *
 * To run a query within a React component, call `useExportCampaignActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCampaignActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCampaignActivityQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExportCampaignActivityQuery(baseOptions: Apollo.QueryHookOptions<ExportCampaignActivityQuery, ExportCampaignActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCampaignActivityQuery, ExportCampaignActivityQueryVariables>(ExportCampaignActivityDocument, options);
      }
export function useExportCampaignActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCampaignActivityQuery, ExportCampaignActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCampaignActivityQuery, ExportCampaignActivityQueryVariables>(ExportCampaignActivityDocument, options);
        }
export type ExportCampaignActivityQueryHookResult = ReturnType<typeof useExportCampaignActivityQuery>;
export type ExportCampaignActivityLazyQueryHookResult = ReturnType<typeof useExportCampaignActivityLazyQuery>;
export type ExportCampaignActivityQueryResult = Apollo.QueryResult<ExportCampaignActivityQuery, ExportCampaignActivityQueryVariables>;