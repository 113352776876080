/* eslint-disable @typescript-eslint/no-explicit-any */
/*  eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useRef } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { ValueOf } from '../../../types/utility'
import JoinedChildren from '../../JoinedChildren'
import { Option, Options, Filters } from './types'
import AddListFilterDialog from './AddListFilterDialog'
import { DateRangeFilterChip } from './DateRangeFilterMenu'
import {
  LabelsFilter,
  RelativeDateRangeFilter,
  AbsoluteDateTimeRangeFilter,
  AbsoluteDateRangeFilter,
} from '../../../gql-global'
import { NumericalRange } from '../../NumericalRangePicker'
import { BulkChipsFilterChip } from './BulkChipsFilterMenu'
import { NumericalRangeFilterChip } from './NumericalRangeFilterMenu'
import { SelectionFilterChip } from './SelectionFilterMenu'
import { KeywordsFilterChip } from './KeywordFilterMenu'
import { SingleSelectionFilterChip } from './SingleSelectionFilterMenu'
import { MenuSelectionFilterChip } from './MenuFilter'
import { BulkKeywordsFilterChip } from './BulkKeywordsFilterMenu'
import { secondary } from '../../../loudcrowd-theme'

const useStyles = makeStyles({
  excludeExpander: {
    cursor: 'pointer',
  },
  boldText: {
    fontWeight: 'bold',
  },
  selectFilterText: {
    color: secondary[500],
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.625,
    borderRadius: 0,
    marginBottom: 4,
    padding: 0,
    height: 'auto',
  },
})

interface TextFiltersProps {
  readonly options: Options
  filters: Filters
  onChangeFilters(newFilters: Filters): void
  editable?: boolean
}

const findNestedOption = (arr: Options = [], itemName: string): Option | null =>
  arr.reduce((previousValue: Option | null, nextValue: Option): Option | null => {
    if (previousValue) return previousValue
    if (nextValue.name === itemName) return nextValue
    if (nextValue && nextValue.type === 'parent' && nextValue.children)
      return findNestedOption(nextValue.children, itemName)
    return null
  }, null)

function TextFilters({ options, filters, onChangeFilters, editable = true }: TextFiltersProps): React.ReactElement {
  const classes = useStyles()
  const [addFilterDialogOpen, setAddFilterDialogOpen] = useState(false)
  const addFilterButtonRef = useRef<HTMLButtonElement>(null)
  function handleDelete(name: string): void {
    onChangeFilters({
      ...filters,
      [name]: undefined,
    })
  }

  function handleValueSelected(selectedOption: Option, value: ValueOf<Filters>): void {
    onChangeFilters({
      ...filters,
      [selectedOption.name]: value,
    })
  }

  function handleSetAddFilters(selectedOption: Option, value: ValueOf<Filters>): void {
    onChangeFilters({
      ...filters,
      [selectedOption.name]: value,
    })
    if (!['keywordsChips', 'chips'].includes(selectedOption.type)) {
      setAddFilterDialogOpen(false)
    }
  }

  const addOptions = options.filter(({ name, type }) => {
    return (
      (type !== 'singleSelection' && filters[name] === null) ||
      filters[name] === undefined ||
      type === 'labels' ||
      type === 'chips' ||
      type === 'keywordsChips'
    )
  })

  const includedFilters = Object.entries(filters).filter((f: any) => {
    const filterValue = f[1]
    const hasFilterValue = filterValue !== null && filterValue !== undefined
    const valueIsString = typeof filterValue === 'string'
    const valueIsObject = typeof filterValue === 'object'
    const valueIsArray = hasFilterValue && Array.isArray(filterValue)
    const hasAll = filterValue && filterValue.hasOwnProperty('all') && filterValue.all?.length > 0
    const hasAny = filterValue && filterValue.hasOwnProperty('any') && filterValue.any?.length > 0
    const hasNone = filterValue && filterValue.hasOwnProperty('none') && filterValue.none?.length > 0
    const isDateRangeFilter = valueIsObject && !valueIsArray && !hasAll && !hasAny && !hasNone
    const valueIsValidObject = (valueIsObject && (hasAll || hasAny || hasNone)) || isDateRangeFilter

    if (hasFilterValue && (valueIsString || valueIsArray || valueIsValidObject)) {
      return options.some(o => {
        if (o.type === 'parent' && o.children) {
          return o.children.some(oC => oC.name === f[0])
        } else {
          return o.name === f[0]
        }
      })
    }
    return false
  })

  const allFiltersSelected = includedFilters.length === options.length
  return (
    <>
      <JoinedChildren joinElement={<> and </>}>
        {includedFilters.reduce((a: React.ReactElement[], [name, value]: [string, unknown]): React.ReactElement[] => {
          const option = findNestedOption(options, name)
          if (!option || option.type === 'parent') {
            return a
          }

          if (option.type === 'singleMenu') {
            const selectedValue = value as string
            return [
              ...a,
              <MenuSelectionFilterChip
                key={option.name}
                option={option}
                value={selectedValue}
                editable={editable}
                onDelete={() => handleDelete(name)}
                onSelectValue={v => handleValueSelected(option, v)}
              />,
            ]
          } else if (option.type === 'dateRange') {
            const dateValue = value as RelativeDateRangeFilter | AbsoluteDateTimeRangeFilter | AbsoluteDateRangeFilter

            return [
              ...a,
              <DateRangeFilterChip
                key={option.name}
                option={option}
                value={dateValue}
                onDelete={() => handleDelete(name)}
                onSelectValue={v => handleValueSelected(option, v)}
              />,
            ]
          } else if (option.type === 'numericalRange') {
            return [
              ...a,
              <NumericalRangeFilterChip
                key={option.name}
                option={option}
                value={value as NumericalRange}
                onSelectValue={v => handleValueSelected(option, v)}
                filterChipProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
                editable={editable}
              />,
            ]
          } else if (option.type === 'chips') {
            const labelValue = value as LabelsFilter
            const labelIsEmpty =
              (labelValue?.none === undefined || labelValue?.none?.length === 0) &&
              (labelValue?.any === undefined || labelValue?.any?.length === 0)
            return [
              ...a,
              !labelIsEmpty ? (
                <BulkChipsFilterChip
                  key={`BulkChipsFilterChip-${option.name}`}
                  option={option}
                  value={labelValue}
                  onDelete={() => handleDelete(name)}
                  onSelectValue={v => handleValueSelected(option, v)}
                  editable={editable}
                />
              ) : (
                <></>
              ),
            ]
          } else if (option.type === 'keywordsChips') {
            const labelValue = value as LabelsFilter
            const labelIsEmpty =
              (labelValue?.none === undefined || labelValue?.none?.length === 0) &&
              (labelValue?.any === undefined || labelValue?.any?.length === 0)
            return [
              ...a,
              !labelIsEmpty ? (
                <BulkKeywordsFilterChip
                  key={`BulkChipsFilterChip-${option.name}`}
                  option={option}
                  value={labelValue}
                  onDelete={() => handleDelete(name)}
                  onSelectValue={v => handleValueSelected(option, v)}
                  editable={editable}
                />
              ) : (
                <></>
              ),
            ]
          } else if (option.type === 'selection') {
            const selectValue = value as Set<string>
            return [
              ...a,
              <SelectionFilterChip
                key={option.name}
                option={option}
                value={selectValue}
                onDelete={() => handleDelete(name)}
                onSelectValue={v => handleValueSelected(option, v)}
              />,
            ]
          } else if (option.type === 'keywords') {
            const keywordsValue = value as string[]
            return [
              ...a,
              <KeywordsFilterChip
                key={option.name}
                option={option}
                value={keywordsValue}
                onDelete={() => handleDelete(name)}
                onSelectValue={v => handleValueSelected(option, v)}
                isExclusion={false}
              />,
            ]
          } else if (option.type === 'singleSelection') {
            const selectedValue = value as string
            return [
              ...a,
              <SingleSelectionFilterChip
                key={option.name}
                option={option}
                value={selectedValue}
                onDelete={() => handleDelete(name)}
              />,
            ]
          }

          return a
        }, [])}
      </JoinedChildren>
      {!allFiltersSelected && editable && (
        <>
          {' '}
          <Button
            ref={addFilterButtonRef}
            style={{ display: 'inline' }}
            className={classes.selectFilterText}
            variant="text"
            onClick={() => setAddFilterDialogOpen(true)}
          >
            {!!includedFilters?.length ? 'and more criteria' : 'select criteria'}
          </Button>
        </>
      )}{' '}
      <AddListFilterDialog
        filters={filters}
        open={addFilterDialogOpen}
        anchorEl={addFilterButtonRef.current}
        options={addOptions}
        onCancel={() => setAddFilterDialogOpen(false)}
        onDelete={handleDelete}
        onAddFilter={handleSetAddFilters}
      />
    </>
  )
}

export default TextFilters
