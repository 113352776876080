export const TREMENDOUS_CURRENCY_CODES = [
  { code: 'USD', name: 'USD - US Dollar' },
  { code: 'AED', name: 'AED - UAE Dirham' },
  { code: 'AUD', name: 'AUD - Australian Dollar' },
  { code: 'CAD', name: 'CAD - Canadian Dollar' },
  { code: 'CHF', name: 'CHF - Swiss Franc' },
  { code: 'CZK', name: 'CZK - Czech Koruna' },
  { code: 'DKK', name: 'DKK - Danish Krone' },
  { code: 'EUR', name: 'EUR - Euro' },
  { code: 'GBP', name: 'GBP - Pound Sterling' },
  { code: 'HKD', name: 'HKD - Hong Kong Dollar' },
  { code: 'HUF', name: 'HUF - Forint' },
  { code: 'IDR', name: 'IDR - Rupiah' },
  { code: 'INR', name: 'INR - Indian Rupee' },
  { code: 'JPY', name: 'JPY - Yen' },
  { code: 'MYR', name: 'MYR - Malaysian Ringgit' },
  { code: 'NOK', name: 'NOK - Norwegian Krone' },
  { code: 'NZD', name: 'NZD - New Zealand Dollar' },
  { code: 'PHP', name: 'PHP - Philippine Peso' },
  { code: 'PLN', name: 'PLN - Zloty' },
  { code: 'QAR', name: 'QAR - Qatari Rial' },
  { code: 'SAR', name: 'SAR - Saudi Riyal' },
  { code: 'SEK', name: 'SEK - Swedish Krona' },
  { code: 'SGD', name: 'SGD - Singapore Dollar' },
  { code: 'THB', name: 'THB - Baht' },
  { code: 'RUB', name: 'RUB - Russian Ruble' },
  { code: 'ARS', name: 'ARS - Argentine Peso' },
  { code: 'MXN', name: 'MXN - Mexican Peso' },
  { code: 'RON', name: 'RON - New Romanian Leu' },
  { code: 'BRL', name: 'BRL - Brazilian Real' },
  { code: 'VND', name: 'VND - Dong' },
  { code: 'TWD', name: 'TWD - New Taiwan Dollar' },
  { code: 'ILS', name: 'ILS - New Israeli Sheqel' },
]
