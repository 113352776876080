import React from 'react'
import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Card,
  CardActions,
  CardContent,
  Tooltip,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { CodesLevel, IntegrationDeliveryMethodEnum, IntegrationType } from '../../gql-global'

import { RewardRowFragment } from './operations/rewards.generated'
import { RewardTypeEnum } from '../../gql-global'
import { getIntegrationBrandColor, rewardHasAutoDelivery } from '../../utils/rewards'
import { IntegrationLogoSmall } from '../IntegrationLogo/IntegrationLogoSmall'
import { ReactComponent as CopyIcon } from '../../icons/copy.svg'
import { ReactComponent as GiftLogo } from '../../icons/gift.svg'
import { ReactComponent as InfoIcon } from '../../icons/information-no-halo.svg'
import { ReactComponent as MailIcon } from '../../icons/mail.svg'
import { ReactComponent as PaperAirplaneIcon } from '../../icons/paper-airplane.svg'

interface StyledProps {
  brandColor: string | undefined
  deliveryMethodIcon: boolean
  hasSendButton: boolean
  hasCopyButton: boolean
  hasNotification: boolean
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
      borderRadius: theme.spacing(2),
      position: 'relative',
      overflow: 'hidden',
      marginRight: 18,
    },
    gift: {
      fill: (props: StyledProps) => (props.brandColor ? props.brandColor : 'none'),
      position: 'absolute',
      height: 57,
      width: 57,
      bottom: -5,
      right: 25,
    },
    title: {
      fontSize: 16,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 500,
    },
    cardContent: {
      flexDirection: 'column',
      padding: (props: StyledProps) => {
        if (props.isLoading) {
          return theme.spacing(5, 5, 5, 8)
        }

        return theme.spacing(4, 4, 2, 8)
      },
      position: 'relative',
      height: 80,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 13,
        height: '100%',
        backgroundColor: (props: StyledProps) => (props.brandColor ? props.brandColor : 'none'),
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
      },
    },
    cardActions: {
      padding: 0,
      marginLeft: (props: StyledProps) => {
        if (props.hasSendButton && !props.hasCopyButton) {
          return -12
        }
        if (!props.hasSendButton && props.hasCopyButton) {
          return -8
        }

        return -12
      },
    },
    lowCodeNotification: {
      color: theme.palette.secondary.main,
    },
    lightButtonText: {
      fontWeight: 400,
    },
  }),
)

interface SendRewardRowProps {
  loading?: boolean
  reward?: RewardRowFragment
  onSend?(reward: RewardRowFragment, offline: boolean): void
  canAutoDeliver?: boolean
  canCopyCode?: boolean
}

export const getDeliveryMethod = (reward: RewardRowFragment | undefined): string | undefined => {
  if (!reward) {
    return undefined
  }
  if (reward?.deliveryMethod) {
    return reward.deliveryMethod.charAt(0).toUpperCase() + reward.deliveryMethod.toLowerCase().slice(1)
  }
  if (reward.integration?.deliveryMethods && reward.integration?.deliveryMethods?.length > 0) {
    return reward.integration?.deliveryMethods
      .map(method => {
        if (method === IntegrationDeliveryMethodEnum.Sms) {
          //SMS should be all caps
          return method
        }
        //otherwise, return only the first char capitalized
        return method.charAt(0).toUpperCase() + method.toLowerCase().slice(1)
      })
      .sort()
      .join(' / ')
  }
  if (
    reward?.integration?.integrationType &&
    [
      IntegrationType.Growave,
      IntegrationType.LoyaltyLion,
      IntegrationType.Smile,
      IntegrationType.Webhook,
      IntegrationType.Yotpo,
    ].includes(reward?.integration?.integrationType)
  ) {
    return 'integration'
  }
  return undefined
}
const RewardListRowV2: React.FC<SendRewardRowProps> = ({
  reward,
  onSend,
  canAutoDeliver = false,
  canCopyCode = false,
  loading = false,
}) => {
  const { name, stats, hasDiscountCode, integration } = reward || {}

  const deliveryMethod = getDeliveryMethod(reward)
  const brandColor = getIntegrationBrandColor(integration?.integrationType)
  const hasSendButton = !!(reward && canAutoDeliver && rewardHasAutoDelivery(reward) && deliveryMethod) || false
  const hasCopyButton = (reward && reward.hasDiscountCode && canCopyCode && !!reward.stats.codesRemaining) || false

  const showCodeLeft = stats?.codesLevel === CodesLevel.Low || stats?.codesLevel === CodesLevel.Empty
  const hasNotification = !!(hasDiscountCode && reward && showCodeLeft)
  const classes = useStyles({
    brandColor,
    deliveryMethodIcon:
      reward?.deliveryMethod === RewardTypeEnum.Sms || reward?.deliveryMethod === RewardTypeEnum.Email,
    hasSendButton,
    hasCopyButton,
    hasNotification,
    isLoading: loading,
  })

  const getLowNoCodeMessage = (): string => {
    let codesLeftText
    if (!stats?.codesRemaining) {
      codesLeftText = 'No codes'
    } else {
      codesLeftText = `${stats.codesRemaining.toString()} code${stats.codesRemaining > 1 ? 's' : ''}`
    }
    codesLeftText += ' left'
    return codesLeftText
  }

  const lowNoCodeMessage = getLowNoCodeMessage()

  if (loading) {
    return (
      <Card className={classes.row} data-testid="reward-row-loading">
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className={classes.row}>
      <CardContent className={classes.cardContent}>
        {name && (
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography className={classes.title}>{name}</Typography>
            <IntegrationLogoSmall integrationType={reward?.integration?.integrationType ?? IntegrationType.Webhook} />
          </Box>
        )}
        <GiftLogo className={classes.gift} fill={brandColor} />
        {(hasSendButton || hasCopyButton) && (
          <CardActions className={classes.cardActions}>
            <Box display="flex" alignItems="center" width="100%">
              {hasSendButton && (
                <Button
                  className={classes.lightButtonText}
                  data-testid="send-reward-button"
                  onClick={() => onSend && reward && onSend(reward, false)}
                  startIcon={
                    <>
                      {reward?.deliveryMethod === RewardTypeEnum.Sms && <PaperAirplaneIcon width={20} />}
                      {reward?.deliveryMethod === RewardTypeEnum.Email && <MailIcon width={20} />}
                      {deliveryMethod === 'integration' && <MailIcon width={20} />}
                      {reward?.integration?.deliveryMethods &&
                        reward?.integration?.deliveryMethods?.length > 0 &&
                        !reward?.deliveryMethod && <MailIcon width={20} />}
                    </>
                  }
                  variant="text"
                  color="primary"
                >
                  {deliveryMethod === 'integration' ? 'Send' : deliveryMethod}
                </Button>
              )}
              {hasCopyButton && (
                <Button
                  className={classes.lightButtonText}
                  onClick={() => onSend && reward && onSend(reward, true)}
                  startIcon={<CopyIcon width={16} />}
                  data-testid="copy-reward-button"
                  variant="text"
                  color="primary"
                >
                  Copy reward code
                </Button>
              )}

              {stats?.codesLevel === CodesLevel.Low && (
                <Box className={classes.lowCodeNotification} flexGrow={1} display="flex" justifyContent="flex-end">
                  <Tooltip title={lowNoCodeMessage}>
                    <InfoIcon width={14} height={14} />
                  </Tooltip>
                </Box>
              )}
            </Box>
          </CardActions>
        )}
      </CardContent>
    </Card>
  )
}

export default RewardListRowV2
