import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
export type TableRowCustomerBaseFragment = { __typename: 'CustomerType', id: number, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null, igUser?: { __typename: 'IGUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, mentionStats?: { __typename: 'CustomerMentionStats', avgEngagementRate: number, impressions: number, maxPostDate?: Date | null, postCount: number } | null, ttUser?: { __typename: 'TTUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string } | null };

export const TableRowCustomerBaseFragmentDoc = gql`
    fragment TableRowCustomerBase on CustomerType {
  activeStoryMentionsStats: mentionStats(where: $activeStoryMentionsWhere) {
    postCount
  }
  id
  igUser {
    avatarUrl
    followerCount
    id
    storiesIgSocialAccounts {
      fbUsers {
        id
        isValid
      }
      id
    }
    username
  }
  mentionStats(where: $mentionsWhere) {
    avgEngagementRate
    impressions
    maxPostDate
    postCount
  }
  ttUser {
    avatarUrl
    followerCount
    id
    username
  }
}
    `;