import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { TableRowCustomerBaseFragmentDoc } from './table-row-customer-base.generated';
export type TableRowCustomerFragment = { __typename: 'CustomerType', id: number, programParticipants: Array<{ __typename: 'ProgramParticipantType', id: number, status: Types.ParticipantStatus }>, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null, igUser?: { __typename: 'IGUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, mentionStats?: { __typename: 'CustomerMentionStats', avgEngagementRate: number, impressions: number, maxPostDate?: Date | null, postCount: number } | null, ttUser?: { __typename: 'TTUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string } | null };

export const TableRowCustomerFragmentDoc = gql`
    fragment TableRowCustomer on CustomerType {
  ...TableRowCustomerBase
  programParticipants {
    id
    status
  }
}
    ${TableRowCustomerBaseFragmentDoc}`;