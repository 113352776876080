import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSyncSubscriptionsMutation } from './operations/sync-subscriptions.generated'
import { HOME_ROUTE } from '../../home/routes'

function BillingCallback(): React.ReactElement {
  const [sync] = useSyncSubscriptionsMutation()
  const [redirect, setRedirect] = useState(false)
  useEffect(() => {
    sync({
      update(cache, { data }) {
        const org = data?.syncSubscriptions?.organization
        if (org) {
          cache.evict({ id: cache.identify(org) })
          const accounts = org.accounts || []
          for (const account of accounts) {
            cache.evict({ id: cache.identify(account) })
          }
          cache.gc()
        }
      },
    }).then(() => setRedirect(true))
  }, [sync])
  return redirect ? <Redirect to={HOME_ROUTE.path} /> : <div />
}

export default BillingCallback
