import React from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { ChallengeStatusEnum } from '../../gql-global'
import { getNumericString } from '../../utils/number-format'
import { ChallengeFragment } from '../operations/challenge.generated'

const useStyles = makeStyles(() =>
  createStyles({
    statsContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      margin: '1em 0',
    },
    statsGroup: {
      flexGrow: 1,
    },
    statsData: {
      fontWeight: 'bold',
    },
  }),
)

interface ChallengeCardStatsProps {
  challenge: ChallengeFragment
}

function ChallengeCardStats({ challenge }: ChallengeCardStatsProps): React.ReactElement {
  const classes = useStyles()
  const challengeHasLaunched =
    challenge.currentStatus !== ChallengeStatusEnum.Draft && challenge.currentStatus !== ChallengeStatusEnum.Upcoming
  return (
    <Box className={classes.statsContainer}>
      <Box className={classes.statsGroup}>
        <Typography variant={'body2'}>Posts</Typography>
        <Typography variant={'body2'} className={classes.statsData}>
          {!!challengeHasLaunched && !!challenge.mediaStats && getNumericString(challenge.mediaStats.total)}
          {!challengeHasLaunched && '--'}
        </Typography>
      </Box>
      <Box className={classes.statsGroup}>
        <Typography variant={'body2'}>Participants</Typography>
        <Typography variant={'body2'} className={classes.statsData}>
          {!!challengeHasLaunched && !!challenge.mediaStats && getNumericString(challenge.mediaStats.participants)}
          {!challengeHasLaunched && '--'}
        </Typography>
      </Box>
      <Box className={classes.statsGroup}>
        <Typography variant={'body2'}>EMV</Typography>
        <Typography variant={'body2'} className={classes.statsData}>
          {!!challengeHasLaunched && !!challenge.mediaStats && '$' + getNumericString(challenge.mediaStats.emv)}
          {!challengeHasLaunched && '--'}
        </Typography>
      </Box>
    </Box>
  )
}

export default ChallengeCardStats
