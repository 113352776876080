import { SimplePaletteColorOptions } from '@material-ui/core'
import { ColorOptions } from '../../loudcrowd-theme'
import { NumberFormatTypes } from '../../utils/number-format'
import { IconType } from './MetricIcon'

export interface MetricConfig {
  id: string
  label: string
  abbrvLabel: string | null
  color: ColorOptions
  paletteOption?: keyof SimplePaletteColorOptions
  dataFormat: NumberFormatTypes
  icon: IconType
}

export type MetricTypes =
  | 'igPosts'
  | 'ttPosts'
  | 'impressions'
  | 'engagement'
  | 'emv'
  | 'ugcValue'
  | 'replies'
  | 'recipients'
  | 'ltv'
  | 'memberSpend'
  | 'rewards'
  | 'totalCustomers'
  | 'totalOrders'
  | 'revenue'
  | 'commission'
  | 'roa'
  | 'landings'
  | 'conversion'

const metricMap: Record<MetricTypes, MetricConfig> = {
  igPosts: {
    id: 'POSTS',
    label: 'Posts',
    abbrvLabel: null,
    color: 'primary',
    dataFormat: 'number',
    icon: 'instagram',
  },
  ttPosts: {
    id: 'POSTS',
    label: 'Posts',
    abbrvLabel: null,
    color: 'primary',
    dataFormat: 'number',
    icon: 'tiktok',
  },
  impressions: {
    id: 'IMPRESSIONS',
    label: 'Impressions',
    abbrvLabel: null,
    color: 'info',
    dataFormat: 'number',
    icon: 'speaker',
  },
  engagement: {
    id: 'ENGAGEMENT',
    label: 'Engagement Rate',
    abbrvLabel: 'Eng. Rate',
    color: 'warning',
    dataFormat: 'percent',
    icon: 'smiley',
  },
  emv: {
    id: 'EMV',
    label: 'EMV',
    abbrvLabel: null,
    color: 'secondary',
    dataFormat: 'currency',
    icon: 'billfold',
  },
  ugcValue: {
    id: 'UGCVALUE',
    label: 'UGC Value',
    abbrvLabel: null,
    color: 'secondary',
    dataFormat: 'currency',
    icon: 'billfold',
  },
  replies: {
    id: 'REPLIES',
    label: 'Replies',
    abbrvLabel: null,
    color: 'warning',
    dataFormat: 'number',
    icon: 'smiley',
  },
  recipients: {
    id: 'RECIPIENTS',
    label: 'Recipients',
    abbrvLabel: null,
    color: 'warning',
    dataFormat: 'number',
    icon: 'smiley',
  },
  ltv: {
    id: 'LTV',
    label: 'Member LTV',
    abbrvLabel: null,
    color: 'success',
    dataFormat: 'currency',
    icon: 'ltv',
  },
  memberSpend: {
    id: 'MEMBERSPEND',
    label: 'Member Spend',
    abbrvLabel: null,
    color: 'purple',
    dataFormat: 'currency',
    icon: 'dollar',
  },
  rewards: {
    id: 'REWARDS',
    label: 'Rewards',
    abbrvLabel: null,
    color: 'warning',
    dataFormat: 'number',
    icon: 'smiley',
  },
  totalCustomers: {
    id: 'CUSTOMERS',
    label: 'Purchasing Members',
    abbrvLabel: null,
    color: 'warning',
    dataFormat: 'number',
    icon: 'customer',
  },
  totalOrders: {
    id: 'ORDERS',
    label: 'Orders',
    abbrvLabel: null,
    color: 'blue',
    dataFormat: 'number',
    icon: 'shopbag',
  },
  revenue: {
    id: 'REVENUE',
    label: 'Revenue',
    abbrvLabel: null,
    color: 'success',
    paletteOption: 'dark',
    dataFormat: 'currency',
    icon: 'dollar',
  },
  commission: {
    id: 'COMMISSION',
    label: 'Commissions',
    abbrvLabel: null,
    color: 'purple',
    paletteOption: 'dark',
    dataFormat: 'currency',
    icon: 'commission',
  },
  roa: {
    id: 'ROA',
    label: 'ROAS',
    abbrvLabel: null,
    color: 'blue',
    dataFormat: 'scalar',
    icon: 'roa',
  },
  landings: {
    id: 'LANDINGS',
    label: 'Sessions',
    abbrvLabel: null,
    color: 'pink',
    dataFormat: 'number',
    icon: 'pageVisits',
  },
  conversion: {
    id: 'CONVERSION',
    label: 'Conversion Rate',
    abbrvLabel: null,
    color: 'warning',
    dataFormat: 'percent',
    icon: 'conversion',
  },
}

export default metricMap
