import { Box, createStyles, LinearProgress, makeStyles, Container, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import useTitle from '../utils/use-title'
import SignupAccount from './SignupAccount'
import ConnectFacebook, { FBUserAuthResult } from './ConnectFacebook'
import PickSocialAccount from './PickSocialAccount'
import Resources from './Resources'
import {
  ADD_TEAM_MEMBERS_ROUTE,
  CONNECT_FACEBOOK_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  PICK_SOCIAL_ACCOUNT_ROUTE,
  RESOURCES_ROUTE,
} from './routes'
import squiggle from '../images/squiggle.png'
import { secondary } from '../loudcrowd-theme'
import facebookSDK from '../facebook'
import { FACEBOOK_APP_ID } from '../facebook/constants'
import AddTeamMembers from './AddTeamMembers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      paddingBottom: 72,
      height: '100vh',
      overflow: 'hidden',
    },
    topbar: {
      borderColor: secondary[400],
    },
    progressbar: {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 444,
      height: 8,

      '& .MuiLinearProgress-bar1Determinate': {
        borderRadius: 444,
      },
    },
  }),
)

function SignupContainer(): React.ReactElement {
  const classes = useStyles()
  const [fbUserState, setFbUserState] = useState<FBUserAuthResult | 'error' | null>(null)
  useTitle('Signup - Create Account')

  useEffect(() => {
    ;(async function () {
      try {
        await facebookSDK.init(FACEBOOK_APP_ID)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  const accountMatch = useRouteMatch(CREATE_ACCOUNT_ROUTE.path)
  const fbConnectMatch = useRouteMatch(CONNECT_FACEBOOK_ROUTE.path)
  const pickIgAccountMatch = useRouteMatch(PICK_SOCIAL_ACCOUNT_ROUTE.path)
  const addTeamMembersMatch = useRouteMatch(ADD_TEAM_MEMBERS_ROUTE.path)
  const resourcesMatch = useRouteMatch(RESOURCES_ROUTE.path)

  function getProgressPercent() {
    let percent = 10
    if (accountMatch) {
      percent = 25
    } else if (fbConnectMatch) {
      percent = 50
    } else if (pickIgAccountMatch) {
      percent = 75
    } else if (addTeamMembersMatch) {
      percent = 95
    }
    return percent
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Box
        className={classes.topbar}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={8}
        px={12}
        borderBottom={1}
      >
        <img width="60" src={squiggle} alt="LoudCrowd Logo" />
        {!resourcesMatch && (
          <Box height={8} width={208}>
            <LinearProgress className={classes.progressbar} variant="determinate" value={getProgressPercent()} />
          </Box>
        )}
      </Box>
      <Switch>
        <Route component={SignupAccount} path={CREATE_ACCOUNT_ROUTE.path} exact />
        <Route
          render={props => <ConnectFacebook {...props} onFbAuthed={setFbUserState} />}
          path={CONNECT_FACEBOOK_ROUTE.path}
          exact
        />
        <Route
          render={props => <PickSocialAccount {...props} fbUserState={fbUserState} />}
          path={PICK_SOCIAL_ACCOUNT_ROUTE.path}
          exact
        />
        <Route component={AddTeamMembers} path={ADD_TEAM_MEMBERS_ROUTE.path} exact />
        <Route component={Resources} path={RESOURCES_ROUTE.path} exact />
      </Switch>
    </Container>
  )
}

export default SignupContainer
