import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContentSocialAccountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ContentSocialAccountQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, messageTemplates?: { __typename: 'PagedMessageTemplate', total: number, cursor?: string | null, results: Array<{ __typename: 'MessageTemplate', id: string, name: string }> } | null, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null } | { __typename: 'TTSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null } | null };


export const ContentSocialAccountDocument = gql`
    query ContentSocialAccount($id: ID!, $limit: Int) {
  socialAccount(id: $id) {
    id
    campaigns {
      id
      program {
        id
        name
      }
    }
    ... on IGSocialAccount {
      messageTemplates(limit: $limit, sortBy: RECENTLY_USED, sortDirection: DESC) {
        total
        cursor
        results {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useContentSocialAccountQuery__
 *
 * To run a query within a React component, call `useContentSocialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSocialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSocialAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useContentSocialAccountQuery(baseOptions: Apollo.QueryHookOptions<ContentSocialAccountQuery, ContentSocialAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentSocialAccountQuery, ContentSocialAccountQueryVariables>(ContentSocialAccountDocument, options);
      }
export function useContentSocialAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentSocialAccountQuery, ContentSocialAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentSocialAccountQuery, ContentSocialAccountQueryVariables>(ContentSocialAccountDocument, options);
        }
export type ContentSocialAccountQueryHookResult = ReturnType<typeof useContentSocialAccountQuery>;
export type ContentSocialAccountLazyQueryHookResult = ReturnType<typeof useContentSocialAccountLazyQuery>;
export type ContentSocialAccountQueryResult = Apollo.QueryResult<ContentSocialAccountQuery, ContentSocialAccountQueryVariables>;