import React, { useState, useRef } from 'react'
import { Paper, Box, Button, makeStyles, Divider, Typography, Collapse } from '@material-ui/core'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'
import { ValueOf } from '../../../types/utility'
import JoinedChildren from '../../JoinedChildren'
import { Option, Options, Filters, DateRangeOption } from './types'
import AddListFilterDialog from './AddListFilterDialog'
import { DateRangeFilterChip } from './DateRangeFilterMenu'
import {
  CustomFieldsFilter,
  LabelsFilter,
  CustomFieldFilterInput,
  RelativeDateRangeFilter,
  AbsoluteDateTimeRangeFilter,
  AbsoluteDateRangeFilter,
  DateInclusionExclusionFilterInput,
  KeywordsFilterInput,
  MessageSendingMethodsFilterInput,
} from '../../../gql-global'
import { NumericalRange } from '../../NumericalRangePicker'
import { BulkLabelsFilterChip } from './BulkLabelsFilterMenu'
import { NumericalRangeFilterChip } from './NumericalRangeFilterMenu'
import { SelectionFilterChip } from './SelectionFilterMenu'
import { KeywordsFilterChip } from './KeywordFilterMenu'
import { SingleSelectionFilterChip } from './SingleSelectionFilterMenu'
import FilterChip, { FilterChipText } from './FilterChip'

const useStyles = makeStyles({
  excludeExpander: {
    cursor: 'pointer',
  },
  boldText: {
    fontWeight: 'bold',
  },
  saveButton: {
    marginRight: '12px',
  },
})

interface ListFiltersProps {
  readonly options: Options
  readonly excludeOptions?: Options
  filters: Filters
  onChangeFilters(newFilters: Filters): void
  isDirty: boolean
  page?: 'Content' | null
  contentType: string
  setSaveFilterDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
  canSave?: boolean
}

function flattenOptions(options: Options, currentAcc: Option[] = []): Options {
  const value = options.reduce((acc: Option[], currentValue: Option) => {
    if (currentValue.type === 'parent') {
      flattenOptions(currentValue.children, acc)
    } else {
      acc.push(currentValue)
    }
    return acc
  }, currentAcc)
  return value
}

function ListFilters({
  options,
  excludeOptions = [],
  filters,
  onChangeFilters,
  isDirty,
  page,
  contentType,
  setSaveFilterDialogOpen,
  canSave = true,
}: ListFiltersProps): React.ReactElement {
  const classes = useStyles()
  const [excludeOpen, setExcludeOpen] = useState(false)
  const [addFilterDialogOpen, setAddFilterDialogOpen] = useState(false)
  const [excludeFilterDialogOpen, setExcludeFilterDialogOpen] = useState(false)
  const addFilterButtonRef = useRef<HTMLButtonElement>(null)
  function handleDelete(name: string): void {
    onChangeFilters({
      ...filters,
      [name]: undefined,
    })
  }
  const flatOptions = flattenOptions(options)
  const flatExcludeOptions = flattenOptions(excludeOptions)

  function handleDeleteCustomField(selectedOption: Option): void {
    const customFieldId = selectedOption.name
    const filterType = selectedOption.type === 'selection' || selectedOption.type === 'keywords' ? 'any' : 'all'
    const filteredCustomFieldFilters = filters.customFields[filterType].filter(
      (filter: CustomFieldFilterInput) => filter.customFieldId !== customFieldId,
    )
    const customFields = {
      ...filters.customFields,
      [filterType]: filteredCustomFieldFilters.length > 0 ? filteredCustomFieldFilters : undefined,
    }
    const newFilters = {
      ...filters,
      customFields: !!customFields.any || !!customFields.all ? customFields : undefined,
    }
    onChangeFilters(newFilters)
  }

  function handleValueSelected(
    selectedOption: Option,
    value: ValueOf<Filters>,
    closeFilterDialog?: 'exclude' | 'add',
  ): void {
    const newFilters: Filters = { ...filters }
    if (selectedOption.isCustomField) {
      const customFieldId = selectedOption.name
      const filterType = selectedOption.type === 'selection' || selectedOption.type === 'keywords' ? 'any' : 'all'
      const filterTypeInFilters =
        filters.customFields &&
        filterType in filters.customFields &&
        !!filters.customFields[filterType] &&
        filters.customFields[filterType].length > 0

      const existingFilter =
        filterTypeInFilters &&
        filters.customFields[filterType].find(
          (filter: CustomFieldFilterInput) => filter.customFieldId === customFieldId,
        )
      const valueKey =
        selectedOption.type === 'dateRange' && selectedOption.entity === 'custom' ? 'dateValue' : 'values'

      const newCustomFieldFilter = {
        customFieldId,
        [valueKey]: value instanceof Set ? Array.from(value) : 'any' in value ? value.any : value,
      }
      let customFieldFilters
      if (existingFilter) {
        const filteredFilters = filters.customFields[filterType].filter(
          (filter: CustomFieldFilterInput) => filter.customFieldId !== customFieldId,
        )
        customFieldFilters = [...filteredFilters, newCustomFieldFilter]
      } else {
        customFieldFilters = filterTypeInFilters
          ? [...filters.customFields[filterType], newCustomFieldFilter]
          : [newCustomFieldFilter]
      }
      newFilters['customFields'] = {
        ...filters.customFields,
        [filterType]: customFieldFilters,
      }
    } else {
      newFilters[selectedOption.name] = value
    }

    onChangeFilters(newFilters)

    if (closeFilterDialog === 'exclude') {
      setExcludeFilterDialogOpen(false)
    } else if (closeFilterDialog === 'add') {
      setAddFilterDialogOpen(false)
    }
  }

  const excludeFilterButtonRef = useRef<HTMLButtonElement>(null)
  const addOptions = options.filter(({ name, type }) => {
    return (
      (type !== 'singleSelection' && filters[name] === null) ||
      name === 'customFields' ||
      (type !== 'dateRange' && filters[name] === undefined) ||
      (type === 'dateRange' && filters[name]?.require === undefined) ||
      type === 'labels'
    )
  })

  // can add more exclude options if its not already set.
  // directSelection set is a value instead of object like { none: value }
  const excludeableOptions = excludeOptions.filter(v =>
    ['dateRange', 'directSelection', 'selection'].includes(v.type)
      ? (v.type !== 'dateRange' && filters[v.name] === undefined) ||
        (v.type === 'dateRange' && filters[v.name]?.exclude === undefined)
      : filters[v.name]?.none === undefined,
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const excludedFilters = Object.entries(filters).filter((f: [string, any]) => {
    const excludedFlatOption = flatExcludeOptions.find(o => o.name === f[0])
    return (
      (excludedFlatOption?.type === 'directSelection' && f[1] === excludedFlatOption.excludeValue) ||
      (excludedFlatOption?.type === 'dateRange' && excludedFlatOption.exclude && f[1]?.exclude) ||
      (excludedFlatOption?.type === 'selection' && excludedFlatOption.exclude && f[1]?.exclude) ||
      (f[1] !== null &&
        f[1] !== undefined &&
        typeof f[1] === 'object' &&
        excludedFlatOption !== undefined &&
        'none' in f[1] &&
        f[1].none?.length)
    )
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const includedFilters = Object.entries(filters).filter((f: any) => {
    const filterValue = f[1]
    const hasFilterValue = filterValue !== null && filterValue !== undefined
    const valueIsString = typeof filterValue === 'string'
    const valueIsObject = typeof filterValue === 'object'
    const valueIsArray = hasFilterValue && Array.isArray(filterValue)
    const hasAll = filterValue && filterValue.hasOwnProperty('all') && filterValue.all?.length > 0
    const hasAny = filterValue && filterValue.hasOwnProperty('any') && filterValue.any?.length > 0
    const hasNone = filterValue && filterValue.hasOwnProperty('none') && filterValue.none?.length > 0
    const isDateRangeFilter = valueIsObject && !valueIsArray && !hasAll && !hasAny && !hasNone
    const valueIsValidObject = (valueIsObject && (hasAll || hasAny)) || isDateRangeFilter
    const fOption = flatOptions.find(o => o.name === f[0])

    if (fOption?.type === 'directSelection' && fOption.addValue === f[1]) {
      return true
    }
    if (hasFilterValue && (valueIsString || valueIsArray || valueIsValidObject)) {
      return true
    }
    return false
  })

  return (
    <Paper>
      <Box px={7} py={4} display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          data-intercom-target={page === 'Content' ? 'Content Filters' : undefined}
        >
          <JoinedChildren
            joinElement={
              <Box fontWeight="typography.fontWeightBold" px={2}>
                and
              </Box>
            }
          >
            {includedFilters.reduce(
              (a: React.ReactElement[], [name, value]: [string, unknown]): React.ReactElement[] => {
                if (name === 'customFields') {
                  const customFieldFilter = value as CustomFieldsFilter
                  const allCustomFieldFilters = customFieldFilter?.all || []
                  const anyCustomFieldFilters = customFieldFilter?.any || []
                  const customFieldFilters = [...allCustomFieldFilters, ...anyCustomFieldFilters]

                  const validFields: React.ReactElement[] = []
                  customFieldFilters.length > 0 &&
                    customFieldFilters.forEach(cff => {
                      // Get selected custom field filter from filter options
                      const selectedFilterOption = flatOptions.find(cfo => {
                        // Get the custom field id of the customField option name
                        // ex. customField:1xrj3l
                        // const cfId = cfo.name.split(':')[1]
                        const cfId = cfo.name
                        return cff && cfId === cff.customFieldId
                      })

                      if (selectedFilterOption && selectedFilterOption.type === 'keywords') {
                        const values = cff.values
                        if (values) {
                          validFields.push(
                            <KeywordsFilterChip
                              key={selectedFilterOption.name}
                              option={selectedFilterOption}
                              value={values}
                              onDelete={() => handleDeleteCustomField(selectedFilterOption)}
                              onSelectValue={v => handleValueSelected(selectedFilterOption, v)}
                              isExclusion={false}
                              operator={selectedFilterOption.operator}
                            />,
                          )
                        }
                      }
                      if (selectedFilterOption && selectedFilterOption.type === 'selection') {
                        const customFieldValueSet = cff.values && new Set(cff.values)
                        if (customFieldValueSet) {
                          validFields.push(
                            <SelectionFilterChip
                              key={selectedFilterOption.name}
                              option={selectedFilterOption}
                              value={customFieldValueSet}
                              onDelete={() => handleDeleteCustomField(selectedFilterOption)}
                              onSelectValue={v => handleValueSelected(selectedFilterOption, v)}
                            />,
                          )
                        }
                      }
                      if (selectedFilterOption && selectedFilterOption.type === 'dateRange') {
                        const dateValue = cff.dateValue
                        if (dateValue) {
                          validFields.push(
                            <DateRangeFilterChip
                              key={selectedFilterOption.name}
                              option={selectedFilterOption}
                              value={dateValue}
                              onDelete={() => handleDeleteCustomField(selectedFilterOption)}
                              onSelectValue={v => {
                                handleValueSelected(selectedFilterOption, v)
                              }}
                            />,
                          )
                        }
                      }
                    })
                  return [...a, ...validFields]
                }
                const option = flatOptions.find(o => o.name === name)
                if (!option || option.type === 'parent') {
                  return a
                }

                if (option.type === 'dateRange') {
                  const dateValue = (
                    (option as DateRangeOption).exclude
                      ? ((value as DateInclusionExclusionFilterInput).require as
                          | RelativeDateRangeFilter
                          | AbsoluteDateTimeRangeFilter
                          | AbsoluteDateRangeFilter)
                      : value
                  ) as RelativeDateRangeFilter | AbsoluteDateTimeRangeFilter | AbsoluteDateRangeFilter

                  if (!dateValue) {
                    return a
                  }

                  return [
                    ...a,
                    <DateRangeFilterChip
                      key={option.name}
                      option={option}
                      value={dateValue}
                      onDelete={() => handleDelete(name)}
                      onSelectValue={v => {
                        if (option.exclude) {
                          handleValueSelected(option, {
                            require: v,
                          })
                        } else {
                          handleValueSelected(option, v)
                        }
                      }}
                    />,
                  ]
                } else if (option.type === 'labels') {
                  const labelValue = value as LabelsFilter
                  if (option.omitOnIncludeListFilter) {
                    return a
                  }

                  if (labelValue.any) {
                    return [
                      ...a,
                      <BulkLabelsFilterChip
                        key={`bulkLabelsFilterChip-${option.name}`}
                        option={option}
                        value={labelValue}
                        onDelete={() => handleDelete(name)}
                        onSelectValue={v => handleValueSelected(option, v)}
                        includeField="any"
                      />,
                    ]
                  } else if (labelValue.all) {
                    return [
                      ...a,
                      ...(labelValue.all?.map((_, i) => (
                        <BulkLabelsFilterChip
                          key={`bulkLabelsFilterChip-${option.name}-${i}`}
                          option={option}
                          filterLabelsAllIndex={i}
                          value={labelValue}
                          onDelete={() => handleDelete(name)}
                          onSelectValue={v => handleValueSelected(option, v)}
                          includeField="all"
                        />
                      )) || []),
                    ]
                  }
                  return a
                } else if (option.type === 'selection') {
                  const selectValue = option.exclude
                    ? (new Set((value as MessageSendingMethodsFilterInput)?.any) as Set<string>)
                    : (value as Set<string>)

                  return [
                    ...a,
                    <SelectionFilterChip
                      key={option.name}
                      option={option}
                      value={selectValue}
                      onDelete={() => handleDelete(name)}
                      onSelectValue={v => {
                        if (option.exclude) {
                          handleValueSelected(option, {
                            any: Array.from(v),
                          })
                        } else {
                          handleValueSelected(option, v)
                        }
                      }}
                    />,
                  ]
                } else if (option.type === 'keywords') {
                  return [
                    ...a,
                    <KeywordsFilterChip
                      key={option.name}
                      option={option}
                      value={value as string[] | KeywordsFilterInput}
                      onDelete={() => handleDelete(name)}
                      onSelectValue={v => handleValueSelected(option, v)}
                      isExclusion={false}
                      operator={option.operator}
                    />,
                  ]
                } else if (option.type === 'singleSelection') {
                  const selectedValue = value as string
                  return [
                    ...a,
                    <SingleSelectionFilterChip
                      key={option.name}
                      option={option}
                      value={selectedValue}
                      onDelete={() => handleDelete(name)}
                    />,
                  ]
                } else if (option.type === 'numericalRange') {
                  return [
                    ...a,
                    <NumericalRangeFilterChip
                      key={option.name}
                      option={option}
                      value={value as NumericalRange}
                      onDelete={() => handleDelete(name)}
                      onSelectValue={v => handleValueSelected(option, v)}
                    />,
                  ]
                } else if (option.type === 'directSelection') {
                  return [
                    ...a,
                    <FilterChip key={option.name} onDelete={() => handleDelete(name)}>
                      <FilterChipText bold text={option.label} />
                    </FilterChip>,
                  ]
                }
                return a
              },
              [],
            )}
          </JoinedChildren>
          <Button
            ref={addFilterButtonRef}
            color="primary"
            startIcon={<PlusIcon width={16} height={16} />}
            onClick={() => setAddFilterDialogOpen(true)}
          >
            Add filter
          </Button>
        </Box>
        <Box>
          {canSave && setSaveFilterDialogOpen && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSaveFilterDialogOpen(true)}
              disabled={!isDirty}
              className={classes.saveButton}
            >
              Save search
            </Button>
          )}
          <Button variant="outlined" disabled={!isDirty} onClick={() => onChangeFilters({})}>
            Reset
          </Button>
        </Box>
      </Box>
      {(!!excludeOptions.length || !!excludedFilters.length) && (
        <>
          <Divider />
          <Box>
            <Box
              py={2}
              px={10}
              onClick={() => setExcludeOpen(p => !p)}
              role="button"
              className={classes.excludeExpander}
            >
              <Typography variant="body2" display="inline" color="primary" className={classes.boldText}>
                Exclude
              </Typography>
              <Typography variant="body2" display="inline" color="secondary">
                {` ${contentType} where...`}
              </Typography>
            </Box>
            <Collapse in={excludeOpen || excludedFilters.length > 0} timeout="auto">
              <Box pt={1} pb={4} px={7} display="flex" alignItems="center" flexWrap="wrap">
                <JoinedChildren
                  joinElement={
                    <Box fontWeight="typography.fontWeightBold" px={2}>
                      and
                    </Box>
                  }
                >
                  {excludedFilters.reduce(
                    (a: React.ReactElement[], [name, value]: [string, unknown]): React.ReactElement[] => {
                      const option = flatExcludeOptions.find(o => o.name === name)
                      const chipOnDelete = () => handleDelete(name)
                      if (option && (option as Option)?.type === 'dateRange') {
                        const dateValue = (
                          (option as DateRangeOption).exclude
                            ? ((value as DateInclusionExclusionFilterInput).exclude as
                                | RelativeDateRangeFilter
                                | AbsoluteDateTimeRangeFilter
                                | AbsoluteDateRangeFilter)
                            : value
                        ) as RelativeDateRangeFilter | AbsoluteDateTimeRangeFilter | AbsoluteDateRangeFilter

                        if (!dateValue) {
                          return a
                        }

                        return [
                          ...a,
                          <DateRangeFilterChip
                            key={option.name}
                            option={option as DateRangeOption}
                            value={dateValue}
                            onDelete={() => handleDelete(name)}
                            onSelectValue={v => {
                              if ((option as DateRangeOption).exclude) {
                                handleValueSelected(option, {
                                  exclude: v,
                                })
                              } else {
                                handleValueSelected(option, v)
                              }
                            }}
                          />,
                        ]
                      } else if ((option as Option)?.type === 'directSelection') {
                        return [
                          ...a,
                          <FilterChip key={`bulkLabelsFilterChip-${option?.name}-none`} onDelete={chipOnDelete}>
                            <FilterChipText bold text={option?.label ?? ''} />
                          </FilterChip>,
                        ]
                      } else if (option?.type === 'labels') {
                        const selectValue = value as LabelsFilter
                        return [
                          ...a,
                          <BulkLabelsFilterChip
                            exclude
                            key={`bulkLabelsFilterChip-${option.name}-none`}
                            option={option}
                            value={selectValue}
                            onDelete={chipOnDelete}
                            onSelectValue={v => handleValueSelected(option, v)}
                          />,
                        ]
                      } else if (option?.type === 'keywords') {
                        return [
                          ...a,
                          <KeywordsFilterChip
                            key={option.name}
                            option={option}
                            value={value as string[] | KeywordsFilterInput}
                            onDelete={() => handleDelete(name)}
                            onSelectValue={v => handleValueSelected(option, v)}
                            isExclusion={true}
                            operator={option.operator}
                          />,
                        ]
                      } else if (option?.type === 'selection') {
                        const selectValue = option.exclude
                          ? (new Set((value as MessageSendingMethodsFilterInput)?.none) as Set<string>)
                          : (value as Set<string>)

                        return [
                          ...a,
                          <SelectionFilterChip
                            key={option.name}
                            option={option}
                            value={selectValue}
                            onDelete={() => handleDelete(name)}
                            onSelectValue={v => {
                              if (option.exclude) {
                                handleValueSelected(option, {
                                  none: Array.from(v),
                                })
                              } else {
                                handleValueSelected(option, v)
                              }
                            }}
                          />,
                        ]
                      }

                      return a
                    },
                    [],
                  )}
                </JoinedChildren>
                {!!excludeableOptions.length && (
                  <Button
                    ref={excludeFilterButtonRef}
                    color="primary"
                    startIcon={<PlusIcon width={16} height={16} />}
                    onClick={() => setExcludeFilterDialogOpen(true)}
                  >
                    Add filter
                  </Button>
                )}
              </Box>
            </Collapse>
          </Box>
        </>
      )}
      <AddListFilterDialog
        filters={filters}
        open={addFilterDialogOpen}
        anchorEl={addFilterButtonRef.current}
        options={addOptions}
        onCancel={() => setAddFilterDialogOpen(false)}
        onDelete={handleDelete}
        onAddFilter={(selectedOption, value) => {
          if (selectedOption.type === 'dateRange' && selectedOption?.exclude) {
            return handleValueSelected(
              selectedOption,
              {
                require: value,
              },
              'add',
            )
          }
          return handleValueSelected(selectedOption, value, 'add')
        }}
      />
      <AddListFilterDialog
        exclude
        filters={filters}
        open={excludeFilterDialogOpen}
        anchorEl={excludeFilterButtonRef.current}
        options={excludeableOptions}
        onCancel={() => setExcludeFilterDialogOpen(false)}
        onDelete={handleDelete}
        onAddFilter={(selectedOption, value) => {
          if (selectedOption.type === 'dateRange' && selectedOption?.exclude) {
            return handleValueSelected(
              selectedOption,
              {
                exclude: value,
              },
              'exclude',
            )
          }
          return handleValueSelected(selectedOption, value, 'exclude')
        }}
      />
    </Paper>
  )
}

export default ListFilters
