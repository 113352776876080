import React, { useRef, useState } from 'react'
import { Box, createStyles, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { secondary } from '../../loudcrowd-theme'
import { conditionalDateRangeFormat } from '../../utils/date-range-helper'
import { MentionIndicator, ReviewIndicator } from './ChallengeIcons'
import { HashtagIndicator } from './ChallengeIcons'
import { CaptionKeywordIndicator } from './ChallengeIcons'
import { ChallengeStatusEnum } from '../../gql-global'
import { ChallengeCardStyledProps, styleChallengeCardByStatus } from './ChallengeCard'
import { ChallengeFragment } from '../operations/challenge.generated'
import { subDays } from 'date-fns'
import { isTypeName } from '../../types/utility'
import { ReactComponent as Caret } from '../../icons/caret.svg'
import ChallengeProgramListMenu from './ChallengeProgramListMenu'

const TITLE_CHARACTER_LENGTH = 28

const useStyles = makeStyles(() =>
  createStyles({
    ellipsisOverflow: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 18,
    },
    challengeTitle: {
      lineHeight: 1,
      backgroundImage: (props: ChallengeCardStyledProps) =>
        styleChallengeCardByStatus({
          currentStatus: props.currentStatus as ChallengeStatusEnum,
          completed: 'linear-gradient(91.11deg, #5B00F9 23.44%, #FFAC33 46.42%, #F528C8 69.79%)',
          live: 'linear-gradient(90.32deg, #019F8E 25.52%, #38D3FF 100%)',
          defaultStyle: 'linear-gradient(90.32deg, #7C7C7C 0.12%, #00ACFF 87.66%)',
        }),
      margin: '0.5em 0',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    challengeDateBox: {
      margin: '0.5em 0',
    },
    challengeDatePlaceholder: {
      color: secondary[600],
      fontStyle: 'italic',
    },
    indicators: {
      display: 'flex',
      justifyContent: 'flex-start',
      columnGap: '5px',
      marginTop: '1em',
    },
    program: {
      color: secondary[600],
      cursor: 'default',
    },
    clickableProgram: {
      color: secondary[600],
      cursor: 'pointer',
    },
    caret: {
      width: '8px',
      height: '8px',
      marginLeft: '4px',
    },
  }),
)

interface ChallengeCardMainProps {
  challenge: ChallengeFragment
}

function ChallengeCardMain({ challenge }: ChallengeCardMainProps): React.ReactElement {
  const classes = useStyles({ currentStatus: challenge.currentStatus })

  const [programListOpen, setProgramListOpen] = useState<boolean>(false)
  const chipRef = useRef<HTMLDivElement>(null)

  const hasDates = !!challenge.startedAt && !!challenge.endedAt
  const datePlaceholder = 'No dates set'

  const igMentions =
    challenge.socialAccounts
      ?.filter(account => isTypeName(account, 'IGSocialAccount'))
      .map(account => account.socialUser.username) || []
  const ttMentions =
    challenge.socialAccounts
      ?.filter(account => isTypeName(account, 'TTSocialAccount'))
      .map(account => account.socialUser.username) || []

  const igPostTypes = challenge.postTypes || []

  const requiresReview = challenge.additionalCriteria?.hasAdditionalCriteria || false

  const programs = challenge?.programs?.map(p => p.name) || []

  const singleProgramLabel = programs[0]

  return (
    <>
      <Box className={classes.challengeTitle}>
        <Tooltip title={challenge.name.length >= TITLE_CHARACTER_LENGTH ? challenge.name : ''} placement="top">
          <Typography variant="h6" className={classes.ellipsisOverflow} style={{ WebkitLineClamp: 1 }}>
            {challenge.name}
          </Typography>
        </Tooltip>
      </Box>
      <Box className={classes.challengeDateBox}>
        {!!challenge.startedAt && !!challenge.endedAt && (
          <Typography variant="body2">
            {conditionalDateRangeFormat(challenge.startedAt, subDays(challenge.endedAt, 1))}
          </Typography>
        )}
        {!hasDates && (
          <Typography variant="body2" className={classes.challengeDatePlaceholder}>
            {datePlaceholder}
          </Typography>
        )}
      </Box>
      {!!programs.length && (
        <Box>
          {programs.length === 1 && singleProgramLabel && (
            <Tooltip
              title={singleProgramLabel.length >= TITLE_CHARACTER_LENGTH ? singleProgramLabel : ''}
              placement="top"
            >
              <Typography className={`${classes.program} ${classes.ellipsisOverflow}`} style={{ WebkitLineClamp: 1 }}>
                {singleProgramLabel}
              </Typography>
            </Tooltip>
          )}
          {programs.length > 1 && (
            <Box>
              <Box onClick={() => setProgramListOpen(true)}>
                <Typography className={classes.clickableProgram} ref={chipRef}>
                  Run in ({programs.length}) programs <Caret className={classes.caret} />
                </Typography>
              </Box>
              <ChallengeProgramListMenu
                currentRef={chipRef.current}
                programs={programs}
                open={programListOpen}
                onClose={() => setProgramListOpen(false)}
              />
            </Box>
          )}
        </Box>
      )}
      <Box className={classes.indicators}>
        {!!igMentions.length && <MentionIndicator isIG={true} postTypes={igPostTypes} mentions={igMentions} />}
        {!!ttMentions.length && <MentionIndicator isIG={false} postTypes={[]} mentions={ttMentions} />}
        {!!challenge.hashtags && !!challenge.hashtags.length && (
          <HashtagIndicator hashtags={challenge.hashtags} hashtagsOperator={challenge.hashtagsCriteriaOperator} />
        )}
        {!!challenge.keywords && !!challenge.keywords.length && (
          <CaptionKeywordIndicator
            keywords={challenge.keywords}
            keywordsOperator={challenge.keywordsCriteriaOperator}
          />
        )}
        {!!requiresReview && <ReviewIndicator />}
      </Box>
    </>
  )
}

export default ChallengeCardMain
