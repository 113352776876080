import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { DateTimePicker } from '@material-ui/pickers'
import { RewardBatchJobStatus } from '../../gql-global'
import { useToast } from '../../components/Alert/ToastProvider'
import { EspMessageRewardFragmentFragment } from '../operations/esp-message-reward-fragment.generated'
import { useUnscheduleEspMessageRewardMutation } from '../operations/unschedule-esp-message-reward.generated'
import { useScheduleEspMessageRewardMutation } from '../operations/schedule-esp-message-reward.generated'
import { useRetryEspMessageRewardBatchJobMutation } from '../operations/retry-esp-message-reward-batch-job.generated'
import { formatUTCDateToClientLocalTime, roundToGreaterHalfHour } from '../../utils/date-format'
import {
  RewardBatchJobFragmentFragment,
  usePagedRewardBatchJobsLazyQuery,
} from '../operations/query-paged-reward-batch-jobs.generated'

interface EspSchedulerModalProps {
  espMessageReward: EspMessageRewardFragmentFragment | null
  open: boolean
  onCancel(): void
  refetchEspMessageReward(): void
  loading: boolean
}

const useStyles = makeStyles({
  dialogPaper: {
    width: '100%',
    height: '100%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
  },
  formContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    '& p': { margin: 0, fontSize: 12 },
  },
  formField: { marginTop: 25, '&:first-of-type': { margin: 0 } },
  dateFormField: { width: 300 },
  tableContainer: { marginTop: 20, borderRadius: 10, outline: '1px solid #DAD9E6', height: 350, overflow: 'scroll' },
})

const EspSchedulerModal = ({
  espMessageReward,
  open,
  onCancel,
  refetchEspMessageReward,
}: EspSchedulerModalProps): React.ReactElement => {
  const classes = useStyles()
  const { showToast } = useToast()
  const isEditModal = !!espMessageReward
  const [rewardBatchJobs, setRewardBatchJobs] = useState<RewardBatchJobFragmentFragment[]>([])
  const [rewardBatchJobCursor, setRewardBatchJobCursor] = useState<string | null>(null)

  const [executeRewardBatchJobsQuery, rewardBatchJobsQueryResults] = usePagedRewardBatchJobsLazyQuery()

  const refreshRewarcBatchJobs = (cursor: string | null) => {
    executeRewardBatchJobsQuery({
      variables: {
        cursor,
        rewardId: espMessageReward?.id.toString() || '',
        accountId: espMessageReward?.account?.id.toString() || '',
      },
    }).then(queryResult => {
      setRewardBatchJobs([
        ...rewardBatchJobs,
        ...(queryResult.data?.account?.reward?.rewardBatchJobs?.results || ([] as RewardBatchJobFragmentFragment[])),
      ])
      setRewardBatchJobCursor(queryResult.data?.account?.reward?.rewardBatchJobs?.cursor || null)
    })
  }

  const [scheduleReward, { loading: scheduleRewardLoading }] = useScheduleEspMessageRewardMutation({
    onCompleted: () => {
      refetchEspMessageReward()
      showToast({
        title: 'Success: Scheduled message successfully',
        message: 'Your message has been scheduled to send.',
        severity: 'success',
      })
    },
    onError: e => {
      showToast({
        title: 'Error: Scheduling message',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    },
  })
  const [unschduleReward, { loading: unscheduleRewardLoading }] = useUnscheduleEspMessageRewardMutation({
    onCompleted: () => {
      refetchEspMessageReward()
      showToast({
        title: 'Success: Your scheduled message has been cancelled',
        severity: 'success',
      })
    },
    onError: e => {
      showToast({
        title: 'Error: Unscheduling message',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    },
  })
  const [retryRewardBatchJob, { loading: retryRewardBatchJobLoading }] = useRetryEspMessageRewardBatchJobMutation({
    onCompleted: () => {
      refetchEspMessageReward()
      refreshRewarcBatchJobs(null)
      showToast({
        title: 'Success: Your message has been retried and is underway',
        severity: 'success',
      })
    },
    onError: e => {
      showToast({
        title: 'Error: Retrying message',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    },
  })

  useEffect(() => {
    if (espMessageReward) {
      executeRewardBatchJobsQuery({
        variables: {
          accountId: espMessageReward.account?.id.toString() || '',
          rewardId: espMessageReward.id.toString(),
        },
      }).then(queryResult => {
        setRewardBatchJobs(queryResult.data?.account?.reward?.rewardBatchJobs?.results || [])
        setRewardBatchJobCursor(queryResult.data?.account?.reward?.rewardBatchJobs?.cursor || null)
      })
    } else {
      setRewardBatchJobs([])
      setRewardBatchJobCursor(null)
    }
  }, [espMessageReward, executeRewardBatchJobsQuery])

  const getMessageLogStatusText = (status: RewardBatchJobStatus) => {
    switch (status) {
      case RewardBatchJobStatus.Pending:
        return 'In Progress'
      case RewardBatchJobStatus.Success:
        return 'Complete - Success'
      case RewardBatchJobStatus.PartialSuccess:
        return 'Complete - With Failures'
      case RewardBatchJobStatus.Failure:
        return 'Failed'
      default:
        return 'Unknown'
    }
  }

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialogPaper }}>
      <Box m={2}>
        <div>
          <IconButton onClick={onCancel}>
            <CrossIcon width={16} height={16} />
          </IconButton>
        </div>
      </Box>
      <Box px={10} display="flex" flexDirection="column" flex={1}>
        <DialogTitle>Schedule your message to send</DialogTitle>
        <DialogContent className={classes.formContent}>
          <Box mt={2}>
            <Box display="flex" flexDirection={'row'} alignItems={'center'}>
              <DateTimePicker
                okLabel="Schedule message"
                className={classes.dateFormField}
                label="Scheduled date and time"
                minDate={new Date()}
                minDateMessage="Date must be after today"
                onChange={value => {
                  const now = new Date()
                  if ((value as Date) <= now) {
                    value = new Date()
                  }
                  value = roundToGreaterHalfHour(value as Date)
                  scheduleReward({
                    variables: {
                      id: espMessageReward?.id.toString() || '',
                      accountId: espMessageReward?.account?.id.toString() || '',
                      scheduledDatetime: value,
                    },
                  })
                }}
                value={espMessageReward?.scheduledJob?.scheduledAt || null}
                hideTabs={true}
                type="datetime"
                disabled={scheduleRewardLoading || unscheduleRewardLoading}
              />
            </Box>
            <Typography style={{ maxWidth: 300 }}>
              Time shown in your browser time zone. Your selected time was rounded to the nearest half hour.
            </Typography>
            {!!espMessageReward?.scheduledJob && (
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: 10 }}
                onClick={() => {
                  unschduleReward({
                    variables: {
                      id: espMessageReward.id.toString(),
                      accountId: espMessageReward.account?.id.toString() || '',
                    },
                  })
                }}
              >
                Cancel scheduled activity
              </Button>
            )}
          </Box>
          {isEditModal && (
            <Box mt={10} mb={10}>
              <Typography variant="subtitle1">Message logs</Typography>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Sent on</TableCell>
                      <TableCell>Recipients</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!rewardBatchJobs ||
                      (rewardBatchJobs.length === 0 && (
                        <TableRow style={{ height: '300px' }}>
                          <TableCell colSpan={4} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            This message has not yet been sent
                          </TableCell>
                        </TableRow>
                      ))}
                    {rewardBatchJobs?.map(job => (
                      <TableRow key={job.id}>
                        <TableCell>
                          <Box display="flex" flexDirection={'column'}>
                            <Typography variant="inherit">{job.isRetry ? 'Retry' : 'Scheduled Send'}</Typography>
                            <Typography>{getMessageLogStatusText(job.status)}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {!!job.startedAt
                            ? formatUTCDateToClientLocalTime(job.startedAt, {
                                month: 'short',
                              })
                            : null}
                        </TableCell>
                        <TableCell>
                          {job.sentCount} sent
                          {!!job.failedCount && job.failedCount > 0 ? ', ' + job.failedCount + ' failed' : ''}
                        </TableCell>
                        <TableCell>
                          {[RewardBatchJobStatus.Failure, RewardBatchJobStatus.PartialSuccess].includes(job.status) &&
                            !job.retryAttempted && (
                              <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                onClick={() => {
                                  retryRewardBatchJob({
                                    variables: {
                                      reward_id: espMessageReward.id.toString() || '',
                                      reward_batch_job_id: job.id.toString(),
                                    },
                                  })
                                }}
                                disabled={retryRewardBatchJobLoading}
                              >
                                Retry
                              </Button>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {!rewardBatchJobsQueryResults.loading &&
                      (rewardBatchJobs || []).length > 0 &&
                      !!rewardBatchJobCursor && (
                        <TableRow>
                          <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                if (!rewardBatchJobCursor) return
                                refreshRewarcBatchJobs(rewardBatchJobCursor)
                              }}
                            >
                              Load more
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default EspSchedulerModal
