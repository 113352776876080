import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
export type UserDetailsFragment = { __typename: 'UserType', email: string, id: number, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null };

export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on UserType {
  email
  id
  roles {
    id
    name
  }
}
    `;