import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { PostAccountDetailsFragmentDoc } from './post-account-details.generated';
import { UserDetailsFragmentDoc } from './user-details.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OtherAccountDetailsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
}>;


export type OtherAccountDetailsQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, organization: { __typename: 'Organization', activeProducts: Array<Types.AccountProductName>, id: string, labelLimit: { __typename: 'LabelLimit', hasHitLimit: boolean } }, labels?: { __typename: 'PagedLabelType', total: number, results: Array<{ __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string }> } | null } | null, whoami?: { __typename: 'UserType', email: string, id: number, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null } | null };


export const OtherAccountDetailsDocument = gql`
    query OtherAccountDetails($accountId: ID!) {
  account(id: $accountId) {
    ...PostAccountDetails
  }
  whoami {
    ...UserDetails
  }
}
    ${PostAccountDetailsFragmentDoc}
${UserDetailsFragmentDoc}`;

/**
 * __useOtherAccountDetailsQuery__
 *
 * To run a query within a React component, call `useOtherAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOtherAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<OtherAccountDetailsQuery, OtherAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherAccountDetailsQuery, OtherAccountDetailsQueryVariables>(OtherAccountDetailsDocument, options);
      }
export function useOtherAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherAccountDetailsQuery, OtherAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherAccountDetailsQuery, OtherAccountDetailsQueryVariables>(OtherAccountDetailsDocument, options);
        }
export type OtherAccountDetailsQueryHookResult = ReturnType<typeof useOtherAccountDetailsQuery>;
export type OtherAccountDetailsLazyQueryHookResult = ReturnType<typeof useOtherAccountDetailsLazyQuery>;
export type OtherAccountDetailsQueryResult = Apollo.QueryResult<OtherAccountDetailsQuery, OtherAccountDetailsQueryVariables>;