import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingUserInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BillingUserInfoQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, account?: { __typename: 'AccountType', id: number, name: string, organization: { __typename: 'Organization', id: string, activeProducts: Array<Types.AccountProductName>, postLimit?: number | null, labelLimit: { __typename: 'LabelLimit', isUnlimited: boolean, used: number, limit?: number | null }, segmentLimit: { __typename: 'SegmentLimit', isUnlimited: boolean, used: number, limit?: number | null }, userLimit: { __typename: 'UserLimit', isUnlimited: boolean, used: number, limit?: number | null }, socialAccountLimit: { __typename: 'SocialAccountLimit', isUnlimited: boolean, used: number, limit?: number | null }, plans: Array<{ __typename: 'AccountPlanType', id: string, amount: number, currentPeriodEnd: Date, currency?: string | null, interval?: string | null, planType?: string | null, customerId: string, isActive: boolean, stripeProducts?: Array<{ __typename: 'StripeProduct', id: string, productId: string, name: string, description?: string | null, isTrialEligible: boolean, isInApp: boolean }> | null }> } } | null } | null };


export const BillingUserInfoDocument = gql`
    query BillingUserInfo {
  whoami {
    id
    email
    account {
      id
      name
      organization {
        id
        activeProducts
        labelLimit {
          isUnlimited
          used
          limit
        }
        segmentLimit {
          isUnlimited
          used
          limit
        }
        postLimit
        userLimit {
          isUnlimited
          used
          limit
        }
        socialAccountLimit {
          isUnlimited
          used
          limit
        }
        plans {
          id
          amount
          currentPeriodEnd
          currency
          interval
          planType
          customerId
          isActive
          stripeProducts {
            id
            productId
            name
            description
            isTrialEligible
            isInApp
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBillingUserInfoQuery__
 *
 * To run a query within a React component, call `useBillingUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<BillingUserInfoQuery, BillingUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingUserInfoQuery, BillingUserInfoQueryVariables>(BillingUserInfoDocument, options);
      }
export function useBillingUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingUserInfoQuery, BillingUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingUserInfoQuery, BillingUserInfoQueryVariables>(BillingUserInfoDocument, options);
        }
export type BillingUserInfoQueryHookResult = ReturnType<typeof useBillingUserInfoQuery>;
export type BillingUserInfoLazyQueryHookResult = ReturnType<typeof useBillingUserInfoLazyQuery>;
export type BillingUserInfoQueryResult = Apollo.QueryResult<BillingUserInfoQuery, BillingUserInfoQueryVariables>;