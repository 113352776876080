import React from 'react'
import { TabProps } from './PostDetailsTabs'
import { isTypeName } from '../../types/utility'
import { PostAccountDetailsFragment } from './operations/post-account-details.generated'
import { ReactComponent as MessageIcon } from '../../icons/message_icon_major.svg'
import PostDetailsMessageTab from './PostDetailsMessageTab'
import { SentMessagesActivityByMentionQueryResult } from './operations/query-sent-messages-by-mention.generated'

interface PostDetailsMessageTabConfigProps {
  mentionId: string
  socialAccount?: { id: string; __typename: string }
  account?: PostAccountDetailsFragment | null
  postCount?: number | null
  poster?: { username: string; customer?: { id: number } | null }
  mediaId?: string
  messageActivityQueryResults: SentMessagesActivityByMentionQueryResult
}

export const buildMessageTabConfig = (props: PostDetailsMessageTabConfigProps): TabProps => {
  const { socialAccount, postCount, poster, mentionId, mediaId, messageActivityQueryResults } = props

  const isIGCustomerAccount = socialAccount && isTypeName(socialAccount, 'IGSocialAccount')
  const hasActiveIGStories = isIGCustomerAccount && postCount
  const messageSocialAccount = messageActivityQueryResults.data?.socialAccount
  const hasMessageHistory =
    messageSocialAccount &&
    isTypeName(messageSocialAccount, 'IGSocialAccount') &&
    !!messageSocialAccount.sentMessages.total

  return {
    icon: <MessageIcon width={25} height={25} />,
    content: (
      <PostDetailsMessageTab
        customer={poster?.customer}
        username={poster?.username}
        socialAccountId={socialAccount?.id}
        mentionId={mentionId}
        mediaId={mediaId}
        messageActivityQueryResults={messageActivityQueryResults}
        isEditable={!!hasActiveIGStories}
      />
    ),
    disabled: !isIGCustomerAccount || !(hasActiveIGStories || hasMessageHistory),
    disabledReason: (
      <>
        <div>Unable to send a message:</div>
        {!isIGCustomerAccount
          ? 'Messages are not yet available for TikTok. Select an Instagram account.'
          : !(hasActiveIGStories || hasMessageHistory) && 'Customer does not have an active story nor message history.'}
      </>
    ),
  }
}
