import React, { forwardRef, useRef, useState } from 'react'
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { SingleMenuOption } from './types'
import FilterChip from './FilterChip'
import MenuHeader from '../../MenuHeader'
import { primary } from '../../../loudcrowd-theme'

export type Ref = HTMLDivElement

interface SelectFilterMenuProps {
  option: SingleMenuOption
  onSelectValue(newValue: string): void
}

function SelectFilterMenu({ option, onSelectValue }: SelectFilterMenuProps): React.ReactElement {
  return (
    <>
      {option.selectionOptions.map(o => (
        <MenuItem value={o.id} key={o.id} onClick={e => onSelectValue(o.id)}>
          {o.label}
        </MenuItem>
      ))}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  inlineButton: {
    color: primary[500],
    fontSize: 'inherit',
    minWidth: 0,
    fontWeight: 400,
    textDecoration: 'underline',
    padding: 0,
    marginBottom: 4,
    textAlign: 'left',
    height: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}))

interface MenuSelectionFilterChipProps {
  option: SingleMenuOption
  value: string
  editable?: boolean
  onDelete(): void
  onSelectValue(newValue: string): void
}

export function MenuSelectionFilterChip({
  option,
  value,
  editable,
  onSelectValue,
}: MenuSelectionFilterChipProps): React.ReactElement {
  const classes = useStyles()
  const chipRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const selectedOption = option.selectionOptions.find(c => c.id.toString() === value)
  return (
    <>
      {selectedOption && (
        <>
          {option?.displayVariant === 'inline' ? (
            <Button
              ref={buttonRef}
              variant="text"
              className={classes.inlineButton}
              style={option.styles ? { ...option.styles } : undefined}
              onClick={() => editable && setMenuOpen(true)}
            >
              {selectedOption.label}
            </Button>
          ) : (
            <FilterChip ref={chipRef} onClick={() => editable && setMenuOpen(true)} variant="outlined" size="small">
              {selectedOption.label}
            </FilterChip>
          )}

          <Menu
            open={menuOpen}
            anchorEl={option.displayVariant === 'inline' ? buttonRef.current : chipRef.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            getContentAnchorEl={null}
            onClose={() => setMenuOpen(false)}
          >
            <SelectFilterMenu
              option={option}
              onSelectValue={s => {
                onSelectValue(s)
                setMenuOpen(false)
              }}
            />
          </Menu>
        </>
      )}
    </>
  )
}

interface MenuSelectionFilterMenuProps {
  option: SingleMenuOption
  onSelectValue(newValue: string): void
  onBack(): void
}

const MenuSelectionFilterMenu = forwardRef<Ref, MenuSelectionFilterMenuProps>((props, ref) => {
  const { option, onSelectValue, onBack } = props
  return (
    <div>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <SelectFilterMenu option={option} onSelectValue={onSelectValue} />
    </div>
  )
})

MenuSelectionFilterMenu.displayName = 'MenuSelectionFilterMenu'

export default MenuSelectionFilterMenu
