import React from 'react'
import { makeStyles, createStyles, Theme, Typography, Box } from '@material-ui/core'

import { ReactComponent as MaginifyingGlassIcon } from '../icons/magnifying_glass.svg'
import { ReactComponent as CaretIcon } from '../icons/caret.svg'
import CardTextInput from '../components/CardTextInput'
import { CriteriaOperatorEnum } from '../gql-global'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      '&:hover': {
        cursor: 'default',
      },
      color: theme.palette.primary.dark,
    },
    underline: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      marginLeft: '16px',
      color: theme.palette.primary.dark,
    },
    clickableText: {
      display: 'flex',
      justifyContent: 'center',
    },
    caret: {
      color: theme.palette.primary.main,
      marginTop: '11px',
      position: 'relative',
      marginLeft: '6px',
    },
    icon: {
      marginTop: '39px',
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
    renderedValuesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '-12px',
      marginBottom: '-4px',
      width: '225px',
      flexDirection: 'row',
    },
    renderedText: {
      marginRight: '4px',
      color: theme.palette.primary.dark,
      fontSize: '12px',
    },
  }),
)

interface RenderedKeywordsValuesProps {
  values?: string[] | null
  criteriaOperatorValue: CriteriaOperatorEnum
}

export function RenderedKeywordsValues({
  values,
  criteriaOperatorValue,
}: RenderedKeywordsValuesProps): React.ReactElement {
  const classes = useStyles()
  const keywordsText: React.ReactElement[] | undefined = values?.map((v, idx) => {
    const lower = v.toLowerCase()
    return (
      <Typography className={classes.renderedText} key={idx}>
        {lower}
        {idx !== values.length - 1 ? ',' : ''}{' '}
      </Typography>
    )
  })
  return (
    <>
      {values && values?.length > 0 && (
        <Box className={classes.renderedValuesContainer}>
          {criteriaOperatorValue && (
            <Typography className={classes.renderedText} variant="subtitle2">
              {criteriaOperatorValue}:
            </Typography>
          )}
          {keywordsText}
        </Box>
      )}
    </>
  )
}

interface KeywordsFieldProps {
  value?: string[] | null
  setValue: (value: string[]) => void
  resetValue: () => string[]
  isValueSelected: () => boolean
  disabled?: boolean
  setCriteriaOperator: (val: CriteriaOperatorEnum) => void
  criteriaOperatorValue: CriteriaOperatorEnum
}

function KeywordsField({
  value,
  setValue,
  resetValue,
  isValueSelected,
  disabled = false,
  setCriteriaOperator,
  criteriaOperatorValue,
}: KeywordsFieldProps): React.ReactElement {
  const classes = useStyles()

  function handleSetValue(value: string[]): void {
    const formatted = value.map(v => v.toLowerCase())
    const unique = Array.from(new Set(formatted))
    setValue(unique)
  }

  return (
    <Box>
      <CardTextInput
        value={value}
        label="Caption Keywords"
        setValue={handleSetValue}
        resetValue={resetValue}
        isValueSelected={isValueSelected}
        keywordsOption={{ allowSpaces: true, allowQuotes: true }}
        disabled={disabled}
        showCriteriaOperatorToggle
        setCriteriaOperator={setCriteriaOperator}
        criteriaOperatorValue={criteriaOperatorValue}
        criteriaOperatorLabel={'REQUIRED KEYWORDS'}
        renderCriteriaOperatorHelpText={(val: CriteriaOperatorEnum) => `Participants will need to use ${val} of these`}
      >
        <MaginifyingGlassIcon className={classes.icon} />
        <Typography className={classes.typography}>Include caption</Typography>
        <Box className={classes.clickableText}>
          <Typography className={`${classes.typography} ${classes.underline}`}>
            keywords
            <CaretIcon className={classes.caret} />
          </Typography>
        </Box>
      </CardTextInput>
      <RenderedKeywordsValues values={value} criteriaOperatorValue={criteriaOperatorValue} />
    </Box>
  )
}

export default KeywordsField
