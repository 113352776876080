import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFulfillmentAccountMutationVariables = Types.Exact<{
  fulfillmentAccountId: Types.Scalars['ID'];
}>;


export type DeleteFulfillmentAccountMutation = { __typename: 'Mutations', deleteFulfillmentAccount?: { __typename: 'DeleteFulfillmentAccount', ok?: boolean | null } | null };


export const DeleteFulfillmentAccountDocument = gql`
    mutation DeleteFulfillmentAccount($fulfillmentAccountId: ID!) {
  deleteFulfillmentAccount(fulfillmentAccountId: $fulfillmentAccountId) {
    ok
  }
}
    `;
export type DeleteFulfillmentAccountMutationFn = Apollo.MutationFunction<DeleteFulfillmentAccountMutation, DeleteFulfillmentAccountMutationVariables>;

/**
 * __useDeleteFulfillmentAccountMutation__
 *
 * To run a mutation, you first call `useDeleteFulfillmentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFulfillmentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFulfillmentAccountMutation, { data, loading, error }] = useDeleteFulfillmentAccountMutation({
 *   variables: {
 *      fulfillmentAccountId: // value for 'fulfillmentAccountId'
 *   },
 * });
 */
export function useDeleteFulfillmentAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFulfillmentAccountMutation, DeleteFulfillmentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFulfillmentAccountMutation, DeleteFulfillmentAccountMutationVariables>(DeleteFulfillmentAccountDocument, options);
      }
export type DeleteFulfillmentAccountMutationHookResult = ReturnType<typeof useDeleteFulfillmentAccountMutation>;
export type DeleteFulfillmentAccountMutationResult = Apollo.MutationResult<DeleteFulfillmentAccountMutation>;
export type DeleteFulfillmentAccountMutationOptions = Apollo.BaseMutationOptions<DeleteFulfillmentAccountMutation, DeleteFulfillmentAccountMutationVariables>;