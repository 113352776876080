import React, { useMemo } from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  ComposedChart,
  ReferenceDot,
  ReferenceLine,
  TooltipProps,
} from 'recharts'
import { Theme, useTheme } from '@material-ui/core/styles'
import { numberFormat, NumberFormatTypes } from '../../utils/number-format'
import { TimeDataPoint, ChartDataPoint, ChartProps, ChartVRefPoint } from './types'
import { convertTimeData, chartValueFormatter, convertVRefLines } from './helpers'
import withXAxisTick from './withXAxisTick'
import { DEFAULT_COLOR } from './constants'
import { differenceInDays, getDaysInMonth } from 'date-fns'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { ColorOptions } from '../../loudcrowd-theme'
import { useWindowSize } from 'usehooks-ts'
import SquarePolynomialModel from '../../utils/predict'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

export type LineChartProps<K extends string, T extends TimeDataPoint<K>> = ChartProps<K, T>
interface StyledProps {
  labelColor: string
  backgroundColor: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refLineWrapper: {
      border: 'none',
      backgroundColor: theme.palette['secondary'].main,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[9],
      margin: 0,
      padding: '3px 16px',
      minHeight: '30px',
      minWidth: '120px',
    },
    refLabelStyle: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
    contentStyle: {
      border: 'none',
      backgroundColor: (props: StyledProps) => props.backgroundColor,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[9],
      margin: 0,
      padding: '8px 16px',
    },
    wrapperStyle: { height: '62px', minWidth: '120px' },
    textStyle: {
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      padding: 0,
    },
    itemStyle: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
    labelStyle: {
      color: (props: StyledProps) => props.labelColor,
    },
  }),
)

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  chartVRefLines?: ChartVRefPoint[]
  color: ColorOptions
  dataFormat: NumberFormatTypes | undefined
  dataLabel: string
}

const CustomTooltip = ({
  active,
  payload,
  label,
  chartVRefLines,
  color,
  dataFormat,
  dataLabel,
}: CustomTooltipProps) => {
  const theme = useTheme()
  const classes = useStyles({
    labelColor: active && payload && payload.length ? theme.palette[color].light : theme.palette[DEFAULT_COLOR].light,
    backgroundColor:
      active && payload && payload.length ? theme.palette[color].main : theme.palette[DEFAULT_COLOR].main,
  })
  if (active && payload && payload[0]) {
    const ref = chartVRefLines?.find(l => l.dateRange === payload[0]?.payload.dateRange)
    return (
      <div>
        {ref && (
          <div className={classes.refLineWrapper}>
            <p className={classes.refLabelStyle + ' ' + classes.textStyle}>{ref.topLabel}</p>
            {ref.botLabel && <p className={classes.refLabelStyle + ' ' + classes.textStyle}>{ref.botLabel}</p>}
          </div>
        )}
        <div className={classes.contentStyle + ' ' + classes.wrapperStyle}>
          <p className={classes.labelStyle + ' ' + classes.textStyle}>{label}</p>
          <p className={classes.itemStyle + ' ' + classes.textStyle}>
            {dataLabel} {chartValueFormatter(payload[0].value as string, payload[0], dataFormat)}
          </p>
        </div>
      </div>
    )
  }

  return null
}

function LineChart<K extends string, T extends TimeDataPoint<K>>({
  data,
  label,
  color = DEFAULT_COLOR,
  dataKey,
  dataFormat,
  vRefLines,
  doProjection = true,
  hasPolynomialRegressionFeature = false,
}: LineChartProps<K, T>): React.ReactElement | null {
  const theme = useTheme()
  const colorDict = theme.palette[color]
  const chartData = convertTimeData(data)
  const { width } = useWindowSize()
  const firstChartData = chartData[0]
  const lastChartData = chartData[chartData.length - 1]
  const updatedChartData = [...chartData]
  let showProjection = false
  if (firstChartData && lastChartData) {
    const chartDateRange =
      lastChartData && firstChartData && differenceInDays(lastChartData.endDate, firstChartData.startDate)
    showProjection =
      lastChartData.partial === 0 &&
      lastChartData[dataKey] > 0 &&
      chartData.length > 1 &&
      dataKey !== 'engagement' &&
      chartDateRange >= 60 &&
      doProjection
  }

  let projected = 0
  if (showProjection && firstChartData && lastChartData) {
    if (hasPolynomialRegressionFeature) {
      const X = Array.from(Array(chartData.length - 1).keys())
      const Y = chartData.slice(0, -1).map(point => {
        return point[dataKey]
      })
      const model = new SquarePolynomialModel()
      model.fit(X, Y)
      projected = model.predict(chartData.length - 1)
      projected = Math.max(projected, lastChartData[dataKey])
    } else {
      const intervalRange = differenceInDays(lastChartData.endDate, lastChartData.startDate)
      const elapsedDays = differenceInDays(new Date(), lastChartData.startDate) || 1
      const numerator = intervalRange <= 7 ? 7 : getDaysInMonth(lastChartData.startDate)
      projected = (lastChartData[dataKey] * numerator) / elapsedDays
    }

    if (projected > lastChartData[dataKey]) {
      lastChartData.actualValue = lastChartData[dataKey]
      lastChartData.projected = projected
      lastChartData[dataKey] = projected as ChartDataPoint<string, T>[K]
    }
  }

  const gap = Math.ceil(chartData.length / 12)
  const XAxisTick = useMemo(() => withXAxisTick(gap, theme.palette.secondary.main, width), [
    gap,
    theme.palette.secondary.main,
    width,
  ])
  return (
    <ResponsiveContainer key={label} width="99%">
      <ComposedChart data={updatedChartData} margin={{ top: 10, left: 30, bottom: 20 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colorDict.main} stopOpacity={0.3} />
            <stop offset="85%" stopColor={colorDict.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="dateRange"
          axisLine={false}
          tickLine={false}
          padding={{ left: 25, right: 25 }}
          interval={0}
          tick={XAxisTick}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={({ x, y, payload: { value, offset } }) => (
            <text textAnchor="end" x={x - 30} y={y + offset} fill={theme.palette.secondary.main}>
              {numberFormat(value, { format: dataFormat })}
            </text>
          )}
        />
        <Tooltip
          animationDuration={500}
          content={(p: TooltipProps<ValueType, NameType>) => (
            <CustomTooltip
              {...p}
              chartVRefLines={vRefLines ? convertVRefLines(vRefLines) : undefined}
              color={color}
              dataFormat={dataFormat}
              dataLabel={label}
            />
          )}
        />
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke={colorDict.main}
          strokeWidth={3}
          dot={{
            r: 8,
            stroke: theme.palette.common.white,
            strokeWidth: 3,
            fill: colorDict.main,
            fillOpacity: 1,
          }}
          activeDot={{ r: 8 }}
          fill="url(#colorUv)"
        />
        {projected && lastChartData && (
          <ReferenceDot
            x={lastChartData.dateRange}
            y={projected}
            r={8}
            fill="white"
            stroke={colorDict.main}
            strokeWidth={3}
          />
        )}
        {vRefLines &&
          convertVRefLines(vRefLines).map(vRefLine => {
            return (
              <ReferenceLine
                key={vRefLine.dateRange}
                x={vRefLine.dateRange}
                stroke={theme.palette['secondary'].main}
                strokeWidth="2"
                strokeDasharray="3"
              />
            )
          })}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default LineChart
