import React, { useState, useRef, useEffect } from 'react'
import { Typography, Box } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { DateTextField } from '../DateTextField/DateTextField'
import { format, addDays, subDays } from 'date-fns'

export interface DateRangeModel {
  gte?: Date | null
  lt?: Date | null
}

export interface AbsoluteDateRangePickerProps {
  onChange(dateRange: DateRangeModel): void
  dateRange?: DateRangeModel | null
  minDate?: Date
  maxDate?: Date
  startDateDisabled?: boolean
  endDateDisabled?: boolean
}

const AbsoluteDateRangePicker: React.FC<AbsoluteDateRangePickerProps> = props => {
  const { onChange, dateRange, maxDate, minDate, startDateDisabled, endDateDisabled } = props
  const endDateRef = useRef<HTMLInputElement>(null)
  const [currentCustomDateField, setCurrentCustomDateField] = useState<'START_DATE' | 'END_DATE'>('START_DATE')

  useEffect(() => {
    if (startDateDisabled) {
      jumpToNextDateField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDatePickerChange = (date?: Date): void => {
    const updatedDateRange: DateRangeModel = { ...dateRange }
    if (currentCustomDateField === 'START_DATE') {
      updatedDateRange.gte = date
      onChange(updatedDateRange)
      if (date) jumpToNextDateField()
    } else if (currentCustomDateField === 'END_DATE') {
      updatedDateRange.lt = date ? addDays(date, 1) : date
      onChange(updatedDateRange)
    }
  }

  const jumpToNextDateField = (): void => {
    if (currentCustomDateField === 'START_DATE' && endDateRef.current) {
      endDateRef.current.focus()
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      jumpToNextDateField()
    }
  }

  const ltViewValue = dateRange?.lt ? subDays(dateRange.lt, 1) : null
  const defaultMaxDate = maxDate ? maxDate : new Date()
  const defaultMinDate = minDate ? minDate : new Date(0)

  return (
    <Box width={325}>
      <Box display="flex" flexDirection="row" mr={5} ml={5} mt={4} justifyContent="center" alignItems="center">
        <DateTextField
          id="start-date-input"
          autoFocus
          value={dateRange?.gte ? format(dateRange.gte, 'MMM d, yyyy') : ''}
          onFocus={() => setCurrentCustomDateField('START_DATE')}
          selected={currentCustomDateField === 'START_DATE'}
          onChange={onDatePickerChange}
          onKeyDown={onKeyDown}
          disabled={startDateDisabled}
        />
        <Box mx={3}>
          <Typography variant="caption">AND</Typography>
        </Box>
        <DateTextField
          id="end-date-input"
          value={ltViewValue ? format(ltViewValue, 'MMM d, yyyy') : ''}
          onFocus={() => setCurrentCustomDateField('END_DATE')}
          selected={currentCustomDateField === 'END_DATE'}
          inputRef={endDateRef}
          onChange={onDatePickerChange}
          onKeyDown={onKeyDown}
          disabled={endDateDisabled}
        />
      </Box>
      <DatePicker
        initialFocusedDate={dateRange?.gte || null}
        disableToolbar
        variant="static"
        format="MM/dd/yyy"
        margin="normal"
        onChange={date => {
          if (date) {
            onDatePickerChange(date)
          }
        }}
        value={(currentCustomDateField === 'START_DATE' ? dateRange?.gte : ltViewValue) || null}
        minDate={currentCustomDateField === 'END_DATE' && dateRange?.gte ? dateRange.gte : defaultMinDate}
        maxDate={currentCustomDateField === 'START_DATE' && ltViewValue ? ltViewValue : defaultMaxDate}
      />
    </Box>
  )
}

export default AbsoluteDateRangePicker
