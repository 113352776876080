import PhoneInput, { Value } from 'react-phone-number-input'
import { TextField, TextFieldProps } from '@material-ui/core'
import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  phoneInput: {
    '& .PhoneInputCountry': {
      display: 'none',
    },
    display: 'flex',
    marginBottom: theme.spacing(5),
    minWidth: 300,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
  },
}))

function PhoneTextField(props: TextFieldProps, ref: TextFieldProps['ref']) {
  return <TextField {...props} inputRef={ref} fullWidth label="Phone" name="phone" />
}

const PhoneTextFieldInput = forwardRef(PhoneTextField)

interface PhoneNumberInputProps {
  value: string
  onChange: (value: string) => void
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange }) => {
  const classes = useStyles()
  return (
    <PhoneInput
      type="text"
      id="phone-input"
      className={classes.phoneInput}
      value={value as Value}
      defaultCountry={'US'}
      inputComponent={PhoneTextFieldInput}
      onChange={onChange as (value: Value) => void}
    />
  )
}

export default PhoneNumberInput
