import { ContentFilterValueType, CustomerFilterValueType, MentionStatus } from '../gql-global'
import { IgMentionTypes, MentionTypes, TtMentionTypes } from '../content/constants'
import { filterArrayEnumValues } from '../types/utility'

type MakeMentionTypesWhereReturnType = {
  wasCaptionMentioned?: boolean
  wasStoryMentioned?: boolean
  wasTagged?: boolean
  wasHashtagMentioned?: boolean
}

export function makeMentionTypesWhere(
  selectedMentionTypes: Set<MentionTypes> | null | undefined,
  isInstagramAccount: boolean,
  hasTikTokHashtags: boolean,
): MakeMentionTypesWhereReturnType {
  const accountMentionTypes = isInstagramAccount ? IgMentionTypes : TtMentionTypes
  const allMentionTypesSelected = selectedMentionTypes?.size === Object.values(accountMentionTypes).length
  const hasMentionType = (mentionType: MentionTypes) =>
    (!allMentionTypesSelected && selectedMentionTypes?.has(mentionType)) || undefined
  return {
    wasCaptionMentioned: hasMentionType(isInstagramAccount ? IgMentionTypes.Caption : TtMentionTypes.TT_Caption),
    wasTagged: isInstagramAccount ? hasMentionType(IgMentionTypes.Tag) : undefined,
    wasStoryMentioned: isInstagramAccount ? hasMentionType(IgMentionTypes.Story) : undefined,
    wasHashtagMentioned: !isInstagramAccount && hasTikTokHashtags ? hasMentionType(TtMentionTypes.Hashtag) : undefined,
  }
}

export function filterResponseMentionTypesToSet(
  filters: ContentFilterValueType | CustomerFilterValueType | undefined | null,
  isInstagramAccount: boolean,
): Set<MentionTypes> {
  const filtersMentionTypes = new Set<MentionTypes>()
  if (filters?.wasCaptionMentioned)
    filtersMentionTypes.add(isInstagramAccount ? IgMentionTypes.Caption : TtMentionTypes.TT_Caption)
  if (isInstagramAccount) {
    if (filters?.wasTagged) filtersMentionTypes.add(IgMentionTypes.Tag)
    if (filters?.wasStoryMentioned) filtersMentionTypes.add(IgMentionTypes.Story)
  } else {
    if (filters?.wasHashtagMentioned) filtersMentionTypes.add(TtMentionTypes.Hashtag)
  }
  return filtersMentionTypes
}

export function filterMentionTypesSetForAccountType(
  mentionTypes: Set<MentionTypes> | null | undefined,
  isInstagramAccount: boolean,
  hasTikTokHashtags: boolean,
): Set<MentionTypes> {
  return new Set<MentionTypes>(
    isInstagramAccount
      ? filterArrayEnumValues(IgMentionTypes, mentionTypes)
      : hasTikTokHashtags
      ? filterArrayEnumValues(TtMentionTypes, mentionTypes)
      : [],
  )
}

export function postStatusArrayFilterFromSelection(
  selectedPostStatus: Set<MentionStatus> | null,
): Array<MentionStatus> {
  return selectedPostStatus?.size ? Array.from(selectedPostStatus) : [MentionStatus.Verified, MentionStatus.Unverified]
}
