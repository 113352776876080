import React from 'react'
import { Grid } from '@material-ui/core'
import Page from '../Page'
import DashboardOverviewBlock from './DashboardOverviewBlock'
import DashboardTopPostBlock from './DashboardTopPostsBlock'
import TopCustomersBlock from './TopCustomersBlock'
import ContentLeaderboardBlock from './ContentLeaderboardBlock'
import { useUpdateLastDashboardDateRangeMutation } from './operations/update-last-dashboard-date-range.generated'
import { useDashboardUserInfoQuery } from './operations/query-dashboard-user-info.generated'
import ContainerEmptyState, { ContainerEmptyStateProps } from '../components/ContainerEmptyState/ContainerEmptyState'

import { ReactComponent as ConnectToSocialImage } from '../images/Connect-to-social.svg'
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg'
import { INTEGRATIONS_ROUTE } from '../integrations/routes'
import { CUSTOMER_ROUTE } from '../customer/routes'
import ContainerError from '../components/ContainerError'
import SyncPendingSnackbar from '../components/SyncPendingSnackbar'
import withAuthorization from '../withAuthorization'
import { DASHBOARD_ROUTE } from './routes'
import { useUpdateSelectedMentionTypeFilterMutation } from './operations/update-selected-mention-type-filter.generated'
import { useUpdateSelectedPostStatusFilterMutation } from './operations/update-selected-post-status-filter.generated'
import useTitle from '../utils/use-title'
import {
  AccountProductName,
  AnySegmentCampaignFilterInput,
  DateRangeFilter,
  IgMediaPostType,
  MentionStatus,
} from '../gql-global'
import { realizedDateRangeFromFilter } from '../utils/date-range-helper'
import { IgMentionTypes, MentionTypes, MentionTypesMap, TtMentionTypes } from '../content/constants'
import useIntercomOnPage from '../hooks/useIntercomOnPage'
import { isTypeName } from '../types/utility'
import { useDashboardCampaignsQuery } from './operations/query-dashboard-campaigns.generated'
import { useUpdateSelectedPostTypeFilterMutation } from './operations/update-selected-post-type-filter.generated'
import { useUpdateSelectedChallengesFilterMutation } from './operations/update-selected-challenges-filter.generated'
import { setUnion } from '../utils/data-structures'
import { filterArrayEnumValues } from '../types/utility'
import useHasFeature from '../hooks/useHasFeature'
import { filterMentionTypesSetForAccountType } from './utils'
import useUserLocalStorage from '../hooks/useUserLocalStorage'

const Dashboard: React.FC = () => {
  useTitle('Dashboard')
  useIntercomOnPage('Dashboard')

  const [setSelectedMentionTypeFilter] = useUpdateSelectedMentionTypeFilterMutation()
  const [setSelectedPostTypeFilter] = useUpdateSelectedPostTypeFilterMutation()
  const [setSelectedChallengesFilter] = useUpdateSelectedChallengesFilterMutation()
  const { loading, data: userInfo, error } = useDashboardUserInfoQuery()
  const [setSelectedPostStatusFilter] = useUpdateSelectedPostStatusFilterMutation()
  const { email: username, roles: rolesObj, account, preferences } = userInfo?.whoami || {}
  const roles = rolesObj?.map(r => r.name) || []
  const { organization, segments: pagedSegments } = account || {}
  const activeProducts = organization?.activeProducts
  const segments = pagedSegments?.results ?? []
  const userHasCustomerProduct = CUSTOMER_ROUTE.hasAccess(roles, activeProducts, username)
  const {
    selectedSocialAccountId,
    lastDashboardDateRange,
    selectedMentionTypeFilter,
    selectedPostTypeFilter,
    selectedPostStatusFilter,
    selectedChallengesFilter,
  } = preferences || {}

  const platform = account?.socialAccounts?.filter(a => a.id === selectedSocialAccountId)[0]
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const [segmentCampaignFilter, setSegmentCampaignFilter] = useUserLocalStorage<AnySegmentCampaignFilterInput[] | null>(
    'segment_campaign_filter',
    null,
  )
  const [includePremembership, setIncludePremembership] = useUserLocalStorage<boolean>('include_premembership', true)

  const { data: campaignsData } = useDashboardCampaignsQuery({
    skip: !platform,
    variables: {
      socialAccountId: (platform && platform.id) || '',
    },
  })
  const campaigns = campaignsData?.socialAccount?.campaigns
    ? campaignsData?.socialAccount?.campaigns.map(campaign => ({
        id: campaign.id,
        startAt: campaign.startAt,
        name: campaign?.program?.name || '',
      }))
    : []

  const challenges =
    campaignsData?.socialAccount?.challenges?.results.map(challenge => ({
      id: challenge.id.toString(),
      name: challenge?.name || '',
    })) || []

  const isInstagramAccount = platform && isTypeName(platform, 'IGSocialAccount')

  // We filter so only Ig mention types are selected for Ig account, and only TikTok mention types for TT account
  const selectedMentionTypes = filterMentionTypesSetForAccountType(
    new Set<MentionTypes>(filterArrayEnumValues(MentionTypesMap, selectedMentionTypeFilter)),
    isInstagramAccount ?? false,
    hasTikTokHashtags,
  )
  const otherAccountTypeMentionTypes: Set<MentionTypes> = new Set(
    filterArrayEnumValues(MentionTypesMap, selectedMentionTypeFilter),
  )
  for (const mentionType of Object.values(isInstagramAccount ? IgMentionTypes : TtMentionTypes)) {
    otherAccountTypeMentionTypes.delete(mentionType)
  }

  const selectedPostTypes = new Set<IgMediaPostType>(
    isInstagramAccount ? filterArrayEnumValues(IgMediaPostType, selectedPostTypeFilter) : [],
  )
  const selectedPostStatus = new Set<MentionStatus>(filterArrayEnumValues(MentionStatus, selectedPostStatusFilter))
  const [setLastDateRange] = useUpdateLastDashboardDateRangeMutation()

  const dateRange = lastDashboardDateRange && realizedDateRangeFromFilter(lastDashboardDateRange)
  const setNewDateRange = (newRange: DateRangeFilter): void => {
    setLastDateRange({
      variables: {
        lastDashboardDateRange: newRange,
      },
    })
  }

  const hasCampaigns = !!userInfo?.whoami?.account?.organization.activeProducts?.some(
    p => p === AccountProductName.Campaign,
  )

  const emptyStateProps: ContainerEmptyStateProps = {
    image: ConnectToSocialImage,
    text: 'Almost ready for take-off',
    subtext: 'First, contact your account owner to connect a social media account.',
  }
  if (INTEGRATIONS_ROUTE.hasAccess(roles, activeProducts, username)) {
    emptyStateProps.subtext = "First, let's connect your social media account."
    emptyStateProps.callToActionText = 'Connect to Instagram'
    emptyStateProps.callToActionIcon = InstagramIcon
    emptyStateProps.callToActionLink = INTEGRATIONS_ROUTE.path
  }

  const selectedChallengeIdsFilter = {
    any: selectedChallengesFilter || [],
  }

  return (
    <Page>
      {selectedSocialAccountId && <SyncPendingSnackbar socialAccountId={selectedSocialAccountId} />}
      {!loading && error && <ContainerError text="Error loading dashboard" />}
      {!loading && !userInfo?.whoami?.account?.socialAccounts?.length && <ContainerEmptyState {...emptyStateProps} />}
      {!loading && !!userInfo?.whoami?.account?.socialAccounts?.length && lastDashboardDateRange && dateRange && (
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <DashboardOverviewBlock
              socialAccountId={selectedSocialAccountId}
              dateSelectionCallback={setNewDateRange}
              realizedDateRange={dateRange}
              dateRangeFilter={lastDashboardDateRange}
              segments={segments}
              campaignData={campaigns}
              segmentCampaignFilter={segmentCampaignFilter}
              setSegmentCampaignFilter={setSegmentCampaignFilter}
              selectedMentionTypes={selectedMentionTypes}
              onSelectMentionTypes={(values: Set<MentionTypes>) => {
                values = setUnion(otherAccountTypeMentionTypes, values)
                setSelectedMentionTypeFilter({ variables: { selectedMentionTypeFilter: Array.from(values) } })
              }}
              selectedPostTypes={selectedPostTypes}
              onSelectPostTypes={(values: Set<IgMediaPostType>) => {
                setSelectedPostTypeFilter({ variables: { selectedPostTypeFilter: Array.from(values) } })
              }}
              selectedPostStatus={selectedPostStatus}
              onSelectPostStatus={(values: Set<MentionStatus>) => {
                setSelectedPostStatusFilter({ variables: { selectedPostStatusFilter: Array.from(values) } })
              }}
              emvCpmUsd={userInfo?.whoami?.account?.settings?.emvCpmUsd}
              isInstagramAccount={isInstagramAccount}
              includePremembership={includePremembership}
              onSetIncludePremembership={setIncludePremembership}
              challengesData={challenges}
              selectedChallenges={selectedChallengeIdsFilter}
              onSelectChallenges={challengesFilter => {
                setSelectedChallengesFilter({ variables: { selectedChallengesFilter: challengesFilter } })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DashboardTopPostBlock
              hasCampaigns={hasCampaigns}
              socialAccountId={selectedSocialAccountId}
              realizedDateRange={dateRange}
              dateRangeFilter={lastDashboardDateRange}
              selectedMentionTypes={selectedMentionTypes}
              selectedPostTypes={selectedPostTypes}
              selectedPostStatus={selectedPostStatus}
              selectedChallenges={selectedChallengeIdsFilter}
              isInstagramAccount={isInstagramAccount}
              includePremembership={includePremembership}
              segmentCampaignFilter={segmentCampaignFilter}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TopCustomersBlock
              hasCampaigns={hasCampaigns}
              useCustomerPageLinks={userHasCustomerProduct}
              socialAccountId={selectedSocialAccountId}
              dateRangeFilter={lastDashboardDateRange}
              selectedMentionTypes={selectedMentionTypes}
              selectedPostTypes={selectedPostTypes}
              selectedPostStatus={selectedPostStatus}
              selectedChallenges={selectedChallengeIdsFilter}
              isInstagramAccount={isInstagramAccount}
              includePremembership={includePremembership}
              segmentCampaignFilter={segmentCampaignFilter}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ContentLeaderboardBlock
              socialAccountId={selectedSocialAccountId}
              dateRangeFilter={lastDashboardDateRange}
              selectedMentionTypes={selectedMentionTypes}
              selectedPostTypes={selectedPostTypes}
              selectedPostStatus={selectedPostStatus}
              selectedChallenges={selectedChallengeIdsFilter}
              isInstagramAccount={isInstagramAccount}
              includePremembership={includePremembership}
              segmentCampaignFilter={segmentCampaignFilter}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  )
}
export default withAuthorization(DASHBOARD_ROUTE)(Dashboard)
