import React from 'react'
import { Box } from '@material-ui/core'

const ListHeader: React.FC = ({ children }) => (
  <Box pt={7} pb={5} display="flex" justifyContent="space-between" alignItems="center">
    {children}
  </Box>
)

export default ListHeader
