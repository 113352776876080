import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import BrandAccountFilter, { SAFragment } from './BrandAccountFilter'
import PostCountFilter from './PostCountFilter'
import RewardFilter from './RewardFilter'
import { useProgramActivityBuilder } from './ProgramActivityBuilderProvider'

interface SingleTierMonthlyBuilderProps {
  socialAccounts: SAFragment[]
}

const SingleTierMonthlyBuilder: FC<SingleTierMonthlyBuilderProps> = ({ socialAccounts }) => {
  const { values, setSingleReward, setSocialAccountIds, setSinglePostCriteria, includesTikTok, includesInstagram } =
    useProgramActivityBuilder()

  return (
    <Box display="flex" flexDirection="column" gridRowGap={40} mt={10}>
      <Box fontSize={24} fontWeight={400}>
        Send <RewardFilter reward={values.reward} onRewardChange={setSingleReward} /> when a member
      </Box>
      <Box fontSize={24} fontWeight={400} position="relative">
        <BrandAccountFilter
          socialAccounts={socialAccounts || []}
          selectedSocialAccountIds={values?.socialAccountIds ?? []}
          onSelectedSocialAccountIds={setSocialAccountIds}
        />{' '}
        in{' '}
        <PostCountFilter
          postCountCriteria={values?.singlePostCriteria}
          onPostCountCriteriaChange={setSinglePostCriteria}
          isInstagramIncluded={includesInstagram}
          isTiktokIncluded={includesTikTok}
        />
      </Box>
    </Box>
  )
}

export default SingleTierMonthlyBuilder
