import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDiscountRedeemCodesMutationVariables = Types.Exact<{
  ecommDiscountRedeemCodeId: Types.Scalars['ID'];
}>;


export type DeleteDiscountRedeemCodesMutation = { __typename: 'Mutations', deleteDiscountRedeemCodes?: { __typename: 'DeleteDiscountRedeemCodes', ok: boolean } | null };


export const DeleteDiscountRedeemCodesDocument = gql`
    mutation DeleteDiscountRedeemCodes($ecommDiscountRedeemCodeId: ID!) {
  deleteDiscountRedeemCodes(ecommDiscountRedeemCodeId: $ecommDiscountRedeemCodeId) {
    ok
  }
}
    `;
export type DeleteDiscountRedeemCodesMutationFn = Apollo.MutationFunction<DeleteDiscountRedeemCodesMutation, DeleteDiscountRedeemCodesMutationVariables>;

/**
 * __useDeleteDiscountRedeemCodesMutation__
 *
 * To run a mutation, you first call `useDeleteDiscountRedeemCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscountRedeemCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscountRedeemCodesMutation, { data, loading, error }] = useDeleteDiscountRedeemCodesMutation({
 *   variables: {
 *      ecommDiscountRedeemCodeId: // value for 'ecommDiscountRedeemCodeId'
 *   },
 * });
 */
export function useDeleteDiscountRedeemCodesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscountRedeemCodesMutation, DeleteDiscountRedeemCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscountRedeemCodesMutation, DeleteDiscountRedeemCodesMutationVariables>(DeleteDiscountRedeemCodesDocument, options);
      }
export type DeleteDiscountRedeemCodesMutationHookResult = ReturnType<typeof useDeleteDiscountRedeemCodesMutation>;
export type DeleteDiscountRedeemCodesMutationResult = Apollo.MutationResult<DeleteDiscountRedeemCodesMutation>;
export type DeleteDiscountRedeemCodesMutationOptions = Apollo.BaseMutationOptions<DeleteDiscountRedeemCodesMutation, DeleteDiscountRedeemCodesMutationVariables>;