import React, { useState, useRef } from 'react'
import { Box, Button, Menu, MenuItem } from '@material-ui/core'

export type SortOptions<T extends string | number> = readonly { label: string; id: T }[]

interface ListSortProps<T extends string | number> {
  readonly sortOptions: SortOptions<T>
  currentSortOptionId: T
  onSortChange(sortId: T): void
}

function ListSort<T extends string | number>(props: ListSortProps<T>): React.ReactElement {
  const { sortOptions, currentSortOptionId, onSortChange } = props
  const [sortOpen, setSortOpen] = useState(false)
  const buttonRef = useRef(null)
  const currentLabel = sortOptions.find(o => o.id === currentSortOptionId)?.label
  const onSelectedSort = (id: T): void => {
    onSortChange(id)
    setSortOpen(false)
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box fontSize="body2.fontSize" color="secondary.main">
          Sort By
        </Box>
        <Button ref={buttonRef} onClick={() => setSortOpen(true)}>
          {currentLabel || 'N/A'}
        </Button>
      </Box>
      <Menu
        open={sortOpen}
        anchorEl={buttonRef.current}
        onClose={() => setSortOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
      >
        {sortOptions.map(({ id, label }) => (
          <MenuItem key={id} onClick={() => onSelectedSort(id)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ListSort
