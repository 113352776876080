import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import withAuthorization from '../withAuthorization'
import { CUSTOMER_DETAIL_ROUTE, CUSTOMER_ROUTE } from './routes'
import CustomerList from './CustomerList'
import CustomerDetail from './CustomerDetail'
import { clearSocialFieldsFilteringBySegments } from '../mutations/labels-cache-update'

function Customer(): React.ReactElement {
  const { cache } = useApolloClient()
  // The customer list queries are all cached
  // this helps speed things up for going back and forth between customer list and customer detail
  // but shouldnt cache the list forever as it gets stale fast for busy users
  // so when they leave the customer section of the app, clear cache for customer lists
  useEffect(() => {
    return () => {
      setTimeout(() => clearSocialFieldsFilteringBySegments(cache))
    }
  }, [cache])
  return (
    <Switch>
      <Route path={CUSTOMER_ROUTE.path} exact component={CustomerList} />
      <Route path={CUSTOMER_DETAIL_ROUTE.path} component={CustomerDetail} />
    </Switch>
  )
}

export default withAuthorization(CUSTOMER_ROUTE)(Customer)
