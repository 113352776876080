import { DecodedValueMap } from 'serialize-query-params'
import { StringParam, useQueryParams, encodeQueryParams, stringify } from 'use-query-params'
import { USERNAME_SEARCH_PARAM_KEY, usernameParam } from './use-username-param'
import { EMAIL_SEARCH_PARAM_KEY, emailParam } from './use-email-param'
import { BIOGRAPHY_SEARCH_PARAM_KEY, biographyParam } from './use-biography-param'
import { NOTES_SEARCH_PARAM_KEY, notesParam } from './use-notes-param'
import { createEnumSetParam, SetParam } from '../../utils/url-param-utils'
import { NumericalRange } from '../../components/NumericalRangePicker'
import {
  LabelsFilter,
  DateTimeRangeFilter,
  MentionStatus,
  MediaTypeEnum,
  CustomFieldsFilter,
  CustomFieldFilter,
  AbsoluteDateRangeFilter,
  AbsoluteDateTimeRangeFilter,
  IdFilterInput,
  IgMediaPostType,
  DateInclusionExclusionFilterInput,
  KeywordsFilterInput,
  MessageSendingMethodsFilterInput,
} from '../../gql-global'
import { MentionTypes, MentionTypesMap } from '../../content/constants'
import {
  getDateInclusionExclusionFiltersToParams,
  getDateFilterParamsConfig,
  cleanAllAnyNoneFilter,
  getDateInclusionExclusionParamsToFilters,
  getDateParamsToFilters,
  getDateRangeFilterToParams,
  getNumericalRangeParamToFilter,
  getNumericalRangeFilterParamsConfig,
  getNumericalRangeFilterToParams,
} from '../../utils/filter-params'
import { MESSAGE_KEYWORDS_SEARCH_PARAM_KEY } from '../../message/use-message-keywords-param'

export interface Filters {
  approvedAt?: DateTimeRangeFilter
  avgEngagementRate?: NumericalRange
  biographyKeywords?: string[]
  customFields?: Omit<CustomFieldsFilter, '__typename'>
  emailKeywords?: string[]
  challengeIds?: Omit<LabelsFilter, '__typename'>
  filterId?: string
  conversionRate?: NumericalRange
  referredOrders?: NumericalRange
  referredSalesRevenue?: NumericalRange
  commissionsEarned?: NumericalRange
  linkViews?: NumericalRange
  followerCount?: NumericalRange
  impressions?: NumericalRange
  labels?: Omit<LabelsFilter, '__typename'>
  minPostedAt?: DateTimeRangeFilter
  maxPostedAt?: DateTimeRangeFilter
  maxRewardedAt?: DateTimeRangeFilter
  mediaType?: Set<MediaTypeEnum>
  mentionType?: Set<MentionTypes>
  messageDate?: Omit<DateInclusionExclusionFilterInput, '__typename'>
  messageKeywords?: KeywordsFilterInput
  messageSendingMethod?: MessageSendingMethodsFilterInput
  messageTemplate?: Omit<LabelsFilter, '__typename'>
  orderStatus?: Set<string>
  commissionsPaymentStatus?: Set<string>
  noteCategories?: Set<string>
  noteCreatedAt?: DateTimeRangeFilter
  notesKeywords?: string[]
  postCount?: NumericalRange
  postType?: Set<IgMediaPostType>
  postedAt?: DateTimeRangeFilter
  dateRange?: DateTimeRangeFilter
  rewards?: Set<string>
  segments?: Omit<LabelsFilter, '__typename'>
  signedUpAt?: DateTimeRangeFilter
  status?: string
  tagStatus?: Set<MentionStatus>
  challengeMediaApproval?: Set<MentionStatus>
  usernameKeywords?: string[]
  paymentPeriods?: Omit<LabelsFilter, '__typename'>
  commissionTiers?: IdFilterInput
  commissionRate?: NumericalRange
}

const memberParamsConfig = {
  filterId: StringParam,
  [USERNAME_SEARCH_PARAM_KEY]: usernameParam,
  [EMAIL_SEARCH_PARAM_KEY]: emailParam,
  [BIOGRAPHY_SEARCH_PARAM_KEY]: biographyParam,
  [NOTES_SEARCH_PARAM_KEY]: notesParam,
  ...getDateFilterParamsConfig('approvedAt', ''),
  challengeIds: StringParam,
  ...getNumericalRangeFilterParamsConfig('conversionRate'),
  ...getNumericalRangeFilterParamsConfig('referredOrders'),
  ...getNumericalRangeFilterParamsConfig('referredSalesRevenue'),
  ...getNumericalRangeFilterParamsConfig('commissionsEarned'),
  ...getNumericalRangeFilterParamsConfig('linkViews'),
  ...getNumericalRangeFilterParamsConfig('avgEngagementRate'),
  ...getNumericalRangeFilterParamsConfig('impressions'),
  ...getNumericalRangeFilterParamsConfig('followerCount'),
  ...getDateFilterParamsConfig('maxRewardedAt', ''),
  ...getDateFilterParamsConfig('minPostedAt', ''),
  ...getDateFilterParamsConfig('maxPostedAt', ''),
  labels: StringParam,
  mediaType: createEnumSetParam(MediaTypeEnum),
  postType: createEnumSetParam(IgMediaPostType),
  mentionType: createEnumSetParam(MentionTypesMap),
  orderStatus: SetParam,
  commissionsPaymentStatus: SetParam,
  noteCategories: SetParam,
  ...getDateFilterParamsConfig('noteCreatedAt', ''),
  ...getNumericalRangeFilterParamsConfig('postCount'),
  ...getDateFilterParamsConfig('postedAt', ''),
  ...getDateFilterParamsConfig('dateRange', ''),
  rewards: SetParam,
  segments: StringParam,
  messageTemplate: StringParam,
  messageKeywords: StringParam,
  messageSendingMethod: StringParam,
  ...getDateFilterParamsConfig('signedUpAt', ''),
  tagStatus: createEnumSetParam(MentionStatus),
  challengeMediaApproval: createEnumSetParam(MentionStatus),
  status: StringParam,
  customFields: StringParam,
  ...getDateFilterParamsConfig('messageDate', 'exclude'),
  ...getDateFilterParamsConfig('messageDate', 'require'),
  paymentPeriods: StringParam,
  commissionTiers: StringParam,
  ...getNumericalRangeFilterParamsConfig('commissionRate'),
}

type MemberParams = Partial<DecodedValueMap<typeof memberParamsConfig>>

function paramsToFilters(params: MemberParams): Filters {
  const {
    challengeIds,
    mediaType,
    mentionType,
    filterId,
    rewards,
    segments,
    messageTemplate,
    messageKeywords,
    tagStatus,
    challengeMediaApproval,
    usernameKeywords,
    labels,
    emailKeywords,
    biographyKeywords,
    notesKeywords,
    orderStatus,
    commissionsPaymentStatus,
    noteCategories,
    status,
    customFields,
    postType,
    messageSendingMethod,
    paymentPeriods,
    commissionTiers,
  } = params
  const segmentsJson = segments ? JSON.parse(segments) : undefined
  const challengeIdsJson = challengeIds ? JSON.parse(challengeIds) : undefined
  const messageTemplateJson = messageTemplate ? JSON.parse(messageTemplate) : undefined
  const labelsJson = labels ? JSON.parse(labels) : undefined
  const messageKeywordsJson = messageKeywords ? JSON.parse(messageKeywords) : undefined
  const customFieldsJson = customFields ? JSON.parse(customFields) : undefined
  const messageSendingMethodJson = messageSendingMethod ? JSON.parse(messageSendingMethod) : undefined
  const paymentPeriodsJson = paymentPeriods ? JSON.parse(paymentPeriods) : undefined
  const commissionTiersJson = commissionTiers ? JSON.parse(commissionTiers) : undefined

  return {
    filterId,
    approvedAt: getDateParamsToFilters('approvedAt', '', params),
    minPostedAt: getDateParamsToFilters('minPostedAt', '', params),
    maxPostedAt: getDateParamsToFilters('maxPostedAt', '', params),
    avgEngagementRate: getNumericalRangeParamToFilter('avgEngagementRate', params),
    challengeIds:
      challengeIdsJson && challengeIdsJson.any
        ? {
            any: challengeIdsJson?.any || undefined,
          }
        : undefined,
    conversionRate: getNumericalRangeParamToFilter('conversionRate', params),
    referredOrders: getNumericalRangeParamToFilter('referredOrders', params),
    referredSalesRevenue: getNumericalRangeParamToFilter('referredSalesRevenue', params),
    commissionsEarned: getNumericalRangeParamToFilter('commissionsEarned', params),
    linkViews: getNumericalRangeParamToFilter('linkViews', params),
    followerCount: getNumericalRangeParamToFilter('followerCount', params),
    maxRewardedAt: getDateParamsToFilters('maxRewardedAt', '', params),
    mediaType,
    postType,
    mentionType,
    postedAt: getDateParamsToFilters('postedAt', '', params),
    dateRange: getDateParamsToFilters('dateRange', '', params),
    postCount: getNumericalRangeParamToFilter('postCount', params),
    rewards: rewards,
    impressions: getNumericalRangeParamToFilter('impressions', params),
    segments:
      segmentsJson && (segmentsJson.all || segmentsJson.none)
        ? {
            all: segmentsJson?.all ? (segmentsJson.all as string[][]) : undefined,
            none: segmentsJson?.none ? (segmentsJson?.none as string[]) : undefined,
          }
        : undefined,
    messageTemplate:
      messageTemplateJson && (messageTemplateJson.all || messageTemplateJson.none)
        ? {
            all: messageTemplateJson?.all ? (messageTemplateJson.all as string[][]) : undefined,
            none: messageTemplateJson?.none ? (messageTemplateJson?.none as string[]) : undefined,
          }
        : undefined,
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: cleanAllAnyNoneFilter(messageKeywordsJson),
    labels:
      labelsJson && (labelsJson.all || labelsJson.none)
        ? {
            all: labelsJson?.all ? (labelsJson.all as string[][]) : undefined,
            none: labelsJson?.none ? (labelsJson?.none as string[]) : undefined,
          }
        : undefined,
    signedUpAt: getDateParamsToFilters('signedUpAt', '', params),
    tagStatus,
    challengeMediaApproval,
    usernameKeywords,
    emailKeywords,
    biographyKeywords,
    notesKeywords,
    orderStatus,
    commissionsPaymentStatus,
    noteCategories,
    noteCreatedAt: getDateParamsToFilters('noteCreatedAt', '', params),
    status,
    customFields:
      customFieldsJson && (customFieldsJson.all || customFieldsJson.any)
        ? {
            all: customFieldsJson?.all
              ? (customFieldsJson.all.map(({ dateValue, ...restField }: CustomFieldFilter) => {
                  if (dateValue) {
                    const newDateValue = dateValue as AbsoluteDateRangeFilter | AbsoluteDateTimeRangeFilter
                    return {
                      ...restField,
                      dateValue: {
                        ...dateValue,
                        ...(newDateValue.gte ? { gte: new Date(newDateValue.gte) } : {}),
                        ...(newDateValue.lt ? { lt: new Date(newDateValue.lt) } : {}),
                      },
                    }
                  }

                  return { ...restField }
                }) as CustomFieldFilter[])
              : undefined,
            any: customFieldsJson?.any
              ? (customFieldsJson.any.map(({ dateValue, ...restField }: CustomFieldFilter) => {
                  if (dateValue) {
                    const newDateValue = dateValue as AbsoluteDateRangeFilter | AbsoluteDateTimeRangeFilter
                    return {
                      ...restField,
                      dateValue: {
                        ...dateValue,
                        ...(newDateValue.gte ? { gte: new Date(newDateValue.gte) } : {}),
                        ...(newDateValue.lt ? { lt: new Date(newDateValue.lt) } : {}),
                      },
                    }
                  }

                  return { ...restField }
                }) as CustomFieldFilter[])
              : undefined,
          }
        : undefined,
    messageDate: getDateInclusionExclusionParamsToFilters('messageDate', params),
    messageSendingMethod: cleanAllAnyNoneFilter(messageSendingMethodJson),
    paymentPeriods:
      paymentPeriodsJson && (paymentPeriodsJson.all || paymentPeriodsJson.any || paymentPeriodsJson.none)
        ? {
            all: paymentPeriodsJson?.all ? (paymentPeriodsJson.all as string[][]) : undefined,
            any: paymentPeriodsJson?.any ? (paymentPeriodsJson?.any as string[]) : undefined,
            none: paymentPeriodsJson?.none ? (paymentPeriodsJson?.none as string[]) : undefined,
          }
        : undefined,
    commissionTiers: cleanAllAnyNoneFilter(commissionTiersJson),
    commissionRate: getNumericalRangeParamToFilter('commissionRate', params),
  }
}

function filtersToParams(filters: Filters): MemberParams {
  const challengeIdsFilter = filters.challengeIds?.any ? { any: filters.challengeIds?.any } : undefined
  return {
    filterId: filters.filterId,
    ...getDateRangeFilterToParams('approvedAt', '', filters.approvedAt),
    ...getDateRangeFilterToParams('signedUpAt', '', filters.signedUpAt),
    ...getDateRangeFilterToParams('postedAt', '', filters.postedAt),
    ...getDateRangeFilterToParams('dateRange', '', filters.dateRange),
    ...getDateRangeFilterToParams('maxRewardedAt', '', filters.maxRewardedAt),
    ...getDateRangeFilterToParams('minPostedAt', '', filters.minPostedAt),
    ...getDateRangeFilterToParams('maxPostedAt', '', filters.maxPostedAt),
    ...getDateRangeFilterToParams('noteCreatedAt', '', filters.noteCreatedAt),
    ...getNumericalRangeFilterToParams('conversionRate', filters.conversionRate),
    ...getNumericalRangeFilterToParams('referredOrders', filters.referredOrders),
    ...getNumericalRangeFilterToParams('referredSalesRevenue', filters.referredSalesRevenue),
    ...getNumericalRangeFilterToParams('commissionsEarned', filters.commissionsEarned),
    ...getNumericalRangeFilterToParams('linkViews', filters.linkViews),
    ...getNumericalRangeFilterToParams('avgEngagementRate', filters.avgEngagementRate),
    challengeIds: JSON.stringify(challengeIdsFilter),
    ...getNumericalRangeFilterToParams('impressions', filters.impressions),
    ...getNumericalRangeFilterToParams('followerCount', filters.followerCount),
    mediaType: filters.mediaType,
    postType: filters.postType,
    mentionType: filters.mentionType,
    ...getNumericalRangeFilterToParams('postCount', filters.postCount),
    rewards: filters.rewards ? new Set(filters.rewards) : undefined,
    segments: JSON.stringify(filters.segments),
    messageTemplate: JSON.stringify(filters.messageTemplate),
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: JSON.stringify(
      cleanAllAnyNoneFilter(filters[MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]),
    ),
    labels: JSON.stringify(filters.labels),
    messageSendingMethod: JSON.stringify(cleanAllAnyNoneFilter(filters.messageSendingMethod)),
    tagStatus: filters.tagStatus,
    challengeMediaApproval: filters.challengeMediaApproval,
    usernameKeywords: filters.usernameKeywords,
    emailKeywords: filters.emailKeywords,
    biographyKeywords: filters.biographyKeywords,
    notesKeywords: filters.notesKeywords,
    orderStatus: filters.orderStatus ? new Set(filters.orderStatus) : undefined,
    commissionsPaymentStatus: filters.commissionsPaymentStatus ? new Set(filters.commissionsPaymentStatus) : undefined,
    noteCategories: filters.noteCategories ? new Set(filters.noteCategories) : undefined,
    status: filters.status,
    customFields: JSON.stringify(filters.customFields),
    ...getDateInclusionExclusionFiltersToParams('messageDate', filters.messageDate),
    paymentPeriods: JSON.stringify(filters.paymentPeriods),
    commissionTiers: JSON.stringify(cleanAllAnyNoneFilter(filters.commissionTiers)),
    ...getNumericalRangeFilterToParams('commissionRate', filters.commissionRate),
  }
}

/*
 * encodes member filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Partial<Filters>): string => {
  return stringify(encodeQueryParams(memberParamsConfig, filtersToParams(filters)))
}

const useMemberParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(memberParamsConfig)
  return {
    filters: paramsToFilters(filterParams),
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, f ? 'replaceIn' : 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useMemberParams
