import { useReducer, Dispatch } from 'react'
import {
  BULK_SEGMENT_ADD_LIMIT,
  EXPORT_LIMIT,
  SEND_REWARD_LIMIT,
  SET_COMMISSION_TIER_LIMIT,
  STATUS_CHANGE_LIMIT,
} from './constants'

/*
 * A state machine to track the bulk action flow for customers.
 * after the user has selected the customers they want to take a bulk action on, this code
 * is used to track the flow once they click a bulk action to take.
 *
 * For export, they click then the machine can be in these states :
 *
   +-------+   +----------+
   | start +-->+ confirm  |
   +-------+   +----------+
       |
       v
   +---------+
   |overLimit|
   +---------+

 *
 * for add/create segments:
 * start ->
 * +-------+     +------------+   +--------+
   | start +-----> pickSegment +-->+confirm |
   +---+---+     +------------+   +--------+
       |
       v
   +---+-----+
   |overLimit|
   +---------+

 * From any state, you can dispatch {type: 'done'} to cancel the flow
 */

type ParticipantData = {
  id: number
  customer?: {
    igUser?: {
      username?: string | null
    } | null
    ttUser?: {
      username?: string | null
    } | null
  } | null
}

type RewardData = {
  id: number
  name?: string | null
}

type AttemptedActionOverLimitState = {
  step: 'overLimit'
  action:
    | 'export'
    | 'segment'
    | 'gift'
    | 'rewardChallenge'
    | 'approve'
    | 'reject'
    | 'remove'
    | 'removeBulk'
    | 'createCommissionTier'
    | 'setCommissionTier'
}
type PickSegmentState = { step: 'pickSegment'; participant?: ParticipantData }
type PickCommissionTierState = { step: 'pickCommissionTier'; participant?: ParticipantData }
type AddSegmentConfirmationStep = {
  step: 'confirmation'
  action: 'addSegment'
  id: number
  participant?: ParticipantData
}
type CreateSegmentConfirmationStep = {
  step: 'confirmation'
  action: 'createSegment'
  name: string
  participant?: ParticipantData
}
type CreateProgramCommissionTierCreationStep = {
  step: 'creation'
  action: 'createCommissionTier'
  name: string
  participant?: ParticipantData
}
type ExportConfirmationStep = { step: 'confirmation'; action: 'export' }
type ApproveConfirmationStep = { step: 'confirmation'; action: 'approve' }
type RemoveConfirmationStep = { step: 'confirmation'; action: 'remove'; participant: ParticipantData }
type RejectConfirmationStep = {
  step: 'confirmation'
  action: 'reject'
  participant?: ParticipantData
}
type RemoveBulkConfirmationStep = {
  step: 'confirmation'
  action: 'removeBulk'
  participant?: ParticipantData
}
type PickRewardStep = { step: 'pickReward'; participant?: ParticipantData }
type SendRewardConfirmationStep = {
  step: 'confirmation'
  action: 'gift'
  reward: RewardData
  offline: boolean
  participant?: ParticipantData
}

type PickWinnerStep = { step: 'pickWinner'; participant?: ParticipantData }
type SendChallengeFulfillmentConfirmationStep = {
  step: 'confirmation'
  action: 'rewardChallenge'
  reward: RewardData
  offline: boolean
  challengeId: string
  participant?: ParticipantData
}

export type BulkActionState =
  | AttemptedActionOverLimitState
  | PickSegmentState
  | PickCommissionTierState
  | AddSegmentConfirmationStep
  | CreateSegmentConfirmationStep
  | CreateProgramCommissionTierCreationStep
  | ExportConfirmationStep
  | PickRewardStep
  | SendRewardConfirmationStep
  | PickWinnerStep
  | SendChallengeFulfillmentConfirmationStep
  | ApproveConfirmationStep
  | RemoveConfirmationStep
  | RejectConfirmationStep
  | RemoveBulkConfirmationStep
  | null

export type BulkActionStateAction =
  | {
      type: 'start'
      action: 'export'
      selectedCount: number
    }
  | {
      type: 'start'
      action: 'segment'
      selectedCount: number
      participant?: ParticipantData
    }
  | {
      type: 'start'
      action: 'gift'
      selectedCount: number
      participant?: ParticipantData
    }
  | {
      type: 'start'
      action: 'rewardChallenge'
      selectedCount: number
      participant?: ParticipantData
    }
  | {
      type: 'start'
      action: 'reject'
      selectedCount: number
      participant?: ParticipantData
    }
  | {
      type: 'start'
      action: 'approve'
      selectedCount: number
    }
  | {
      type: 'start'
      action: 'remove'
      participant: ParticipantData
    }
  | {
      type: 'start'
      action: 'removeBulk'
      selectedCount: number
      participant?: ParticipantData
    }
  | {
      type: 'start'
      action: 'createCommissionTier'
      selectedCount: number
      participant?: ParticipantData
    }
  | {
      type: 'start'
      action: 'setCommissionTier'
      selectedCount: number
      participant?: ParticipantData
    }
  | { type: 'selectedReward'; reward: RewardData; offline: boolean }
  | { type: 'selectedChallengeReward'; reward: RewardData; challengeId: string; offline: boolean }
  | { type: 'selectedSegment'; id: number }
  | { type: 'createSegment'; name: string }
  | { type: 'createCommissionTier'; name: string }
  | { type: 'done' }

function bulkReducer(state: BulkActionState, action: BulkActionStateAction): BulkActionState {
  switch (action.type) {
    case 'start':
      switch (action.action) {
        case 'createCommissionTier':
        case 'setCommissionTier':
          if (action.selectedCount > SET_COMMISSION_TIER_LIMIT) {
            return {
              step: 'overLimit',
              action: action.action,
            }
          }
          return {
            step: 'pickCommissionTier',
            participant: action.participant,
          }
        case 'export':
          if (action.selectedCount > EXPORT_LIMIT) {
            return {
              step: 'overLimit',
              action: 'export',
            }
          }
          return {
            step: 'confirmation',
            action: 'export',
          }
        case 'segment':
          if (action.selectedCount > BULK_SEGMENT_ADD_LIMIT) {
            return {
              step: 'overLimit',
              action: action.action,
            }
          }
          return {
            step: 'pickSegment',
            participant: action.participant,
          }
        case 'gift':
          if (action.selectedCount > SEND_REWARD_LIMIT) {
            return {
              step: 'overLimit',
              action: action.action,
            }
          }
          return {
            step: 'pickReward',
            participant: action.participant,
          }
        case 'rewardChallenge':
          if (action.selectedCount > SEND_REWARD_LIMIT) {
            return {
              step: 'overLimit',
              action: action.action,
            }
          }
          return {
            step: 'pickWinner',
            participant: action.participant,
          }
        case 'approve':
        case 'reject':
        case 'removeBulk':
          if (action.selectedCount > STATUS_CHANGE_LIMIT) {
            return {
              step: 'overLimit',
              action: action.action,
            }
          }
          return {
            step: 'confirmation',
            action: action.action,
            participant: action.action === 'reject' ? action.participant : undefined,
          }
        case 'remove':
          return {
            step: 'confirmation',
            action: action.action,
            participant: action.participant,
          }
        default:
          return null
      }
    case 'selectedSegment':
      return {
        step: 'confirmation',
        action: 'addSegment',
        id: action.id,
        participant: state?.step === 'pickSegment' ? state.participant : undefined,
      }
    case 'createSegment':
      return {
        step: 'confirmation',
        action: 'createSegment',
        name: action.name,
        participant: state?.step === 'pickSegment' ? state.participant : undefined,
      }
    case 'createCommissionTier':
      return {
        step: 'creation',
        action: 'createCommissionTier',
        name: action.name,
        participant: state?.step === 'pickCommissionTier' ? state.participant : undefined,
      }
    case 'selectedReward':
      return {
        step: 'confirmation',
        action: 'gift',
        reward: action.reward,
        offline: action.offline,
        participant: state?.step === 'pickReward' ? state.participant : undefined,
      }
    case 'selectedChallengeReward':
      return {
        step: 'confirmation',
        action: 'rewardChallenge',
        reward: action.reward,
        offline: action.offline,
        challengeId: action.challengeId,
        participant: state?.step === 'pickWinner' ? state.participant : undefined,
      }
    case 'done':
      return null
  }
}

function useBulkActionState(): [BulkActionState, Dispatch<BulkActionStateAction>] {
  return useReducer(bulkReducer, null)
}

export default useBulkActionState
