import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelectedPostStatusFilterMutationVariables = Types.Exact<{
  selectedPostStatusFilter?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type UpdateSelectedPostStatusFilterMutation = { __typename: 'Mutations', updateSelectedPostStatusFilter?: Array<string | null> | null };


export const UpdateSelectedPostStatusFilterDocument = gql`
    mutation UpdateSelectedPostStatusFilter($selectedPostStatusFilter: [String!]) {
  updateSelectedPostStatusFilter(
    selectedPostStatusFilter: $selectedPostStatusFilter
  ) @client
}
    `;
export type UpdateSelectedPostStatusFilterMutationFn = Apollo.MutationFunction<UpdateSelectedPostStatusFilterMutation, UpdateSelectedPostStatusFilterMutationVariables>;

/**
 * __useUpdateSelectedPostStatusFilterMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedPostStatusFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedPostStatusFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedPostStatusFilterMutation, { data, loading, error }] = useUpdateSelectedPostStatusFilterMutation({
 *   variables: {
 *      selectedPostStatusFilter: // value for 'selectedPostStatusFilter'
 *   },
 * });
 */
export function useUpdateSelectedPostStatusFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectedPostStatusFilterMutation, UpdateSelectedPostStatusFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectedPostStatusFilterMutation, UpdateSelectedPostStatusFilterMutationVariables>(UpdateSelectedPostStatusFilterDocument, options);
      }
export type UpdateSelectedPostStatusFilterMutationHookResult = ReturnType<typeof useUpdateSelectedPostStatusFilterMutation>;
export type UpdateSelectedPostStatusFilterMutationResult = Apollo.MutationResult<UpdateSelectedPostStatusFilterMutation>;
export type UpdateSelectedPostStatusFilterMutationOptions = Apollo.BaseMutationOptions<UpdateSelectedPostStatusFilterMutation, UpdateSelectedPostStatusFilterMutationVariables>;