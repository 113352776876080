import React from 'react'
import { SelectionOption } from '../lists/ListFilters/types'
import { SelectionList } from './SelectionList'

interface SelectionPickerMenuProps<T extends string> {
  option: SelectionOption<T>
  onSelectValue(newValue: Set<T>): void
  setPickerOpen(newValue: boolean): void
  value?: Set<T>
  resetValues?: Set<T>
}

function SelectionPickerMenuContent<T extends string>({
  option,
  onSelectValue,
  setPickerOpen,
  value,
  resetValues,
}: SelectionPickerMenuProps<T>): React.ReactElement {
  function handleReset(): Set<T> {
    if (resetValues === undefined) return new Set<T>()
    setPickerOpen(false)
    onSelectValue(resetValues)
    return resetValues
  }

  function handleApply(values: Set<T>): void {
    setPickerOpen(false)
    onSelectValue(values)
  }

  return (
    <div>
      <SelectionList
        options={option.selectionOptions}
        onChangeOptions={handleApply}
        selectedOptions={value}
        onReset={handleReset}
        selectionRequired={true}
      />
    </div>
  )
}

export default SelectionPickerMenuContent
