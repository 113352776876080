import React from 'react'
import { Box } from '@material-ui/core'

import MessagePhonePreview from '../../components/MessagePhonePreview/MessagePhonePreview'
import { useMessagePreviewUserDataQuery } from '../operations/query-message-preview-user-data.generated'
import { useMessagePreviewDataQuery } from '../operations/query-message-preview-data.generated'
import { MessageTemplateFormFields, REWARD_CODE_TAG, REWARD_PREVIEW } from './messageSchema'
import { useFormikContext } from 'formik'

interface MessageReviewProps {
  messageForm?: MessageTemplateFormFields
}

function MessagePreview({ messageForm }: MessageReviewProps): React.ReactElement {
  const {
    values: { template, rewardId },
  } = useFormikContext<MessageTemplateFormFields>()
  const { data: userData, loading: userDataLoading } = useMessagePreviewUserDataQuery()
  const socialAccountId = userData?.whoami?.preferences.selectedSocialAccountId
  const { data, loading } = useMessagePreviewDataQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
    },
  })
  const isLoading = loading || userDataLoading

  const messageTemplate = !template && messageForm ? messageForm.template : template
  const messageRewardId = !rewardId && messageForm ? messageForm.rewardId : rewardId

  const messageText =
    messageRewardId && !messageTemplate.includes(REWARD_CODE_TAG)
      ? `${messageTemplate}\n Code: ${REWARD_PREVIEW}`
      : messageTemplate?.replace(REWARD_CODE_TAG, REWARD_PREVIEW)

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <MessagePhonePreview
        loading={isLoading}
        mentionedSocialUser={data?.socialAccount?.socialUser}
        message={messageText}
        customerPreview
      />
    </Box>
  )
}

export default MessagePreview
