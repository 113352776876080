import React, { useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  createStyles,
  InputAdornment,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { SocialPlatformEnum } from '../../gql-global'

const USERNAME_REGEX = /^(?!.*\.$)^[.]?[^\W][\w.]{0,29}$/

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: 420,
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
    content: {
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
    },
  }),
)

export type AddMemberFormFields = {
  email: string
  igUsername?: string
  ttUsername?: string
}

interface AddMemberDialogProps {
  open: boolean
  onCancel(): void
  onSave(newMember: AddMemberFormFields): void
  platforms: Set<SocialPlatformEnum>
  isIGRequired: boolean
  isTTRequired: boolean
}

function AddMemberDialog({
  open,
  onCancel,
  onSave,
  platforms,
  isIGRequired,
  isTTRequired,
}: AddMemberDialogProps): React.ReactElement {
  const classes = useStyles()
  const hasIG = platforms.has(SocialPlatformEnum.Instagram)
  const hasTT = platforms.has(SocialPlatformEnum.Tiktok)
  const fieldSchema = useMemo(() => {
    const usernameField = yup.string().matches(USERNAME_REGEX, { message: 'Please enter a valid ${path}' }) // eslint-disable-line no-template-curly-in-string
    let igUsername = usernameField.label('Instagram Username')
    let ttUsername = usernameField.label('TikTok Username')
    if (isIGRequired) {
      igUsername = igUsername.required()
    }
    if (isTTRequired) {
      ttUsername = ttUsername.required()
    }
    if (!isIGRequired && !isTTRequired && hasIG && hasTT) {
      igUsername = igUsername.when('ttUsername', {
        is: tt => !tt,
        then: (schema: yup.StringSchema) => schema.required('Instagram or TikTok username required.'),
      })
      ttUsername = ttUsername.when('igUsername', {
        is: ig => !ig,
        then: (schema: yup.StringSchema) => schema.required('Instagram or TikTok username required.'),
      })
    }
    return yup.object().shape(
      {
        email: yup
          .string()
          .email('Please enter a valid ${path}') // eslint-disable-line no-template-curly-in-string
          .required(),
        ...(hasIG ? { igUsername } : {}),
        ...(hasTT ? { ttUsername } : {}),
      },
      [['ttUsername', 'igUsername']],
    )
  }, [hasIG, hasTT, isIGRequired, isTTRequired])

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.paper }}>
      <Formik<AddMemberFormFields>
        initialValues={{ email: '', igUsername: '', ttUsername: '' }}
        validationSchema={fieldSchema}
        onSubmit={onSave}
      >
        <Form noValidate>
          <DialogTitle>Add Member</DialogTitle>
          <DialogContent className={classes.content}>
            {hasIG && (
              <Field
                className={classes.textField}
                component={TextField}
                label="Instagram username"
                name="igUsername"
                fullWidth
                helperText=" " // hack to keep field size the same height w/ error message
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disableTypography>
                      @
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {hasTT && (
              <Field
                className={classes.textField}
                component={TextField}
                label="TikTok username"
                name="ttUsername"
                fullWidth
                helperText=" " // hack to keep field size the same height w/ error message
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disableTypography>
                      @
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Field
              className={classes.textField}
              component={TextField}
              label="Member's email"
              type="email"
              name="email"
              helperText=" "
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default AddMemberDialog
