import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendMonthlyEmailMutationVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  email: Types.Scalars['String'];
}>;


export type SendMonthlyEmailMutation = { __typename: 'Mutations', sendMonthlyEmail?: { __typename: 'SendMonthlyEmail', ok?: boolean | null } | null };


export const SendMonthlyEmailDocument = gql`
    mutation SendMonthlyEmail($accountId: ID!, $email: String!) {
  sendMonthlyEmail(accountId: $accountId, email: $email) {
    ok
  }
}
    `;
export type SendMonthlyEmailMutationFn = Apollo.MutationFunction<SendMonthlyEmailMutation, SendMonthlyEmailMutationVariables>;

/**
 * __useSendMonthlyEmailMutation__
 *
 * To run a mutation, you first call `useSendMonthlyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMonthlyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMonthlyEmailMutation, { data, loading, error }] = useSendMonthlyEmailMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendMonthlyEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendMonthlyEmailMutation, SendMonthlyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMonthlyEmailMutation, SendMonthlyEmailMutationVariables>(SendMonthlyEmailDocument, options);
      }
export type SendMonthlyEmailMutationHookResult = ReturnType<typeof useSendMonthlyEmailMutation>;
export type SendMonthlyEmailMutationResult = Apollo.MutationResult<SendMonthlyEmailMutation>;
export type SendMonthlyEmailMutationOptions = Apollo.BaseMutationOptions<SendMonthlyEmailMutation, SendMonthlyEmailMutationVariables>;