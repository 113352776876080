import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { AccountLabel } from '../LabelMenu/LabelMenu'
import ChallengeFilterMenu from './ChallengeFilterMenu'
import { ReactComponent as CaretIcon } from '../../icons/caret.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseTextColor: {
      color: theme.palette.primary.dark,
      fontSize: 20,
    },
    textGradient: {
      background: 'linear-gradient(170.48deg, #7800F9 28.39%, #F528C8 56.52%, #FFDD55 95%)',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      color: 'transparent',
      fontSize: 20,
    },
    caret: {
      marginLeft: '8px',
      marginTop: '14px',
      color: theme.palette.primary.main,
    },
    caretOpen: {
      transform: 'rotate(180deg)',
    },
  }),
)

interface ChallengeTitleProps {
  name: string
  selectedFilter?: string
  filterOptions?: ReadonlyArray<AccountLabel<string>>
  setFilter(selection: string): void
}

export default function ChallengeTitle({
  name,
  selectedFilter,
  filterOptions,
  setFilter,
}: ChallengeTitleProps): React.ReactElement {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const caretOpenClass = `${classes.caret} ${classes.caretOpen}`

  function handleTitleClicked() {
    if (!!selectedFilter) {
      setOpen(true)
    }
  }

  return (
    <Box display="flex" pb={6}>
      <Typography className={classes.baseTextColor}>Send reward for</Typography>
      <Typography>&nbsp;</Typography>
      <Box display="flex" onClick={handleTitleClicked}>
        <Typography
          className={classes.textGradient}
          ref={ref}
          style={{ cursor: selectedFilter ? 'pointer' : undefined }}
        >
          {name}
        </Typography>
        {!!filterOptions && !!selectedFilter && <CaretIcon className={open ? caretOpenClass : classes.caret} />}
      </Box>

      {!!filterOptions && !!selectedFilter && (
        <ChallengeFilterMenu
          open={open}
          setOpen={setOpen}
          challenges={filterOptions}
          setSelected={setFilter}
          selected={selectedFilter}
          currentRef={ref.current}
        />
      )}
    </Box>
  )
}
