import { Route } from '../types/route'

export const AUTH_ROUTE = new Route('/auth')
export const LOGIN_ROUTE = AUTH_ROUTE.subroute('/login')
export const LOGIN_CALLBACK_ROUTE = AUTH_ROUTE.subroute('/login_callback')
export const LOGOUT_ROUTE = AUTH_ROUTE.subroute('/logout')
export const AUTH0_LOGIN_ROUTE = AUTH_ROUTE.subroute('/auth-login')
export const AUTH0_SIGNUP_ROUTE = AUTH_ROUTE.subroute('/auth-signup')
export const AUTH0_LOGOUT_ROUTE = AUTH_ROUTE.subroute('/auth-logout')
export const AUTH0_CONFIRM_EMAIL_ROUTE = AUTH_ROUTE.subroute('/confirm-email')
export const AUTH0_FORGOT_PASSWORD_ROUTE = AUTH_ROUTE.subroute('/forgot-password')
export const AUTH0_FORGOT_PASSWORD_EMAIL_ROUTE = AUTH_ROUTE.subroute('/password-email')
export const SHOPIFY_AUTH_ROUTE = AUTH_ROUTE.subroute('/shopify')
export const GOOGLE_AUTH_ROUTE = AUTH_ROUTE.subroute('/google')
