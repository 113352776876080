import React from 'react'
import { Box } from '@material-ui/core'
import PostDetailsMedia from './PostDetailsMedia'
import PostDetailsDetails from './PostDetailsDetails'
import { AccountProductName, MentionStatus, IgMediaPostType, ChallengeStatusEnum } from '../../gql-global'
import { useUpdateMentionStatusMutation } from '../../mutations/operations/update-mention-status.generated'
import { useAddMentionLabelMutation } from '../ConnectedPostDetailModal/operations/add-mention-label.generated'
import { useCreateLabelWithMentionMutation } from '../ConnectedPostDetailModal/operations/create-label-with-mention.generated'
import { useDeleteLabelMutation } from '../../mutations/operations/delete-label.generated'
import labelsCacheUpdate from '../../mutations/labels-cache-update'
import { useRemoveMentionLabelMutation } from '../ConnectedPostDetailModal/operations/remove-mention-label.generated'
import { useUpdateLabelMutation } from '../ConnectedPostDetailModal/operations/update-label.generated'
import { useMentionQuery } from '../ConnectedPostDetailModal/operations/mention.generated'
import { UserAccountDetailsDocument, UserAccountDetailsQuery } from './operations/user-account-details.generated'
import {
  OtherAccountDetailsDocument,
  OtherAccountDetailsQuery,
  OtherAccountDetailsQueryVariables,
} from './operations/other-account-details.generated'
import { PostAccountDetailsFragment } from './operations/post-account-details.generated'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { ApolloError, isApolloError, OperationVariables, useQuery } from '@apollo/client'
import { PostDetailsFragment } from './operations/post-details.generated'
import PostDetailsTabs from './PostDetailsTabs'
import PostDetailsRewardTab from './PostDetailsRewardTab'
import { ReactComponent as InformationIcon } from '../../icons/information-bordered.svg'
import { ReactComponent as PresentIcon } from '../../icons/present.svg'
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg'
import { ReactComponent as GradientTrophyIcon } from '../../icons/gradient-trophy.svg'
import { buildMessageTabConfig } from './builders'
import { useUserPreferencesQuery } from '../../queries/operations/user-prefs.generated'
import { useSentMessagesActivityByMentionQuery } from './operations/query-sent-messages-by-mention.generated'
import { useToast } from '../Alert/ToastProvider'
import { useUpdateChallengeMediaApprovalMutation } from './operations/update-challenge-media-approval.generated'
import ChallengeRewardPicker from '../ChallengeRewardPicker'
import { RewardRowFragment } from '../RewardList/operations/rewards.generated'
import { sendRewardAndHandleResult } from '../../utils/rewards'
import { useSendRewardMutation } from '../../customer/operations/send-reward.generated'
import { usePostUserRewardsHistoryQuery } from './operations/post-user-rewards-history.generated'

interface PostDetailsProps {
  accountId?: string | null
  mention: string | PostDetailsFragment
  setHasError?: React.Dispatch<React.SetStateAction<boolean>>
  forReview?: boolean
  updateMentionStatusSuccessCallback?(): void
  setUpdateStatusError?(error: ApolloError): void
}

function getAccountData(
  data: UserAccountDetailsQuery | OtherAccountDetailsQuery,
): PostAccountDetailsFragment | undefined | null {
  return 'account' in data ? data.account : data.whoami && 'account' in data.whoami ? data.whoami?.account : undefined
}

function PostDetails({
  accountId,
  mention,
  setHasError,
  forReview,
  updateMentionStatusSuccessCallback,
  setUpdateStatusError,
}: PostDetailsProps): React.ReactElement {
  const [currentMediaIndex, setCurrentMediaIndex] = React.useState(0)

  // Check if we got already loaded mention or if we just got id and need to load it ourselves
  const isMentionId = (x: string | PostDetailsFragment): x is string => typeof x === 'string'
  const mentionId = isMentionId(mention) ? mention : mention.id

  const { showToast } = useToast()
  const { data: userPreferencesData } = useUserPreferencesQuery()
  const socialAccountId = userPreferencesData?.whoami?.preferences?.selectedSocialAccountId
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
  }

  const {
    loading: mentionQueryLoading,
    data: mentionData,
    error,
  } = useMentionQuery({
    skip: !isMentionId(mention) || !mentionId,
    variables: {
      mentionId: mentionId || '',
      activeStoryMentionsWhere,
    },
  })
  const selectedPost = isMentionId(mention) ? mentionData?.mention : mention
  const mentionLoading = isMentionId(mention) ? mentionQueryLoading : false

  const { data: userLabelData, error: userLabelError } = useQuery<
    UserAccountDetailsQuery | OtherAccountDetailsQuery,
    OtherAccountDetailsQueryVariables | OperationVariables
  >(
    accountId ? OtherAccountDetailsDocument : UserAccountDetailsDocument,
    accountId ? { variables: { accountId } } : undefined,
  )

  const variables = { limit: 100, socialAccountId: socialAccountId ?? '', mentionId }
  const messageActivityQueryResults = useSentMessagesActivityByMentionQuery({ skip: !socialAccountId, variables })

  const [updateMentionStatus, { error: updateStatusError }] = useUpdateMentionStatusMutation()
  const [updateChallengeMediaApproval] = useUpdateChallengeMediaApprovalMutation({
    update(cache, { data }) {
      const newChallenge = data?.updateChallengeMediaApproval?.challenge
      if (newChallenge) {
        cache.modify({
          id: cache.identify(newChallenge),
          fields: {
            mediaStats(existingNotes) {
              return newChallenge?.mediaStats
            },
          },
        })
      }
    },
  })
  const [addMentionLabel] = useAddMentionLabelMutation({ update: labelsCacheUpdate })
  const [removeMentionLabel] = useRemoveMentionLabelMutation()
  const [createLabel] = useCreateLabelWithMentionMutation({ update: labelsCacheUpdate })
  const [updateLabel] = useUpdateLabelMutation()
  const [deleteLabel] = useDeleteLabelMutation({ update: labelsCacheUpdate })
  const [sendReward] = useSendRewardMutation()

  const hasError = error || userLabelError
  setHasError && !!hasError && setHasError(!!hasError)
  setUpdateStatusError && updateStatusError && setUpdateStatusError(updateStatusError)

  const account = userLabelData ? getAccountData(userLabelData) : null
  const email = userLabelData?.whoami?.email
  const roles = userLabelData?.whoami?.roles?.map(r => r.name)
  const products = account?.organization.activeProducts
  const hasCustomersAccess = roles && products && !!email && CUSTOMER_ROUTE.hasAccess(roles, products, email)
  const canModifyLabels = !!roles?.some(r => r === 'ADMIN' || r === 'OWNER')
  const hasCampaigns = products?.some(p => p === AccountProductName.Campaign)

  const onChangeMentionStatus = (id: string, newStatus: MentionStatus): void => {
    updateMentionStatus({
      variables: {
        id: id,
        status: newStatus,
      },
    })
      .then(results => {
        if (results.errors?.length) return
        updateMentionStatusSuccessCallback && updateMentionStatusSuccessCallback()
      })
      .catch(() => {
        showToast({
          title: 'Error: Updating Status',
          message: 'Something went wrong when updating the status of this post, please try again',
          severity: 'error',
        })
      })
  }

  const onChangeChallengeMediaStatus = (mediaId: string, challengeId: string, approval: MentionStatus): void => {
    updateChallengeMediaApproval({
      variables: { challengeId, mediaId, approval },
      awaitRefetchQueries: true,
      refetchQueries: ['Mention'],
    }).catch(error => {
      showToast({ title: 'Error: Updating Challenge Media Approval', message: error.message })
    })
  }

  const onCreateLabel = (name: string): void => {
    createLabel({
      variables: {
        mentionId: mentionId || '',
        name,
      },
    }).catch(e => {
      let message = 'Something went wrong when creating this label, please try again'
      if (isApolloError(e) && e.graphQLErrors?.some(e => e.extensions?.code === 'LIMIT_REACHED')) {
        message = 'Account cannot create more labels. Please contact support to increase limit.'
      }
      showToast({ title: 'Error: Creating Label', message, severity: 'error' })
    })
  }
  const onUpdateLabel = (id: number, name: string): void => {
    updateLabel({
      variables: {
        id: id.toString(),
        name,
      },
    }).catch(e => {
      let message = 'Something went wrong when updating this label, please try again.'
      if (isApolloError(e) && e.graphQLErrors?.some(e => e.extensions?.code === 'DUPLICATE_KEY')) {
        message = 'Label with this name already exists, please use that label or pick a different name.'
      }
      showToast({ title: 'Error: Updating Label', message, severity: 'error' })
    })
  }
  const onDeleteLabel = (id: number): void => {
    deleteLabel({
      variables: {
        id: id.toString(),
      },
    }).catch(() => {
      showToast({
        title: 'Error: Deleting Label',
        message: 'Something went wrong when deleting this label, please try again',
        severity: 'error',
      })
    })
  }

  const onAddMentionLabel = (id: number): void => {
    addMentionLabel({
      variables: {
        mentionId: mentionId || '',
        labelId: id.toString(),
      },
    }).catch(() => {
      showToast({
        title: 'Error: Add Label To Post',
        message: 'Something went wrong adding this label to the post, please try again',
        severity: 'error',
      })
    })
  }

  const onRemoveLabel = (labelId: number): void => {
    if (selectedPost?.id) {
      removeMentionLabel({
        variables: {
          mentionId: selectedPost.id,
          labelId: labelId.toString(),
        },
      }).catch(() => {
        showToast({
          title: 'Error: Remove Label From Post',
          message: 'Something went wrong removing this label from the post, please try again',
          severity: 'error',
        })
      })
    }
  }

  const challengeIds =
    (selectedPost?.media?.challengeMedia
      ?.filter(cm => {
        const challenge = cm?.challenge
        return (
          !!challenge &&
          (!!challenge.isRecentlyCompleted ||
            [ChallengeStatusEnum.Live, ChallengeStatusEnum.Paused].includes(cm.challenge.currentStatus)) &&
          cm.approval === MentionStatus.Verified
        )
      })
      .map(cm => cm?.challenge?.id)
      .filter(lc_id => !!lc_id) as string[]) || []

  const customerId = selectedPost?.media.poster.customer?.id?.toString() ?? ''
  const mediaId = selectedPost?.media.id

  const { data: rewardsData } = usePostUserRewardsHistoryQuery({
    skip: !customerId || !socialAccountId,
    variables: {
      id: customerId,
      socialAccountId: socialAccountId ?? '',
      mediaId: mediaId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  async function handleSendChallengeReward(
    reward: RewardRowFragment,
    challengeId: string,
    offline: boolean,
  ): Promise<void> {
    if (!customerId) {
      showToast({
        title: 'Error: Sending Reward',
        message: "Can't send reward to the user from this content",
        severity: 'error',
      })
      return
    }

    await sendRewardAndHandleResult(
      reward,
      sendReward,
      {
        rewardId: reward.id.toString(),
        customerId: customerId,
        mediaId: mediaId,
        offlineDelivery: offline,
        socialAccountId: socialAccountId || '',
        challengeId: challengeId,
      },
      offline,
      showToast,
    )
  }

  const hasChallengeRewards = !!rewardsData?.socialAccount?.customer?.events?.results.find(e => {
    if (e.__typename === 'FulfilledTierCustomerEvent') {
      return !!e.fulfillment?.challenge
    }
    return false
  })
  const showPickWinner = !!challengeIds.length || hasChallengeRewards

  const hasHitLabelLimit = !!account?.organization.labelLimit.hasHitLimit

  const contentDetailsComponent = (
    <PostDetailsDetails
      mentionLoading={mentionLoading}
      mention={selectedPost}
      hasCampaigns={hasCampaigns}
      hasCustomersAccess={hasCustomersAccess}
      hasHitLabelLimit={hasHitLabelLimit}
      accountLabels={account?.labels?.results}
      canModifyLabels={canModifyLabels}
      onAddLabel={onAddMentionLabel}
      onRemoveLabel={onRemoveLabel}
      onCreateAssignLabel={onCreateLabel}
      onUpdateLabel={onUpdateLabel}
      onDeleteLabel={onDeleteLabel}
      onChangeStatus={onChangeMentionStatus}
      onChangeChallengeMediaStatus={onChangeChallengeMediaStatus}
      currentMediaIndex={currentMediaIndex}
      socialAccountId={socialAccountId}
      forReview={forReview}
    />
  )

  const tabs = [
    { icon: <InformationIcon width={32} height={32} />, content: contentDetailsComponent },
    buildMessageTabConfig({
      account,
      mentionId,
      socialAccount: selectedPost?.socialAccount,
      poster: selectedPost?.media.poster,
      postCount: selectedPost?.media.poster.customer?.activeStoryMentionsStats?.postCount,
      mediaId: selectedPost?.media.id,
      messageActivityQueryResults,
    }),
    {
      icon: <PresentIcon width={25} height={25} />,
      content: <PostDetailsRewardTab selectedPost={selectedPost} socialAccountId={socialAccountId} />,
    },
    ...(showPickWinner
      ? [
          {
            icon: <GradientTrophyIcon width={25} height={25} />,
            isSelectedIcon: <TrophyIcon width={25} height={25} />,
            content: (
              <ChallengeRewardPicker
                width={480}
                height={680}
                challenges={challengeIds}
                onSendReward={handleSendChallengeReward}
                selectedPost={selectedPost}
                socialAccountId={socialAccountId}
              />
            ),
            backgroundColor: 'linear-gradient(132.33deg, #FFBA3B 5.67%, #FF68DE 40.59%, #00ACFF 86.44%)',
          },
        ]
      : []),
  ]
  return (
    <Box display="flex">
      <PostDetailsMedia
        media={selectedPost?.media}
        mentionLoading={mentionLoading}
        onMediaChange={setCurrentMediaIndex}
      />
      <PostDetailsTabs tabs={forReview ? tabs.filter((_, index) => index === 0) : tabs} orientation="vertical" />
    </Box>
  )
}

export default PostDetails
