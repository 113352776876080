import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  Button,
  Link,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TableSortLabel,
} from '@material-ui/core'
import Page from '../../Page'
import useTitle from '../../utils/use-title'
import { AdminUserAccountsDocument, useAdminUserAccountsQuery } from './operations/admin-accounts.generated'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { ReactComponent as LtvIcon } from '../../icons/ltv.svg'
import { ReactComponent as ExternalIcon } from '../../icons/external.svg'
import { ADMIN_ACCOUNTS_ROUTE } from '../routes'
import AddAccountDialog, { AddAccountFormFields } from './AddAccountDialog'
import { NetworkStatus } from '@apollo/client'
import useFilterParams, { Filters } from './use-filter-params'
import { AccountFilterInput, AccountSort, SortDirection } from '../../gql-global'
import { useCreateOrganizationMutation } from './operations/create-organization.generated'
import useSortDirParam from './use-sort-dir-param'
import useSortParam from './use-sort-param'
import { AccountRowFragment } from './operations/account-row.generated'
import ActionMenu, { ActionType } from '../../components/ActionsMenu/ActionsMenu'
import LTVManagementDialog from './LTVManagementDialog'

const PAGE_SIZE = 25

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: 1,
      lineHeight: 2,
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
)

const useWhereFilters = (filters: Filters): AccountFilterInput => {
  return {
    search: !!filters.accountKeywords.length ? { keywords: filters.accountKeywords } : null,
  }
}

function getProductsArray(account: AccountRowFragment) {
  const products: string[] = []
  account?.organization?.plans?.forEach(p => {
    const stripeProductNames = p.stripeProducts?.map(sp => sp.name)
    products.push(...(stripeProductNames || []))
  })
  return products
}

const AccountList: React.FC = () => {
  useTitle('Admin - Accounts')
  const classes = useStyles()
  const [addOpen, setAddOpen] = useState(false)
  const [ltvSettingsOpen, setLtvSettingsOpen] = useState<boolean>(false)
  const [activeAccount, setActiveAccount] = useState<AccountRowFragment>()
  const { filters } = useFilterParams()
  const whereFilters = useWhereFilters(filters)
  const [sort = AccountSort.Name, setSort] = useSortParam()
  const [sortDir = SortDirection.Asc, setSortDir] = useSortDirParam()

  function setSortAndDir(sortField: AccountSort) {
    if (sortField === sort) {
      const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
      setSortDir(sortDirection)
      setSort(sortField)
    } else {
      setSort(sortField)
      setSortDir(SortDirection.Desc)
    }
  }

  function TableHeaderCell({
    fieldName,
    sort,
    sortDirection,
  }: {
    fieldName: 'name' | 'createdAt'
    sort: AccountSort
    sortDirection: SortDirection
  }) {
    const sortField = fieldName === 'name' ? AccountSort.Name : AccountSort.CreatedAt
    const label = fieldName === 'name' ? 'Name' : 'Created At'

    return (
      <TableCell>
        <TableSortLabel
          direction={sortDirection === SortDirection.Desc ? 'desc' : 'asc'}
          active={sort === sortField}
          onClick={() => setSortAndDir(sortField)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    )
  }

  const { loading, data: userAccountsData, error, fetchMore, networkStatus } = useAdminUserAccountsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      sortBy: sort,
      sortDirection: sortDir,
      limit: PAGE_SIZE,
      where: whereFilters,
    },
  })
  const loadingMore = networkStatus === NetworkStatus.fetchMore

  const { cursor } = userAccountsData?.accounts || {}
  function handleLoadMore(): void {
    if (cursor) {
      fetchMore({
        variables: {
          cursor,
          limit: PAGE_SIZE,
          where: whereFilters,
        },
      })
    }
  }

  const [createOrganization] = useCreateOrganizationMutation({
    refetchQueries: [
      { query: AdminUserAccountsDocument, variables: { limit: PAGE_SIZE, cursor, where: whereFilters } },
    ],
  })

  if (loading) return <p>Loading</p>
  if (error || (!loading && !loadingMore && !userAccountsData)) return <p>Error: {error && error.message}</p>

  async function handleAddAccount(fields: AddAccountFormFields) {
    await createOrganization({
      variables: {
        organizationInput: fields,
      },
    })
    setAddOpen(false)
  }

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h5" className={classes.title}>
              Accounts
            </Typography>
            {!!filters.accountKeywords.length && (
              <Box ml={4}>
                <Typography>(name contains "{filters.accountKeywords.join(', ')}")</Typography>
              </Box>
            )}
          </Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<PlusIcon width={16} height={16} />}
            onClick={() => setAddOpen(true)}
          >
            Add
          </Button>
        </Box>
        <Box mt={6}>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell fieldName="name" sort={sort} sortDirection={sortDir} />
                  <TableCell>Id</TableCell>
                  <TableHeaderCell fieldName="createdAt" sort={sort} sortDirection={sortDir} />
                  <TableCell>IsTrialing</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Used Seats</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userAccountsData?.accounts?.results?.map(
                  (account, i): JSX.Element => (
                    <TableRow key={i} hover>
                      <TableCell>
                        <Link to={`${ADMIN_ACCOUNTS_ROUTE.path}/${account.id}/users`} component={RouterLink}>
                          {account.name}
                        </Link>
                      </TableCell>
                      <TableCell>{account.id}</TableCell>
                      <TableCell>{account.createdAt?.toLocaleString()}</TableCell>
                      <TableCell>
                        {account.organization.isTrialing
                          ? `Yes, ${account.organization.trialDaysRemaining} days left`
                          : 'No'}
                      </TableCell>
                      <TableCell>{account && getProductsArray(account).join(', ')}</TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color={account.organization.userLimit.hasHitLimit ? 'error' : 'textPrimary'}
                        >
                          {account.organization.userLimit.used} of{' '}
                          {account.organization.userLimit.isUnlimited
                            ? 'Unlimited'
                            : account.organization.userLimit.limit}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <ActionMenu
                          disabled={false}
                          isMenuItemDisabled={(action: ActionType) => {
                            switch (action.action) {
                              case 'stripe':
                                return !account.organization.stripeCustomerId
                              default:
                                return false
                            }
                          }}
                          actions={[
                            {
                              action: 'stripe',
                              icon: <ExternalIcon width={16} />,
                              label: 'Go to Stripe',
                              disabledTooltip: 'No Stripe Customer',
                              actionCallback: () => {
                                window.open(
                                  `https://dashboard.stripe.com/customers/${account.organization.stripeCustomerId}`,
                                  '_blank',
                                  'noopener',
                                )
                              },
                            },
                            {
                              action: 'ltv_action',
                              icon: <LtvIcon width={16} />,
                              label: 'Manage Program LTV Settings',
                              actionCallback: () => {
                                setLtvSettingsOpen(true)
                                setActiveAccount(account)
                              },
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!error && cursor && (
          <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
            <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore}>
              Load more
            </Button>
          </Box>
        )}
      </Box>
      <AddAccountDialog
        open={addOpen}
        onCancel={() => setAddOpen(false)}
        onSubmit={handleAddAccount}
        enterpriseProducts={userAccountsData?.appStripeProducts?.filter(p => p.isEnterprise) || []}
      />
      <LTVManagementDialog open={ltvSettingsOpen} onClose={() => setLtvSettingsOpen(false)} account={activeAccount} />
    </Page>
  )
}

export default AccountList
