import React, { useState } from 'react'
import {
  Box,
  createStyles,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slider,
  Theme,
  Typography,
  withStyles,
  Divider,
  Button,
  ButtonProps,
} from '@material-ui/core'
import { TextField } from '../TextField/TextField'
import { currencyCodeToSymbol, NumberFormatTypes, numericalRangeFormat } from '../../utils/number-format'

export type NumericalRange = {
  gt?: number
  gte?: number
  lt?: number
  lte?: number
  eq?: number
}

export type NumericalRangeSliderType = Partial<NumericalRange> & Required<Pick<NumericalRange, 'gte' | 'lte'>>

export interface NumericalRangePickerProps {
  onChange(range: NumericalRange | undefined): void
  value?: NumericalRange | null
  // min and max valid values for range
  min?: number
  max?: number
  numberFormat?: NumberFormatTypes
  currencyCode?: string
  // The max and min for the slider control, not the same as min and max props
  sliderRange?: NumericalRangeSliderType
  presets?: readonly Readonly<NumericalRange>[]
  clearable?: boolean
  clearButtonProps?: ButtonProps
}

const useStyles = makeStyles({
  presetButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  applyButton: {
    marginLeft: 8,
  },
})

const LoudcrowdSlider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      height: 3,
      padding: '13px 0',
    },
    thumb: {
      height: 27,
      width: 27,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      marginTop: -12,
      marginLeft: -13,
      boxShadow: '#ebebeb 0px 2px 2px',
      '&:focus,&:hover,&$active': {
        boxShadow: '#ccc 0px 2px 3px 1px',
      },
      '& .bar': {
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 3,
    },
    rail: {
      color: '#d8d8d8',
      opacity: 1,
      height: 3,
    },
  }),
)(Slider)

function LoudcrowdThumbComponent(props: React.HTMLProps<HTMLSpanElement>): React.ReactElement {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  )
}

function getNumberRangeError(range: NumericalRange, min?: number, max?: number): string | null {
  if (range.gte === undefined && range.lte === undefined) {
    return 'No range selected'
  }
  if (range.gte !== undefined && range.lte !== undefined && range.gte > range.lte) {
    return 'Minimum value is larger than maximum value'
  }
  if (min !== undefined && range.gte && range.gte < min) {
    return `Minimum value must be greater than ${min}`
  }
  if (max !== undefined && range.gte && range.gte > max) {
    return `Minimum value must be less than ${max}`
  }
  if (min !== undefined && range.lte && range.lte < min) {
    return `Maximum value must be greater than ${min}`
  }
  if (max !== undefined && range.lte && range.lte > max) {
    return `Maximum value must be less than ${max}`
  }
  return null
}

function getNumberFromString(v: string, numberFormat: NumberFormatTypes): number | undefined {
  if (v === '') {
    return undefined
  }
  if (numberFormat === 'percent') {
    return parseFloat(v)
  }
  return parseInt(v)
}

function isRange(r: unknown): r is readonly [number, number] {
  return Array.isArray(r) && r.length === 2
}

function NumericalRangePicker({
  onChange,
  value,
  min,
  max,
  sliderRange = { gte: 0, lte: 1000000 },
  presets,
  numberFormat = 'number',
  currencyCode,
  clearable = false,
  clearButtonProps = {},
}: NumericalRangePickerProps): React.ReactElement {
  const classes = useStyles()
  // set current slider values to either the value, or if the value have gte/lte outside of the slider range,
  // then set the slider values to the gte/lte of the sliderRange (basically pulling the value gte/lte into the range
  const [currentSliderValue, setCurrentSliderValue] = useState<NumericalRangeSliderType>(
    value
      ? {
          gte: value.gte ? Math.min(Math.max(value.gte, sliderRange.gte), sliderRange.lte) : sliderRange.gte,
          lte: value.lte ? Math.min(Math.max(value.lte, sliderRange.gte), sliderRange.lte) : sliderRange.lte,
        }
      : sliderRange,
  )
  const [textRange, setTextRange] = useState<NumericalRange>(value || {})
  const [isDirty, setIsDirty] = useState(!!value)

  function handleSliderChange(_: unknown, value: number | number[]): void {
    if (isRange(value)) {
      const range = { gte: value[0], lte: value[1] }
      setCurrentSliderValue(range)
      setTextRange(range)
      setIsDirty(true)
    }
  }

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const fieldName = event.target.name
    const newValue = getNumberFromString(event.target.value, numberFormat)
    if (fieldName === 'gte' || fieldName === 'lte') {
      setTextRange(prev => ({
        ...prev,
        [fieldName]: newValue,
      }))
      if (newValue && newValue >= sliderRange.gte && newValue <= sliderRange.lte) {
        setCurrentSliderValue(prev => ({
          ...prev,
          [fieldName]: newValue,
        }))
      }
      setIsDirty(true)
    }
  }

  function handleApply(): void {
    onChange(textRange)
  }

  const errorStatus = getNumberRangeError(textRange, min, max)
  const isValid = !errorStatus
  const errorMessage = isDirty && (errorStatus || '')
  let minMaxInputAdornment =
    numberFormat === 'percent'
      ? {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }
      : numberFormat === 'currency'
      ? {
          startAdornment: (
            <InputAdornment position="start">{currencyCodeToSymbol(currencyCode ?? 'USD')}</InputAdornment>
          ),
        }
      : undefined

  return (
    <>
      <Box px={6} pt={6} width={320}>
        {!presets && (
          <LoudcrowdSlider
            value={[currentSliderValue.gte, currentSliderValue.lte]}
            ThumbComponent={LoudcrowdThumbComponent}
            getAriaLabel={index => (index === 0 ? 'Minimum follower count' : 'Maximum follower count')}
            onChange={handleSliderChange}
            min={sliderRange.gte}
            max={sliderRange.lte}
            step={Math.floor((sliderRange.lte - sliderRange.gte) / 100)}
          />
        )}
        <Box display="flex">
          <Box>
            <Typography variant="body2">Minimum</Typography>
            <TextField
              name="gte"
              type="number"
              value={textRange.gte !== undefined ? textRange.gte : ''}
              onChange={handleTextChange}
              error={!!errorMessage}
              helperText={errorMessage}
              InputProps={minMaxInputAdornment}
            />
          </Box>
          <Box ml={4.5}>
            <Typography variant="body2">Maximum</Typography>
            <TextField
              name="lte"
              type="number"
              value={textRange.lte !== undefined ? textRange.lte : ''}
              onChange={handleTextChange}
              error={!!errorMessage}
              InputProps={minMaxInputAdornment}
            />
          </Box>
        </Box>
        {presets && (
          <List>
            {presets.map(p => (
              <ListItem
                key={`${p.lte}${p.gte}`}
                button
                onClick={() => onChange(p)}
                classes={{ button: classes.presetButton }}
              >
                <ListItemText
                  primary={numericalRangeFormat(p, { format: numberFormat })}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      <Box mt={2}>
        <Divider />
        <Box px={5} pt={2} display="flex" justifyContent="flex-end">
          {clearable && (
            <Button variant="contained" {...clearButtonProps} onClick={() => onChange(undefined)} disabled={!isValid}>
              Clear Filter
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={handleApply}
            disabled={!isValid}
            className={classes.applyButton}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default NumericalRangePicker
