import React, { useCallback, useRef, useState, useEffect } from 'react'
import { IconButton, TextField, InputAdornment, Popover } from '@material-ui/core'
import { Calendar } from '@material-ui/pickers'
import { FieldProps } from 'formik'
import { isValid, parse, format } from 'date-fns'
import { ReactComponent as AgendaIcon } from '../../icons/calendar.svg'
import { secondary } from '../../loudcrowd-theme'

const defaultDate = new Date(1900, 1, 1)
const currentDate = new Date()

type DatePickerFieldProps = FieldProps & {
  label: string
  name: string
  disabled?: boolean
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({ field, form, name, label, disabled, ...props }) => {
  const currentError = form.errors[field.name]
  const containerRef = useRef(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [potentialDate, setPotentialDate] = useState<string>('')
  const [currentFormat, setCurrentFormat] = useState<string>('MMM d, yyyy')
  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value
    let formatFound = false
    setPotentialDate(value)
    ;['MM/dd/yyyy', 'yyyy-MM-dd', 'MMM d, yyyy', 'MMMM d, yyyy', 'MMM do, yyyy', 'MMMM do, yyyy'].forEach(
      (iFormat: string) => {
        if (formatFound) return
        const parsedDate = parse(value, iFormat, currentDate)
        if (parsedDate.getTime() > defaultDate.getTime() && isValid(parsedDate)) {
          form.setFieldValue(field.name, parsedDate, false)
          setCurrentFormat(iFormat)
          formatFound = true
        }
      },
    )
  }

  const handleSetPotentialDate = useCallback((date: Date) => setPotentialDate(format(date, currentFormat)), [
    currentFormat,
  ])

  useEffect(() => {
    if (field.value) {
      handleSetPotentialDate(field.value)
    }
  }, [field.value, handleSetPotentialDate])

  return (
    <>
      <div ref={containerRef}>
        <TextField
          id={name}
          label={label}
          variant="standard"
          value={potentialDate}
          fullWidth
          onChange={handleOnChange}
          helperText={currentError}
          error={Boolean(currentError)}
          disabled={form.isSubmitting || disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => !disabled && setIsOpen(!isOpen)}>
                  <AgendaIcon color={secondary[500]} width={20} height={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Popover
        open={isOpen}
        aria-labelledby={name}
        anchorEl={containerRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setIsOpen(false)}
      >
        <Calendar
          date={field.value ? field.value : currentDate}
          onChange={date => {
            setIsOpen(false)
            if (!date) return
            form.setFieldValue(field.name, date, false)
            handleSetPotentialDate(date)
          }}
        />
      </Popover>
    </>
  )
}

export default DatePickerField
