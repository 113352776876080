import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignSocialStatsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  socialAccountFilter: Types.IdFilterInput;
  timezone: Types.Scalars['String'];
  dateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
  previousDateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
}>;


export type CampaignSocialStatsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, mentionStats?: { __typename: 'CampaignMentionStats', impressions: number } | null, previousMentionStats?: { __typename: 'CampaignMentionStats', impressions: number } | null, mentionStatsTimeseries?: Array<{ __typename: 'SocialDataPoint', startDate: Date, endDate: Date, impressions: number }> | null } | null };


export const CampaignSocialStatsDocument = gql`
    query CampaignSocialStats($campaignId: ID!, $socialAccountFilter: IdFilterInput!, $timezone: String!, $dateRange: DateTimeFilterInput, $previousDateRange: DateTimeFilterInput) {
  campaign(id: $campaignId) {
    id
    mentionStats(
      where: {postedAt: $dateRange, socialAccountId: $socialAccountFilter}
    ) {
      impressions
    }
    previousMentionStats: mentionStats(
      where: {postedAt: $previousDateRange, socialAccountId: $socialAccountFilter}
    ) {
      impressions
    }
    mentionStatsTimeseries(
      where: {postedAt: $dateRange, socialAccountId: $socialAccountFilter}
      timezone: $timezone
    ) {
      startDate
      endDate
      impressions
    }
  }
}
    `;

/**
 * __useCampaignSocialStatsQuery__
 *
 * To run a query within a React component, call `useCampaignSocialStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignSocialStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignSocialStatsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      socialAccountFilter: // value for 'socialAccountFilter'
 *      timezone: // value for 'timezone'
 *      dateRange: // value for 'dateRange'
 *      previousDateRange: // value for 'previousDateRange'
 *   },
 * });
 */
export function useCampaignSocialStatsQuery(baseOptions: Apollo.QueryHookOptions<CampaignSocialStatsQuery, CampaignSocialStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignSocialStatsQuery, CampaignSocialStatsQueryVariables>(CampaignSocialStatsDocument, options);
      }
export function useCampaignSocialStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignSocialStatsQuery, CampaignSocialStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignSocialStatsQuery, CampaignSocialStatsQueryVariables>(CampaignSocialStatsDocument, options);
        }
export type CampaignSocialStatsQueryHookResult = ReturnType<typeof useCampaignSocialStatsQuery>;
export type CampaignSocialStatsLazyQueryHookResult = ReturnType<typeof useCampaignSocialStatsLazyQuery>;
export type CampaignSocialStatsQueryResult = Apollo.QueryResult<CampaignSocialStatsQuery, CampaignSocialStatsQueryVariables>;