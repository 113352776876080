import React, { useState, useRef, forwardRef } from 'react'
import { makeStyles, Divider, Button, Box, Menu, IconButton, MenuProps, Typography } from '@material-ui/core'
import MenuHeader from '../../MenuHeader'
import JoinedChildren from '../../JoinedChildren'
import { KeywordsOption } from './types'
import FilterChip, { FilterChipText } from './FilterChip'
import { TextField } from '../../TextField/TextField'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'
import { ReactComponent as TrashIcon } from '../../../icons/trash_can.svg'
import { CriteriaOperatorEnum, KeywordsFilterInput } from '../../../gql-global'
import ChipsInput from '../../ChipsInput/ChipsInput'
import SettingsToggle from '../../SettingsToggle'
import { secondary } from '../../../loudcrowd-theme'

export type Ref = HTMLDivElement

const useStyles = makeStyles({
  keywordTextField: {
    width: 315,
  },
  criteriaOperatorContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginBottom: '12px',
  },
  criteriaOperatorLabel: {
    marginTop: '2px',
    textAlign: 'center',
    fontSize: 12,
    color: secondary[600],
  },
  criteriaOperatorHelpText: {
    marginTop: '-8px',
    marginLeft: '20px',
    marginBottom: '8px',
    fontSize: 12,
    color: secondary[600],
  },
  criteriaOperatorSwitch: {
    marginLeft: '10px',
  },
})

interface KeywordBuilderProps {
  keywords?: string[] | null
  option?: KeywordsOption
  onApply(newKeywords: string[]): void
  showClear?: boolean
  className?: string
  showCriteriaOperatorToggle?: boolean
  setCriteriaOperator?: (val: CriteriaOperatorEnum) => void
  criteriaOperatorValue?: CriteriaOperatorEnum
  criteriaOperatorLabel?: string
  renderCriteriaOperatorHelpText?: (val: CriteriaOperatorEnum) => string
}

export function KeywordBuilder({
  keywords,
  onApply,
  showClear = false,
  option,
  showCriteriaOperatorToggle = false,
  setCriteriaOperator,
  criteriaOperatorValue,
  criteriaOperatorLabel,
  renderCriteriaOperatorHelpText,
}: KeywordBuilderProps): React.ReactElement {
  const classes = useStyles()
  const [newOperator, setNewOperator] = useState<CriteriaOperatorEnum>(
    criteriaOperatorValue || CriteriaOperatorEnum.All,
  )
  const [newKeywords, setNewKeywords] = useState<string[]>(
    keywords && keywords.length > 0 ? keywords : option?.useChips ? [] : [''],
  )

  const [inputText, setInputText] = useState<string>('')

  function handleApply(): void {
    if (!newKeywords.length && inputText) {
      newKeywords.push(inputText)
    }

    const result = newKeywords.map(k => k.trim()).filter(k => k !== '')
    if (result) {
      onApply(result)
      setCriteriaOperator && setCriteriaOperator(newOperator)
    }
  }

  function handleTextChange(i: number, value: string): void {
    const temp = [...newKeywords]
    temp[i] = value
    setNewKeywords(temp)
  }

  function handleTrashClick(i: number): void {
    const temp = [...newKeywords]
    temp.splice(i, 1)
    setNewKeywords(temp)
  }

  function handleClear(): void {
    setNewKeywords(option?.useChips ? [] : [''])
  }

  function isDisabled(): boolean {
    const criteriaOperatorChanged = criteriaOperatorValue !== newOperator
    return (
      (keywords === newKeywords || (newKeywords.length === 0 && !keywords?.length) || !newKeywords.every(Boolean)) &&
      !inputText &&
      !criteriaOperatorChanged
    )
  }

  return (
    <Box width={392} pt={4}>
      {showCriteriaOperatorToggle && setCriteriaOperator && (
        <Box className={classes.criteriaOperatorContainer} px={5}>
          <Typography className={classes.criteriaOperatorLabel}>{criteriaOperatorLabel}:</Typography>
          <Box className={classes.criteriaOperatorSwitch}>
            <SettingsToggle
              options={Object.values(CriteriaOperatorEnum)}
              selected={newOperator}
              onSelect={(val: CriteriaOperatorEnum) => setNewOperator(val)}
              width={104}
              height={20}
            />
          </Box>
        </Box>
      )}
      {option?.useChips ? (
        <Box px={5} mb={4}>
          <ChipsInput
            chips={newKeywords}
            onChange={setNewKeywords}
            allowSpaces={!!option?.allowSpaces}
            allowQuotes={!!option?.allowQuotes}
            prependChar={option?.prependChar}
            inputText={inputText}
            setInputText={setInputText}
          />
        </Box>
      ) : (
        <>
          {newKeywords?.map((k: string, i: number) => (
            <Box px={5} key={`keyword-text-field-${i}`}>
              <TextField
                value={k}
                name={i.toString()}
                onChange={({ target: { value } }) => handleTextChange(i, value)}
                className={classes.keywordTextField}
                autoFocus={i === newKeywords.length - 1}
              />
              {newKeywords.length > 1 && (
                <IconButton onClick={() => handleTrashClick(i)}>
                  <TrashIcon width={13} />
                </IconButton>
              )}
            </Box>
          ))}
          <Box px={5}>
            <Button
              color="primary"
              startIcon={<PlusIcon width={16} height={16} />}
              onClick={() => setNewKeywords([...newKeywords, ''])}
            >
              Add
            </Button>
          </Box>
        </>
      )}

      <Box mt={2}>
        {renderCriteriaOperatorHelpText && newOperator && (
          <Typography className={classes.criteriaOperatorHelpText}>
            {renderCriteriaOperatorHelpText(newOperator)}
          </Typography>
        )}
        <Divider />
        <Box px={5} pt={3} display="flex" justifyContent={showClear ? 'space-between' : 'flex-end'}>
          {showClear && (
            <Button color="secondary" onClick={handleClear}>
              Clear All
            </Button>
          )}
          <Button color="primary" variant="contained" onClick={handleApply} disabled={isDisabled()}>
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

function handleApplyKeywords(
  onSelectValue: (newValue: string[] | KeywordsFilterInput) => void,
  newKeywords: string[],
  isExclusion: boolean,
  includeField: 'all' | 'any' | undefined,
  value: string[] | KeywordsFilterInput,
): void {
  if (includeField !== undefined) {
    const newValue: KeywordsFilterInput = { ...((value ?? {}) as KeywordsFilterInput) }
    if (isExclusion) {
      newValue.none = newKeywords
    } else if (includeField === 'all') {
      // We need to wrap the array into another array because the all field supports multiple keywords
      // arrays to be OR'd together
      newValue.all = [newKeywords]
    } else if (includeField === 'any') {
      newValue.any = newKeywords
    }
    onSelectValue(newValue)
  } else {
    onSelectValue(newKeywords)
  }
}

function getKeywordOptionValueArray(
  value: string[] | KeywordsFilterInput,
  includeField: 'all' | 'any' | undefined,
  isExclusion: boolean,
): string[] {
  if (includeField !== undefined) {
    if (isExclusion) {
      return (value as KeywordsFilterInput)?.none ?? []
    } else if (includeField === 'all') {
      // Again, all field is always an array of arrays, but we are only adding a single inner array
      // on these Keyword filters, so we get that one
      return (value as KeywordsFilterInput)?.all?.[0] ?? []
    } else if (includeField === 'any') {
      return (value as KeywordsFilterInput)?.any ?? []
    }
  }
  return value as string[]
}

interface KeywordsFilterChipProps {
  option: KeywordsOption
  value: string[] | KeywordsFilterInput
  onSelectValue(newValue: string[] | KeywordsFilterInput): void
  onDelete(): void
  isExclusion: boolean
  operator?: string
}

export function KeywordsFilterChip({
  option,
  value,
  onDelete,
  onSelectValue,
  isExclusion,
  operator = 'and',
}: KeywordsFilterChipProps): React.ReactElement {
  const [menuOpen, setMenuOpen] = useState(false)
  const chipRef = useRef<HTMLDivElement>(null)

  function handleApply(newKeywords: string[]): void {
    handleApplyKeywords(onSelectValue, newKeywords, isExclusion, option.includeField, value)
    setMenuOpen(false)
  }

  function handleDelete() {
    if (option.includeField === undefined) {
      onDelete()
    } else {
      const newValue: KeywordsFilterInput = { ...((value ?? {}) as KeywordsFilterInput) }
      if (isExclusion) {
        newValue.none = undefined
      } else {
        newValue[option.includeField] = undefined
      }
      if (newValue.all || newValue.any || newValue.none) {
        onSelectValue(newValue)
      } else {
        onDelete()
      }
    }
  }

  const valueArray = getKeywordOptionValueArray(value, option.includeField, isExclusion)
  return (
    <>
      <FilterChip ref={chipRef} onClick={() => setMenuOpen(true)} onDelete={handleDelete}>
        <FilterChipText bold text={option.label} />
        <FilterChipText text="includes" />
        <JoinedChildren joinElement={<FilterChipText text={operator} />}>
          {valueArray.map(v => (
            <FilterChipText bold text={v} key={v} />
          ))}
        </JoinedChildren>
      </FilterChip>
      <Menu
        open={menuOpen}
        anchorEl={chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMenuOpen(false)}
      >
        <MenuHeader title={option.label} />
        <KeywordBuilder keywords={valueArray} option={option} onApply={keywords => handleApply(keywords)} />
      </Menu>
    </>
  )
}

interface KeywordFilterMenuProps {
  option: KeywordsOption
  value: string[]
  onSelectValue(newValue: string[] | KeywordsFilterInput): void
  onBack(): void
  anchorEl: MenuProps['anchorEl']
  isExclusion: boolean
}

const KeywordFilterMenu = forwardRef<Ref, KeywordFilterMenuProps>(props => {
  const [menuOpen, setMenuOpen] = useState(true)
  const { option, value, onSelectValue, onBack, anchorEl, isExclusion } = props
  const valueArray = getKeywordOptionValueArray(value, option.includeField, isExclusion)
  function handleApply(newKeywords: string[]): void {
    handleApplyKeywords(onSelectValue, newKeywords, isExclusion, option.includeField, value)
  }
  return (
    <Menu open={menuOpen} onClose={() => setMenuOpen(false)} anchorEl={anchorEl}>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <KeywordBuilder keywords={valueArray} onApply={handleApply} option={option} />
    </Menu>
  )
})

KeywordFilterMenu.displayName = 'KeywordFilterMenu'

export default KeywordFilterMenu
