import React, { useRef, useState } from 'react'
import { Paper, Button, Theme, Grid, Box, makeStyles, createStyles } from '@material-ui/core'
import { NumberParam, useQueryParam } from 'use-query-params'
import { NetworkStatus } from '@apollo/client'
import Metric from '../components/metric/Metric'
import ActivityComponent, { LoadingActivityRow } from './ActivityComponent'
import ListHeader from '../components/lists/ListHeader'
import ListCount from '../components/lists/ListCount'
import ListActions from '../components/lists/ListActions'
import MessagePhonePreviewModal from '../components/MessagePhonePreview/MessagePhonePreviewModal'
import { ReactComponent as NoPostsImage } from '../images/no-posts.svg'
import { ReactComponent as Export } from '../icons/export.svg'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../components/ContainerError'
import { useMessagesUserDataQuery } from './operations/query-messages-user-data.generated'
import { SentMessageFragment, useSentMessagesActivityQuery } from './operations/query-sent-messages-activity.generated'
import { Options, ParentOption } from '../components/lists/ListFilters/types'
import ListFilters from '../components/lists/ListFilters'
import useFilterParams, { Filters } from './use-filter-params'
import {
  CustomerFilterInput,
  MessageFilter,
  AccountProductName,
  RewardTypeEnum,
  AbsoluteDateTimeRangeFilter,
  IgSocialAccount,
} from '../gql-global'
import { isTypeName } from '../types/utility'
import { useExportsActivityListLazyQuery } from './operations/exports-activity-list.generated'
import { useMessageTemplatesDataQuery } from './operations/query-message-templates-data.generated'
import { EXPORT_LIMIT } from './constants'
import { messageSendingMethodOptions } from '../content/constants'
import { cleanAllAnyNoneFilter, useDateInclusionExclusionWhereFilter } from '../utils/filter-params'
import { useToast } from '../components/Alert/ToastProvider'
import { SEGMENT_MANAGEMENT_ROUTE } from '../settings/routes'

const PAGE_SIZE = 10

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      columnGap: theme.spacing(5),
      marginTop: theme.spacing(6),
    },
  }),
)

const useCustomersWhereFilters = (filters: Filters): CustomerFilterInput => {
  return {
    username: filters.usernameKeywords?.length ? { keywords: filters.usernameKeywords } : null,
    segments:
      filters.segments?.all || filters.segments?.none
        ? {
            all: filters.segments.all || undefined,
            none: filters.segments.none || undefined,
          }
        : undefined,
    biography: filters.biographyKeywords?.length ? { all: filters.biographyKeywords.map(k => [k]) } : undefined,
    followerCount: filters.followerCount,
    campaigns:
      filters.campaigns?.any || filters.campaigns?.none
        ? {
            any: filters.campaigns?.any || undefined,
            none: filters.campaigns?.none || undefined,
          }
        : undefined,
  }
}

const useMessageFilters = (filters: Filters): MessageFilter => {
  const sentAt = useDateInclusionExclusionWhereFilter(filters?.sentAt)
  return {
    messageTemplate:
      filters.messageTemplate && (filters.messageTemplate.any || filters.messageTemplate.none)
        ? {
            any: filters.messageTemplate.any || undefined,
            none: filters.messageTemplate.none || undefined,
          }
        : undefined,
    sentAt,
    reward:
      filters.reward && (filters.reward.any || filters.reward.none)
        ? {
            any: filters.reward.any || undefined,
            none: filters.reward.none || undefined,
          }
        : undefined,
    messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
  }
}

function LoadingActivityComponent(): JSX.Element {
  return (
    <>
      {new Array(PAGE_SIZE).fill(null).map((_, i) => (
        <LoadingActivityRow key={i} />
      ))}
    </>
  )
}

const TEMPLATE_LIMIT = 200

function ActivityList(): React.ReactElement {
  const classes = useStyles()
  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)
  const { filters, setFilters, isDirty } = useFilterParams()
  const {
    data: userData,
    error: userDataError,
    loading: userDataLoading,
  } = useMessagesUserDataQuery({
    variables: {
      where: {
        rewardType: {
          any: [RewardTypeEnum.Dm],
        },
      },
    },
  })
  const { showToast } = useToast()
  const [previewMessage, setPreviewMessage] = useState<SentMessageFragment | null>(null)
  const socialAccountId = userData?.whoami?.preferences.selectedSocialAccountId
  const accountRewards =
    userData?.whoami?.account?.rewards?.results.map(r => ({
      id: r.id.toString(),
      name: r.name || `Reward ${r.id.toString()}`,
    })) || []
  const customersWhere = useCustomersWhereFilters(filters)
  const { sentAt: startDateRange, messageTemplate, reward, messageSendingMethod } = useMessageFilters(filters)
  const { data: messageTemplatesData } = useMessageTemplatesDataQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
      limit: TEMPLATE_LIMIT,
    },
  })

  const messageTemplates =
    messageTemplatesData?.socialAccount && isTypeName(messageTemplatesData?.socialAccount, 'IGSocialAccount')
      ? messageTemplatesData?.socialAccount?.messageTemplates?.results ?? []
      : []

  const endDateRange = useDateInclusionExclusionWhereFilter(filters?.sentAt)
  const {
    data: sentMessagesActivityData,
    error: sentMessagesActivityError,
    loading: sentMessagesActivityLoading,
    fetchMore,
    networkStatus,
  } = useSentMessagesActivityQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: PAGE_SIZE,
      socialAccountId: socialAccountId || '',
      messageTemplate: messageTemplate || {},
      rewards: reward || {},
      customersWhere,
      messageKeywords: filters.messageKeywords,
      startDateRange,
      endDateRange,
      messageSendingMethod,
    },
  })
  const exportUrlRef = useRef<string | null>(null)
  const reffedStartDateTimeRange = useDateInclusionExclusionWhereFilter(filters?.sentAt)
  const [exportActivityList] = useExportsActivityListLazyQuery({
    variables: {
      socialAccountId: socialAccountId || '',
      messageTemplate: messageTemplate || {},
      rewards: reward || {},
      customersWhere,
      startDateRange: reffedStartDateTimeRange || null,
      limit: EXPORT_LIMIT,
    },
    onError: () => {
      showToast({ title: 'Error', message: `Error exporting activity list. Please try again Later` })
    },
    onCompleted: data => {
      const exportUrl = (data?.socialAccount as IgSocialAccount)?.sentMessages?.csvUrl
      if (exportUrl && exportUrl !== exportUrlRef.current) {
        exportUrlRef.current = exportUrl
        window.location.href = exportUrl
      }
    },
  })

  const socialAccount =
    sentMessagesActivityData?.socialAccount && isTypeName(sentMessagesActivityData?.socialAccount, 'IGSocialAccount')
      ? sentMessagesActivityData.socialAccount
      : null
  const activityList = socialAccount?.sentMessages?.results || []
  const totalMessagesCount = socialAccount?.sentMessages?.total || 0
  const dateRangeFilter = useDateInclusionExclusionWhereFilter(filters?.sentAt)
  const metricDateTimeRangeFilter = (dateRangeFilter?.require as AbsoluteDateTimeRangeFilter) || null
  const messagesActivityCursor = socialAccount?.sentMessages.cursor
  const currentPeriodStats = socialAccount?.currentPeriodStats
  const previousStats = socialAccount?.previousPeriodStats
  const accountCampaigns =
    socialAccount?.campaigns &&
    (socialAccount?.campaigns).map(c => {
      return { id: c.id, name: c.program?.name || '' }
    })
  const hasCampaigns = userData?.whoami?.account?.organization.activeProducts?.some(
    p => p === AccountProductName.Campaign,
  )

  const accountSegments = userData?.whoami?.account?.segments?.results || []

  const activityOptions: Options = [
    {
      name: 'sentAt',
      type: 'dateRange' as const,
      label: 'Message Date',
      exclude: true,
      entity: 'normalPlusHours' as const,
    },
    {
      name: 'messageKeywords',
      type: 'keywords' as const,
      label: 'Message Keywords ',
      includeField: 'all' as const,
      useChips: true,
      allowSpaces: true,
    },
    ...(messageTemplates.length
      ? [
          {
            name: 'messageTemplate',
            type: 'labels' as const,
            label: 'Template Name ',
            entity: 'message' as const,
            selectionOptions: messageTemplates,
            includeField: 'any' as const,
          },
        ]
      : []),
    {
      name: 'reward',
      type: 'labels' as const,
      label: 'Reward',
      entity: 'reward' as const,
      selectionOptions: accountRewards,
      includeField: 'any' as const,
    },
    {
      name: 'messageSendingMethod',
      type: 'selection',
      label: 'Sending Method',
      selectionOptions: messageSendingMethodOptions,
      exclude: true,
    },
    {
      name: 'customerInfo',
      type: 'parent',
      label: 'Customer Info',
      children: [
        {
          name: 'segments',
          type: 'labels',
          label: 'Segments',
          entity: 'segment' as const,
          selectionOptions: accountSegments,
          includeField: 'all' as const,
          seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
        },
        ...(hasCampaigns && accountCampaigns && accountCampaigns.length > 0
          ? [
              {
                name: 'campaigns',
                type: 'labels' as const,
                label: 'Programs',
                entity: 'program' as const,
                selectionOptions: accountCampaigns || [],
                includeField: 'any' as const,
              },
            ]
          : []),
        {
          name: 'usernameKeywords',
          type: 'keywords',
          label: 'Username',
          useChips: true,
        },
        {
          name: 'biographyKeywords',
          type: 'keywords',
          label: 'Biography',
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'followerCount',
          type: 'numericalRange',
          label: 'Follower Count',
          min: 0,
          max: 1_000_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
      ],
    } as ParentOption,
  ]

  const messageActivityExcludeOpts = [
    {
      name: 'sentAt',
      type: 'dateRange' as const,
      label: 'Message Date',
      exclude: true,
      entity: 'normalPlusHours' as const,
    },
    {
      name: 'messageKeywords',
      type: 'keywords' as const,
      label: 'Message Keywords ',
      includeField: 'all' as const,
      useChips: true,
      allowSpaces: true,
    },
    {
      name: 'messageTemplate',
      type: 'labels' as const,
      label: 'Template Name ',
      entity: 'message' as const,
      selectionOptions: messageTemplates,
    },
    {
      name: 'reward',
      type: 'labels' as const,
      label: 'Reward',
      entity: 'reward' as const,
      selectionOptions: accountRewards,
      includeField: 'any' as const,
    },
    {
      name: 'messageSendingMethod',
      type: 'selection' as const,
      label: 'Sending Method',
      selectionOptions: messageSendingMethodOptions,
      exclude: true,
    },
    {
      name: 'customerInfo',
      type: 'parent',
      label: 'Customer Info',
      children: [
        {
          name: 'segments',
          type: 'labels',
          label: 'Segments',
          entity: 'segment' as const,
          selectionOptions: accountSegments,
          includeField: 'all' as const,
          seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
        },
        ...(hasCampaigns && accountCampaigns && accountCampaigns.length > 0
          ? [
              {
                name: 'campaigns',
                type: 'labels' as const,
                label: 'Programs',
                entity: 'program' as const,
                selectionOptions: accountCampaigns || [],
                includeField: 'any' as const,
              },
            ]
          : []),
      ],
    } as ParentOption,
  ]

  function handleSetFilters(newFilters: Filters): void {
    limitRef.current = PAGE_SIZE
    setFilters(newFilters)
  }

  function handleLoadMore(): void {
    if (messagesActivityCursor) {
      void fetchMore({
        variables: {
          cursor: messagesActivityCursor,
          limit: PAGE_SIZE,
          socialAccountId: socialAccountId || '',
          messageTemplate: messageTemplate || {},
          reward: reward || {},
          customersWhere,
          startDateRange,
          endDateRange,
        },
      })
    }
  }

  const hasError = sentMessagesActivityError || userDataError
  const isLoading = sentMessagesActivityLoading || userDataLoading
  const isLoadingMoreMessagesActivity = networkStatus === NetworkStatus.fetchMore
  return (
    <>
      <div data-intercom-target="Activity Filters">
        <ListFilters
          isDirty={isDirty}
          onChangeFilters={handleSetFilters}
          options={activityOptions}
          excludeOptions={messageActivityExcludeOpts}
          filters={filters}
          contentType="messages"
        />
      </div>
      <Grid container className={classes.grid} wrap="nowrap">
        <Grid item xs={3}>
          <Paper>
            <Metric
              showIcon={false}
              dateRangeFilter={metricDateTimeRangeFilter}
              loading={isLoading}
              metric={currentPeriodStats ? currentPeriodStats?.recipients : 0}
              previousMetric={previousStats ? previousStats?.recipients : 0}
              metricType="recipients"
              size="lg"
              hasCompare={!!metricDateTimeRangeFilter}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper>
            <Metric
              showIcon={false}
              dateRangeFilter={metricDateTimeRangeFilter}
              loading={isLoading}
              metric={currentPeriodStats ? currentPeriodStats?.total : 0}
              previousMetric={previousStats ? previousStats?.total : 0}
              metricType="replies"
              size="lg"
              hasCompare={!!metricDateTimeRangeFilter}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper>
            <Metric
              showIcon={false}
              dateRangeFilter={metricDateTimeRangeFilter}
              loading={isLoading}
              metric={currentPeriodStats ? currentPeriodStats?.rewardsSent : 0}
              previousMetric={previousStats ? previousStats?.rewardsSent : 0}
              metricType="rewards"
              size="lg"
              hasCompare={!!metricDateTimeRangeFilter}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper>
            <Metric
              showIcon={false}
              dateRangeFilter={metricDateTimeRangeFilter}
              loading={isLoading}
              metric={currentPeriodStats ? currentPeriodStats?.emv : 0}
              previousMetric={previousStats ? previousStats?.emv : 0}
              metricType="emv"
              size="lg"
              hasCompare={!!metricDateTimeRangeFilter}
              emvForUgcReplied={true}
            />
          </Paper>
        </Grid>
      </Grid>
      {!hasError && (
        <ListHeader>
          <Box mr={2}>
            <ListCount loading={isLoading} count={totalMessagesCount} units="Messages Sent" />
          </Box>
          <Box flexGrow={1} />
          <ListActions>
            {activityList.length > 0 && (
              <Button variant="outlined" onClick={() => exportActivityList()} startIcon={<Export />} color="primary">
                Export
              </Button>
            )}
          </ListActions>
        </ListHeader>
      )}
      {!hasError && !isLoading && !activityList?.length && (
        <Box display="flex" justifyContent="center">
          <ContainerEmptyState image={NoPostsImage} text="No Messages Sent" />
        </Box>
      )}
      {hasError && <ContainerError text="Sorry, we had a problem loading activities." />}
      {!hasError &&
        !isLoading &&
        activityList.map(message => (
          <ActivityComponent
            key={message.id}
            username={message.socialUser.username}
            profilePic={message.socialUser?.avatarUrl || ''}
            isManual={!!message.sentBy}
            messageName={message.messageTemplate?.name || 'N/A'}
            rewardName={message.fulfillment?.reward?.name || 'N/A'}
            discountCode={message.fulfillment?.rewardDiscountCode?.code || 'N/A'}
            postedAt={message.createdAt}
            isActive={!message.replyMention?.media?.isExpired}
            storyLink={message.replyMention?.media?.permalink}
            customerId={message.socialUser?.customer?.id}
            onClickPreview={() => setPreviewMessage(message)}
          />
        ))}
      {isLoading && !hasError && <LoadingActivityComponent />}
      {!hasError && messagesActivityCursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleLoadMore}
            disabled={isLoadingMoreMessagesActivity}
          >
            Load more
          </Button>
        </Box>
      )}
      <MessagePhonePreviewModal
        open={!!previewMessage}
        onClose={() => setPreviewMessage(null)}
        mentionedSocialUser={socialAccount?.socialUser}
        message={previewMessage?.messageText}
        media={previewMessage?.replyMention?.media}
        createdAt={previewMessage?.createdAt}
      />
    </>
  )
}

export default ActivityList
