import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFilterMutationVariables = Types.Exact<{
  filterId: Types.Scalars['ID'];
}>;


export type DeleteFilterMutation = { __typename: 'Mutations', deleteFilter?: { __typename: 'DeleteFilter', ok?: boolean | null, filter?: { __typename: 'ContentFilterType', id: string, name: string } | { __typename: 'CustomerFilterType', id: string, name: string } | null } | null };


export const DeleteFilterDocument = gql`
    mutation DeleteFilter($filterId: ID!) {
  deleteFilter(filterId: $filterId) {
    ok
    filter {
      ... on BaseFilterType {
        id
        name
      }
    }
  }
}
    `;
export type DeleteFilterMutationFn = Apollo.MutationFunction<DeleteFilterMutation, DeleteFilterMutationVariables>;

/**
 * __useDeleteFilterMutation__
 *
 * To run a mutation, you first call `useDeleteFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilterMutation, { data, loading, error }] = useDeleteFilterMutation({
 *   variables: {
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useDeleteFilterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFilterMutation, DeleteFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFilterMutation, DeleteFilterMutationVariables>(DeleteFilterDocument, options);
      }
export type DeleteFilterMutationHookResult = ReturnType<typeof useDeleteFilterMutation>;
export type DeleteFilterMutationResult = Apollo.MutationResult<DeleteFilterMutation>;
export type DeleteFilterMutationOptions = Apollo.BaseMutationOptions<DeleteFilterMutation, DeleteFilterMutationVariables>;