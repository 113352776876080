import React, { useCallback, useEffect, useRef, useState } from 'react'
import { addHours, intervalToDuration } from 'date-fns'
import { createStyles, makeStyles, Theme, Box } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expirationDisplay: {
      color: theme.palette.text.primary,
      fontSize: 12,
      marginBottom: 12,
    },
    timeLeftYellow: {
      color: theme.palette.warning.dark,
    },
    timeLeftRed: {
      color: theme.palette.error.main,
    },
    timeLeftDefault: {
      color: theme.palette.secondary.main,
    },
  }),
)

interface CountDownTimerType {
  expiration: Date
}

function CountDownTimer({ expiration }: CountDownTimerType): JSX.Element {
  const classes = useStyles()
  const start = new Date()
  const { hours = 0, minutes = 0, seconds = 0 } = intervalToDuration({
    start: start,
    end: expiration,
  })

  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds])

  const expired = start > expiration
  const timesUp = expired || (hrs === 0 && mins === 0 && secs === 0)

  const timerId = useRef<NodeJS.Timeout | null>(null)

  const tick = useCallback(() => {
    setTime(prev => {
      const prevHours = prev[0]
      const prevMins = prev[1]
      const prevSec = prev[2]
      if (prevMins === 0 && prevSec === 0) {
        return [prevHours - 1, 59, 59]
      } else if (prevSec === 0) {
        return [prevHours, prevMins - 1, 59]
      } else {
        return [prevHours, prevMins, prevSec - 1]
      }
    })
  }, [])

  useEffect(() => {
    if (!timesUp && !timerId.current) {
      timerId.current = setInterval(tick, 1000)
    } else if (timesUp && timerId.current) {
      timerId.current = null
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
      } else {
        return
      }
    }
  }, [tick, timesUp])

  const timeLeftClassName = timesUp
    ? classes.timeLeftDefault
    : addHours(start, 1) > expiration
    ? classes.timeLeftRed
    : addHours(start, 5) > expiration
    ? classes.timeLeftYellow
    : undefined

  return (
    <Box minWidth={150}>
      <span className={timeLeftClassName}>
        {timesUp
          ? 'Expired'
          : `${hrs.toString().padStart(2, '0')}h ${mins.toString().padStart(2, '0')}m ${secs
              .toString()
              .padStart(2, '0')}s`}
      </span>
    </Box>
  )
}

export default CountDownTimer
