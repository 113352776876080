import React from 'react'
import { makeStyles, Chip, createStyles } from '@material-ui/core'
import { MentionStatus } from '../../gql-global'

const useStyles = makeStyles(theme =>
  createStyles({
    [MentionStatus.Verified]: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.dark,
    },
    [MentionStatus.Rejected]: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    [MentionStatus.Unverified]: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
    [MentionStatus.OfficialReview]: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
  }),
)

export const mentionStatusLabels = {
  [MentionStatus.Rejected]: 'Rejected',
  [MentionStatus.Verified]: 'Approved',
  [MentionStatus.OfficialReview]: 'Needs Official Review',
  [MentionStatus.Unverified]: 'Unverified',
} as const

const TagStatusChip: React.FC<{ status: MentionStatus }> = ({ status }) => {
  const classes = useStyles()
  const className = classes[status]
  const text = mentionStatusLabels[status]
  return <Chip className={className} label={text} size="small" />
}

export default TagStatusChip
