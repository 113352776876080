import { Active, closestCenter, DndContext, Over, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { createStyles, makeStyles } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { SortableOverlay } from './components/SortableOverlay'

interface BaseItem {
  id: string
}

interface SortableListProps<T extends BaseItem> {
  items: T[]
  setItems: React.Dispatch<React.SetStateAction<T[]>>
  renderItem(item: T): React.ReactNode
}

const useStyles = makeStyles(() =>
  createStyles({
    sortableListContainer: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      width: '100%',
    },
  }),
)

export function SortableList<T extends BaseItem>({ items, setItems, renderItem }: SortableListProps<T>) {
  const classes = useStyles()
  const [active, setActive] = useState<Active | null>(null)
  const activeItem = useMemo(() => items.find(item => item.id === active?.id), [active, items])
  const sensors = useSensors(useSensor(PointerSensor))

  const onDragStart = ({ active }: { active: Active }) => {
    setActive(active)
  }

  const onDragEnd = ({ active, over }: { active: Active; over: Over | null }) => {
    if (over && active.id !== over.id) {
      const activeIndex = items.findIndex(({ id }) => id === active.id)
      const overIndex = items.findIndex(({ id }) => id === over.id)
      setItems(arrayMove(items, activeIndex, overIndex))
    }
    setActive(null)
  }

  const onDragCancel = () => {
    setActive(null)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      collisionDetection={closestCenter}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ul className={classes.sortableListContainer}>
          {items.map(item => (
            <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
    </DndContext>
  )
}
