import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AccountManagement from './accounts/AccountManagement'
import {
  ADMIN_ACCOUNTS_ROUTE,
  ADMIN_ADD_STORY_MENTION,
  ADMIN_ROUTE,
  ADMIN_CUSTOMER_UPLOAD_ROUTE,
  ADMIN_FEATURE_OVERRIDE,
  ADMIN_SEND_MONTHLY_EMAIL,
  ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS,
} from './routes'
import SegmentUpload from './customer-upload/CustomerUpload'
import Admin from './Admin'
import AddStoryMention from './add-story-mention/AddStoryMention'
import FeatureOverride from './FeatureOverride'
import SendMonthlyEmail from './send-monthly-email/SendMonthlyEmail'
import TikTokHashtagManagement from './tiktok-hashtags/TikTokHashtagManagement'

function AdminContainer(): React.ReactElement {
  return (
    <Switch>
      <Route path={ADMIN_ROUTE.path} component={Admin} exact />
      <Route path={ADMIN_CUSTOMER_UPLOAD_ROUTE.path} component={SegmentUpload} />
      <Route path={ADMIN_ACCOUNTS_ROUTE.path} component={AccountManagement} />
      <Route path={ADMIN_ADD_STORY_MENTION.path} component={AddStoryMention} />
      <Route path={ADMIN_FEATURE_OVERRIDE.path} component={FeatureOverride} />
      <Route path={ADMIN_SEND_MONTHLY_EMAIL.path} component={SendMonthlyEmail} />
      <Route path={ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS.path} component={TikTokHashtagManagement} />
    </Switch>
  )
}

export default AdminContainer
