import { RefObject, useState, useEffect } from 'react'
import useDebouncedCallback from './useDebouncedCallback'

type dimension = {
  width: number
  height: number
}

export const useContainerDimensions = (myRef: RefObject<HTMLDivElement>): dimension => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const handleResize = (): void => {
    setDimensions({
      width: myRef?.current?.offsetWidth || 0,
      height: myRef?.current?.offsetHeight || 0,
    })
  }
  const debouncedHandleResize = useDebouncedCallback(handleResize, 1000)

  useEffect(() => {
    if (myRef.current) {
      setDimensions({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
      })
    }
  }, [myRef])

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [debouncedHandleResize])

  return dimensions
}
