import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SocialAccountChallengesQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SocialAccountChallengesQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, challenges?: { __typename: 'ChallengeTypePaged', cursor?: string | null, total: number, results: Array<{ __typename: 'ChallengeType', id: string, name: string }> } | null } | { __typename: 'TTSocialAccount', id: string, challenges?: { __typename: 'ChallengeTypePaged', cursor?: string | null, total: number, results: Array<{ __typename: 'ChallengeType', id: string, name: string }> } | null } | null };


export const SocialAccountChallengesDocument = gql`
    query SocialAccountChallenges($socialAccountId: ID!, $limit: Int!, $cursor: String) {
  socialAccount(id: $socialAccountId) {
    id
    challenges(limit: $limit, cursor: $cursor) {
      results {
        id
        name
      }
      cursor
      total
    }
  }
}
    `;

/**
 * __useSocialAccountChallengesQuery__
 *
 * To run a query within a React component, call `useSocialAccountChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialAccountChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialAccountChallengesQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSocialAccountChallengesQuery(baseOptions: Apollo.QueryHookOptions<SocialAccountChallengesQuery, SocialAccountChallengesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialAccountChallengesQuery, SocialAccountChallengesQueryVariables>(SocialAccountChallengesDocument, options);
      }
export function useSocialAccountChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialAccountChallengesQuery, SocialAccountChallengesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialAccountChallengesQuery, SocialAccountChallengesQueryVariables>(SocialAccountChallengesDocument, options);
        }
export type SocialAccountChallengesQueryHookResult = ReturnType<typeof useSocialAccountChallengesQuery>;
export type SocialAccountChallengesLazyQueryHookResult = ReturnType<typeof useSocialAccountChallengesLazyQuery>;
export type SocialAccountChallengesQueryResult = Apollo.QueryResult<SocialAccountChallengesQuery, SocialAccountChallengesQueryVariables>;