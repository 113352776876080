import { useRef } from 'react'
function useDebouncedCallback<F extends (...args: unknown[]) => void>(
  func: F,
  wait: number,
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>()

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    const doLater = (): void => {
      timeoutRef.current = undefined
      func.apply(this, args)
    }

    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(doLater, wait)
  }
}

export default useDebouncedCallback
