import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageAutomationDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MessageAutomationDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null }, account?: { __typename: 'AccountType', id: number, segments?: { __typename: 'PagedSegmentType', total: number, results: Array<{ __typename: 'SegmentType', id: number, name: string }> } | null } | null } | null };


export const MessageAutomationDataDocument = gql`
    query MessageAutomationData {
  whoami {
    id
    preferences @client {
      id
      selectedSocialAccountId
    }
    account {
      id
      segments(limit: 200, sortBy: RECENTLY_USED) {
        total
        results {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMessageAutomationDataQuery__
 *
 * To run a query within a React component, call `useMessageAutomationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageAutomationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageAutomationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageAutomationDataQuery(baseOptions?: Apollo.QueryHookOptions<MessageAutomationDataQuery, MessageAutomationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageAutomationDataQuery, MessageAutomationDataQueryVariables>(MessageAutomationDataDocument, options);
      }
export function useMessageAutomationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageAutomationDataQuery, MessageAutomationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageAutomationDataQuery, MessageAutomationDataQueryVariables>(MessageAutomationDataDocument, options);
        }
export type MessageAutomationDataQueryHookResult = ReturnType<typeof useMessageAutomationDataQuery>;
export type MessageAutomationDataLazyQueryHookResult = ReturnType<typeof useMessageAutomationDataLazyQuery>;
export type MessageAutomationDataQueryResult = Apollo.QueryResult<MessageAutomationDataQuery, MessageAutomationDataQueryVariables>;