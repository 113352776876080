import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme, Typography, Box, Button, Collapse } from '@material-ui/core'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import { Field, useFormikContext } from 'formik'
import { AdditionalCriteriaInputType } from '../gql-global'
import { AddChallengeFormFields } from './AddEditChallengeDialog'
import { buildAdditionalCriteria, challengeNeedCriteria } from './utils/challenge-utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openButton: {
      marginLeft: -13,
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 400,
      '& > span:first-child': {
        borderBottom: '1px solid',
        lineHeight: '1.5em',
      },
      '&:hover > span:first-child': {
        borderBottomColor: 'transparent',
      },
    },
    closeButton: {
      color: theme.palette.secondary.dark,
    },
    label: {
      fontSize: 16,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      textUnderlineOffset: '6px',
      textDecorationThickness: '1px',
    },
    helpText: {
      color: theme.palette.secondary.main,
      margin: '10px 0',
      fontSize: 12,
    },
    checkbox: {
      color: theme.palette.primary.main,
      '& .Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
    criteria: {
      fontSize: 14,
      whiteSpace: 'pre-wrap',
      marginBottom: '10px',
    },
  }),
)

interface AdditionalCriteriaProps {
  value?: AdditionalCriteriaInputType | null
  disabled?: boolean
}

function AdditionalCriteria({ value, disabled }: AdditionalCriteriaProps): React.ReactElement {
  const classes = useStyles()
  const { setFieldValue, values } = useFormikContext<AddChallengeFormFields>()
  const { postTypes, hashtags, keywords, additionalCriteria } = values
  const needsCriteria = challengeNeedCriteria(postTypes, hashtags, keywords)
  const placeholder =
    'List any additional criteria that would qualify or disqualify a post from your challenge. ' +
    'Reviewers will need to verify these criteria before approving posts for the challenge.'

  const handleOpen = () => {
    setFieldValue('additionalCriteria.hasAdditionalCriteria', true)
  }

  const handleRemove = () => {
    setFieldValue('additionalCriteria', {
      criteriaDescription: '',
      storyCriteriaDescription: '',
      shouldMeetProgramRules: false,
    })
  }

  useEffect(() => {
    let newValue = { ...additionalCriteria, hasAdditionalCriteria: false, criteriaDescription: '' }
    if (needsCriteria) {
      newValue = {
        ...additionalCriteria,
        hasAdditionalCriteria: true,
        criteriaDescription: buildAdditionalCriteria(values),
      }
    } else if (!!additionalCriteria?.storyCriteriaDescription) {
      newValue = {
        ...additionalCriteria,
        criteriaDescription: '',
        hasAdditionalCriteria: true,
      }
    }
    setFieldValue('additionalCriteria', newValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTypes, hashtags, keywords])

  return (
    <Box>
      <Collapse in={!value?.hasAdditionalCriteria}>
        <Button
          disabled={disabled}
          startIcon={<PlusIcon height={14} width={14} />}
          onClick={handleOpen}
          className={classes.openButton}
        >
          Add Additional Criteria
        </Button>
      </Collapse>
      <Collapse in={!!value?.hasAdditionalCriteria}>
        <Typography className={classes.label}>Additional Criteria</Typography>
        <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.helpText}>
            These criteria will require posts to be reviewed and approved before counting towards challenge rewards.
          </Typography>
          <Button onClick={handleRemove} className={classes.closeButton} disabled={disabled || needsCriteria}>
            Remove
          </Button>
        </Box>
        <Typography className={classes.criteria}>{additionalCriteria?.criteriaDescription}</Typography>
        <Field
          name="additionalCriteria.storyCriteriaDescription"
          component={TextField}
          placeholder={placeholder}
          disabled={disabled}
          rows={4}
          fullWidth
          multiline
        />
        <Field
          name="additionalCriteria.shouldMeetProgramRules"
          component={CheckboxWithLabel}
          type="checkbox"
          disabled={disabled}
          className={classes.checkbox}
          Label={{ label: 'Challenge posts must also meet my program post rules', className: classes.checkbox }}
        />
      </Collapse>
    </Box>
  )
}

export default AdditionalCriteria
