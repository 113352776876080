import React, { ChangeEvent, useState } from 'react'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { TextField } from '../../components/TextField/TextField'
import withAuthorization from '../../withAuthorization'
import { useSendMonthlyEmailMutation } from './operations/send-monthly-email.generated'
import { ADMIN_SEND_MONTHLY_EMAIL } from '../routes'

function SendMonthlyEmail(): React.ReactElement {
  const [accountId, setAccountId] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [
    sendMonthlyEmail,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useSendMonthlyEmailMutation()
  const handleSave = () => {
    if (accountId && email) {
      sendMonthlyEmail({
        variables: {
          accountId: accountId,
          email: email,
        },
      })
    }
  }
  return (
    <Paper>
      <Box p={5}>
        <Box display="flex" alignItems="center" mb={5}>
          <Box mr={5} width={200}>
            Account Id:
          </Box>
          <TextField
            value={accountId}
            disabled={mutationLoading}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setAccountId(event.target.value)}
            autoFocus
          />
        </Box>
        <Box display="flex" alignItems="center" mb={5}>
          <Box mr={5} width={200}>
            Email:
          </Box>
          <TextField
            value={email}
            disabled={mutationLoading}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
            autoFocus
          />
        </Box>
        <Box display="flex" alignItems="center" mb={5}>
          <Button
            color="primary"
            disabled={!accountId || !email || mutationLoading}
            variant="contained"
            onClick={handleSave}
          >
            Send
          </Button>
        </Box>
        <Box p={5}>
          {'Result: '}
          {mutationData?.sendMonthlyEmail?.ok === true && (
            <Typography>
              email sent under account id: {accountId} and email: {email}
            </Typography>
          )}
          {mutationLoading && <Typography>sending email please wait...</Typography>}
          {mutationError && (
            <Typography color="error">Error sending email please drop a note to @engineers in slack.</Typography>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default withAuthorization(ADMIN_SEND_MONTHLY_EMAIL)(SendMonthlyEmail)
