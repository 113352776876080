import { useQueryParam, stringify, encodeQueryParams } from 'use-query-params'
import { createEnumParam } from '../utils/url-param-utils'
import { ProgramSort } from '../gql-global'

const SORT_PARAM = 'sort'

const sortParam = createEnumParam(ProgramSort)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSortParam = () => {
  return useQueryParam(SORT_PARAM, sortParam)
}

export const encodeSortParam = (sort: ProgramSort): string => {
  return stringify(encodeQueryParams({ [SORT_PARAM]: sortParam }, { [SORT_PARAM]: sort }))
}

export default useSortParam
