import React, { FC, useRef, useState } from 'react'
import { Box, Button, Menu, createStyles, makeStyles, Typography, Divider } from '@material-ui/core'
import { primary } from '../../../loudcrowd-theme'
import MenuHeader from '../../../components/MenuHeader'
import { SelectField } from '../../../components/SelectField/SelectField'
import { RewardType } from '../../../gql-global'
import useProgramActivityRewards from './useProgramActivityRewards'

const useStyles = makeStyles(theme =>
  createStyles({
    inlineButton: {
      color: primary[500],
      fontSize: 'inherit',
      minWidth: 0,
      fontWeight: 400,
      textDecoration: 'underline',
      padding: 0,
      marginBottom: 4,
      textAlign: 'left',
      height: 'inherit',
      '& > .MuiButton-label': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    menu: {
      width: 320,
      border: '1px solid rgba(0, 0, 0, 0.01)',
    },
    renderedIcon: {
      marginTop: '3px',
      color: theme.palette.primary.dark,
      marginRight: '6px',
    },
    sentenceIcon: {
      color: theme.palette.primary.main,
      margin: '5px 6px 0',
    },
    sentenceSeparator: {
      color: theme.palette.text.primary,
      marginLeft: 8,
      textDecoration: 'underline',
      textDecorationColor: 'white',
    },
    metricIcon: {
      marginRight: 8,
      marginTop: 2,
    },
  }),
)

interface RewardFilterProps {
  reward?: Partial<{ id: number; name?: string | null }>
  onRewardChange: (value: Partial<RewardType>) => void
}

const REWARD_PAGE_SIZE = 100

const RewardFilter: FC<RewardFilterProps> = ({ reward, onRewardChange }) => {
  const classes = useStyles()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [value, setValue] = useState(reward?.id ?? null)

  const { rewards } = useProgramActivityRewards(REWARD_PAGE_SIZE)

  const handleSave = () => {
    onRewardChange(rewards?.find(reward => reward.id === value) ?? {})
    setMenuOpen(false)
  }

  const handleClear = () => {
    onRewardChange({})
    setValue(null)
    setMenuOpen(false)
  }

  return (
    <>
      <Button variant="text" className={classes.inlineButton} onClick={() => setMenuOpen(true)} ref={buttonRef}>
        {reward?.id ? `${reward?.name}` : 'a reward'}
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMenuOpen(false)}
        classes={{ paper: classes.menu }}
      >
        <MenuHeader title="Rewards" />
        <Box display="flex" flexDirection="column" p={5}>
          <Box display="flex" flexDirection="column" gridGap={10}>
            <Typography>Choose a reward to send</Typography>
            <SelectField
              hideSearch
              value={value}
              setValue={value => setValue(value as number)}
              options={rewards?.map(reward => ({ id: reward.id, label: reward.name ?? '' })) ?? []}
            />
          </Box>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <Button color="secondary" onClick={handleClear}>
            Reset
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Menu>
    </>
  )
}

export default RewardFilter
