import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'

export interface ContainerEmptyStateProps {
  image: React.FC
  text: string
  subtext?: string
  callToActionText?: string
  callToActionIcon?: React.FC | null
  callToActionCallback?(): void
  callToActionLink?: string
}

const ContainerEmptyState: React.FC<ContainerEmptyStateProps> = ({
  image: Image,
  text,
  subtext,
  callToActionText,
  callToActionIcon: CallToActionIcon,
  callToActionCallback,
  callToActionLink,
}) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box>
      <Image />
    </Box>
    <Typography variant="h5">{text}</Typography>
    {subtext && (
      <Typography variant="body1" color="secondary">
        {subtext}
      </Typography>
    )}
    {callToActionText && (
      <Box mt={11}>
        <Button
          onClick={callToActionCallback}
          color="primary"
          size="large"
          variant="contained"
          startIcon={CallToActionIcon ? <CallToActionIcon /> : undefined}
          href={callToActionLink}
        >
          {callToActionText}
        </Button>
      </Box>
    )}
  </Box>
)

export default ContainerEmptyState
