import React from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { AddChallengeFormFields } from '../AddEditChallengeDialog'
import { TextField } from 'formik-material-ui'
import { primary } from '../../loudcrowd-theme'
import { MultipleSelectField } from '../../components/SelectField/MultipleSelectField'
import { ChallengeMessageDeliveryMethodEnum, ChallengeMessageTriggerEnum } from '../../gql-global'

const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      fontSize: 14,
      marginTop: 10,
      display: 'block',
      color: primary[600],
    },
    textContainer: {
      '& textarea': {
        lineHeight: 1.5,
        fontSize: 14,
      },
    },
    selectWrapper: {
      minWidth: 300,
      '& .MuiFormControl-root': {
        minWidth: 300,
      },
    },
  }),
)

interface ParticipantMessagesProps {
  disabled: boolean
}

function ParticipantMessages({ disabled }: ParticipantMessagesProps): React.ReactElement {
  const classes = useStyles()
  const { values, setFieldValue } = useFormikContext<AddChallengeFormFields>()
  const placeholder =
    'Please input all the messages and message criteria you would like to send. \n' +
    'Ie. Send this message to all participants when there is 1 week left of the challenge; "Reminder! You only ' +
    'have 1 week left to submit entries to our challenge. Post a reel\n' +
    'with our hashtag for a chance to win a $100 gift card."'

  const challengeMessageTriggerOptions = [
    { id: ChallengeMessageTriggerEnum.Start, label: 'Challenge start' },
    { id: ChallengeMessageTriggerEnum.End, label: 'Challenge end' },
    { id: ChallengeMessageTriggerEnum.ParticipantProgress, label: 'Participation progress' },
    { id: ChallengeMessageTriggerEnum.Reminders, label: 'Reminders' },
  ]

  const challengeMessageDeliveryOptions = [
    { id: ChallengeMessageDeliveryMethodEnum.Sms, label: 'SMS' },
    { id: ChallengeMessageDeliveryMethodEnum.Email, label: 'Email' },
    { id: ChallengeMessageDeliveryMethodEnum.Ig, label: 'Instagram' },
  ]

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Automated Messages to Participants
      </Typography>
      <Box mt={3} mb={5} className={classes.selectWrapper}>
        <MultipleSelectField
          placeholder="Select the types of messages you want to send"
          value={values.paintedDoor?.automatedMessageTriggers ?? []}
          setValue={values => setFieldValue('paintedDoor.automatedMessageTriggers', values)}
          options={challengeMessageTriggerOptions}
          disabled={disabled}
          selectionRequired={true}
        />
      </Box>
      <Typography variant="caption" className={classes.caption}>
        Message Delivery Channel
      </Typography>
      <Box mt={3} mb={5} className={classes.selectWrapper}>
        <MultipleSelectField
          placeholder="Select the channel to send messages on"
          value={values.paintedDoor?.automatedMessageDeliveryMethods ?? []}
          setValue={values => setFieldValue('paintedDoor.automatedMessageDeliveryMethods', values)}
          options={challengeMessageDeliveryOptions}
          disabled={disabled}
          selectionRequired={true}
        />
      </Box>
      <Typography variant="caption" className={classes.caption}>
        Messages
      </Typography>
      <Box mb={5} my={2} position="relative" className={classes.textContainer}>
        <Field
          name="paintedDoor.automatedMessageContent"
          component={TextField}
          placeholder={placeholder}
          rows={4}
          fullWidth
          multiline
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

export default ParticipantMessages
