import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessagePreviewDataQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
}>;


export type MessagePreviewDataQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, avatarUrl?: string | null, username: string }, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, avatarUrl?: string | null, username: string }, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null } | null };


export const MessagePreviewDataDocument = gql`
    query MessagePreviewData($socialAccountId: ID!) {
  socialAccount(id: $socialAccountId) {
    id
    socialUser {
      id
      avatarUrl
      username
    }
    campaigns {
      id
      program {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMessagePreviewDataQuery__
 *
 * To run a query within a React component, call `useMessagePreviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagePreviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagePreviewDataQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *   },
 * });
 */
export function useMessagePreviewDataQuery(baseOptions: Apollo.QueryHookOptions<MessagePreviewDataQuery, MessagePreviewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagePreviewDataQuery, MessagePreviewDataQueryVariables>(MessagePreviewDataDocument, options);
      }
export function useMessagePreviewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagePreviewDataQuery, MessagePreviewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagePreviewDataQuery, MessagePreviewDataQueryVariables>(MessagePreviewDataDocument, options);
        }
export type MessagePreviewDataQueryHookResult = ReturnType<typeof useMessagePreviewDataQuery>;
export type MessagePreviewDataLazyQueryHookResult = ReturnType<typeof useMessagePreviewDataLazyQuery>;
export type MessagePreviewDataQueryResult = Apollo.QueryResult<MessagePreviewDataQuery, MessagePreviewDataQueryVariables>;