import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import ActivityListItemContent from './ActivityListItemContent'
import { ReactComponent as ShoppingBagIcon } from '../icons/shopping-bag.svg'

interface AttributedSaleActivityListItemProps {
  activity: ConcreteEventType<'AttributedSaleCustomerEvent'>
  customerUsername: string
}

function AttributedSaleActivityListItem({
  activity,
  customerUsername,
}: AttributedSaleActivityListItemProps): React.ReactElement {
  const text = `@${customerUsername} made a sale! - Order #${activity.platformOrderId}`

  return (
    <ActivityListItemContent
      media={
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent={'center'}
          m={4}
          p={2}
          width={48}
          height={48}
          borderRadius={24}
          bgcolor="#D4EAFF"
          color="#005099"
        >
          <ShoppingBagIcon width={25} height={25} />
        </Box>
      }
      text={
        <Typography variant="subtitle1" display="inline">
          {text}
        </Typography>
      }
      eventAt={activity.eventAt}
    />
  )
}

export default AttributedSaleActivityListItem
