import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Divider, TextField, Menu, createStyles, makeStyles, Typography, Chip } from '@material-ui/core'
import { primary } from '../../../loudcrowd-theme'
import MenuHeader from '../../../components/MenuHeader'
import { IgMediaPostType, ProgramTierBuffer, SocialPlatformEnum } from '../../../gql-global'
import { SingleMenuOption } from '../../../components/lists/ListFilters/types'
import { IgMentionTypes } from '../constants'
import clsx from 'clsx'
import Switch from '../../../components/Switch'
import TextFilters from '../../../components/lists/ListFilters/TextFilters'
import { capitalizeFirstLetterOnly } from '../../../utils/text-format'
import MetricIcon from '../../../components/metric/MetricIcon'
import { PostCountCriteriaType, useProgramActivityBuilder } from './ProgramActivityBuilderProvider'

type PostType = IgMediaPostType | SocialPlatformEnum.Tiktok

const POST_TYPE_OPTIONS: Array<{ id: PostType; name: string }> = [
  { id: IgMediaPostType.Feed, name: 'In-Feed Post' },
  { id: IgMediaPostType.Story, name: 'Story' },
  { id: IgMediaPostType.Reels, name: 'Reel' },
  { id: SocialPlatformEnum.Tiktok, name: 'TikTok' },
]

const MENTION_TYPE_OPTIONS: Array<{ id: IgMentionTypes; name: string }> = [
  { id: IgMentionTypes.Caption, name: 'caption' },
  { id: IgMentionTypes.Tag, name: 'tag' },
]

const DATE_RANGE_OPTIONS = [
  { label: 'per day', id: ProgramTierBuffer.OneDay },
  { label: 'per 2 days', id: ProgramTierBuffer.TwoDays },
  { label: 'per 5 days', id: ProgramTierBuffer.FiveDays },
  { label: 'per 7 days', id: ProgramTierBuffer.OneWeek },
]

const BUFFER_DATE_RANGE_OPTIONS = [
  {
    name: 'bufferDateRange',
    type: 'singleMenu',
    label: '',
    selectionOptions: DATE_RANGE_OPTIONS,
    displayVariant: 'inline',
  } as SingleMenuOption,
]

const useStyles = makeStyles(theme =>
  createStyles({
    inlineButton: {
      color: primary[500],
      fontSize: 'inherit',
      minWidth: 0,
      fontWeight: 400,
      textDecoration: 'underline',
      padding: 0,
      marginBottom: 4,
      textAlign: 'left',
      height: 'inherit',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    caption: {
      fontSize: 12,
      fontStyle: 'italic',
      marginLeft: 5,
      marginTop: 4,
    },
    toggleChip: {
      fontWeight: 400,
      borderRadius: '6px',
      border: `1px solid ${theme.palette.primary.main}`,
      '&.MuiChip-outlined': {
        color: theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
      },
    },
    menu: {
      minWidth: 320,
      border: '1px solid rgba(0, 0, 0, 0.01)',
    },
    switchBox: {
      '& .MuiButton-root': {
        marginBottom: 3,
      },
    },
    buttonCaption: {
      display: 'block',
    },
    postTypeCaption: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    buttonWrapper: {
      display: 'inline-flex',
      marginLeft: 5,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    labelDisabled: {
      color: theme.palette.secondary.main,
    },
  }),
)

interface PostCountFilterProps {
  postCountCriteria?: PostCountCriteriaType
  onPostCountCriteriaChange: (value: PostCountCriteriaType) => void
  isInstagramIncluded: boolean
  isTiktokIncluded: boolean
}

const PostCountFilter = ({
  postCountCriteria,
  onPostCountCriteriaChange,
  isInstagramIncluded,
  isTiktokIncluded,
}: PostCountFilterProps) => {
  const classes = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [values, setValues] = useState<PostCountCriteriaType>(postCountCriteria || {})
  const { buildCriteria } = useProgramActivityBuilder()

  const isMentionTypeDisabled = useMemo(
    () =>
      !values?.selectedPostTypes?.has(IgMediaPostType.Feed) && !values?.selectedPostTypes?.has(IgMediaPostType.Reels),
    [values],
  )

  const isDisabled = useMemo(
    () =>
      !values?.totalPosts ||
      (isInstagramIncluded &&
        !isMentionTypeDisabled &&
        (!values?.selectedMentionTypes?.size || !values?.selectedPostTypes?.size)) ||
      (isInstagramIncluded && isTiktokIncluded && !values?.selectedPostTypes?.has(SocialPlatformEnum.Tiktok)) ||
      (isInstagramIncluded && isTiktokIncluded && (values?.selectedPostTypes?.size ?? 0) < 2),
    [isInstagramIncluded, isTiktokIncluded, isMentionTypeDisabled, values],
  )

  const handleOnChange = (value: Partial<PostCountCriteriaType>) => {
    setValues({ ...values, ...value })
  }

  const handleSave = () => {
    onPostCountCriteriaChange(values)
    setMenuOpen(false)
  }

  const handleClear = () => {
    onPostCountCriteriaChange(buildCriteria())
    setMenuOpen(false)
  }

  useEffect(() => {
    !!postCountCriteria && setValues(postCountCriteria)
  }, [postCountCriteria])

  return (
    <>
      <div className={classes.buttonWrapper}>
        <Button variant="text" className={classes.inlineButton} onClick={() => setMenuOpen(true)} ref={buttonRef}>
          {postCountCriteria?.totalPosts
            ? `${postCountCriteria?.totalPosts} post${postCountCriteria?.totalPosts > 1 ? 's' : ''}`
            : 'a number of posts'}
        </Button>
        {!!postCountCriteria?.selectedPostTypes?.size && postCountCriteria?.totalPosts && (
          <PostTypeCaption
            options={Array.from(postCountCriteria?.selectedPostTypes)}
            className={classes.postTypeCaption}
          />
        )}
        {!!postCountCriteria?.selectedMentionTypes?.size && postCountCriteria?.totalPosts && !isMentionTypeDisabled && (
          <Typography color="primary" variant="caption" className={classes.buttonCaption}>
            {postCountCriteria?.selectedMentionTypes.size === MENTION_TYPE_OPTIONS.length
              ? 'All mention types'
              : `${capitalizeFirstLetterOnly(Array.from(postCountCriteria?.selectedMentionTypes)?.[0])} mention only`}
          </Typography>
        )}
        {postCountCriteria?.isBuffered && (
          <Typography color="primary" variant="caption" className={classes.buttonCaption}>
            Only count 1 post {DATE_RANGE_OPTIONS.find(item => item.id === postCountCriteria.bufferDateRange)?.label}
          </Typography>
        )}
      </div>

      <Menu
        open={menuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMenuOpen(false)}
        classes={{ paper: classes.menu }}
      >
        <MenuHeader title="Post Count" />
        <Box p={5}>
          <Typography>Enter number of total posts</Typography>
          <TextField
            fullWidth
            value={values?.totalPosts ? values?.totalPosts : ''}
            onChange={e => handleOnChange({ totalPosts: parseInt(e.target.value) })}
          />
          <Box mt={4} display="flex" alignItems="center" gridGap={5} className={classes.switchBox}>
            <Switch
              size="small"
              value={values.isBuffered}
              checked={values.isBuffered}
              onClick={() => handleOnChange({ isBuffered: !values.isBuffered })}
            />
            Only count 1 post
            <TextFilters
              editable={values.isBuffered}
              onChangeFilters={({ bufferDateRange }) => handleOnChange({ bufferDateRange })}
              options={BUFFER_DATE_RANGE_OPTIONS}
              filters={{ bufferDateRange: values.bufferDateRange }}
            />
          </Box>
          {isInstagramIncluded && (
            <>
              <Box mt={4} display="flex" alignItems="center">
                <Typography>Included post types</Typography>
              </Box>
              <ChipWrapper
                options={POST_TYPE_OPTIONS.filter(
                  ({ id }) => isTiktokIncluded || (!isTiktokIncluded && id !== SocialPlatformEnum.Tiktok),
                )}
                className={classes.toggleChip}
                selected={values?.selectedPostTypes}
                handleOnChange={value => handleOnChange({ selectedPostTypes: value })}
              />
              <Box
                mt={3}
                display="flex"
                alignItems="center"
                className={clsx(isMentionTypeDisabled && classes.labelDisabled)}
              >
                <Typography>Included mention types</Typography>
                <span className={classes.caption}>In-feed posts & Reels</span>
              </Box>
              <ChipWrapper
                disabled={isMentionTypeDisabled}
                options={MENTION_TYPE_OPTIONS}
                className={classes.toggleChip}
                selected={values?.selectedMentionTypes}
                handleOnChange={value => handleOnChange({ selectedMentionTypes: value })}
              />
            </>
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <Button color="secondary" onClick={handleClear}>
            Reset
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave} disabled={isDisabled}>
            Save
          </Button>
        </Box>
      </Menu>
    </>
  )
}

interface PostTypeCaptionProps {
  options: Array<PostType>
  className: string
}

const PostTypeCaption = ({ options, className }: PostTypeCaptionProps) => {
  const isAllSelected = options?.length === POST_TYPE_OPTIONS.length
  const igPostTypes = options?.filter(item => item !== SocialPlatformEnum.Tiktok)
  const ttPostTypes = options?.filter(item => item === SocialPlatformEnum.Tiktok)
  const isAllInstagramSelected =
    igPostTypes?.length === POST_TYPE_OPTIONS.filter(({ id }) => id !== SocialPlatformEnum.Tiktok).length

  return (
    <Typography color="primary" variant="caption" className={className}>
      {isAllInstagramSelected && !isAllSelected && 'All Instagram posts'}
      {isAllSelected && 'All Instagram and TikTok posts'}
      {!isAllSelected && !isAllInstagramSelected && (
        <>
          {igPostTypes?.length > 0 && (
            <Box display="flex" gridGap={5} justifyContent="center">
              <MetricIcon icon="instagram" width={14} />
              {igPostTypes?.map((value, key) => (
                <React.Fragment key={key}>
                  {POST_TYPE_OPTIONS.find(item => item.id === value)?.name}
                  {key < igPostTypes.length - 1 && ', '}
                </React.Fragment>
              ))}
            </Box>
          )}
          {ttPostTypes?.length > 0 && (
            <Box display="flex" gridGap={5} justifyContent="center">
              <MetricIcon icon="tiktok" width={14} />
              TikTok
            </Box>
          )}
        </>
      )}
    </Typography>
  )
}

interface ChipWrapperProps<T extends PostType | IgMentionTypes> {
  options: Array<{ id: T; name: string }>
  selected?: Set<T>
  handleOnChange: (value: Set<T>) => void
  className?: any
  disabled?: boolean
}

function ChipWrapper<T extends PostType | IgMentionTypes>({
  options,
  selected,
  handleOnChange,
  className,
  disabled,
}: ChipWrapperProps<T>) {
  return (
    <Box display="flex" py={2} gridColumnGap={6}>
      {options.map(({ id, name }) => {
        const isSelected = selected?.has(id)
        return (
          <Chip
            key={id}
            label={name}
            color={disabled ? 'secondary' : isSelected ? 'primary' : undefined}
            variant={isSelected && !disabled ? 'default' : 'outlined'}
            disabled={disabled}
            size="small"
            onClick={() => {
              const updated = new Set(selected)
              isSelected ? updated.delete(id) : updated.add(id)
              handleOnChange(updated)
            }}
            className={className}
          />
        )
      })}
    </Box>
  )
}

export default PostCountFilter
