import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSegmentSingleCustomerMutationVariables = Types.Exact<{
  segmentId: Types.Scalars['ID'];
  customerId: Types.Scalars['ID'];
}>;


export type AddSegmentSingleCustomerMutation = { __typename: 'Mutations', addCustomerSegment?: { __typename: 'AddCustomerSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null, customers?: Array<{ __typename: 'CustomerType', id: number, segments?: Array<{ __typename: 'SegmentType', id: number, name: string }> | null }> | null } | null };


export const AddSegmentSingleCustomerDocument = gql`
    mutation AddSegmentSingleCustomer($segmentId: ID!, $customerId: ID!) {
  addCustomerSegment(customerIds: [$customerId], segmentId: $segmentId, limit: 1) {
    ok
    segment {
      id
      name
      account {
        id
      }
    }
    customers {
      id
      segments {
        id
        name
      }
    }
  }
}
    `;
export type AddSegmentSingleCustomerMutationFn = Apollo.MutationFunction<AddSegmentSingleCustomerMutation, AddSegmentSingleCustomerMutationVariables>;

/**
 * __useAddSegmentSingleCustomerMutation__
 *
 * To run a mutation, you first call `useAddSegmentSingleCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSegmentSingleCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSegmentSingleCustomerMutation, { data, loading, error }] = useAddSegmentSingleCustomerMutation({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAddSegmentSingleCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddSegmentSingleCustomerMutation, AddSegmentSingleCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSegmentSingleCustomerMutation, AddSegmentSingleCustomerMutationVariables>(AddSegmentSingleCustomerDocument, options);
      }
export type AddSegmentSingleCustomerMutationHookResult = ReturnType<typeof useAddSegmentSingleCustomerMutation>;
export type AddSegmentSingleCustomerMutationResult = Apollo.MutationResult<AddSegmentSingleCustomerMutation>;
export type AddSegmentSingleCustomerMutationOptions = Apollo.BaseMutationOptions<AddSegmentSingleCustomerMutation, AddSegmentSingleCustomerMutationVariables>;