import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
import { useUserInfoQuery } from '../queries/operations/user-info.generated'
import { StringParam, useQueryParams } from 'use-query-params'

type InnerComponentFactory<WrappedComponentProps> = (
  comp: React.ComponentType<WrappedComponentProps>,
) => React.ComponentType<WrappedComponentProps>

export default function withAuthentication<WrappedComponentProps>(): InnerComponentFactory<WrappedComponentProps> {
  return Comp => {
    const WrappedComponent: React.FC<WrappedComponentProps> = props => {
      const [shopifyQuery] = useQueryParams({
        hmac: StringParam,
        host: StringParam,
        code: StringParam,
        state: StringParam,
        shop: StringParam,
        timestamp: StringParam,
      })
      const { user: auth0User, isLoading: auth0IsLoading, error: auth0Error } = useAuth0()
      const { data: userInfo, loading: userInfoLoading, error: userInfoError } = useUserInfoQuery({
        skip: auth0IsLoading,
      })
      const userHasAccount = !userInfoLoading && userInfo?.whoami?.account?.id

      const { hmac, host, code, state, shop, timestamp } = shopifyQuery

      if (auth0IsLoading || userInfoLoading) {
        return null
      } else if (!auth0IsLoading && !auth0User && hmac && host && code && state && shop && timestamp) {
        return (
          <Redirect
            to={{
              pathname: '/auth/auth-login',
              search: `?hmac=${hmac}&host=${host}&code=${code}&state=${state}&shop=${shop}&timestamp=${timestamp}`,
            }}
          />
        )
      } else if (!auth0IsLoading && !auth0User && !auth0Error) {
        return <Redirect to="/auth/auth-login" />
      } else if (userInfoError || auth0Error) {
        return <Redirect to="/auth/auth-logout" />
      } else if (!auth0User?.email_verified) {
        return <Redirect to="/auth/check-email" />
      } else if (auth0User?.email_verified && !userHasAccount) {
        return <Redirect to="/signup/account" />
      } else {
        return <Comp {...props} />
      }
    }

    WrappedComponent.displayName = `withAuthorization(${Comp.displayName || 'Comp'})`
    return WrappedComponent
  }
}
