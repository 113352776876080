import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { IntegrationRowFragment } from './operations/integration-row.generated'
import TableHeader, { TableHeaderCell } from './TableHeader'
import TableRow, { TableRowCell } from './TableRow'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import EcommIntegrationModal from './EcommIntegrationModal'
import { IntegrationType, SegmentType } from '../gql-global'
import type { CustomSourceIntegration } from './EcommIntegrationModal'

export const ShopIntegrationTypeNames = [
  'ShopifyIntegration',
  'GoogleIntegration',
  'CustomStoreIntegration',
  'CommerceCloudIntegration',
] as const
type ShopIntegrationTypes = (typeof ShopIntegrationTypeNames)[number]
export type ShopIntegration = Extract<IntegrationRowFragment, { __typename: ShopIntegrationTypes }>

const typeNameMap: Record<ShopIntegrationTypes, string> = {
  ShopifyIntegration: 'Shopify',
  GoogleIntegration: 'Google Merchant Center',
  CustomStoreIntegration: 'Custom Ecommerce',
  CommerceCloudIntegration: 'Salesforce Commerce Cloud',
}

interface EcommerceIntegrationsProps {
  segments: SegmentType[]
  shopIntegrations: ShopIntegration[]
  customSourceIntegrations: CustomSourceIntegration[]
  loading?: false
  accountId?: string | null
}

type AddEditState = { open: boolean; editingIntegration?: ShopIntegration }

const useStyles = makeStyles({
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
})

function EcommerceIntegrations({
  loading,
  segments,
  shopIntegrations,
  customSourceIntegrations,
  accountId,
}: EcommerceIntegrationsProps): React.ReactElement {
  const [addEditState, setAddEditState] = useState<AddEditState>({ open: false })
  const styles = useStyles()

  function handleClose() {
    setAddEditState({ open: false })
  }

  return (
    <>
      {(loading || !!shopIntegrations.length) && (
        <TableHeader>
          <TableHeaderCell>Integration</TableHeaderCell>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell />
          <TableHeaderCell>Shop ID</TableHeaderCell>
          <TableHeaderCell />
          <TableHeaderCell />
        </TableHeader>
      )}
      {loading && (
        <>
          <TableRow>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell />
          </TableRow>
          <TableRow>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell />
          </TableRow>
        </>
      )}
      {shopIntegrations.map(i => (
        <TableRow key={i.id}>
          <TableRowCell className={styles.truncated}>{typeNameMap[i.__typename]}</TableRowCell>
          <TableRowCell className={styles.truncated}>{i.name}</TableRowCell>
          <TableRowCell />
          <TableRowCell className={styles.truncated}>{i.id}</TableRowCell>
          <TableRowCell textAlign="right">
            {!(i.integrationType === IntegrationType.Google) && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => setAddEditState({ open: true, editingIntegration: i })}
              >
                Edit
              </Button>
            )}
          </TableRowCell>
          <TableRowCell display="flex" justifyContent="flex-end" alignItems="center">
            <Box mr={2} /> {/* TODO: Add Test Button */}
          </TableRowCell>
        </TableRow>
      ))}
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => {
          setAddEditState({ open: true })
        }}
      >
        Add Integration
      </Button>
      <EcommIntegrationModal
        open={addEditState.open}
        onCancel={handleClose}
        onClose={handleClose}
        segments={segments}
        integrations={customSourceIntegrations}
        editingIntegration={
          addEditState?.editingIntegration?.integrationType === IntegrationType.CustomStore ||
          addEditState?.editingIntegration?.integrationType === IntegrationType.CommerceCloud ||
          addEditState?.editingIntegration?.integrationType === IntegrationType.Shopify
            ? addEditState.editingIntegration
            : undefined
        }
        accountId={accountId}
      />
    </>
  )
}

export default EcommerceIntegrations
