import React from 'react'
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listActions: {
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  }),
)

const ListActions: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center" className={classes.listActions}>
      {children}
    </Box>
  )
}

export default ListActions
