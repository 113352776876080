import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type IntegrationRow_BluecoreIntegration_Fragment = { __typename: 'BluecoreIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_BrazeIntegration_Fragment = { __typename: 'BrazeIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_CommerceCloudIntegration_Fragment = { __typename: 'CommerceCloudIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, displayName?: string | null, keepUnattributedOrders?: boolean | null, creatorWidget: boolean, creatorWidgetPosition: Types.EcommWidgetPosition, slug?: string | null, clientId?: string | null, lcRelativeLogoUrl?: string | null, organizationId: string, productIdRegex: string, productUrlTemplate: string, shopUrl: string, shortCode: string, siteId: string, storeLogoUrl?: string | null, validScopes?: Array<Types.SalesforceCommerceCloudScopes> | null, ilpFilterSegment?: { __typename: 'SegmentType', id: number } | null };

export type IntegrationRow_CustomStoreIntegration_Fragment = { __typename: 'CustomStoreIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, displayName?: string | null, keepUnattributedOrders?: boolean | null, creatorWidget: boolean, creatorWidgetPosition: Types.EcommWidgetPosition, slug?: string | null, lcRelativeLogoUrl?: string | null, productIdRegex: string, shopUrl: string, storeLogoUrl?: string | null, ilpFilterSegment?: { __typename: 'SegmentType', id: number } | null, productSourceIntegration?: { __typename: 'BluecoreIntegration', id: string } | { __typename: 'BrazeIntegration', id: string } | { __typename: 'CommerceCloudIntegration', id: string } | { __typename: 'CustomStoreIntegration', id: string } | { __typename: 'EmarsysIntegration', id: string } | { __typename: 'GoogleIntegration', id: string } | { __typename: 'GrowaveIntegration', id: string } | { __typename: 'ImpactIntegration', id: string } | { __typename: 'KlaviyoIntegration', id: string } | { __typename: 'LoyaltyLionIntegration', id: string } | { __typename: 'MandrillIntegration', id: string } | { __typename: 'OmetriaIntegration', id: string } | { __typename: 'RefersionIntegration', id: string } | { __typename: 'SFTPIntegration', id: string } | { __typename: 'SFTPPullIntegration', id: string } | { __typename: 'SalesforceOcapiIntegration', id: string } | { __typename: 'SendinblueIntegration', id: string } | { __typename: 'ShopifyIntegration', id: string } | { __typename: 'SmileIntegration', id: string } | { __typename: 'TremendousIntegration', id: string } | { __typename: 'WebhookIntegration', id: string } | { __typename: 'YotpoIntegration', id: string } | { __typename: 'YotpoVizIntegration', id: string } | { __typename: 'ZaiusIntegration', id: string } | null };

export type IntegrationRow_EmarsysIntegration_Fragment = { __typename: 'EmarsysIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_GoogleIntegration_Fragment = { __typename: 'GoogleIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_GrowaveIntegration_Fragment = { __typename: 'GrowaveIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_ImpactIntegration_Fragment = { __typename: 'ImpactIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, accountSid: string, accessToken: string, campaignId: string, linkTextMessage: string, campaignRedirectDomain: string };

export type IntegrationRow_KlaviyoIntegration_Fragment = { __typename: 'KlaviyoIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_LoyaltyLionIntegration_Fragment = { __typename: 'LoyaltyLionIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_MandrillIntegration_Fragment = { __typename: 'MandrillIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_OmetriaIntegration_Fragment = { __typename: 'OmetriaIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_RefersionIntegration_Fragment = { __typename: 'RefersionIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, igUsernameFields: Array<string>, key: string, ttUsernameFields: Array<string> };

export type IntegrationRow_SftpIntegration_Fragment = { __typename: 'SFTPIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_SftpPullIntegration_Fragment = { __typename: 'SFTPPullIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, objectType: Types.ClientSftpObjectType };

export type IntegrationRow_SalesforceOcapiIntegration_Fragment = { __typename: 'SalesforceOcapiIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_SendinblueIntegration_Fragment = { __typename: 'SendinblueIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_ShopifyIntegration_Fragment = { __typename: 'ShopifyIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string, displayName?: string | null, keepUnattributedOrders?: boolean | null, creatorWidget: boolean, creatorWidgetPosition: Types.EcommWidgetPosition, slug?: string | null, lcRelativeLogoUrl?: string | null, storeLogoUrl?: string | null, ilpFilterSegment?: { __typename: 'SegmentType', id: number } | null };

export type IntegrationRow_SmileIntegration_Fragment = { __typename: 'SmileIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_TremendousIntegration_Fragment = { __typename: 'TremendousIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_WebhookIntegration_Fragment = { __typename: 'WebhookIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_YotpoIntegration_Fragment = { __typename: 'YotpoIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_YotpoVizIntegration_Fragment = { __typename: 'YotpoVizIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRow_ZaiusIntegration_Fragment = { __typename: 'ZaiusIntegration', id: string, capabilities: Array<Types.IntegrationCapabilityEnum>, integrationType: Types.IntegrationType, name: string };

export type IntegrationRowFragment = IntegrationRow_BluecoreIntegration_Fragment | IntegrationRow_BrazeIntegration_Fragment | IntegrationRow_CommerceCloudIntegration_Fragment | IntegrationRow_CustomStoreIntegration_Fragment | IntegrationRow_EmarsysIntegration_Fragment | IntegrationRow_GoogleIntegration_Fragment | IntegrationRow_GrowaveIntegration_Fragment | IntegrationRow_ImpactIntegration_Fragment | IntegrationRow_KlaviyoIntegration_Fragment | IntegrationRow_LoyaltyLionIntegration_Fragment | IntegrationRow_MandrillIntegration_Fragment | IntegrationRow_OmetriaIntegration_Fragment | IntegrationRow_RefersionIntegration_Fragment | IntegrationRow_SftpIntegration_Fragment | IntegrationRow_SftpPullIntegration_Fragment | IntegrationRow_SalesforceOcapiIntegration_Fragment | IntegrationRow_SendinblueIntegration_Fragment | IntegrationRow_ShopifyIntegration_Fragment | IntegrationRow_SmileIntegration_Fragment | IntegrationRow_TremendousIntegration_Fragment | IntegrationRow_WebhookIntegration_Fragment | IntegrationRow_YotpoIntegration_Fragment | IntegrationRow_YotpoVizIntegration_Fragment | IntegrationRow_ZaiusIntegration_Fragment;

export const IntegrationRowFragmentDoc = gql`
    fragment IntegrationRow on IntegrationTypes {
  ... on Node {
    id
  }
  ... on Integration {
    capabilities
    integrationType
    name
  }
  ... on EcommStore {
    displayName
    keepUnattributedOrders
    creatorWidget
    creatorWidgetPosition
    slug
  }
  ... on RefersionIntegration {
    igUsernameFields
    key
    ttUsernameFields
  }
  ... on CustomStoreIntegration {
    ilpFilterSegment {
      id
    }
    lcRelativeLogoUrl
    productIdRegex
    productSourceIntegration {
      id
    }
    shopUrl
    storeLogoUrl
  }
  ... on CommerceCloudIntegration {
    clientId
    ilpFilterSegment {
      id
    }
    lcRelativeLogoUrl
    organizationId
    productIdRegex
    productUrlTemplate
    shopUrl
    shortCode
    siteId
    storeLogoUrl
    validScopes
  }
  ... on ShopifyIntegration {
    ilpFilterSegment {
      id
    }
    lcRelativeLogoUrl
    storeLogoUrl
  }
  ... on SFTPPullIntegration {
    objectType
  }
  ... on ImpactIntegration {
    accountSid
    accessToken
    campaignId
    linkTextMessage
    campaignRedirectDomain
  }
}
    `;