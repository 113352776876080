import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClaimProgramReviewLockMutationVariables = Types.Exact<{
  programId: Types.Scalars['ID'];
}>;


export type ClaimProgramReviewLockMutation = { __typename: 'Mutations', claimProgramReviewLock?: { __typename: 'ClaimProgramReviewLock', ok?: boolean | null } | null };

export type ClaimChallengeReviewLockMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['ID'];
}>;


export type ClaimChallengeReviewLockMutation = { __typename: 'Mutations', claimChallengeReviewLock?: { __typename: 'ClaimChallengeReviewLock', ok?: boolean | null } | null };


export const ClaimProgramReviewLockDocument = gql`
    mutation ClaimProgramReviewLock($programId: ID!) {
  claimProgramReviewLock(programId: $programId) {
    ok
  }
}
    `;
export type ClaimProgramReviewLockMutationFn = Apollo.MutationFunction<ClaimProgramReviewLockMutation, ClaimProgramReviewLockMutationVariables>;

/**
 * __useClaimProgramReviewLockMutation__
 *
 * To run a mutation, you first call `useClaimProgramReviewLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimProgramReviewLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimProgramReviewLockMutation, { data, loading, error }] = useClaimProgramReviewLockMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useClaimProgramReviewLockMutation(baseOptions?: Apollo.MutationHookOptions<ClaimProgramReviewLockMutation, ClaimProgramReviewLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimProgramReviewLockMutation, ClaimProgramReviewLockMutationVariables>(ClaimProgramReviewLockDocument, options);
      }
export type ClaimProgramReviewLockMutationHookResult = ReturnType<typeof useClaimProgramReviewLockMutation>;
export type ClaimProgramReviewLockMutationResult = Apollo.MutationResult<ClaimProgramReviewLockMutation>;
export type ClaimProgramReviewLockMutationOptions = Apollo.BaseMutationOptions<ClaimProgramReviewLockMutation, ClaimProgramReviewLockMutationVariables>;
export const ClaimChallengeReviewLockDocument = gql`
    mutation ClaimChallengeReviewLock($challengeId: ID!) {
  claimChallengeReviewLock(challengeId: $challengeId) {
    ok
  }
}
    `;
export type ClaimChallengeReviewLockMutationFn = Apollo.MutationFunction<ClaimChallengeReviewLockMutation, ClaimChallengeReviewLockMutationVariables>;

/**
 * __useClaimChallengeReviewLockMutation__
 *
 * To run a mutation, you first call `useClaimChallengeReviewLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimChallengeReviewLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimChallengeReviewLockMutation, { data, loading, error }] = useClaimChallengeReviewLockMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useClaimChallengeReviewLockMutation(baseOptions?: Apollo.MutationHookOptions<ClaimChallengeReviewLockMutation, ClaimChallengeReviewLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimChallengeReviewLockMutation, ClaimChallengeReviewLockMutationVariables>(ClaimChallengeReviewLockDocument, options);
      }
export type ClaimChallengeReviewLockMutationHookResult = ReturnType<typeof useClaimChallengeReviewLockMutation>;
export type ClaimChallengeReviewLockMutationResult = Apollo.MutationResult<ClaimChallengeReviewLockMutation>;
export type ClaimChallengeReviewLockMutationOptions = Apollo.BaseMutationOptions<ClaimChallengeReviewLockMutation, ClaimChallengeReviewLockMutationVariables>;