import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { LabelMenuFragmentDoc } from '../../../queries/operations/label-menu.generated';
export type PostAccountDetailsFragment = { __typename: 'AccountType', id: number, organization: { __typename: 'Organization', activeProducts: Array<Types.AccountProductName>, id: string, labelLimit: { __typename: 'LabelLimit', hasHitLimit: boolean } }, labels?: { __typename: 'PagedLabelType', total: number, results: Array<{ __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string }> } | null };

export const PostAccountDetailsFragmentDoc = gql`
    fragment PostAccountDetails on AccountType {
  id
  ...LabelMenu
  organization {
    activeProducts
    id
    labelLimit {
      hasHitLimit
    }
  }
}
    ${LabelMenuFragmentDoc}`;