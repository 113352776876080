import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardUserInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardUserInfoQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, isFbTester: boolean, account?: { __typename: 'AccountType', id: number, name: string, settings: { __typename: 'AccountSettingsType', id: string, emvCpmUsd: number }, organization: { __typename: 'Organization', id: string, activeProducts: Array<Types.AccountProductName>, isTrialing?: boolean | null, trialDaysRemaining?: number | null, plans: Array<{ __typename: 'AccountPlanType', id: string, stripeProducts?: Array<{ __typename: 'StripeProduct', id: string, name: string }> | null }> }, socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, platformId?: string | null } | { __typename: 'TTSocialAccount', id: string, platformId?: string | null }> | null, segments?: { __typename: 'PagedSegmentType', total: number, results: Array<{ __typename: 'SegmentType', id: number, name: string }> } | null } | null, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, preferences: { __typename: 'UserPreferences', id: string, hideSyncSocialAccountIds: Array<string>, selectedSocialAccountId?: string | null, selectedMentionTypeFilter?: Array<string> | null, selectedPostTypeFilter?: Array<string> | null, selectedPostStatusFilter?: Array<string> | null, selectedChallengesFilter?: Array<string> | null, lastDashboardDateRange: { __typename: 'AbsoluteDateTimeRangeFilter', rangeType: Types.DateRangeFilterType, gte: Date, lt?: Date | null } | { __typename: 'RelativeDateRangeFilter', rangeType: Types.DateRangeFilterType, unit: Types.DateRangeFilterUnits, value: number, offset?: number | null } } } | null };


export const DashboardUserInfoDocument = gql`
    query DashboardUserInfo {
  whoami {
    id
    email
    isFbTester
    account {
      id
      name
      settings {
        id
        emvCpmUsd
      }
      organization {
        id
        activeProducts
        isTrialing
        trialDaysRemaining
        plans {
          id
          stripeProducts {
            id
            name
          }
        }
      }
      socialAccounts {
        id
        platformId
      }
      segments(limit: 200, sortBy: RECENTLY_USED) {
        total
        results {
          id
          name
        }
      }
    }
    roles {
      id
      name
    }
    preferences @client {
      id
      hideSyncSocialAccountIds
      lastDashboardDateRange {
        ... on DateRangeFilterBase {
          rangeType
        }
        ... on RelativeDateRangeFilter {
          unit
          value
          offset
        }
        ... on AbsoluteDateTimeRangeFilter {
          gte
          lt
        }
      }
      selectedSocialAccountId
      selectedMentionTypeFilter
      selectedPostTypeFilter
      selectedPostStatusFilter
      selectedChallengesFilter
    }
  }
}
    `;

/**
 * __useDashboardUserInfoQuery__
 *
 * To run a query within a React component, call `useDashboardUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<DashboardUserInfoQuery, DashboardUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardUserInfoQuery, DashboardUserInfoQueryVariables>(DashboardUserInfoDocument, options);
      }
export function useDashboardUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardUserInfoQuery, DashboardUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardUserInfoQuery, DashboardUserInfoQueryVariables>(DashboardUserInfoDocument, options);
        }
export type DashboardUserInfoQueryHookResult = ReturnType<typeof useDashboardUserInfoQuery>;
export type DashboardUserInfoLazyQueryHookResult = ReturnType<typeof useDashboardUserInfoLazyQuery>;
export type DashboardUserInfoQueryResult = Apollo.QueryResult<DashboardUserInfoQuery, DashboardUserInfoQueryVariables>;