import React, { useEffect } from 'react'
import { Container, ContainerProps } from '@material-ui/core'

function Page({ children }: { children: ContainerProps['children'] }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Container>{children}</Container>
}

export default Page
