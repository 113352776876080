import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignRewardsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  periodDateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
  previousPeriodDateRange?: Types.InputMaybe<Types.DateTimeFilterInput>;
}>;


export type CampaignRewardsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, startAt: Date, program?: { __typename: 'ProgramType', id: string, tiers?: Array<{ __typename: 'ProgramTierType', id: string, triggerType?: Types.ProgramTierTriggerType | null, triggerQuantity?: number | null, reward?: { __typename: 'RewardType', id: number, name?: string | null, hasDiscountCode?: boolean | null, statsAllTime: { __typename: 'RewardStats', sentTotal: number, codesRemaining?: number | null }, statsThisPeriod: { __typename: 'RewardStats', sentTotal: number }, statsPreviousPeriod: { __typename: 'RewardStats', sentTotal: number } } | null }> | null } | null } | null };


export const CampaignRewardsDocument = gql`
    query CampaignRewards($campaignId: ID!, $periodDateRange: DateTimeFilterInput, $previousPeriodDateRange: DateTimeFilterInput) {
  campaign(id: $campaignId) {
    id
    startAt
    program {
      id
      tiers {
        id
        triggerType
        triggerQuantity
        reward {
          id
          name
          statsAllTime: stats {
            sentTotal
            codesRemaining
          }
          statsThisPeriod: stats(where: {rewardedAt: $periodDateRange}) {
            sentTotal
          }
          statsPreviousPeriod: stats(where: {rewardedAt: $previousPeriodDateRange}) {
            sentTotal
          }
          hasDiscountCode
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignRewardsQuery__
 *
 * To run a query within a React component, call `useCampaignRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignRewardsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      periodDateRange: // value for 'periodDateRange'
 *      previousPeriodDateRange: // value for 'previousPeriodDateRange'
 *   },
 * });
 */
export function useCampaignRewardsQuery(baseOptions: Apollo.QueryHookOptions<CampaignRewardsQuery, CampaignRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignRewardsQuery, CampaignRewardsQueryVariables>(CampaignRewardsDocument, options);
      }
export function useCampaignRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignRewardsQuery, CampaignRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignRewardsQuery, CampaignRewardsQueryVariables>(CampaignRewardsDocument, options);
        }
export type CampaignRewardsQueryHookResult = ReturnType<typeof useCampaignRewardsQuery>;
export type CampaignRewardsLazyQueryHookResult = ReturnType<typeof useCampaignRewardsLazyQuery>;
export type CampaignRewardsQueryResult = Apollo.QueryResult<CampaignRewardsQuery, CampaignRewardsQueryVariables>;