import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthFbBusinessMutationVariables = Types.Exact<{
  code?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AuthFbBusinessMutation = { __typename: 'Mutations', authFbBusiness?: { __typename: 'AuthFBBusiness', ok: boolean, fbUser?: { __typename: 'FBUser', id: string, socialAccounts?: Array<{ __typename: 'IGSocialAccount', expiresAt?: Date | null, id: string, lastPull?: Date | null, platformId?: string | null }> | null } | null } | null };


export const AuthFbBusinessDocument = gql`
    mutation AuthFBBusiness($code: String, $token: String) {
  authFbBusiness(addSocialAccounts: true, code: $code, token: $token) {
    fbUser {
      id
      socialAccounts {
        expiresAt
        id
        lastPull
        platformId
      }
    }
    ok
  }
}
    `;
export type AuthFbBusinessMutationFn = Apollo.MutationFunction<AuthFbBusinessMutation, AuthFbBusinessMutationVariables>;

/**
 * __useAuthFbBusinessMutation__
 *
 * To run a mutation, you first call `useAuthFbBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthFbBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authFbBusinessMutation, { data, loading, error }] = useAuthFbBusinessMutation({
 *   variables: {
 *      code: // value for 'code'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAuthFbBusinessMutation(baseOptions?: Apollo.MutationHookOptions<AuthFbBusinessMutation, AuthFbBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthFbBusinessMutation, AuthFbBusinessMutationVariables>(AuthFbBusinessDocument, options);
      }
export type AuthFbBusinessMutationHookResult = ReturnType<typeof useAuthFbBusinessMutation>;
export type AuthFbBusinessMutationResult = Apollo.MutationResult<AuthFbBusinessMutation>;
export type AuthFbBusinessMutationOptions = Apollo.BaseMutationOptions<AuthFbBusinessMutation, AuthFbBusinessMutationVariables>;