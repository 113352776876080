import React from 'react'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import { ReactComponent as RocketLaunchIcon } from '../../icons/rocket-launch.svg'
import { secondary } from '../../loudcrowd-theme'

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 400,
    fontSize: 24,
    color: secondary[700],
    marginBottom: 16,
  },
  subheader: {
    fontWeight: 400,
    fontSize: 20,
    color: secondary[700],
    marginTop: 18,
  },
  text: {
    color: secondary[600],
  },
  containerButton: {
    borderTop: `2px solid ${secondary[400]}`,
    marginRight: 20,
    paddingTop: 16,
    marginTop: 24,
  },
}))

type LaunchSuccessProps = {
  onClose(): void
}

function LaunchScreen({ onClose }: LaunchSuccessProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Box display="flex" height="100%">
      <Box width="50%" display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="left" width={496}>
          <Typography variant="h5" className={classes.header}>
            Congrats, you launched an automated message!
          </Typography>
          <Typography variant="body2" className={classes.text}>
            We are now sending replies to your targeted audience.
          </Typography>
          <Typography variant="h6" className={classes.subheader}>
            What's next
          </Typography>
          <Typography variant="body2" className={classes.text}>
            View your message on the Manage tab. Check the Activity tab to see which customers are getting your replies.
          </Typography>
          <Box display="flex" justifyContent="right" className={classes.containerButton}>
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Box>
      <Box width="50%" display="flex" justifyContent="center" alignItems="center">
        <RocketLaunchIcon width={236} height={252} />
      </Box>
    </Box>
  )
}

export default LaunchScreen
