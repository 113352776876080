import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    textAlign: 'left',
    marginTop: 25,
    paddingBottom: 0,
  },
  dialogContent: {
    textAlign: 'left',
  },
  errorButton: {
    color: theme.palette.error.main,
  },
  actionsContainer: {
    marginBottom: 25,
  },
}))

interface DeleteNoteProps {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
}

function ConfirmDeleteNoteDialog({ open, onCancel, onSubmit }: DeleteNoteProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onCancel} PaperProps={{ style: { height: 230, width: 420 } }}>
      <DialogTitle className={classes.dialogTitle} style={{}}>
        Deleting Note
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">Are you sure you want to delete this? This cannot be undone!</Typography>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={onCancel}>No, don't delete</Button>
        <Button className={classes.errorButton} onClick={onSubmit}>
          Yes, delete note
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteNoteDialog
