import React, { FC } from 'react'
import clsx from 'clsx'
import { Card, CardActions, CardContent, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { ReactComponent as ChevronUpIcon } from '../../../icons/chevron-up_minor.svg'
import { ReactComponent as SingleHeartIcon } from '../../../icons/single-heart.svg'
import { ReactComponent as MultiHeartIcon } from '../../../icons/multi-heart.svg'
import CircleCheck from '../../../components/CircleCheck'
import { RewardAutomationTypes } from './constants'
import { useProgramActivityBuilder } from './ProgramActivityBuilderProvider'

type OccasionType = {
  id: RewardAutomationTypes
  icon: React.ReactElement
  sample?: string
  title: React.ReactElement
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      fontWeight: 400,
      fontSize: 34,
    },
    card: {
      position: 'relative',
      width: 268,
      minHeight: 275,
      textAlign: 'center',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',

      '&:hover, &.selected': {
        boxShadow: '2px 4px 30px 0px rgba(91, 0, 249, 0.28)',
        cursor: 'pointer',
      },
    },
    cornerLogo: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
    cardTitle: {
      fontWeight: 400,
      fontSize: 20,
      marginTop: 10,
    },
    cardContent: {
      padding: '24px 16px 0',
    },
    cardSubtitle: {
      padding: '15px 10px 0',
      fontSize: 14,
    },
    cardActions: {
      justifyContent: 'center',
      paddingBottom: 10,
    },
    container: {
      minHeight: 500,
    },
  }),
)

const occasions: OccasionType[] = [
  {
    id: RewardAutomationTypes.SINGLE_TIER_MONTHLY,
    icon: <SingleHeartIcon />,
    sample: 'ie. When someone mentions us and posts 5 posts in a month, send reward.',
    title: (
      <>
        Set <u>single-tiered</u> monthly recurring post goals
      </>
    ),
  },
  {
    id: RewardAutomationTypes.MULTI_TIER_MONTHLY,
    icon: <MultiHeartIcon />,
    sample: 'ie. Send tier 1 reward when someone makes 3 posts, send tier 2 reward when someone makes 5 posts, etc.',
    title: (
      <>
        Set <u>multi-tiered</u> monthly recurring post goals
      </>
    ),
  },
]

const OccasionPicker: FC = () => {
  const classes = useStyles()
  const { values, setOccasion } = useProgramActivityBuilder()

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Typography variant="h6" className={classes.header}>
          What’s the occasion?
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={4} className={classes.container}>
          {occasions.map(occasion => (
            <Grid item key={occasion.id} onClick={() => setOccasion(occasion.id)}>
              <Card className={clsx(classes.card, values.occasion === occasion.id && 'selected')}>
                <CardContent className={classes.cardContent}>
                  {values.occasion === occasion.id && <CircleCheck className={classes.cornerLogo} />}
                  {occasion.icon}
                  <Typography className={classes.cardTitle}>{occasion.title}</Typography>
                  <Typography className={classes.cardSubtitle}>{occasion.sample}</Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <ChevronUpIcon width={18} height={18} />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OccasionPicker
