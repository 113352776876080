import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddStoryMentionMutationVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  igMediaId: Types.Scalars['ID'];
}>;


export type AddStoryMentionMutation = { __typename: 'Mutations', addStoryMention?: { __typename: 'AddStoryMention', ok: boolean, igMention?: { __typename: 'IGMention', id: string } | null } | null };


export const AddStoryMentionDocument = gql`
    mutation AddStoryMention($socialAccountId: ID!, $igMediaId: ID!) {
  addStoryMention(socialAccountId: $socialAccountId, igMediaId: $igMediaId) {
    ok
    igMention {
      id
    }
  }
}
    `;
export type AddStoryMentionMutationFn = Apollo.MutationFunction<AddStoryMentionMutation, AddStoryMentionMutationVariables>;

/**
 * __useAddStoryMentionMutation__
 *
 * To run a mutation, you first call `useAddStoryMentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoryMentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoryMentionMutation, { data, loading, error }] = useAddStoryMentionMutation({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      igMediaId: // value for 'igMediaId'
 *   },
 * });
 */
export function useAddStoryMentionMutation(baseOptions?: Apollo.MutationHookOptions<AddStoryMentionMutation, AddStoryMentionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoryMentionMutation, AddStoryMentionMutationVariables>(AddStoryMentionDocument, options);
      }
export type AddStoryMentionMutationHookResult = ReturnType<typeof useAddStoryMentionMutation>;
export type AddStoryMentionMutationResult = Apollo.MutationResult<AddStoryMentionMutation>;
export type AddStoryMentionMutationOptions = Apollo.BaseMutationOptions<AddStoryMentionMutation, AddStoryMentionMutationVariables>;