import { DateTimeRangeFilter, DateRangeFilterType, DateRangeFilterUnits } from '../../gql-global'

export const BAKED_FILTERS: readonly DateTimeRangeFilter[] = [
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 1,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 1,
    offset: 1,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 7,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 3,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 6,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 12,
  },
] as const

export const CUSTOM_BAKED_FILTERS: readonly DateTimeRangeFilter[] = [
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Years,
    value: 21,
    isExclude: true,
  },
] as const

export const BAKED_FILTERS_WITH_HOURS: readonly DateTimeRangeFilter[] = [
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 1,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 1,
    offset: 1,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Hours,
    value: 24,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 7,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 3,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 6,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 12,
  },
]

export const INACTIVE_SINCE_FILTERS: readonly DateTimeRangeFilter[] = [
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 1,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 1,
    offset: 1,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 7,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 3,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 6,
  },
  {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Months,
    value: 12,
  },
] as const
