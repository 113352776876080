import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import ActivityListItemContent from './ActivityListItemContent'
import { ReactComponent as CommissionIcon } from '../icons/commission.svg'

function SetCommissionTierActivityListItem(props: {
  activity: ConcreteEventType<'SetCommissionTierCustomerEvent'>
}): React.ReactElement {
  const activity = props.activity

  return (
    <ActivityListItemContent
      media={
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent={'center'}
          m={4}
          p={2}
          width={48}
          height={48}
          borderRadius={24}
          bgcolor="success.light"
          color="success.main"
        >
          <CommissionIcon width={25} height={25} />
        </Box>
      }
      text={
        <Typography variant="subtitle1" display="inline">
          {`Commission tier changed - ${activity.oldCommissionTier.name} to ${activity.newCommissionTier.name}`}
        </Typography>
      }
      eventAt={activity.eventAt}
      actorName={activity.actor?.email || undefined}
    />
  )
}

export default SetCommissionTierActivityListItem
