import React, { useState } from 'react'
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import PhoneNumberInput from '../PhoneInput/PhoneInput'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 300,
    },
  }),
)

interface EditDialogProps {
  title: string
  type: 'Email' | 'Phone' | 'TikTok'
  open: boolean
  required?: boolean
  val?: string | null
  onClose: () => void
  onSave: (val: string) => void
}

const EditDialog: React.FC<EditDialogProps> = ({ title, type, val, onClose, onSave, open, required }) => {
  const classes = useStyles()
  const [newVal, setNewVal] = useState(val || '')
  const validate = () => {
    let isValid = false

    switch (type) {
      case 'Email': {
        isValid = /\S+@\S+\.\S+/.test(newVal)
        break
      }
      case 'Phone': {
        isValid = !!newVal && isPossiblePhoneNumber(newVal)
        break
      }
      case 'TikTok': {
        isValid = /^(?!.*\.$)@?[\w.]{2,24}$/.test(newVal)
        break
      }
    }
    return !required ? (val && !newVal) || isValid : isValid
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'Edit ' + title}</DialogTitle>
      <DialogContent style={{ paddingTop: 5 }}>
        {type === 'Phone' ? (
          <PhoneNumberInput value={newVal} onChange={value => setNewVal(value)} />
        ) : (
          <TextField
            label={type}
            value={newVal}
            onChange={({ target: { value } }) => setNewVal(value)}
            className={classes.formControl}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" color="primary" onClick={() => onSave(newVal)} disabled={!validate()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialog
