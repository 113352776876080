import React, { useState, useEffect } from 'react'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import {
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Divider,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.primary.main,
      margin: '10px 0',
    },
    placeholder: {
      display: 'none',
    },
    select: {
      '& .MuiSelect-select': {
        color: theme.palette.secondary.main,
        opacity: 0.65,
      },
    },
    selected: {
      '& .MuiInput-input': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    menu: {
      '& .MuiMenu-paper': {
        paddingBottom: 10,
      },
    },
    search: {
      backgroundColor: 'transparent',
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
    },
  }),
)

export interface SelectFieldOption {
  id: number | string
  label?: string
  icon?: React.ReactNode
  disabled?: boolean
}

interface SelectEntityProps {
  label?: string
  menuLabel?: string
  placeholder?: string
  searchPlaceholder?: string
  options: Array<SelectFieldOption>
  value?: number | string | null
  errors?: string | null
  setValue: (value: number | string) => void
  hideSearch?: boolean
  disabled?: boolean
  name?: string
}

export const SelectField: React.FC<SelectEntityProps> = props => {
  const classes = useStyles()
  const { label, menuLabel, placeholder, searchPlaceholder, options, value, hideSearch, errors, setValue, name } = props

  const [selected, setSelected] = useState<string | number | undefined | null>(!!value ? value : placeholder)
  const [search, setSearch] = useState<string>('')

  const selectClass = selected === placeholder ? classes.select : classes.selected

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.value && (typeof event.target.value === 'number' || typeof event.target.value === 'string')) {
      setSelected(event.target.value)
      setValue(event.target.value)
    }
  }

  useEffect(() => setSelected(!!value ? value : placeholder), [value, placeholder])

  return (
    <FormControl fullWidth>
      {!!label && <Typography className={classes.label}>{label}</Typography>}
      <Select
        role="selection"
        disabled={props.disabled}
        value={selected ?? ''}
        className={selectClass}
        MenuProps={{
          className: classes.menu,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        style={{
          maxWidth: 300,
        }}
        onChange={handleChange}
        name={name}
      >
        <MenuItem value={placeholder} className={classes.placeholder}>
          {placeholder}
        </MenuItem>

        {menuLabel && (
          <Box pb={2} fontWeight={600} textAlign="center">
            {menuLabel}
          </Box>
        )}
        {menuLabel && <Divider />}

        {!hideSearch && (
          <MenuItem onKeyDown={event => event.stopPropagation()} className={classes.search}>
            <OutlinedInput
              autoFocus
              fullWidth
              value={search}
              placeholder={searchPlaceholder}
              onClick={event => event.stopPropagation()}
              onChange={event => setSearch(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon width={16} height={16} />
                </InputAdornment>
              }
            />
          </MenuItem>
        )}

        {options
          .filter(option => option.label?.toLowerCase()?.includes(search.toLowerCase()))
          .map(option => (
            <MenuItem key={option.id} value={option.id} disabled={option?.disabled}>
              {option?.icon}
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {!!errors && <div className={classes.error}>{errors}</div>}
    </FormControl>
  )
}
