import React, { PropsWithChildren, useState } from 'react'
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core'

import CircleCheck from '../CircleCheck'
import CircleCross from '../CircleCross'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      borderColor: theme.palette.primary.dark,
      borderRadius: '4px',
      width: '225px',
      height: '161px',
      background: 'white',
    },
    disabledBorder: {
      borderColor: theme.palette.primary.dark,
      borderRadius: '4px',
      width: '225px',
      height: '161px',
      background: 'white',
      opacity: 0.25,
    },
    label: {
      marginTop: '12px',
      textAlign: 'center',
    },
    cornerLogo: {
      margin: 'auto',
      float: 'right',
      marginTop: '8px',
      marginRight: '8px',
    },
  }),
)

interface ClickableCardFieldProps {
  showCheck: boolean
  onCardClicked: () => void
  onReset?: () => void
  disabled?: boolean
  allowClear?: boolean
}

function ClickableCardField({
  showCheck = false,
  children,
  onCardClicked,
  onReset,
  allowClear = true,
  disabled = false,
}: PropsWithChildren<ClickableCardFieldProps>): React.ReactElement {
  const classes = useStyles()

  const [showX, setShowX] = useState<boolean>(false)

  function handleReset() {
    if (onReset && !disabled) {
      onReset()
      setShowX(false)
    }
  }

  function handleClicked() {
    if (!showX && !disabled) {
      onCardClicked()
    }
  }

  function handleMouseEnter() {
    if (!disabled && allowClear) {
      setShowX(true)
    }
  }

  function handleMouseLeave() {
    setShowX(false)
  }

  return (
    <Box className={!disabled ? classes.border : classes.disabledBorder} border={2} onClick={handleClicked}>
      {showCheck && (
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {onReset && showX && (
            <Box onClick={handleReset}>
              <CircleCross className={classes.cornerLogo} />
            </Box>
          )}
          {(!onReset || !showX) && <CircleCheck className={classes.cornerLogo} />}
        </Box>
      )}
      <Box className={classes.label}>{children}</Box>
    </Box>
  )
}

export default ClickableCardField
