import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateParticipantMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  participant: Types.CreateParticipantInput;
}>;


export type CreateParticipantMutation = { __typename: 'Mutations', createParticipant?: { __typename: 'CreateParticipant', ok?: boolean | null, participant?: { __typename: 'ProgramParticipantType', id: number, customer: { __typename: 'CustomerType', id: number, email?: string | null, igUser?: { __typename: 'IGUser', id: string, username: string } | null, ttUser?: { __typename: 'TTUser', id: string, username: string } | null } } | null } | null };


export const CreateParticipantDocument = gql`
    mutation CreateParticipant($campaignId: ID!, $participant: CreateParticipantInput!) {
  createParticipant(campaignId: $campaignId, participant: $participant) {
    ok
    participant {
      id
      customer {
        id
        email
        igUser {
          id
          username
        }
        ttUser {
          id
          username
        }
      }
    }
  }
}
    `;
export type CreateParticipantMutationFn = Apollo.MutationFunction<CreateParticipantMutation, CreateParticipantMutationVariables>;

/**
 * __useCreateParticipantMutation__
 *
 * To run a mutation, you first call `useCreateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantMutation, { data, loading, error }] = useCreateParticipantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      participant: // value for 'participant'
 *   },
 * });
 */
export function useCreateParticipantMutation(baseOptions?: Apollo.MutationHookOptions<CreateParticipantMutation, CreateParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParticipantMutation, CreateParticipantMutationVariables>(CreateParticipantDocument, options);
      }
export type CreateParticipantMutationHookResult = ReturnType<typeof useCreateParticipantMutation>;
export type CreateParticipantMutationResult = Apollo.MutationResult<CreateParticipantMutation>;
export type CreateParticipantMutationOptions = Apollo.BaseMutationOptions<CreateParticipantMutation, CreateParticipantMutationVariables>;