import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Box, Menu, MenuItem, Divider, Button, ButtonBase, Typography } from '@material-ui/core'
import { ReactComponent as SelectIcon } from '../../icons/select_minor.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { primary } from '../../loudcrowd-theme'
import { useUpdateSelectedSocialAccountIdMutation } from './operations/update-last-social-account-id.generated'
import { useSocialAccountPickerQuery } from './operations/account-picker.generated'
import { INTEGRATIONS_ROUTE } from '../../integrations/routes'
import { TextField } from '../TextField/TextField'
import { createTypeNamePredicate } from '../../types/utility'
import StoriesAvatarV2 from '../CustomerAvatar/StoriesAvatarV2'

const useStyles = makeStyles({
  menuPaper: {
    width: 272,
  },
  menuItem: {
    height: 40,
  },
  menuItemLogo: {
    marginRight: 10,
  },
  avatarBox: {
    marginRight: 12,
  },
  newAccountButton: {
    width: '100%',
    height: 40,
    textAlign: 'left',
    justifyContent: 'flex-start',
    paddingLeft: 24,
  },
  newAccountMenuItemIcon: {
    marginRight: 8,
  },
  pickerButton: {
    width: '100%',
    justifyContent: 'space-between',
    height: 80,
  },
  menuSearchBox: {
    width: '90%',
  },
})

export enum SocialAccountPickerSortTypes {
  Followers = 'FOLLOWERS',
  Alpha = 'ALPHA',
}
interface NewAccountButtonProps {
  classString: string
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
}

const NewAccountButton: React.FC<NewAccountButtonProps> = ({ classString, setAnchorEl }) => {
  return (
    <Box mt={2}>
      <Button
        onClick={() => {
          setAnchorEl(null)
        }}
        className={classString}
        color="primary"
        startIcon={<PlusIcon fill="currentColor" height="12.5px" width="12.5px" />}
        component={Link}
        to={INTEGRATIONS_ROUTE.path}
      >
        New Account
      </Button>
    </Box>
  )
}

const isIGSocialAccount = createTypeNamePredicate('IGSocialAccount')
const isTTSocialAccount = createTypeNamePredicate('TTSocialAccount')

const SocialAccountPicker: React.FC = () => {
  const classes = useStyles()
  const [sort, setSort] = useState<SocialAccountPickerSortTypes>(SocialAccountPickerSortTypes.Followers)
  const [search, setSearch] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { data, loading } = useSocialAccountPickerQuery()
  const { email: username, roles: rolesObj, account } = data?.whoami || {}
  const products = account?.organization.activeProducts || []
  const roles = rolesObj?.map(r => r.name) || []
  const [setSelectedSocialAccountId] = useUpdateSelectedSocialAccountIdMutation()
  const savedSelectedSocialAccountId = data?.whoami?.preferences.selectedSocialAccountId
  const socialAccounts = data?.socialAccounts
  const igSocialAccounts = socialAccounts && socialAccounts.filter(isIGSocialAccount)
  const ttSocialAccounts = socialAccounts && socialAccounts.filter(isTTSocialAccount)
  let sortedIgFilteredSocialAccounts = igSocialAccounts?.slice()
  let sortedTtFilteredSocialAccounts = ttSocialAccounts?.slice()

  if (sort === SocialAccountPickerSortTypes.Followers) {
    sortedIgFilteredSocialAccounts = sortedIgFilteredSocialAccounts?.sort(
      (a, b) => (b.socialUser?.followerCount || -1) - (a.socialUser?.followerCount || -1),
    )

    sortedTtFilteredSocialAccounts = sortedTtFilteredSocialAccounts?.sort(
      (a, b) => (b.socialUser?.followerCount || -1) - (a.socialUser?.followerCount || -1),
    )
  } else {
    sortedIgFilteredSocialAccounts = sortedIgFilteredSocialAccounts?.sort((a, b) => {
      //sort alphabetically
      if (!a?.socialUser?.username || !b?.socialUser?.username) return -1
      if (a?.socialUser?.username > b?.socialUser?.username) return 1
      else if (b?.socialUser?.username > a?.socialUser?.username) return -1
      else return 0
    })

    sortedTtFilteredSocialAccounts = sortedTtFilteredSocialAccounts?.sort((a, b) => {
      //sort alphabetically
      if (!a?.socialUser?.username || !b?.socialUser?.username) return -1
      if (a?.socialUser?.username > b?.socialUser?.username) return 1
      else if (b?.socialUser?.username > a?.socialUser?.username) return -1
      else return 0
    })
  }

  if (search !== '') {
    sortedIgFilteredSocialAccounts = sortedIgFilteredSocialAccounts?.filter(sa =>
      sa.socialUser?.username.includes(search),
    )

    sortedTtFilteredSocialAccounts = sortedTtFilteredSocialAccounts?.filter(sa =>
      sa.socialUser?.username.includes(search),
    )
  }

  useEffect(() => {
    if (
      !loading &&
      socialAccounts?.length &&
      (!savedSelectedSocialAccountId || !socialAccounts.some(sa => sa.id === savedSelectedSocialAccountId))
    ) {
      const bestSocialAccount = socialAccounts.reduce((best, current) =>
        (current.socialUser?.followerCount || -1) > (best.socialUser?.followerCount || -1) ? current : best,
      )
      if (bestSocialAccount) {
        setSelectedSocialAccountId({ variables: { selectedSocialAccountId: bestSocialAccount.id } })
      }
    }
  }, [socialAccounts, savedSelectedSocialAccountId, setSelectedSocialAccountId, loading])

  const currentSocialAccountId = savedSelectedSocialAccountId || data?.socialAccounts?.[0]?.id || ''
  const currentSocialAccount = socialAccounts?.find(i => i.id === currentSocialAccountId)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (id: string): void => {
    // call mutation for local selectedSocialAccountId that sets localstorage
    setSelectedSocialAccountId({ variables: { selectedSocialAccountId: id || '' } })
    setAnchorEl(null)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  function toggleSort() {
    if (sort === SocialAccountPickerSortTypes.Followers) {
      setSort(SocialAccountPickerSortTypes.Alpha)
    } else {
      setSort(SocialAccountPickerSortTypes.Followers)
    }
  }

  const hasTTAccounts = sortedTtFilteredSocialAccounts && !!sortedTtFilteredSocialAccounts.length
  const hasIGAccounts = sortedIgFilteredSocialAccounts && !!sortedIgFilteredSocialAccounts.length
  const hasTikTok = data?.whoami?.account?.organization?.hasTiktok

  return (
    <>
      <Box mb={5} bgcolor={primary[900]} height={80} color="white">
        <ButtonBase
          className={classes.pickerButton}
          onClick={handleClick}
          disabled={loading}
          data-testid={'social-account-switcher'}
        >
          <Box ml={10} display="flex" alignItems="center" justifyContent="center">
            <Box className={classes.avatarBox}>
              <StoriesAvatarV2
                loading={loading}
                avatarUrl={currentSocialAccount?.socialUser?.avatarUrl}
                alt="LoudCrowd Logo"
                size="xsmall"
              />
            </Box>
            <div>{currentSocialAccount?.socialUser?.username}</div>
          </Box>
          <Box mr={3.5}>
            <SelectIcon fill="currentColor" height="20px" width="20px" />
          </Box>
        </ButtonBase>
      </Box>
      <Menu
        id="customized-menu"
        classes={{
          paper: classes.menuPaper,
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <TextField
              placeholder="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
              className={classes.menuSearchBox}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center" mr={3}>
            <Typography variant="body2">Sort:</Typography>
            <Button variant="text" color="primary" size="small" onClick={() => toggleSort()}>
              {sort === SocialAccountPickerSortTypes.Alpha ? 'Alphabetical' : 'Followers'}
            </Button>
          </Box>
          <>
            <Box ml={4}>
              <Typography variant="subtitle1">Instagram</Typography>
            </Box>
            {hasIGAccounts &&
              sortedIgFilteredSocialAccounts?.map(socialAccount => (
                <MenuItem
                  key={socialAccount.id}
                  className={classes.menuItem}
                  onClick={() => handleMenuItemClick(socialAccount.id)}
                  data-testid={`social-account-${socialAccount.socialUser.username}`}
                >
                  <Box display="flex" alignItems="center" justifyContent="center" fontSize="14px">
                    {socialAccount?.socialUser?.avatarUrl && socialAccount?.socialUser?.username && (
                      <Box className={classes.menuItemLogo}>
                        <StoriesAvatarV2 size="micro" avatarUrl={socialAccount.socialUser.avatarUrl} />
                      </Box>
                    )}
                    {socialAccount.socialUser?.username}
                  </Box>
                </MenuItem>
              ))}
            {!hasIGAccounts && <NewAccountButton classString={classes.newAccountButton} setAnchorEl={setAnchorEl} />}
          </>
          <>
            {hasTikTok && (
              <>
                <Box ml={4} mt={4}>
                  <Typography variant="subtitle1">TikTok</Typography>
                </Box>
                {sortedTtFilteredSocialAccounts?.map(socialAccount => {
                  return (
                    <MenuItem
                      key={socialAccount.id}
                      className={classes.menuItem}
                      onClick={() => handleMenuItemClick(socialAccount.id)}
                    >
                      <Box display="flex" alignItems="center" justifyContent="center" fontSize="14px">
                        {socialAccount?.socialUser?.avatarUrl && socialAccount?.socialUser?.username && (
                          <Box className={classes.menuItemLogo}>
                            <StoriesAvatarV2 size="micro" avatarUrl={socialAccount.socialUser.avatarUrl} />
                          </Box>
                        )}
                        {socialAccount.socialUser?.username}
                      </Box>
                    </MenuItem>
                  )
                })}
                {!hasTTAccounts && (
                  <NewAccountButton classString={classes.newAccountButton} setAnchorEl={setAnchorEl} />
                )}
              </>
            )}
          </>
          {((hasIGAccounts && !hasTikTok) || (hasTikTok && hasTTAccounts && hasIGAccounts)) &&
            INTEGRATIONS_ROUTE.hasAccess(roles, products, username) && (
              <>
                <Divider />
                <NewAccountButton classString={classes.newAccountButton} setAnchorEl={setAnchorEl} />
              </>
            )}
        </Box>
      </Menu>
    </>
  )
}

export default SocialAccountPicker
