import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Button,
  Box,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import AddSegment from './AddSegment'
import { useAccountSegmentsQuery } from './operations/account-segments.generated'
import { useSegmentUserInfoQuery } from './operations/segment-user-info.generated'
import { useCreateSegmentMutation } from './operations/create-segment.generated'
import { useDeleteSegmentMutation } from '../../mutations/operations/delete-segment.generated'
import { useUpdateSegmentMutation } from '../../mutations/operations/update-segment.generated'
import labelsCacheUpdate from '../../mutations/labels-cache-update'
import Page from '../../Page'
import { SegmentSort, SortDirection, SegmentFilterInput } from '../../gql-global'
import { NetworkStatus } from '@apollo/client'
import useFilterParams, { Filters } from './use-filter-params'
import { Skeleton } from '@material-ui/lab'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { encodeFilterParams } from '../../customer/use-filter-params'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import { ReactComponent as TrashCanIcon } from '../../icons/trash_can.svg'
import { ReactComponent as PencilIcon } from '../../icons/edit_pencil.svg'
import { ReactComponent as CheckIcon } from '../../icons/checkmark.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { TextField } from '../../components/TextField/TextField'
import useTitle from '../../utils/use-title'
import { UpsellModalUpdaterContext } from '../../components/UpsellModal/'
import { useToast } from '../../components/Alert/ToastProvider'

const SEGMENT_PAGE_SIZE = 25

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountFormControl: {
      marginRight: theme.spacing(3),
      minWidth: 200,
      borderRadius: '2rem',
    },
    accountSelect: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      overflow: 'hidden',
    },
    accountInput: {
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      color: 'white',
      borderRadius: '2rem',
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    title: {
      flex: 1,
      lineHeight: 2,
    },
    tableHeaderCell: {
      flex: 1,
      color: theme.palette.secondary.main,
    },
    row: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    rowContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    rowCell: {
      lineHeight: 2,
    },
    nameCell: {
      '& button': {
        display: 'none',
        marginLeft: 4,
      },
      '&:hover': {
        '& button': {
          display: 'inline',
        },
      },
    },
    smallButton: {
      borderRadius: 16,
    },
    largeButton: {
      borderRadius: 20,
    },
    deleteButton: {
      marginRight: theme.spacing(4),
    },
    icon: {
      fill: 'white',
    },
    lightText: {
      color: theme.palette.secondary.main,
    },
    plusIcon: {
      transform: 'rotate(45deg)',
    },
    editNameField: {
      marginRight: 4,
    },
  }),
)

interface SegmentType {
  id: number
  name: string
  customerCount?: number | null
}

interface SegmentRowProps {
  canDelete?: boolean
  segment?: SegmentType
  handleDelete?: () => void
  handleRename?: (newName: string) => void
  loading?: boolean
  renaming?: boolean
}

const SegmentRow: React.FC<SegmentRowProps> = ({
  canDelete = false,
  segment,
  loading = false,
  handleDelete,
  handleRename,
  renaming = false,
}) => {
  const classes = useStyles()
  const { name, customerCount } = segment || {}
  const [editingName, setEditingName] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>(name || '')

  const handleNameChange = (): void => {
    setEditingName(false)
    const value = newName.trim()
    if (value === name) {
      setNewName(value)
      return
    }
    if (handleRename) {
      handleRename(value)
    }
  }

  return (
    <Paper className={classes.row}>
      <Box className={classes.rowContainer}>
        {loading && <Skeleton width={250} />}
        {!loading && (
          <Box display="flex">
            {!editingName && (
              <Box width={550} className={`${classes.rowCell} ${classes.nameCell}`} display="flex">
                <Typography variant="subtitle1" color="textPrimary">
                  {name}
                </Typography>
                {!renaming && (
                  <IconButton size="small" color="secondary" onClick={() => setEditingName(true)}>
                    <PencilIcon width={16} height={16} />
                  </IconButton>
                )}
              </Box>
            )}

            {editingName && (
              <Box width={550} display="flex">
                <TextField
                  fullWidth
                  label="Name"
                  type="name"
                  value={newName}
                  onChange={({ target: { value } }) => setNewName(value)}
                  autoFocus
                  className={classes.editNameField}
                />
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setEditingName(false)
                    setNewName(name || '')
                  }}
                >
                  <PlusIcon className={classes.plusIcon} width={16} height={16} />
                </IconButton>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleNameChange()
                  }}
                >
                  <CheckIcon width={16} height={16} />
                </IconButton>
              </Box>
            )}
            {renaming && <CircularProgress size={24} color="primary" />}
          </Box>
        )}
        {!loading ? (
          <Box className={classes.rowCell}>
            <Typography variant="subtitle1" className={classes.lightText}>
              Customers
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {customerCount}
            </Typography>
          </Box>
        ) : (
          <Skeleton width={50} />
        )}
        {!loading ? (
          <Box className={classes.rowCell} display="flex" flexWrap="noWrap">
            {canDelete && (
              <IconButton
                size="small"
                color="primary"
                onClick={(): void => segment && handleDelete && handleDelete()}
                className={classes.deleteButton}
              >
                <TrashCanIcon width={16} height={16} />
              </IconButton>
            )}
            <Link
              variant="subtitle2"
              component={RouterLink}
              to={{
                pathname: CUSTOMER_ROUTE.path,
                search: `?${encodeFilterParams({
                  segments: segment ? { all: [[segment.id.toString()]] } : undefined,
                })}`,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                color="primary"
                endIcon={<ArrowIcon width={12} height={12} transform="rotate(-90)" />}
              >
                View Customers
              </Button>
            </Link>
          </Box>
        ) : (
          <Skeleton width={65} />
        )}
      </Box>
    </Paper>
  )
}

const useWhereFilters = (filters: Filters): SegmentFilterInput => {
  return {
    search: filters.labelKeywords.length > 0 ? { keywords: filters.labelKeywords } : null,
  }
}

const SegmentManagement: React.FC = () => {
  useTitle('Segments - Settings')
  const classes = useStyles()
  const [addOpen, setAddOpen] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [segmentBeingDeleted, setSegmentBeingDeleted] = useState<SegmentType | null>(null)
  const [segmentIdBeingUpdated, setSegmentIdBeingUpdated] = useState<number | null>(null)
  const { showToast } = useToast()
  const setModalOptions = React.useContext(UpsellModalUpdaterContext)

  const { filters } = useFilterParams()
  const whereFilters = useWhereFilters(filters)

  const { loading: userDataLoading, data: userData } = useSegmentUserInfoQuery()

  const roles = userData?.whoami?.roles?.map(r => r.name) || []
  const canDelete = roles.some(r => r === 'ADMIN' || r === 'OWNER') || false

  const { loading: segmentsLoading, error, data, fetchMore, networkStatus } = useAccountSegmentsQuery({
    variables: {
      limit: SEGMENT_PAGE_SIZE,
      sortBy: SegmentSort.Name,
      sortDirection: SortDirection.Asc,
      where: whereFilters,
    },
  })
  const segmentLimit = userData?.whoami?.account?.organization?.segmentLimit

  const [createSegment] = useCreateSegmentMutation({
    update: labelsCacheUpdate,
  })

  const [deleteSegment] = useDeleteSegmentMutation({
    update: labelsCacheUpdate,
  })

  const [updateSegment, { loading: renamingInProgress }] = useUpdateSegmentMutation({
    onCompleted: () => setSegmentIdBeingUpdated(null),
  })

  const loading = userDataLoading || (segmentsLoading && networkStatus !== NetworkStatus.fetchMore)
  if (loading) return <p>Loading</p>
  if (error || (!loading && !data)) return <p>Error: {error && error.message}</p>

  const loadMoreContent = (): void => {
    if (data?.whoami?.account?.segments?.cursor) {
      void fetchMore({
        variables: {
          cursor: data.whoami.account.segments.cursor,
        },
      })
    }
  }
  const handleAdd = (): void => {
    if (segmentLimit?.hasHitLimit) {
      setModalOptions({
        isOpen: true,
        modalProps: {
          context: { reason: 'LIMIT', limit: 'SEGMENTS' },
          onCancel: () => setModalOptions({ isOpen: false }),
        },
      })
    } else {
      setAddOpen(true)
    }
  }

  const handleClose = (): void => {
    setAddOpen(false)
  }

  const handleSave = (name: string): void => {
    createSegment({ variables: { name } })
      .then(() => {
        setAddOpen(false)
      })
      .catch(e => {
        showToast({
          title: 'Error: Creating Segment',
          message: 'Something went wrong when creating this segment, please try again. ' + e,
          severity: 'error',
        })
      })
  }

  const handleDelete = (segment: SegmentType): void => {
    setSegmentBeingDeleted(segment)
    setDeleteConfirmationOpen(true)
  }

  const onDeleteSegmentConfirmed = async (segment: SegmentType | null): Promise<void> => {
    setDeleteConfirmationOpen(false)
    if (segment) {
      await deleteSegment({ variables: { segmentId: `${segment.id}` } })
    }
  }

  const onRenameSegment = (id: number, newName: string): void => {
    setSegmentIdBeingUpdated(id)
    updateSegment({ variables: { id: id.toString(), name: newName } }).catch(e => {
      showToast({
        title: 'Error: Updating Segment',
        message: 'Something went wrong when updating this segment, please try again' + e,
        severity: 'error',
      })
    })
  }

  const renderSkeletons = (): React.ReactElement => {
    const skeletons = []
    for (let i = 0; i < SEGMENT_PAGE_SIZE; i++) {
      skeletons.push(<SegmentRow loading />)
    }
    return <>{skeletons}</>
  }

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" flex={1} mb={4}>
          <Typography variant="h5" className={classes.title}>
            Segment Management
          </Typography>
        </Box>
        <Box display="flex" flex={1} alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="subtitle1" display="inline">
              Segments {segmentLimit?.used.toLocaleString()}
            </Typography>
            <Typography display="inline">
              {' '}
              of {segmentLimit?.isUnlimited ? 'Unlimited' : segmentLimit?.limit?.toLocaleString()}
            </Typography>
          </Box>
          <Box>
            <Tooltip title={segmentLimit?.hasHitLimit ? 'Account has hit segment limit.' : ''} placement="top">
              <span>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={handleAdd}
                  className={classes.largeButton}
                >
                  Add Segment
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box mt={6}>
          {data?.whoami?.account?.segments?.results?.map(segment => (
            <SegmentRow
              canDelete={canDelete}
              segment={segment}
              handleDelete={() => handleDelete(segment)}
              key={segment.id}
              handleRename={(newName: string) => onRenameSegment(segment.id, newName)}
              renaming={renamingInProgress && segmentIdBeingUpdated === segment.id}
            />
          ))}
          {networkStatus === NetworkStatus.fetchMore && renderSkeletons()}
          {!loading && data?.whoami?.account?.segments?.cursor && (
            <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
              <Button variant="outlined" color="primary" size="large" onClick={loadMoreContent}>
                Load more
              </Button>
            </Box>
          )}
        </Box>

        <AddSegment open={addOpen} handleClose={handleClose} handleSave={handleSave} />
      </Box>
      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Delete Segment {segmentBeingDeleted?.name}?</DialogTitle>
        <DialogContent>
          <p>You are about to delete the {segmentBeingDeleted?.name} segment. Are you sure?</p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setDeleteConfirmationOpen(false)
              setSegmentBeingDeleted(null)
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => onDeleteSegmentConfirmed(segmentBeingDeleted)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default SegmentManagement
