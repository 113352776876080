import React, { useState, useRef } from 'react'
import { Chip, Box, IconButton, Menu, MenuItem, createStyles, makeStyles } from '@material-ui/core'
import { ReactComponent as OptionsIcon } from '../../icons/kebab.svg'
import { primary, secondary, success } from '../../loudcrowd-theme'
import { ChallengeStatusEnum } from '../../gql-global'
import { ChallengeFragment } from '../operations/challenge.generated'

const menuBorder = `1px solid ${secondary[400]}`

const useStyles = makeStyles(() =>
  createStyles({
    challengeTop: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    challengeStatus: {
      height: 50,
    },
    iconButton: {
      padding: 4,
    },
    options: {
      width: 27,
      height: 8,
    },
    challengeChip: {
      fontSize: 12,
      height: 24,
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
      padding: '0 12px',
      lineHeight: '120%',
    },
    menuPaper: {
      border: menuBorder,
      minWidth: 170,
    },
    listItem: {
      color: secondary[700],
    },
    challengeInactive: {
      backgroundColor: secondary[100],
      color: secondary[600],
    },
    challengeActive: {
      backgroundColor: success[100],
      color: success[700],
    },
    challengeComplete: {
      backgroundColor: primary[100],
      color: primary[500],
    },
  }),
)

type MapType = {
  [id: string]: string
}

const statusDisplayMap: MapType = {
  DRAFT: 'Draft',
  PAUSED: 'Paused',
  UPCOMING: 'Upcoming',
  LIVE: 'Live',
  COMPLETED: 'Completed',
}

type ClassMapType = {
  [id: string]: string
}

interface ChallengeCardTopProps {
  challenge: ChallengeFragment
  onDelete: (challenge: ChallengeFragment) => void
  onDuplicate: (challenge: ChallengeFragment) => void
  onEdit: (challenge: ChallengeFragment) => void
  onToggle: (challenge: ChallengeFragment) => void
}

function ChallengeCardTop(props: ChallengeCardTopProps): React.ReactElement {
  const classes = useStyles()
  const { challenge, onDelete, onDuplicate, onEdit, onToggle } = props
  const menuRef = useRef<HTMLButtonElement>(null)
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const status = challenge.currentStatus

  const chipClassMap: ClassMapType = {
    DRAFT: classes.challengeInactive,
    PAUSED: classes.challengeInactive,
    UPCOMING: classes.challengeInactive,
    LIVE: classes.challengeActive,
    COMPLETED: classes.challengeComplete,
  }

  function statusDisplay(challenge: ChallengeFragment): string | undefined {
    const display = statusDisplayMap[challenge.currentStatus]
    if (!!challenge.isRecentlyCompleted) {
      return `Recently ${display}`
    }
    return display
  }

  return (
    <Box className={classes.challengeTop}>
      <Chip label={statusDisplay(challenge)} className={[classes.challengeChip, chipClassMap[status]].join(' ')} />
      <IconButton ref={menuRef} onClick={() => setMenuOpen(true)} className={classes.iconButton}>
        <OptionsIcon className={classes.options} />
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={menuRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        onClose={() => setMenuOpen(false)}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuItem
          className={classes.listItem}
          onClick={() => {
            onEdit(challenge)
            setMenuOpen(false)
          }}
        >
          Edit
        </MenuItem>
        {status === ChallengeStatusEnum.Live && (
          <MenuItem
            className={classes.listItem}
            onClick={() => {
              onToggle(challenge)
              setMenuOpen(false)
            }}
          >
            Pause
          </MenuItem>
        )}
        {status === ChallengeStatusEnum.Paused && (
          <MenuItem
            className={classes.listItem}
            onClick={() => {
              onToggle(challenge)
              setMenuOpen(false)
            }}
          >
            Resume
          </MenuItem>
        )}
        <MenuItem
          className={classes.listItem}
          onClick={() => {
            onDuplicate(challenge)
            setMenuOpen(false)
          }}
        >
          Make a Copy
        </MenuItem>
        {(status === ChallengeStatusEnum.Draft || status === ChallengeStatusEnum.Upcoming) && (
          <MenuItem
            className={classes.listItem}
            onClick={() => {
              onDelete(challenge)
              setMenuOpen(false)
            }}
          >
            Delete
          </MenuItem>
        )}

        {/* TODO: pick and view winners */}
      </Menu>
    </Box>
  )
}

export default ChallengeCardTop
