import React from 'react'

interface JoinedChildrenProps {
  joinElement: JSX.Element
  children: React.ReactElement[]
}

function JoinedChildren({ joinElement, children }: JoinedChildrenProps): React.ReactElement {
  return (
    <>
      {children.reduce((a: JSX.Element[], v: JSX.Element, i: number, s: JSX.Element[]) => {
        if (i !== s.length - 1) {
          return [...a, v, React.cloneElement(joinElement, { key: i })]
        }
        return [...a, v]
      }, [])}
    </>
  )
}

export default JoinedChildren
