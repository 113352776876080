import React, { ReactElement, useRef, useState } from 'react'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { Button, createStyles, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core'
import { MentionStatus } from '../../gql-global'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rejectedButton: {
      color: theme.palette.error.main,
    },
    verifiedButton: {
      color: theme.palette.success.main,
    },
    officialReviewButton: {
      color: theme.palette.secondary.main,
    },
  }),
)

interface StatusOptions {
  value: MentionStatus
  name: string
}

const mentionStatusOptions: StatusOptions[] = [
  {
    value: MentionStatus.Unverified,
    name: 'Set Status',
  },
  {
    value: MentionStatus.Verified,
    name: 'Approved',
  },
  {
    value: MentionStatus.Rejected,
    name: 'Rejected',
  },
  {
    value: MentionStatus.OfficialReview,
    name: 'Needs Official Review',
  },
]

const mentionStatusMap: Record<
  MentionStatus,
  { text: string; className?: 'rejectedButton' | 'verifiedButton' | 'officialReviewButton' }
> = {
  [MentionStatus.Rejected]: {
    text: 'Rejected',
    className: 'rejectedButton',
  },
  [MentionStatus.Verified]: {
    text: 'Approved',
    className: 'verifiedButton',
  },
  [MentionStatus.OfficialReview]: {
    text: 'Needs Official Review',
    className: 'officialReviewButton',
  },
  [MentionStatus.Unverified]: {
    text: 'Set Status',
  },
}

interface StatusSelectorPropsType {
  mentionId?: string
  mediaId?: string
  mentionStatus: MentionStatus
  onChangeStatus?(id: string, newStatus: MentionStatus): void
  excludeOfficialReview?: boolean
}

const StatusSelector = (props: StatusSelectorPropsType): ReactElement => {
  const classes = useStyles()
  const { mentionStatus, onChangeStatus, mentionId, mediaId, excludeOfficialReview } = props

  const statusButton = useRef<HTMLButtonElement | null>(null)
  const [statusMenuOpen, setStatusMenuOpen] = useState(false)

  const { className, text } = mentionStatusMap[mentionStatus] || {}
  const tagClassName = className ? classes[className] : undefined

  function handleChangeStatus(status: MentionStatus) {
    if (mentionId && onChangeStatus) {
      onChangeStatus(mentionId, status)
    } else if (mediaId && onChangeStatus) {
      onChangeStatus(mediaId, status)
    }
    setStatusMenuOpen(false)
  }

  const options = excludeOfficialReview
    ? mentionStatusOptions.filter(value => value.value !== MentionStatus.OfficialReview)
    : mentionStatusOptions

  return (
    <>
      <Button
        color="primary"
        ref={statusButton}
        onClick={() => setStatusMenuOpen(true)}
        endIcon={<ChevronDownIcon height={16} width={16} />}
      >
        <Typography variant="subtitle2" className={tagClassName}>
          {text}
        </Typography>
      </Button>
      <Menu
        open={statusMenuOpen}
        anchorEl={statusButton.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setStatusMenuOpen(false)}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === mentionStatus}
            onClick={() => handleChangeStatus(option.value)}
          >
            <Typography variant="subtitle2" color="primary">
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default StatusSelector
