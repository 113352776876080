import React from 'react'
import { CampaignStatsQuery } from './operations/campaign-stats.generated'
import { Link as RouterLink } from 'react-router-dom'
import {
  makeStyles,
  Tooltip,
  Box,
  Link,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { numberFormat } from '../../utils/number-format'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { ReactComponent as EmptyListImage } from '../../images/empty-list.svg'
import ContainerEmptyState from '../../components/ContainerEmptyState/ContainerEmptyState'
import { Skeleton } from '@material-ui/lab'
import { format, formatDistanceToNow } from 'date-fns'
import SeeAllLink from '../../components/SeeAllLink'
import { CAMPAIGN_ROUTE } from '../routes'
import { encodeSortParam } from './use-sort-param'
import { FbUser, ParticipantSort } from '../../gql-global'
import StoriesAvatarV2 from '../../components/CustomerAvatar/StoriesAvatarV2'

const useStyles = makeStyles({
  underline: {
    textDecoration: 'underline',
  },
})

type Signups = NonNullable<NonNullable<CampaignStatsQuery['campaign']>['participants']>['results']
interface RecentSignupsWidgetProps {
  signups?: Signups
  loading: boolean
  error: boolean
  rows: number
  campaignId: string
  isIGSocialAccount?: boolean
}

interface SignupRowProps {
  loading?: boolean
  signup?: Signups[number]
  isIGSocialAccount?: boolean
}

function SignupRow({ loading = false, signup, isIGSocialAccount = true }: SignupRowProps): React.ReactElement {
  const socialAccounts = signup?.customer?.igUser?.storiesIgSocialAccounts || []
  const fbUsers: FbUser[] = []
  if (socialAccounts.length > 0) {
    socialAccounts.forEach(sa => {
      if (sa.fbUsers && sa.fbUsers.length > 0) {
        fbUsers.push(...sa.fbUsers)
      }
    })
  }

  const socialUser = isIGSocialAccount ? signup?.customer?.igUser : signup?.customer?.ttUser
  const linkProps = { to: `${CUSTOMER_ROUTE.path}/${signup?.customer?.id}`, component: RouterLink }
  const hasActiveStory = !!signup?.customer?.activeStoryMentionsStats?.postCount
  const classes = useStyles()
  return (
    <TableRow>
      <TableCell align="right" style={{ paddingLeft: 32, width: 36 }}>
        <StoriesAvatarV2
          loading={loading}
          hasActiveStory={hasActiveStory}
          avatarUrl={socialUser?.avatarUrl}
          size="small"
          linkProps={linkProps}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {loading ? <Skeleton /> : <Link {...linkProps}>{socialUser?.username}</Link>}
      </TableCell>
      <TableCell>
        {loading && <Skeleton />}
        {!loading &&
          (!!socialUser?.followerCount ? (
            numberFormat(socialUser.followerCount)
          ) : (
            <Box color="secondary.main">processing</Box>
          ))}
      </TableCell>
      <TableCell>
        {loading && <Skeleton />}
        {!loading && signup && (
          <Tooltip title={signup.approvedAt ? format(signup.approvedAt, 'PPp') : ''} placement="top-start">
            <div className={classes.underline}>
              {signup.approvedAt ? formatDistanceToNow(signup.approvedAt, { addSuffix: true }) : ''}
            </div>
          </Tooltip>
        )}
      </TableCell>
      <TableCell />
    </TableRow>
  )
}

function RecentSignupsWidget({
  signups,
  loading,
  error,
  rows,
  campaignId,
  isIGSocialAccount,
}: RecentSignupsWidgetProps): React.ReactElement {
  return (
    <>
      <Box mb={5} fontSize="1.125rem" fontWeight="fontWeightBold" lineHeight="1.5">
        Recent Members
        <SeeAllLink
          to={{
            pathname: `${CAMPAIGN_ROUTE.path}/${campaignId}/members`,
            search: `?${encodeSortParam(ParticipantSort.ApprovedAt)}`,
          }}
          variant="text"
        />
      </Box>
      <TableContainer component={Paper}>
        <Table style={{ tableLayout: 'auto' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Recent Members</TableCell>
              <TableCell>Followers</TableCell>
              <TableCell>Sign-up date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && signups?.map(s => <SignupRow key={s.id} signup={s} isIGSocialAccount={isIGSocialAccount} />)}
            {loading && Array.from({ length: rows }, (_, i) => <SignupRow key={i} loading />)}
            {!loading && error && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <ContainerEmptyState image={EmptyListImage} text="No customers found" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default RecentSignupsWidget
