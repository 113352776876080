import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { AccountRowFragmentDoc } from './account-row.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminUserAccountsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.AccountFilterInput>;
  sortBy?: Types.InputMaybe<Types.AccountSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type AdminUserAccountsQuery = { __typename: 'Query', appStripeProducts?: Array<{ __typename: 'StripeProduct', id: string, name: string, isEnterprise: boolean }> | null, whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number } | null } | null, accounts?: { __typename: 'PagedAccountType', total: number, cursor?: string | null, results: Array<{ __typename: 'AccountType', id: number, name: string, createdAt: Date, organization: { __typename: 'Organization', id: string, stripeCustomerId?: string | null, isTrialing?: boolean | null, trialDaysRemaining?: number | null, userLimit: { __typename: 'UserLimit', used: number, limit?: number | null, isUnlimited: boolean, hasHitLimit: boolean }, plans: Array<{ __typename: 'AccountPlanType', id: string, stripeProducts?: Array<{ __typename: 'StripeProduct', id: string, name: string }> | null }> } }> } | null };


export const AdminUserAccountsDocument = gql`
    query AdminUserAccounts($limit: Int, $cursor: String, $where: AccountFilterInput, $sortBy: AccountSort, $sortDirection: SortDirection) {
  appStripeProducts {
    id
    name
    isEnterprise
  }
  whoami {
    id
    account {
      id
    }
  }
  accounts(
    limit: $limit
    cursor: $cursor
    where: $where
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    total
    cursor
    results {
      ...AccountRow
    }
  }
}
    ${AccountRowFragmentDoc}`;

/**
 * __useAdminUserAccountsQuery__
 *
 * To run a query within a React component, call `useAdminUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAdminUserAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AdminUserAccountsQuery, AdminUserAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserAccountsQuery, AdminUserAccountsQueryVariables>(AdminUserAccountsDocument, options);
      }
export function useAdminUserAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserAccountsQuery, AdminUserAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserAccountsQuery, AdminUserAccountsQueryVariables>(AdminUserAccountsDocument, options);
        }
export type AdminUserAccountsQueryHookResult = ReturnType<typeof useAdminUserAccountsQuery>;
export type AdminUserAccountsLazyQueryHookResult = ReturnType<typeof useAdminUserAccountsLazyQuery>;
export type AdminUserAccountsQueryResult = Apollo.QueryResult<AdminUserAccountsQuery, AdminUserAccountsQueryVariables>;