import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { IgChallengeSaFragmentDoc } from './ig-challenge-sa.generated';
import { TtChallengeSaFragmentDoc } from './tt-challenge-sa.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SocialAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SocialAccountsQuery = { __typename: 'Query', socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, programs?: Array<{ __typename: 'ProgramType', id: string, name: string }> | null, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string }, programs?: Array<{ __typename: 'ProgramType', id: string, name: string }> | null }> | null, whoami?: { __typename: 'UserType', id: number, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null } } | null };


export const SocialAccountsDocument = gql`
    query SocialAccounts {
  socialAccounts {
    ... on IGSocialAccount {
      ...IGChallengeSA
    }
    ... on TTSocialAccount {
      ...TTChallengeSA
    }
  }
  whoami {
    id
    preferences @client {
      id
      selectedSocialAccountId
    }
  }
}
    ${IgChallengeSaFragmentDoc}
${TtChallengeSaFragmentDoc}`;

/**
 * __useSocialAccountsQuery__
 *
 * To run a query within a React component, call `useSocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialAccountsQuery(baseOptions?: Apollo.QueryHookOptions<SocialAccountsQuery, SocialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialAccountsQuery, SocialAccountsQueryVariables>(SocialAccountsDocument, options);
      }
export function useSocialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialAccountsQuery, SocialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialAccountsQuery, SocialAccountsQueryVariables>(SocialAccountsDocument, options);
        }
export type SocialAccountsQueryHookResult = ReturnType<typeof useSocialAccountsQuery>;
export type SocialAccountsLazyQueryHookResult = ReturnType<typeof useSocialAccountsLazyQuery>;
export type SocialAccountsQueryResult = Apollo.QueryResult<SocialAccountsQuery, SocialAccountsQueryVariables>;