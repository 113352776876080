import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeactivateProgramEcommDiscountCodeMutationVariables = Types.Exact<{
  ecommDiscountCodeId: Types.Scalars['ID'];
}>;


export type DeactivateProgramEcommDiscountCodeMutation = { __typename: 'Mutations', deactivateProgramEcommDiscountCode?: { __typename: 'DeactivateProgramEcommDiscountCode', ok: boolean, ecommDiscountCode?: { __typename: 'EcommDiscountCodeType', id: string } | null } | null };


export const DeactivateProgramEcommDiscountCodeDocument = gql`
    mutation DeactivateProgramEcommDiscountCode($ecommDiscountCodeId: ID!) {
  deactivateProgramEcommDiscountCode(ecommDiscountCodeId: $ecommDiscountCodeId) {
    ecommDiscountCode {
      id
    }
    ok
  }
}
    `;
export type DeactivateProgramEcommDiscountCodeMutationFn = Apollo.MutationFunction<DeactivateProgramEcommDiscountCodeMutation, DeactivateProgramEcommDiscountCodeMutationVariables>;

/**
 * __useDeactivateProgramEcommDiscountCodeMutation__
 *
 * To run a mutation, you first call `useDeactivateProgramEcommDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateProgramEcommDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateProgramEcommDiscountCodeMutation, { data, loading, error }] = useDeactivateProgramEcommDiscountCodeMutation({
 *   variables: {
 *      ecommDiscountCodeId: // value for 'ecommDiscountCodeId'
 *   },
 * });
 */
export function useDeactivateProgramEcommDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateProgramEcommDiscountCodeMutation, DeactivateProgramEcommDiscountCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateProgramEcommDiscountCodeMutation, DeactivateProgramEcommDiscountCodeMutationVariables>(DeactivateProgramEcommDiscountCodeDocument, options);
      }
export type DeactivateProgramEcommDiscountCodeMutationHookResult = ReturnType<typeof useDeactivateProgramEcommDiscountCodeMutation>;
export type DeactivateProgramEcommDiscountCodeMutationResult = Apollo.MutationResult<DeactivateProgramEcommDiscountCodeMutation>;
export type DeactivateProgramEcommDiscountCodeMutationOptions = Apollo.BaseMutationOptions<DeactivateProgramEcommDiscountCodeMutation, DeactivateProgramEcommDiscountCodeMutationVariables>;