import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelectedChallengesFilterMutationVariables = Types.Exact<{
  selectedChallengesFilter: Types.ChallengesFilterInput;
}>;


export type UpdateSelectedChallengesFilterMutation = { __typename: 'Mutations', updateSelectedChallengesFilter?: Array<string> | null };


export const UpdateSelectedChallengesFilterDocument = gql`
    mutation UpdateSelectedChallengesFilter($selectedChallengesFilter: ChallengesFilterInput!) {
  updateSelectedChallengesFilter(
    selectedChallengesFilter: $selectedChallengesFilter
  ) @client
}
    `;
export type UpdateSelectedChallengesFilterMutationFn = Apollo.MutationFunction<UpdateSelectedChallengesFilterMutation, UpdateSelectedChallengesFilterMutationVariables>;

/**
 * __useUpdateSelectedChallengesFilterMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedChallengesFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedChallengesFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedChallengesFilterMutation, { data, loading, error }] = useUpdateSelectedChallengesFilterMutation({
 *   variables: {
 *      selectedChallengesFilter: // value for 'selectedChallengesFilter'
 *   },
 * });
 */
export function useUpdateSelectedChallengesFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectedChallengesFilterMutation, UpdateSelectedChallengesFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectedChallengesFilterMutation, UpdateSelectedChallengesFilterMutationVariables>(UpdateSelectedChallengesFilterDocument, options);
      }
export type UpdateSelectedChallengesFilterMutationHookResult = ReturnType<typeof useUpdateSelectedChallengesFilterMutation>;
export type UpdateSelectedChallengesFilterMutationResult = Apollo.MutationResult<UpdateSelectedChallengesFilterMutation>;
export type UpdateSelectedChallengesFilterMutationOptions = Apollo.BaseMutationOptions<UpdateSelectedChallengesFilterMutation, UpdateSelectedChallengesFilterMutationVariables>;