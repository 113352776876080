import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type MentionEventItem_IgMention_Fragment = { __typename: 'IGMention', wasTagged: boolean, wasCaptionMentioned: boolean, wasStoryMentioned: boolean, id: string, isPastLimit: boolean, media: { __typename: 'IGMedia', mediaType?: string | null, postType: Types.IgMediaPostType, isExpired: boolean, fromAuthedApi: boolean, id: string, lcMediaUrl?: string | null, lcThumbnailUrl?: string | null, mediaUrl?: string | null } };

export type MentionEventItem_TtMention_Fragment = { __typename: 'TTMention', id: string, isPastLimit: boolean, media: { __typename: 'TTMedia', id: string, lcMediaUrl?: string | null, lcThumbnailUrl?: string | null, mediaUrl?: string | null } };

export type MentionEventItemFragment = MentionEventItem_IgMention_Fragment | MentionEventItem_TtMention_Fragment;

export const MentionEventItemFragmentDoc = gql`
    fragment MentionEventItem on Mention {
  id
  isPastLimit
  media {
    id
    lcMediaUrl
    lcThumbnailUrl
    mediaUrl
    ... on IGMedia {
      mediaType
      postType
      isExpired
      fromAuthedApi
    }
  }
  ... on IGMention {
    wasTagged
    wasCaptionMentioned
    wasStoryMentioned
  }
}
    `;