import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSegmentMutationVariables = Types.Exact<{
  segmentId: Types.Scalars['ID'];
  customerIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  socialAccountId?: Types.InputMaybe<Types.Scalars['ID']>;
  where?: Types.InputMaybe<Types.CustomerFilterInput>;
  limit: Types.Scalars['Int'];
  sortBy?: Types.InputMaybe<Types.CustomerSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type AddSegmentMutation = { __typename: 'Mutations', addCustomerSegment?: { __typename: 'AddCustomerSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null } | null };


export const AddSegmentDocument = gql`
    mutation AddSegment($segmentId: ID!, $customerIds: [ID!], $socialAccountId: ID, $where: CustomerFilterInput, $limit: Int!, $sortBy: CustomerSort, $sortDirection: SortDirection) {
  addCustomerSegment(
    customerIds: $customerIds
    segmentId: $segmentId
    socialAccountId: $socialAccountId
    where: $where
    limit: $limit
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    ok
    segment {
      id
      name
      account {
        id
      }
    }
  }
}
    `;
export type AddSegmentMutationFn = Apollo.MutationFunction<AddSegmentMutation, AddSegmentMutationVariables>;

/**
 * __useAddSegmentMutation__
 *
 * To run a mutation, you first call `useAddSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSegmentMutation, { data, loading, error }] = useAddSegmentMutation({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *      customerIds: // value for 'customerIds'
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAddSegmentMutation(baseOptions?: Apollo.MutationHookOptions<AddSegmentMutation, AddSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSegmentMutation, AddSegmentMutationVariables>(AddSegmentDocument, options);
      }
export type AddSegmentMutationHookResult = ReturnType<typeof useAddSegmentMutation>;
export type AddSegmentMutationResult = Apollo.MutationResult<AddSegmentMutation>;
export type AddSegmentMutationOptions = Apollo.BaseMutationOptions<AddSegmentMutation, AddSegmentMutationVariables>;