import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Switch, Route } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { QueryParamProvider } from 'use-query-params'

import { AUTH_ROUTE } from './auth/routes'
import Auth from './auth/Auth'
import Auth0ProviderWithHistory from './auth/Auth0Auth'
import theme from './loudcrowd-theme'
import Container from './Container'
import AppErrorBoundary from './AppErrorBoundary'
import { SIGNUP_ROUTE } from './signup/routes'
import SignupContainer from './signup/SignupContainer'
import { IntercomProvider } from 'react-use-intercom'
import { Toast, ToastProvider } from './components/Alert'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_KEY || ''

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <QueryParamProvider ReactRouterRoute={Route}>
                <AppErrorBoundary>
                  <ToastProvider>
                    <Toast />
                    <Switch>
                      <Route path={AUTH_ROUTE.path} component={Auth} />
                      <Route path={SIGNUP_ROUTE.path} component={SignupContainer} />
                      <Route component={Container} />
                    </Switch>
                  </ToastProvider>
                </AppErrorBoundary>
              </QueryParamProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </IntercomProvider>
    </MuiThemeProvider>
  )
}

export default App
