import React, { FC } from 'react'
import { Box, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import RewardCard from '../RewardCard'
import useProgramActivityRewards from './useProgramActivityRewards'
import { useProgramActivityBuilder } from './ProgramActivityBuilderProvider'

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontWeight: 400,
      fontSize: 34,
    },
    containerGrid: {
      maxHeight: 460,
      overflowY: 'auto',
    },
  }),
)

const RewardPicker: FC = () => {
  const classes = useStyles()
  const { values, setSingleReward } = useProgramActivityBuilder()
  const { rewards, loading, loadingMore, error, cursor, handleLoadMore } = useProgramActivityRewards()

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Typography variant="h6" className={classes.header}>
          Which reward do you want to send?
        </Typography>
      </Grid>
      <Grid item>
        {loading ? (
          <Grid container direction="row" spacing={4}>
            {Array.from({ length: 8 }, (_, index) => (
              <Grid item sm={12} md={6} key={index}>
                <RewardCard loading onClick={() => false} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container direction="row" spacing={4} className={classes.containerGrid}>
            {error || (!loading && !rewards) ? () => <p>Error: {error && error.message}</p> : null}
            {rewards?.map(reward => (
              <Grid key={reward.id} item sm={12} md={6}>
                <RewardCard
                  reward={reward}
                  selected={values.reward?.id === reward.id}
                  onClick={() => setSingleReward(reward)}
                />
              </Grid>
            ))}

            {!error && cursor && (
              <Box display="flex" flexDirection="row" justifyContent="center" mt={8} width="100%">
                <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore} disabled={loadingMore}>
                  Load more
                </Button>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default RewardPicker
