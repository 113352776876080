import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportCustomersQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  where: Types.CustomerFilterInput;
  mentionsWhere: Types.CustomerMentionStatsFilters;
  limit: Types.Scalars['Int'];
  sortBy: Types.CustomerSort;
  sortDirection: Types.SortDirection;
  fields?: Types.InputMaybe<Array<Types.CustomerExportFieldEnum> | Types.CustomerExportFieldEnum>;
}>;


export type ExportCustomersQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, customers?: { __typename: 'PagedCustomerType', csvUrl?: string | null } | null } | { __typename: 'TTSocialAccount', id: string, customers?: { __typename: 'PagedCustomerType', csvUrl?: string | null } | null } | null };


export const ExportCustomersDocument = gql`
    query ExportCustomers($socialAccountId: ID!, $where: CustomerFilterInput!, $mentionsWhere: CustomerMentionStatsFilters!, $limit: Int!, $sortBy: CustomerSort!, $sortDirection: SortDirection!, $fields: [CustomerExportFieldEnum!]) {
  socialAccount(id: $socialAccountId) {
    id
    customers(
      sortBy: $sortBy
      sortDirection: $sortDirection
      limit: $limit
      where: $where
    ) {
      csvUrl(mentionsWhere: $mentionsWhere, fields: $fields)
    }
  }
}
    `;

/**
 * __useExportCustomersQuery__
 *
 * To run a query within a React component, call `useExportCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCustomersQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useExportCustomersQuery(baseOptions: Apollo.QueryHookOptions<ExportCustomersQuery, ExportCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCustomersQuery, ExportCustomersQueryVariables>(ExportCustomersDocument, options);
      }
export function useExportCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCustomersQuery, ExportCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCustomersQuery, ExportCustomersQueryVariables>(ExportCustomersDocument, options);
        }
export type ExportCustomersQueryHookResult = ReturnType<typeof useExportCustomersQuery>;
export type ExportCustomersLazyQueryHookResult = ReturnType<typeof useExportCustomersLazyQuery>;
export type ExportCustomersQueryResult = Apollo.QueryResult<ExportCustomersQuery, ExportCustomersQueryVariables>;