import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type ChallengeReviewFragment = { __typename: 'ChallengeType', id: string, name: string, startedAt?: Date | null, endedAt?: Date | null, postTypes?: Array<Types.IgMediaPostType> | null, hashtags?: Array<string> | null, keywords?: Array<string> | null, currentStatus: Types.ChallengeStatusEnum, isRecentlyCompleted?: boolean | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }>, reviewLock?: { __typename: 'ReviewLockType', id: string, updatedAt?: Date | null, user?: { __typename: 'UserType', id: number, email: string } | null } | null, programs?: Array<{ __typename: 'ProgramType', id: string, campaign?: { __typename: 'CampaignType', id: number } | null }> | null, additionalCriteria?: { __typename: 'AdditionalCriteriaType', criteriaDescription?: string | null, storyCriteriaDescription?: string | null, shouldMeetProgramRules?: boolean | null } | null, challengeRewards?: Array<{ __typename: 'ChallengeRewardType', id: string, winningCriteria: Types.ChallengeRewardCriteriaEnum, reward?: { __typename: 'RewardType', id: number } | null }> | null, paintedDoor?: { __typename: 'ChallengePaintedDoorType', briefContent?: string | null, automatedMessageContent?: string | null, briefDeliveryMethods?: Array<Types.ChallengeBriefDeliveryMethodEnum> | null, automatedMessageTriggers?: Array<Types.ChallengeMessageTriggerEnum> | null, automatedMessageDeliveryMethods?: Array<Types.ChallengeMessageDeliveryMethodEnum> | null, winningInstructions?: Array<{ __typename: 'ChallengeRewardInstructionType', instructions: string, reward?: { __typename: 'RewardType', id: number } | null }> | null } | null, reviewPendingPosts?: { __typename: 'PagedMentionType', total: number } | null };

export const ChallengeReviewFragmentDoc = gql`
    fragment ChallengeReview on ChallengeType {
  id
  name
  startedAt
  endedAt
  socialAccounts {
    id
    socialUser {
      id
      username
    }
  }
  reviewLock {
    id
    user {
      id
      email
    }
    updatedAt
  }
  programs {
    id
    campaign {
      id
    }
  }
  additionalCriteria {
    criteriaDescription
    storyCriteriaDescription
    shouldMeetProgramRules
  }
  postTypes
  hashtags
  keywords
  currentStatus
  challengeRewards {
    id
    reward {
      id
    }
    winningCriteria
  }
  paintedDoor {
    briefContent
    automatedMessageContent
    briefDeliveryMethods
    automatedMessageTriggers
    automatedMessageDeliveryMethods
    winningInstructions {
      reward {
        id
      }
      instructions
    }
  }
  isRecentlyCompleted
  reviewPendingPosts {
    total
  }
}
    `;