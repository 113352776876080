import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProgramsLtvStatusesMutationVariables = Types.Exact<{
  account_id: Types.Scalars['ID'];
  programs: Array<Types.ProgramLtvStatusInput> | Types.ProgramLtvStatusInput;
}>;


export type UpdateProgramsLtvStatusesMutation = { __typename: 'Mutations', updateProgramsLtvStatuses?: { __typename: 'UpdateProgramsLtvStatuses', ok: boolean } | null };


export const UpdateProgramsLtvStatusesDocument = gql`
    mutation UpdateProgramsLtvStatuses($account_id: ID!, $programs: [ProgramLtvStatusInput!]!) {
  updateProgramsLtvStatuses(accountId: $account_id, programs: $programs) {
    ok
  }
}
    `;
export type UpdateProgramsLtvStatusesMutationFn = Apollo.MutationFunction<UpdateProgramsLtvStatusesMutation, UpdateProgramsLtvStatusesMutationVariables>;

/**
 * __useUpdateProgramsLtvStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateProgramsLtvStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramsLtvStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramsLtvStatusesMutation, { data, loading, error }] = useUpdateProgramsLtvStatusesMutation({
 *   variables: {
 *      account_id: // value for 'account_id'
 *      programs: // value for 'programs'
 *   },
 * });
 */
export function useUpdateProgramsLtvStatusesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgramsLtvStatusesMutation, UpdateProgramsLtvStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProgramsLtvStatusesMutation, UpdateProgramsLtvStatusesMutationVariables>(UpdateProgramsLtvStatusesDocument, options);
      }
export type UpdateProgramsLtvStatusesMutationHookResult = ReturnType<typeof useUpdateProgramsLtvStatusesMutation>;
export type UpdateProgramsLtvStatusesMutationResult = Apollo.MutationResult<UpdateProgramsLtvStatusesMutation>;
export type UpdateProgramsLtvStatusesMutationOptions = Apollo.BaseMutationOptions<UpdateProgramsLtvStatusesMutation, UpdateProgramsLtvStatusesMutationVariables>;