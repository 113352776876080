import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelectedPostTypeFilterMutationVariables = Types.Exact<{
  selectedPostTypeFilter?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type UpdateSelectedPostTypeFilterMutation = { __typename: 'Mutations', updateSelectedPostTypeFilter?: Array<string | null> | null };


export const UpdateSelectedPostTypeFilterDocument = gql`
    mutation UpdateSelectedPostTypeFilter($selectedPostTypeFilter: [String!]) {
  updateSelectedPostTypeFilter(selectedPostTypeFilter: $selectedPostTypeFilter) @client
}
    `;
export type UpdateSelectedPostTypeFilterMutationFn = Apollo.MutationFunction<UpdateSelectedPostTypeFilterMutation, UpdateSelectedPostTypeFilterMutationVariables>;

/**
 * __useUpdateSelectedPostTypeFilterMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedPostTypeFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedPostTypeFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedPostTypeFilterMutation, { data, loading, error }] = useUpdateSelectedPostTypeFilterMutation({
 *   variables: {
 *      selectedPostTypeFilter: // value for 'selectedPostTypeFilter'
 *   },
 * });
 */
export function useUpdateSelectedPostTypeFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectedPostTypeFilterMutation, UpdateSelectedPostTypeFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectedPostTypeFilterMutation, UpdateSelectedPostTypeFilterMutationVariables>(UpdateSelectedPostTypeFilterDocument, options);
      }
export type UpdateSelectedPostTypeFilterMutationHookResult = ReturnType<typeof useUpdateSelectedPostTypeFilterMutation>;
export type UpdateSelectedPostTypeFilterMutationResult = Apollo.MutationResult<UpdateSelectedPostTypeFilterMutation>;
export type UpdateSelectedPostTypeFilterMutationOptions = Apollo.BaseMutationOptions<UpdateSelectedPostTypeFilterMutation, UpdateSelectedPostTypeFilterMutationVariables>;