import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPreferencesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPreferencesQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null, selectedMentionTypeFilter?: Array<string> | null, selectedPostTypeFilter?: Array<string> | null, hideSyncSocialAccountIds: Array<string>, selectedPostStatusFilter?: Array<string> | null, selectedChallengesFilter?: Array<string> | null, lastDashboardDateRange: { __typename: 'AbsoluteDateTimeRangeFilter', rangeType: Types.DateRangeFilterType, gte: Date, lt?: Date | null } | { __typename: 'RelativeDateRangeFilter', rangeType: Types.DateRangeFilterType, unit: Types.DateRangeFilterUnits, value: number, offset?: number | null } } } | null };


export const UserPreferencesDocument = gql`
    query UserPreferences {
  whoami {
    id
    preferences @client {
      id
      lastDashboardDateRange {
        ... on DateRangeFilterBase {
          rangeType
        }
        ... on RelativeDateRangeFilter {
          unit
          value
          offset
        }
        ... on AbsoluteDateTimeRangeFilter {
          gte
          lt
        }
      }
      selectedSocialAccountId
      selectedMentionTypeFilter
      selectedPostTypeFilter
      hideSyncSocialAccountIds
      selectedPostStatusFilter
      selectedChallengesFilter
    }
  }
}
    `;

/**
 * __useUserPreferencesQuery__
 *
 * To run a query within a React component, call `useUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
      }
export function useUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
        }
export type UserPreferencesQueryHookResult = ReturnType<typeof useUserPreferencesQuery>;
export type UserPreferencesLazyQueryHookResult = ReturnType<typeof useUserPreferencesLazyQuery>;
export type UserPreferencesQueryResult = Apollo.QueryResult<UserPreferencesQuery, UserPreferencesQueryVariables>;