import React, { useState } from 'react'
import { Dialog, makeStyles, createStyles } from '@material-ui/core'
import { StringParam, useQueryParam } from 'use-query-params'
import ContainerError from '../ContainerError'
import PostDetails from '../PostDetails'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOpenPostParam = () => useQueryParam('open_post', StringParam)

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      maxWidth: 1004,
    },
  }),
)

const ConnectedPostDetailModal: React.FC = () => {
  const classes = useStyles()
  const [hasError, setHasError] = useState(false)
  const [mentionId, setOpenPostParam] = useOpenPostParam()

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} onClose={() => setOpenPostParam(null)} open={!!mentionId}>
      {!hasError && mentionId && <PostDetails mention={mentionId} setHasError={setHasError} />}
      {(hasError || !mentionId) && <ContainerError text="Error loading post" />}
    </Dialog>
  )
}

export default ConnectedPostDetailModal
