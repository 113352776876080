import React, { useRef } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  Button,
  Link,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from '@material-ui/core'
import Page from '../../Page'
import useTitle from '../../utils/use-title'
import { useAdminSocialAccountsQuery } from './operations/query-admin-social-accounts.generated'
import { NetworkStatus } from '@apollo/client'
import useFilterParams, { Filters } from './use-filter-params'
import { SocialAccountFilterInput, SocialAccountSort, SocialPlatformEnum, SortDirection } from '../../gql-global'
import useSortDirParam from './use-sort-dir-param'
import useSortParam from './use-sort-param'
import { createTypeNamePredicate } from '../../types/utility'
import { ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS } from '../routes'
import { Link as RouterLink } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { NumberParam, useQueryParam } from 'use-query-params'
import { Skeleton } from '@material-ui/lab'

const PAGE_SIZE = 25

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: 1,
      lineHeight: 2,
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
)

const isTTSocialAccount = createTypeNamePredicate('TTSocialAccount')

const useWhereFilters = (filters: Filters): SocialAccountFilterInput => {
  return {
    socialAccountUsername: filters.socialAccountKeywords?.length
      ? { keywords: filters.socialAccountKeywords }
      : undefined,
  }
}

const TikTokSocialAccountList: React.FC = () => {
  useTitle('Admin - TikTok Social Accounts')
  const classes = useStyles()
  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)
  const { filters } = useFilterParams()
  const whereFilters = useWhereFilters(filters)
  const [sort = SocialAccountSort.CreatedAt] = useSortParam()
  const [sortDir = SortDirection.Asc] = useSortDirParam()

  const { loading, data: userSocialAccountsData, error, fetchMore, networkStatus } = useAdminSocialAccountsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      sortBy: sort,
      sortDirection: sortDir,
      limit: PAGE_SIZE,
      where: whereFilters,
      platform: SocialPlatformEnum.Tiktok,
    },
  })
  const socialAccounts = userSocialAccountsData?.socialAccountsPaged?.results || []
  const ttSocialAccounts = (socialAccounts && socialAccounts.filter(isTTSocialAccount)) || []

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore
  const { cursor } = userSocialAccountsData?.socialAccountsPaged || {}

  function handleLoadMore(): void {
    if (cursor) {
      fetchMore({
        variables: {
          cursor,
          sortBy: sort,
          sortDirection: sortDir,
          limit: limitRef.current,
          where: whereFilters,
          platform: SocialPlatformEnum.Tiktok,
        },
      })
    }
  }

  if (loading && !isLoadingMore) return <p>Loading</p>
  if (error || (!loading && !isLoadingMore && !userSocialAccountsData)) return <p>Error: {error && error.message}</p>

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h5" className={classes.title}>
              TikTok Social Accounts
            </Typography>
          </Box>
        </Box>
        <Box mt={6}>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Social Account</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Active Hashtag</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ttSocialAccounts.map(
                  (socialAccount, i): JSX.Element => {
                    const idDecoded = atob(socialAccount.id).split(':')
                    return (
                      <TableRow key={i} hover>
                        <TableCell>
                          <Link
                            to={`${ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS.path}/${socialAccount.id}`}
                            component={RouterLink}
                          >
                            {idDecoded[1]}
                          </Link>
                        </TableCell>
                        <TableCell>{socialAccount.socialUser.username}</TableCell>
                        <TableCell>{socialAccount.account.name}</TableCell>
                        <TableCell>{socialAccount.activeTrackedHashtag}</TableCell>
                        <TableCell>
                          {socialAccount.lastHashtagUpdatedAt &&
                            formatDistanceToNow(socialAccount.lastHashtagUpdatedAt)}
                        </TableCell>
                      </TableRow>
                    )
                  },
                )}
                {(loading || isLoadingMore) &&
                  new Array(isLoadingMore ? 5 : 10).fill(1).map((_, i) => (
                    <TableRow key={i} hover>
                      <TableCell>
                        <Box p={2}>
                          <Skeleton component="span" height={32} width="100%" />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box p={2}>
                          <Skeleton component="span" height={32} width="100%" />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box p={2}>
                          <Skeleton component="span" height={32} width="100%" />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box p={2}>
                          <Skeleton component="span" height={32} width="100%" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!error && cursor && (
          <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
            <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore} disabled={isLoadingMore}>
              Load more
            </Button>
          </Box>
        )}
      </Box>
    </Page>
  )
}

export default TikTokSocialAccountList
