import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme, FormControl, Select, MenuItem, Typography } from '@material-ui/core'
import { SelectionList } from '../SelectionPicker/SelectionList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.primary.main,
      margin: '10px 0',
    },
    placeholder: {
      display: 'none',
    },
    select: {
      '& .MuiSelect-select': {
        color: theme.palette.secondary.main,
        opacity: 0.65,
      },
    },
    menu: {
      '& .MuiMenu-paper': {
        paddingBottom: 10,
        minWidth: '300px !important',
      },
    },
  }),
)

export interface MultipleSelectFieldOption {
  id: number | string
  label: string
}

interface MultipleSelectEntityProps {
  label?: string
  placeholder?: string
  options: Array<MultipleSelectFieldOption>
  value: Array<string | number>
  setValue: (value: Array<string | number> | undefined) => void
  disabled?: boolean
  selectionRequired?: boolean
}

export const MultipleSelectField: React.FC<MultipleSelectEntityProps> = props => {
  const classes = useStyles()
  const { label, placeholder, options, value, setValue } = props

  const defaultValue = value.length ? value : placeholder ? [placeholder] : []

  const [selected, setSelected] = useState<Array<string | number> | undefined>(defaultValue)
  const [open, setOpen] = React.useState(false)

  const selectClass = selected?.[0] === placeholder ? classes.select : ''

  const handleClose = (values?: Array<string | number>) => {
    if (values) {
      setSelected(values.length ? values : placeholder ? [placeholder] : [])
      setValue(values)
    }
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleOnChange = (values: Set<string | number>): void => {
    const selection = Array.from(values).filter(item => item !== placeholder)
    handleClose(selection)
  }

  const handleOnReset = (): Set<string | number> => {
    const selection = selected?.filter(item => item !== placeholder)
    const values = selection?.length
      ? props.selectionRequired && placeholder
        ? [placeholder]
        : []
      : options.map(item => item.id)
    handleClose(values)
    return new Set(values)
  }

  const renderValue = (values: unknown) => {
    return (values as Array<string | number>)[0] === placeholder
      ? placeholder
      : options
          .filter(option => (values as Array<string | number>).includes(option.id))
          .map(option => option.label)
          .join(', ')
  }

  useEffect(() => {
    if (!!value.length) {
      setSelected(value)
    }
  }, [value])

  return (
    <FormControl>
      {!!label && <Typography className={classes.label}>{label}</Typography>}
      <Select
        multiple
        disabled={props.disabled}
        value={selected}
        placeholder={placeholder}
        className={selectClass}
        open={open}
        onClose={() => handleClose()}
        onOpen={handleOpen}
        renderValue={renderValue}
        MenuProps={{
          className: classes.menu,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        <MenuItem value={placeholder} className={classes.placeholder}>
          {placeholder}
        </MenuItem>

        <SelectionList
          toggleReset
          options={options}
          selectedOptions={new Set(selected?.filter(item => item !== placeholder))}
          onReset={handleOnReset}
          onChangeOptions={handleOnChange}
          selectionRequired={props.selectionRequired}
        />
      </Select>
    </FormControl>
  )
}
