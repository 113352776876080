import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PricingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PricingQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, account?: { __typename: 'AccountType', id: number, organization: { __typename: 'Organization', id: string, isTrialing?: boolean | null, plans: Array<{ __typename: 'AccountPlanType', id: string, status: string, stripeProducts?: Array<{ __typename: 'StripeProduct', id: string, isEnterprise: boolean, isInApp: boolean }> | null }> } } | null } | null, appStripeProducts?: Array<{ __typename: 'StripeProduct', id: string, name: string, description?: string | null, monthlyPrice?: number | null, yearlyPrice?: number | null, userLimit?: number | null, socialAccountLimit?: number | null, postLimit?: number | null, segmentLimit?: number | null, labelLimit?: number | null, campaignLimit?: number | null, isEnterprise: boolean }> | null };


export const PricingDocument = gql`
    query Pricing {
  whoami {
    id
    roles {
      id
      name
    }
    account {
      id
      organization {
        id
        isTrialing
        plans {
          id
          status
          stripeProducts {
            id
            isEnterprise
            isInApp
          }
        }
      }
    }
  }
  appStripeProducts {
    id
    name
    description
    monthlyPrice
    yearlyPrice
    userLimit
    socialAccountLimit
    postLimit
    segmentLimit
    labelLimit
    campaignLimit
    isEnterprise
  }
}
    `;

/**
 * __usePricingQuery__
 *
 * To run a query within a React component, call `usePricingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingQuery(baseOptions?: Apollo.QueryHookOptions<PricingQuery, PricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
      }
export function usePricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricingQuery, PricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
        }
export type PricingQueryHookResult = ReturnType<typeof usePricingQuery>;
export type PricingLazyQueryHookResult = ReturnType<typeof usePricingLazyQuery>;
export type PricingQueryResult = Apollo.QueryResult<PricingQuery, PricingQueryVariables>;