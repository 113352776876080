import { useUserFeatureDataQuery } from './operations/user-feature-data.generated'
import { ApolloError } from '@apollo/client'
import { userHasFeature } from '../utils/feature-manager'

interface HasFeatureResult {
  hasFeature: boolean
  loading: boolean
  error?: ApolloError
}

function useHasFeature(feature: string): HasFeatureResult {
  const { data, error, loading } = useUserFeatureDataQuery()
  if (data?.whoami) {
    const roleNames = data.whoami.roles?.map(r => r.name) || []
    const email = data.whoami.email
    return {
      hasFeature: userHasFeature(
        { username: email, roles: roleNames, accountId: data.whoami.account?.id || null },
        feature,
      ),
      loading: false,
    }
  }
  return {
    hasFeature: false,
    loading,
    error,
  }
}

export default useHasFeature
