import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserInfoQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, account?: { __typename: 'AccountType', id: number, name: string, organization: { __typename: 'Organization', id: string, activeProducts: Array<Types.AccountProductName>, isTrialing?: boolean | null, trialDaysRemaining?: number | null, plans: Array<{ __typename: 'AccountPlanType', id: string, stripeProducts?: Array<{ __typename: 'StripeProduct', id: string, name: string }> | null }> } } | null, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null } | null };


export const CurrentUserInfoDocument = gql`
    query CurrentUserInfo {
  whoami {
    id
    email
    account {
      id
      name
      organization {
        id
        activeProducts
        isTrialing
        trialDaysRemaining
        plans {
          id
          stripeProducts {
            id
            name
          }
        }
      }
    }
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useCurrentUserInfoQuery__
 *
 * To run a query within a React component, call `useCurrentUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserInfoQuery, CurrentUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserInfoQuery, CurrentUserInfoQueryVariables>(CurrentUserInfoDocument, options);
      }
export function useCurrentUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserInfoQuery, CurrentUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserInfoQuery, CurrentUserInfoQueryVariables>(CurrentUserInfoDocument, options);
        }
export type CurrentUserInfoQueryHookResult = ReturnType<typeof useCurrentUserInfoQuery>;
export type CurrentUserInfoLazyQueryHookResult = ReturnType<typeof useCurrentUserInfoLazyQuery>;
export type CurrentUserInfoQueryResult = Apollo.QueryResult<CurrentUserInfoQuery, CurrentUserInfoQueryVariables>;