import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type CustomFieldFilterFragment = { __typename: 'CustomFieldType', id: string, options?: Array<string> | null, position?: number | null, required: boolean, title: string, type: Types.CustomFieldEnumType };

export const CustomFieldFilterFragmentDoc = gql`
    fragment CustomFieldFilter on CustomFieldType {
  id
  options
  position
  required
  title
  type
}
    `;