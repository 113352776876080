import React, { useState, useRef } from 'react'
import { format, subDays } from 'date-fns'
import { DateRangeFilter, DateTimeRangeFilter } from '../../../gql-global'
import DateRangePickerMenuFlow from '../../DateRangePicker/DateRangePickerMenuFlow'
import { DateRangeOption } from './types'
import FilterChip, { FilterChipText } from './FilterChip'
import { dateRangeLabel } from '../../../utils/date-range-helper'
import { Menu, makeStyles, Box } from '@material-ui/core'
import { isTypeName } from '../../../types/utility'

const defaultDate = new Date(1900, 1, 1)

interface DateRangeFilterChipProps {
  option: DateRangeOption
  value: DateRangeFilter | DateTimeRangeFilter
  onDelete(): void
  onSelectValue(newValue: DateRangeFilter | DateTimeRangeFilter): void
}

const DATE_FORMAT = 'MMM dd, yyyy'

const useStyles = makeStyles({
  menuPaper: {
    minWidth: 272,
  },
})

export function DateRangeFilterChip({
  option,
  value,
  onDelete,
  onSelectValue,
}: DateRangeFilterChipProps): React.ReactElement {
  const [dateMenuOpen, setDateMenuOpen] = useState(false)
  const classes = useStyles()
  const chipRef = useRef<HTMLDivElement>(null)
  let text: React.ReactElement | string | null = null
  if (isTypeName(value, 'AbsoluteDateTimeRangeFilter') || isTypeName(value, 'AbsoluteDateRangeFilter')) {
    const filterChipType = value?.lt?.getTime()
      ? value?.gte?.getTime() !== defaultDate?.getTime()
        ? 'between'
        : 'before'
      : 'after'

    if (filterChipType === 'between') {
      text = (
        <>
          <FilterChipText text="between" />
          <FilterChipText bold text={format(value.gte, DATE_FORMAT)} />
          <FilterChipText text="and" />
          {value.lt && <FilterChipText bold text={format(subDays(value.lt, 1), DATE_FORMAT)} />}
        </>
      )
    } else if (filterChipType === 'after') {
      text = (
        <>
          {(option.entity === 'normal' || option.entity === 'normalPlusHours' || option.entity === 'custom') && (
            <FilterChipText text="after" />
          )}
          {value.gte && <FilterChipText bold text={format(subDays(value.gte, 1), DATE_FORMAT)} />}
        </>
      )
    } else if (filterChipType === 'before') {
      text = (
        <>
          {(option.entity === 'normal' || option.entity === 'normalPlusHours' || option.entity === 'custom') && (
            <FilterChipText text="before" />
          )}
          {value.lt && <FilterChipText bold text={format(value.lt, DATE_FORMAT)} />}
        </>
      )
    }
  } else {
    text = <FilterChipText bold text={dateRangeLabel(value, option.entity === 'inactiveFilter')} />
  }

  const onChangeDateRange = (newDateRange: DateRangeFilter | DateTimeRangeFilter): void => {
    setDateMenuOpen(false)
    onSelectValue(newDateRange)
  }

  return (
    <>
      <FilterChip onClick={() => setDateMenuOpen(true)} ref={chipRef} onDelete={onDelete}>
        <>
          <FilterChipText bold text={option.label} />
          {text}
        </>
      </FilterChip>
      <Menu
        open={dateMenuOpen}
        anchorEl={chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setDateMenuOpen(false)}
        classes={{ paper: classes.menuPaper }}
      >
        <DateRangePickerMenuFlow
          title={option.label}
          dateRange={value}
          onChangeDateRange={onChangeDateRange}
          entity={option.entity}
        />
      </Menu>
    </>
  )
}

interface DateRangeFilterMenuProps {
  option: DateRangeOption
  onSelectValue(newValue: DateRangeFilter | DateTimeRangeFilter): void
  onBack(): void
}

function DateRangeFilterMenu({ option, onSelectValue, onBack }: DateRangeFilterMenuProps): React.ReactElement {
  return (
    <Box mb={2}>
      <DateRangePickerMenuFlow
        title={option.label}
        dateRange={null}
        onChangeDateRange={onSelectValue}
        onQuit={onBack}
        entity={option.entity}
      />
    </Box>
  )
}

export default DateRangeFilterMenu
