import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMessageTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteMessageTemplateMutation = { __typename: 'Mutations', deleteMessageTemplate?: { __typename: 'DeleteMessageTemplate', ok: boolean, messageTemplate?: { __typename: 'MessageTemplate', id: string } | null } | null };


export const DeleteMessageTemplateDocument = gql`
    mutation DeleteMessageTemplate($id: ID!) {
  deleteMessageTemplate(id: $id) {
    ok
    messageTemplate {
      id
    }
  }
}
    `;
export type DeleteMessageTemplateMutationFn = Apollo.MutationFunction<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;

/**
 * __useDeleteMessageTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageTemplateMutation, { data, loading, error }] = useDeleteMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>(DeleteMessageTemplateDocument, options);
      }
export type DeleteMessageTemplateMutationHookResult = ReturnType<typeof useDeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationResult = Apollo.MutationResult<DeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;