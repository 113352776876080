import React from 'react'
import { Box } from '@material-ui/core'

import MessageForm from './MessageForm'
import MessagePreview from './MessagePreview'
import { MessageTemplateFragmentFragment } from '../operations/message-template-fragment.generated'

type MessageFormProps = {
  loading: boolean
  messageTemplate?: MessageTemplateFragmentFragment | null
}

function MessageBuilder({ loading, messageTemplate }: MessageFormProps): React.ReactElement {
  return (
    <Box display="flex" flexGrow={1}>
      <Box width="50%" display="flex" justifyContent="center" alignItems="center">
        <MessageForm loading={loading} messageTemplate={messageTemplate} />
      </Box>
      <Box width="50%" display="flex" justifyContent="center">
        <MessagePreview />
      </Box>
    </Box>
  )
}

export default MessageBuilder
