import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { TableRowShoppableCustomerFragmentDoc } from './table-row-shoppable-customer.generated';
export type TableRowShoppableParticipantFragment = { __typename: 'ProgramParticipantType', approvedAt?: Date | null, createdAt: Date, id: number, status: Types.ParticipantStatus, customer: { __typename: 'CustomerType', email?: string | null, fullName?: string | null, id: number, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null, ambassadorStats?: { __typename: 'AmbassadorStats', commissionsOwed: number, commissionsPaid: number, commissionsPending: number, landings: number, ordersApproved: number, ordersAwaitingApproval: number, revenueApproved: number, revenueAwaitingApproval: number, totalLandedOrders: number, totalOrders: number } | null, igUser?: { __typename: 'IGUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, mentionStats?: { __typename: 'CustomerMentionStats', avgEngagementRate: number, impressions: number, maxPostDate?: Date | null, postCount: number } | null, ttUser?: { __typename: 'TTUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string } | null }, program: { __typename: 'ProgramType', id: string, commissionsEnabled: boolean }, programCommissionTier?: { __typename: 'CommissionTierType', id: string, name: string } | null };

export const TableRowShoppableParticipantFragmentDoc = gql`
    fragment TableRowShoppableParticipant on ProgramParticipantType {
  approvedAt
  createdAt
  customer {
    ...TableRowShoppableCustomer
  }
  id
  program {
    id
    commissionsEnabled
  }
  programCommissionTier {
    id
    name
  }
  status
}
    ${TableRowShoppableCustomerFragmentDoc}`;