import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { MessageTemplateFragmentFragmentDoc } from './message-template-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMessageTemplateMutationVariables = Types.Exact<{
  messageTemplateInput: Types.MessageTemplateInput;
  statsWhere: Types.MessageTemplateStatsFilterInput;
}>;


export type CreateMessageTemplateMutation = { __typename: 'Mutations', createMessageTemplate?: { __typename: 'CreateMessageTemplate', ok: boolean, messageTemplate?: { __typename: 'MessageTemplate', id: string, name: string, template: string, skipSendMessage: Types.SkipMessageTemplateActionEnum, skipActionTimeBuffer?: number | null, customerTriggerQuantity?: number | null, automation?: { __typename: 'Automation', id: string, enabled: boolean, trigger: { __typename: 'MentionedTrigger', id: string, filter?: { __typename: 'ContentFilterType', id: string, name: string, entity: Types.FilterEntity, isSystem: boolean, value?: { __typename: 'ContentFilterValueType', followerCount?: { __typename: 'IntegerRangeFilter', gte?: number | null, lte?: number | null } | null, segments?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, campaigns?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, biography?: { __typename: 'KeywordsFilter', any?: Array<string> | null, none?: Array<string> | null } | null } | null } | null }, action: { __typename: 'AutomationAction', id: string } } | null, stats?: { __typename: 'MessageTemplateStats', totalRecipients: number } | null, reward?: { __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesInCurrentBatch?: number | null, codesRemaining?: number | null, codesLevel?: Types.CodesLevel | null } } | null } | null } | null };


export const CreateMessageTemplateDocument = gql`
    mutation CreateMessageTemplate($messageTemplateInput: MessageTemplateInput!, $statsWhere: MessageTemplateStatsFilterInput!) {
  createMessageTemplate(messageTemplateInput: $messageTemplateInput) {
    ok
    messageTemplate {
      ...MessageTemplateFragment
    }
  }
}
    ${MessageTemplateFragmentFragmentDoc}`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      messageTemplateInput: // value for 'messageTemplateInput'
 *      statsWhere: // value for 'statsWhere'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(CreateMessageTemplateDocument, options);
      }
export type CreateMessageTemplateMutationHookResult = ReturnType<typeof useCreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationResult = Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;