import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
export type SocialAccountHashtagFragmentFragment = { __typename: 'SocialAccountHashtagType', id: string, hashtag?: string | null, hashtagId?: string | null, active?: boolean | null, createdAt?: Date | null, updatedAt?: Date | null };

export const SocialAccountHashtagFragmentFragmentDoc = gql`
    fragment SocialAccountHashtagFragment on SocialAccountHashtagType {
  id
  hashtag
  hashtagId
  active
  createdAt
  updatedAt
}
    `;