import {
  CommissionTierRuleConditionClauseEnum,
  CommissionTierRuleConditionEnum,
  CommissionTierRuleConditionType,
  IntegrationType,
} from '../../../gql-global'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'

import { ReactComponent as XCircleIcon } from '../../../icons/x_circle_outline.svg'

import ChipsSelect from '../../../components/ChipsInput/ChipsSelect'
import { SelectField } from '../../../components/SelectField/SelectField'
import ProductCollectionBrandPickerMenu, { OptionType, PickerTypeEnum } from './ProductCollectionBrandPickerMenu'

export enum CommissionTierRuleConditionItemsTypeEnum {
  BRAND = 'BRAND',
  COLLECTION = 'COLLECTION',
  PRODUCT = 'PRODUCT',
}

export type CommissionTierRuleConditionTypeWithOptions = Omit<
  CommissionTierRuleConditionType,
  'products' | 'collections' | 'brands'
> & {
  products: OptionType[]
  collections: OptionType[]
  brands: OptionType[]
}

interface CommissionTierRuleConditionProps {
  condition: CommissionTierRuleConditionTypeWithOptions
  handleRemoveClick: () => void
  campaignId?: string
  integrationType?: IntegrationType
  onChange: (condition: CommissionTierRuleConditionTypeWithOptions) => void
}

export default function CommissionTierRuleCondition({
  condition,
  handleRemoveClick,
  campaignId,
  integrationType,
  onChange,
}: CommissionTierRuleConditionProps): React.ReactElement {
  const ref = useRef<HTMLDivElement>(null)
  const [openProductCollectionPicker, setOpenProductCollectionPicker] = useState(false)
  const [selectedItems, setSelectedItems] = useState<OptionType[]>([])
  const [selectedItemsType, setSelectedItemsType] = useState<CommissionTierRuleConditionClauseEnum>(
    CommissionTierRuleConditionClauseEnum.Product,
  )

  useEffect(() => {
    setSelectedItemsType(condition.conditionClauseType)
    if (condition.conditionClauseType === CommissionTierRuleConditionClauseEnum.Product) {
      setSelectedItems(condition.products || [])
    } else if (condition.conditionClauseType === CommissionTierRuleConditionClauseEnum.Collection) {
      setSelectedItems(condition.collections || [])
    } else {
      setSelectedItems(condition.brands || [])
    }
  }, [condition])

  const handleUpdateItems = (titles: string[]) => {
    const newSelectedItems = selectedItems.filter(
      selectedItem => selectedItem.title && titles.includes(selectedItem.title),
    )
    setSelectedItems(newSelectedItems)
    onChange({
      ...condition,
      products: selectedItemsType === CommissionTierRuleConditionClauseEnum.Product ? newSelectedItems : [],
      collections: selectedItemsType === CommissionTierRuleConditionClauseEnum.Collection ? newSelectedItems : [],
      brands: selectedItemsType === CommissionTierRuleConditionClauseEnum.Brand ? newSelectedItems : [],
    })
  }
  const handleProductCollectionPickerCancel = () => {
    setOpenProductCollectionPicker(false)
  }
  const handleProductCollectionPickerAddItems = (items: OptionType[]) => {
    setOpenProductCollectionPicker(false)
    setSelectedItems(items)
    onChange({
      ...condition,
      products: selectedItemsType === CommissionTierRuleConditionClauseEnum.Product ? items : [],
      collections: selectedItemsType === CommissionTierRuleConditionClauseEnum.Collection ? items : [],
      brands: selectedItemsType === CommissionTierRuleConditionClauseEnum.Brand ? items : [],
    })
  }
  const handleProductCollectionPickerOpen = () => {
    setOpenProductCollectionPicker(true)
  }

  function handleConditionTypeChange(value: string | number) {
    onChange({
      ...condition,
      conditionType: value as CommissionTierRuleConditionEnum,
      products: condition.products || [],
      collections: condition.collections || [],
      brands: condition.brands || [],
    })
  }

  const handleItemsTypeChange = (value: CommissionTierRuleConditionClauseEnum) => {
    if (value === selectedItemsType) return

    setSelectedItemsType(value)

    setSelectedItems([])

    onChange({
      ...condition,
      conditionClauseType: value,
      products: [],
      collections: [],
      brands: [],
    })
  }

  return (
    <Box display="flex" alignItems="flex-start" width="100%">
      <Box mr={6} width="131px">
        <Typography color="primary" variant="body1">
          Condition
        </Typography>
        <SelectField
          hideSearch
          placeholder="Includes?"
          value={condition.conditionType}
          setValue={(value: string | number) => handleConditionTypeChange(value)}
          options={[
            { label: 'Include', id: CommissionTierRuleConditionEnum.Inclusion },
            { label: 'Exclude', id: CommissionTierRuleConditionEnum.Exclusion },
          ]}
        />
      </Box>

      <Box mr={6} width="131px">
        <Typography color="primary" variant="body1">
          Criteria
        </Typography>
        <SelectField
          hideSearch
          placeholder="Products"
          value={selectedItemsType}
          setValue={(value: string | number) =>
            handleItemsTypeChange(
              value === CommissionTierRuleConditionItemsTypeEnum.PRODUCT
                ? CommissionTierRuleConditionClauseEnum.Product
                : value === CommissionTierRuleConditionItemsTypeEnum.COLLECTION
                ? CommissionTierRuleConditionClauseEnum.Collection
                : CommissionTierRuleConditionClauseEnum.Brand,
            )
          }
          options={[
            { label: 'Products', id: CommissionTierRuleConditionItemsTypeEnum.PRODUCT },
            { label: 'Collections', id: CommissionTierRuleConditionItemsTypeEnum.COLLECTION },
            { label: 'Brands', id: CommissionTierRuleConditionItemsTypeEnum.BRAND },
          ]}
        />
      </Box>

      <Box flexGrow={1}>
        <Box>
          <Typography color="primary" variant="body1">
            Criteria
          </Typography>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <div ref={ref}>
                <ChipsSelect
                  aria-label="Select items"
                  onClick={() => handleProductCollectionPickerOpen()}
                  chips={selectedItems.map(item => item.title || '').filter(s => s !== '')}
                  onChange={items => {
                    handleUpdateItems(items)
                  }}
                />
              </div>
            </Box>
            <Button
              color="primary"
              endIcon={<XCircleIcon width={20} height={20} onClick={handleRemoveClick} />}
            ></Button>
          </Box>
          <ProductCollectionBrandPickerMenu
            campaignId={campaignId}
            open={openProductCollectionPicker}
            onCancel={handleProductCollectionPickerCancel}
            onAdd={handleProductCollectionPickerAddItems}
            anchorEl={ref.current}
            pickerType={
              selectedItemsType === CommissionTierRuleConditionClauseEnum.Product
                ? PickerTypeEnum.PRODUCT
                : selectedItemsType === CommissionTierRuleConditionClauseEnum.Collection
                ? PickerTypeEnum.COLLECTION
                : PickerTypeEnum.BRAND
            }
            selectedItems={selectedItems}
            integrationType={integrationType}
          />
        </Box>
      </Box>
    </Box>
  )
}
