import React, { useEffect, useState } from 'react'
import { Box, Button, createStyles, Dialog, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import { error, amber } from '../../../loudcrowd-theme'
import { CommissionTierInput, CommissionTierType, IntegrationType } from '../../../gql-global'
import CommissionTierRule, { CommissionTierRuleTypeWithOptions } from './CommissionTierRule'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      width: '90%',
      minWidth: '1120px',
      height: '90%',
      minHeight: '655px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogPaperWithoutFeature: {
      width: '30%',
      minWidth: '400px',
      height: '35%',
      minHeight: '280px',
    },
    dialogField: { width: '100%', marginBottom: '20px' },
    dialogButton: { width: '80px' },
    cancelButton: { marginRight: '15px' },
    deleteButton: {
      color: error[600],
      borderColor: error[600],
      marginLeft: '-16px',
    },
    infoBox: {
      color: amber[700],
      fontSize: '0.8rem',
      marginBottom: '20px',
      borderRadius: '5px',
      border: `1px solid ${amber[700]}`,
      background: amber[100],
      padding: '10px 40px',
    },
  }),
)

interface UpsertCommissionTierModalProps {
  campaignId?: string
  integrationType?: IntegrationType
  open: boolean
  selectedCommissionTier?: CommissionTierType | null
  existingCommissionTiers: CommissionTierType[] | null | undefined
  doneCallback: (newTier: CommissionTierInput) => void
  cancelCallback: () => void
  deleteCallback?: () => void
  allowDelete?: boolean
  initialTierName?: string | null
  hasLineItemCommissions?: boolean
}

const UpsertCommissionTierModal: React.FC<UpsertCommissionTierModalProps> = ({
  campaignId,
  integrationType,
  open,
  selectedCommissionTier,
  existingCommissionTiers,
  doneCallback,
  deleteCallback,
  cancelCallback,
  initialTierName,
  allowDelete = true,
  hasLineItemCommissions = false,
}) => {
  const classes = useStyles()
  const [tierName, setTierName] = useState<string | null>(null)
  const [commissionPercentage, setCommissionPercentage] = useState<string | null>(null)
  const [percentageErrorMessage, setPercentageErrorMessage] = useState<string | null>(null)
  const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null)
  const [tierRules, setTierRules] = useState<CommissionTierRuleTypeWithOptions[]>([])
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (selectedCommissionTier) {
      setTierName(selectedCommissionTier.name)
      setCommissionPercentage(selectedCommissionTier.ratePercentage.toString())

      if (hasLineItemCommissions && selectedCommissionTier.rules?.length === 0) {
        // it's possible we are editing a tier that was created before the introduction of the rule system
        // in that case, we need to set the first rule based on the selectedCommissionTier.ratePercentage
        setTierRules([
          {
            __typename: 'CommissionTierRuleType',
            id: 'temp-1',
            ruleNumber: 1,
            ratePercentage: selectedCommissionTier.ratePercentage,
            conditions: [],
            updatedAt: new Date(),
          },
        ])
      } else {
        const convertedRules = selectedCommissionTier.rules?.map(rule => ({
          ...rule,
          conditions: rule.conditions?.map(condition => ({
            ...condition,
            brands: condition.brands?.map(brand => ({
              id: brand,
              title: brand,
            })),
            collections: condition.collections?.map(collection => ({
              id: collection.id,
              title: collection.title,
            })),
            products: condition.products?.map(product => ({
              id: product.id,
              title: product.title,
            })),
          })),
        }))
        setTierRules(convertedRules || [])
      }
      return
    } else {
      // selectedCommissionTier is null, we are adding a new tier
      // set the default first rule
      const defaultRule = {
        __typename: 'CommissionTierRuleType' as const,
        id: 'temp-1',
        ruleNumber: 1,
        ratePercentage: 0.0,
        conditions: [],
        updatedAt: new Date(),
      }
      setTierRules([defaultRule])
    }
    setTierName(initialTierName ?? '')
  }, [
    selectedCommissionTier,
    setCommissionPercentage,
    setTierRules,
    initialTierName,
    open,
    hasLineItemCommissions,
  ])

  useEffect(() => {
    let nameErrorMsg: string | null = null;
    if (!!tierName) {
      if (tierName.length > 50) {
        nameErrorMsg = 'Name is too long (maximum is 50 characters)';
      } else if (
        existingCommissionTiers
          ?.filter(ct => !selectedCommissionTier || ct.id !== selectedCommissionTier.id)
          .map(ct => ct.name)
          ?.includes(tierName)
      ) {
        nameErrorMsg = 'You must choose a unique name for this tier';
      }
    }
    setNameErrorMessage(nameErrorMsg);

    const validateForm = () => {
      if (!!nameErrorMsg || !tierName) {
        return false;
      }

      let errorMsg = null;
      const ratePercentages =
        tierRules.length === 0 ? [Number(commissionPercentage)] : tierRules.map(rule => rule.ratePercentage);

      for (const rate of ratePercentages) {
        const numRate = Number(rate);
        if (isNaN(numRate) || !Number.isInteger(numRate)) {
          errorMsg = 'Commission Percentage must be a whole number';
        } else if (numRate < 0 || numRate > 100) {
          errorMsg = 'Commission Percentage must be an integer between 0 and 100';
        }
        if (errorMsg) break;
      }

      // Check if rules with conditions have selected products, collections, or brands
      const areRulesValid = tierRules.every(rule => 
        rule.conditions.length === 0 || rule.conditions.every(condition => 
          (condition.brands && condition.brands.length > 0) ||
          (condition.collections && condition.collections.length > 0) ||
          (condition.products && condition.products.length > 0)
        )
      )

      setPercentageErrorMessage(errorMsg);
      return !errorMsg && areRulesValid;
    };

    setIsValid(validateForm());
  }, [existingCommissionTiers, selectedCommissionTier, tierName, commissionPercentage, tierRules]);


  const percentageAdornment = { endAdornment: <InputAdornment position="end">%</InputAdornment> }

  function handleAddRule() {
    const newRule = {
      __typename: 'CommissionTierRuleType' as const,
      id: `temp-${tierRules.length + 1}`,
      ruleNumber: tierRules.length + 1,
      ratePercentage: tierRules.length === 0 ? Number.parseInt(commissionPercentage!) : 0.0,
      conditions: [],
      updatedAt: new Date(),
    } as CommissionTierRuleTypeWithOptions
    setTierRules([...tierRules, newRule])
  }

  const handleRemoveRule = (ruleNumber: number) => {
    const newTierRules = tierRules.filter(rule => rule.ruleNumber !== ruleNumber)
    newTierRules.forEach((rule, index) => {
      rule.ruleNumber = index + 1
    })
    setTierRules(newTierRules)
  }
  function handleRuleChange(newRuleWithOptions: CommissionTierRuleTypeWithOptions) {
    let newTierRules = tierRules
    if (isNaN(Number.parseInt(newRuleWithOptions.ratePercentage.toString()))) {
      newTierRules = tierRules.map(rule =>
        rule.ruleNumber === newRuleWithOptions.ruleNumber ? { ...rule, ratePercentage: 0.0 } : rule,
      )
    } else {
      newTierRules = tierRules.map(rule =>
        rule.ruleNumber === newRuleWithOptions.ruleNumber ? newRuleWithOptions : rule,
      )
    }
    setTierRules(newTierRules)
  }

  function handleDone() {
    const newTier: CommissionTierInput = {
      id: selectedCommissionTier?.id,
      name: tierName!,
      ratePercentage:
        hasLineItemCommissions && tierRules.length > 0 ? 0.0 : Number.parseInt(commissionPercentage!),
      rules: tierRules.map(rule => ({
        id: rule.id.startsWith('temp-') ? undefined : rule.id,
        ratePercentage: rule.ratePercentage,
        conditions: rule.conditions.map(condition => ({
          id: condition.id.startsWith('temp-') ? undefined : condition.id,
          conditionType: condition.conditionType,
          conditionClauseType: condition.conditionClauseType,
          brands: condition.brands.map(brand => brand.id),
          collections: condition.collections.map(collection => collection.id),
          products: condition.products.map(product => product.id),
        })),
      })),
    }
    // clear the states
    setTierName('')
    setCommissionPercentage(null)
    setTierRules([])
    doneCallback(newTier)
  }

  const handleCancel = () => {
    setTierName('')
    setCommissionPercentage(null)
    setTierRules([])
    cancelCallback()
  }

  return (
    <Dialog
      classes={{ paper: hasLineItemCommissions ? classes.dialogPaper : classes.dialogPaperWithoutFeature }}
      open={open}
      onClose={handleCancel}
    >
      <Box
        style={{ width: '100%', height: '100%', display: 'grid', gridTemplateRows: '9fr 1fr', padding: '3% 5% 3% 5%' }}
      >
        <Box>
          <Typography variant="h5" style={{ paddingBottom: '10px' }}>
            {!!selectedCommissionTier ? 'Edit' : 'Add'} Commission Tier
          </Typography>
          <Box>
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.dialogField}
              label="Tier Name"
              placeholder="Provide a unique name that creators will see on their dashboard"
              value={tierName}
              onChange={e => setTierName(e.target.value)}
              error={!!nameErrorMessage}
              helperText={nameErrorMessage}
            ></TextField>
          </Box>
          {!hasLineItemCommissions && (
            <Box>
              <TextField
                InputLabelProps={{ shrink: true }}
                className={classes.dialogField}
                label="Commission Percentage"
                placeholder="Set an Integer number between 0 & 100"
                value={commissionPercentage || 0}
                onChange={e => setCommissionPercentage(e.target.value)}
                error={!!percentageErrorMessage}
                helperText={percentageErrorMessage}
                InputProps={percentageAdornment}
              ></TextField>
            </Box>
          )}
          {hasLineItemCommissions && tierRules.length > 1 && (
            <Box className={classes.infoBox}>
              IMPORTANT: Rules are applied independently of each other. Where products meet criteria for more than one
              rule, the highest commission percentage will be applied.
            </Box>
          )}
            {hasLineItemCommissions && (
            <>
              <CommissionTierRule
                campaignId={campaignId}
                integrationType={integrationType}
                rule={tierRules[0]}
                border={tierRules.length > 1 ? true : false}
                showRuleNumber={tierRules.length > 1 ? true : false}
                percentageErrorMessage={percentageErrorMessage || undefined}
                onChange={newRule => handleRuleChange(newRule)}
              ></CommissionTierRule>
              {tierRules.length > 1 &&
                tierRules
                  .slice(1)
                  .map((rule, index) => (
                    <CommissionTierRule
                      campaignId={campaignId}
                      integrationType={integrationType}
                      rule={rule}
                      key={index}
                      border
                      showRuleNumber
                      allowRemoveRule
                      onRemoveRule={() => handleRemoveRule(rule.ruleNumber)}
                      percentageErrorMessage={percentageErrorMessage || undefined}
                      onChange={newRule => handleRuleChange(newRule)}
                    ></CommissionTierRule>
                  ))}
              <Button
                startIcon={<PlusIcon width={20} height={20} />}
                variant="outlined"
                color="primary"
                onClick={() => handleAddRule()}
              >
                Add commission rule
              </Button>
            </>
          )}
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ textAlign: 'start', width: 'auto' }}>
            {!!selectedCommissionTier && (
              <Button
                className={`${classes.dialogButton} ${classes.deleteButton}`}
                onClick={() => !!deleteCallback && deleteCallback()}
                variant="text"
                size="medium"
                disabled={!allowDelete}
              >
                Delete
              </Button>
            )}
          </Box>
          <Box style={{ textAlign: 'end', width: '100%' }}>
            <Button
              className={`${classes.dialogButton} ${classes.cancelButton}`}
              onClick={handleCancel}
              color="secondary"
              size="medium"
              aria-label="Cancel"
            >
              Cancel
            </Button>
            <Button
              className={classes.dialogButton}
              disabled={!isValid}
              onClick={() => handleDone()}
              color="primary"
              size="medium"
              variant="contained"
              aria-label="Done"
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default UpsertCommissionTierModal
