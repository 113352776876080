import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, CardMedia, createStyles, makeStyles, MobileStepper, Theme, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ReactComponent as NoMediaImage } from '../../images/no-media.svg'
import { ReactComponent as PlayButton } from '../../icons/play-button.svg'
import { ReactComponent as VideoStoryIcon } from '../../icons/video-story-filled.svg'
import { ReactComponent as PhotoStoryIcon } from '../../icons/photo-story-filled.svg'
import { ReactComponent as TikTokLogo } from '../../icons/tiktok_logo_filled.svg'
import { ReactComponent as VideoReelsIcon } from '../../icons/Reels.svg'
import expiredStory from '../../images/expired-story.svg'
import { MediaTypeEnum } from '../../gql-global'
import { ReactComponent as ArrowIcon } from '../../icons/arrow-skinny.svg'
import { isTypeName } from '../../types/utility'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commonWrapper: {
      position: 'relative',
      width: 502,
      height: 689,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    mediaWrapper: {
      backgroundColor: theme.palette.background.default,
      borderRight: `1px solid rgba(0, 0, 0, .12)`,
    },
    mediaContainer: {
      alignItems: 'center',
      height: 629,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    expiredWrapper: {
      textAlign: 'center',
      backgroundImage: `url(${expiredStory})`,
    },
    expiredTitle: {
      fontWeight: 600,
    },
    storyWrapper: {
      height: 629,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    commonMedia: {
      objectFit: 'contain',
      borderRadius: theme.shape.borderRadius,
    },
    storyMedia: {
      maxWidth: 320,
      maxHeight: 609,
    },
    feedMedia: {
      maxWidth: 438,
      maxHeight: 533,
    },
    playButtonContainer: {
      cursor: 'pointer',
      position: 'absolute',
      top: '45%',
      left: '40%',
    },
    dotsStepper: {
      width: '100%',
      maxWidth: 438,
    },
    skeletonOverlay: {
      width: 438,
      height: 533,
      position: 'absolute',
      top: '12%',
      left: '6%',
      borderRadius: theme.shape.borderRadius,
    },
    reelsIcon: {
      color: theme.palette.primary.main,
    },
    videoMessageContainer: {
      maxWidth: 300,
    },
  }),
)

function DotsMobileStepper({
  numSteps,
  activeStep,
  setActiveStep,
}: {
  numSteps: number
  activeStep: number
  setActiveStep(newActiveStep: number): void
}): React.ReactElement {
  const classes = useStyles()

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <MobileStepper
      className={classes.dotsStepper}
      variant="dots"
      steps={numSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === numSteps - 1}>
          Next
          <Box ml={2} display="flex" alignItems="center">
            <ArrowIcon width={20} height={16} transform="rotate(180)" />
          </Box>
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <Box mr={2} display="flex" alignItems="center">
            <ArrowIcon width={20} height={16} />
          </Box>
          Back
        </Button>
      }
    />
  )
}

interface PostDetailsMediaChildType {
  id: string | null
  mediaUrl?: string | null
  thumbnailUrl?: string | null
  platformId?: string | null
  permalink?: string | null
  mediaType?: string | null
  lcMediaUrl?: string | null
  lcThumbnailUrl?: string | null
}

interface PostDetailsMediaType {
  media?: {
    id?: string | null
    lcMediaUrl?: string | null
    mediaUrl?: string | null
    mediaType?: string | null
    postType?: string | null
    fromAuthedApi?: boolean | null
    isExpired?: boolean | null
    permalink?: string | null
    children?: PostDetailsMediaChildType[] | null
    __typename: string
  } | null
  mentionLoading: boolean
  onMediaChange: (mediaIndex: number) => void
}
function PostDetailsMedia({ media, onMediaChange }: PostDetailsMediaType): React.ReactElement {
  const classes = useStyles()
  const [mediaFailed, setMediaFailed] = useState(false)
  const [loadingMedia, setLoadingMedia] = useState(true)
  const [triedVideo, setTriedVideo] = useState<boolean>(false)
  const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false)

  const isCarousel = media?.mediaType === MediaTypeEnum.CarouselAlbum && media?.children && !!media.children.length
  const [activeMediaChildIndex, setActiveMediaChildIndex] = useState<number>(0)
  const mediaType = (isCarousel ? media?.children?.[activeMediaChildIndex]?.mediaType : media?.mediaType) || null
  const [mediaTypeState, setMediaType] = useState<string | null>(mediaType || null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const url = isCarousel
    ? media?.children?.[activeMediaChildIndex]?.lcMediaUrl ||
      media?.children?.[activeMediaChildIndex]?.mediaUrl ||
      undefined
    : media?.lcMediaUrl || media?.mediaUrl || undefined
  const isVideo = mediaTypeState === 'VIDEO'
  const postType = media?.postType
  const isExpired = media?.isExpired
  const isStory = postType === 'STORY'
  const isReels = postType === 'REELS'
  const isUnauthedStory = !media?.fromAuthedApi && isStory
  const expiredUnauthedStory = isUnauthedStory && isExpired
  const isValidMedia = url && !mediaFailed
  const renderVideoEl = (!isUnauthedStory && isVideo) || (isUnauthedStory && !triedVideo)

  const isVideoTag = (elm: HTMLDivElement | HTMLVideoElement): elm is HTMLVideoElement =>
    (elm as HTMLVideoElement).play !== undefined

  useEffect(() => {
    const isIGMedia = media && isTypeName(media, 'IGMedia')
    const isTTMedia = media && isTypeName(media, 'TTMedia')
    if (isIGMedia && mediaType !== null && mediaType !== mediaTypeState) {
      setMediaType(mediaType)
    } else if (isTTMedia && url) {
      const mediaType = url.includes('.mp4') ? 'VIDEO' : 'IMAGE'
      setMediaType(mediaType)
    }
  }, [media, url, mediaType, mediaTypeState])

  function handleOnMediaLoad(e: React.SyntheticEvent) {
    if (isUnauthedStory) {
      const tag = e.currentTarget.tagName
      setMediaType(tag)
    }
    setLoadingMedia(false)
  }

  function handleOnMediaError() {
    if (!isUnauthedStory) {
      setMediaFailed(true)
    } else if (isUnauthedStory) {
      triedVideo ? setMediaFailed(true) : setTriedVideo(true)
    }
    setLoadingMedia(false)
  }

  function handleOnClickPlay(event: React.MouseEvent) {
    event.stopPropagation()
    if (mediaTypeState === 'VIDEO' && videoRef.current && videoRef.current.paused) {
      videoRef.current.play()
      setIsPlayingVideo(true)
    }
  }

  function handleOnPause() {
    if (mediaTypeState === 'VIDEO' && videoRef.current && isVideoTag(videoRef.current)) {
      videoRef.current.pause()
      setIsPlayingVideo(false)
    }
  }
  const wrapperClasses = [classes.commonWrapper]
  if (expiredUnauthedStory) {
    wrapperClasses.push(classes.expiredWrapper)
  } else {
    wrapperClasses.push(classes.mediaWrapper)
  }
  const wrapperClassName = wrapperClasses.join(' ')

  const mediaClasses = [classes.commonMedia]
  if (isStory) {
    mediaClasses.push(classes.storyMedia)
  } else {
    mediaClasses.push(classes.feedMedia)
  }
  const mediaClassName = mediaClasses.join(' ')
  let mediaComponent = (
    <Box>
      <Skeleton className={classes.skeletonOverlay} variant="rect" />
    </Box>
  )

  let logo = null

  if (isStory && mediaTypeState === 'VIDEO') {
    logo = <VideoStoryIcon width={26} height={26} />
  } else if (isStory && mediaTypeState === 'IMAGE') {
    logo = <PhotoStoryIcon width={26} height={26} />
  } else if (media && isTypeName(media, 'TTMedia')) {
    logo = <TikTokLogo width={26} height={26} />
  } else if (isReels && mediaTypeState === 'VIDEO') {
    logo = <VideoReelsIcon width={26} height={26} className={classes.reelsIcon} />
  }

  if ((media && !url) || (!loadingMedia && !isValidMedia) || expiredUnauthedStory) {
    mediaComponent = (
      <Box textAlign="center">
        <NoMediaImage />
        {expiredUnauthedStory ? (
          <Box textAlign="center" marginX={6}>
            <Box my={4}>
              <Typography variant="h4" className={classes.expiredTitle}>
                Story Expired
              </Typography>
            </Box>
            <Typography component="p">
              Due to Facebook’s regulations, we can’t show stories that have been published more than 24 hours ago.
            </Typography>
          </Box>
        ) : (
          <Box my={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {isVideo ? (
              <Box className={classes.videoMessageContainer}>
                <Typography component="p">Video cannot be displayed</Typography>
                <Typography component="p" style={{ marginTop: 5 }}>
                  Meta won’t let us display videos that contain copyrighted material or have been flagged for copyright
                  violations.
                </Typography>
                {media?.permalink && (
                  <Box>
                    Try{' '}
                    <Button
                      color="primary"
                      href={media.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()}
                    >
                      viewing the video on Instagram
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Typography component="p">Image not found</Typography>
            )}
          </Box>
        )}
      </Box>
    )
  } else if (isValidMedia && !expiredUnauthedStory) {
    if (activeMediaChildIndex && onMediaChange) {
      onMediaChange(activeMediaChildIndex)
    }
    mediaComponent = (
      <>
        {loadingMedia && <Skeleton className={classes.skeletonOverlay} variant="rect" />}
        <Box margin={3}>{logo}</Box>
        <Box className={isStory ? classes.storyWrapper : classes.mediaContainer}>
          <CardMedia
            component={renderVideoEl ? 'video' : 'img'}
            className={mediaClassName}
            ref={isVideo ? videoRef : undefined}
            src={url}
            controls={isPlayingVideo}
            onPause={handleOnPause}
            onError={handleOnMediaError}
            onLoadedMetadata={handleOnMediaLoad}
            onLoad={handleOnMediaLoad}
          />
          {!loadingMedia && isVideo && !isPlayingVideo && (
            <Box className={classes.playButtonContainer}>
              <PlayButton onClick={handleOnClickPlay} />
            </Box>
          )}
          {!loadingMedia && isCarousel && (
            <DotsMobileStepper
              numSteps={media?.children?.length || 0}
              activeStep={activeMediaChildIndex}
              setActiveStep={(newIndex: number) => setActiveMediaChildIndex(newIndex)}
            />
          )}
        </Box>
      </>
    )
  }
  return (
    <Box className={wrapperClassName}>
      <Box>{mediaComponent}</Box>
    </Box>
  )
}

export default PostDetailsMedia
