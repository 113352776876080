import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SegmentUserInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SegmentUserInfoQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, account?: { __typename: 'AccountType', id: number, organization: { __typename: 'Organization', id: string, segmentLimit: { __typename: 'SegmentLimit', used: number, hasHitLimit: boolean, limit?: number | null, isUnlimited: boolean } } } | null } | null };


export const SegmentUserInfoDocument = gql`
    query SegmentUserInfo {
  whoami {
    id
    roles {
      id
      name
    }
    account {
      id
      organization {
        id
        segmentLimit {
          used
          hasHitLimit
          limit
          isUnlimited
        }
      }
    }
  }
}
    `;

/**
 * __useSegmentUserInfoQuery__
 *
 * To run a query within a React component, call `useSegmentUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSegmentUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<SegmentUserInfoQuery, SegmentUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentUserInfoQuery, SegmentUserInfoQueryVariables>(SegmentUserInfoDocument, options);
      }
export function useSegmentUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentUserInfoQuery, SegmentUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentUserInfoQuery, SegmentUserInfoQueryVariables>(SegmentUserInfoDocument, options);
        }
export type SegmentUserInfoQueryHookResult = ReturnType<typeof useSegmentUserInfoQuery>;
export type SegmentUserInfoLazyQueryHookResult = ReturnType<typeof useSegmentUserInfoLazyQuery>;
export type SegmentUserInfoQueryResult = Apollo.QueryResult<SegmentUserInfoQuery, SegmentUserInfoQueryVariables>;