import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrganizationMutationVariables = Types.Exact<{
  organizationInput: Types.CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename: 'Mutations', createOrganization?: { __typename: 'CreateOrganization', ok: boolean, organization?: { __typename: 'Organization', id: string, stripeCustomerId?: string | null, userLimit: { __typename: 'UserLimit', used: number, limit?: number | null, isUnlimited: boolean, hasHitLimit: boolean }, accounts: Array<{ __typename: 'AccountType', id: number, name: string }> } | null } | null };


export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($organizationInput: CreateOrganizationInput!) {
  createOrganization(organization: $organizationInput) {
    ok
    organization {
      id
      stripeCustomerId
      userLimit {
        used
        limit
        isUnlimited
        hasHitLimit
      }
      accounts {
        id
        name
      }
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      organizationInput: // value for 'organizationInput'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;