import React, { useEffect, useRef, useState } from 'react'
import { addDays, format, isWithinInterval } from 'date-fns'
import { PostDetailsFragment } from './operations/post-details.generated'
import {
  Box,
  Button,
  createStyles,
  Divider,
  Link,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import StoriesAvatarV2 from '../CustomerAvatar/StoriesAvatarV2'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as ExternalIcon } from '../../icons/external.svg'
import { ReactComponent as DownloadIcon } from '../../icons/download.svg'
import Metric from '../metric/Metric'
import ExpandoText from '../ExpandoText'
import Caption from '../Caption'
import { Skeleton } from '@material-ui/lab'
import { ReactComponent as HeartIcon } from '../../icons/heart.svg'
import { numberFormat } from '../../utils/number-format'
import { ReactComponent as ChatIcon } from '../../icons/chat.svg'
import LabelMenu from '../LabelMenu'
import LabelChip from '../labelChip'
import { ReactComponent as LabelIcon } from '../../icons/label.svg'
import { ChallengeStatusEnum, LabelType, MentionStatus } from '../../gql-global'
import CountDownTimer from '../CountdownTimer/CountdownTimer'
import { ReactComponent as InfoIcon } from '../../icons/information-current-color.svg'
import { isTypeName } from '../../types/utility'
import StatusSelector from './StatusSelector'
import { useChallengeListQuery } from '../../challenges/operations/challenge-list.generated'
import ChallengeSelector from './ChallengeSelector'
import { LABEL_MANAGEMENT_ROUTE } from '../../settings/routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    h7: {
      // MUI doesn't have a h7 whereas the mocks did
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    avatar: {
      margin: '0 10px 0 0',
    },
    postedAt: {
      marginRight: 5,
    },
    divider: {
      margin: '20px 0',
    },
    emptyLabels: {
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 114,
    },
    expiredCaption: {
      color: theme.palette.secondary.main,
      fontStyle: 'italic',
    },
    scrollContainer: {
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
    selectorWrapper: {
      '& .MuiButton-label h6': {
        width: 70,
        textAlign: 'left',
      },
    },
    ellipsis: {
      maxWidth: 185,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
)

type Label = Pick<LabelType, 'id' | 'name'>
interface PostDetailsProps {
  accountLabels?: Label[] | null
  canModifyLabels: boolean
  hasCampaigns?: boolean
  hasCustomersAccess?: boolean
  hasHitLabelLimit?: boolean
  labels?: Label[] | null | undefined
  mention?: PostDetailsFragment | null
  mentionLoading?: boolean
  forReview?: boolean
  onAddLabel?(id: number): void
  onChangeStatus?(id: string, newStatus: MentionStatus): void
  onChangeChallengeMediaStatus?(mediaId: string, challengeId: string, approval: MentionStatus): void
  onCreateAssignLabel?(name: string): void
  onDeleteLabel?(id: number): void
  onRemoveLabel?(labelId: number): void
  onUpdateLabel?(id: number, name: string): void
  currentMediaIndex?: number
  socialAccountId?: string | null
}

function PostDetailsDetails({
  accountLabels = [],
  canModifyLabels = false,
  hasCampaigns = false,
  hasCustomersAccess = false,
  hasHitLabelLimit = false,
  mention,
  mentionLoading = false,
  forReview,
  onAddLabel,
  onChangeStatus,
  onCreateAssignLabel,
  onChangeChallengeMediaStatus,
  onDeleteLabel,
  onRemoveLabel,
  onUpdateLabel,
  currentMediaIndex,
  socialAccountId,
}: PostDetailsProps): React.ReactElement {
  const classes = useStyles()
  const addLabelButton = useRef<HTMLButtonElement | null>(null)
  const [addLabelOpen, setAddLabelOpen] = useState(false)
  const [imageObjectURL, setImageObjectURL] = useState<string | null>(null)

  const { data: challengeListData } = useChallengeListQuery({
    skip: !socialAccountId,
    variables: { socialAccountId: socialAccountId || '', limit: 200 },
  })

  // Mention
  const mentionId = mention?.id
  const mentionStatus = mention?.status || MentionStatus.Unverified
  const mentionLabels = mention?.labels
  const socialAccountUsername = mention?.socialAccount?.socialUser?.username

  // Media
  const media = mention?.media
  const postedAt = media?.postedAt
  const permalink = media?.permalink
  const caption = media?.caption
  const likeCount = media?.stats?.likeCount
  const commentCount = media?.stats?.commentCount
  const impressions = media?.stats?.impressions
  const replies = media?.stats?.replies
  const engagementRate = media?.stats?.engagementRate
  const emv = media?.stats?.emv
  const poster = media?.poster
  const participants = poster?.customer?.programParticipants
  //   ig media stuff
  const igMedia = media && isTypeName(media, 'IGMedia') ? media : null
  const fromAuthedApi = igMedia?.fromAuthedApi
  const isStory = igMedia?.postType === 'STORY'
  const isUnAuthedStory = !fromAuthedApi && isStory
  const isAuthedStory = fromAuthedApi && isStory
  const isExpiredUnAuthedStory = isUnAuthedStory && igMedia?.isExpired
  const postExpiration = postedAt && addDays(postedAt, 1)
  const isCarousel = igMedia?.children && !!igMedia?.children.length
  const downloadMediaUrl =
    isCarousel && currentMediaIndex
      ? igMedia?.children?.[currentMediaIndex]?.lcMediaUrl ||
        igMedia?.children?.[currentMediaIndex]?.mediaUrl ||
        undefined
      : media?.lcMediaUrl || media?.mediaUrl || undefined

  // Poster
  const avatarImgUrl = poster?.avatarUrl
  const posterHasActiveStory = !!poster?.customer?.activeStoryMentionsStats?.postCount
  const customerId = poster?.customer?.id
  const username = poster?.username
  let isFbAuth = false
  if (hasCampaigns && poster && isTypeName(poster, 'IGUser')) {
    poster.storiesIgSocialAccounts?.forEach(sa => {
      isFbAuth = isFbAuth || !!sa.fbUsers?.some(f => f.isValid)
    })
  }
  const linkProps = hasCustomersAccess
    ? { to: `${CUSTOMER_ROUTE.path}/${customerId}`, component: RouterLink }
    : { href: `https://www.instagram.com/${username}`, target: '_blank', rel: 'noopener noreferrer' }
  const selectedLabels = new Set(mentionLabels?.map(l => l.id) || [])

  const mediaType =
    media &&
    ((igMedia && igMedia.mediaType === 'VIDEO') || isTypeName(media, 'TTMedia') || downloadMediaUrl?.includes('.mp4'))
      ? '.mp4'
      : '.jpg'

  const challenges = challengeListData?.socialAccount?.challenges?.results
    .filter(item => {
      const isProgramMember = participants?.find(participant =>
        item.programs?.find(program => program.id === participant.program.id),
      )
      const wasMarked = !!media?.challengeMedia?.find(challengeMedia => challengeMedia?.challenge.id === item.id)
      const isWithinDateRange =
        postedAt &&
        item.startedAt &&
        item.endedAt &&
        isWithinInterval(postedAt, { start: item.startedAt, end: addDays(item.endedAt, 7) })
      const isChallengeActive =
        item.isRecentlyCompleted || [ChallengeStatusEnum.Live, ChallengeStatusEnum.Paused].includes(item.currentStatus)

      return (isProgramMember && isWithinDateRange && isChallengeActive) || wasMarked
    })
    .map(challenge => ({
      ...challenge,
      challengeMedia: media?.challengeMedia?.find(item => item?.challenge.id === challenge.id),
    }))

  const listedChallenges = challenges?.filter(challenge => !!challenge?.challengeMedia) ?? []
  const dropdownChallenges = challenges?.filter(challenge => !challenge?.challengeMedia) ?? []
  const singleChallenge = dropdownChallenges?.[0]

  useEffect(
    function () {
      ;(async function () {
        if (!downloadMediaUrl) {
          return
        }
        try {
          const response = await fetch(downloadMediaUrl, {
            cache: 'no-cache',
          })
          if (response.ok) {
            const blob = await response.blob()
            const url = URL.createObjectURL(blob)
            setImageObjectURL(url)
          }
        } catch (e) {
          console.log(e)
        }
      })()
    },
    [downloadMediaUrl, setImageObjectURL],
  )

  const handleOnChangeChallengeApproval = (approval: MentionStatus, mediaId?: string, challengeId?: string) => {
    if (onChangeChallengeMediaStatus && challengeId && mediaId) {
      onChangeChallengeMediaStatus(mediaId, challengeId, approval)
    }
  }

  return (
    <Box position="relative" width={480} height={689} display="flex" flexDirection="column">
      <Box height={40} marginX={8} display="flex" justifyContent="space-between" marginTop={8}>
        <Box display="flex" alignItems="end">
          <Box className={classes.avatar}>
            <StoriesAvatarV2
              loading={mentionLoading}
              avatarUrl={avatarImgUrl}
              linkProps={username ? linkProps : undefined}
              hasActiveStory={posterHasActiveStory}
            />
          </Box>
          <Box>
            {mentionLoading ? (
              <>
                <Skeleton width={100} />
                <Skeleton width={100} />
              </>
            ) : (
              <>
                <Link {...linkProps}>
                  <Typography variant="subtitle1" color="primary">
                    {username}
                  </Typography>
                </Link>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="secondary" className={classes.postedAt}>
                    {postedAt && format(postedAt, 'd LLLL')}
                  </Typography>
                  {permalink && !isStory && (
                    <Link href={permalink} target="_blank" rel="noopener noreferrer">
                      View Post <ExternalIcon />
                    </Link>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="end">
          {mentionLoading && <Skeleton variant="circle" width={20} height={20} />}
          {!mentionLoading && !isStory && media && imageObjectURL && (
            <Button
              href={imageObjectURL}
              download={`post_${media.id}${mediaType}`}
              variant="contained"
              color="primary"
              disabled={mentionLoading}
            >
              <DownloadIcon width={20} />
            </Button>
          )}
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft={8} marginRight={14}>
        {isUnAuthedStory && (
          <Box p={0} display="flex" alignItems="left" flexDirection="column" width={138}>
            <Tooltip
              title="Due to Facebook’s regulations, we can’t show stories that have been published more than 24 hours ago."
              placement="top"
            >
              <Typography variant="subtitle2" color="secondary">
                Time Left
                <Box display="inline" ml={2}>
                  <InfoIcon width={20} height={20} />
                </Box>
              </Typography>
            </Tooltip>
            <Box fontSize="h5.fontSize" lineHeight="h5.lineHeight">
              {mentionLoading && <Skeleton component="span" />}
              {!mentionLoading && isUnAuthedStory && postExpiration && <CountDownTimer expiration={postExpiration} />}
            </Box>
          </Box>
        )}
        <Metric metricType="impressions" metric={impressions} loading={mentionLoading} showIcon={false} />
        {!isStory && (
          <Metric metricType="engagement" metric={engagementRate} loading={mentionLoading} showIcon={false} />
        )}
        {isAuthedStory && <Metric metricType="replies" metric={replies} loading={mentionLoading} showIcon={false} />}
        <Metric metricType="emv" metric={emv} loading={mentionLoading} showIcon={false} />
      </Box>
      <Divider className={classes.divider} />
      <Box marginBottom={5} className={classes.scrollContainer}>
        <Box marginX={8}>
          <Typography variant="body2" component="span">
            {!mentionLoading && caption && (
              <ExpandoText minLines={4}>
                <Caption
                  text={caption}
                  highlightedText={socialAccountUsername}
                  platform={media && isTypeName(media, 'TTMedia') ? 'tiktok' : 'instagram'}
                />
              </ExpandoText>
            )}
            {!mentionLoading && isExpiredUnAuthedStory && (
              <Typography variant="body2" className={classes.expiredCaption}>
                Content Expired
              </Typography>
            )}
            {!mentionLoading && !isExpiredUnAuthedStory && !caption && <Typography variant="subtitle2">--</Typography>}
            {mentionLoading && (
              <>
                <Skeleton style={{ width: '100%' }} />
                <Skeleton style={{ width: '100%' }} />
                <Skeleton style={{ width: '100%' }} />
              </>
            )}
          </Typography>
          <Box display="flex" mt={4}>
            {mentionLoading ? (
              <Skeleton width={100} />
            ) : (
              <>
                <Box mr={3}>
                  <HeartIcon width={20} height={20} />
                </Box>
                <Typography display="block" variant="subtitle2" color="secondary">
                  {!isStory && (!!likeCount || likeCount === 0) ? numberFormat(likeCount) : '--'}
                </Typography>
                <Box mr={3} ml={10}>
                  <ChatIcon width={20} height={20} />
                </Box>
                <Typography display="block" variant="subtitle2" color="secondary">
                  {!isStory && (!!commentCount || commentCount === 0) ? numberFormat(commentCount) : '--'}
                </Typography>
              </>
            )}
          </Box>
          <Box mt={4}>
            {mentionLoading && <Skeleton component="span" width={100} />}
            {!mentionLoading && mention && isTypeName(mention, 'IGMention') && (
              <>
                <Typography variant="subtitle2" color="secondary">
                  {mention.wasTagged && (
                    <Box display="flex" alignItems="center" mb={3}>
                      Tagged in post
                    </Box>
                  )}
                  {mention.wasCaptionMentioned && (
                    <Box display="flex" alignItems="center" mb={3}>
                      Mentioned in caption
                    </Box>
                  )}
                  {mention.wasStoryMentioned && (
                    <Box display="flex" alignItems="center">
                      Mentioned in story
                    </Box>
                  )}
                </Typography>
              </>
            )}
            {!mentionLoading && mention && isTypeName(mention, 'TTMention') && mention.wasHashtagMentioned && (
              <Typography variant="subtitle2" color="secondary">
                Mentioned #hashtag
              </Typography>
            )}
          </Box>
        </Box>
        {!forReview && !!challenges?.length && (
          <>
            <Divider className={classes.divider} />
            <Box display="flex" mb={3} mr={8} ml={4} justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                {listedChallenges?.map(challenge => (
                  <Box display="flex" alignItems="center" key={challenge.id} className={classes.selectorWrapper}>
                    <StatusSelector
                      mentionStatus={challenge?.challengeMedia?.approval ?? MentionStatus.Unverified}
                      onChangeStatus={(mediaId, status) =>
                        handleOnChangeChallengeApproval(status, mediaId, challenge.id)
                      }
                      mediaId={media?.id}
                      excludeOfficialReview
                    />
                    <Typography variant="subtitle2" className={classes.ellipsis} title={challenge.name}>
                      {challenge.name}
                    </Typography>
                  </Box>
                ))}
                {dropdownChallenges.length > 1 ? (
                  <Box display="flex" alignItems="center" ml={4}>
                    <Typography variant="subtitle2">Approve for</Typography>
                    <ChallengeSelector
                      challenges={dropdownChallenges}
                      onSelectChallenge={challenge =>
                        handleOnChangeChallengeApproval(MentionStatus.Verified, media?.id, challenge?.id)
                      }
                    />
                  </Box>
                ) : (
                  !!singleChallenge && (
                    <Box
                      display="flex"
                      alignItems="center"
                      key={singleChallenge?.id}
                      className={classes.selectorWrapper}
                    >
                      <StatusSelector
                        mentionStatus={MentionStatus.Unverified}
                        onChangeStatus={(mediaId, status) =>
                          handleOnChangeChallengeApproval(status, mediaId, singleChallenge?.id)
                        }
                        mediaId={media?.id}
                        excludeOfficialReview
                      />
                      <Typography variant="subtitle2" className={classes.ellipsis} title={singleChallenge?.name}>
                        {singleChallenge?.name}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
              <StatusSelector mentionStatus={mentionStatus} onChangeStatus={onChangeStatus} mentionId={mentionId} />
            </Box>
          </>
        )}
        <Divider className={classes.divider} />
        <Box marginX={8}>
          <Box display="flex" mb={3} alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2">Labels</Typography>
              <Box marginLeft={2}>
                <Button
                  ref={addLabelButton}
                  color="primary"
                  disabled={mentionLoading}
                  onClick={() => setAddLabelOpen(true)}
                >
                  + Add
                </Button>
                <LabelMenu
                  entity="label"
                  seeAllLink={LABEL_MANAGEMENT_ROUTE.path}
                  open={addLabelOpen}
                  onCancel={() => setAddLabelOpen(false)}
                  anchorEl={addLabelButton.current}
                  labels={accountLabels || []}
                  selectedLabelIds={selectedLabels}
                  onSelect={id => {
                    setAddLabelOpen(false)
                    onAddLabel && onAddLabel(id)
                  }}
                  onCreate={
                    onCreateAssignLabel
                      ? name => {
                          setAddLabelOpen(false)
                          onCreateAssignLabel(name)
                        }
                      : undefined
                  }
                  onUpdate={(id, name) => {
                    onUpdateLabel && onUpdateLabel(id, name)
                  }}
                  onDelete={id => {
                    setAddLabelOpen(false)
                    onDeleteLabel && onDeleteLabel(id)
                  }}
                  hasHitLimit={hasHitLabelLimit}
                  editable
                  allowedActions={canModifyLabels ? ['update', 'delete'] : []}
                />
              </Box>
            </Box>
            {!forReview && !challenges?.length && (
              <StatusSelector mentionStatus={mentionStatus} onChangeStatus={onChangeStatus} mentionId={mentionId} />
            )}
          </Box>
          {!mentionLoading && mentionLabels && mentionLabels.length > 0 && (
            <Box display="flex" flexWrap="wrap" mt={-1}>
              {mentionLabels.map(
                mentionLabel =>
                  mentionLabel &&
                  mentionId && (
                    <Box mt={1} key={mentionLabel.id}>
                      <LabelChip
                        labelId={mentionLabel.id}
                        label={mentionLabel.name}
                        onDeleteCallback={onRemoveLabel}
                        isRejectionReason={mentionLabel.isRejectionReason}
                      />
                    </Box>
                  ),
              )}
            </Box>
          )}
          {!mentionLoading && !mentionLabels?.length && (
            <Paper elevation={0} className={classes.emptyLabels}>
              <Box mr={3} color="text.hint" display="flex" alignItems="center">
                <LabelIcon width={16} height={16} />
              </Box>
              <Typography component="div" variant="subtitle2" color="secondary">
                No Labels
              </Typography>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default PostDetailsDetails
