import React from 'react'
import { Box, createStyles, makeStyles, Typography, TextField } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { SelectField } from '../../../components/SelectField/SelectField'
import { ProgramSettingsFields } from './CampaignSettings'
import theme from '../../../loudcrowd-theme'
import ProductPickerCollectionComponent, { ProductPickerCollectionsType } from './ProductPickerCollectionSettings'

const ATTRIBUTION_TYPE_OPTIONS = [
  { id: 'FIRST', label: 'FIRST' },
  { id: 'LAST', label: 'LAST' },
]

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
    },
    title: {
      fontSize: 32,
      fontWeight: 500,
    },
    field: {
      marginTop: 20,
      marginBottom: 20,
    },
    input: {
      width: '100%',
    },
    helpText: {
      color: theme.palette.secondary.main,
      fontSize: 12,
    },
    label: {
      color: theme.palette.primary.main,
    },
    dialogActions: {
      justifyContent: 'flex-start',
      padding: '25px 0 0 25px',
      '& button': {
        minWidth: 100,
      },
    },
    customFieldContainer: {
      height: 250,
      overflowY: 'scroll',
    },
    customFieldList: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px',
    },
  }),
)

interface ShoppableAmbassadorCampaignSettingsProps {
  campaignId: string
  productPickerCollections: ProductPickerCollectionsType
}

function ShoppableAmbassadorCampaignSettings({
  campaignId,
  productPickerCollections,
}: ShoppableAmbassadorCampaignSettingsProps): React.ReactElement {
  const classes = useStyles()

  const { values, setFieldValue, initialValues } = useFormikContext<ProgramSettingsFields>()

  const hasShopifyIntegration = initialValues.storeIntegrationId !== 'none'

  return (
    <Box className={classes.container}>
      <Typography variant="h5">Shoppable Ambassador Settings</Typography>
      <Box className={classes.field} ml={1}>
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: 'Filter Ambassador UGC Block to only approved posts' }}
          name="ambassadorUgcApprovedOnly"
        />
      </Box>
      <Box className={classes.field} ml={1}>
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: 'Show products that are out of stock' }}
          name="showOutOfStockProducts"
        />
      </Box>
      <Box className={classes.field} ml={1}>
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: 'Hide sales price and display "View your price" button' }}
          name="hideSalesPrice"
        />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label} variant="body1">
          Attribution Days
        </Typography>
        <Field className={classes.input} as={TextField} type="number" name="attributionWindow" />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label} variant="body1">
          Attribution Type
        </Typography>
        <SelectField
          hideSearch
          placeholder="Attribution Type?"
          value={values.attributionType}
          setValue={(value: string | number) => setFieldValue('attributionType', value)}
          options={ATTRIBUTION_TYPE_OPTIONS}
        />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label} variant="body1">
          Ambassador Template Url
        </Typography>
        <Field className={classes.input} as={TextField} type="text" name="ambassadorBaseUrl" />
      </Box>
      {hasShopifyIntegration && (
        <Box className={classes.field}>
          <ProductPickerCollectionComponent
            setFieldValue={setFieldValue}
            productPickerCollections={productPickerCollections}
            campaignId={campaignId}
          />
        </Box>
      )}
    </Box>
  )
}

export default ShoppableAmbassadorCampaignSettings
