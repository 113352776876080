import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportsActivityListQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  messageTemplate: Types.IdFilterInput;
  rewards: Types.IdFilterInput;
  customersWhere: Types.CustomerFilterInput;
  startDateRange?: Types.InputMaybe<Types.DateInclusionExclusionFilterInput>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ExportsActivityListQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, sentMessages: { __typename: 'PagedMessages', csvUrl?: string | null } } | { __typename: 'TTSocialAccount', id: string } | null };


export const ExportsActivityListDocument = gql`
    query ExportsActivityList($socialAccountId: ID!, $messageTemplate: IdFilterInput!, $rewards: IdFilterInput!, $customersWhere: CustomerFilterInput!, $startDateRange: DateInclusionExclusionFilterInput, $limit: Int) {
  socialAccount(id: $socialAccountId) {
    id
    ... on IGSocialAccount {
      sentMessages(
        where: {messageTemplate: $messageTemplate, reward: $rewards, sentAt: $startDateRange}
        customersWhere: $customersWhere
        limit: $limit
      ) {
        csvUrl
      }
    }
  }
}
    `;

/**
 * __useExportsActivityListQuery__
 *
 * To run a query within a React component, call `useExportsActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportsActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportsActivityListQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      messageTemplate: // value for 'messageTemplate'
 *      rewards: // value for 'rewards'
 *      customersWhere: // value for 'customersWhere'
 *      startDateRange: // value for 'startDateRange'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useExportsActivityListQuery(baseOptions: Apollo.QueryHookOptions<ExportsActivityListQuery, ExportsActivityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportsActivityListQuery, ExportsActivityListQueryVariables>(ExportsActivityListDocument, options);
      }
export function useExportsActivityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportsActivityListQuery, ExportsActivityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportsActivityListQuery, ExportsActivityListQueryVariables>(ExportsActivityListDocument, options);
        }
export type ExportsActivityListQueryHookResult = ReturnType<typeof useExportsActivityListQuery>;
export type ExportsActivityListLazyQueryHookResult = ReturnType<typeof useExportsActivityListLazyQuery>;
export type ExportsActivityListQueryResult = Apollo.QueryResult<ExportsActivityListQuery, ExportsActivityListQueryVariables>;