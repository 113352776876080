import { Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { formatChartMonthDay } from '../../components/charts/helpers'
import { vRefLine } from '../../components/charts/types'
import ContainerError from '../../components/ContainerError'
import metricMap from '../../components/metric/metric-map'
import MetricLineChart from '../../components/metric/MetricLineChart'
import {
  AbsoluteDateTimeRangeFilter,
  AmbassadorStatsInput,
  DateRangeFilterType,
  DateRangeFilterUnits,
  DateTimeRangeFilter,
  EcommWebEventType,
} from '../../gql-global'
import theme from '../../loudcrowd-theme'
import { DateRange, getPreviousRangeDates, realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import CampaignDashboardOverviewTab from './CampaignDashboardOverviewTab'
import { useCampaignRevenueStatsQuery } from './operations/campaign-revenue-stats.generated'
import { useCampaignQuery } from './operations/campaign.generated'
import { useCampaignSocialStatsQuery } from './operations/campain-social-stats.generated'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'
import useHasFeature from '../../hooks/useHasFeature'

type TabKeys = 'impressions' | 'landings' | 'totalOrders' | 'conversion'
interface Tab {
  readonly id: string
  readonly dataKey: TabKeys
  readonly metricType: keyof typeof metricMap
}

const OVERVIEW_TABS: readonly [Tab, Tab, Tab, Tab] = [
  {
    id: 'IMPRESSIONS',
    dataKey: 'impressions',
    metricType: 'impressions',
  },
  {
    id: 'LANDINGS',
    dataKey: 'landings',
    metricType: 'landings',
  },
  {
    id: 'ORDERS',
    dataKey: 'totalOrders',
    metricType: 'totalOrders',
  },
  {
    id: 'CONVERSION',
    dataKey: 'conversion',
    metricType: 'conversion',
  },
] as const

interface CampaignSocialAttributionProps {
  id: string
  dateRangeFilter: DateTimeRangeFilter | AbsoluteDateTimeRangeFilter | null | undefined
}

function constructAmbassadorStatsInput(dateRange: DateRange | undefined | null, hasLineItemCalculationsFeature: boolean = false): AmbassadorStatsInput {
  const eventTypes = [EcommWebEventType.Landed, EcommWebEventType.DiscountRedeemed]
  if (dateRange) {
    return {
      rangeAt: [dateRange],
      eventTypes: eventTypes,
      disjointRefunds: false,
      allowLineItemCalculations: hasLineItemCalculationsFeature,
    }
  }
  return { eventTypes: eventTypes, disjointRefunds: false, allowLineItemCalculations: hasLineItemCalculationsFeature }
}

function CampaignSocialAttribution({ id, dateRangeFilter }: CampaignSocialAttributionProps): React.ReactElement {
  const [activeOverviewTab, setActiveOverviewTab] = useState<Tab>(OVERVIEW_TABS[0])

  const { data: userData } = useCampaignUserInfoQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const { hasFeature: hasLineItemCalculationsFeature } = useHasFeature('hasLineItemCalculations')

  const socialPlatform = selectedSocialPlatform(userData)

  const { data: campaignData } = useCampaignQuery({
    skip: !socialPlatform,
    variables: { id: id, platform: socialPlatform! },
  })
  const lastThirtyDateRangeFilter: DateTimeRangeFilter = {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  }
  const startAt = campaignData?.campaign?.startAt
  const dateRange = dateRangeFilter && realizedDateRangeFromFilter(dateRangeFilter)
  const previousDateRange = dateRangeFilter
    ? getPreviousRangeDates(dateRangeFilter)
    : getPreviousRangeDates(lastThirtyDateRangeFilter)

  const {
    data: ambassadorStatsData,
    loading: ambassadorStatsLoading,
    error: ambassadorStatsError,
  } = useCampaignRevenueStatsQuery({
    variables: {
      campaignId: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      currentAmbassadorStatsInput: constructAmbassadorStatsInput(dateRange, hasLineItemCalculationsFeature),
      previousAmbassadorStatsInput: constructAmbassadorStatsInput(previousDateRange, hasLineItemCalculationsFeature),
      ambassadorStatsTimeseriesInput: dateRange ? { rangeAt: dateRange, allowLineItemCalculations: hasLineItemCalculationsFeature } : { allowLineItemCalculations: hasLineItemCalculationsFeature },
    },
  })

  const {
    data: socialData,
    loading: socialLoading,
    error: socialError,
  } = useCampaignSocialStatsQuery({
    skip: !selectedSocialAccountId,
    variables: {
      campaignId: id,
      socialAccountFilter: { any: [selectedSocialAccountId || ''] },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      dateRange: dateRange,
      previousDateRange: previousDateRange,
    },
  })

  const impressions = socialData?.campaign?.mentionStats?.impressions || 0
  const previousImpressions = socialData?.campaign?.previousMentionStats?.impressions || 0

  const landings = ambassadorStatsData?.campaign?.ambassadorStats?.landings || 0
  const previousLandings = ambassadorStatsData?.campaign?.previousAmbassadorStats?.landings || 0

  const totalOrders = ambassadorStatsData?.campaign?.ambassadorStats?.totalOrders || 0
  const previousTotalOrders = ambassadorStatsData?.campaign?.previousAmbassadorStats?.totalOrders || 0

  const conversion = (landings === 0 ? 0 : totalOrders / landings) * 100
  const previousConversions = (previousLandings === 0 ? 0 : previousTotalOrders / previousLandings) * 100

  const currentSummary = {
    impressions: impressions,
    landings: landings,
    totalOrders: totalOrders,
    conversion: conversion,
  }

  const previousSummary = {
    impressions: previousImpressions,
    landings: previousLandings,
    totalOrders: previousTotalOrders,
    conversion: previousConversions,
  }

  const timeseries =
    ambassadorStatsData?.campaign?.ambassadorStatsTimeseries?.map((elm, idx) => {
      const impressions = socialData?.campaign?.mentionStatsTimeseries?.[idx]?.impressions || 0
      const orders = elm.totalOrders
      return {
        startDate: elm.startDate,
        endDate: elm.endDate,
        impressions: impressions,
        landings: elm.landings,
        totalOrders: orders,
        conversion: elm.landings === 0 ? 0 : (100 * orders) / elm.landings,
      }
    }) || []

  const refLines: vRefLine[] = []
  if (startAt) {
    for (const stats of timeseries) {
      if (stats.startDate <= startAt && stats.endDate > startAt) {
        refLines.push({
          x: {
            startDate: stats.startDate,
            endDate: stats.endDate,
          },
          topLabel: `Program Start: ${formatChartMonthDay(startAt)}`,
        })
        break
      }
    }
  }
  const loading = ambassadorStatsLoading || socialLoading
  const error = ambassadorStatsError || socialError
  return (
    <Box>
      <Box mt={7} mb={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item xs={11}>
          <Box fontSize="1.125rem" fontWeight="fontWeightBold">
            Program Social Attributions
          </Box>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {OVERVIEW_TABS.map(tab => {
          const metricType = tab.metricType
          return (
            <CampaignDashboardOverviewTab
              active={activeOverviewTab.id === tab.id}
              onClick={(): void => setActiveOverviewTab(tab)}
              key={tab.id}
              metric={tab.dataKey && currentSummary?.[tab.dataKey]}
              previousMetric={tab.dataKey && previousSummary?.[tab.dataKey]}
              loading={loading}
              metricType={metricType}
              dateRangeFilter={dateRangeFilter}
              currencyCode={campaignData?.campaign?.program?.currencyCode || 'USD'}
            />
          )
        })}
      </Box>
      <Box
        height="434px"
        bgcolor={theme.palette.background.paper}
        borderRadius="0 0 8px 8px"
        padding="100px 44px 10px 32px"
      >
        {timeseries && !error && (
          <MetricLineChart
            metricType={activeOverviewTab.metricType}
            data={timeseries}
            dataKey={activeOverviewTab.dataKey}
            vRefLines={refLines}
            doProjection={false}
          />
        )}
        {error && <ContainerError text="Could not load chart" />}
      </Box>
    </Box>
  )
}

export default CampaignSocialAttribution
