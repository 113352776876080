import React from 'react'
import { Box, createStyles, InputLabel, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core'
import { CustomFieldEnumType } from '../../gql-global'
import { TextField, Select } from 'formik-material-ui'
import { Field } from 'formik'
import DatePickerField from '../DatePickerField/DatePickerField'
import CheckBoxField from './CheckBoxField'
import { CustomerDetailInfoCustomFieldType } from '../../customer/CustomerDetailInfo'

export interface CustomFieldValue {
  id: string
  value: string | null
}

interface CustomFieldProps {
  customField: CustomerDetailInfoCustomFieldType[0]
  value: string | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      marginBottom: theme.spacing(4),
    },
    formField: {
      width: '100%',
    },
  }),
)

function CustomField({ customField, value }: CustomFieldProps): React.ReactElement | null {
  const { id, type, title, options } = customField
  const classes = useStyles()
  const fieldName = `customFields.${id}`
  switch (type) {
    case CustomFieldEnumType.Text: {
      return (
        <Box className={classes.formGroup}>
          <Field
            className={classes.formField}
            value={value}
            component={TextField}
            label={title}
            type="text"
            name={fieldName}
          />
        </Box>
      )
    }
    case CustomFieldEnumType.Date: {
      return (
        <Box className={classes.formGroup}>
          <Field name={fieldName} label={title} component={DatePickerField} />
        </Box>
      )
    }
    case CustomFieldEnumType.Select: {
      return (
        <Box className={classes.formGroup}>
          <InputLabel htmlFor={`${id}-select`}>{title}</InputLabel>
          <Field
            labelId={`${id}-select`}
            className={classes.formField}
            component={Select}
            type="select"
            name={fieldName}
          >
            {options &&
              options?.map(o => {
                return (
                  <MenuItem key={`option-${o}`} value={o} selected={o === value}>
                    {o}
                  </MenuItem>
                )
              })}
            <MenuItem key="none" value="">
              Select
            </MenuItem>
          </Field>
        </Box>
      )
    }
    case CustomFieldEnumType.Checkbox: {
      return (
        <Box className={classes.formGroup}>
          <Box display="flex" flexDirection="row" alignItems="center" className={classes.formField}>
            <Field name={fieldName} component={CheckBoxField} value={value} />
            <Typography style={{ marginLeft: 10 }}>{title}</Typography>
          </Box>
        </Box>
      )
    }
    case CustomFieldEnumType.SmsConsent: {
      return (
        <Box className={classes.formGroup}>
          <Box display="flex" flexDirection="row" alignItems="center" className={classes.formField}>
            <Field name={fieldName} component={CheckBoxField} value={value} />
            <Typography style={{ marginLeft: 10 }}>{title}</Typography>
          </Box>
        </Box>
      )
    }
    default: {
      return null
    }
  }
}

export default CustomField
