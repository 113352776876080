import React, { useRef, useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Link,
  makeStyles,
  Theme,
  createStyles,
  Badge,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useParams, useHistory, useRouteMatch, Route, Switch } from 'react-router-dom'
import { format } from 'date-fns'
import withAuthorization from '../../withAuthorization'
import useTitle from '../../utils/use-title'
import Page from '../../Page'
import { ReactComponent as ArrowIcon } from '../../icons/arrow-skinny.svg'
import { ReactComponent as SettingsIcon } from '../../icons/settings_major_monotone.svg'
import { CampaignDetailRouteParams, CAMPAIGN_DETAIL_ROUTE } from '../routes'
import RouterTabs from '../../components/RouterTabs'
import RouterTab from '../../components/RouterTabs/RouterTab'
import { useCampaignQuery } from './operations/campaign.generated'
import CampaignDashboard from './CampaignDashboard'
import CampaignRewardsActivity from './CampaignRewardsActivity'
import CampaignMembers from './CampaignMembers'
import { dateTypePostfix } from '../../utils/date-range-helper'
import CampaignApplicants from './CampaignApplicants'
import HostLink from './HostLink'
import CampaignSettings from './campaign-settings/CampaignSettings'
import { ProgramTypeEnum, UserRoleType } from '../../gql-global'
import CampaignRevenueDashboard from './CampaignRevenueDashboard'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'
import CampaignProgramActivity from './CampaignProgramActivity'
import ShoppableCampaignMembers from './ShoppableCampaignMembers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    tab: {
      fontSize: '16px',
      color: theme.palette.text.primary,
    },
    hostButton: {
      color: theme.palette.text.primary,
    },
    tabWithIcon: {
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        '& :first-child': {
          marginBottom: 0,
          marginRight: 8,
        },
      },
    },
    rightAligned: {
      marginLeft: 'auto',
    },
  }),
)

function CampaignDetail(): React.ReactElement {
  const classes = useStyles()
  const hostButtonRef = useRef<HTMLButtonElement>(null)
  const [hostMenuOpen, setHostMenuOpen] = useState<boolean>(false)
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const { id } = useParams<CampaignDetailRouteParams>()

  const { data: userData } = useCampaignUserInfoQuery()

  const socialPlatform = selectedSocialPlatform(userData)

  const { data, loading, error } = useCampaignQuery({
    skip: !socialPlatform || !socialPlatform,
    variables: { id, platform: socialPlatform! },
  })
  const isOwner =
    data?.whoami?.roles?.some(r => r.name === UserRoleType.Owner || r.name === UserRoleType.Admin) || false

  const { campaign } = data || {}
  const titleParts = ['Program']
  if (campaign?.program?.name) {
    titleParts.unshift(campaign.program.name)
  }
  useTitle(titleParts.join(' - '))
  const notFound = !loading && !error && !campaign

  const showRevenueDashboardTab =
    campaign?.program?.programType === ProgramTypeEnum.ShoppableAmbassador && !!campaign.program.ecommIntegration?.id

  const programDashboardLabel = showRevenueDashboardTab ? 'Revenue Dashboard' : 'Program Dashboard'

  return (
    <Page>
      {!error && !notFound && (
        <>
          <Box display="flex">
            <Box mr={5}>
              <IconButton color="secondary" onClick={() => history.goBack()}>
                <ArrowIcon width={20} height={16} />
              </IconButton>
            </Box>
            <Box flexGrow={1}>
              <Typography variant="h5">
                {loading ? <Skeleton variant="text" width={150} /> : campaign?.program?.name || 'Campaign'}
              </Typography>
              {campaign?.program?.hosts?.length === 1 && campaign?.program?.hosts?.[0] && (
                <HostLink url={campaign.program.hosts[0].url} lcId={campaign.program.hosts[0].lcId} />
              )}
              {!!campaign?.program?.hosts?.length && campaign?.program?.hosts?.length > 1 && (
                <div>
                  <Link
                    component="button"
                    className={classes.hostButton}
                    ref={hostButtonRef}
                    onClick={() => setHostMenuOpen(true)}
                  >
                    Multiple Landing Pages
                  </Link>
                  <Menu
                    id="simple-menu"
                    anchorEl={hostButtonRef.current}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    keepMounted
                    open={hostMenuOpen}
                    onClose={() => setHostMenuOpen(false)}
                  >
                    {campaign.program.hosts.map(h => {
                      return (
                        <MenuItem key={h.lcId}>
                          <HostLink url={h.url} onClose={() => setHostMenuOpen(false)} lcId={h.lcId} />
                        </MenuItem>
                      )
                    })}
                  </Menu>
                </div>
              )}
            </Box>
            <div>
              <Typography variant="body2" color="secondary">
                {campaign?.startAt && `Start${dateTypePostfix(campaign.startAt)}: ${format(campaign.startAt, 'PP')}`}
              </Typography>
            </div>
          </Box>
          <Box mt={7}>
            <RouterTabs className={classes.tabs} indicatorColor="primary" textColor="primary">
              <RouterTab className={classes.tab} label={programDashboardLabel} value={url} replace />
              {showRevenueDashboardTab && (
                <RouterTab className={classes.tab} label="UGC Dashboard" value={`${url}/ugc`} replace />
              )}
              <RouterTab className={classes.tab} label="Members" value={`${url}/members`} replace />
              <RouterTab className={classes.tab} label="Program Activity" value={`${url}/program-activity`} replace />
              <RouterTab className={classes.tab} label="Feed" value={`${url}/activity`} replace />
              {!loading && !campaign?.program?.autoApproved && (
                <RouterTab
                  className={classes.tab}
                  label={
                    <Badge
                      badgeContent={data?.campaign?.applicantStats?.count}
                      max={99}
                      color="error"
                      overlap="rectangular"
                    >
                      Applicants
                    </Badge>
                  }
                  value={`${url}/applicants`}
                  replace
                />
              )}
              {isOwner && (
                <RouterTab
                  className={`${classes.tab} ${classes.tabWithIcon} ${classes.rightAligned}`}
                  label="Settings"
                  icon={<SettingsIcon height="20px" width="20px" />}
                  value={`${url}/settings`}
                  replace
                />
              )}
            </RouterTabs>
            <Box mt={8}>
              <Switch>
                <Route
                  exact
                  path={path}
                  component={showRevenueDashboardTab ? CampaignRevenueDashboard : CampaignDashboard}
                />
                {showRevenueDashboardTab && <Route path={`${path}/ugc`} component={CampaignDashboard} />}
                <Route
                  path={`${path}/members`}
                  component={showRevenueDashboardTab ? ShoppableCampaignMembers : CampaignMembers}
                />
                <Route path={`${path}/program-activity`} component={CampaignProgramActivity} />
                <Route exact path={`${path}/activity`} component={CampaignRewardsActivity} />
                {!loading && !campaign?.program?.autoApproved && (
                  <Route exact path={`${path}/applicants`} component={CampaignApplicants} />
                )}
                <Route exact path={`${path}/settings`} component={CampaignSettings} />
              </Switch>
            </Box>
          </Box>
        </>
      )}
    </Page>
  )
}

export default withAuthorization(CAMPAIGN_DETAIL_ROUTE)(CampaignDetail)
