import React, { useEffect, useRef } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import useTitle from '../utils/use-title'
import { NetworkStatus } from '@apollo/client'
import useFilterParams, { Filters } from './use-filter-params'
import { ProgramFilterInput, ProgramSort, SortDirection } from '../gql-global'
import useSortDirParam from './use-sort-dir-param'
import useSortParam from './use-sort-param'
import { useProgramsUserQuery } from './operations/programs-user.generated'
import { Skeleton } from '@material-ui/lab'
import ContainerError from '../components/ContainerError'
import { NumberParam, useQueryParam } from 'use-query-params'
import ReviewProgramRow from './ReviewProgramRow'

const PAGE_SIZE = 10

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: 1,
      lineHeight: 2,
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
)

const useWhereFilters = (filters: Filters): ProgramFilterInput => {
  return {
    search: !!filters.reviewProgramKeywords.length ? { keywords: filters.reviewProgramKeywords } : null,
  }
}

const ReviewProgramsList: React.FC = () => {
  useTitle('Review')
  const classes = useStyles()
  const { filters } = useFilterParams()
  const whereFilters = useWhereFilters(filters)
  const [sort = ProgramSort.Name, setSort] = useSortParam()
  const [sortDir = SortDirection.Asc, setSortDir] = useSortDirParam()
  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('programsViewing', NumberParam)
  const limitRef = useRef(viewing)

  function setSortAndDir(sortField: ProgramSort) {
    if (sortField === sort) {
      const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
      setSortDir(sortDirection)
      setSort(sortField)
    } else {
      setSort(sortField)
      setSortDir(SortDirection.Desc)
    }
  }

  const {
    loading: loadingProgramUserData,
    data: programUserData,
    error,
    fetchMore,
    networkStatus,
  } = useProgramsUserQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // for updating pending posts and last review when navigating back from program
    variables: {
      sortBy: sort,
      sortDirection: sortDir,
      limit: limitRef.current,
      where: whereFilters,
    },
  })

  // Update view count
  const newResultsLength = programUserData?.programs?.results?.length || limitRef.current
  const newViewingCount = (Math.ceil(newResultsLength / PAGE_SIZE) || 1) * PAGE_SIZE
  useEffect(() => setViewing(newViewingCount), [newViewingCount, setViewing])

  const { cursor } = programUserData?.programs || {}
  function handleLoadMore(): void {
    if (cursor) {
      fetchMore({
        variables: {
          cursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  const loadingMorePrograms = networkStatus === NetworkStatus.fetchMore
  const isLoadingState = loadingProgramUserData || loadingMorePrograms
  const errorState = !loadingProgramUserData && !loadingMorePrograms && !programUserData

  const renderSkeletonTableRow = () => (
    <TableRow>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
    </TableRow>
  )
  function TableHeaderSortCell({
    fieldName,
    sort,
    sortDirection,
  }: {
    fieldName: 'name'
    sort: ProgramSort
    sortDirection: SortDirection
  }) {
    const sortField = ProgramSort.Name
    return (
      <TableCell>
        <TableSortLabel
          direction={sortDirection === SortDirection.Desc ? 'desc' : 'asc'}
          active={sort === sortField}
          onClick={() => setSortAndDir(sortField)}
        >
          {fieldName}
        </TableSortLabel>
      </TableCell>
    )
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" className={classes.title}>
            Programs
          </Typography>
          {!!filters.reviewProgramKeywords.length && (
            <Box ml={4}>
              <Typography>(name contains "{filters.reviewProgramKeywords.join(', ')}")</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={6}>
        {(error || errorState) && (
          <Box display="flex" justifyContent="center">
            <ContainerError text={`Error: ${error && error?.message}` || 'Error loading customers'} />
          </Box>
        )}
        {!errorState && (
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableHeaderSortCell fieldName="name" sort={sort} sortDirection={sortDir} />
                  <TableCell>Account</TableCell>
                  <TableCell>Social Accounts</TableCell>
                  <TableCell>Pending</TableCell>
                  <TableCell>Last Reviewed</TableCell>
                  <TableCell>Last Reviewer</TableCell>
                  <TableCell>Brand Photo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {programUserData?.programs?.results?.map(
                  (program, i): JSX.Element => (
                    <ReviewProgramRow currentUserId={programUserData?.whoami?.id} program={program} key={program.id} />
                  ),
                )}
                {!errorState && isLoadingState && (
                  <>
                    {renderSkeletonTableRow()}
                    {renderSkeletonTableRow()}
                    {renderSkeletonTableRow()}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {!errorState && !isLoadingState && cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore}>
            Load more
          </Button>
        </Box>
      )}
    </>
  )
}

export default ReviewProgramsList
