import { Box, Button, Collapse, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { differenceInMinutes, format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { rewardDeliveryMethodCaption, rewardHasAutoDelivery } from '../../utils/rewards'
import { RewardRowFragment } from '../RewardList/operations/rewards.generated'
import RewardListRowV2 from '../RewardList/RewardListRowV2'
import ChallengeHeaderFilter from './ChallengeHeaderFilter'
import { useAccountChallengeRewardsQuery } from './operations/account-challenge-rewards.generated'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up_minor.svg'
import { ReactComponent as PresentIcon } from '../../icons/present.svg'
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { usePostUserRewardsHistoryQuery } from '../PostDetails/operations/post-user-rewards-history.generated'
import {
  PostDetails_IgMention_Fragment,
  PostDetails_TtMention_Fragment,
} from '../PostDetails/operations/post-details.generated'
import ChallengeTitle from './ChallengeTitle'
import { Skeleton } from '@material-ui/lab'

interface StyledProps {
  width: number
  height: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: (props: StyledProps) => props.width,
      height: (props: StyledProps) => props.height,
      paddingTop: 28,
      overflowX: 'hidden',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      alignItems: 'flex-start',
      '& .MuiCollapse-entered': {
        display: 'flex',
        flex: 'auto',
        height: '100% !important', // MuiCollapse adds height styling on the element, so we need to override it
        '& .MuiButton-contained': {
          opacity: 1,
          transition: 'opacity 0.2s',
        },
      },
      '& .MuiCollapse-wrapper': {
        width: '100%',
      },
      '& .MuiButton-contained': {
        opacity: 0,
        transition: 'opacity 0.2s',
      },
    },
    toggleButton: {
      color: theme.palette.secondary.main,
      fontSize: 16,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  }),
)

interface ChallengeRewardPickerProps {
  width: number
  height: number
  challenges: ReadonlyArray<string>
  onSendReward: (reward: RewardRowFragment, challengeId: string, offline: boolean) => Promise<void>
  selectedPost?: PostDetails_IgMention_Fragment | PostDetails_TtMention_Fragment | null | undefined
  socialAccountId?: string | null | undefined
}

export default function ChallengeRewardPicker({
  width,
  height,
  challenges = [],
  onSendReward,
  selectedPost,
  socialAccountId,
}: ChallengeRewardPickerProps): React.ReactElement {
  const classes = useStyles({ width, height })

  const customerId = selectedPost?.media.poster.customer?.id?.toString() ?? ''
  const mediaId = selectedPost?.media.id

  const showRewardHistoryOnly = !challenges.length

  const [challengeIdFilter, setChallengeIdFilter] = useState<string>()
  const [viewingHistory, setViewingHistory] = useState(false)

  // Used for polling reward history after sending reward
  const [oldFirstRewardId, setFirstRewardId] = useState<string>()
  const [pollingEnabled, setPollingEnabled] = useState(false)

  const { data: accountChallengeRewardsData } = useAccountChallengeRewardsQuery({
    skip: showRewardHistoryOnly,
    variables: { limit: 999, cursor: null },
  })

  const { data, loading, startPolling, stopPolling } = usePostUserRewardsHistoryQuery({
    skip: !selectedPost || !customerId || !socialAccountId,
    variables: {
      id: customerId,
      socialAccountId: socialAccountId ?? '',
      mediaId: mediaId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const rewardsHistoryCount = data?.socialAccount?.customer?.events?.results.length ?? 0
  const firstReward = data?.socialAccount?.customer?.events?.results[0]?.id

  const toggleViewingHistory = () => {
    if (!showRewardHistoryOnly) {
      setViewingHistory(value => !value)
    }
  }

  useEffect(() => {
    if (pollingEnabled && firstReward !== oldFirstRewardId) {
      stopPolling()
      setFirstRewardId(firstReward)
      setPollingEnabled(false)
    }
  }, [rewardsHistoryCount, firstReward, oldFirstRewardId, pollingEnabled, stopPolling])

  const rewardableAccountChallenges = (
    accountChallengeRewardsData?.whoami?.account?.challenges?.results?.filter(c => challenges.includes(c.id)) || []
  )
    .map(challenge => {
      const challengeRewards = challenge.challengeRewards?.filter(cr => {
        const r = cr.reward
        if (!r) {
          return false
        }
        if (r.hasDiscountCode && !r.stats.codesRemaining) {
          return false
        }
        if (rewardHasAutoDelivery(r)) {
          return true
        }
        return !!r.hasDiscountCode
      })

      return {
        ...challenge,
        challengeRewards: challengeRewards,
      }
    })
    .filter(challenge => !!challenge.challengeRewards?.length)

  const challengeAccountLabels = rewardableAccountChallenges.map(c => {
    return {
      id: c.id,
      name: c.name,
    }
  })

  const filteredChallenge = rewardableAccountChallenges.find(c => c.id === challengeIdFilter)
  const filteredAccountChallenges = filteredChallenge ? [filteredChallenge] : rewardableAccountChallenges

  const viewHistoryEndIcon = !showRewardHistoryOnly ? (
    !viewingHistory ? (
      <ChevronDownIcon width={20} />
    ) : (
      <ChevronUpIcon width={20} />
    )
  ) : undefined

  const isPollingOrLoading = loading || pollingEnabled

  return (
    <Box position="relative" className={classes.wrapper}>
      {rewardsHistoryCount > 0 && (
        <Button onClick={toggleViewingHistory} className={classes.toggleButton} endIcon={viewHistoryEndIcon}>
          View reward history
        </Button>
      )}
      <Box px={4} width="100%" height="100%">
        <Collapse in={viewingHistory}>
          {isPollingOrLoading && (
            <Box height="100%">
              <Skeleton height="10%" />
              <Skeleton height="10%" />
              <Skeleton height="10%" />
              <Skeleton height="10%" />
              <Skeleton height="10%" />
              <Skeleton height="10%" />
              <Skeleton height="10%" />
              <Skeleton height="10%" />
            </Box>
          )}
          {!isPollingOrLoading && (
            <Box>
              <Box height="80%" style={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
                {data?.socialAccount?.customer?.events?.results.reduce(
                  (result: React.ReactElement[], e): React.ReactElement[] => {
                    // Because of the query we are making, this won't ever actually happen, we are just doing
                    // it so TypeScript can narrow the types
                    if (e.__typename !== 'FulfilledTierCustomerEvent' || !e.fulfillment?.reward) return result
                    const reward = e.fulfillment.reward
                    const eventAtCaption =
                      differenceInMinutes(new Date(), e.eventAt) > 5 ? `${format(e.eventAt, 'P haaa')}` : 'Just now'

                    const reward_icon = e.fulfillment.challenge?.id ? (
                      <TrophyIcon width={20} height={20} style={{ margin: '0 20px 0 20px' }} />
                    ) : (
                      <PresentIcon width={20} height={20} style={{ margin: '0 20px 0 20px' }} />
                    )
                    const postfixReward = !!e.fulfillment?.rewardDiscountCode?.code
                      ? ` - ${e.fulfillment?.rewardDiscountCode?.code}`
                      : ''
                    result.push(
                      <Box mt={4}>
                        <Typography variant="caption" display="block" color="secondary">
                          Reward sent at {eventAtCaption}
                        </Typography>
                        <Typography variant="caption" display="block" color="secondary">
                          Delivery Method: {rewardDeliveryMethodCaption(reward)}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: '#f3f3f3',
                            minHeight: '50px',
                            width: '90%',
                            borderRadius: '10px',
                            display: 'grid',
                            gridTemplateColumns: '60px auto',
                            alignItems: 'center',
                          }}
                        >
                          {reward_icon}
                          <Typography variant="body1" style={{ fontWeight: 600 }}>
                            {reward.name}
                            {postfixReward}
                          </Typography>
                        </div>
                      </Box>,
                    )
                    return result
                  },
                  [],
                )}
              </Box>
              {!showRewardHistoryOnly && (
                <Box display="flex" height="100px" alignItems="center" marginLeft="20%">
                  <Button color="primary" onClick={toggleViewingHistory}>
                    <Box display="flex" alignItems="center">
                      <Box component="span" mr={2} lineHeight={0}>
                        <PlusIcon width={20} />
                      </Box>
                      <Typography variant="subtitle1">Send another reward</Typography>
                    </Box>
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Collapse>
        <Collapse in={!viewingHistory}>
          {rewardsHistoryCount > 0 && (
            <Box display="flex" mb={4} justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color="secondary">
                {rewardsHistoryCount} other rewards
              </Typography>
            </Box>
          )}
          <Box>
            {challenges.length > 1 && !challengeIdFilter && (
              <Box pb={4}>
                <ChallengeHeaderFilter
                  challenges={challengeAccountLabels}
                  selected={challengeIdFilter}
                  setSelected={(selection: string) => setChallengeIdFilter(selection)}
                />
              </Box>
            )}
            {filteredAccountChallenges.map(challenge => {
              const rewards =
                (challenge?.challengeRewards?.map(cr => cr.reward).filter(r => !!r) as RewardRowFragment[]) || []
              return (
                <Box>
                  <ChallengeTitle
                    name={challenge?.name || ''}
                    selectedFilter={challengeIdFilter}
                    filterOptions={challengeAccountLabels}
                    setFilter={(selection: string) => setChallengeIdFilter(selection)}
                  />
                  <Box style={{ overflow: 'hidden' }} pb={4}>
                    {rewards.map(r => (
                      <RewardListRowV2
                        key={r.id}
                        reward={r}
                        canAutoDeliver
                        canCopyCode
                        onSend={async (reward: RewardRowFragment, offline: boolean) => {
                          if (!!selectedPost) {
                            setFirstRewardId(firstReward)
                            toggleViewingHistory()
                            setPollingEnabled(true)
                          }

                          await onSendReward(reward, challenge?.id || '', offline)

                          if (!!selectedPost) {
                            startPolling(1000)
                            setTimeout(() => {
                              stopPolling()
                              setPollingEnabled(false)
                            }, 25 * 1000)
                          }
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}
