import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPresignedUrlForRewardCodeUploadQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
}>;


export type GetPresignedUrlForRewardCodeUploadQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, presignedUrlForRewardCodeUpload?: { __typename: 'PresignedUrlAndFields', url: string, fields: Array<{ __typename: 'AWSS3PostField', key: string, value: string }> } | null } | null };


export const GetPresignedUrlForRewardCodeUploadDocument = gql`
    query GetPresignedUrlForRewardCodeUpload($accountId: ID!) {
  account(id: $accountId) {
    id
    presignedUrlForRewardCodeUpload {
      url
      fields {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetPresignedUrlForRewardCodeUploadQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlForRewardCodeUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlForRewardCodeUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlForRewardCodeUploadQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetPresignedUrlForRewardCodeUploadQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedUrlForRewardCodeUploadQuery, GetPresignedUrlForRewardCodeUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedUrlForRewardCodeUploadQuery, GetPresignedUrlForRewardCodeUploadQueryVariables>(GetPresignedUrlForRewardCodeUploadDocument, options);
      }
export function useGetPresignedUrlForRewardCodeUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedUrlForRewardCodeUploadQuery, GetPresignedUrlForRewardCodeUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedUrlForRewardCodeUploadQuery, GetPresignedUrlForRewardCodeUploadQueryVariables>(GetPresignedUrlForRewardCodeUploadDocument, options);
        }
export type GetPresignedUrlForRewardCodeUploadQueryHookResult = ReturnType<typeof useGetPresignedUrlForRewardCodeUploadQuery>;
export type GetPresignedUrlForRewardCodeUploadLazyQueryHookResult = ReturnType<typeof useGetPresignedUrlForRewardCodeUploadLazyQuery>;
export type GetPresignedUrlForRewardCodeUploadQueryResult = Apollo.QueryResult<GetPresignedUrlForRewardCodeUploadQuery, GetPresignedUrlForRewardCodeUploadQueryVariables>;