import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { NetworkStatus } from '@apollo/client'
import ProgramRules from './ProgramRules'
import { MentionFilterInput, MentionStatus, ProgramType } from '../gql-global'
import { useReviewProgramQuery } from './operations/review-program.generated'
import { useMentionsPagerQuery } from './operations/mentions-pager.generated'
import { useClaimProgramReviewLockMutation } from './operations/claim-lock.generated'
import Page from '../Page'
import { isTypeName } from '../types/utility'
import ReviewDetails, { UseDetailsType } from './ReviewDetails'

interface ReviewProgramRouteParams {
  programId: string
}

const useProgramMentions = (programId: string): UseDetailsType => {
  const programQuery = useReviewProgramQuery({
    variables: {
      programId,
    },
  })

  const { data } = programQuery
  const campaign = data?.program?.campaign?.isActive ? data.program.campaign : undefined
  const campaignId = campaign?.id

  let where: MentionFilterInput = {
    tagStatus: { any: [MentionStatus.Unverified] },
  }

  const skipStories =
    !!data?.program?.socialAccounts?.find(sa => isTypeName(sa, 'IGSocialAccount')) &&
    data?.program?.contentRules?.skipStories

  if (skipStories) {
    where = {
      ...where,
      wasStoryMentioned: false,
    }
  }
  const mentionsQuery = useMentionsPagerQuery({
    skip: programQuery.loading || !!programQuery.error || !campaignId,
    variables: {
      campaignId: campaignId?.toString() || '',
      where,
    },
    notifyOnNetworkStatusChange: true,
  })

  return {
    program: programQuery.data?.program as unknown as ProgramType,
    mentions: mentionsQuery.data?.campaign?.mentions?.results,
    loading: programQuery.loading || mentionsQuery.networkStatus === NetworkStatus.loading,
    error: programQuery.error || mentionsQuery.error,
    fetchNext: mentionsQuery.refetch,
  }
}

const ReviewProgram: React.FC = () => {
  const { programId } = useParams<ReviewProgramRouteParams>()

  const { program, mentions, ...mentionResult } = useProgramMentions(programId)
  const [claimLock, mutationResult] = useClaimProgramReviewLockMutation()

  const mention = mentions?.[0]

  useEffect(() => {
    void claimLock({ variables: { programId } })
  }, [programId, claimLock])

  return (
    <Page>
      <Box px={12} py={10}>
        <ReviewDetails
          title={`${program?.name} Program`}
          itemQueryResult={{ program, mentions, ...mentionResult }}
          itemId={programId}
          pageTitle={program?.name}
          entityType="program"
          mutationResult={mutationResult}
        />
        {!mentionResult.loading && mention && (
          <Box ml={1} width="100%">
            <Typography variant="h5">Content Rules</Typography>
            {program && <ProgramRules program={program} mention={mention} needsRefetch />}
          </Box>
        )}
      </Box>
    </Page>
  )
}
export default ReviewProgram
