import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { SocialAccountHashtagFragmentFragmentDoc } from './social-account-hashtag-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSocialAccountHashtagMutationVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  hashtag: Types.Scalars['String'];
  active: Types.Scalars['Boolean'];
}>;


export type CreateSocialAccountHashtagMutation = { __typename: 'Mutations', createTikTokSocialAccountHashtag?: { __typename: 'CreateTikTokSocialAccountHashtag', ok: boolean, trackedHashtag?: { __typename: 'SocialAccountHashtagType', id: string, hashtag?: string | null, hashtagId?: string | null, active?: boolean | null, createdAt?: Date | null, updatedAt?: Date | null } | null } | null };


export const CreateSocialAccountHashtagDocument = gql`
    mutation CreateSocialAccountHashtag($socialAccountId: ID!, $hashtag: String!, $active: Boolean!) {
  createTikTokSocialAccountHashtag(
    socialAccountId: $socialAccountId
    hashtag: $hashtag
    active: $active
  ) {
    ok
    trackedHashtag {
      ...SocialAccountHashtagFragment
    }
  }
}
    ${SocialAccountHashtagFragmentFragmentDoc}`;
export type CreateSocialAccountHashtagMutationFn = Apollo.MutationFunction<CreateSocialAccountHashtagMutation, CreateSocialAccountHashtagMutationVariables>;

/**
 * __useCreateSocialAccountHashtagMutation__
 *
 * To run a mutation, you first call `useCreateSocialAccountHashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSocialAccountHashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSocialAccountHashtagMutation, { data, loading, error }] = useCreateSocialAccountHashtagMutation({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      hashtag: // value for 'hashtag'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateSocialAccountHashtagMutation(baseOptions?: Apollo.MutationHookOptions<CreateSocialAccountHashtagMutation, CreateSocialAccountHashtagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSocialAccountHashtagMutation, CreateSocialAccountHashtagMutationVariables>(CreateSocialAccountHashtagDocument, options);
      }
export type CreateSocialAccountHashtagMutationHookResult = ReturnType<typeof useCreateSocialAccountHashtagMutation>;
export type CreateSocialAccountHashtagMutationResult = Apollo.MutationResult<CreateSocialAccountHashtagMutation>;
export type CreateSocialAccountHashtagMutationOptions = Apollo.BaseMutationOptions<CreateSocialAccountHashtagMutation, CreateSocialAccountHashtagMutationVariables>;