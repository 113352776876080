import { useQueryParam, StringParam } from 'use-query-params'
import { cleanAllAnyNoneFilter } from '../utils/filter-params'

export const MESSAGE_KEYWORDS_SEARCH_PARAM_KEY = 'messageKeywords'
export const messageKeywordsParam = StringParam

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useMessageKeywordsParam() {
  const [keywordsJSON, setKeywordsJSON] = useQueryParam(MESSAGE_KEYWORDS_SEARCH_PARAM_KEY, messageKeywordsParam)
  const messageKeywordsJson = keywordsJSON ? JSON.parse(keywordsJSON) : undefined
  const setMessageKeywordsSearchURLParam = (
    newValue: string[] | null | undefined,
    updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined,
  ) => {
    setKeywordsJSON(newValue ? JSON.stringify({ all: [newValue] }) : undefined, updateType)
  }
  return [
    (cleanAllAnyNoneFilter(messageKeywordsJson)?.all?.[0] ?? []) as string[],
    setMessageKeywordsSearchURLParam,
  ] as const
}
