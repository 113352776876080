import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLabelWithMentionMutationVariables = Types.Exact<{
  mentionId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type CreateLabelWithMentionMutation = { __typename: 'Mutations', createLabel?: { __typename: 'CreateLabel', ok?: boolean | null, label?: { __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string, account?: { __typename: 'AccountType', id: number } | null } | null, mentions?: Array<{ __typename: 'IGMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null } | { __typename: 'TTMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null }> | null } | null };


export const CreateLabelWithMentionDocument = gql`
    mutation CreateLabelWithMention($mentionId: ID!, $name: String!) {
  createLabel(mentionIds: [$mentionId], name: $name) {
    label {
      account {
        id
      }
      id
      isRejectionReason
      name
    }
    mentions {
      id
      labels {
        id
      }
    }
    ok
  }
}
    `;
export type CreateLabelWithMentionMutationFn = Apollo.MutationFunction<CreateLabelWithMentionMutation, CreateLabelWithMentionMutationVariables>;

/**
 * __useCreateLabelWithMentionMutation__
 *
 * To run a mutation, you first call `useCreateLabelWithMentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelWithMentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelWithMentionMutation, { data, loading, error }] = useCreateLabelWithMentionMutation({
 *   variables: {
 *      mentionId: // value for 'mentionId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateLabelWithMentionMutation(baseOptions?: Apollo.MutationHookOptions<CreateLabelWithMentionMutation, CreateLabelWithMentionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLabelWithMentionMutation, CreateLabelWithMentionMutationVariables>(CreateLabelWithMentionDocument, options);
      }
export type CreateLabelWithMentionMutationHookResult = ReturnType<typeof useCreateLabelWithMentionMutation>;
export type CreateLabelWithMentionMutationResult = Apollo.MutationResult<CreateLabelWithMentionMutation>;
export type CreateLabelWithMentionMutationOptions = Apollo.BaseMutationOptions<CreateLabelWithMentionMutation, CreateLabelWithMentionMutationVariables>;