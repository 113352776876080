import { Box, CircularProgress, createStyles, Dialog, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import {
  CreateChallengeInput,
  CreateChallengeRewardInput,
  CriteriaOperatorEnum,
  IgMediaPostType,
  Maybe,
} from '../gql-global'
import theme from '../loudcrowd-theme'
import AddEditChallengeContent from './AddEditChallengeContent'
import PaintedDoorContent from './PaintedDoorContent'
import { useCreateChallengeMutation } from './operations/create-challenge.generated'
import { useChallengeUserDataQuery } from './operations/challenge-user-data.generated'
import { useChallengeDetailsQuery } from './operations/query-challenge-details.generated'
import { useUpdateChallengeMutation } from './operations/update-challenge.generated'
import { buildAdditionalCriteria, clearChallengesAndSavedFilters } from './utils/challenge-utils'
import { useToast } from '../components/Alert/ToastProvider'
import { ChallengeFragment } from './operations/challenge.generated'
import { useChallengeValidationSchema } from './ChallengeValidationSchema'
import { IgChallengeSaFragment } from './operations/ig-challenge-sa.generated'
import { TtChallengeSaFragment } from './operations/tt-challenge-sa.generated'

const REWARD_QUERY_LIMIT = 200

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: '50px',
      marginLeft: '50px',
      fontSize: 40,
    },
    dialogContent: {
      minWidth: 900,
    },
    inputText: {
      marginBottom: theme.spacing(5),
      width: '250px',
      marginLeft: '50px',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
          borderWidth: '2px',
        },
      },
    },
    label: {
      marginLeft: '50px',
      color: theme.palette.primary.main,
    },
    button: {
      marginRight: '50px',
    },
  }),
)

export interface AddChallengeFormFields extends CreateChallengeInput {
  dates?: { gte?: Maybe<Date>; lt?: Maybe<Date> }
}

type SAFragment =
  | ({ __typename: 'IGSocialAccount' } & IgChallengeSaFragment)
  | ({ __typename: 'TTSocialAccount' } & TtChallengeSaFragment)

interface AddEditChallengeProps {
  open: boolean
  challengeId?: string
  selectedSocialAccount: Maybe<string> | undefined
  socialAccounts: SAFragment[]
  onClose(): void
  accountChallenges: ChallengeFragment[]
}

export type ChallengeDialogState = 'CREATE' | 'PAINTED_DOOR'

function AddEditChallengeDialog(props: AddEditChallengeProps): React.ReactElement {
  const classes = useStyles()
  const { open, onClose, socialAccounts, selectedSocialAccount, challengeId, accountChallenges } = props

  const [state, setState] = useState<ChallengeDialogState>('CREATE')
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [selectedChallengeId, setSelectedChallengeId] = useState<string | undefined>(challengeId)
  const { showToast } = useToast()

  const { data: userData } = useChallengeUserDataQuery({
    skip: !selectedSocialAccount,
    variables: {
      socialAccountId: selectedSocialAccount ?? '',
      limit: REWARD_QUERY_LIMIT,
    },
  })
  const { data, loading: isLoading } = useChallengeDetailsQuery({
    skip: !selectedChallengeId,
    variables: { challengeId: selectedChallengeId || '' },
  })

  const [createChallenge] = useCreateChallengeMutation({
    update(cache, { data }) {
      const campaignIds: number[] = []
      const programs = data?.createChallenge?.challenge?.programs || []
      for (let i = 0; i < programs.length; i++) {
        const campaignId = programs[i]?.campaign?.id
        if (campaignId !== undefined) {
          campaignIds.push(campaignId)
        }
      }
      clearChallengesAndSavedFilters(
        cache,
        campaignIds,
        data?.createChallenge?.challenge?.socialAccounts?.map(s => s.id),
      )
    },
    refetchQueries: ['AccountChallenges'],
  })
  const [updateChallenge] = useUpdateChallengeMutation({
    update(cache, { data }) {
      const campaignIds: number[] = []
      const programs = data?.updateChallenge?.challenge?.programs || []
      for (let i = 0; i < programs.length; i++) {
        const campaignId = programs[i]?.campaign?.id
        if (campaignId !== undefined) {
          campaignIds.push(campaignId)
        }
      }
      clearChallengesAndSavedFilters(
        cache,
        campaignIds,
        data?.updateChallenge?.challenge?.socialAccounts?.map(s => s.id),
      )
    },
  })

  const initSocialAccountIds = data?.challenge?.socialAccounts?.map(account => account.id)

  const schema = useChallengeValidationSchema(accountChallenges, data?.challenge)
  const rewards = (userData?.whoami?.account?.rewards?.results.map(reward => ({
    id: reward.id,
    name: reward.name ?? undefined,
  })) ?? []) as AccountLabel[]
  const paintedDoor = data?.challenge?.paintedDoor
  const initValues = {
    name: data?.challenge?.name ?? '',
    dates: { gte: data?.challenge?.startedAt || undefined, lt: data?.challenge?.endedAt || undefined },
    programs: data?.challenge?.programs?.map(p => p.id.toString()) ?? [],
    hashtags: (data?.challenge?.hashtags as string[]) ?? [],
    hashtagsCriteriaOperator: data?.challenge?.hashtagsCriteriaOperator ?? CriteriaOperatorEnum.All,
    keywords: (data?.challenge?.keywords as string[]) ?? [],
    keywordsCriteriaOperator: data?.challenge?.keywordsCriteriaOperator ?? CriteriaOperatorEnum.All,
    postTypes: (data?.challenge?.postTypes as IgMediaPostType[]) ?? [],
    socialAccountId: selectedSocialAccount ?? '',
    socialAccountIds: initSocialAccountIds || (selectedSocialAccount ? [selectedSocialAccount] : []),
    additionalCriteria: data?.challenge?.additionalCriteria ?? {
      criteriaDescription: '',
      storyCriteriaDescription: '',
      hasAdditionalCriteria: false,
      shouldMeetProgramRules: false,
    },
    paintedDoor: {
      briefDeliveryMethods: paintedDoor?.briefDeliveryMethods ?? [],
      briefContent: paintedDoor?.briefContent ?? '',
      automatedMessageTriggers: paintedDoor?.automatedMessageTriggers ?? [],
      automatedMessageDeliveryMethods: paintedDoor?.automatedMessageDeliveryMethods ?? [],
      automatedMessageContent: paintedDoor?.automatedMessageContent ?? '',
      winningInstructions:
        paintedDoor?.winningInstructions?.map(item => ({
          rewardId: item.reward?.id.toString() ?? '',
          instructions: item.instructions,
        })) ?? [],
    },
    rewards: data?.challenge?.challengeRewards?.length
      ? data.challenge.challengeRewards.reduce((results: CreateChallengeRewardInput[], { reward, winningCriteria }) => {
          return reward?.id ? [...results, { rewardId: reward.id.toString(), winningCriteria }] : results
        }, [])
      : [{} as CreateChallengeRewardInput],
  }

  useEffect(() => {
    setSelectedChallengeId(challengeId)
  }, [challengeId])

  const handleClose = function () {
    setState('CREATE')
    setSelectedChallengeId(undefined)
    onClose()
  }

  const handleSubmitChallenge = async (values: AddChallengeFormFields) => {
    const {
      name,
      socialAccountIds,
      rewards,
      programs,
      dates,
      postTypes,
      hashtags,
      hashtagsCriteriaOperator,
      keywords,
      keywordsCriteriaOperator,
      paintedDoor,
      additionalCriteria,
    } = values

    const startedAt = dates?.gte
    const endedAt = dates?.lt

    const hasExtraCriteria =
      !!additionalCriteria?.storyCriteriaDescription ||
      !!additionalCriteria?.criteriaDescription ||
      additionalCriteria?.shouldMeetProgramRules
    const additionalCriteriaValues: Partial<AddChallengeFormFields> = {
      hashtags,
      keywords,
      keywordsCriteriaOperator,
      hashtagsCriteriaOperator,
    }

    startedAt?.setHours(0, 0, 0, 0)
    endedAt?.setHours(0, 0, 0, 0)

    const variables = {
      challenge: {
        name,
        socialAccountIds,
        startedAt: startedAt,
        endedAt: endedAt,
        rewards: rewards && rewards[0]?.rewardId ? rewards : [],
        programs: programs ?? [],
        postTypes,
        hashtags,
        hashtagsCriteriaOperator,
        keywords,
        keywordsCriteriaOperator,
        additionalCriteria: {
          ...additionalCriteria,
          hasAdditionalCriteria: hasExtraCriteria,
          criteriaDescription: buildAdditionalCriteria(additionalCriteriaValues),
        },
        paintedDoor: {
          ...paintedDoor,
          winningInstructions: paintedDoor?.winningInstructions?.filter(item =>
            rewards?.find(reward => reward.rewardId === item.rewardId),
          ),
        },
      },
    }
    if (!!selectedChallengeId) {
      await updateChallenge({
        variables: { id: selectedChallengeId, ...variables },
        awaitRefetchQueries: true,
        refetchQueries: ['AccountChallenges', 'ChallengeList'],
      })
    } else {
      const { data: newChallenge } = await createChallenge({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: ['AccountChallenges', 'ChallengeList'],
      })
      setSelectedChallengeId(newChallenge?.createChallenge?.challenge?.id)
    }
  }

  const handleSubmit = async (values: AddChallengeFormFields) => {
    try {
      setIsSaving(true)
      await handleSubmitChallenge(values)
      setState('PAINTED_DOOR')
      setIsSaving(false)

      if (state === 'PAINTED_DOOR') {
        handleClose()
      }
    } catch (error) {
      const operation = selectedChallengeId ? 'Updating' : 'Creating'
      setIsSaving(false)
      showToast({
        title: `Error: ${operation} Challenge`,
        message: `Something went wrong when ${operation.toLowerCase()} this challenge, please try again. ${error}`,
      })
      handleClose()
    }
  }

  const handleOnEdit = function () {
    setState('CREATE')
  }

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogContent }}>
      <Formik<AddChallengeFormFields>
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initValues}
      >
        {isSaving || isLoading ? (
          <Box minHeight="60vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Form noValidate>
            {state === 'CREATE' ? (
              <AddEditChallengeContent
                onClose={handleClose}
                socialAccounts={socialAccounts}
                socialAccountContext={selectedSocialAccount || ''}
                challengeId={selectedChallengeId}
                challengeStatus={data?.challenge?.currentStatus}
                userData={userData}
              />
            ) : (
              <PaintedDoorContent
                onClose={handleClose}
                onEdit={handleOnEdit}
                rewards={rewards}
                currentStatus={data?.challenge?.currentStatus}
              />
            )}
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddEditChallengeDialog
