import React, { ReactElement } from 'react'
import { Box, Typography } from '@material-ui/core'
import { DiscountCodeSettingBlockType } from './DiscountSettingsBlock'

interface SettingsBlockProps {
  blocks: DiscountCodeSettingBlockType[]
}

function SettingsBlocks({ blocks }: SettingsBlockProps): ReactElement {
  return (
    <Box display="flex" padding={5} gridGap={25}>
      {blocks.map((block, idx) => (
        <Box flex={1} key={idx}>
          <Typography variant="caption">{block.title}</Typography>
          <Box display="flex" flexDirection="column" paddingLeft={3}>
            {block.items.map((item, itemIdx: number) => (
              <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop={2}>
                <Box display="flex" flexDirection="column" gridGap={5}>
                  <Typography variant="caption" color="secondary" key={itemIdx}>
                    {item.title}
                  </Typography>
                </Box>
                <Box textAlign="right" display="flex" flexDirection="column" gridGap={5}>
                  <Typography variant="caption" key={itemIdx}>
                    {item.value}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SettingsBlocks
