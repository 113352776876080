import { BooleanParam, useQueryParams, encodeQueryParams, stringify, NumberParam, StringParam } from 'use-query-params'
import { DecodedValueMap } from 'serialize-query-params'
import {
  LabelsFilter,
  KeywordsFilterInput,
  MessageSendingMethodsFilterInput,
  DateInclusionExclusionFilterInput,
} from '../gql-global'
import { NumericalRange } from '../components/NumericalRangePicker'
import { MESSAGE_KEYWORDS_SEARCH_PARAM_KEY, messageKeywordsParam } from './use-message-keywords-param'
import { BIOGRAPHY_SEARCH_PARAM_KEY, biographyParam } from './use-biography-param'
import { USERNAME_SEARCH_PARAM_KEY, usernameParam } from './use-username-param'
import { cleanAllAnyNoneFilter } from '../utils/filter-params'
import {
  getDateFilterParamsConfig,
  getDateInclusionExclusionParamsToFilters,
  getDateInclusionExclusionFiltersToParams,
} from '../utils/filter-params'

export interface Filters {
  [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]?: KeywordsFilterInput
  followerCount?: NumericalRange
  biographyKeywords?: string[]
  segments?: Omit<LabelsFilter, '__typename'>
  messageTemplate?: Omit<LabelsFilter, '__typename'>
  reward?: Omit<LabelsFilter, '__typename'>
  sentAt?: Omit<DateInclusionExclusionFilterInput, '__typename'>
  automationEnabled?: boolean
  campaigns?: Omit<LabelsFilter, '__typename'>
  usernameKeywords?: string[]
  messageSendingMethod?: MessageSendingMethodsFilterInput
}

const filterParamConfig = {
  [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: messageKeywordsParam,
  followerCountGte: NumberParam,
  followerCountLte: NumberParam,
  postCountGte: NumberParam,
  postCountLte: NumberParam,
  [BIOGRAPHY_SEARCH_PARAM_KEY]: biographyParam,
  segments: StringParam,
  messageTemplate: StringParam,
  reward: StringParam,
  ...getDateFilterParamsConfig('sentAt', 'exclude'),
  ...getDateFilterParamsConfig('sentAt', 'require'),
  automationEnabled: BooleanParam,
  campaigns: StringParam,
  [USERNAME_SEARCH_PARAM_KEY]: usernameParam,
  messageSendingMethod: StringParam,
}

type MessageParams = Partial<DecodedValueMap<typeof filterParamConfig>>

function paramsToFilters(params: MessageParams): Filters {
  const {
    followerCountLte,
    followerCountGte,
    segments,
    campaigns,
    messageTemplate,
    messageKeywords,
    reward,
    automationEnabled,
    messageSendingMethod,
  } = params

  const segmentsJson = segments ? JSON.parse(segments) : undefined
  const campaignsJson = campaigns ? JSON.parse(campaigns) : undefined
  const messageKeywordsJson = messageKeywords ? JSON.parse(messageKeywords) : undefined
  const messageTemplateJson = messageTemplate ? JSON.parse(messageTemplate) : undefined
  const rewardJson = reward ? JSON.parse(reward) : undefined

  const messageSendingMethodJson = messageSendingMethod ? JSON.parse(messageSendingMethod) : undefined
  return {
    followerCount:
      followerCountGte !== undefined || followerCountLte !== undefined
        ? { lte: followerCountLte, gte: followerCountGte }
        : undefined,
    biographyKeywords: params[BIOGRAPHY_SEARCH_PARAM_KEY] || undefined,
    segments:
      segmentsJson && (segmentsJson.all || segmentsJson.none)
        ? {
            all: segmentsJson?.all ? (segmentsJson.all as string[][]) : undefined,
            none: segmentsJson?.none ? (segmentsJson?.none as string[]) : undefined,
          }
        : undefined,
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: cleanAllAnyNoneFilter(messageKeywordsJson),
    sentAt: getDateInclusionExclusionParamsToFilters('sentAt', params),
    reward:
      rewardJson && (rewardJson?.any || rewardJson?.none)
        ? {
            any: rewardJson?.any || undefined,
            none: rewardJson?.none || undefined,
          }
        : undefined,
    messageTemplate:
      messageTemplateJson && (messageTemplateJson.any || messageTemplateJson.none)
        ? {
            any: messageTemplateJson?.any ? (messageTemplateJson.any as string[]) : undefined,
            none: messageTemplateJson?.none ? (messageTemplateJson?.none as string[]) : undefined,
          }
        : undefined,
    automationEnabled,
    campaigns:
      campaignsJson && (campaignsJson?.any || campaignsJson?.none)
        ? {
            any: campaignsJson?.any || undefined,
            none: campaignsJson?.none || undefined,
          }
        : undefined,
    usernameKeywords: params[USERNAME_SEARCH_PARAM_KEY] || undefined,
    messageSendingMethod: cleanAllAnyNoneFilter(messageSendingMethodJson),
  }
}

function filtersToParams(filters: Filters): MessageParams {
  return {
    followerCountGte: filters.followerCount?.gte,
    followerCountLte: filters.followerCount?.lte,
    [BIOGRAPHY_SEARCH_PARAM_KEY]: filters.biographyKeywords,
    segments: JSON.stringify(cleanAllAnyNoneFilter(filters.segments)),
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: JSON.stringify(
      cleanAllAnyNoneFilter(filters[MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]),
    ),
    reward: JSON.stringify(cleanAllAnyNoneFilter(filters.reward)),
    messageTemplate: JSON.stringify(cleanAllAnyNoneFilter(filters.messageTemplate)),
    automationEnabled: filters.automationEnabled,
    ...getDateInclusionExclusionFiltersToParams('sentAt', filters.sentAt),
    campaigns: JSON.stringify(cleanAllAnyNoneFilter(filters.campaigns)),
    [USERNAME_SEARCH_PARAM_KEY]: filters.usernameKeywords,
    messageSendingMethod: JSON.stringify(cleanAllAnyNoneFilter(filters.messageSendingMethod)),
  }
}

/*
 * encodes message filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Partial<Filters>): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  return {
    filters: paramsToFilters(filterParams),
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, f ? 'replaceIn' : 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
