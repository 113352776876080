import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { CustomerNoteFragmentFragmentDoc } from './customer-note-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerNoteMutationVariables = Types.Exact<{
  noteId: Types.Scalars['ID'];
  updatedNote: Types.Scalars['String'];
  updatedCategoryLcid?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type UpdateCustomerNoteMutation = { __typename: 'Mutations', updateCustomerNote?: { __typename: 'UpdateCustomerNote', ok?: boolean | null, note?: { __typename: 'CustomerNoteType', id: string, createdAt: Date, deletedAt?: Date | null, note: string, author: { __typename: 'UserType', id: number, email: string }, category?: { __typename: 'CustomerNoteCategory', id: string, name: string } | null } | null } | null };


export const UpdateCustomerNoteDocument = gql`
    mutation UpdateCustomerNote($noteId: ID!, $updatedNote: String!, $updatedCategoryLcid: ID) {
  updateCustomerNote(
    noteId: $noteId
    updatedNote: $updatedNote
    updatedCategoryLcid: $updatedCategoryLcid
  ) {
    ok
    note {
      ...CustomerNoteFragment
    }
  }
}
    ${CustomerNoteFragmentFragmentDoc}`;
export type UpdateCustomerNoteMutationFn = Apollo.MutationFunction<UpdateCustomerNoteMutation, UpdateCustomerNoteMutationVariables>;

/**
 * __useUpdateCustomerNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerNoteMutation, { data, loading, error }] = useUpdateCustomerNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      updatedNote: // value for 'updatedNote'
 *      updatedCategoryLcid: // value for 'updatedCategoryLcid'
 *   },
 * });
 */
export function useUpdateCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerNoteMutation, UpdateCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerNoteMutation, UpdateCustomerNoteMutationVariables>(UpdateCustomerNoteDocument, options);
      }
export type UpdateCustomerNoteMutationHookResult = ReturnType<typeof useUpdateCustomerNoteMutation>;
export type UpdateCustomerNoteMutationResult = Apollo.MutationResult<UpdateCustomerNoteMutation>;
export type UpdateCustomerNoteMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerNoteMutation, UpdateCustomerNoteMutationVariables>;