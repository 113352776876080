import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAllDiscountRedeemCodesMutationVariables = Types.Exact<{
  ecommDiscountCodeId: Types.Scalars['ID'];
}>;


export type DeleteAllDiscountRedeemCodesMutation = { __typename: 'Mutations', deleteAllDiscountRedeemCodes?: { __typename: 'DeleteAllDiscountRedeemCodes', ok: boolean } | null };


export const DeleteAllDiscountRedeemCodesDocument = gql`
    mutation DeleteAllDiscountRedeemCodes($ecommDiscountCodeId: ID!) {
  deleteAllDiscountRedeemCodes(ecommDiscountCodeId: $ecommDiscountCodeId) {
    ok
  }
}
    `;
export type DeleteAllDiscountRedeemCodesMutationFn = Apollo.MutationFunction<DeleteAllDiscountRedeemCodesMutation, DeleteAllDiscountRedeemCodesMutationVariables>;

/**
 * __useDeleteAllDiscountRedeemCodesMutation__
 *
 * To run a mutation, you first call `useDeleteAllDiscountRedeemCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllDiscountRedeemCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllDiscountRedeemCodesMutation, { data, loading, error }] = useDeleteAllDiscountRedeemCodesMutation({
 *   variables: {
 *      ecommDiscountCodeId: // value for 'ecommDiscountCodeId'
 *   },
 * });
 */
export function useDeleteAllDiscountRedeemCodesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllDiscountRedeemCodesMutation, DeleteAllDiscountRedeemCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllDiscountRedeemCodesMutation, DeleteAllDiscountRedeemCodesMutationVariables>(DeleteAllDiscountRedeemCodesDocument, options);
      }
export type DeleteAllDiscountRedeemCodesMutationHookResult = ReturnType<typeof useDeleteAllDiscountRedeemCodesMutation>;
export type DeleteAllDiscountRedeemCodesMutationResult = Apollo.MutationResult<DeleteAllDiscountRedeemCodesMutation>;
export type DeleteAllDiscountRedeemCodesMutationOptions = Apollo.BaseMutationOptions<DeleteAllDiscountRedeemCodesMutation, DeleteAllDiscountRedeemCodesMutationVariables>;