import React from 'react'
import clsx from 'clsx'
import { Box, Typography, createStyles, makeStyles, Theme, Card, CardActionArea, CardContent } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { RewardType } from '../../gql-global'
import { ReactComponent as GiftLogo } from '../../icons/gift.svg'
import IntegrationSentWith from '../../components/IntegrationSentWith'
import { getIntegrationBrandColor } from '../../utils/rewards'
import CircleCheck from '../../components/CircleCheck'

interface StyledProps {
  brandColor: string | undefined
  isLoading: boolean
  isSelected: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.spacing(2),
      position: 'relative',
      overflow: 'hidden',
    },
    gift: {
      fill: (props: StyledProps) => (props.brandColor ? props.brandColor : 'none'),
      position: 'absolute',
      bottom: -10,
      right: 200,
    },
    title: {
      fontSize: 20,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 300,
    },
    cardContent: {
      flexDirection: 'column',
      padding: theme.spacing(5, 5, 5, 14),
      position: 'relative',
      height: 110,
      borderRadius: theme.spacing(2),
      border: '2px solid transparent',
      '&:hover, &.selected': {
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: '2px 4px 30px 0px rgba(91, 0, 249, 0.28)',
        cursor: 'pointer',
        '&::before': {
          borderTopLeftRadius: theme.spacing(0.8),
          borderBottomLeftRadius: theme.spacing(0.8),
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 18,
        height: '100%',
        backgroundColor: (props: StyledProps) => (props.brandColor ? props.brandColor : 'none'),
      },
    },
    details: {
      color: '#7C7CA1',
    },
    cornerLogo: {
      position: 'absolute',
      bottom: 10,
      right: 10,
    },
  }),
)

interface RewardRowProps {
  reward?: Partial<RewardType>
  loading?: boolean
  selected?: boolean
  onClick: () => void
}

const RewardCard: React.FC<RewardRowProps> = ({ reward, loading = false, selected = false, onClick }) => {
  const { name, integration } = reward || {}

  const brandColor = getIntegrationBrandColor(integration?.integrationType)

  const classes = useStyles({ brandColor, isLoading: loading, isSelected: selected })

  const deliveryMethod =
    reward && reward?.deliveryMethod
      ? reward.deliveryMethod
      : reward?.isDmReward
      ? 'Instagram DM'
      : reward && reward?.integration?.deliveryMethods && reward?.integration?.deliveryMethods.length > 0
      ? reward?.integration?.deliveryMethods?.join(', ')
      : 'Manual'

  if (loading) {
    return (
      <Card className={classes.card} data-testid="reward-card-loading">
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <Skeleton variant="rect" width="100%" height={20} />
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className={classes.card} onClick={onClick}>
      <Box position="relative">
        <CardActionArea component="div" className={clsx(classes.cardContent, selected && 'selected')}>
          <Box position="absolute" right={0} top={0}>
            <IntegrationSentWith integrationType={reward?.integration?.integrationType} hideWording />
          </Box>
          {name && (
            <Typography title={name} className={classes.title}>
              {name}
            </Typography>
          )}
          <Typography variant="body2" className={classes.details}>
            Delivery Method: {deliveryMethod}
          </Typography>
          {selected && <CircleCheck className={classes.cornerLogo} />}
          <GiftLogo className={classes.gift} fill={brandColor} width={83} />
        </CardActionArea>
      </Box>
    </Card>
  )
}

export default RewardCard
