import { useQueryParams, encodeQueryParams, stringify } from 'use-query-params'

import { ACCOUNT_SEARCH_PARAM_KEY, accountParam } from './use-account-param'

export interface Filters {
  accountKeywords: string[]
}

interface AccountParams {
  [ACCOUNT_SEARCH_PARAM_KEY]?: string[]
}

function paramsToFilters(params: AccountParams): Filters {
  return {
    accountKeywords: params[ACCOUNT_SEARCH_PARAM_KEY] || [],
  }
}

function filtersToParams(filters: Filters): AccountParams {
  return {
    [ACCOUNT_SEARCH_PARAM_KEY]: filters.accountKeywords,
  }
}

const filterParamConfig = {
  [ACCOUNT_SEARCH_PARAM_KEY]: accountParam,
}

/*
 * encodes account filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Filters): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  const filters = paramsToFilters(filterParams)
  return {
    filters,
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
