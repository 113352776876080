import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  AUTH0_CONFIRM_EMAIL_ROUTE,
  AUTH0_FORGOT_PASSWORD_EMAIL_ROUTE,
  AUTH0_FORGOT_PASSWORD_ROUTE,
  AUTH0_LOGIN_ROUTE,
  AUTH0_LOGOUT_ROUTE,
  AUTH0_SIGNUP_ROUTE,
  GOOGLE_AUTH_ROUTE,
  SHOPIFY_AUTH_ROUTE,
} from './routes'
import Auth0Login from './Auth0Login'
import Auth0Logout from './Auth0Logout'
import Auth0ConfirmEmail from './Auth0ConfirmEmail'
import Auth0ForgotPasswordEmail from './Auth0ForgotPasswordEmail'
import Auth0Signup from './Auth0Signup'
import Auth0ForgotPassword from './Auth0ForgotPassword'
import ShopifyAuth from './ShopifyAuth'
import GoogleAuth from './GoogleAuth'

const Auth: React.FC = () => {
  return (
    <Switch>
      <Route path={AUTH0_LOGIN_ROUTE.path} component={Auth0Login} />
      <Route path={AUTH0_SIGNUP_ROUTE.path} component={Auth0Signup} />
      <Route path={AUTH0_LOGOUT_ROUTE.path} component={Auth0Logout} />
      <Route path={AUTH0_CONFIRM_EMAIL_ROUTE.path} component={Auth0ConfirmEmail} />
      <Route path={AUTH0_FORGOT_PASSWORD_ROUTE.path} component={Auth0ForgotPassword} />
      <Route path={AUTH0_FORGOT_PASSWORD_EMAIL_ROUTE.path} component={Auth0ForgotPasswordEmail} />
      <Route path={SHOPIFY_AUTH_ROUTE.path} component={ShopifyAuth} />
      <Route path={GOOGLE_AUTH_ROUTE.path} component={GoogleAuth} />
    </Switch>
  )
}

export default Auth
