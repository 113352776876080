import React, { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import CampaignDashboardOverviewTab from './CampaignDashboardOverviewTab'
import metricMap from '../../components/metric/metric-map'
import MetricLineChart from '../../components/metric/MetricLineChart'
import {
  AllOrderStatsFieldsFragment,
  QueryCampaignOrderStatsQuery,
} from './operations/query-overview-dashboard.generated'
import ContainerError from '../../components/ContainerError'
import { DateRangeFilter, DateTimeRangeFilter } from '../../gql-global'
import SwitchComponent from '../../components/Switch'

import { ApolloError, NetworkStatus } from '@apollo/client'
import { vRefLine } from '../../components/charts/types'
import { formatChartMonthDay } from '../../components/charts/helpers'

interface Tab {
  readonly id: string
  readonly dataKey: Exclude<keyof AllOrderStatsFieldsFragment, '__typename'>
  readonly metricType: keyof typeof metricMap
}

const OVERVIEW_TABS: readonly [Tab, Tab, Tab] = [
  {
    id: 'MEMBERSPEND',
    dataKey: 'revenue',
    metricType: 'memberSpend',
  },
  {
    id: 'ORDERS',
    dataKey: 'totalOrders',
    metricType: 'totalOrders',
  },
  {
    id: 'CUSTOMERS',
    dataKey: 'totalCustomers',
    metricType: 'totalCustomers',
  },
] as const

export interface CampaignDashboardOverviewProps {
  orderStatsData: QueryCampaignOrderStatsQuery | undefined
  orderStatsLoading: boolean
  orderStatsError: ApolloError | undefined
  orderStatsNetwork: NetworkStatus | undefined
  dateRangeFilter: DateRangeFilter | DateTimeRangeFilter | null | undefined
  includePreMembership: boolean
  setIncludePreMembership: React.Dispatch<React.SetStateAction<boolean>>
  startAt: Date | undefined
  currencyCode?: string
}

const CampaignDashboardOverviewBlock: React.FC<CampaignDashboardOverviewProps> = ({
  orderStatsData,
  orderStatsLoading,
  orderStatsError,
  orderStatsNetwork,
  includePreMembership,
  setIncludePreMembership,
  dateRangeFilter,
  startAt,
  currencyCode,
}) => {
  const theme = useTheme()
  const [activeOverviewTab, setActiveOverviewTab] = useState<Tab>(OVERVIEW_TABS[0])

  const { currentOrderStats, previousOrderStats, orderStatsTimeseries } = orderStatsData?.campaign || {}

  const refLines: vRefLine[] = []
  if (startAt && orderStatsTimeseries) {
    for (const stats of orderStatsTimeseries) {
      if (stats.startDate <= startAt && stats.endDate > startAt) {
        refLines.push({
          x: {
            startDate: stats.startDate,
            endDate: stats.endDate,
          },
          topLabel: `Program Start: ${formatChartMonthDay(startAt)}`,
        })
        break
      }
    }
  }

  return (
    <Box>
      <Box mt={7} mb={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item xs={11}>
          <Box fontSize="1.125rem" fontWeight="fontWeightBold">
            Program Member Spend
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <SwitchComponent checked={includePreMembership} onChange={() => setIncludePreMembership(c => !c)} />
            <Box ml={3}>
              <Typography noWrap>Include pre-membership purchases</Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {OVERVIEW_TABS.map(tab => {
          const metricType = tab.metricType
          return (
            <CampaignDashboardOverviewTab
              active={activeOverviewTab.id === tab.id}
              onClick={(): void => setActiveOverviewTab(tab)}
              key={tab.id}
              metric={tab.dataKey && currentOrderStats?.[tab.dataKey]}
              previousMetric={tab.dataKey && previousOrderStats?.[tab.dataKey]}
              loading={orderStatsLoading && orderStatsNetwork !== NetworkStatus.refetch}
              metricType={metricType}
              dateRangeFilter={dateRangeFilter}
              currencyCode={currencyCode}
            />
          )
        })}
      </Box>
      <Box
        height="434px"
        bgcolor={theme.palette.background.paper}
        borderRadius="0 0 8px 8px"
        padding="100px 44px 10px 32px"
      >
        {orderStatsTimeseries && (
          <MetricLineChart
            metricType={activeOverviewTab.metricType}
            data={orderStatsNetwork !== NetworkStatus.refetch ? orderStatsTimeseries : []}
            dataKey={activeOverviewTab.dataKey}
            vRefLines={refLines}
            doProjection={true}
            hasPolynomialRegressionFeature={true}
          />
        )}
        {orderStatsError && <ContainerError text="Could not load chart" />}
      </Box>
    </Box>
  )
}

export default CampaignDashboardOverviewBlock
