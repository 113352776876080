import React, { useEffect } from 'react'
import { Box, Button, Typography, MenuItem, makeStyles, InputLabel } from '@material-ui/core'
import { Field, Form, FieldProps, useFormikContext } from 'formik'
import { TextField, Select } from 'formik-material-ui'
import { CodesLevel, RewardTypeEnum } from '../../gql-global'
import { MessageTemplateFragmentFragment } from '../operations/message-template-fragment.generated'
import { FormikSwitch } from '../../components/Switch'
import { createTypeNamePredicate } from '../../types/utility'
import { useMessageFormDataQuery } from '../operations/query-message-form-data.generated'
import RewardPicker from '../../components/RewardPicker'
import { MessageTemplateFormFields, REWARD_CODE_TAG } from './messageSchema'
import { secondary } from '../../loudcrowd-theme'
import { rewardName } from '../../utils/rewards'

const isIGSocialAccount = createTypeNamePredicate('IGSocialAccount')

const useStyles = makeStyles(() => ({
  automationBox: {
    border: `2px solid ${secondary[200]}`,
    backgroundColor: secondary[50],
    padding: 16,
    borderRadius: 10,
  },
  automationTitle: {
    fontSize: 14,
    color: secondary[700],
    fontWeight: 600,
  },
  automationDescription: {
    fontSize: 14,
    color: secondary[700],
    fontWeight: 400,
    marginTop: 4,
  },
  counter: {
    position: 'absolute',
    right: 0,
    top: 115,
  },
  templateMessage: {
    fontSize: '0.75rem',
  },
}))

const MAX_CHARACTERS = 900

type MessageFormProps = {
  loading: boolean
  messageTemplate?: MessageTemplateFragmentFragment | null
}

function MessageForm({ loading, messageTemplate }: MessageFormProps): React.ReactElement {
  const classes = useStyles()
  const { values, errors, setFieldValue, setValues } = useFormikContext<MessageTemplateFormFields>()
  const { data, loading: dataLoading } = useMessageFormDataQuery({
    variables: {
      where: {
        rewardType: {
          any: [RewardTypeEnum.Dm],
        },
      },
    },
  })

  const { template, automationEnabled, rewardId } = values
  const accountRewards = data?.whoami?.account?.rewards?.results
  const socialAccounts = data?.socialAccounts
  const customerRewards =
    accountRewards?.map(r => ({
      id: r.id.toString(),
      label: rewardName(r),
    })) ?? []

  const igSocialAccounts = socialAccounts && socialAccounts.filter(isIGSocialAccount)
  const isLoading = loading || dataLoading
  const title = `${messageTemplate ? 'Editing' : 'Create an'}  Instagram Story Reply`

  const buttonAction = automationEnabled ? 'Next' : 'Save'
  const currentReward = accountRewards?.find(({ id }) => id.toString() === rewardId)

  useEffect(() => {
    if (currentReward?.stats.codesLevel === CodesLevel.Empty) {
      setFieldValue('automationEnabled', false, false)
    }
  }, [currentReward, setFieldValue])

  const handleOnRewardChange = (value: string) => {
    let updatedTemplate = template

    if (value) {
      if (!template.includes(REWARD_CODE_TAG)) {
        updatedTemplate = `${template ? `${template}\n` : template}Code: ${REWARD_CODE_TAG}`
      }
    } else {
      updatedTemplate = template.replace(/(\n?Code: |)\{\{reward\.code\}\}/, '')
    }

    setValues({ ...values, rewardId: value, template: updatedTemplate })
  }

  return (
    <Box minWidth={440} maxWidth={440}>
      <Typography variant="h5">{title}</Typography>
      <Form noValidate>
        <Box mt={8}>
          <Field name="name" component={TextField} label="Name" fullWidth disabled={isLoading} />
        </Box>
        <Box mt={8}>
          <InputLabel id="socialAccountIdLabel">Send message from</InputLabel>
          <Field
            name="socialAccountId"
            component={Select}
            label="Send message from"
            labelId="socialAccountIdLabel"
            fullWidth
            disabled={isLoading}
          >
            {igSocialAccounts?.map(socialAccount => (
              <MenuItem value={socialAccount.id} key={socialAccount.id}>
                {socialAccount?.socialUser?.username}
              </MenuItem>
            ))}
          </Field>
        </Box>
        <Box mt={8} position="relative">
          <Typography variant="body2" align="right" className={classes.counter}>
            {template.length ?? 0}/{MAX_CHARACTERS}
          </Typography>
          <Field
            name="template"
            component={TextField}
            placeholder="Write a new message"
            rows={6}
            label="Message"
            fullWidth
            multiline
            inputProps={{ maxLength: MAX_CHARACTERS }}
            disabled={isLoading}
          />
          {!errors.template && rewardId && (
            <Typography variant="body2" component="p" className={classes.templateMessage}>
              {`Use ${REWARD_CODE_TAG} to add the code anywhere in your message. If you remove it, we'll add the code in a new line at the end of your message.`}
            </Typography>
          )}
        </Box>
        <Box mt={4}>
          <Field name="rewardId">
            {({ field }: FieldProps) => (
              <RewardPicker
                option={{
                  name: field.name,
                  type: 'singleSelection',
                  label: 'Program',
                  selectionOptions: customerRewards,
                }}
                onChange={handleOnRewardChange}
                disabled={isLoading || !customerRewards || customerRewards.length === 0}
                value={field.value}
              />
            )}
          </Field>
        </Box>
        <Box mb={6} mt={4} display="flex" flexDirection="column" className={classes.automationBox}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" className={classes.automationTitle}>
              Automatically reply to stories with this message
            </Typography>
            <Field
              component={FormikSwitch}
              color="primary"
              size="small"
              name="automationEnabled"
              type="checkbox"
              disabled={isLoading}
            />
          </Box>
          <Typography variant="body2" className={classes.automationDescription}>
            Define rules to target the right audience
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={12}>
          <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
            {buttonAction}
          </Button>
        </Box>
      </Form>
    </Box>
  )
}

export default MessageForm
