import { IgMediaPostType, MentionStatus } from '../gql-global'

export const mentionTypeLength = 3 // 3 since stories is released

export const postTypeOptions = [
  {
    id: IgMediaPostType.Feed,
    label: 'Feed',
  },
  {
    id: IgMediaPostType.Story,
    label: 'Story',
  },
  {
    id: IgMediaPostType.Reels,
    label: 'Reels',
  },
] as const

export const postTypeLabels: { readonly [K in IgMediaPostType]: string } = {
  [IgMediaPostType.Feed]: 'Feed Posts',
  [IgMediaPostType.Story]: 'Stories',
  [IgMediaPostType.Reels]: 'Reels',
} as const

export const postStatusLabels: { readonly [K in MentionStatus]: string } = {
  [MentionStatus.Verified]: 'Approved',
  [MentionStatus.Unverified]: 'Not Reviewed',
  [MentionStatus.Rejected]: 'Rejected',
  [MentionStatus.OfficialReview]: 'Needs Official Review',
} as const

export const postStatusTypesLength = Object.keys(postStatusLabels).length

export const postStatusOptions = Object.entries(postStatusLabels).map(entry => ({ id: entry[0], label: entry[1] }))
