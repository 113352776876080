import React from 'react'
import { Switch, SwitchProps, styled, Theme } from '@material-ui/core'
import { Switch as FormikSwitchMui, SwitchProps as FormikSwitchProps } from 'formik-material-ui'

import { primary, white, gray } from '../../loudcrowd-theme'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

function createCss({ theme }: { theme: Theme }): CreateCSSProperties {
  return {
    width: 42,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: '3px 3px 3px 4px',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: white,
        '& + .MuiSwitch-track': {
          backgroundColor: primary[500],
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: white,
        border: `6px solid ${white}`,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: white,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
    },
    '& .MuiSwitch-track': {
      borderRadius: 24 / 2,
      backgroundColor: gray,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
    '& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: gray,
    },
  }
}

const SwitchComponent = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(createCss)

export const FormikSwitch = styled((props: FormikSwitchProps) => (
  <FormikSwitchMui focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(createCss)

export default SwitchComponent
