import React from 'react'
import clsx from 'clsx'
import { Box, Dialog, makeStyles, IconButton } from '@material-ui/core'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import MessageBuilder from './MessageBuilder'
import AutomationBuilder from './AutomationBuilder'
import LaunchSuccess from './LaunchSuccess'
import { MessageContext } from './messageSchema'
import { AutomationBuilderContext } from './automationSchema'
import { BuilderProvider, BuilderStepType } from './use-steps'
import MessageReview from './MessageReview'
import { MessageTemplateFragmentFragment } from '../operations/message-template-fragment.generated'
import { MessageTemplateInput } from '../../gql-global'

const useStyles = makeStyles({
  dialogPaper: { width: '100%', height: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'column' },
  messageBuilderPaper: {
    background: `linear-gradient(90deg, white 0%, white 50%, #F3EEFF 50%, #F3EEFF 100%)`,
  },
})

const previewSteps: BuilderStepType[] = ['messageForm', 'messageFormSubmit', 'launch', 'review']

interface BuilderModalProps {
  open: boolean
  onCancel(): void
  onSubmit(messageTemplateInput: MessageTemplateInput, messageTemplateId?: string | null): void
  loading: boolean
  socialAccountId: string
  messageTemplate: MessageTemplateFragmentFragment | null
  clearTemplate: () => void
  initialStep: BuilderStepType
}

function BuilderModal({
  initialStep,
  open,
  onCancel,
  onSubmit,
  loading,
  socialAccountId,
  messageTemplate,
  clearTemplate,
}: BuilderModalProps): React.ReactElement {
  const classes = useStyles()
  return (
    <BuilderProvider
      onSubmit={onSubmit}
      clearTemplate={clearTemplate}
      socialAccountId={socialAccountId}
      messageTemplate={messageTemplate}
      initialStep={initialStep}
    >
      {({
        handleMessageFormSubmit,
        handleAutomationFormSubmit,
        handleReviewSubmit,
        handleDialogClosed,
        handleMessageReviewOnBack,
        handleAutomationBuilderOnBack,
        stepsState,
      }) => {
        const currentStep = stepsState.currentStep
        return (
          <Dialog
            open={open}
            TransitionProps={{ onExited: handleDialogClosed }}
            onClose={onCancel}
            classes={{
              paper: clsx(classes.dialogPaper, {
                [classes.messageBuilderPaper]: previewSteps.includes(currentStep),
              }),
            }}
          >
            <Box m={2} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
              <div>
                <IconButton onClick={onCancel}>
                  <CrossIcon width={16} height={16} />
                </IconButton>
              </div>

              {currentStep === 'messageForm' && (
                <MessageContext messageFormValues={stepsState.messageForm} onSubmit={handleMessageFormSubmit}>
                  <MessageBuilder messageTemplate={messageTemplate} loading={loading} />
                </MessageContext>
              )}
              <AutomationBuilderContext
                onSubmit={handleAutomationFormSubmit}
                automationFormValues={stepsState.automationForm}
              >
                <>
                  {currentStep === 'automationBuilder' && <AutomationBuilder onBack={handleAutomationBuilderOnBack} />}
                  {currentStep === 'review' && (
                    <MessageReview
                      onBack={handleMessageReviewOnBack}
                      onSubmit={handleReviewSubmit}
                      messageForm={stepsState.messageForm}
                      isNew={messageTemplate === null}
                    />
                  )}
                </>
              </AutomationBuilderContext>
              {currentStep === 'launch' && <LaunchSuccess onClose={onCancel} />}
            </Box>
          </Dialog>
        )
      }}
    </BuilderProvider>
  )
}

export default BuilderModal
