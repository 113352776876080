import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { CustomerNoteFragmentFragmentDoc } from './customer-note-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveCustomerNoteMutationVariables = Types.Exact<{
  noteId: Types.Scalars['ID'];
}>;


export type RemoveCustomerNoteMutation = { __typename: 'Mutations', removeCustomerNote?: { __typename: 'RemoveCustomerNote', ok?: boolean | null, note?: { __typename: 'CustomerNoteType', id: string, createdAt: Date, deletedAt?: Date | null, note: string, author: { __typename: 'UserType', id: number, email: string }, category?: { __typename: 'CustomerNoteCategory', id: string, name: string } | null } | null } | null };


export const RemoveCustomerNoteDocument = gql`
    mutation RemoveCustomerNote($noteId: ID!) {
  removeCustomerNote(noteId: $noteId) {
    ok
    note {
      ...CustomerNoteFragment
    }
  }
}
    ${CustomerNoteFragmentFragmentDoc}`;
export type RemoveCustomerNoteMutationFn = Apollo.MutationFunction<RemoveCustomerNoteMutation, RemoveCustomerNoteMutationVariables>;

/**
 * __useRemoveCustomerNoteMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerNoteMutation, { data, loading, error }] = useRemoveCustomerNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useRemoveCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerNoteMutation, RemoveCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomerNoteMutation, RemoveCustomerNoteMutationVariables>(RemoveCustomerNoteDocument, options);
      }
export type RemoveCustomerNoteMutationHookResult = ReturnType<typeof useRemoveCustomerNoteMutation>;
export type RemoveCustomerNoteMutationResult = Apollo.MutationResult<RemoveCustomerNoteMutation>;
export type RemoveCustomerNoteMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerNoteMutation, RemoveCustomerNoteMutationVariables>;