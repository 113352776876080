import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'

export const REWARD_CODE_TAG = '{{reward.code}}'
export const REWARD_PREVIEW = 'DISCOUNT123'

export const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Template name is required.'),
  template: yup.string().required('Message is required.'),
  socialAccountId: yup
    .string()
    .trim()
    .required('Send message from is required.'),
  rewardId: yup.string().trim(),
  automationEnabled: yup.boolean().required(),
})

export type MessageTemplateFormFields = yup.InferType<typeof schema>

type MessageContextProps = {
  children: React.ReactNode
  messageFormValues: MessageTemplateFormFields
  onSubmit(input: MessageTemplateFormFields): void
}

export const initialMessageForm = {
  name: '',
  socialAccountId: '',
  template: '',
  rewardId: '',
  automationEnabled: true,
}

export function MessageContext({ children, messageFormValues, onSubmit }: MessageContextProps): JSX.Element {
  const handleOnSubmit = (values: MessageTemplateFormFields): void => {
    onSubmit(values)
  }

  return (
    <Formik<MessageTemplateFormFields>
      initialValues={messageFormValues}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
    >
      {children}
    </Formik>
  )
}
