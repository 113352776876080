import { Paper, Box, Typography, Theme, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg'
import { format } from 'date-fns'
import DiffText from '../../components/metric/DiffText'
import { DateRangeFilter, DateTimeRangeFilter, ProgramTierTriggerType } from '../../gql-global'
import { dateRangeLabel } from '../../utils/date-range-helper'
import { Skeleton } from '@material-ui/lab'
import { CAMPAIGN_ROUTE } from '../routes'
import { encodeFilterParams } from './use-filter-params-activity'
import SeeAllLink from '../../components/SeeAllLink'

interface RewardRowProps {
  loading?: boolean
  id?: number
  campaignId?: number
  name?: string
  triggerQuantity?: number | null
  triggerType?: ProgramTierTriggerType | null
  dateRangeFilter?: DateRangeFilter | DateTimeRangeFilter
  periodCount?: number | null
  previousPeriodCount?: number | null
  allTimeCount?: number | null
  campaignStartDate?: Date | null
  remainingCodes?: number | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoveredLink: {
      color: theme.palette.primary.main,
    },
    root: {
      '&:hover .hoverLink': {
        color: theme.palette.primary.main,
      },
    },
  }),
)

function RewardRow({
  loading = false,
  id,
  campaignId,
  name,
  triggerQuantity,
  triggerType,
  dateRangeFilter,
  periodCount,
  previousPeriodCount,
  allTimeCount,
  campaignStartDate,
  remainingCodes = null,
}: RewardRowProps): React.ReactElement {
  const classes = useStyles()

  function getTriggerText(triggerType: ProgramTierTriggerType, triggerQuantity = 0): string {
    if (triggerType === ProgramTierTriggerType.Register) {
      return 'Registration'
    }
    if (triggerType === ProgramTierTriggerType.Manual) {
      return 'Manual'
    }
    if (triggerType === ProgramTierTriggerType.Approved) {
      return 'Approval'
    }
    return triggerQuantity + ' Posts'
  }

  return (
    <Box mb={5}>
      <Paper className={classes.root}>
        <Box
          className="reward-row"
          display="flex"
          justifyContent="space-between"
          height={136}
          alignItems="center"
          pl={6}
          pr={11}
        >
          {!loading && (
            <Box display="flex">
              <Box
                p={2}
                width={40}
                height={40}
                borderRadius={20}
                bgcolor="info.light"
                color="info.main"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <TrophyIcon width={20} height={20} />
              </Box>
              <Box ml={4} width={250} display="flex" alignItems="left" flexDirection="column">
                <Typography variant="h6" color="textPrimary">
                  {name}
                </Typography>
                {triggerType && (
                  <Typography variant="subtitle1" color="secondary">
                    Trigger: {getTriggerText(triggerType, triggerQuantity || undefined)}
                  </Typography>
                )}
                {remainingCodes && (
                  <Typography variant="subtitle1" color="secondary">
                    Remaining Codes: {remainingCodes.toLocaleString()}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {loading && (
            <Box display="flex">
              <Skeleton variant="circle" height={40} width={40} />
              <Box display="flex" flexDirection="column" alignItems="left">
                <Skeleton variant="text" height="20" width={250} />
                <Skeleton variant="text" height="14" width={250} />
              </Box>
            </Box>
          )}

          {!loading && (
            <Box width={250}>
              {dateRangeFilter && <Typography variant="subtitle2">Sent {dateRangeLabel(dateRangeFilter)}</Typography>}
              <Typography variant="h4">{periodCount}</Typography>
              {dateRangeFilter &&
                (periodCount || periodCount === 0) &&
                (previousPeriodCount || previousPeriodCount === 0) && (
                  <DiffText dateRangeFilter={dateRangeFilter} num={periodCount} prev={previousPeriodCount} />
                )}
            </Box>
          )}
          {loading && (
            <Box display="flex" flexDirection="column" alignItems="left">
              <Skeleton variant="text" width={250} />
              <Skeleton variant="rect" width={100} height={40} />
              <Skeleton variant="text" width={250} />
            </Box>
          )}

          {!loading && (
            <Box>
              <Typography variant="subtitle2">Sent all time</Typography>
              <Typography variant="h4">{allTimeCount}</Typography>
              {campaignStartDate && <div>Start date: {format(campaignStartDate, 'P')}</div>}
            </Box>
          )}
          {loading && (
            <Box display="flex" flexDirection="column" alignItems="left">
              <Skeleton variant="text" width={250} />
              <Skeleton variant="rect" width={100} height={40} />
              <Skeleton variant="text" width={250} />
            </Box>
          )}

          {!loading && (
            <SeeAllLink
              variant="icon"
              to={{
                pathname: `${CAMPAIGN_ROUTE.path}/${campaignId}/activity`,
                search: `?${encodeFilterParams({
                  rewards: id ? [id.toString()] : undefined,
                })}`,
              }}
            />
          )}
          {loading && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="text" width={75} />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default RewardRow
