import React from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { AddChallengeFormFields } from '../AddEditChallengeDialog'
import { TextField } from 'formik-material-ui'
import { primary } from '../../loudcrowd-theme'
import { MultipleSelectField } from '../../components/SelectField/MultipleSelectField'
import { ChallengeBriefDeliveryMethodEnum } from '../../gql-global'

const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      fontSize: 14,
      marginTop: 10,
      display: 'block',
      color: primary[600],
    },
    textContainer: {
      '& textarea': {
        lineHeight: 1.5,
        fontSize: 14,
      },
    },
    selectWrapper: {
      minWidth: 300,
      '& .MuiFormControl-root': {
        minWidth: 300,
      },
    },
  }),
)

interface DraftBriefProps {
  disabled: boolean
}

function DraftBrief({ disabled }: DraftBriefProps): React.ReactElement {
  const classes = useStyles()
  const { values, setFieldValue } = useFormikContext<AddChallengeFormFields>()
  const placeholder =
    'Type out the content for your brief.\n' +
    'Ie. "To celebrate our newest collection, we’re dropping a challenge! Post a video of you in your favorite ' +
    'outfit and use our hashtag for 10% off your next order.\n' +
    'Our favorite video will get a $100 store credit." \n'

  const options = [
    { id: ChallengeBriefDeliveryMethodEnum.Email, label: 'Email' },
    { id: ChallengeBriefDeliveryMethodEnum.Sms, label: 'SMS' },
    { id: ChallengeBriefDeliveryMethodEnum.SocialPost, label: 'Social media post' },
    { id: ChallengeBriefDeliveryMethodEnum.Website, label: 'Website announcement' },
    { id: ChallengeBriefDeliveryMethodEnum.SomethingElse, label: 'Something else' },
  ]

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Draft your brief
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        Your Client Strategist will reach out to help you publish it.
      </Typography>
      <Box my={5} position="relative" className={classes.textContainer}>
        <Field
          name="paintedDoor.briefContent"
          component={TextField}
          placeholder={placeholder}
          rows={4}
          fullWidth
          multiline
          disabled={disabled}
        />
      </Box>
      <Typography variant="caption" className={classes.caption}>
        How will you send your brief?
      </Typography>
      <Box mt={3} mb={5} className={classes.selectWrapper}>
        <MultipleSelectField
          placeholder="Select all that apply"
          value={values.paintedDoor?.briefDeliveryMethods ?? []}
          setValue={values => setFieldValue('paintedDoor.briefDeliveryMethods', values)}
          options={options}
          disabled={disabled}
          selectionRequired={true}
        />
      </Box>
    </Box>
  )
}

export default DraftBrief
