import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetParticipantCommissionTierMutationVariables = Types.Exact<{
  commissionTierId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  mentionsWhere?: Types.InputMaybe<Types.CustomerMentionStatsFilters>;
  participantIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  where?: Types.InputMaybe<Types.ParticipantsInput>;
}>;


export type SetParticipantCommissionTierMutation = { __typename: 'Mutations', setParticipantCommissionTier?: { __typename: 'SetParticipantCommissionTier', affectedRows: number, ok: boolean } | null };


export const SetParticipantCommissionTierDocument = gql`
    mutation SetParticipantCommissionTier($commissionTierId: ID!, $limit: Int, $mentionsWhere: CustomerMentionStatsFilters, $participantIds: [ID!], $sortBy: ParticipantSort, $sortDirection: SortDirection, $where: ParticipantsInput) {
  setParticipantCommissionTier(
    commissionTierId: $commissionTierId
    limit: $limit
    mentionsWhere: $mentionsWhere
    participantIds: $participantIds
    sortBy: $sortBy
    sortDirection: $sortDirection
    where: $where
  ) {
    affectedRows
    ok
  }
}
    `;
export type SetParticipantCommissionTierMutationFn = Apollo.MutationFunction<SetParticipantCommissionTierMutation, SetParticipantCommissionTierMutationVariables>;

/**
 * __useSetParticipantCommissionTierMutation__
 *
 * To run a mutation, you first call `useSetParticipantCommissionTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetParticipantCommissionTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setParticipantCommissionTierMutation, { data, loading, error }] = useSetParticipantCommissionTierMutation({
 *   variables: {
 *      commissionTierId: // value for 'commissionTierId'
 *      limit: // value for 'limit'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      participantIds: // value for 'participantIds'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSetParticipantCommissionTierMutation(baseOptions?: Apollo.MutationHookOptions<SetParticipantCommissionTierMutation, SetParticipantCommissionTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetParticipantCommissionTierMutation, SetParticipantCommissionTierMutationVariables>(SetParticipantCommissionTierDocument, options);
      }
export type SetParticipantCommissionTierMutationHookResult = ReturnType<typeof useSetParticipantCommissionTierMutation>;
export type SetParticipantCommissionTierMutationResult = Apollo.MutationResult<SetParticipantCommissionTierMutation>;
export type SetParticipantCommissionTierMutationOptions = Apollo.BaseMutationOptions<SetParticipantCommissionTierMutation, SetParticipantCommissionTierMutationVariables>;