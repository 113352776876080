import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardCampaignsQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
}>;


export type DashboardCampaignsQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, startAt: Date, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null, challenges?: { __typename: 'ChallengeTypePaged', results: Array<{ __typename: 'ChallengeType', id: string, name: string }> } | null } | { __typename: 'TTSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, startAt: Date, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null, challenges?: { __typename: 'ChallengeTypePaged', results: Array<{ __typename: 'ChallengeType', id: string, name: string }> } | null } | null };


export const DashboardCampaignsDocument = gql`
    query DashboardCampaigns($socialAccountId: ID!) {
  socialAccount(id: $socialAccountId) {
    id
    campaigns {
      id
      program {
        id
        name
      }
      startAt
    }
    challenges(limit: 200) {
      results {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useDashboardCampaignsQuery__
 *
 * To run a query within a React component, call `useDashboardCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCampaignsQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *   },
 * });
 */
export function useDashboardCampaignsQuery(baseOptions: Apollo.QueryHookOptions<DashboardCampaignsQuery, DashboardCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCampaignsQuery, DashboardCampaignsQueryVariables>(DashboardCampaignsDocument, options);
      }
export function useDashboardCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCampaignsQuery, DashboardCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCampaignsQuery, DashboardCampaignsQueryVariables>(DashboardCampaignsDocument, options);
        }
export type DashboardCampaignsQueryHookResult = ReturnType<typeof useDashboardCampaignsQuery>;
export type DashboardCampaignsLazyQueryHookResult = ReturnType<typeof useDashboardCampaignsLazyQuery>;
export type DashboardCampaignsQueryResult = Apollo.QueryResult<DashboardCampaignsQuery, DashboardCampaignsQueryVariables>;