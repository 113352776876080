import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { addDays, differenceInDays, startOfDay } from 'date-fns'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker'
import {
  DateRangeFilter,
  DateRangeFilterType,
  DateRangeFilterUnits,
  DateTimeRangeFilter,
  IgMediaPostType,
  ParticipantStatus,
  TimeDimension,
} from '../../gql-global'
import { getPreviousRangeDates, realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import { CampaignDetailRouteParams } from '../routes'
import CampaignProgramRevenueAttribution from './CampaignProgramRevenueAttribution'
import CampaignSocialAttribution from './CampaignSocialAttribution'
import { useCampaignStatsQuery } from './operations/campaign-stats.generated'
import { useCampaignQuery } from './operations/campaign.generated'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'

const RECENT_SIGNUP_ROWS = 6

function CampaignRevenueDashboard(): React.ReactElement {
  const { id } = useParams<CampaignDetailRouteParams>()
  const [dateRangeFilter, setDateRangeFilter] = useState<DateTimeRangeFilter | null>(null)
  const lastThirtyDateRangeFilter: DateTimeRangeFilter = {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  }
  const handleOnChangeDateRangeFilter = (date: DateTimeRangeFilter | DateRangeFilter) =>
    setDateRangeFilter(date as DateTimeRangeFilter)
  const dateRange = dateRangeFilter && realizedDateRangeFromFilter(dateRangeFilter)
  const previousDateRange = dateRangeFilter
    ? getPreviousRangeDates(dateRangeFilter)
    : getPreviousRangeDates(lastThirtyDateRangeFilter)
  const dateRangeLength = dateRange && differenceInDays(dateRange.lt, dateRange.gte)
  const timeDimension =
    (dateRangeLength !== null &&
      (dateRangeLength < 60 ? TimeDimension.Day : dateRangeLength < 320 ? TimeDimension.Week : TimeDimension.Month)) ||
    null

  const { data: userData } = useCampaignUserInfoQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const socialPlatform = selectedSocialPlatform(userData)

  const { data: campaignData } = useCampaignQuery({
    skip: !socialPlatform,
    variables: { id: id, platform: socialPlatform! },
  })

  const startAt = campaignData?.campaign?.startAt
  const allTimeRangeFilter: DateTimeRangeFilter | undefined = startAt
    ? {
        __typename: 'AbsoluteDateTimeRangeFilter',
        rangeType: DateRangeFilterType.Absolute,
        gte: startAt,
        lt: addDays(startOfDay(new Date()), 1),
      }
    : undefined

  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    campaignId: {
      any: [id],
    },
    socialAccountId: { any: [selectedSocialAccountId || ''] },
  }

  const { data: statsData, loading: statsLoading } = useCampaignStatsQuery({
    skip: !selectedSocialAccountId || !socialPlatform,
    variables: {
      campaignId: id,
      socialAccountFilter: { any: [selectedSocialAccountId || ''] },
      participantStatus: { any: [ParticipantStatus.Approved] },
      dateRange: dateRange,
      previousDateRange: previousDateRange,
      includePrevious: true,
      includeLastThirty: !dateRangeFilter,
      lastThirtyDateRange: realizedDateRangeFromFilter(lastThirtyDateRangeFilter),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      timeDimension,
      recentSignupLimit: RECENT_SIGNUP_ROWS,
      participantDateRange: dateRange && startAt ? { lt: dateRange?.lt, gte: startAt } : undefined,
      activeStoryMentionsWhere,
      platform: socialPlatform!,
    },
  })

  const participantCount = statsData?.campaign?.participantStats?.count
  const participantTotal = statsData?.campaign?.allTimeParticipantStats?.count
  const participantComparisonCountText = participantCount === participantTotal ? 'All' : `${participantTotal} total`

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={8}>
        <Box fontSize="1.125rem" lineHeight={1.5} color="secondary.main">
          {statsLoading && <Skeleton width={200} />}
          {participantCount !== undefined && (
            <Box display="flex" alignItems="baseline">
              <Box mr={2} display="flex" alignItems="baseline">
                <Typography variant="body1" color="textPrimary">
                  Showing data for
                </Typography>
              </Box>
              <Typography variant="h5" color="textPrimary">
                {participantCount.toLocaleString()} members ({participantComparisonCountText})
              </Typography>
            </Box>
          )}
        </Box>
        <DateRangePicker selectedDateRange={dateRangeFilter} onChange={handleOnChangeDateRangeFilter} includeAllTime />
      </Box>
      <Box>
        <CampaignProgramRevenueAttribution id={id} dateRangeFilter={dateRangeFilter || allTimeRangeFilter} />
      </Box>
      <Box pt={10}>
        <CampaignSocialAttribution id={id} dateRangeFilter={dateRangeFilter || allTimeRangeFilter} />
      </Box>
    </div>
  )
}

export default CampaignRevenueDashboard
