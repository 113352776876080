import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFulfillmentAccountMutationVariables = Types.Exact<{
  accountType: Types.Scalars['String'];
  apiSecret?: Types.InputMaybe<Types.Scalars['String']>;
  apiUrl?: Types.InputMaybe<Types.Scalars['String']>;
  appId?: Types.InputMaybe<Types.Scalars['String']>;
  code?: Types.InputMaybe<Types.Scalars['String']>;
  fromEmail?: Types.InputMaybe<Types.Scalars['String']>;
  fromName?: Types.InputMaybe<Types.Scalars['String']>;
  guid?: Types.InputMaybe<Types.Scalars['String']>;
  key?: Types.InputMaybe<Types.Scalars['String']>;
  namespace?: Types.InputMaybe<Types.Scalars['String']>;
  scope?: Types.InputMaybe<Types.Scalars['String']>;
  smsConsentList?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateFulfillmentAccountMutation = { __typename: 'Mutations', createFulfillmentAccount?: { __typename: 'CreateFulfillmentAccount', ok?: boolean | null, fulfillmentAccount?: { __typename: 'FulfillAccountType', accountType: Types.FulfillmentAccountType, apiSecret?: string | null, apiUrl?: string | null, code?: string | null, fromEmail?: string | null, fromName?: string | null, guid?: string | null, id: number, key?: string | null, lastPush?: Date | null, namespace?: string | null, smsConsentList?: string | null } | null } | null };


export const CreateFulfillmentAccountDocument = gql`
    mutation CreateFulfillmentAccount($accountType: String!, $apiSecret: String, $apiUrl: String, $appId: String, $code: String, $fromEmail: String, $fromName: String, $guid: String, $key: String, $namespace: String, $scope: String, $smsConsentList: String) {
  createFulfillmentAccount(
    accountType: $accountType
    apiSecret: $apiSecret
    apiUrl: $apiUrl
    appId: $appId
    code: $code
    fromEmail: $fromEmail
    fromName: $fromName
    guid: $guid
    key: $key
    namespace: $namespace
    scope: $scope
    smsConsentList: $smsConsentList
  ) {
    fulfillmentAccount {
      accountType
      apiSecret
      apiUrl
      code
      fromEmail
      fromName
      guid
      id
      key
      lastPush
      namespace
      smsConsentList
    }
    ok
  }
}
    `;
export type CreateFulfillmentAccountMutationFn = Apollo.MutationFunction<CreateFulfillmentAccountMutation, CreateFulfillmentAccountMutationVariables>;

/**
 * __useCreateFulfillmentAccountMutation__
 *
 * To run a mutation, you first call `useCreateFulfillmentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFulfillmentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFulfillmentAccountMutation, { data, loading, error }] = useCreateFulfillmentAccountMutation({
 *   variables: {
 *      accountType: // value for 'accountType'
 *      apiSecret: // value for 'apiSecret'
 *      apiUrl: // value for 'apiUrl'
 *      appId: // value for 'appId'
 *      code: // value for 'code'
 *      fromEmail: // value for 'fromEmail'
 *      fromName: // value for 'fromName'
 *      guid: // value for 'guid'
 *      key: // value for 'key'
 *      namespace: // value for 'namespace'
 *      scope: // value for 'scope'
 *      smsConsentList: // value for 'smsConsentList'
 *   },
 * });
 */
export function useCreateFulfillmentAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateFulfillmentAccountMutation, CreateFulfillmentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFulfillmentAccountMutation, CreateFulfillmentAccountMutationVariables>(CreateFulfillmentAccountDocument, options);
      }
export type CreateFulfillmentAccountMutationHookResult = ReturnType<typeof useCreateFulfillmentAccountMutation>;
export type CreateFulfillmentAccountMutationResult = Apollo.MutationResult<CreateFulfillmentAccountMutation>;
export type CreateFulfillmentAccountMutationOptions = Apollo.BaseMutationOptions<CreateFulfillmentAccountMutation, CreateFulfillmentAccountMutationVariables>;