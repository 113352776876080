import React from 'react'
import { Box, Divider, Paper, Typography } from '@material-ui/core'

import { format } from 'date-fns'
import { numberFormat } from '../../utils/number-format'
import { Skeleton } from '@material-ui/lab'

interface Limit {
  limit?: number | null
  used: number
  isUnlimited: boolean
}

function PlanLimit({ limit }: { limit?: Limit | null }): React.ReactElement {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Typography variant="subtitle1">{limit?.used?.toLocaleString() || '-'}</Typography>
      </Box>
      <Typography variant="subtitle1" color="secondary">
        of {limit?.isUnlimited ? 'Unlimited' : limit?.limit}
      </Typography>
    </Box>
  )
}

interface PlanRowProps {
  loading?: boolean
  name?: string | null
  priceInPennies?: number | null
  nextPaymentDate?: Date | null
  postLimit?: number | null
  userLimit?: Limit | null
  socialAccountLimit?: Limit | null
  segmentLimit?: Limit | null
  labelLimit?: Limit | null
  isActive?: boolean | null
}

function PlanRow({
  name,
  priceInPennies,
  nextPaymentDate,
  postLimit,
  userLimit,
  socialAccountLimit,
  segmentLimit,
  labelLimit,
  isActive = false,
  loading = false,
}: PlanRowProps): React.ReactElement {
  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" mr={10} py={5} px={6}>
        <Box>
          <Typography variant="body2" color="secondary">
            Plan Type
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {loading ? <Skeleton /> : <Typography variant="subtitle1">{name}</Typography>}
            {!loading && !isActive && (
              <Box bgcolor="error.main" color="white" borderRadius="20px" paddingX={3} ml={2}>
                Expired
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" color="secondary">
            Post Limit
          </Typography>
          {loading ? (
            <Skeleton />
          ) : (
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" color="secondary">
                {postLimit?.toLocaleString() || 'Unlimited'}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="body2" color="secondary">
            Users
          </Typography>
          {loading ? <Skeleton /> : <PlanLimit limit={userLimit} />}
        </Box>
        <Box>
          <Typography variant="body2" color="secondary">
            Accounts
          </Typography>
          {loading ? <Skeleton /> : <PlanLimit limit={socialAccountLimit} />}
        </Box>
        <Box>
          <Typography variant="body2" color="secondary">
            Segments
          </Typography>
          {loading ? <Skeleton /> : <PlanLimit limit={segmentLimit} />}
        </Box>
        <Box>
          <Typography variant="body2" color="secondary">
            Labels
          </Typography>
          {loading ? <Skeleton /> : <PlanLimit limit={labelLimit} />}
        </Box>
      </Box>
      <Divider />
      <Box display="flex" py={3} px={6} alignItems="center">
        <Box mr={8}>
          {loading ? (
            <Skeleton width={48} />
          ) : (
            <Typography variant="h6">
              {priceInPennies || priceInPennies === 0
                ? numberFormat(priceInPennies / 100, { format: 'currency' })
                : '-'}
            </Typography>
          )}
        </Box>
        <Box mr={2}>
          <Typography variant="body2" color="secondary">
            Next Payment
          </Typography>
        </Box>
        {loading ? (
          <Skeleton width={100} />
        ) : (
          <Typography variant="subtitle1">{nextPaymentDate ? format(nextPaymentDate, 'PP') : '-'}</Typography>
        )}
      </Box>
    </Paper>
  )
}

export default PlanRow
