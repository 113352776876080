import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { SocialAccountHashtagFragmentFragmentDoc } from './social-account-hashtag-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSocialAccountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AdminSocialAccountQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', activeTrackedHashtag?: string | null, lastHashtagUpdatedAt?: Date | null, id: string, trackedHashtags?: Array<{ __typename: 'SocialAccountHashtagType', id: string, hashtag?: string | null, hashtagId?: string | null, active?: boolean | null, createdAt?: Date | null, updatedAt?: Date | null }> | null, account: { __typename: 'AccountType', id: number, name: string }, socialUser: { __typename: 'TTUser', id: string, username: string } } | null };


export const AdminSocialAccountDocument = gql`
    query AdminSocialAccount($id: ID!) {
  socialAccount(id: $id) {
    id
    socialUser {
      id
      username
    }
    ... on TTSocialAccount {
      trackedHashtags {
        ...SocialAccountHashtagFragment
      }
      activeTrackedHashtag
      lastHashtagUpdatedAt
      account {
        id
        name
      }
    }
  }
}
    ${SocialAccountHashtagFragmentFragmentDoc}`;

/**
 * __useAdminSocialAccountQuery__
 *
 * To run a query within a React component, call `useAdminSocialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSocialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSocialAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminSocialAccountQuery(baseOptions: Apollo.QueryHookOptions<AdminSocialAccountQuery, AdminSocialAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSocialAccountQuery, AdminSocialAccountQueryVariables>(AdminSocialAccountDocument, options);
      }
export function useAdminSocialAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSocialAccountQuery, AdminSocialAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSocialAccountQuery, AdminSocialAccountQueryVariables>(AdminSocialAccountDocument, options);
        }
export type AdminSocialAccountQueryHookResult = ReturnType<typeof useAdminSocialAccountQuery>;
export type AdminSocialAccountLazyQueryHookResult = ReturnType<typeof useAdminSocialAccountLazyQuery>;
export type AdminSocialAccountQueryResult = Apollo.QueryResult<AdminSocialAccountQuery, AdminSocialAccountQueryVariables>;