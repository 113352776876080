import React from 'react'
import { useSocialAccountSyncQuery } from './operations/social-account-sync.generated'
import { useUserPrefHideSyncAlertQuery } from './operations/user-pref-hide.generated'
import { useHideSyncAlertMutation } from './operations/hide-sync-alert.generated'
import DancingDots from '../DancingDots'
import Alert from '../Alert'
import { isTypeName } from '../../types/utility'

const SyncPendingSnackbar: React.FC<{ socialAccountId: string }> = ({ socialAccountId }) => {
  const { data: userData } = useUserPrefHideSyncAlertQuery()
  const { data } = useSocialAccountSyncQuery({
    skip: !userData || userData.whoami?.preferences.hideSyncSocialAccountIds.includes(socialAccountId),
    variables: { id: socialAccountId },
  })
  const [hideSyncForSocialAccount] = useHideSyncAlertMutation({
    variables: {
      socialAccountId: socialAccountId,
    },
  })
  const socialAccount =
    data?.socialAccount && isTypeName(data.socialAccount, 'IGSocialAccount') ? data.socialAccount : null
  const { isInitialJobPending, pendingIgUsers, initialIgUsers } = socialAccount?.syncProgress || {}
  const show =
    isInitialJobPending === true || (!!pendingIgUsers && !!initialIgUsers && pendingIgUsers / initialIgUsers > 0.1)
  if (!show) {
    return null
  }

  return (
    <Alert
      severity="info"
      title="Data enrichment in progress."
      message="Some fields may appear empty while data is being retrieved"
      icon={<DancingDots />}
      onClose={hideSyncForSocialAccount}
      open
    />
  )
}

export default SyncPendingSnackbar
