import React from 'react'
import { Button, Box, IconButton, Typography, makeStyles, createStyles, Theme, fade } from '@material-ui/core'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { ReactComponent as CheckIcon } from '../../icons/checkmark.svg'
import { PricingQuery } from './operations/pricing.generated'
import { quickPluralize } from '../../utils/number-format'

type TierData = NonNullable<PricingQuery['appStripeProducts']>[number]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkIcon: {
      color: theme.palette.success.main,
      marginRight: 12,
    },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background: `linear-gradient(3.28deg, ${theme.palette.primary.dark} -6%, ${fade(
        theme.palette.primary.dark,
        0,
      )} 64.97%)`,
    },
    image: {
      backgroundImage: (props: SingleTierUpsellContentProps) => `url(${props.imageURL})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
    },
    darkButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    roundedRectangleButton: {
      borderRadius: 4,
    },
  }),
)

interface SingleTierUpsellContentProps {
  tier: TierData
  title: string
  text: string
  imageURL: string
  isCurrentlyEnterprise?: boolean
  onCancel(e: React.MouseEvent<HTMLButtonElement>): void
}

function SingleTierUpsellContent(props: SingleTierUpsellContentProps): React.ReactElement {
  const { tier, title, text, onCancel, isCurrentlyEnterprise = false } = props
  const styles = useStyles(props)
  return (
    <Box display="flex">
      <Box width={320} position="relative" flexShrink={0}>
        <div className={styles.image} />
        <div className={styles.imageOverlay} />
      </Box>
      <Box
        ml={4}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        minHeight={480}
        p={6}
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={onCancel}>
            <CrossIcon width={24} height={24} />
          </IconButton>
        </Box>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Box mb={8} lineHeight="24px">
          {text}
        </Box>
        {!isCurrentlyEnterprise && (
          <>
            <Typography variant="subtitle2" gutterBottom>
              What you get with {tier.name}
            </Typography>
            <Box display="flex">
              <Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">
                    {quickPluralize('campaign', tier.campaignLimit || 'Unlimited')}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">{quickPluralize('user', tier.userLimit || 'Unlimited')}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">
                    {quickPluralize('Social account', tier.socialAccountLimit || 'Unlimited')}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">
                    {quickPluralize('visible post', tier.postLimit || 'Unlimited')}
                  </Typography>
                </Box>
              </Box>
              <Box ml={24}>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">
                    {quickPluralize('segment', tier?.segmentLimit || 'Unlimited')}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">{quickPluralize('label', tier?.labelLimit || 'Unlimited')}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <CheckIcon className={styles.checkIcon} height={14} width={14} />
                  <Typography variant="body2">Chat based support</Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={9}>
          <Box mr={4}>
            <Button color="primary" onClick={onCancel}>
              Maybe later
            </Button>
          </Box>
          {isCurrentlyEnterprise && (
            <Button color="primary" variant="contained" href="mailto:support@loudcrowd.com">
              Contact Rep
            </Button>
          )}
          {!isCurrentlyEnterprise && (
            <Button
              className={`${styles.roundedRectangleButton} ${styles.darkButton}`}
              variant="contained"
              href="mailto:sales@loudcrowd.com"
            >
              Talk to Sales
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SingleTierUpsellContent
