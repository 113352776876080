import {
  format,
  subHours,
  subDays,
  addDays,
  startOfDay,
  differenceInDays,
  subWeeks,
  subMonths,
  subYears,
  formatDistanceStrict,
  isPast,
  isSameYear,
  addMonths,
  startOfMonth,
} from 'date-fns'
import {
  DateTimeRangeFilter,
  DateRangeFilter,
  DateRangeFilterUnits,
  DateRangeFilterType,
  DateRangeFilterInput,
  PaymentPeriodEnum,
} from '../gql-global'
import { numberFormat } from './number-format'
import { createTypeNamePredicate } from '../types/utility'

export function isDateRangeType(str: string): str is DateRangeFilterType {
  return [DateRangeFilterType.Relative, DateRangeFilterType.Absolute].some(t => t === str)
}

export function isDateRangeUnit(str: string): str is DateRangeFilterUnits {
  return [
    DateRangeFilterUnits.Hours,
    DateRangeFilterUnits.Days,
    DateRangeFilterUnits.Weeks,
    DateRangeFilterUnits.Years,
    DateRangeFilterUnits.Months,
  ].some(t => t === str)
}

const subMap = {
  [DateRangeFilterUnits.Hours]: subHours,
  [DateRangeFilterUnits.Days]: subDays,
  [DateRangeFilterUnits.Weeks]: subWeeks,
  [DateRangeFilterUnits.Months]: subMonths,
  [DateRangeFilterUnits.Years]: subYears,
}

export type DateRange = {
  gte: Date
  lt: Date
}

export const realizedDateRangeFromFilter = (filter: DateTimeRangeFilter | DateRangeFilter): DateRange => {
  const startOfTomorrowLocal = addDays(startOfDay(new Date()), 1)
  if (filter.__typename === 'AbsoluteDateTimeRangeFilter' || filter.__typename === 'AbsoluteDateRangeFilter') {
    return {
      gte: filter.gte || new Date('1970-01-01'),
      lt: filter.lt || startOfTomorrowLocal,
    }
  }

  const subFunc = subMap[filter.unit]
  let endDate = null
  if (filter.unit === DateRangeFilterUnits.Hours) {
    const currentDateTime = new Date()
    endDate = filter.offset ? subFunc(currentDateTime, filter.offset) : currentDateTime
  } else {
    endDate = filter.offset ? subFunc(startOfTomorrowLocal, filter.offset) : startOfTomorrowLocal
  }
  const startDate = subFunc(endDate, filter.value)
  if (filter.isExclude) {
    // For now isExclude is only being used by the 21+ years old filter, so we just use a lower bound so old
    // nobody living could possibly have a birthday before it, beware if attempting to use for other purpose
    return {
      gte: new Date('1900-01-01'),
      lt: startDate,
    }
  }
  return {
    gte: startDate,
    lt: endDate,
  }
}

export const invalidSinceRealizedDateRangeFromFilter = (filter: DateTimeRangeFilter | DateRangeFilter): DateRange => {
  const startOfTomorrowLocal = addDays(startOfDay(new Date()), 1)
  if (filter.__typename === 'AbsoluteDateTimeRangeFilter' || filter.__typename === 'AbsoluteDateRangeFilter') {
    return {
      gte: new Date('1970-01-01'),
      lt: filter.gte || startOfTomorrowLocal,
    }
  }

  const subFunc = subMap[filter.unit]
  const endDate = filter.offset ? subFunc(startOfTomorrowLocal, filter.offset) : startOfTomorrowLocal
  const startDate = subFunc(endDate, filter.value)
  return {
    gte: new Date('1970-01-01'),
    lt: startDate,
  }
}

export const getPreviousRangeDates = (filter: DateTimeRangeFilter | DateRangeFilter): DateRange => {
  if (filter.__typename === 'AbsoluteDateTimeRangeFilter' || filter.__typename === 'AbsoluteDateRangeFilter') {
    const dateRangeLength = differenceInDays(filter.lt || new Date(), filter.gte)
    return {
      gte: subDays(filter.gte, dateRangeLength),
      lt: filter.gte,
    }
  } else {
    return realizedDateRangeFromFilter({
      ...filter,
      offset: (filter.offset || 0) + filter.value,
    })
  }
}

const dateUnitString = {
  [DateRangeFilterUnits.Hours]: 'hour' as const,
  [DateRangeFilterUnits.Days]: 'day' as const,
  [DateRangeFilterUnits.Weeks]: 'week' as const,
  [DateRangeFilterUnits.Months]: 'month' as const,
  [DateRangeFilterUnits.Years]: 'year' as const,
} as const

export const dateRangeLabel = (
  dateRangeFilter: DateTimeRangeFilter | DateRangeFilter,
  inactiveFilter = false,
): string => {
  if (
    dateRangeFilter.__typename === 'AbsoluteDateTimeRangeFilter' ||
    dateRangeFilter.__typename === 'AbsoluteDateRangeFilter'
  ) {
    let gteString = null
    if (dateRangeFilter.gte) {
      if (dateRangeFilter.lt && !isSameYear(dateRangeFilter.gte, dateRangeFilter.lt)) {
        gteString = format(dateRangeFilter.gte, 'MMM dd, yyyy')
      } else {
        gteString = format(dateRangeFilter.gte, 'MMM dd')
      }
    }
    let lteString = null
    if (dateRangeFilter.lt) {
      if (dateRangeFilter.gte && !isSameYear(dateRangeFilter.gte, dateRangeFilter.lt)) {
        lteString = format(addDays(dateRangeFilter.lt, -1), 'MMM dd, yyyy')
      } else {
        lteString = format(addDays(dateRangeFilter.lt, -1), 'MMM dd')
      }
    }
    if (gteString && lteString) {
      return gteString === lteString ? gteString : `${gteString} - ${lteString}`
    }
    if (gteString) {
      return `After ${gteString}`
    }
    return `Before ${lteString}`
  }

  const unit = dateUnitString[dateRangeFilter.unit]
  const unitString = `${unit[0]?.toUpperCase()}${unit.substring(1)}`
  // special cases for today and yesterday
  if (dateRangeFilter.unit === DateRangeFilterUnits.Days && dateRangeFilter.value === 1) {
    return dateRangeFilter.offset ? 'Yesterday' : 'Today'
  }

  // add previous if there is an offset, aka skip a range
  // if inactive or exclude its not prev or last so no prefix
  let prefix = ''
  if (dateRangeFilter.offset) {
    prefix = 'Previous '
  } else if (!inactiveFilter || dateRangeFilter.isExclude) {
    prefix = 'Last '
  }
  const suffix = inactiveFilter ? ' Ago' : ''
  return `${unitString !== 'Year' ? prefix : ''}${numberFormat(dateRangeFilter.value, {
    singularUnit: unitString,
    customPostfix: dateRangeFilter.isExclude ? '+' : '',
  })}${suffix}`
}

export const prevDateRangeText = (dateRangeFilter: DateTimeRangeFilter | DateRangeFilter): string => {
  if (
    dateRangeFilter.__typename === 'AbsoluteDateTimeRangeFilter' ||
    dateRangeFilter.__typename === 'AbsoluteDateRangeFilter'
  ) {
    let distance = formatDistanceStrict(dateRangeFilter.gte, dateRangeFilter.lt || new Date())
    distance = distance.replace(/^1 (\w*)$/, '$1')
    return `from previous ${distance}`
  }

  if (dateRangeFilter.unit === DateRangeFilterUnits.Days && dateRangeFilter.value === 1) {
    if (!dateRangeFilter.offset) {
      return 'from yesterday'
    } else {
      const realizedDateRange = realizedDateRangeFromFilter(dateRangeFilter)
      return `from ${format(addDays(realizedDateRange.gte, -1), 'MMM dd')}`
    }
  }

  const unitString = dateUnitString[dateRangeFilter.unit]
  if (dateRangeFilter.value === 1) {
    return `from previous ${unitString}`
  }

  return `from previous ${numberFormat(dateRangeFilter.value, { singularUnit: unitString })}`
}

export function dateTypePostfix(date: Date): string {
  return isPast(date) ? 'ed' : 's'
}

export function subtractDateRange(range: DateRange, days: number): DateRange {
  return {
    gte: subDays(range.gte, days),
    lt: subDays(range.lt, days),
  }
}

export function conditionalDateRangeFormat(started: Date, ended: Date): string {
  // When year is the same: Oct 1 - Oct 31, 2022
  // Different years: Dec 15, 2022 - Jan 15, 2023
  const sameYear = isSameYear(started, ended)
  const ndash = String.fromCharCode(8211)
  let startDate: string

  // Start date
  if (sameYear) {
    startDate = format(started, 'MMM d')
  } else {
    startDate = format(started, 'MMM d, y')
  }

  // End date
  const endDate = format(ended, 'PP')

  return `${startDate} ${ndash} ${endDate}`
}

export const isAbsoluteDateRangeFilter = createTypeNamePredicate('AbsoluteDateTimeRangeFilter')

export function dateTimeRangeFilterToInput(dateRangeFilter?: DateTimeRangeFilter): DateRangeFilterInput | null {
  if (!dateRangeFilter) return null
  if (isAbsoluteDateRangeFilter(dateRangeFilter)) {
    return {
      rangeType: DateRangeFilterType.Absolute,
      gte: dateRangeFilter.gte,
      lt: dateRangeFilter.lt,
    }
  } else {
    return {
      rangeType: DateRangeFilterType.Relative,
      value: dateRangeFilter.value,
      unit: dateRangeFilter.unit,
      offset: dateRangeFilter.offset,
    }
  }
}

export const getPaymentPeriodEndDate = (paymentPeriod: PaymentPeriodEnum, orderMaturationPeriodDays: number): Date => {
  switch (paymentPeriod) {
    // TODO: Implement weekly and biweekly period end dates once we know the logic
    case PaymentPeriodEnum.Monthly:
      const firstDayOfNextMonth = startOfMonth(addMonths(new Date(), 1))
      firstDayOfNextMonth.setUTCHours(0, 0, 0, 0)
      const resultingDate = subDays(firstDayOfNextMonth, orderMaturationPeriodDays)
      resultingDate.setUTCHours(0, 0, 0, 0)
      return resultingDate
    default:
      throw new Error('Unsupported payment period')
  }
}
