import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { TableRowParticipantFragmentDoc } from '../../../components/CustomerTable/operations/table-row-participant.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignTopCustomersQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  platform: Types.SocialPlatformEnum;
  mentionsWhere: Types.CustomerMentionStatsFilters;
  activeStoryMentionsWhere: Types.CustomerMentionStatsFilters;
  limit: Types.Scalars['Int'];
  sortBy: Types.ParticipantSort;
  sortDirection: Types.SortDirection;
}>;


export type CampaignTopCustomersQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> } | null, participants?: { __typename: 'PagedParticipants', total: number, results: Array<{ __typename: 'ProgramParticipantType', id: number, createdAt: Date, approvedAt?: Date | null, status: Types.ParticipantStatus, customer: { __typename: 'CustomerType', id: number, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null, igUser?: { __typename: 'IGUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, mentionStats?: { __typename: 'CustomerMentionStats', avgEngagementRate: number, impressions: number, maxPostDate?: Date | null, postCount: number } | null, ttUser?: { __typename: 'TTUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string } | null } }> } | null } | null };


export const CampaignTopCustomersDocument = gql`
    query CampaignTopCustomers($campaignId: ID!, $platform: SocialPlatformEnum!, $mentionsWhere: CustomerMentionStatsFilters!, $activeStoryMentionsWhere: CustomerMentionStatsFilters!, $limit: Int!, $sortBy: ParticipantSort!, $sortDirection: SortDirection!) {
  campaign(id: $campaignId) {
    id
    program {
      id
      socialAccounts {
        id
      }
    }
    participants(
      where: {status: {any: [APPROVED]}, platform: $platform}
      mentionsWhere: $mentionsWhere
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      total
      results {
        ...TableRowParticipant
      }
    }
  }
}
    ${TableRowParticipantFragmentDoc}`;

/**
 * __useCampaignTopCustomersQuery__
 *
 * To run a query within a React component, call `useCampaignTopCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTopCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTopCustomersQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      platform: // value for 'platform'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      activeStoryMentionsWhere: // value for 'activeStoryMentionsWhere'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useCampaignTopCustomersQuery(baseOptions: Apollo.QueryHookOptions<CampaignTopCustomersQuery, CampaignTopCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignTopCustomersQuery, CampaignTopCustomersQueryVariables>(CampaignTopCustomersDocument, options);
      }
export function useCampaignTopCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignTopCustomersQuery, CampaignTopCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignTopCustomersQuery, CampaignTopCustomersQueryVariables>(CampaignTopCustomersDocument, options);
        }
export type CampaignTopCustomersQueryHookResult = ReturnType<typeof useCampaignTopCustomersQuery>;
export type CampaignTopCustomersLazyQueryHookResult = ReturnType<typeof useCampaignTopCustomersLazyQuery>;
export type CampaignTopCustomersQueryResult = Apollo.QueryResult<CampaignTopCustomersQuery, CampaignTopCustomersQueryVariables>;