import React from 'react'
import { TextField as MUITextField, makeStyles, createStyles, Theme, OutlinedTextFieldProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notchedOutline: {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
  }),
)

export type TextFieldProps = Omit<OutlinedTextFieldProps, 'variant'> & { selected?: boolean }

export const TextField: React.FC<TextFieldProps> = ({ selected, ...props }) => {
  const classes = useStyles()
  return <MUITextField variant="outlined" InputProps={selected ? { classes } : {}} {...props} />
}
