import { useQueryParams, encodeQueryParams, stringify } from 'use-query-params'

import { LABEL_SEARCH_PARAM_KEY, labelParam } from './use-label-param'

export interface Filters {
  labelKeywords: string[]
}

interface LabelParams {
  [LABEL_SEARCH_PARAM_KEY]?: string[]
}

function paramsToFilters(params: LabelParams): Filters {
  return {
    labelKeywords: params[LABEL_SEARCH_PARAM_KEY] || [],
  }
}

function filtersToParams(filters: Filters): LabelParams {
  return {
    [LABEL_SEARCH_PARAM_KEY]: filters.labelKeywords,
  }
}

const filterParamConfig = {
  [LABEL_SEARCH_PARAM_KEY]: labelParam,
}

/*
 * encodes label filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Filters): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  const filters = paramsToFilters(filterParams)
  return {
    filters,
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
