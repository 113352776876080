import React, { ReactElement } from 'react'
import { Box, SimplePaletteColorOptions, Tooltip, Typography, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { numberFormat } from '../../utils/number-format'
import DiffText from './DiffText'
import metricMap from './metric-map'
import MetricIcon from './MetricIcon'
import { DateRangeFilter, DateTimeRangeFilter } from '../../gql-global'
import { ReactComponent as InfoIcon } from '../../icons/information-current-color.svg'

export interface MetricProps {
  hasCompare?: boolean
  previousMetric?: number | null
  dateRangeFilter?: DateRangeFilter | DateTimeRangeFilter | null
  size?: 'sm' | 'lg' | 'xlg'
  metric?: number | null
  loading?: boolean
  metricType: keyof typeof metricMap
  currencyCode?: string
  link?: ReactElement | null
  overridingMetricForComparison?: number | null
  accountEMV?: number | null
  showIcon?: boolean
  emvForUgcReplied?: boolean
}

const Metric: React.FC<MetricProps> = ({
  size = 'sm',
  hasCompare = false,
  metric,
  previousMetric,
  loading = false,
  metricType,
  currencyCode,
  dateRangeFilter,
  link = null,
  overridingMetricForComparison = null,
  accountEMV,
  showIcon = true,
  emvForUgcReplied = false,
}) => {
  const theme = useTheme()
  const { color, dataFormat, icon, label, abbrvLabel, paletteOption } = metricMap[metricType]
  const colorDict = theme.palette[color]
  const metricString =
    !!metric || metric === 0 ? numberFormat(metric, { format: dataFormat, currencyCode: currencyCode }) : '--'
  let previousSection: JSX.Element | string = ' '
  const comparisonMetric = overridingMetricForComparison || metric
  if (loading) {
    previousSection = <Skeleton component="span" />
  } else if (
    !!dateRangeFilter &&
    (!!comparisonMetric || comparisonMetric === 0) &&
    (!!previousMetric || previousMetric === 0)
  ) {
    previousSection = <DiffText dateRangeFilter={dateRangeFilter} num={comparisonMetric} prev={previousMetric} />
  }
  const isEMV = metricType.toUpperCase() === metricMap.emv.id
  const EMVPhrase = accountEMV ? ` CPM currently set to ${numberFormat(accountEMV * 1000, { format: 'currency' })}` : ''
  return (
    <Box p={size === 'xlg' ? 9 : size === 'lg' ? 5 : 0} display="flex" alignItems="center">
      {showIcon && !loading && (
        <Box
          width={40}
          minWidth={40}
          height={40}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={4}
          mt={size === 'xlg' ? 3 : 0}
          borderRadius={20}
          bgcolor={colorDict.light}
          color={colorDict?.[(paletteOption || 'main') as keyof SimplePaletteColorOptions] || colorDict.main}
        >
          <MetricIcon icon={icon} />
        </Box>
      )}
      <Box flexGrow={1}>
        <Tooltip
          title={
            isEMV
              ? `Earned Media Value${
                  emvForUgcReplied ? ' for UGC that you have replied to' : ''
                }. Shows the value of your UGC based on the CPM of the impressions.${EMVPhrase}`
              : ''
          }
          placement="top"
        >
          <Typography variant="subtitle2" color={size === 'lg' || size === 'xlg' ? 'textPrimary' : 'secondary'}>
            {size === 'sm' && abbrvLabel ? abbrvLabel : label}
            <Box display="inline" ml={2} color={theme.palette.secondary.main} visibility={isEMV ? 'visible' : 'hidden'}>
              <InfoIcon width={20} height={20} />
            </Box>
          </Typography>
        </Tooltip>
        <Box
          fontSize={size === 'xlg' ? 'h3.fontSize' : size === 'lg' ? 'h4.fontSize' : 'h5.fontSize'}
          lineHeight={size === 'lg' || size === 'xlg' ? 'h4.lineHeight' : 'h5.lineHeight'}
          data-testid={`metric-${metricType}`}
        >
          {loading ? <Skeleton component="span" /> : metricString}
        </Box>
        {hasCompare && (
          <Box color="secondary.main" fontSize="caption.fontSize" fontWeight="bold">
            {previousSection}
          </Box>
        )}
      </Box>
      {link && <Box mr={5}>{link}</Box>}
    </Box>
  )
}

export default Metric
