import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChallengeUserDataQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  socialAccountId: Types.Scalars['ID'];
  where?: Types.InputMaybe<Types.RewardFilterInput>;
}>;


export type ChallengeUserDataQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null } | { __typename: 'TTSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null } | null, whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, rewards?: { __typename: 'PagedRewardType', results: Array<{ __typename: 'RewardType', id: number, name?: string | null, integration?: { __typename: 'BluecoreIntegration', id: string } | { __typename: 'BrazeIntegration', id: string } | { __typename: 'CommerceCloudIntegration', id: string } | { __typename: 'CustomStoreIntegration', id: string } | { __typename: 'EmarsysIntegration', id: string } | { __typename: 'GoogleIntegration', id: string } | { __typename: 'GrowaveIntegration', id: string } | { __typename: 'ImpactIntegration', id: string } | { __typename: 'KlaviyoIntegration', id: string } | { __typename: 'LoyaltyLionIntegration', id: string } | { __typename: 'MandrillIntegration', id: string } | { __typename: 'OmetriaIntegration', id: string } | { __typename: 'RefersionIntegration', id: string } | { __typename: 'SFTPIntegration', id: string } | { __typename: 'SFTPPullIntegration', id: string } | { __typename: 'SalesforceOcapiIntegration', id: string } | { __typename: 'SendinblueIntegration', id: string } | { __typename: 'ShopifyIntegration', id: string } | { __typename: 'SmileIntegration', id: string } | { __typename: 'TremendousIntegration', id: string } | { __typename: 'WebhookIntegration', id: string } | { __typename: 'YotpoIntegration', id: string } | { __typename: 'YotpoVizIntegration', id: string } | { __typename: 'ZaiusIntegration', id: string } | null }> } | null } | null } | null };


export const ChallengeUserDataDocument = gql`
    query ChallengeUserData($limit: Int, $socialAccountId: ID!, $where: RewardFilterInput) {
  socialAccount(id: $socialAccountId) {
    campaigns {
      id
      program {
        id
        name
      }
    }
    id
  }
  whoami {
    account {
      id
      rewards(limit: $limit, where: $where) {
        results {
          id
          integration {
            id
          }
          name
        }
      }
    }
    id
  }
}
    `;

/**
 * __useChallengeUserDataQuery__
 *
 * To run a query within a React component, call `useChallengeUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengeUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengeUserDataQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChallengeUserDataQuery(baseOptions: Apollo.QueryHookOptions<ChallengeUserDataQuery, ChallengeUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengeUserDataQuery, ChallengeUserDataQueryVariables>(ChallengeUserDataDocument, options);
      }
export function useChallengeUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengeUserDataQuery, ChallengeUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengeUserDataQuery, ChallengeUserDataQueryVariables>(ChallengeUserDataDocument, options);
        }
export type ChallengeUserDataQueryHookResult = ReturnType<typeof useChallengeUserDataQuery>;
export type ChallengeUserDataLazyQueryHookResult = ReturnType<typeof useChallengeUserDataLazyQuery>;
export type ChallengeUserDataQueryResult = Apollo.QueryResult<ChallengeUserDataQuery, ChallengeUserDataQueryVariables>;