import { NumericalRange } from '../components/NumericalRangePicker'

export type NumberFormatTypes = 'number' | 'percent' | 'currency' | 'scalar'

export interface NumberFormatOptions {
  format?: NumberFormatTypes
  forceSign?: boolean
  singularUnit?: string
  customPostfix?: string
  currencyCode?: string
}

const scales = ['k', 'M', 'B', 'T']

export function currencyCodeToSymbol(code: string): string {
  try {
    const dummy = 1
    const formatted = dummy.toLocaleString('en-US', { style: 'currency', currency: code })
    const symbol = formatted[0]
    if (!symbol) {
      return ''
    }
    return symbol
  } catch {
    console.log(`error converting currency code to symbol for code {code}`)
    return ''
  }
}

export function getNumericString(number: number): string {
  number = Math.abs(number)
  let scaledNumber = number
  let unit = ''
  if (number > 999) {
    for (let i = 0; i < scales.length; i++) {
      const scale = scales[i]
      const min = Math.pow(1000, i + 1)
      const over = Math.pow(1000, i + 2)
      if (number < over) {
        scaledNumber = number / min
        unit = scale || ''
        break
      }
    }
  }

  const wholeNumbers = scaledNumber.toString().split('.')[0]?.length || 0
  const decimalPoints = scaledNumber % 1 === 0 ? 0 : 3 - wholeNumbers
  const stringNumber = scaledNumber
    .toFixed(decimalPoints)
    .replace(/^(\d+\.\d+)0+$/, '$1') // trim extra 0s after .
    .replace(/^(\d+)\.0+$/, '$1')
  return `${stringNumber}${unit}`
}

export function numberFormat(number: number, options: NumberFormatOptions = {}): string {
  const { format = 'number', forceSign = false, singularUnit, customPostfix = '' } = options
  let sign = ''
  if (number < 0) {
    sign = '-'
  } else if (forceSign && number > 0) {
    sign = '+'
  }
  const stringNumberAndUnit = getNumericString(number)
  const currencyCode = options.currencyCode || 'USD'

  const prefix = format === 'currency' ? currencyCodeToSymbol(currencyCode) : ''
  const postfix = format === 'percent' && !customPostfix ? '%' : customPostfix
  const multiplier = format === 'scalar' ? 'x' : ''
  const units = number === 1 ? singularUnit : `${singularUnit}s`
  const unitsString = singularUnit ? ` ${units}` : ''
  return `${sign}${prefix}${stringNumberAndUnit}${postfix}${unitsString}${multiplier}`
}

export function createNumberFormatter(options: NumberFormatOptions): (n: number) => string {
  return (n: number): string => {
    return numberFormat(n, options)
  }
}

export function numericalRangeFormat({ gt, gte, lt, lte, eq }: NumericalRange, options: NumberFormatOptions): string {
  let gtFilterValue: number | undefined = undefined
  let gtIncludeEq = false
  if (gt !== undefined) {
    gtFilterValue = gt
  }
  if (gte !== undefined) {
    if (gt === undefined) {
      gtFilterValue = gte
      gtIncludeEq = true
    } else {
      // We keep the larger amount for the filter, note when gte==gt we keep gt
      // because its more restrictive thus consistent with backend
      gtFilterValue = gte > gt ? gte : gt
      gtIncludeEq = gtFilterValue !== gt
    }
  }

  let ltFilterValue: number | undefined = undefined
  let ltIncludeEq = false
  if (lt !== undefined) {
    ltFilterValue = lt
  }
  if (lte !== undefined) {
    if (lt === undefined) {
      ltFilterValue = lte
      ltIncludeEq = true
    } else {
      // Analogous but reverse than for gt/gte, we keep the lowest most restrictive filter
      ltFilterValue = lte < lt ? lte : lt
      ltIncludeEq = ltFilterValue !== lt
    }
  }

  // for numerical ranges we don't want the currency symbol, so we don't send the currency format to numberFormat
  const gtString =
    gtFilterValue !== undefined ? numberFormat(gtFilterValue, { ...options, format: undefined }) : undefined
  const ltString =
    ltFilterValue !== undefined ? numberFormat(ltFilterValue, { ...options, format: undefined }) : undefined
  const eqString = eq !== undefined ? numberFormat(eq, { ...options, format: undefined }) : undefined

  if (eqString !== undefined) {
    return `=${eqString}`
  }
  if (ltString !== undefined && gtString !== undefined) {
    return ltString === gtString ? `=${gtString}` : `${gtString} - ${ltString}`
  }
  if (ltString !== undefined) {
    return ltIncludeEq ? `<= ${ltString}` : `< ${ltString}`
  }
  return gtIncludeEq ? `${gtString}+` : `<${gtString}`
}

// Returns plural version of text (only appends "s") and value. if value is not "numberish", pluralizes
// ex:
//    quickPluralize('user', 1) -> '1 user'
//    quickPluralize('user', '1') -> '1 user'
//    quickPluralize('user', 3) -> '3 users'
//    quickPluralize('user', 'unlimited) -> 'unlimited users'
export function quickPluralize(text: string, value: number | string): string {
  if (value === 1) {
    return `${value.toLocaleString()} ${text}`
  }
  return `${value.toLocaleString()} ${text}s`
}

export const formatAsOrdinalNumber = (number?: number | null) => {
  const lastDigit = number && number % 10
  const lastTwoDigits = number && number % 100
  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return 'st'
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return 'nd'
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return 'rd'
  } else {
    return 'th'
  }
}

export const formatAsWords = (number?: number | null) => {
  if (!number) return ''

  switch (number) {
    case 1:
      return 'once'
    case 2:
      return 'twice'
    default:
      return number + ' times'
  }
}
