import React from 'react'
import { TimeDataPoint } from '../charts/types'
import LineChart, { LineChartProps } from '../charts/LineChart'
import metricMap, { MetricTypes, MetricConfig } from './metric-map'

interface MetricLineChartProps<K extends string, T extends TimeDataPoint<K>>
  extends Omit<LineChartProps<K, T>, keyof MetricConfig> {
  metricType: MetricTypes
}

function MetricLineChart<K extends string, T extends TimeDataPoint<K>>({
  metricType,
  ...rest
}: MetricLineChartProps<K, T>): React.ReactElement | null {
  const metricConfig = metricMap[metricType]
  return <LineChart {...metricConfig} {...rest} />
}

export default MetricLineChart
