import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AmbassadorStatsQueryVariables = Types.Exact<{
  currencyCode: Types.CurrencyCodeEnum;
  customerId: Types.Scalars['ID'];
  orderMaturationDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  pendingStatsRangeAt?: Types.InputMaybe<Array<Types.DateTimeFilterInput> | Types.DateTimeFilterInput>;
  socialAccountId: Types.Scalars['ID'];
  allowLineItemCalculations: Types.Scalars['Boolean'];
  integrationId: Types.Scalars['ID'];
}>;


export type AmbassadorStatsQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, customer?: { __typename: 'CustomerType', id: number, filteredAmbassadorStats?: { __typename: 'AmbassadorStats', commissionsAwaitingApproval: number, commissionsOwed: number, commissionsPaid: number, commissionsPending: number, ordersApproved: number, ordersAwaitingApproval: number, revenueApproved: number, revenueAwaitingApproval: number } | null, totalAmbassadorStats?: { __typename: 'AmbassadorStats', commissionsPaid: number, commissionsPending: number, ordersApproved: number, revenueApproved: number } | null } | null } | { __typename: 'TTSocialAccount', id: string, customer?: { __typename: 'CustomerType', id: number, filteredAmbassadorStats?: { __typename: 'AmbassadorStats', commissionsAwaitingApproval: number, commissionsOwed: number, commissionsPaid: number, commissionsPending: number, ordersApproved: number, ordersAwaitingApproval: number, revenueApproved: number, revenueAwaitingApproval: number } | null, totalAmbassadorStats?: { __typename: 'AmbassadorStats', commissionsPaid: number, commissionsPending: number, ordersApproved: number, revenueApproved: number } | null } | null } | null };


export const AmbassadorStatsDocument = gql`
    query AmbassadorStats($currencyCode: CurrencyCodeEnum!, $customerId: ID!, $orderMaturationDate: DateTime, $pendingStatsRangeAt: [DateTimeFilterInput!], $socialAccountId: ID!, $allowLineItemCalculations: Boolean!, $integrationId: ID!) {
  socialAccount(id: $socialAccountId) {
    customer(id: $customerId) {
      filteredAmbassadorStats: ambassadorStats(
        where: {rangeAt: $pendingStatsRangeAt, disjointRefunds: false, currencyCode: $currencyCode, orderMaturationDate: $orderMaturationDate, allowLineItemCalculations: $allowLineItemCalculations}
        integrationId: $integrationId
      ) {
        commissionsAwaitingApproval
        commissionsOwed
        commissionsPaid
        commissionsPending
        ordersApproved
        ordersAwaitingApproval
        revenueApproved
        revenueAwaitingApproval
      }
      id
      totalAmbassadorStats: ambassadorStats(
        where: {currencyCode: $currencyCode, disjointRefunds: false, allowLineItemCalculations: $allowLineItemCalculations}
        integrationId: $integrationId
      ) {
        commissionsPaid
        commissionsPending
        ordersApproved
        revenueApproved
      }
    }
    id
  }
}
    `;

/**
 * __useAmbassadorStatsQuery__
 *
 * To run a query within a React component, call `useAmbassadorStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmbassadorStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmbassadorStatsQuery({
 *   variables: {
 *      currencyCode: // value for 'currencyCode'
 *      customerId: // value for 'customerId'
 *      orderMaturationDate: // value for 'orderMaturationDate'
 *      pendingStatsRangeAt: // value for 'pendingStatsRangeAt'
 *      socialAccountId: // value for 'socialAccountId'
 *      allowLineItemCalculations: // value for 'allowLineItemCalculations'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useAmbassadorStatsQuery(baseOptions: Apollo.QueryHookOptions<AmbassadorStatsQuery, AmbassadorStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AmbassadorStatsQuery, AmbassadorStatsQueryVariables>(AmbassadorStatsDocument, options);
      }
export function useAmbassadorStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmbassadorStatsQuery, AmbassadorStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AmbassadorStatsQuery, AmbassadorStatsQueryVariables>(AmbassadorStatsDocument, options);
        }
export type AmbassadorStatsQueryHookResult = ReturnType<typeof useAmbassadorStatsQuery>;
export type AmbassadorStatsLazyQueryHookResult = ReturnType<typeof useAmbassadorStatsLazyQuery>;
export type AmbassadorStatsQueryResult = Apollo.QueryResult<AmbassadorStatsQuery, AmbassadorStatsQueryVariables>;