import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivateProgramEcommDiscountCodeMutationVariables = Types.Exact<{
  ecommDiscountCodeId: Types.Scalars['ID'];
}>;


export type ActivateProgramEcommDiscountCodeMutation = { __typename: 'Mutations', activateProgramEcommDiscountCode?: { __typename: 'ActivateProgramEcommDiscountCode', ok: boolean, ecommDiscountCode?: { __typename: 'EcommDiscountCodeType', id: string } | null } | null };


export const ActivateProgramEcommDiscountCodeDocument = gql`
    mutation ActivateProgramEcommDiscountCode($ecommDiscountCodeId: ID!) {
  activateProgramEcommDiscountCode(ecommDiscountCodeId: $ecommDiscountCodeId) {
    ecommDiscountCode {
      id
    }
    ok
  }
}
    `;
export type ActivateProgramEcommDiscountCodeMutationFn = Apollo.MutationFunction<ActivateProgramEcommDiscountCodeMutation, ActivateProgramEcommDiscountCodeMutationVariables>;

/**
 * __useActivateProgramEcommDiscountCodeMutation__
 *
 * To run a mutation, you first call `useActivateProgramEcommDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateProgramEcommDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateProgramEcommDiscountCodeMutation, { data, loading, error }] = useActivateProgramEcommDiscountCodeMutation({
 *   variables: {
 *      ecommDiscountCodeId: // value for 'ecommDiscountCodeId'
 *   },
 * });
 */
export function useActivateProgramEcommDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<ActivateProgramEcommDiscountCodeMutation, ActivateProgramEcommDiscountCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateProgramEcommDiscountCodeMutation, ActivateProgramEcommDiscountCodeMutationVariables>(ActivateProgramEcommDiscountCodeDocument, options);
      }
export type ActivateProgramEcommDiscountCodeMutationHookResult = ReturnType<typeof useActivateProgramEcommDiscountCodeMutation>;
export type ActivateProgramEcommDiscountCodeMutationResult = Apollo.MutationResult<ActivateProgramEcommDiscountCodeMutation>;
export type ActivateProgramEcommDiscountCodeMutationOptions = Apollo.BaseMutationOptions<ActivateProgramEcommDiscountCodeMutation, ActivateProgramEcommDiscountCodeMutationVariables>;