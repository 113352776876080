import React, { useCallback, useState } from 'react'
import Modal from './Modal'
import {
  ModalStateProps,
  UpsellModalUpdaterContext as UpsellModalUpdaterContextVar,
  UpsellModalStateContext as UpsellModalStateContextVar,
} from './context'

const useModalState = (initialState: ModalStateProps) => {
  const [isOpen, setIsOpen] = useState(initialState.isOpen)
  const [modalProps, setModalProps] = useState(initialState.modalProps)

  const setModalState = useCallback(
    ({ isOpen, modalProps = {} }: ModalStateProps) => {
      setIsOpen(isOpen)
      setModalProps(modalProps)
    },
    [setIsOpen, setModalProps],
  )

  return [{ isOpen, modalProps }, setModalState] as const
}

interface WithModalProps {
  children: React.ReactNode
}

function WithUpsellModal({ children }: WithModalProps): React.ReactElement {
  const [modalOptions, setModalOptions] = useModalState({
    isOpen: false,
    modalProps: {},
  })

  return (
    <UpsellModalUpdaterContextVar.Provider value={setModalOptions}>
      <UpsellModalStateContextVar.Provider value={modalOptions}>
        <Modal />
        {children}
      </UpsellModalStateContextVar.Provider>
    </UpsellModalUpdaterContextVar.Provider>
  )
}

export const UpsellModalStateContext = UpsellModalStateContextVar
export const UpsellModalUpdaterContext = UpsellModalUpdaterContextVar
export default WithUpsellModal
