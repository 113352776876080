import React, { useRef, useState } from 'react'
import {
  Button,
  Menu,
  Theme,
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
  Divider,
  ButtonProps,
} from '@material-ui/core'
import { ReactComponent as DownChevron } from '../../icons/chevron-down_minor.svg'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      minWidth: 170,
    },
    listItemIcon: {
      minWidth: 28,
      color: 'inherit',
    },
    listItem: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    deleteItem: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

export interface ListActionConfig<K extends string> {
  action: K
  label: string
  icon: React.ReactElement
  disabled?: boolean
}

export interface ListActionMenuProps<K extends string> {
  actions: ListActionConfig<K>[]
  deleteAction?: ListActionConfig<K>
  onSelectAction(action: K): void
  buttonText?: string
  buttonProps?: ButtonProps
}

export interface ControlledListActionMenuProps<K extends string> extends ListActionMenuProps<K> {
  open: boolean
  onOpen(): void
  onCancel(): void
}

export function ControlledListActionMenu<K extends string>({
  actions,
  deleteAction,
  onSelectAction,
  open,
  onOpen,
  onCancel,
  buttonText,
  buttonProps,
}: ControlledListActionMenuProps<K>): React.ReactElement {
  const classes = useStyle()
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button
        data-intercom-target="Customer Bulk Actions Button"
        variant="outlined"
        size="large"
        onClick={onOpen}
        endIcon={<DownChevron width={16} />}
        color="primary"
        ref={buttonRef}
        {...buttonProps}
      >
        {buttonText || 'Actions'}
      </Button>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        onClose={onCancel}
        classes={{ paper: classes.menuPaper }}
      >
        {actions.map((a, i) => (
          <MenuItem
            data-intercom-target={i === 0 ? 'Customer Bulk Actions First Item' : undefined}
            key={a.action}
            onClick={() => onSelectAction(a.action)}
            className={classes.listItem}
            disabled={!!a.disabled}
          >
            <ListItemIcon className={classes.listItemIcon}>{a.icon}</ListItemIcon>
            <ListItemText disableTypography>{a.label}</ListItemText>
          </MenuItem>
        ))}
        {deleteAction && [
          <Divider key="divider" />,
          <MenuItem
            key={deleteAction.action}
            onClick={() => deleteAction && onSelectAction(deleteAction.action)}
            className={classes.deleteItem}
          >
            <ListItemIcon className={classes.listItemIcon}>{deleteAction.icon}</ListItemIcon>
            <ListItemText disableTypography>{deleteAction.label}</ListItemText>
          </MenuItem>,
        ]}
      </Menu>
    </>
  )
}

function ListActionMenu<K extends string>(props: ListActionMenuProps<K>): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  function handleSelectAction(action: K): void {
    setIsOpen(false)
    props.onSelectAction(action)
  }
  return (
    <ControlledListActionMenu
      {...props}
      onSelectAction={handleSelectAction}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onCancel={() => setIsOpen(false)}
    />
  )
}

export default ListActionMenu
