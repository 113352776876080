import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignProgramEcommCustomerSegmentQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  id: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CampaignProgramEcommCustomerSegmentQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, ecommCustomerSegments?: Array<{ __typename: 'EcommCustomerSegmentType', id: string, name: string }> | null } | null } | null };


export const CampaignProgramEcommCustomerSegmentDocument = gql`
    query CampaignProgramEcommCustomerSegment($cursor: String, $id: ID!, $limit: Int, $searchText: String) {
  campaign(id: $id) {
    id
    program {
      ecommCustomerSegments(cursor: $cursor, limit: $limit, searchText: $searchText) {
        id
        name
      }
      id
    }
  }
}
    `;

/**
 * __useCampaignProgramEcommCustomerSegmentQuery__
 *
 * To run a query within a React component, call `useCampaignProgramEcommCustomerSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignProgramEcommCustomerSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignProgramEcommCustomerSegmentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useCampaignProgramEcommCustomerSegmentQuery(baseOptions: Apollo.QueryHookOptions<CampaignProgramEcommCustomerSegmentQuery, CampaignProgramEcommCustomerSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignProgramEcommCustomerSegmentQuery, CampaignProgramEcommCustomerSegmentQueryVariables>(CampaignProgramEcommCustomerSegmentDocument, options);
      }
export function useCampaignProgramEcommCustomerSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignProgramEcommCustomerSegmentQuery, CampaignProgramEcommCustomerSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignProgramEcommCustomerSegmentQuery, CampaignProgramEcommCustomerSegmentQueryVariables>(CampaignProgramEcommCustomerSegmentDocument, options);
        }
export type CampaignProgramEcommCustomerSegmentQueryHookResult = ReturnType<typeof useCampaignProgramEcommCustomerSegmentQuery>;
export type CampaignProgramEcommCustomerSegmentLazyQueryHookResult = ReturnType<typeof useCampaignProgramEcommCustomerSegmentLazyQuery>;
export type CampaignProgramEcommCustomerSegmentQueryResult = Apollo.QueryResult<CampaignProgramEcommCustomerSegmentQuery, CampaignProgramEcommCustomerSegmentQueryVariables>;