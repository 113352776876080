import React, { useState, useRef } from 'react'
import {
  Button,
  Menu,
  Theme,
  MenuItem,
  ListItemIcon,
  makeStyles,
  createStyles,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import { ReactComponent as DownChevron } from '../../icons/chevron-down_minor.svg'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      minWidth: 170,
    },
    listItemIcon: {
      minWidth: 28,
      color: 'inherit',
    },
    listItem: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    deleteItem: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

export interface ActionType {
  action: string
  label: string
  icon: React.ReactElement
  disabledTooltip?: string
  actionCallback(): void
}

interface ActionMenuProps {
  actions: ActionType[]
  disabled: boolean
  isMenuItemDisabled?(action: ActionType): boolean
}

function ActionMenu({ actions, disabled, isMenuItemDisabled }: ActionMenuProps): React.ReactElement {
  const classes = useStyle()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        endIcon={<DownChevron width={16} />}
        color="primary"
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        ref={buttonRef}
      >
        Actions
      </Button>
      <Menu
        open={isOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        onClose={() => setIsOpen(false)}
        classes={{ paper: classes.menuPaper }}
      >
        {actions.map(action => {
          const menuItemDisabled = !!isMenuItemDisabled ? isMenuItemDisabled(action) : false

          return (
            <Tooltip
              key={action.action}
              title={!!action.disabledTooltip && menuItemDisabled ? action.disabledTooltip : ''}
              placement={'top-start'}
            >
              <div>
                <MenuItem
                  disabled={menuItemDisabled}
                  className={classes.listItem}
                  onClick={() => {
                    setIsOpen(false)
                    action.actionCallback()
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>{action.icon}</ListItemIcon>
                  <ListItemText disableTypography>{action.label}</ListItemText>
                </MenuItem>
              </div>
            </Tooltip>
          )
        })}
      </Menu>
    </>
  )
}

export default ActionMenu
