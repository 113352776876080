import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Link,
  Box,
  Theme,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import RewardActivityCard from './RewardActivityCard'
import { CampaignDetailRouteParams } from '../routes'
import { CampaignActivityQuery, useCampaignActivityQuery } from './operations/campaign-activity.generated'
import { NetworkStatus } from '@apollo/client'
import ContainerEmptyState from '../../components/ContainerEmptyState/ContainerEmptyState'
import { ReactComponent as NoActivityImage } from '../../images/no-activities.svg'
import ContainerError from '../../components/ContainerError'
import ListHeader from '../../components/lists/ListHeader'
import ListCount from '../../components/lists/ListCount'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFilterParams, { Filters } from './use-filter-params-activity'
import { EventsInput, SocialPlatformEnum } from '../../gql-global'
import ListFilters from '../../components/lists/ListFilters'
import { realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import { useDateRangeRef } from '../../hooks/useDateRangeRef'
import { useExportCampaignActivityLazyQuery } from './operations/export-activity.generated'
import { EXPORT_LIMIT } from './constants'
import ListActions from '../../components/lists/ListActions'
import ListActionMenu from '../../components/lists/ListActionMenu'
import { ReactComponent as Export } from '../../icons/export.svg'
import { useCampaignRewardsBasicDataQuery } from './operations/campaign-rewards-basic.generated'
import { isNonNull, isTypeName } from '../../types/utility'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const PAGE_SIZE = 10

export type CampaignActivityEvent = NonNullable<NonNullable<CampaignActivityQuery['campaign']>['events']>['results'][0]

const useWhereFilters = (filters: Filters): EventsInput => {
  const currentRewardedAtDateRange = filters.rewardedAt ? realizedDateRangeFromFilter(filters.rewardedAt) : null
  const reffedRewardedAtDateRange = useDateRangeRef(currentRewardedAtDateRange)
  return {
    username: filters.usernameKeywords?.length ? { keywords: filters.usernameKeywords } : null,
    rewards: filters.rewards
      ? {
          any: filters.rewards || undefined,
        }
      : undefined,
    rewardedAt: reffedRewardedAtDateRange,
  }
}

function CampaignRewardsActivity(): React.ReactElement {
  const classes = useStyles()
  const { id } = useParams<CampaignDetailRouteParams>()
  const [exportLimitDialogOpen, setExportLimitDialogOpen] = useState<boolean>(false)
  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)

  const { data: campaignRewardsData, loading: campaignRewardsLoading } = useCampaignRewardsBasicDataQuery({
    variables: { campaignId: id },
  })

  const { data: userData } = useCampaignUserInfoQuery()

  const socialPlatform = selectedSocialPlatform(userData)
  const isIGSocialAccount = socialPlatform === SocialPlatformEnum.Instagram

  const { filters, isDirty, setFilters } = useFilterParams()
  const where = useWhereFilters(filters)
  const {
    data: activitiesData,
    error,
    loading: activitiesLoading,
    fetchMore,
    networkStatus,
  } = useCampaignActivityQuery({
    skip: !id || !socialPlatform,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      campaignId: id,
      limit: limitRef.current,
      where: where,
    },
  })

  const loadMoreActivities = (): void => {
    if (activitiesData?.campaign?.events?.cursor) {
      fetchMore({
        variables: {
          cursor: activitiesData.campaign.events.cursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  function handleSetFilters(newFilters: Filters): void {
    limitRef.current = PAGE_SIZE
    setFilters({
      ...newFilters,
      rewards: newFilters.rewards instanceof Set ? Array.from(newFilters.rewards) : newFilters.rewards,
    })
  }

  const exportUrlRef = useRef<string | null>(null)
  const [exportCustomers, exportResults] = useExportCampaignActivityLazyQuery()
  const exportUrl = exportResults.data?.campaign?.events?.csvUrl
  useEffect(() => {
    if (exportUrl && exportUrl !== exportUrlRef.current) {
      exportUrlRef.current = exportUrl
      window.location.href = exportUrl
    }
  }, [exportUrl])

  const handleExport = (currentTotal: number): void => {
    if (currentTotal > EXPORT_LIMIT) {
      setExportLimitDialogOpen(true)
    } else {
      exportCustomers({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          campaignId: id!,
          limit: EXPORT_LIMIT,
          where: {
            ...where,
            platform: socialPlatform,
          },
        },
      })
    }
  }

  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const loading = (campaignRewardsLoading || activitiesLoading) && !loadingMore
  const activities = activitiesData?.campaign?.events?.results
  const activityCount = activities?.length
  const newViewingCount = activityCount || limitRef.current

  useEffect(() => setViewing((Math.ceil(newViewingCount / PAGE_SIZE) || 1) * PAGE_SIZE), [newViewingCount, setViewing])

  return (
    <>
      <ListFilters
        contentType="rewards"
        isDirty={isDirty}
        onChangeFilters={handleSetFilters}
        options={[
          {
            name: 'rewards',
            type: 'selection',
            label: 'Reward',
            selectionOptions:
              campaignRewardsData?.campaign?.program?.tiers
                ?.map(t => t?.reward)
                .filter(isNonNull)
                .map(r => {
                  return { id: r.id.toString(), label: r.name || '' }
                }) || [],
          },
          {
            name: 'usernameKeywords',
            type: 'keywords',
            label: 'Username',
            useChips: true,
          },
          {
            name: 'rewardedAt',
            type: 'dateRange',
            label: 'Reward Date',
            entity: 'normal',
          },
        ]}
        filters={{ ...filters, rewards: filters.rewards ? new Set<string>(filters.rewards) : undefined }}
      />
      <ListHeader>
        <Box mr={2}>
          <ListCount loading={loading} count={activitiesData?.campaign?.events?.total} units="Rewards Sent" />
        </Box>
        <ListActions>
          {!!activitiesData?.campaign?.events?.total && (
            <ListActionMenu
              actions={[{ action: 'export', label: 'Export', icon: <Export /> }]}
              onSelectAction={action => {
                if (action === 'export') {
                  handleExport(activitiesData?.campaign?.events?.total || 0)
                }
              }}
            />
          )}
        </ListActions>
      </ListHeader>
      <Box>
        {!error && !loading && !!activities?.length && (
          <div className={classes.list}>
            {activities.map((a, i) => {
              if (isTypeName(a, 'FulfilledTierCustomerEvent')) {
                return <RewardActivityCard key={i} activity={a} isIGSocialAccount={isIGSocialAccount || false} />
              } else {
                return <></>
              }
            })}
          </div>
        )}
        {!error && (loading || loadingMore) && (
          <div className={classes.list}>
            {new Array(PAGE_SIZE).fill(null).map((_, i) => (
              <RewardActivityCard key={i} loading isIGSocialAccount={isIGSocialAccount || false} />
            ))}
          </div>
        )}
        {!error && !loading && !activities?.length && (
          <Box display="flex" justifyContent="center">
            <ContainerEmptyState image={NoActivityImage} text="No activities yet for this campaign" />
          </Box>
        )}
        {error && <ContainerError text="Sorry, we had a problem loading activities." />}
        {!error && activitiesData?.campaign?.events?.cursor && (
          <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
            <Button variant="outlined" color="primary" size="large" onClick={loadMoreActivities} disabled={loadingMore}>
              Load more
            </Button>
          </Box>
        )}
      </Box>
      <Dialog open={exportLimitDialogOpen} onClose={() => setExportLimitDialogOpen(false)}>
        <DialogTitle>Cannot Export</DialogTitle>
        <DialogContent>
          <p>
            You cannot export more than {EXPORT_LIMIT.toLocaleString()} customers at a time. Please select under{' '}
            {EXPORT_LIMIT.toLocaleString()} customers or make a bulk request to{' '}
            <Link href="mailto:support@loudcrowd.com">support@loudcrowd.com</Link>
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setExportLimitDialogOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CampaignRewardsActivity
