import { Route } from '../types/route'
import { AccountProductName } from '../gql-global'

export const CAMPAIGN_ROUTE = new Route('/program', ['MAIN'], undefined, AccountProductName.Base)
export const CAMPAIGN_DETAIL_ROUTE = CAMPAIGN_ROUTE.subroute('/:id', ['MAIN'], undefined, AccountProductName.Campaign)
export const CAMPAIGN_DETAIL_MEMBERS_ROUTE = CAMPAIGN_DETAIL_ROUTE.subroute('/members')
export const CAMPAIGN_DETAIL_ACTIVITY_ROUTE = CAMPAIGN_DETAIL_ROUTE.subroute('/activity')
export const CAMPAIGN_DETAIL_APPLICATIONS_ROUTE = CAMPAIGN_DETAIL_ROUTE.subroute('/applicants')

export interface CampaignDetailRouteParams {
  id: string
}
