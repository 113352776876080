import { Dispatch, SetStateAction, useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { UserPreferences } from '../gql-global'

type SetValue<T> = Dispatch<SetStateAction<T>>

function useUserLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  const [userpref] = useLocalStorage<UserPreferences | null>(`userpref`, null)

  const storeKey = `${userpref?.id}__${key}`
  const wrongKey = `undefined__${key}`

  const [storedValue, setValue] = useLocalStorage<T>(storeKey, initialValue)

  useEffect(() => {
    const wrongValue = localStorage.getItem(wrongKey)

    if (userpref?.id && wrongValue) {
      localStorage.setItem(storeKey, wrongValue)
      localStorage.removeItem(wrongKey)
      window.dispatchEvent(new Event('local-storage'))
    }
  }, [storeKey, wrongKey, userpref?.id])

  return [storedValue, setValue]
}

export default useUserLocalStorage
