import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdditionalCriteriaInputType, CriteriaOperatorEnum, IgMediaPostType, MentionStatus } from '../gql-global'
import RuleButtons from './RuleButtons'
import { useUpdateChallengeMediaApprovalMutation } from '../components/PostDetails/operations/update-challenge-media-approval.generated'
import { useToast } from '../components/Alert/ToastProvider'
import { RenderedHashtagValues } from '../challenges/HashtagsField'
import { RenderedPostCriteriaValues } from '../challenges/PostCriteriaField'
import { RenderedKeywordsValues } from '../challenges/KeywordsField'

interface ChallengeRulesProps {
  mediaId?: string
  needsRefetch?: boolean
  challenge: {
    id: string
    postTypes?: IgMediaPostType[] | null
    hashtags?: Array<string> | null
    keywords?: Array<string> | null
    paintedDoor?: { briefContent?: string | null } | null
    challengeMedia?: { approval?: MentionStatus } | null
    additionalCriteria?: AdditionalCriteriaInputType | null
    socialAccount?: { __typename: string } | null
    keywordsCriteriaOperator: CriteriaOperatorEnum
    hashtagsCriteriaOperator: CriteriaOperatorEnum
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.common.white,
      height: '90%',
    },
    ruleLabel: {
      fontWeight: 'bold',
      marginRight: theme.spacing(3),
      minWidth: 170,
    },
    ruleValue: {
      color: theme.palette.primary.dark,
      fontSize: 14,
    },
    ruleItem: {
      '& > div p': {
        fontSize: 14,
        marginTop: 8,
      },
      '& > div h6': {
        marginTop: 8,
        fontSize: 14,
      },
      '& > div svg': {
        height: 14,
        width: 14,
        marginTop: 12,
      },
    },
    lastRuleItem: {
      alignItems: 'flex-start',
      '& > div p': {
        marginTop: 4,
        marginBottom: 5,
      },
      '& > div label': {
        marginLeft: -8,
      },
    },
  }),
)

const ChallengeRules: React.FC<ChallengeRulesProps> = ({ challenge, mediaId, needsRefetch }) => {
  const styles = useStyles()
  const {
    postTypes,
    hashtags,
    keywords,
    paintedDoor,
    additionalCriteria,
    keywordsCriteriaOperator,
    hashtagsCriteriaOperator,
  } = challenge

  const { showToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [mediaStatus, setMediaStatus] = useState(challenge?.challengeMedia?.approval ?? MentionStatus.Unverified)
  const [updateChallengeMediaApproval] = useUpdateChallengeMediaApprovalMutation()

  const onChangeStatus = (status: MentionStatus) => {
    const refetch = needsRefetch ? { awaitRefetchQueries: true, refetchQueries: ['ChallengeReviewDetails'] } : {}
    if (challenge.id && mediaId) {
      setLoading(true)
      updateChallengeMediaApproval({
        variables: { challengeId: challenge.id, mediaId, approval: status },
        ...refetch,
      })
        .catch(error => {
          showToast({ title: 'Error: Updating Challenge Media Approval', message: error.message })
        })
        .finally(() => {
          setMediaStatus(status)
          setLoading(false)
        })
    }
  }

  return (
    <>
      <RuleButtons
        excludeOfficialReview
        onChangeStatus={onChangeStatus}
        currentStatus={mediaStatus}
        loading={loading}
      />
      <Paper className={styles.container}>
        <Box p={2}>
          <List>
            <ListItem className={styles.ruleItem}>
              <Typography className={styles.ruleLabel}>Post Types: </Typography>
              <RenderedPostCriteriaValues values={postTypes ? Array.from(postTypes) : []} />
            </ListItem>
            <ListItem className={styles.ruleItem}>
              <Typography className={styles.ruleLabel}>Hashtags: </Typography>
              {hashtags?.length ? (
                <RenderedHashtagValues values={hashtags} criteriaOperatorValue={hashtagsCriteriaOperator} />
              ) : (
                <Typography className={styles.ruleValue}>N/A</Typography>
              )}
            </ListItem>
            <ListItem className={styles.ruleItem}>
              <Typography className={styles.ruleLabel}>Caption: </Typography>
              {keywords?.length ? (
                <RenderedKeywordsValues values={keywords} criteriaOperatorValue={keywordsCriteriaOperator} />
              ) : (
                <Typography className={styles.ruleValue}>N/A</Typography>
              )}
            </ListItem>
            <ListItem>
              <Typography className={styles.ruleLabel}>When in Doubt: </Typography>
              <Typography className={styles.ruleValue}>Approve</Typography>
            </ListItem>
            <ListItem className={styles.lastRuleItem}>
              <Typography className={styles.ruleLabel}>Additional Criteria: </Typography>
              <Box>
                {!!paintedDoor?.briefContent && (
                  <Typography className={styles.ruleValue}>{paintedDoor?.briefContent}</Typography>
                )}
                {!!additionalCriteria?.criteriaDescription && (
                  <Typography className={styles.ruleValue}>{additionalCriteria?.criteriaDescription}</Typography>
                )}
                {!!additionalCriteria?.storyCriteriaDescription && (
                  <Typography className={styles.ruleValue}>{additionalCriteria?.storyCriteriaDescription}</Typography>
                )}
                {!additionalCriteria?.criteriaDescription && !paintedDoor?.briefContent && (
                  <Typography className={styles.ruleValue}>N/A</Typography>
                )}
                <FormControlLabel
                  control={<Checkbox disabled checked={additionalCriteria?.shouldMeetProgramRules ?? false} />}
                  label="Posts must meet program rules"
                />
              </Box>
            </ListItem>
          </List>
        </Box>
      </Paper>
    </>
  )
}

export default ChallengeRules
