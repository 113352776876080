import { MentionStatus } from '../../gql-global'

export const participantStatusOptions = [
  {
    id: 1,
    label: 'Pending',
  },
  {
    id: 2,
    label: 'Rejected',
  },
]

export const tagStatusOptions = [
  {
    id: MentionStatus.Unverified,
    label: 'Not Reviewed',
  },
  {
    id: MentionStatus.Verified,
    label: 'Approved',
  },
  {
    id: MentionStatus.Rejected,
    label: 'Rejected',
  },
  {
    id: MentionStatus.OfficialReview,
    label: 'Needs Official Review',
  },
] as const

//IgMentionTypes is now an entirely front-end concept for filtering...
// the api's have wasTagged and wasCaptionMentioned
export enum IgMentionTypes {
  Tag = 'TAG',
  Caption = 'CAPTION',
  Story = 'STORY',
}

export const mentionTypeOptions = [
  {
    id: IgMentionTypes.Tag,
    label: 'Tag',
  },
  {
    id: IgMentionTypes.Caption,
    label: 'Caption Mention',
  },
  {
    id: IgMentionTypes.Story,
    label: 'Story',
  },
] as const

export const BULK_SEGMENT_ADD_LIMIT = 1000
export const BULK_REJECT_PARTICIPANT_LIMIT = 1000
export const EXPORT_LIMIT = 25000
export const SEND_REWARD_LIMIT = 1000
export const STATUS_CHANGE_LIMIT = 1000
export const PAGE_SIZE = 10
export const MESSAGE_TEMPLATES_LIMIT = 100
export const SET_COMMISSION_TIER_LIMIT = 1000
