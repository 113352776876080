import { Button, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { CustomerNoteCategory } from '../gql-global'
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down_minor.svg'

export interface NoteCategoryMenuProps {
  addOrEdit: 'ADD' | 'EDIT'
  noteCategories?: CustomerNoteCategory[] | null
  value?: string | null
  onClick?(id: string, addOrEdit: 'ADD' | 'EDIT'): void
}
function NoteCategoryMenu({ onClick, value, noteCategories, addOrEdit }: NoteCategoryMenuProps): React.ReactElement {
  const hostButtonRef = useRef<HTMLButtonElement>(null)

  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        ref={hostButtonRef}
        color="primary"
        variant="outlined"
        endIcon={<ChevronDownIcon width={16} />}
        onClick={() => setOpen(true)}
      >
        {value || 'Categories'}
      </Button>
      <Menu
        style={{ width: 1000 }}
        open={open}
        anchorEl={hostButtonRef.current}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setOpen(false)}
      >
        {noteCategories?.map(c => (
          <MenuItem
            key={`add-note-category-${c.id}`}
            onClick={() => {
              if (onClick) {
                onClick(c.id, addOrEdit)
              }
              setOpen(false)
            }}
          >
            <Typography variant="subtitle1" color="primary">
              {c.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default NoteCategoryMenu
