import React from 'react'
import { Box } from '@material-ui/core'
import Page from '../Page'
import useTitle from '../utils/use-title'
import ReviewProgramsList from './ReviewProgramsList'
import ReviewChallengesList from './ReviewChallengesList'

const ReviewLists: React.FC = () => {
  useTitle('Review')

  return (
    <Page>
      <Box px={12} py={10}>
        <ReviewChallengesList />
      </Box>
      <Box px={12} py={10}>
        <ReviewProgramsList />
      </Box>
    </Page>
  )
}

export default ReviewLists
