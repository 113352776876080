import { CustomerSort, ParticipantSort } from '../../gql-global'
import { Size } from '@material-ui/core'

export type Column =
  | 'signupDate'
  | 'approvedAt'
  | 'posts'
  | 'followers'
  | 'maxPostDate'
  | 'avgEngagementRate'
  | 'impressions'
  | 'status'
  | 'conversionRate'
  | 'pageViews'
  | 'salesOrders'
  | 'salesRevenue'
  | 'commissions'

export const columnLabelMap: Record<Column, string | { [K in Size]: string }> = {
  signupDate: 'Sign-up',
  approvedAt: 'Member Since',
  posts: 'Posts',
  followers: 'Followers',
  maxPostDate: 'Last Post',
  avgEngagementRate: 'Eng. Rate',
  impressions: { small: 'Impr.', medium: 'Impressions' },
  status: 'Status',
  conversionRate: 'Conversion Rate',
  pageViews: 'Sessions',
  salesOrders: { small: 'Orders', medium: 'Referred Orders' },
  salesRevenue: { small: 'Revenue', medium: 'Referred Sales Revenue' },
  commissions: 'Commissions Earned',
}

export const columnApplicantSortMap: { [K in Column]?: ParticipantSort } = {
  signupDate: ParticipantSort.SignedUpAt,
  posts: ParticipantSort.Posts,
  followers: ParticipantSort.Followers,
  avgEngagementRate: ParticipantSort.AvgEngagementRate,
  impressions: ParticipantSort.Impressions,
}

export const columnParticipantSortMap: { [K in Column]?: ParticipantSort } = {
  approvedAt: ParticipantSort.ApprovedAt,
  posts: ParticipantSort.Posts,
  followers: ParticipantSort.Followers,
  avgEngagementRate: ParticipantSort.AvgEngagementRate,
  impressions: ParticipantSort.Impressions,
  pageViews: ParticipantSort.Landings,
  conversionRate: ParticipantSort.ConversionRate,
  commissions: ParticipantSort.Commissions,
  salesRevenue: ParticipantSort.Revenue,
  salesOrders: ParticipantSort.Orders,
}

export const columnCustomerSortMap: { [K in Column]?: CustomerSort } = {
  posts: CustomerSort.Posts,
  followers: CustomerSort.Followers,
  maxPostDate: CustomerSort.MaxPostDate,
  avgEngagementRate: CustomerSort.AvgEngagementRate,
  impressions: CustomerSort.Impressions,
}

export const customerColumns: readonly Column[] = [
  'posts',
  'followers',
  'maxPostDate',
  'avgEngagementRate',
  'impressions',
] as const

export const smallCustomerColumns: readonly Column[] = ['posts', 'followers', 'maxPostDate', 'impressions'] as const

export const applicantColumns: readonly Column[] = [
  'signupDate',
  'posts',
  'followers',
  'avgEngagementRate',
  'impressions',
  'status',
] as const

export const participantColumns: readonly Column[] = ['posts', 'followers', 'avgEngagementRate', 'impressions'] as const

export const shoppableParticipantColumns: readonly Column[] = [
  'posts',
  'impressions',
  'pageViews',
  'conversionRate',
  'salesOrders',
  'salesRevenue',
  'commissions',
] as const
