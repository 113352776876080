import { Box, createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { AbsoluteDateRangeFilter, AbsoluteDateTimeRangeFilter, RelativeDateRangeFilter } from '../../gql-global'
import theme from '../../loudcrowd-theme'
import { NumberFormatTypes } from '../../utils/number-format'
import { DataPoint, TimeDataPoint, vRefLine } from '../charts/types'
import ContainerError from '../ContainerError'
import metricMap, { MetricTypes } from '../metric/metric-map'
import MetricMultiBarChart from '../metric/MetricMultiBarChart'
import MetricTab from './Tab'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      background: theme.palette.background.paper,
      '&:first-child': {
        borderRadius: '8px 0 0 0',
      },
      '&:last-child': {
        borderRadius: '0 8px 0 0',
      },
    },
  }),
)

export interface DataConfig<K> {
  metricType: MetricTypes
  dataKey: K
  currencyCode?: string
}

interface SummarizedMetricBarChartProps<
  A extends string,
  T extends TimeDataPoint<A>,
  B extends string,
  D extends DataPoint<B>,
> {
  title: string
  timeseries: T[]
  readonly timeseriesConfig: ReadonlyArray<DataConfig<A>>
  data: D
  previousData?: D
  readonly config: ReadonlyArray<DataConfig<B>>
  timeseriesDataFormat: NumberFormatTypes
  loading: boolean
  dateRangeFilter?: RelativeDateRangeFilter | AbsoluteDateRangeFilter | AbsoluteDateTimeRangeFilter | null | undefined
  vRefLines?: vRefLine[]
  error?: boolean
}

function SummarizedMetricBarChart<
  A extends string,
  T extends TimeDataPoint<A>,
  B extends string,
  D extends DataPoint<B>,
>({
  title,
  data,
  previousData,
  config,
  timeseries,
  timeseriesConfig,
  timeseriesDataFormat,
  loading = true,
  dateRangeFilter,
  vRefLines,
  error,
}: SummarizedMetricBarChartProps<A, T, B, D>): React.ReactElement {
  const classes = useStyles()
  const timeseriesDataKeys = timeseriesConfig.map(c => {
    return c.dataKey
  })
  const timeseriesMetricTypes = timeseriesConfig.map(c => {
    return c.metricType
  })
  const currencyCode = timeseriesConfig[0]?.currencyCode || 'USD'
  return (
    <Box>
      <Box fontSize="1.125rem" fontWeight="fontWeightBold" pb={2}>
        {title}
      </Box>
      <Grid container>
        {config.map((key, idx) => {
          const t = metricMap[key.metricType]
          return (
            <Grid item xs={4} className={classes.container} key={t.id}>
              <MetricTab
                hasCompare={!!dateRangeFilter && !!previousData}
                metric={key.dataKey && data?.[key.dataKey]}
                previousMetric={key.dataKey && previousData?.[key.dataKey]}
                loading={loading}
                metricType={key.metricType}
                dateRangeFilter={dateRangeFilter}
                currencyCode={key.currencyCode}
              />
              {idx < config.length - 1 && (
                <Divider
                  orientation="vertical"
                  style={{ height: '73px', width: '1px', marginLeft: '-1px', marginTop: '35px' }}
                />
              )}
            </Grid>
          )
        })}
      </Grid>
      <Box
        height="434px"
        bgcolor={theme.palette.background.paper}
        borderRadius="0 0 8px 8px"
        padding="100px 44px 10px 32px"
      >
        {!error && (
          <MetricMultiBarChart
            dataKeys={timeseriesDataKeys}
            data={timeseries}
            dataFormat={timeseriesDataFormat}
            metricTypes={timeseriesMetricTypes}
            vRefLines={vRefLines}
            currencyCode={currencyCode}
          />
        )}
        {error && <ContainerError text="Could not load chart" />}
      </Box>
    </Box>
  )
}

export default SummarizedMetricBarChart
