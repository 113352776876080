import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetryEspMessageRewardBatchJobMutationVariables = Types.Exact<{
  reward_id: Types.Scalars['ID'];
  reward_batch_job_id: Types.Scalars['ID'];
}>;


export type RetryEspMessageRewardBatchJobMutation = { __typename: 'Mutations', retryRewardBatchJob?: { __typename: 'RetryRewardBatchJob', ok?: boolean | null } | null };


export const RetryEspMessageRewardBatchJobDocument = gql`
    mutation RetryEspMessageRewardBatchJob($reward_id: ID!, $reward_batch_job_id: ID!) {
  retryRewardBatchJob(
    rewardId: $reward_id
    rewardBatchJobId: $reward_batch_job_id
  ) {
    ok
  }
}
    `;
export type RetryEspMessageRewardBatchJobMutationFn = Apollo.MutationFunction<RetryEspMessageRewardBatchJobMutation, RetryEspMessageRewardBatchJobMutationVariables>;

/**
 * __useRetryEspMessageRewardBatchJobMutation__
 *
 * To run a mutation, you first call `useRetryEspMessageRewardBatchJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryEspMessageRewardBatchJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryEspMessageRewardBatchJobMutation, { data, loading, error }] = useRetryEspMessageRewardBatchJobMutation({
 *   variables: {
 *      reward_id: // value for 'reward_id'
 *      reward_batch_job_id: // value for 'reward_batch_job_id'
 *   },
 * });
 */
export function useRetryEspMessageRewardBatchJobMutation(baseOptions?: Apollo.MutationHookOptions<RetryEspMessageRewardBatchJobMutation, RetryEspMessageRewardBatchJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryEspMessageRewardBatchJobMutation, RetryEspMessageRewardBatchJobMutationVariables>(RetryEspMessageRewardBatchJobDocument, options);
      }
export type RetryEspMessageRewardBatchJobMutationHookResult = ReturnType<typeof useRetryEspMessageRewardBatchJobMutation>;
export type RetryEspMessageRewardBatchJobMutationResult = Apollo.MutationResult<RetryEspMessageRewardBatchJobMutation>;
export type RetryEspMessageRewardBatchJobMutationOptions = Apollo.BaseMutationOptions<RetryEspMessageRewardBatchJobMutation, RetryEspMessageRewardBatchJobMutationVariables>;