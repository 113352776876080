import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import useTitle from '../utils/use-title'
import {
  ADMIN_ACCOUNTS_ROUTE,
  ADMIN_ADD_STORY_MENTION,
  ADMIN_ROUTE,
  ADMIN_CUSTOMER_UPLOAD_ROUTE,
  ADMIN_FEATURE_OVERRIDE,
  ADMIN_SEND_MONTHLY_EMAIL,
  ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS,
} from './routes'
import withAuthorization from '../withAuthorization'

type ToolListItem = {
  title: string
  route: string
}

const tools: ToolListItem[] = [
  {
    title: 'Customer Uploader',
    route: ADMIN_CUSTOMER_UPLOAD_ROUTE.path,
  },
  {
    title: 'Account Management',
    route: ADMIN_ACCOUNTS_ROUTE.path,
  },
  {
    title: 'Add a Story Mention',
    route: ADMIN_ADD_STORY_MENTION.path,
  },
  {
    title: 'Override Features',
    route: ADMIN_FEATURE_OVERRIDE.path,
  },
  {
    title: 'Send Monthly Email',
    route: ADMIN_SEND_MONTHLY_EMAIL.path,
  },
  {
    title: 'TikTok Hashtag Management',
    route: ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS.path,
  },
]

function Admin(): React.ReactElement {
  useTitle('Admin')

  return (
    <Grid container spacing={10} style={{ padding: '24px' }}>
      {tools.map((t, i) => (
        <Grid key={t.route} item xs={12} sm={6} md={4} lg={4} xl={3}>
          <NavLink to={t.route} style={{ textDecoration: 'none' }}>
            <Paper>
              <Box p={4}>
                <Typography variant="h6">{t.title}</Typography>
              </Box>
            </Paper>
          </NavLink>
        </Grid>
      ))}
    </Grid>
  )
}

export default withAuthorization(ADMIN_ROUTE)(Admin)
