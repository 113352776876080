import React from 'react'
import { IntegrationType } from '../../gql-global'

import { Box, makeStyles, createStyles } from '@material-ui/core'
import IntegrationLogo from '.'

interface IntegrationLogoSmallProps {
  integrationType: IntegrationType
}

const useStylesSmallLogo = makeStyles((props: IntegrationLogoSmallProps) => {
  let integrationStyles = {}
  switch (props.integrationType) {
    case IntegrationType.Mandrill:
      integrationStyles = { height: 15, margin: '0 5px 0 7px' }
      break
    case IntegrationType.Zaius:
      integrationStyles = { height: 14, margin: '0 5px 4px 9px', width: 100 } // Optimizely
      break
    case IntegrationType.Sendinblue:
      integrationStyles = { height: 8, margin: '0 5px 4px 9px' }
      break
    case IntegrationType.Yotpo:
      integrationStyles = { height: 12, margin: '0 5px 2px 10px' }
      break
    case IntegrationType.YotpoViz:
      integrationStyles = { height: 18, margin: '0 5px 2px 10px' }
      break
    case IntegrationType.Growave:
      integrationStyles = { height: 9, margin: '0 5px 0 7px' }
      break
    case IntegrationType.Klaviyo:
      integrationStyles = { height: 11, margin: '0 5px 3px 7px' }
      break
    case IntegrationType.Emarsys:
      integrationStyles = { height: 14, margin: '0 5px 4px 7px' }
      break
    case IntegrationType.Braze:
      integrationStyles = { height: 14, margin: '0 5px 1px 7px' }
      break
    case IntegrationType.LoyaltyLion:
      integrationStyles = { height: 16, margin: '0 5px 0 7px' }
      break
    case IntegrationType.Smile:
      integrationStyles = { height: 15, margin: '0 5px 6px 7px' }
      break
    case IntegrationType.Shopify:
      integrationStyles = { height: 14, margin: '0 5px 3px 10px' }
      break
    case IntegrationType.Tremendous:
      integrationStyles = { height: 6, margin: '0 5px 7px 10px' }
      break
    case IntegrationType.Bluecore:
      integrationStyles = { height: 1, margin: '0 5px 0 7px' }
      break
    default:
      integrationStyles = { height: 20, margin: '0 5px 0 7px', width: 57 }
      break
  }
  return createStyles({ integration: integrationStyles })
})

export function IntegrationLogoSmall(props: IntegrationLogoSmallProps) {
  const classes = useStylesSmallLogo(props)
  return (
    <Box display="flex" alignItems="flex-start" height={24}>
      <IntegrationLogo
        integrationType={props.integrationType ?? IntegrationType.Webhook}
        className={classes.integration}
      />
    </Box>
  )
}

export default IntegrationLogoSmall
