import { useEffect, useState } from 'react'
import { EcommCustomerSegmentType, EcommDiscountAppliesToEnum } from '../../../../gql-global'
import { OptionType } from '../ProductCollectionBrandPickerMenu'

type DiscountCodePickerOptionType = OptionType | EcommCustomerSegmentType

/**
 * Custom hook to support a picker in the discount code modal
 * @param fieldValueProperty
 * @param setFieldValue
 * @param selectedItems
 * @param discountAppliesTo
 * @returns
 */
const useDiscountCodePicker = <T extends DiscountCodePickerOptionType>(
  fieldValueProperty: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  selectedItems: T[],
  discountAppliesTo?: EcommDiscountAppliesToEnum,
) => {
  /**
   * State
   */
  const [openPicker, setOpenPicker] = useState(false)
  const [selected, setSelected] = useState<T[]>(selectedItems)

  /**
   * Event handler
   */
  const handleOnAdd = (items: T[]) => {
    setSelected(items)
    setFieldValue(fieldValueProperty, items)
    setOpenPicker(false)
  }

  const handleRemoveItem = (id: string) => {
    const items = selectedItems.filter(item => item.id !== id)
    setSelected(items)
    setFieldValue(fieldValueProperty, items)
  }

  /**
   * Effects
   */
  useEffect(() => {
    setSelected([])
  }, [discountAppliesTo])
  useEffect(() => {
    setSelected(selectedItems)
  }, [selectedItems])

  return {
    states: {
      openPicker,
      setOpenPicker,
      selectedItems: selected,
      setSelectedItems: setSelected,
    },
    events: {
      handleOnAdd,
      handleRemoveItem,
    },
  }
}

export default useDiscountCodePicker
