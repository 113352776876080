import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLabelMutationVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  isRejectionReason: Types.Scalars['Boolean'];
  name: Types.Scalars['String'];
}>;


export type CreateLabelMutation = { __typename: 'Mutations', createLabel?: { __typename: 'CreateLabel', ok?: boolean | null, label?: { __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string, account?: { __typename: 'AccountType', id: number } | null } | null } | null };


export const CreateLabelDocument = gql`
    mutation CreateLabel($accountId: ID!, $isRejectionReason: Boolean!, $name: String!) {
  createLabel(
    accountId: $accountId
    isRejectionReason: $isRejectionReason
    name: $name
  ) {
    label {
      account {
        id
      }
      id
      isRejectionReason
      name
    }
    ok
  }
}
    `;
export type CreateLabelMutationFn = Apollo.MutationFunction<CreateLabelMutation, CreateLabelMutationVariables>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      isRejectionReason: // value for 'isRejectionReason'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateLabelMutation, CreateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLabelMutation, CreateLabelMutationVariables>(CreateLabelDocument, options);
      }
export type CreateLabelMutationHookResult = ReturnType<typeof useCreateLabelMutation>;
export type CreateLabelMutationResult = Apollo.MutationResult<CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<CreateLabelMutation, CreateLabelMutationVariables>;