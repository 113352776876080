import React, { useState, useEffect } from 'react'
import { Box, Popover, PopoverProps, MenuList, MenuItem, makeStyles } from '@material-ui/core'
import { ValueOf } from '../../../types/utility'
import { TypeMap, Option, Options, ParentChildOption, ParentOption, DirectSelectionOption } from './types'
import DateRangeFilterMenu from './DateRangeFilterMenu'
import BulkLabelsFilterMenu from './BulkLabelsFilterMenu'
import BulkChipsFilterMenu from './BulkChipsFilterMenu'
import BulkKeywordsFilterMenu from './BulkKeywordsFilterMenu'
import { Filters } from './types'
import NumericalRangeFilterMenu from './NumericalRangeFilterMenu'
import SelectionFilterMenu from './SelectionFilterMenu'
import KeywordFilterMenu from './KeywordFilterMenu'
import SingleSelectionFilterMenu from './SingleSelectionFilterMenu'
import MenuSelectionFilterMenu from './MenuFilter'
import { ReactComponent as ChevronRight } from '../../../icons/chevron-right_minor.svg'
import MenuHeader from '../../MenuHeader'

interface AddListFilterDialogProps {
  filters: Filters
  options: Options
  onAddFilter(selectedOption: Option, value: ValueOf<TypeMap>, exclude: boolean): void
  anchorEl: PopoverProps['anchorEl']
  open: boolean
  exclude?: boolean
  onCancel(): void
  onDelete?(filter: string): void
}

const useStyles = makeStyles({
  menuPaper: {
    minWidth: 272,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

interface DirectSelectionFilterMenuProps {
  option: DirectSelectionOption
  handleValueSelected: (value: ValueOf<TypeMap>) => void
  exclude: boolean
}
// This component is rendered just to set the value (which closes the menu) immediately after render.
// It's done this way so it's like the other option types and also to avoid rendering loops.
const DirectSelectionFilterMenu: React.FC<DirectSelectionFilterMenuProps> = ({
  option,
  handleValueSelected,
  exclude,
}) => {
  useEffect(() => {
    handleValueSelected(exclude ? option.excludeValue : option.addValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

function AddListFilterDialog({
  filters,
  options,
  anchorEl,
  open,
  exclude = false,
  onAddFilter,
  onCancel,
  onDelete,
}: AddListFilterDialogProps): React.ReactElement {
  const classes = useStyles()
  const [selectedOption, setSelectedOption] = useState<Option | ParentChildOption | null>(null)
  const [parentOption, setParentOption] = useState<ParentOption | null>(null)

  useEffect(() => {
    if (open) {
      setParentOption(null)
      setSelectedOption(null)
    }
  }, [open])

  function handleValueSelected(value: ValueOf<TypeMap>): void {
    if (selectedOption) {
      onAddFilter(selectedOption, value, exclude)
    }
  }

  function handleMenuClosed(): void {
    onCancel()
  }

  function handleSelectedOption(optionName: string): void {
    let option
    if (parentOption && parentOption.children) {
      option = parentOption.children.find(o => o.name === optionName) || null
      setParentOption(null)
    } else if (selectedOption && selectedOption.type === 'parent' && selectedOption.children) {
      option = selectedOption.children.find(o => o.name === optionName) || null
      setParentOption(selectedOption)
    } else {
      option = options.find(o => o.name === optionName) || null
    }
    setSelectedOption(option)
  }

  function handleClickBack(): void {
    if (parentOption) {
      setSelectedOption(parentOption)
      setParentOption(null)
    } else {
      setSelectedOption(null)
    }
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      classes={{ paper: classes.menuPaper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      getContentAnchorEl={null}
      onClose={handleMenuClosed}
    >
      {!selectedOption && (
        <MenuList>
          {options.map(o => (
            <Box key={o.name}>
              <MenuItem onClick={() => handleSelectedOption(o.name)} className={classes.menuItem}>
                {o.label}
                {o.type === 'parent' && <ChevronRight width={16} height={16} />}
              </MenuItem>
            </Box>
          ))}
        </MenuList>
      )}
      {selectedOption?.type === 'parent' && selectedOption?.children && (
        <div>
          <MenuHeader title={selectedOption.label} onClickBack={handleClickBack} />
          <Box pt={4}>
            {selectedOption.children.map(o => (
              <MenuItem key={o.name} onClick={() => handleSelectedOption(o.name)}>
                {o.label}
              </MenuItem>
            ))}
          </Box>
        </div>
      )}
      {selectedOption?.type === 'dateRange' && (
        <DateRangeFilterMenu option={selectedOption} onBack={handleClickBack} onSelectValue={handleValueSelected} />
      )}
      {selectedOption?.type === 'numericalRange' && (
        <NumericalRangeFilterMenu
          option={selectedOption}
          onBack={handleClickBack}
          onSelectValue={handleValueSelected}
        />
      )}
      {selectedOption?.type === 'labels' && (
        <BulkLabelsFilterMenu
          option={selectedOption}
          value={filters[selectedOption.name]}
          onDelete={() => {
            if (onDelete) {
              onDelete(selectedOption.name)
              handleMenuClosed()
            }
          }}
          onBack={handleClickBack}
          onSelectValue={handleValueSelected}
          exclude={exclude}
          includeField={selectedOption?.includeField}
          filterLabelsAllIndex={
            (selectedOption?.includeField && filters[selectedOption.name]?.[selectedOption?.includeField]?.length) || 0
          }
        />
      )}
      {selectedOption?.type === 'chips' && (
        <BulkChipsFilterMenu
          option={selectedOption}
          value={filters[selectedOption.name]}
          onBack={handleClickBack}
          onSelectValue={handleValueSelected}
          onClose={handleMenuClosed}
          onDelete={() => onDelete && onDelete(selectedOption.name)}
        />
      )}
      {selectedOption?.type === 'selection' && (
        <SelectionFilterMenu
          option={selectedOption}
          onBack={handleClickBack}
          onSelectValue={v => {
            if (selectedOption?.exclude) {
              const valueKey = exclude ? 'none' : 'any'
              handleValueSelected({
                ...(filters?.[selectedOption.name] ? filters?.[selectedOption.name] : {}),
                [valueKey]: Array.from(v),
              })
            } else {
              handleValueSelected(v)
            }
          }}
        />
      )}
      {selectedOption?.type === 'singleSelection' && (
        <SingleSelectionFilterMenu
          option={selectedOption}
          onBack={handleClickBack}
          onSelectValue={handleValueSelected}
        />
      )}
      {selectedOption?.type === 'directSelection' && (
        <DirectSelectionFilterMenu
          option={selectedOption}
          handleValueSelected={handleValueSelected}
          exclude={exclude}
        />
      )}
      {selectedOption?.type === 'singleMenu' && (
        <MenuSelectionFilterMenu option={selectedOption} onBack={handleClickBack} onSelectValue={handleValueSelected} />
      )}
      {selectedOption?.type === 'keywords' && (
        <KeywordFilterMenu
          option={selectedOption}
          value={filters[selectedOption.name]}
          onBack={handleClickBack}
          onSelectValue={handleValueSelected}
          anchorEl={anchorEl}
          isExclusion={exclude ?? false}
        />
      )}
      {selectedOption?.type === 'keywordsChips' && (
        <BulkKeywordsFilterMenu
          option={selectedOption}
          value={filters[selectedOption.name]}
          onBack={handleClickBack}
          onSelectValue={handleValueSelected}
          onClose={handleMenuClosed}
        />
      )}
    </Popover>
  )
}

export default AddListFilterDialog
