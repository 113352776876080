import React, { useRef, Dispatch } from 'react'
import { useTheme, Box } from '@material-ui/core'
import PostCard, { cardWidth } from '../components/PostCard/PostCard'
import { useContainerDimensions } from '../hooks/useComponentDimensions'
import { PostCardFragment } from '../queries/operations/post-card.generated'
import { SelectionState, SelectionAction } from '../hooks/useSelectionState'

const gapSpacing = 8

interface PostListProps {
  hasCampaigns?: boolean
  hasStoriesFeature?: boolean
  loading?: boolean
  loadingMore?: boolean
  posts?: PostCardFragment[] | null
  onClickPost(id: string): void
  max?: number
  selectable?: boolean
  selectionState?: SelectionState<string>
  selectionDispatch?: Dispatch<SelectionAction<string>>
}

const PostList: React.FC<PostListProps> = ({
  hasCampaigns = false,
  loading,
  loadingMore,
  posts,
  onClickPost,
  selectionState,
  max = 12,
  selectionDispatch,
  selectable = false,
}) => {
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)
  const gap = theme.spacing(gapSpacing)
  const cardsInRow = Math.floor((width - gap) / (cardWidth + gap))
  const lastCardStyles =
    posts?.length && posts.length < cardsInRow
      ? {
          gridColumn: `span ${cardsInRow - posts.length + 1}`,
        }
      : undefined

  const createClickedPost = (id: string) => () => onClickPost(id)
  return (
    <Box
      display="grid"
      gridGap={gap}
      gridTemplateColumns={`repeat(auto-fit, ${cardWidth}px)`}
      justifyContent="space-between"
      {...{ ref: componentRef }}
    >
      {!loading &&
        posts?.map((p, i) => {
          return (
            <PostCard
              key={p.id}
              post={p}
              hasCampaigns={hasCampaigns}
              handleClick={createClickedPost(p.id)}
              style={i === posts.length - 1 ? lastCardStyles : undefined}
              selectable={selectable}
              selected={selectionState === 'ALL' || selectionState?.has(p.id)}
              onSelectChanged={(isSelected: boolean) => {
                if (!selectionDispatch) return
                const id = p.id
                if (isSelected) {
                  selectionDispatch({ type: 'select', id })
                } else {
                  selectionDispatch({ type: 'deselect', id, idsVisible: posts?.map(p => p.id) })
                }
              }}
              disabled={p.isPastLimit}
            />
          )
        })}
      {(loading || loadingMore) &&
        new Array(loadingMore ? 12 : max).fill(1).map((_, i) => <PostCard key={i} loading />)}
    </Box>
  )
}

export default PostList
