import React, { Dispatch, useRef, useState } from 'react'
import {
  Box,
  Checkbox,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  Size,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import CustomerRow, { CustomerRowProps, UsernameColumnType } from './CustomerRow'
import { CustomerSort, FbUser, ParticipantSort, PaymentStatusEnum, SortDirection } from '../../gql-global'
import { ReactComponent as EmptyListImage } from '../../images/empty-list.svg'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg'
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg'
import { ReactComponent as TikTokIcon } from '../../icons/tiktok_logo_outline.svg'
import { ReactComponent as InfoIcon } from '../../icons/information-bordered.svg'
import { SelectionAction, SelectionState } from '../../hooks/useSelectionState'
import ContainerEmptyState from '../ContainerEmptyState/ContainerEmptyState'
import { Skeleton } from '@material-ui/lab'
import { TableRowCustomerFragment } from './operations/table-row-customer.generated'
import { TableRowParticipantFragment } from './operations/table-row-participant.generated'
import {
  applicantColumns,
  Column,
  columnApplicantSortMap,
  columnCustomerSortMap,
  columnLabelMap,
  columnParticipantSortMap,
  customerColumns,
  participantColumns,
  shoppableParticipantColumns,
  smallCustomerColumns,
} from './columns'
import { ListActionConfig } from '../lists/ListActionMenu'
import { TableRowShoppableParticipantFragment } from './operations/table-row-shoppable-participant.generated'
import theme, { gray } from '../../loudcrowd-theme'
import Tooltip from '../Tooltip/Tooltip'

type CustomerTableEntity = 'participant' | 'shoppableParticipant' | 'customer' | 'applicant'

interface CustomerTableBaseProps<K extends string> {
  entity: CustomerTableEntity
  hasCampaigns?: boolean
  nextPaymentDate?: Date
  isIGSocialAccount?: boolean | null
  limit?: number
  ready?: boolean
  loadingMore?: boolean
  multiSelect?: boolean
  selectionState?: SelectionState
  selectionDispatch?: Dispatch<SelectionAction>
  //TODO: remove useCustomerPageLinks when we have the marketing page for customer details
  useCustomerPageLinks?: boolean
  actions?: ListActionConfig<K>[]
  deleteAction?: ListActionConfig<K>
  sortDir?: SortDirection
  onSelectCustomerAction?(id: number, action: K): void
  size?: Size
  emptyText?: string
}

interface CustomerTableProps<K extends string> extends CustomerTableBaseProps<K> {
  entity: 'customer'
  list: TableRowCustomerFragment[]
  sort: CustomerSort
  setSort?(sort: CustomerSort): void
}

interface ApplicantCustomerTableProps<K extends string> extends CustomerTableBaseProps<K> {
  entity: 'applicant'
  list: TableRowParticipantFragment[]
  sort: ParticipantSort
  setSort?(sort: ParticipantSort): void
}

interface ParticipantCustomerTableProps<K extends string> extends CustomerTableBaseProps<K> {
  entity: 'participant'
  list: TableRowParticipantFragment[]
  sort: ParticipantSort
  setSort?(sort: ParticipantSort): void
}

interface ShoppableCustomerTableProps<K extends string> extends CustomerTableBaseProps<K> {
  entity: 'shoppableParticipant'
  list: TableRowParticipantFragment[]
  sort: ParticipantSort
  showCommissionsColumn: boolean
  setSort?(sort: ParticipantSort, forceDir?: SortDirection): void
  includedCommissionsStatuses: Set<PaymentStatusEnum>
  ordersAwaitingApproval: boolean | null
  currencyCode?: string
}

const useStyles = makeStyles({
  menuPaper: {
    minWidth: 272,
  },
  infoIcon: {
    color: theme.palette.primary.main,
    verticalAlign: 'bottom',
    marginBottom: '0.25rem',
  },
  infoIconContainer: {
    display: 'inline-block',
    verticalAlign: 'bottom',
  },
  sectionCaption: {
    marginLeft: '1rem',
    fontWeight: 600,
    color: 'grey',
  },
})

function mapCustomerRow<K extends string>(
  customer: TableRowCustomerFragment,
  otherProps: Pick<
    CustomerRowProps<K>,
    | 'hasCampaigns'
    | 'columns'
    | 'useCustomerPageLinks'
    | 'selectable'
    | 'onSelect'
    | 'actions'
    | 'onSelectAction'
    | 'deleteAction'
  >,
  selectionSet: SelectionState | undefined,
  indexInList: number,
  isIGSocialAccount?: boolean | null,
): React.ReactElement {
  const socialAccounts = customer?.igUser?.storiesIgSocialAccounts || []
  const fbUsers: FbUser[] = []
  if (socialAccounts.length > 0) {
    socialAccounts.forEach(sa => {
      if (sa.fbUsers && sa.fbUsers.length > 0) {
        fbUsers.push(...sa.fbUsers)
      }
    })
  }

  const customerSocialUser = isIGSocialAccount ? customer?.igUser : customer?.ttUser
  const username = customerSocialUser?.username
  const avatarUrl = customerSocialUser?.avatarUrl
  const followerCount = customerSocialUser?.followerCount

  return (
    <CustomerRow
      isFirstRow={indexInList === 0}
      fbUsers={fbUsers}
      key={customer?.id}
      customerId={customer?.id}
      username={username}
      avatarUrl={avatarUrl}
      postCount={customer?.mentionStats?.postCount}
      hasActiveStory={!!customer?.activeStoryMentionsStats?.postCount}
      maxPostDate={customer?.mentionStats?.maxPostDate}
      followerCount={followerCount}
      impressions={customer?.mentionStats?.impressions}
      avgEngagementRate={customer?.mentionStats?.avgEngagementRate}
      selected={selectionSet === 'ALL' || selectionSet?.has(customer.id)}
      {...otherProps}
    />
  )
}

function mapParticipantCustomerRow<K extends string>(
  participant: TableRowParticipantFragment,
  otherProps: Pick<
    CustomerRowProps<K>,
    | 'hasCampaigns'
    | 'columns'
    | 'useCustomerPageLinks'
    | 'selectable'
    | 'onSelect'
    | 'actions'
    | 'onSelectAction'
    | 'deleteAction'
  >,
  selectionSet: SelectionState | undefined,
  entity: 'applicant' | 'participant' | 'shoppableParticipant' | 'customer' | null,
  indexInList: number,
  isIGSocialAccount?: boolean | null,
): React.ReactElement {
  const customer = participant.customer
  const fbUsers: FbUser[] = []

  const customerSocialUser = isIGSocialAccount ? customer?.igUser : customer?.ttUser

  if (customerSocialUser && isIGSocialAccount) {
    const socialAccounts = customer?.igUser?.storiesIgSocialAccounts || []
    if (socialAccounts.length > 0) {
      socialAccounts.forEach(sa => {
        if (sa.fbUsers && sa.fbUsers.length > 0) {
          fbUsers.push(...sa.fbUsers)
        }
      })
    }
  }

  const username = customerSocialUser?.username
  const avatarUrl = customerSocialUser?.avatarUrl
  const followerCount = customerSocialUser?.followerCount

  return (
    <CustomerRow
      isFirstRow={indexInList === 0}
      fbUsers={fbUsers}
      key={participant.id}
      entityId={participant.id}
      entity={entity}
      customerId={customer?.id}
      hasActiveStory={!!customer?.activeStoryMentionsStats?.postCount}
      username={username}
      approvedAt={participant.approvedAt}
      signupDate={participant.createdAt}
      avatarUrl={avatarUrl}
      postCount={customer?.mentionStats?.postCount}
      maxPostDate={customer?.mentionStats?.maxPostDate}
      followerCount={followerCount}
      impressions={customer?.mentionStats?.impressions}
      avgEngagementRate={customer?.mentionStats?.avgEngagementRate}
      selected={selectionSet === 'ALL' || selectionSet?.has(participant.id)}
      status={participant?.status}
      {...otherProps}
    />
  )
}

function mapShoppableCustomerRow<K extends string>(
  participant: TableRowShoppableParticipantFragment,
  tableProps: ShoppableCustomerTableProps<K>,
  otherProps: Pick<
    CustomerRowProps<K>,
    | 'hasCampaigns'
    | 'columns'
    | 'useCustomerPageLinks'
    | 'selectable'
    | 'onSelect'
    | 'actions'
    | 'onSelectAction'
    | 'deleteAction'
    | 'nextPaymentDate'
  >,
  usernameColumn: UsernameColumnType,
  selectionSet: SelectionState | undefined,
  entity: 'applicant' | 'participant' | 'shoppableParticipant' | 'customer' | null,
  indexInList: number,
  isIGSocialAccount?: boolean | null,
): React.ReactElement {
  const customer = participant.customer
  const fbUsers: FbUser[] = []

  const customerSocialUser = isIGSocialAccount ? customer?.igUser : customer?.ttUser

  if (customerSocialUser && isIGSocialAccount) {
    const socialAccounts = customer?.igUser?.storiesIgSocialAccounts || []
    if (socialAccounts.length > 0) {
      socialAccounts.forEach(sa => {
        if (sa.fbUsers && sa.fbUsers.length > 0) {
          fbUsers.push(...sa.fbUsers)
        }
      })
    }
  }

  let username
  let avatarUrl = customerSocialUser?.avatarUrl
  let usernameIcon
  switch (usernameColumn) {
    case ParticipantSort.IgUsername:
      if (customer?.igUser) {
        username = customer.igUser.username
        avatarUrl = customer.igUser.avatarUrl
      } else {
        username = customer?.ttUser?.username
        avatarUrl = customer?.ttUser?.avatarUrl
        usernameIcon = TikTokIcon
      }
      break
    case ParticipantSort.TtUsername:
      if (customer?.ttUser) {
        username = customer.ttUser.username
        avatarUrl = customer.ttUser.avatarUrl
      } else {
        username = customer?.igUser?.username
        avatarUrl = customer?.igUser?.avatarUrl
        usernameIcon = InstagramIcon
      }
      break
    case ParticipantSort.Email:
      username = customer?.email
      break
    case ParticipantSort.FullName:
      // Name might not be present, fallback to email if necessary
      if (customer?.fullName) {
        username = customer.fullName
      } else {
        username = customer?.email
      }
  }
  const followerCount = customerSocialUser?.followerCount

  const pageViews = customer?.ambassadorStats?.landings || 0
  const salesOrders = {
    approved: customer?.ambassadorStats?.ordersApproved ?? 0,
    awaiting_approval: customer?.ambassadorStats?.ordersAwaitingApproval ?? 0,
  }
  const salesRevenue = {
    approved: customer?.ambassadorStats?.revenueApproved ?? 0,
    awaiting_approval: customer?.ambassadorStats?.revenueAwaitingApproval ?? 0,
  }

  const totalOrders = customer?.ambassadorStats?.totalOrders || 0

  const conversionRate = (pageViews === 0 ? 0 : totalOrders / pageViews) * 100

  const paidCommissions = customer?.ambassadorStats?.commissionsPaid || 0
  const pendingCommissions = customer?.ambassadorStats?.commissionsPending || 0
  const owedCommissions = customer?.ambassadorStats?.commissionsOwed || 0

  return (
    <CustomerRow
      isFirstRow={indexInList === 0}
      fbUsers={fbUsers}
      key={participant.id}
      entityId={participant.id}
      entity={entity}
      customerId={customer?.id}
      hasActiveStory={!!customer?.activeStoryMentionsStats?.postCount}
      username={username}
      usernameColumn={usernameColumn}
      UsernameIcon={usernameIcon}
      approvedAt={participant.approvedAt}
      signupDate={participant.createdAt}
      avatarUrl={avatarUrl}
      postCount={customer?.mentionStats?.postCount}
      maxPostDate={customer?.mentionStats?.maxPostDate}
      followerCount={followerCount}
      pageViews={pageViews}
      salesOrders={salesOrders}
      salesRevenue={salesRevenue}
      conversionRate={conversionRate}
      paymentStatuses={tableProps.includedCommissionsStatuses}
      ordersAwaitingApproval={tableProps.ordersAwaitingApproval}
      paidCommissions={paidCommissions}
      pendingCommissions={pendingCommissions}
      owedCommissions={owedCommissions}
      impressions={customer?.mentionStats?.impressions}
      avgEngagementRate={customer?.mentionStats?.avgEngagementRate}
      selected={selectionSet === 'ALL' || selectionSet?.has(participant.id)}
      status={participant?.status}
      programCommissionTierName={
        participant?.program?.commissionsEnabled ? participant?.programCommissionTier?.name : undefined
      }
      currencyCode={tableProps.currencyCode}
      {...otherProps}
    />
  )
}

const usernameFields: { [k in UsernameColumnType]: string } = {
  [ParticipantSort.IgUsername]: 'Instagram Username',
  [ParticipantSort.TtUsername]: 'TiKTok Username',
  [ParticipantSort.FullName]: 'First & Last Name',
  [ParticipantSort.Email]: 'Email',
}
const usernameSortDirection: { dir: SortDirection; title: string; rotate: number | null }[] = [
  { dir: SortDirection.Asc, title: 'Sort by A to Z', rotate: 180 },
  { dir: SortDirection.Desc, title: 'Sort by Z to A', rotate: null },
]

function UsernameSelectPopover(props: {
  anchorEl: Element | null
  usernameMenuOpen: boolean
  setUsernameMenuOpen: (val: boolean) => void
  usernameSelectedField: UsernameColumnType
  setUsernameSelectedField: (val: UsernameColumnType) => void
  sort: ParticipantSort
  setSort: (val: ParticipantSort, forceDir?: SortDirection) => void
  sortDir: SortDirection
}): React.ReactElement {
  const {
    usernameMenuOpen,
    setUsernameMenuOpen,
    usernameSelectedField,
    setUsernameSelectedField,
    anchorEl,
    sort,
    setSort,
    sortDir,
  } = props
  const classes = useStyles()

  return (
    <Popover
      open={usernameMenuOpen}
      anchorEl={anchorEl}
      classes={{ paper: classes.menuPaper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      getContentAnchorEl={null}
      onClose={() => setUsernameMenuOpen(false)}
    >
      <Typography variant="caption" component="i" className={classes.sectionCaption}>
        Show as
      </Typography>
      {Object.entries(usernameFields).map((field, i) => {
        const [fieldType, fieldTitle] = field as [UsernameColumnType, string]
        return (
          <ListItem
            key={i}
            button
            onClick={() => {
              if (usernameSelectedField === fieldType) return
              setUsernameSelectedField(fieldType)
              setUsernameMenuOpen(false)
            }}
          >
            <Checkbox
              size="small"
              edge="start"
              icon={<CheckmarkIcon color="transparent" width={12} />}
              checkedIcon={<CheckmarkIcon color="black" width={12} />}
              checked={usernameSelectedField === fieldType}
              tabIndex={-1}
            />
            <ListItemText primary={fieldTitle} />
          </ListItem>
        )
      })}
      <hr style={{ border: 'none', backgroundColor: gray, height: 2 }} />
      <Typography variant="caption" component="i" className={classes.sectionCaption}>
        Order
      </Typography>
      {usernameSortDirection.map((field, i) => (
        <ListItem
          key={i + Object.keys(usernameFields).length}
          button
          onClick={() => {
            if (sortDir === field.dir && usernameSelectedField === sort) return
            setSort(usernameSelectedField, field.dir)
            setUsernameMenuOpen(false)
          }}
        >
          <Checkbox
            size="small"
            edge="start"
            icon={
              <ArrowIcon
                color="transparent"
                transform={field.rotate ? `rotate(${field.rotate})` : undefined}
                width={12}
              />
            }
            checkedIcon={
              <ArrowIcon color="black" transform={field.rotate ? `rotate(${field.rotate})` : undefined} width={12} />
            }
            checked={sortDir === field.dir && usernameSelectedField === sort}
            tabIndex={-1}
          />
          <ListItemText primary={field.title} />
        </ListItem>
      ))}
    </Popover>
  )
}

function CustomerTable<K extends string>(
  props:
    | CustomerTableProps<K>
    | ParticipantCustomerTableProps<K>
    | ApplicantCustomerTableProps<K>
    | ShoppableCustomerTableProps<K>,
): React.ReactElement {
  const {
    hasCampaigns,
    list,
    ready,
    loadingMore,
    entity,
    limit = 10,
    sort,
    sortDir = SortDirection.Desc,
    selectionState,
    selectionDispatch,
    actions,
    deleteAction,
    onSelectCustomerAction,
    useCustomerPageLinks = false,
    multiSelect = false,
    size = 'medium',
    isIGSocialAccount,
    emptyText = 'No customers found',
  } = props
  const sortDirection = sortDir === SortDirection.Desc ? 'desc' : 'asc'
  const usernameMenuRef = useRef<HTMLButtonElement>(null)
  const [usernameMenuOpen, setUsernameMenuOpen] = useState(false)
  const [usernameSelectedField, setUsernameSelectedField] = useState<UsernameColumnType>(
    isIGSocialAccount ? ParticipantSort.IgUsername : ParticipantSort.TtUsername,
  )
  const classes = useStyles()

  function handleSelectAllShown(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!selectionDispatch) return
    if (event.target.checked) {
      const id = props.entity === 'customer' ? props.list.map(c => c.id) : props.list.map(p => p.id)
      selectionDispatch({ type: 'select', id })
    } else {
      selectionDispatch({ type: 'reset' })
    }
  }

  let columns: readonly Column[]
  if (entity === 'customer') {
    columns = size === 'medium' ? customerColumns : smallCustomerColumns
  } else if (entity === 'applicant') {
    columns = applicantColumns
  } else if (entity === 'participant') {
    columns = participantColumns
  } else {
    columns = shoppableParticipantColumns
    if (!props.showCommissionsColumn) {
      columns = columns.filter(c => c !== 'commissions')
    }
  }
  const sharedRowProps = {
    hasCampaigns: hasCampaigns,
    nextPaymentDate: props?.nextPaymentDate,
    columns: columns,
    useCustomerPageLinks: useCustomerPageLinks,
    selectable: multiSelect,
    onSelect(id: number, value: boolean) {
      if (!selectionDispatch) return
      if (value) {
        selectionDispatch({ type: 'select', id })
      } else {
        const ids = props.entity === 'customer' ? props.list.map(c => c.id) : props.list.map(p => p.id)
        selectionDispatch({ type: 'deselect', id, idsVisible: ids })
      }
    },
    actions,
    deleteAction,
    onSelectAction: onSelectCustomerAction,
  }
  const gridTemplateColumns =
    entity === 'shoppableParticipant'
      ? // some extra space allowed for the Referred Orders and Sales Revenue columns
        `${multiSelect ? 'max-content ' : ''}max-content minmax(auto, 15rem) minmax(auto, 5rem) minmax(auto, 7rem)
         minmax(auto, 5rem) minmax(auto, 7rem) repeat(${props.showCommissionsColumn ? 3 : 2}, minmax(auto, 2fr))`
      : `${multiSelect ? 'max-content ' : ''}max-content repeat(${columns.length + 1}, auto)`

  return (
    <div>
      <Paper>
        <Table
          size={size}
          style={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            ...{},
          }}
        >
          <TableHead style={{ display: 'contents' }}>
            <TableRow style={{ display: 'contents' }}>
              {multiSelect && (
                <TableCell>
                  {!ready ? (
                    <Skeleton />
                  ) : (
                    <Checkbox
                      data-testid="select-all-checkbox"
                      color="primary"
                      checked={selectionState === 'ALL' || selectionState?.size === list.length}
                      indeterminate={
                        selectionState instanceof Set && selectionState.size > 0 && selectionState.size !== list.length
                      }
                      onChange={handleSelectAllShown}
                    />
                  )}
                </TableCell>
              )}
              <TableCell />
              <TableCell>
                {props.entity === 'shoppableParticipant' ? (
                  <>
                    <TableSortLabel
                      ref={usernameMenuRef}
                      direction={'desc'}
                      active={true}
                      onClick={() => setUsernameMenuOpen(true)}
                    >
                      {usernameFields[usernameSelectedField]}
                      {[ParticipantSort.IgUsername, ParticipantSort.TtUsername].includes(usernameSelectedField) && (
                        <Tooltip
                          placement="top"
                          title="Username and profile pic for another social platform may be displayed
                        for members for whom data from the selected social platform is not available.
                        These members have the respective social platform icon shown on the left of their username."
                        >
                          <Box className={classes.infoIconContainer}>
                            <InfoIcon width={16} height={16} fill="currentColor" className={classes.infoIcon} />
                          </Box>
                        </Tooltip>
                      )}
                      {usernameSelectedField === ParticipantSort.FullName && (
                        <Tooltip
                          placement="top"
                          title="Email may be displayed for members for whom name data is not available."
                        >
                          <Box className={classes.infoIconContainer}>
                            <InfoIcon width={16} height={16} fill="currentColor" className={classes.infoIcon} />
                          </Box>
                        </Tooltip>
                      )}
                    </TableSortLabel>
                  </>
                ) : (
                  'Username'
                )}
              </TableCell>
              {columns.map(col => {
                const labelMap = columnLabelMap[col]
                const label = typeof labelMap === 'string' ? labelMap : labelMap[size]
                let colSort: ParticipantSort | CustomerSort | undefined
                let handleClick: () => void
                if (props.entity === 'customer') {
                  colSort = columnCustomerSortMap[col]
                  const colSortc = colSort
                  handleClick = () => colSortc && props.setSort && props.setSort(colSortc)
                } else if (props.entity === 'applicant') {
                  colSort = columnApplicantSortMap[col]
                  const colSortc = colSort
                  handleClick = () => colSortc && props.setSort && props.setSort(colSortc)
                } else {
                  colSort = columnParticipantSortMap[col]
                  const colSortc = colSort
                  handleClick = () => colSortc && props.setSort && props.setSort(colSortc)
                }
                return (
                  <TableCell key={col} sortDirection={sortDirection}>
                    {size === 'medium' && colSort && (props.setSort || colSort === sort) ? (
                      <TableSortLabel direction={sortDirection} active={sort === colSort} onClick={handleClick}>
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody style={{ display: 'contents' }}>
            {ready &&
              list.length > 0 &&
              (props.entity === 'participant' || props.entity === 'applicant'
                ? props.list.map((p, i) =>
                    mapParticipantCustomerRow(p, sharedRowProps, selectionState, entity, i, isIGSocialAccount),
                  )
                : props.entity === 'shoppableParticipant'
                ? props.list.map((p, i) =>
                    mapShoppableCustomerRow(
                      p as TableRowShoppableParticipantFragment,
                      props as ShoppableCustomerTableProps<K>,
                      sharedRowProps,
                      usernameSelectedField,
                      selectionState,
                      entity,
                      i,
                      isIGSocialAccount,
                    ),
                  )
                : props.list.map((c, i) => mapCustomerRow(c, sharedRowProps, selectionState, i, isIGSocialAccount)))}
            {ready && list.length === 0 && (
              <TableRow style={{ display: 'contents' }}>
                <TableCell align="center" style={{ gridColumn: 'span 8' }}>
                  <ContainerEmptyState image={EmptyListImage} text={emptyText} />
                </TableCell>
              </TableRow>
            )}
            {(!ready || loadingMore) && (
              <>
                {new Array(loadingMore ? 10 : limit).fill(null).map((_, i) => (
                  <CustomerRow hasCampaigns={hasCampaigns} columns={columns} key={i} loading selectable={multiSelect} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {props.setSort && props.entity === 'shoppableParticipant' ? (
          <UsernameSelectPopover
            anchorEl={usernameMenuRef.current}
            usernameMenuOpen={usernameMenuOpen}
            setUsernameMenuOpen={setUsernameMenuOpen}
            usernameSelectedField={usernameSelectedField}
            setUsernameSelectedField={setUsernameSelectedField}
            sort={props.sort}
            setSort={props.setSort}
            sortDir={sortDir}
          />
        ) : null}
      </Paper>
    </div>
  )
}

export default CustomerTable
