import React, { useRef, useState } from 'react'
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'

export interface OptionType {
  value: any
  label: string
}

interface SplitButtonProps {
  options: Array<OptionType>
  onClick: (option?: OptionType) => void
  disabled?: boolean
  showDefaultItemOnList?: boolean
  showDropdownHandler?: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    buttonContainer: {
      boxShadow: 'none',
    },
    button: {
      borderRight: 'none !important',
    },
    arrowButton: {
      minWidth: '35px !important',
    },
    paper: {
      padding: theme.spacing(2),
      minWidth: 200,
    },
    menuItem: {
      marginBottom: theme.spacing(1),
    },
  }),
)

function SplitButton({
  options,
  onClick,
  disabled,
  showDefaultItemOnList,
  showDropdownHandler,
}: SplitButtonProps): React.ReactElement {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleClick = (option?: OptionType) => {
    onClick(option)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
        className={classes.buttonContainer}
      >
        <Button onClick={() => handleClick(options[0])} color="primary" className={classes.button}>
          {options[0]?.label}
        </Button>
        {showDropdownHandler && (
          <Button color="primary" onClick={handleToggle} className={classes.arrowButton}>
            <ChevronDownIcon width={16} />
          </Button>
        )}
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-start">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options
                    .filter(o => (!showDefaultItemOnList ? o !== options[0] : true))
                    .map((option, key) => (
                      <MenuItem key={key} onClick={() => handleClick(option)} className={classes.menuItem}>
                        {option.label}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default SplitButton
