import React from 'react'
import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  TextField,
  Grid,
  FormHelperText,
} from '@material-ui/core'
import { Field, FieldProps, useFormikContext } from 'formik'
import theme from '../loudcrowd-theme'
import { AddChallengeFormFields } from './AddEditChallengeDialog'
import { ChallengeStatusEnum, CriteriaOperatorEnum, IgMediaPostType } from '../gql-global'
import { ChallengeUserDataQuery } from './operations/challenge-user-data.generated'
import PostCriteriaField from './PostCriteriaField'
import HashtagsField from './HashtagsField'
import KeywordsField from './KeywordsField'
import { DateRangePickerSingleInput } from '../components/DateRangePicker/DateRangePickerSingleInput'
import { DateRangeModel } from '../components/DateRangePicker/AbsoluteDateRangePicker'
import { ChallengeRewardSelector } from './ChallengeRewardSelector'
import AdditionalCriteria from './AdditionalCriteria'
import { IgChallengeSaFragment } from './operations/ig-challenge-sa.generated'
import { TtChallengeSaFragment } from './operations/tt-challenge-sa.generated'
import { ChallengeSocialAccountType } from './Challenges'
import { isTypeName } from '../types/utility'
import MultiSelect from '../components/MultiSelect'
import SelectedSocialAccountPreview from './SelectedSocialAccountPreview'
import MetricIcon from '../components/metric/MetricIcon'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import { IconType } from '../components/metric/MetricIcon'
import SelectedProgramsPreview from './SelectedProgramsPreview'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
      width: '730px',
    },
    title: {
      fontSize: 32,
      fontWeight: 500,
    },
    field: {
      marginTop: 12,
      minWidth: 240,
      '& > .error': {
        color: theme.palette.error.main,
        fontSize: 12,
      },
      '& .MuiOutlinedInput-root.error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
    },
    helpText: {
      color: theme.palette.secondary.main,
      fontSize: 12,
    },
    label: {
      color: theme.palette.primary.main,
    },
    dialogActions: {
      justifyContent: 'flex-start',
      padding: '25px 0 0 25px',
      '& button': {
        minWidth: 100,
      },
    },
    renderedIcon: {
      marginTop: '3px',
      color: theme.palette.primary.dark,
      marginRight: '6px',
    },
    placeholder: {
      color: theme.palette.secondary.main,
      opacity: 0.65,
    },
  }),
)

type SAFragment =
  | ({ __typename: 'IGSocialAccount' } & IgChallengeSaFragment)
  | ({ __typename: 'TTSocialAccount' } & TtChallengeSaFragment)

interface AddEditChallengeContentProps {
  socialAccounts: SAFragment[]
  socialAccountContext: string
  challengeId?: string
  challengeStatus?: ChallengeStatusEnum
  onClose(): void
  userData: ChallengeUserDataQuery | undefined
}

function AddEditChallengeContent(props: AddEditChallengeContentProps): React.ReactElement {
  const classes = useStyles()

  const { onClose, socialAccounts, socialAccountContext, challengeId, challengeStatus, userData } = props
  const { values, errors, touched, setFieldValue } = useFormikContext<AddChallengeFormFields>()

  const socialAccountOptions: ChallengeSocialAccountType[] =
    socialAccounts.map(account => {
      return {
        id: account.id,
        name: account.socialUser.username,
        isIG: isTypeName(account, 'IGSocialAccount'),
        icon: isTypeName(account, 'IGSocialAccount') ? 'instagram' : 'tiktok',
      }
    }) || []

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "your browser's time zone"

  const constrainedEditMode =
    challengeStatus &&
    [ChallengeStatusEnum.Live, ChallengeStatusEnum.Paused, ChallengeStatusEnum.Completed].includes(challengeStatus)

  const endDateDisabled = challengeStatus === ChallengeStatusEnum.Completed

  function getMultiProgramOptions(accounts: string[]) {
    const options: (AccountLabel<string> & { icon?: IconType })[] = []
    for (const account of socialAccounts.filter(a => !!accounts.find(sa => sa === a.id))) {
      for (const program of account?.programs || []) {
        const id = program?.id
        const name = program?.name
        if (id && name && !options.some(o => o.id === id.toString())) {
          options.push({
            id: id.toString(),
            name: name,
          })
        }
      }
    }
    return options
  }
  const multiProgramOptions = getMultiProgramOptions(values?.socialAccountIds || [])

  if (multiProgramOptions?.length === 1 && !values.programs?.length) {
    setFieldValue('programs', [multiProgramOptions?.[0]?.id])
  }

  const selectedSocialAccounts = !!values.socialAccountIds?.length
    ? socialAccountOptions.filter(account => {
        return !!values.socialAccountIds?.find(id => account.id === id)
      })
    : []

  const hasIG = selectedSocialAccounts.some(s => s.isIG)
  const availableRewards =
    userData?.whoami?.account?.rewards?.results.map(reward => ({
      id: reward.id.toString(),
      label: reward.name ?? undefined,
      hasIntegration: !!reward.integration,
    })) ?? []

  const defaultPostTypes = hasIG ? [IgMediaPostType.Feed, IgMediaPostType.Story, IgMediaPostType.Reels] : []

  if (hasIG && values?.postTypes && values.postTypes.length === 0) {
    setFieldValue('postTypes', defaultPostTypes)
  }

  function synchronizeSelectedPrograms(newSocialAccounts: string[]) {
    const programOptions = getMultiProgramOptions(newSocialAccounts)
    const newValues = values?.programs?.filter(v => {
      return !!programOptions.find(option => option.id === v)
    })
    setFieldValue('programs', newValues)
  }

  return (
    <Box p={12}>
      <DialogTitle disableTypography>
        <Typography className={classes.title}>{`${challengeId ? 'Edit' : 'Save'} Your Challenge`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" maxHeight="60vh" overflow="visible">
          <Box width={300} mt={3} mb={4}>
            <Typography className={classes.label}>Name your challenge</Typography>
            <Field name="name">
              {({ field, meta }: FieldProps) => (
                <div className={classes.field}>
                  <TextField
                    fullWidth
                    placeholder="Type in the challenge name"
                    InputProps={{ className: meta.touched && meta.error ? 'error' : '' }}
                    {...field}
                  />
                  {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
          </Box>
          <Box my={4}>
            <Typography className={classes.label}>Which brand accounts should participants mention?</Typography>
            <Field name="socialAccountIds">
              {({ form: { errors } }: FieldProps) => (
                <Box display="flex">
                  <Box>
                    <MultiSelect
                      disabled={constrainedEditMode}
                      className={classes.field}
                      options={socialAccountOptions}
                      selectedOptions={values?.socialAccountIds || []}
                      defaultOptions={[socialAccountContext]}
                      renderValue={() => {
                        if (values.socialAccountIds) {
                          if (values.socialAccountIds.length > 1) {
                            return <Typography>{values.socialAccountIds.length} brand accounts</Typography>
                          } else if (values.socialAccountIds.length === 1) {
                            const option = socialAccountOptions.find(
                              account => account.id === values?.socialAccountIds?.[0],
                            )

                            return (
                              <Box display="flex">
                                <MetricIcon
                                  icon={option?.isIG ? 'instagram' : 'tiktok'}
                                  className={classes.renderedIcon}
                                />
                                <Typography>@{option?.name}</Typography>
                              </Box>
                            )
                          }
                        }
                      }}
                      onApply={(selected: string[]) => {
                        setFieldValue('socialAccountIds', selected)
                        synchronizeSelectedPrograms(selected)
                      }}
                    />
                    {errors && !!touched.socialAccountIds && (
                      <FormHelperText error>{errors?.socialAccountIds || ''}</FormHelperText>
                    )}
                  </Box>
                  <SelectedSocialAccountPreview selected={values.socialAccountIds} options={socialAccountOptions} />
                </Box>
              )}
            </Field>
          </Box>
          <Box my={4}>
            <Typography className={classes.label}>How can someone participate?</Typography>
            <Typography className={classes.helpText}>
              Select any activities that apply. Click on the checkmark icon to remove an activity.
            </Typography>
            <Box my={4}>
              <Grid container spacing={4} className={classes.container}>
                <Grid item>
                  <PostCriteriaField
                    selectedSocialAccounts={selectedSocialAccounts}
                    value={new Set(values.postTypes)}
                    setValue={(value: Set<string>) => setFieldValue('postTypes', Array.from(value))}
                    resetValue={() => {
                      setFieldValue('postTypes', defaultPostTypes)
                      return new Set<IgMediaPostType>(defaultPostTypes)
                    }}
                    isValueSelected={() => (values?.postTypes ? values.postTypes.length > 0 : false)}
                    disabled={constrainedEditMode}
                  />
                </Grid>
                <Grid item>
                  <HashtagsField
                    value={values.hashtags}
                    setValue={(value: string[]) => setFieldValue('hashtags', value)}
                    resetValue={() => {
                      setFieldValue('hashtags', [])
                      return []
                    }}
                    isValueSelected={() => (values?.hashtags ? values.hashtags.length > 0 : false)}
                    disabled={constrainedEditMode}
                    setCriteriaOperator={(val: CriteriaOperatorEnum) => setFieldValue('hashtagsCriteriaOperator', val)}
                    criteriaOperatorValue={values?.hashtagsCriteriaOperator}
                  />
                </Grid>
                <Grid item>
                  <KeywordsField
                    value={values.keywords}
                    setValue={(value: string[]) => setFieldValue('keywords', value)}
                    resetValue={() => {
                      setFieldValue('keywords', [])
                      return []
                    }}
                    isValueSelected={() => (values?.keywords ? values.keywords.length > 0 : false)}
                    disabled={constrainedEditMode}
                    setCriteriaOperator={(val: CriteriaOperatorEnum) => setFieldValue('keywordsCriteriaOperator', val)}
                    criteriaOperatorValue={values?.keywordsCriteriaOperator}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <AdditionalCriteria value={values.additionalCriteria} disabled={constrainedEditMode} />
          <Box mt={4}>
            <DateRangePickerSingleInput
              label="When would you like to run this challenge?"
              helpText={`Challenges will begin at 12:00 AM and end at 11:59 PM in ${timezone} on your selected dates.`}
              placeholder="Select start and end dates"
              value={values.dates}
              errors={touched.dates ? errors.dates : ''}
              setValue={(value: DateRangeModel) => setFieldValue('dates', value)}
              startDateDisabled={constrainedEditMode}
              endDateDisabled={endDateDisabled}
            />
          </Box>
          <Box>
            <Box mt={8}>
              <Typography className={classes.label}>Who can participate?</Typography>
              <Box display="flex">
                <Box>
                  <MultiSelect
                    disabled={multiProgramOptions.length === 1 || constrainedEditMode}
                    className={classes.field}
                    options={multiProgramOptions}
                    selectedOptions={values?.programs || []}
                    defaultOptions={[]}
                    renderValue={() => {
                      if (values.programs && values.programs.length > 1) {
                        return <Typography>{values.programs.length} programs</Typography>
                      } else if (values.programs && values.programs.length === 1) {
                        const option = multiProgramOptions.find(p => p.id === values?.programs?.[0])
                        return (
                          <Box display="flex">
                            <Typography>{option?.name}</Typography>
                          </Box>
                        )
                      } else {
                        return <Typography className={classes.placeholder}>Select who can participate</Typography>
                      }
                    }}
                    onApply={(selected: string[]) => setFieldValue('programs', selected)}
                    menuLabel="Programs"
                    enableSelectAll={true}
                  />
                  {errors && !!touched.programs && <FormHelperText error>{errors?.programs || ''}</FormHelperText>}
                </Box>
                <Box>
                  <SelectedProgramsPreview
                    selected={
                      values.programs?.map(p => {
                        const option = multiProgramOptions.find(mp => mp.id === p)
                        return option?.name || ''
                      }) || []
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box my={4}>
            <ChallengeRewardSelector
              label="What rewards do you want to give out?"
              rewards={values.rewards}
              errors={touched.rewards ? errors.rewards : ''}
              setRewards={value => setFieldValue('rewards', value)}
              availableRewards={availableRewards}
              disabled={constrainedEditMode}
              challengeHasAdditionalCriteria={values.additionalCriteria?.hasAdditionalCriteria || false}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Box>
  )
}

export default AddEditChallengeContent
