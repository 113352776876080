import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddTeamMemberMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  accountId: Types.Scalars['ID'];
  roles?: Types.InputMaybe<Array<Types.UserRoleType> | Types.UserRoleType>;
}>;


export type AddTeamMemberMutation = { __typename: 'Mutations', createUser?: { __typename: 'CreateUser', ok?: boolean | null, user?: { __typename: 'UserType', id: number, email: string } | null } | null };


export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($email: String!, $accountId: ID!, $roles: [UserRoleType!]) {
  createUser(email: $email, accountId: $accountId, roles: $roles) {
    ok
    user {
      id
      email
    }
  }
}
    `;
export type AddTeamMemberMutationFn = Apollo.MutationFunction<AddTeamMemberMutation, AddTeamMemberMutationVariables>;

/**
 * __useAddTeamMemberMutation__
 *
 * To run a mutation, you first call `useAddTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMemberMutation, { data, loading, error }] = useAddTeamMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      accountId: // value for 'accountId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAddTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMemberMutation, AddTeamMemberMutationVariables>(AddTeamMemberDocument, options);
      }
export type AddTeamMemberMutationHookResult = ReturnType<typeof useAddTeamMemberMutation>;
export type AddTeamMemberMutationResult = Apollo.MutationResult<AddTeamMemberMutation>;
export type AddTeamMemberMutationOptions = Apollo.BaseMutationOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>;