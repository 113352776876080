import { AccountProductName } from '../gql-global'
import { Route } from '../types/route'

export const REVIEW_ROUTE = new Route('/review', ['REVIEWER'], undefined, AccountProductName.Base)
export const REVIEW_PROGRAM_ROUTE = REVIEW_ROUTE.subroute(
  '/program/:programId',
  undefined,
  undefined,
  AccountProductName.Base,
)
export const REVIEW_CHALLENGE_ROUTE = REVIEW_ROUTE.subroute(
  '/challenge/:challengeId',
  undefined,
  undefined,
  AccountProductName.Base,
)
