import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignEcommIntegrationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CampaignEcommIntegrationQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, ecommIntegration?: { __typename: 'CommerceCloudIntegration', id: string, sellsSubscriptions?: boolean | null } | { __typename: 'CustomStoreIntegration', id: string } | { __typename: 'ShopifyIntegration', id: string, sellsSubscriptions?: boolean | null } | null } | null } | null };


export const CampaignEcommIntegrationDocument = gql`
    query CampaignEcommIntegration($id: ID!) {
  campaign(id: $id) {
    id
    program {
      ecommIntegration {
        ... on Node {
          id
        }
        ... on CommerceCloudIntegration {
          sellsSubscriptions
        }
        ... on ShopifyIntegration {
          sellsSubscriptions
        }
      }
      id
    }
  }
}
    `;

/**
 * __useCampaignEcommIntegrationQuery__
 *
 * To run a query within a React component, call `useCampaignEcommIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignEcommIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignEcommIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignEcommIntegrationQuery(baseOptions: Apollo.QueryHookOptions<CampaignEcommIntegrationQuery, CampaignEcommIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignEcommIntegrationQuery, CampaignEcommIntegrationQueryVariables>(CampaignEcommIntegrationDocument, options);
      }
export function useCampaignEcommIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignEcommIntegrationQuery, CampaignEcommIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignEcommIntegrationQuery, CampaignEcommIntegrationQueryVariables>(CampaignEcommIntegrationDocument, options);
        }
export type CampaignEcommIntegrationQueryHookResult = ReturnType<typeof useCampaignEcommIntegrationQuery>;
export type CampaignEcommIntegrationLazyQueryHookResult = ReturnType<typeof useCampaignEcommIntegrationLazyQuery>;
export type CampaignEcommIntegrationQueryResult = Apollo.QueryResult<CampaignEcommIntegrationQuery, CampaignEcommIntegrationQueryVariables>;