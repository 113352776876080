import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnscheduleEspMessageRewardMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  accountId: Types.Scalars['ID'];
}>;


export type UnscheduleEspMessageRewardMutation = { __typename: 'Mutations', unscheduleReward?: { __typename: 'UnscheduleReward', ok?: boolean | null, reward?: { __typename: 'RewardType', id: number } | null } | null };


export const UnscheduleEspMessageRewardDocument = gql`
    mutation UnscheduleEspMessageReward($id: ID!, $accountId: ID!) {
  unscheduleReward(id: $id, accountId: $accountId) {
    ok
    reward {
      id
    }
  }
}
    `;
export type UnscheduleEspMessageRewardMutationFn = Apollo.MutationFunction<UnscheduleEspMessageRewardMutation, UnscheduleEspMessageRewardMutationVariables>;

/**
 * __useUnscheduleEspMessageRewardMutation__
 *
 * To run a mutation, you first call `useUnscheduleEspMessageRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnscheduleEspMessageRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unscheduleEspMessageRewardMutation, { data, loading, error }] = useUnscheduleEspMessageRewardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUnscheduleEspMessageRewardMutation(baseOptions?: Apollo.MutationHookOptions<UnscheduleEspMessageRewardMutation, UnscheduleEspMessageRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnscheduleEspMessageRewardMutation, UnscheduleEspMessageRewardMutationVariables>(UnscheduleEspMessageRewardDocument, options);
      }
export type UnscheduleEspMessageRewardMutationHookResult = ReturnType<typeof useUnscheduleEspMessageRewardMutation>;
export type UnscheduleEspMessageRewardMutationResult = Apollo.MutationResult<UnscheduleEspMessageRewardMutation>;
export type UnscheduleEspMessageRewardMutationOptions = Apollo.BaseMutationOptions<UnscheduleEspMessageRewardMutation, UnscheduleEspMessageRewardMutationVariables>;