import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HideSyncAlertMutationVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
}>;


export type HideSyncAlertMutation = { __typename: 'Mutations', hideSyncForSocialAccount?: string | null };


export const HideSyncAlertDocument = gql`
    mutation HideSyncAlert($socialAccountId: ID!) {
  hideSyncForSocialAccount(socialAccountId: $socialAccountId) @client
}
    `;
export type HideSyncAlertMutationFn = Apollo.MutationFunction<HideSyncAlertMutation, HideSyncAlertMutationVariables>;

/**
 * __useHideSyncAlertMutation__
 *
 * To run a mutation, you first call `useHideSyncAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideSyncAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideSyncAlertMutation, { data, loading, error }] = useHideSyncAlertMutation({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *   },
 * });
 */
export function useHideSyncAlertMutation(baseOptions?: Apollo.MutationHookOptions<HideSyncAlertMutation, HideSyncAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideSyncAlertMutation, HideSyncAlertMutationVariables>(HideSyncAlertDocument, options);
      }
export type HideSyncAlertMutationHookResult = ReturnType<typeof useHideSyncAlertMutation>;
export type HideSyncAlertMutationResult = Apollo.MutationResult<HideSyncAlertMutation>;
export type HideSyncAlertMutationOptions = Apollo.BaseMutationOptions<HideSyncAlertMutation, HideSyncAlertMutationVariables>;