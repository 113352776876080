import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendDirectMessageMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  message: Types.Scalars['String'];
  messageTemplateId?: Types.InputMaybe<Types.Scalars['ID']>;
  rewardId?: Types.InputMaybe<Types.Scalars['ID']>;
  socialAccountId: Types.Scalars['ID'];
  mediaId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type SendDirectMessageMutation = { __typename: 'Mutations', sendDirectMessage?: { __typename: 'SendDirectMessage', ok: boolean } | null };


export const SendDirectMessageDocument = gql`
    mutation SendDirectMessage($customerId: ID!, $message: String!, $messageTemplateId: ID, $rewardId: ID, $socialAccountId: ID!, $mediaId: ID) {
  sendDirectMessage(
    customerId: $customerId
    message: $message
    messageTemplateId: $messageTemplateId
    rewardId: $rewardId
    socialAccountId: $socialAccountId
    mediaId: $mediaId
  ) {
    ok
  }
}
    `;
export type SendDirectMessageMutationFn = Apollo.MutationFunction<SendDirectMessageMutation, SendDirectMessageMutationVariables>;

/**
 * __useSendDirectMessageMutation__
 *
 * To run a mutation, you first call `useSendDirectMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDirectMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDirectMessageMutation, { data, loading, error }] = useSendDirectMessageMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      message: // value for 'message'
 *      messageTemplateId: // value for 'messageTemplateId'
 *      rewardId: // value for 'rewardId'
 *      socialAccountId: // value for 'socialAccountId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useSendDirectMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendDirectMessageMutation, SendDirectMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDirectMessageMutation, SendDirectMessageMutationVariables>(SendDirectMessageDocument, options);
      }
export type SendDirectMessageMutationHookResult = ReturnType<typeof useSendDirectMessageMutation>;
export type SendDirectMessageMutationResult = Apollo.MutationResult<SendDirectMessageMutation>;
export type SendDirectMessageMutationOptions = Apollo.BaseMutationOptions<SendDirectMessageMutation, SendDirectMessageMutationVariables>;