import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertCommissionTierMutationVariables = Types.Exact<{
  data: Types.CommissionTierInput;
  programId: Types.Scalars['ID'];
}>;


export type UpsertCommissionTierMutation = { __typename: 'Mutations', upsertCommissionTier?: { __typename: 'UpsertCommissionTier', ok: boolean, commissionTier?: { __typename: 'CommissionTierType', id: string } | null } | null };


export const UpsertCommissionTierDocument = gql`
    mutation UpsertCommissionTier($data: CommissionTierInput!, $programId: ID!) {
  upsertCommissionTier(data: $data, programId: $programId) {
    commissionTier {
      id
    }
    ok
  }
}
    `;
export type UpsertCommissionTierMutationFn = Apollo.MutationFunction<UpsertCommissionTierMutation, UpsertCommissionTierMutationVariables>;

/**
 * __useUpsertCommissionTierMutation__
 *
 * To run a mutation, you first call `useUpsertCommissionTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCommissionTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCommissionTierMutation, { data, loading, error }] = useUpsertCommissionTierMutation({
 *   variables: {
 *      data: // value for 'data'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useUpsertCommissionTierMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCommissionTierMutation, UpsertCommissionTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCommissionTierMutation, UpsertCommissionTierMutationVariables>(UpsertCommissionTierDocument, options);
      }
export type UpsertCommissionTierMutationHookResult = ReturnType<typeof useUpsertCommissionTierMutation>;
export type UpsertCommissionTierMutationResult = Apollo.MutationResult<UpsertCommissionTierMutation>;
export type UpsertCommissionTierMutationOptions = Apollo.BaseMutationOptions<UpsertCommissionTierMutation, UpsertCommissionTierMutationVariables>;