import React, { FC, useEffect, useRef, useState } from 'react'
import { CampaignSettingsQuery } from './operations/campaign-settings.generated'
import { SortableList } from '../../../components/SortableList'
import { DragHandle, SortableItemContainer } from '../../../components/SortableList/components/SortableItemContainer'
import { ReactComponent as TrashIcon } from '../../../icons/trash_can.svg'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg'
import { Box, Button, createStyles, Dialog, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import theme from '../../../loudcrowd-theme'
import { useCampaignCollectionsQuery } from './operations/campaign-collections.generated'
import ProductCollectionBrandPickerMenu, { OptionType, PickerTypeEnum } from './ProductCollectionBrandPickerMenu'
import { IntegrationType } from '../../../gql-global'
import { isTypeName } from '../../../types/utility'
import { useSyncIntegrationProductsAndCollectionsMutation } from './operations/sync-integration-products-and-collections.generated'
import { useToast } from '../../../components/Alert/ToastProvider'

export interface SelectedCollectionsType {
  id: string
  title: string
  sortOrder: number
}

export type ProductPickerCollectionsType = NonNullable<
  NonNullable<NonNullable<CampaignSettingsQuery['campaign']>['program']>['productPickerCollections']
>

interface ProductPickerCollectionComponentProps {
  campaignId: string
  productPickerCollections: ProductPickerCollectionsType
  setFieldValue: (field: string, value: string[], shouldValidate?: boolean | undefined) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: theme.palette.primary.main,
    },
    collectionsContainer: {
      height: 250,
      overflowY: 'scroll',
      marginBottom: 10,
    },
    productPickerItemContainer: {
      display: 'flex',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
      margin: '10px 10px 0',
      borderRadius: '5px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
    productPickerActionItems: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteIcon: {
      cursor: 'pointer',
    },
    buttonContainer: {
      bottom: 0,
      position: 'absolute',
      right: 0,
      padding: 8,
    },
  }),
)

const ProductPickerCollectionComponent: FC<ProductPickerCollectionComponentProps> = ({
  productPickerCollections,
  campaignId,
  setFieldValue,
}): React.ReactElement => {
  const classes = useStyles()
  const { showToast } = useToast()
  const [collections, setCollections] = useState<SelectedCollectionsType[]>(
    productPickerCollections.map(productPickerCollection => ({
      id: productPickerCollection.collection.id,
      title: productPickerCollection.title,
      sortOrder: productPickerCollection.sortOrder,
    })),
  )
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const addCollectionButtonRef = useRef<HTMLButtonElement>(null)
  const [deleteCollectionId, setDeleteCollectionId] = useState<string>('')
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const { data: campaignCollectionsQueryData } = useCampaignCollectionsQuery({ variables: { id: campaignId } })

  const [syncIntegrationProductsAndCollections] = useSyncIntegrationProductsAndCollectionsMutation()

  useEffect(() => {
    setFieldValue(
      'productPickerCollectionIds',
      collections.map(collection => collection.id),
    )
  }, [collections, setFieldValue])

  const updateCollectionsFromOptionTypes = (selectedCollectionOptionTypes: OptionType[]) => {
    let newCollectionArr: SelectedCollectionsType[] = []
    let sortOrder = 1

    for (let collection of collections) {
      let index = selectedCollectionOptionTypes.findIndex(ot => ot.id === collection.id)
      if (index >= 0) {
        newCollectionArr.push(collection)
        selectedCollectionOptionTypes.splice(index, 1)

        if (collection.sortOrder > sortOrder) {
          sortOrder = collection.sortOrder
        }
      }
    }

    for (let collectionOptionType of selectedCollectionOptionTypes) {
      newCollectionArr.push({
        id: collectionOptionType.id,
        title: collectionOptionType.title || '',
        sortOrder: ++sortOrder,
      })
    }

    setCollections(newCollectionArr)
    setOpenDialog(false)
  }

  const onAddCollectionClick = () => {
    setOpenDialog(true)
  }

  const onDialogClose = () => {
    setOpenDialog(false)
  }

  const deleteProductPickerCollection = (collectionId: string) => {
    let collectionIds = collections.map(collection => collection.id)
    let index = collectionIds.indexOf(collectionId)
    if (index >= 0) {
      collections.splice(index, 1)
      setCollections(collections.map(collection => ({ ...collection })) || [])
    }
  }

  const onDeleteClick = (collectionId: string) => {
    setDeleteCollectionId(collectionId)
    setOpenDeleteDialog(true)
  }
  const onDeleteClose = () => {
    setOpenDeleteDialog(false)
  }
  const programEcommIntegration = campaignCollectionsQueryData?.campaign?.program?.ecommIntegration

  const handleSyncProductsAndCollections = async () => {
    if (programEcommIntegration?.id) {
      syncIntegrationProductsAndCollections({
        variables: {
          id: programEcommIntegration.id,
        },
        refetchQueries: ['CampaignCollections'],
      })
        .then(() => {
          showToast({
            title: 'Success: Product and Collection sync job started',
            message: 'Successfully queued the Product and Collection sync job for your Ecomm integration',
            severity: 'success',
            autoHideDuration: 5000,
          })
        })
        .catch(e => {
          showToast({
            title: 'Error: Product and Collection sync job failed',
            message:
              'Failed to queue the Product and Collection sync job for your Ecomm integration. Please try again later or contact support.',
            severity: 'error',
            autoHideDuration: 5000,
          })
        })
    }
  }

  const canSyncProductsAndCollections =
    !!programEcommIntegration &&
    isTypeName(programEcommIntegration, 'CommerceCloudIntegration') &&
    programEcommIntegration?.canSyncCollections &&
    programEcommIntegration?.canSyncProducts

  const integrationType = campaignCollectionsQueryData?.campaign?.program?.ecommIntegration?.integrationType
  return (
    <>
      <Typography className={classes.label} variant="body1">
        Product Picker {integrationType === IntegrationType.CommerceCloud ? 'Categories' : 'Collections'}
      </Typography>
      <Paper className={classes.collectionsContainer}>
        <SortableList
          items={collections || []}
          setItems={setCollections}
          renderItem={(collection: { id: string; title: string }) => {
            return (
              <SortableItemContainer id={collection.id}>
                <div className={classes.productPickerItemContainer}>
                  <DragHandle />
                  <span style={{ flex: 1, marginLeft: 10 }}>{collection.title}</span>
                  <span className={classes.productPickerActionItems}>
                    <TrashIcon
                      className={classes.deleteIcon}
                      onClick={() => {
                        onDeleteClick(collection.id)
                      }}
                    />
                  </span>
                </div>
              </SortableItemContainer>
            )
          }}
        ></SortableList>
      </Paper>
      <Button
        onClick={onAddCollectionClick}
        variant="outlined"
        startIcon={<PlusIcon width={16} />}
        color="primary"
        ref={addCollectionButtonRef}
      >
        Add {integrationType === IntegrationType.CommerceCloud ? 'Category' : 'Collection'}
      </Button>
      {campaignCollectionsQueryData?.campaign?.program?.ecommIntegration?.integrationType ===
        IntegrationType.CommerceCloud && (
        <>
          <Button
            onClick={handleSyncProductsAndCollections}
            variant="outlined"
            color="primary"
            style={{ marginLeft: '8px' }}
            disabled={!canSyncProductsAndCollections}
          >
            Sync Products and {integrationType === IntegrationType.CommerceCloud ? 'Categories' : 'Collections'}
          </Button>
          {!canSyncProductsAndCollections && (
            <Typography variant="caption" style={{ marginLeft: '4px' }}>
              Product and {integrationType === IntegrationType.CommerceCloud ? 'Category' : 'Collection'} sync is in
              progress.
            </Typography>
          )}
        </>
      )}

      <ProductCollectionBrandPickerMenu
        campaignId={campaignCollectionsQueryData?.campaign?.id.toString() || undefined}
        open={openDialog}
        anchorEl={addCollectionButtonRef.current}
        onCancel={onDialogClose}
        onAdd={updateCollectionsFromOptionTypes}
        pickerType={PickerTypeEnum.COLLECTION}
        selectedItems={collections}
        confirmButtonText="Ok"
        integrationType={integrationType}
      />
      <DeleteProductPickerCollectionDialog
        open={openDeleteDialog}
        onClose={onDeleteClose}
        collectionId={deleteCollectionId}
        handleDelete={deleteProductPickerCollection}
        isLastCollection={collections.length === 1}
        integrationType={integrationType}
      />
    </>
  )
}

interface DeleteProductPickerCollectionDialogProps {
  open: boolean
  onClose(): void
  handleDelete(collectionId: string): void
  collectionId: string
  isLastCollection: boolean
  integrationType?: IntegrationType
}

const DeleteProductPickerCollectionDialog: FC<DeleteProductPickerCollectionDialogProps> = ({
  open,
  onClose,
  handleDelete,
  collectionId,
  isLastCollection,
  integrationType,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open}>
      <Box width={500} height={250}>
        <IconButton style={{ position: 'absolute', right: 0, top: 0 }} onClick={onClose}>
          <CrossIcon width={16} height={16} />
        </IconButton>
        <Box padding={5}>
          <Typography variant="h6">
            Delete Product Picker {integrationType === IntegrationType.CommerceCloud ? 'Category' : 'Collection'}
          </Typography>
          <p>
            {isLastCollection ? (
              <>
                <b>ERROR: </b>You must have at least one product picker{' '}
                {integrationType === IntegrationType.CommerceCloud ? 'category' : 'collection'} selected in the modal.
              </>
            ) : (
              <>
                <b>WARNING: </b>Deleting this{' '}
                {integrationType === IntegrationType.CommerceCloud ? 'category' : 'collection'} will remove any products
                from <b>live ambassador storefronts</b> that may have been selected from this{' '}
                {integrationType === IntegrationType.CommerceCloud ? 'category' : 'collection'}.
              </>
            )}
          </p>
          <span className={classes.buttonContainer}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDelete(collectionId)
                onClose()
              }}
              disabled={isLastCollection}
            >
              I understand
            </Button>
          </span>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ProductPickerCollectionComponent
