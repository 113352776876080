import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  InputAdornment,
  OutlinedInput,
  makeStyles,
  createStyles,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Tooltip,
  Link,
  Checkbox,
  Button,
} from '@material-ui/core'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as PencilIcon } from '../../icons/edit_pencil.svg'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { ReactComponent as QuestionMarkIcon } from '../../icons/question-mark_major_monotone.svg'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import { ReactComponent as ZigZagIcon } from '../../icons/zig-zag.svg'

import { primary } from '../../loudcrowd-theme'
import EditLabel from './EditLabel'
import { ProgramType } from '../../gql-global'
import { camelCaseSplit } from '../../utils/text-format'
import { SelectionOptionData } from '../lists/ListFilters/types'

export interface AccountLabel<T extends string | number> {
  id: T
  name: string
  program?: ProgramType
}

export type LabelMenuActions = 'update' | 'delete'

type BaseLabelMenuContentProps<T extends string | number> = {
  entity: string
  labels: AccountLabel<T>[]
  selectedLabelIds?: Set<T>
  editable: boolean
  onApply(labelIds: Set<T>): void
  onClose?(): void
  seeAllLink?: string
  placeholder?: string
  selectedAllByDefault?: boolean
  customLabelRenderer?: (label: SelectionOptionData) => JSX.Element
}

type ReadOnlyLabelMenuContentProps<T extends string | number> = BaseLabelMenuContentProps<T> & { editable: false }

type EditableLabelMenuContentProps<T extends string | number> = BaseLabelMenuContentProps<T> & {
  onCreate(name: string): void
  onUpdate(id: T, name: string): void
  onDelete(id: T): void
  hasHitLimit: boolean
  editable: true
  allowedActions: LabelMenuActions[]
}

type BulkLabelMenuContentProps<T extends string | number> =
  | ReadOnlyLabelMenuContentProps<T>
  | EditableLabelMenuContentProps<T>

const MENU_HEIGHT = 400
const useStyles = makeStyles(theme =>
  createStyles({
    applyButton: {
      marginLeft: theme.spacing(2),
    },
    search: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: '150%',
    },
    searchInput: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: 21,
    },
    listSubheader: {
      textTransform: 'capitalize',
    },
    listIcon: {
      minWidth: 28,
      minHeight: 28,
      justifyContent: 'center',
      alignItems: 'center',
      color: 'inherit',
      marginRight: 12,
    },
    listIconEdit: {
      opacity: 0,
      // sync showing edit icon w/ list item backgroundColor transition on hover
      transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shortest }),

      '&:hover': {
        backgroundColor: primary[200],
        borderRadius: 4,
      },
    },
    listItemButton: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,

        '& $listIconEdit': {
          opacity: 1,
        },
      },
    },
    addButton: {
      color: theme.palette.primary.main,
      paddingLeft: 16,
      height: 40,
      borderRadius: 4,
    },
    containerInput: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }),
)

function isEditableLabelMenu<T extends string | number>(
  p: BulkLabelMenuContentProps<T>,
): p is EditableLabelMenuContentProps<T> {
  return !!p.editable
}

export function BulkLabelMenuContents<T extends string | number>(
  props: BulkLabelMenuContentProps<T>,
): React.ReactElement {
  const emptySet = new Set<T>()
  const {
    entity,
    seeAllLink = undefined,
    labels,
    onApply,
    placeholder,
    onClose,
    selectedAllByDefault,
    selectedLabelIds = emptySet,
    customLabelRenderer,
  } = props

  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const [shownLabels, setShownLabels] = useState<AccountLabel<T>[]>(labels)
  const [editingLabel, setEditingLabel] = useState<AccountLabel<T> | null>(null)
  const [checkedLabelIds, setCheckedLabelIds] = useState(selectedLabelIds)

  useEffect(() => {
    let filtered = labels
    const lowerSearchText = searchText.toLowerCase()
    if (searchText) {
      filtered = labels
        .map(l => ({
          ...l,
          index: l.name.toLowerCase().indexOf(lowerSearchText),
        }))
        .filter(l => l.index >= 0)
        .sort(l => l.index)
    }
    setShownLabels(filtered.slice(0, labels.length))
  }, [labels, searchText])

  function handleEditIconClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>, label: AccountLabel<T>): void {
    e.stopPropagation()
    setEditingLabel(label)
  }

  function handleApply(): void {
    setEditingLabel(null)
    if (checkedLabelIds.size > 0) {
      onApply(checkedLabelIds)
    } else {
      onApply(new Set<T>())
    }
    onClose?.()
  }

  const toggleLabel = (id: T): void => {
    let newSet = new Set(checkedLabelIds)
    if (selectedAllByDefault) {
      if (new Set(checkedLabelIds).add(id).size === shownLabels.length) {
        newSet.clear()
      } else {
        if (newSet.size === 0) {
          newSet = new Set(shownLabels.filter(l => l.id !== id).map(l => l.id))
        } else if (newSet.has(id)) {
          newSet.delete(id)
        } else {
          newSet.add(id)
        }
      }
    } else if (newSet.has(id)) {
      newSet.delete(id)
    } else {
      newSet.add(id)
    }

    setCheckedLabelIds(newSet)
  }

  const handleOnClear = (): void => {
    setCheckedLabelIds(emptySet)
  }

  const handleOnSelectAll = (): void => {
    setCheckedLabelIds(new Set(shownLabels.map(l => l.id)))
  }

  const showAddLabel = searchText && !shownLabels.some(l => l.name === searchText)
  const canUpdate = isEditableLabelMenu(props) && props.allowedActions.includes('update')
  const listHeight = shownLabels.length * 30 < MENU_HEIGHT ? undefined : MENU_HEIGHT

  const isChecked = (id: T): boolean => {
    if (selectedAllByDefault) {
      return checkedLabelIds.size === 0 || checkedLabelIds.has(id)
    }

    return checkedLabelIds.has(id)
  }

  return (
    <>
      {editingLabel && (
        <Box>
          <EditLabel
            id={editingLabel.id}
            name={editingLabel.name}
            labels={labels}
            canDelete={isEditableLabelMenu(props) && props.allowedActions.includes('delete')}
            onCancel={() => setEditingLabel(null)}
            onSave={(id, name) => {
              if (!isEditableLabelMenu(props)) return
              props.onUpdate(id, name)
              setEditingLabel(null)
            }}
            onDelete={id => {
              if (!isEditableLabelMenu(props)) return
              props.onDelete(id)
              setEditingLabel(null)
            }}
          />
        </Box>
      )}
      {!editingLabel && (
        <Box width={392} height={334} display="flex" flexDirection="column">
          <Box className={classes.containerInput}>
            <OutlinedInput
              classes={{ root: classes.search, input: classes.searchInput }}
              type="text"
              autoFocus
              fullWidth
              value={searchText}
              placeholder={placeholder}
              onChange={e => setSearchText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon width={16} height={16} />
                </InputAdornment>
              }
            />
          </Box>
          <List
            style={{ height: listHeight, overflowY: 'auto', flex: 1 }}
            subheader={
              <Box display="flex" justifyContent="space-between" mr={2} mb={1} align-items="center">
                {seeAllLink && (
                  <>
                    <ListSubheader className={classes.listSubheader}>
                      {!searchText && 'Recent '}
                      {entity}s
                    </ListSubheader>
                    <Box display="inline-flex" alignItems="center">
                      <Link
                        variant="body2"
                        color="primary"
                        component={RouterLink}
                        to={{
                          pathname: seeAllLink,
                        }}
                      >
                        <Box display="inline-flex" alignItems="center">
                          See all
                          <Box display="inline" ml={2}>
                            <ArrowIcon width={12} height={12} transform="rotate(-90)" />
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
            }
          >
            {shownLabels.map(l => (
              <ListItem
                key={l.id}
                button
                onClick={() => toggleLabel(l.id)}
                classes={{ button: classes.listItemButton }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Checkbox
                    data-testid={`checkbox-${entity}-${l.id}`}
                    size="small"
                    edge="start"
                    checked={isChecked(l.id)}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText primary={customLabelRenderer ? customLabelRenderer(l) : l.name} />
                {canUpdate && !l.program && (
                  <ListItemIcon className={`${classes.listIcon} ${classes.listIconEdit}`}>
                    <PencilIcon width={16} onClick={e => handleEditIconClick(e, l)} />
                  </ListItemIcon>
                )}
                {l.program && (
                  <ListItemIcon className={classes.listIcon}>
                    <Box
                      width={20}
                      height={20}
                      bgcolor="primary.main"
                      color="white"
                      borderRadius={10}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <ZigZagIcon width={12} height={12} />
                    </Box>
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
            {!shownLabels.length && (
              <ListItem>
                {isEditableLabelMenu(props) && !searchText
                  ? `No ${camelCaseSplit(entity).toLowerCase()}s, type to add a new one.`
                  : `No matching ${camelCaseSplit(entity).toLowerCase()}s.`}
              </ListItem>
            )}
            {showAddLabel && isEditableLabelMenu(props) && (
              <>
                <Divider />
                <Box px={5} mt={2}>
                  <ListItem
                    button
                    disabled={props.hasHitLimit}
                    className={classes.addButton}
                    classes={{ button: classes.listItemButton }}
                    onClick={() => isEditableLabelMenu(props) && props.onCreate(searchText)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <PlusIcon width={13} />
                    </ListItemIcon>
                    <ListItemText primary={searchText} primaryTypographyProps={{ variant: 'subtitle2' }} />
                    {props.hasHitLimit && (
                      <ListItemSecondaryAction>
                        <Tooltip
                          title={`Account cannot create more ${camelCaseSplit(
                            entity,
                          ).toLowerCase()}s. Contact support to increase limit.`}
                        >
                          <QuestionMarkIcon width={13} />
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Box>
              </>
            )}
          </List>
          <Divider />
          <Box display="flex" alignItems="center" flex="0 0 auto" p={2}>
            <Box display="flex" alignItems="center" flexGrow={1}>
              {checkedLabelIds.size > 0 && (
                <Button color="primary" variant="text" disabled={checkedLabelIds.size === 0} onClick={handleOnClear}>
                  Unselect All
                </Button>
              )}
              {checkedLabelIds.size === 0 && (
                <Button
                  color="primary"
                  variant="text"
                  disabled={checkedLabelIds.size === shownLabels.length}
                  onClick={handleOnSelectAll}
                >
                  Select All
                </Button>
              )}
            </Box>
            <Button className={classes.applyButton} color="primary" variant="contained" onClick={handleApply}>
              Apply
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
