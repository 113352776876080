import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import OnMentionActivityListItem from './OnMentionActivityListItem'
import TagStatusChip from '../components/PostCard/TagStatusChip'

interface ChallengeApprovalStatusActivityListItemProps {
  activity: ConcreteEventType<'ChallengeApprovalStatusCustomerEvent'>
}

function ChallengeApprovalStatusActivityListItem({
  activity,
}: ChallengeApprovalStatusActivityListItemProps): React.ReactElement {
  return (
    <OnMentionActivityListItem
      mention={activity.mention}
      text={
        <>
          <Box mr={2}>
            <Typography variant="subtitle1" display="inline">
              {activity.challenge.name} - Post status changed to
            </Typography>{' '}
          </Box>
          {activity.challengeMediaApprovalStatus && <TagStatusChip status={activity.challengeMediaApprovalStatus} />}
        </>
      }
      eventAt={activity.eventAt}
      actorName={activity.actor?.email}
    />
  )
}

export default ChallengeApprovalStatusActivityListItem
