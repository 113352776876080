import React, { forwardRef } from 'react'
import { Box, IconButton, Divider } from '@material-ui/core'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'

interface MenuHeaderProps {
  title: string
  onClickBack?(): void
}
export type Ref = HTMLDivElement

const MenuHeader = forwardRef<Ref, MenuHeaderProps>(({ title, onClickBack }, ref) => (
  <div ref={ref}>
    <Box display="flex" alignItems="center" justifyContent="center" pb={2} mx={3}>
      {onClickBack && (
        <IconButton onClick={onClickBack}>
          <ArrowIcon transform="rotate(90)" width={12} height={12} />
        </IconButton>
      )}
      <Box flexGrow={1} fontSize="body2.fontSize" fontWeight={600} lineHeight="body2.lineHeight" textAlign="center">
        {title}
      </Box>
      {onClickBack && <Box width={34} />}
    </Box>
    <Divider />
  </div>
))

export default MenuHeader
