import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageAutomationUserDataQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
}>;


export type MessageAutomationUserDataQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null, socialUser: { __typename: 'IGUser', id: string, avatarUrl?: string | null, username: string } } | { __typename: 'TTSocialAccount', id: string, campaigns?: Array<{ __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, name: string } | null }> | null, socialUser: { __typename: 'TTUser', id: string, avatarUrl?: string | null, username: string } } | null };


export const MessageAutomationUserDataDocument = gql`
    query MessageAutomationUserData($socialAccountId: ID!) {
  socialAccount(id: $socialAccountId) {
    id
    campaigns {
      id
      program {
        id
        name
      }
    }
    socialUser {
      id
      avatarUrl
      username
    }
  }
}
    `;

/**
 * __useMessageAutomationUserDataQuery__
 *
 * To run a query within a React component, call `useMessageAutomationUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageAutomationUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageAutomationUserDataQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *   },
 * });
 */
export function useMessageAutomationUserDataQuery(baseOptions: Apollo.QueryHookOptions<MessageAutomationUserDataQuery, MessageAutomationUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageAutomationUserDataQuery, MessageAutomationUserDataQueryVariables>(MessageAutomationUserDataDocument, options);
      }
export function useMessageAutomationUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageAutomationUserDataQuery, MessageAutomationUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageAutomationUserDataQuery, MessageAutomationUserDataQueryVariables>(MessageAutomationUserDataDocument, options);
        }
export type MessageAutomationUserDataQueryHookResult = ReturnType<typeof useMessageAutomationUserDataQuery>;
export type MessageAutomationUserDataLazyQueryHookResult = ReturnType<typeof useMessageAutomationUserDataLazyQuery>;
export type MessageAutomationUserDataQueryResult = Apollo.QueryResult<MessageAutomationUserDataQuery, MessageAutomationUserDataQueryVariables>;