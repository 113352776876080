import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { StringParam, useQueryParams } from 'use-query-params'

const auth0Db = process.env.REACT_APP_AUTH0_DB

const Auth0Login: React.FC = () => {
  // Shopify sends query params on callback after successfully authing with store
  const [shopifyQuery] = useQueryParams({
    hmac: StringParam,
    host: StringParam,
    code: StringParam,
    state: StringParam,
    shop: StringParam,
    timestamp: StringParam,
  })

  const { loginWithRedirect } = useAuth0()

  const { hmac, host, code, state, shop, timestamp } = shopifyQuery
  const shopifyRedirect =
    hmac &&
    host &&
    code &&
    state &&
    shop &&
    timestamp &&
    `/integrations?hmac=${hmac}&host=${host}&code=${code}&state=${state}&shop=${shop}&timestamp=${timestamp}`

  loginWithRedirect({
    connection: auth0Db,
    appState: {
      returnTo: shopifyRedirect,
    },
  })

  return null
}

export default Auth0Login
