import React from 'react'
import { differenceInMinutes, formatDistance, formatDistanceToNow } from 'date-fns'
import { Box, Link, TableCell, TableRow, Avatar } from '@material-ui/core'
import { REVIEW_ROUTE } from './routes'
import { Link as RouterLink } from 'react-router-dom'
import { ReviewProgramRowFragment } from './operations/review-program-row.generated'

interface ReviewProgramRowProps {
  currentUserId?: number
  program: ReviewProgramRowFragment
}

const ReviewProgramRow: React.FC<ReviewProgramRowProps> = ({ currentUserId, program }) => {
  const lockedByEmail = program.reviewLock?.user?.email
  const lockedAtTimestamp = program.reviewLock?.updatedAt
  const currentUserOwnsLock = !program.reviewLock?.user?.id || program.reviewLock?.user?.id === currentUserId
  const unlocked =
    !lockedAtTimestamp ||
    currentUserOwnsLock ||
    (lockedAtTimestamp && differenceInMinutes(new Date(), lockedAtTimestamp) > 15)

  const renderProgramNameBox = (): React.ReactElement => (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box p={2} color="text.secondary">
          {program.name}
        </Box>
        {!unlocked && lockedAtTimestamp && (
          <Box p={2} color="maroon">
            Locked by {lockedByEmail}, {formatDistance(lockedAtTimestamp, new Date())} ago.
          </Box>
        )}
      </Box>
    </>
  )

  const renderProgramNameLink = () => (
    <Link to={`${REVIEW_ROUTE.path}/program/${program.id}`} component={RouterLink}>
      {renderProgramNameBox()}
    </Link>
  )

  const brandPhoto = program.contentRules?.brandPhoto

  return (
    <TableRow hover>
      <TableCell>{unlocked ? renderProgramNameLink() : renderProgramNameBox()}</TableCell>
      <TableCell>{program.account?.name}</TableCell>
      <TableCell>{program.socialAccounts?.map(sa => sa.socialUser?.username).join(', ')}</TableCell>
      <TableCell>{program.pendingPosts || 0}</TableCell>
      <TableCell>
        {program.reviewLock?.updatedAt && formatDistanceToNow(program.reviewLock?.updatedAt, { addSuffix: true })}
      </TableCell>
      <TableCell>{program.reviewLock?.user?.email}</TableCell>
      <TableCell>{brandPhoto && <Avatar src={brandPhoto} />}</TableCell>
    </TableRow>
  )
}

export default ReviewProgramRow
