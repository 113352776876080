import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { forwardRef, useRef } from 'react'
import FilterChip, { FilterChipText } from './FilterChip'
import { SingleSelectionOption } from './types'
import MenuHeader from '../../MenuHeader'
import SingleSelectFilterMenu from '../../SingleSelectionPicker'

export type Ref = HTMLDivElement

interface SingleSelectionFilterChipProps {
  option: SingleSelectionOption
  value: string
  onDelete(): void
}

export function SingleSelectionFilterChip({
  option,
  value,
  onDelete,
}: SingleSelectionFilterChipProps): React.ReactElement {
  const chipRef = useRef<HTMLDivElement>(null)
  const selectedOption = option.selectionOptions.find(c => c.id.toString() === value)
  return (
    <>
      <FilterChip ref={chipRef} onDelete={onDelete}>
        <Box display="flex" minWidth={150}>
          <FilterChipText bold text={option.label} />
          <FilterChipText text="is" />
          {!selectedOption && (
            <Box ml={3}>
              <Skeleton variant="text" width={50} />
            </Box>
          )}
          {selectedOption && <FilterChipText bold text={selectedOption.label} />}
        </Box>
      </FilterChip>
    </>
  )
}

interface SingleSelectionFilterMenuProps {
  option: SingleSelectionOption
  onSelectValue(newValue: string): void
  onBack(): void
}

const SingleSelectionFilterMenu = forwardRef<Ref, SingleSelectionFilterMenuProps>((props, ref) => {
  const { option, onSelectValue, onBack } = props
  return (
    <div>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <SingleSelectFilterMenu option={option} onSelectValue={onSelectValue} />
    </div>
  )
})

SingleSelectionFilterMenu.displayName = 'SingleSelectionFilterMenu'

export default SingleSelectionFilterMenu
