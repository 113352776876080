import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRewardCodesMutationVariables = Types.Exact<{
  rewardId: Types.Scalars['ID'];
}>;


export type DeleteRewardCodesMutation = { __typename: 'Mutations', deleteRewardCodes?: { __typename: 'DeleteRewardCodes', ok: boolean, reward?: { __typename: 'RewardType', id: number } | null } | null };


export const DeleteRewardCodesDocument = gql`
    mutation DeleteRewardCodes($rewardId: ID!) {
  deleteRewardCodes(rewardId: $rewardId) {
    ok
    reward {
      id
    }
  }
}
    `;
export type DeleteRewardCodesMutationFn = Apollo.MutationFunction<DeleteRewardCodesMutation, DeleteRewardCodesMutationVariables>;

/**
 * __useDeleteRewardCodesMutation__
 *
 * To run a mutation, you first call `useDeleteRewardCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRewardCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRewardCodesMutation, { data, loading, error }] = useDeleteRewardCodesMutation({
 *   variables: {
 *      rewardId: // value for 'rewardId'
 *   },
 * });
 */
export function useDeleteRewardCodesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRewardCodesMutation, DeleteRewardCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRewardCodesMutation, DeleteRewardCodesMutationVariables>(DeleteRewardCodesDocument, options);
      }
export type DeleteRewardCodesMutationHookResult = ReturnType<typeof useDeleteRewardCodesMutation>;
export type DeleteRewardCodesMutationResult = Apollo.MutationResult<DeleteRewardCodesMutation>;
export type DeleteRewardCodesMutationOptions = Apollo.BaseMutationOptions<DeleteRewardCodesMutation, DeleteRewardCodesMutationVariables>;