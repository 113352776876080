import { useQueryParam, stringify, encodeQueryParams } from 'use-query-params'
import { createFilteredParam } from '../utils/url-param-utils'
import { SortDirection } from '../gql-global'

const SORT_DIR_PARAM = 'sortDir'
const isCustomerSort = (str: string): str is SortDirection =>
  Object.values(SortDirection).includes(str as SortDirection)

const sortDirParam = createFilteredParam((str: string): SortDirection | undefined =>
  isCustomerSort(str) ? str : undefined,
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSortDirParam = () => {
  return useQueryParam(SORT_DIR_PARAM, sortDirParam)
}

export const encodeSortParam = (sort: SortDirection): string => {
  return stringify(encodeQueryParams({ [SORT_DIR_PARAM]: sortDirParam }, { [SORT_DIR_PARAM]: sort }))
}

export default useSortDirParam
