import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Link,
  LinearProgress,
  TableRow,
  TableCell,
  Typography,
  useTheme,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { ReactComponent as ArrowIcon } from '../icons/arrow-skinny.svg'
import { numberFormat } from '../utils/number-format'
import { encodeFilterParams, Filters } from '../content/use-filter-params'
import { CONTENT_ROUTE } from '../content/routes'

const useStyles = makeStyles(theme =>
  createStyles({
    cell: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      '&:last-child': {
        paddingRight: theme.spacing(4),
      },
      '&:first-child': {
        paddingLeft: theme.spacing(4),
      },
    },
    progress: {
      height: 2,
      position: 'absolute',
      width: '100px',
    },
    volumeCell: {
      position: 'relative',
    },
  }),
)

interface HashtagRowProps {
  hashtag: string
  ranking?: number | null
  count: number
  prevRanking?: number | null
  prevCount: number
  mentionFilters: Filters
}

function HashtagRow({
  hashtag,
  ranking,
  count,
  prevRanking,
  prevCount,
  mentionFilters,
}: HashtagRowProps): React.ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const countDiff = count - prevCount
  // holds how the ranking has changed.
  // -1 = drop, 0 = same, 1 = increase, null for if either ranking is not defined
  const rankDiffState =
    ranking !== undefined && ranking !== null && prevRanking !== undefined && prevRanking !== null
      ? Math.sign(prevRanking - ranking)
      : null

  let rankColor: string
  if (rankDiffState === -1) {
    rankColor = theme.palette.error.main
  } else if (rankDiffState === 1) {
    rankColor = theme.palette.success.main
  } else {
    rankColor = theme.palette.secondary.main
  }
  const hashtagFilters = {
    ...mentionFilters,
    hashtagKeywords: [hashtag],
  }
  return (
    <TableRow hover>
      <TableCell classes={{ sizeSmall: classes.cell }}>
        <Typography component="span" variant="body1">
          {ranking}
        </Typography>
        {rankDiffState !== null && (
          <Box component="sup" color={rankColor} fontSize={11} lineHeight="17px" ml="2px">
            {prevRanking}
            {!!rankDiffState && <ArrowIcon height={11} width={8} transform={`rotate(${rankDiffState * 90})`} />}
          </Box>
        )}
      </TableCell>
      <TableCell component="th" scope="row" classes={{ sizeSmall: classes.cell }}>
        <Link
          component={RouterLink}
          to={{
            pathname: CONTENT_ROUTE.path,
            search: `?${encodeFilterParams(hashtagFilters)}`,
          }}
        >
          #{hashtag}
        </Link>
      </TableCell>
      <TableCell classes={{ sizeSmall: classes.cell }} className={classes.volumeCell}>
        {numberFormat(count)}
        <LinearProgress className={classes.progress} variant="determinate" value={60} />
      </TableCell>
      <TableCell classes={{ sizeSmall: classes.cell }}>{numberFormat(countDiff, { forceSign: true })}</TableCell>
    </TableRow>
  )
}

export default HashtagRow
