import React from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
} from 'recharts'
import { useTheme } from '@material-ui/core/styles'
import { numberFormat } from '../../utils/number-format'
import { ChartProps, TimeDataPoint } from './types'
import { convertTimeData, buildTooltipProps } from './helpers'
import { DEFAULT_COLOR } from './constants'
import { success } from '../../loudcrowd-theme'

function BarChart<K extends string, T extends TimeDataPoint<K>>({
  data,
  label,
  color = DEFAULT_COLOR,
  dataKey,
  dataFormat,
}: ChartProps<K, T>): React.ReactElement | null {
  const theme = useTheme()
  const chartData = convertTimeData(data)
  return (
    <ResponsiveContainer key={label} width="99%">
      <RechartsBarChart data={chartData}>
        <CartesianGrid vertical={false} />
        <XAxis
          padding={{ left: 25, right: 25 }}
          dataKey="dateRange"
          axisLine={false}
          tickLine={false}
          interval="preserveStart"
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={({ x, y, payload: { value, offset } }) => (
            <text textAnchor="end" x={x - 30} y={y + offset} fill={theme.palette.secondary.main}>
              {numberFormat(value, { format: dataFormat })}
            </text>
          )}
        />
        <Tooltip {...buildTooltipProps({ theme, label, color, dataFormat })} />
        <Bar stackId="a" dataKey={dataKey} barSize={20} radius={[44, 44, 0, 0]}>
          {chartData.map((entry, index) => (
            <Cell key={index} fill={entry[dataKey] >= 0 ? success[500] : theme.palette.error.main} />
          ))}
        </Bar>
      </RechartsBarChart>
    </ResponsiveContainer>
  )
}

export default BarChart
