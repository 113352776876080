import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Box } from '@material-ui/core'
import { formatDistanceToNow } from 'date-fns'
import TableHeader, { TableHeaderCell } from './TableHeader'
import TableRow, { TableRowCell } from './TableRow'
import { SocialAccountRowFragment } from './operations/social-account-row.generated'
import { createTypeNamePredicate } from '../types/utility'

export type RefreshStatus = { type: 'loading' } | { type: 'error'; message: string } | { type: 'success' }

const useStyles = makeStyles({
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
})

export const SocialTableHeader: React.FC = () => (
  <TableHeader>
    <TableHeaderCell>Account</TableHeaderCell>
    <TableHeaderCell>Platform</TableHeaderCell>
    <TableHeaderCell>Last Pull</TableHeaderCell>
    <TableHeaderCell />
    <TableHeaderCell />
  </TableHeader>
)

type IGSocialAccountFrag = Extract<SocialAccountRowFragment, { __typename: 'IGSocialAccount' }>
type TTSocialAccountFrag = Extract<SocialAccountRowFragment, { __typename: 'TTSocialAccount' }>

export interface SocialAccountRowProps {
  socialAccount: IGSocialAccountFrag | TTSocialAccountFrag
  handleRefresh: () => void
  manageTTSocialAccount: () => void
  refreshStatus?: RefreshStatus
  canRefresh: boolean
}

const isIGSocialAccount = createTypeNamePredicate('IGSocialAccount')

export const SocialAccountRow: React.FC<SocialAccountRowProps> = ({
  canRefresh,
  socialAccount,
  handleRefresh,
  refreshStatus,
  manageTTSocialAccount,
}) => {
  const classes = useStyles()
  const socialUser = socialAccount.socialUser
  const lastPull = socialAccount.lastPull
  const platform = isIGSocialAccount(socialAccount) ? 'Instagram' : 'TikTok'
  const canAddTT = !isIGSocialAccount(socialAccount)
  return (
    <TableRow>
      <TableRowCell className={classes.truncated}>@{socialUser?.username}</TableRowCell>
      <TableRowCell>{platform}</TableRowCell>
      <TableRowCell>{lastPull ? formatDistanceToNow(lastPull, { addSuffix: true }) : 'N/A'}</TableRowCell>
      <TableRowCell style={{ textAlign: 'right' }}>
        {canAddTT && (
          <Button size="small" variant="outlined" color="primary" onClick={manageTTSocialAccount}>
            Manage
          </Button>
        )}
        {canRefresh && (
          <Button
            size="small"
            disabled={refreshStatus?.type === 'loading'}
            variant="outlined"
            color="primary"
            onClick={() => handleRefresh()}
          >
            Refresh
          </Button>
        )}
      </TableRowCell>
      <TableRowCell>
        {refreshStatus?.type === 'success' && (
          <Box color="success.main" textAlign="center" paddingX={2}>
            Successfully Refreshed!
          </Box>
        )}
        {refreshStatus?.type === 'error' && (
          <Box color="error.main" textAlign="center" paddingX={2}>
            Error refreshing: {refreshStatus.message}
          </Box>
        )}
      </TableRowCell>
    </TableRow>
  )
}
