import React, { useRef, useState } from 'react'
import { Button, Typography, Menu, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { AccountLabel } from '../../components/LabelMenu/LabelMenu'
import BulkLabelsFilterMenu from '../../components/lists/ListFilters/BulkLabelsFilterMenu'
import { LabelsFilter } from '../../gql-global'

const useStyles = makeStyles({
  h7: {
    //MUI doesn't have a h7 whereas the mocks did
    fontSize: '1.125rem',
    lineHeight: 1.5,
    fontWeight: 600,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 240,
  },
  filterButton: {
    margin: '0px 8px 8px 0px',
  },
})

function filterButtonLabel(
  defaultLabel: string,
  filterSelection: string[],
  allOptionsLength: number,
  allOptionsLabel = true,
  useTwoSelectionPlus = true,
) {
  let buttonLabel = defaultLabel
  if (!filterSelection.length) {
    return buttonLabel
  } else if (filterSelection[0] && filterSelection.length === 1) {
    buttonLabel = filterSelection[0]
  } else if (useTwoSelectionPlus && filterSelection.length === 2) {
    buttonLabel = `${filterSelection[0]} + ${filterSelection[1]}`
  } else if (filterSelection.length === allOptionsLength) {
    buttonLabel = allOptionsLabel ? `All ${buttonLabel}` : buttonLabel
  } else {
    buttonLabel = `${defaultLabel} (${filterSelection.length})`
  }
  return buttonLabel
}

type RewardFilterProps = {
  campaignFilter?: Omit<LabelsFilter, '__typename'>
  integrationFilter?: Omit<LabelsFilter, '__typename'>
  deliveryMethodFilter?: Omit<LabelsFilter, '__typename'>
  campaignData: AccountLabel<string>[] | null
  integrationData: AccountLabel<string>[] | null
  deliveryMethodData: AccountLabel<string>[] | null
  onSelectPrograms: (programs: LabelsFilter) => void
  onSelectIntegrations: (integrations: LabelsFilter) => void
  onSelectDeliveryMethods: (deliveryMethods: LabelsFilter) => void
}

const RewardFilter: React.FC<RewardFilterProps> = ({
  campaignData,
  integrationData,
  deliveryMethodData,
  onSelectPrograms,
  onSelectIntegrations,
  onSelectDeliveryMethods,
  campaignFilter,
  integrationFilter,
  deliveryMethodFilter,
}) => {
  const programButtonRef = useRef<HTMLButtonElement>(null)
  const integrationButtonRef = useRef<HTMLButtonElement>(null)
  const deliveryMethodButtonRef = useRef<HTMLButtonElement>(null)
  const [programPickerOpen, setProgramPickerOpen] = useState(false)
  const [integrationPickerOpen, setIntegrationPickerOpen] = useState(false)
  const [deliveryMethodPickerOpen, setDeliveryMethodPickerOpen] = useState(false)

  const programButtonLabel = filterButtonLabel(
    'Programs',
    campaignFilter?.any?.map(c => campaignData?.find(d => d.id === c)?.name || '') ?? [],
    campaignData?.length || 0,
    false,
    false,
  )

  const integrationButtonLabel = filterButtonLabel(
    'Integrations',
    integrationFilter?.any?.map(c => integrationData?.find(d => d.id === c)?.name || '') ?? [],
    integrationData?.length || 0,
    false,
    false,
  )

  const deliveryMethodButtonLabel = filterButtonLabel(
    'Delivery Methods',
    deliveryMethodFilter?.any?.map(c => deliveryMethodData?.find(d => d.id === c)?.name || '') ?? [],
    deliveryMethodData?.length || 0,
    false,
    false,
  )

  const classes = useStyles()

  function handleValueSelected(
    newValue: LabelsFilter,
    setFilter: (value: boolean) => void,
    onSelect: (value: LabelsFilter) => void,
  ): void {
    setFilter(false)
    onSelect(newValue)
  }

  return (
    <Box>
      <Button
        color={campaignFilter?.any && campaignFilter?.any?.length > 0 ? 'primary' : undefined}
        data-intercom-target="Reward Program Picker"
        variant="outlined"
        ref={programButtonRef}
        onClick={() => setProgramPickerOpen(true)}
        endIcon={<ChevronDownIcon height="16px" width="16px" />}
        className={classes.filterButton}
      >
        <Typography className={classes.h7}>{programButtonLabel}</Typography>
      </Button>
      <Button
        color={integrationFilter?.any && integrationFilter?.any?.length > 0 ? 'primary' : undefined}
        data-intercom-target="Reward Integration Picker"
        variant="outlined"
        ref={integrationButtonRef}
        onClick={() => setIntegrationPickerOpen(true)}
        endIcon={<ChevronDownIcon height="16px" width="16px" />}
        className={classes.filterButton}
      >
        <Typography className={classes.h7}>{integrationButtonLabel}</Typography>
      </Button>
      <Button
        color={deliveryMethodFilter?.any && deliveryMethodFilter?.any?.length > 0 ? 'primary' : undefined}
        data-intercom-target="Reward Delivery Method Picker"
        variant="outlined"
        ref={deliveryMethodButtonRef}
        onClick={() => setDeliveryMethodPickerOpen(true)}
        endIcon={<ChevronDownIcon height="16px" width="16px" />}
        className={classes.filterButton}
      >
        <Typography className={classes.h7}>{deliveryMethodButtonLabel}</Typography>
      </Button>
      <Menu
        open={programPickerOpen}
        anchorEl={programButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setProgramPickerOpen(false)}
        variant="selectedMenu"
      >
        <BulkLabelsFilterMenu
          option={{
            name: 'campaigns',
            type: 'labels',
            label: 'Programs',
            entity: 'program',
            selectionOptions: campaignData ?? [],
            includeField: 'any',
          }}
          value={{ ...campaignFilter, __typename: 'LabelsFilter' }}
          onDelete={() => {
            onSelectPrograms({
              __typename: 'LabelsFilter',
              any: undefined,
            })
            setProgramPickerOpen(false)
          }}
          onSelectValue={newValue => handleValueSelected(newValue, setProgramPickerOpen, onSelectPrograms)}
          includeField="any"
        />
      </Menu>
      <Menu
        open={integrationPickerOpen}
        anchorEl={integrationButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setIntegrationPickerOpen(false)}
        variant="selectedMenu"
      >
        <BulkLabelsFilterMenu
          option={{
            name: 'integrations',
            type: 'labels',
            label: 'Integrations',
            entity: 'integration',
            omitOnIncludeListFilter: true,
            selectionOptions: integrationData ?? [],
            includeField: 'any',
          }}
          value={{ ...integrationFilter, __typename: 'LabelsFilter' }}
          onDelete={() => {
            onSelectIntegrations({
              __typename: 'LabelsFilter',
              any: undefined,
            })
            setIntegrationPickerOpen(false)
          }}
          onSelectValue={newValue => handleValueSelected(newValue, setIntegrationPickerOpen, onSelectIntegrations)}
          includeField="any"
          selectedAllByDefault
        />
      </Menu>
      <Menu
        open={deliveryMethodPickerOpen}
        anchorEl={deliveryMethodButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setDeliveryMethodPickerOpen(false)}
        variant="selectedMenu"
      >
        <BulkLabelsFilterMenu
          option={{
            name: 'deliveryMethods',
            type: 'labels',
            label: 'Delivery Methods',
            entity: 'deliveryMethod',
            omitOnIncludeListFilter: true,
            selectionOptions: deliveryMethodData ?? [],
            includeField: 'any',
          }}
          value={{ ...deliveryMethodFilter, __typename: 'LabelsFilter' }}
          onDelete={() => {
            onSelectDeliveryMethods({
              __typename: 'LabelsFilter',
              any: undefined,
            })
            setDeliveryMethodPickerOpen(false)
          }}
          onSelectValue={newValue =>
            handleValueSelected(newValue, setDeliveryMethodPickerOpen, onSelectDeliveryMethods)
          }
          includeField="any"
          selectedAllByDefault
        />
      </Menu>
    </Box>
  )
}

export default RewardFilter
