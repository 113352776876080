import React, { useEffect, useState, useMemo } from 'react'
import {
  Avatar,
  Box,
  Button,
  createStyles,
  FormControlLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { gql } from '@apollo/client'
import { ADD_TEAM_MEMBERS_ROUTE, CONNECT_FACEBOOK_ROUTE } from './routes'
import { useCurrentUserInfoQuery } from './operations/current-user-info.generated'
import { ReactComponent as InformationIcon } from '../icons/information-current-color.svg'
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg'
import { useToast } from '../components/Alert/ToastProvider'
import { FBUserAuthResult } from './ConnectFacebook'
import { useTrackSocialAccountsMutation } from './operations/track-social-accounts.generated'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: { fontWeight: 600 },
    roundedRectangleButton: {
      borderRadius: 4,
      marginTop: theme.spacing(5),
    },
    radioRoot: {
      color: theme.palette.text.hint,
    },
    igAccountList: {
      border: '1px solid',
      borderColor: theme.palette.secondary.light,
      borderRadius: 4,
      padding: 0,
      width: '100%',
    },
    salesLink: {
      marginLeft: '5px',
    },
  }),
)

interface PickSocialAccountProps {
  fbUserState: FBUserAuthResult | 'error' | null
}

export type AvailableIgAccount = NonNullable<FBUserAuthResult['availableIgAccounts']>[number]

function PickSocialAccount({ fbUserState }: PickSocialAccountProps): React.ReactElement {
  const styles = useStyles()
  const history = useHistory()
  const { showToast } = useToast()
  const [selectedIgAccount, setSelectedIgAccount] = useState<AvailableIgAccount | null>(null)
  const { data: currentUserData, loading: currentUserDataIsLoading } = useCurrentUserInfoQuery()
  const igAccounts = useMemo(
    () =>
      fbUserState && fbUserState !== 'error' && 'availableIgAccounts' in fbUserState
        ? fbUserState.availableIgAccounts || []
        : [],
    [fbUserState],
  )

  useEffect(() => {
    if (igAccounts?.[0]) {
      setSelectedIgAccount(igAccounts[0])
    }
  }, [currentUserData, igAccounts, setSelectedIgAccount])

  if (!currentUserDataIsLoading && !currentUserData?.whoami?.account?.id) {
    showToast({
      severity: 'error',
      title: 'No account id in context',
      message: 'Something went wrong, please go back and make an account before coming to this step',
    })
  }

  const [signupSocialAccounts, { loading: signupSocialsIsLoading }] = useTrackSocialAccountsMutation({
    update(cache, { data: signupSocialAccountsData }) {
      if (!signupSocialAccountsData?.trackSocialAccounts?.socialAccounts || !currentUserData?.whoami?.account) {
        return
      }
      cache.writeFragment({
        id: cache.identify(currentUserData.whoami.account),
        fragment: gql`
          fragment AccountSocialAccount on AccountType {
            socialAccounts {
              id
              platformId
              expiresAt
              lastPull
            }
          }
        `,
        data: {
          __typename: 'AccountType',
          socialAccounts: signupSocialAccountsData?.trackSocialAccounts?.socialAccounts,
        },
      })
    },
  })

  async function saveSocialAccount() {
    if (fbUserState && fbUserState !== 'error' && selectedIgAccount) {
      try {
        await signupSocialAccounts({
          variables: {
            fbUserId: fbUserState.id,
            socialAccountPlatformIds: [selectedIgAccount.platformId],
          },
        })
        history.push(ADD_TEAM_MEMBERS_ROUTE.path)
      } catch (error) {
        if (error instanceof Error && error.message.includes('Instagram account is tied to a different')) {
          showToast({
            severity: 'error',
            title: 'Instagram Account Unavailable',
            message:
              'That IG account is already tied to a different account. If you believe this is an error, please contact customer support.',
          })
        } else {
          showToast({
            severity: 'error',
            title: 'Saving Social Account Failed',
            message: 'Something went wrong, please go back and reconnect Facebook before trying again.',
          })
        }
      }
    }
  }

  return (
    <Box width={484} margin="auto">
      {!currentUserDataIsLoading && !igAccounts?.length && (
        <Box display="flex" flexDirection="column" alignItems="center" pt={10}>
          <Typography color="error">
            <InformationIcon width={48} />
          </Typography>
          <Box mt={8}>
            <Typography>
              You were not able to successfully connect any Instagram Accounts to our App. Please confirm:
            </Typography>
            <Typography>
              <ol>
                <li>You are an admin of your brand's Facebook Page.</li>
                <li>Your brand's Facebook Page is connected to your brand's Instagram account.</li>
                <li>You did not de-select any permissions before completing your sign-up.</li>
              </ol>
            </Typography>
            <Box display="inline">
              <Typography>
                If you are having trouble, please refer to our{' '}
                <Link
                  href="https://help.loudcrowd.com/en/articles/5647889-loudcrowd-sign-up-troubleshooting-your-facebook-authentication"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Troubleshooting Guide
                </Link>{' '}
                or contact <Link href="mailto:support@loudcrowd.com">support@loudcrowd.com</Link>
                {'.'}
              </Typography>
            </Box>
          </Box>
          <Box mt={8} display="flex" flexDirection="column" alignItems="center">
            <Link component={RouterLink} to={CONNECT_FACEBOOK_ROUTE.path}>
              <Button
                className={styles.roundedRectangleButton}
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<ArrowIcon width={12} height={12} transform="rotate(90)" />}
              >
                Back to Facebook Sign In
              </Button>
            </Link>
          </Box>
        </Box>
      )}
      {!!igAccounts?.length && (
        <Box display="flex" flexDirection="column" alignItems="center" pt={27}>
          <Typography className={styles.bold} variant="h4">
            Select a business account
          </Typography>
          <Box mt={2} mb={6.5} textAlign="center">
            <Typography variant="body1">Only one Business Account can be associated with LoudCrowd.</Typography>
          </Box>

          <List className={styles.igAccountList}>
            <RadioGroup
              value={selectedIgAccount?.platformId || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                setSelectedIgAccount(igAccounts?.find(a => a.platformId === value) || null)
              }}
            >
              {igAccounts?.map(a => (
                <ListItem divider key={a.platformId}>
                  <ListItemIcon>
                    <FormControlLabel
                      value={a.platformId}
                      control={
                        <Radio
                          color="primary"
                          classes={{
                            root: styles.radioRoot,
                          }}
                        />
                      }
                      label=""
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: styles.bold }} primary={a.username} />
                  <ListItemAvatar>
                    <Avatar alt={a.username} src={a.profilePictureUrl || ''} />
                  </ListItemAvatar>
                </ListItem>
              ))}
            </RadioGroup>
          </List>
          <Box mt={5} mb={8}>
            <Typography>
              Looking to add more Business Accounts?
              <Link href="mailto:sales@loudcrowd.com" className={styles.salesLink}>
                Contact Sales
              </Link>
            </Typography>
          </Box>
          <Button
            disabled={currentUserDataIsLoading || signupSocialsIsLoading}
            className={styles.roundedRectangleButton}
            variant="contained"
            color="primary"
            fullWidth
            onClick={saveSocialAccount}
          >
            Continue
          </Button>
          <Box mt={8} display="flex" flexDirection="column" alignItems="center">
            <Link component={RouterLink} to={CONNECT_FACEBOOK_ROUTE.path}>
              Back to Facebook Sign In
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PickSocialAccount
