import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type ChallengeFragment = { __typename: 'ChallengeType', id: string, keywords?: Array<string> | null, name: string, startedAt?: Date | null, endedAt?: Date | null, postTypes?: Array<Types.IgMediaPostType> | null, currentStatus: Types.ChallengeStatusEnum, hashtags?: Array<string> | null, hashtagsCriteriaOperator: Types.CriteriaOperatorEnum, keywordsCriteriaOperator: Types.CriteriaOperatorEnum, isRecentlyCompleted?: boolean | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }>, programs?: Array<{ __typename: 'ProgramType', id: string, name: string, campaign?: { __typename: 'CampaignType', id: number } | null }> | null, additionalCriteria?: { __typename: 'AdditionalCriteriaType', hasAdditionalCriteria?: boolean | null, criteriaDescription?: string | null, storyCriteriaDescription?: string | null, shouldMeetProgramRules?: boolean | null } | null, challengeRewards?: Array<{ __typename: 'ChallengeRewardType', id: string, winningCriteria: Types.ChallengeRewardCriteriaEnum, reward?: { __typename: 'RewardType', id: number } | null }> | null, paintedDoor?: { __typename: 'ChallengePaintedDoorType', briefContent?: string | null, automatedMessageContent?: string | null, briefDeliveryMethods?: Array<Types.ChallengeBriefDeliveryMethodEnum> | null, automatedMessageTriggers?: Array<Types.ChallengeMessageTriggerEnum> | null, automatedMessageDeliveryMethods?: Array<Types.ChallengeMessageDeliveryMethodEnum> | null, winningInstructions?: Array<{ __typename: 'ChallengeRewardInstructionType', instructions: string, reward?: { __typename: 'RewardType', id: number } | null }> | null } | null, mediaStats?: { __typename: 'ChallengeMediaStats', emv: number, participants: number, total: number } | null };

export const ChallengeFragmentDoc = gql`
    fragment Challenge on ChallengeType {
  id
  keywords
  name
  startedAt
  endedAt
  socialAccounts {
    id
    socialUser {
      id
      username
    }
  }
  programs {
    id
    campaign {
      id
    }
    name
  }
  additionalCriteria {
    hasAdditionalCriteria
    criteriaDescription
    storyCriteriaDescription
    shouldMeetProgramRules
  }
  postTypes
  currentStatus
  hashtags
  challengeRewards {
    id
    reward {
      id
    }
    winningCriteria
  }
  hashtagsCriteriaOperator
  keywordsCriteriaOperator
  paintedDoor {
    briefContent
    automatedMessageContent
    briefDeliveryMethods
    automatedMessageTriggers
    automatedMessageDeliveryMethods
    winningInstructions {
      reward {
        id
      }
      instructions
    }
  }
  isRecentlyCompleted
  mediaStats {
    emv
    participants
    total
  }
}
    `;