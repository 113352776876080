import React, { useEffect, useState } from 'react'
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import { ProgramTierGroupStatus } from '../../gql-global'
import { isFuture } from 'date-fns'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import OccasionPicker from './reward-automation-builder/OccasionPicker'
import RewardPicker from './reward-automation-builder/RewardPicker'
import SingleTierMonthlyBuilder from './reward-automation-builder/SingleTierMonthlyBuilder'
import { RewardAutomationTypes } from './reward-automation-builder/constants'
import SaveBuilderCriteriaButton, {
  BuilderCriteriaSubmitParams,
} from './reward-automation-builder/SaveBuilderCriteriaButton'
import { useUpsertProgramTierGroupMutation } from './operations/upsert-program-tier-group.generated'
import { useToast } from '../../components/Alert/ToastProvider'
import { ProgramTierGroup } from './ProgramActivityTimeline'
import FulfillmentLimitFilter from './reward-automation-builder/FulfillmentLimitFilter'
import MultiTierMonthlyBuilder from './reward-automation-builder/MultiTierMonthlyBuilder'
import { useProgramActivityBuilder } from './reward-automation-builder/ProgramActivityBuilderProvider'

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: 1200,
      maxWidth: 1200,
      minHeight: 700,
      maxHeight: 850,
      padding: '0 40px',
      overflow: 'visible',
    },
    content: {
      height: '100%',
      overflow: 'visible',
    },
    dialogActions: {
      padding: 25,
      justifyContent: 'flex-start',
      '& button': {
        minWidth: 100,
      },
    },
  }),
)

interface ProgramActivityDialogProps {
  open: boolean
  programId: string
  selectedAutomation?: ProgramTierGroup
  onCancel(): void
  onSetFutureStartDate: (date: Date) => void
}

function ProgramActivityDialog({
  open,
  programId,
  selectedAutomation,
  onCancel,
  onSetFutureStartDate,
}: ProgramActivityDialogProps): React.ReactElement {
  const classes = useStyles()
  const { showToast } = useToast()

  const {
    values,
    socialAccounts,
    isFormValid,
    setFulfillmentLimit,
    handleInitialData,
    buildActivityBuilderDataToSave,
    setSocialAccountIds,
  } = useProgramActivityBuilder()

  const [step, setStep] = useState<'occasion' | 'rewards' | 'builder'>('occasion')

  const [upsertProgramTierGroup] = useUpsertProgramTierGroupMutation({
    onCompleted: onCancel,
    refetchQueries: ['CampaignProgramActivities'],
  })

  const handleOnSaveBuilderCriteria = ({ status, startAt }: BuilderCriteriaSubmitParams) => {
    if (isFormValid()) {
      const activityBuilderData = buildActivityBuilderDataToSave()

      upsertProgramTierGroup({
        variables: {
          programTierGroupId: selectedAutomation?.id,
          programId,
          status,
          startAt,
          tiers: activityBuilderData?.map(item => ({ ...item, programId })),
        },
      })
        .then(() => {
          if (startAt && isFuture(new Date(startAt))) onSetFutureStartDate(startAt)
          showToast({
            title: 'Program Activity Saved',
            message:
              'Program activity has been saved successfully. Changes will go into effect for the upcoming reward date',
            severity: 'success',
          })
        })
        .catch(() => {
          showToast({
            title: 'Program Activity Failed',
            message: 'Something went wrong when saving the program activity, please try again.',
          })
        })
    }
  }

  useEffect(() => {
    if (selectedAutomation) {
      handleInitialData(selectedAutomation)
      setStep('builder')
    } else {
      setSocialAccountIds(socialAccounts?.map(sa => sa.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAutomation, socialAccounts])

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.paper }} maxWidth="lg">
      <DialogContent className={classes.content}>
        {step !== 'occasion' && (
          <Button
            color="primary"
            startIcon={<ArrowIcon transform="rotate(90)" />}
            onClick={() =>
              setStep(
                step === 'rewards' || values.occasion !== RewardAutomationTypes.SINGLE_TIER_MONTHLY
                  ? 'occasion'
                  : 'rewards',
              )
            }
          >
            Back
          </Button>
        )}
        <Box pt={3} px={2}>
          {step === 'occasion' && <OccasionPicker />}
          {step === 'rewards' && <RewardPicker />}
          {step === 'builder' && values.occasion && (
            <>
              <Box fontSize={32} fontWeight={400}>
                {values.occasion === RewardAutomationTypes.SINGLE_TIER_MONTHLY
                  ? 'Set up a single monthly recurring post goal'
                  : 'Set up your monthly recurring reward tiers'}
              </Box>
              <Box overflow="auto">
                {values.occasion === RewardAutomationTypes.SINGLE_TIER_MONTHLY ? (
                  <SingleTierMonthlyBuilder socialAccounts={socialAccounts ?? []} />
                ) : (
                  <MultiTierMonthlyBuilder socialAccounts={socialAccounts ?? []} />
                )}
              </Box>
              <Box fontSize={16} mt={4}>
                <Box mb={-1}>
                  A member can earn this reward once every calendar month{' '}
                  <FulfillmentLimitFilter
                    fulfillmentLimit={values.fulfillmentLimit}
                    onFulfillmentLimitChange={setFulfillmentLimit}
                  />
                  .
                </Box>
                <Box>
                  The post counter starts at 08:00 AM UTC on the first day of a calendar month and resets at 07:59 AM
                  UTC the first day of the next month.
                </Box>
                <Box>Rewards are sent on the first business day of the next calendar month.</Box>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {step === 'builder' && (
          <>
            <Button variant="outlined" color="primary" onClick={onCancel}>
              Cancel
            </Button>
            <SaveBuilderCriteriaButton
              programTierGroupStatus={selectedAutomation?.status}
              isEditing={!!selectedAutomation?.status && selectedAutomation?.status !== ProgramTierGroupStatus.Draft}
              onSaveBuilderCriteria={handleOnSaveBuilderCriteria}
            />
          </>
        )}

        {['rewards', 'occasion'].includes(step) && (
          <Button
            variant="contained"
            color="primary"
            disabled={(!values.occasion && step === 'occasion') || (!values.reward?.id && step === 'rewards')}
            onClick={() =>
              setStep(
                step === 'occasion' && values.occasion === RewardAutomationTypes.SINGLE_TIER_MONTHLY
                  ? 'rewards'
                  : 'builder',
              )
            }
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ProgramActivityDialog
