import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomerSourceJobMutationVariables = Types.Exact<{
  job: Types.CustomerSourceJobInput;
}>;


export type CreateCustomerSourceJobMutation = { __typename: 'Mutations', createCustomerSourceJob?: { __typename: 'CreateCustomerSourceJob', job?: { __typename: 'CustomerSourceJob', id: string, uploadUrl: { __typename: 'PresignedUrlAndFields', url: string, fields: Array<{ __typename: 'AWSS3PostField', key: string, value: string }> } } | null } | null };


export const CreateCustomerSourceJobDocument = gql`
    mutation CreateCustomerSourceJob($job: CustomerSourceJobInput!) {
  createCustomerSourceJob(job: $job) {
    job {
      id
      uploadUrl {
        url
        fields {
          key
          value
        }
      }
    }
  }
}
    `;
export type CreateCustomerSourceJobMutationFn = Apollo.MutationFunction<CreateCustomerSourceJobMutation, CreateCustomerSourceJobMutationVariables>;

/**
 * __useCreateCustomerSourceJobMutation__
 *
 * To run a mutation, you first call `useCreateCustomerSourceJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerSourceJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerSourceJobMutation, { data, loading, error }] = useCreateCustomerSourceJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useCreateCustomerSourceJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerSourceJobMutation, CreateCustomerSourceJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerSourceJobMutation, CreateCustomerSourceJobMutationVariables>(CreateCustomerSourceJobDocument, options);
      }
export type CreateCustomerSourceJobMutationHookResult = ReturnType<typeof useCreateCustomerSourceJobMutation>;
export type CreateCustomerSourceJobMutationResult = Apollo.MutationResult<CreateCustomerSourceJobMutation>;
export type CreateCustomerSourceJobMutationOptions = Apollo.BaseMutationOptions<CreateCustomerSourceJobMutation, CreateCustomerSourceJobMutationVariables>;