import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { PostAccountDetailsFragmentDoc } from './post-account-details.generated';
import { UserDetailsFragmentDoc } from './user-details.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAccountDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserAccountDetailsQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', email: string, id: number, account?: { __typename: 'AccountType', id: number, organization: { __typename: 'Organization', activeProducts: Array<Types.AccountProductName>, id: string, labelLimit: { __typename: 'LabelLimit', hasHitLimit: boolean } }, labels?: { __typename: 'PagedLabelType', total: number, results: Array<{ __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string }> } | null } | null, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null } | null };


export const UserAccountDetailsDocument = gql`
    query UserAccountDetails {
  whoami {
    account {
      ...PostAccountDetails
    }
    ...UserDetails
  }
}
    ${PostAccountDetailsFragmentDoc}
${UserDetailsFragmentDoc}`;

/**
 * __useUserAccountDetailsQuery__
 *
 * To run a query within a React component, call `useUserAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAccountDetailsQuery(baseOptions?: Apollo.QueryHookOptions<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>(UserAccountDetailsDocument, options);
      }
export function useUserAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>(UserAccountDetailsDocument, options);
        }
export type UserAccountDetailsQueryHookResult = ReturnType<typeof useUserAccountDetailsQuery>;
export type UserAccountDetailsLazyQueryHookResult = ReturnType<typeof useUserAccountDetailsLazyQuery>;
export type UserAccountDetailsQueryResult = Apollo.QueryResult<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>;