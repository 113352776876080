import { createStyles, List, ListItem, makeStyles, Menu } from '@material-ui/core'
import React from 'react'
import { secondary } from '../../loudcrowd-theme'

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      maxHeight: 108,
      overflowY: 'auto',
      overflowX: 'hidden',
      minWidth: '60px',
    },
    listItem: {
      marginRight: '20px',
      marginLeft: '12px',
      fontWeight: 500,
      color: secondary[600],
      marginTop: 0,
      marginBottom: 0,
      padding: 4,
    },
  }),
)

interface ChallengeProgramListMenuProps {
  currentRef: HTMLDivElement | null
  programs: string[]
  open: boolean
  onClose(): void
}

function ChallengeProgramListMenu({
  currentRef,
  programs,
  open,
  onClose,
}: ChallengeProgramListMenuProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Menu
      open={open}
      anchorEl={currentRef}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      getContentAnchorEl={null}
      onClose={onClose}
    >
      <List className={classes.list}>
        {programs.map((p, idx) => (
          <ListItem key={idx} className={classes.listItem}>
            {p}
          </ListItem>
        ))}
      </List>
    </Menu>
  )
}

export default ChallengeProgramListMenu
