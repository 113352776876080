import React, { ReactElement } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import DiscountSettingsDialog from './DiscountSettingsDialog'
import DiscountSettingsBlock from '../../../components/SettingsBlocks/DiscountSettingsBlock'
import { useToast } from '../../../components/Alert/ToastProvider'
import { EcommDiscountCodeFragment } from './operations/ecomm-discount-code.generated'
import {
  UpsertProgramEcommDiscountCodeMutationVariables,
  useUpsertProgramEcommDiscountCodeMutation,
} from './operations/upsert-program-ecomm-discount-code.generated'
import { useDeactivateProgramEcommDiscountCodeMutation } from './operations/deactivate-program-ecomm-discount-code.generated'
import { useActivateProgramEcommDiscountCodeMutation } from './operations/activate-program-ecomm-discount-code.generated'
import { EcommDiscountCodeTypeEnum } from '../../../gql-global'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
      width: '800px',
    },
  }),
)

interface DiscountGenerationSettingsProps {
  programId?: string
  campaignId?: string
  ecommDiscountCode?: EcommDiscountCodeFragment | null
  discountType: EcommDiscountCodeTypeEnum
  isOpen: boolean
  handleOpen: (state: boolean) => void
}

function DiscountGenerationSettings({
  programId,
  campaignId,
  ecommDiscountCode,
  discountType,
  isOpen,
  handleOpen,
}: DiscountGenerationSettingsProps): ReactElement {
  const classes = useStyles()
  const { showToast } = useToast()

  const discountTypeLabel =
    discountType === EcommDiscountCodeTypeEnum.Affiliate ? 'Affiliate Code' : 'Automatic Discount'

  const [upsertProgramEcommDiscountCode] = useUpsertProgramEcommDiscountCodeMutation({
    onError: () => {
      showToast({
        title: `Error: Update ${discountTypeLabel} Settings`,
        message: `Something went wrong when saving ${discountTypeLabel} settings, please try again.`,
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: `${discountTypeLabel} settings saved`,
        severity: 'success',
      })
    },
  })
  const [deactivateProgramEcommDiscountCode] = useDeactivateProgramEcommDiscountCodeMutation({
    onError: () => {
      showToast({
        title: `Error: Update ${discountTypeLabel} Settings`,
        message: `Something went wrong when deactivating ${discountTypeLabel} settings, please try again.`,
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: `${discountTypeLabel} settings deactivated`,
        severity: 'success',
      })
    },
  })
  const [activateProgramEcommDiscountCode] = useActivateProgramEcommDiscountCodeMutation({
    onError: () => {
      showToast({
        title: `Error: Update ${discountTypeLabel} Settings`,
        message: `Something went wrong when activiting ${discountTypeLabel} settings, please try again.`,
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: `${discountTypeLabel} settings activated`,
        severity: 'success',
      })
    },
  })

  const handleOnClicked = () => {
    handleOpen(true)
  }

  const handleOnModalClose = () => {
    handleOpen(false)
  }

  const handleOnSubmit = async (variables: UpsertProgramEcommDiscountCodeMutationVariables) => {
    await upsertProgramEcommDiscountCode({
      variables,
      refetchQueries: ['CampaignSettings'],
    })
  }

  const deactivateDiscountCode = async () => {
    if (programId && ecommDiscountCode?.id) {
      const variables = {
        ecommDiscountCodeId: ecommDiscountCode.id,
      }
      await deactivateProgramEcommDiscountCode({ variables, refetchQueries: ['CampaignSettings'] })
    }
  }

  const activateDiscountCode = async () => {
    if (programId && ecommDiscountCode?.id) {
      const variables = {
        ecommDiscountCodeId: ecommDiscountCode.id,
      }
      await activateProgramEcommDiscountCode({ variables, refetchQueries: ['CampaignSettings'] })
    }
  }

  return (
    <Box className={ecommDiscountCode ? classes.container : undefined}>
      <Box mb={6}>
        {!!ecommDiscountCode && (
          <DiscountSettingsBlock
            ecommDiscountCode={ecommDiscountCode}
            isSettingsExpired={!!(ecommDiscountCode?.endsAt && ecommDiscountCode.endsAt < new Date())}
            handleOnSettingsEdit={handleOnClicked}
            deactivateDiscountCode={deactivateDiscountCode}
            activateDiscountCode={activateDiscountCode}
            discountType={discountType}
          />
        )}
      </Box>
      <DiscountSettingsDialog
        open={isOpen}
        onClose={handleOnModalClose}
        ecommDiscountCode={ecommDiscountCode}
        programId={programId}
        campaignId={campaignId}
        onSubmit={handleOnSubmit}
        discountType={discountType}
      />
    </Box>
  )
}

export default DiscountGenerationSettings
