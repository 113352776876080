import { IntegrationTypes, RewardType, RewardStats, CodesLevel, IntegrationType } from '../gql-global'
import { isApolloError, MutationFunctionOptions } from '@apollo/client'
import { FetchResult } from '@apollo/client/link/core/types'
import {
  MembersSendRewardMutation,
  MembersSendRewardMutationVariables,
} from '../campaign/campaign-detail/operations/members-send-reward.generated'
import { SendRewardMutation, SendRewardMutationVariables } from '../customer/operations/send-reward.generated'
import { ToastData } from '../components/Alert/Toast'

export type MessageRewardType = Pick<RewardType, 'id' | 'name'> & { stats: Pick<RewardStats, 'codesLevel'> }

type WithNotNullProperty<T, K extends keyof T> = T & { [P in K]-?: Exclude<NonNullable<T[P]>, undefined> }

export function hasNotNullProperty<T, K extends keyof T>(thing: T, prop: K): thing is WithNotNullProperty<T, K> {
  return !!thing[prop]
}

type RewardIntegration = Pick<IntegrationTypes, '__typename' | 'name'>
type Reward = {
  integration?: RewardIntegration | null
  hasDiscountCode?: boolean | null
  codesRemaining?: number | null
  deliveryMethod?: string | null
}

type RewardWithAutoDelivery<T extends Reward> = WithNotNullProperty<T, 'integration'>

export function rewardHasAutoDelivery<T extends Reward>(reward: T): reward is RewardWithAutoDelivery<T> {
  return hasNotNullProperty(reward, 'integration')
}

export function getIntegrationText(integration: RewardIntegration): string {
  switch (integration.__typename) {
    case 'MandrillIntegration':
    case 'KlaviyoIntegration':
    case 'SendinblueIntegration':
    case 'ZaiusIntegration':
    case 'BrazeIntegration':
    case 'LoyaltyLionIntegration':
    case 'SmileIntegration':
    case 'EmarsysIntegration': {
      return 'Email'
    }
  }
  return integration.name
}

export function getAutoDeliveryText(
  reward: WithNotNullProperty<Reward, 'integration'>,
  checkCodesRemaining = true,
): string {
  switch (reward.integration.__typename) {
    case 'MandrillIntegration':
    case 'KlaviyoIntegration':
    case 'ZaiusIntegration':
    case 'SendinblueIntegration':
    case 'BrazeIntegration':
    case 'EmarsysIntegration':
    case 'TremendousIntegration': {
      if (reward.hasDiscountCode && (!checkCodesRemaining || (reward.codesRemaining && reward.codesRemaining > 0))) {
        return 'Email or Manual'
      } else {
        return 'Email'
      }
    }
  }
  return getIntegrationText(reward.integration)
}

export function rewardName(reward: MessageRewardType): string {
  let name = reward.name || `Reward ${reward.id}`
  if (reward.stats.codesLevel === CodesLevel.Low) {
    name += ' (codes low)'
  } else if (reward.stats.codesLevel === CodesLevel.Empty) {
    name += ' (no codes)'
  }
  return name
}

export function rewardDeliveryMethodCaption<T extends Reward>(reward: T): string {
  return reward.deliveryMethod ?? (rewardHasAutoDelivery(reward) ? getAutoDeliveryText(reward, false) : 'Manual')
}

export async function sendRewardAndHandleResult<
  T extends MembersSendRewardMutation | SendRewardMutation,
  R extends MembersSendRewardMutationVariables | SendRewardMutationVariables,
  S extends R,
>(
  reward: { id: number; name?: string | null },
  sendReward: (options?: MutationFunctionOptions<T, R>) => Promise<FetchResult<T>>,
  variables: S,
  offline: boolean,
  showToast: (value: ToastData) => void,
  refetch?: string[],
): Promise<void> {
  try {
    const result = await sendReward({
      variables,
      refetchQueries: refetch || ['CustomerActivities', 'PostUserRewardsHistory'],
    })
    if (offline) {
      const code = result?.data?.sendFulfillment?.fulfillments?.[0]?.rewardDiscountCode?.code
      if (!code) {
        showToast({
          title: 'Error: Sending Reward',
          message: 'Something went wrong while sending reward to customer. Please try again.',
        })
      } else {
        await navigator.clipboard.writeText(code)
        showToast({
          title: 'Success. Copied',
          message: `Successfully copied code to clipboard: ${code}`,
          severity: 'success',
        })
      }
    } else {
      showToast({
        title: 'Success: Sent Reward',
        message: `Sent reward ${reward.name}`,
        severity: 'success',
        autoHideDuration: 5000,
      })
    }
  } catch (e) {
    let message = 'Something went wrong sending the reward to member(s), please try again.'
    if (
      e instanceof Error &&
      isApolloError(e) &&
      e.graphQLErrors.some(e => e.extensions?.code === 'RESOURCE_EXHAUSTED')
    ) {
      message = 'There are not enough discount codes loaded for this reward. Please upload more and try again'
    }
    showToast({ title: 'Error: Sending Reward', message })
  }
}

export function getRewardIntegrationLabel(integrationType: IntegrationType): string {
  switch (integrationType) {
    case IntegrationType.Mandrill:
      return 'Mandrill'
    case IntegrationType.Klaviyo:
      return 'Klaviyo'
    case IntegrationType.Zaius:
      return 'Zaius'
    case IntegrationType.Sendinblue:
      return 'Sendinblue'
    case IntegrationType.Braze:
      return 'Braze'
    case IntegrationType.Emarsys:
      return 'Emarsys'
    case IntegrationType.Tremendous:
      return 'Tremendous'
    case IntegrationType.Yotpo:
      return 'Yotpo'
    case IntegrationType.YotpoViz:
      return 'Yotpo Viz'
    case IntegrationType.Growave:
      return 'Growave'
    case IntegrationType.LoyaltyLion:
      return 'Loyalty Lion'
    case IntegrationType.Smile:
      return 'Smile.io'
    case IntegrationType.Shopify:
      return 'Shopify'
    case IntegrationType.Webhook:
      return 'Webhook'
    case IntegrationType.Ometria:
      return 'Ometria'
    case IntegrationType.Bluecore:
      return 'Bluecore'
  }
  return ''
}

const PLATFORM_BRAND_COLORS_MAP: { [key: string]: string } = {
  [IntegrationType.Mandrill]: '#212121',
  [IntegrationType.Zaius]: '#FC7F10', // Optimizely
  [IntegrationType.Sendinblue]: '#0092FF',
  [IntegrationType.Yotpo]: '#0042E4',
  [IntegrationType.YotpoViz]: '#0042E4',
  [IntegrationType.Webhook]: '#5500FF',
  [IntegrationType.Growave]: '#00BBF0',
  [IntegrationType.Klaviyo]: '#000000',
  [IntegrationType.Emarsys]: '#3BCF85',
  [IntegrationType.Braze]: '#39C5D7',
  [IntegrationType.LoyaltyLion]: '#582C83',
  [IntegrationType.Smile]: '#FDC032',
  [IntegrationType.Shopify]: '#95BF47',
  [IntegrationType.Tremendous]: '#0373FC',
  [IntegrationType.Ometria]: '#333499',
  [IntegrationType.Bluecore]: '#0b209f',
  // [IntegrationType.FreshSends]: '#EDB6B2',
}

export const getIntegrationBrandColor = (integrationType = IntegrationType.Webhook): string | undefined => {
  return PLATFORM_BRAND_COLORS_MAP[integrationType]
}
