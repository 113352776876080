import { ApolloError, NetworkStatus } from '@apollo/client'
import { RewardSort, SortDirection } from '../../../gql-global'
import { useProgramActivityRewardsQuery } from './operations/program-activity-rewards.generated'

const REWARD_PAGE_SIZE = 10

interface RetrieveRewardsResult {
  rewards?: { id: number; name?: string | null }[] | undefined
  loading: boolean
  loadingMore: boolean
  error?: ApolloError
  handleLoadMore: () => void
  cursor?: string | null
}

function useProgramActivityRewards(limit: number = REWARD_PAGE_SIZE): RetrieveRewardsResult {
  const {
    loading: rewardsLoading,
    data,
    error,
    networkStatus,
    fetchMore,
  } = useProgramActivityRewardsQuery({
    variables: {
      limit: limit,
      sortBy: RewardSort.Name,
      sortDirection: SortDirection.Asc,
    },
  })
  const loading = rewardsLoading && networkStatus !== NetworkStatus.fetchMore
  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const cursor = data?.whoami?.account?.rewards?.cursor

  const handleLoadMore = () => {
    if (cursor) {
      void fetchMore({ variables: { cursor, limit } })
    }
  }

  return {
    rewards: data?.whoami?.account?.rewards?.results ?? [],
    loading,
    loadingMore,
    error,
    cursor,
    handleLoadMore,
  }
}

export default useProgramActivityRewards
