import React from 'react'
import { Box, Modal, useTheme } from '@material-ui/core'
import { DialogTransition } from '../../loudcrowd-transition'
import MessagePhonePreview, { MessagePhonePreviewProps } from './MessagePhonePreview'

interface MessagePhonePreviewModalProps extends MessagePhonePreviewProps {
  open: boolean
  onClose(): void
}

function MessagePhonePreviewModal({
  open,
  onClose,
  ...previewProps
}: MessagePhonePreviewModalProps): React.ReactElement {
  const theme = useTheme()
  return (
    <Modal open={open} onClose={onClose}>
      <DialogTransition
        appear
        in={open}
        timeout={{ enter: theme.transitions.duration.enteringScreen, exit: theme.transitions.duration.leavingScreen }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          onClick={event => event.target === event.currentTarget && onClose()}
        >
          <MessagePhonePreview {...previewProps} />
        </Box>
      </DialogTransition>
    </Modal>
  )
}

export default MessagePhonePreviewModal
