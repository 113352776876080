import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ReactComponent as CrossMark } from '../../icons/cross.svg'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circle: {
      display: 'flex',
      width: '22px',
      height: '22px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
    logo: {
      color: 'white',
      margin: 'auto',
    },
  }),
)

export interface CircleCrossProps {
  className?: string
}

function CircleCross({ className }: CircleCrossProps): React.ReactElement {
  const classes = useStyles()

  const circleClass = className ? `${className} ${classes.circle}` : classes.circle
  return (
    <div className={circleClass}>
      <CrossMark className={classes.logo} />
    </div>
  )
}

export default CircleCross
