import React from 'react'
import { useFormikContext } from 'formik'
import MessagePreview from './MessagePreview'
import { Box, Typography, createStyles, makeStyles, Button, Divider } from '@material-ui/core'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import TextFilters from '../../components/lists/ListFilters/TextFilters'
import { Options } from '../../components/lists/ListFilters/types'
import { useMessageAutomationDataQuery } from '../operations/query-message-automation-data.generated'
import { useMessagePreviewDataQuery } from '../operations/query-message-preview-data.generated'
import { NumericalFilter } from '../../components/lists/ListFilters/NumericalFilter'
import { skipCustomerMessageTimeFrameSelectionOptions, skipSendMessageSelectionOptions } from './AutomationBuilder'
import { MessageTemplateFormFields } from './messageSchema'
import { AutomationFormFields } from './automationSchema'

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      fontSize: 24,
      fontWeight: 400,
      margin: '0 0 16px 0',
    },
    subtitle: {
      color: '#7C7CA4',
      margin: '0 0 24px 0',
    },
    bold: {
      fontWeight: 600,
    },
    whoText: {
      fontWeight: 600,
      margin: '0 0 8px 0',
    },
    builderText: {
      fontSize: '1.25rem',
      lineHeight: '2.5rem',
      margin: '0 0 20px 0',
    },
    skipText: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    builderTextBold: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    container: {
      width: 544,
    },
    divider: {
      marginTop: theme.spacing(5),
    },
  }),
)

type MessageReviewProps = {
  messageForm?: MessageTemplateFormFields
  isNew: boolean
  onBack: () => void
  onSubmit: () => void
}
function MessageReview(props: MessageReviewProps): React.ReactElement {
  const { onBack, onSubmit, messageForm, isNew } = props
  const classes = useStyles()
  const { data } = useMessageAutomationDataQuery()
  const socialAccountId = data?.whoami?.preferences.selectedSocialAccountId ?? ''
  const { data: userData } = useMessagePreviewDataQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId,
    },
  })
  const accountCampaigns =
    userData?.socialAccount?.campaigns?.map(c => {
      return { id: c.id, name: c.program?.name || '' }
    }) || []

  const { values: filters, setFieldValue } = useFormikContext<AutomationFormFields>()
  const options: Options = [
    {
      name: 'followerCount',
      type: 'numericalRange',
      label: 'Follower Count',
      min: 0,
      max: 1_000_000_000,
      clearable: true,
      presets: [
        { lte: 1000 },
        { gte: 1000, lte: 10_000 },
        { gte: 10_000, lte: 1_000_000 },
        { gte: 100_000, lte: 10_000_000 },
        { gte: 1_000_000 },
      ],
      displayVariant: 'inline',
    },
    {
      name: 'segments',
      type: 'chips',
      label: 'Segments',
      entity: 'segment',
      selectionOptions: data?.whoami?.account?.segments?.results || [],
      displayVariant: 'inline',
    },
    {
      name: 'campaigns',
      type: 'chips',
      label: 'Programs',
      entity: 'program',
      selectionOptions: accountCampaigns,
      displayVariant: 'inline',
    },
    {
      name: 'biography',
      type: 'keywordsChips',
      label: 'Creator Bio',
      displayVariant: 'inline',
    },
  ]

  const selectedSkipMessageOption = skipSendMessageSelectionOptions.find(o => o.id === filters.skipSendMessage)
  const selectedSkipMessageTimeFrameOption = skipCustomerMessageTimeFrameSelectionOptions.find(
    o => o.id === filters.skipActionTimeBuffer,
  )

  let skipTimeBufferText = ''
  if (selectedSkipMessageTimeFrameOption) {
    if (selectedSkipMessageTimeFrameOption.label !== 'ever') {
      skipTimeBufferText =
        selectedSkipMessageTimeFrameOption.label !== 'ever'
          ? `in the ${selectedSkipMessageTimeFrameOption.label}`
          : selectedSkipMessageTimeFrameOption.label
    }
  }
  return (
    <Box display="flex" flexGrow={1}>
      <Box width="50%" display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" className={classes.container}>
          <Typography variant="h5" className={classes.title}>
            Finally, let’s review your automated message
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            If everything looks good, then you’re ready to launch
          </Typography>
          <Typography variant="body2" className={classes.bold}>
            Who will get a message
          </Typography>
          <Box className={classes.builderText}>
            Customers
            {filters.who === 'specificCustomers' ? ' who ' : ' '}
            <TextFilters editable={false} onChangeFilters={() => null} options={options} filters={filters} /> when they
            mention @{userData?.socialAccount?.socialUser?.username} in{' '}
            <NumericalFilter
              onSelectValue={value => setFieldValue('storyCount', value, false)}
              value={filters.customerTriggerQuantity}
              defaultValue={1}
              label="Story Count"
              subLabel="stories"
              editable={false}
            />{' '}
            instagram {filters.customerTriggerQuantity === 1 ? 'story' : 'stories'}
          </Box>
          {filters.skipCustomers && (
            <>
              <Typography variant="body2" className={classes.whoText}>
                Who will not get a message
              </Typography>
              <Typography variant="body2" className={classes.skipText}>
                Skip customers who have already received
              </Typography>
              <Typography variant="body2" className={classes.builderTextBold}>
                {selectedSkipMessageOption && selectedSkipMessageOption.label} {skipTimeBufferText}
              </Typography>
            </>
          )}
          <Divider className={classes.divider} />
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button color="primary" startIcon={<ArrowIcon transform="rotate(90)" />} onClick={onBack}>
              Back
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              {isNew ? 'Launch' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box width="50%" display="flex" justifyContent="center">
        <MessagePreview messageForm={messageForm} />
      </Box>
    </Box>
  )
}

export default MessageReview
