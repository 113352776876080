import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { CustomerNoteFragmentFragmentDoc } from './customer-note-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddNoteMutationVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  note: Types.Scalars['String'];
  categoryLcid?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type AddNoteMutation = { __typename: 'Mutations', addCustomerNote?: { __typename: 'AddCustomerNote', ok?: boolean | null, note?: { __typename: 'CustomerNoteType', id: string, createdAt: Date, deletedAt?: Date | null, note: string, author: { __typename: 'UserType', id: number, email: string }, category?: { __typename: 'CustomerNoteCategory', id: string, name: string } | null } | null } | null };


export const AddNoteDocument = gql`
    mutation AddNote($customerId: ID!, $note: String!, $categoryLcid: ID) {
  addCustomerNote(
    customerId: $customerId
    note: $note
    categoryLcid: $categoryLcid
  ) {
    ok
    note {
      ...CustomerNoteFragment
    }
  }
}
    ${CustomerNoteFragmentFragmentDoc}`;
export type AddNoteMutationFn = Apollo.MutationFunction<AddNoteMutation, AddNoteMutationVariables>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      note: // value for 'note'
 *      categoryLcid: // value for 'categoryLcid'
 *   },
 * });
 */
export function useAddNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, options);
      }
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<AddNoteMutation, AddNoteMutationVariables>;