import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProgramActivitySocialAccountsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProgramActivitySocialAccountsQuery = { __typename: 'Query', program?: { __typename: 'ProgramType', id: string, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }> } | null };


export const ProgramActivitySocialAccountsDocument = gql`
    query ProgramActivitySocialAccounts($id: ID!) {
  program(id: $id) {
    id
    socialAccounts {
      id
      socialUser {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useProgramActivitySocialAccountsQuery__
 *
 * To run a query within a React component, call `useProgramActivitySocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramActivitySocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramActivitySocialAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProgramActivitySocialAccountsQuery(baseOptions: Apollo.QueryHookOptions<ProgramActivitySocialAccountsQuery, ProgramActivitySocialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramActivitySocialAccountsQuery, ProgramActivitySocialAccountsQueryVariables>(ProgramActivitySocialAccountsDocument, options);
      }
export function useProgramActivitySocialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramActivitySocialAccountsQuery, ProgramActivitySocialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramActivitySocialAccountsQuery, ProgramActivitySocialAccountsQueryVariables>(ProgramActivitySocialAccountsDocument, options);
        }
export type ProgramActivitySocialAccountsQueryHookResult = ReturnType<typeof useProgramActivitySocialAccountsQuery>;
export type ProgramActivitySocialAccountsLazyQueryHookResult = ReturnType<typeof useProgramActivitySocialAccountsLazyQuery>;
export type ProgramActivitySocialAccountsQueryResult = Apollo.QueryResult<ProgramActivitySocialAccountsQuery, ProgramActivitySocialAccountsQueryVariables>;