import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { CustomFieldFilterFragmentDoc } from '../../../utils/operations/custom-field-filter.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignMembersCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  challengesCursor?: Types.InputMaybe<Types.Scalars['String']>;
  challengesLimit: Types.Scalars['Int'];
}>;


export type CampaignMembersCampaignQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, nextPaymentDate?: Date | null, paymentPeriods?: Array<{ __typename: 'PaymentPeriod', endAt: Date, id: string, paymentAt: Date, startAt: Date }> | null, program?: { __typename: 'ProgramType', currencyCode?: Types.CurrencyCodeEnum | null, id: string, programType?: Types.ProgramTypeEnum | null, challenges?: { __typename: 'ChallengeTypePaged', cursor?: string | null, total: number, results: Array<{ __typename: 'ChallengeType', id: string, name: string }> } | null, ecommIntegration?: { __typename: 'CommerceCloudIntegration', id: string } | { __typename: 'CustomStoreIntegration', id: string } | { __typename: 'ShopifyIntegration', id: string } | null, hosts?: Array<{ __typename: 'OwnedProgramHost', id: string, igRequired: boolean, ttRequired: boolean }> | null, programPayoutSettings?: { __typename: 'ProgramPayoutSettingsType', id: string, orderMaturationPeriodDays: number, period: Types.PaymentPeriodEnum } | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }>, tiers?: Array<{ __typename: 'ProgramTierType', id: string, reward?: { __typename: 'RewardType', id: number, name?: string | null } | null }> | null } | null } | null, noteCategories?: Array<{ __typename: 'CustomerNoteCategory', id: string, name: string }> | null, whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, customFields: Array<{ __typename: 'CustomFieldType', id: string, options?: Array<string> | null, position?: number | null, required: boolean, title: string, type: Types.CustomFieldEnumType }>, organization: { __typename: 'Organization', id: string, segmentLimit: { __typename: 'SegmentLimit', hasHitLimit: boolean } }, segments?: { __typename: 'PagedSegmentType', total: number, results: Array<{ __typename: 'SegmentType', id: number, name: string }> } | null } | null } | null };


export const CampaignMembersCampaignDocument = gql`
    query CampaignMembersCampaign($campaignId: ID!, $challengesCursor: String, $challengesLimit: Int!) {
  campaign(id: $campaignId) {
    id
    nextPaymentDate
    paymentPeriods {
      endAt
      id
      paymentAt
      startAt
    }
    program {
      challenges(cursor: $challengesCursor, limit: $challengesLimit) {
        cursor
        results {
          id
          name
        }
        total
      }
      currencyCode
      ecommIntegration {
        ... on Node {
          id
        }
      }
      hosts {
        id
        igRequired
        ttRequired
      }
      id
      programPayoutSettings {
        id
        orderMaturationPeriodDays
        period
      }
      programType
      socialAccounts {
        id
      }
      tiers {
        id
        reward {
          id
          name
        }
      }
    }
  }
  noteCategories {
    id
    name
  }
  whoami {
    account {
      customFields {
        ...CustomFieldFilter
      }
      id
      organization {
        id
        segmentLimit {
          hasHitLimit
        }
      }
      segments(limit: 200, sortBy: RECENTLY_USED) {
        results {
          id
          name
        }
        total
      }
    }
    id
  }
}
    ${CustomFieldFilterFragmentDoc}`;

/**
 * __useCampaignMembersCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignMembersCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignMembersCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignMembersCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      challengesCursor: // value for 'challengesCursor'
 *      challengesLimit: // value for 'challengesLimit'
 *   },
 * });
 */
export function useCampaignMembersCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignMembersCampaignQuery, CampaignMembersCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignMembersCampaignQuery, CampaignMembersCampaignQueryVariables>(CampaignMembersCampaignDocument, options);
      }
export function useCampaignMembersCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignMembersCampaignQuery, CampaignMembersCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignMembersCampaignQuery, CampaignMembersCampaignQueryVariables>(CampaignMembersCampaignDocument, options);
        }
export type CampaignMembersCampaignQueryHookResult = ReturnType<typeof useCampaignMembersCampaignQuery>;
export type CampaignMembersCampaignLazyQueryHookResult = ReturnType<typeof useCampaignMembersCampaignLazyQuery>;
export type CampaignMembersCampaignQueryResult = Apollo.QueryResult<CampaignMembersCampaignQuery, CampaignMembersCampaignQueryVariables>;