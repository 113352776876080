export const deepClone = <T>(value: T): T => {
  if (value === null || typeof value !== 'object') return value

  if (value instanceof Set) return new Set(value) as T

  const clone: any = Array.isArray(value) ? [] : {}

  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      clone[key] = deepClone(value[key])
    }
  }

  return clone as T
}

export const allSetsToArray = <T>(value: T) => {
  let result = deepClone(value)

  for (const key in result) {
    if (result[key] instanceof Set) {
      result = { ...result, [key]: Array.from(result[key] as Set<T>).sort() }
    }
  }
  return result
}

export const isEqual = <T extends Record<string, any>>(original?: T, modified?: T): boolean => {
  if (!original || !modified) return true

  const orderAttributes = <T extends Record<string, any>>(value: T): T | undefined => {
    const keys = Object.keys(value).sort()
    const result: { [key: string]: any } = {}

    keys.forEach(key => (result[key] = value[key]))

    return result as T
  }

  return (
    JSON.stringify(allSetsToArray(orderAttributes(original))) ===
    JSON.stringify(allSetsToArray(orderAttributes(modified)))
  )
}
