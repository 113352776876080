import React, { useRef, useState } from 'react'
import { Menu, Button, Typography, makeStyles } from '@material-ui/core'
import { dateRangeLabel } from '../../utils/date-range-helper'
import { DateRangeFilter, DateTimeRangeFilter } from '../../gql-global'
import DateRangePickerMenuFlow from './DateRangePickerMenuFlow'

export interface DateRangePickerProps {
  // Called when date range selected and applied
  onChange(newDateRange: DateRangeFilter | DateTimeRangeFilter | null): void
  selectedDateRange?: DateRangeFilter | DateTimeRangeFilter | null
  includeAllTime?: boolean
  variant?: 'outlined' | 'contained'
  emptyLabel?: string
  endIcon?: React.ReactNode
}

const useStyles = makeStyles({
  paper: {
    minWidth: 272,
  },
})

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  selectedDateRange,
  emptyLabel,
  includeAllTime = false,
  variant = 'contained',
  endIcon,
}) => {
  const placeholder = includeAllTime ? 'All Time' : 'Select Date Range'
  const classes = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const onChangeDateRange = (newDateRange: DateRangeFilter): void => {
    setOpen(false)
    onChange(newDateRange)
  }

  const onMenuClosed = (): void => {
    setOpen(false)
  }

  const buttonLabel = selectedDateRange ? dateRangeLabel(selectedDateRange) : emptyLabel ?? placeholder
  return (
    <>
      <Button
        variant={variant}
        size="large"
        onClick={() => setOpen(true)}
        color="primary"
        ref={buttonRef}
        endIcon={endIcon}
      >
        <Typography variant="body2">{buttonLabel}</Typography>
      </Button>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        onClose={onMenuClosed}
        classes={{ paper: classes.paper }}
      >
        <DateRangePickerMenuFlow
          dateRange={selectedDateRange || null}
          includeAllTime={includeAllTime}
          onChangeDateRange={onChangeDateRange}
        />
      </Menu>
    </>
  )
}

export default DateRangePicker
