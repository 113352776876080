import { userHasFeature } from '../utils/feature-manager'
import { AccountProductName } from '../gql-global'
export class Route {
  readonly path: string
  readonly anyRole?: Set<string>
  readonly featureflag?: string
  readonly product?: AccountProductName

  constructor(path: string, anyRole?: string[], featureflag?: string, product?: AccountProductName) {
    this.path = path
    this.anyRole = anyRole ? new Set(anyRole) : undefined
    this.featureflag = featureflag
    this.product = product
  }

  // Create subroute, inheriting roles unless overridden
  subroute(
    subpath: string,
    subRouteSpecificRoles?: string[],
    featureflag?: string,
    product?: AccountProductName,
  ): Route {
    let roles = subRouteSpecificRoles
    if (!subRouteSpecificRoles) {
      roles = this.anyRole ? Array.from(this.anyRole) : undefined
    }
    const sub = new Route(`${this.path}${subpath}`, roles, featureflag, product)
    return sub
  }

  hasAccess(
    roles: string[] = [],
    products: AccountProductName[] = [],
    username = '',
    accountId: number | null = null,
  ): boolean {
    if (this.featureflag) {
      if (!userHasFeature({ username: username, roles: roles, accountId: accountId }, this.featureflag)) {
        return false
      }
    }
    if (this.product && !products.includes(this.product)) {
      return false
    }
    return !this.anyRole || roles.some(r => this.anyRole?.has(r))
  }
}
