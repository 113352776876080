import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { Link, makeStyles, Theme, createStyles, Box, Typography } from '@material-ui/core'
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg'
import { ReactComponent as ForwardArrowIcon } from '../../icons/forward-arrow.svg'

interface SeeAllLinkProps {
  to: RouterLinkProps<unknown>['to']
  variant: 'text' | 'icon'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    seeAll: {
      marginLeft: theme.spacing(3),
    },
  }),
)

function SeeAllLink({ to, variant }: SeeAllLinkProps): React.ReactElement {
  const classes = useStyles()
  if (variant === 'icon') {
    return (
      <Link variant="subtitle2" component={RouterLink} to={to} color="secondary">
        <Box className="hoverLink" display="flex" flexDirection="column" alignItems="center">
          <ForwardArrowIcon height={24} width={24} />
          <Typography>See All</Typography>
        </Box>
      </Link>
    )
  } else {
    return (
      <Link variant="subtitle2" className={classes.seeAll} component={RouterLink} to={to}>
        <Box display="inline-flex" alignItems="center">
          See all
          <Box display="inline" ml={2}>
            <ArrowIcon width={12} height={12} transform="rotate(-90)" />
          </Box>
        </Box>
      </Link>
    )
  }
}

export default SeeAllLink
