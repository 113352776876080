import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  DialogActions,
  CircularProgress,
  useTheme,
  Collapse,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ProgramFilterInput, ProgramLtvStatusInput, ProgramSort, SortDirection } from '../../gql-global'
import { AccountRowFragment } from './operations/account-row.generated'
import {
  useAdminProgramsByAccountLazyQuery,
  AdminProgramsByAccountDocument,
  AdminProgramsByAccountQuery
} from './operations/admin-programs-by-account.generated'
import { AdminProgramLtvStatsFragment, useProgramLtvStatsLazyQuery } from './operations/program-ltv-stats.generated'
import { useProgramSortParam } from './use-sort-param'
import useFilterParams, { Filters } from './use-filter-params'
import useSortDirParam from './use-sort-dir-param'
import SwitchComponent from '../../components/Switch/Switch'
import { useUpdateProgramsLtvStatusesMutation } from './operations/update-programs-ltv-status.generated'
import { amber, primary, secondary } from './../../loudcrowd-theme'
import Alert from '../../components/Alert'
import CampaignDashboardEcommStats, {
  calculateLtvDiffs,
} from '../../campaign/campaign-detail/CampaignDashboardEcommStats'
import { ReactComponent as Triangle } from '../../icons/triangle.svg'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { ReactComponent as Customer } from '../../icons/customer.svg'

const PAGE_SIZE = 1000
const TABLE_HEIGHT = 500
const ROW_HEIGHT = 50
const LTV_PARTICIPANTS_THRESHOLD = 50

interface LTVManagementDialogProps {
  open: boolean
  onClose(): void
  account: AccountRowFragment | undefined
}

const useStyles = makeStyles({
  tableContainer: {
    outline: '1px solid ' + secondary[400],
    borderRadius: '5px',
    height: `${TABLE_HEIGHT}px`,
    float: 'left',
  },
  contentHeader: {
    display: 'flex',
  },
  accountName: {
    flex: 1,
    padding: 2,
  },
  modifiedCount: {
    padding: '2px 10px',
    backgroundColor: amber[600],
    borderRadius: '100px',
    color: 'white',
    fontWeight: 'bold',
  },
  loadingTableCell: {
    height: `${TABLE_HEIGHT - ROW_HEIGHT}px`,
    border: 'none',
  },
})

function LTVManagementDialog({ open, onClose, account }: LTVManagementDialogProps): React.ReactElement {
  const styles = useStyles()

  /**
   * States
   */
  const [programLtvStatusInputs, setProgramLtvStatusInputs] = useState<{ [key: string]: ProgramLtvStatusInput }>({})
  const [alertVisible, setAlertVisible] = useState<boolean>(false)
  const [alertState, setAlertState] = useState<{
    title: string
    message: string
    severity: 'success' | 'info' | 'warning' | 'error' | undefined
  }>({
    title: '',
    message: '',
    severity: undefined,
  })
  const [rerender, setRerender] = useState<boolean>(false)

  /**
   * Queries
   */
  const { filters } = useFilterParams()
  const programWhereFilters = useProgramWhereFilters(filters)
  const [programSort = ProgramSort.Name] = useProgramSortParam()
  const [sortDir = SortDirection.Asc] = useSortDirParam()
  const accountId = account?.id.toString() || ''

  const [executeQuery, queryResults] = useAdminProgramsByAccountLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId,
      limit: PAGE_SIZE,
      where: programWhereFilters,
      sortBy: programSort,
      sortDirection: sortDir,
    },
  })
  const [executeStatsQuery, statsResults] = useProgramLtvStatsLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId,
      limit: PAGE_SIZE,
      where: programWhereFilters,
      sortBy: programSort,
      sortDirection: sortDir,
    },
  })

  const [updateProgramsLtvStatuses] = useUpdateProgramsLtvStatusesMutation({
    refetchQueries: [
      {
        query: AdminProgramsByAccountDocument,
        variables: {
          accountId: account?.id.toString() || '',
          limit: PAGE_SIZE,
          where: programWhereFilters,
          sortBy: programSort,
          sortDirection: sortDir,
        },
      },
    ],
  })

  /**
   * Effects
   */
  useEffect(() => {
    setProgramLtvStatusInputs({})
    if (accountId) {
      executeQuery()
      executeStatsQuery()
    }
  }, [executeQuery, accountId, executeStatsQuery])

  useEffect(() => {
    if (!!alertState.title) {
      setAlertVisible(true)
      const alertTimer = setTimeout(() => {
        setAlertVisible(false)
      }, 2000)
      return () => clearTimeout(alertTimer)
    }
  }, [alertState])

  /**
   * Event Handlers
   */
  const onFormSubmit = async () => {
    try {
      await updateProgramsLtvStatuses({
        variables: { account_id: account?.id.toString() || '', programs: Object.values(programLtvStatusInputs) },
      })
      setAlertState({ title: 'Success', message: 'LTV settings were saved successfully.', severity: 'success' })
    } catch (ex) {
      setRerender(!rerender)
      setAlertState({ title: 'Error', message: 'Something went wrong. Please try again.', severity: 'error' })
    } finally {
      setProgramLtvStatusInputs({})
    }
  }

  const onFormClose = () => {
    onClose()
    setProgramLtvStatusInputs({})
  }

  const onLtvSwitch = (programId: string, ltvActive: boolean) => {
    if (programLtvStatusInputs.hasOwnProperty(programId)) {
      const newState = { ...programLtvStatusInputs }
      delete newState[programId]
      setProgramLtvStatusInputs(newState)
    } else {
      const newProgramInput: ProgramLtvStatusInput = { id: programId, accountId, ltvActive }
      setProgramLtvStatusInputs({ ...programLtvStatusInputs, [programId]: newProgramInput })
    }
  }

  return (
    <Dialog open={open} onClose={onFormClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Program LTV Settings</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <div className={styles.contentHeader}>
            <p className={styles.accountName}>
              Account: <b>{account?.name}</b>
            </p>
            {Object.keys(programLtvStatusInputs).length > 0 && (
              <p className={styles.modifiedCount}>{Object.keys(programLtvStatusInputs).length} modified</p>
            )}
          </div>

          <TableContainer className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Program Name</TableCell>
                  <TableCell>Member LTV</TableCell>
                  <TableCell>Pre-membership LTV</TableCell>
                  <TableCell>Non-member LTV</TableCell>
                  <TableCell align="center">LTV Setting</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* loading animation */}
                {queryResults.loading && (
                  <TableRow>
                    <TableCell colSpan={6} className={styles.loadingTableCell} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {/* actual data */}
                {!!queryResults.data?.account?.programs?.results &&
                  queryResults.data?.account?.programs.results.map((program, programIndex) => {
                    return (
                      <ProgramTableRow
                        key={`ltv-program-table-row-${programIndex}-${rerender}`}
                        program={program}
                        stats={statsResults?.data?.account?.programs?.results?.[programIndex]}
                        ltvActive={program.ltvActive}
                        onLtvSwitch={onLtvSwitch}
                      />
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onFormClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" onClick={onFormSubmit}>
          Save
        </Button>
      </DialogActions>
      <Alert severity={alertState.severity} title={alertState.title} message={alertState.message} open={alertVisible} />
    </Dialog>
  )
}

export default LTVManagementDialog

const useProgramWhereFilters = (filters: Filters): ProgramFilterInput => {
  return { search: null }
}

interface ProgramTableRowStylesProps {
  previewOpen: boolean
}

const useProgramTableRowStyles = makeStyles({
  tableCell: {
    borderBottom: 'none',
  },
  previewHeader: {
    padding: '2px 10px',
    backgroundColor: primary[100],
    borderRadius: '100px',
    float: 'left',
    margin: 0,
  },
  previewContainer: {
    padding: '0 15px 15px',
    margin: '15px 0',
    borderRadius: '10px',
    backgroundColor: secondary[100],
    border: '1px solid ' + secondary[300],
    float: 'left',
  },
  collapseIcon: {
    width: '20px',
    transform: (props: ProgramTableRowStylesProps) => {
      return props.previewOpen ? 'rotate(-180deg)' : 'rotate(0)'
    },
  },
  programNameContainer: { display: 'flex', flexDirection: 'column' },
  customerCountContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: secondary[600],
  },
  customerIcon: { width: 15, height: 15, marginRight: 6 },
})

const ProgramTableRow = ({
  program,
  stats,
  ltvActive,
  onLtvSwitch,
}: {
  program: NonNullable<NonNullable<NonNullable<AdminProgramsByAccountQuery['account']>['programs']>['results'][number]>,
  stats: AdminProgramLtvStatsFragment | null | undefined,
  ltvActive: boolean
  onLtvSwitch(programId: string, ltvActive: boolean): void
}) => {
  const ltvBreakdown = stats?.campaign?.ltvBreakdown ?? undefined

  const { prememberDiff, nonmemberDiff } = calculateLtvDiffs(ltvBreakdown)

  /**
   * State
   */
  const [checked, setChecked] = useState<boolean>(ltvActive)
  const [previewOpen, setPreviewOpen] = useState<boolean>(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false)
  const ltvCustomers = ltvBreakdown?.customers

  /**
   * Event Handlers
   */
  const onSwitchClick = () => {
    if (!checked && !!ltvCustomers && ltvCustomers < LTV_PARTICIPANTS_THRESHOLD) {
      setWarningDialogOpen(true)
    } else {
      switchOnOff()
    }
  }

  const switchOnOff = () => {
    onLtvSwitch(program.id.toString(), !checked)
    setChecked(!checked)
  }

  const onDropdownClick = () => {
    setPreviewOpen(!previewOpen)
  }

  const onWarningDialogClose = () => {
    setWarningDialogOpen(false)
  }

  // Styles
  const styles = useProgramTableRowStyles({ previewOpen })

  return (
    <>
      {/* Table row */}
      <TableRow style={{ backgroundColor: checked !== ltvActive ? amber[100] : '' }}>
        <TableCell className={styles.tableCell}>
          <div className={styles.programNameContainer}>
            <b>{program.name}</b>
            <span className={styles.customerCountContainer}>
              <Customer className={styles.customerIcon} />
              <span>
                <i>
                  {ltvCustomers || ltvCustomers === 0 ? ltvCustomers : '-'} purchasing customer
                  {ltvCustomers !== 1 && 's'}
                </i>
              </span>
            </span>
          </div>
        </TableCell>
        <TableCell className={styles.tableCell}>
          <StyledLtvValues value={ltvBreakdown?.ltvAfter} showTriangle={false} />
        </TableCell>
        <TableCell className={styles.tableCell}>
          <StyledLtvValues value={prememberDiff} />
        </TableCell>
        <TableCell className={styles.tableCell}>
          <StyledLtvValues value={nonmemberDiff} />
        </TableCell>
        <TableCell className={styles.tableCell} align="center">
          <SwitchComponent checked={checked} onChange={onSwitchClick} />
        </TableCell>
        <TableCell className={styles.tableCell}>
          <Button onClick={onDropdownClick}>
            <ChevronDownIcon className={styles.collapseIcon} />
          </Button>
        </TableCell>
      </TableRow>

      {/* LTV Widget Preview */}
      <TableRow style={{ backgroundColor: checked !== ltvActive ? amber[100] : '' }}>
        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={previewOpen}>
            <p className={styles.previewHeader}>Preview</p>
            <div className={styles.previewContainer}>
              <CampaignDashboardEcommStats
                dateRangeFilter={null}
                statsLoading={false}
                data={{ currentLtvBreakdown: ltvBreakdown, previousLtvBreakdown: ltvBreakdown }}
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Warning Dialog */}
      <LtvWarningDialog
        open={warningDialogOpen}
        onClose={onWarningDialogClose}
        confirm={() => {
          switchOnOff()
          setWarningDialogOpen(false)
        }}
      />
    </>
  )
}

const useLtvValuesStyles = makeStyles({
  triangle: {
    marginRight: '5px',
    width: '10px',
  },
  upTriangle: {
    fill: '#009E8E',
    stroke: '#009E8E',
    paddingTop: '4px',
  },
  downTriangle: {
    fill: '#FF3C3C',
    transform: 'rotate(180deg)',
    paddingBottom: '4px',
  },
})

const StyledLtvValues = ({
  value,
  showTriangle = true,
}: {
  value: number | undefined
  showTriangle?: boolean
}): JSX.Element => {
  const styles = useLtvValuesStyles()
  const theme = useTheme()

  if (typeof value !== 'number') {
    return <>-</>
  }

  const numericValue = Math.round(value)
  const isNegative = numericValue < 0
  const triangleClassName = `${styles.triangle} ${isNegative ? styles.downTriangle : styles.upTriangle}`

  return (
    <span style={{ color: isNegative ? theme.palette['error'].dark : '' }}>
      {showTriangle ? <Triangle className={triangleClassName} /> : <></>}${Math.abs(numericValue)}
    </span>
  )
}

const LtvWarningDialog = ({ open, onClose, confirm }: { open: boolean; onClose(): void; confirm(): void }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <Box>
          <p>
            This program has a low number of participants, and its LTV values may fluctuate greatly as new participants
            are added to the program.
          </p>
        </Box>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={confirm}>
            I understand
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
