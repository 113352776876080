import { DateTimeParam, NumberParam } from 'use-query-params'
import {
  DateInclusionExclusionFilterInput,
  DateRangeFilterType,
  DateTimeRangeFilterInput,
  DateRangeFilterUnits,
  DateTimeRangeFilter,
  DateRangeFilterInput,
} from '../gql-global'
import { createEnumParam } from '../utils/url-param-utils'
import { useDateRangeRef } from '../hooks/useDateRangeRef'
import { realizedDateRangeFromFilter } from './date-range-helper'
import { NumericalRange } from '../components/NumericalRangePicker/NumericalRangePicker'

type DateParamType = 'require' | 'exclude' | ''

function getDateParamTypeSuffix(type: DateParamType) {
  return type ? `${type.charAt(0).toUpperCase()}${type.substring(1)}` : ''
}

export const getDateRangeFilterToParams = (
  name: string,
  type: DateParamType,
  input?: DateTimeRangeFilterInput | null,
): DateTimeRangeFilterInput => {
  const typeSuffix = getDateParamTypeSuffix(type)
  const params = { [`${name}${typeSuffix}Type`]: input?.rangeType || undefined }

  if (input?.rangeType === DateRangeFilterType.Absolute) {
    return {
      ...params,
      [`${name}${typeSuffix}Gte`]: input.gte || undefined,
      [`${name}${typeSuffix}Lt`]: input.lt || undefined,
    }
  } else if (input?.rangeType === DateRangeFilterType.Relative) {
    return {
      ...params,
      [`${name}${typeSuffix}Unit`]: input.unit || undefined,
      [`${name}${typeSuffix}Value`]: input.value || undefined,
      [`${name}${typeSuffix}Offset`]: input.offset || undefined,
    }
  }

  return {
    [`${name}${typeSuffix}Type`]: undefined,
    [`${name}${typeSuffix}Gte`]: undefined,
    [`${name}${typeSuffix}Lt`]: undefined,
    [`${name}${typeSuffix}Unit`]: undefined,
    [`${name}${typeSuffix}Value`]: undefined,
    [`${name}${typeSuffix}Offset`]: undefined,
  }
}

export const getDateInclusionExclusionFiltersToParams = (
  name: string,
  config?: DateInclusionExclusionFilterInput,
): DateTimeRangeFilterInput => {
  return {
    ...getDateRangeFilterToParams(name, 'require', config?.require),
    ...getDateRangeFilterToParams(name, 'exclude', config?.exclude),
  }
}

export const getDateFilterParamsConfig = (
  name: string,
  type: DateParamType,
): {
  [key: string]: unknown
} => {
  const typeSuffix = getDateParamTypeSuffix(type)
  return {
    [`${name}${typeSuffix}Type`]: createEnumParam(DateRangeFilterType),
    [`${name}${typeSuffix}Gte`]: DateTimeParam,
    [`${name}${typeSuffix}Lt`]: DateTimeParam,
    [`${name}${typeSuffix}Value`]: NumberParam,
    [`${name}${typeSuffix}Offset`]: NumberParam,
    [`${name}${typeSuffix}Unit`]: createEnumParam(DateRangeFilterUnits),
  }
}

export const getNumericalRangeFilterParamsConfig = (name: string): { [key: string]: typeof NumberParam } => {
  return {
    [`${name}Eq`]: NumberParam,
    [`${name}Gt`]: NumberParam,
    [`${name}Gte`]: NumberParam,
    [`${name}Lt`]: NumberParam,
    [`${name}Lte`]: NumberParam,
  }
}

export const getNumericalRangeFilterToParams = (name: string, input?: NumericalRange | null): NumericalRange => {
  return {
    [`${name}Gt`]: input?.gt,
    [`${name}Gte`]: input?.gte,
    [`${name}Lt`]: input?.lt,
    [`${name}Lte`]: input?.lte,
    [`${name}Eq`]: input?.eq,
  }
}

export const getDateParamsToFilters = (
  name: string,
  type: DateParamType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
): DateTimeRangeFilter | undefined => {
  const typeSuffix = getDateParamTypeSuffix(type)
  let dateRangeFilter: DateTimeRangeFilter | undefined = undefined
  if (params?.[`${name}${typeSuffix}Type`]) {
    if (params?.[`${name}${typeSuffix}Type`] === DateRangeFilterType.Absolute && params?.[`${name}${typeSuffix}Gte`]) {
      dateRangeFilter = {
        __typename: 'AbsoluteDateTimeRangeFilter',
        rangeType: DateRangeFilterType.Absolute,
        gte: params?.[`${name}${typeSuffix}Gte`],
        lt: params?.[`${name}${typeSuffix}Lt`],
      }
    } else if (
      params?.[`${name}${typeSuffix}Type`] === DateRangeFilterType.Relative &&
      params?.[`${name}${typeSuffix}Value`] &&
      params?.[`${name}${typeSuffix}Unit`]
    ) {
      dateRangeFilter = {
        __typename: 'RelativeDateRangeFilter',
        rangeType: DateRangeFilterType.Relative,
        value: params?.[`${name}${typeSuffix}Value`],
        unit: params?.[`${name}${typeSuffix}Unit`],
        offset: params?.[`${name}${typeSuffix}Offset`],
      }
    }
  }
  return dateRangeFilter
}

export const getDateInclusionExclusionParamsToFilters = (
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
): Omit<DateInclusionExclusionFilterInput, '__typename'> => {
  return {
    require: getDateParamsToFilters(name, 'require', params),
    exclude: getDateParamsToFilters(name, 'exclude', params),
  }
}

export const useDateInclusionExclusionWhereFilter = (
  input?: DateInclusionExclusionFilterInput,
): DateInclusionExclusionFilterInput | undefined => {
  const dateInclusionRange = input?.require ? realizedDateRangeFromFilter(input?.require as DateTimeRangeFilter) : null
  const reffedDateInclusionRange = useDateRangeRef(dateInclusionRange)

  const dateExclusionRange = input?.exclude ? realizedDateRangeFromFilter(input?.exclude as DateTimeRangeFilter) : null
  const reffedDateExclusionRange = useDateRangeRef(dateExclusionRange)

  return reffedDateInclusionRange || reffedDateExclusionRange
    ? {
        ...(reffedDateInclusionRange && { require: reffedDateInclusionRange }),
        ...(reffedDateExclusionRange && { exclude: reffedDateExclusionRange }),
      }
    : undefined
}

export const dateInclusionExclusionSaveFilter = (
  input?: DateInclusionExclusionFilterInput,
): DateInclusionExclusionFilterInput => {
  let dateInclusionFilter: DateRangeFilterInput | null = null
  let dateExclusionFilter: DateRangeFilterInput | null = null
  if (input?.require) {
    if (input.require.rangeType === DateRangeFilterType.Absolute) {
      dateInclusionFilter = {
        rangeType: DateRangeFilterType.Absolute,
        gte: input.require.gte,
        lt: input.require.lt,
      }
    } else {
      dateInclusionFilter = {
        rangeType: DateRangeFilterType.Relative,
        value: input.require.value,
        unit: input.require.unit,
        offset: input.require.offset,
      }
    }
  }

  if (input?.exclude) {
    if (input.exclude.rangeType === DateRangeFilterType.Absolute) {
      dateExclusionFilter = {
        rangeType: DateRangeFilterType.Absolute,
        gte: input.exclude.gte,
        lt: input.exclude.lt,
      }
    } else {
      dateExclusionFilter = {
        rangeType: DateRangeFilterType.Relative,
        value: input.exclude.value,
        unit: input.exclude.unit,
        offset: input.exclude.offset,
      }
    }
  }

  return {
    ...(dateExclusionFilter && {
      exclude: dateExclusionFilter,
    }),
    ...(dateInclusionFilter && {
      require: dateInclusionFilter,
    }),
  }
}

export const getNumericalRangeParamToFilter = (
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any },
): NumericalRange | undefined => {
  const gt = params?.[`${name}Gt`]
  const gte = params?.[`${name}Gte`]
  const lt = params?.[`${name}Lt`]
  const lte = params?.[`${name}Lte`]
  const eq = params?.[`${name}Eq`]
  return gt !== undefined || gte !== undefined || lt !== undefined || lte !== undefined || eq !== undefined
    ? {
        lt: params?.[`${name}Lt`],
        lte: params?.[`${name}Lte`],
        gt: params?.[`${name}Gt`],
        gte: params?.[`${name}Gte`],
        eq: params?.[`${name}Eq`],
      }
    : undefined
}

type AllAnyNoneFilter<T extends string> = {
  all?: Array<Array<T>> | null
  any?: Array<T> | null
  none?: Array<T> | null
}
export const cleanAllAnyNoneFilter = <R extends string, T extends AllAnyNoneFilter<R>>(
  params: T | undefined,
): AllAnyNoneFilter<R> | undefined => {
  if (!params) return undefined

  const anyParam = params.any && params.any.length > 0
  const noneParam = params.none && params.none.length > 0
  const allParam = params.all && params.all.some(p => (p?.length ?? 0) > 0)

  return anyParam || noneParam || allParam
    ? {
        any: anyParam ? Array.from(new Set(params.any)) : undefined,
        none: noneParam ? Array.from(new Set(params.none)) : undefined,
        all: allParam ? Array.from(new Set(params.all)) : undefined,
      }
    : undefined
}
