import {
  CommissionTierRuleConditionClauseEnum,
  CommissionTierRuleConditionEnum,
  CommissionTierRuleType,
  IntegrationType,
} from '../../../gql-global'
import React, { ReactElement, useState } from 'react'
import { Box, createStyles, makeStyles, Typography, TextField, InputAdornment } from '@material-ui/core'
import { gray, primary } from '../../../loudcrowd-theme'

import { ReactComponent as PlusCircleIcon } from '../../../icons/plus-circle-outline.svg'
import { ReactComponent as TrashIcon } from '../../../icons/trash_can.svg'
import CommissionTierRuleCondition, { CommissionTierRuleConditionTypeWithOptions } from './CommissionTierRuleCondition'

const useStyles = makeStyles(() =>
  createStyles({
    withBorder: {
      border: `1px solid ${gray}`,
      borderRadius: '5px',
    },
    dialogField: {
      width: '50%',
      marginBottom: '20px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '20px',
    },
    addConditions: {
      cursor: 'pointer',
    },
    conditionSeparator: {
      fontWeight: 700,
      color: primary[500],
      marginBottom: 4,
    },
  }),
)

export type CommissionTierRuleTypeWithOptions = Omit<CommissionTierRuleType, 'conditions'> & {
  conditions: CommissionTierRuleConditionTypeWithOptions[]
}

interface CommissionTierRuleProps {
  rule?: CommissionTierRuleTypeWithOptions | null
  border?: boolean
  showRuleNumber?: boolean
  allowRemoveRule?: boolean
  onRemoveRule?: () => void
  campaignId?: string
  integrationType?: IntegrationType
  percentageErrorMessage?: string
  onChange?: (rule: CommissionTierRuleTypeWithOptions) => void
}

export default function CommissionTierRule({
  rule = null,
  border = false,
  showRuleNumber = false,
  allowRemoveRule = false,
  campaignId,
  integrationType,
  onRemoveRule,
  percentageErrorMessage,
  onChange,
}: CommissionTierRuleProps): React.ReactElement {
  const classes = useStyles()
  const percentageAdornment = { endAdornment: <InputAdornment position="end">%</InputAdornment> }
  const [conditions, setConditions] = useState<CommissionTierRuleConditionTypeWithOptions[]>(rule?.conditions || [])



  const handleAddConditionsClick = () => {
    const newCondition = {
      __typename: 'CommissionTierRuleConditionType',
      brands: [],
      collections: [],
      conditionClauseType: CommissionTierRuleConditionClauseEnum.Product,
      conditionNumber: conditions.length + 1,
      conditionType: CommissionTierRuleConditionEnum.Inclusion,
      id: `temp-${conditions.length + 1}`,
      products: [],
    } as CommissionTierRuleConditionTypeWithOptions
    const newConditions = [...conditions, newCondition]
    setConditions(newConditions)
    if (onChange && rule) {
      onChange({ ...rule, conditions: newConditions })
    }
  }

  const handleConditionRemove = (index: number) => {
    const newConditions = conditions.filter((_, i) => i !== index)
    setConditions(newConditions)
    if (onChange && rule) {
      onChange({ ...rule, conditions: newConditions })
    }
  }

  function handleConditionChange(newCondition: CommissionTierRuleConditionTypeWithOptions, index: number) {
    const newConditions = conditions.map((condition, i) => (i === index ? newCondition : condition))
    setConditions(newConditions)
    if (onChange && rule) {
      onChange({ ...rule, conditions: newConditions })
    } else {
      console.error("shouldn't get here", onChange, rule)
    }
  }

  function handlePercentageChange(newPercentage: number) {
    if (onChange && rule) {
      onChange({ ...rule, ratePercentage: newPercentage, conditions: conditions })
    }
  }

  function renderConditions(conditions: CommissionTierRuleConditionTypeWithOptions[]) {
    // renders the list of conditions, but places an 'AND' between each condition
    let elements: ReactElement[] = []

    conditions.forEach((condition, index) => {
      elements.push(
        <CommissionTierRuleCondition
          key={index}
          condition={condition}
          handleRemoveClick={() => handleConditionRemove(index)}
          campaignId={campaignId}
          integrationType={integrationType}
          onChange={(newCondition: CommissionTierRuleConditionTypeWithOptions) =>
            handleConditionChange(newCondition, index)
          }
        />,
      )
      if (conditions[index + 1]) {
        elements.push(<Typography className={classes.conditionSeparator}>AND</Typography>)
      }
    })
    return <Box>{elements}</Box>
  }

  return (
    <Box width="100%" mb={3} padding={2} className={border ? classes.withBorder : ''}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {showRuleNumber && (
          <Box color={primary[500]} fontSize={20} fontWeight={600} mb={2}>
            Rule {rule?.ruleNumber}
          </Box>
        )}
        {allowRemoveRule && (
          <Box color={primary[500]} display="flex" alignItems="center" fontWeight={700} mr={2} onClick={onRemoveRule}>
            <Box mr={2} display="flex" alignItems="center">
              <TrashIcon width={20} height={20} />
            </Box>
            Remove rule
          </Box>
        )}
      </Box>
      <Box className={classes.row}>
        <TextField
          InputLabelProps={{ shrink: true }}
          className={classes.dialogField}
          label="Commission Percentage"
          placeholder="Set an Integer number between 0 & 100"
          value={rule?.ratePercentage}
          onChange={e => handlePercentageChange(parseInt(e.target.value))}
          error={!!percentageErrorMessage}
          helperText={percentageErrorMessage}
          InputProps={percentageAdornment}
        ></TextField>
        {conditions.length === 0 && (
          <Box
            className={classes.addConditions}
            ml={5}
            display="flex"
            alignItems="center"
            color={primary[500]}
            fontWeight={700}
            onClick={handleAddConditionsClick}
          >
            <Box mr={2} display="flex" alignItems="center">
              <PlusCircleIcon width={20} height={20} />
            </Box>
            Add specific conditions to earn this commission rate
          </Box>
        )}
      </Box>

      {rule && renderConditions(conditions)}

      {conditions.length > 0 && (
        <Box
          className={classes.addConditions}
          display="flex"
          alignItems="center"
          color={primary[500]}
          fontWeight={700}
          onClick={handleAddConditionsClick}
        >
          <Box mr={2} display="flex" alignItems="center">
            <PlusCircleIcon width={20} height={20} />
          </Box>
          Add new condition
        </Box>
      )}
    </Box>
  )
}
