import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useToast } from '../components/Alert/ToastProvider'
import { CustomerRefetchType } from './CustomerDetail'
import { ProgramAffiliateDiscountCode } from './CustomerDetailInfo'
import {
  UpsertAffiliateCodeMutationVariables,
  useUpsertAffiliateCodeMutation,
} from './operations/upsert-affiliate-code.generated'
import { useDeleteDiscountRedeemCodesMutation } from './operations/delete-discount-redeem-codes.generated'
import { ReactComponent as CrossIcon } from '../icons/cross.svg'

interface AffiliateCodeDialogProps {
  open: boolean
  onClose: () => void
  programArr: ProgramAffiliateDiscountCode[] | null
  selectedProgram: ProgramAffiliateDiscountCode | null
  selectedRedeemCode: { code: string; ecommDiscountRedeemCodeId: string } | null
  customerId: string
  customerRefetch: CustomerRefetchType
}

function AffiliateCodeDialog({
  customerId,
  programArr,
  selectedProgram,
  selectedRedeemCode,
  open,
  onClose,
  customerRefetch,
}: AffiliateCodeDialogProps): React.ReactElement {
  const [modalView, setModalView] = useState<'update' | 'delete'>('update')

  useEffect(() => {
    setModalView('update')
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <Box position="absolute" right={0}>
        <IconButton onClick={onClose}>
          <CrossIcon width={13} height={13} />
        </IconButton>
      </Box>
      {modalView === 'update' && (
        <UpdateAffiliateCodeDialogContent
          customerId={customerId}
          programArr={programArr}
          selectedProgram={selectedProgram}
          selectedRedeemCode={selectedRedeemCode}
          customerRefetch={customerRefetch}
          onClose={onClose}
          setModalView={setModalView}
        />
      )}
      {modalView === 'delete' && (
        <DeleteAffiliateCodeDialogContent
          onClose={onClose}
          setModalView={setModalView}
          selectedRedeemCode={selectedRedeemCode}
          customerRefetch={customerRefetch}
        />
      )}
    </Dialog>
  )
}

const useUpdateStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '100%',
    margin: '15px 0',
    '& .MuiInput-root': {
      fontSize: 14,
    },
    '& label.Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
      borderColor: theme.palette.text.hint,
    },
    '& .MuiFormHelperText-filled': {
      marginBottom: -15,
      fontSize: 14,
    },
  },
}))

interface UpdateAffiliateCodeDialogContentProps {
  programArr: ProgramAffiliateDiscountCode[] | null
  selectedProgram: ProgramAffiliateDiscountCode | null
  selectedRedeemCode: { code: string; ecommDiscountRedeemCodeId: string } | null
  customerId: string
  customerRefetch: CustomerRefetchType
  onClose(): void
  setModalView: React.Dispatch<React.SetStateAction<'update' | 'delete'>>
}

const UpdateAffiliateCodeDialogContent = ({
  programArr,
  selectedProgram,
  customerId,
  selectedRedeemCode,
  customerRefetch,
  onClose,
  setModalView,
}: UpdateAffiliateCodeDialogContentProps) => {
  const classes = useUpdateStyles()
  const { showToast } = useToast()

  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(null)
  const [codeVal, setCodeVal] = useState<string>(selectedRedeemCode?.code || '')
  const [upsertAffiliateCode] = useUpsertAffiliateCodeMutation({
    onCompleted: () => customerRefetch(),
  })

  useEffect(() => {
    setCodeVal(selectedRedeemCode?.code || '')
  }, [selectedRedeemCode, setCodeVal])
  useEffect(() => {
    setSelectedProgramId(selectedProgram ? selectedProgram.program.id : null)
  }, [selectedProgram, setSelectedProgramId])

  const handleSave = () => {
    if (!selectedProgramId) {
      showToast({ title: 'Error: Saving affiliate code', message: 'Please select a program', severity: 'error' })
    }
    if (!codeVal) {
      showToast({ title: 'Error: Saving affiliate code', message: 'Please input a valid code', severity: 'error' })
    }
    if (codeVal === selectedRedeemCode?.code) {
      onClose()
      return
    }

    let variables: UpsertAffiliateCodeMutationVariables = {
      customerId,
      programId: selectedProgramId || '',
      code: codeVal,
    }
    if (selectedRedeemCode?.ecommDiscountRedeemCodeId) {
      variables = { ...variables, ecommDiscountRedeemCodeId: selectedRedeemCode.ecommDiscountRedeemCodeId }
    }

    upsertAffiliateCode({
      variables: variables,
    })
      .then(() => {
        showToast({ title: 'Success', message: 'Affiliate code saved', severity: 'success' })
        onClose()
      })
      .catch(error => {
        showToast({ title: 'Error: Saving affiliate code', message: error.toString(), severity: 'error' })
      })
  }

  return (
    <>
      <DialogTitle>{selectedProgram ? 'Update' : 'Add'} Affiliate Code</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box>
          {selectedProgram && (
            <Box>
              <TextField
                className={classes.input}
                label="Program"
                value={selectedProgram.program.name}
                disabled={true}
              />
            </Box>
          )}
          {programArr && (
            <Box>
              <InputLabel>Program</InputLabel>
              <Select
                className={classes.input}
                onChange={selectItem => {
                  setSelectedProgramId(selectItem.target.value as string)
                }}
              >
                {programArr.map(program => (
                  <MenuItem value={program.program.id} key={program.program.id}>
                    {program.program.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <Box>
            <TextField
              className={classes.input}
              label="Code"
              value={codeVal.toUpperCase()}
              onChange={({ target: { value } }) => setCodeVal(value.toUpperCase())}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedProgram ? (
          <Button
            onClick={() => {
              setModalView('delete')
            }}
            color="primary"
          >
            Delete
          </Button>
        ) : (
          <Button
            color="secondary"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>
        )}
        <Button type="submit" variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  )
}

interface DeleteAffiliateCodeDialogContentProps {
  onClose(): void
  setModalView: React.Dispatch<React.SetStateAction<'update' | 'delete'>>
  selectedRedeemCode: { code: string; ecommDiscountRedeemCodeId: string } | null
  customerRefetch: CustomerRefetchType
}

const DeleteAffiliateCodeDialogContent = ({
  setModalView,
  onClose,
  selectedRedeemCode,
  customerRefetch,
}: DeleteAffiliateCodeDialogContentProps) => {
  const { showToast } = useToast()
  const [executeDeleteDiscountRedeemCodesMutation] = useDeleteDiscountRedeemCodesMutation({
    onCompleted: () => customerRefetch(),
  })

  const handleConfirm = () => {
    if (!selectedRedeemCode?.ecommDiscountRedeemCodeId) return

    executeDeleteDiscountRedeemCodesMutation({
      variables: { ecommDiscountRedeemCodeId: selectedRedeemCode?.ecommDiscountRedeemCodeId },
    })
      .then(() => {
        showToast({ title: 'Success', message: 'Affiliate code successfully deleted', severity: 'success' })
        onClose()
      })
      .catch(error => {
        showToast({ title: 'Error: Deleting affiliate code', message: error.toString(), severity: 'error' })
      })
  }

  return (
    <>
      <DialogTitle>Delete Affiliate Code</DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <Box>
          Are you sure you want to delete this affiliate code
          {selectedRedeemCode?.code ? ` (${selectedRedeemCode?.code})` : ''} from this program? This action cannot be
          undone!
        </Box>
        <Box mt={4}>
          Once the code is deleted, it can no longer be used at checkout, and it will be removed from this member's CRM.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setModalView('update')
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}

export default AffiliateCodeDialog
