import React, { useCallback, useEffect, useState } from 'react'
import MultiSelectSearch from '../../../components/MultiSelectSearch'
import {
  useCampaignProgramEcommCustomerSegmentLazyQuery,
  CampaignProgramEcommCustomerSegmentQuery,
} from './operations/campaign-program-ecomm-customer-segment.generated'
import { Box, Checkbox, Divider, MenuProps } from '@material-ui/core'

const PAGE_SIZE = 15

type EcommCustomerSegmentType = NonNullable<
  NonNullable<NonNullable<CampaignProgramEcommCustomerSegmentQuery['campaign']>['program']>['ecommCustomerSegments']
>[0]

interface EcommCustomerSegmentPickerMenuProps {
  campaignId?: string
  open: boolean
  onCancel(): void
  onAdd(params: EcommCustomerSegmentType[]): void
  selectedSegments: EcommCustomerSegmentType[]
  anchorEl?: MenuProps['anchorEl']
}

const EcommCustomerSegmentPickerMenu = (props: EcommCustomerSegmentPickerMenuProps): JSX.Element => {
  const { open, onCancel, onAdd, anchorEl, campaignId, selectedSegments } = props

  /**
   * State
   */
  const [selectedCustomerSegments, setSelectedCustomerSegments] = useState<EcommCustomerSegmentType[]>(selectedSegments)

  /**
   * Queries
   */
  const [executeCustomerSegmentQuery, { data: customerSegmentData, fetchMore: fetchMoreCustomerSegments }] =
    useCampaignProgramEcommCustomerSegmentLazyQuery()

  /**
   * Event handlers
   */
  const loadData = useCallback(
    async (searchText?: string) => {
      if (!campaignId) return
      const variables = { id: campaignId || '', limit: PAGE_SIZE, searchText: searchText || '' }
      executeCustomerSegmentQuery({ variables })
    },
    [campaignId, executeCustomerSegmentQuery],
  )

  const handleOnClick = async (option: EcommCustomerSegmentType) => {
    if (!!selectedCustomerSegments.find(o => o.id === option?.id)) {
      setSelectedCustomerSegments(selectedCustomerSegments.filter(o => o.id !== option.id))
    } else {
      setSelectedCustomerSegments([...selectedCustomerSegments, option])
    }
  }

  const handleLoadMore = async (searchText: string) => {
    const variables = { id: campaignId || '', limit: PAGE_SIZE, searchText: searchText || '' }
    fetchMoreCustomerSegments({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          campaign: {
            ...prev.campaign,
            program: {
              ...prev.campaign?.program,
              ecommCustomerSegments: [
                ...(prev.campaign?.program?.ecommCustomerSegments || []),
                ...(fetchMoreResult.campaign?.program?.ecommCustomerSegments || []),
              ],
            },
          },
        } as CampaignProgramEcommCustomerSegmentQuery
      },
    })
  }

  /**
   * Effects
   */
  useEffect(() => {
    setSelectedCustomerSegments(selectedSegments)
  }, [selectedSegments])

  return (
    <MultiSelectSearch
      label="Customer Segments"
      options={customerSegmentData?.campaign?.program?.ecommCustomerSegments || []}
      open={open}
      onCancel={onCancel}
      handleConfirm={onAdd}
      anchorEl={anchorEl}
      loadOptions={loadData}
      loadMoreOptions={handleLoadMore}
      handleOnClick={handleOnClick}
      selectedItems={selectedCustomerSegments}
      optionTemplate={(customerSegment: EcommCustomerSegmentType) => {
        return (
          <>
            <Box display="flex" alignItems="center" pl={2}>
              <Checkbox
                checked={
                  (customerSegment && !!selectedCustomerSegments.find(o => o.id === customerSegment?.id)) || false
                }
              />
              {customerSegment?.name}
            </Box>
            <Divider />
          </>
        )
      }}
    />
  )
}

export default EcommCustomerSegmentPickerMenu
