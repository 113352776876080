import { useReducer, Dispatch } from 'react'
import { BULK_ACTION_LIMIT, BULK_ADD_LABEL_LIMIT } from './constants'

/*
 * A state machine to track the bulk action flow for content.
 * after the user has selected the posts they want to take a bulk action on, this code
 * is used to track the flow once they click a bulk action to take.
 *
 * For export, they click then the machine can be in these states :
 *
   +-------+   +----------+
   | start +-->+ confirm  |
   +-------+   +----------+
       |
       v
   +---------+
   |overLimit|
   +---------+

 *
 * for add/create labels:
 * start ->
 * +-------+     +------------+   +--------+
   | start +-----> pickLabel +-->+confirm |
   +---+---+     +------------+   +--------+
       |
       v
   +---+-----+
   |overLimit|
   +---------+

 * From any state, you can dispatch {type: 'done'} to cancel the flow
 */

type AttemptedActionOverLimitState = { step: 'overLimit'; action: 'export' | 'label' }
type PickLabelState = { step: 'pickLabel' }
type AddLabelConfirmationState = { step: 'confirmation'; action: 'addLabel'; id: number }
type CreateLabelConfirmationState = { step: 'confirmation'; action: 'createLabel'; name: string }
type ExportConfirmationState = { step: 'confirmation'; action: 'export' }
type BulkActionState =
  | AttemptedActionOverLimitState
  | PickLabelState
  | AddLabelConfirmationState
  | CreateLabelConfirmationState
  | ExportConfirmationState
  | null

type BulkActionStateAction =
  | {
      type: 'start'
      action: 'export' | 'label'
      selectedCount: number
    }
  | { type: 'selectedLabel'; id: number }
  | { type: 'createLabel'; name: string }
  | { type: 'done' }

function bulkReducer(_: BulkActionState, action: BulkActionStateAction): BulkActionState {
  switch (action.type) {
    case 'start':
      if (action.action === 'export') {
        if (action.selectedCount > BULK_ACTION_LIMIT) {
          return {
            step: 'overLimit',
            action: 'export',
          }
        } else {
          return {
            step: 'confirmation',
            action: 'export',
          }
        }
      } else {
        if (action.selectedCount > BULK_ADD_LABEL_LIMIT) {
          return {
            step: 'overLimit',
            action: action.action,
          }
        } else {
          return {
            step: 'pickLabel',
          }
        }
      }
    case 'selectedLabel':
      return {
        step: 'confirmation',
        action: 'addLabel',
        id: action.id,
      }
    case 'createLabel':
      return {
        step: 'confirmation',
        action: 'createLabel',
        name: action.name,
      }
    case 'done':
      return null
  }
}

function useBulkActionState(): [BulkActionState, Dispatch<BulkActionStateAction>] {
  return useReducer(bulkReducer, null)
}

export default useBulkActionState
