export const messageTemplateStatusOptions = [
  {
    id: true,
    label: 'Auto On',
  },
  {
    id: false,
    label: 'Auto Off',
  },
] as const

export const EXPORT_LIMIT = 25000
