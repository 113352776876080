import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMentionLabelMutationVariables = Types.Exact<{
  labelId: Types.Scalars['ID'];
  mentionId: Types.Scalars['ID'];
}>;


export type AddMentionLabelMutation = { __typename: 'Mutations', addMentionLabel?: { __typename: 'AddMentionLabel', ok?: boolean | null, label?: { __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string, account?: { __typename: 'AccountType', id: number } | null } | null, mentions?: Array<{ __typename: 'IGMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null } | { __typename: 'TTMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null }> | null } | null };


export const AddMentionLabelDocument = gql`
    mutation AddMentionLabel($labelId: ID!, $mentionId: ID!) {
  addMentionLabel(labelId: $labelId, mentionIds: [$mentionId]) {
    label {
      account {
        id
      }
      id
      isRejectionReason
      name
    }
    mentions {
      id
      labels {
        id
      }
    }
    ok
  }
}
    `;
export type AddMentionLabelMutationFn = Apollo.MutationFunction<AddMentionLabelMutation, AddMentionLabelMutationVariables>;

/**
 * __useAddMentionLabelMutation__
 *
 * To run a mutation, you first call `useAddMentionLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMentionLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMentionLabelMutation, { data, loading, error }] = useAddMentionLabelMutation({
 *   variables: {
 *      labelId: // value for 'labelId'
 *      mentionId: // value for 'mentionId'
 *   },
 * });
 */
export function useAddMentionLabelMutation(baseOptions?: Apollo.MutationHookOptions<AddMentionLabelMutation, AddMentionLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMentionLabelMutation, AddMentionLabelMutationVariables>(AddMentionLabelDocument, options);
      }
export type AddMentionLabelMutationHookResult = ReturnType<typeof useAddMentionLabelMutation>;
export type AddMentionLabelMutationResult = Apollo.MutationResult<AddMentionLabelMutation>;
export type AddMentionLabelMutationOptions = Apollo.BaseMutationOptions<AddMentionLabelMutation, AddMentionLabelMutationVariables>;