import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { CustomerNoteFragmentFragmentDoc } from './customer-note-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerNotesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  socialAccountId: Types.Scalars['ID'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CustomerNotesQuery = { __typename: 'Query', noteCategories?: Array<{ __typename: 'CustomerNoteCategory', id: string, name: string }> | null, socialAccount?: { __typename: 'IGSocialAccount', id: string, customer?: { __typename: 'CustomerType', id: number, email?: string | null, notes?: { __typename: 'PagedCustomerNoteType', total: number, cursor?: string | null, results: Array<{ __typename: 'CustomerNoteType', id: string, createdAt: Date, deletedAt?: Date | null, note: string, author: { __typename: 'UserType', id: number, email: string }, category?: { __typename: 'CustomerNoteCategory', id: string, name: string } | null }> } | null, igUser?: { __typename: 'IGUser', id: string, username: string } | null, ttUser?: { __typename: 'TTUser', id: string, username: string } | null } | null } | { __typename: 'TTSocialAccount', id: string, customer?: { __typename: 'CustomerType', id: number, email?: string | null, notes?: { __typename: 'PagedCustomerNoteType', total: number, cursor?: string | null, results: Array<{ __typename: 'CustomerNoteType', id: string, createdAt: Date, deletedAt?: Date | null, note: string, author: { __typename: 'UserType', id: number, email: string }, category?: { __typename: 'CustomerNoteCategory', id: string, name: string } | null }> } | null, igUser?: { __typename: 'IGUser', id: string, username: string } | null, ttUser?: { __typename: 'TTUser', id: string, username: string } | null } | null } | null };


export const CustomerNotesDocument = gql`
    query CustomerNotes($id: ID!, $socialAccountId: ID!, $cursor: String, $limit: Int) {
  noteCategories {
    id
    name
  }
  socialAccount(id: $socialAccountId) {
    id
    customer(id: $id) {
      id
      email
      notes(cursor: $cursor, limit: $limit) {
        total
        cursor
        results {
          ...CustomerNoteFragment
        }
      }
      igUser {
        id
        username
      }
      ttUser {
        id
        username
      }
    }
  }
}
    ${CustomerNoteFragmentFragmentDoc}`;

/**
 * __useCustomerNotesQuery__
 *
 * To run a query within a React component, call `useCustomerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      socialAccountId: // value for 'socialAccountId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCustomerNotesQuery(baseOptions: Apollo.QueryHookOptions<CustomerNotesQuery, CustomerNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerNotesQuery, CustomerNotesQueryVariables>(CustomerNotesDocument, options);
      }
export function useCustomerNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerNotesQuery, CustomerNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerNotesQuery, CustomerNotesQueryVariables>(CustomerNotesDocument, options);
        }
export type CustomerNotesQueryHookResult = ReturnType<typeof useCustomerNotesQuery>;
export type CustomerNotesLazyQueryHookResult = ReturnType<typeof useCustomerNotesLazyQuery>;
export type CustomerNotesQueryResult = Apollo.QueryResult<CustomerNotesQuery, CustomerNotesQueryVariables>;