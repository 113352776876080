import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import BillingManagement from './billing/BillingManagement'
import BillingCallback from './billing/BillingCallback'
import LabelManagement from './labels/LabelManagement'
import RewardManagement from './rewards/RewardManagement'
import SegmentManagement from './segments/SegmentManagement'
import UserManagement from './users/UserManagement'
import {
  BILLING_MANAGEMENT_ROUTE,
  BILLING_CALLBACK_ROUTE,
  LABEL_MANAGEMENT_ROUTE,
  REWARD_MANAGEMENT_ROUTE,
  SEGMENT_MANAGEMENT_ROUTE,
  SETTINGS_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from './routes'
import withAuthorization from '../withAuthorization'
import useIntercomOnPage from '../hooks/useIntercomOnPage'

const Settings: React.FC = () => {
  useIntercomOnPage('Settings')
  return (
    <Switch>
      <Route path={BILLING_MANAGEMENT_ROUTE.path} component={BillingManagement} exact />
      <Route path={BILLING_CALLBACK_ROUTE.path} component={BillingCallback} />
      <Route path={LABEL_MANAGEMENT_ROUTE.path} component={LabelManagement} />
      <Route path={REWARD_MANAGEMENT_ROUTE.path} component={RewardManagement} />
      <Route path={SEGMENT_MANAGEMENT_ROUTE.path} component={SegmentManagement} />
      <Route path={USER_MANAGEMENT_ROUTE.path} component={UserManagement} />
      <Route render={() => <Redirect to={USER_MANAGEMENT_ROUTE.path} />} />
    </Switch>
  )
}

export default withAuthorization(SETTINGS_ROUTE)(Settings)
