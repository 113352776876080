import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, makeStyles, Paper, Link, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { format, formatDistanceToNow } from 'date-fns'
import Tooltip from '../../components/Tooltip/Tooltip'
import { CampaignActivityQuery } from './operations/campaign-activity.generated'
import { ReactComponent as ClockIcon } from '../../icons/clock.svg'
import { ReactComponent as TrophyIcon } from '../../icons/gradient-trophy-outlined.svg'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { FbUser } from '../../gql-global'
import StoriesAvatarV2 from '../../components/CustomerAvatar/StoriesAvatarV2'
import IntegrationLogo from '../../components/IntegrationLogo'

const useStyles = makeStyles({
  underline: {
    textDecoration: 'underline',
  },
  integrationLogoWrapper: {
    display: 'inline-block',
    paddingLeft: 10,
    height: 20,
  },
  integrationLogo: {
    height: '100%',
    verticalAlign: 'middle',
  },
  challengePrefix: {
    fontSize: 16,
  },
  challengeIcon: {
    width: 20,
    height: 20,
    paddingTop: '4px',
  },
  challenge: {
    background: 'linear-gradient(170.48deg, #7800F9 28.39%, #F528C8 56.52%, #FFDD55 95%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    fontSize: 16,
    fontWeight: 500,
  },
})

type Event = NonNullable<NonNullable<CampaignActivityQuery['campaign']>['events']>['results'][0]

type ConcreteEventType<T extends Event['__typename']> = Extract<Event, { __typename: T }>

interface RewardActivityCardProps {
  loading?: boolean | null
  activity?: ConcreteEventType<'FulfilledTierCustomerEvent'>
  isIGSocialAccount?: boolean
}

function RewardActivityCard({
  activity,
  loading = false,
  isIGSocialAccount,
}: RewardActivityCardProps): React.ReactElement {
  const classes = useStyles()
  const customer = activity?.fulfillment?.customer
  const customerSocialUser = isIGSocialAccount ? customer?.igUser : customer?.ttUser
  const customerId = customer?.id
  const username = customerSocialUser?.username || customer?.email
  const avatarUrl = customerSocialUser?.avatarUrl
  const eventAt = activity?.eventAt

  const rewardName = activity?.fulfillment?.reward?.name
  const challengeName = activity?.fulfillment?.challenge?.name

  const code = activity?.fulfillment?.rewardDiscountCode?.code
  const integrationType = activity?.fulfillment?.reward?.integration?.integrationType
  const socialAccounts = (isIGSocialAccount && customer?.igUser?.storiesIgSocialAccounts) || []

  const fbUsers: FbUser[] = []
  if (socialAccounts.length > 0) {
    socialAccounts.forEach(sa => {
      if (sa.fbUsers && sa.fbUsers.length > 0) {
        fbUsers.push(...sa.fbUsers)
      }
    })
  }

  const linkProps = { to: `${CUSTOMER_ROUTE.path}/${customerId}`, component: RouterLink }

  return (
    <Box mb={5}>
      <Paper>
        <Box display="grid" gridTemplateColumns="4fr 3fr 3fr 3fr" height={120} alignItems="center">
          <Box display="flex">
            <Box m={4} ml={8} p={2}>
              <StoriesAvatarV2
                avatarUrl={avatarUrl}
                loading={loading || false}
                linkProps={customerId ? linkProps : undefined}
              />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="left">
              {!loading && (
                <Typography variant="subtitle1">
                  Reward Sent to {customerId ? <Link {...linkProps}>{username}</Link> : { username }}
                </Typography>
              )}
              {loading && <Skeleton variant="text" width={300} />}
              {!loading && eventAt && (
                <Tooltip title={format(eventAt, 'PPp')} placement="top-start">
                  <Box display="flex" alignItems="center">
                    <ClockIcon height={16} width={16} />
                    <Typography className={classes.underline} variant="body2">
                      {formatDistanceToNow(eventAt, { addSuffix: true })}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              {loading && <Skeleton variant="text" width={300} />}
            </Box>
          </Box>
          <Box>
            {!loading && (
              <div>
                <Typography variant="body2" color="secondary">
                  Reward Name
                </Typography>
                <Typography variant="body1">{rewardName}</Typography>
                {!!challengeName && (
                  <Box display="flex">
                    <Typography className={classes.challengePrefix}>for&nbsp;</Typography>
                    <TrophyIcon className={classes.challengeIcon} />
                    <Typography className={classes.challenge}>&nbsp;{challengeName}</Typography>
                  </Box>
                )}
              </div>
            )}
            {loading && (
              <div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </div>
            )}
          </Box>
          <Box mr={4}>
            {!loading && (
              <div>
                <Typography variant="body2" color="secondary">
                  Reward Code
                </Typography>
                <Typography variant="body1">{code || 'n/a'}</Typography>
              </div>
            )}
            {loading && (
              <Box mr={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Box>
            )}
          </Box>
          <Box>
            {!loading && (
              <Typography variant="body2" color="secondary">
                Sent With
              </Typography>
            )}
            {!loading && !!integrationType && (
              <Box className={classes.integrationLogoWrapper}>
                <IntegrationLogo integrationType={integrationType} className={classes.integrationLogo} />
              </Box>
            )}
            {!loading && !integrationType && <Typography variant="body1">n/a</Typography>}
            {loading && (
              <Box mr={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default RewardActivityCard
