import React from 'react'
import { Paper, Box, Button, makeStyles, Theme, createStyles } from '@material-ui/core'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(9),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
)

interface AddCampaignCardProps {
  title: string
  text: string
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  callToActionText: string
  onCallToActionClicked(): void
}

function AddCampaignCard({
  title,
  text,
  image: ImageComponent,
  callToActionText,
  onCallToActionClicked,
}: AddCampaignCardProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Box mb={4} fontSize="1.125rem" lineHeight={1.5} fontWeight="fontWeightBold">
        {title}
      </Box>
      <Box mb={6} display="flex" justifyContent="center">
        <ImageComponent height={180} />
      </Box>
      <Box mb={4} flexGrow={1}>
        {text}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button onClick={onCallToActionClicked} variant="outlined" startIcon={<PlusIcon width={16} />} color="primary">
          {callToActionText}
        </Button>
      </Box>
    </Paper>
  )
}

export default AddCampaignCard
