import { QueryParamConfig, ArrayParam, StringParam } from 'use-query-params'
import { isEnumValue, isNonNull } from '../types/utility'

/*
 * Helper to create a set url param type (use-url-param lib)
 * The set will be encoded as ?param_name=value1&param_name=value2
 * decoded from that to a set
 * where each value is unique, that is the only diff from a ArrayParam
 * decoder: func to convert url param string items to a set item
 * ie: to make a set param of number (already defined as NumberSetParam) --
 * `createSetParam((str: string) => !!str ? Number(str) : undefined)`
 */
export function createSetParam<T extends string | { toString(): string }>(
  decoder: (str: string) => T | undefined,
): QueryParamConfig<Set<T> | null | undefined, Set<T> | undefined> {
  return {
    decode(value: string | string[]): Set<T> | undefined {
      const arrayDecoded = ArrayParam.decode(value)
      if (!arrayDecoded) {
        return undefined
      }

      return new Set(arrayDecoded.map(decoder).filter(isNonNull))
    },
    encode(value: Set<T> | null | undefined): string | string[] | undefined {
      if (!value?.size) {
        return undefined
      }

      return ArrayParam.encode(Array.from(value).map(v => v.toString()))
    },
  }
}

/*
 * Helper to create a url param (use-url-param) that filters out invalid options
 * This is mainly used to get a enum or string union type url param
 */
export function createFilteredParam<T extends string>(
  filter: (str: string) => T | undefined,
): QueryParamConfig<T | null | undefined, T | undefined> {
  return {
    decode(value: string | string[]) {
      const strValue = StringParam.decode(value)
      if (!strValue) {
        return undefined
      }

      return filter(strValue)
    },
    encode: StringParam.encode,
  }
}

export function createEnumSetParam<T extends string, K extends string>(
  enumType: { [key in K]: T },
): QueryParamConfig<Set<T> | null | undefined, Set<T> | undefined> {
  return createSetParam((str: string) => (isEnumValue(enumType, str) ? str : undefined))
}

export function createEnumParam<T extends string, K extends string>(
  enumType: { [key in K]: T },
): QueryParamConfig<T | null | undefined, T | undefined> {
  return createFilteredParam((str: string) => (isEnumValue(enumType, str) ? str : undefined))
}

export const NumberSetParam = createSetParam(str => (!!str ? Number(str) : undefined))
export const SetParam = createSetParam(s => s)
