import React, { useState, useRef, forwardRef } from 'react'
import { LabelsOption } from './types'
import { LabelsFilter } from '../../../gql-global'
import { BulkLabelMenuContents } from '../../LabelMenu/BulkLabelMenu'
import MenuHeader from '../../MenuHeader'
import FilterChip, { FilterChipText, FilterChipProps } from './FilterChip'
import { makeStyles, Menu } from '@material-ui/core'

export type Ref = HTMLDivElement

interface BulkLabelsFilterMenuProps {
  option: LabelsOption
  value: LabelsFilter
  onSelectValue(newValue: LabelsFilter): void
  onBack?: () => void
  onDelete(): void
  filterLabelsAllIndex?: number
  exclude?: boolean
  includeField?: 'all' | 'any'
  selectedAllByDefault?: boolean
}

interface BulkLabelsFilterChipProps {
  option: LabelsOption
  value: LabelsFilter
  exclude?: boolean
  onDelete(): void
  onSelectValue(newValue: LabelsFilter): void
  filterLabelsAllIndex?: number
  chipProps?: FilterChipProps
  includeField?: 'all' | 'any'
}

const useStyles = makeStyles({
  menuPaper: {
    minWidth: 272,
  },
})

function handleApplyLabelFilter(
  currentValue: LabelsFilter,
  labelIds: Set<number | string>,
  onSelectValue: (newValue: LabelsFilter) => void,
  onDelete: () => void,
  exclude: boolean,
  filterLabelsAllIndex?: number,
  includeField?: 'all' | 'any',
): void {
  const labelIdsStringArray = Array.from(labelIds).map(id => id.toString())
  const newLabelsField = exclude ? 'none' : includeField ?? 'all'
  const newValue = { ...currentValue }
  if (newLabelsField === 'all') {
    newValue['all'] = newValue['all'] ?? []
    if (labelIdsStringArray.length > 0) {
      newValue['all'][filterLabelsAllIndex || 0] = labelIdsStringArray
    } else {
      newValue['all'] = newValue['all'].filter((_, removeIndex) => removeIndex !== filterLabelsAllIndex)
    }
    // if we end up with no inner array that isn't empty, we just remove the 'all' filterlabelIdsStringArray
    if (!newValue['all'].some(v => v)) newValue['all'] = undefined
  } else {
    newValue[newLabelsField] = labelIdsStringArray.length ? labelIdsStringArray : undefined
  }

  // if no field has any values just remove the filter, else apply the new value
  if (!newValue.none?.length && !newValue.all?.length && !newValue.any?.length) {
    onDelete()
  } else {
    onSelectValue(newValue)
  }
}

export function BulkLabelsFilterChip({
  option,
  value,
  exclude = false,
  onDelete,
  onSelectValue,
  filterLabelsAllIndex,
  includeField,
  chipProps = {},
}: BulkLabelsFilterChipProps): React.ReactElement {
  const [labelMenuOpen, setLabelMenuOpen] = useState(false)
  const classes = useStyles()
  const chipRef = useRef<HTMLDivElement>(null)

  const closeMenu = (): void => {
    setLabelMenuOpen(false)
  }

  const handleApply = (labelIds: Set<string>): void => {
    handleApplyLabelFilter(value, labelIds, onSelectValue, onDelete, exclude, filterLabelsAllIndex, includeField)
    closeMenu()
  }

  const clearValues = (): void => {
    handleApply(new Set<string>())
  }

  const handleDelete = (): void => {
    clearValues()
    closeMenu()
  }

  let currentLabelGroup: string[] = []
  if (exclude) {
    currentLabelGroup = value.none || []
  } else if (includeField === 'any') {
    currentLabelGroup = value?.any || []
  } else if (value.all) {
    currentLabelGroup = value.all[filterLabelsAllIndex || 0] || []
  }
  let text: string
  if (currentLabelGroup.length > 2) {
    text = `${currentLabelGroup.length} labels selected`
  } else {
    const names = option.selectionOptions.filter(l => currentLabelGroup.includes(l.id.toString())).map(l => l.name)
    text = names.join(', ')
  }

  const selectedLabelIds = new Set(currentLabelGroup)
  const options = option.selectionOptions.map(l => ({
    ...l,
    id: l.id.toString(),
  }))

  return (
    <>
      <FilterChip onClick={() => setLabelMenuOpen(true)} ref={chipRef} onDelete={handleDelete} {...chipProps}>
        <>
          <FilterChipText bold text={option.label} />
          <FilterChipText text={text} />
        </>
      </FilterChip>
      <Menu
        open={labelMenuOpen}
        anchorEl={chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={closeMenu}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuHeader title={option.label} />
        <BulkLabelMenuContents
          editable={false}
          onApply={handleApply}
          onClose={closeMenu}
          entity={option.entity}
          labels={options}
          selectedLabelIds={selectedLabelIds}
          seeAllLink={option.seeAllLink}
          customLabelRenderer={option.customLabelRenderer}
        />
      </Menu>
    </>
  )
}

const BulkLabelsFilterMenu = forwardRef<Ref, BulkLabelsFilterMenuProps>((props, ref) => {
  const {
    option,
    value,
    exclude = false,
    onSelectValue,
    onBack,
    filterLabelsAllIndex,
    onDelete,
    includeField,
    selectedAllByDefault = false,
  } = props

  function handleApply(labelIds: Set<number>): void {
    handleApplyLabelFilter(value, labelIds, onSelectValue, onDelete, exclude, filterLabelsAllIndex, includeField)
  }

  let currentLabelGroup: string[] = []
  if (exclude) {
    currentLabelGroup = value?.none || []
  } else if (includeField === 'any') {
    currentLabelGroup = value?.any || []
  } else if (value?.all) {
    currentLabelGroup = value.all[filterLabelsAllIndex || 0] || []
  }

  const selectedLabelIds = new Set(currentLabelGroup)

  return (
    <div>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <BulkLabelMenuContents
        editable={false}
        onApply={handleApply}
        entity={option.entity}
        labels={option.selectionOptions}
        onClose={onBack}
        selectedLabelIds={selectedLabelIds}
        seeAllLink={option.seeAllLink}
        selectedAllByDefault={selectedAllByDefault}
        customLabelRenderer={option.customLabelRenderer}
      />
    </div>
  )
})
BulkLabelsFilterMenu.displayName = 'BulkLabelsFilterMenu'

export default BulkLabelsFilterMenu
