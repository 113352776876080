import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetCampaignCommissionsEnabledMutationVariables = Types.Exact<{
  value: Types.Scalars['Boolean'];
  programId: Types.Scalars['ID'];
}>;


export type SetCampaignCommissionsEnabledMutation = { __typename: 'Mutations', setCampaignCommissionsEnabled?: { __typename: 'SetCampaignCommissionsEnabled', ok: boolean } | null };


export const SetCampaignCommissionsEnabledDocument = gql`
    mutation SetCampaignCommissionsEnabled($value: Boolean!, $programId: ID!) {
  setCampaignCommissionsEnabled(value: $value, programId: $programId) {
    ok
  }
}
    `;
export type SetCampaignCommissionsEnabledMutationFn = Apollo.MutationFunction<SetCampaignCommissionsEnabledMutation, SetCampaignCommissionsEnabledMutationVariables>;

/**
 * __useSetCampaignCommissionsEnabledMutation__
 *
 * To run a mutation, you first call `useSetCampaignCommissionsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCampaignCommissionsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCampaignCommissionsEnabledMutation, { data, loading, error }] = useSetCampaignCommissionsEnabledMutation({
 *   variables: {
 *      value: // value for 'value'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useSetCampaignCommissionsEnabledMutation(baseOptions?: Apollo.MutationHookOptions<SetCampaignCommissionsEnabledMutation, SetCampaignCommissionsEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCampaignCommissionsEnabledMutation, SetCampaignCommissionsEnabledMutationVariables>(SetCampaignCommissionsEnabledDocument, options);
      }
export type SetCampaignCommissionsEnabledMutationHookResult = ReturnType<typeof useSetCampaignCommissionsEnabledMutation>;
export type SetCampaignCommissionsEnabledMutationResult = Apollo.MutationResult<SetCampaignCommissionsEnabledMutation>;
export type SetCampaignCommissionsEnabledMutationOptions = Apollo.BaseMutationOptions<SetCampaignCommissionsEnabledMutation, SetCampaignCommissionsEnabledMutationVariables>;