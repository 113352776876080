import React, { FC } from 'react'
import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core'
import BrandAccountFilter, { SAFragment } from './BrandAccountFilter'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'
import { ReactComponent as TrashIcon } from '../../../icons/trash_can.svg'
import { primary } from '../../../loudcrowd-theme'
import PostCountFilter from './PostCountFilter'
import RewardFilter from './RewardFilter'
import { MINIMUM_ALLOWED_REWARD_TIERS } from './constants'
import { PostCountCriteriaType, useProgramActivityBuilder } from './ProgramActivityBuilderProvider'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(() =>
  createStyles({
    overline: {
      fontWeight: 700,
      color: primary[700],
      fontSize: 14,
      marginTop: 5,
    },
    addRewardButton: {
      color: primary[500],
      textAlign: 'left',
      display: 'inline-flex',
      alignSelf: 'flex-start',
      margin: 15,
    },
    trashIcon: {
      float: 'right',
      opacity: 0.65,
      marginTop: 16,
      marginRight: 10,
      cursor: 'pointer',
      '&:hover': {
        opacity: 1,
      },
    },
  }),
)

interface MultiTierMonthlyBuilderProps {
  socialAccounts: SAFragment[]
}

const MultiTierMonthlyBuilder: FC<MultiTierMonthlyBuilderProps> = ({ socialAccounts }) => {
  const classes = useStyles()
  const {
    values,
    setSocialAccountIds,
    setMultiTierCriteria,
    handleAddTierCriteria,
    handleRemoveTierCriteria,
    includesTikTok,
    includesInstagram,
    isReorderingTiers,
  } = useProgramActivityBuilder()

  const handleOnRewardChange = (reward: Partial<{ id: number; name?: string | null }>, key: number) => {
    const newMultiTierCriteria = values.multiTierCriteria?.map((criteria, index) =>
      index === key ? { ...criteria, reward } : criteria,
    )
    setMultiTierCriteria(newMultiTierCriteria)
  }

  const handleOnPostCountCriteriaChange = (postCountCriteria: PostCountCriteriaType, key: number) => {
    const newMultiTierCriteria = values.multiTierCriteria?.map((criteria, index) =>
      index === key ? { ...criteria, postCountCriteria } : criteria,
    )
    setMultiTierCriteria(newMultiTierCriteria)
  }

  return (
    <Box display="flex" flexDirection="column" maxHeight={500} overflow="auto">
      <Typography variant="overline" className={classes.overline}>
        ADD CRITERIA
      </Typography>
      <Box fontSize={24} fontWeight={400}>
        <BrandAccountFilter
          preButtonLabel="When a member posts content and"
          socialAccounts={socialAccounts || []}
          selectedSocialAccountIds={values.socialAccountIds || []}
          onSelectedSocialAccountIds={setSocialAccountIds}
        />
      </Box>
      <Typography variant="overline" className={classes.overline}>
        CREATE TIERS
      </Typography>
      <Box fontSize={24} fontWeight={400}>
        A member can earn ONE of the following rewards in a calendar month:
      </Box>
      {values.multiTierCriteria?.map((criteria, key) =>
        isReorderingTiers ? (
          <Box fontSize={24} fontWeight={400} position="relative" pl={7} key={key}>
            <Skeleton width={50} />
            <Box fontSize={24} fontWeight={400} position="relative" mt={-2}>
              <Skeleton width={100} />
            </Box>
          </Box>
        ) : (
          <Box fontSize={24} fontWeight={400} position="relative" pl={7} key={key}>
            <Typography variant="overline" className={classes.overline}>
              {key > 1 && !criteria.postCountCriteria?.totalPosts ? 'New Tier' : `Tier ${key + 1}`}
            </Typography>
            <Box fontSize={24} fontWeight={400} position="relative" mt={-2}>
              <RewardFilter reward={criteria.reward} onRewardChange={reward => handleOnRewardChange(reward, key)} /> for
              <PostCountFilter
                postCountCriteria={criteria.postCountCriteria}
                onPostCountCriteriaChange={value => handleOnPostCountCriteriaChange(value, key)}
                isInstagramIncluded={includesInstagram}
                isTiktokIncluded={includesTikTok}
              />
              {key + 1 > MINIMUM_ALLOWED_REWARD_TIERS && (
                <TrashIcon width={28} className={classes.trashIcon} onClick={() => handleRemoveTierCriteria(key)} />
              )}
            </Box>
          </Box>
        ),
      )}
      <Button
        variant="text"
        className={classes.addRewardButton}
        startIcon={<PlusIcon width={16} />}
        onClick={handleAddTierCriteria}
      >
        Add another reward tier
      </Button>
    </Box>
  )
}

export default MultiTierMonthlyBuilder
