import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddToProgramMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  customerId: Types.Scalars['ID'];
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddToProgramMutation = { __typename: 'Mutations', addParticipantCustomer?: { __typename: 'AddParticipantCustomer', ok?: boolean | null, participant?: { __typename: 'ProgramParticipantType', id: number } | null } | null };


export const AddToProgramDocument = gql`
    mutation AddToProgram($campaignId: ID!, $customerId: ID!, $email: String) {
  addParticipantCustomer(
    campaignId: $campaignId
    customerId: $customerId
    email: $email
  ) {
    ok
    participant {
      id
    }
  }
}
    `;
export type AddToProgramMutationFn = Apollo.MutationFunction<AddToProgramMutation, AddToProgramMutationVariables>;

/**
 * __useAddToProgramMutation__
 *
 * To run a mutation, you first call `useAddToProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToProgramMutation, { data, loading, error }] = useAddToProgramMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      customerId: // value for 'customerId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddToProgramMutation(baseOptions?: Apollo.MutationHookOptions<AddToProgramMutation, AddToProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToProgramMutation, AddToProgramMutationVariables>(AddToProgramDocument, options);
      }
export type AddToProgramMutationHookResult = ReturnType<typeof useAddToProgramMutation>;
export type AddToProgramMutationResult = Apollo.MutationResult<AddToProgramMutation>;
export type AddToProgramMutationOptions = Apollo.BaseMutationOptions<AddToProgramMutation, AddToProgramMutationVariables>;