import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CampaignList from './CampaignList'
import CampaignDetail from './campaign-detail/CampaignDetail'
import { CAMPAIGN_ROUTE, CAMPAIGN_DETAIL_ROUTE } from './routes'
import withAuthorization from '../withAuthorization'
import useTitle from '../utils/use-title'
import useIntercomOnPage from '../hooks/useIntercomOnPage'

function Campaigns(): React.ReactElement {
  useTitle('Programs')
  useIntercomOnPage('Campaigns')
  return (
    <Switch>
      <Route component={CampaignList} path={CAMPAIGN_ROUTE.path} exact />
      <Route component={CampaignDetail} path={CAMPAIGN_DETAIL_ROUTE.path} />
    </Switch>
  )
}

export default withAuthorization(CAMPAIGN_ROUTE)(Campaigns)
