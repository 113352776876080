import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RewardBatchJobFragmentFragment = { __typename: 'RewardBatchJobType', id: number, status: Types.RewardBatchJobStatus, sentCount?: number | null, failedCount?: number | null, totalCount?: number | null, startedAt?: Date | null, completedAt?: Date | null, failedAt?: Date | null, isRetry?: boolean | null, retryAttempted?: boolean | null };

export type PagedRewardBatchJobsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  rewardId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PagedRewardBatchJobsQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, reward?: { __typename: 'RewardType', id: number, rewardBatchJobs?: { __typename: 'PagedRewardBatchJobType', cursor?: string | null, results: Array<{ __typename: 'RewardBatchJobType', id: number, status: Types.RewardBatchJobStatus, sentCount?: number | null, failedCount?: number | null, totalCount?: number | null, startedAt?: Date | null, completedAt?: Date | null, failedAt?: Date | null, isRetry?: boolean | null, retryAttempted?: boolean | null }> } | null } | null } | null };

export const RewardBatchJobFragmentFragmentDoc = gql`
    fragment RewardBatchJobFragment on RewardBatchJobType {
  id
  status
  sentCount
  failedCount
  totalCount
  startedAt
  completedAt
  failedAt
  isRetry
  retryAttempted
}
    `;
export const PagedRewardBatchJobsDocument = gql`
    query PagedRewardBatchJobs($accountId: ID!, $rewardId: ID!, $limit: Int, $cursor: String) {
  account(id: $accountId) {
    id
    reward(id: $rewardId) {
      id
      rewardBatchJobs(limit: $limit, cursor: $cursor) {
        results {
          ...RewardBatchJobFragment
        }
        cursor
      }
    }
  }
}
    ${RewardBatchJobFragmentFragmentDoc}`;

/**
 * __usePagedRewardBatchJobsQuery__
 *
 * To run a query within a React component, call `usePagedRewardBatchJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagedRewardBatchJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagedRewardBatchJobsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      rewardId: // value for 'rewardId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePagedRewardBatchJobsQuery(baseOptions: Apollo.QueryHookOptions<PagedRewardBatchJobsQuery, PagedRewardBatchJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PagedRewardBatchJobsQuery, PagedRewardBatchJobsQueryVariables>(PagedRewardBatchJobsDocument, options);
      }
export function usePagedRewardBatchJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagedRewardBatchJobsQuery, PagedRewardBatchJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PagedRewardBatchJobsQuery, PagedRewardBatchJobsQueryVariables>(PagedRewardBatchJobsDocument, options);
        }
export type PagedRewardBatchJobsQueryHookResult = ReturnType<typeof usePagedRewardBatchJobsQuery>;
export type PagedRewardBatchJobsLazyQueryHookResult = ReturnType<typeof usePagedRewardBatchJobsLazyQuery>;
export type PagedRewardBatchJobsQueryResult = Apollo.QueryResult<PagedRewardBatchJobsQuery, PagedRewardBatchJobsQueryVariables>;