import React, { ReactElement, useEffect, useState } from 'react'
import { Button, createStyles, makeStyles, Theme, Card, Collapse, Typography } from '@material-ui/core'
import { Message } from '../../gql-global'
import { format, differenceInMinutes } from 'date-fns'

const useStyles = makeStyles(({ palette: { background, primary } }: Theme) =>
  createStyles({
    cardWrapper: {
      margin: '5px 0 15px',
      boxShadow: 'none',
      padding: '15px 15px',
      backgroundColor: background.default,
      borderRadius: 18,
      fontSize: 16,
    },
    button: {
      padding: 0,
      height: 'auto',
      color: primary.main,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
)

interface MessageCollapsibleCardProps {
  message: Message
}

function truncate(text: string, length: number): string {
  if (text.length <= length) {
    return text
  }
  return text.slice(0, length - 3) + '...'
}

const INITIAL_LENGTH = 110
const MessageCollapsibleCard = ({ message }: MessageCollapsibleCardProps): ReactElement => {
  const classes = useStyles()

  const needsCollapsedButton = message.messageText.length > INITIAL_LENGTH
  const messageDate =
    differenceInMinutes(new Date(), message.createdAt) > 5 ? `at ${format(message.createdAt, 'E dd, p')}` : 'Just now'

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  useEffect(() => {
    if (message.messageText.length > INITIAL_LENGTH) {
      setIsCollapsed(false)
    }
  }, [message.messageText])

  return (
    <>
      <Typography variant="caption" display="block" color="secondary">
        {!!message.sentBy ? 'Manual' : 'Auto'} Story Reply {messageDate}
      </Typography>
      {message.fulfillment?.reward?.id && (
        <Typography variant="caption" display="block" color="secondary">
          Reward Name: {message.fulfillment?.reward?.name}
        </Typography>
      )}
      <Card className={classes.cardWrapper}>
        <Collapse in={isCollapsed} collapsedHeight={needsCollapsedButton ? 80 : undefined}>
          {isCollapsed ? message.messageText : truncate(message.messageText, INITIAL_LENGTH)}
          {needsCollapsedButton && (
            <Button onClick={() => setIsCollapsed(!isCollapsed)} className={classes.button}>
              View {isCollapsed ? 'less' : 'more'}
            </Button>
          )}
        </Collapse>
      </Card>
    </>
  )
}

export default MessageCollapsibleCard
