import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQueryParams, StringParam } from 'use-query-params'
import { INTEGRATIONS_ROUTE } from './routes'
import { useToast } from '../components/Alert/ToastProvider'
import { IntegrationType } from '../gql-global'
import { useCreateIntegrationMutation } from './operations/create-integration.generated'

function TremendousCallback(): React.ReactElement | null {
  const [authDone, setAuthDone] = useState(false)
  const { showToast } = useToast()

  const [tremendousQuery] = useQueryParams({
    code: StringParam,
  })

  const [createIntegration] = useCreateIntegrationMutation()

  useEffect(() => {
    if (tremendousQuery.code) {
      createIntegration({
        variables: {
          integrationType: IntegrationType.Tremendous,
          key: tremendousQuery.code,
          scope: 'default team_management',
        },
      })
        .then(() => {
          showToast({
            title: 'Success: Tremendous API Key Added',
            message: `Added Tremendous API Key`,
            severity: 'success',
            autoHideDuration: 5000,
          })
        })
        .catch(() => {
          showToast({
            title: 'Error Adding Tremendous API Key',
            message: 'Something went wrong when adding this API Key, please try again.',
          })
        })
        .finally(() => {
          setAuthDone(true)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tremendousQuery.code])

  return authDone ? <Redirect to={INTEGRATIONS_ROUTE.path} push={false} /> : null
}

export default TremendousCallback
