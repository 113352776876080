import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { SocialAccountHashtagFragmentFragmentDoc } from './social-account-hashtag-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSocialAccountHashtagMutationVariables = Types.Exact<{
  socialAccountHashtagId: Types.Scalars['ID'];
  active: Types.Scalars['Boolean'];
}>;


export type UpdateSocialAccountHashtagMutation = { __typename: 'Mutations', updateTikTokSocialAccountHashtag?: { __typename: 'UpdateTikTokSocialAccountHashtag', ok: boolean, trackedHashtag?: { __typename: 'SocialAccountHashtagType', id: string, hashtag?: string | null, hashtagId?: string | null, active?: boolean | null, createdAt?: Date | null, updatedAt?: Date | null } | null } | null };


export const UpdateSocialAccountHashtagDocument = gql`
    mutation UpdateSocialAccountHashtag($socialAccountHashtagId: ID!, $active: Boolean!) {
  updateTikTokSocialAccountHashtag(
    socialAccountHashtagId: $socialAccountHashtagId
    active: $active
  ) {
    ok
    trackedHashtag {
      ...SocialAccountHashtagFragment
    }
  }
}
    ${SocialAccountHashtagFragmentFragmentDoc}`;
export type UpdateSocialAccountHashtagMutationFn = Apollo.MutationFunction<UpdateSocialAccountHashtagMutation, UpdateSocialAccountHashtagMutationVariables>;

/**
 * __useUpdateSocialAccountHashtagMutation__
 *
 * To run a mutation, you first call `useUpdateSocialAccountHashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialAccountHashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialAccountHashtagMutation, { data, loading, error }] = useUpdateSocialAccountHashtagMutation({
 *   variables: {
 *      socialAccountHashtagId: // value for 'socialAccountHashtagId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateSocialAccountHashtagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSocialAccountHashtagMutation, UpdateSocialAccountHashtagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSocialAccountHashtagMutation, UpdateSocialAccountHashtagMutationVariables>(UpdateSocialAccountHashtagDocument, options);
      }
export type UpdateSocialAccountHashtagMutationHookResult = ReturnType<typeof useUpdateSocialAccountHashtagMutation>;
export type UpdateSocialAccountHashtagMutationResult = Apollo.MutationResult<UpdateSocialAccountHashtagMutation>;
export type UpdateSocialAccountHashtagMutationOptions = Apollo.BaseMutationOptions<UpdateSocialAccountHashtagMutation, UpdateSocialAccountHashtagMutationVariables>;