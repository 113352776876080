import React, { useState, useRef } from 'react'
import { ChipsOption } from './types'
import { LabelsFilter } from '../../../gql-global'
import { AccountLabel } from '../../LabelMenu/LabelMenu'
import MenuHeader from '../../MenuHeader'
import FilterChip, { FilterChipText } from './FilterChip'
import { makeStyles, Menu, FormControl, Box, Typography, Button, styled } from '@material-ui/core'
import { secondary } from '../../../loudcrowd-theme'
import { BulkLabelMenuContents } from '../../LabelMenu/BulkLabelMenu'
import { ReactComponent as Cross } from '../../../icons/cross.svg'
import { primary } from '../../../loudcrowd-theme'

export type Ref = HTMLDivElement

const StyledChip = styled(FilterChip)(() => ({
  '& .MuiChip-deleteIconSmall': {
    width: 8,
    height: 8,
  },
  '& .MuiChip-icon': {
    color: secondary[600],
  },
}))

interface BulkLChipsFilterMenuProps {
  option: ChipsOption
  value: LabelsFilter
  onSelectValue(newValue: LabelsFilter): void
  onBack(): void
  onClose(): void
  editable?: boolean
  onDelete(): void
}

interface BulkChipsFilterChipProps {
  option: ChipsOption
  value: LabelsFilter
  onDelete: () => void | null
  onSelectValue(newValue: LabelsFilter): void
  editable?: boolean
}

const useStyles = makeStyles({
  menuPaper: {
    minWidth: 272,
  },
  menuContainer: {
    maxWidth: 334,
    padding: 16,
  },
  chipsContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: 0.5,
    maxHeight: 72,
    overflowY: 'scroll',
    height: 72,
    width: '100%',
    border: `1px solid ${secondary[400]}`,
    pointer: 'cursor',
  },
  icon: {
    height: 8,
    width: 8,
  },
  chip: {
    margin: '10px 10px 0 0',
    backgroundColor: secondary[300],
  },
  formControl: {
    margin: '10px 0 0 0',
  },
  applyButton: {
    margin: '16px 16px 0 0',
  },
  inlineButton: {
    color: primary[500],
    fontSize: 20,
    fontWeight: 400,
    textDecoration: 'underline',
    padding: 0,
    marginBottom: 4,
    minWidth: 0,
    textAlign: 'left',
    height: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
})

type BulkChipMenuContentProps = {
  labels: readonly AccountLabel<string | number>[]
  includeLabelsIds?: Set<string>
  excludeLabelsIds?: Set<string>
  editable: boolean
  onApply(include: Set<string>, exclude: Set<string>): void
  onClear(): void
  onClose: () => void
  option: ChipsOption
}

const emptySet = new Set<string>()

function BulkChipsMenuContent(props: BulkChipMenuContentProps): React.ReactElement {
  const classes = useStyles()
  const {
    option,
    onClear,
    labels,
    onApply,
    editable = true,
    includeLabelsIds = emptySet,
    excludeLabelsIds = emptySet,
  } = props

  const [editingIncludeLabelIds, setEditingIncludeLabelIds] = useState(includeLabelsIds)
  const [editingExcludeLabelIds, setEditingExcludeLabelIds] = useState(excludeLabelsIds)

  const includeChipsRef = useRef<HTMLDivElement | null>(null)
  const excludeChipsRef = useRef<HTMLDivElement | null>(null)

  const [includeLabelsIsOpen, setIncludesLabelsIsOpen] = useState<boolean>(false)
  const [excludeLabelsIsOpen, setExcludesLabelsIsOpen] = useState<boolean>(false)

  const includeValues = labels.filter(label => editingIncludeLabelIds.has(label.id.toString()))
  const excludeValues = labels.filter(label => editingExcludeLabelIds.has(label.id.toString()))
  const includeValuesIds = new Set(includeValues.map(label => label.id))
  const excludeValuesIds = new Set(excludeValues.map(label => label.id))
  const includeAvailableLabels = labels.filter(label => !editingExcludeLabelIds.has(label.id.toString()))
  const excludeAvailableLabels = labels.filter(label => !editingIncludeLabelIds.has(label.id.toString()))
  const includeTitle = option.isContainer
    ? `Customer is in one of these ${option.label.toLowerCase()}`
    : `${option.label} has one of these keywords `
  const excludeTitle = option.isContainer
    ? `And is not in any of these ${option.label.toLowerCase()}`
    : "And doesn't have any of these keywords"

  function handleDeleteLabel(id: string, exclude: boolean): void {
    const setFunc = exclude ? setEditingExcludeLabelIds : setEditingIncludeLabelIds
    setFunc(prev => {
      const cop = new Set(prev)
      cop.delete(id)
      return cop
    })
  }

  function handleClickApply(): void {
    onApply(editingIncludeLabelIds, editingExcludeLabelIds)
  }

  return (
    <Box display="flex" flexDirection="column" className={classes.menuContainer}>
      <FormControl>
        <Typography variant="body2">{includeTitle}</Typography>
        <div
          className={classes.chipsContainer}
          ref={includeChipsRef}
          onClick={editable ? () => setIncludesLabelsIsOpen(true) : undefined}
        >
          {includeValues.map(o => (
            <StyledChip
              key={o.id}
              label={o.name}
              variant="outlined"
              size="small"
              className={classes.chip}
              onDelete={
                editable
                  ? () => {
                      handleDeleteLabel(o.id.toString(), false)
                    }
                  : undefined
              }
            />
          ))}
        </div>
        <Menu
          open={includeLabelsIsOpen}
          anchorEl={includeChipsRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={() => setIncludesLabelsIsOpen(false)}
          classes={{ paper: classes.menuPaper }}
        >
          <MenuHeader title={option.label} onClickBack={() => setIncludesLabelsIsOpen(false)} />
          <BulkLabelMenuContents
            editable={false}
            onApply={labelIds => {
              setIncludesLabelsIsOpen(false)
              setEditingIncludeLabelIds(new Set(Array.from(labelIds).map(id => id.toString())))
            }}
            onClose={() => setIncludesLabelsIsOpen(false)}
            entity={option.entity}
            placeholder={`Search ${option.entity}s`}
            labels={includeAvailableLabels}
            selectedLabelIds={includeValuesIds}
          />
        </Menu>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Typography variant="body2">{excludeTitle}</Typography>
        <div
          className={classes.chipsContainer}
          ref={excludeChipsRef}
          onClick={editable ? () => setExcludesLabelsIsOpen(true) : undefined}
        >
          {excludeValues.map(o => (
            <StyledChip
              key={o.id}
              label={o.name}
              variant="outlined"
              size="small"
              className={classes.chip}
              deleteIcon={<Cross width={4} height={4} color={secondary[600]} />}
              onDelete={
                editable
                  ? () => {
                      handleDeleteLabel(o.id.toString(), true)
                    }
                  : undefined
              }
            />
          ))}
        </div>
        <Menu
          open={excludeLabelsIsOpen}
          anchorEl={excludeChipsRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={() => setExcludesLabelsIsOpen(false)}
          classes={{ paper: classes.menuPaper }}
        >
          <MenuHeader title={option.label} onClickBack={() => setExcludesLabelsIsOpen(false)} />
          <BulkLabelMenuContents
            editable={false}
            onApply={labelIds => {
              setExcludesLabelsIsOpen(false)
              setEditingExcludeLabelIds(new Set(Array.from(labelIds).map(id => id.toString())))
            }}
            onClose={() => setIncludesLabelsIsOpen(false)}
            placeholder={`Search ${option.entity}s`}
            entity={option.entity}
            labels={excludeAvailableLabels}
            selectedLabelIds={excludeValuesIds}
          />
        </Menu>
      </FormControl>
      {editable && (
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" className={classes.applyButton} onClick={onClear}>
            Clear Filter
          </Button>
          <Button color="primary" variant="contained" className={classes.applyButton} onClick={handleClickApply}>
            Apply
          </Button>
        </Box>
      )}
    </Box>
  )
}

export function BulkChipsFilterChip({
  option,
  value,
  onDelete,
  onSelectValue,
  editable = true,
}: BulkChipsFilterChipProps): React.ReactElement {
  const [labelMenuOpen, setLabelMenuOpen] = useState(false)
  const classes = useStyles()
  const chipRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const includeLabels = new Set(value?.any ? value.any : [])
  const excludeLabels = new Set(value?.none ? value.none : [])

  function handleApply(include: Set<string>, exclude: Set<string>): void {
    setLabelMenuOpen(false)
    onSelectValue({
      ...(value ?? {}),
      any: Array.from(include),
      none: Array.from(exclude),
    })
  }

  const onMenuClosed = (): void => {
    setLabelMenuOpen(false)
  }

  let includeText = ''
  let excludeText = ''
  const andText = excludeLabels.size > 0 && includeLabels.size > 0 ? 'and' : ''
  if (includeLabels.size === 1) {
    const labelName = option.selectionOptions.find(l => includeLabels.has(String(l.id)))?.name
    if (option?.displayVariant === 'inline') {
      includeText = `are in the ${labelName} ${option.entity}`
    } else {
      includeText = `that includes ${labelName} selected`
    }
  } else if (includeLabels.size > 1) {
    if (option?.displayVariant === 'inline') {
      includeText = `are in at least one of ${includeLabels.size} ${option.entity}s`
    } else {
      includeText = `that includes ${includeLabels.size} labels selected`
    }
  }
  if (excludeLabels.size === 1) {
    const labelName = option.selectionOptions.find(l => excludeLabels.has(String(l.id)))?.name
    if (option?.displayVariant === 'inline') {
      excludeText = `are not in the ${labelName} ${option.entity}`
    } else {
      excludeText += `that excludes ${labelName} selected`
    }
  } else if (excludeLabels.size > 1) {
    if (option?.displayVariant === 'inline') {
      excludeText = `are not in any of ${excludeLabels.size} ${option.entity}s`
    } else {
      excludeText = `that excludes ${excludeLabels.size} labels selected`
    }
  }

  return (
    <>
      {option?.displayVariant === 'inline' ? (
        <Button className={classes.inlineButton} variant="text" onClick={() => setLabelMenuOpen(true)} ref={buttonRef}>
          {includeLabels.size > 0 && includeText}
          {andText && <> {andText} </>}
          {excludeLabels.size > 0 && excludeText}
        </Button>
      ) : (
        <FilterChip onClick={() => setLabelMenuOpen(true)} ref={chipRef} variant="outlined" size="small">
          <>
            {includeLabels.size > 0 && (
              <>
                <FilterChipText bold text={option.label} />
                <FilterChipText text={includeText} />
              </>
            )}
            {andText && <FilterChipText text={andText} />}
            {excludeLabels.size > 0 && (
              <>
                <FilterChipText bold text={option.label} />
                <FilterChipText text={excludeText} />
              </>
            )}
          </>
        </FilterChip>
      )}
      <Menu
        open={labelMenuOpen}
        anchorEl={option?.displayVariant === 'inline' ? buttonRef.current : chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={onMenuClosed}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuHeader title={option.label} />
        <BulkChipsMenuContent
          editable={editable}
          onApply={handleApply}
          labels={option.selectionOptions}
          includeLabelsIds={includeLabels}
          excludeLabelsIds={excludeLabels}
          onClear={() => {
            setLabelMenuOpen(false)
            onDelete()
          }}
          option={option}
          onClose={onMenuClosed}
        />
      </Menu>
    </>
  )
}

function BulkChipsFilterMenu(props: BulkLChipsFilterMenuProps): React.ReactElement {
  const { option, value, onSelectValue, onBack, onClose, onDelete, editable = true } = props

  const includeLabels = new Set(value?.any ? value.any : [])
  const excludeLabels = new Set(value?.none ? value.none : [])

  function handleApply(include: Set<string>, exclude: Set<string>): void {
    onClose()
    onSelectValue({
      ...(value ?? {}),
      any: Array.from(include),
      none: Array.from(exclude),
    })
  }

  return (
    <div>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <BulkChipsMenuContent
        editable={editable}
        onApply={handleApply}
        onClear={() => {
          onClose()
          onDelete()
        }}
        labels={option.selectionOptions}
        includeLabelsIds={includeLabels}
        excludeLabelsIds={excludeLabels}
        option={option}
        onClose={onClose}
      />
    </div>
  )
}

export default BulkChipsFilterMenu
