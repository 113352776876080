import React from 'react'
import { Box, Grid, makeStyles, Paper, Divider, useTheme } from '@material-ui/core'
import { DateRangeFilter, DateTimeRangeFilter, LtvBreakdown } from '../../gql-global'
import Metric from '../../components/metric/Metric'
import { Skeleton } from '@material-ui/lab'
import { currencyCodeToSymbol, getNumericString } from '../../utils/number-format'
import { ReactComponent as Triangle } from '../../icons/triangle.svg'
import useHasFeature from '../../hooks/useHasFeature'

const useStyles = makeStyles({
  smallPaperTop: {
    borderRadius: '8px 8px 0 0',
    height: '100px',
    boxShadow: '0',
  },
  smallPaperBottom: {
    borderRadius: '0 0 8px 8px',
    height: '100px',
    boxShadow: '0',
  },
  largePaper: {
    borderRadius: '8px',
    height: '200px',
  },
  icon: {
    marginRight: '5px',
  },
  upTriangle: {
    fill: '#009E8E',
    stroke: '#009E8E',
  },
  downTriangle: {
    fill: '#FF3C3C',
    transform: 'rotate(180deg)',
  },
  dividerColor: {
    opacity: '40%',
    backgroundColor: '#000000',
    marginRight: '50px',
    marginLeft: '50px',
    marginTop: '-1px',
    height: '1px',
  },
  diffMetric: {
    minWidth: '175px',
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  diffLabel: {
    justifyContent: 'center',
    paddingRight: '-40px',
    width: '100%',
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
})

interface LTVDiffBoxProps {
  loading: boolean
  metric?: number | undefined
  label: string
  subLabel(value: string, description: string): string
  className?: string
  currencyCode?: string
}

const LTVDiffBox: React.FC<LTVDiffBoxProps> = ({ loading, metric, label, subLabel, className, currencyCode }) => {
  const classes = useStyles()
  const theme = useTheme()

  function getMetricColor(metric: number | undefined): string {
    let color = theme.palette['secondary'].dark
    if (metric !== undefined) {
      if (metric > 0) {
        color = theme.palette['success'].main
      } else if (metric < 0) {
        color = theme.palette['error'].dark
      }
    }
    return color
  }

  function getLabelColor(): string {
    return theme.palette['secondary'].dark
  }

  let metricString = !!metric || metric === 0 ? getNumericString(metric) : '--'
  metricString =
    !!metric || metric === 0 ? `${currencyCodeToSymbol(currencyCode || 'USD')}${metricString}` : metricString
  const description = !!metric || metric === 0 ? (metric >= 0 ? 'higher' : 'lower') : ''

  const metricColor = getMetricColor(metric)
  const labelColor = getLabelColor()

  return (
    <Paper className={className}>
      {loading ? (
        <Skeleton component="span" className={classes.smallPaperBottom} />
      ) : (
        <Grid container>
          <Grid item className={classes.diffMetric} xs={2}>
            <Box fontSize={'h4.fontSize'} lineHeight={'h4.lineHeight'} pt="20px" pl="50px" color={metricColor}>
              {metric !== undefined && metric >= 0 && <Triangle className={`${classes.icon} ${classes.upTriangle}`} />}
              {metric !== undefined && metric < 0 && <Triangle className={`${classes.icon} ${classes.downTriangle}`} />}
              {loading ? <Skeleton component="span" className={classes.smallPaperTop} /> : metricString}
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.diffLabel}>
            <Box display="flex" justifyContent={'center'}>
              <Box width="360px">
                <Box fontSize={'22px'} fontWeight={500} pb="0px" pt="17px" color={labelColor}>
                  {label}
                </Box>
                <Box fontSize={'15px'} lineHeight={'h8.lineHeight'} pt="2px" color={labelColor}>
                  {subLabel(metricString, description)}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}

export interface CampaignDashboardEcommStatsData {
  currentLtvBreakdown?: LtvBreakdown | null
  previousLtvBreakdown?: LtvBreakdown | null
}

export interface CampaignDashboardEcommStatsProps {
  dateRangeFilter?: DateRangeFilter | DateTimeRangeFilter | null
  data: CampaignDashboardEcommStatsData
  statsLoading: boolean
  currencyCode?: string
}

const CampaignDashboardEcommStats: React.FC<CampaignDashboardEcommStatsProps> = ({
  dateRangeFilter,
  data,
  statsLoading,
  currencyCode,
}) => {
  const classes = useStyles()
  const { currentLtvBreakdown, previousLtvBreakdown } = data
  const { prememberDiff, nonmemberDiff } = calculateLtvDiffs(!statsLoading ? currentLtvBreakdown : undefined)

  const { hasFeature: hasLTVPercentChange } = useHasFeature('ltvPercentChange')

  return (
    <Grid container spacing={3}>
      <Grid item xs={11}>
        <Box fontSize="1.125rem" fontWeight="fontWeightBold" pt={6}>
          Customer Lifetime Value
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.largePaper}>
          <Box ml={-4} mr={-3}>
            <Metric
              dateRangeFilter={dateRangeFilter}
              hasCompare={hasLTVPercentChange}
              size="xlg"
              metricType={'ltv'}
              previousMetric={previousLtvBreakdown?.ltvAfter}
              metric={currentLtvBreakdown?.ltvAfter}
              loading={statsLoading}
              currencyCode={currencyCode}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <LTVDiffBox
          loading={statsLoading}
          metric={prememberDiff}
          label={'Compared to Pre-membership LTV'}
          subLabel={(value, description) => {
            return `Member LTV is ${value} ${description} after joining a program.`
          }}
          className={classes.smallPaperTop}
          currencyCode={currencyCode}
        />
        <Divider className={classes.dividerColor} />
        <LTVDiffBox
          loading={statsLoading}
          metric={nonmemberDiff}
          label={'Compared to Non-member LTV'}
          subLabel={(value, description) => {
            return `Member LTV is ${value} ${description} than non-members.`
          }}
          className={classes.smallPaperBottom}
          currencyCode={currencyCode}
        />
      </Grid>
    </Grid>
  )
}

export default CampaignDashboardEcommStats

const calculateLtvDiffs = (
  ltvBreakdown: LtvBreakdown | undefined | null,
): { prememberDiff: number | undefined; nonmemberDiff: number | undefined } => {
  const prememberDiff =
    ltvBreakdown?.ltvAfter !== undefined && ltvBreakdown?.ltvBefore !== undefined
      ? ltvBreakdown.ltvAfter - ltvBreakdown.ltvBefore
      : undefined
  const nonmemberDiff =
    ltvBreakdown?.ltvAfter !== undefined && ltvBreakdown?.ltvNonmember !== undefined
      ? ltvBreakdown.ltvAfter - ltvBreakdown.ltvNonmember
      : undefined

  return {
    prememberDiff,
    nonmemberDiff,
  }
}

export { calculateLtvDiffs }
