import React, { useState } from 'react'
import { Box, Button, makeStyles, createStyles, Typography } from '@material-ui/core'
import Page from '../Page'
import withAuthorization from '../withAuthorization'
import { CHALLENGES_ROUTE } from './routes'
import useTitle from '../utils/use-title'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import AddEditChallenge from './AddEditChallengeDialog'
import { useSocialAccountsQuery } from './operations/query-social-accounts.generated'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import ChallengesList from './ChallengesList'
import { ChallengeFragment } from './operations/challenge.generated'
import { useAccountChallengesQuery } from './operations/query-account-challenges.generated'
import { IconType } from '../components/metric/MetricIcon'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: 1,
      lineHeight: 2,
    },
  }),
)

export type ChallengeSocialAccountType = AccountLabel<string> & { isIG: boolean; icon?: IconType }

const Challenges: React.FC = () => {
  useTitle('Challenges')
  const classes = useStyles()
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<boolean>(false)
  const [selectedChallenge, setSelectedChallenge] = useState<string>()

  const { data } = useAccountChallengesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 999,
    },
  })

  const accountChallenges: ChallengeFragment[] = data?.whoami?.account?.challenges?.results || []

  const { data: socialData } = useSocialAccountsQuery()

  const selectedSocialAccountId = socialData?.whoami?.preferences?.selectedSocialAccountId || ''

  const handleOnClose = () => {
    setSelectedChallenge(undefined)
    setAddEditDialogOpen(false)
  }

  const handleOnOpen = (challenge?: ChallengeFragment) => {
    setSelectedChallenge(challenge?.id)
    setAddEditDialogOpen(true)
  }

  return (
    <Page>
      <Box mx={10}>
        <Box mb={5} display="flex" flex={1}>
          <Typography variant="h5" className={classes.title}>
            Challenges
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleOnOpen()}
            startIcon={<PlusIcon height={16} width={16} />}
          >
            Create New Challenge
          </Button>
        </Box>
        <ChallengesList onEditChallenge={handleOnOpen} />
      </Box>
      <AddEditChallenge
        open={addEditDialogOpen}
        challengeId={selectedChallenge}
        socialAccounts={socialData?.socialAccounts || []}
        selectedSocialAccount={selectedSocialAccountId}
        onClose={handleOnClose}
        accountChallenges={accountChallenges}
      />
    </Page>
  )
}

export default withAuthorization(CHALLENGES_ROUTE)(Challenges)
