import { NetworkStatus } from '@apollo/client'
import { Box, Typography, Button, makeStyles, createStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import useFilterParams, { Filters } from './use-filter-params'
import {
  RewardFilterInput,
  SortDirection,
  RewardSort,
  UserRoleType,
  CodesLevel,
  FulfillmentAccountType,
  RewardTypeEnum,
} from '../../gql-global'
import Page from '../../Page'
import useTitle from '../../utils/use-title'
import { AccountRewardsDocument, useAccountRewardsQuery } from './operations/account-rewards.generated'
import { SettingsRewardRowFragment } from './operations/settings-reward-row.generated'
import { RewardUserInfoQuery, useRewardUserInfoQuery } from './operations/reward-user-info.generated'
import { useCreateRewardMutation } from './operations/create-reward.generated'
import { useUpdateRewardMutation } from './operations/update-reward.generated'
import { useDeleteRewardMutation } from './operations/delete-reward.generated'
import { useDeleteRewardCodesMutation } from './operations/delete-reward-codes.generated'
import RewardRowV2 from './RewardRowV2'

import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import AddEditReward, { RewardFormOutput } from './AddEditReward'
import DeleteReward from './DeleteReward'
import UploadRewardCodesDialog from './UploadRewardCodesDialog'
import { ReactComponent as EmptyListImage } from '../../images/empty-list.svg'
import ContainerEmptyState from '../../components/ContainerEmptyState/ContainerEmptyState'
import { useToast } from '../../components/Alert/ToastProvider'
import RewardFilter from './RewardFilter'
import { useRewardsCampaignsQuery } from './operations/query-rewards-campaigns.generated'
import { getRewardIntegrationLabel } from '../../utils/rewards'

const REWARD_PAGE_SIZE = 25

const deliveryMethodData = [
  // {
  //   id: RewardTypeEnum.Sms,
  //   name: 'SMS',
  // },
  {
    id: RewardTypeEnum.Email,
    name: 'Email',
  },
  {
    id: RewardTypeEnum.Dm,
    name: 'Instagram DM',
  },
  {
    id: RewardTypeEnum.Manual,
    name: 'Manual',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountFormControl: {
      marginRight: theme.spacing(3),
      minWidth: 200,
      borderRadius: '2rem',
    },
    accountSelect: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      overflow: 'hidden',
    },
    accountInput: {
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      color: 'white',
      borderRadius: '2rem',
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    title: {
      flex: 1,
      lineHeight: 2,
    },
    tableHeaderCell: {
      flex: 1,
      color: theme.palette.secondary.main,
    },
    icon: {
      fill: 'white',
    },
  }),
)

const useWhereFilters = (filters: Filters): RewardFilterInput => {
  return {
    search: filters.rewardKeywords?.length > 0 ? { keywords: filters.rewardKeywords } : null,
    campaigns: filters.campaigns?.any ? { any: filters.campaigns.any } : null,
    integrations: filters.integrations?.any ? { any: filters.integrations.any as FulfillmentAccountType[] } : null,
    rewardType: filters.rewardType?.any ? { any: filters.rewardType.any as RewardTypeEnum[] } : null,
  }
}

const RewardManagement: React.FC = () => {
  useTitle('Rewards - Settings')
  const classes = useStyles()
  const { showToast } = useToast()

  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
  const [selectedReward, setSelectedReward] = useState<SettingsRewardRowFragment | null>(null)
  const [rewardsBeingProcessed, setRewardsBeingProcessed] = useState<number[]>([])

  const { filters, setFilters } = useFilterParams()
  const whereFilters = useWhereFilters(filters)

  const [createReward] = useCreateRewardMutation({
    update: (cache, { data }) => {
      const account = data?.createReward?.reward?.account
      const newReward = data?.createReward?.reward
      if (account && newReward) {
        cache.modify({
          id: cache.identify(account),
          fields: {
            rewards(existingRewards) {
              return {
                ...existingRewards,
                results: [...existingRewards.results, newReward],
              }
            },
          },
        })
      }
    },
    onError: e => {
      showToast({
        title: 'Error: Creating Reward',
        message: 'Something went wrong when creating this reward, please try again' + e,
        severity: 'error',
      })
    },
  })

  const accountRewardsVariables = {
    accountId: selectedAccountId?.toString() || '',
    limit: REWARD_PAGE_SIZE,
    sortBy: RewardSort.Name,
    sortDirection: SortDirection.Asc,
    where: whereFilters,
  }

  const [updateReward] = useUpdateRewardMutation({
    onError: e => {
      showToast({
        title: 'Error: Creating Reward',
        message: 'Something went wrong when creating this reward, please try again' + e,
        severity: 'error',
      })
    },
    refetchQueries: [
      {
        query: AccountRewardsDocument,
        variables: accountRewardsVariables,
      },
    ],
  })

  const [deleteReward] = useDeleteRewardMutation({
    update: (cache, { data }) => {
      const account = data?.deleteReward?.reward?.account
      const deletedReward = data?.deleteReward?.reward
      if (account && deletedReward) {
        cache.modify({
          id: cache.identify(account),
          fields: {
            rewards(_, { DELETE }) {
              return DELETE
            },
          },
        })
      }
    },
    onError: e => {
      showToast({
        title: 'Error: Deleting Reward',
        message: 'Something went wrong when deleting this reward, please try again' + e,
        severity: 'error',
      })
    },
    refetchQueries: [
      {
        query: AccountRewardsDocument,
        variables: accountRewardsVariables,
      },
    ],
  })

  const [deleteRewardCodes] = useDeleteRewardCodesMutation({
    update(cache, { data }) {
      if (data?.deleteRewardCodes?.reward) {
        cache.modify({
          id: cache.identify(data.deleteRewardCodes.reward),
          fields: {
            stats(stats) {
              return {
                ...stats,
                codesRemaining: 0,
                codesLevel: CodesLevel.Empty,
              }
            },
          },
        })
      }
    },
  })

  const { loading: userDataLoading, data: userData } = useRewardUserInfoQuery({
    onCompleted: (data: RewardUserInfoQuery) => {
      if (!selectedAccountId && data?.whoami?.account) setSelectedAccountId(data.whoami.account.id)
    },
  })

  const { preferences } = userData?.whoami || {}
  const isAdmin = userData?.whoami?.roles?.some(r => r.name === UserRoleType.Admin)
  const isOwner = userData?.whoami?.roles?.some(r => r.name === UserRoleType.Owner)

  const integrations = userData?.whoami?.account?.integrations || []
  const integrationTypes = Array.from(new Set(integrations.map(i => i.integrationType)))
  const integrationData = integrationTypes
    .map(type => ({
      id: type,
      name: getRewardIntegrationLabel(type),
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const {
    loading: rewardsLoading,
    error,
    data,
    fetchMore,
    networkStatus,
  } = useAccountRewardsQuery({
    skip: !selectedAccountId,
    variables: accountRewardsVariables,
  })

  const { data: campaignsData } = useRewardsCampaignsQuery({
    skip: !preferences?.selectedSocialAccountId,
    variables: {
      socialAccountId: preferences?.selectedSocialAccountId?.toString() || '',
    },
  })

  const campaigns = campaignsData?.socialAccount?.campaigns
    ? campaignsData?.socialAccount?.campaigns.map(campaign => ({
        id: campaign.id.toString(),
        name: campaign?.program?.name || '',
      }))
    : []

  const loading = userDataLoading || (rewardsLoading && networkStatus !== NetworkStatus.fetchMore)

  if (error || (!loading && !data)) return <p>Error: {error && error.message}</p>

  const loadMoreContent = (): void => {
    if (data?.account?.rewards?.cursor) {
      void fetchMore({
        variables: {
          cursor: data?.account?.rewards?.cursor,
        },
      })
    }
  }

  const renderSkeletons = (): React.ReactElement => {
    const skeletons = []
    for (let i = 0; i < REWARD_PAGE_SIZE; i++) {
      skeletons.push(<RewardRowV2 loading />)
    }
    return <>{skeletons}</>
  }

  function handleAddRewardClick(): void {
    setAddEditDialogOpen(true)
  }

  function handleEditClick(reward: SettingsRewardRowFragment): void {
    setSelectedReward(reward)
    setAddEditDialogOpen(true)
  }

  function handleAddEditClose(): void {
    setSelectedReward(null)
    setAddEditDialogOpen(false)
  }

  async function handleDeleteRewardCodes(reward: SettingsRewardRowFragment) {
    try {
      await deleteRewardCodes({ variables: { rewardId: reward.id.toString() } })
      showToast({
        title: 'Success: Removing Reward Codes',
        message: 'Reward codes removed.',
        severity: 'success',
      })
    } catch {
      showToast({
        title: 'Error: Removing Reward Codes',
        message: 'Something went wrong when remove reward codes, please try again',
        severity: 'error',
      })
    }
  }

  async function handleAddEditSave(values: RewardFormOutput, id?: number | null): Promise<void> {
    const rewardVariables = {
      name: values.name,
      hasDiscountCode: values.hasDiscountCode,
      emailTemplateName: values.emailTemplateName || undefined,
      templateId: values.templateId || undefined,
      subject: values.subject || undefined,
      emailTemplateVariableName: values.discountCodeFieldName || undefined,
      tierId: values.loyaltyTierId || undefined,
      eventId: values.eventId || undefined,
      integrationId: values.integrationId || undefined,
      isDmReward: values.isDmReward,
      deliveryMethod: values.deliveryMethod || undefined,
      value: values.value || undefined,
      currencyCode: values.currencyCode || undefined,
      tremendousCampaignId: values.tremendousCampaignId || undefined,
      bluecoreCampaignId: values.bluecoreCampaignId || undefined,
    }

    if (!id) {
      const codeUpload = values.codeFile
        ? {
            codeFile: values.codeFile,
          }
        : null
      const results = await createReward({
        variables: {
          accountId: selectedAccountId?.toString() || '',
          reward: rewardVariables,
          codeUpload,
        },
      })
      const affectedRewardId = results.data?.createReward?.reward?.id
      if (values.codeFile && affectedRewardId) {
        setRewardsBeingProcessed(prev => [...prev, affectedRewardId])
      }
    } else {
      await updateReward({
        variables: {
          id: id.toString(),
          reward: rewardVariables,
        },
      })
    }
    setAddEditDialogOpen(false)
    setSelectedReward(null)
  }

  function handleDeleteButtonClick(reward: SettingsRewardRowFragment): void {
    setSelectedReward(reward)
    setAddEditDialogOpen(false)
    setDeleteDialogOpen(true)
  }

  function handleDeleteDialogClose(): void {
    setSelectedReward(null)
    setDeleteDialogOpen(false)
  }

  async function handleDeleteDialogSave(id: number): Promise<void> {
    await deleteReward({ variables: { id: id.toString() } })
    setSelectedReward(null)
    setDeleteDialogOpen(false)
  }

  function handleUploadButtonClick(reward: SettingsRewardRowFragment): void {
    setSelectedReward(reward)
    setUploadDialogOpen(true)
  }

  function handleUploadDialogClose(): void {
    setSelectedReward(null)
    setUploadDialogOpen(false)
  }

  async function handleUploadDialogSave(id: number, filename: string, reenableDms: boolean): Promise<void> {
    await updateReward({
      variables: {
        id: id.toString(),
        reward: {
          name: selectedReward?.name || undefined,
          hasDiscountCode: selectedReward?.hasDiscountCode,
          emailTemplateName: selectedReward?.emailTemplateName || undefined,
          templateId: selectedReward?.templateId || undefined,
          subject: selectedReward?.subject || undefined,
          emailTemplateVariableName: selectedReward?.emailTemplateVariableName || undefined,
          tierId: selectedReward?.tierId || undefined,
          eventId: selectedReward?.eventId || undefined,
          integrationId: selectedReward?.integration?.id || undefined,
          isDmReward: selectedReward?.isDmReward,
        },
        codeUpload: {
          codeFile: filename,
          reenableDms,
        },
      },
    })
    if (filename && id) {
      setRewardsBeingProcessed(prev => [...prev, id])
    }
    setSelectedReward(null)
    setUploadDialogOpen(false)
  }

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" flex={1}>
          <Typography variant="h5" className={classes.title}>
            Reward Management
          </Typography>
          <Box display="flex">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddRewardClick}
              startIcon={<PlusIcon height={16} width={16} />}
            >
              Add Reward
            </Button>
          </Box>
        </Box>
        <Box display="flex" flex={1}>
          <RewardFilter
            campaignFilter={filters?.campaigns}
            integrationFilter={filters?.integrations}
            deliveryMethodFilter={filters?.rewardType}
            campaignData={campaigns}
            integrationData={integrationData}
            deliveryMethodData={deliveryMethodData}
            onSelectIntegrations={integrations => {
              setFilters({
                ...filters,
                integrations,
              })
            }}
            onSelectPrograms={campaigns => {
              setFilters({
                ...filters,
                campaigns,
              })
            }}
            onSelectDeliveryMethods={rewardType => {
              setFilters({
                ...filters,
                rewardType,
              })
            }}
          />
        </Box>
        <Box mt={6}>
          {loading && (
            <>
              <RewardRowV2 loading />
              <RewardRowV2 loading />
              <RewardRowV2 loading />
              <RewardRowV2 loading />
              <RewardRowV2 loading />
              <RewardRowV2 loading />
              <RewardRowV2 loading />
              <RewardRowV2 loading />
            </>
          )}
          {!loading && (
            <>
              {data?.account?.rewards?.results?.map(reward => (
                <RewardRowV2
                  key={reward.id}
                  reward={reward}
                  onEdit={handleEditClick}
                  onUpload={handleUploadButtonClick}
                  uploadProcessing={rewardsBeingProcessed.includes(reward.id)}
                  onDeleteCodes={handleDeleteRewardCodes}
                  canDeleteCodes={isAdmin || isOwner}
                />
              ))}
            </>
          )}

          {networkStatus === NetworkStatus.fetchMore && renderSkeletons()}
          {!loading && data?.account?.rewards?.cursor && (
            <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
              <Button variant="outlined" color="primary" size="large" onClick={loadMoreContent}>
                Load more
              </Button>
            </Box>
          )}
          {!loading && data?.account?.rewards?.total === 0 && (
            <Box>
              <ContainerEmptyState
                image={EmptyListImage}
                text="Looks like you haven’t set up any Rewards yet."
                subtext="Let’s add your first Reward now!"
                callToActionCallback={handleAddRewardClick}
                callToActionText="Add Reward"
              />
            </Box>
          )}
        </Box>
      </Box>
      <AddEditReward
        open={addEditDialogOpen}
        reward={selectedReward}
        onClose={handleAddEditClose}
        onSave={handleAddEditSave}
        onDelete={reward => handleDeleteButtonClick(reward)}
        integrations={integrations}
        accountId={selectedAccountId}
      />
      <DeleteReward
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onSave={handleDeleteDialogSave}
        reward={selectedReward}
      />
      <UploadRewardCodesDialog
        accountId={selectedAccountId}
        open={uploadDialogOpen}
        onClose={handleUploadDialogClose}
        onSave={handleUploadDialogSave}
        reward={selectedReward}
      />
    </Page>
  )
}

export default RewardManagement
