import React from 'react'
import Metric, { MetricProps } from '../metric/Metric'
import { Box } from '@material-ui/core'

const Tab: React.FC<MetricProps> = ({ ...props }) => {
  return (
    <Box height="136px" width="100%">
      <Box style={{ margin: '0 auto', minWidth: '218px', width: '60%' }}>
        <Metric size="lg" hasCompare {...props} />
      </Box>
    </Box>
  )
}

export default Tab
