import React, { useEffect, useRef } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useTitle from '../utils/use-title'
import { NetworkStatus } from '@apollo/client'
import useFilterParams from './use-filter-params'
import { Skeleton } from '@material-ui/lab'
import ContainerError from '../components/ContainerError'
import { NumberParam, useQueryParam } from 'use-query-params'
import ReviewChallengeRow from './ReviewChallengeRow'
import { useReviewChallengeListQuery } from './operations/review-challenge-list.generated'
import { ChallengeReviewFragment } from './operations/challenge-review-fragment.generated'

const PAGE_SIZE = 10

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: 1,
      lineHeight: 2,
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
)

const ReviewChallengesList: React.FC = () => {
  useTitle('Review')

  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('challengesViewing', NumberParam)
  const limitRef = useRef(viewing)

  const { loading, error, data, fetchMore, networkStatus } = useReviewChallengeListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      limit: limitRef.current,
      forReview: true,
    },
  })

  const classes = useStyles()
  const { filters } = useFilterParams()

  // Update view count
  const newResultsLength = data?.challenges?.results?.length || limitRef.current
  const newViewingCount = (Math.ceil(newResultsLength / PAGE_SIZE) || 1) * PAGE_SIZE
  useEffect(() => setViewing(newViewingCount), [newViewingCount, setViewing])

  const { cursor } = data?.challenges || {}
  function handleLoadMore(): void {
    if (cursor) {
      fetchMore({
        variables: {
          cursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const isLoadingState = loading || loadingMore
  const errorState = !loading && !loadingMore && !data

  const renderSkeletonTableRow = () => (
    <TableRow>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
      <TableCell>
        <Skeleton height={50} />
      </TableCell>
    </TableRow>
  )

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" className={classes.title}>
            Challenges
          </Typography>
          {!!filters.reviewProgramKeywords.length && (
            <Box ml={4}>
              <Typography>(name contains "{filters.reviewProgramKeywords.join(', ')}")</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={6}>
        {(error || errorState) && (
          <Box display="flex" justifyContent="center">
            <ContainerError text={`Error: ${error && error?.message}` || 'Error loading challenges'} />
          </Box>
        )}
        {!errorState && (
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Dates</TableCell>
                  <TableCell>Social Accounts</TableCell>
                  <TableCell>Posts</TableCell>
                  <TableCell>Last Reviewed</TableCell>
                  <TableCell>Last Reviewer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.challenges?.results?.map(challenge => (
                  <ReviewChallengeRow
                    currentUserId={data?.whoami?.id}
                    challenge={challenge as ChallengeReviewFragment}
                    key={challenge.id}
                  />
                ))}
                {!errorState && isLoadingState && (
                  <>
                    {renderSkeletonTableRow()}
                    {renderSkeletonTableRow()}
                    {renderSkeletonTableRow()}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {!errorState && !isLoadingState && cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore}>
            Load more
          </Button>
        </Box>
      )}
    </>
  )
}

export default ReviewChallengesList
