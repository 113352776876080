import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChallengeListUserDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ChallengeListUserDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null } } | null };


export const ChallengeListUserDataDocument = gql`
    query ChallengeListUserData {
  whoami {
    id
    email
    roles {
      id
      name
    }
    preferences @client {
      id
      selectedSocialAccountId
    }
  }
}
    `;

/**
 * __useChallengeListUserDataQuery__
 *
 * To run a query within a React component, call `useChallengeListUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengeListUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengeListUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useChallengeListUserDataQuery(baseOptions?: Apollo.QueryHookOptions<ChallengeListUserDataQuery, ChallengeListUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengeListUserDataQuery, ChallengeListUserDataQueryVariables>(ChallengeListUserDataDocument, options);
      }
export function useChallengeListUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengeListUserDataQuery, ChallengeListUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengeListUserDataQuery, ChallengeListUserDataQueryVariables>(ChallengeListUserDataDocument, options);
        }
export type ChallengeListUserDataQueryHookResult = ReturnType<typeof useChallengeListUserDataQuery>;
export type ChallengeListUserDataLazyQueryHookResult = ReturnType<typeof useChallengeListUserDataLazyQuery>;
export type ChallengeListUserDataQueryResult = Apollo.QueryResult<ChallengeListUserDataQuery, ChallengeListUserDataQueryVariables>;