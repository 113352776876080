import React from 'react'
import {
  MenuItem,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'

const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Name is required.'),
  billingEmail: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Billing email is required.'),
  emvCpmUsd: yup
    .number()
    .positive()
    .required(),
  productId: yup.string().required(),
})

export type AddAccountFormFields = yup.InferType<typeof schema>

interface AddAccountDialogProps {
  open: boolean
  onCancel(): void
  onSubmit(fields: AddAccountFormFields): void
  enterpriseProducts: { id: string; name: string }[]
}

const defaultFields: AddAccountFormFields = {
  name: '',
  billingEmail: '',
  emvCpmUsd: 0.03,
  productId: '',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(5),
    },
  }),
)

function AddAccountDialog({ open, onCancel, onSubmit, enterpriseProducts }: AddAccountDialogProps): React.ReactElement {
  const styles = useStyles()
  return (
    <Dialog open={open} onClose={onCancel}>
      <Formik<AddAccountFormFields> initialValues={defaultFields} validationSchema={schema} onSubmit={onSubmit}>
        <Form noValidate>
          <DialogTitle>Add Account</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" minWidth={300}>
              <Field className={styles.field} component={TextField} label="Name" name="name" />
              <Field className={styles.field} component={TextField} label="Billing Email" name="billingEmail" />
              <Field
                className={styles.field}
                component={TextField}
                label="EMV per Impression $ Amount"
                name="emvCpmUsd"
              />
              <Field className={styles.field} component={TextField} select label="Product" name="productId">
                {enterpriseProducts.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default AddAccountDialog
