import React, { useState } from 'react'
import ActivityListItemContent, { ActivityListItemContentProps } from './ActivityListItemContent'
import { Box, makeStyles } from '@material-ui/core'
import { useOpenPostParam } from '../components/ConnectedPostDetailModal'
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg'
import { ReactComponent as TikTokIcon } from '../icons/tiktok_logo_outline.svg'
import expiredStory from '../images/expired-story.svg'
import { ReactComponent as VideoStoryIcon } from '../icons/photo-story.svg'
import { secondary } from '../loudcrowd-theme'
import { createTypeNamePredicate } from '../types/utility'
import { MentionEventItemFragment } from './operations/mention-event-item.generated'

const isIGMedia = createTypeNamePredicate('IGMedia')

interface OnMentionActivityListItemProps extends Omit<ActivityListItemContentProps, 'media' | 'action'> {
  mention: MentionEventItemFragment
}

const useStyles = makeStyles({
  imageContainer: {
    height: 48,
    width: 48,
    margin: 16,
    padding: 12,
    borderRadius: 24,
  },
  postThumbnail: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  expiredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'contain',
    backgroundImage: `url(${expiredStory})`,
    height: 80,
    width: 80,
    padding: 12,
  },
  expiredStory: {
    color: secondary[400],
    height: 48,
    width: 48,
  },
  blurred: {
    filter: 'blur(15px) opacity(0.8) grayscale(0.3)',
  },
})

function OnMentionActivityListItem({ mention, ...rest }: OnMentionActivityListItemProps): React.ReactElement {
  const classes = useStyles()
  const [, setOpenPostParam] = useOpenPostParam()
  const [mediaFailed, setMediaFailed] = useState(false)
  const [triedVideo, setTriedVideo] = useState<boolean>(false)
  const media = mention?.media
  const mediaUrl = media?.mediaUrl || undefined
  const imageUrl = media?.lcMediaUrl || undefined
  const videoUrl = media?.lcThumbnailUrl || undefined
  const mediaType = isIGMedia(media) ? media.mediaType : null
  const postType = isIGMedia(media) ? media.postType : null
  const isUnathedStory = isIGMedia(media) && !media?.fromAuthedApi && postType === 'STORY'
  const isExpiredUnauthedStory = isIGMedia(media) && isUnathedStory && media.isExpired

  let sourceUrl: string | undefined = imageUrl

  if (mediaType === 'VIDEO') {
    sourceUrl = videoUrl
  } else if (mediaType === null) {
    sourceUrl = mediaUrl
  }

  const imageClasses = [isExpiredUnauthedStory ? classes.expiredStory : classes.postThumbnail]
  if (mention?.isPastLimit) {
    imageClasses.push(classes.blurred)
  }

  let mediaThumb = (
    <Box className={classes.imageContainer} bgcolor="primary.light" color="primary.main">
      {isIGMedia(media) ? <InstagramIcon width={24} height={24} /> : <TikTokIcon width={24} height={24} />}
    </Box>
  )

  if (!mediaFailed && sourceUrl) {
    if (mediaType !== null) {
      mediaThumb = (
        <img alt="post media" className={imageClasses.join(' ')} src={sourceUrl} onError={() => setMediaFailed(true)} />
      )
    } else {
      if (isExpiredUnauthedStory) {
        mediaThumb = (
          <Box className={classes.expiredContainer}>
            <VideoStoryIcon className={imageClasses.join(' ')} />
          </Box>
        )
      } else {
        mediaThumb = !triedVideo ? (
          <video className={imageClasses.join(' ')} src={sourceUrl} onError={() => setTriedVideo(true)} />
        ) : (
          <img
            alt="post media"
            className={imageClasses.join(' ')}
            src={sourceUrl}
            onError={() => setMediaFailed(true)}
          />
        )
      }
    }
  }

  return (
    <ActivityListItemContent
      media={mediaThumb}
      action={{
        text: 'View post',
        onAction() {
          if (!mention.isPastLimit) {
            setOpenPostParam(mention.id)
          }
        },
      }}
      {...rest}
    />
  )
}

export default OnMentionActivityListItem
