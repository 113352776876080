import { getFeatureConfig } from '../features'
import { AccountType, UserType } from '../gql-global'

type GQLUser = Pick<UserType, 'email' | 'roles' | '__typename'> & {
  account?: Pick<AccountType, 'id'> | null
}

export interface FeatureUserType {
  username: string
  roles: string[] | null
  accountId: number | null
}

function usernameAndRolesAndAccountId(user: FeatureUserType | GQLUser): {
  username: string
  roles: string[]
  accountId: number | null
} {
  let username: string
  let roles: string[]
  let accountId: number | null
  if ('__typename' in user) {
    username = user.email
    roles = user.roles?.map(r => r.name) || []
    accountId = user.account?.id || null
  } else {
    username = user.username
    roles = user.roles || []
    accountId = user.accountId
  }
  return { username, roles, accountId }
}

export function getUserFeatures(user: FeatureUserType | GQLUser, includeOverrides = true): string[] {
  const features = getFeatureConfig()
  return features.features.filter(f => userHasFeature(user, f.id, includeOverrides)).map(f => f.id)
}

export function userHasFeature(user: FeatureUserType | GQLUser, featureName: string, includeOverrides = true): boolean {
  const features = getFeatureConfig()
  const feature = features.features.find(feature => feature.id === featureName)
  if (!feature) {
    return false
  }
  if (includeOverrides) {
    const overridesValue = localStorage.getItem('featureOverrides')
    const overrides = overridesValue ? (JSON.parse(overridesValue) as Record<string, boolean>) : null
    if (overrides && featureName in overrides) {
      return !!overrides[featureName]
    }
  }
  const { username, roles, accountId } = usernameAndRolesAndAccountId(user)
  let result = false
  feature.allowed.forEach((segmentName: string) => {
    const segment = features.segments.find(segment => segment.id === segmentName)
    if (segment) {
      const segmentRoles = segment.roles
      if (segment.loudcrowdEmail && username.endsWith('@loudcrowd.com')) {
        result = true
      } else if (segment.users?.includes(username)) {
        result = true
      } else if (segmentRoles && roles.some(role => segmentRoles.includes(role))) {
        result = true
      } else if (accountId && segment.accountIds && segment.accountIds.includes(accountId)) {
        result = true
      }
    }
  })
  return result
}
