import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportMentionsQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  where: Types.MentionFilterInput;
  limit: Types.Scalars['Int'];
  sortBy: Types.MentionSort;
}>;


export type ExportMentionsQuery = { __typename: 'Query', mentions?: { __typename: 'PagedMentionType', csvUrl?: string | null } | null };


export const ExportMentionsDocument = gql`
    query ExportMentions($socialAccountId: ID!, $where: MentionFilterInput!, $limit: Int!, $sortBy: MentionSort!) {
  mentions(
    socialAccountId: $socialAccountId
    where: $where
    limit: $limit
    sortBy: $sortBy
    sortDirection: DESC
  ) {
    csvUrl
  }
}
    `;

/**
 * __useExportMentionsQuery__
 *
 * To run a query within a React component, call `useExportMentionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMentionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMentionsQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useExportMentionsQuery(baseOptions: Apollo.QueryHookOptions<ExportMentionsQuery, ExportMentionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMentionsQuery, ExportMentionsQueryVariables>(ExportMentionsDocument, options);
      }
export function useExportMentionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMentionsQuery, ExportMentionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMentionsQuery, ExportMentionsQueryVariables>(ExportMentionsDocument, options);
        }
export type ExportMentionsQueryHookResult = ReturnType<typeof useExportMentionsQuery>;
export type ExportMentionsLazyQueryHookResult = ReturnType<typeof useExportMentionsLazyQuery>;
export type ExportMentionsQueryResult = Apollo.QueryResult<ExportMentionsQuery, ExportMentionsQueryVariables>;