import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPrefHideSyncAlertQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPrefHideSyncAlertQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, preferences: { __typename: 'UserPreferences', id: string, hideSyncSocialAccountIds: Array<string> } } | null };


export const UserPrefHideSyncAlertDocument = gql`
    query UserPrefHideSyncAlert {
  whoami {
    id
    preferences @client {
      id
      hideSyncSocialAccountIds
    }
  }
}
    `;

/**
 * __useUserPrefHideSyncAlertQuery__
 *
 * To run a query within a React component, call `useUserPrefHideSyncAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPrefHideSyncAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPrefHideSyncAlertQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPrefHideSyncAlertQuery(baseOptions?: Apollo.QueryHookOptions<UserPrefHideSyncAlertQuery, UserPrefHideSyncAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPrefHideSyncAlertQuery, UserPrefHideSyncAlertQueryVariables>(UserPrefHideSyncAlertDocument, options);
      }
export function useUserPrefHideSyncAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPrefHideSyncAlertQuery, UserPrefHideSyncAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPrefHideSyncAlertQuery, UserPrefHideSyncAlertQueryVariables>(UserPrefHideSyncAlertDocument, options);
        }
export type UserPrefHideSyncAlertQueryHookResult = ReturnType<typeof useUserPrefHideSyncAlertQuery>;
export type UserPrefHideSyncAlertLazyQueryHookResult = ReturnType<typeof useUserPrefHideSyncAlertLazyQuery>;
export type UserPrefHideSyncAlertQueryResult = Apollo.QueryResult<UserPrefHideSyncAlertQuery, UserPrefHideSyncAlertQueryVariables>;