import React from 'react'
import { Tab, TabProps } from '@material-ui/core'
import { Link, LinkProps } from 'react-router-dom'

// Cannot use router.Link.to prop b/c material ui's Tabs component
// needs a value prop on all its children to work correctly.
// so instead of to use value as the location
//
// dont need `component` or `to` props from lib components, making `value` required in this case
type RouterTabProps = Omit<TabProps, 'component'> & Omit<LinkProps, 'to'> & { value: string }

function RouterTab({ value, ...props }: RouterTabProps): React.ReactElement {
  return <Tab component={Link} value={value} to={value} {...props} />
}

export default RouterTab
