import React, { createContext, Dispatch, useEffect, useReducer } from 'react'
import { initialMessageForm, MessageTemplateFormFields } from './messageSchema'
import { MessageTemplateFragmentFragment } from '../operations/message-template-fragment.generated'
import { AutomationFormFields, initialAutomationForm } from './automationSchema'
import { MessageTemplateInput, SkipMessageTemplateActionEnum } from '../../gql-global'

export type BuilderStepType =
  | 'messageForm'
  | 'messageFormSubmit'
  | 'automationBuilder'
  | 'automationBuilderSubmit'
  | 'review'
  | 'launch'
  | 'reset'
  | 'back'

interface DefaultBuilderType {
  messageTemplateId: string | null
  messageForm: MessageTemplateFormFields
  automationForm: AutomationFormFields
}

interface BuilderActionType extends DefaultBuilderType {
  type: BuilderStepType
  nextStep: BuilderStepType
}

interface BuilderStateType extends DefaultBuilderType {
  currentStep: BuilderStepType
}

function builderStepsReducer(state: BuilderStateType, action: BuilderActionType): BuilderStateType {
  switch (action.type) {
    case 'messageForm':
    case 'messageFormSubmit':
    case 'automationBuilder':
    case 'automationBuilderSubmit':
    case 'review':
    case 'launch':
    case 'reset':
    case 'back':
      return {
        ...state,
        ...action,
        currentStep: action.nextStep,
      }
    default:
      return state
  }
}

function useBuilderSteps(initialState: BuilderStateType): [BuilderStateType, Dispatch<BuilderActionType>] {
  return useReducer(builderStepsReducer, initialState)
}

const BuilderContext = createContext<BuilderStateType>({
  currentStep: 'messageForm',
  messageTemplateId: null,
  messageForm: initialMessageForm,
  automationForm: initialAutomationForm,
})

type BuilderProviderProps = {
  onSubmit(messageTemplateInput: MessageTemplateInput, messageTemplateId?: string | null): void
  children: (props: {
    handleMessageFormSubmit: (messageForm: MessageTemplateFormFields) => void
    handleAutomationFormSubmit: (automationForm: AutomationFormFields) => void
    handleAutomationBuilderOnBack: () => void
    handleMessageReviewOnBack: () => void
    handleReviewSubmit: () => void
    handleDialogClosed: () => void
    stepsState: BuilderStateType
  }) => React.ReactElement
  messageTemplate: MessageTemplateFragmentFragment | null
  socialAccountId: string
  clearTemplate: () => void
  initialStep: BuilderStepType
}

const skipMessageTimeBufferMap = {
  last24hours: 24,
  last7days: 164,
  last30days: 210,
} as const

export function BuilderProvider({
  children,
  onSubmit,
  messageTemplate,
  socialAccountId,
  clearTemplate,
  initialStep,
}: BuilderProviderProps): React.ReactElement {
  const [stepsState, dispatch] = useBuilderSteps({
    currentStep: 'messageForm',
    messageTemplateId: null,
    messageForm: { ...initialMessageForm, socialAccountId },
    automationForm: { ...initialAutomationForm },
  })
  const { messageTemplateId, messageForm, automationForm } = stepsState

  // If editing message
  //  - automation is off, and automation doesn't exist - Save; close modal
  //  - automation is off, and automation exists - Save; close modal
  //  - automation is on, and automation doesn't exist - Next; dispatch 'automationBuilder'
  //  - automation is on and automation exists - Next; dispatch 'review'
  function handleMessageFormSubmit(values: MessageTemplateFormFields): void {
    if (!values.automationEnabled) {
      const messageTemplateInput = {
        name: values.name,
        rewardId: values?.rewardId ? values?.rewardId.toString() : undefined,
        socialAccountId: socialAccountId,
        template: values.template,
        automationEnabled: values.automationEnabled,
      }
      onSubmit(messageTemplateInput, stepsState.messageTemplateId)
    } else if (values.automationEnabled && !messageTemplate?.automation) {
      dispatch({ ...stepsState, type: 'messageFormSubmit', nextStep: 'automationBuilder', messageForm: values })
    } else if (values.automationEnabled && messageTemplate?.automation) {
      dispatch({ ...stepsState, type: 'messageFormSubmit', nextStep: 'review', messageForm: values })
    }
  }

  function handleAutomationFormSubmit(values: AutomationFormFields): void {
    dispatch({ ...stepsState, type: 'automationBuilderSubmit', nextStep: 'review', automationForm: values })
  }

  function handleReviewSubmit(): void {
    let messageTemplateInput: MessageTemplateInput = {
      name: messageForm.name,
      rewardId: messageForm?.rewardId ? messageForm?.rewardId.toString() : undefined,
      socialAccountId: socialAccountId,
      template: messageForm.template,
      automationEnabled: messageForm.automationEnabled,
    }

    // If editing an existing template and are on the review screen, only
    // submit message form data from state since the automation can't be edited
    if (messageTemplate?.automation) {
      onSubmit(messageTemplateInput, stepsState.messageTemplateId)
    } else {
      // Otherwise, user is creating a new template with a new automation, so
      // include automation data from state on submit
      const skipSendMessage = automationForm.skipSendMessage || SkipMessageTemplateActionEnum.Never

      const timeBuffer = automationForm.skipActionTimeBuffer
      const skipActionTimeBuffer = timeBuffer !== 'ever' ? skipMessageTimeBufferMap[timeBuffer] : null
      messageTemplateInput = {
        ...messageTemplateInput,
        automation: {
          mentionFilter: {
            followerCount: automationForm.followerCount || undefined,
            biography:
              automationForm.biography && (automationForm.biography.any || automationForm.biography.none)
                ? {
                    any: automationForm.biography?.any || undefined,
                    none: automationForm.biography?.none || undefined,
                  }
                : undefined,
            segments:
              automationForm.segments && (automationForm.segments.any || automationForm.segments.none)
                ? {
                    any: automationForm.segments?.any || undefined,
                    none: automationForm.segments?.none || undefined,
                  }
                : undefined,
            campaigns:
              automationForm.campaigns && (automationForm.campaigns.any || automationForm.campaigns.none)
                ? {
                    any: automationForm.campaigns?.any || undefined,
                    none: automationForm.campaigns?.none || undefined,
                  }
                : undefined,
          },
          skipSendMessage,
          skipActionTimeBuffer,
          customerTriggerQuantity: automationForm.customerTriggerQuantity,
        },
      }

      onSubmit(messageTemplateInput, messageTemplateId)
      dispatch({
        ...stepsState,
        type: 'launch',
        nextStep: 'launch',
      })
    }
  }

  function handleDialogClosed(): void {
    dispatch({
      type: 'reset',
      nextStep: 'messageForm',
      messageTemplateId: null,
      messageForm: { ...initialMessageForm, socialAccountId },
      automationForm: { ...initialAutomationForm },
    })
    if (messageTemplate) {
      clearTemplate()
    }
  }

  function handleMessageReviewOnBack() {
    if (messageTemplate?.automation) {
      dispatch({ ...stepsState, type: 'messageForm', nextStep: 'messageForm' })
    } else {
      dispatch({ ...stepsState, type: 'automationBuilder', nextStep: 'automationBuilder' })
    }
  }

  function handleAutomationBuilderOnBack() {
    dispatch({ ...stepsState, type: 'messageForm', nextStep: 'messageForm' })
  }

  useEffect(() => {
    let messageForm = initialMessageForm
    if (messageTemplate) {
      let automationStatus = false
      if (messageTemplate.automation) {
        automationStatus = messageTemplate.automation?.enabled || false
      } else if (!messageTemplate.automation && initialStep === 'automationBuilder') {
        automationStatus = true
      }

      messageForm = {
        ...messageForm,
        socialAccountId,
        name: messageTemplate.name,
        template: messageTemplate.template,
        rewardId: messageTemplate.reward?.id.toString() || '',
        automationEnabled: automationStatus,
      }

      const automationForm = {
        ...initialAutomationForm,
        customerTriggerQuantity: messageTemplate.customerTriggerQuantity || 1,
      }

      if (messageTemplate.automation) {
        automationForm.skipSendMessage = messageTemplate.skipSendMessage
        automationForm.skipCustomers = messageTemplate.skipSendMessage !== SkipMessageTemplateActionEnum.Never
        if (automationForm.skipCustomers) {
          if (messageTemplate.skipActionTimeBuffer === skipMessageTimeBufferMap.last7days) {
            automationForm.skipActionTimeBuffer = 'last7days'
          } else if (messageTemplate.skipActionTimeBuffer === skipMessageTimeBufferMap.last30days) {
            automationForm.skipActionTimeBuffer = 'last30days'
          } else if (messageTemplate.skipActionTimeBuffer === skipMessageTimeBufferMap.last24hours) {
            automationForm.skipActionTimeBuffer = 'last24hours'
          } else if (!messageTemplate.skipActionTimeBuffer) {
            automationForm.skipActionTimeBuffer = 'ever'
          }
        }
      }

      if (messageTemplate.automation?.trigger?.filter?.value) {
        const filters = messageTemplate.automation?.trigger?.filter?.value
        automationForm.followerCount = filters?.followerCount
          ? {
              gte: filters.followerCount.gte || undefined,
              lte: filters.followerCount.lte || undefined,
            }
          : undefined
        automationForm.segments = filters?.segments
          ? {
              any: filters.segments.any || undefined,
              none: filters.segments.none || undefined,
            }
          : undefined
        automationForm.campaigns = filters?.campaigns
          ? {
              any: filters.campaigns.any || undefined,
              none: filters.campaigns.none || undefined,
            }
          : undefined
        automationForm.biography = filters?.biography
          ? {
              any: filters.biography.any || undefined,
              none: filters.biography.none || undefined,
            }
          : undefined
      }
      dispatch({
        type: initialStep,
        nextStep: initialStep,
        messageTemplateId: messageTemplate.id,
        messageForm: messageForm,
        automationForm,
      })
    }
  }, [dispatch, initialStep, messageTemplate, socialAccountId])

  return (
    <BuilderContext.Provider value={stepsState}>
      {children({
        handleMessageFormSubmit,
        handleAutomationFormSubmit,
        handleReviewSubmit,
        handleDialogClosed,
        handleMessageReviewOnBack,
        handleAutomationBuilderOnBack,
        stepsState,
      })}
    </BuilderContext.Provider>
  )
}

export default useBuilderSteps
