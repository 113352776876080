import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Button, Collapse, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core'
import ManualMessageBuilder from '../ManualMessageBuilder'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { ReactComponent as InfoIcon } from '../../icons/information-no-halo.svg'
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up_minor.svg'
import { Skeleton } from '@material-ui/lab'
import { IgSocialAccount } from '../../gql-global'
import MessageCollapsibleCard from './MessageCollapsibleCard'
import { useToast } from '../Alert/ToastProvider'
import { SentMessagesActivityByMentionQueryResult } from './operations/query-sent-messages-by-mention.generated'

const useStyles = makeStyles(({ palette: { primary, secondary } }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 480,
      overflow: 'hidden',
      height: '100%',
      alignItems: 'flex-start',
      '& .MuiCollapse-entered': {
        display: 'flex',
        flex: 'auto',
        '& .MuiButton-contained': {
          opacity: 1,
          transition: 'opacity 0.2s',
        },
      },
      '& .MuiButton-contained': {
        opacity: 0,
        transition: 'opacity 0.2s',
      },
    },
    toggleButton: {
      color: secondary.main,
      fontSize: 16,
      marginTop: 28,
      '& svg': {
        color: primary.main,
      },
    },
    caption: {
      margin: '-5px 16px -20px',
      color: secondary.main,
      display: 'block',
    },
    fakeInput: {
      '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        marginTop: 10,
        '& input': {
          textOverflow: 'ellipsis',
        },
      },
    },
    skeleton: {
      padding: '25px 15px',
      marginTop: -35,
    },
    noRepliesMessage: {
      display: 'flex',
      alignItems: 'center',
      color: secondary.main,
      marginTop: 20,
    },
  }),
)

interface PostDetailsMessageTabProps {
  mentionId: string
  customer?: { id: number } | null
  username?: string
  socialAccountId?: string
  mediaId?: string
  messageActivityQueryResults?: Pick<SentMessagesActivityByMentionQueryResult, 'data' | 'loading' | 'refetch'>
  isEditable: boolean
}

const PostDetailsMessageTab = (props: PostDetailsMessageTabProps): ReactElement => {
  const classes = useStyles()

  const { customer, username, socialAccountId, mentionId, isEditable, messageActivityQueryResults } = props

  const variables = { limit: 100, socialAccountId: socialAccountId ?? '', mentionId }
  const { data, loading, refetch } = messageActivityQueryResults || {}

  const messageHistory = (data?.socialAccount as IgSocialAccount)?.sentMessages
  const haveHistory = !!messageHistory?.total

  const [isWriting, setIsWriting] = useState<boolean>(isEditable)
  const [messageDraft, setMessageDraft] = useState<string>('')
  const { showToast } = useToast()
  const scrollable = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollable.current) {
      const { scrollHeight } = scrollable.current
      scrollable.current.scrollTop = scrollHeight
    }
  }, [isWriting, messageHistory])

  const handleOnMessageSent = async () => {
    showToast({
      title: `Success: You replied to @${username}’s story`,
      message: 'Way to go!',
      severity: 'success',
      autoHideDuration: 5000,
    })

    if (refetch) {
      refetch(variables).then(() => {
        setMessageDraft('')
        setIsWriting(false)
      })
    }
  }

  const handleOnMessageFailed = async () => {
    showToast({
      title: 'Error: Sending Direct Message',
      message: 'Something went wrong when when sending this message, please try again',
      severity: 'error',
    })
  }

  return (
    <Box position="relative" className={classes.wrapper}>
      {loading ? (
        <Box px={3} py={5} width="100%">
          <Skeleton height={40} width={200} />
          <Skeleton width={300} />
          <Skeleton width={300} />
          <Skeleton className={classes.skeleton}>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </Skeleton>
        </Box>
      ) : (
        <>
          {haveHistory && (
            <Button
              onClick={() => setIsWriting(!isWriting)}
              className={classes.toggleButton}
              disabled={!isEditable}
              endIcon={isWriting ? <ChevronDownIcon width={20} /> : <ChevronUpIcon width={20} />}
            >
              View message history
            </Button>
          )}
          <Collapse in={!isWriting} mountOnEnter unmountOnExit>
            <Box px={4} mt={3} display="flex" flexDirection="column" justifyContent="space-between" width={480}>
              <div ref={scrollable} style={{ height: 530, overflow: 'auto' }}>
                {messageHistory?.results?.map(message => (
                  <MessageCollapsibleCard key={message.id} message={message} />
                ))}
                {!isEditable && (
                  <Box className={classes.noRepliesMessage}>
                    <InfoIcon width={20} />
                    No replies can be sent because this history has expired.
                  </Box>
                )}
              </div>
              {isEditable && (
                <TextField
                  variant="outlined"
                  value={messageDraft}
                  onClick={() => setIsWriting(true)}
                  placeholder={`Reply to @${username}'s story`}
                  className={classes.fakeInput}
                />
              )}
            </Box>
          </Collapse>
          <Collapse in={isWriting} mountOnEnter unmountOnExit={!messageDraft}>
            {haveHistory && (
              <Typography variant="caption" className={classes.caption}>
                {messageHistory?.total} other replies
              </Typography>
            )}
            {customer && username && socialAccountId && (
              <ManualMessageBuilder
                socialAccountId={socialAccountId}
                username={username}
                customerId={customer?.id.toString()}
                onSuccess={handleOnMessageSent}
                onError={handleOnMessageFailed}
                onMessageChange={setMessageDraft}
              />
            )}
          </Collapse>
        </>
      )}
    </Box>
  )
}

export default PostDetailsMessageTab
