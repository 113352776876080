import React, { PropsWithChildren, useRef, useState } from 'react'
import { makeStyles, createStyles, Theme, FormControl, Menu } from '@material-ui/core'
import ClickableCardField from '../ClickableCardField'
import { KeywordBuilder } from '../lists/ListFilters/KeywordFilterMenu'
import { KeywordsOption } from '../lists/ListFilters/types'
import MenuHeader from '../MenuHeader'
import { CriteriaOperatorEnum } from '../../gql-global'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 32,
      fontWeight: 500,
    },
    form: {
      minWidth: '225px',
    },
    input: {
      width: '225px',
    },
    field: {
      marginTop: '-40px',
      '&:before': {
        border: 'none',
      },
      '&:after': {
        border: 'none',
      },
      '&:not(.Mui-disabled):hover:before': {
        border: 'none',
      },
    },
  }),
)

interface CardTextInputProps {
  value?: string[] | null
  label?: string
  setValue: (value: string[]) => void
  resetValue?: () => string[]
  isValueSelected: () => boolean
  forceShowCheckmark?: boolean
  keywordsOption?: { allowSpaces?: boolean; allowQuotes?: boolean; prependChar?: string }
  disabled?: boolean
  showCriteriaOperatorToggle?: boolean
  setCriteriaOperator?: (val: CriteriaOperatorEnum) => void
  criteriaOperatorValue?: CriteriaOperatorEnum
  criteriaOperatorLabel?: string
  renderCriteriaOperatorHelpText?: (val: CriteriaOperatorEnum) => string
}

function CardTextInput({
  value,
  label,
  setValue,
  resetValue,
  isValueSelected,
  children,
  forceShowCheckmark = false,
  keywordsOption,
  disabled = false,
  showCriteriaOperatorToggle = false,
  setCriteriaOperator,
  criteriaOperatorValue,
  criteriaOperatorLabel,
  renderCriteriaOperatorHelpText,
}: PropsWithChildren<CardTextInputProps>): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false)
  const chipRef = useRef<HTMLDivElement>(null)

  const classes = useStyles()

  function handleChange(v: string[]): void {
    setValue(v)
    handleClose()
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const option = {
    label,
    type: 'keywords',
    includeField: 'any',
    useChips: true,
    ...keywordsOption,
  } as KeywordsOption

  return (
    <>
      <ClickableCardField
        onCardClicked={toggleOpen}
        showCheck={forceShowCheckmark || isValueSelected()}
        onReset={resetValue}
        disabled={disabled}
      >
        {children}
      </ClickableCardField>
      <FormControl className={classes.form} ref={chipRef}>
        <Menu
          open={open}
          anchorEl={chipRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={handleClose}
          className={classes.form}
        >
          <MenuHeader title={option.label} />
          <KeywordBuilder
            keywords={value}
            onApply={keywords => handleChange(keywords)}
            showClear={!!resetValue}
            option={option}
            className={classes.input}
            showCriteriaOperatorToggle={showCriteriaOperatorToggle}
            setCriteriaOperator={setCriteriaOperator}
            criteriaOperatorValue={criteriaOperatorValue}
            criteriaOperatorLabel={criteriaOperatorLabel}
            renderCriteriaOperatorHelpText={renderCriteriaOperatorHelpText}
          />
        </Menu>
      </FormControl>
    </>
  )
}

export default CardTextInput
