import React from 'react'
import {
  addDays,
  addHours,
  isYesterday,
  isToday,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
  format,
} from 'date-fns'
import { createStyles, makeStyles, Theme, Tooltip, Box, Typography } from '@material-ui/core'
import { ReactComponent as ClockIcon } from '../../icons/clock-filled.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expirationDisplay: {
      color: theme.palette.secondary.main,
      fontSize: 12,
    },
    timeLeftBlue: {
      color: theme.palette.info.main,
    },
    timeLeftYellow: {
      color: theme.palette.warning.dark,
    },
    timeLeftRed: {
      color: theme.palette.error.main,
    },
  }),
)

interface ExpirationDisplayProps {
  postedAt: Date
  children?: JSX.Element
  className?: string
  clockRight?: boolean
}
function ExpirationDisplay({ postedAt, children, className, clockRight }: ExpirationDisplayProps): JSX.Element {
  const classes = useStyles()
  const now = new Date()
  const expiration = addDays(postedAt, 1)
  const expired = now > expiration
  const postedToday = isToday(postedAt)
  const postedYesterday = isYesterday(postedAt)

  const duration = formatDuration(
    intervalToDuration({
      start: now,
      end: expiration,
    }),
    { format: ['hours', 'minutes'] },
  )

  const expirationString = duration
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .concat(' left')

  const timeLeftClassName = expired
    ? undefined
    : addHours(now, 1) > expiration
    ? classes.timeLeftRed
    : addHours(now, 12) > expiration
    ? classes.timeLeftYellow
    : classes.timeLeftBlue

  return (
    <Box display="flex" alignItems="center" className={`${classes.expirationDisplay} ${className}`}>
      <Box mr={2} className={timeLeftClassName} display="flex" alignItems="center">
        <ClockIcon width={16} height={16} />
      </Box>
      <Tooltip title={format(postedAt, 'PPp')} placement="top-start">
        <Box mr={2} display="flex" alignItems="center">
          {postedToday && <Typography variant="caption">Today</Typography>}
          {postedYesterday && <Typography variant="caption">Yesterday</Typography>}
          {!postedYesterday && !postedToday && (
            <Typography variant="caption">
              {formatDistanceToNow(postedAt, { addSuffix: true }).replace('about ', '')}
            </Typography>
          )}
        </Box>
      </Tooltip>
      <Box mr={2} className={timeLeftClassName} display="flex" alignItems="center">
        {expired && <Typography variant="caption">Expired</Typography>}
        {!expired && <Typography variant="caption">{expirationString}</Typography>}
      </Box>
      {children}
    </Box>
  )
}

export default ExpirationDisplay
