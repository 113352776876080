import React, { useState, useEffect } from 'react'
import { Box, Button, IconButton, makeStyles, createStyles, Typography } from '@material-ui/core'
import { TextField } from '../TextField/TextField'
import { ReactComponent as ArrowIcon } from '../../icons/arrow-skinny.svg'
import { error } from '../../loudcrowd-theme'
import { AccountLabel } from './LabelMenu'

interface EditLabelProps<T extends string | number> {
  id: T
  name?: string | null
  labels: AccountLabel<T>[]
  canDelete: boolean
  onCancel(): void
  onSave(id: T, newName: string): void
  onDelete(id: T): void
}
const useStyles = makeStyles(theme =>
  createStyles({
    textField: {
      width: '100%',
    },
    deleteButton: {
      color: error[600],
      borderColor: error[600],
    },
    deleteButtonContained: {
      color: theme.palette.common.white,
      backgroundColor: error[600],
    },
    header: {
      height: 60,
      paddingTop: 20,
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
)

function EditLabel<T extends string | number>({
  id,
  name,
  labels,
  onCancel,
  onSave,
  onDelete,
  canDelete,
}: EditLabelProps<T>): React.ReactElement {
  const classes = useStyles()
  const [newName, setNewName] = useState('')
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  useEffect(() => {
    if (name) setNewName(name)
  }, [name])

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setNewName(value)
  }

  return (
    <Box width={392} px={5} display="flex" flexDirection="column">
      <Box className={classes.header}>
        <IconButton color="secondary" onClick={() => onCancel()}>
          <ArrowIcon width={20} height={16} />
        </IconButton>
        <Typography variant="subtitle1">{deleteConfirmationOpen ? 'Delete' : 'Edit'} Label</Typography>
        <Box width={44} />
      </Box>
      {!deleteConfirmationOpen && (
        <Box>
          <Box mt={5}>
            <TextField className={classes.textField} value={newName} onChange={onTextChange} autoFocus />
          </Box>
          <Box my={5} display="flex" justifyContent="space-between">
            {canDelete && (
              <Button
                variant="outlined"
                className={classes.deleteButton}
                onClick={() => setDeleteConfirmationOpen(true)}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSave(id, newName)}
              disabled={labels.some(l => l.name === newName)}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
      {deleteConfirmationOpen && (
        <Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body2">Are you sure you want to delete this label?</Typography>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body2">(Deleting it will also remove it from any posts.)</Typography>
          </Box>
          <Box my={5} display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={() => setDeleteConfirmationOpen(false)} color="secondary">
              Oops! No, Cancel!
            </Button>
            <Button variant="contained" onClick={() => onDelete(id)} className={classes.deleteButtonContained}>
              Delete Label
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default EditLabel
