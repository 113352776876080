import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
export type AccountRowFragment = { __typename: 'AccountType', id: number, name: string, createdAt: Date, organization: { __typename: 'Organization', id: string, stripeCustomerId?: string | null, isTrialing?: boolean | null, trialDaysRemaining?: number | null, userLimit: { __typename: 'UserLimit', used: number, limit?: number | null, isUnlimited: boolean, hasHitLimit: boolean }, plans: Array<{ __typename: 'AccountPlanType', id: string, stripeProducts?: Array<{ __typename: 'StripeProduct', id: string, name: string }> | null }> } };

export const AccountRowFragmentDoc = gql`
    fragment AccountRow on AccountType {
  id
  name
  createdAt
  organization {
    id
    stripeCustomerId
    isTrialing
    trialDaysRemaining
    userLimit {
      used
      limit
      isUnlimited
      hasHitLimit
    }
    plans {
      id
      stripeProducts {
        id
        name
      }
    }
  }
}
    `;