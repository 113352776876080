import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { Box } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import { ADMIN_ROUTE } from './admin/routes'
import { CONTENT_ROUTE } from './content/routes'
import { CUSTOMER_ROUTE } from './customer/routes'
import { DASHBOARD_ROUTE } from './dashboard/routes'
import { HOME_ROUTE } from './home/routes'
import { INTEGRATIONS_ROUTE } from './integrations/routes'
import { REVIEW_ROUTE } from './review/routes'
import { MESSAGE_ROUTE } from './message/routes'
import { BILLING_MANAGEMENT_ROUTE, SETTINGS_ROUTE, USER_MANAGEMENT_ROUTE } from './settings/routes'
import { CAMPAIGN_ROUTE } from './campaign/routes'
import { CHALLENGES_ROUTE } from './challenges/routes'
import Content from './content/Content'
import Customer from './customer/Customer'
import Dashboard from './dashboard/Dashboard'
import Messages from './message/Messages'
import Challenges from './challenges/Challenges'
import Home from './home/Home'
import Integrations from './integrations/Integrations'
import NavSidebar from './components/NavSidebar/NavSidebar'
import Review from './review/Review'
import Settings from './settings/Settings'
import Campaign from './campaign/Campaign'
import TopBar from './components/TopBar/TopBar'
import { useUserInfoQuery } from './queries/operations/user-info.generated'

import AdminContainer from './admin/AdminContainer'
import withAuthentication from './hoc/withAuthentication'
import WithUpsellModal from './components/UpsellModal'
import { UserRoleType } from './gql-global'
import { BETA_TESTER_ROLE_NAME } from './settings/users/constants'

const Container: React.FC = () => {
  const { data, loading, error: userInfoError } = useUserInfoQuery()
  if (data?.whoami) {
    const { id, email, account, roles } = data?.whoami
    const stripeProductNames: string[] = []
    account?.organization?.plans?.forEach(p => {
      if (p.stripeProducts) {
        p.stripeProducts?.forEach(sp => stripeProductNames.push(sp.name))
      }
    })
    Sentry.setUser({ id: id.toString(), email })

    if (pendo && process.env.REACT_APP_PENDO_API_KEY) {
      pendo.initialize({
        visitor: {
          id: email,
          email: email,
          roleOwner: roles?.some(r => r.name === UserRoleType.Owner) || null,
          roleBetaTester: roles?.some(r => r.name === UserRoleType.BetaTester) || null,
        },

        account: {
          id: account?.id?.toString() || '',
          name: account?.name || null,
          //see https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo
          //for more options
        },
      })
    }
  }

  const roles = data?.whoami?.roles?.map(r => r.name) || []
  const products = data?.whoami?.account?.organization.activeProducts || []
  const username = data?.whoami?.email || ''
  const billingMatch = useRouteMatch(BILLING_MANAGEMENT_ROUTE.path)
  const userMgmtMatch = useRouteMatch(USER_MANAGEMENT_ROUTE.path)
  if (!loading && !userInfoError && !products.length && !(billingMatch || userMgmtMatch)) {
    // redirect to billing
    return <Redirect to={BILLING_MANAGEMENT_ROUTE.path} />
  }

  return (
    <WithUpsellModal>
      <Box display="flex">
        <Box flexGrow={0}>
          <NavSidebar
            products={products}
            roles={roles}
            loading={loading}
            username={username}
            isFbTester={data?.whoami?.isFbTester}
            accountId={data?.whoami?.account?.id || null}
          />
        </Box>
        <Box flexGrow={1}>
          <TopBar
            email={data?.whoami?.email}
            loading={loading}
            organization={data?.whoami?.account?.organization || null}
            isBetaTester={data?.whoami?.roles?.some(r => r.name === BETA_TESTER_ROLE_NAME)}
          />
          <Box paddingY={9}>
            <Switch>
              <Route exact path={HOME_ROUTE.path} component={Home} />
              <Route path={DASHBOARD_ROUTE.path} component={Dashboard} />
              <Route path={MESSAGE_ROUTE.path} component={Messages} />
              <Route path={CONTENT_ROUTE.path} component={Content} />
              <Route path={CHALLENGES_ROUTE.path} component={Challenges} />
              <Route path={CUSTOMER_ROUTE.path} component={Customer} />
              <Route path={['/settings/integrations', '/admin/integrations']}>
                <Redirect to={INTEGRATIONS_ROUTE.path} />
              </Route>
              <Route path={REVIEW_ROUTE.path} component={Review} />
              <Route path={SETTINGS_ROUTE.path} component={Settings} />
              <Route path={INTEGRATIONS_ROUTE.path} component={Integrations} />
              <Route path={ADMIN_ROUTE.path} component={AdminContainer} />
              <Route path={CAMPAIGN_ROUTE.path} component={Campaign} />
            </Switch>
          </Box>
        </Box>
      </Box>
    </WithUpsellModal>
  )
}

export default withAuthentication()(Container)
