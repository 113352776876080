import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type CampaignRowFragment = { __typename: 'CampaignType', id: number, isActive: boolean, startAt: Date, program?: { __typename: 'ProgramType', id: string, name: string, currencyCode?: Types.CurrencyCodeEnum | null } | null, mentionStats?: { __typename: 'CampaignMentionStats', posts: number, engagement: number } | null, participantStats?: { __typename: 'ParticipantStats', count: number } | null };

export const CampaignRowFragmentDoc = gql`
    fragment CampaignRow on CampaignType {
  id
  isActive
  startAt
  program {
    id
    name
    currencyCode
  }
  mentionStats(where: {socialAccountId: {any: [$socialAccountId]}}) {
    posts
    engagement
  }
  participantStats(where: {status: {any: [APPROVED]}, platform: $platform}) {
    count
  }
}
    `;