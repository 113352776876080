import React, { useCallback, useState } from 'react'

import { Box, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import {
  GetPresignedUrlForRewardCodeUploadQuery,
  GetPresignedUrlForRewardCodeUploadQueryVariables,
  GetPresignedUrlForRewardCodeUploadDocument,
} from './operations/get-presigned-upload-url.generated'
import { useApolloClient } from '@apollo/client'
import { ReactComponent as FileIcon } from '../../icons/file.svg'
import { uploadFileToS3 } from '../../utils/aws'
import { useToast } from '../../components/Alert/ToastProvider'
import { ReactComponent as CrossMark } from '../../icons/cross.svg'
import { UploadedFile } from '../../global'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      backgroundColor: theme.palette.secondary.light,
      border: '2px dashed #BCBBD1',
      boxSizing: 'border-box',
      borderRadius: 8,
    },
    looksLikeLink: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: theme.typography.fontWeightBold,
    },
    disabledText: {
      color: theme.palette.text.disabled,
    },
    successText: {
      color: theme.palette.success.main,
    },
    errorText: {
      color: theme.palette.error.main,
      float: 'left',
    },
    errorContainer: {
      display: 'flex',
      paddingLeft: '50px',
    },
    circle: {
      display: 'flex',
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: theme.palette.error.main,
      marginRight: '4px',
    },
    errorIcon: {
      color: theme.palette.error.main,
      margin: 'auto',
    },
    instructions: {
      marginTop: '0px',
      marginLeft: '-16px',
    },
  }),
)
interface UploadRewardCodesProps {
  accountId: number | null
  onSelectFile(file: UploadedFile): void
}

const fileToText = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function validateDiscountCodeContent(data: string): boolean {
  const firstLine = data.split('\n')[0]
  if (firstLine) {
    const columns = firstLine.split(',')
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i]?.trim()
      if (!column) {
        return false
      }
      if (column.toLowerCase() === 'code') {
        return true
      }
    }
  }
  return false
}

async function validDiscountCodes(file: File) {
  return new Promise((resolve, reject) => {
    fileToText(file)
      .then(data => {
        if (typeof data === 'string') {
          resolve(validateDiscountCodeContent(data))
        }
        reject(new Error("'unexpected error reading file'"))
      })
      .catch(err => {
        reject(err)
      })
  })
}

const UploadRewardCodes: React.FC<UploadRewardCodesProps> = ({ onSelectFile, accountId }) => {
  const classes = useStyles()
  const client = useApolloClient()
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null)
  const [error, setError] = useState<boolean>(false)
  const { showToast, settings } = useToast()

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      if (!file) {
        return
      }

      try {
        const valid = await validDiscountCodes(file)
        if (!valid) {
          setError(true)
          return
        }
      } catch {
        setError(true)
        return
      }

      setUploadedFile({ name: file.name || '', loading: true, bucketKey: '' })

      client
        .query<GetPresignedUrlForRewardCodeUploadQuery, GetPresignedUrlForRewardCodeUploadQueryVariables>({
          fetchPolicy: 'no-cache',
          query: GetPresignedUrlForRewardCodeUploadDocument,
          variables: {
            accountId: accountId?.toString() || '',
          },
        })
        .then(async data => {
          const presignedUrl = data.data.account?.presignedUrlForRewardCodeUpload
          const keyField = data.data.account?.presignedUrlForRewardCodeUpload?.fields?.find(f => f.key === 'key')
          if (presignedUrl && keyField) {
            try {
              await uploadFileToS3(presignedUrl, file)
              console.log('File was successfully uploaded!')
              const newFile: UploadedFile = {
                name: acceptedFiles[0]?.name || 'unknown file name',
                loading: false,
                bucketKey: keyField.value,
              }
              setUploadedFile(newFile)
              onSelectFile(newFile)
            } catch (e) {
              showToast({
                title: 'Uh Oh! It looks like we had a problem uploading that file.',
                message: 'Please try uploading that again.',
                severity: 'error',
              })
            }
          }
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, client, onSelectFile],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['.csv', 'text/csv'],
    onDrop,
  })

  return (
    <Box>
      <Box
        {...getRootProps()}
        width={348}
        height={132}
        px={8}
        className={classes.dropzone}
        display="flex"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
      >
        {(!uploadedFile || uploadedFile.loading || settings?.severity === 'error') && !error && (
          <>
            <input {...getInputProps()} />
            {isDragActive && <Typography variant="body1">Drop the CSV file here ...</Typography>}
            {!isDragActive && (
              <Typography variant="body1" className={uploadedFile?.loading ? classes.disabledText : ''}>
                Drag and drop CSV of reward codes or <span className={classes.looksLikeLink}>browse</span> your files
              </Typography>
            )}
          </>
        )}
        {uploadedFile?.loading === false && uploadedFile?.bucketKey && settings?.severity !== 'error' && !error && (
          <Typography variant="body1" className={classes.successText}>
            Upload Successful!
          </Typography>
        )}
        {error && (
          <Box className={classes.errorContainer}>
            <Box>
              <div className={classes.circle}>
                <CrossMark className={classes.errorIcon} />
              </div>
            </Box>
            <Box>
              <Typography variant="body1" className={classes.errorText}>
                Upload unsuccessful.
              </Typography>
              <Typography variant="body1" className={classes.errorText}>
                Please try again.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" my={3}>
        {uploadedFile && (
          <Box display="flex">
            <FileIcon width={20} height={24} />
            <Box ml={4} display="flex" flexDirection="column">
              {uploadedFile.name}
              {uploadedFile.loading && <LinearProgress variant="indeterminate" color="primary" />}
              {uploadedFile.loading === false && uploadedFile.bucketKey && (
                <LinearProgress variant="determinate" color="primary" value={100} />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Typography>Please format your .csv file in this way:</Typography>
      <ul className={classes.instructions}>
        <li>Include a header row (ie. Code, Pin).</li>
        <li>The Code column is required.</li>
        <li>The Pin column is optional.</li>
        <li>All other columns are ignored.</li>
      </ul>
    </Box>
  )
}

export default UploadRewardCodes
