import { HOME_ROUTE } from '../home/routes'
import { LAST_PATH_KEY } from './constants'

export function popRedirectUrl(): string {
  const url = localStorage.getItem(LAST_PATH_KEY) || HOME_ROUTE.path
  localStorage.removeItem(LAST_PATH_KEY)
  return url
}

export function setRedirectUrl(): void {
  const { pathname, search } = window.location
  localStorage.setItem(LAST_PATH_KEY, `${pathname}${search}`)
}
