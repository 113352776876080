import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountProgramsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy: Types.ProgramSort;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.ProgramFilterInput>;
}>;


export type AccountProgramsQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, programs?: { __typename: 'PagedProgramType', cursor?: string | null, total: number, results: Array<{ __typename: 'ProgramType', id: string, name: string, campaign?: { __typename: 'CampaignType', id: number, isActive: boolean } | null }> } | null } | null } | null };


export const AccountProgramsDocument = gql`
    query AccountPrograms($limit: Int, $sortBy: ProgramSort!, $sortDirection: SortDirection, $cursor: String, $where: ProgramFilterInput) {
  whoami {
    id
    account {
      id
      programs(
        limit: $limit
        sortBy: $sortBy
        sortDirection: $sortDirection
        cursor: $cursor
        where: $where
      ) {
        cursor
        total
        results {
          id
          name
          campaign {
            id
            isActive
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAccountProgramsQuery__
 *
 * To run a query within a React component, call `useAccountProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountProgramsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAccountProgramsQuery(baseOptions: Apollo.QueryHookOptions<AccountProgramsQuery, AccountProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountProgramsQuery, AccountProgramsQueryVariables>(AccountProgramsDocument, options);
      }
export function useAccountProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountProgramsQuery, AccountProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountProgramsQuery, AccountProgramsQueryVariables>(AccountProgramsDocument, options);
        }
export type AccountProgramsQueryHookResult = ReturnType<typeof useAccountProgramsQuery>;
export type AccountProgramsLazyQueryHookResult = ReturnType<typeof useAccountProgramsLazyQuery>;
export type AccountProgramsQueryResult = Apollo.QueryResult<AccountProgramsQuery, AccountProgramsQueryVariables>;