import React from 'react'
import { styled } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FilterChip from '../lists/ListFilters/FilterChip'
import { secondary } from '../../loudcrowd-theme'

const StyledChip = styled(FilterChip)(() => ({
  '& .MuiChip-deleteIconSmall': {
    width: 8,
    height: 8,
  },
  '& .MuiChip-icon': {
    color: secondary[600],
  },
}))

interface KeywordChipsProps {
  keywords: string[]
  onApply(newKeywords: string[]): void
  id: string
  editable?: boolean
}

const KeywordChips = ({ keywords = [], onApply, id, editable = true }: KeywordChipsProps): React.ReactElement => {
  return (
    <Autocomplete<string>
      multiple
      freeSolo
      id={id}
      options={[]}
      inputValue={editable ? undefined : ''}
      value={keywords}
      onChange={(_: unknown, value: string[]) => {
        onApply(value)
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.includes(',')) {
          const newValue = newInputValue.replace(/,/g, '')
          onApply([...keywords, newValue])
        }
      }}
      renderTags={(value: string[], getTagProps): React.ReactElement[] =>
        value.map((option: string, index: number) => (
          <StyledChip
            variant="outlined"
            size="small"
            label={option}
            style={{
              backgroundColor: secondary[300],
            }}
            {...getTagProps({ index })}
            {...(!editable && {
              onDelete: undefined,
            })}
          />
        ))
      }
      renderInput={params => <TextField {...params} variant="outlined" fullWidth disabled={!editable} />}
      open={false}
      disableClearable={!editable}
      blurOnSelect
    />
  )
}

export default KeywordChips
