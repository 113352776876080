import React from 'react'
import { differenceInMinutes, formatDistance, formatDistanceToNow } from 'date-fns'
import { Box, Link, TableCell, TableRow } from '@material-ui/core'
import { REVIEW_ROUTE } from './routes'
import { Link as RouterLink } from 'react-router-dom'
import { ChallengeStatusEnum } from '../gql-global'
import { conditionalDateRangeFormat } from '../utils/date-range-helper'
import { getNumericString } from '../utils/number-format'
import { ChallengeReviewFragment } from './operations/challenge-review-fragment.generated'

interface ReviewChallengeRowProps {
  currentUserId?: number
  challenge: ChallengeReviewFragment
}

const ReviewChallengeRow: React.FC<ReviewChallengeRowProps> = ({ currentUserId, challenge }) => {
  const lockedByEmail = challenge.reviewLock?.user?.email
  const lockedAtTimestamp = challenge.reviewLock?.updatedAt
  const currentUserOwnsLock = !challenge.reviewLock?.user?.id || challenge.reviewLock?.user?.id === currentUserId
  const unlocked =
    !lockedAtTimestamp ||
    currentUserOwnsLock ||
    (lockedAtTimestamp && differenceInMinutes(new Date(), lockedAtTimestamp) > 15)

  const renderChallengeNameBox = (): React.ReactElement => (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box p={2} color="text.secondary">
          {challenge.name}
        </Box>
        {!unlocked && lockedAtTimestamp && (
          <Box p={2} color="maroon">
            Locked by {lockedByEmail}, {formatDistance(lockedAtTimestamp, new Date())} ago.
          </Box>
        )}
      </Box>
    </>
  )

  const renderChallengeNameLink = () => (
    <Link to={`${REVIEW_ROUTE.path}/challenge/${challenge.id}`} component={RouterLink}>
      {renderChallengeNameBox()}
    </Link>
  )

  const hasDates = challenge.startedAt && challenge.endedAt
  const challengeHasLaunched =
    challenge.currentStatus !== ChallengeStatusEnum.Draft && challenge.currentStatus !== ChallengeStatusEnum.Upcoming

  const socialAccountsText = challenge.socialAccounts?.map(sa => sa.socialUser?.username).join(', ')
  return (
    <TableRow hover>
      <TableCell>{unlocked ? renderChallengeNameLink() : renderChallengeNameBox()}</TableCell>
      <TableCell>
        {hasDates ? conditionalDateRangeFormat(challenge.startedAt as Date, challenge.endedAt as Date) : 'No dates set'}
      </TableCell>
      <TableCell>{socialAccountsText}</TableCell>
      <TableCell>
        {!!challengeHasLaunched &&
          !!challenge.reviewPendingPosts &&
          getNumericString(challenge.reviewPendingPosts.total)}
        {!challengeHasLaunched && '--'}
      </TableCell>
      <TableCell>
        {challenge.reviewLock?.updatedAt
          ? formatDistanceToNow(challenge.reviewLock?.updatedAt, { addSuffix: true })
          : '---'}
      </TableCell>
      <TableCell>{challenge.reviewLock?.user?.email ?? '---'}</TableCell>
    </TableRow>
  )
}

export default ReviewChallengeRow
