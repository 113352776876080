import React, { useState, useRef } from 'react'
import { Menu } from '@material-ui/core'
import MenuHeader from '../../MenuHeader'
import JoinedChildren from '../../JoinedChildren'
import { SelectionOption } from './types'
import FilterChip, { FilterChipText } from './FilterChip'
import { SelectionList } from '../../SelectionPicker/SelectionList'

interface SelectionFilterChipProps<T extends string> {
  option: SelectionOption<T>
  value: Set<T>
  onDelete(): void
  onSelectValue(newValue: Set<T>): void
}

function nonNullFilter<T>(a: Array<T | undefined | null>): Array<T> {
  return a.filter((v: T | undefined | null): v is T => v !== undefined && v !== null)
}

export function SelectionFilterChip<T extends string>({
  option,
  value,
  onDelete,
  onSelectValue,
}: SelectionFilterChipProps<T>): React.ReactElement {
  const [menuOpen, setMenuOpen] = useState(false)
  const chipRef = useRef<HTMLDivElement>(null)

  function handleSelectionChanged(newSelections: Set<T>): void {
    // if the value and the new value set's are different then callback
    onSelectValue(newSelections)
    setMenuOpen(false)
  }

  const selectionValues = nonNullFilter(Array.from(value).map(v => option.selectionOptions.find(so => so.id === v)))

  return (
    <>
      <FilterChip ref={chipRef} onClick={() => setMenuOpen(true)} onDelete={onDelete}>
        <FilterChipText bold text={option.label} />
        <FilterChipText text="is" />
        <JoinedChildren joinElement={<FilterChipText text="or" />}>
          {selectionValues.map(v => (
            <FilterChipText bold text={v.label} key={v.id} />
          ))}
        </JoinedChildren>
      </FilterChip>
      <Menu
        open={menuOpen}
        anchorEl={chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMenuOpen(false)}
      >
        <SelectionList
          options={option.selectionOptions}
          selectedOptions={value}
          onChangeOptions={handleSelectionChanged}
          selectionRequired={true}
        />
      </Menu>
    </>
  )
}

interface SelectionFilterMenuProps<T extends string> {
  option: SelectionOption<T>
  onSelectValue(newValue: Set<T>): void
  onBack(): void
  value?: Set<T>
}

function SelectionFilterMenu<T extends string>({
  option,
  onSelectValue,
  onBack,
  value,
}: SelectionFilterMenuProps<T>): React.ReactElement {
  return (
    <div>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <SelectionList
        options={option.selectionOptions}
        onChangeOptions={onSelectValue}
        selectedOptions={value}
        selectionRequired={true}
      />
    </div>
  )
}

export default SelectionFilterMenu
