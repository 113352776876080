import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { isToday, format, isYesterday, formatRelative } from 'date-fns'
import { secondary } from '../../loudcrowd-theme'
import MessagePhoneMedia from './MessagePhoneMedia'
import StoriesAvatarV2 from '../CustomerAvatar/StoriesAvatarV2'

const useStyles = makeStyles(theme => ({
  phone: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 50,
    width: 319,
    height: 700,
    marginTop: 10,
    boxShadow: '0px 10px 20px rgba(37, 36, 103, 0.1)',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    boxShadow: '0px 10px -10px rgba(37, 36, 103, 0.1)',
    height: 76,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  previewText: {
    fontWeight: 600,
    fontSize: 11,
    marginBottom: 2,
  },
  body: {
    marginTop: 15,
    marginBottom: 50,
    paddingLeft: 15,
    paddingRight: 15,
    overflowY: 'scroll',
  },
  justNowText: {
    fontWeight: 600,
    color: secondary[600],
    textAlign: 'center',
    fontSize: 11,
  },
  replyText: {
    fontWeight: 600,
    color: secondary[600],
    marginTop: 15,
    textAlign: 'right',
    fontSize: 12,
  },
  messageBubble: {
    borderRadius: 20,
    backgroundColor: secondary[100],
    padding: 15,
    wordBreak: 'break-word',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 15,
  },
}))

export interface MessagePhonePreviewProps {
  loading?: boolean
  mentionedSocialUser?: {
    avatarUrl?: string | null
    username: string
  } | null
  message?: string | null
  media?: {
    id?: string | null
    lcMediaUrl?: string | null
    mediaUrl?: string | null
    mediaType?: string | null
    postType?: string | null
    fromAuthedApi?: boolean | null
    isExpired?: boolean | null
    permalink?: string | null
    __typename: string
  } | null
  customerPreview?: boolean
  createdAt?: Date
}

function MessagePhonePreview({
  mentionedSocialUser,
  message,
  media,
  loading = false,
  customerPreview,
  createdAt,
}: MessagePhonePreviewProps): React.ReactElement {
  const classes = useStyles()

  const formatDate = (date: Date) => {
    if (isToday(date)) {
      return format(date, 'p')
    }
    return (isYesterday(date) ? formatRelative(date, new Date()) : format(date, 'E dd, p')).toUpperCase()
  }

  return (
    <Box className={classes.phone}>
      <Box className={classes.header}>
        <StoriesAvatarV2 size="xsmall" loading={loading} avatarUrl={mentionedSocialUser?.avatarUrl} />
        <Typography variant="body2" className={classes.previewText}>
          {customerPreview ? 'Customer Preview' : mentionedSocialUser?.username}
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Typography variant="body2" className={classes.justNowText}>
          {createdAt ? formatDate(createdAt) : 'Just now'}
        </Typography>
        <Typography variant="body2" className={classes.replyText}>
          You mentioned {mentionedSocialUser?.username} in your story
        </Typography>
        <MessagePhoneMedia media={media} />
        {message && (
          <Box className={classes.messageContainer}>
            <Box className={classes.messageBubble}>
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {message}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default MessagePhonePreview
