import React, { useRef, useState, useEffect } from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  Typography,
  InputAdornment,
  Input,
  Box,
  Divider,
  Button,
  Menu,
} from '@material-ui/core'
import { ReactComponent as CalendarEmptyIcon } from '../../icons/calendar-empty.svg'
import AbsoluteDateRangePicker, { DateRangeModel } from './AbsoluteDateRangePicker'
import { format, addYears, subDays } from 'date-fns'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.primary.main,
      margin: '10px 0',
    },
    helpText: {
      color: theme.palette.secondary.main,
      margin: '-10px 0 10px',
      fontSize: 12,
    },
    input: {
      maxWidth: 300,
    },
    icon: {
      cursor: 'pointer',
    },
    error: {
      color: theme.palette.error.main,
      fontSize: 12,
    },
  }),
)

interface DateRangePickerSingleInputProps {
  label: string
  helpText?: string
  placeholder?: string
  value?: DateRangeModel
  errors?: string | null
  setValue: (value: DateRangeModel) => void
  startDateDisabled?: boolean
  endDateDisabled?: boolean
}

export const DateRangePickerSingleInput: React.FC<DateRangePickerSingleInputProps> = props => {
  const classes = useStyles()
  const { label, helpText, placeholder, value, errors, setValue, startDateDisabled, endDateDisabled } = props

  const inputRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const [absoluteDateRange, setAbsoluteDateRange] = useState<DateRangeModel | undefined>()

  const inputValue = value?.gte && value.lt ? `${format(value.gte, 'PP')} - ${format(subDays(value.lt, 1), 'PP')}` : ''

  useEffect(() => {
    setAbsoluteDateRange(value)
  }, [value, open])

  const handleApply = () => {
    absoluteDateRange && setValue(absoluteDateRange)
    setOpen(false)
  }

  const handleClear = () => {
    const defaultValue = { gte: undefined, lt: undefined }
    setValue(defaultValue)
    setAbsoluteDateRange(defaultValue)
    setOpen(false)
  }

  const handleOpen = () => {
    if (!startDateDisabled || !endDateDisabled) {
      setOpen(true)
    }
  }

  const isApplyDisabled = (dates?: DateRangeModel) => !!dates?.gte && !!dates?.lt && dates?.gte < dates?.lt

  return (
    <FormControl fullWidth ref={inputRef}>
      <Typography className={classes.label}>{label}</Typography>
      {!!helpText && <Typography className={classes.helpText}>{helpText}</Typography>}
      <Input
        value={inputValue}
        className={classes.input}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end" className={classes.icon}>
            <CalendarEmptyIcon width={16} height={16} />
          </InputAdornment>
        }
        onClick={handleOpen}
        disabled={startDateDisabled && endDateDisabled}
        readOnly={endDateDisabled}
      />
      <Menu
        open={open}
        anchorEl={inputRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setOpen(false)}
      >
        <AbsoluteDateRangePicker
          onChange={setAbsoluteDateRange}
          dateRange={absoluteDateRange}
          minDate={absoluteDateRange?.gte ?? new Date()}
          maxDate={addYears(new Date(), 1)}
          startDateDisabled={startDateDisabled}
          endDateDisabled={endDateDisabled}
        />
        <Box mt={2}>
          <Divider />
          <Box px={5} pt={2} display="flex" justifyContent="space-between">
            <Button
              color="secondary"
              onClick={handleClear}
              disabled={!(absoluteDateRange?.gte || absoluteDateRange?.lt)}
            >
              Clear
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleApply}
              disabled={!isApplyDisabled(absoluteDateRange)}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
      {!!errors && <div className={classes.error}>{errors}</div>}
    </FormControl>
  )
}
