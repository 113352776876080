import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Typography, Box, Grid, makeStyles, Theme, createStyles } from '@material-ui/core'
import { AccountProductName, CurrencyCodeEnum, IntegrationCapabilityEnum, ProgramTypeEnum } from '../gql-global'
import Page from '../Page'
import ContainerError from '../components/ContainerError'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import { ReactComponent as EmptyImage } from '../images/empty-list.svg'
import { ReactComponent as RewardCampaign } from '../images/reward-campaign.svg'
import { ReactComponent as AmbassadorCampaign } from '../images/ambassador-campaign.svg'
import { UpsellModalUpdaterContext } from '../components/UpsellModal'
import CampaignRow from './CampaignRow'
import { useCampaignsQuery } from './operations/campaigns.generated'
import { useCampaignListUserDataQuery } from './operations/campaign-list-user-data.generated'
import AddCampaignCard from './AddCampaignCard'
import AddCampaignDialog, { AddCampaignFields } from './AddCampaignDialog'
import { useCreateCampaignMutation } from './operations/create-campaign.generated'
import { CampaignRowFragmentDoc } from './operations/campaign-row.generated'
import useHasFeature from '../hooks/useHasFeature'
import { createTypeNamePredicate } from '../types/utility'
import { useToast } from '../components/Alert/ToastProvider'
import { selectedSocialPlatform } from '../utils/social-account'

const customersIntegrationTypeNames = ['RefersionIntegration', 'ImpactIntegration'] as const
const isCustomerIntegration = createTypeNamePredicate(...customersIntegrationTypeNames)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addContainer: {
      marginBottom: theme.spacing(12),
    },
  }),
)

function CampaignList(): React.ReactElement {
  const classes = useStyles()
  const { showToast } = useToast()
  const { hasFeature: hasCreateFeature } = useHasFeature('campaignCreationTemp')
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const { data: userData, loading: userLoading, error: userError } = useCampaignListUserDataQuery()
  const hasCampaigns = userData?.whoami?.account?.organization.activeProducts.includes(AccountProductName.Campaign)
  const isLoudcrowdInternal = userData?.whoami?.email?.endsWith('@loudcrowd.com')
  const isAdmin = userData?.whoami?.roles?.some(r => r.name === 'ADMIN')
  const socialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId
  const selectedPlatform = selectedSocialPlatform(userData)
  const setModalContext = useContext(UpsellModalUpdaterContext)
  const { data, loading, error } = useCampaignsQuery({
    skip: !socialAccountId || !hasCampaigns || !selectedPlatform,
    variables: {
      socialAccountId: socialAccountId || '',
      platform: selectedPlatform!,
    },
  })
  const [createCampaign] = useCreateCampaignMutation({
    update(cache, { data: createData }) {
      if (!data?.socialAccount || !createData?.createCampaign?.campaign) {
        return
      }
      const newCampaign = createData.createCampaign.campaign
      cache.modify({
        id: cache.identify(data.socialAccount),
        fields: {
          campaigns(existing = []) {
            const newRef = cache.writeFragment({
              data: newCampaign,
              fragment: CampaignRowFragmentDoc,
            })
            return [...existing, newRef]
          },
        },
      })
    },
  })
  const hasError = userError || error
  const isLoading = loading || userLoading

  const openUpsell = useCallback(() => {
    setModalContext({
      isOpen: true,
      modalProps: {
        context: { reason: 'CAMPAIGNS' },
        onCancel() {
          setModalContext({ isOpen: false })
        },
      },
    })
  }, [setModalContext])

  useEffect(() => {
    // when we _know_ they do not have campaigns feature, show modal
    if (hasCampaigns !== undefined && !hasCampaigns) {
      openUpsell()
    }
  }, [hasCampaigns, openUpsell])

  function handleAddCampaignClicked() {
    if (hasCampaigns && (isLoudcrowdInternal || isAdmin)) {
      setAddDialogOpen(true)
    } else {
      openUpsell()
    }
  }

  function handleCreateCampaign(campaign: AddCampaignFields): void {
    if (!socialAccountId || !selectedPlatform) {
      return
    }
    const ttSocialAccountId = campaign.ttSocialAccountId
    const igSocialAccountId = campaign.igSocialAccountId
    createCampaign({
      variables: {
        socialAccountId,
        platform: selectedPlatform,
        campaign: {
          name: campaign.name,
          integrationId: campaign.integrationId,
          ecommIntegrationId: campaign.ecommIntegrationId,
          igSocialAccountId: igSocialAccountId,
          ttSocialAccountId: ttSocialAccountId,
          requiresVerification: campaign.requiresVerification,
          autoApproved: !campaign.requiresApproval,
          currencyCode: campaign.currencyCode as CurrencyCodeEnum,
          programType: campaign.programType as ProgramTypeEnum,
        },
      },
    }).catch(e => {
      showToast({
        title: `Error: creating program`,
        message: e.message || 'Unknown error',
      })
    })
    setAddDialogOpen(false)
  }

  return (
    <Page>
      <Box mb={7}>
        <Typography variant="h5">Programs</Typography>
      </Box>
      <Grid container className={classes.addContainer} spacing={6} alignItems="stretch">
        <Grid item xs={6}>
          <AddCampaignCard
            title="All Customer Program"
            text="Incentivize every single one of your customers to create content on Instagram or TikTok. Reward them with discounts, credits, merch, products, etc."
            image={RewardCampaign}
            callToActionText="New Reward Program"
            onCallToActionClicked={handleAddCampaignClicked}
          />
        </Grid>
        <Grid item xs={6}>
          <AddCampaignCard
            title="Exclusive Ambassador Program"
            text="Identify and invite your most valuable brand advocates to an exclusive ambassador program, and give them incentives to create extremely high value content."
            image={AmbassadorCampaign}
            callToActionText="New Ambassador Program"
            onCallToActionClicked={handleAddCampaignClicked}
          />
        </Grid>
      </Grid>
      {!isLoading &&
        data?.socialAccount?.campaigns?.map(c => (
          <CampaignRow
            key={c.id}
            id={c.id}
            name={c.program?.name}
            isActive={c.isActive}
            participantCount={c.participantStats?.count}
            posts={c.mentionStats?.posts}
            avgEngagementRate={c.mentionStats?.engagement}
            startAt={c.startAt}
          />
        ))}
      {isLoading && (
        <>
          <CampaignRow loading />
          <CampaignRow loading />
          <CampaignRow loading />
          <CampaignRow loading />
          <CampaignRow loading />
        </>
      )}
      {hasError && <ContainerError text="Could not load programs" />}
      {!isLoading && !hasError && !data?.socialAccount?.campaigns?.length && (
        <ContainerEmptyState image={EmptyImage} text="No programs" />
      )}
      <AddCampaignDialog
        canCreate={hasCreateFeature}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onCreate={handleCreateCampaign}
        customerIntegrations={userData?.whoami?.account?.integrations?.filter(isCustomerIntegration) || []}
        ecommIntegrations={
          userData?.whoami?.account?.integrations?.filter(i =>
            i.capabilities.includes(IntegrationCapabilityEnum.Storefront),
          ) || []
        }
      />
    </Page>
  )
}

export default CampaignList
