import React, { useState } from 'react'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from '@material-ui/core'
import { CustomerQuery } from './operations/customer.generated'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 300,
    },
  }),
)

const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface ProgramType {
  campaignId: number
  name: string
  programId: string
}

type Customer = NonNullable<NonNullable<CustomerQuery['socialAccount']>['customer']>

interface AddToProgramDialogProps {
  customer: Customer
  platform: 'instagram' | 'tiktok'
  campaigns?: ProgramType[] | null
  onSave: (campaignId: number, email?: string | null) => void
  open: boolean
  onClose: () => void
}

function AddToProgramDialog({
  open,
  onClose,
  campaigns,
  customer,
  onSave,
  platform,
}: AddToProgramDialogProps): React.ReactElement {
  const classes = useStyles()
  const [newEmail, setNewEmail] = useState<string>(customer.email || '')
  const [campaignId, setCampaignId] = useState<number | null>(0)
  const [search, setSearch] = useState<string>('')

  const handleClose = () => {
    setNewEmail(customer.email || '')
    setCampaignId(null)
    setSearch('')
    onClose()
  }

  let searchedPrograms = campaigns

  if (search !== '') {
    searchedPrograms = searchedPrograms?.filter(sp => sp.name?.includes(search))
  }

  const username = platform === 'instagram' ? customer.igUser?.username : customer.ttUser?.username

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add to Program</DialogTitle>
      <DialogContent style={{ width: 450 }}>
        <Box overflow="hidden">
          <Box>
            <TextField label="Username" value={username} className={classes.formControl} disabled={true} />
          </Box>
          <Box>
            <TextField
              label="Email"
              type="email"
              value={newEmail}
              className={classes.formControl}
              onChange={e => setNewEmail(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" mt={3}>
          <FormLabel component="legend">Programs</FormLabel>
        </Box>
        <Box display="flex" mt={3}>
          <TextField placeholder="search" variant="outlined" fullWidth onChange={e => setSearch(e.target.value)} />
        </Box>
        <Box overflow="auto">
          <Box mt={3} display="flex" flexDirection="column" maxHeight={200}>
            <RadioGroup>
              {searchedPrograms
                ?.sort((a, b) => a.name?.localeCompare(b.name)) // Sorting the programs
                ?.map(c => (
                  <FormControlLabel
                    key={c.programId}
                    value={c.name}
                    control={<Radio />}
                    label={c.name}
                    onChange={() => setCampaignId(c.campaignId)}
                  />
                ))}
            </RadioGroup>
          </Box>
          {searchedPrograms?.length === 0 && 'No matching program.'}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={!campaignId || !VALID_EMAIL_REGEX.test(newEmail)}
          onClick={() => {
            campaignId && onSave(campaignId, newEmail)
            handleClose()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddToProgramDialog
