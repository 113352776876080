import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddParticipantSegmentMutationVariables = Types.Exact<{
  ambassadorStatsWhere?: Types.InputMaybe<Types.AmbassadorStatsInput>;
  segmentId: Types.Scalars['ID'];
  participantIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  campaignId?: Types.InputMaybe<Types.Scalars['ID']>;
  where?: Types.InputMaybe<Types.ParticipantsInput>;
  mentionsWhere?: Types.InputMaybe<Types.CustomerMentionStatsFilters>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type AddParticipantSegmentMutation = { __typename: 'Mutations', addParticipantSegment?: { __typename: 'AddParticipantSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null } | null };


export const AddParticipantSegmentDocument = gql`
    mutation AddParticipantSegment($ambassadorStatsWhere: AmbassadorStatsInput, $segmentId: ID!, $participantIds: [ID!], $campaignId: ID, $where: ParticipantsInput, $mentionsWhere: CustomerMentionStatsFilters, $limit: Int, $sortBy: ParticipantSort, $sortDirection: SortDirection) {
  addParticipantSegment(
    ambassadorStatsWhere: $ambassadorStatsWhere
    participantIds: $participantIds
    segmentId: $segmentId
    campaignId: $campaignId
    where: $where
    mentionsWhere: $mentionsWhere
    limit: $limit
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    ok
    segment {
      id
      name
      account {
        id
      }
    }
  }
}
    `;
export type AddParticipantSegmentMutationFn = Apollo.MutationFunction<AddParticipantSegmentMutation, AddParticipantSegmentMutationVariables>;

/**
 * __useAddParticipantSegmentMutation__
 *
 * To run a mutation, you first call `useAddParticipantSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantSegmentMutation, { data, loading, error }] = useAddParticipantSegmentMutation({
 *   variables: {
 *      ambassadorStatsWhere: // value for 'ambassadorStatsWhere'
 *      segmentId: // value for 'segmentId'
 *      participantIds: // value for 'participantIds'
 *      campaignId: // value for 'campaignId'
 *      where: // value for 'where'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAddParticipantSegmentMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantSegmentMutation, AddParticipantSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantSegmentMutation, AddParticipantSegmentMutationVariables>(AddParticipantSegmentDocument, options);
      }
export type AddParticipantSegmentMutationHookResult = ReturnType<typeof useAddParticipantSegmentMutation>;
export type AddParticipantSegmentMutationResult = Apollo.MutationResult<AddParticipantSegmentMutation>;
export type AddParticipantSegmentMutationOptions = Apollo.BaseMutationOptions<AddParticipantSegmentMutation, AddParticipantSegmentMutationVariables>;