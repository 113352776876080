import React from 'react'
import { Box } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import NavSidebar from './components/NavSidebar/NavSidebar'
import TopBar from './components/TopBar/TopBar'
import ContainerError from './components/ContainerError'

type AppErrorBoundaryState = {
  errored: boolean
}

class AppErrorBoundary extends React.Component<unknown, AppErrorBoundaryState> {
  state = { errored: false }
  static getDerivedStateFromError(): AppErrorBoundaryState {
    return { errored: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render(): React.ReactNode {
    if (this.state.errored) {
      return (
        <Box display="flex">
          <Box flexGrow={0}>
            <NavSidebar loading noPicker />
          </Box>
          <Box flexGrow={1}>
            <TopBar loading />
            <Box paddingTop={9} display="flex" justifyContent="center">
              <ContainerError text="Something went wrong" />
            </Box>
          </Box>
        </Box>
      )
    }
    return this.props.children
  }
}

export default AppErrorBoundary
