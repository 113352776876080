import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertAffiliateCodeMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  customerId: Types.Scalars['ID'];
  ecommDiscountRedeemCodeId?: Types.InputMaybe<Types.Scalars['ID']>;
  programId: Types.Scalars['ID'];
}>;


export type UpsertAffiliateCodeMutation = { __typename: 'Mutations', upsertProgramParticipantEcommDiscountRedeemCode?: { __typename: 'UpsertProgramParticipantEcommDiscountRedeemCode', ok: boolean } | null };


export const UpsertAffiliateCodeDocument = gql`
    mutation UpsertAffiliateCode($code: String!, $customerId: ID!, $ecommDiscountRedeemCodeId: ID, $programId: ID!) {
  upsertProgramParticipantEcommDiscountRedeemCode(
    code: $code
    customerId: $customerId
    ecommDiscountRedeemCodeId: $ecommDiscountRedeemCodeId
    programId: $programId
  ) {
    ok
  }
}
    `;
export type UpsertAffiliateCodeMutationFn = Apollo.MutationFunction<UpsertAffiliateCodeMutation, UpsertAffiliateCodeMutationVariables>;

/**
 * __useUpsertAffiliateCodeMutation__
 *
 * To run a mutation, you first call `useUpsertAffiliateCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAffiliateCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAffiliateCodeMutation, { data, loading, error }] = useUpsertAffiliateCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      customerId: // value for 'customerId'
 *      ecommDiscountRedeemCodeId: // value for 'ecommDiscountRedeemCodeId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useUpsertAffiliateCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAffiliateCodeMutation, UpsertAffiliateCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAffiliateCodeMutation, UpsertAffiliateCodeMutationVariables>(UpsertAffiliateCodeDocument, options);
      }
export type UpsertAffiliateCodeMutationHookResult = ReturnType<typeof useUpsertAffiliateCodeMutation>;
export type UpsertAffiliateCodeMutationResult = Apollo.MutationResult<UpsertAffiliateCodeMutation>;
export type UpsertAffiliateCodeMutationOptions = Apollo.BaseMutationOptions<UpsertAffiliateCodeMutation, UpsertAffiliateCodeMutationVariables>;