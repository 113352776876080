import { useQueryParam, stringify, encodeQueryParams } from 'use-query-params'
import { createEnumParam } from '../../utils/url-param-utils'
import { AccountSort, ProgramSort } from '../../gql-global'

const SORT_PARAM = 'sort'

const sortParam = createEnumParam(AccountSort)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSortParam = () => {
  return useQueryParam(SORT_PARAM, sortParam)
}

export const encodeSortParam = (sort: AccountSort): string => {
  return stringify(encodeQueryParams({ [SORT_PARAM]: sortParam }, { [SORT_PARAM]: sort }))
}

export default useSortParam

const sortProgramParam = createEnumParam(ProgramSort)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useProgramSortParam = () => {
  return useQueryParam(SORT_PARAM, sortProgramParam)
}

export { useProgramSortParam }
