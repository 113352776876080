import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SocialAccountPickerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SocialAccountPickerQuery = { __typename: 'Query', socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null, socialUser: { __typename: 'IGUser', id: string, username: string, avatarUrl?: string | null, followerCount?: number | null } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string, avatarUrl?: string | null, followerCount?: number | null } }> | null, whoami?: { __typename: 'UserType', id: number, email: string, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, account?: { __typename: 'AccountType', id: number, organization: { __typename: 'Organization', id: string, hasTiktok?: boolean | null, activeProducts: Array<Types.AccountProductName> } } | null, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null } } | null };


export const SocialAccountPickerDocument = gql`
    query SocialAccountPicker {
  socialAccounts {
    id
    socialUser {
      id
      username
      avatarUrl
      followerCount
    }
    ... on IGSocialAccount {
      fbUsers {
        id
        isValid
      }
    }
  }
  whoami {
    id
    email
    roles {
      id
      name
    }
    account {
      id
      organization {
        id
        hasTiktok
        activeProducts
      }
    }
    preferences @client {
      id
      selectedSocialAccountId
    }
  }
}
    `;

/**
 * __useSocialAccountPickerQuery__
 *
 * To run a query within a React component, call `useSocialAccountPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialAccountPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialAccountPickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialAccountPickerQuery(baseOptions?: Apollo.QueryHookOptions<SocialAccountPickerQuery, SocialAccountPickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialAccountPickerQuery, SocialAccountPickerQueryVariables>(SocialAccountPickerDocument, options);
      }
export function useSocialAccountPickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialAccountPickerQuery, SocialAccountPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialAccountPickerQuery, SocialAccountPickerQueryVariables>(SocialAccountPickerDocument, options);
        }
export type SocialAccountPickerQueryHookResult = ReturnType<typeof useSocialAccountPickerQuery>;
export type SocialAccountPickerLazyQueryHookResult = ReturnType<typeof useSocialAccountPickerLazyQuery>;
export type SocialAccountPickerQueryResult = Apollo.QueryResult<SocialAccountPickerQuery, SocialAccountPickerQueryVariables>;