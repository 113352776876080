import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type ReviewProgramRowFragment = { __typename: 'ProgramType', id: string, name: string, pendingPosts?: number | null, account?: { __typename: 'AccountType', id: number, name: string } | null, campaign?: { __typename: 'CampaignType', id: number } | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }>, contentRules?: { __typename: 'ProgramContentRulesType', id: string, goodExampleUrl?: string | null, badExampleUrl?: string | null, brandPhoto?: string | null } | null, reviewLock?: { __typename: 'ReviewLockType', id: string, updatedAt?: Date | null, user?: { __typename: 'UserType', id: number, email: string } | null } | null };

export const ReviewProgramRowFragmentDoc = gql`
    fragment ReviewProgramRow on ProgramType {
  id
  name
  account {
    id
    name
  }
  pendingPosts
  campaign {
    id
  }
  socialAccounts {
    id
    socialUser {
      id
      username
    }
  }
  contentRules {
    id
    goodExampleUrl
    badExampleUrl
    brandPhoto
  }
  reviewLock {
    id
    user {
      id
      email
    }
    updatedAt
  }
}
    `;