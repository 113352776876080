import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LookupSocialAccountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type LookupSocialAccountQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } } | null };


export const LookupSocialAccountDocument = gql`
    query lookupSocialAccount($id: ID!) {
  socialAccount(id: $id) {
    id
    socialUser {
      id
      username
    }
  }
}
    `;

/**
 * __useLookupSocialAccountQuery__
 *
 * To run a query within a React component, call `useLookupSocialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupSocialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupSocialAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLookupSocialAccountQuery(baseOptions: Apollo.QueryHookOptions<LookupSocialAccountQuery, LookupSocialAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupSocialAccountQuery, LookupSocialAccountQueryVariables>(LookupSocialAccountDocument, options);
      }
export function useLookupSocialAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupSocialAccountQuery, LookupSocialAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupSocialAccountQuery, LookupSocialAccountQueryVariables>(LookupSocialAccountDocument, options);
        }
export type LookupSocialAccountQueryHookResult = ReturnType<typeof useLookupSocialAccountQuery>;
export type LookupSocialAccountLazyQueryHookResult = ReturnType<typeof useLookupSocialAccountLazyQuery>;
export type LookupSocialAccountQueryResult = Apollo.QueryResult<LookupSocialAccountQuery, LookupSocialAccountQueryVariables>;