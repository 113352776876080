import React, { PropsWithChildren, useRef, useState } from 'react'
import { makeStyles, createStyles, Theme, FormControl, Menu } from '@material-ui/core'
import { SelectionList } from '../SelectionPicker/SelectionList'
import ClickableCardField from '../ClickableCardField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 32,
      fontWeight: 500,
    },
    form: {
      minWidth: '225px',
    },
  }),
)

interface CardMultiSelectProps<T extends string> {
  value: Set<T>
  setValue: (value: Set<T>) => void
  resetValue: () => Set<T>
  isValueSelected: () => boolean
  isInvalid?: (value: ReadonlySet<T>) => boolean
  options: readonly { id: T; label: string }[]
  forceShowCheckmark?: boolean
  disabled?: boolean
  allowClear?: boolean
}

function CardMultiSelect<T extends string>({
  value,
  setValue,
  resetValue,
  isValueSelected,
  isInvalid,
  options,
  children,
  forceShowCheckmark = false,
  disabled = false,
  allowClear = true,
}: PropsWithChildren<CardMultiSelectProps<T>>): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false)
  const chipRef = useRef<HTMLDivElement>(null)

  const classes = useStyles()

  function handleChange(v: Set<T>): void {
    setValue(v)
    handleClose()
  }

  function handleReset(): Set<T> {
    return resetValue()
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ClickableCardField
        onCardClicked={toggleOpen}
        showCheck={forceShowCheckmark || isValueSelected()}
        onReset={() => resetValue()}
        disabled={disabled}
        allowClear={allowClear}
      >
        {children}
      </ClickableCardField>
      <FormControl className={classes.form} ref={chipRef}>
        <Menu
          open={open}
          anchorEl={chipRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={handleClose}
          className={classes.form}
        >
          <SelectionList
            options={options}
            onChangeOptions={handleChange}
            onReset={handleReset}
            selectedOptions={value}
            isInvalid={isInvalid}
            selectionRequired={true}
          />
        </Menu>
      </FormControl>
    </>
  )
}

export default CardMultiSelect
