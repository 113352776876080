import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveCustomerSegmentMutationVariables = Types.Exact<{
  segmentId: Types.Scalars['ID'];
  customerId: Types.Scalars['ID'];
}>;


export type RemoveCustomerSegmentMutation = { __typename: 'Mutations', removeCustomerSegment?: { __typename: 'RemoveCustomerSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, account?: { __typename: 'AccountType', id: number } | null } | null, customers?: Array<{ __typename: 'CustomerType', id: number, segments?: Array<{ __typename: 'SegmentType', id: number, name: string }> | null }> | null } | null };


export const RemoveCustomerSegmentDocument = gql`
    mutation RemoveCustomerSegment($segmentId: ID!, $customerId: ID!) {
  removeCustomerSegment(segmentId: $segmentId, customerIds: [$customerId]) {
    ok
    segment {
      id
      account {
        id
      }
    }
    customers {
      id
      segments {
        id
        name
      }
    }
  }
}
    `;
export type RemoveCustomerSegmentMutationFn = Apollo.MutationFunction<RemoveCustomerSegmentMutation, RemoveCustomerSegmentMutationVariables>;

/**
 * __useRemoveCustomerSegmentMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerSegmentMutation, { data, loading, error }] = useRemoveCustomerSegmentMutation({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useRemoveCustomerSegmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerSegmentMutation, RemoveCustomerSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomerSegmentMutation, RemoveCustomerSegmentMutationVariables>(RemoveCustomerSegmentDocument, options);
      }
export type RemoveCustomerSegmentMutationHookResult = ReturnType<typeof useRemoveCustomerSegmentMutation>;
export type RemoveCustomerSegmentMutationResult = Apollo.MutationResult<RemoveCustomerSegmentMutation>;
export type RemoveCustomerSegmentMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerSegmentMutation, RemoveCustomerSegmentMutationVariables>;