import React, { useRef, useState, useMemo, ReactElement } from 'react'
import {
  Box,
  Menu,
  OutlinedInput,
  makeStyles,
  createStyles,
  Button,
  Typography,
  InputAdornment,
  styled,
  Chip,
} from '@material-ui/core'
import clsx from 'clsx'
import MenuHeader from '../MenuHeader'
import SingleSelectFilterMenu from '../SingleSelectionPicker'
import { SingleSelectionOption } from '../lists/ListFilters/types'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as Cross } from '../../icons/cross.svg'
import { primary, error } from '../../loudcrowd-theme'
import Tooltip from '../Tooltip/Tooltip'

const useStyles = makeStyles(theme =>
  createStyles({
    search: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: '150%',
    },
    searchInput: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: 21,
    },
    highlightedChip: {
      fontSize: theme.typography.body2.fontSize,
      borderRadius: 4,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      height: 56,
      width: '100%',
      lineHeight: '150%',
      display: 'grid',
      gridTemplateColumns: '50px 1fr 30px',
    },
    warningBorder: {
      border: `solid 1px ${error[600]}`,
    },
    adornmentIcon: {
      display: 'flex',
      justifyContent: 'center',
    },
    deleteIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    deleteIcon: {
      height: 12,
      width: 12,
    },
    list: {
      maxHeight: 190, // should display five items on the menu
      overflowY: 'scroll',
    },
  }),
)

const StyledChip = styled(Chip)(() => ({
  '& .MuiChip-deleteIcon': {
    marginRight: 0,
  },
  '& .MuiChip-icon': {
    color: primary[500],
  },
}))

export interface MenuPickerProps {
  option: SingleSelectionOption
  value: string
  disabled: boolean
  onChange: (value: string) => void
  adornmentIcon: JSX.Element
  noOptionsMessage: string
  menuTitle: string
  label: string
  warning?: string
  labelIcon?: ReactElement
}

export default function MenuPicker({
  option,
  value,
  disabled,
  onChange,
  adornmentIcon,
  noOptionsMessage,
  menuTitle,
  label,
  warning,
  labelIcon,
}: MenuPickerProps): JSX.Element {
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const buttonRef = useRef<HTMLButtonElement>(null)
  const chipRef = useRef<HTMLDivElement>(null)
  const [menuPickerOpen, setMenuPickerOpen] = useState<boolean>(false)
  const filterOptions = useMemo(
    () =>
      option.selectionOptions.filter(o =>
        o.label
          .toLowerCase()
          .trim()
          .includes(searchText.toLowerCase().trim()),
      ),
    [searchText, option.selectionOptions],
  )
  const selectedOption = option.selectionOptions.find(o => o.id === value)

  const handleOnChange = (value: string): void => {
    onChange(value)
    setMenuPickerOpen(false)
    setSearchText('')
  }

  return (
    <>
      {selectedOption ? (
        <StyledChip
          icon={<span className={classes.adornmentIcon}>{adornmentIcon}</span>}
          className={clsx(classes.highlightedChip, {
            [classes.warningBorder]: warning,
          })}
          label={selectedOption.label}
          onDelete={() => onChange('')}
          variant="outlined"
          deleteIcon={
            <span className={classes.deleteIconContainer}>
              <Cross className={classes.deleteIcon} />
            </span>
          }
          onClick={() => setMenuPickerOpen(true)}
          ref={chipRef}
        />
      ) : (
        <Tooltip title={disabled ? noOptionsMessage : ''} placement="top-start">
          <span>
            <Button ref={buttonRef} color="primary" disabled={disabled} onClick={() => setMenuPickerOpen(true)}>
              <Box display="flex" alignItems="center">
                <Box component="span" mr={2} lineHeight={0}>
                  {labelIcon}
                </Box>
                <Typography variant="subtitle2">{label}</Typography>
              </Box>
            </Button>
          </span>
        </Tooltip>
      )}
      <Menu
        open={menuPickerOpen}
        anchorEl={buttonRef.current || chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMenuPickerOpen(false)}
      >
        <MenuHeader title={menuTitle} />
        <Box marginX={5}>
          <OutlinedInput
            classes={{ root: classes.search, input: classes.searchInput }}
            type="text"
            autoFocus
            fullWidth
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon width={16} height={16} />
              </InputAdornment>
            }
          />
        </Box>
        <SingleSelectFilterMenu
          option={{
            ...option,
            selectionOptions: filterOptions,
          }}
          onSelectValue={handleOnChange}
          className={classes.list}
        />
      </Menu>
    </>
  )
}
