import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  FieldFunctionOptions,
  FieldPolicy,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client/link/core'
import { withScalars } from 'apollo-link-scalars'
import { parseISO, parse, format } from 'date-fns'
import { AUTH0_LOGIN_ROUTE } from './auth/routes'
import { setRedirectUrl } from './auth/redirect'
import introspectionResult from '@loudcrowd/graphql/introspection.json'
import fragments from './fragments.json'
import { buildClientSchema, IntrospectionQuery } from 'graphql'
import { UserPreferencesDocument, UserPreferencesQuery } from './queries/operations/user-prefs.generated'
import {
  MutationsHideSyncForSocialAccountArgs,
  MutationsUpdateLastDashboardDateRangeArgs,
  MutationsUpdateSelectedSocialAccountIdArgs,
  MutationsUpdateSelectedMentionTypeFilterArgs,
  MutationsUpdateSelectedPostStatusFilterArgs,
  MutationsUpdateSelectedChallengesFilterArgs,
  UserPreferences,
  UserType,
  DateTimeRangeFilter,
  DateRangeFilter,
  DateRangeFilterType,
  DateRangeFilterUnits,
  MutationsUpdateSelectedPostTypeFilterArgs,
  IgMediaPostType,
  MentionStatus,
} from './gql-global'
import produce from 'immer'
import { IgMentionTypes, TtMentionTypes } from './content/constants'
import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'
import CleanTypenameLink from './clean-typename-link'

const USER_PREF_KEY = 'userpref'

type GqlContext = { cache: InMemoryCache }
const dateFormat = 'yyyy-MM-dd'

const typesMap = {
  Date: {
    serialize: (parsed: unknown): string | null => {
      if (parsed && typeof parsed === 'string') {
        return format(new Date(parsed), dateFormat)
      }
      return null
    },
    parseValue: (raw: unknown): Date | null => {
      if (raw && typeof raw === 'string') {
        return parse(raw, dateFormat, new Date())
      }
      return null
    },
  },
  DateTime: {
    serialize: (parsed: unknown): string | null => {
      if (!parsed) return null
      if (typeof parsed === 'string') return parsed
      if (parsed instanceof Date) {
        return parsed.toISOString()
      }
      return null
    },
    parseValue: (raw: unknown): Date | null => {
      if (!raw || typeof raw !== 'string') {
        return null
      }
      let strRaw = raw
      if (!raw.match(/T.+([+-][\d:]+|[Z])$/)) {
        strRaw = `${strRaw}Z`
      }
      return parseISO(strRaw)
    },
  },
  JSONString: {
    serialize(parsed: unknown): string | null {
      if (!parsed) return null
      if (typeof parsed === 'string') return parsed
      return JSON.stringify(parsed)
    },
    parseValue(raw: unknown) {
      if (!raw || typeof raw !== 'string') return raw
      return JSON.parse(raw)
    },
  },
}

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery)

function updateUserPref<K extends keyof UserPreferences>(
  pref: K,
  value: UserPreferences[K],
  context: GqlContext,
): UserPreferences[K] | null {
  const data = context.cache.readQuery<UserPreferencesQuery>({
    query: UserPreferencesDocument,
  })
  if (data && data.whoami) {
    const { whoami } = data
    if (whoami.preferences[pref] === value) {
      return value
    }
    const newData = produce(data, draft => {
      if (!draft.whoami) {
        return draft
      }
      draft.whoami.preferences[pref] = value
      return draft
    })
    context.cache.writeQuery<UserPreferencesQuery>({
      query: UserPreferencesDocument,
      data: newData,
    })
    return value
  }
  return null
}

const defaultPrefs: Omit<UserPreferences, '__typename' | 'id'> = {
  lastDashboardDateRange: {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    value: 30,
    unit: DateRangeFilterUnits.Days,
    offset: null,
  },
  selectedSocialAccountId: null,
  hideSyncSocialAccountIds: [],
  selectedPostTypeFilter: [IgMediaPostType.Story, IgMediaPostType.Reels, IgMediaPostType.Feed],
  selectedMentionTypeFilter: [
    IgMentionTypes.Tag,
    IgMentionTypes.Caption,
    IgMentionTypes.Story,
    TtMentionTypes.Hashtag,
    TtMentionTypes.TT_Caption,
  ],
  selectedPostStatusFilter: [
    MentionStatus.Verified,
    MentionStatus.Unverified,
    MentionStatus.OfficialReview,
    MentionStatus.Rejected,
  ],
  selectedChallengesFilter: [],
}

const resolvers = {
  UserType: {
    preferences(user: UserType): UserPreferences {
      const neededValues: Pick<UserPreferences, '__typename' | 'id'> = {
        __typename: 'UserPreferences',
        id: user.id.toString(),
      }
      const prefsString = localStorage.getItem(USER_PREF_KEY)
      if (prefsString) {
        const json = JSON.parse(prefsString)
        // Make sure we have a new date range format if not skip it
        if (json.lastDashboardDateRange && !json.lastDashboardDateRange.rangeType) {
          json.lastDashboardDateRange = null
        }
        if (!json.lastDashboardDateRange) {
          json.lastDashboardDateRange = defaultPrefs.lastDashboardDateRange
        }
        // need to convert from string to date because apollo wont do that automatically
        // for us when going from local storage -> apollo
        json.lastDashboardDateRange.gte = json.lastDashboardDateRange.gte
          ? parseISO(json.lastDashboardDateRange.gte)
          : null
        json.lastDashboardDateRange.lt = json.lastDashboardDateRange.lt
          ? parseISO(json.lastDashboardDateRange.lt)
          : null
        const storedPrefs = json as UserPreferences
        if (storedPrefs.id === user.id.toString()) {
          return {
            ...neededValues,
            ...defaultPrefs,
            ...storedPrefs,
          }
        }
      }
      return {
        ...defaultPrefs,
        ...neededValues,
      }
    },
  },
  Mutation: {
    updateSelectedSocialAccountId: (
      _: unknown,
      variables: MutationsUpdateSelectedSocialAccountIdArgs,
      context: GqlContext,
    ) => {
      const newValue = updateUserPref('selectedSocialAccountId', variables.selectedSocialAccountId, context)

      return {
        __typename: 'updateSelectedSocialAccountId',
        updateSelectedSocialAccountId: newValue,
      }
    },
    updateLastDashboardDateRange: (
      _: unknown,
      { lastDashboardDateRange }: MutationsUpdateLastDashboardDateRangeArgs,
      context: GqlContext,
    ) => {
      let dateRange: DateTimeRangeFilter | DateRangeFilter | null = null
      if (lastDashboardDateRange.rangeType === DateRangeFilterType.Absolute && lastDashboardDateRange.gte) {
        dateRange = {
          __typename: 'AbsoluteDateTimeRangeFilter',
          rangeType: DateRangeFilterType.Absolute,
          gte: lastDashboardDateRange.gte,
          lt: lastDashboardDateRange.lt || null,
        }
      } else if (
        lastDashboardDateRange.rangeType === DateRangeFilterType.Relative &&
        lastDashboardDateRange.unit &&
        lastDashboardDateRange.value
      ) {
        dateRange = {
          __typename: 'RelativeDateRangeFilter',
          rangeType: DateRangeFilterType.Relative,
          unit: lastDashboardDateRange.unit,
          value: lastDashboardDateRange.value,
          offset: lastDashboardDateRange.offset || null,
        }
      }
      if (!dateRange) {
        throw new Error('Error updating last dashboard date range, invalid input')
      }

      const newValue = updateUserPref('lastDashboardDateRange', dateRange, context)

      return { __typename: 'updateLastDashboardDateRange', updateLastDashboardDateRange: newValue }
    },
    hideSyncForSocialAccount(
      _: unknown,
      { socialAccountId }: MutationsHideSyncForSocialAccountArgs,
      { cache }: GqlContext,
    ) {
      const { whoami } =
        cache.readQuery<UserPreferencesQuery>({
          query: UserPreferencesDocument,
        }) || {}
      if (whoami?.id) {
        const userId = whoami.id
        const cacheKey = cache.identify({ __typename: 'UserPreferences', id: userId })
        cache.modify({
          id: cacheKey,
          fields: {
            hideSyncSocialAccountIds(existingIds: string[] | null | undefined): string[] | null | undefined {
              if (!existingIds?.length) {
                return [socialAccountId]
              }
              if (existingIds.includes(socialAccountId)) {
                return existingIds
              }

              return [...existingIds, socialAccountId]
            },
          },
        })
        return socialAccountId
      }
      return null
    },
    updateSelectedMentionTypeFilter: (
      _: unknown,
      variables: MutationsUpdateSelectedMentionTypeFilterArgs,
      context: GqlContext,
    ) => {
      const newValue = updateUserPref('selectedMentionTypeFilter', variables.selectedMentionTypeFilter, context)
      return { __typename: 'updateSelectedMentionTypeFilter', updateSelectedMentionTypeFilter: newValue }
    },
    updateSelectedPostTypeFilter: (
      _: unknown,
      variables: MutationsUpdateSelectedPostTypeFilterArgs,
      context: GqlContext,
    ) => {
      const newValue = updateUserPref('selectedPostTypeFilter', variables.selectedPostTypeFilter, context)
      return { __typename: 'updateSelectedPostTypeFilter', updateSelectedPostTypeFilter: newValue }
    },
    updateSelectedPostStatusFilter: (
      _: unknown,
      variables: MutationsUpdateSelectedPostStatusFilterArgs,
      context: GqlContext,
    ) => {
      const newValue = updateUserPref('selectedPostStatusFilter', variables.selectedPostStatusFilter, context)
      return { __typename: 'updateSelectedPostStatusFilter', updateSelectedPostStatusFilter: newValue }
    },
    updateSelectedChallengesFilter: (
      _: unknown,
      variables: MutationsUpdateSelectedChallengesFilterArgs,
      context: GqlContext,
    ) => {
      const newValue = updateUserPref(
        'selectedChallengesFilter',
        variables.selectedChallengesFilter?.any || null,
        context,
      )
      return { __typename: 'updateSelectedChallengesFilter', updateSelectedChallengesFilter: newValue }
    },
  },
}

type PagedType =
  | {
      cursor?: string | null
      results?: Array<unknown> | null
      csvUrl?: string | null
    }
  | null
  | undefined

// create a field policy for our paged graphql types
// adds a keyArgs that make it so passing in different limit and cursor args
// do not break cache (those fields just add to existing cache when changed
// and adds a merge function to merge two results together if a new cursor
// is used to fetch more
function pagedFieldPolicy(): FieldPolicy {
  return {
    keyArgs(args: Record<string, unknown> | null): ReturnType<KeyArgsFunction> {
      return args ? Object.keys(args).filter(a => a !== 'limit' && a !== 'cursor') : undefined
    },
    merge(
      existing: PagedType,
      incoming: PagedType,
      { args, fieldName }: FieldFunctionOptions<{ cursor?: string }>,
    ): PagedType {
      if (!incoming || !existing) return incoming
      // no cursor, not looking for next page, replace fields instead
      if (!args?.cursor) {
        return {
          ...existing,
          ...incoming,
        }
      }
      // strangeness is happening, incoming is not
      // next set, so we dont know what to do with it
      if (existing.cursor !== args.cursor && incoming.results !== undefined) {
        throw new Error(
          `Could not merge paged data for ${fieldName}. Current cursor in cache is not same as cursor arg.`,
        )
      }
      return {
        ...existing,
        ...incoming,
        results: (existing.results || []).concat(incoming.results),
      }
    },
  }
}

export const cache = new InMemoryCache({
  possibleTypes: fragments.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        labels(_, { args, toReference }) {
          return args?.ids.map((i: string) => toReference({ __typename: 'LabelType', id: i }))
        },
        mentions: pagedFieldPolicy(),
        accounts: pagedFieldPolicy(),
        socialAccountsPaged: pagedFieldPolicy(),
        programs: pagedFieldPolicy(),
        account: {
          read(_, { args, toReference }) {
            return toReference({ __typename: 'AccountType', id: args?.id })
          },
        },
      },
    },
    IGSocialAccount: {
      fields: {
        sentMessages: pagedFieldPolicy(),
        customers: pagedFieldPolicy(),
        messageTemplates: pagedFieldPolicy(),
        challenges: pagedFieldPolicy(),
      },
    },
    TTSocialAccount: {
      fields: {
        customers: pagedFieldPolicy(),
        challenges: pagedFieldPolicy(),
      },
    },
    AccountType: {
      fields: {
        labels: pagedFieldPolicy(),
        segments: pagedFieldPolicy(),
        rewards: pagedFieldPolicy(),
        integrations: {
          keyArgs(args: Record<string, unknown> | null): ReturnType<KeyArgsFunction> {
            return args ? ('where' in args ? 'where' : undefined) : undefined
          },
        },
      },
    },
    CustomerType: {
      fields: {
        mentions: pagedFieldPolicy(),
        events: pagedFieldPolicy(),
        notes: pagedFieldPolicy(),
        mentionStats: { merge: true },
      },
    },
    CampaignType: {
      fields: {
        events: pagedFieldPolicy(),
        participants: pagedFieldPolicy(),
      },
    },
    RewardType: {
      fields: {
        stats: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
      },
    },
  },
})

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
  credentials: 'include',
})

interface getTokenOptions {
  audience?: string
  scope?: string
}

let client: ApolloClient<NormalizedCacheObject> | null = null

export default function createClient(
  getToken: (options?: getTokenOptions) => Promise<string>,
): ApolloClient<NormalizedCacheObject> {
  if (client) {
    return client
  }
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken()
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      new CleanTypenameLink(),
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            // handle errors differently based on its error code
            switch (err && err.extensions && err.extensions.code) {
              case 'UNAUTHENTICATED':
                setRedirectUrl()
                window.location.href = AUTH0_LOGIN_ROUTE.path
            }
          }
        }
      }),
      withScalars({
        schema,
        typesMap,
      }),
      authLink.concat(httpLink),
    ]),
    cache,
    resolvers,
  })

  apolloClient
    .watchQuery<UserPreferencesQuery>({ query: UserPreferencesDocument, fetchPolicy: 'cache-only' })
    .subscribe(i => {
      if (i.data?.whoami?.preferences) {
        localStorage.setItem(USER_PREF_KEY, JSON.stringify(i.data.whoami.preferences))
      }
    })
  client = apolloClient
  return apolloClient
}
