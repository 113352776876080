import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { primary, secondary } from '../../loudcrowd-theme'

interface StyledProps {
  selectedColor: string
  selectedBackgroundColor: string
  width: number
  height: number
  fontSize: string
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      outline: `1px solid ${secondary[400]}`,
      borderRadius: (props: StyledProps) => {
        const rad = Math.floor(props.height * 0.38)
        return `${rad}px ${rad}px ${rad}px ${rad}px`
      },
      width: (props: StyledProps) => `${props.width}px`,
      height: (props: StyledProps) => `${props.height}px`,
      zIndex: 0,
      display: 'flex',
    },
    option: {
      width: (props: StyledProps) => `${props.width}px`,
      flexGrow: 1,
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: (props: StyledProps) => {
          const rad = Math.floor(props.height * 0.38)
          return `${rad}px 0 0 ${rad}px`
        },
      },
      '&:last-child': {
        borderRadius: (props: StyledProps) => {
          const rad = Math.floor(props.height * 0.38)
          return `0 ${rad}px ${rad}px 0`
        },
      },
    },
    slectedOption: {
      backgroundColor: (props: StyledProps) => props.selectedBackgroundColor,
      outline: (props: StyledProps) => `1px solid ${props.selectedColor}`,
      zIndex: 1,
    },
    label: {
      width: '100%',
      textAlign: 'center',
      fontSize: (props: StyledProps) => props.fontSize,
    },
    optionLabel: {
      color: secondary[600],
    },
    selectedOptionLabel: {
      color: (props: StyledProps) => props.selectedColor,
    },
  }),
)

interface SettingsToggleProps<T> {
  options: T[]
  selected: T
  onSelect(selection: T): void
  width: number // entire width in pixels
  height: number // height in pixels
  fontSize?: number //pixels
}

export default function SettingsToggle<T>({
  options,
  selected,
  onSelect,
  width,
  height,
  fontSize = 12,
}: SettingsToggleProps<T>): React.ReactElement {
  const classes = useStyles({
    selectedColor: primary[500],
    selectedBackgroundColor: primary[100],
    width: width,
    height: height,
    fontSize: `${fontSize}px`,
  })
  return (
    <Box className={classes.container}>
      {options.map(o => {
        const isSelected = selected === o
        const optionClass = isSelected ? `${classes.option} ${classes.slectedOption}` : classes.option
        const optionLabelClass = `${classes.label} ${isSelected ? classes.selectedOptionLabel : classes.optionLabel}`
        return (
          <Box className={optionClass} onClick={() => onSelect(o)}>
            <Typography className={optionLabelClass}>{o}</Typography>
          </Box>
        )
      })}
    </Box>
  )
}
