import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountSocialAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountSocialAccountsQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }> | null } | null } | null };


export const AccountSocialAccountsDocument = gql`
    query AccountSocialAccounts {
  whoami {
    id
    account {
      id
      socialAccounts {
        ... on IGSocialAccount {
          id
          socialUser {
            id
            username
          }
        }
        ... on TTSocialAccount {
          id
          socialUser {
            id
            username
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAccountSocialAccountsQuery__
 *
 * To run a query within a React component, call `useAccountSocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSocialAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSocialAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountSocialAccountsQuery, AccountSocialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSocialAccountsQuery, AccountSocialAccountsQueryVariables>(AccountSocialAccountsDocument, options);
      }
export function useAccountSocialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSocialAccountsQuery, AccountSocialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSocialAccountsQuery, AccountSocialAccountsQueryVariables>(AccountSocialAccountsDocument, options);
        }
export type AccountSocialAccountsQueryHookResult = ReturnType<typeof useAccountSocialAccountsQuery>;
export type AccountSocialAccountsLazyQueryHookResult = ReturnType<typeof useAccountSocialAccountsLazyQuery>;
export type AccountSocialAccountsQueryResult = Apollo.QueryResult<AccountSocialAccountsQuery, AccountSocialAccountsQueryVariables>;