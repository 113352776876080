import React, { useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Collapse } from '@material-ui/core'

const ExpandoText: React.FC<{ minLines: number }> = ({ minLines, children }) => {
  const textRef = useRef<HTMLDivElement | null>(null)
  const [needsExpando, setNeedsExpando] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const [collapsedHeight, setCollapsedHeight] = useState(0)
  useLayoutEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(window.getComputedStyle(textRef.current).lineHeight)
      const newHeight = lineHeight * minLines
      setCollapsedHeight(newHeight)
      if (textRef.current?.clientHeight > newHeight) {
        setNeedsExpando(true)
        setIsExpanded(false)
      } else {
        setNeedsExpando(false)
        setIsExpanded(false)
      }
    }
  }, [minLines])
  return (
    <Box>
      <Collapse in={!needsExpando || isExpanded} collapsedSize={collapsedHeight}>
        <div ref={textRef}>{children}</div>
      </Collapse>
      {needsExpando && (
        <Box mt={2}>
          <Button color="primary" onClick={() => setIsExpanded(prev => !prev)}>
            {isExpanded ? '- Collapse' : '+ Expand'}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default ExpandoText
