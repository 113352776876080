import { useQueryParams, encodeQueryParams, stringify, NumberParam, StringParam, BooleanParam } from 'use-query-params'
import { DecodedValueMap } from 'serialize-query-params'
import { createEnumSetParam, SetParam } from '../utils/url-param-utils'
import { USERNAME_SEARCH_PARAM_KEY, usernameParam } from './use-username-param'
import { BIOGRAPHY_SEARCH_PARAM_KEY, biographyParam } from './use-biography-param'
import { EMAIL_SEARCH_PARAM_KEY, emailParam } from './use-email-param'
import {
  DateTimeRangeFilter,
  MentionStatus,
  LabelsFilter,
  MediaTypeEnum,
  CustomFieldsFilter,
  CustomFieldFilter,
  AbsoluteDateRangeFilter,
  AbsoluteDateTimeRangeFilter,
  IgMediaPostType,
  DateInclusionExclusionFilterInput,
  KeywordsFilterInput,
  MessageSendingMethodsFilterInput,
} from '../gql-global'
import { NumericalRange } from '../components/NumericalRangePicker'
import { MentionTypes, MentionTypesMap } from '../content/constants'
import { NOTES_SEARCH_PARAM_KEY, notesParam } from './use-notes-param'
import {
  getDateInclusionExclusionFiltersToParams,
  getDateFilterParamsConfig,
  getDateInclusionExclusionParamsToFilters,
  cleanAllAnyNoneFilter,
  getDateParamsToFilters,
  getDateRangeFilterToParams,
} from '../utils/filter-params'
import { MESSAGE_KEYWORDS_SEARCH_PARAM_KEY } from '../message/use-message-keywords-param'

export interface Filters {
  approvedAt?: DateTimeRangeFilter
  avgEngagementRate?: NumericalRange
  biographyKeywords?: string[]
  campaigns?: Omit<LabelsFilter, '__typename'>
  challengeIds?: Omit<LabelsFilter, '__typename'>
  customFields?: Omit<CustomFieldsFilter, '__typename'>
  emailKeywords?: string[]
  filterId?: string
  followerCount?: NumericalRange
  hasIgUser?: boolean
  impressions?: NumericalRange
  labels?: Omit<LabelsFilter, '__typename'>
  maxPostedAt?: DateTimeRangeFilter
  maxRewardedAt?: DateTimeRangeFilter
  mediaType?: Set<MediaTypeEnum>
  mentionType?: Set<MentionTypes>
  messageDate?: Omit<DateInclusionExclusionFilterInput, '__typename'>
  messageKeywords?: KeywordsFilterInput
  messageSendingMethod?: MessageSendingMethodsFilterInput
  messageTemplate?: Omit<LabelsFilter, '__typename'>
  minPostedAt?: DateTimeRangeFilter
  noteCategories?: Set<string>
  noteCreatedAt?: DateTimeRangeFilter
  notesKeywords?: string[]
  postCount?: NumericalRange
  postType?: Set<IgMediaPostType>
  postedAt?: DateTimeRangeFilter
  rewards?: Set<string>
  segments?: Omit<LabelsFilter, '__typename'>
  tagStatus?: Set<MentionStatus>
  challengeMediaApproval?: Set<MentionStatus>
  usernameKeywords?: string[]
}

const filterParamConfig = {
  filterId: StringParam,
  ...getDateFilterParamsConfig('approvedAt', ''),
  ...getDateFilterParamsConfig('postedAt', ''),
  ...getDateFilterParamsConfig('maxPostedAt', ''),
  ...getDateFilterParamsConfig('maxRewardedAt', ''),
  ...getDateFilterParamsConfig('minPostedAt', ''),
  followerCountGte: NumberParam,
  followerCountLte: NumberParam,
  impressionsGte: NumberParam,
  impressionsLte: NumberParam,
  postCountGte: NumberParam,
  postCountLte: NumberParam,
  [USERNAME_SEARCH_PARAM_KEY]: usernameParam,
  [EMAIL_SEARCH_PARAM_KEY]: emailParam,
  [BIOGRAPHY_SEARCH_PARAM_KEY]: biographyParam,
  [NOTES_SEARCH_PARAM_KEY]: notesParam,
  avgEngagementRateGte: NumberParam,
  avgEngagementRateLte: NumberParam,
  challengeIds: StringParam,
  segments: StringParam,
  campaigns: StringParam,
  labels: StringParam,
  messageTemplate: StringParam,
  [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: StringParam,
  messageSendingMethod: StringParam,
  mentionType: createEnumSetParam(MentionTypesMap),
  tagStatus: createEnumSetParam(MentionStatus),
  challengeMediaApproval: createEnumSetParam(MentionStatus),
  mediaType: createEnumSetParam(MediaTypeEnum),
  postType: createEnumSetParam(IgMediaPostType),
  rewards: SetParam,
  noteCategories: SetParam,
  ...getDateFilterParamsConfig('noteCreatedAt', ''),
  hasIgUser: BooleanParam,
  customFields: StringParam,
  ...getDateFilterParamsConfig('messageDate', 'exclude'),
  ...getDateFilterParamsConfig('messageDate', 'require'),
}

function paramsToFilters(params: CustomerParams): Filters {
  const {
    filterId,
    followerCountLte,
    followerCountGte,
    postCountLte,
    postCountGte,
    avgEngagementRateLte,
    avgEngagementRateGte,
    impressionsGte,
    impressionsLte,
    segments,
    campaigns,
    challengeIds,
    labels,
    messageTemplate,
    messageKeywords,
    mentionType,
    tagStatus,
    challengeMediaApproval,
    mediaType,
    postType,
    rewards,
    noteCategories,
    hasIgUser,
    customFields,
    messageSendingMethod,
  } = params

  const segmentsJson = segments ? JSON.parse(segments) : undefined
  const campaignsJson = campaigns ? JSON.parse(campaigns) : undefined
  const challengeIdsJson = challengeIds ? JSON.parse(challengeIds) : undefined
  const labelsJson = labels ? JSON.parse(labels) : undefined
  const messageTemplateJson = messageTemplate ? JSON.parse(messageTemplate) : undefined
  const messageKeywordsJson = messageKeywords ? JSON.parse(messageKeywords) : undefined
  const customFieldsJson = customFields ? JSON.parse(customFields) : undefined

  const messageSendingMethodJson = messageSendingMethod ? JSON.parse(messageSendingMethod) : undefined

  return {
    filterId,
    approvedAt: getDateParamsToFilters('approvedAt', '', params),
    postedAt: getDateParamsToFilters('postedAt', '', params),
    maxPostedAt: getDateParamsToFilters('maxPostedAt', '', params),
    minPostedAt: getDateParamsToFilters('minPostedAt', '', params),
    followerCount:
      followerCountGte !== undefined || followerCountLte !== undefined
        ? { lte: followerCountLte, gte: followerCountGte }
        : undefined,
    impressions:
      impressionsGte !== undefined || impressionsLte !== undefined
        ? { lte: impressionsLte, gte: impressionsGte }
        : undefined,
    postCount:
      postCountGte !== undefined || postCountLte !== undefined ? { lte: postCountLte, gte: postCountGte } : undefined,
    usernameKeywords: params[USERNAME_SEARCH_PARAM_KEY] || undefined,
    emailKeywords: params[EMAIL_SEARCH_PARAM_KEY] || undefined,
    biographyKeywords: params[BIOGRAPHY_SEARCH_PARAM_KEY] || undefined,
    notesKeywords: params[NOTES_SEARCH_PARAM_KEY] || undefined,
    avgEngagementRate:
      avgEngagementRateGte !== undefined || avgEngagementRateLte !== undefined
        ? { lte: avgEngagementRateLte, gte: avgEngagementRateGte }
        : undefined,
    segments:
      segmentsJson && (segmentsJson.all || segmentsJson.none)
        ? {
            all: segmentsJson?.all ? (segmentsJson.all as string[][]) : undefined,
            none: segmentsJson?.none ? (segmentsJson?.none as string[]) : undefined,
          }
        : undefined,
    campaigns:
      campaignsJson && campaignsJson.any
        ? {
            any: campaignsJson?.any || undefined,
          }
        : undefined,
    challengeIds:
      challengeIdsJson && challengeIdsJson.any
        ? {
            any: challengeIdsJson?.any || undefined,
          }
        : undefined,
    messageTemplate:
      messageTemplateJson && (messageTemplateJson.all || messageTemplateJson.none)
        ? {
            all: messageTemplateJson?.all ? (messageTemplateJson.all as string[][]) : undefined,
            none: messageTemplateJson?.none ? (messageTemplateJson?.none as string[]) : undefined,
          }
        : undefined,
    messageDate: getDateInclusionExclusionParamsToFilters('messageDate', params),
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: cleanAllAnyNoneFilter(messageKeywordsJson),
    labels:
      labelsJson && (labelsJson.all || labelsJson.none)
        ? {
            all: labelsJson?.all ? (labelsJson.all as string[][]) : undefined,
            none: labelsJson?.none ? (labelsJson?.none as string[]) : undefined,
          }
        : undefined,
    mentionType,
    tagStatus,
    challengeMediaApproval,
    mediaType,
    postType,
    rewards,
    maxRewardedAt: getDateParamsToFilters('maxRewardedAt', '', params),
    noteCategories,
    noteCreatedAt: getDateParamsToFilters('noteCreatedAt', '', params),
    hasIgUser,
    customFields:
      customFieldsJson && (customFieldsJson.all || customFieldsJson.any)
        ? {
            all: customFieldsJson?.all
              ? (customFieldsJson.all.map(({ dateValue, ...restField }: CustomFieldFilter) => {
                  if (dateValue) {
                    const newDateValue = dateValue as AbsoluteDateRangeFilter | AbsoluteDateTimeRangeFilter
                    return {
                      ...restField,
                      dateValue: {
                        ...dateValue,
                        ...(newDateValue.gte ? { gte: new Date(newDateValue.gte) } : {}),
                        ...(newDateValue.lt ? { lt: new Date(newDateValue.lt) } : {}),
                      },
                    }
                  }

                  return { ...restField }
                }) as CustomFieldFilter[])
              : undefined,
            any: customFieldsJson?.any
              ? (customFieldsJson.any.map(({ dateValue, ...restField }: CustomFieldFilter) => {
                  if (dateValue) {
                    const newDateValue = dateValue as AbsoluteDateRangeFilter | AbsoluteDateTimeRangeFilter
                    return {
                      ...restField,
                      dateValue: {
                        ...dateValue,
                        ...(newDateValue.gte ? { gte: new Date(newDateValue.gte) } : {}),
                        ...(newDateValue.lt ? { lt: new Date(newDateValue.lt) } : {}),
                      },
                    }
                  }

                  return { ...restField }
                }) as CustomFieldFilter[])
              : undefined,
          }
        : undefined,
    messageSendingMethod: cleanAllAnyNoneFilter(messageSendingMethodJson),
  }
}

function filtersToParams(filters: Partial<Filters>): CustomerParams {
  const challengeIdsFilter = filters.challengeIds?.any ? { any: filters.challengeIds?.any } : undefined
  return {
    ...getDateRangeFilterToParams('approvedAt', '', filters.approvedAt),
    ...getDateRangeFilterToParams('postedAt', '', filters.postedAt),
    ...getDateRangeFilterToParams('maxPostedAt', '', filters.maxPostedAt),
    ...getDateRangeFilterToParams('minPostedAt', '', filters.minPostedAt),
    ...getDateRangeFilterToParams('maxRewardedAt', '', filters.maxRewardedAt),
    ...getDateInclusionExclusionFiltersToParams('messageDate', filters.messageDate),
    filterId: filters.filterId,
    followerCountGte: filters.followerCount?.gte,
    followerCountLte: filters.followerCount?.lte,
    impressionsGte: filters.impressions?.gte,
    impressionsLte: filters.impressions?.lte,
    postCountGte: filters.postCount?.gte,
    postCountLte: filters.postCount?.lte,
    [USERNAME_SEARCH_PARAM_KEY]: filters.usernameKeywords,
    [EMAIL_SEARCH_PARAM_KEY]: filters.emailKeywords,
    [BIOGRAPHY_SEARCH_PARAM_KEY]: filters.biographyKeywords,
    [NOTES_SEARCH_PARAM_KEY]: filters.notesKeywords,
    avgEngagementRateLte: filters.avgEngagementRate?.lte,
    avgEngagementRateGte: filters.avgEngagementRate?.gte,
    segments: JSON.stringify(cleanAllAnyNoneFilter(filters.segments)),
    campaigns: JSON.stringify(cleanAllAnyNoneFilter(filters.campaigns)),
    challengeIds: JSON.stringify(challengeIdsFilter),
    messageTemplate: JSON.stringify(filters.messageTemplate),
    [MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]: JSON.stringify(
      cleanAllAnyNoneFilter(filters[MESSAGE_KEYWORDS_SEARCH_PARAM_KEY]),
    ),
    labels: JSON.stringify(filters.labels),
    mentionType: filters.mentionType,
    tagStatus: filters.tagStatus,
    challengeMediaApproval: filters.challengeMediaApproval,
    mediaType: filters.mediaType,
    postType: filters.postType,
    rewards: filters.rewards ? new Set(filters.rewards) : undefined,
    noteCategories: filters.noteCategories ? new Set(filters.noteCategories) : undefined,
    ...getDateRangeFilterToParams('noteCreatedAt', '', filters.noteCreatedAt),
    hasIgUser: filters.hasIgUser,
    customFields: JSON.stringify(filters.customFields),
    messageSendingMethod: JSON.stringify(cleanAllAnyNoneFilter(filters.messageSendingMethod)),
  }
}

type CustomerParams = Partial<DecodedValueMap<typeof filterParamConfig>>

/*
 * encodes customer filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Partial<Filters>): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  return {
    filters: paramsToFilters(filterParams),
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, f ? 'replaceIn' : 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
