import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateParticipantsStatusMutationVariables = Types.Exact<{
  campaignId?: Types.InputMaybe<Types.Scalars['ID']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  mentionsWhere?: Types.InputMaybe<Types.CustomerMentionStatsFilters>;
  participantIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  status: Types.ParticipantStatus;
  where?: Types.InputMaybe<Types.ParticipantsInput>;
}>;


export type UpdateParticipantsStatusMutation = { __typename: 'Mutations', updateParticipantStatus?: { __typename: 'UpdateParticipantStatus', ok?: boolean | null, participants?: Array<{ __typename: 'ProgramParticipantType', id: number, status: Types.ParticipantStatus }> | null } | null };


export const UpdateParticipantsStatusDocument = gql`
    mutation UpdateParticipantsStatus($campaignId: ID, $limit: Int, $mentionsWhere: CustomerMentionStatsFilters, $participantIds: [ID!], $sortBy: ParticipantSort, $sortDirection: SortDirection, $status: ParticipantStatus!, $where: ParticipantsInput) {
  updateParticipantStatus(
    campaignId: $campaignId
    limit: $limit
    mentionsWhere: $mentionsWhere
    participantIds: $participantIds
    sortBy: $sortBy
    sortDirection: $sortDirection
    status: $status
    where: $where
  ) {
    ok
    participants {
      id
      status
    }
  }
}
    `;
export type UpdateParticipantsStatusMutationFn = Apollo.MutationFunction<UpdateParticipantsStatusMutation, UpdateParticipantsStatusMutationVariables>;

/**
 * __useUpdateParticipantsStatusMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantsStatusMutation, { data, loading, error }] = useUpdateParticipantsStatusMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      limit: // value for 'limit'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      participantIds: // value for 'participantIds'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      status: // value for 'status'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateParticipantsStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateParticipantsStatusMutation, UpdateParticipantsStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateParticipantsStatusMutation, UpdateParticipantsStatusMutationVariables>(UpdateParticipantsStatusDocument, options);
      }
export type UpdateParticipantsStatusMutationHookResult = ReturnType<typeof useUpdateParticipantsStatusMutation>;
export type UpdateParticipantsStatusMutationResult = Apollo.MutationResult<UpdateParticipantsStatusMutation>;
export type UpdateParticipantsStatusMutationOptions = Apollo.BaseMutationOptions<UpdateParticipantsStatusMutation, UpdateParticipantsStatusMutationVariables>;