import React from 'react'
import { makeStyles, Chip, createStyles, Box } from '@material-ui/core'
import { FbUser } from '../gql-global'
import { ReactComponent as DotIcon } from '../icons/dot.svg'

const useStyles = makeStyles(theme =>
  createStyles({
    connected: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.dark,
      display: 'flex',
      alignItems: 'center',
    },
    disconnected: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

interface CustomerAuthStatusChipProps {
  fbUsers?: FbUser[]
}

function CustomerAuthStatusChips({ fbUsers = [] }: CustomerAuthStatusChipProps): React.ReactElement {
  const classes = useStyles()
  const validFbUsers = fbUsers.filter(u => u.isValid)

  const storiesClass = !!validFbUsers.length ? classes.connected : classes.disconnected

  return (
    <Box display="flex">
      <Chip
        className={storiesClass}
        icon={<DotIcon className={storiesClass} width={8} height={8} />}
        label={'Stories'}
        size="small"
      />
    </Box>
  )
}

export default CustomerAuthStatusChips
