import { IgMediaPostType, ProgramTierBuffer, SocialPlatformEnum } from '../../../gql-global'
import { IgMentionTypes } from '../constants'

export enum RewardAutomationTypes {
  SINGLE_TIER_MONTHLY = 'SINGLE_TIER_MONTHLY',
  MULTI_TIER_MONTHLY = 'MULTI_TIER_MONTHLY',
}

export const MINIMUM_ALLOWED_REWARD_TIERS = 2

export const POST_COUNT_CRITERIA_DEFAULT_VALUES = {
  isBuffered: false,
  bufferDateRange: ProgramTierBuffer.OneDay,
  selectedPostTypes: new Set<IgMediaPostType | SocialPlatformEnum.Tiktok>([
    IgMediaPostType.Feed,
    IgMediaPostType.Story,
    IgMediaPostType.Reels,
    SocialPlatformEnum.Tiktok,
  ]),
  selectedMentionTypes: new Set<IgMentionTypes>([IgMentionTypes.Tag, IgMentionTypes.Caption]),
}
