import React, { useState, useRef, useEffect } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Link,
  Avatar,
  Menu,
  MenuItem,
  InputBase,
  InputAdornment,
  Typography,
  Badge,
} from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { AUTH0_LOGOUT_ROUTE } from '../../auth/routes'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { CONTENT_ROUTE } from '../../content/routes'
import useCaptionParam from '../../content/use-caption-param'
import useHashtagParam from '../../content/use-hashtag-param'
import useUsernameParam from '../../customer/use-username-param'
import useLabelParam from '../../settings/labels/use-label-param'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { LABEL_MANAGEMENT_ROUTE, REWARD_MANAGEMENT_ROUTE, SEGMENT_MANAGEMENT_ROUTE } from '../../settings/routes'
import useSegmentParam from '../../settings/segments/use-segment-param'
import useRewardParam from '../../settings/rewards/use-reward-param'
import useAccountParam from '../../admin/accounts/use-account-param'
import useSocialAccountParam from '../../admin/tiktok-hashtags/use-social-account-param'
import {
  CAMPAIGN_DETAIL_ACTIVITY_ROUTE,
  CAMPAIGN_DETAIL_MEMBERS_ROUTE,
  CAMPAIGN_DETAIL_APPLICATIONS_ROUTE,
} from '../../campaign/routes'
import { cyan, secondary, success } from '../../loudcrowd-theme'
import { UpsellModalUpdaterContext } from '../UpsellModal'
import { ADMIN_ACCOUNTS_ROUTE, ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS } from '../../admin/routes'
import { parseCaptionKeywords } from '../../utils/caption-keywords'
import { REVIEW_ROUTE } from '../../review/routes'
import useReviewProgramParam from '../../review/use-review-program-param'
import useMessageKeywordsParam from '../../message/use-message-keywords-param'
import { MESSAGE_ACTIVITY_ROUTE, MESSAGE_ROUTE } from '../../message/routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      justifyContent: 'space-between',
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      '& .floatRight': {
        marginLeft: 'auto',
      },
    },
    searchAdornment: {
      marginRight: theme.spacing(7),
    },
    searchIcon: {
      color: theme.palette.secondary.main,
    },
    crossAdornment: {
      cursor: 'pointer',
    },
    crossIcon: {
      color: theme.palette.secondary.main,
    },
    searchInput: {
      minWidth: 250,
      marginLeft: theme.spacing(7),
    },
    trialContainer: {
      display: 'flex',
      background: cyan[100],
      padding: '.75rem',
      borderRadius: '5px',
      marginRight: '.75rem',
    },
    trialTime: {
      fontColor: secondary[900],
      fontWeight: 700,
      margin: '0 .25rem',
    },
    trialContainerLink: {
      color: cyan[700],
      textDecoration: 'none',
      fontWeight: 700,
      marginLeft: '.5rem',
    },
    betaIndicator: {
      // marginTop: 2,
      marginRight: 12,
      color: success[700],
      fontSize: 12,
      fontWeight: 700,
    },
    betaIndicatorBadge: {
      '& .MuiBadge-dot': {
        backgroundColor: success[700],
        height: 10,
        width: 10,
        top: 8,
        left: -22,
        border: 0,
      },
    },
  }),
)

interface TopBarProps {
  email?: string | null
  organization?: {
    isTrialing?: boolean | null
    trialDaysRemaining?: number | null
  } | null
  loading?: boolean
  isBetaTester?: boolean
}

const TopBar: React.FC<TopBarProps> = ({ email, organization, loading, isBetaTester = false }) => {
  const classes = useStyles()
  const setModalOptions = React.useContext(UpsellModalUpdaterContext)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { pathname, search } = useLocation()

  const [open, setOpen] = useState(false)
  const [logout, setLogout] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isWriting, setIsWriting] = useState(false)

  const [captions, setCaptionSearchURLParam] = useCaptionParam()
  const [hashtags, setHashtagSearchURLParam] = useHashtagParam()
  const [usernames, setUsernameSearchURLParam] = useUsernameParam()
  const [keywords, setMessageKeywordsSearchURLParam] = useMessageKeywordsParam()
  const [labels, setLabelSearchURLParam] = useLabelParam()
  const [segments, setSegmentSearchURLParam] = useSegmentParam()
  const [rewards, setRewardSearchURLParam] = useRewardParam()
  const [accounts, setAccountSearchURLParam] = useAccountParam()
  const [socialAccounts, setSocialAccountSearchURLParam] = useSocialAccountParam()
  const [programs, setReviewProgramSearchURLParam] = useReviewProgramParam()

  const customSetSubmitCaptionSearchURLParam = (words?: string[]): void => {
    const hashtags = words?.filter(k => k.startsWith('#')).map(k => k.slice(1))
    const keywords = words?.filter(k => !k.startsWith('#'))

    setCaptionSearchURLParam(keywords)
    setHashtagSearchURLParam(hashtags)
  }

  const startIcon = loading ? <Skeleton variant="circle" width={40} height={40} /> : <Avatar />

  const searchOptionsConfig = [
    { paths: CONTENT_ROUTE.path, searchProp: 'captions', hookSetFunction: customSetSubmitCaptionSearchURLParam },
    { paths: MESSAGE_ROUTE.path, searchProp: 'messages', hookSetFunction: setMessageKeywordsSearchURLParam },
    {
      paths: [
        CUSTOMER_ROUTE.path,
        CAMPAIGN_DETAIL_ACTIVITY_ROUTE.path,
        CAMPAIGN_DETAIL_MEMBERS_ROUTE.path,
        CAMPAIGN_DETAIL_APPLICATIONS_ROUTE.path,
        MESSAGE_ACTIVITY_ROUTE.path,
      ],
      searchProp: 'usernames',
      hookSetFunction: setUsernameSearchURLParam,
    },
    { paths: LABEL_MANAGEMENT_ROUTE.path, searchProp: 'labels', hookSetFunction: setLabelSearchURLParam },
    { paths: SEGMENT_MANAGEMENT_ROUTE.path, searchProp: 'segments', hookSetFunction: setSegmentSearchURLParam },
    { paths: REWARD_MANAGEMENT_ROUTE.path, searchProp: 'rewards', hookSetFunction: setRewardSearchURLParam },
    { paths: ADMIN_ACCOUNTS_ROUTE.path, searchProp: 'accounts', hookSetFunction: setAccountSearchURLParam },
    {
      paths: ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS.path,
      searchProp: 'social account name',
      hookSetFunction: setSocialAccountSearchURLParam,
    },
    { paths: REVIEW_ROUTE.path, searchProp: 'program names', hookSetFunction: setReviewProgramSearchURLParam },
  ]

  const handleSubmit = (event: React.FormEvent, hookSetFunction: (value?: string[]) => void): void => {
    event.preventDefault()
    setIsWriting(false)
    hookSetFunction(parseCaptionKeywords(searchText))
  }

  const handleReset = (hookSetFunction: (value?: string[]) => void) => {
    hookSetFunction()
    setSearchText('')
  }

  const handleOpenUpsellModal = () => {
    setModalOptions({
      isOpen: true,
      modalProps: {
        context: { reason: 'USER_TRIAL' },
        onCancel: () => setModalOptions({ isOpen: false }),
      },
    })
  }

  const shouldResetSearch = () =>
    ![searchText, searchText.replace('#', '')].includes(
      [captions, hashtags, usernames, keywords, labels, segments, rewards, accounts, socialAccounts, programs]
        .flatMap(item => item)
        .join(' ')
        .trim(),
    )

  useEffect(() => {
    if (!isWriting && shouldResetSearch()) {
      setSearchText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isWriting])

  useEffect(() => setSearchText(''), [pathname])

  useEffect(() => setIsWriting(!!searchText), [searchText])

  if (logout) {
    return <Redirect to={AUTH0_LOGOUT_ROUTE.path} />
  }

  return (
    <AppBar position="sticky" color="inherit" elevation={1}>
      <Toolbar className={classes.toolbar}>
        {searchOptionsConfig.map(({ paths, searchProp, hookSetFunction }) => (
          <Route exact path={paths} key={searchProp}>
            <form noValidate onSubmit={event => handleSubmit(event, hookSetFunction)}>
              <InputBase
                className={classes.searchInput}
                placeholder={`Search ${searchProp}...`}
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start" className={classes.searchAdornment}>
                    <SearchIcon width={20} height={20} className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment
                      position="end"
                      className={classes.crossAdornment}
                      onClick={() => handleReset(hookSetFunction)}
                    >
                      <CrossIcon width={12} height={12} className={classes.crossIcon} role="cross-icon" />
                    </InputAdornment>
                  )
                }
              />
            </form>
          </Route>
        ))}

        {organization?.isTrialing && (
          <Box className={classes.trialContainer}>
            <Typography variant="body2"> Free Trial </Typography>
            <Typography variant="body2" className={classes.trialTime}>
              {organization?.trialDaysRemaining} days left
            </Typography>
            <Link variant="body2" onClick={handleOpenUpsellModal} className={classes.trialContainerLink}>
              View plans and pricing
            </Link>
          </Box>
        )}

        <Box className="floatRight" display="flex" alignItems="center">
          {isBetaTester && (
            <Box display="flex" alignItems="center">
              <Badge className={classes.betaIndicatorBadge} invisible={false} variant="dot" overlap="rectangular">
                <Typography className={classes.betaIndicator}>Beta</Typography>
              </Badge>
            </Box>
          )}
          <Button size="large" startIcon={startIcon} disabled={loading} onClick={() => setOpen(true)} ref={buttonRef}>
            {loading ? <Skeleton width={80} /> : email}
          </Button>
        </Box>
        <Menu
          open={open}
          anchorEl={buttonRef.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={() => setLogout(true)}>Log out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
