import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLastDashboardDateRangeMutationVariables = Types.Exact<{
  lastDashboardDateRange: Types.DateRangeFilterInput;
}>;


export type UpdateLastDashboardDateRangeMutation = { __typename: 'Mutations', updateLastDashboardDateRange: { __typename: 'AbsoluteDateTimeRangeFilter' } | { __typename: 'RelativeDateRangeFilter' } };


export const UpdateLastDashboardDateRangeDocument = gql`
    mutation UpdateLastDashboardDateRange($lastDashboardDateRange: DateRangeFilterInput!) {
  updateLastDashboardDateRange(lastDashboardDateRange: $lastDashboardDateRange) @client {
    __typename
  }
}
    `;
export type UpdateLastDashboardDateRangeMutationFn = Apollo.MutationFunction<UpdateLastDashboardDateRangeMutation, UpdateLastDashboardDateRangeMutationVariables>;

/**
 * __useUpdateLastDashboardDateRangeMutation__
 *
 * To run a mutation, you first call `useUpdateLastDashboardDateRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastDashboardDateRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastDashboardDateRangeMutation, { data, loading, error }] = useUpdateLastDashboardDateRangeMutation({
 *   variables: {
 *      lastDashboardDateRange: // value for 'lastDashboardDateRange'
 *   },
 * });
 */
export function useUpdateLastDashboardDateRangeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLastDashboardDateRangeMutation, UpdateLastDashboardDateRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLastDashboardDateRangeMutation, UpdateLastDashboardDateRangeMutationVariables>(UpdateLastDashboardDateRangeDocument, options);
      }
export type UpdateLastDashboardDateRangeMutationHookResult = ReturnType<typeof useUpdateLastDashboardDateRangeMutation>;
export type UpdateLastDashboardDateRangeMutationResult = Apollo.MutationResult<UpdateLastDashboardDateRangeMutation>;
export type UpdateLastDashboardDateRangeMutationOptions = Apollo.BaseMutationOptions<UpdateLastDashboardDateRangeMutation, UpdateLastDashboardDateRangeMutationVariables>;