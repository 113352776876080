import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type SocialAccountRow_IgSocialAccount_Fragment = { __typename: 'IGSocialAccount', id: string, lastPull?: Date | null, platformId?: string | null, socialUser: { __typename: 'IGUser', id: string, username: string } };

export type SocialAccountRow_TtSocialAccount_Fragment = { __typename: 'TTSocialAccount', id: string, lastPull?: Date | null, platformId?: string | null, socialUser: { __typename: 'TTUser', id: string, username: string } };

export type SocialAccountRowFragment = SocialAccountRow_IgSocialAccount_Fragment | SocialAccountRow_TtSocialAccount_Fragment;

export const SocialAccountRowFragmentDoc = gql`
    fragment SocialAccountRow on SocialAccount {
  id
  lastPull
  platformId
  socialUser {
    id
    username
  }
}
    `;