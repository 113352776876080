import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportCampaignMembersQueryVariables = Types.Exact<{
  ambassadorsWhere?: Types.InputMaybe<Types.AmbassadorStatsInput>;
  campaignId: Types.Scalars['ID'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  fields?: Types.InputMaybe<Array<Types.CustomerExportFieldEnum> | Types.CustomerExportFieldEnum>;
  limit: Types.Scalars['Int'];
  mentionsWhere: Types.CustomerMentionStatsFilters;
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  where?: Types.InputMaybe<Types.ParticipantsInput>;
}>;


export type ExportCampaignMembersQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, participants?: { __typename: 'PagedParticipants', csvUrl?: string | null } | null } | null };


export const ExportCampaignMembersDocument = gql`
    query ExportCampaignMembers($ambassadorsWhere: AmbassadorStatsInput, $campaignId: ID!, $cursor: String, $fields: [CustomerExportFieldEnum!], $limit: Int!, $mentionsWhere: CustomerMentionStatsFilters!, $sortBy: ParticipantSort, $sortDirection: SortDirection, $where: ParticipantsInput) {
  campaign(id: $campaignId) {
    id
    participants(
      ambassadorStatsWhere: $ambassadorsWhere
      cursor: $cursor
      limit: $limit
      mentionsWhere: $mentionsWhere
      sortBy: $sortBy
      sortDirection: $sortDirection
      where: $where
    ) {
      csvUrl(
        ambassadorsWhere: $ambassadorsWhere
        fields: $fields
        mentionsWhere: $mentionsWhere
      )
    }
  }
}
    `;

/**
 * __useExportCampaignMembersQuery__
 *
 * To run a query within a React component, call `useExportCampaignMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCampaignMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCampaignMembersQuery({
 *   variables: {
 *      ambassadorsWhere: // value for 'ambassadorsWhere'
 *      campaignId: // value for 'campaignId'
 *      cursor: // value for 'cursor'
 *      fields: // value for 'fields'
 *      limit: // value for 'limit'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExportCampaignMembersQuery(baseOptions: Apollo.QueryHookOptions<ExportCampaignMembersQuery, ExportCampaignMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCampaignMembersQuery, ExportCampaignMembersQueryVariables>(ExportCampaignMembersDocument, options);
      }
export function useExportCampaignMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCampaignMembersQuery, ExportCampaignMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCampaignMembersQuery, ExportCampaignMembersQueryVariables>(ExportCampaignMembersDocument, options);
        }
export type ExportCampaignMembersQueryHookResult = ReturnType<typeof useExportCampaignMembersQuery>;
export type ExportCampaignMembersLazyQueryHookResult = ReturnType<typeof useExportCampaignMembersLazyQuery>;
export type ExportCampaignMembersQueryResult = Apollo.QueryResult<ExportCampaignMembersQuery, ExportCampaignMembersQueryVariables>;