import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProgramsByAccountQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.ProgramFilterInput>;
  sortBy?: Types.InputMaybe<Types.ProgramSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type AdminProgramsByAccountQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, programs?: { __typename: 'PagedProgramType', total: number, cursor?: string | null, results: Array<{ __typename: 'ProgramType', id: string, name: string, ltvActive: boolean, campaign?: { __typename: 'CampaignType', id: number, startAt: Date } | null }> } | null } | null };


export const AdminProgramsByAccountDocument = gql`
    query AdminProgramsByAccount($accountId: ID!, $limit: Int, $cursor: String, $where: ProgramFilterInput, $sortBy: ProgramSort, $sortDirection: SortDirection) {
  account(id: $accountId) {
    id
    programs(
      limit: $limit
      cursor: $cursor
      where: $where
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      total
      cursor
      results {
        id
        name
        ltvActive
        campaign {
          id
          startAt
        }
      }
    }
  }
}
    `;

/**
 * __useAdminProgramsByAccountQuery__
 *
 * To run a query within a React component, call `useAdminProgramsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProgramsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProgramsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAdminProgramsByAccountQuery(baseOptions: Apollo.QueryHookOptions<AdminProgramsByAccountQuery, AdminProgramsByAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProgramsByAccountQuery, AdminProgramsByAccountQueryVariables>(AdminProgramsByAccountDocument, options);
      }
export function useAdminProgramsByAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProgramsByAccountQuery, AdminProgramsByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProgramsByAccountQuery, AdminProgramsByAccountQueryVariables>(AdminProgramsByAccountDocument, options);
        }
export type AdminProgramsByAccountQueryHookResult = ReturnType<typeof useAdminProgramsByAccountQuery>;
export type AdminProgramsByAccountLazyQueryHookResult = ReturnType<typeof useAdminProgramsByAccountLazyQuery>;
export type AdminProgramsByAccountQueryResult = Apollo.QueryResult<AdminProgramsByAccountQuery, AdminProgramsByAccountQueryVariables>;