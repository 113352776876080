import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackSocialAccountsMutationVariables = Types.Exact<{
  fbUserId: Types.Scalars['ID'];
  socialAccountPlatformIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type TrackSocialAccountsMutation = { __typename: 'Mutations', trackSocialAccounts?: { __typename: 'TrackSocialAccounts', ok: boolean, socialAccounts?: Array<{ __typename: 'IGSocialAccount', expiresAt?: Date | null, lastPull?: Date | null, id: string, platformId?: string | null }> | null } | null };


export const TrackSocialAccountsDocument = gql`
    mutation TrackSocialAccounts($fbUserId: ID!, $socialAccountPlatformIds: [String!]!) {
  trackSocialAccounts(fbUserId: $fbUserId, platformIds: $socialAccountPlatformIds) {
    ok
    socialAccounts {
      id
      platformId
      ... on IGSocialAccount {
        expiresAt
        lastPull
      }
    }
  }
}
    `;
export type TrackSocialAccountsMutationFn = Apollo.MutationFunction<TrackSocialAccountsMutation, TrackSocialAccountsMutationVariables>;

/**
 * __useTrackSocialAccountsMutation__
 *
 * To run a mutation, you first call `useTrackSocialAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackSocialAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackSocialAccountsMutation, { data, loading, error }] = useTrackSocialAccountsMutation({
 *   variables: {
 *      fbUserId: // value for 'fbUserId'
 *      socialAccountPlatformIds: // value for 'socialAccountPlatformIds'
 *   },
 * });
 */
export function useTrackSocialAccountsMutation(baseOptions?: Apollo.MutationHookOptions<TrackSocialAccountsMutation, TrackSocialAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackSocialAccountsMutation, TrackSocialAccountsMutationVariables>(TrackSocialAccountsDocument, options);
      }
export type TrackSocialAccountsMutationHookResult = ReturnType<typeof useTrackSocialAccountsMutation>;
export type TrackSocialAccountsMutationResult = Apollo.MutationResult<TrackSocialAccountsMutation>;
export type TrackSocialAccountsMutationOptions = Apollo.BaseMutationOptions<TrackSocialAccountsMutation, TrackSocialAccountsMutationVariables>;