import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopHashtagsQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  mentionsWhere?: Types.InputMaybe<Types.HashtagMentionsFilter>;
  prevMentionsWhere?: Types.InputMaybe<Types.HashtagMentionsFilter>;
}>;


export type TopHashtagsQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, hashtags: { __typename: 'PagedHashtags', total: number, results: Array<{ __typename: 'Hashtag', id: string, hashtag: string, mentionStats: { __typename: 'HashtagMentionStats', ranking?: number | null, count: number }, prevMentionStats: { __typename: 'HashtagMentionStats', ranking?: number | null, count: number } }> } } | { __typename: 'TTSocialAccount', id: string, hashtags: { __typename: 'PagedHashtags', total: number, results: Array<{ __typename: 'Hashtag', id: string, hashtag: string, mentionStats: { __typename: 'HashtagMentionStats', ranking?: number | null, count: number }, prevMentionStats: { __typename: 'HashtagMentionStats', ranking?: number | null, count: number } }> } } | null };


export const TopHashtagsDocument = gql`
    query TopHashtags($socialAccountId: ID!, $mentionsWhere: HashtagMentionsFilter, $prevMentionsWhere: HashtagMentionsFilter) {
  socialAccount(id: $socialAccountId) {
    id
    hashtags(mentionsWhere: $mentionsWhere) {
      total
      results {
        id
        hashtag
        mentionStats(where: $mentionsWhere) {
          ranking
          count
        }
        prevMentionStats: mentionStats(where: $prevMentionsWhere) {
          ranking
          count
        }
      }
    }
  }
}
    `;

/**
 * __useTopHashtagsQuery__
 *
 * To run a query within a React component, call `useTopHashtagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopHashtagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopHashtagsQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      prevMentionsWhere: // value for 'prevMentionsWhere'
 *   },
 * });
 */
export function useTopHashtagsQuery(baseOptions: Apollo.QueryHookOptions<TopHashtagsQuery, TopHashtagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopHashtagsQuery, TopHashtagsQueryVariables>(TopHashtagsDocument, options);
      }
export function useTopHashtagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopHashtagsQuery, TopHashtagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopHashtagsQuery, TopHashtagsQueryVariables>(TopHashtagsDocument, options);
        }
export type TopHashtagsQueryHookResult = ReturnType<typeof useTopHashtagsQuery>;
export type TopHashtagsLazyQueryHookResult = ReturnType<typeof useTopHashtagsLazyQuery>;
export type TopHashtagsQueryResult = Apollo.QueryResult<TopHashtagsQuery, TopHashtagsQueryVariables>;