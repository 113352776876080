import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertProgramTierGroupMutationVariables = Types.Exact<{
  programId: Types.Scalars['ID'];
  programTierGroupId?: Types.InputMaybe<Types.Scalars['ID']>;
  startAt?: Types.InputMaybe<Types.Scalars['DateTime']>;
  status?: Types.InputMaybe<Types.ProgramTierGroupStatus>;
  tiers?: Types.InputMaybe<Array<Types.ProgramTierInput> | Types.ProgramTierInput>;
}>;


export type UpsertProgramTierGroupMutation = { __typename: 'Mutations', upsertProgramTierGroup?: { __typename: 'UpsertProgramTierGroup', ok?: boolean | null } | null };


export const UpsertProgramTierGroupDocument = gql`
    mutation UpsertProgramTierGroup($programId: ID!, $programTierGroupId: ID, $startAt: DateTime, $status: ProgramTierGroupStatus, $tiers: [ProgramTierInput!]) {
  upsertProgramTierGroup(
    programId: $programId
    programTierGroupId: $programTierGroupId
    startAt: $startAt
    status: $status
    tiers: $tiers
  ) {
    ok
  }
}
    `;
export type UpsertProgramTierGroupMutationFn = Apollo.MutationFunction<UpsertProgramTierGroupMutation, UpsertProgramTierGroupMutationVariables>;

/**
 * __useUpsertProgramTierGroupMutation__
 *
 * To run a mutation, you first call `useUpsertProgramTierGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProgramTierGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProgramTierGroupMutation, { data, loading, error }] = useUpsertProgramTierGroupMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      programTierGroupId: // value for 'programTierGroupId'
 *      startAt: // value for 'startAt'
 *      status: // value for 'status'
 *      tiers: // value for 'tiers'
 *   },
 * });
 */
export function useUpsertProgramTierGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProgramTierGroupMutation, UpsertProgramTierGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertProgramTierGroupMutation, UpsertProgramTierGroupMutationVariables>(UpsertProgramTierGroupDocument, options);
      }
export type UpsertProgramTierGroupMutationHookResult = ReturnType<typeof useUpsertProgramTierGroupMutation>;
export type UpsertProgramTierGroupMutationResult = Apollo.MutationResult<UpsertProgramTierGroupMutation>;
export type UpsertProgramTierGroupMutationOptions = Apollo.BaseMutationOptions<UpsertProgramTierGroupMutation, UpsertProgramTierGroupMutationVariables>;