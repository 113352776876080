import React, { useState } from 'react'
import { Avatar, makeStyles, Paper, TextField, Button, Box } from '@material-ui/core'
import { ReactComponent as SendIcon } from '../icons/paper-airplane.svg'
import { useAddNoteMutation } from './operations/add-note.generated'
import { useRemoveCustomerNoteMutation } from './operations/remove-note.generated'
import ContainerError from '../components/ContainerError'
import { useParams } from 'react-router-dom'
import { CustomerDetailRouteParams } from './routes'
import { useCustomerNotesQuery } from './operations/customer-notes.generated'
import { useUpdateCustomerNoteMutation } from './operations/update-customer-note.generated'
import CustomerNote from './CustomerNote'
import { ApolloError, NetworkStatus } from '@apollo/client'
import CustomerNoteListLoading from './CustomerNoteListLoading'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import { ReactComponent as EmptyListImage } from '../images/empty-list.svg'
import ConfirmDeleteNoteDialog from './ConfirmNoteDeleteDialog'
import { CustomerNoteFragmentFragmentDoc } from './operations/customer-note-fragment.generated'
import { CustomerNoteCategory } from '../gql-global'
import NoteCategoryMenu from './NoteCategoryMenu'
import { isTypeName } from '../types/utility'
import { useToast } from '../components/Alert/ToastProvider'

const useStyles = makeStyles(theme => ({
  notesPaper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    margin: theme.spacing(10),
  },
  inputTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(7),
    backgroundColor: 'white',
  },
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  inputTextField: {
    marginLeft: theme.spacing(4),
    boxShadow: '13px 10px 20px 0px rgba(37, 36, 103, 0.1)',
  },
}))

export interface CustomerDetailNoteType {
  id?: string
  createdAt?: Date
  author?: {
    id: number
    fullName?: string | null
    email: string
  }
  note?: string
  category?: CustomerNoteCategory | null
}

interface CustomerDetailNotesProps {
  selectedSocialAccountId?: string | null
  userEmail?: string
  userDataLoading?: boolean
  userDataError?: ApolloError
}

const PAGE_SIZE = 10

function CustomerDetailNotes({
  selectedSocialAccountId,
  userEmail,
  userDataLoading,
  userDataError,
}: CustomerDetailNotesProps): React.ReactElement {
  const classes = useStyles()
  const { id } = useParams<CustomerDetailRouteParams>()
  const [noteToAdd, setNoteToAdd] = useState<CustomerDetailNoteType | null>(null)
  const [noteToEdit, setNoteToEdit] = useState<CustomerDetailNoteType | null>(null)
  const [deletingNoteId, setDeletingNoteId] = useState<string | null>(null)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const { showToast } = useToast()

  const {
    data: customerData,
    loading: customerDataLoading,
    error: customerDataError,
    fetchMore,
    networkStatus,
  } = useCustomerNotesQuery({
    skip: !selectedSocialAccountId,
    notifyOnNetworkStatusChange: true,
    variables: {
      socialAccountId: selectedSocialAccountId || '',
      id,
      limit: PAGE_SIZE,
    },
  })

  const noteCategories = customerData?.noteCategories
  function handleCategoryClick(categoryId: string, addOrEdit: 'ADD' | 'EDIT') {
    const newCategory = noteCategories?.find(c => c.id === categoryId)
    if (addOrEdit === 'ADD' && newCategory) {
      if (noteToAdd) {
        setNoteToAdd({ ...noteToAdd, category: newCategory })
      } else {
        setNoteToAdd({ note: '', category: newCategory })
      }
    } else if (addOrEdit === 'EDIT' && newCategory) {
      setNoteToEdit({
        ...noteToEdit,
        category: newCategory,
      })
    }
  }

  const cursor = customerData?.socialAccount?.customer?.notes?.cursor

  const loadMoreCustomerNotes = (): void => {
    if (cursor) {
      void fetchMore({
        variables: {
          cursor: cursor,
          socialAccountId: selectedSocialAccountId || '',
          id,
          limit: PAGE_SIZE,
        },
      })
    }
  }
  const customerSocialAccountType =
    customerData?.socialAccount && isTypeName(customerData.socialAccount, 'IGSocialAccount') ? 'instagram' : 'tiktok'

  const customer = customerData?.socialAccount?.customer
  const customerSocialUser = customerSocialAccountType === 'instagram' ? customer?.igUser : customer?.ttUser
  const customerUsername = customerSocialUser?.username || ''
  const customerNotes = customer?.notes?.results || []
  const userInitials =
    userEmail && userEmail[0] && userEmail[1] ? `${userEmail[0].toUpperCase()}${userEmail[1].toUpperCase()}` : ''

  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const loading = userDataLoading || (customerDataLoading && networkStatus !== NetworkStatus.fetchMore)
  const error = userDataError || customerDataError

  const [addNote] = useAddNoteMutation()
  const [removeNote] = useRemoveCustomerNoteMutation()

  function handleAddNote() {
    if (id && noteToAdd) {
      addNote({
        variables: {
          customerId: id.toString(),
          note: noteToAdd.note || '',
          categoryLcid: noteToAdd.category?.id || undefined,
        },
        update: (cache, { data }) => {
          const newNote = data?.addCustomerNote?.note
          if (newNote && customer) {
            cache.modify({
              id: cache.identify(customer),
              fields: {
                notes(existingNotes) {
                  const newRef = cache.writeFragment({
                    data: newNote,
                    fragment: CustomerNoteFragmentFragmentDoc,
                  })
                  return {
                    ...existingNotes,
                    results: [newRef, ...existingNotes.results],
                  }
                },
              },
            })
          }
        },
      })
        .then(result => {
          setNoteToAdd(null)
          showToast({
            title: 'Success: Added Note',
            message: `Added note to ${customerUsername}`,
            severity: 'success',
            autoHideDuration: 5000,
          })
        })
        .catch(() => {
          showToast({
            title: 'Error: Adding Note',
            message: 'Something went wrong when adding the note, please try again.',
          })
        })
    }
  }

  const [updateCustomerNote] = useUpdateCustomerNoteMutation()
  function handleUpdateNote() {
    if (noteToEdit && noteToEdit.id && noteToEdit.note) {
      updateCustomerNote({
        variables: {
          noteId: noteToEdit.id,
          updatedNote: noteToEdit.note,
          updatedCategoryLcid: noteToEdit.category?.id || undefined,
        },
      })
        .then(result => {
          setNoteToEdit(null)
          showToast({
            title: 'Success: Saving Note',
            message: `Saved note to ${customerUsername}`,
            severity: 'success',
            autoHideDuration: 5000,
          })
        })
        .catch(() => {
          showToast({
            title: 'Error: Saving Note',
            message: 'Something went wrong when saving the note, please try again.',
          })
        })
    }
  }

  function handleEditNote(e: React.ChangeEvent<HTMLInputElement>) {
    if (noteToEdit) {
      setNoteToEdit({
        ...noteToEdit,
        note: e.target.value,
      })
    }
  }

  function handleConfirmDeleteNote() {
    if (deletingNoteId && customer) {
      removeNote({
        variables: {
          noteId: deletingNoteId,
        },
        update(cache, { data }) {
          if (!data?.removeCustomerNote?.ok || !data?.removeCustomerNote?.note) {
            return
          }
          const cacheId = cache.identify(data.removeCustomerNote.note)
          cache.evict({ id: cacheId })
          cache.gc()
        },
      }).then(result => {
        setDeleteConfirmationOpen(false)
        setDeletingNoteId(null)
        showToast({
          title: 'Success: Removed Note',
          message: `Removed note from ${customerUsername}`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
    }
  }

  function handleOnDeleteClick(noteId: string): void {
    setDeletingNoteId(noteId)
    setDeleteConfirmationOpen(true)
  }

  function handleOnCancelDelete(): void {
    setDeleteConfirmationOpen(false)
    setDeletingNoteId(null)
  }

  return (
    <div>
      <Paper className={classes.notesPaper}>
        <div>
          {!error && (
            <>
              <div className={classes.inputTab}>
                <Avatar className={classes.avatar}>{userInitials}</Avatar>
                <TextField
                  disabled={!!error || loading}
                  placeholder="Write a new note"
                  value={noteToAdd?.note || ''}
                  variant="outlined"
                  fullWidth
                  multiline
                  className={classes.inputTextField}
                  onChange={e => setNoteToAdd({ ...noteToAdd, note: e.target.value })}
                />
              </div>
              {!loading && noteToAdd && noteToAdd.note !== '' && (
                <Box display="flex" justifyContent="space-between" marginBottom={5} marginRight={8} marginLeft={22}>
                  <Box>
                    <NoteCategoryMenu
                      addOrEdit="ADD"
                      onClick={handleCategoryClick}
                      value={noteToAdd?.category?.name}
                      noteCategories={noteCategories}
                    />
                  </Box>
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<SendIcon width={16} />}
                      onClick={handleAddNote}
                    >
                      Add Note
                    </Button>
                  </Box>
                </Box>
              )}
              {!error &&
                !loading &&
                customerNotes &&
                customerNotes.length > 0 &&
                customerNotes.map((note: CustomerDetailNoteType) => (
                  <CustomerNote
                    key={note.id}
                    note={note}
                    editingNote={noteToEdit}
                    setNoteEditing={setNoteToEdit}
                    onSaveNote={handleUpdateNote}
                    onEditNote={handleEditNote}
                    onChangeNoteCategory={handleCategoryClick}
                    onDeleteNote={handleOnDeleteClick}
                    noteCategories={noteCategories || null}
                  />
                ))}
              {(loading || loadingMore) && new Array(5).fill(null).map((_, i) => <CustomerNoteListLoading key={i} />)}
            </>
          )}
          {!error && !loading && !cursor && (!customerNotes || customerNotes.length === 0) && (
            <Box display="flex" justifyContent="center" marginY={10}>
              <ContainerEmptyState image={EmptyListImage} text="No notes yet for this customer yet" />
            </Box>
          )}
          {error && <ContainerError text="Sorry, we had a problem loading notes." />}
        </div>
      </Paper>
      <ConfirmDeleteNoteDialog
        open={deleteConfirmationOpen}
        onSubmit={handleConfirmDeleteNote}
        onCancel={handleOnCancelDelete}
      />
      {!error && cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={loadMoreCustomerNotes}
            disabled={loadingMore}
          >
            Load more
          </Button>
        </Box>
      )}
    </div>
  )
}

export default CustomerDetailNotes
