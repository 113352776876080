import React from 'react'
import withAuthorization from '../../withAuthorization'
import { ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS, ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNT } from '../routes'
import useTitle from '../../utils/use-title'
import { Route, Switch } from 'react-router-dom'
import TikTokSocialAccountList from './TikTokSocialAccountList'
import TikTokSocialAccount from './TikTokSocialAccount'

function TikTokHashtagManagement(): React.ReactElement {
  useTitle('Admin - TikTok Hashtag Management')
  return (
    <Switch>
      <Route component={TikTokSocialAccountList} path={ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS.path} exact />
      <Route component={TikTokSocialAccount} path={ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNT.path} />
    </Switch>
  )
}

export default withAuthorization(ADMIN_TIKTOK_HASHTAG_SOCIAL_ACCOUNTS)(TikTokHashtagManagement)
