import { TikApiLoginData } from '../global'

const TIKAPI_SCRIPT_ID = 'tikapisdk'

class TikApiSDK {
  async init(): Promise<void> {
    return await this.loadScript()
  }

  auth(): Promise<void> {
    return new Promise((resolve, reject) => {
      const TIK_API_CLIENT_ID = process.env.REACT_APP_TIK_API_CLIENT_ID
      if (TIK_API_CLIENT_ID) {
        window.TikAPI.oauth({
          client_id: TIK_API_CLIENT_ID,
          scope: ['VIEW_PROFILE', 'VIEW_NOTIFICATIONS', 'EXPLORE'],
        })
        resolve()
      }
      reject('No TikApi client id provided.')
    })
  }

  async login(): Promise<TikApiLoginData | null> {
    return new Promise(resolve => window.TikAPI.onLogin(resolve))
  }

  async authAndLogin(): Promise<TikApiLoginData | null> {
    await this.auth()
    return await this.login()
  }

  private loadScript(): Promise<void> {
    if (document.getElementById(TIKAPI_SCRIPT_ID)) {
      return Promise.resolve()
    }
    const js = document.createElement('script')
    js.src = '//www.tikapi.io/assets/js/sdk.js'
    js.id = TIKAPI_SCRIPT_ID
    const fjs = document.getElementsByTagName('script')[0] || document.body.lastChild
    const parent = fjs?.parentNode || document.body
    parent.insertBefore(js, fjs)
    return new Promise((resolve, reject) => {
      js.onload = () => resolve()
      js.onerror = () => reject()
    })
  }
}

const TikApiSDKInstance = new TikApiSDK()
export default TikApiSDKInstance
