import React from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px',
    minHeight: '56px',
    cursor: 'text',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  placeholder: {
    color: '#aaa',
  },
}))

interface ChipsSelectProps {
  chips: string[]
  onChange: (chips: string[]) => void
  onClick: () => void
}

const ChipsSelect: React.FC<ChipsSelectProps> = ({ chips, onChange, onClick }) => {
  const classes = useStyles()

  const handleDelete = (item: string) => () => {
    onChange(chips.filter(chip => chip !== item))
  }

  return (
    <div className={classes.field} onClick={onClick}>
      {chips.length === 0 ? (
        <span className={classes.placeholder}>Click to select</span>
      ) : (
        chips.map(item => (
          <Chip key={item} label={item} title={item} className={classes.chip} onDelete={handleDelete(item)} />
        ))
      )}
    </div>
  )
}

export default ChipsSelect
