import { Box, createStyles, List, ListItem, makeStyles, Menu } from '@material-ui/core'
import React from 'react'
import { secondary } from '../../loudcrowd-theme'
import { AccountLabel } from '../LabelMenu/LabelMenu'

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      maxHeight: 108,
      overflowY: 'auto',
      overflowX: 'hidden',
      minWidth: '60px',
    },
    listItem: {
      marginRight: '20px',
      marginLeft: '12px',
      fontWeight: 500,
      color: secondary[600],
      marginTop: 0,
      marginBottom: 0,
      padding: 4,
      cursor: 'pointer',
    },
  }),
)

interface ChallengeFilterMenuProps {
  open: boolean
  setOpen: (v: boolean) => void
  challenges: ReadonlyArray<AccountLabel<string>>
  selected?: string | undefined
  setSelected: (selection: string) => void
  currentRef: HTMLDivElement | null
}

export default function ChallengeFilterMenu({
  open,
  setOpen,
  challenges,
  selected,
  setSelected,
  currentRef,
}: ChallengeFilterMenuProps): React.ReactElement {
  const classes = useStyles()

  const filteredChallenges = selected ? challenges.filter(c => c.id !== selected) : challenges
  return (
    <Menu
      open={open}
      anchorEl={currentRef}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      getContentAnchorEl={null}
      onClose={() => setOpen(false)}
    >
      <List className={classes.list}>
        {filteredChallenges.map(c => (
          <Box
            key={c.id}
            onClick={() => {
              setSelected(c.id)
              setOpen(false)
            }}
          >
            <ListItem className={classes.listItem}>{c.name}</ListItem>
          </Box>
        ))}
      </List>
    </Menu>
  )
}
