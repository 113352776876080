import { DateRange } from '../utils/date-range-helper'
import { isSameDay } from 'date-fns'
import { useRef } from 'react'

export function useDateRangeRef(dateRange: null): null
export function useDateRangeRef(dateRange: DateRange): DateRange
export function useDateRangeRef(dateRange: DateRange | null): DateRange | null
export function useDateRangeRef(dateRange: DateRange | null): DateRange | null {
  const dateRangeRef = useRef<DateRange | null>(dateRange)
  if (
    dateRangeRef.current &&
    dateRange &&
    isSameDay(dateRangeRef.current.gte, dateRange.gte) &&
    isSameDay(dateRangeRef.current.lt, dateRange.lt)
  ) {
    return dateRangeRef.current
  }

  dateRangeRef.current = dateRange
  return dateRange
}

export function useDateRangesRef(dateRanges: DateRange[]): DateRange[]
export function useDateRangesRef(dateRanges: (DateRange | null)[]): (DateRange | null)[]
export function useDateRangesRef(dateRanges: (DateRange | null)[]): (DateRange | null)[] {
  const dateRangeRef = useRef<(DateRange | null)[]>(dateRanges)
  const currentValue = dateRangeRef.current
  const isSameRanges = dateRanges.every((dateRange, index) => {
    const reffedValue = currentValue[index]
    if (reffedValue === dateRange) {
      return true
    }
    if (!reffedValue || !dateRange) {
      return false
    }
    return isSameDay(reffedValue.gte, dateRange.gte) && isSameDay(reffedValue.lt, dateRange.lt)
  })
  if (isSameRanges) {
    return dateRangeRef.current
  }

  dateRangeRef.current = dateRanges
  return dateRanges
}
