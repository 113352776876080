import { Box, Button, Checkbox, Divider, List, makeStyles, MenuItem, Select, SelectProps } from '@material-ui/core'
import React, { useState } from 'react'
import theme from '../../loudcrowd-theme'
import { AccountLabel } from '../LabelMenu/LabelMenu'
import MetricIcon, { IconType } from '../metric/MetricIcon'

const useStyles = makeStyles({
  optionText: {
    margin: 0,
  },
  option: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: '40px',
    cursor: 'pointer',
  },
  optionIcon: {
    minWidth: 0,
  },
  container: { minWidth: 225 },
  list: {
    maxHeight: 190,
    overflowY: 'auto',
  },
  menuList: {
    paddingBottom: 0,
  },
})

function toggleSetValue<T>(set: ReadonlySet<T>, value: T): Set<T> {
  const newSet = new Set(set)
  if (set.has(value)) {
    newSet.delete(value)
  } else {
    newSet.add(value)
  }
  return newSet
}
interface MultiSelectProps<T extends string> {
  className?: T
  options: (AccountLabel<T> & { icon?: IconType })[]
  selectedOptions: T[]
  defaultOptions?: T[]
  renderValue: (selected: unknown) => JSX.Element | undefined
  onApply: (selected: T[]) => void
  menuLabel?: string
  enableSelectAll?: boolean
  disabled?: boolean
  variant?: SelectProps['variant']
}

function MultiSelect<T extends string>({
  className,
  options,
  selectedOptions,
  defaultOptions,
  renderValue,
  onApply,
  menuLabel,
  enableSelectAll = false,
  disabled = false,
  variant,
}: MultiSelectProps<T>): React.ReactElement {
  const classes = useStyles()
  const [newValues, setNewValues] = useState<Array<T>>(selectedOptions)
  const [open, setOpen] = useState(false)

  function handleApply() {
    onApply(newValues)
    setOpen(false)
  }

  function handleReset() {
    if (defaultOptions) {
      setNewValues(defaultOptions)
      onApply(defaultOptions)
      setOpen(false)
    }
  }

  function handleSelectAll() {
    setNewValues(options.map(option => option.id))
    onApply(options.map(option => option.id))
  }

  return (
    <Select
      displayEmpty={true}
      disabled={disabled}
      open={open}
      className={className}
      onOpen={() => {
        setNewValues(selectedOptions)
        onApply(selectedOptions) // TODO delete this?
        setOpen(true)
      }}
      onClose={() => {
        if (newValues.length === 0) {
          setNewValues(selectedOptions)
        }
        setOpen(false)
      }}
      multiple
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        classes: {
          list: classes.menuList,
        },
        getContentAnchorEl: null,
      }}
      renderValue={renderValue}
      value={selectedOptions}
      variant={variant}
    >
      {menuLabel && (
        <Box pb={2} fontWeight={600} textAlign="center">
          {menuLabel}
        </Box>
      )}
      {menuLabel && <Divider />}
      <List className={classes.list}>
        {options.map((option, idx) => {
          const iconKey = option?.icon
          return (
            <Box
              onClick={() => {
                const set = new Set(newValues)
                const newSet = toggleSetValue(set, option.id)
                setNewValues(Array.from(newSet))
              }}
              key={idx}
            >
              <MenuItem key={option.id} value={option.id}>
                <Checkbox checked={newValues.indexOf(option.id) > -1} />
                {iconKey && (
                  <Box width={15} height={15} mt={-1} mr={2} color={theme.palette.primary.dark} display="flex">
                    <MetricIcon icon={iconKey} />
                  </Box>
                )}
                {option.name}
              </MenuItem>
            </Box>
          )
        })}
      </List>
      <Box mt={2}>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent={defaultOptions ? 'space-between' : 'flex-end'}
          flex="0 0 auto"
          p={2}
        >
          {defaultOptions && !enableSelectAll && (
            <Button color="secondary" onClick={handleReset}>
              Reset
            </Button>
          )}
          {enableSelectAll && (
            <Button color="secondary" onClick={handleSelectAll}>
              Select All
            </Button>
          )}
          <Button color="primary" variant="contained" onClick={handleApply} disabled={!newValues.length}>
            Apply
          </Button>
        </Box>
      </Box>
    </Select>
  )
}

export default MultiSelect
