import React, { useState, useEffect } from 'react'
import {
  InputAdornment,
  OutlinedInput,
  makeStyles,
  createStyles,
  MenuProps,
  Menu,
  Box,
  Divider,
  Button,
} from '@material-ui/core'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      minWidth: 465,
    },
    searchInput: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    button: {
      borderRadius: 5,
    },
    image: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(2),
      padding: 5,
    },
    listItem: {
      cursor: 'pointer',
    },
  }),
)

export type OptionType = {
  id: string
}

type MultiSelectSearchProps<T extends OptionType> = {
  options: T[]
  loadOptions(searchText: string): void
  loadMoreOptions(searchText: string): void
  open: boolean
  onCancel(): void
  anchorEl?: MenuProps['anchorEl']
  label: string
  optionTemplate(option: T): JSX.Element
  handleConfirm(options: T[]): void
  handleOnClick: (option: T) => void
  selectedItems: T[]
  confirmButtonText?: string
}

const MultiSelectSearch = <T extends OptionType>({
  open,
  options,
  anchorEl,
  optionTemplate,
  label,
  selectedItems,
  onCancel,
  handleConfirm,
  confirmButtonText,
  loadMoreOptions,
  loadOptions,
  handleOnClick,
}: MultiSelectSearchProps<T>) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<T[]>(selectedItems)
  const [searchText, setSearchText] = useState<string>('')

  useEffect(() => {
    setSelectedOptions(selectedItems)
  }, [selectedItems])
  useEffect(() => {
    loadOptions(searchText)
  }, [searchText, loadOptions])

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget

    const currentPosition = Math.round(scrollTop + clientHeight)
    if (currentPosition === scrollHeight) {
      await loadMoreOptions(searchText)
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
    event.preventDefault()
  }

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      anchorEl={anchorEl}
      open={open}
      onClose={onCancel}
    >
      <Box className={classes.wrapper}>
        <Box mb={4} mt={3} textAlign="center">
          Select {label}
        </Box>
        <Divider />
        <Box px={4}>
          <OutlinedInput
            autoFocus
            fullWidth
            placeholder="Search"
            className={classes.searchInput}
            value={searchText}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon width={16} height={16} />
              </InputAdornment>
            }
            onKeyDown={e => e.stopPropagation()} // prevent menu from changing focus when you press the s key
          />
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" pb={4} maxHeight={400} overflow="auto" onScroll={handleScroll}>
          {!options?.length ? (
            <Box p={5}>{`No ${label} found`}</Box>
          ) : (
            options?.map(option => (
              <Box key={option?.id} onClick={() => option && handleOnClick(option)} className={classes.listItem}>
                {optionTemplate(option)}
              </Box>
            ))
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" pt={4} gridGap={8} pr={4}>
          <Button variant="outlined" color="primary" onClick={onCancel} className={classes.button}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleConfirm(selectedOptions)}
          >
            {confirmButtonText || 'Add'}
          </Button>
        </Box>
      </Box>
    </Menu>
  )
}

export default MultiSelectSearch
