import React from 'react'
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  AnySegmentCampaignFilterInput,
  ChallengesFilterInput,
  DateTimeRangeFilter,
  IgMediaPostType,
  MentionStatus,
} from '../gql-global'
import { realizedDateRangeFromFilter, getPreviousRangeDates } from '../utils/date-range-helper'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../components/ContainerError'
import { ReactComponent as EmptyListImage } from '../images/empty-list.svg'
import { useDateRangeRef } from '../hooks/useDateRangeRef'
import { MentionTypes } from '../content/constants'
import { useTopHashtagsQuery } from './operations/query-dashboard-top-hashtags.generated'
import HashtagRow from './HashtagRow'
import { makeMentionTypesWhere, postStatusArrayFilterFromSelection } from './utils'
import useHasFeature from '../hooks/useHasFeature'

interface ContentLeaderboardBlockProps {
  socialAccountId?: string | null
  dateRangeFilter: DateTimeRangeFilter
  selectedMentionTypes: Set<MentionTypes> | null
  selectedPostTypes: Set<IgMediaPostType> | null
  selectedPostStatus: Set<MentionStatus> | null
  selectedChallenges: ChallengesFilterInput | null
  isInstagramAccount?: boolean | null
  includePremembership: boolean
  segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null
}

function ContentLeaderboardBlock({
  socialAccountId,
  dateRangeFilter,
  selectedMentionTypes,
  selectedPostTypes,
  selectedPostStatus,
  selectedChallenges,
  isInstagramAccount,
  includePremembership,
  segmentCampaignFilter = null,
}: ContentLeaderboardBlockProps): React.ReactElement {
  const dateRange = realizedDateRangeFromFilter(dateRangeFilter)
  const reffedDateRange = useDateRangeRef(dateRange)
  const prevDateRange = getPreviousRangeDates(dateRangeFilter)
  const reffedPrevDateRange = useDateRangeRef(prevDateRange)
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')
  const sharedWhere = {
    ...(selectedChallenges?.any?.length && { challengeIds: selectedChallenges }),
    postType: selectedPostTypes?.size ? { any: Array.from(selectedPostTypes) } : undefined,
    ...makeMentionTypesWhere(selectedMentionTypes, isInstagramAccount ?? false, hasTikTokHashtags),
    tagStatus: { any: postStatusArrayFilterFromSelection(selectedPostStatus) },
    includePremembership,
    segmentsCampaigns: segmentCampaignFilter?.length ? { all: segmentCampaignFilter } : undefined,
  }
  const mentionsWhere = {
    ...sharedWhere,
    postedAt: reffedDateRange,
  }
  const prevMentionsWhere = {
    ...sharedWhere,
    postedAt: reffedPrevDateRange,
  }
  const { data, loading, error } = useTopHashtagsQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
      mentionsWhere,
      prevMentionsWhere,
    },
  })
  const hashtags = data?.socialAccount?.hashtags?.results || []
  return (
    <div>
      <Box mb={5}>
        <Typography variant="h6">Top Hashtags</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table style={{ tableLayout: 'auto' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: 60 }}>Ranking</TableCell>
              <TableCell>Hashtag</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              hashtags.map(h => (
                <HashtagRow
                  key={h.id}
                  hashtag={h.hashtag}
                  count={h.mentionStats.count}
                  ranking={h.mentionStats.ranking}
                  prevCount={h.prevMentionStats.count}
                  prevRanking={h.prevMentionStats.ranking}
                  mentionFilters={{
                    ...sharedWhere,
                    postType: selectedPostTypes ?? undefined,
                    tagStatus: new Set(postStatusArrayFilterFromSelection(selectedPostStatus)),
                    postedAt: dateRangeFilter,
                  }}
                />
              ))}
            {loading &&
              new Array(10).fill(null).map((_, i) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <Typography component="span" variant="body1">
                      <Skeleton />
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            {!loading && hashtags.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <ContainerEmptyState image={EmptyListImage} text="No hashtags found." />
                </TableCell>
              </TableRow>
            )}
            {error && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <ContainerError text="Could not load top hashtags." />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ContentLeaderboardBlock
