import { useQueryParam, stringify, encodeQueryParams } from 'use-query-params'
import { createFilteredParam } from '../utils/url-param-utils'
import { CustomerSort } from '../gql-global'

const SORT_PARAM = 'sort'
const isCustomerSort = (str: string): str is CustomerSort => Object.values(CustomerSort).includes(str as CustomerSort)

const sortParam = createFilteredParam((str: string): CustomerSort | undefined =>
  isCustomerSort(str) ? str : undefined,
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSortParam = () => {
  return useQueryParam(SORT_PARAM, sortParam)
}

export const encodeSortParam = (sort: CustomerSort): string => {
  return stringify(encodeQueryParams({ [SORT_PARAM]: sortParam }, { [SORT_PARAM]: sort }))
}

export default useSortParam
