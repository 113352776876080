import React, { useEffect, useRef, useState } from 'react'
import {
  Typography,
  Button,
  Menu,
  Box,
  InputAdornment,
  OutlinedInput,
  createStyles,
  Theme,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down_minor.svg'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import { AnySegmentCampaignFilterInput } from '../gql-global'

const useStyles = makeStyles(({ typography, palette, spacing }: Theme) =>
  createStyles({
    h7: {
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    filterButton: {
      margin: '0px 8px 8px 0px',
    },
    search: {
      fontSize: typography.body2.fontSize,
      lineHeight: '150%',
    },
    searchInput: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      height: 21,
    },
    searchIcon: {
      color: palette.text.hint,
    },
    rightBorderBox: {
      borderRight: `1px solid ${palette.text.hint}`,
      width: 440,
    },
    topBorderBox: {
      borderTop: `1px solid ${palette.text.hint}`,
    },
    leftAlignedButton: {
      marginLeft: -15,
    },
    filterSectionTitle: {
      fontWeight: 700,
      fontSize: 14,
      marginBottom: 5,
      color: palette.secondary.main,
    },
    listIcon: {
      marginLeft: 5,
      minWidth: 24,
      minHeight: 24,
    },
    listItemButton: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.main,
      },
    },
    field: {
      border: `2px solid ${palette.secondary.main}`,
      minHeight: 150,
      width: 400,
      padding: 5,
      cursor: 'pointer',
      '&:hover': {
        border: `2px solid ${palette.primary.main}`,
      },
      '& .MuiChip-root': {
        margin: 5,
      },
    },
    fieldLabel: {
      marginBottom: 10,
    },
    fieldActive: {
      border: `2px solid ${palette.primary.main}`,
    },
  }),
)

export interface DashboardCustomerFilterProps {
  segments: AccountLabel[] | null
  campaigns: AccountLabel[] | null
  segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null
  setSegmentCampaignFilter: (segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null) => void
}

interface AccountLabelType {
  id: number
  name: string
  type: 'Segments' | 'Programs'
}

interface CustomerFilterFormFields {
  any: AccountLabelType[]
  other: AccountLabelType[]
}

interface CustomerFilterSelectSectionProps {
  label: string
  items: AccountLabel[] | null
  count: number
  type: 'Segments' | 'Programs'
  selectedItems: CustomerFilterFormFields
  onSelect(item: AccountLabelType): void
}

interface DashboardCustomerFilterFieldProps {
  isAnd?: boolean
  setActiveField: (field: 'any' | 'other') => void
  values: CustomerFilterFormFields
  handleSelect: (selected: AccountLabelType) => void
  activeField: 'any' | 'other'
  field: 'any' | 'other'
}

const CustomerFilterSelectSection: React.FC<CustomerFilterSelectSectionProps> = props => {
  const { label, items, count, type, selectedItems, onSelect } = props
  const classes = useStyles()
  const minHeight = count < 5 ? 175 + (5 - count) * 35 : 175
  const onClick = (item: AccountLabel) => onSelect({ type, ...item })
  const isChecked = (items: AccountLabelType[], id: number) => !!items.find(val => val.id === id && val.type === type)
  return (
    <Box mt={7}>
      <Typography className={classes.filterSectionTitle}>{label}</Typography>
      <List>
        <Box overflow="auto" maxHeight={minHeight}>
          {items?.map(item => (
            <ListItem key={item.id} button classes={{ button: classes.listItemButton }} onClick={() => onClick(item)}>
              <ListItemIcon className={classes.listIcon}>
                <Checkbox
                  data-testid={`checkbox-customer-${item.id}`}
                  size="small"
                  edge="start"
                  tabIndex={-1}
                  checked={isChecked(selectedItems.any, item.id) || isChecked(selectedItems.other, item.id)}
                />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </Box>
      </List>
    </Box>
  )
}

const DashboardCustomerFilterField = (props: DashboardCustomerFilterFieldProps) => {
  const { isAnd, activeField, values, field, setActiveField, handleSelect } = props
  const classes = useStyles()
  return (
    <>
      <Typography variant="subtitle2" className={classes.fieldLabel}>
        {isAnd && (
          <>
            AND <br />
          </>
        )}
        Any of these
      </Typography>
      <Field
        name="other"
        render={() => (
          <Box
            onClick={() => setActiveField(field)}
            className={clsx(classes.field, { [classes.fieldActive]: activeField === field })}
          >
            {values[field].map(item => (
              <Chip key={item.id} label={`${item?.type}: ${item.name}`} onDelete={() => handleSelect(item)} />
            ))}
          </Box>
        )}
      />
    </>
  )
}

const DashboardCustomerFilter: React.FC<DashboardCustomerFilterProps> = props => {
  const { campaigns, segments, segmentCampaignFilter, setSegmentCampaignFilter } = props
  const classes = useStyles()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [activeField, setActiveField] = useState<'any' | 'other'>('any')
  const [isOtherFieldOpen, setIsOtherFieldOpen] = useState<boolean>(false)

  const filterCollection = (collection: AccountLabel[] | null): AccountLabel[] =>
    collection?.filter(item => item.name.toLowerCase().includes(searchText)) ?? []

  const formik = useFormik<CustomerFilterFormFields>({
    initialValues: { any: [], other: [] },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      const parseSelectionByType = (collection: AccountLabelType[], type: 'Programs' | 'Segments') =>
        collection.filter(item => item.type === type).map(item => item.id.toString())

      const data = {
        campaigns: parseSelectionByType(values.any, 'Programs'),
        segments: parseSelectionByType(values.any, 'Segments'),
      }

      setSegmentCampaignFilter(
        values.other.length
          ? [
              data,
              {
                campaigns: parseSelectionByType(values.other, 'Programs'),
                segments: parseSelectionByType(values.other, 'Segments'),
              },
            ]
          : [data],
      )
      if (!values.other.length) {
        setIsOtherFieldOpen(false)
      }
      setOpen(false)
    },
  })

  function getValues(field: 'any' | 'other'): AccountLabelType[] {
    const fieldValues = segmentCampaignFilter?.[field === 'any' ? 0 : 1]

    const get = (collection: AccountLabel[] | null, type: 'Programs' | 'Segments', key: 'segments' | 'campaigns') =>
      collection?.filter(item => fieldValues?.[key]?.includes(item.id.toString())).map(item => ({ type, ...item }))

    return [...(get(campaigns, 'Programs', 'campaigns') ?? []), ...(get(segments, 'Segments', 'segments') ?? [])]
  }

  function setInitialFormValues() {
    const any = getValues('any')
    const other = getValues('other')

    formik.setValues({ ...formik.values, any, other })

    if (!!other.length) {
      setIsOtherFieldOpen(true)
    }
  }

  useEffect(() => {
    setInitialFormValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, segments, segmentCampaignFilter])

  const selectedCampaigns = segmentCampaignFilter?.flatMap(item => item.campaigns)
  const selectedSegments = segmentCampaignFilter?.flatMap(item => item.segments)
  const selectionCount = (selectedSegments?.length ?? 0) + (selectedCampaigns?.length ?? 0)

  const haveValues = !!(formik.values.any.length || formik.values.other.length)

  function handleSelect(selected: AccountLabelType) {
    // activeValues: selected things for the active selection box
    let activeValues = formik.values.any
    // nonActiveValues: selected things for the not active selection box
    let nonActiveValues = formik.values.other
    if (activeField === 'other') {
      activeValues = formik.values.other
      nonActiveValues = formik.values.any
    }

    // test if the clicked thing is already in active or not active boxes
    const selectedInActive = activeValues.some(v => v.id === selected.id)
    const selectedInNonActive = nonActiveValues.some(v => v.id === selected.id)

    if (selectedInActive) {
      // if already selected in active box, remove it
      formik.setFieldValue(
        activeField,
        activeValues.filter(v => v.id !== selected.id),
      )
    } else if (selectedInNonActive) {
      // if already selected in not active box, remove it, and activate box
      const nonActiveField = activeField === 'any' ? 'other' : 'any'
      formik.setFieldValue(
        nonActiveField,
        nonActiveValues.filter(v => v.id !== selected.id),
      )
      setActiveField(nonActiveField)
    } else {
      // if not already selected, add to active box
      formik.setFieldValue(activeField, activeValues.concat(selected))
    }
  }

  function handleAddOtherClicked() {
    setIsOtherFieldOpen(true)
    setActiveField('other')
  }

  const handleUncheckAll = () => {
    setIsOtherFieldOpen(false)
    setActiveField('any')
    formik.resetForm()
  }

  const getSelectedElementName = () => {
    const segment = segments?.find(segment => selectedSegments?.[0] === segment.id.toString())
    const campaign = campaigns?.find(campaign => selectedCampaigns?.[0] === campaign.id.toString())
    return (campaign ?? segment)?.name
  }

  function handleClose() {
    setOpen(false)
    setInitialFormValues()
  }

  return (
    <FormikProvider value={formik}>
      <Button
        color={selectionCount ? 'primary' : undefined}
        data-intercom-target="Dashboard Customer Picker"
        variant="outlined"
        ref={buttonRef}
        onClick={() => setOpen(true)}
        endIcon={<ChevronDownIcon height="16px" width="16px" />}
        className={classes.filterButton}
      >
        <Typography className={classes.h7}>
          {selectionCount
            ? selectionCount === 1
              ? getSelectedElementName()
              : `Customers (${selectionCount})`
            : 'Customers'}
        </Typography>
      </Button>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={handleClose}
      >
        <Box display="flex" minWidth={800}>
          <Box py={5} px={8} flex={1} className={classes.rightBorderBox}>
            <OutlinedInput
              classes={{ root: classes.search, input: classes.searchInput }}
              type="text"
              autoFocus
              fullWidth
              value={searchText}
              placeholder="Search programs and segments"
              onChange={event => setSearchText(event.target.value)}
              startAdornment={
                <InputAdornment position="start" className={classes.searchIcon}>
                  <SearchIcon width={16} height={16} />
                </InputAdornment>
              }
            />
            {!!campaigns?.length && (
              <CustomerFilterSelectSection
                label="Programs"
                type="Programs"
                items={filterCollection(campaigns)}
                count={campaigns?.length ?? 0}
                selectedItems={formik.values}
                onSelect={handleSelect}
              />
            )}
            <CustomerFilterSelectSection
              label="Segments"
              type="Segments"
              items={filterCollection(segments)}
              count={segments?.length ?? 0}
              selectedItems={formik.values}
              onSelect={handleSelect}
            />
            <Box pt={3} display="flex" justifyContent="space-between" className={classes.topBorderBox}>
              <Button
                className={classes.leftAlignedButton}
                color="secondary"
                disabled={!haveValues}
                onClick={handleUncheckAll}
              >
                Uncheck All
              </Button>
              <Button color="primary" variant="contained" onClick={() => formik.handleSubmit()}>
                Apply
              </Button>
            </Box>
          </Box>
          <Box py={5} px={8} flex={1}>
            <DashboardCustomerFilterField
              field="any"
              activeField={activeField}
              values={formik.values}
              setActiveField={setActiveField}
              handleSelect={handleSelect}
            />
            <Box mt={4}>
              {isOtherFieldOpen ? (
                <DashboardCustomerFilterField
                  isAnd
                  field="other"
                  activeField={activeField}
                  values={formik.values}
                  setActiveField={setActiveField}
                  handleSelect={handleSelect}
                />
              ) : (
                <Button className={classes.leftAlignedButton} color="primary" onClick={handleAddOtherClicked}>
                  <Box display="flex" alignItems="center">
                    <Box component="span" mr={2} lineHeight={0}>
                      <PlusIcon width={16} />
                    </Box>
                    <Typography variant="subtitle2">Filter within this selection</Typography>
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Menu>
    </FormikProvider>
  )
}

export default DashboardCustomerFilter
