import { useQueryParams, encodeQueryParams, stringify, ArrayParam, DecodedValueMap } from 'use-query-params'
import { DateTimeRangeFilter } from '../../gql-global'
import { usernameParam, USERNAME_SEARCH_PARAM_KEY } from './use-username-param'
import {
  getDateFilterParamsConfig,
  getDateParamsToFilters,
  getDateRangeFilterToParams,
} from '../../utils/filter-params'

export interface Filters {
  usernameKeywords?: string[]
  rewards?: string[]
  rewardedAt?: DateTimeRangeFilter
}

function paramsToFilters(params: ActivityParams): Filters {
  const { rewards } = params

  return {
    usernameKeywords: params[USERNAME_SEARCH_PARAM_KEY] || undefined,
    rewards: rewards || undefined,
    rewardedAt: getDateParamsToFilters('rewardedAt', '', params),
  }
}

function filtersToParams(filters: Filters): ActivityParams {
  return {
    [USERNAME_SEARCH_PARAM_KEY]: filters.usernameKeywords,
    rewards: filters.rewards || undefined,
    ...getDateRangeFilterToParams('rewardedAt', '', filters.rewardedAt),
  }
}

const filterParamConfig = {
  [USERNAME_SEARCH_PARAM_KEY]: usernameParam,
  rewards: ArrayParam,
  ...getDateFilterParamsConfig('rewardedAt', ''),
}

type ActivityParams = Partial<DecodedValueMap<typeof filterParamConfig>>

/*
 * encodes label filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Filters): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  const filters = paramsToFilters(filterParams)
  return {
    filters,
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
