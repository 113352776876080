import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type LabelMenuFragment = { __typename: 'AccountType', labels?: { __typename: 'PagedLabelType', total: number, results: Array<{ __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string }> } | null };

export const LabelMenuFragmentDoc = gql`
    fragment LabelMenu on AccountType {
  labels(limit: 100, sortBy: RECENTLY_USED, sortDirection: DESC) {
    results {
      id
      isRejectionReason
      name
    }
    total
  }
}
    `;