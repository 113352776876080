import * as yup from 'yup'
import { ChallengeStatusEnum, CriteriaOperatorEnum, IgMediaPostType } from '../gql-global'
import { ObjectSchema } from 'yup'
import { ChallengeFragment } from './operations/challenge.generated'

export const useChallengeValidationSchema = (
  challenges: ChallengeFragment[],
  challenge?: ChallengeFragment | null,
): ObjectSchema => {
  return yup.object({
    name: yup
      .string()
      .trim()
      .required('Please name your challenge.')
      .test({
        test: function (name) {
          if (!name || name === '') {
            return this.createError({ message: 'Please name your challenge.', path: 'name' })
          }
          for (const item of challenges) {
            if (item.name.toLowerCase() === name.toLowerCase()) {
              if (challenge?.id && challenge.id === item.id) {
                continue
              }
              return this.createError({ message: 'Please provide a unique challenge name.', path: 'name' })
            }
          }
          return true
        },
      }),
    socialAccountIds: yup.array<string>().test({
      test: function (values) {
        const invalid = !values?.length
        if (!invalid) {
          return true
        }
        return this.createError({ message: 'Please select your brand accounts to mention.', path: 'socialAccountIds' })
      },
    }),
    programs: yup
      .array<string>()
      .required('Please select a program.')
      .test({
        test: function (values) {
          if (values.length === 0) {
            return this.createError({
              message: 'Please select a program.',
              path: 'programs',
            })
          }
          return true
        },
      }),
    postTypes: yup.array<IgMediaPostType>(),
    hashtags: yup.array<string>(),
    hashtagsCriteriaOperator: yup.mixed<CriteriaOperatorEnum>().oneOf(Object.values(CriteriaOperatorEnum)),
    keywords: yup.array<string>(),
    keywordsCriteriaOperator: yup.mixed<CriteriaOperatorEnum>().oneOf(Object.values(CriteriaOperatorEnum)),
    rewards: yup
      .array()
      .of(
        yup.object({
          rewardId: yup.number(),
          winningCriteria: yup.string(),
        }),
      )
      .test({
        test: function (values) {
          if (values.length === 1 && !values[0].rewardId && !values[0].winningCriteria) {
            return true
          }
          for (let index = 0; index < values.length; index++) {
            const { rewardId, winningCriteria } = values[index]
            const message = !rewardId
              ? 'Please select a reward'
              : !winningCriteria
              ? 'Please specify how to win the reward'
              : false
            if (message) return this.createError({ message, path: 'rewards' })
          }
          return true
        },
      }),
    dates: yup.object({ gte: yup.date(), lt: yup.date() }).test({
      test: function (values) {
        if (
          challenge?.currentStatus === ChallengeStatusEnum.Live ||
          challenge?.currentStatus === ChallengeStatusEnum.Completed
        ) {
          return true
        }
        const now = new Date()
        now.setHours(0)
        now.setMinutes(0)
        now.setMilliseconds(0)
        now.setSeconds(0)
        if (values.gte < now) {
          return this.createError({
            message: "Please chose a start date greater than or equal to today's date.",
            path: 'dates',
          })
        }

        if (values.gte > values.lt) {
          return this.createError({
            message: 'Start date cannot be greater than End date.',
            path: 'dates',
          })
        }
        return true
      },
    }),
    paintedDoor: yup.object({
      briefDeliveryMethods: yup.array().of(yup.string()),
      briefContent: yup.string(),
      automatedMessageTriggers: yup.array().of(yup.string()),
      automatedMessageDeliveryMethods: yup.array().of(yup.string()),
      automatedMessageContent: yup.string(),
      winningInstructions: yup.array().of(yup.object({ rewardId: yup.string(), instructions: yup.string() })),
    }),
  })
}
