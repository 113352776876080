import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignRewardsBasicDataQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
}>;


export type CampaignRewardsBasicDataQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }>, tiers?: Array<{ __typename: 'ProgramTierType', id: string, reward?: { __typename: 'RewardType', id: number, name?: string | null } | null }> | null } | null } | null };


export const CampaignRewardsBasicDataDocument = gql`
    query CampaignRewardsBasicData($campaignId: ID!) {
  campaign(id: $campaignId) {
    id
    program {
      id
      socialAccounts {
        id
      }
      tiers {
        id
        reward {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignRewardsBasicDataQuery__
 *
 * To run a query within a React component, call `useCampaignRewardsBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignRewardsBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignRewardsBasicDataQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCampaignRewardsBasicDataQuery(baseOptions: Apollo.QueryHookOptions<CampaignRewardsBasicDataQuery, CampaignRewardsBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignRewardsBasicDataQuery, CampaignRewardsBasicDataQueryVariables>(CampaignRewardsBasicDataDocument, options);
      }
export function useCampaignRewardsBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignRewardsBasicDataQuery, CampaignRewardsBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignRewardsBasicDataQuery, CampaignRewardsBasicDataQueryVariables>(CampaignRewardsBasicDataDocument, options);
        }
export type CampaignRewardsBasicDataQueryHookResult = ReturnType<typeof useCampaignRewardsBasicDataQuery>;
export type CampaignRewardsBasicDataLazyQueryHookResult = ReturnType<typeof useCampaignRewardsBasicDataLazyQuery>;
export type CampaignRewardsBasicDataQueryResult = Apollo.QueryResult<CampaignRewardsBasicDataQuery, CampaignRewardsBasicDataQueryVariables>;