import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core'
import { SettingsRewardRowFragment } from './operations/settings-reward-row.generated'

interface DeleteRewardProps {
  reward: SettingsRewardRowFragment | null
  open: boolean
  onClose: () => void
  onSave: (id: number) => void
}

const DeleteReward: React.FC<DeleteRewardProps> = ({ reward, open, onClose, onSave }) => {
  const [checkbox1Checked, setCheckbox1Checked] = useState<boolean>(false)
  const [checkbox2Checked, setCheckbox2Checked] = useState<boolean>(false)
  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <DialogTitle>Delete this Reward?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Are you sure you want to delete this reward? This cannot be undone!</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox1Checked}
              onChange={(e: { target: { checked: React.SetStateAction<boolean> } }) =>
                setCheckbox1Checked(e.target.checked)
              }
            />
          }
          label="I acknowledge that this deletion is permanent."
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkbox2Checked}
              onChange={(e: { target: { checked: React.SetStateAction<boolean> } }) =>
                setCheckbox2Checked(e.target.checked)
              }
            />
          }
          label="I acknowledge that all data associated with this reward will be deleted."
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setCheckbox1Checked(false)
            setCheckbox2Checked(false)
            onClose()
          }}
          color="secondary"
        >
          Cancel
        </Button>
        {reward && (
          <Button
            onClick={() => {
              setCheckbox1Checked(false)
              setCheckbox2Checked(false)
              onSave(reward.id)
            }}
            color="primary"
            disabled={!checkbox1Checked || !checkbox2Checked}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DeleteReward
