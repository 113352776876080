import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProgramLtvStatsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.ProgramFilterInput>;
  sortBy?: Types.InputMaybe<Types.ProgramSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type ProgramLtvStatsQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, programs?: { __typename: 'PagedProgramType', total: number, cursor?: string | null, results: Array<{ __typename: 'ProgramType', id: string, campaign?: { __typename: 'CampaignType', id: number, ltvBreakdown: { __typename: 'LtvBreakdown', ltvAfter: number, ltvBefore: number, ltvNonmember: number, customers: number } } | null }> } | null } | null };

export type AdminProgramLtvStatsFragment = { __typename: 'ProgramType', id: string, campaign?: { __typename: 'CampaignType', id: number, ltvBreakdown: { __typename: 'LtvBreakdown', ltvAfter: number, ltvBefore: number, ltvNonmember: number, customers: number } } | null };

export const AdminProgramLtvStatsFragmentDoc = gql`
    fragment AdminProgramLTVStats on ProgramType {
  id
  campaign {
    id
    ltvBreakdown {
      ltvAfter
      ltvBefore
      ltvNonmember
      customers
    }
  }
}
    `;
export const ProgramLtvStatsDocument = gql`
    query ProgramLTVStats($accountId: ID!, $limit: Int, $cursor: String, $where: ProgramFilterInput, $sortBy: ProgramSort, $sortDirection: SortDirection) {
  account(id: $accountId) {
    id
    programs(
      limit: $limit
      cursor: $cursor
      where: $where
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      total
      cursor
      results {
        ...AdminProgramLTVStats
      }
    }
  }
}
    ${AdminProgramLtvStatsFragmentDoc}`;

/**
 * __useProgramLtvStatsQuery__
 *
 * To run a query within a React component, call `useProgramLtvStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramLtvStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramLtvStatsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useProgramLtvStatsQuery(baseOptions: Apollo.QueryHookOptions<ProgramLtvStatsQuery, ProgramLtvStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramLtvStatsQuery, ProgramLtvStatsQueryVariables>(ProgramLtvStatsDocument, options);
      }
export function useProgramLtvStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramLtvStatsQuery, ProgramLtvStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramLtvStatsQuery, ProgramLtvStatsQueryVariables>(ProgramLtvStatsDocument, options);
        }
export type ProgramLtvStatsQueryHookResult = ReturnType<typeof useProgramLtvStatsQuery>;
export type ProgramLtvStatsLazyQueryHookResult = ReturnType<typeof useProgramLtvStatsLazyQuery>;
export type ProgramLtvStatsQueryResult = Apollo.QueryResult<ProgramLtvStatsQuery, ProgramLtvStatsQueryVariables>;