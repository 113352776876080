import React from 'react'
import { Filters } from './use-filter-params'
import { ContentFilterType, DateRangeFilterInput, DateRangeFilterType } from '../gql-global'
import { useCreateFilterMutation, CreateFilterMutation } from './operations/create-filter.generated'
import { useDeleteFilterMutation, DeleteFilterMutation } from '../mutations/operations/delete-filter.generated'
import produce from 'immer'
import { FetchResult } from '@apollo/client/link/core'
import { DataProxy, isApolloError } from '@apollo/client'
import {
  ContentUserDataQuery,
  ContentUserDataQueryVariables,
  ContentUserDataDocument,
} from './operations/content-user-data.generated'
import SavedFilterBar from '../components/SavedFilterBar'
import { isTypeName } from '../types/utility'
import { makeMentionTypesWhere, filterResponseMentionTypesToSet } from '../dashboard/utils'
import useHasFeature from '../hooks/useHasFeature'
import { cleanAllAnyNoneFilter, dateInclusionExclusionSaveFilter } from '../utils/filter-params'
import { useToast } from '../components/Alert/ToastProvider'

const addToFrontOfFilterCache = (proxy: DataProxy, { data }: FetchResult<CreateFilterMutation>): void => {
  if (!data) return
  const filter = data.createFilter?.filter
  if (!filter || !isTypeName(filter, 'ContentFilterType')) return

  const accountFiltersResults = proxy.readQuery<ContentUserDataQuery, ContentUserDataQueryVariables>({
    query: ContentUserDataDocument,
  })
  if (!accountFiltersResults?.whoami?.account?.contentFilters) return

  const newAccountfilters = produce(accountFiltersResults, draft => {
    if (!draft.whoami?.account?.contentFilters) return
    const recentFilters = draft.whoami?.account?.contentFilters
    recentFilters.unshift(filter)
    const filterIndex = recentFilters.findIndex((f, i) => i !== 0 && f.id === filter.id)
    if (filterIndex > 0) {
      recentFilters.splice(filterIndex, 1)
    }
  })

  proxy.writeQuery<ContentUserDataQuery, ContentUserDataQueryVariables>({
    query: ContentUserDataDocument,
    data: newAccountfilters,
  })
}

const removeFromFilterCache = (proxy: DataProxy, { data }: FetchResult<DeleteFilterMutation>): void => {
  if (!data) return
  const filter = data.deleteFilter?.filter
  if (!filter) return

  const accountFiltersResults = proxy.readQuery<ContentUserDataQuery, ContentUserDataQueryVariables>({
    query: ContentUserDataDocument,
  })
  if (!accountFiltersResults?.whoami?.account?.contentFilters) return

  const newAccountfilters = produce(accountFiltersResults, draft => {
    if (!draft.whoami?.account?.contentFilters) return
    const recentFilters = draft.whoami?.account?.contentFilters
    const filterIndex = recentFilters.findIndex(f => f.id === filter.id)
    if (filterIndex > -1) {
      recentFilters.splice(filterIndex, 1)
    }
  })

  proxy.writeQuery<ContentUserDataQuery, ContentUserDataQueryVariables>({
    query: ContentUserDataDocument,
    data: newAccountfilters,
  })
}

interface ContentSavedFilterBarProps {
  filters: Filters
  setFilters: (f?: Filters) => void
  accountId?: number | null
  accountFilters?: ContentFilterType[] | null
  isInstagramAccount: boolean
  setSaveFilterDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  saveFilterDialogOpen: boolean
}

function convertFilterResponseToParamsFilter(filter: ContentFilterType, isInstagramAccount: boolean): Filters {
  const mentionType = filterResponseMentionTypesToSet(filter.value, isInstagramAccount)

  const filterCampaignId = filter.value?.campaignId
  const filterCampaigns = filter.value?.campaigns?.any
  let campaigns = undefined

  if (filterCampaigns && !filterCampaignId) {
    campaigns = filterCampaigns
  } else if (filterCampaigns && filterCampaignId) {
    campaigns = filterCampaigns.concat([filterCampaignId])
  } else if (!filterCampaigns && filterCampaignId) {
    campaigns = [filterCampaignId]
  }

  return {
    filterId: filter.id,
    postedAt: filter.value?.postedAt || undefined,
    tagStatus: new Set(filter.value?.tagStatus?.any || []),
    challengeMediaApproval: new Set(filter.value?.challengeMediaApproval?.any || []),
    followerCount: filter.value?.followerCount
      ? {
          gte: filter.value?.followerCount?.gte || undefined,
          lte: filter.value?.followerCount?.lte || undefined,
        }
      : undefined,
    impressions: filter.value?.impressions
      ? {
          gte: filter.value.impressions.gte || undefined,
          lte: filter.value.impressions.lte || undefined,
        }
      : undefined,
    engagementRate: filter.value?.engagementRate
      ? {
          gte: filter.value?.engagementRate?.gte || undefined,
          lte: filter.value?.engagementRate?.lte || undefined,
        }
      : undefined,
    captionKeywords: filter.value?.caption?.keywords || [],
    hashtagKeywords: filter.value?.hashtag?.keywords || [],
    labels: filter.value?.labels || undefined,
    segments: filter.value?.segments || undefined,
    postCount: filter.value?.postCount
      ? { gte: filter.value.postCount.gte || undefined, lte: filter.value.postCount.lte || undefined }
      : undefined,
    mentionType: mentionType.size > 0 ? mentionType : undefined,
    mediaType: new Set(filter.value?.mediaType?.any || []),
    campaigns: campaigns
      ? {
          any: campaigns,
        }
      : undefined,
    challengeIds: filter.value?.challengeIds?.any
      ? {
          any: filter.value?.challengeIds?.any,
        }
      : undefined,
    postType: new Set(filter.value?.postType?.any || []),
    unavailableMedia: filter.value?.unavailableMedia === false ? false : undefined,
    expiredStories: filter.value?.expiredStories === false ? false : undefined,
    messageTemplate: filter.value?.messageTemplate || undefined,
    messageDate: filter.value?.messageDate || undefined,
    messageKeywords: filter.value?.messageKeywords || undefined,
    messageSendingMethod: filter.value?.messageSendingMethod || undefined,
  }
}

const ContentSavedFilterBar: React.FC<ContentSavedFilterBarProps> = ({
  filters,
  setFilters,
  accountId,
  accountFilters,
  isInstagramAccount,
  setSaveFilterDialogOpen,
  saveFilterDialogOpen = false,
}) => {
  const [createFilter] = useCreateFilterMutation()
  const [deleteFilter] = useDeleteFilterMutation()
  const { showToast } = useToast()
  const selectedFilter = accountFilters?.find(f => f.id === filters.filterId) || null
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  function handleSelectFilter(id: string): void {
    const filter = accountFilters?.find(f => f.id === id.toString())
    if (filter) {
      setFilters(convertFilterResponseToParamsFilter(filter, isInstagramAccount))
    }
  }

  function handleSaveFilter(saveFilterName: string): void {
    let postedAtFilter: DateRangeFilterInput | null = null
    if (filters.postedAt) {
      if (isTypeName(filters.postedAt, 'AbsoluteDateTimeRangeFilter')) {
        postedAtFilter = {
          rangeType: DateRangeFilterType.Absolute,
          gte: filters.postedAt.gte,
          lt: filters.postedAt.lt,
        }
      } else {
        postedAtFilter = {
          rangeType: DateRangeFilterType.Relative,
          value: filters.postedAt.value,
          unit: filters.postedAt.unit,
          offset: filters.postedAt.offset,
        }
      }
    }

    createFilter({
      variables: {
        accountId: accountId?.toString() || '',
        name: saveFilterName,
        value: {
          postedAt: postedAtFilter,
          tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : null,
          challengeMediaApproval: filters.challengeMediaApproval?.size
            ? { any: Array.from(filters.challengeMediaApproval) }
            : null,
          followerCount: filters.followerCount
            ? { gte: filters.followerCount.gte, lte: filters.followerCount.lte }
            : null,
          engagementRate: filters.engagementRate
            ? { gte: filters.engagementRate.gte, lte: filters.engagementRate.lte }
            : null,
          impressions: filters.impressions ? { gte: filters.impressions.gte, lte: filters.impressions.lte } : null,
          postCount: filters.postCount ? { gte: filters.postCount.gte, lte: filters.postCount.lte } : null,
          caption: filters.captionKeywords ? { keywords: filters.captionKeywords } : null,
          hashtag: filters.hashtagKeywords ? { keywords: filters.hashtagKeywords } : null,
          labels:
            filters.labels && (filters.labels.all || filters.labels.none)
              ? {
                  all: filters.labels.all || undefined,
                  none: filters.labels.none || undefined,
                }
              : null,
          segments:
            filters.segments && (filters.segments.all || filters.segments.none)
              ? {
                  all: filters.segments.all || undefined,
                  none: filters.segments.none || undefined,
                }
              : null,
          ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
          mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : null,
          campaignId: filters.campaignId,
          postType: filters.postType?.size ? { any: Array.from(filters.postType) } : null,
          unavailableMedia: filters.expiredStories === false ? false : undefined,
          expiredStories: filters.expiredStories === false ? false : undefined,
          messageTemplate:
            filters.messageTemplate && (filters.messageTemplate.all || filters.messageTemplate.none)
              ? {
                  all: filters.messageTemplate.all || undefined,
                  none: filters.messageTemplate.none || undefined,
                }
              : null,
          messageDate: dateInclusionExclusionSaveFilter(filters.messageDate),
          messageKeywords: filters.messageKeywords,
          messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
          challengeIds:
            filters.challengeIds && filters.challengeIds?.any
              ? {
                  any: filters.challengeIds.any || undefined,
                }
              : null,
        },
      },
      update: addToFrontOfFilterCache,
    })
      .then(response => {
        const newFilter = response.data?.createFilter?.filter
        if (newFilter && isTypeName(newFilter, 'ContentFilterType')) {
          setFilters(convertFilterResponseToParamsFilter(newFilter, isInstagramAccount))
        }
      })
      .catch((e: Error) => {
        if (isApolloError(e) && e.graphQLErrors.some(g => g.extensions?.code === 'DUPLICATE_KEY')) {
          showToast({
            title: 'Error: Create Saved Search',
            message: `Saved search already exists for name ${saveFilterName}, please use a unique name.`,
            severity: 'error',
          })
        } else {
          showToast({
            title: 'Error: Create Saved Search',
            message: `Something went wrong when saving this set of search filters, please try again`,
            severity: 'error',
          })
        }
      })
  }

  const handleDeleteFilter = (id: string): void => {
    deleteFilter({
      variables: {
        filterId: id,
      },
      update: removeFromFilterCache,
    })
      .then(data => {
        showToast({
          severity: 'success',
          title: 'Success: Filter Deleted',
          message: `The search ${data.data?.deleteFilter?.filter?.name} was deleted.`,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Deleting Filter',
          message: 'Something went wrong while deleting this filter. Please try again.',
        })
      })
  }

  return (
    <SavedFilterBar
      selectedFilter={selectedFilter}
      defaultText="All Social Posts"
      filters={accountFilters || []}
      onDeleteFilter={handleDeleteFilter}
      onSelectSavedFilter={handleSelectFilter}
      onCreateFilter={handleSaveFilter}
      setSaveFilterDialogOpen={setSaveFilterDialogOpen}
      saveFilterDialogOpen={saveFilterDialogOpen}
    />
  )
}

export default ContentSavedFilterBar
