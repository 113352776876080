import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, createStyles, makeStyles } from '@material-ui/core'
import { white } from '../../loudcrowd-theme'
import ChallengeCardTop from './ChallengeCardTop'
import ChallengeCardMain from './ChallengeCardMain'
import ChallengeCardStats from './ChallengeCardStats'
import ChallengeCardCallsToAction from './ChallengeCardCallsToAction'
import { ChallengeStatusEnum } from '../../gql-global'
import { ChallengeFragment } from '../operations/challenge.generated'
import ReactCanvasConfetti from 'react-canvas-confetti'

const cardWidth = 327
const cardHeight = 342
const borderWidth = 6

export interface ChallengeCardStyledProps {
  currentStatus: string
}

const useStyles = makeStyles(() =>
  createStyles({
    challengeCardContainer: {
      width: cardWidth,
      height: cardHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      backgroundImage: (props: ChallengeCardStyledProps) =>
        styleChallengeCardByStatus({
          currentStatus: props.currentStatus as ChallengeStatusEnum,
          live: 'linear-gradient(180deg, #009E8E 0%, #38D3FF 50%)',
          completed: 'linear-gradient(180deg, #5500FF 0%, #FFBA3B 33.33%, #00ACFF 67.19%, #FF68DE 91.67%)',
        }),
    },
    challengeCard: {
      width: cardWidth - borderWidth,
      height: cardHeight - borderWidth,
      borderRadius: 5,
      padding: '16px 32px',
      backgroundColor: white,
      position: 'relative',
    },
    confetti: {
      position: 'absolute',
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  }),
)

interface ChallengeCardProps {
  challenge: ChallengeFragment
  onDelete: (challenge: ChallengeFragment) => void
  onDuplicate: (challenge: ChallengeFragment) => void
  onEdit: (challenge: ChallengeFragment) => void
  onToggle: (challenge: ChallengeFragment) => void
}

const PX_BEFORE_ANIMATION = '-250px'

function ChallengeCard({ challenge, onDelete, onDuplicate, onToggle, onEdit }: ChallengeCardProps): React.ReactElement {
  const classes = useStyles({
    currentStatus: challenge.currentStatus,
  })

  const [triggerConfetti, setTriggerConfetti] = useState<boolean>(false)
  const challengeCardRef = useRef<HTMLElement>()

  useEffect(() => {
    if (!challenge.isRecentlyCompleted) return
    if (!challengeCardRef.current) return

    const currentRef: HTMLElement = challengeCardRef.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) {
          setTriggerConfetti(true)
        }
      },
      { rootMargin: PX_BEFORE_ANIMATION },
    )

    observer.observe(currentRef)
    return () => observer.unobserve(currentRef)
  }, [challenge.isRecentlyCompleted])

  return (
    <Card ref={challengeCardRef} className={classes.challengeCardContainer}>
      <Box className={classes.challengeCard}>
        <ReactCanvasConfetti
          fire={triggerConfetti}
          className={classes.confetti}
          startVelocity={50}
          spread={90}
          ticks={350}
          zIndex={0}
          particleCount={300}
          origin={{ y: 1.3 }}
        />
        <ChallengeCardTop
          challenge={challenge}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          onEdit={onEdit}
          onToggle={onToggle}
        />
        <ChallengeCardMain challenge={challenge} />
        <ChallengeCardStats challenge={challenge} />
        <ChallengeCardCallsToAction challenge={challenge} onToggle={onToggle} onEdit={onEdit} />
      </Box>
    </Card>
  )
}

export default ChallengeCard

export function styleChallengeCardByStatus({
  currentStatus,
  completed,
  live,
  defaultStyle,
}: {
  currentStatus: ChallengeStatusEnum
  completed: string
  live: string
  defaultStyle?: string
}): string {
  switch (currentStatus) {
    case ChallengeStatusEnum.Completed:
      return completed
    case ChallengeStatusEnum.Live:
      return live
    default:
      return defaultStyle || ''
  }
}
