import React, { useState, useEffect } from 'react'
import { isValid, parse } from 'date-fns'

import { TextField, TextFieldProps } from '../TextField/TextField'

interface DateTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (date?: Date) => void
  value?: string
}

const defaultDate = new Date(1900, 1, 1)
const currentDate = new Date()
const allowedFormats = [
  'MM/dd/yyyy',
  'yyyy-MM-dd',
  'MMM d, yyyy',
  'MMM d yyyy',
  'MMMM d, yyyy',
  'MMMM d yyyy',
  'MMM do, yyyy',
  'MMM do yyyy',
  'MMMM do, yyyy',
  'MMMM do yyyy',
]

export const DateTextField: React.FC<DateTextFieldProps> = ({ value, onChange, ...props }) => {
  const [potentialDate, setPotentialDate] = useState<string | undefined>(value)
  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value
    setPotentialDate(value)

    if (!value) {
      onChange(undefined)
    } else {
      allowedFormats.forEach((format: string) => {
        const parsedDate = parse(value, format, currentDate)
        if (parsedDate.getTime() > defaultDate.getTime() && isValid(parsedDate)) {
          onChange(parsedDate)
        }
      })
    }
  }

  useEffect(() => setPotentialDate(value), [value])

  return <TextField {...props} onChange={handleOnChange} value={potentialDate} />
}
