import React, { Dispatch } from 'react'
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import {
  EXPORT_LIMIT,
  SEND_REWARD_LIMIT,
  BULK_SEGMENT_ADD_LIMIT,
  STATUS_CHANGE_LIMIT,
  SET_COMMISSION_TIER_LIMIT,
} from './constants'
import { BulkActionState, BulkActionStateAction } from './use-bulk-action-state'

function getBulkActionTexts(action: Extract<BulkActionState, { step: 'overLimit' | 'confirmation' }>['action']): {
  title: string
  actionText: string
  limitText: string
} {
  switch (action) {
    case 'export':
      return {
        title: 'Export',
        actionText: 'export',
        limitText: EXPORT_LIMIT.toLocaleString(),
      }
    case 'gift':
      return {
        title: 'Send Reward',
        actionText: 'send reward to',
        limitText: SEND_REWARD_LIMIT.toLocaleString(),
      }
    case 'rewardChallenge':
      return {
        title: 'Pick Winner',
        actionText: 'send reward to',
        limitText: SEND_REWARD_LIMIT.toLocaleString(),
      }
    case 'segment':
    case 'addSegment':
    case 'createSegment':
      return {
        title: 'Add Segment',
        actionText: 'add a segment to',
        limitText: BULK_SEGMENT_ADD_LIMIT.toLocaleString(),
      }
    case 'createCommissionTier':
    case 'setCommissionTier':
      return {
        title: 'Set Program commission tier',
        actionText: 'set the program commission tier for',
        limitText: SET_COMMISSION_TIER_LIMIT.toLocaleString(),
      }
    case 'reject':
      return {
        title: 'Reject',
        actionText: 'reject',
        limitText: STATUS_CHANGE_LIMIT.toLocaleString(),
      }
    case 'approve':
      return {
        title: 'Approve',
        actionText: 'approve',
        limitText: STATUS_CHANGE_LIMIT.toLocaleString(),
      }
    case 'remove':
      return {
        title: 'Remove',
        actionText: 'remove',
        limitText: STATUS_CHANGE_LIMIT.toLocaleString(),
      }
    case 'removeBulk':
      return {
        title: 'Remove',
        actionText: 'Remove',
        limitText: STATUS_CHANGE_LIMIT.toLocaleString(),
      }
  }
}

interface ActionDialogsProps {
  bulkState: BulkActionState
  dispatch: Dispatch<BulkActionStateAction>
  selectedCount: number
  onConfirm(): void
  entityName: 'members' | 'applicants'
}

function ActionDialogs({
  bulkState,
  dispatch,
  selectedCount,
  onConfirm,
  entityName,
}: ActionDialogsProps): React.ReactElement {
  const customer = (bulkState !== null && 'participant' in bulkState && bulkState.participant?.customer) || null
  const socialUser = customer?.igUser || customer?.ttUser
  const username = socialUser?.username
  return (
    <>
      <Dialog open={bulkState?.step === 'overLimit'} onClose={() => dispatch({ type: 'done' })}>
        {bulkState?.step === 'overLimit' && (
          <>
            <DialogTitle>Cannot {getBulkActionTexts(bulkState.action).title}</DialogTitle>
            <DialogContent>
              <p>
                You cannot {getBulkActionTexts(bulkState.action).actionText} to more than{' '}
                {getBulkActionTexts(bulkState.action).limitText} {entityName} at a time. Please select under{' '}
                {getBulkActionTexts(bulkState.action).limitText} {entityName} or make a bulk request to{' '}
                <Link href="mailto:support@loudcrowd.com">support@loudcrowd.com</Link>
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => dispatch({ type: 'done' })}>
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={bulkState?.step === 'confirmation'} onClose={() => dispatch({ type: 'done' })}>
        {bulkState?.step === 'confirmation' && (
          <>
            <DialogTitle>{getBulkActionTexts(bulkState.action).title}?</DialogTitle>
            <DialogContent>
              <p>
                You are about to {getBulkActionTexts(bulkState.action).actionText}{' '}
                {username || `${selectedCount.toLocaleString()} ${entityName}`}.
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => dispatch({ type: 'done' })}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={onConfirm}>
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}

export default ActionDialogs
