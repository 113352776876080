import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignRevenueStatsQueryVariables = Types.Exact<{
  ambassadorStatsTimeseriesInput?: Types.InputMaybe<Types.AmbassadorStatsTimeseriesInput>;
  campaignId: Types.Scalars['ID'];
  currentAmbassadorStatsInput?: Types.InputMaybe<Types.AmbassadorStatsInput>;
  previousAmbassadorStatsInput?: Types.InputMaybe<Types.AmbassadorStatsInput>;
  timezone: Types.Scalars['String'];
}>;


export type CampaignRevenueStatsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, ambassadorStats?: { __typename: 'AmbassadorStats', commissionsTotal: number, landings: number, revenue: number, sales: number, totalCustomers: number, totalLandedOrders: number, totalOrders: number } | null, ambassadorStatsTimeseries?: Array<{ __typename: 'AmbassadorStatsTimeseries', commissions: number, endDate: Date, landings: number, revenue: number, sales: number, startDate: Date, totalCustomers: number, totalLandedOrders: number, totalOrders: number }> | null, previousAmbassadorStats?: { __typename: 'AmbassadorStats', commissions: number, landings: number, revenue: number, sales: number, totalCustomers: number, totalLandedOrders: number, totalOrders: number } | null, program?: { __typename: 'ProgramType', currencyCode?: Types.CurrencyCodeEnum | null, id: string } | null } | null };


export const CampaignRevenueStatsDocument = gql`
    query CampaignRevenueStats($ambassadorStatsTimeseriesInput: AmbassadorStatsTimeseriesInput, $campaignId: ID!, $currentAmbassadorStatsInput: AmbassadorStatsInput, $previousAmbassadorStatsInput: AmbassadorStatsInput, $timezone: String!) {
  campaign(id: $campaignId) {
    ambassadorStats(where: $currentAmbassadorStatsInput) {
      commissionsTotal
      landings
      revenue
      sales
      totalCustomers
      totalLandedOrders
      totalOrders
    }
    ambassadorStatsTimeseries(
      timezone: $timezone
      where: $ambassadorStatsTimeseriesInput
    ) {
      commissions
      endDate
      landings
      revenue
      sales
      startDate
      totalCustomers
      totalLandedOrders
      totalOrders
    }
    id
    previousAmbassadorStats: ambassadorStats(where: $previousAmbassadorStatsInput) {
      commissions
      landings
      revenue
      sales
      totalCustomers
      totalLandedOrders
      totalOrders
    }
    program {
      currencyCode
      id
    }
  }
}
    `;

/**
 * __useCampaignRevenueStatsQuery__
 *
 * To run a query within a React component, call `useCampaignRevenueStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignRevenueStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignRevenueStatsQuery({
 *   variables: {
 *      ambassadorStatsTimeseriesInput: // value for 'ambassadorStatsTimeseriesInput'
 *      campaignId: // value for 'campaignId'
 *      currentAmbassadorStatsInput: // value for 'currentAmbassadorStatsInput'
 *      previousAmbassadorStatsInput: // value for 'previousAmbassadorStatsInput'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCampaignRevenueStatsQuery(baseOptions: Apollo.QueryHookOptions<CampaignRevenueStatsQuery, CampaignRevenueStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignRevenueStatsQuery, CampaignRevenueStatsQueryVariables>(CampaignRevenueStatsDocument, options);
      }
export function useCampaignRevenueStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignRevenueStatsQuery, CampaignRevenueStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignRevenueStatsQuery, CampaignRevenueStatsQueryVariables>(CampaignRevenueStatsDocument, options);
        }
export type CampaignRevenueStatsQueryHookResult = ReturnType<typeof useCampaignRevenueStatsQuery>;
export type CampaignRevenueStatsLazyQueryHookResult = ReturnType<typeof useCampaignRevenueStatsLazyQuery>;
export type CampaignRevenueStatsQueryResult = Apollo.QueryResult<CampaignRevenueStatsQuery, CampaignRevenueStatsQueryVariables>;