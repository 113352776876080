import React, { useState } from 'react'
import { Checkbox, useTheme } from '@material-ui/core'
import { FieldProps } from 'formik'

const CheckBoxField: React.FC<FieldProps> = ({ field, form, ...other }) => {
  const theme = useTheme()
  const [checkVal, setCheckVal] = useState<boolean>(field?.value === 'true')

  return (
    <Checkbox
      style={{ color: theme.palette.primary.main }}
      name={field.name}
      value={checkVal}
      checked={checkVal}
      onChange={() => {
        setCheckVal(!checkVal)
        form.setFieldValue(field.name, checkVal ? 'false' : 'true', false)
      }}
      {...other}
    />
  )
}

export default CheckBoxField
