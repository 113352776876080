import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSegmentMutationVariables = Types.Exact<{
  segmentId: Types.Scalars['ID'];
}>;


export type DeleteSegmentMutation = { __typename: 'Mutations', deleteSegment?: { __typename: 'DeleteSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number } | null } | null };


export const DeleteSegmentDocument = gql`
    mutation DeleteSegment($segmentId: ID!) {
  deleteSegment(segmentId: $segmentId) {
    ok
    segment {
      id
    }
  }
}
    `;
export type DeleteSegmentMutationFn = Apollo.MutationFunction<DeleteSegmentMutation, DeleteSegmentMutationVariables>;

/**
 * __useDeleteSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentMutation, { data, loading, error }] = useDeleteSegmentMutation({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *   },
 * });
 */
export function useDeleteSegmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(DeleteSegmentDocument, options);
      }
export type DeleteSegmentMutationHookResult = ReturnType<typeof useDeleteSegmentMutation>;
export type DeleteSegmentMutationResult = Apollo.MutationResult<DeleteSegmentMutation>;
export type DeleteSegmentMutationOptions = Apollo.BaseMutationOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>;