import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@material-ui/core'
import { primary } from '../../../loudcrowd-theme'
import MenuHeader from '../../../components/MenuHeader'

const useStyles = makeStyles(theme =>
  createStyles({
    inlineButton: {
      color: primary[500],
      fontSize: 'inherit',
      minWidth: 0,
      fontWeight: 400,
      textDecoration: 'underline',
      padding: 0,
      marginBottom: 4,
      textAlign: 'left',
      height: 'inherit',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    menu: {
      minWidth: 320,
      border: '1px solid rgba(0, 0, 0, 0.01)',
    },
    menuItem: {
      marginBottom: theme.spacing(1),
    },
  }),
)

interface FulfillmentLimitFilterProps {
  fulfillmentLimit?: number
  onFulfillmentLimitChange: (value?: number) => void
}

const FulfillmentLimitFilter = ({ fulfillmentLimit, onFulfillmentLimitChange }: FulfillmentLimitFilterProps) => {
  const classes = useStyles()
  const options = ['forever', 'up to a number of times']
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [value, setValue] = useState<number | undefined>(fulfillmentLimit)
  const [submenuOpen, setSubmenuOpen] = useState(false)

  const handleOnClick = (option?: string) => {
    if (option === options[0]) {
      setMenuOpen(false)
      setValue(0)
      onFulfillmentLimitChange(0)
    } else {
      setSubmenuOpen(true)
    }
  }

  const handleApply = () => {
    value && onFulfillmentLimitChange(value)
    setSubmenuOpen(false)
    setMenuOpen(false)
  }

  const handleReset = () => {
    onFulfillmentLimitChange(undefined)
    setValue(undefined)
    setSubmenuOpen(false)
    setMenuOpen(false)
  }

  const handleOnButtonClick = () => {
    fulfillmentLimit ? setSubmenuOpen(true) : setMenuOpen(true)
  }

  return (
    <>
      <Button variant="text" className={classes.inlineButton} onClick={() => handleOnButtonClick()} ref={buttonRef}>
        {fulfillmentLimit ? `up to ${fulfillmentLimit} time${fulfillmentLimit > 1 ? 's' : ''}` : 'forever'}
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        onClose={() => setMenuOpen(false)}
      >
        <MenuList autoFocusItem>
          {options.map(option => (
            <MenuItem key={option} onClick={() => handleOnClick(option)} className={classes.menuItem}>
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Menu
        open={submenuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setSubmenuOpen(false)}
      >
        <MenuHeader title="Earn Reward Count" />
        <Box width={320} p={5}>
          <Typography>Enter total number of times the reward can be earned up to</Typography>
          <TextField fullWidth value={value ? value : ''} onChange={e => setValue(parseInt(e.target.value))} />
        </Box>
        <Box mt={2}>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <Button color="secondary" onClick={() => handleReset()}>
              Reset
            </Button>
            <Button color="primary" variant="contained" onClick={() => handleApply()} disabled={!value}>
              Save
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  )
}

export default FulfillmentLimitFilter
