import React, { useMemo } from 'react'
import { Box, createStyles, makeStyles, Typography, TextField, MenuItem, Tooltip } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import theme from '../../../loudcrowd-theme'

import { CurrencyCodeEnum, IntegrationType } from '../../../gql-global'
import DatePickerField from '../../../components/DatePickerField/DatePickerField'
import { CheckboxWithLabel } from 'formik-material-ui'
import { ProgramSettingsFields } from './CampaignSettings'
import { ReactComponent as InfoIcon } from '../../../icons/information-no-halo.svg'
import { SelectField, SelectFieldOption } from '../../../components/SelectField/SelectField'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
      padding: 10,
    },
    title: {
      fontSize: 32,
      fontWeight: 500,
    },
    field: {
      marginTop: 20,
      marginBottom: 20,
    },
    input: {
      width: '100%',
    },
    helpText: {
      color: theme.palette.secondary.main,
      fontSize: 12,
    },
    label: {
      color: theme.palette.primary.main,
    },
    dialogActions: {
      justifyContent: 'flex-start',
      padding: '25px 0 0 25px',
      '& button': {
        minWidth: 100,
      },
    },
    infoIcon: {
      color: theme.palette.secondary.main,
    },
  }),
)
interface AllTypesCampaignSettingsProps {
  editMode?: boolean
  integrations?: { id: string; name: string; integrationType: IntegrationType }[] | null
  isIntegrationEditable?: boolean
}

function AllTypesCampaignSettings({
  editMode,
  integrations,
  isIntegrationEditable = true,
}: AllTypesCampaignSettingsProps): React.ReactElement {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext<ProgramSettingsFields>()

  const integrationsOptions: SelectFieldOption[] = useMemo(() => {
    const options =
      integrations
        ?.filter(i =>
          [IntegrationType.Shopify, IntegrationType.CommerceCloud, IntegrationType.CustomStore].includes(
            i.integrationType,
          ),
        )
        .map(i => ({
          id: i.id,
          label: i.name,
        })) || []

    options.push({ id: 'none', label: 'none' })

    return options
  }, [integrations])

  return (
    <Box className={classes.container}>
      <Typography variant="h5">Base Settings (for all programs)</Typography>
      <Box className={classes.field}>
        <Box className={classes.input}>
          <Field component={DatePickerField} label="Start Date" name="startDate" disabled />
        </Box>
        <Field
          name="currencyCode"
          className={classes.input}
          component={TextField}
          select
          label="Currency Code"
          defaultValue={values.currencyCode}
          disabled
        >
          {Object.keys(CurrencyCodeEnum).map(code => (
            <MenuItem key={code.toUpperCase()} value={code.toUpperCase()}>
              {code.toUpperCase()}
            </MenuItem>
          ))}
        </Field>
        <Box>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{ label: 'Requires Member Approval' }}
            name="requiresApproval"
            disabled={!editMode}
          />
        </Box>
        <Box>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{ label: 'Requires Content Verification' }}
            name="requiresVerification"
            disabled={!editMode}
          />
        </Box>
        <Box className={classes.field}>
          <Box display="flex">
            <Typography className={classes.label} variant="body1">
              Signup Form Url
            </Typography>
            <Tooltip
              placement="top"
              title="A signup form in a shareable page format. Prospective members can easily fill out via social direct message, email, SMS, etc"
            >
              <Box ml={2} className={classes.infoIcon}>
                <InfoIcon width={20} height={20} />
              </Box>
            </Tooltip>
          </Box>
          <Field className={classes.input} as={TextField} type="text" name="signupFormUrl" />
        </Box>
        <Box className={classes.field}>
          <Box display="flex">
            <Typography className={classes.label} variant="body1">
              Landing Page Url
            </Typography>
            <Tooltip
              placement="top"
              title="A page on your eCommerce site to collect program signups and centralize program information (rules, perks, FAQs, terms & conditions, etc…)"
            >
              <Box ml={2} className={classes.infoIcon}>
                <InfoIcon width={20} height={20} />
              </Box>
            </Tooltip>
          </Box>

          <Field className={classes.input} as={TextField} type="text" name="postingRulesUrl" />
        </Box>
        <Box className={classes.field}>
          <Typography className={classes.label} variant="body1">
            Connected Ecomm Store
          </Typography>
          <SelectField
            hideSearch
            placeholder="ecomm Store"
            value={values.storeIntegrationId}
            setValue={(value: string | number) => setFieldValue('storeIntegrationId', value)}
            options={integrationsOptions}
            disabled={!isIntegrationEditable}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AllTypesCampaignSettings
