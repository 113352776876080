import { useQueryParams, encodeQueryParams, stringify, StringParam } from 'use-query-params'
import { LabelsFilter } from '../../gql-global'

import { REWARD_SEARCH_PARAM_KEY, rewardParam } from './use-reward-param'
import { cleanAllAnyNoneFilter } from '../../utils/filter-params'

export interface Filters {
  rewardKeywords: string[]
  rewardType?: Omit<LabelsFilter, '__typename'>
  campaigns?: Omit<LabelsFilter, '__typename'>
  integrations?: Omit<LabelsFilter, '__typename'>
}

interface RewardParams {
  [REWARD_SEARCH_PARAM_KEY]?: string[]
  rewardType?: string
  campaigns?: string
  integrations?: string
}

function paramsToFilters(params: RewardParams): Filters {
  const { campaigns, integrations } = params
  const campaignsJson = campaigns ? JSON.parse(campaigns) : undefined
  const integrationsJson = integrations ? JSON.parse(integrations) : undefined
  const rewardTypeJson = params.rewardType ? JSON.parse(params.rewardType) : undefined
  return {
    rewardKeywords: params[REWARD_SEARCH_PARAM_KEY] || [],
    campaigns:
      campaignsJson && campaignsJson.any
        ? {
            any: campaignsJson?.any || undefined,
          }
        : undefined,
    integrations:
      integrationsJson && integrationsJson.any
        ? {
            any: integrationsJson?.any || undefined,
          }
        : undefined,
    rewardType:
      rewardTypeJson && rewardTypeJson.any
        ? {
            any: rewardTypeJson?.any || undefined,
          }
        : undefined,
  }
}

function filtersToParams(filters: Filters): RewardParams {
  return {
    [REWARD_SEARCH_PARAM_KEY]: filters.rewardKeywords,
    campaigns: JSON.stringify(cleanAllAnyNoneFilter(filters.campaigns)),
    integrations: JSON.stringify(cleanAllAnyNoneFilter(filters.integrations)),
    rewardType: JSON.stringify(cleanAllAnyNoneFilter(filters.rewardType)),
  }
}

const filterParamConfig = {
  [REWARD_SEARCH_PARAM_KEY]: rewardParam,
  campaigns: StringParam,
  integrations: StringParam,
  rewardType: StringParam,
}

/*
 * encodes label filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Filters): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  const filters = paramsToFilters(filterParams)
  return {
    filters,
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
