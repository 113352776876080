import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
  Tooltip,
} from '@material-ui/core'
import React from 'react'
import { Formik, Form, Field, yupToFormErrors, FormikErrors } from 'formik'
import { TextField, Select, CheckboxWithLabel } from 'formik-material-ui'
import * as yup from 'yup'
import UploadRewardCodes from './UploadRewardCodes'
import { SettingsRewardRowFragment } from './operations/settings-reward-row.generated'
import { TREMENDOUS_CURRENCY_CODES } from './tremendous_currencies'
import { TREMENDOUS_DELIVERY_METHODS } from './tremendous_delivery'
import { TremendousCampaign } from '../../gql-global'
import { isTypeName } from '../../types/utility'
import { RewardIntegrationFragment } from './operations/reward-integration.generated'
import { UploadedFile } from '../../global'

type Integration = RewardIntegrationFragment

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      marginBottom: theme.spacing(7),
    },
    deleteButton: {
      color: theme.palette.error.dark,
    },
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 300,
    },
    helper: {
      color: theme.palette.secondary.main,
    },
    disabledDeleteMessage: {
      display: 'block',
      position: 'absolute',
      top: -30,
      left: 17,
    },
  }),
)

interface RewardFields {
  name: string
  rewardType: string | 'dm' | 'manual'
  emailTemplateName: string
  loyaltyTierId: string
  templateId: string
  discountCodeFieldName: string
  subject: string
  eventId: string
  bluecoreCampaignId: string
  hasDiscountCode: boolean
  codeFile: string | null
  deliveryMethod: string
  value?: number
  currencyCode: string
  tremendousCampaignId: string
}

const fieldSchema = {
  name: yup.string().required(),
  rewardType: yup.string(),
  emailTemplateName: yup.string().required(),
  loyaltyTierId: yup.string(),
  templateId: yup.string().required(),
  discountCodeFieldName: yup
    .string()
    .when('discountCode', (other: string | null, schema: yup.StringSchema) =>
      !!other ? schema.required() : schema.notRequired().nullable(),
    ),
  subject: yup.string().required(),
  eventId: yup.string().required(),
  bluecoreCampaignId: yup.string().nullable().notRequired(),
  hasDiscountCode: yup.bool().required(),
  codeFile: yup.string(),
  deliveryMethod: yup.string().required(),
  value: yup.number().integer().positive().required(),
  currencyCode: yup.string().required(),
  tremendousCampaignId: yup.string().required(),
}

function integrationFields(integration: Integration): Set<keyof RewardFields> {
  switch (integration.__typename) {
    case 'MandrillIntegration':
      return new Set(['name', 'emailTemplateName', 'discountCodeFieldName', 'hasDiscountCode'] as const)
    case 'YotpoIntegration':
      return new Set(['eventId'] as const)
    case 'GrowaveIntegration':
      return new Set(['eventId', 'value'] as const)
    case 'ZaiusIntegration':
      return new Set(['loyaltyTierId', 'hasDiscountCode'] as const)
    case 'SendinblueIntegration':
      return new Set(['templateId', 'hasDiscountCode'] as const)
    case 'WebhookIntegration':
      return new Set(['loyaltyTierId'] as const)
    case 'KlaviyoIntegration':
      return new Set(['eventId', 'hasDiscountCode'] as const)
    case 'EmarsysIntegration':
      return new Set(['eventId', 'hasDiscountCode'] as const)
    case 'BrazeIntegration':
      return new Set(['templateId', 'discountCodeFieldName', 'hasDiscountCode'] as const)
    case 'LoyaltyLionIntegration':
      return new Set(['eventId'] as const)
    case 'SmileIntegration':
      return new Set(['eventId'] as const)
    case 'TremendousIntegration':
      return new Set(['deliveryMethod', 'value', 'currencyCode', 'tremendousCampaignId'] as const)
    case 'OmetriaIntegration':
      return new Set(['eventId', 'hasDiscountCode'] as const)
    case 'BluecoreIntegration':
      return new Set(['bluecoreCampaignId', 'hasDiscountCode'] as const)
  }
  return new Set()
}

export type RewardFormOutput = Omit<RewardFields, 'rewardType'> & {
  integrationId?: string | null
  isDmReward: boolean
}

interface AddEditRewardProps {
  reward: SettingsRewardRowFragment | null
  open: boolean
  onClose(): void
  onSave(values: RewardFormOutput, rewardId?: number | null): void
  onDelete(reward: SettingsRewardRowFragment): void
  integrations?: Integration[] | null
  accountId?: number | null
}

function AddEditReward({
  reward,
  open,
  onClose,
  onSave,
  onDelete,
  accountId,
  integrations = null,
}: AddEditRewardProps): React.ReactElement {
  const classes = useStyles()
  const isAdd = !reward

  const isUsed =
    !!reward?.tiers?.filter(t => !!t.program).length ||
    !!reward?.messageTemplates?.length ||
    !!reward?.challenges?.length

  function getFields(values: RewardFields): Set<keyof RewardFields> {
    if (values.rewardType === 'manual' || values.rewardType === 'dm') {
      return new Set(['name', 'hasDiscountCode'] as const)
    }

    const integration = integrations?.find(i => i.id === values.rewardType)
    if (!integration) {
      return new Set()
    }
    return integrationFields(integration)
  }

  function getTremendousCampaigns(values: RewardFields): Array<TremendousCampaign> {
    const integration = integrations?.find(i => i.id === values.rewardType)
    if (!integration) {
      return []
    }
    if (isTypeName(integration, 'TremendousIntegration')) {
      return integration.tremendousCampaigns
    }
    return []
  }

  async function handleValidate(values: RewardFields): Promise<FormikErrors<RewardFields>> {
    const fields = getFields(values)
    const schema = yup.object(Array.from(fields).reduce((a, v) => ({ ...a, [v]: fieldSchema[v] }), {}))
    try {
      await schema.validate(values)
      return {}
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        return yupToFormErrors(e)
      } else {
        throw e
      }
    }
  }

  function handleSubmit(values: RewardFields) {
    onSave(
      {
        ...values,
        integrationId: values.rewardType === 'manual' || values.rewardType === 'dm' ? null : values.rewardType,
        isDmReward: values.rewardType === 'dm',
      },
      reward?.id,
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik<RewardFields>
        enableReinitialize
        initialValues={{
          name: reward?.name || '',
          rewardType: reward?.isDmReward ? 'dm' : reward?.integration?.id || 'manual',
          emailTemplateName: reward?.emailTemplateName || '',
          templateId: reward?.templateId || '',
          loyaltyTierId: reward?.tierId || '',
          discountCodeFieldName: reward?.emailTemplateVariableName || '',
          subject: reward?.subject || '',
          eventId: reward?.eventId || '',
          hasDiscountCode: reward?.hasDiscountCode || isAdd,
          codeFile: null,
          deliveryMethod: reward?.deliveryMethod || '',
          value: reward?.value || undefined,
          currencyCode: reward?.currencyCode || 'USD',
          tremendousCampaignId: reward?.tremendousCampaignId || '',
          bluecoreCampaignId: reward?.bluecoreCampaignId || '',
        }}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {props => {
          function deliveryMethodHas(fieldName: keyof RewardFields): boolean {
            return !!getFields(props.values)?.has(fieldName)
          }
          return (
            <Form noValidate>
              <DialogTitle>{`${isAdd ? 'Add' : 'Edit'} Reward`}</DialogTitle>
              <DialogContent>
                <Box display="flex" flexDirection="column" width={350}>
                  <Field
                    className={classes.formField}
                    component={TextField}
                    type="text"
                    name="name"
                    label="Reward Name"
                  />

                  <FormControl>
                    <InputLabel htmlFor="auto-delivery-method">Reward Method</InputLabel>
                    <Field
                      className={classes.formField}
                      labelId="auto-delivery-method"
                      name="rewardType"
                      component={Select}
                    >
                      {integrations?.map(i => {
                        return (
                          <MenuItem key={i.id} value={i.id}>
                            {i.name}
                          </MenuItem>
                        )
                      })}
                      <MenuItem value="dm">DM</MenuItem>
                      <MenuItem value="manual">Manual</MenuItem>
                    </Field>
                  </FormControl>

                  {deliveryMethodHas('emailTemplateName') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="emailTemplateName"
                      label="Email Template Name"
                      type="text"
                    />
                  )}
                  {deliveryMethodHas('templateId') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="templateId"
                      label="Template ID"
                      type="text"
                    />
                  )}
                  {deliveryMethodHas('subject') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="subject"
                      label="Subject"
                      type="text"
                    />
                  )}
                  {deliveryMethodHas('loyaltyTierId') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="loyaltyTierId"
                      label="Loyalty Tier ID"
                      type="text"
                    />
                  )}
                  {deliveryMethodHas('discountCodeFieldName') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="discountCodeFieldName"
                      label="Discount Code Field Name"
                      type="text"
                    />
                  )}

                  {deliveryMethodHas('eventId') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="eventId"
                      label="Event ID"
                      type="text"
                    />
                  )}
                  {deliveryMethodHas('bluecoreCampaignId') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="bluecoreCampaignId"
                      label="Bluecore Campaign ID (in Bluecore)"
                      type="text"
                    />
                  )}
                  {/* Tremendous-specific fields */}

                  {deliveryMethodHas('deliveryMethod') && (
                    <FormControl>
                      <InputLabel htmlFor="deliveryMethod">Delivery Method</InputLabel>
                      <Field
                        className={classes.formField}
                        component={Select}
                        name="deliveryMethod"
                        labelId="delivery_method"
                      >
                        {TREMENDOUS_DELIVERY_METHODS?.map(delivery => {
                          return (
                            <MenuItem key={delivery.value} value={delivery.value}>
                              {delivery.display}
                            </MenuItem>
                          )
                        })}
                      </Field>
                    </FormControl>
                  )}

                  {deliveryMethodHas('currencyCode') && (
                    <FormControl>
                      <InputLabel htmlFor="currency-code">Currency</InputLabel>
                      <Field
                        className={classes.formField}
                        component={Select}
                        name="currencyCode"
                        labelId="currency-code"
                      >
                        {TREMENDOUS_CURRENCY_CODES?.map(currency => {
                          return (
                            <MenuItem key={currency.code} value={currency.code}>
                              {currency.name}
                            </MenuItem>
                          )
                        })}
                      </Field>
                    </FormControl>
                  )}

                  {deliveryMethodHas('value') && (
                    <Field
                      className={classes.formField}
                      component={TextField}
                      name="value"
                      label="Value"
                      type="text"
                      helperText="Enter a whole number for the reward value"
                      FormHelperTextProps={{
                        classes: { root: classes.helper },
                      }}
                    />
                  )}

                  {deliveryMethodHas('tremendousCampaignId') && (
                    <FormControl>
                      <InputLabel htmlFor="tremendous-campaign-id">Campaign Name</InputLabel>
                      <Field
                        className={classes.formField}
                        component={Select}
                        name="tremendousCampaignId"
                        label="Tremendous Campaign ID"
                        type="text"
                      >
                        {getTremendousCampaigns(props.values).map(campaign => {
                          return (
                            <MenuItem key={campaign.code} value={campaign.code}>
                              {campaign.name}
                            </MenuItem>
                          )
                        })}
                      </Field>
                    </FormControl>
                  )}

                  {/* End of Tremendous fields */}

                  {deliveryMethodHas('hasDiscountCode') && (
                    <>
                      <Typography variant="subtitle1">Upload reward codes</Typography>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="hasDiscountCode"
                        Label={{ label: 'This reward has discount codes.' }}
                        color="primary"
                      />
                      {isAdd && props.values.hasDiscountCode && (
                        <UploadRewardCodes
                          accountId={accountId || null}
                          onSelectFile={(file: UploadedFile) => {
                            props.setFieldValue('codeFile', file.bucketKey)
                            props.setFieldValue('hasDiscountCode', !!file.bucketKey)
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Box flexGrow={1} position="relative">
                  {reward &&
                    (isUsed ? (
                      <Tooltip
                        title="Cannot delete a reward that is used in a program, message or challenge"
                        placement="top-start"
                      >
                        <span>
                          <Button variant="text" disabled>
                            Delete
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button variant="text" onClick={() => onDelete(reward)} className={classes.deleteButton}>
                        Delete
                      </Button>
                    ))}
                </Box>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={!(props.dirty && props.isValid)}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default AddEditReward
