import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box, Paper } from '@material-ui/core'

function ActivityListItemLoading(): React.ReactElement | null {
  return (
    <Paper>
      <Box py={3} px={5} display="flex" alignItems="center">
        <Skeleton width={80} height={80} variant="rect" />
        <Box ml={6} flexGrow={1}>
          <Skeleton width={300} variant="text" />
          <Skeleton width={300} variant="text" />
        </Box>
        <Skeleton width={100} variant="text" />
      </Box>
    </Paper>
  )
}

export default ActivityListItemLoading
