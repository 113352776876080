import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type FulfillmentAccountRowFragment = { __typename: 'FulfillAccountType', accountType: Types.FulfillmentAccountType, apiSecret?: string | null, apiUrl?: string | null, fromEmail?: string | null, fromName?: string | null, guid?: string | null, id: number, integrationName?: string | null, key?: string | null, lastPush?: Date | null, namespace?: string | null, organizationName?: string | null, smsConsentList?: string | null };

export const FulfillmentAccountRowFragmentDoc = gql`
    fragment FulfillmentAccountRow on FulfillAccountType {
  accountType
  apiSecret
  apiUrl
  fromEmail
  fromName
  guid
  id
  integrationName
  key
  lastPush
  namespace
  organizationName
  smsConsentList
}
    `;