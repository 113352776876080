import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountSegmentsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy: Types.SegmentSort;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.SegmentFilterInput>;
}>;


export type AccountSegmentsQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, segments?: { __typename: 'PagedSegmentType', cursor?: string | null, total: number, results: Array<{ __typename: 'SegmentType', id: number, name: string, customerCount?: number | null }> } | null } | null } | null };


export const AccountSegmentsDocument = gql`
    query AccountSegments($limit: Int, $sortBy: SegmentSort!, $sortDirection: SortDirection, $cursor: String, $where: SegmentFilterInput) {
  whoami {
    id
    account {
      id
      segments(
        limit: $limit
        sortBy: $sortBy
        sortDirection: $sortDirection
        cursor: $cursor
        where: $where
      ) {
        cursor
        total
        results {
          id
          name
          customerCount
        }
      }
    }
  }
}
    `;

/**
 * __useAccountSegmentsQuery__
 *
 * To run a query within a React component, call `useAccountSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSegmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAccountSegmentsQuery(baseOptions: Apollo.QueryHookOptions<AccountSegmentsQuery, AccountSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSegmentsQuery, AccountSegmentsQueryVariables>(AccountSegmentsDocument, options);
      }
export function useAccountSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSegmentsQuery, AccountSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSegmentsQuery, AccountSegmentsQueryVariables>(AccountSegmentsDocument, options);
        }
export type AccountSegmentsQueryHookResult = ReturnType<typeof useAccountSegmentsQuery>;
export type AccountSegmentsLazyQueryHookResult = ReturnType<typeof useAccountSegmentsLazyQuery>;
export type AccountSegmentsQueryResult = Apollo.QueryResult<AccountSegmentsQuery, AccountSegmentsQueryVariables>;