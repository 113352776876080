import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import Page from '../../Page'
import useTitle from '../../utils/use-title'

import { useBillingUserInfoQuery } from './operations/billing.generated'
import { useCreateBillingPortalSessionMutation } from './operations/portal-session.generated'
import { UpsellModalUpdaterContext } from '../../components/UpsellModal'
import PlanRow from './PlanRow'

function BillingManagement(): React.ReactElement {
  useTitle('Billing - Settings')

  const setModalOptions = React.useContext(UpsellModalUpdaterContext)
  const { data, loading } = useBillingUserInfoQuery()
  const account = data?.whoami?.account
  const plans = account?.organization.plans || []
  const amount = plans.reduce((s, p) => s + p.amount, 0)
  const firstPlanDue = plans.length ? plans.reduce((m, p) => (m.currentPeriodEnd < p.currentPeriodEnd ? m : p)) : null
  const inAppPlan = plans.find(p => p.stripeProducts?.some(s => s.isInApp))
  const inAppProduct = inAppPlan?.stripeProducts?.find(s => s.isInApp)

  const planData =
    inAppProduct && inAppPlan
      ? {
          name: inAppProduct.name,
          isActive: inAppPlan.isActive,
          priceInPennies: amount,
          nextPaymentDate: firstPlanDue?.currentPeriodEnd,
          postLimit: account?.organization.postLimit,
          userLimit: account?.organization.userLimit,
          socialAccountLimit: account?.organization.socialAccountLimit,
          segmentLimit: account?.organization.segmentLimit,
          labelLimit: account?.organization.labelLimit,
        }
      : null

  const [createSession] = useCreateBillingPortalSessionMutation()

  async function handleBillingClick() {
    const { data } = await createSession()
    const redirectLink = data?.createBillingPortalSession?.link
    if (redirectLink) {
      window.location.href = redirectLink
    }
  }

  function handlePlanClick() {
    setModalOptions({
      isOpen: true,
      modalProps: {
        context: { reason: 'BILLING' },
        onCancel: () => setModalOptions({ isOpen: false }),
      },
    })
  }

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" flex={1} mb={5}>
          <Typography variant="h5">Billing</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {loading && <PlanRow loading />}
          {!loading && planData && <PlanRow {...planData} />}
          {!loading && inAppPlan && (
            <Box display="flex" mt={8}>
              <Button variant="contained" color="primary" onClick={handleBillingClick}>
                Manage My Billing
              </Button>
            </Box>
          )}
          {!loading && !inAppPlan && (
            <Box display="flex" mt={8}>
              <Button variant="contained" color="primary" onClick={handlePlanClick}>
                Pick a Plan
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default BillingManagement
