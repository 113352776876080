import React, { useEffect } from 'react'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { createStyles, IconButton, makeStyles, Typography, Box, Button } from '@material-ui/core'
import { Field, useFormik, FormikProvider } from 'formik'
import { TextField } from 'formik-material-ui'
import clsx from 'clsx'
import * as yup from 'yup'
import { secondary } from '../../loudcrowd-theme'
import { RewardTypeEnum, CodesLevel, IgMediaPostType } from '../../gql-global'
import { useManualMessageFormDataQuery } from './operations/query-manual-message-form.generated'
import { useSendDirectMessageMutation } from './operations/send-direct-message.generated'
import RewardPicker from '../RewardPicker'
import MenuPicker from '../MenuPicker'
import { ReactComponent as MessageIcon } from '../../icons/message_icon_major.svg'
import { rewardName } from '../../utils/rewards'
import { isTypeName } from '../../types/utility'
import { CustomerActivitiesDocument } from '../../customer/operations/customer-activities.generated'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'

export type SendManualMessageFormFields = {
  message: string
  rewardId: string
  messageTemplateId: string
}

const useStyles = makeStyles(() =>
  createStyles({
    h7: {
      //MUI doesn't have a h7 whereas the mocks did
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    closeModal: {
      position: 'absolute',
      right: 10,
      top: 10,
    },
    counter: {
      color: secondary[600],
      position: 'absolute',
      right: 10,
      bottom: 10,
    },
    counterError: {
      bottom: 40,
    },
    textContainer: {
      position: 'relative',
      '& .MuiOutlinedInput-multiline': {
        paddingBottom: 36,
      },
      '& .MuiFormHelperText-root': {
        fontWeight: 600,
        fontSize: '0.75rem',
        marginTop: 10,
        marginLeft: 0,
      },
    },
    templateMessage: {
      fontSize: '0.75rem',
      fontWeight: 500,
      marginTop: 10,
    },
  }),
)

type Props = {
  socialAccountId: string
  username: string
  customerId: string
  onClose?: () => void
  onSuccess: () => void
  onError: () => void
  onMessageChange?: (message: string) => void
  isDisposable?: boolean
  mediaId?: string
}

const MAX_CHARACTERS = 900

const REWARD_CODE_TAG = '{{reward.code}}'

const LOADING_BATCH_SIZE = 12

const ManualMessageBuilder: React.FC<Props> = ({
  onClose,
  socialAccountId,
  customerId,
  username,
  onSuccess,
  onError,
  isDisposable,
  onMessageChange,
  mediaId,
}) => {
  const classes = useStyles()

  const { data, loading: dataLoading } = useManualMessageFormDataQuery({
    variables: {
      socialAccountId,
      where: {
        rewardType: {
          any: [RewardTypeEnum.Dm],
        },
      },
    },
  })

  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
  }

  const [sendDirectMessage] = useSendDirectMessageMutation({
    onError: () => {
      onError()
      onClose && onClose()
    },
    onCompleted(mutationData) {
      if (mutationData && mutationData?.sendDirectMessage?.ok === true) {
        onSuccess()
        onClose && onClose()
      } else {
        onError()
        onClose && onClose()
      }
    },
    refetchQueries: [
      {
        query: CustomerActivitiesDocument,
        variables: {
          id: customerId,
          socialAccountId: socialAccountId,
          limit: LOADING_BATCH_SIZE,
          activeStoryMentionsWhere,
        },
      },
      'PostUserRewardsHistory',
    ],
  })

  const onSubmit = async (fields: SendManualMessageFormFields) => {
    const { message, messageTemplateId, rewardId } = fields

    await sendDirectMessage({
      variables: {
        rewardId,
        customerId,
        messageTemplateId,
        mediaId,
        socialAccountId: socialAccountId || '',
        message: rewardId && !message.includes(REWARD_CODE_TAG) ? `${message}\n Code: ${REWARD_CODE_TAG}` : message,
      },
    })
  }

  const formik = useFormik<SendManualMessageFormFields>({
    initialValues: { message: '', rewardId: '', messageTemplateId: '' },
    validationSchema: yup.object().shape({
      message: yup.string().required('Unable to send message: Message is required'),
      rewardId: yup.string().when('message', {
        is: value => value?.includes(REWARD_CODE_TAG),
        then: yup.string().required(`Select a reward in order to use ${REWARD_CODE_TAG} tag`),
      }),
      messageTemplateId: yup.string(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  })

  useEffect(() => onMessageChange && onMessageChange(formik.values.message), [onMessageChange, formik.values.message])

  const messageTemplatesRaw =
    (data?.socialAccount &&
      isTypeName(data?.socialAccount, 'IGSocialAccount') &&
      data?.socialAccount?.messageTemplates?.results) ||
    []
  const messageTemplates = messageTemplatesRaw.map(m => ({ id: m.id.toString(), label: m.name })) || []
  const accountRewards = data?.whoami?.account?.rewards?.results
  const customerRewards = accountRewards?.map(reward => ({ id: reward.id.toString(), label: rewardName(reward) })) ?? []
  const rewardCodeEmpty =
    accountRewards?.find(reward => reward.id.toString() === formik.values.rewardId)?.stats.codesLevel ===
    CodesLevel.Empty
  const placeholder = isDisposable ? 'Write a new message' : `Reply to @${username}'s Instagram Story`

  const handleOnRewardChange = (rewardId: string) => {
    const { message } = formik.values
    let updatedMessage = message

    if (rewardId) {
      if (!message.includes(REWARD_CODE_TAG)) {
        updatedMessage = `${message ? `${message}\n` : message}Code: ${REWARD_CODE_TAG}`
      }
    } else {
      updatedMessage = message.replace(/(\n?Code: |)\{\{reward\.code\}\}/, '')
    }

    formik.setValues({ ...formik.values, rewardId, message: updatedMessage }, true)
  }

  const handleOnTemplateChange = (messageTemplateId: string) => {
    if (messageTemplateId) {
      const { template = '', reward } = messageTemplatesRaw.find(mt => mt.id === messageTemplateId) || {}
      const { id: rewardId } = reward || {}
      formik.setValues({ ...formik.values, messageTemplateId, message: template, rewardId: rewardId?.toString() ?? '' })
    } else {
      formik.setFieldValue('messageTemplateId', messageTemplateId)
    }
  }

  return (
    <FormikProvider value={formik}>
      <Box display="flex" justifyContent="space-between" height="100%" flexDirection="column">
        <Box p={isDisposable ? 9 : '12px 36px 12px 16px'} width={500}>
          {isDisposable && (
            <>
              <div className={classes.closeModal}>
                <IconButton onClick={onClose} size="small">
                  <CrossIcon width={16} height={16} />
                </IconButton>
              </div>
              <Typography className={classes.h7}>Reply to @{username}'s Instagram Story</Typography>
            </>
          )}
          <Box>
            <Box mt={6} className={classes.textContainer}>
              <Field
                name="message"
                component={TextField}
                placeholder={placeholder}
                inputProps={{ maxLength: MAX_CHARACTERS }}
                rows={6}
                fullWidth
                multiline
                variant="outlined"
              />
              <Typography
                variant="body2"
                align="right"
                className={clsx(classes.counter, { [classes.counterError]: formik.errors.message })}
              >
                {formik.values.message.length ?? 0}/{MAX_CHARACTERS}
              </Typography>
            </Box>
            {!formik.errors.message && formik.values.rewardId && (
              <Typography variant="body2" component="p" className={classes.templateMessage}>
                {`Use ${REWARD_CODE_TAG} to add the code anywhere in your message. If you remove it, we'll add the code in a new line at the end of your message.`}
              </Typography>
            )}
          </Box>
          <Box mt={4}>
            <MenuPicker
              option={{
                name: 'messageTemplateId',
                type: 'singleSelection',
                label: 'Message Template',
                selectionOptions: messageTemplates,
              }}
              adornmentIcon={<MessageIcon width={16} height={16} />}
              onChange={handleOnTemplateChange}
              disabled={dataLoading || !messageTemplates || messageTemplates.length === 0}
              value={formik.values.messageTemplateId}
              label="Start from template"
              labelIcon={<PlusIcon width={14} />}
              menuTitle="Messages Template"
              noOptionsMessage="No message templates available. Create them in Messages."
            />
          </Box>
          <Box mt={4}>
            <RewardPicker
              option={{
                name: 'rewardId',
                type: 'singleSelection',
                label: 'Program',
                selectionOptions: customerRewards,
              }}
              onChange={handleOnRewardChange}
              disabled={dataLoading || !customerRewards || customerRewards.length === 0}
              value={formik.values.rewardId}
              warning={
                rewardCodeEmpty ? 'Unable to send message: selected reward has no codes' : formik.errors.rewardId
              }
            />
          </Box>
        </Box>
        <Box p={9} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={rewardCodeEmpty || formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Send
          </Button>
        </Box>
      </Box>
    </FormikProvider>
  )
}

export default ManualMessageBuilder
