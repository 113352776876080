import React, { useState, useRef } from 'react'
import {
  Box,
  Typography,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Button,
  Card,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from '@material-ui/core'
import Switch from '../components/Switch'
import { ReactComponent as RecipientsIcon } from '../icons/people-outlined-cropped.svg'
import { ReactComponent as OptionsIcon } from '../icons/kebab.svg'
import { ReactComponent as PresentIcon } from '../icons/present.svg'
import { ReactComponent as TrashIcon } from '../icons/trash_can.svg'
import { ReactComponent as WarningIcon } from '../icons/warning-icon.svg'
import { ReactComponent as DangerIcon } from '../icons/danger-icon.svg'
import { ReactComponent as EditIcon } from '../icons/edit_pencil.svg'
import { secondary, primary, white } from '../loudcrowd-theme'
import { numberFormat } from '../utils/number-format'
import { encodeFilterParams } from './use-filter-params'
import { MESSAGE_ACTIVITY_ROUTE } from './routes'
import { Link } from 'react-router-dom'
import { CodesLevel } from '../gql-global'
import MenuHeader from '../components/MenuHeader'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import SingleSelectFilterMenu from '../components/SingleSelectionPicker'
import { SingleSelectionOption } from '../components/lists/ListFilters/types'
import { MessageTemplateFragmentFragment } from './operations/message-template-fragment.generated'
import { REWARD_MANAGEMENT_ROUTE } from '../settings/routes'
import { rewardName, MessageRewardType } from '../utils/rewards'
import { REWARD_CODE_TAG, REWARD_PREVIEW } from './builder/messageSchema'

interface StyledProps {
  isAutomationEnabled: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      minWidth: 170,
    },
    search: {
      ...theme.typography.body2,
      lineHeight: '150%',
    },
    searchInput: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: 21,
    },
    listItem: {
      color: theme.palette.text.secondary,
    },
    listItemIcon: {
      minWidth: 28,
      color: 'inherit',
    },
    button: {
      borderRadius: 8,
      letterSpacing: 2,
      color: primary[500],
      borderColor: primary[500],
      marginRight: 10,
    },
    buttonIcon: {
      marginTop: 2,
      marginRight: theme.spacing(2),
    },
    recipientsButtonIcon: {
      marginRight: theme.spacing(2),
    },
    automationCard: {
      width: 327,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: (props: StyledProps) =>
        props.isAutomationEnabled ? 'linear-gradient(180deg, #751BCF 0%, #B62586 49.48%, #EFAC59 100%)' : 'none',
      boxShadow: '0px 10px 20px rgba(37, 36, 103, 0.1)',
      border: (props: StyledProps) => (props.isAutomationEnabled ? 'none' : `1px solid ${secondary[400]}`),
      borderRadius: 20,
    },
    messageCard: {
      margin: (props: StyledProps) => (props.isAutomationEnabled ? 6 : 5),
      width: 318,
      borderRadius: 14,
      backgroundColor: white,
    },
    messageCardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px 24px',
    },
    messageCardToggle: {
      display: 'flex',
      flex: 'row',
      alignItems: 'center',
    },
    iconButton: {
      padding: 4,
    },
    messageCardBody: {
      height: 140,
      padding: '16px 24px',
    },
    ellipsisOverflow: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    messageText: {
      color: secondary[600],
      marginTop: 4,
    },
    messageCardTitleOn: {
      background: 'linear-gradient(90deg, #751BCF 0%, #B62586 49.48%, #EFAC59 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    messageCardTitleOff: {
      background: 'linear-gradient(90deg, #7C7C7C 0%, #00ACFF 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    messageCardFooter: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0px 8px 24px 24px',
    },
    options: {
      width: 27,
      height: 8,
    },
    autoLabel: {
      marginLeft: theme.spacing(2),
    },
  }),
)

interface MessageComponentProps {
  messageTemplate: MessageTemplateFragmentFragment
  accountRewards?: MessageRewardType[] | null
  hasReward: boolean
  totalRecipients: number
  codesTotal: number
  codesRemaining: number
  codesLevel: CodesLevel | null | undefined
  automationEnabled: boolean
  onUpdateMessageStatus: (checked: boolean, id: string, hasAutomation: boolean) => void
  onUpdateReward: (id: string, name: string, template: string, rewardId: string, automationEnabled: boolean) => void
  onDelete: (id: string) => void
  onEdit: (messageTemplate: MessageTemplateFragmentFragment) => void
  style?: {
    gridColumn: string
  }
}

function MessageComponent({
  messageTemplate,
  accountRewards,
  codesTotal,
  codesRemaining,
  codesLevel,
  totalRecipients,
  automationEnabled,
  style,
  onUpdateMessageStatus,
  onUpdateReward,
  onDelete,
  onEdit,
}: MessageComponentProps): React.ReactElement {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const [isRewardPickerOpen, setRewardPickerOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLButtonElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [searchText, setSearchText] = useState('')

  const id = messageTemplate.id
  const name = messageTemplate.name
  const template = messageTemplate.template
  const hasReward = !!messageTemplate.reward

  const rewardOptions =
    accountRewards?.map(r => ({
      id: r.id.toString(),
      label: rewardName(r),
    })) || []

  const filterOptions = rewardOptions.filter(o =>
    o.label
      .toLowerCase()
      .trim()
      .includes(searchText.toLowerCase().trim()),
  )

  const filterOption: SingleSelectionOption = {
    name: 'rewards',
    type: 'singleSelection',
    label: 'Reward',
    selectionOptions: filterOptions,
  }

  const classes = useStyles({ isAutomationEnabled: automationEnabled })
  const canDelete = totalRecipients === 0
  const isRewardButtonDisabled = !!(
    (!hasReward && !accountRewards) ||
    (!hasReward && accountRewards && accountRewards.length === 0)
  )

  function handleOnRewardClick() {
    if (!hasReward) {
      setRewardPickerOpen(true)
    }
  }

  function handleOnSelectValue(rewardId: string): void {
    onUpdateReward(id, name, template, rewardId, automationEnabled)
    setRewardPickerOpen(false)
  }

  const codesRemainingPercent = codesTotal ? Math.round((codesRemaining / codesTotal) * 100) : 0

  return (
    <Card className={`${classes.automationCard}`} style={style}>
      <Box className={classes.messageCard}>
        <Box className={classes.messageCardHeader}>
          <div className={classes.messageCardToggle}>
            <FormControlLabel
              label={
                <Typography className={classes.autoLabel} variant="subtitle2" color="primary">
                  AUTO {automationEnabled ? 'ON' : 'OFF'}
                </Typography>
              }
              labelPlacement="end"
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={automationEnabled}
                  disabled={!!messageTemplate.automation && codesLevel === CodesLevel.Empty}
                  onChange={value => onUpdateMessageStatus(value.target.checked, id, !!messageTemplate.automation)}
                />
              }
              style={{ marginLeft: 0 }}
            />
          </div>
          <IconButton ref={menuRef} onClick={() => setMenuOpen(true)} className={classes.iconButton}>
            <OptionsIcon className={classes.options} />
          </IconButton>
          <Menu
            open={isMenuOpen}
            anchorEl={menuRef.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            onClose={() => setMenuOpen(false)}
            classes={{ paper: classes.menuPaper }}
          >
            <MenuItem
              className={classes.listItem}
              onClick={() => {
                onEdit(messageTemplate)
                setMenuOpen(false)
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon />
              </ListItemIcon>
              <ListItemText disableTypography>Edit</ListItemText>
            </MenuItem>
            <MenuItem className={classes.listItem} onClick={() => onDelete(id)} disabled={!canDelete}>
              <ListItemIcon className={classes.listItemIcon}>
                <TrashIcon />
              </ListItemIcon>
              <ListItemText disableTypography>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Box className={classes.messageCardBody}>
          <Box>
            <Box>
              <Typography
                variant="h6"
                className={`${classes.ellipsisOverflow} ${
                  automationEnabled ? classes.messageCardTitleOn : classes.messageCardTitleOff
                }`}
                style={{ WebkitLineClamp: 2 }}
              >
                {name}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                className={`${classes.ellipsisOverflow} ${classes.messageText}`}
                style={{ WebkitLineClamp: name.length >= 28 ? 2 : 3 }}
              >
                {template.replace(REWARD_CODE_TAG, REWARD_PREVIEW)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.messageCardFooter}>
          <Tooltip title={`${codesRemaining} of ${codesTotal} left`} placement="top">
            {hasReward ? (
              <Button
                variant="outlined"
                className={classes.button}
                component={Link}
                to={{
                  pathname: REWARD_MANAGEMENT_ROUTE.path,
                }}
              >
                <>
                  <PresentIcon height={20} width={20} className={classes.buttonIcon} />
                  <Typography variant="body2">{`${codesRemainingPercent}% left`}</Typography>
                  {codesLevel === CodesLevel.Empty && <DangerIcon height={13} width={13} style={{ marginLeft: 4 }} />}
                  {codesLevel === CodesLevel.Low && <WarningIcon height={13} width={13} style={{ marginLeft: 4 }} />}
                </>
              </Button>
            ) : (
              <span>
                <Button
                  variant="outlined"
                  ref={buttonRef}
                  className={classes.button}
                  disabled={isRewardButtonDisabled}
                  onClick={handleOnRewardClick}
                >
                  <PresentIcon height={20} width={20} className={classes.buttonIcon} />
                  <Typography variant="body2">Add Reward</Typography>
                </Button>
              </span>
            )}
          </Tooltip>
          <Button
            variant="outlined"
            className={classes.button}
            to={{
              pathname: MESSAGE_ACTIVITY_ROUTE.path,
              search: `?${encodeFilterParams({
                messageTemplate: { any: [id] },
              })}`,
            }}
            component={Link}
          >
            <RecipientsIcon height={24} width={24} className={classes.recipientsButtonIcon} />
            <Typography variant="body2">{numberFormat(totalRecipients)}</Typography>
          </Button>
        </Box>
        <Menu
          open={isRewardPickerOpen}
          anchorEl={buttonRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={() => setRewardPickerOpen(false)}
        >
          <MenuHeader title="DM Rewards" />
          <Box marginX={5}>
            <OutlinedInput
              classes={{ root: classes.search, input: classes.searchInput }}
              type="text"
              autoFocus
              fullWidth
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon width={16} height={16} />
                </InputAdornment>
              }
            />
          </Box>
          <SingleSelectFilterMenu option={filterOption} onSelectValue={handleOnSelectValue} />
        </Menu>
      </Box>
    </Card>
  )
}

export default MessageComponent
