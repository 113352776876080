import React, { createContext, CSSProperties, FC, useContext, useMemo } from 'react'
import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { createStyles, makeStyles } from '@material-ui/core'
import { ReactComponent as DraggableHandle } from './../../../icons/draggable-handle.svg'

interface Context {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ref() {},
})

interface SortableItemContainerProps {
  id: string
  children: React.ReactNode
  style?: CSSProperties
}

const useStyles = makeStyles(() =>
  createStyles({
    sortableHandle: {
      listStyle: 'none',
    },
    dragHandle: {
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'grab',
      padding: '5px',
      borderRadius: '25%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export const SortableItemContainer: FC<SortableItemContainerProps> = ({ children, id, style }) => {
  const classes = useStyles()
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  })

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  )

  const dndStyle: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <SortableItemContext.Provider value={context}>
      <li className={classes.sortableHandle} ref={setNodeRef} style={{ ...style, ...dndStyle }}>
        {children}
      </li>
    </SortableItemContext.Provider>
  )
}

export const DragHandle = () => {
  const classes = useStyles()
  const { attributes, listeners, ref } = useContext(SortableItemContext)
  return (
    <button className={classes.dragHandle} {...attributes} {...listeners} ref={ref}>
      <DraggableHandle />
    </button>
  )
}
