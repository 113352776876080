import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type TtChallengeSaFragment = { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string }, programs?: Array<{ __typename: 'ProgramType', id: string, name: string }> | null };

export const TtChallengeSaFragmentDoc = gql`
    fragment TTChallengeSA on TTSocialAccount {
  id
  socialUser {
    id
    username
  }
  programs {
    id
    name
  }
}
    `;