import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyncSubscriptionsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SyncSubscriptionsMutation = { __typename: 'Mutations', syncSubscriptions?: { __typename: 'SyncSubscriptions', ok?: boolean | null, organization?: { __typename: 'Organization', id: string, accounts: Array<{ __typename: 'AccountType', id: number }> } | null } | null };


export const SyncSubscriptionsDocument = gql`
    mutation SyncSubscriptions {
  syncSubscriptions {
    ok
    organization {
      id
      accounts {
        id
      }
    }
  }
}
    `;
export type SyncSubscriptionsMutationFn = Apollo.MutationFunction<SyncSubscriptionsMutation, SyncSubscriptionsMutationVariables>;

/**
 * __useSyncSubscriptionsMutation__
 *
 * To run a mutation, you first call `useSyncSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSubscriptionsMutation, { data, loading, error }] = useSyncSubscriptionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<SyncSubscriptionsMutation, SyncSubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncSubscriptionsMutation, SyncSubscriptionsMutationVariables>(SyncSubscriptionsDocument, options);
      }
export type SyncSubscriptionsMutationHookResult = ReturnType<typeof useSyncSubscriptionsMutation>;
export type SyncSubscriptionsMutationResult = Apollo.MutationResult<SyncSubscriptionsMutation>;
export type SyncSubscriptionsMutationOptions = Apollo.BaseMutationOptions<SyncSubscriptionsMutation, SyncSubscriptionsMutationVariables>;