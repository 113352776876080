import React, { useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Tooltip,
  Dialog,
} from '@material-ui/core'
import labelsCacheUpdate, { clearSocialFieldsFilteringBySegments } from '../mutations/labels-cache-update'
import { ApolloQueryResult, isApolloError } from '@apollo/client'
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import RouterTabs, { RouterTab } from '../components/RouterTabs'
import { useCustomerDetailUserDataQuery } from './operations/customer-detail-user-data.generated'
import { CustomerQuery, CustomerQueryVariables, useCustomerQuery } from './operations/customer.generated'
import { useAddSegmentSingleCustomerMutation } from './operations/add-segment-single-customer.generated'
import { useAddToProgramMutation } from './operations/add-to-program.generated'
import { useCreateSegmentForSingleCustomerMutation } from './operations/create-segment-single-customer.generated'
import { useRemoveCustomerSegmentMutation } from './operations/remove-customer-segment.generated'
import { useUpdateSegmentMutation } from '../mutations/operations/update-segment.generated'
import { useDeleteSegmentMutation } from '../mutations/operations/delete-segment.generated'
import { ReactComponent as ArrowIcon } from '../icons/arrow-skinny.svg'
import { ReactComponent as PresentIcon } from '../icons/present.svg'
import { ReactComponent as TrophyIcon } from '../icons/trophy.svg'
import { ReactComponent as MessageMajorIcon } from '../icons/message_icon_major.svg'
import { Skeleton } from '@material-ui/lab'
import CustomerDetailPosts from './CustomerDetailPosts'
import CustomerDetailNotes from './CustomerDetailNotes'
import Page from '../Page'
import LabelMenu from '../components/LabelMenu'
import ContainerError from '../components/ContainerError'
import useTitle from '../utils/use-title'
import { CustomerDetailRouteParams } from './routes'
import CustomerDetailActivityFeed, { LOADING_BATCH_SIZE as ACTIVITIES_BATCH_SIZE } from './CustomerDetailActivityFeed'
import AddToProgramDialog from './AddToProgramDialog'
import { UpdateCustomerMutationVariables, useUpdateCustomerMutation } from './operations/update-customer.generated'
import SendRewardDialog from '../components/SendRewardDialog/SendRewardDialog'
import { RewardRowFragment } from '../components/RewardList/operations/rewards.generated'
import ActionMenu, { ActionType } from '../components/ActionsMenu/ActionsMenu'
import { useSendRewardMutation } from './operations/send-reward.generated'
import {
  AccountProductName,
  CustomFieldEnumType,
  CustomFieldInput,
  FbUser,
  CustomerFieldInput,
  CustomerFieldEnumType,
  IgMediaPostType,
  EcommDiscountCodeTypeEnum,
  Exact,
  InputMaybe,
  CustomerMentionStatsFilters,
  CustomerExternalIdInput,
} from '../gql-global'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import { useCustomerCampaignsQuery } from './operations/customer-campaigns.generated'
import useIntercomOnPage from '../hooks/useIntercomOnPage'
import CustomerDetailInfo, { ProgramAffiliateDiscountCode } from './CustomerDetailInfo'
import EditDialog from '../components/EditDialog/EditDialog'
import { isTypeName } from '../types/utility'
import ManualMessageBuilder from '../components/ManualMessageBuilder'
import { sendRewardAndHandleResult } from '../utils/rewards'
import { useToast } from '../components/Alert/ToastProvider'
import ChallengeRewardPicker from '../components/ChallengeRewardPicker'
import AffiliateCodeDialog from './AffiliateCodeDialog'
import { SEGMENT_MANAGEMENT_ROUTE } from '../settings/routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    h7: {
      //MUI doesn't have a h7 whereas the mocks did
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    bold: {
      fontWeight: 600,
    },
    tooltipTitle: {
      fontWeight: 800,
    },
    backButton: {
      marginRight: theme.spacing(5),
    },
    bioBox: {
      overflowX: 'hidden',
      width: '100%',
    },
    igStat: {
      minWidth: 18,
      marginRight: 4,
    },
    emailEditButton: {
      display: 'none',
    },
    emailCell: {
      '&:hover $emailEditButton': {
        display: 'inline',
      },
    },
    email: {
      display: 'inline-block',
    },

    statsPaper: {
      height: 110,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(5.5),
    },
    statsPaperItem: {
      '& + &': {
        marginLeft: theme.spacing(4),
      },
    },
    segmentsPaper: {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(7),
      paddingBottom: theme.spacing(7),
      paddingLeft: theme.spacing(7),
    },
    emptySegments: {
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '114px',
    },
    bioText: {
      wordWrap: 'break-word',
    },
    externalUrl: {
      wordWrap: 'break-word',
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    usernameLinkIcon: {
      display: 'none',
    },
    usernameLink: {
      display: 'flex',
      alignItems: 'center',

      '&:hover $usernameLinkIcon': {
        display: 'inline',
      },
    },
    pickWinnerButton: {
      background: 'linear-gradient(132.33deg, #FFBA3B 5.67%, #FF68DE 40.59%, #00ACFF 86.44%)',
      color: 'white',
      '&:hover': {
        background: 'linear-gradient(132.33deg, #EFAF38 5.67%, #FF35D3 40.59%, #0499E1 86.44%)',
      },
    },
  }),
)

const CustomerDetail: React.FC = () => {
  useIntercomOnPage('CustomerDetail')
  const { path, url } = useRouteMatch()
  const classes = useStyles()
  const history = useHistory()
  const { showToast } = useToast()
  const [segmentsEditorOpen, setSegmentsEditorOpen] = useState(false)
  const [editingEmail, setEditingEmail] = useState<boolean>(false)
  const [editingPhone, setEditingPhone] = useState<boolean>(false)
  const [editingTiktok, setEditingTiktok] = useState<boolean>(false)
  const [editingCustomFields, setEditingCustomFields] = useState<boolean>(false)
  const [affiliateCodeDialogState, setAffiliateCodeDialogState] = useState<{
    open: boolean
    programs: ProgramAffiliateDiscountCode[] | null
    selectedProgram: ProgramAffiliateDiscountCode | null
    selectedRedeemCode: { code: string; ecommDiscountRedeemCodeId: string } | null
  }>({ open: false, programs: [], selectedProgram: null, selectedRedeemCode: null })
  const [sendRewardOpen, setSendRewardOpen] = useState(false)
  const [addToProgramOpen, setAddToProgramOpen] = useState<boolean>(false)
  const [messageOpen, setMessageOpen] = useState<boolean>(false)
  const [pickWinnerOpen, setPickWinnerOpen] = useState<boolean>(false)

  const { id } = useParams<CustomerDetailRouteParams>()
  const { data: userData, loading: userDataLoading, error: userDataError } = useCustomerDetailUserDataQuery()
  const { roles } = userData?.whoami || {}
  const socialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
  }
  const customerQueryVariables: CustomerQueryVariables = {
    socialAccountId: socialAccountId || '',
    id,
    activeStoryMentionsWhere,
  }
  const locationState: { entity?: string } = history.location.state || {}
  const {
    data,
    loading: customerLoading,
    refetch: customerRefetch,
    error,
  } = useCustomerQuery({
    skip: !socialAccountId,
    variables: customerQueryVariables,
    fetchPolicy: locationState?.entity === 'shoppableParticipant' ? 'network-only' : 'cache-first',
  })
  const account = userData?.whoami?.account
  const accountCustomFields = account?.customFields
    ? account?.customFields?.filter(acf => {
        const filteredTypes = [
          CustomFieldEnumType.Tiktok,
          CustomFieldEnumType.Address,
          CustomFieldEnumType.Address_2,
          CustomFieldEnumType.City,
          CustomFieldEnumType.State,
          CustomFieldEnumType.Country,
          CustomFieldEnumType.ZipCode,
        ]
        return !filteredTypes.includes(acf.type)
      })
    : null

  const hasCampaigns = userData?.whoami?.account?.organization.activeProducts.some(
    p => p === AccountProductName.Campaign,
  )

  const customerSocialAccountType =
    data?.socialAccount && isTypeName(data.socialAccount, 'IGSocialAccount') ? 'instagram' : 'tiktok'

  const customer = data?.socialAccount?.customer
  const customerSocialUser = customerSocialAccountType === 'instagram' ? customer?.igUser : customer?.ttUser
  const customerUsername = customerSocialUser?.username || ''

  const hasActiveIGStories =
    customerSocialAccountType === 'instagram' && !!customer?.activeStoryMentionsStats?.postCount

  const { data: campaignData } = useCustomerCampaignsQuery({
    skip: !socialAccountId || !hasCampaigns,
    variables: {
      socialAccountId: socialAccountId || '',
    },
  })

  const [addSegment] = useAddSegmentSingleCustomerMutation({
    update(cache, result): void {
      labelsCacheUpdate(cache, result)
      clearSocialFieldsFilteringBySegments(cache, { fields: ['customers'], mentionsToo: false })
    },
    refetchQueries: ['CustomerActivities'],
  })
  const [createSegment] = useCreateSegmentForSingleCustomerMutation({
    update: labelsCacheUpdate,
  })
  const [removeCustomerSegment] = useRemoveCustomerSegmentMutation({
    update(cache): void {
      clearSocialFieldsFilteringBySegments(cache, { fields: ['customers'], mentionsToo: false })
    },
    refetchQueries: ['CustomerActivities'],
  })
  const [updateSegment] = useUpdateSegmentMutation({ refetchQueries: ['CustomerActivities'] })
  const [deleteSegment] = useDeleteSegmentMutation({
    update: labelsCacheUpdate,
    refetchQueries: ['CustomerActivities'],
  })

  const [updateCustomer] = useUpdateCustomerMutation()
  const [sendReward] = useSendRewardMutation()
  const [addToProgram] = useAddToProgramMutation()

  const loading = userDataLoading || customerLoading
  const breadcrumbTitle =
    customerUsername && customerUsername.length > 0 ? `${customerUsername} - Customers` : 'Customers'
  useTitle(breadcrumbTitle)

  const segments = customer?.segments || []
  const handleOnRemoveSegment = (id: number): void => {
    if (!customer) {
      return
    }
    removeCustomerSegment({
      variables: {
        segmentId: id.toString(),
        customerId: customer.id.toString(),
      },
    }).catch(() =>
      showToast({
        title: 'Error: Removing Segment',
        message: 'Something went wrong when removing this segment. Please try again.',
      }),
    )
  }

  const onAddSegment = (id: number): void => {
    setSegmentsEditorOpen(false)
    if (!customer) {
      return
    }
    addSegment({
      variables: {
        customerId: customer.id.toString(),
        segmentId: id.toString(),
      },
    }).catch(() =>
      showToast({
        title: 'Error: Adding Segment',
        message: 'Something went wrong when adding this segment to the customer. Please try again.',
      }),
    )
  }

  const onCreateSegment = (name: string): void => {
    setSegmentsEditorOpen(false)
    if (!customer) {
      return
    }
    createSegment({
      variables: {
        customerId: customer.id.toString(),
        name,
      },
    }).catch(() => {
      showToast({
        title: 'Error: Creating Segment',
        message: 'Something went wrong when creating this label. Please try again.',
      })
    })
  }

  const onUpdateSegment = (id: number, name: string): void => {
    updateSegment({
      variables: {
        id: id.toString(),
        name,
      },
    }).catch(e => {
      let message = 'Something went wrong when updating this segment. Please try again.'
      if (isApolloError(e) && e.graphQLErrors?.some(e => e.extensions?.code === 'DUPLICATE_KEY')) {
        message = 'Segment with this name already exists; please use that segment or pick a different name.'
      }
      showToast({ title: 'Error: Updating Segment', message })
    })
  }

  const onDeleteSegment = (id: number): void => {
    deleteSegment({
      variables: {
        segmentId: id.toString(),
      },
    }).catch(() => {
      showToast({
        title: 'Error: Deleting Segment',
        message: 'Something went wrong while deleting this segment. Please try again.',
      })
    })
  }

  const onUpdateEmailSave = (email: string) => {
    const variables: UpdateCustomerMutationVariables = {
      customerId: customer?.id.toString() || '',
      customerFields: [{ type: CustomerFieldEnumType.Email, value: email }],
    }
    updateCustomer({ variables: variables })
      .then(() => {
        showToast({
          title: 'Success: Updated Customer',
          message: `Updated email for customer ${customerUsername || ''} `,
          severity: 'success',
          autoHideDuration: 5000,
        })
        setEditingEmail(false)
      })
      .catch(() => {
        showToast({
          title: 'Error: Updating Customer',
          message: 'Something went wrong while updating the customer email. Please try again.',
        })
      })
  }

  const onUpdatePhoneSave = (phone: string) => {
    const variables: UpdateCustomerMutationVariables = {
      customerId: customer?.id.toString() || '',
      customerFields: [{ type: CustomerFieldEnumType.Phone, value: phone }],
    }
    updateCustomer({ variables: variables })
      .then(() => {
        showToast({
          title: 'Success: Updated Customer',
          message: `Updated phone for customer ${customerUsername || ''} `,
          severity: 'success',
          autoHideDuration: 5000,
        })
        setEditingPhone(false)
      })
      .catch(() => {
        showToast({
          title: 'Error: Updating Customer',
          message: 'Something went wrong while updating the customer phone number. Please try again.',
        })
      })
  }

  const onUpdateTiktokSave = (tiktokUsername: string) => {
    const variables: UpdateCustomerMutationVariables = {
      customerId: customer?.id.toString() || '',
      customerFields: [{ type: CustomerFieldEnumType.Tiktok, value: tiktokUsername }],
    }
    updateCustomer({ variables: variables })
      .then(() => {
        showToast({
          title: 'Success: Updated Customer',
          message: `Updated tiktok username for customer ${customerUsername || ''} `,
          severity: 'success',
          autoHideDuration: 5000,
        })
        setEditingTiktok(false)
      })
      .catch(() => {
        showToast({
          title: 'Error: Updating Customer',
          message: 'Something went wrong while updating the customer tiktok username. Please try again.',
        })
      })
  }

  function handleOnDetailsFormSubmit(
    customFields: CustomFieldInput[],
    customerFields: CustomerFieldInput[],
    externalIds: CustomerExternalIdInput[],
  ) {
    updateCustomer({
      variables: {
        customerId: customer?.id.toString() || '',
        customFields,
        customerFields,
        externalIdentifiers: externalIds,
      },
    })
      .then(() => {
        showToast({
          title: 'Success: Updated Customer Details',
          message: `Successfully updated customer details for ${customerUsername || ''}`,
          severity: 'success',
          autoHideDuration: 5000,
        })
        setEditingCustomFields(false)
      })
      .catch(() => {
        showToast({
          title: 'Error: Updating Customer',
          message: 'Something went wrong while updating the customer. Please try again.',
        })
      })
  }

  function handleAddParticipant(campaignId: number, email?: string | null) {
    if (!customer) {
      return
    }
    const customerId = customer?.id?.toString()
    addToProgram({
      update(cache): void {
        const cacheKey = cache.identify({ __typename: 'CampaignType', id: campaignId })
        for (const field of [
          'mentionStats',
          'mentions',
          'participantStats',
          'participantStatsTimeseries',
          'participants',
        ]) {
          cache.evict({
            id: cacheKey,
            fieldName: field,
          })
        }
        cache.gc()
      },
      variables: {
        customerId,
        campaignId: campaignId.toString(),
        email: email,
      },
    })
      .then(() =>
        showToast({
          title: 'Success: Added Member',
          message: `Added member ${customerUsername}`,
          severity: 'success',
          autoHideDuration: 5000,
        }),
      )
      .catch(error => {
        let message = 'Something went wrong when adding a member, please try again.'
        if (isApolloError(error) && error.graphQLErrors.some(e => e.extensions?.code === 'DUPLICATE_KEY')) {
          message = 'Member with that email or username already exists in the campaign.'
        }
        showToast({ title: 'Error: Adding Member', message })
      })
  }

  async function handleSendReward(reward: RewardRowFragment, offline: boolean): Promise<void> {
    setSendRewardOpen(false)
    await sendRewardAndHandleResult(
      reward,
      sendReward,
      {
        rewardId: reward.id.toString(),
        customerId: id,
        offlineDelivery: offline,
        socialAccountId: socialAccountId || '',
        limit: ACTIVITIES_BATCH_SIZE,
      },
      offline,
      showToast,
    )
  }

  async function handleSendChallengeReward(
    reward: RewardRowFragment,
    challengeId: string,
    offline: boolean,
  ): Promise<void> {
    setPickWinnerOpen(false)
    await sendRewardAndHandleResult(
      reward,
      sendReward,
      {
        rewardId: reward.id.toString(),
        customerId: id,
        offlineDelivery: offline,
        socialAccountId: socialAccountId || '',
        limit: ACTIVITIES_BATCH_SIZE,
        challengeId: challengeId,
      },
      offline,
      showToast,
      ['CustomerActivities'],
    )
  }

  function handleOnDMSend() {
    if (customer) {
      showToast({
        title: 'Success: Direct Message sent',
        message: `Successfully Direct Message sent to @${customerUsername}`,
        severity: 'success',
        autoHideDuration: 5000,
      })
      history.push(`${path}/activity`.replace(':id', customer.id.toString()))
    }
  }

  const toggleEditingEmailDialog = () => setEditingEmail(!editingEmail)
  const toggleEditingPhoneDialog = () => setEditingPhone(!editingPhone)
  const toggleEditingTiktokDialog = () => setEditingTiktok(!editingTiktok)
  const toggleEditingCustomFieldsDialog = () => setEditingCustomFields(!editingCustomFields)
  const toggleEditingAffiliateCodeDialog = (
    programsWithMissingCodes: ProgramAffiliateDiscountCode[] | null = null,
    selectedProgram: ProgramAffiliateDiscountCode | null = null,
    selectedRedeemCode: { code: string; ecommDiscountRedeemCodeId: string } | null = null,
  ) => {
    setAffiliateCodeDialogState({
      open: !affiliateCodeDialogState.open,
      programs: programsWithMissingCodes,
      selectedProgram: selectedProgram,
      selectedRedeemCode: selectedRedeemCode,
    })
  }

  const roleNames = roles?.map(r => r.name) || []
  const canModifySegments = roleNames.some(r => r === 'ADMIN' || r === 'OWNER')
  const hasHitLimit = !!userData?.whoami?.account?.organization.segmentLimit.hasHitLimit
  const hasError = userDataError || error
  const notFound = !loading && !hasError && !customer
  const customerFbUsers: FbUser[] = []
  customer?.igUser?.storiesIgSocialAccounts?.forEach(s => {
    if (s.fbUsers) {
      customerFbUsers.push(...s.fbUsers)
    }
  })

  const programArray = campaignData?.socialAccount?.campaigns?.map(c => ({
    // Creating a new array with the required program details
    campaignId: c.id || -1,
    programId: c.program?.id || '',
    name: c.program?.name || '',
  }))
  const activeProgramIds = customer?.programParticipants.map(pp => pp.program.id) || []
  const programsWithAffiliateCodesArray: ProgramAffiliateDiscountCode[] =
    campaignData?.socialAccount?.campaigns?.reduce((result: ProgramAffiliateDiscountCode[], campaign) => {
      const program = campaign.program
      if (program && activeProgramIds.includes(program.id) && program.ecommDiscountCodes?.length) {
        const affiliateDiscountCode = program.ecommDiscountCodes.find(
          d => d.codeType === EcommDiscountCodeTypeEnum.Affiliate && (!d.endsAt || d.endsAt >= new Date()),
        )
        if (affiliateDiscountCode) {
          result.push({
            program: { id: program.id, name: program.name },
            ecommDiscountCodeId: affiliateDiscountCode.id,
          })
        }
      }
      return result
    }, []) || []
  const hasPrograms = !!programArray?.length

  let messageTooltip = undefined
  if (customerSocialAccountType === 'tiktok') {
    messageTooltip = 'Messages are not yet available for TikTok. Select an Instagram account.'
  } else if (!hasActiveIGStories) {
    messageTooltip = 'Customer does not have an active story.'
  }

  const rewardableChallenges = data?.socialAccount?.customer?.challenges?.map(challenge => challenge.id) || []

  const showPickWinner = !!rewardableChallenges.length
  return (
    <Page>
      {!hasError && !notFound && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <IconButton color="secondary" className={classes.backButton} onClick={() => history.goBack()}>
                    <ArrowIcon width={20} height={16} />
                  </IconButton>
                  <Typography variant="h5">
                    {loading ? <Skeleton variant="text" width={150} /> : customerUsername}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Box mr={2}>
                    {messageTooltip ? (
                      <Tooltip
                        title={
                          <Box display="flex" flexDirection="column">
                            <span className={classes.tooltipTitle}>Unable to send a message:</span>
                            {'\n'}
                            <span>{messageTooltip}</span>
                          </Box>
                        }
                        placement="top-end"
                      >
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MessageMajorIcon width={20} />}
                            disabled
                          >
                            Message
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setMessageOpen(true)}
                        startIcon={<MessageMajorIcon width={20} />}
                      >
                        Message
                      </Button>
                    )}
                  </Box>
                  {showPickWinner && (
                    <Box mr={2}>
                      <Button
                        variant="contained"
                        className={classes.pickWinnerButton}
                        disabled={loading}
                        onClick={() => setPickWinnerOpen(true)}
                        startIcon={<TrophyIcon width={20} />}
                      >
                        Pick Winner
                      </Button>
                    </Box>
                  )}
                  <Box mr={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={() => setSendRewardOpen(true)}
                      startIcon={<PresentIcon width={20} />}
                    >
                      Send Gift
                    </Button>
                  </Box>
                  <Box>
                    {hasCampaigns ? (
                      <ActionMenu
                        disabled={loading}
                        isMenuItemDisabled={(action: ActionType) => action.action === 'program' && !hasPrograms}
                        actions={[
                          {
                            action: 'program',
                            icon: <PlusIcon width={16} />,
                            label: 'Program',
                            actionCallback: () => setAddToProgramOpen(true),
                          },
                          {
                            action: 'segment',
                            icon: <PlusIcon width={16} />,
                            label: 'Segment',
                            actionCallback: () => setSegmentsEditorOpen(true),
                          },
                        ]}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={loading}
                        onClick={() => setSegmentsEditorOpen(true)}
                      >
                        Add to Segment
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomerDetailInfo
                socialAccountId={socialAccountId || ''}
                customerQueryVariables={customerQueryVariables}
                accountCustomFields={accountCustomFields}
                customerIdIntegrations={account?.integrations || []}
                loading={loading}
                hasActiveStory={hasActiveIGStories}
                customer={customer}
                socialAccountType={customerSocialAccountType}
                fbUsers={customerFbUsers}
                setSegmentEditor={() => setSegmentsEditorOpen(true)}
                editEmail={toggleEditingEmailDialog}
                editPhone={toggleEditingPhoneDialog}
                editTiktok={toggleEditingTiktokDialog}
                editAffiliateCodes={toggleEditingAffiliateCodeDialog}
                editCustomFieldsToggle={toggleEditingCustomFieldsDialog}
                editingCustomFields={editingCustomFields}
                onDetailsFormSubmit={handleOnDetailsFormSubmit}
                onRemoveSegment={handleOnRemoveSegment}
                programAffiliateDiscountCodes={programsWithAffiliateCodesArray}
                discountCodes={customer?.programParticipants.flatMap(pp => pp.affiliateDiscountCodes || []) || []}
                shoppableAmbassadorUrls={
                  customer?.programParticipants
                    ?.map(pp => pp.shoppableAmbassadorLink)
                    .filter((u: string | null | undefined): u is string => !!u) || []
                }
                customerRefetch={customerRefetch}
                campaignsData={campaignData}
              />
            </Grid>
            <Grid item xs={12}>
              <RouterTabs className={classes.tabs} indicatorColor="primary" textColor="primary">
                <RouterTab label="Posts" value={url} replace />
                <RouterTab label="Activity" value={`${url}/activity`} replace />
                <RouterTab label="Notes" value={`${url}/notes`} replace />
              </RouterTabs>
              <Switch>
                <Route
                  exact
                  path={path}
                  render={() => (
                    <Box>
                      <CustomerDetailPosts />
                    </Box>
                  )}
                />
                <Route
                  exact
                  path={`${path}/activity`}
                  render={() => (
                    <Box mt={8}>
                      <CustomerDetailActivityFeed
                        selectedSocialAccountId={socialAccountId}
                        userDataLoading={userDataLoading}
                        userDataError={userDataError}
                        customerUsername={customerUsername}
                      />
                    </Box>
                  )}
                />
                <Route
                  exact
                  path={`${path}/notes`}
                  render={() => (
                    <Box mt={8}>
                      <CustomerDetailNotes
                        userEmail={userData?.whoami?.email}
                        userDataLoading={userDataLoading}
                        userDataError={userDataError}
                        selectedSocialAccountId={socialAccountId}
                      />
                    </Box>
                  )}
                />
              </Switch>
            </Grid>
          </Grid>
          <LabelMenu
            entity="segment"
            seeAllLink={SEGMENT_MANAGEMENT_ROUTE.path}
            open={segmentsEditorOpen}
            variant="dialog"
            labels={userData?.whoami?.account?.segments?.results || []}
            onSelect={id => onAddSegment(id)}
            onCreate={onCreateSegment}
            onUpdate={onUpdateSegment}
            onDelete={onDeleteSegment}
            hasHitLimit={hasHitLimit}
            onCancel={() => setSegmentsEditorOpen(false)}
            selectedLabelIds={new Set(segments.map(s => s.id))}
            editable
            allowedActions={canModifySegments ? ['update', 'delete'] : []}
          />
          {customer && (
            <>
              <EditDialog
                required
                val={customer.email}
                title="Email Address"
                type="Email"
                open={editingEmail}
                onClose={toggleEditingEmailDialog}
                onSave={onUpdateEmailSave}
              />
              <EditDialog
                val={customer.phone}
                title="Phone"
                type="Phone"
                open={editingPhone}
                onClose={toggleEditingPhoneDialog}
                onSave={onUpdatePhoneSave}
              />
              <EditDialog
                required
                val={customer.ttUser?.username}
                title="TikTok"
                type="TikTok"
                open={editingTiktok}
                onClose={toggleEditingTiktokDialog}
                onSave={onUpdateTiktokSave}
              />
              <AffiliateCodeDialog
                open={affiliateCodeDialogState.open}
                onClose={() => {
                  setAffiliateCodeDialogState({
                    open: false,
                    programs: [],
                    selectedProgram: null,
                    selectedRedeemCode: null,
                  })
                }}
                programArr={affiliateCodeDialogState.programs}
                selectedProgram={affiliateCodeDialogState.selectedProgram}
                selectedRedeemCode={affiliateCodeDialogState.selectedRedeemCode}
                customerId={customer.lcId}
                customerRefetch={customerRefetch}
              />
              <AddToProgramDialog
                platform={customerSocialAccountType}
                open={addToProgramOpen}
                onClose={() => setAddToProgramOpen(false)}
                campaigns={programArray}
                customer={customer}
                onSave={handleAddParticipant}
              />
            </>
          )}
        </>
      )}
      {hasError && <ContainerError text="Error loading customer" />}
      {notFound && <ContainerError text="Could not find customer." />}
      <SendRewardDialog
        open={sendRewardOpen}
        onClose={() => setSendRewardOpen(false)}
        onSendReward={handleSendReward}
        canCopyCode
        canAutoDeliver={!!customer?.email}
      />
      {socialAccountId && (
        <Dialog
          open={messageOpen}
          onClose={setMessageOpen}
          aria-labelledby="message-title"
          aria-describedby="message-title"
        >
          <ManualMessageBuilder
            isDisposable
            socialAccountId={socialAccountId}
            onClose={() => setMessageOpen(false)}
            username={customerUsername}
            customerId={customer?.id?.toString() || ''}
            onSuccess={handleOnDMSend}
            onError={() => {
              showToast({
                title: 'Error: Sending Direct Message',
                message: 'Something went wrong when when sending this message, please try again',
                severity: 'error',
              })
            }}
          />
        </Dialog>
      )}
      {showPickWinner && (
        <Dialog open={pickWinnerOpen} onClose={() => setPickWinnerOpen(false)} maxWidth={false}>
          <Box ml={4} width={520} height={600}>
            <ChallengeRewardPicker
              width={520}
              height={600}
              challenges={rewardableChallenges}
              onSendReward={handleSendChallengeReward}
            />
          </Box>
        </Dialog>
      )}
    </Page>
  )
}
export type CustomerRefetchType = (
  variables?:
    | Partial<
        Exact<{
          activeStoryMentionsWhere?: InputMaybe<CustomerMentionStatsFilters> | undefined
          id: string
          socialAccountId: string
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<CustomerQuery>>
export default CustomerDetail
