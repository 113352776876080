import React, { useContext, useRef } from 'react'
import { Box, Button, Dialog, DialogProps, Typography, IconButton } from '@material-ui/core'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { UpsellModalStateContext, Limits, ModalStateProps } from './context'
import growthPhoto from '../../images/ferris-wheel-portrait.png'
import { usePricingQuery } from './operations/pricing.generated'
import { useCreateCheckoutSessionMutation } from './operations/create-checkout-session.generated'
import Tiers from './Tiers'
import SingleTierUpsellContent from './SingleTierUpsellContent'

const limitTextMap: Record<Limits, string> = {
  USERS: 'users',
  SEGMENTS: 'segments',
  LABELS: 'labels',
  POSTS: 'visible posts.  You will see analytics, but not content for older posts.',
  SOCIAL_ACCOUNTS: 'social accounts',
}

// going from modal state of { isOpen: true, context: someData } -> { isOpen: false, context: null }
// will trigger the modal to close. During the close animation, the contents of
// the modal can change to whatever { context: null } would render instead of what was
// in the modal when it was about to close. To prevent this, when going from
// isOpen to !isOpen, delay changing context until after the modal is gone.
//
// This function returns a tuple of the modal state, and a function
// to call when the modal if fully gone from the dom
function useDelayExitChange(): [ModalStateProps, () => void] {
  const { isOpen, modalProps } = useContext(UpsellModalStateContext)
  // hold a ref of the modalProps so we can change it on our terms
  const modalPropsRef = useRef(modalProps)
  if (isOpen) {
    // if open, always change props if they change
    modalPropsRef.current = modalProps
  }

  // if not open, dont change the props yet, wait for on exit.
  return [
    { isOpen, modalProps: modalPropsRef.current },
    function onExited() {
      // ok now change them
      modalPropsRef.current = modalProps
    },
  ]
}

function UpsellModal(): React.ReactElement | null {
  const [{ isOpen, modalProps }, onExited] = useDelayExitChange()
  const { context, onCancel } = modalProps || {}
  const { data, error } = usePricingQuery()
  const [createSession] = useCreateCheckoutSessionMutation()
  const isOwner = data?.whoami?.roles?.some(r => r.name === 'OWNER') || false
  const tiers = (data?.appStripeProducts || []).slice().sort((a, b) => (a.monthlyPrice || 0) - (b.monthlyPrice || 0))
  const nonEnterpriseTiers = tiers.filter(t => !t.isEnterprise)
  if (error || nonEnterpriseTiers.length < 2) {
    return <div />
  }
  const lowestTier = nonEnterpriseTiers[0]
  const secondTier = nonEnterpriseTiers[1]
  const isTrialing = !!data?.whoami?.account?.organization?.isTrialing
  const currentPaidTier = !isTrialing
    ? data?.whoami?.account?.organization?.plans?.flatMap(p => p.stripeProducts).find(l => l && l.isInApp)
    : null

  async function handleClickTier(id: string) {
    const result = await createSession({
      variables: {
        productId: id,
      },
    })
    const link = result.data?.createCheckoutSession?.link
    if (link) {
      window.location.href = link
    }
  }
  const alertText =
    context?.reason === 'LIMIT' ? `You have reached your plan's limit for ${limitTextMap[context.limit]}` : null

  const commonDialogProps: DialogProps = {
    TransitionProps: { onExited },
    open: isOpen,
    onClose: (event, reason) => {
      if (onCancel && reason === 'backdropClick') {
        onCancel(event, context)
      }
    },
  }

  if (!secondTier || !lowestTier) {
    return null
  }

  if (context?.reason === 'CAMPAIGNS') {
    return (
      <Dialog fullWidth maxWidth="md" {...commonDialogProps}>
        <SingleTierUpsellContent
          tier={secondTier}
          title="Run programs with Growth"
          text="Your customers are your most powerful marketers. Create a feedback loop with your customers by incentivizing them to create social content after a purchase. Build a UGC incentive program from scratch or integrate with your existing ambassador program."
          imageURL={growthPhoto}
          isCurrentlyEnterprise={currentPaidTier?.isEnterprise}
          onCancel={e => {
            if (onCancel) {
              onCancel(e, context)
            }
          }}
        />
      </Dialog>
    )
  } else if (context?.reason === 'MESSAGING_STORY_REPLIES') {
    return (
      <Dialog fullWidth maxWidth="md" {...commonDialogProps}>
        <SingleTierUpsellContent
          tier={secondTier}
          title="Engage Better with Story Replies"
          text="Your customers are starting conversations with you! Every story where you are mentioned is a chance to engage and build a relationship. Don't miss an opportunity with automated story replies through LoudCrowd."
          imageURL={growthPhoto}
          isCurrentlyEnterprise={currentPaidTier?.isEnterprise}
          onCancel={e => {
            if (onCancel) {
              onCancel(e, context)
            }
          }}
        />
      </Dialog>
    )
  } else if (currentPaidTier?.isEnterprise) {
    return (
      <Dialog {...commonDialogProps}>
        <Box display="flex" flexDirection="column" width={500}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" mt={8} mr={8}>
            <IconButton onClick={onCancel}>
              <CrossIcon width={24} height={24} />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" mt={3}>
            <Typography variant="h4">Your Plan</Typography>
          </Box>
          <Box my={5} textAlign="center" color="secondary">
            {alertText}. Talk to your customer representative to increase your limits.
          </Box>
          <Box mb={7} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              href="mailto:support@loudcrowd.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Talk to LoudCrowd
            </Button>
          </Box>
        </Box>
      </Dialog>
    )
  } else {
    return (
      <Dialog fullWidth maxWidth="xl" {...commonDialogProps}>
        <Tiers
          currentTierId={currentPaidTier?.id}
          onCancel={e => {
            if (onCancel) {
              onCancel(e, context)
            }
          }}
          onClickTier={handleClickTier}
          alertText={alertText}
          isOwner={isOwner}
        />
      </Dialog>
    )
  }
}

export default UpsellModal
