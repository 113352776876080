import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerSourceJobQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CustomerSourceJobQuery = { __typename: 'Query', customerSourceJob?: { __typename: 'CustomerSourceJob', id: string, completedAt?: Date | null, failedAt?: Date | null, errorMessage?: string | null } | null };


export const CustomerSourceJobDocument = gql`
    query CustomerSourceJob($id: ID!) {
  customerSourceJob(id: $id) {
    id
    completedAt
    failedAt
    errorMessage
  }
}
    `;

/**
 * __useCustomerSourceJobQuery__
 *
 * To run a query within a React component, call `useCustomerSourceJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSourceJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSourceJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerSourceJobQuery(baseOptions: Apollo.QueryHookOptions<CustomerSourceJobQuery, CustomerSourceJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerSourceJobQuery, CustomerSourceJobQueryVariables>(CustomerSourceJobDocument, options);
      }
export function useCustomerSourceJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerSourceJobQuery, CustomerSourceJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerSourceJobQuery, CustomerSourceJobQueryVariables>(CustomerSourceJobDocument, options);
        }
export type CustomerSourceJobQueryHookResult = ReturnType<typeof useCustomerSourceJobQuery>;
export type CustomerSourceJobLazyQueryHookResult = ReturnType<typeof useCustomerSourceJobLazyQuery>;
export type CustomerSourceJobQueryResult = Apollo.QueryResult<CustomerSourceJobQuery, CustomerSourceJobQueryVariables>;