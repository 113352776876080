import React from 'react'
import { Box } from '@material-ui/core'

export const TableHeaderCell: React.FC = ({ children }) => (
  <Box color="secondary.main" flex={1}>
    {children}
  </Box>
)

const TableHeader: React.FC = ({ children }) => (
  <Box display="flex" flex={1} pb={2} px={7}>
    {children}
  </Box>
)

export default TableHeader
