import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelectedSocialAccountIdMutationVariables = Types.Exact<{
  selectedSocialAccountId: Types.Scalars['String'];
}>;


export type UpdateSelectedSocialAccountIdMutation = { __typename: 'Mutations', updateSelectedSocialAccountId?: string | null };


export const UpdateSelectedSocialAccountIdDocument = gql`
    mutation UpdateSelectedSocialAccountId($selectedSocialAccountId: String!) {
  updateSelectedSocialAccountId(selectedSocialAccountId: $selectedSocialAccountId) @client
}
    `;
export type UpdateSelectedSocialAccountIdMutationFn = Apollo.MutationFunction<UpdateSelectedSocialAccountIdMutation, UpdateSelectedSocialAccountIdMutationVariables>;

/**
 * __useUpdateSelectedSocialAccountIdMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedSocialAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedSocialAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedSocialAccountIdMutation, { data, loading, error }] = useUpdateSelectedSocialAccountIdMutation({
 *   variables: {
 *      selectedSocialAccountId: // value for 'selectedSocialAccountId'
 *   },
 * });
 */
export function useUpdateSelectedSocialAccountIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectedSocialAccountIdMutation, UpdateSelectedSocialAccountIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectedSocialAccountIdMutation, UpdateSelectedSocialAccountIdMutationVariables>(UpdateSelectedSocialAccountIdDocument, options);
      }
export type UpdateSelectedSocialAccountIdMutationHookResult = ReturnType<typeof useUpdateSelectedSocialAccountIdMutation>;
export type UpdateSelectedSocialAccountIdMutationResult = Apollo.MutationResult<UpdateSelectedSocialAccountIdMutation>;
export type UpdateSelectedSocialAccountIdMutationOptions = Apollo.BaseMutationOptions<UpdateSelectedSocialAccountIdMutation, UpdateSelectedSocialAccountIdMutationVariables>;