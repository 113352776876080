import React from 'react'
import { TimeDataPoint } from '../charts/types'
import MultiBarChart, { MultiBarChartProps } from '../charts/MultiBarChart'
import metricMap, { MetricTypes } from './metric-map'

interface MetricMultiBarChartProps<K extends string, T extends TimeDataPoint<K>>
  extends Omit<MultiBarChartProps<K, T>, 'chartConfigs'> {
  metricTypes: MetricTypes[]
}

function MetricMultiBarChart<K extends string>({
  metricTypes,
  ...rest
}: MetricMultiBarChartProps<K, TimeDataPoint<K>>): React.ReactElement | null {
  const metricConfigs = metricTypes.map(t => {
    const config = metricMap[t]
    return {
      label: config.label,
      color: config.color,
      paletteOption: config.paletteOption,
    }
  })
  return <MultiBarChart chartConfigs={metricConfigs} {...rest} />
}

export default MetricMultiBarChart
