import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PresignedUploadUrlStoreLogoQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  mimetype: Types.Scalars['String'];
}>;


export type PresignedUploadUrlStoreLogoQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, presignedUrlForStoreLogoUpload?: { __typename: 'PresignedUrlAndFields', url: string, fields: Array<{ __typename: 'AWSS3PostField', key: string, value: string }> } | null } | null };


export const PresignedUploadUrlStoreLogoDocument = gql`
    query PresignedUploadUrlStoreLogo($accountId: ID!, $mimetype: String!) {
  account(id: $accountId) {
    id
    presignedUrlForStoreLogoUpload(mimetype: $mimetype) {
      fields {
        key
        value
      }
      url
    }
  }
}
    `;

/**
 * __usePresignedUploadUrlStoreLogoQuery__
 *
 * To run a query within a React component, call `usePresignedUploadUrlStoreLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignedUploadUrlStoreLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignedUploadUrlStoreLogoQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      mimetype: // value for 'mimetype'
 *   },
 * });
 */
export function usePresignedUploadUrlStoreLogoQuery(baseOptions: Apollo.QueryHookOptions<PresignedUploadUrlStoreLogoQuery, PresignedUploadUrlStoreLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresignedUploadUrlStoreLogoQuery, PresignedUploadUrlStoreLogoQueryVariables>(PresignedUploadUrlStoreLogoDocument, options);
      }
export function usePresignedUploadUrlStoreLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresignedUploadUrlStoreLogoQuery, PresignedUploadUrlStoreLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresignedUploadUrlStoreLogoQuery, PresignedUploadUrlStoreLogoQueryVariables>(PresignedUploadUrlStoreLogoDocument, options);
        }
export type PresignedUploadUrlStoreLogoQueryHookResult = ReturnType<typeof usePresignedUploadUrlStoreLogoQuery>;
export type PresignedUploadUrlStoreLogoLazyQueryHookResult = ReturnType<typeof usePresignedUploadUrlStoreLogoLazyQuery>;
export type PresignedUploadUrlStoreLogoQueryResult = Apollo.QueryResult<PresignedUploadUrlStoreLogoQuery, PresignedUploadUrlStoreLogoQueryVariables>;