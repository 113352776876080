import React, { useEffect, useRef } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useParams } from 'react-router-dom'
import { useQueryParam, NumberParam } from 'use-query-params'
import { CampaignDetailRouteParams } from '../routes'
import CustomerTable from '../../components/CustomerTable/CustomerTable'
import useSelectionState from '../../hooks/useSelectionState'
import {
  ParticipantSort,
  ParticipantsInput,
  ParticipantStatus,
  PagedParticipants,
  CustomerMentionStatsFilters,
  SortDirection,
  CustomerExportFieldEnum,
  IgMediaPostType,
  SocialPlatformEnum,
} from '../../gql-global'
import { useCampaignMembersQuery } from './operations/members.generated'
import ContainerError from '../../components/ContainerError'
import ListHeader from '../../components/lists/ListHeader'
import ListActions from '../../components/lists/ListActions'
import ListActionMenu, { ListActionConfig } from '../../components/lists/ListActionMenu'
import ListCount from '../../components/lists/ListCount'
import { ReactComponent as OutlineCrossIcon } from '../../icons/outline-cross.svg'
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg'
import { ReactComponent as ClockIcon } from '../../icons/clock.svg'
import { NetworkStatus } from '@apollo/client'
import useSortParam from './use-sort-param'
import {
  PAGE_SIZE,
  STATUS_CHANGE_LIMIT,
  tagStatusOptions,
  EXPORT_LIMIT,
  participantStatusOptions,
  MESSAGE_TEMPLATES_LIMIT,
} from './constants'
import useFilterParams, { Filters } from './use-filter-params-members'
import ListFilters from '../../components/lists/ListFilters'
import {
  Options,
  KeywordsOption,
  SelectionOption,
  ParentOption,
  DateRangeOption,
} from '../../components/lists/ListFilters/types'
import {
  igMentionTypeOptions,
  mediaTypeOptions,
  messageSendingMethodOptions,
  postTypeOptions,
  ttMentionTypeOptions,
} from '../../content/constants'
import { useDateRangeRef, useDateRangesRef } from '../../hooks/useDateRangeRef'
import { realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import { useCampaignMembersCampaignQuery } from './operations/campaign-members-campaign.generated'
import useBulkActionState from './use-bulk-action-state'
import ActionDialogs from './ActionsDialogs'
import {
  useUpdateParticipantsStatusMutation,
  UpdateParticipantsStatusMutationVariables,
} from './operations/update-participants-status.generated'
import useSortDirParam from '../../customer/use-sort-dir-param'
import { isTypeName } from '../../types/utility'
import { useExportCampaignMembersLazyQuery } from './operations/export-campaign-members.generated'
import { ReactComponent as Export } from '../../icons/export.svg'
import useHasFeature from '../../hooks/useHasFeature'
import { useMessageTemplatesListFilterQuery } from '../../customer/operations/query-message-templates-list-filter.generated'
import { makeMentionTypesWhere } from '../../dashboard/utils'
import { cleanAllAnyNoneFilter, useDateInclusionExclusionWhereFilter } from '../../utils/filter-params'
import { useToast } from '../../components/Alert/ToastProvider'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'
import { SEGMENT_MANAGEMENT_ROUTE } from '../../settings/routes'
import { createCustomFieldFilters } from '../../utils/custom-field-filters'

function useWhereFilters(filters: Filters, isInstagramAccount: boolean, hasTikTokHashtags: boolean): ParticipantsInput {
  const currentSignedUpDateRange = filters.signedUpAt ? realizedDateRangeFromFilter(filters.signedUpAt) : null
  const reffedCurrentSignedUpDateRange = useDateRangeRef(currentSignedUpDateRange)

  const customFieldsAllDates =
    filters.customFields?.all?.map(accf => (accf.dateValue ? realizedDateRangeFromFilter(accf.dateValue) : null)) || []
  const customFieldsAllDateRefs = useDateRangesRef(customFieldsAllDates)
  const customFieldsAll = filters.customFields?.all?.map((f, i) => ({ ...f, dateValue: customFieldsAllDateRefs[i] }))

  const customFieldsAnyDates =
    filters.customFields?.any?.map(accf => (accf.dateValue ? realizedDateRangeFromFilter(accf.dateValue) : null)) || []
  const customFieldsAnyDateRefs = useDateRangesRef(customFieldsAnyDates)
  const customFieldsAny = filters.customFields?.any?.map((f, i) => ({ ...f, dateValue: customFieldsAnyDateRefs[i] }))

  const messageDate = useDateInclusionExclusionWhereFilter(filters.messageDate)

  return {
    signedUpAt: reffedCurrentSignedUpDateRange,
    status: filters.status
      ? filters.status === '1'
        ? { any: [ParticipantStatus.Pending] }
        : { any: [ParticipantStatus.Rejected] }
      : { any: [ParticipantStatus.Pending, ParticipantStatus.Rejected] },
    followerCount: filters.followerCount,
    postCount: filters.postCount,
    avgEngagementRate: filters.avgEngagementRate,
    impressions: filters.impressions,
    username: filters.usernameKeywords?.length ? { keywords: filters.usernameKeywords } : undefined,
    messageTemplate: filters.messageTemplate,
    messageKeywords: filters.messageKeywords,
    email: filters.emailKeywords?.length ? { keywords: filters.emailKeywords } : undefined,
    biography: filters.biographyKeywords?.length ? { all: filters.biographyKeywords.map(k => [k]) } : undefined,
    notes: filters.notesKeywords?.length ? { keywords: filters.notesKeywords } : undefined,
    noteCategories: filters.noteCategories ? { any: Array.from(filters.noteCategories) } : undefined,
    segments: filters.segments,
    ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
    mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
    postType: filters.postType?.size ? { any: Array.from(filters.postType) } : undefined,
    customFields:
      customFieldsAll || customFieldsAny
        ? {
            all: customFieldsAll || undefined,
            any: customFieldsAny || undefined,
          }
        : undefined,
    messageDate,
    messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
  }
}

function useMentionsWhereFilters(
  filters: Filters,
  socialId: string,
  isInstagramAccount: boolean,
  hasTikTokHashtags: boolean,
): CustomerMentionStatsFilters {
  const currentPostedDateRange = filters.postedAt ? realizedDateRangeFromFilter(filters.postedAt) : null
  const reffedCurrentPostedDateRange = useDateRangeRef(currentPostedDateRange)
  return {
    socialAccountId: {
      any: [socialId],
    },
    postedAt: reffedCurrentPostedDateRange,
    ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
    tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : undefined,
    mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
    postType: filters.postType?.size ? { any: Array.from(filters.postType) } : undefined,
  }
}

function CampaignApplicants(): React.ReactElement {
  const { id } = useParams<CampaignDetailRouteParams>()
  const [selectionState, selectionDispatch] = useSelectionState()
  const [sort = ParticipantSort.SignedUpAt, setSort] = useSortParam()
  const [sortDir = SortDirection.Desc, setSortDir] = useSortDirParam()
  const { showToast } = useToast()

  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('viewing', NumberParam)
  const [bulkState, dispatch] = useBulkActionState()
  const limitRef = useRef(viewing)
  const { data: campaignData, loading: campaignLoading } = useCampaignMembersCampaignQuery({
    variables: {
      campaignId: id,
      challengesLimit: 200,
    },
  })
  const { filters, isDirty, setFilters } = useFilterParams()
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const { data: userData } = useCampaignUserInfoQuery()
  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const socialPlatform = selectedSocialPlatform(userData)
  const isIGSocialAccount = socialPlatform === SocialPlatformEnum.Instagram

  const where = {
    ...useWhereFilters(filters, isIGSocialAccount, hasTikTokHashtags),
    platform: socialPlatform,
  }

  const mentionsWhere = useMentionsWhereFilters(
    filters,
    selectedSocialAccountId ? selectedSocialAccountId : '',
    isIGSocialAccount,
    hasTikTokHashtags,
  )
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: selectedSocialAccountId ? { any: [selectedSocialAccountId] } : undefined,
  }
  const { data, loading, error, fetchMore, networkStatus } = useCampaignMembersQuery({
    skip: !selectedSocialAccountId,
    notifyOnNetworkStatusChange: true,
    variables: {
      campaignId: id,
      where: {
        ...where,
        platform: socialPlatform,
      },
      mentionsWhere,
      activeStoryMentionsWhere,
      limit: limitRef.current,
      sortBy: sort,
      sortDirection: sortDir,
      pendingWhere: {
        ...where,
        status: { any: [ParticipantStatus.Pending] },
        platform: socialPlatform,
      },
    },
  })

  const { data: messageTemplatesListData } = useMessageTemplatesListFilterQuery({
    skip: !selectedSocialAccountId,
    variables: {
      socialAccountId: selectedSocialAccountId || '',
      limit: MESSAGE_TEMPLATES_LIMIT,
    },
  })

  const [updateStatus] = useUpdateParticipantsStatusMutation({
    update(cache, { data: updateData }) {
      const changed = updateData?.updateParticipantStatus?.participants
      if (data?.campaign && changed) {
        const cacheKey = cache.identify(data.campaign)
        cache.modify({
          id: cacheKey,
          fields: {
            participants(value: PagedParticipants, { storeFieldName, isReference, readField, DELETE }) {
              const ids = changed.map(p => p.id)
              if (
                storeFieldName.includes(ParticipantStatus.Pending) &&
                !storeFieldName.includes(ParticipantStatus.Approved)
              ) {
                const results = value.results.filter(p => {
                  const id: number | undefined = isReference(p) ? readField('id', p) : p.id
                  if (!id) return false
                  return !ids.includes(id)
                })
                return {
                  ...value,
                  total: value.total - ids.length,
                  results,
                }
              }
              return DELETE
            },
          },
        })
        for (const field of ['mentionStats', 'mentions', 'participantStats', 'participantStatsTimeseries']) {
          cache.evict({
            id: cacheKey,
            fieldName: field,
          })
        }
        cache.gc()
      }
    },
  })

  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const loadingInitial = loading && !loadingMore
  const totalMembers = data?.campaign?.participants?.total || 0
  const shownMemberCount = data?.campaign?.participants?.results.length
  const pendingMembers = data?.campaign?.applicantStats?.count
  const newViewingCount = shownMemberCount || limitRef.current
  // update viewing url param based on what we got from the api
  useEffect(() => setViewing((Math.ceil(newViewingCount / PAGE_SIZE) || 1) * PAGE_SIZE), [newViewingCount, setViewing])

  const exportUrlRef = useRef<string | null>(null)
  const [exportMembers, exportResults] = useExportCampaignMembersLazyQuery()
  const exportUrl = exportResults.data?.campaign?.participants?.csvUrl
  useEffect(() => {
    if (exportUrl && exportUrl !== exportUrlRef.current) {
      exportUrlRef.current = exportUrl
      window.location.href = exportUrl
    }
  }, [exportUrl])

  const { cursor } = data?.campaign?.participants || {}

  function handleLoadMore(): void {
    if (cursor) {
      void fetchMore({
        variables: {
          cursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  const actionOptions: ListActionConfig<'approve' | 'export'>[] = [
    { label: 'Export', action: 'export', icon: <Export /> },
    { label: 'Approve', action: 'approve', icon: <CheckmarkIcon width={20} /> },
  ]
  const deleteAction: ListActionConfig<'reject'> = {
    label: 'Reject',
    action: 'reject',
    icon: <OutlineCrossIcon width={20} />,
  }

  const applicantsActionOptions: ListActionConfig<'export' | 'approve' | 'pending' | 'reject'>[] = [
    { label: 'Approve', action: 'approve', icon: <CheckmarkIcon width={20} /> },
    { label: 'Pending', action: 'pending', icon: <ClockIcon width={20} /> },
    { label: 'Reject', action: 'reject', icon: <OutlineCrossIcon width={20} /> },
  ]

  const noteCategoryOptions =
    campaignData?.noteCategories && !campaignLoading
      ? campaignData.noteCategories.map(c => {
          return { id: c.id, label: c.name }
        })
      : []

  const customFields = campaignData?.whoami?.account?.customFields || []
  const customFieldsFilterConfig = createCustomFieldFilters(customFields)

  const messageTemplates =
    messageTemplatesListData?.socialAccount &&
    isTypeName(messageTemplatesListData?.socialAccount, 'IGSocialAccount') &&
    messageTemplatesListData?.socialAccount?.messageTemplates
  const messageTemplatesList = messageTemplates ? messageTemplates.results : []

  const accountSegments = campaignData?.whoami?.account?.segments?.results || []

  let applicantOptions: Options = [
    { name: 'postedAt', type: 'dateRange', label: 'Post Date', entity: 'normal' },
    { name: 'status', type: 'singleSelection', label: 'Status', selectionOptions: participantStatusOptions },
    {
      name: 'segments',
      type: 'labels' as const,
      label: 'Segments',
      entity: 'segment' as const,
      selectionOptions: accountSegments,
      includeField: 'all' as const,
      seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
    },
    {
      name: 'usernameKeywords',
      type: 'keywords',
      label: 'Username',
      useChips: true,
    },
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
          includeField: 'all' as const,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          includeField: 'all' as const,
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
    {
      name: 'emailKeywords',
      type: 'keywords',
      label: 'Email',
      useChips: true,
    },
    {
      name: 'biographyKeywords',
      type: 'keywords',
      label: 'Biography',
      useChips: true,
      allowSpaces: true,
    },
    {
      name: 'noteInfo',
      type: 'parent',
      label: 'Notes',
      children: [
        {
          name: 'notesKeywords',
          type: 'keywords',
          label: 'Note Keyword',
          useChips: true,
          allowSpaces: true,
        } as KeywordsOption,
        {
          name: 'noteCategories',
          type: 'selection',
          label: 'Note Category',
          selectionOptions: noteCategoryOptions,
        } as SelectionOption,
      ],
    } as ParentOption,
    {
      name: 'customerInfo',
      type: 'parent',
      label: 'Customer Info',
      children: [
        {
          name: 'followerCount',
          type: 'numericalRange',
          label: 'Follower Count',
          min: 0,
          max: 1_000_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          type: 'numericalRange',
          name: 'avgEngagementRate',
          label: 'Engagement Rate',
          min: 0,
          max: 300,
          sliderRange: {
            gte: 0,
            lte: 150,
          },
          numberFormat: 'percent',
        },
        {
          name: 'impressions',
          type: 'numericalRange',
          label: 'Impressions',
          min: 0,
          max: 300_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
      ],
    },
    {
      name: 'activity',
      type: 'parent',
      label: 'Activity',
      children: [
        {
          type: 'numericalRange',
          name: 'postCount',
          label: 'Post Count',
          min: 0,
          max: 5000,
          sliderRange: {
            gte: 0,
            lte: 1000,
          },
        },
      ],
    },
    {
      name: 'postInfo',
      type: 'parent',
      label: 'Post Info',
      children: [
        ...(isIGSocialAccount || hasTikTokHashtags
          ? [
              {
                name: 'mentionType',
                type: 'selection',
                label: 'Mention Type',
                selectionOptions: isIGSocialAccount ? igMentionTypeOptions : ttMentionTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'mediaType',
          type: 'selection',
          label: 'Media Type',
          selectionOptions: mediaTypeOptions,
        } as SelectionOption,
        ...(isIGSocialAccount
          ? [
              {
                name: 'postType',
                type: 'selection',
                label: 'Post Type',
                selectionOptions: postTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'tagStatus',
          type: 'selection',
          label: 'Post Status',
          selectionOptions: tagStatusOptions,
        },
      ],
    },
  ]

  if (customFields && customFields.length > 0) {
    applicantOptions = [
      ...applicantOptions,
      {
        name: 'customFields',
        type: 'parent',
        label: 'Custom Field Data',
        children: customFieldsFilterConfig,
      } as ParentOption,
    ]
  }

  function handleSetFilters(newFilters: Filters): void {
    limitRef.current = PAGE_SIZE
    setFilters(newFilters)
  }

  function handleBulkAction(action: 'approve' | 'reject'): void {
    if (selectionState === null) {
      return
    }
    const selectedCount = selectionState === 'ALL' ? totalMembers : selectionState.size
    dispatch({ type: 'start', action, selectedCount })
  }

  function exportList(): void {
    if (!selectionState) {
      return
    }
    let exportWhere: ParticipantsInput
    let limit = EXPORT_LIMIT
    if (selectionState === 'ALL') {
      exportWhere = where
    } else {
      exportWhere = {
        participantIds: {
          any: Array.from(selectionState).map(s => s.toString()),
        },
      }
      limit = selectionState.size
    }
    const isTiktok = socialPlatform === SocialPlatformEnum.Tiktok
    const fields = [
      isTiktok ? CustomerExportFieldEnum.TtUsername : CustomerExportFieldEnum.IgUsername,
      CustomerExportFieldEnum.Email,
      isTiktok ? CustomerExportFieldEnum.TtAvatar : CustomerExportFieldEnum.IgAvatar,
      CustomerExportFieldEnum.SignUp,
      CustomerExportFieldEnum.Status,
      CustomerExportFieldEnum.PostCount,
      isTiktok ? CustomerExportFieldEnum.TtFollowers : CustomerExportFieldEnum.IgFollowers,
      CustomerExportFieldEnum.AvgEngagementRate,
      CustomerExportFieldEnum.Impressions,
      isTiktok ? CustomerExportFieldEnum.IgUsername : CustomerExportFieldEnum.TtUsername,
      CustomerExportFieldEnum.Address,
      CustomerExportFieldEnum.Address_2,
      CustomerExportFieldEnum.City,
      CustomerExportFieldEnum.State,
      CustomerExportFieldEnum.Country,
      CustomerExportFieldEnum.Zip,
      CustomerExportFieldEnum.Phone,
      CustomerExportFieldEnum.ExternalId,
    ]

    exportMembers({
      variables: {
        campaignId: id,
        where: exportWhere,
        mentionsWhere,
        limit: limit,
        sortBy: sort,
        sortDirection: sortDir,
        fields,
      },
    })
  }

  async function handleConfirmAction(): Promise<void> {
    if (bulkState?.step !== 'confirmation') {
      return
    }
    if (bulkState.action === 'export') {
      exportList()
    } else if (bulkState.action === 'reject') {
      let variables: Partial<UpdateParticipantsStatusMutationVariables> = {}
      if (bulkState.participant) {
        variables.participantIds = [bulkState.participant.id.toString()]
      } else if (selectionState === 'ALL') {
        variables = {
          campaignId: id,
          limit: Math.min(STATUS_CHANGE_LIMIT, totalMembers),
          where,
          mentionsWhere,
          sortDirection: sortDir,
          sortBy: sort,
        }
      } else if (selectionState) {
        variables.participantIds = Array.from(selectionState).map(i => i.toString())
      }
      try {
        const result = await updateStatus({
          variables: {
            ...variables,
            status: ParticipantStatus.Rejected,
          },
        })
        const participants = result.data?.updateParticipantStatus?.participants
        showToast({
          title: 'Success: Rejecting Applicants',
          message: `Rejected ${participants?.length.toLocaleString() ?? ''} applicants.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      } catch {
        showToast({
          title: 'Error: Rejecting Applicants',
          message: 'Something went wrong when rejecting these applicants, please try again.',
        })
      }
    } else if (bulkState.action === 'approve') {
      let variables: Partial<UpdateParticipantsStatusMutationVariables> = {}
      if (selectionState === 'ALL') {
        variables = {
          ...variables,
          campaignId: id,
          limit: Math.min(STATUS_CHANGE_LIMIT, totalMembers),
          where,
          mentionsWhere,
          sortDirection: sortDir,
          sortBy: sort,
        }
      } else if (selectionState) {
        variables.participantIds = Array.from(selectionState).map(i => i.toString())
      }
      try {
        const result = await updateStatus({
          variables: {
            ...variables,
            status: ParticipantStatus.Approved,
          },
        })
        const participants = result.data?.updateParticipantStatus?.participants
        showToast({
          title: 'Success: Approving Applicants',
          message: `Approved ${participants?.length.toLocaleString() || ''} applicants.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      } catch {
        showToast({
          title: 'Error: Approving Applicants',
          message: 'Something went wrong when approving these applicants, please try again.',
        })
      }
    }
    dispatch({ type: 'done' })
  }

  async function handleCustomerAction(participantId: number, action: 'approve' | 'reject' | 'pending'): Promise<void> {
    switch (action) {
      case 'reject':
        dispatch({
          type: 'start',
          action: 'reject',
          selectedCount: 1,
          participant: data?.campaign?.participants?.results?.find(p => p.id === participantId),
        })
        break
      case 'approve':
        try {
          const result = await updateStatus({
            variables: {
              participantIds: [participantId.toString()],
              status: ParticipantStatus.Approved,
            },
          })
          const participants = result.data?.updateParticipantStatus?.participants
          showToast({
            title: 'Success: Approving Applicants',
            message: `Approved ${participants?.length.toLocaleString() || ''} applicants.`,
            severity: 'success',
            autoHideDuration: 5000,
          })
        } catch {
          showToast({
            title: 'Error: Approving Applicants',
            message: 'Something went wrong when approving these applicants, please try again.',
          })
        }
        break
      case 'pending':
        try {
          const result = await updateStatus({
            variables: {
              participantIds: [participantId.toString()],
              status: ParticipantStatus.Pending,
            },
          })
          const participants = result.data?.updateParticipantStatus?.participants
          showToast({
            title: 'Success: Marking Applicants as Pending',
            message: `Pending ${participants?.length.toLocaleString() || ''} applicants.`,
            severity: 'success',
            autoHideDuration: 5000,
          })
        } catch {
          showToast({
            title: 'Error: Marking Applicants as Pending',
            message: 'Something went wrong when pending these applicants, please try again.',
          })
        }
    }
  }

  const selectedCount = selectionState === null ? 0 : selectionState === 'ALL' ? totalMembers : selectionState.size
  const participantData = data?.campaign?.participants?.results

  const excludeOptions = [
    {
      name: 'segments',
      type: 'labels' as const,
      label: 'Segments',
      entity: 'segment' as const,
      selectionOptions: accountSegments,
      includeField: 'all' as const,
      seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
    },
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
  ]
  return (
    <>
      <ListFilters
        contentType="applicants"
        isDirty={isDirty}
        onChangeFilters={handleSetFilters}
        options={applicantOptions}
        excludeOptions={excludeOptions}
        filters={filters}
        canSave={false}
      />
      <ListHeader>
        <Box mr={2}>
          <ListCount loading={loadingInitial} count={totalMembers} units="Customers" />
        </Box>
        <Box mr={2}>
          <Typography display="block" variant="body2" color="secondary">
            {loadingInitial && <Skeleton width={80} component="span" />}
            {!loadingInitial && (
              <Box component="b" color="text.primary">
                ({pendingMembers?.toLocaleString()} Pending)
              </Box>
            )}
          </Typography>
        </Box>
        {!loadingInitial && (
          <Button
            variant="text"
            color={selectionState === 'ALL' ? 'secondary' : 'primary'}
            onClick={() => selectionDispatch({ type: 'toggleSelectAll' })}
          >
            {selectionState !== 'ALL'
              ? `Select All ${totalMembers.toLocaleString()}`
              : `All ${totalMembers.toLocaleString()} Selected`}
          </Button>
        )}
        <Box flexGrow={1} />
        <ListActions>
          {!!selectionState && !!totalMembers && (
            <ListActionMenu actions={actionOptions} deleteAction={deleteAction} onSelectAction={handleBulkAction} />
          )}
        </ListActions>
      </ListHeader>
      <CustomerTable
        isIGSocialAccount={isIGSocialAccount}
        entity="applicant"
        limit={10}
        ready={!loadingInitial}
        loadingMore={loadingMore}
        useCustomerPageLinks={true}
        list={participantData || []}
        sort={sort}
        sortDir={sortDir}
        setSort={(x: ParticipantSort) => {
          if (x === sort) {
            const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
            setSortDir(sortDirection)
          }
          selectionDispatch({ type: 'reset' })
          setSort(x)
        }}
        selectionDispatch={selectionDispatch}
        selectionState={selectionState}
        multiSelect
        actions={applicantsActionOptions}
        deleteAction={deleteAction}
        onSelectCustomerAction={handleCustomerAction}
      />
      <ActionDialogs
        bulkState={bulkState}
        dispatch={dispatch}
        selectedCount={selectedCount}
        onConfirm={handleConfirmAction}
        entityName="applicants"
      />
      {error && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Error loading customers." />
        </Box>
      )}
      {!error && cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore}>
            Load more
          </Button>
        </Box>
      )}
    </>
  )
}

export default CampaignApplicants
