import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignCollectionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CampaignCollectionsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, program?: { __typename: 'ProgramType', id: string, collections?: Array<{ __typename: 'CollectionType', id: string, title: string }> | null, ecommIntegration?: { __typename: 'CommerceCloudIntegration', id: string, integrationType: Types.IntegrationType, canSyncCollections: boolean, canSyncProducts: boolean } | { __typename: 'CustomStoreIntegration', id: string, integrationType: Types.IntegrationType } | { __typename: 'ShopifyIntegration', id: string, integrationType: Types.IntegrationType } | null } | null } | null };


export const CampaignCollectionsDocument = gql`
    query CampaignCollections($id: ID!) {
  campaign(id: $id) {
    id
    program {
      collections {
        id
        title
      }
      ecommIntegration {
        ... on Integration {
          id
          integrationType
        }
        ... on CommerceCloudIntegration {
          canSyncCollections
          canSyncProducts
        }
      }
      id
    }
  }
}
    `;

/**
 * __useCampaignCollectionsQuery__
 *
 * To run a query within a React component, call `useCampaignCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignCollectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignCollectionsQuery(baseOptions: Apollo.QueryHookOptions<CampaignCollectionsQuery, CampaignCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignCollectionsQuery, CampaignCollectionsQueryVariables>(CampaignCollectionsDocument, options);
      }
export function useCampaignCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignCollectionsQuery, CampaignCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignCollectionsQuery, CampaignCollectionsQueryVariables>(CampaignCollectionsDocument, options);
        }
export type CampaignCollectionsQueryHookResult = ReturnType<typeof useCampaignCollectionsQuery>;
export type CampaignCollectionsLazyQueryHookResult = ReturnType<typeof useCampaignCollectionsLazyQuery>;
export type CampaignCollectionsQueryResult = Apollo.QueryResult<CampaignCollectionsQuery, CampaignCollectionsQueryVariables>;