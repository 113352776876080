import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllStatsFields_CampaignMentionStats_Fragment = { __typename: 'CampaignMentionStats', posts: number, impressions: number, engagement: number, emv: number };

export type AllStatsFields_SocialDataPoint_Fragment = { __typename: 'SocialDataPoint', posts: number, impressions: number, engagement: number, emv: number };

export type AllStatsFields_StatsType_Fragment = { __typename: 'StatsType', posts: number, impressions: number, engagement: number, emv: number };

export type AllStatsFieldsFragment = AllStatsFields_CampaignMentionStats_Fragment | AllStatsFields_SocialDataPoint_Fragment | AllStatsFields_StatsType_Fragment;

export type DashboardOverviewQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  startDate: Types.Scalars['DateTime'];
  endDate: Types.Scalars['DateTime'];
  prevStartDate: Types.Scalars['DateTime'];
  prevEndDate: Types.Scalars['DateTime'];
  timezone: Types.Scalars['String'];
  timeDimension: Types.TimeDimension;
  wasTagged?: Types.InputMaybe<Types.Scalars['Boolean']>;
  wasCaptionMentioned?: Types.InputMaybe<Types.Scalars['Boolean']>;
  wasStoryMentioned?: Types.InputMaybe<Types.Scalars['Boolean']>;
  wasHashtagMentioned?: Types.InputMaybe<Types.Scalars['Boolean']>;
  tagStatus: Array<Types.MentionStatus> | Types.MentionStatus;
  postType: Array<Types.IgMediaPostType> | Types.IgMediaPostType;
  segmentsCampaigns: Array<Types.AnySegmentCampaignFilterInput> | Types.AnySegmentCampaignFilterInput;
  includePremembership: Types.Scalars['Boolean'];
  challengeIds: Types.ChallengesFilterInput;
}>;


export type DashboardOverviewQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, mentionStatsTimeseries?: Array<{ __typename: 'SocialDataPoint', startDate: Date, endDate: Date, posts: number, impressions: number, engagement: number, emv: number }> | null, currentPeriodStats?: { __typename: 'StatsType', posts: number, impressions: number, engagement: number, emv: number } | null, previousPeriodStats?: { __typename: 'StatsType', posts: number, impressions: number, engagement: number, emv: number } | null } | { __typename: 'TTSocialAccount', id: string, mentionStatsTimeseries?: Array<{ __typename: 'SocialDataPoint', startDate: Date, endDate: Date, posts: number, impressions: number, engagement: number, emv: number }> | null, currentPeriodStats?: { __typename: 'StatsType', posts: number, impressions: number, engagement: number, emv: number } | null, previousPeriodStats?: { __typename: 'StatsType', posts: number, impressions: number, engagement: number, emv: number } | null } | null };

export const AllStatsFieldsFragmentDoc = gql`
    fragment AllStatsFields on MentionStats {
  posts
  impressions
  engagement
  emv
}
    `;
export const DashboardOverviewDocument = gql`
    query DashboardOverview($socialAccountId: ID!, $startDate: DateTime!, $endDate: DateTime!, $prevStartDate: DateTime!, $prevEndDate: DateTime!, $timezone: String!, $timeDimension: TimeDimension!, $wasTagged: Boolean, $wasCaptionMentioned: Boolean, $wasStoryMentioned: Boolean, $wasHashtagMentioned: Boolean, $tagStatus: [MentionStatus!]!, $postType: [IGMediaPostType!]!, $segmentsCampaigns: [AnySegmentCampaignFilterInput!]!, $includePremembership: Boolean!, $challengeIds: ChallengesFilterInput!) {
  socialAccount(id: $socialAccountId) {
    id
    mentionStatsTimeseries(
      where: {postedAt: {gte: $startDate, lt: $endDate}, segmentsCampaigns: {all: $segmentsCampaigns}, wasTagged: $wasTagged, wasCaptionMentioned: $wasCaptionMentioned, wasStoryMentioned: $wasStoryMentioned, wasHashtagMentioned: $wasHashtagMentioned, tagStatus: {any: $tagStatus}, postType: {any: $postType}, includePremembership: $includePremembership, challengeIds: $challengeIds}
      timezone: $timezone
      timeDimension: $timeDimension
    ) {
      startDate
      endDate
      ...AllStatsFields
    }
    currentPeriodStats: stats(
      where: {postedAt: {gte: $startDate, lt: $endDate}, segmentsCampaigns: {all: $segmentsCampaigns}, wasTagged: $wasTagged, wasCaptionMentioned: $wasCaptionMentioned, wasStoryMentioned: $wasStoryMentioned, wasHashtagMentioned: $wasHashtagMentioned, tagStatus: {any: $tagStatus}, postType: {any: $postType}, includePremembership: $includePremembership, challengeIds: $challengeIds}
    ) {
      ...AllStatsFields
    }
    previousPeriodStats: stats(
      where: {postedAt: {gte: $prevStartDate, lt: $prevEndDate}, segmentsCampaigns: {all: $segmentsCampaigns}, wasTagged: $wasTagged, wasCaptionMentioned: $wasCaptionMentioned, wasStoryMentioned: $wasStoryMentioned, wasHashtagMentioned: $wasHashtagMentioned, tagStatus: {any: $tagStatus}, postType: {any: $postType}, includePremembership: $includePremembership, challengeIds: $challengeIds}
    ) {
      ...AllStatsFields
    }
  }
}
    ${AllStatsFieldsFragmentDoc}`;

/**
 * __useDashboardOverviewQuery__
 *
 * To run a query within a React component, call `useDashboardOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOverviewQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prevStartDate: // value for 'prevStartDate'
 *      prevEndDate: // value for 'prevEndDate'
 *      timezone: // value for 'timezone'
 *      timeDimension: // value for 'timeDimension'
 *      wasTagged: // value for 'wasTagged'
 *      wasCaptionMentioned: // value for 'wasCaptionMentioned'
 *      wasStoryMentioned: // value for 'wasStoryMentioned'
 *      wasHashtagMentioned: // value for 'wasHashtagMentioned'
 *      tagStatus: // value for 'tagStatus'
 *      postType: // value for 'postType'
 *      segmentsCampaigns: // value for 'segmentsCampaigns'
 *      includePremembership: // value for 'includePremembership'
 *      challengeIds: // value for 'challengeIds'
 *   },
 * });
 */
export function useDashboardOverviewQuery(baseOptions: Apollo.QueryHookOptions<DashboardOverviewQuery, DashboardOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardOverviewQuery, DashboardOverviewQueryVariables>(DashboardOverviewDocument, options);
      }
export function useDashboardOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardOverviewQuery, DashboardOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardOverviewQuery, DashboardOverviewQueryVariables>(DashboardOverviewDocument, options);
        }
export type DashboardOverviewQueryHookResult = ReturnType<typeof useDashboardOverviewQuery>;
export type DashboardOverviewLazyQueryHookResult = ReturnType<typeof useDashboardOverviewLazyQuery>;
export type DashboardOverviewQueryResult = Apollo.QueryResult<DashboardOverviewQuery, DashboardOverviewQueryVariables>;