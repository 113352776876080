import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type CustomerNoteFragmentFragment = { __typename: 'CustomerNoteType', id: string, createdAt: Date, deletedAt?: Date | null, note: string, author: { __typename: 'UserType', id: number, email: string }, category?: { __typename: 'CustomerNoteCategory', id: string, name: string } | null };

export const CustomerNoteFragmentFragmentDoc = gql`
    fragment CustomerNoteFragment on CustomerNoteType {
  id
  createdAt
  deletedAt
  note
  author {
    id
    email
  }
  category {
    id
    name
  }
}
    `;