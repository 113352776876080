import { MentionSort, MentionStatus, MediaTypeEnum, IgMediaPostType } from '../gql-global'

export const sortOptions = [
  {
    label: 'Posted Date',
    id: MentionSort.PostedAt,
  },
  {
    label: 'Impressions',
    id: MentionSort.Impressions,
  },
  {
    label: 'Engagement Rate',
    id: MentionSort.EngagementRate,
  },
] as const

export const tagStatusOptions = [
  {
    id: MentionStatus.Unverified,
    label: 'Not Reviewed',
  },
  {
    id: MentionStatus.Verified,
    label: 'Approved',
  },
  {
    id: MentionStatus.Rejected,
    label: 'Rejected',
  },
  {
    id: MentionStatus.OfficialReview,
    label: 'Needs Official Review',
  },
] as const

export const challengeApprovalStatusOptions = [
  {
    id: MentionStatus.Unverified,
    label: 'Not Reviewed',
  },
  {
    id: MentionStatus.Verified,
    label: 'Approved',
  },
  {
    id: MentionStatus.Rejected,
    label: 'Rejected',
  },
]

export const mediaTypeOptions = [
  {
    id: MediaTypeEnum.Image,
    label: 'Image',
  },
  {
    id: MediaTypeEnum.Video,
    label: 'Video',
  },
  {
    id: MediaTypeEnum.CarouselAlbum,
    label: 'Carousel',
  },
] as const

export const postTypeOptions = [
  {
    id: IgMediaPostType.Feed,
    label: 'Feed',
  },
  {
    id: IgMediaPostType.Story,
    label: 'Story',
  },
  {
    id: IgMediaPostType.Reels,
    label: 'Reels',
  },
] as const

//IgMentionTypes is now an entirely front-end concept for filtering...
// the api's have wasTagged and wasCaptionMentioned
export enum IgMentionTypes {
  Tag = 'TAG',
  Caption = 'CAPTION',
  Story = 'STORY',
}

export enum TtMentionTypes {
  Hashtag = 'HASHTAG',
  TT_Caption = 'TT_CAPTION',
}

export const MentionTypesMap = {
  ...IgMentionTypes,
  ...TtMentionTypes,
} as const
export type MentionTypes = IgMentionTypes | TtMentionTypes

export const mentionTypesLabels: { readonly [K in MentionTypes]: string } = {
  [IgMentionTypes.Tag]: 'Tags',
  [IgMentionTypes.Caption]: 'Captions',
  [IgMentionTypes.Story]: 'Stories',
  [TtMentionTypes.Hashtag]: 'Hashtags',
  [TtMentionTypes.TT_Caption]: 'Captions',
} as const

export const igMentionTypeOptions = [
  {
    id: IgMentionTypes.Tag,
    label: 'Tag',
  },
  {
    id: IgMentionTypes.Caption,
    label: 'Caption',
  },
  {
    id: IgMentionTypes.Story,
    label: 'Story',
  },
] as const

export const ttMentionTypeOptions = [
  {
    id: TtMentionTypes.Hashtag,
    label: 'Hashtag',
  },
  {
    id: TtMentionTypes.TT_Caption,
    label: 'Caption',
  },
] as const

export const BULK_ACTION_LIMIT = 25000
export const BULK_ADD_LABEL_LIMIT = 1000

export enum MessageSendingMethods {
  ManualTemplates = 'MANUAL_TEMPLATES',
  ManualCustom = 'MANUAL_CUSTOM',
  AutomaticSend = 'AUTOMATIC_SEND',
}

export const messageSendingMethodOptions = [
  {
    id: MessageSendingMethods.ManualTemplates,
    label: 'Manual Templates',
  },
  {
    id: MessageSendingMethods.ManualCustom,
    label: 'Manual Custom',
  },
  {
    id: MessageSendingMethods.AutomaticSend,
    label: 'Automatic Send',
  },
] as const
