import React from 'react'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Metric, { MetricProps } from '../../components/metric/Metric'

interface DashboardOverviewTabProps extends MetricProps {
  active: boolean
  onClick(): void
}

const CampaignDashboardOverviewTab: React.FC<DashboardOverviewTabProps> = ({ active, onClick, ...rest }) => {
  const theme = useTheme()
  return (
    <Box
      height="136px"
      width="32.7%"
      borderRadius="8px 8px 0 0"
      bgcolor={active ? theme.palette.background.paper : 'rgba(255,255,255,0.5)'}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <Metric size="lg" hasCompare {...rest} />
    </Box>
  )
}

export default CampaignDashboardOverviewTab
