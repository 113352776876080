import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { MentionStatus, ProgramType } from '../gql-global'
import { useClaimChallengeReviewLockMutation } from './operations/claim-lock.generated'
import Page from '../Page'
import { useChallengeReviewDetailsQuery } from './operations/query-challenge-review-details.generated'
import ProgramRules from './ProgramRules'
import ChallengeRules from './ChallengeRules'
import ReviewDetails from './ReviewDetails'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
)

interface ReviewChallengeRouteParams {
  challengeId: string
}

const ReviewChallenge: React.FC = () => {
  const classes = useStyles()

  const { challengeId } = useParams<ReviewChallengeRouteParams>()

  const {
    data: challengeData,
    refetch: fetchNext,
    ...mentionResult
  } = useChallengeReviewDetailsQuery({
    variables: { challengeId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [claimLock, mutationResult] = useClaimChallengeReviewLockMutation()

  const program = challengeData?.challenge?.programs?.[0] as unknown as ProgramType
  const mention = challengeData?.challenge?.reviewPendingPosts?.results[0]

  const challengeMedia = mention?.media?.challengeMedia?.find(item => item?.challenge.id === challengeId)
  const mentionNeedsRefetch = challengeMedia?.approval && challengeMedia?.approval !== MentionStatus.Unverified
  const challengeNeedsRefetch = mention?.status !== MentionStatus.Unverified

  useEffect(() => {
    void claimLock({ variables: { challengeId } })
  }, [challengeId, claimLock])

  return (
    <Page>
      <Box px={12} py={10}>
        <ReviewDetails
          title={`${challengeData?.challenge?.name} Challenge -- ${program?.name} Program`}
          itemQueryResult={{
            program,
            mentions: challengeData?.challenge?.reviewPendingPosts?.results,
            fetchNext,
            ...mentionResult,
          }}
          itemId={challengeId}
          pageTitle={challengeData?.challenge?.name ?? ''}
          entityType="challenge"
          mutationResult={mutationResult}
        />

        {!mentionResult.loading && mention && (
          <Box display="flex">
            <Box minWidth={502} mr={1}>
              <Typography variant="h5" className={classes.title} title={`${challengeData?.challenge?.name} Rules`}>
                {challengeData?.challenge?.name} Rules
              </Typography>
              {!!challengeData?.challenge && (
                <ChallengeRules
                  challenge={{ ...challengeData?.challenge, challengeMedia }}
                  mediaId={mention?.media.id}
                  needsRefetch={challengeNeedsRefetch}
                />
              )}
            </Box>
            <Box ml={1} width="100%">
              <Typography
                variant="h5"
                className={classes.title}
                title={`${challengeData?.challenge?.programs?.[0]?.name} Program Rules`}
              >
                {challengeData?.challenge?.programs?.[0]?.name} Program Rules
              </Typography>
              <ProgramRules program={program} mention={mention} needsRefetch={mentionNeedsRefetch} />
            </Box>
          </Box>
        )}
      </Box>
    </Page>
  )
}
export default ReviewChallenge
