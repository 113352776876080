import React, { useState } from 'react'
import { useCampaignRewardsQuery } from './operations/campaign-rewards.generated'
import { getPreviousRangeDates, realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import ContainerEmptyState from '../../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../../components/ContainerError'
import { ReactComponent as NoPostsImage } from '../../images/no-posts.svg'
import { Box, Button } from '@material-ui/core'
import RewardRow from './RewardRow'
import { DateRangeFilter, DateTimeRangeFilter, DateRangeFilterType, DateRangeFilterUnits } from '../../gql-global'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'

interface RewardsWidgetProps {
  campaignId: string
  dateRangeFilter?: DateRangeFilter | DateTimeRangeFilter | null
}

function RewardsWidget({ dateRangeFilter, campaignId }: RewardsWidgetProps): React.ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false)
  // the reward row should show the 30 day view when the All Time is selected (dateRangeFiler===null)
  const adjustedDateFilter = dateRangeFilter || {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  }
  const dateRange = realizedDateRangeFromFilter(adjustedDateFilter)
  const previousDateRange = getPreviousRangeDates(adjustedDateFilter)

  const { data, loading, error } = useCampaignRewardsQuery({
    variables: {
      campaignId: campaignId,
      periodDateRange: dateRange,
      previousPeriodDateRange: previousDateRange,
    },
  })

  function toggleExpanded() {
    setExpanded(!expanded)
  }

  const totalTiers = data?.campaign?.program?.tiers?.length || 0
  const tiers = expanded
    ? data?.campaign?.program?.tiers
    : data?.campaign?.program?.tiers?.slice(0, totalTiers > 5 ? 5 : totalTiers)
  const campaignStartDate = data?.campaign?.startAt
  return (
    <>
      <Box mb={5} fontSize="1.125rem" fontWeight="fontWeightBold" lineHeight="1.5">
        Rewards
      </Box>
      {loading && (
        <>
          <RewardRow loading />
          <RewardRow loading />
          <RewardRow loading />
        </>
      )}
      {!loading &&
        !error &&
        tiers?.map(t => {
          return (
            t?.reward && (
              <RewardRow
                key={t.id}
                id={t.reward.id}
                campaignId={parseInt(campaignId)}
                name={t.reward.name || ''}
                triggerQuantity={t.triggerQuantity}
                triggerType={t.triggerType}
                periodCount={t.reward.statsThisPeriod.sentTotal}
                previousPeriodCount={t.reward.statsPreviousPeriod.sentTotal}
                allTimeCount={t.reward.statsAllTime.sentTotal}
                campaignStartDate={campaignStartDate}
                dateRangeFilter={adjustedDateFilter}
                remainingCodes={t.reward.statsAllTime.codesRemaining}
              />
            )
          )
        })}
      {!loading && !error && totalTiers > 5 && (
        <Button
          variant="text"
          onClick={toggleExpanded}
          color="primary"
          endIcon={
            expanded ? (
              <ChevronDownIcon height={16} width={16} transform="rotate(180)" />
            ) : (
              <ChevronDownIcon height={16} width={16} />
            )
          }
        >
          {expanded ? 'Collapse Rewards' : `Show all ${totalTiers} rewards`}
        </Button>
      )}
      {!loading && error && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Could not load posts" />
        </Box>
      )}
      {!loading && !tiers?.length && (
        <Box display="flex" justifyContent="center">
          <ContainerEmptyState image={NoPostsImage} text="No rewards have been attached to this campaign." />
        </Box>
      )}
    </>
  )
}

export default RewardsWidget
