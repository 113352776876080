import React, { useState, useRef } from 'react'
import { makeStyles, Popover, FormControl, Box, Typography, Button } from '@material-ui/core'
import { KeywordsChipsOption } from './types'
import { KeywordsFilter } from '../../../gql-global'
import MenuHeader from '../../MenuHeader'
import FilterChip, { FilterChipText } from './FilterChip'
import { secondary, primary } from '../../../loudcrowd-theme'
import KeywordChips from '../../KeywordChips'

export type Ref = HTMLDivElement

type ValueType = Omit<KeywordsFilter, '__typename'>

interface BulkKeywordsFilterMenuProps {
  option: KeywordsChipsOption
  value: ValueType
  onSelectValue(newValue: ValueType): void
  onBack(): void
  onClose(): void
}

interface BulkKeywordsFilterChipProps {
  option: KeywordsChipsOption
  value: ValueType
  onDelete(): void
  onSelectValue(newValue: ValueType): void
  editable?: boolean
}

const useStyles = makeStyles({
  menuPaper: {
    minWidth: 272,
  },
  menuContainer: {
    maxWidth: 334,
    padding: 16,
  },
  chipsContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: 0.5,
    maxHeight: 72,
    overflowY: 'scroll',
    height: 72,
    width: '100%',
    border: `1px solid ${secondary[400]}`,
    pointer: 'cursor',
  },
  icon: {
    height: 8,
    width: 8,
  },
  chip: {
    margin: '10px 10px 0 0',
    backgroundColor: secondary[300],
  },
  formControl: {
    margin: '10px 0 0 0',
  },
  applyButton: {
    margin: '16px 16px 0 0',
  },
  inlineButton: {
    color: primary[500],
    fontSize: 20,
    fontWeight: 400,
    textDecoration: 'underline',
    padding: 0,
    marginBottom: 4,
    minWidth: 0,
    textAlign: 'left',
    height: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
})

type BaseKeywordsMenuContentProps = {
  includeKeywords?: string[]
  excludeKeywords?: string[]
  onApply(includeKeywords: string[], excludeKeywords: string[]): void
  option: KeywordsChipsOption
  editable?: boolean
  onDelete: () => void
}

const BulkKeywordsMenuContent = (props: BaseKeywordsMenuContentProps): React.ReactElement => {
  const classes = useStyles()
  const { option, onApply, onDelete, includeKeywords = [], excludeKeywords = [], editable = true } = props

  const [editingIncludeKeywords, setEditingIncludeKeywords] = useState(includeKeywords)
  const [editingExcludeKeywords, setEditingExcludeKeywords] = useState(excludeKeywords)

  return (
    <Box display="flex" flexDirection="column" className={classes.menuContainer}>
      <FormControl>
        <Typography variant="body2">{option.label} has one of these keywords</Typography>
        <KeywordChips
          id={`includeKeywordsFilter-${option.name}`}
          keywords={editingIncludeKeywords}
          onApply={setEditingIncludeKeywords}
          editable={editable}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Typography variant="body2">And doesn't have any of these keywords</Typography>
        <KeywordChips
          id={`excludeKeywordsFilter-${option.name}`}
          keywords={editingExcludeKeywords}
          onApply={setEditingExcludeKeywords}
          editable={editable}
        />
      </FormControl>
      {editable && (
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" className={classes.applyButton} onClick={onDelete}>
            Clear Filter
          </Button>
          <Button
            disabled={!editingExcludeKeywords.length && !editingIncludeKeywords.length}
            color="primary"
            variant="contained"
            className={classes.applyButton}
            onClick={() => onApply(editingIncludeKeywords, editingExcludeKeywords)}
          >
            Apply
          </Button>
        </Box>
      )}
    </Box>
  )
}

export function BulkKeywordsFilterChip({
  option,
  value,
  onDelete,
  onSelectValue,
  editable = true,
}: BulkKeywordsFilterChipProps): React.ReactElement {
  const [labelMenuOpen, setLabelMenuOpen] = useState(false)
  const classes = useStyles()
  const chipRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const excludeKeywords = value?.none ?? []
  const includeKeywords = value?.any ?? []

  function handleApply(include: string[], exclude: string[]): void {
    if (!include && !exclude) {
      onDelete()
    } else {
      onSelectValue({
        none: exclude,
        any: include,
      })
    }
    onMenuClosed()
  }

  const onMenuClosed = (): void => {
    setLabelMenuOpen(false)
  }

  let includeText = ''
  let excludeText = ''
  const andText = excludeKeywords.length > 0 && includeKeywords.length > 0 ? 'and' : ''
  const optionLabel = option?.textLabel ?? option?.label
  if (includeKeywords.length === 1) {
    const labelName = includeKeywords[0]
    if (option?.displayVariant === 'inline') {
      includeText = `have the keyword ${labelName} in their ${optionLabel.toLowerCase()}`
    } else {
      includeText = `that includes ${labelName} selected`
    }
  } else if (includeKeywords.length > 1) {
    if (option?.displayVariant === 'inline') {
      includeText = `have at least one of the keywords ${includeKeywords.join(
        ', ',
      )} in their ${optionLabel.toLowerCase()}`
    } else {
      includeText = `that includes ${includeKeywords.length} labels selected`
    }
  }
  if (excludeKeywords.length === 1) {
    const labelName = excludeKeywords[0]
    if (option?.displayVariant === 'inline') {
      excludeText = `do not have the keyword ${labelName} in their bio in their ${optionLabel.toLowerCase()}`
    } else {
      excludeText = `that excludes ${labelName} selected`
    }
  }
  if (excludeKeywords.length > 1) {
    if (option?.displayVariant === 'inline') {
      excludeText = `do not have the keywords ${excludeKeywords.join(', ')} in their ${optionLabel.toLowerCase()}`
    } else {
      excludeText = `that excludes ${excludeKeywords.length} labels selected`
    }
  }

  return (
    <>
      {option?.displayVariant === 'inline' ? (
        <Button className={classes.inlineButton} variant="text" onClick={() => setLabelMenuOpen(true)} ref={buttonRef}>
          {includeKeywords.length > 0 && includeText}
          {andText && <> {andText} </>}
          {excludeKeywords.length > 0 && excludeText}
        </Button>
      ) : (
        <FilterChip onClick={() => setLabelMenuOpen(true)} variant="outlined" size="small" ref={chipRef}>
          <>
            {includeKeywords.length > 0 && (
              <>
                <FilterChipText bold text={option.label} />
                <FilterChipText text={includeText} />
              </>
            )}
            {andText && <FilterChipText text={andText} />}
            {excludeKeywords.length > 0 && (
              <>
                <FilterChipText bold text={option.label} />
                <FilterChipText text={excludeText} />
              </>
            )}
          </>
        </FilterChip>
      )}
      <Popover
        open={labelMenuOpen}
        anchorEl={option?.displayVariant === 'inline' ? buttonRef.current : chipRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={onMenuClosed}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuHeader title={option.label} />
        <BulkKeywordsMenuContent
          onApply={handleApply}
          includeKeywords={includeKeywords}
          excludeKeywords={excludeKeywords}
          option={option}
          editable={editable}
          onDelete={onDelete}
        />
      </Popover>
    </>
  )
}

function BulkKeywordsFilterMenu(props: BulkKeywordsFilterMenuProps): React.ReactElement {
  const { option, value, onSelectValue, onBack, onClose } = props

  const excludeKeywords = value?.none ?? []
  const includeKeywords = value?.any ?? []

  const onDelete = (): void => {
    onClose()
    onSelectValue({})
  }

  function handleApply(include: string[], exclude: string[]): void {
    onClose()
    if (!include && !exclude) {
      onDelete()
    } else {
      onSelectValue({
        any: include,
        none: exclude,
      })
    }
  }

  return (
    <div>
      <MenuHeader title={option.label} onClickBack={onBack} />
      <BulkKeywordsMenuContent
        onApply={handleApply}
        includeKeywords={includeKeywords}
        excludeKeywords={excludeKeywords}
        option={option}
        onDelete={onDelete}
      />
    </div>
  )
}

export default BulkKeywordsFilterMenu
