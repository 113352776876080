// Returns if string value is an enum value, return type predicate, will narrow type to enum
export function isEnumValue<K extends string, T extends string>(enumType: { [P in K]: T }, value: string): value is T {
  return Object.values(enumType).includes(value)
}

// Takes an array that contains values that can be of an Enum, filters away anything that is not of the enum
// and returns an array with the type narrowed down to Enum[]
export function filterArrayEnumValues<K extends string, T extends string>(
  enumType: { [P in K]: T },
  array: Iterable<string> | ArrayLike<string> | null | undefined,
): T[] {
  return Array.from(array ?? []).filter(v => isEnumValue(enumType, v)) as T[]
}

// return if value is not null or undefined, will return NonNullable<T>
export function isNonNull<T extends unknown | null | undefined>(t: T): t is NonNullable<T> {
  return t !== undefined && t !== null
}

// returns if object is a certain GQL type by __typename, will narrow type
// useful for narrowing gql interfaces and unions
export function isTypeName<TN extends string, V extends { __typename: string }, K extends TN & V['__typename']>(
  value: V,
  typename: K,
): value is V & { __typename: K } {
  return value.__typename === typename
}

// returns a function to test if object is a certain GQL type by __typename, will narrow type
// useful for narrowing gql interfaces and unions in collections
// const igMedias = allMedias.filter(createTypeNamePredicate('IGMedia'))
export function createTypeNamePredicate<TN extends string>(
  ...typenames: TN[]
): <V extends { __typename: string }>(v: V) => v is Extract<V, { __typename: TN }> {
  return function isGQLType<V extends { __typename: string }>(value: V): value is Extract<V, { __typename: TN }> {
    return typenames.includes(value.__typename as TN)
  }
}

// creates a new type taking all members that are of type U
// ex: OnlyMembersOfType<{ a: string, b: number }, string> = interface { a: 'a' }
export type OnlyMembersOfType<T, U> = { [P in keyof T]: T[P] extends U ? P : never }

// grabs union type of all types of values of T
// ex: ValueOf<{ a: string, b: 'a', c: number }> = string | 'a' | number
export type ValueOf<T> = T[keyof T]

// grabs all key values where the value type is of type U
// ex: KeysWithValuesOf<{ a: string, b: number, c: Person }, string> = "a"
export type KeysWithValuesOf<T, U> = ValueOf<OnlyMembersOfType<T, U>>

// returns a type w/ props only of type U
// ex: SubOfType<{ a: string, b: number, c: string }, number> = { b: number }
export type SubOfType<F, U> = { [K in KeysWithValuesOf<F, U>]: U }
