import React, { useRef } from 'react'
import { Avatar, Box, Button, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import { formatDistanceToNow } from 'date-fns'
import { ReactComponent as EditIcon } from '../icons/edit_pencil.svg'
import { ReactComponent as TrashIcon } from '../icons/trash_can.svg'
import { CustomerDetailNoteType } from './CustomerDetailNotes'
import LabelChip from '../components/labelChip'
import NoteCategoryMenu from './NoteCategoryMenu'
import { CustomerNoteCategory } from '../gql-global'

const useStyles = makeStyles(theme => ({
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  editTextField: {
    boxShadow: '13px 10px 20px 0px rgba(37, 36, 103, 0.1)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(4),
    width: '100%',
  },
  noteTab: {
    borderTop: '1px solid #DAD9E6',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(8),
  },
  cancelButton: {
    marginRight: theme.spacing(4),
  },
  editDeleteIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(2),
  },
  notesDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: theme.spacing(8.5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(11),
  },
  note: {
    marginTop: theme.spacing(2),
  },
}))

interface CustomerNoteProps {
  note: CustomerDetailNoteType
  editingNote: CustomerDetailNoteType | null
  onSaveNote: () => void
  onEditNote: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeNoteCategory: (category_id: string, addOrEdit: 'ADD' | 'EDIT') => void
  onDeleteNote: (noteId: string) => void
  setNoteEditing: (note: CustomerDetailNoteType | null) => void
  noteCategories: CustomerNoteCategory[] | null
}

function CustomerNote({
  editingNote,
  note,
  onEditNote,
  onChangeNoteCategory,
  onSaveNote,
  onDeleteNote,
  setNoteEditing,
  noteCategories,
}: CustomerNoteProps): React.ReactElement {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)

  const email = note.author?.email || ''
  const avatarInitials = `${email[0]}${email[1]}`
  const dirty = editingNote?.note !== note.note || editingNote?.category !== note.category

  return (
    <div key={note.id} className={classes.noteTab}>
      <div className={classes.notesDiv}>
        <Avatar className={classes.avatar}>{avatarInitials.toUpperCase()}</Avatar>
        <Box className={classes.contentContainer}>
          <Typography variant="subtitle1">{note?.author?.email}</Typography>
          <Typography variant="subtitle2" color="secondary">
            {note.createdAt && formatDistanceToNow(note.createdAt, { addSuffix: true })}
          </Typography>
          {editingNote && note.id === editingNote.id ? (
            <>
              <TextField
                className={classes.editTextField}
                inputRef={inputRef}
                fullWidth
                multiline
                variant="outlined"
                value={editingNote.note}
                onChange={onEditNote}
              />
              <Box width="100%" display="flex" justifyContent="space-between" mt={4}>
                <Box>
                  <NoteCategoryMenu
                    addOrEdit="EDIT"
                    onClick={onChangeNoteCategory}
                    value={editingNote.category?.name}
                    noteCategories={noteCategories}
                  />
                </Box>
                <Box>
                  <Button className={classes.cancelButton} variant="contained" onClick={() => setNoteEditing(null)}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" onClick={onSaveNote} disabled={!dirty}>
                    Save
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <Box>
              <Box>
                <Typography variant="body1" className={classes.note}>
                  {note.note}
                </Typography>
              </Box>
              <Box marginTop={5}>{note.category && <LabelChip label={note.category?.name} />}</Box>
            </Box>
          )}
        </Box>
        {(!editingNote || editingNote.id !== note.id) && (
          <div className={classes.editDeleteIconContainer}>
            <IconButton onClick={() => setNoteEditing(note)}>
              <EditIcon className={classes.editIcon} />
            </IconButton>
            <IconButton
              onClick={() => {
                if (note.id) {
                  onDeleteNote(note.id)
                }
              }}
            >
              <TrashIcon className={classes.deleteIcon} />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomerNote
