import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { ChallengeFragmentDoc } from './challenge.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChallengeDetailsQueryVariables = Types.Exact<{
  challengeId: Types.Scalars['ID'];
}>;


export type ChallengeDetailsQuery = { __typename: 'Query', challenge?: { __typename: 'ChallengeType', id: string, keywords?: Array<string> | null, name: string, startedAt?: Date | null, endedAt?: Date | null, postTypes?: Array<Types.IgMediaPostType> | null, currentStatus: Types.ChallengeStatusEnum, hashtags?: Array<string> | null, hashtagsCriteriaOperator: Types.CriteriaOperatorEnum, keywordsCriteriaOperator: Types.CriteriaOperatorEnum, isRecentlyCompleted?: boolean | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }>, programs?: Array<{ __typename: 'ProgramType', id: string, name: string, campaign?: { __typename: 'CampaignType', id: number } | null }> | null, additionalCriteria?: { __typename: 'AdditionalCriteriaType', hasAdditionalCriteria?: boolean | null, criteriaDescription?: string | null, storyCriteriaDescription?: string | null, shouldMeetProgramRules?: boolean | null } | null, challengeRewards?: Array<{ __typename: 'ChallengeRewardType', id: string, winningCriteria: Types.ChallengeRewardCriteriaEnum, reward?: { __typename: 'RewardType', id: number } | null }> | null, paintedDoor?: { __typename: 'ChallengePaintedDoorType', briefContent?: string | null, automatedMessageContent?: string | null, briefDeliveryMethods?: Array<Types.ChallengeBriefDeliveryMethodEnum> | null, automatedMessageTriggers?: Array<Types.ChallengeMessageTriggerEnum> | null, automatedMessageDeliveryMethods?: Array<Types.ChallengeMessageDeliveryMethodEnum> | null, winningInstructions?: Array<{ __typename: 'ChallengeRewardInstructionType', instructions: string, reward?: { __typename: 'RewardType', id: number } | null }> | null } | null, mediaStats?: { __typename: 'ChallengeMediaStats', emv: number, participants: number, total: number } | null } | null };


export const ChallengeDetailsDocument = gql`
    query ChallengeDetails($challengeId: ID!) {
  challenge(id: $challengeId) {
    ...Challenge
  }
}
    ${ChallengeFragmentDoc}`;

/**
 * __useChallengeDetailsQuery__
 *
 * To run a query within a React component, call `useChallengeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengeDetailsQuery({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useChallengeDetailsQuery(baseOptions: Apollo.QueryHookOptions<ChallengeDetailsQuery, ChallengeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChallengeDetailsQuery, ChallengeDetailsQueryVariables>(ChallengeDetailsDocument, options);
      }
export function useChallengeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChallengeDetailsQuery, ChallengeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChallengeDetailsQuery, ChallengeDetailsQueryVariables>(ChallengeDetailsDocument, options);
        }
export type ChallengeDetailsQueryHookResult = ReturnType<typeof useChallengeDetailsQuery>;
export type ChallengeDetailsLazyQueryHookResult = ReturnType<typeof useChallengeDetailsLazyQuery>;
export type ChallengeDetailsQueryResult = Apollo.QueryResult<ChallengeDetailsQuery, ChallengeDetailsQueryVariables>;