import React from 'react'
import { createStyles, makeStyles, Box } from '@material-ui/core'
import { ReactComponent as HashtagIcon } from '../../icons/hashtag.svg'
import { ReactComponent as SocialPostIcon } from '../../icons/social-post.svg'
import { ReactComponent as CaptionKeywordIcon } from '../../icons/caption-keyword.svg'
import { white, purple, amber, primary } from '../../loudcrowd-theme'
import Tooltip from '../../components/Tooltip/Tooltip'
import { CriteriaOperatorEnum, IgMediaPostType } from '../../gql-global'
import { ReactComponent as IGIcon } from '../../icons/instagram.svg'
import { ReactComponent as TTIcon } from '../../icons/tiktok_logo_outline.svg'
import { ReactComponent as EyeIcon } from '../../icons/eye.svg'
import UnrestrainedTooltip from '../../components/Tooltip/UnrestrainedTooltip'

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      borderRadius: '50%',
      color: white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 20,
      height: 20,
    },
    mentionIndicator: {
      backgroundColor: purple[600],
    },
    hashtagIndicator: {
      backgroundColor: amber[600],
    },
    socialPostIndicator: {
      backgroundColor: 'rgba(0, 172, 255, 1)',
    },
    captionKeywordIndicator: {
      backgroundColor: primary[500],
    },
    reviewIndicator: {
      backgroundColor: '#00ACFF',
    },
    socialPostIcon: {
      marginLeft: 2,
    },
    toolTipContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tooltipContent: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    renderedPlatformIcon: {
      marginTop: '3px',
      width: '12px',
      height: '12px',
      marginRight: '6px',
    },
    renderedText: {
      marginRight: '4px',
      fontSize: '14px',
    },
  }),
)

interface MentionIndicatorProps {
  isIG: boolean | undefined
  postTypes: IgMediaPostType[]
  mentions: string[]
}

export function MentionIndicator({ isIG, postTypes, mentions }: MentionIndicatorProps): React.ReactElement {
  const classes = useStyles()

  const igText: string[] = postTypes.map((v, idx) => {
    const lower = v.toLowerCase()
    const formatted = lower.charAt(0).toUpperCase() + lower.slice(1)
    return `${formatted}`
  })

  const formattedTitle = `Mentions: ${mentions.map(m => `@${m}`).join(', ')}`

  return (
    <UnrestrainedTooltip
      title={<IconTooltip title={formattedTitle} valueLabel="Post Types" valueArr={isIG ? igText : ['Feed']} />}
      placement="top"
    >
      <Box className={`${classes.indicator} ${classes.mentionIndicator}`}>
        {isIG && <IGIcon width={10} />}
        {!isIG && <TTIcon width={10} />}
      </Box>
    </UnrestrainedTooltip>
  )
}

interface HashtagIndicatorProps {
  hashtags: string[]
  hashtagsOperator: CriteriaOperatorEnum
}

export function HashtagIndicator({ hashtags, hashtagsOperator }: HashtagIndicatorProps): React.ReactElement {
  const classes = useStyles()

  const formattedHashtags: string[] = hashtags.map(hashtag => {
    return (/^#/g.test(hashtag) ? hashtag : `#${hashtag}`).toLowerCase()
  })

  return (
    <Tooltip
      title={<IconTooltip title="Hashtags:" valueLabel={hashtagsOperator} valueArr={formattedHashtags} />}
      placement="top"
    >
      <Box className={`${classes.indicator} ${classes.hashtagIndicator}`}>
        <HashtagIcon width={10} />
      </Box>
    </Tooltip>
  )
}

export function SocialPostIndicator(): React.ReactElement {
  const classes = useStyles()
  return (
    <Tooltip title={'Social Post'} placement="top">
      <Box className={`${classes.indicator} ${classes.socialPostIndicator}`}>
        <SocialPostIcon width={10} className={classes.socialPostIcon} />
      </Box>
    </Tooltip>
  )
}

interface CaptionKeywordIndicatorProps {
  keywords: string[]
  keywordsOperator: CriteriaOperatorEnum
}

export function CaptionKeywordIndicator({
  keywords,
  keywordsOperator,
}: CaptionKeywordIndicatorProps): React.ReactElement {
  const classes = useStyles()

  const formattedKeywords: string[] = keywords?.map((keywords, idx) => {
    return keywords.toLowerCase()
  })

  return (
    <Tooltip
      title={<IconTooltip title="Caption Keywords:" valueLabel={keywordsOperator} valueArr={formattedKeywords} />}
      placement="top"
    >
      <Box className={`${classes.indicator} ${classes.captionKeywordIndicator}`}>
        <CaptionKeywordIcon width={10} />
      </Box>
    </Tooltip>
  )
}

export function ReviewIndicator(): React.ReactElement {
  const classes = useStyles()

  return (
    <UnrestrainedTooltip title="This challenge requires review" placement="top">
      <Box className={`${classes.indicator} ${classes.reviewIndicator}`}>
        <EyeIcon width={10} />
      </Box>
    </UnrestrainedTooltip>
  )
}

interface IconTooltipProps {
  title: string
  valueLabel?: string
  valueArr: string[]
  icon?: React.ReactElement
}

function IconTooltip({ title, valueLabel, valueArr, icon }: IconTooltipProps): React.ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.toolTipContainer}>
      <span>{title}</span>
      {valueArr.length > 0 && (
        <Box className={classes.tooltipContent}>
          {!!icon && icon}
          <span className={classes.renderedText}>
            {valueLabel || ''}
            {!!valueLabel ? ': ' : ''}
            {valueArr.join(', ')}
          </span>
        </Box>
      )}
    </div>
  )
}
