import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllOrderStatsFieldsFragment = { __typename: 'OrderStats', revenue: number, totalOrders: number, totalCustomers: number };

export type QueryCampaignOrderStatsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID'];
  timezone: Types.Scalars['String'];
  currentOrdersInput?: Types.InputMaybe<Types.OrdersInput>;
  previousOrdersInput?: Types.InputMaybe<Types.OrdersInput>;
}>;


export type QueryCampaignOrderStatsQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, currentOrderStats?: { __typename: 'OrderStats', revenue: number, totalOrders: number, totalCustomers: number } | null, previousOrderStats?: { __typename: 'OrderStats', revenue: number, totalOrders: number, totalCustomers: number } | null, orderStatsTimeseries?: Array<{ __typename: 'OrderStatsTimeseries', startDate: Date, endDate: Date, revenue: number, totalOrders: number, totalCustomers: number }> | null } | null };

export const AllOrderStatsFieldsFragmentDoc = gql`
    fragment AllOrderStatsFields on OrderStats {
  revenue
  totalOrders
  totalCustomers
}
    `;
export const QueryCampaignOrderStatsDocument = gql`
    query QueryCampaignOrderStats($campaignId: ID!, $timezone: String!, $currentOrdersInput: OrdersInput, $previousOrdersInput: OrdersInput) {
  campaign(id: $campaignId) {
    id
    currentOrderStats: orderStats(where: $currentOrdersInput) {
      ...AllOrderStatsFields
    }
    previousOrderStats: orderStats(where: $previousOrdersInput) {
      ...AllOrderStatsFields
    }
    orderStatsTimeseries(timezone: $timezone, where: $currentOrdersInput) {
      startDate
      endDate
      revenue
      totalOrders
      totalCustomers
    }
  }
}
    ${AllOrderStatsFieldsFragmentDoc}`;

/**
 * __useQueryCampaignOrderStatsQuery__
 *
 * To run a query within a React component, call `useQueryCampaignOrderStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCampaignOrderStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCampaignOrderStatsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      timezone: // value for 'timezone'
 *      currentOrdersInput: // value for 'currentOrdersInput'
 *      previousOrdersInput: // value for 'previousOrdersInput'
 *   },
 * });
 */
export function useQueryCampaignOrderStatsQuery(baseOptions: Apollo.QueryHookOptions<QueryCampaignOrderStatsQuery, QueryCampaignOrderStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryCampaignOrderStatsQuery, QueryCampaignOrderStatsQueryVariables>(QueryCampaignOrderStatsDocument, options);
      }
export function useQueryCampaignOrderStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryCampaignOrderStatsQuery, QueryCampaignOrderStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryCampaignOrderStatsQuery, QueryCampaignOrderStatsQueryVariables>(QueryCampaignOrderStatsDocument, options);
        }
export type QueryCampaignOrderStatsQueryHookResult = ReturnType<typeof useQueryCampaignOrderStatsQuery>;
export type QueryCampaignOrderStatsLazyQueryHookResult = ReturnType<typeof useQueryCampaignOrderStatsLazyQuery>;
export type QueryCampaignOrderStatsQueryResult = Apollo.QueryResult<QueryCampaignOrderStatsQuery, QueryCampaignOrderStatsQueryVariables>;