import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { ReviewProgramRowFragmentDoc } from './review-program-row.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProgramsUserQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.ProgramFilterInput>;
  sortBy?: Types.InputMaybe<Types.ProgramSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type ProgramsUserQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number } | null, programs: { __typename: 'PagedProgramType', total: number, cursor?: string | null, results: Array<{ __typename: 'ProgramType', id: string, name: string, pendingPosts?: number | null, account?: { __typename: 'AccountType', id: number, name: string } | null, campaign?: { __typename: 'CampaignType', id: number } | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string } }>, contentRules?: { __typename: 'ProgramContentRulesType', id: string, goodExampleUrl?: string | null, badExampleUrl?: string | null, brandPhoto?: string | null } | null, reviewLock?: { __typename: 'ReviewLockType', id: string, updatedAt?: Date | null, user?: { __typename: 'UserType', id: number, email: string } | null } | null }> } };


export const ProgramsUserDocument = gql`
    query ProgramsUser($limit: Int, $cursor: String, $where: ProgramFilterInput, $sortBy: ProgramSort, $sortDirection: SortDirection) {
  whoami {
    id
  }
  programs(
    limit: $limit
    cursor: $cursor
    where: $where
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    total
    cursor
    results {
      ...ReviewProgramRow
    }
  }
}
    ${ReviewProgramRowFragmentDoc}`;

/**
 * __useProgramsUserQuery__
 *
 * To run a query within a React component, call `useProgramsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsUserQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useProgramsUserQuery(baseOptions?: Apollo.QueryHookOptions<ProgramsUserQuery, ProgramsUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramsUserQuery, ProgramsUserQueryVariables>(ProgramsUserDocument, options);
      }
export function useProgramsUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramsUserQuery, ProgramsUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramsUserQuery, ProgramsUserQueryVariables>(ProgramsUserDocument, options);
        }
export type ProgramsUserQueryHookResult = ReturnType<typeof useProgramsUserQuery>;
export type ProgramsUserLazyQueryHookResult = ReturnType<typeof useProgramsUserLazyQuery>;
export type ProgramsUserQueryResult = Apollo.QueryResult<ProgramsUserQuery, ProgramsUserQueryVariables>;