import React, { FC, useState } from 'react'
import { Box, Checkbox, Link, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Link as RouterLink } from 'react-router-dom'
import { currencyCodeToSymbol, numberFormat } from '../../utils/number-format'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { format, formatDistanceToNow } from 'date-fns'
import Tooltip from '../../components/Tooltip/Tooltip'
import { Column } from './columns'
import { ControlledListActionMenu, ListActionMenuProps } from '../lists/ListActionMenu'
import { FbUser, ParticipantSort, PaymentStatusEnum } from '../../gql-global'
import StatusAction from './StatusActions'
import StoriesAvatarV2 from '../CustomerAvatar/StoriesAvatarV2'
import theme, { error } from '../../loudcrowd-theme'
import { SkeletonProps } from '@material-ui/lab/Skeleton/Skeleton'
import { paymentPeriodsNextPayoutFormat } from '../../utils/date-format'

export type UsernameColumnType =
  | ParticipantSort.IgUsername
  | ParticipantSort.TtUsername
  | ParticipantSort.FullName
  | ParticipantSort.Email

const CustomerTableCell: FC<{
  className?: string
  loading?: boolean
  skeletonProps?: SkeletonProps
}> = ({ children, className = '', loading = false, skeletonProps = { width: 50 } }) => {
  const classes = useStyles()
  return (
    <TableCell className={className || classes.cell}>{loading ? <Skeleton {...skeletonProps} /> : children}</TableCell>
  )
}

function DateTableCellContent({
  date,
  styleClasses,
}: {
  date?: Date | null
  styleClasses: ReturnType<typeof useStyles>
}): React.ReactElement | null {
  return date ? (
    <Tooltip title={format(date, 'PPp')} placement="top-start">
      <Typography className={styleClasses.underline} variant="body2">
        {formatDistanceToNow(date, { addSuffix: true })}
      </Typography>
    </Tooltip>
  ) : null
}

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  pending: {
    fontStyle: 'italic',
  },
  owedCommissions: {
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    color: error[700],
  },
  infoIcon: {
    color: theme.palette.primary.main,
  },
  infoIconContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  maxPostDateCell: {
    display: 'flex',
    alignItems: 'center',
  },
  underline: {
    textDecoration: 'underline',
  },
  actionButtonContainer: {
    position: 'absolute',
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
})

const formatMoney = (amt: number | null | undefined, currencyCode: string) => {
  const currencySymbol = currencyCodeToSymbol(currencyCode)
  return `${currencySymbol}${(amt ?? 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
export interface CustomerRowProps<K extends string>
  extends Partial<Pick<ListActionMenuProps<K>, 'actions' | 'deleteAction'>> {
  hasCampaigns?: boolean
  fbUsers?: FbUser[] | null
  columns: readonly Column[]
  customerId?: number | null
  currencyCode?: string
  entityId?: number | null
  entity?: 'participant' | 'shoppableParticipant' | 'applicant' | 'customer' | null
  username?: string | null
  usernameColumn?: UsernameColumnType
  // This type is the type of an SVG file imported as a Component
  UsernameIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
  postCount?: number | null
  maxPostDate?: Date | null
  impressions?: number | null
  avgEngagementRate?: number | null
  followerCount?: number | null
  pageViews?: number | null
  salesOrders?: { approved: number; awaiting_approval: number }
  salesRevenue?: { approved: number; awaiting_approval: number }
  conversionRate?: number | null
  paymentStatuses?: Set<PaymentStatusEnum>
  ordersAwaitingApproval?: boolean | null
  paidCommissions?: number | null
  pendingCommissions?: number | null
  owedCommissions?: number | null
  nextPaymentDate?: Date
  loading?: boolean
  avatarUrl?: string | null
  signupDate?: Date | null
  approvedAt?: Date | null
  selectable?: boolean
  selected?: boolean
  status?: 'PENDING' | 'APPROVED' | 'REJECTED' | null
  onSelect?: ((entityId: number, value: boolean) => void) | null
  onSelectAction?(entityId: number, action: K): void
  //TODO: remove useCustomerPageLinks when we have the marketing page for customer details
  useCustomerPageLinks?: boolean
  isFirstRow?: boolean
  hasActiveStory?: boolean
  programCommissionTierName?: string
}

function CustomerRow<K extends string>({
  columns,
  customerId,
  entityId,
  entity,
  username,
  usernameColumn,
  UsernameIcon,
  postCount,
  maxPostDate,
  impressions,
  avgEngagementRate,
  followerCount,
  pageViews,
  salesOrders,
  salesRevenue,
  conversionRate,
  paymentStatuses,
  ordersAwaitingApproval,
  paidCommissions,
  pendingCommissions,
  owedCommissions,
  nextPaymentDate,
  avatarUrl,
  signupDate,
  approvedAt,
  actions,
  status,
  deleteAction,
  onSelectAction,
  currencyCode = 'USD',
  selectable = false,
  selected = false,
  onSelect = null,
  loading = false,
  useCustomerPageLinks = false,
  isFirstRow = false,
  hasActiveStory = false,
  programCommissionTierName = undefined,
}: CustomerRowProps<K>): React.ReactElement {
  usernameColumn = usernameColumn || ParticipantSort.IgUsername
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  let linkProps = {}
  if (username) {
    linkProps = useCustomerPageLinks
      ? { to: { pathname: `${CUSTOMER_ROUTE.path}/${customerId}`, state: { entity } }, component: RouterLink }
      : { href: `https://www.instagram.com/${username}`, target: '_blank', rel: 'noopener noreferrer' }
  }
  // If no payment statuses are selected it's the same as though them all were selected
  paymentStatuses = paymentStatuses?.size
    ? paymentStatuses
    : new Set<PaymentStatusEnum>([PaymentStatusEnum.Paid, PaymentStatusEnum.Pending, PaymentStatusEnum.Owed])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const id = entityId || customerId
    if (id && onSelect) {
      onSelect(id, event.target.checked)
    }
  }

  const showPaidCommissions = paymentStatuses?.has(PaymentStatusEnum.Paid)
  const showPendingCommissions = paymentStatuses?.has(PaymentStatusEnum.Pending) && !!pendingCommissions
  const showOwedCommissions = paymentStatuses?.has(PaymentStatusEnum.Owed) && !!owedCommissions
  const showApprovedFigures = ordersAwaitingApproval !== true

  const renderCustomerCell = (
    c: Column,
  ): { customerTableCellProps?: React.ComponentProps<typeof CustomerTableCell>; cell?: JSX.Element | string } => {
    const dateTableCellProps = { className: classes.maxPostDateCell, skeletonProps: { width: 60 } }
    switch (c) {
      case 'signupDate':
        return {
          customerTableCellProps: dateTableCellProps,
          cell: <DateTableCellContent date={signupDate} styleClasses={classes} />,
        }
      case 'approvedAt':
        return {
          customerTableCellProps: dateTableCellProps,
          cell: <DateTableCellContent date={approvedAt} styleClasses={classes} />,
        }
      case 'posts':
        return { cell: postCount?.toLocaleString() }
      case 'followers':
        return { cell: followerCount?.toLocaleString() }
      case 'maxPostDate':
        return {
          customerTableCellProps: dateTableCellProps,
          cell: <DateTableCellContent date={maxPostDate} styleClasses={classes} />,
        }
      case 'avgEngagementRate':
        return { cell: numberFormat(avgEngagementRate || 0, { format: 'percent' }) }
      case 'impressions':
        return { cell: impressions?.toLocaleString() }
      case 'status':
        return {
          cell: (
            <StatusAction
              type={status}
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onCancel={() => setIsOpen(false)}
              actions={actions}
              onSelectAction={action => {
                setIsOpen(false)
                onSelectAction && entityId && onSelectAction(entityId, action)
              }}
            />
          ),
        }
      case 'conversionRate':
        return { cell: `${conversionRate?.toLocaleString()}%` }
      case 'pageViews':
        return { cell: pageViews?.toLocaleString() }
      case 'salesOrders':
        return {
          cell: (
            <Box>
              {showApprovedFigures && (
                <Box>
                  <Typography>{`${salesOrders?.approved ?? 0} Approved`}</Typography>
                </Box>
              )}
              {ordersAwaitingApproval === true || !salesOrders?.awaiting_approval ? null : (
                <Box>
                  <Typography variant="inherit" className={classes.pending}>
                    {showApprovedFigures ? '+' : null}
                    {`${salesOrders?.awaiting_approval} Awaiting approval`}
                  </Typography>
                </Box>
              )}
            </Box>
          ),
        }
      case 'salesRevenue':
        return {
          cell: (
            <Box>
              {showApprovedFigures && (
                <Box>
                  <Typography>{`${formatMoney(salesRevenue?.approved, currencyCode)} Approved`}</Typography>
                </Box>
              )}
              {ordersAwaitingApproval === true || !salesRevenue?.awaiting_approval ? null : (
                <Box>
                  <Typography variant="inherit" className={classes.pending}>
                    {showApprovedFigures ? '+' : null}
                    {`${formatMoney(salesRevenue?.awaiting_approval, currencyCode)} Awaiting approval`}
                  </Typography>
                </Box>
              )}
            </Box>
          ),
        }
      case 'commissions':
        return {
          cell: (
            <Box>
              {showPaidCommissions && (
                <Box>
                  <Typography>{`${formatMoney(paidCommissions, currencyCode)} Paid`}</Typography>
                </Box>
              )}
              {showPendingCommissions && (
                <Box>
                  <Typography variant="inherit" className={classes.pending}>
                    {showPaidCommissions ? '+' : null}
                    {`${formatMoney(pendingCommissions, currencyCode)}`} Next Payout{' '}
                    {paymentPeriodsNextPayoutFormat(nextPaymentDate)}
                  </Typography>
                </Box>
              )}
              {showOwedCommissions && (
                <Box>
                  <Typography variant="inherit" className={classes.owedCommissions}>
                    {showPaidCommissions || showPendingCommissions ? '+' : null}
                    {`${formatMoney(owedCommissions, currencyCode)}`} Owed
                  </Typography>
                </Box>
              )}
              {programCommissionTierName && (
                <Box>
                  <Typography variant="subtitle2">{`Tier: ${programCommissionTierName}`}</Typography>
                </Box>
              )}
            </Box>
          ),
        }
    }
  }

  return (
    <TableRow
      hover
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ display: 'contents' }}
    >
      {selectable && (
        <TableCell className={classes.cell}>
          {loading ? (
            <Box p={2}>
              <Skeleton width={16} height={16} variant="rect" />
            </Box>
          ) : (
            <Checkbox
              data-intercom-target={isFirstRow ? 'Customer First Row Checkbox' : undefined}
              data-testid={`checkbox-${customerId}`}
              color="primary"
              checked={selected}
              onChange={handleChange}
            />
          )}
        </TableCell>
      )}
      <TableCell className={classes.cell}>
        <StoriesAvatarV2
          loading={loading}
          hasActiveStory={hasActiveStory}
          avatarUrl={avatarUrl}
          linkProps={linkProps}
          size="small"
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <Box>
          <Typography variant="body2" color="primary">
            {loading && <Skeleton width={100} />}
            {!loading && (
              <Link {...linkProps}>
                {UsernameIcon && (
                  <Box className={classes.infoIconContainer}>
                    <UsernameIcon width={16} height={16} className={classes.infoIcon} />
                  </Box>
                )}
                {[ParticipantSort.IgUsername, ParticipantSort.TtUsername].includes(usernameColumn)
                  ? `@${username}`
                  : username}
              </Link>
            )}
          </Typography>
          <Box>
            {loading && <Skeleton width={100} />}
            {!loading && (entity === 'participant' || entity === 'shoppableParticipant') && !!approvedAt && (
              <Typography style={{ lineHeight: 1 }} variant="caption">
                Joined {format(approvedAt, 'MM/dd/yyyy')}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      {columns.map((c, i, a) => {
        const { customerTableCellProps, cell } = renderCustomerCell(c)
        const shouldDisplayMenu = i + 1 === a.length && (isHovered || isOpen) && entity !== 'applicant'
        return (
          <CustomerTableCell loading={loading} {...(customerTableCellProps ?? {})} key={c}>
            {!loading && actions?.length && shouldDisplayMenu && (
              <div className={classes.actionButtonContainer}>
                <ControlledListActionMenu
                  actions={actions}
                  deleteAction={deleteAction}
                  onSelectAction={action => {
                    setIsOpen(false)
                    setIsHovered(false)
                    onSelectAction && entityId && onSelectAction(entityId, action)
                  }}
                  open={isOpen}
                  onOpen={() => setIsOpen(true)}
                  onCancel={() => {
                    setIsOpen(false)
                    setIsHovered(false)
                  }}
                />
              </div>
            )}
            <div className={shouldDisplayMenu ? classes.visibilityHidden : undefined}>{cell}</div>
          </CustomerTableCell>
        )
      })}
    </TableRow>
  )
}

export default CustomerRow
