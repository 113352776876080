import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FulfillmentAccountType, FulfillAccountType } from '../gql-global'
import { TREMENDOUS_OAUTH_BASE, PLATFORM_ITEMS } from './constants'
import { TREMENDOUS_CALLBACK_ROUTE } from './routes'

const INITIAL_INTEGRATION = {
  accountType: FulfillmentAccountType.Mandrill.toString(),
  apiSecret: '',
  apiUrl: '',
  appId: '',
  fromEmail: '',
  fromName: '',
  guid: '',
  key: '',
  namespace: '',
  smsConsentList: '',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 300,
    },
    deleteIntegrationButton: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.dark}`,
    },
    dialogueActions: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 14px 10px',
    },
  }),
)

export interface EditIntegrationProps {
  open: boolean
  handleClose: () => void
  handleSave: (
    integrationId: number | null,
    accountType: string,
    key: string,
    fromEmail: string,
    fromName: string,
    guid: string,
    apiUrl: string,
    apiSecret: string,
    appId: string,
    smsConsentList: string | null,
    namespace: string | null,
  ) => void
  handleDelete: (integrationId: number) => void
  integration: Pick<
    FulfillAccountType,
    | 'id'
    | 'fromName'
    | 'guid'
    | 'apiUrl'
    | 'apiSecret'
    | 'appId'
    | 'key'
    | 'accountType'
    | 'fromEmail'
    | 'smsConsentList'
    | 'namespace'
  > | null
}

const AddEditIntegration: React.FC<EditIntegrationProps> = ({
  open,
  handleClose,
  handleSave,
  handleDelete,
  integration,
}) => {
  const classes = useStyles()

  const [newKey, setNewKey] = useState('')
  const [newFromEmail, setNewFromEmail] = useState('')
  const [newFromName, setNewFromName] = useState('')
  const [newGuid, setNewGuid] = useState('')
  const [newApiUrl, setNewApiUrl] = useState('')
  const [newApiSecret, setNewApiSecret] = useState('')
  const [newAccountType, setNewAccountType] = useState(FulfillmentAccountType.Mandrill.toString())
  const [newAppId, setNewAppId] = useState('')
  const [newSmsConsentList, setNewSmsConsentList] = useState('')
  const [newNamespace, setNewNamespace] = useState('')

  useEffect(() => {
    const { key, accountType, fromEmail, fromName, guid, apiUrl, apiSecret, appId, smsConsentList, namespace } =
      integration || INITIAL_INTEGRATION
    setNewKey(key || '')
    setNewAccountType(accountType)
    setNewFromEmail(fromEmail || '')
    setNewFromName(fromName || '')
    setNewGuid(guid || '')
    setNewApiUrl(apiUrl || '')
    setNewApiSecret(apiSecret || '')
    setNewAppId(appId || '')
    setNewSmsConsentList(smsConsentList || '')
    setNewNamespace(namespace || '')
  }, [integration])

  const isAdd = !integration
  const needsFromEmail = [
    FulfillmentAccountType.Mandrill.toString(),
    FulfillmentAccountType.Sendinblue.toString(),
  ].includes(newAccountType)
  const needsGuid = newAccountType === FulfillmentAccountType.Yotpo.toString()
  const needsApiUrl =
    newAccountType === FulfillmentAccountType.Webhook.toString() ||
    newAccountType === FulfillmentAccountType.Braze.toString()
  const needsApiSecret =
    newAccountType === FulfillmentAccountType.Growave.toString() ||
    newAccountType === FulfillmentAccountType.Emarsys.toString() ||
    newAccountType === FulfillmentAccountType.LoyaltyLion.toString()
  const needsOauth = newAccountType === FulfillmentAccountType.Tremendous.toString()
  const needsNamespace = newAccountType === FulfillmentAccountType.Bluecore.toString()

  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <DialogTitle>{`${isAdd ? 'Add' : 'Edit'} Integration`}</DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <FormControl className={classes.formControl}>
          <InputLabel id="platform">Platform</InputLabel>
          <Select
            labelId="platform"
            value={newAccountType.toString()}
            onChange={({ target: { value } }) => setNewAccountType(value as string)}
          >
            {PLATFORM_ITEMS.map(platform => (
              <MenuItem value={platform.value} key={platform.value}>
                {platform.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {needsApiUrl && (
          <TextField
            label="API Url"
            type="text"
            value={newApiUrl}
            onChange={({ target: { value } }) => setNewApiUrl(value)}
            className={classes.formControl}
          />
        )}
        {!needsOauth && (
          <TextField
            label="API Key"
            type="text"
            value={newKey}
            onChange={({ target: { value } }) => setNewKey(value)}
            className={classes.formControl}
          />
        )}
        {needsFromEmail && (
          <TextField
            label="From Email"
            type="text"
            value={newFromEmail}
            onChange={({ target: { value } }) => setNewFromEmail(value)}
            className={classes.formControl}
          />
        )}
        {needsGuid && (
          <TextField
            label="GUID"
            type="text"
            value={newGuid}
            onChange={({ target: { value } }) => setNewGuid(value)}
            className={classes.formControl}
          />
        )}
        {needsApiSecret && (
          <TextField
            label="API Secret"
            type="text"
            value={newApiSecret}
            onChange={({ target: { value } }) => setNewApiSecret(value)}
            className={classes.formControl}
          />
        )}
        {needsNamespace && (
          <TextField
            label="Namespace"
            type="text"
            value={newNamespace}
            onChange={({ target: { value } }) => setNewNamespace(value)}
            className={classes.formControl}
          />
        )}
        {newAccountType === FulfillmentAccountType.Klaviyo.toString() && (
          <TextField
            label="Consent List (required for SMS/Email conset tracking)"
            type="text"
            value={newSmsConsentList}
            onChange={({ target: { value } }) => setNewSmsConsentList(value)}
            className={classes.formControl}
          />
        )}
        {needsOauth && (
          <a
            href={`${TREMENDOUS_OAUTH_BASE}&redirect_uri=${window.location.origin}${TREMENDOUS_CALLBACK_ROUTE.path}`}
            rel="noreferrer"
          >
            follow these steps
          </a>
        )}
      </DialogContent>
      <DialogActions className={integration ? classes.dialogueActions : undefined}>
        {integration && (
          <Button onClick={() => handleDelete(integration.id)} className={classes.deleteIntegrationButton}>
            Remove Integration
          </Button>
        )}
        <div>
          {!needsOauth && (
            <Button
              onClick={() =>
                handleSave(
                  integration?.id || null,
                  newAccountType,
                  newKey,
                  newFromEmail,
                  newFromName,
                  newGuid,
                  newApiUrl,
                  newApiSecret,
                  newAppId,
                  newSmsConsentList,
                  newNamespace,
                )
              }
              color="primary"
              disabled={
                !newKey ||
                (needsFromEmail && !newFromEmail) ||
                (needsGuid && !newGuid) ||
                (needsApiUrl && !newApiUrl) ||
                (needsApiSecret && !newApiSecret)
              }
            >
              Save
            </Button>
          )}
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditIntegration
