import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { MessageTemplateFragmentFragmentDoc } from './message-template-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageTemplatesListQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where: Types.MessageTemplateFilterInput;
  statsWhere: Types.MessageTemplateStatsFilterInput;
}>;


export type MessageTemplatesListQuery = { __typename: 'Query', socialAccount?: { __typename: 'IGSocialAccount', id: string, messageTemplates?: { __typename: 'PagedMessageTemplate', total: number, cursor?: string | null, results: Array<{ __typename: 'MessageTemplate', id: string, name: string, template: string, skipSendMessage: Types.SkipMessageTemplateActionEnum, skipActionTimeBuffer?: number | null, customerTriggerQuantity?: number | null, automation?: { __typename: 'Automation', id: string, enabled: boolean, trigger: { __typename: 'MentionedTrigger', id: string, filter?: { __typename: 'ContentFilterType', id: string, name: string, entity: Types.FilterEntity, isSystem: boolean, value?: { __typename: 'ContentFilterValueType', followerCount?: { __typename: 'IntegerRangeFilter', gte?: number | null, lte?: number | null } | null, segments?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, campaigns?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, biography?: { __typename: 'KeywordsFilter', any?: Array<string> | null, none?: Array<string> | null } | null } | null } | null }, action: { __typename: 'AutomationAction', id: string } } | null, stats?: { __typename: 'MessageTemplateStats', totalRecipients: number } | null, reward?: { __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesInCurrentBatch?: number | null, codesRemaining?: number | null, codesLevel?: Types.CodesLevel | null } } | null }> } | null } | { __typename: 'TTSocialAccount', id: string } | null };


export const MessageTemplatesListDocument = gql`
    query MessageTemplatesList($socialAccountId: ID!, $limit: Int!, $cursor: String, $where: MessageTemplateFilterInput!, $statsWhere: MessageTemplateStatsFilterInput!) {
  socialAccount(id: $socialAccountId) {
    id
    ... on IGSocialAccount {
      messageTemplates(limit: $limit, cursor: $cursor, where: $where) {
        total
        cursor
        results {
          ...MessageTemplateFragment
        }
      }
    }
  }
}
    ${MessageTemplateFragmentFragmentDoc}`;

/**
 * __useMessageTemplatesListQuery__
 *
 * To run a query within a React component, call `useMessageTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageTemplatesListQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      statsWhere: // value for 'statsWhere'
 *   },
 * });
 */
export function useMessageTemplatesListQuery(baseOptions: Apollo.QueryHookOptions<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>(MessageTemplatesListDocument, options);
      }
export function useMessageTemplatesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>(MessageTemplatesListDocument, options);
        }
export type MessageTemplatesListQueryHookResult = ReturnType<typeof useMessageTemplatesListQuery>;
export type MessageTemplatesListLazyQueryHookResult = ReturnType<typeof useMessageTemplatesListLazyQuery>;
export type MessageTemplatesListQueryResult = Apollo.QueryResult<MessageTemplatesListQuery, MessageTemplatesListQueryVariables>;