import React from 'react'
import { Box, Dialog } from '@material-ui/core'
import { RewardRowFragment } from '../RewardList/operations/rewards.generated'
import RewardList from '../RewardList/RewardList'

interface SendRewardDialogProps {
  open: boolean
  onClose(): void
  onSendReward(reward: RewardRowFragment, offline: boolean): void
  canAutoDeliver?: boolean
  canCopyCode?: boolean
}

function SendRewardDialog({
  open,
  onClose,
  onSendReward,
  canCopyCode = false,
  canAutoDeliver = false,
}: SendRewardDialogProps): React.ReactElement {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="send-reward-title"
      aria-describedby="send-reward-title"
      maxWidth={false}
    >
      <Box p={9} width={520} height={600}>
        <RewardList
          onSendReward={onSendReward}
          onClose={onClose}
          canCopyCode={canCopyCode}
          canAutoDeliver={canAutoDeliver}
        />
      </Box>
    </Dialog>
  )
}

export default SendRewardDialog
