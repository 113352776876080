import React from 'react'
import { makeStyles, createStyles, Theme, Typography, Box, Button } from '@material-ui/core'

import { SelectField, SelectFieldOption } from '../components/SelectField/SelectField'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import { ReactComponent as Cross } from '../icons/cross.svg'
import { ChallengeRewardCriteriaEnum, CreateChallengeRewardInput } from '../gql-global'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.primary.main,
      margin: '10px 0',
    },
    helpText: {
      color: theme.palette.secondary.main,
      fontSize: 12,
      marginTop: 5,
      position: 'absolute',
      whiteSpace: 'nowrap',
    },
    button: {
      marginLeft: -8,
    },
    cross: {
      marginLeft: -25,
      minWidth: 0,
    },
    error: {
      position: 'absolute',
      top: 39,
      color: theme.palette.error.main,
      fontSize: 12,
    },
  }),
)

type ChallengeReward = SelectFieldOption & { hasIntegration: boolean }

interface SelectEntityProps {
  label: string
  rewards?: Array<CreateChallengeRewardInput> | null
  errors?: string | null
  setRewards: (value: Array<CreateChallengeRewardInput>) => void
  availableRewards: Array<ChallengeReward>
  disabled?: boolean
  challengeHasAdditionalCriteria?: boolean
}

export const ChallengeRewardSelector: React.FC<SelectEntityProps> = props => {
  const classes = useStyles()
  const {
    label,
    rewards,
    availableRewards,
    errors,
    setRewards,
    disabled = false,
    challengeHasAdditionalCriteria = false,
  } = props

  const criteriaOptions = [
    {
      id: ChallengeRewardCriteriaEnum.Participating,
      label: 'Automatically by participating',
      helpText: challengeHasAdditionalCriteria
        ? 'Rewards will send immediately when posts are approved for this challenge.'
        : 'Rewards will send immediately when above post criteria are met.',
    },
    {
      id: ChallengeRewardCriteriaEnum.HandPicked,
      label: 'I want to hand-pick my winners',
      helpText: 'Great! We’ll set up filters on the Content page to make this easier.',
    },
    {
      id: ChallengeRewardCriteriaEnum.SpecificCriteria,
      label: 'Automatically by meeting my specific criteria',
      helpText: 'You’ll be able to enter your criteria on the next step.',
    },
  ]

  const handleChange = (index: number, value: string | number, field: string) => {
    const values = rewards?.map((item, key) => {
      if (key !== index) {
        return item
      }
      return { ...item, [field]: value }
    })
    setRewards(values ?? [])
  }

  const handleFieldsChange = (index: number, rewardId: string, winningCriteria?: ChallengeRewardCriteriaEnum) => {
    const values = rewards?.map((item, key) => {
      if (key !== index) {
        return item
      }
      return { ...item, rewardId, winningCriteria }
    })
    setRewards(values ?? [])
  }

  const handleRemove = (index: number) => {
    const values = rewards?.filter((_, key) => key !== index)
    setRewards(values?.length ? values : [{ rewardId: '', winningCriteria: undefined }])
  }

  // Exclude the options if they have already been selected.
  // Don't exclude the selected option for the provided rowValue
  const filterOptions = (rowValue: string): SelectFieldOption[] => {
    const filteredRewards = rewards
      ? availableRewards.filter(availableReward => {
          const found = rewards?.find(r => r?.rewardId?.toString() === availableReward.id.toString())
          return rowValue ? !found || rowValue.toString() === found?.rewardId.toString() : !found
        })
      : availableRewards

    return filteredRewards
  }

  const filterCriteriaOptions = (rewardSelected?: string) => {
    const hasIntegration = availableRewards.find(({ id }) => id === rewardSelected)?.hasIntegration ?? false
    return criteriaOptions.map(option => ({
      ...option,
      disabled: !!(
        rewardSelected &&
        !hasIntegration &&
        (option.id === ChallengeRewardCriteriaEnum.Participating ||
          option.id === ChallengeRewardCriteriaEnum.SpecificCriteria)
      ),
    }))
  }

  return (
    <Box position="relative">
      <Typography className={classes.label}>{label}</Typography>

      {rewards?.map(({ rewardId, winningCriteria }, key) => (
        <Box display="flex" alignItems="center" gridColumnGap={35} mb={5} key={key} position="relative">
          <Box minWidth={300}>
            <SelectField
              menuLabel="Rewards"
              placeholder="Select a reward"
              searchPlaceholder="Search rewards..."
              value={rewardId}
              setValue={value => {
                const hasIntegration = availableRewards.find(({ id }) => id === value)?.hasIntegration ?? false
                if (
                  !hasIntegration &&
                  (winningCriteria === ChallengeRewardCriteriaEnum.Participating ||
                    winningCriteria === ChallengeRewardCriteriaEnum.SpecificCriteria)
                ) {
                  handleFieldsChange(key, String(value), undefined)
                } else {
                  handleChange(key, value, 'rewardId')
                }
              }}
              options={filterOptions(rewardId)}
              disabled={disabled}
            />
          </Box>
          <Box minWidth={300} position="relative">
            <SelectField
              hideSearch
              placeholder="How can someone earn it?"
              value={winningCriteria}
              setValue={value => handleChange(key, value, 'winningCriteria')}
              options={filterCriteriaOptions(rewardId)}
              disabled={disabled}
            />
            {winningCriteria && (
              <Typography className={classes.helpText}>
                {criteriaOptions.find(({ id }) => winningCriteria === id)?.helpText}
              </Typography>
            )}
          </Box>
          {(winningCriteria || rewardId || key > 0) && (
            <Button
              variant="text"
              color="secondary"
              className={classes.cross}
              onClick={() => handleRemove(key)}
              disabled={disabled}
            >
              <Cross width={16} height={16} />
            </Button>
          )}
          {!!errors && (!rewardId || !winningCriteria) && <div className={classes.error}>{errors}</div>}
        </Box>
      ))}

      <Button
        size="large"
        variant="text"
        color="primary"
        className={classes.button}
        startIcon={<PlusIcon width={16} height={16} />}
        onClick={() => setRewards([...(rewards ?? []), {} as CreateChallengeRewardInput])}
        disabled={disabled}
      >
        Add another reward
      </Button>
    </Box>
  )
}
