import React from 'react'

import { SingleSelectionOption } from '../lists/ListFilters/types'
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg'
import MenuPicker from '../MenuPicker'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { error } from '../../loudcrowd-theme'

const useStyles = makeStyles(() =>
  createStyles({
    warning: {
      fontWeight: 600,
      fontSize: '0.75rem',
      color: error[600],
      marginTop: 10,
    },
    emptyWarning: {
      fontWeight: 600,
      fontSize: '0.75rem',
      color: error[600],
      marginLeft: 20,
    },
  }),
)

interface RewardPickerProps {
  option: SingleSelectionOption
  value: string
  disabled: boolean
  onChange: (value: string) => void
  warning?: string
}

export default function RewardPicker(props: RewardPickerProps): JSX.Element {
  const { warning, value } = props
  const classes = useStyles()
  return (
    <>
      <MenuPicker
        adornmentIcon={<TrophyIcon width={16} height={16} />}
        noOptionsMessage="No DM rewards available. Create them in Settings -> Rewards."
        labelIcon={<PlusIcon width={14} />}
        label="Attach reward"
        menuTitle="Rewards"
        {...props}
      />
      <Typography variant="body2" component="p" className={value ? classes.warning : classes.emptyWarning}>
        {warning}
      </Typography>
    </>
  )
}
