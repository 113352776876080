import React, { useRef, useState } from 'react'
import { SegmentSort, SortDirection, ProgramSort } from '../../gql-global'
import { isApolloError } from '@apollo/client'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, Box, MenuItem, Menu, Typography } from '@material-ui/core'
import LabelMenu from '../../components/LabelMenu'
import { useAccountSegmentsQuery } from '../../settings/segments/operations/account-segments.generated'
import { useAccountProgramsQuery } from '../../settings/programs/operations/account-programs.generated'
import { useUpdateSegmentMutation } from '../../mutations/operations/update-segment.generated'
import { useDeleteSegmentMutation } from '../../mutations/operations/delete-segment.generated'
import { useCreateSegmentMutation } from '../../settings/segments/operations/create-segment.generated'
import { useCreateCustomerSourceJobMutation } from './operations/create-customer-source-job.generated'
import useTitle from '../../utils/use-title'
import withAuthorization from '../../withAuthorization'
import { ADMIN_CUSTOMER_UPLOAD_ROUTE } from '../routes'
import { uploadFileToS3 } from '../../utils/aws'
import { useCustomerSourceJobQuery } from './operations/customer-source-job.generated'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { useToast } from '../../components/Alert/ToastProvider'
import { SEGMENT_MANAGEMENT_ROUTE } from '../../settings/routes'

const SEGMENT_PAGE_SIZE = 200

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editNameField: {
      marginRight: 4,
    },
    mainBox: {
      textAlign: 'center',
    },
    secondaryBox: {
      padding: '10px',
    },
    accountFormControl: {
      marginRight: theme.spacing(3),
      minWidth: 200,
      borderRadius: '2rem',
    },
    title: {
      flex: 1,
      lineHeight: 2,
    },
    icon: {
      fill: 'white',
    },
    accountSelect: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      overflow: 'hidden',
    },
    accountInput: {
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      color: 'white',
      borderRadius: '2rem',
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
)

const SegmentUpload: React.FC = () => {
  useTitle('Segment Upload - Admin')
  const classes = useStyles()
  const [file, setFile] = useState<File | null>(null)
  const [selectedSegmentId, setSelectedSegmentId] = useState<number | null>(null)
  const step2ButtonRef = useRef<HTMLButtonElement>(null)
  const [step2Open, setStep2Open] = useState<boolean>(false)
  const [segmentsEditorOpen, setSegmentsEditorOpen] = useState(false)
  const [programsEditorOpen, setProgramsEditorOpen] = useState(false)
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(null)
  const [jobId, setJobId] = useState<string | null>(null)
  const [step, setStep] = useState<number>(1)
  const { showToast } = useToast()

  const { data: segmentData } = useAccountSegmentsQuery({
    variables: {
      limit: SEGMENT_PAGE_SIZE,
      sortBy: SegmentSort.Name,
      sortDirection: SortDirection.Asc,
    },
  })

  const { data: programData } = useAccountProgramsQuery({
    variables: {
      limit: SEGMENT_PAGE_SIZE,
      sortBy: ProgramSort.Name,
      sortDirection: SortDirection.Asc,
    },
  })

  const { data: jobPollData, error: jobPollError } = useCustomerSourceJobQuery({
    skip: !jobId || step < 4,
    variables: {
      id: jobId || '',
    },
    pollInterval: 5000,
  })

  const [createSegment] = useCreateSegmentMutation()
  const [updateSegment] = useUpdateSegmentMutation()
  const [deleteSegment] = useDeleteSegmentMutation({
    update(cache, { data: deleteSegmentData }) {
      if (!deleteSegmentData?.deleteSegment?.ok || !deleteSegmentData.deleteSegment.segment) return
      cache.evict({ id: cache.identify(deleteSegmentData.deleteSegment.segment) })
    },
  })

  const [createJob] = useCreateCustomerSourceJobMutation()

  const handleUpload = (files: FileList | null) => {
    if (files && files[0]) {
      const file = files[0]
      setFile(file)
      setStep(2)
    } else {
      setStep(1)
    }
  }

  const onAddSegment = (id: number): void => {
    setSegmentsEditorOpen(false)
    setSelectedSegmentId(id)
    setSelectedProgramId(null)
    setStep(3)
  }

  const onAddProgram = (id: string): void => {
    setProgramsEditorOpen(false)
    setSelectedProgramId(id)
    setSelectedSegmentId(null)
    setStep(3)
  }

  const onCreateSegment = (name: string): void => {
    setSegmentsEditorOpen(false)
    createSegment({ variables: { name } })
      .then(data => {
        const id = data?.data?.createSegment?.segment?.id
        if (id) {
          setSelectedSegmentId(id)
          setStep(3)
        }
      })
      .catch(() =>
        showToast({
          title: 'Error: Creating Segment',
          message: 'Something went wrong when creating this segment, please try again.',
        }),
      )
  }

  const onUpdateSegment = (id: number, name: string): void => {
    updateSegment({
      variables: {
        id: id.toString(),
        name,
      },
    }).catch(e => {
      let message = 'Something went wrong when updating this segment, please try again.'
      if (isApolloError(e) && e.graphQLErrors?.some(e => e.extensions?.code === 'DUPLICATE_KEY')) {
        message = 'Segment with this name already exists, please use that segment or pick a different name.'
      }
      showToast({ title: 'Error: Updating Segment', message })
    })
  }

  const onDeleteSegment = (id: number): void => {
    deleteSegment({
      variables: {
        segmentId: id.toString(),
      },
    }).catch(() => {
      showToast({
        title: 'Error: Deleting Segment',
        message: 'Something went wrong while deleting this segment. please try again.',
      })
    })
    if (selectedSegmentId === id) {
      setSelectedSegmentId(null)
      setStep(2)
    }
  }

  async function handleSubmit(): Promise<void> {
    if (!file) {
      return
    }
    try {
      const selectedProgram = programData?.whoami?.account?.programs?.results.find(p => p.id === selectedProgramId)
      const selectedProgramActiveCampaign = selectedProgram?.campaign?.isActive ? selectedProgram?.campaign : undefined
      const response = await createJob({
        variables: {
          job: {
            segmentId: selectedSegmentId?.toString(),
            campaignId: selectedProgramActiveCampaign?.id?.toString(),
          },
        },
      })
      const job = response.data?.createCustomerSourceJob?.job
      if (response.errors || !job || !job.uploadUrl.url) {
        showToast({
          title: 'Error submitting customers',
          message: 'Something went wrong while submitting customers. please try again.',
        })
      } else {
        await uploadFileToS3(job.uploadUrl, file)
      }
      setJobId(job?.id || null)
    } catch (e) {
      showToast({
        title: 'Error submitting customers',
        message: 'Something went wrong while submitting customers. please try again.',
      })
    }
    setStep(4)
  }

  return (
    <Box className={classes.mainBox}>
      <Box>
        <Box className={classes.secondaryBox}>
          <Button
            component="label"
            color={step === 1 ? 'primary' : undefined}
            variant={step === 1 ? 'contained' : 'outlined'}
          >
            1. Select CSV
            <input
              type="file"
              accept=".csv"
              onChange={e => handleUpload(e?.currentTarget?.files)}
              style={{ display: 'none' }}
            />
          </Button>
        </Box>
        {file && (
          <Box mb={5}>
            <Typography>{`Selected File: ${file?.name}`}</Typography>
          </Box>
        )}
      </Box>
      {step >= 2 && (
        <Box>
          <Box>
            <Button
              id="basic-button"
              aria-controls={step2Open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={step2Open ? 'true' : undefined}
              onClick={() => {
                setStep2Open(true)
              }}
              color={step === 2 ? 'primary' : undefined}
              variant={step === 2 ? 'contained' : 'outlined'}
              endIcon={<ChevronDownIcon height="16px" width="16px" />}
              ref={step2ButtonRef}
            >
              2. Select Customer Destination
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={step2ButtonRef.current}
              open={step2Open}
              onClose={() => {
                setStep2Open(false)
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem key="none" value=""></MenuItem>
              <MenuItem
                key="1"
                value="1"
                onClick={() => {
                  setStep2Open(false)
                  setSegmentsEditorOpen(true)
                  setProgramsEditorOpen(false)
                }}
              >
                Segment
              </MenuItem>
              <MenuItem
                key="2"
                value="2"
                onClick={() => {
                  setStep2Open(false)
                  setSegmentsEditorOpen(false)
                  setProgramsEditorOpen(true)
                }}
              >
                Program
              </MenuItem>
            </Menu>
          </Box>
          <Box>
            {selectedProgramId !== null && (
              <p>
                Selected Program:{' '}
                {
                  programData?.whoami?.account?.programs?.results.find(program => program.id === selectedProgramId)
                    ?.name
                }
              </p>
            )}

            {selectedSegmentId !== null && (
              <p>
                Selected Segment:{' '}
                {
                  segmentData?.whoami?.account?.segments?.results.find(segment => segment.id === selectedSegmentId)
                    ?.name
                }
              </p>
            )}
          </Box>
        </Box>
      )}
      <Box>
        {step >= 3 && (
          <Box className={classes.secondaryBox}>
            <Button
              type="submit"
              size="large"
              color={step === 3 ? 'primary' : undefined}
              variant={step === 3 ? 'contained' : 'outlined'}
              onClick={handleSubmit}
            >
              3. Upload Customers
            </Button>
          </Box>
        )}
        <Box>
          {step === 4 && (
            <p>
              {!jobPollData?.customerSourceJob?.completedAt ? 'Uploading....' : 'Done uploading customers'}
              {jobPollData?.customerSourceJob?.failedAt ? 'Failed uploading' : ''}
              {jobPollData?.customerSourceJob?.errorMessage && jobPollData?.customerSourceJob?.errorMessage !== 'null'
                ? jobPollData?.customerSourceJob?.errorMessage
                : ''}
              {segmentData?.whoami?.account?.segments?.results.find(segment => segment.id === selectedSegmentId)
                ?.name || ''}
              {programData?.whoami?.account?.programs?.results.find(program => program.id === selectedProgramId)
                ?.name || ''}
              {jobPollError ? 'did not get uploaded' : ''}
            </p>
          )}
        </Box>
      </Box>
      <LabelMenu
        entity="program"
        open={programsEditorOpen}
        variant="dialog"
        labels={programData?.whoami?.account?.programs?.results.filter(p => p?.campaign?.isActive === true) || []}
        onSelect={onAddProgram}
        onCancel={() => setProgramsEditorOpen(false)}
        selectedLabelIds={selectedProgramId ? new Set([selectedProgramId]) : undefined}
        editable={false}
      />
      <LabelMenu
        entity="segment"
        seeAllLink={SEGMENT_MANAGEMENT_ROUTE.path}
        open={segmentsEditorOpen}
        variant="dialog"
        labels={segmentData?.whoami?.account?.segments?.results || []}
        onSelect={onAddSegment}
        onCreate={onCreateSegment}
        onUpdate={onUpdateSegment}
        onDelete={onDeleteSegment}
        hasHitLimit={false}
        onCancel={() => setSegmentsEditorOpen(false)}
        selectedLabelIds={selectedSegmentId ? new Set([selectedSegmentId]) : undefined}
        editable
        allowedActions={['update', 'delete']}
      />
    </Box>
  )
}
export default withAuthorization(ADMIN_CUSTOMER_UPLOAD_ROUTE)(SegmentUpload)
