import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  SortDirection,
  CustomerSort,
  DateTimeRangeFilter,
  MentionStatus,
  IgMediaPostType,
  AnySegmentCampaignFilterInput,
  ChallengesFilterInput,
} from '../gql-global'
import { useTopCustomersQuery } from './operations/query-dashboard-top-customers.generated'
import CustomerTable from '../components/CustomerTable/CustomerTable'
import { encodeFilterParams } from '../customer/use-filter-params'
import { useDateRangeRef } from '../hooks/useDateRangeRef'
import { Box, Typography, Link, makeStyles, Theme, createStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { CUSTOMER_ROUTE } from '../customer/routes'
import { encodeSortParam } from '../customer/use-sort-param'
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg'
import ContainerError from '../components/ContainerError'
import { realizedDateRangeFromFilter } from '../utils/date-range-helper'
import { MentionTypes } from '../content/constants'
import { NetworkStatus } from '@apollo/client'
import useSortDirParam from '../customer/use-sort-dir-param'
import { isTypeName } from '../types/utility'
import { makeMentionTypesWhere, postStatusArrayFilterFromSelection } from './utils'
import useHasFeature from '../hooks/useHasFeature'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    seeAll: {
      marginLeft: theme.spacing(3),
    },
  }),
)

interface TopCustomersBlockProps {
  //TODO: remove useCustomerPageLinks when we have the marketing page for customer details
  useCustomerPageLinks: boolean
  socialAccountId?: string | null
  dateRangeFilter: DateTimeRangeFilter
  selectedMentionTypes: Set<MentionTypes> | null
  selectedPostTypes: Set<IgMediaPostType> | null
  selectedPostStatus: Set<MentionStatus> | null
  selectedChallenges?: ChallengesFilterInput | null
  hasCampaigns?: boolean
  isInstagramAccount?: boolean | null
  includePremembership: boolean
  segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null
}

const TopCustomersBlock: React.FC<TopCustomersBlockProps> = ({
  hasCampaigns = false,
  useCustomerPageLinks,
  socialAccountId,
  dateRangeFilter,
  segmentCampaignFilter = null,
  selectedChallenges = null,
  selectedMentionTypes,
  selectedPostTypes,
  selectedPostStatus,
  isInstagramAccount,
  includePremembership,
}) => {
  const theme = useTheme()
  const isSplit = useMediaQuery(theme.breakpoints.up('lg'))
  const classes = useStyles()
  const [sort, setSort] = useState<CustomerSort>(CustomerSort.Impressions)
  const [sortDir = SortDirection.Desc, setSortDir] = useSortDirParam()
  const currentPostedAtDateRange = realizedDateRangeFromFilter(dateRangeFilter)
  const reffedPostedAtDateRange = useDateRangeRef(currentPostedAtDateRange)
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const challengesIds = { challengeIds: selectedChallenges?.any?.length ? selectedChallenges : undefined }

  const whereCommon = {
    postedAt: reffedPostedAtDateRange,
    ...makeMentionTypesWhere(selectedMentionTypes, isInstagramAccount ?? false, hasTikTokHashtags),
    tagStatus: { any: postStatusArrayFilterFromSelection(selectedPostStatus) },
    postType: selectedPostTypes?.size ? { any: Array.from(selectedPostTypes) } : undefined,
    includePremembership,
    ...challengesIds,
  }
  const where = {
    ...whereCommon,
    segmentsCampaigns: segmentCampaignFilter?.length ? { all: segmentCampaignFilter } : undefined,
  }
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
    ...challengesIds,
  }
  const { loading, data, error, networkStatus } = useTopCustomersQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      sortDirection: sortDir,
      socialAccountId: socialAccountId || '',
      sortBy: sort,
      where,
      mentionsWhere: {
        ...whereCommon,
        socialAccountId: { any: [socialAccountId || ''] },
      },
      activeStoryMentionsWhere,
    },
  })

  const socialAccount = data?.socialAccount
  const list = socialAccount?.customers?.results || []
  const ready = !loading && !!socialAccountId && networkStatus !== NetworkStatus.refetch
  return (
    <div>
      <Box mb={5} display="inline-flex" alignItems="center">
        <Typography variant="h6">Top Customers</Typography>
        {useCustomerPageLinks && (
          <Link
            variant="subtitle2"
            className={classes.seeAll}
            component={RouterLink}
            to={{
              pathname: CUSTOMER_ROUTE.path,
              search: `?${encodeFilterParams({
                postedAt: dateRangeFilter,
                mentionType: selectedMentionTypes || undefined,
                tagStatus: selectedPostStatus || undefined,
                postType: selectedPostTypes || undefined,
                ...(selectedChallenges?.any?.length && { challengeIds: selectedChallenges }),
              })}&${encodeSortParam(CustomerSort.Impressions)}`,
            }}
          >
            <Box display="inline-flex" alignItems="center">
              See all
              <Box display="inline" ml={2}>
                <ArrowIcon width={12} height={12} transform="rotate(-90)" />
              </Box>
            </Box>
          </Link>
        )}
      </Box>
      {!error && (
        <CustomerTable
          size={isSplit ? 'small' : 'medium'}
          hasCampaigns={hasCampaigns}
          entity="customer"
          list={list}
          ready={ready}
          sort={sort}
          sortDir={sortDir}
          setSort={(x: CustomerSort) => {
            if (x === sort) {
              const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
              setSortDir(sortDirection)
            }
            setSort(x)
          }}
          useCustomerPageLinks={useCustomerPageLinks}
          isIGSocialAccount={socialAccount && isTypeName(socialAccount, 'IGSocialAccount')}
        />
      )}
      {error && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Could not load top customers." />
        </Box>
      )}
    </div>
  )
}

export default TopCustomersBlock
