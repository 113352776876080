export interface Segment {
  id: string
  users?: string[]
  roles?: string[]
  accountIds?: number[]
  loudcrowdEmail?: boolean
}

export interface Feature {
  id: string
  description: string
  allowed: string[]
}

export interface FeatureConfig {
  segments: Segment[]
  features: Feature[]
}

const features: FeatureConfig = {
  segments: [
    {
      //EXAMPLE USER SEGMENT DEFINITION - DO NOT DELETE THIS ONE
      id: 'exampleSegment',
      users: ['emailThatGetsTheFeature@test.com'] as string[],
      roles: ['ROLE__WITH_FEATURE'] as string[],
    },
    {
      id: 'adminSegment',
      users: [] as string[],
      roles: ['ADMIN'] as string[],
    },
    {
      id: 'betaTestersSegment',
      users: [] as string[],
      roles: ['BETA_TESTER'] as string[],
    },
    {
      id: 'engineerSegment',
      users: [
        'brian@loudcrowd.com',
        'carter@loudcrowd.com',
        'josh@loudcrowd.com',
        'rahul@loudcrowd.com',
        'ronny@loudcrowd.com',
        'nicolas@loudcrowd.com',
        'andy@loudcrowd.com',
        'joe@loudcrowd.com',
        'chris@loudcrowd.com',
        'eric@loudcrowd.com',
      ] as string[],
      roles: [] as string[],
    },
    {
      id: 'lcEmailSegment',
      loudcrowdEmail: true,
    },
    {
      id: 'fbTesterSegment',
      users: ['tester1@test.com'],
    },
    {
      id: 'googleMarketBetaTesters',
      accountIds: [718],
    },
    {
      id: 'orangeTheorySegment',
      accountIds: [564],
    },
    {
      id: 'huelSegment',
      accountIds: [893],
    },
    {
      id: 'crocsSegment',
      accountIds: [901],
    },
  ],
  features: [
    {
      //EXAMPLE FEATURE DEFINITION - DO NOT DELETE THIS ONE
      id: 'exampleFeature',
      description: 'describe this feature so we know what it does',
      allowed: ['exampleSegment'],
    },
    {
      id: 'campaignCreationTemp',
      description: 'feature to create campaigns from list',
      allowed: ['lcEmailSegment'],
    },
    {
      id: 'tiktokHashtags',
      description: 'TikTok hashtag mentions filtering',
      allowed: ['engineerSegment'],
    },
    {
      id: 'ltvPercentChange',
      description: 'show percent change of member ltv compared to previous period',
      allowed: ['engineerSegment', 'betaTestersSegment'],
    },
    {
      id: 'robin',
      description: 'feature flag for project robin',
      allowed: ['engineerSegment', 'betaTestersSegment'],
    },
    {
      id: 'autoCommissions',
      description: 'feature flag for auto commissions',
      allowed: ['engineerSegment', 'betaTestersSegment'],
    },
    {
      id: 'enterpriseGoogleMarket',
      description: 'feature flag for Enterprise project google market integration stuff',
      allowed: ['engineerSegment', 'googleMarketBetaTesters'],
    },
    {
      id: 'orderDiscountCombine',
      description: 'feature flag for order discount combination',
      allowed: ['engineerSegment'],
    },
    {
      id: 'keepAttributtedOrderSwitch',
      description: 'allow an integration to control if unattributed orders should be saved to DB or discarded',
      allowed: ['engineerSegment', 'huelSegment'],
    },
    {
      id: 'commissionsByProduct',
      description: 'whether or not to show the new product rules in commission tiers setup',
      allowed: ['engineerSegment'],
    },
    {
      id: 'customCommerceCloudScopes',
      description: 'feature flag for custom scopes for commerce cloud',
      allowed: ['engineerSegment', 'betaTestersSegment', 'crocsSegment'],
    },
    {
      id: 'hasLineItemCalculations',
      description: 'feature flag for line item calculations',
      allowed: ['engineerSegment', 'betaTestersSegment'],
    },
    {
      id: 'espMessages',
      description: 'feature flag for esp messages',
      allowed: ['engineerSegment', 'betaTestersSegment'],
    },
  ],
}
export function getFeatureConfig(): FeatureConfig {
  return features
}
