import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  InputAdornment,
  OutlinedInput,
  makeStyles,
  createStyles,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuProps,
  Menu,
  Typography,
} from '@material-ui/core'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as CheckIcon } from '../../icons/checkmark.svg'
import { ReactComponent as TrashIcon } from '../../icons/trash_can.svg'
import { ReactComponent as ZigZagIcon } from '../../icons/zig-zag.svg'
import { primary } from '../../loudcrowd-theme'

export type Filter = { id: string; name: string; isSystem: boolean }

type FilterMenuProps = {
  open: boolean
  filters: Filter[]
  selectedFilterIds?: Set<string>
  onSelect(id: string): void
  onCancel(): void
  anchorEl?: MenuProps['anchorEl']
  onDelete?(id: string): void
}

const useStyles = makeStyles(theme =>
  createStyles({
    search: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: '150%',
    },
    searchInput: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: 21,
    },
    list: {
      height: 375,
      overflowY: 'scroll',
    },
    listSubheader: {
      textTransform: 'capitalize',
      backgroundColor: theme.palette.common.white,
    },
    listIcon: {
      minWidth: 28,
      minHeight: 28,
      justifyContent: 'center',
      alignItems: 'center',
      color: 'inherit',
      marginRight: 12,
    },
    listIconEdit: {
      opacity: 0,
      // sync showing edit icon w/ list item backgroundColor transition on hover
      transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.shortest }),

      '&:hover': {
        backgroundColor: primary[200],
        borderRadius: 4,
      },
    },
    listItemButton: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,

        '& $listIconEdit': {
          opacity: 1,
        },
      },
    },
    addButton: {
      color: theme.palette.primary.main,
      paddingLeft: 16,
      height: 40,
      borderRadius: 4,
    },
  }),
)

const FilterMenu: React.FC<FilterMenuProps> = ({
  filters,
  open,
  onSelect,
  onCancel,
  selectedFilterIds = new Set<string>(),
  onDelete = null,
  anchorEl,
}) => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const shownFilters = useMemo(() => {
    let filtered = filters
    const lowerSearchText = searchText.toLowerCase()
    if (searchText) {
      filtered = filters
        .map(l => ({
          ...l,
          index: l.name.toLowerCase().indexOf(lowerSearchText),
        }))
        .filter(l => l.index >= 0)
        .sort(l => l.index || 0)
    }

    return filtered
  }, [filters, searchText])

  useEffect(() => {
    if (!open) {
      setSearchText('')
    }
  }, [open])

  const handleClose = (): void => {
    onCancel()
  }

  const handleTrashIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, filter: Filter): void => {
    e.stopPropagation()

    if (onDelete) {
      onDelete(filter.id)
    }
  }

  const deletable = !!onDelete
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      {filters.length === 0 && (
        <Box width={392} p={4}>
          <Typography variant="body2">
            No saved search filters in this account. Set some filters and save the search to see them show up here.
          </Typography>
        </Box>
      )}
      {filters.length > 0 && (
        <Box width={392}>
          <Box marginX={5}>
            <OutlinedInput
              classes={{ root: classes.search, input: classes.searchInput }}
              type="text"
              autoFocus
              fullWidth
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon width={16} height={16} />
                </InputAdornment>
              }
            />
          </Box>
          <List
            subheader={
              <ListSubheader className={classes.listSubheader}>
                {!searchText ? 'Recent ' : 'Saved '}
                Filters
              </ListSubheader>
            }
            className={classes.list}
          >
            {shownFilters.map(l => (
              <ListItem
                key={l.id || ''}
                button
                onClick={() => l.id && !selectedFilterIds.has(l.id) && onSelect(l.id)}
                classes={{ button: classes.listItemButton }}
              >
                {l.id && selectedFilterIds.has(l.id) && (
                  <ListItemIcon className={classes.listIcon}>
                    <CheckIcon width={12} />
                  </ListItemIcon>
                )}
                <ListItemText primary={l.name} />
                {deletable && !l.isSystem && !selectedFilterIds.has(l.id) && (
                  <ListItemIcon className={`${classes.listIcon} ${classes.listIconEdit}`}>
                    <TrashIcon width={16} onClick={e => handleTrashIconClick(e, l)} />
                  </ListItemIcon>
                )}
                {l.isSystem && (
                  <ListItemIcon className={classes.listIcon}>
                    <Box
                      width={20}
                      height={20}
                      bgcolor="primary.main"
                      color="white"
                      borderRadius={10}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <ZigZagIcon width={12} height={12} />
                    </Box>
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Menu>
  )
}

export default FilterMenu
