import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMentionLabelBulkMutationVariables = Types.Exact<{
  labelId: Types.Scalars['ID'];
  mentionIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  socialAccountId?: Types.InputMaybe<Types.Scalars['ID']>;
  where?: Types.InputMaybe<Types.MentionFilterInput>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.MentionSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type AddMentionLabelBulkMutation = { __typename: 'Mutations', addMentionLabel?: { __typename: 'AddMentionLabel', ok?: boolean | null, label?: { __typename: 'LabelType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null, mentions?: Array<{ __typename: 'IGMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null } | { __typename: 'TTMention', id: string, labels?: Array<{ __typename: 'LabelType', id: number }> | null }> | null } | null };


export const AddMentionLabelBulkDocument = gql`
    mutation AddMentionLabelBulk($labelId: ID!, $mentionIds: [ID!], $socialAccountId: ID, $where: MentionFilterInput, $limit: Int, $sortBy: MentionSort, $sortDirection: SortDirection) {
  addMentionLabel(
    labelId: $labelId
    mentionIds: $mentionIds
    socialAccountId: $socialAccountId
    where: $where
    limit: $limit
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    ok
    label {
      id
      name
      account {
        id
      }
    }
    mentions {
      id
      labels {
        id
      }
    }
  }
}
    `;
export type AddMentionLabelBulkMutationFn = Apollo.MutationFunction<AddMentionLabelBulkMutation, AddMentionLabelBulkMutationVariables>;

/**
 * __useAddMentionLabelBulkMutation__
 *
 * To run a mutation, you first call `useAddMentionLabelBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMentionLabelBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMentionLabelBulkMutation, { data, loading, error }] = useAddMentionLabelBulkMutation({
 *   variables: {
 *      labelId: // value for 'labelId'
 *      mentionIds: // value for 'mentionIds'
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAddMentionLabelBulkMutation(baseOptions?: Apollo.MutationHookOptions<AddMentionLabelBulkMutation, AddMentionLabelBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMentionLabelBulkMutation, AddMentionLabelBulkMutationVariables>(AddMentionLabelBulkDocument, options);
      }
export type AddMentionLabelBulkMutationHookResult = ReturnType<typeof useAddMentionLabelBulkMutation>;
export type AddMentionLabelBulkMutationResult = Apollo.MutationResult<AddMentionLabelBulkMutation>;
export type AddMentionLabelBulkMutationOptions = Apollo.BaseMutationOptions<AddMentionLabelBulkMutation, AddMentionLabelBulkMutationVariables>;