import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      marginBottom: theme.spacing(5),
      minWidth: 300,
    },
  }),
)

export interface AddLabelProps {
  open: boolean
  handleClose: () => void
  handleSave: (name: string, isRejectionReason: boolean) => void
}

const validate = (name: string): boolean => {
  if (!name || name.trim() === '') {
    return false
  }
  return true
}

const AddLabel: React.FC<AddLabelProps> = ({ open, handleClose, handleSave }) => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [isRejectionReason, setIsRejectionReason] = useState(false)

  useEffect(() => {
    setName('')
  }, [open])

  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <DialogTitle>Add Label</DialogTitle>
      <>
        <DialogContent style={{ paddingTop: 0 }}>
          <TextField
            label="Name"
            type="name"
            value={name}
            onChange={({ target: { value } }) => setName(value)}
            className={classes.formControl}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isRejectionReason}
                onChange={() => setIsRejectionReason(!isRejectionReason)}
                color="primary"
              />
            }
            label="Use as a reason for rejecting a reviewed post"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleSave(name, isRejectionReason)} color="primary" disabled={!validate(name)}>
            Save
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default AddLabel
