import { Box, Card, CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'

const howToUrl = 'https://loudcrowd.com/resources?post_types=how_to_guide'
const howToImgUrl = 'https://loudcrowd.com/wp-content/uploads/2021/08/how-to-guides-self-service-image-scaled.jpg'

const caseStudyUrl = 'https://loudcrowd.com/resources?post_types=how_to_guide'
const caseStudyImgUrl = 'http://loudcrowd.com/wp-content/uploads/2021/05/pexels-elevate-1267350-scaled.jpg'

const ugcBlogUrl = 'https://loudcrowd.com/resources?post_types=how_to_guide'
const ugcBlogImgUrl = 'http://loudcrowd.com/wp-content/uploads/2021/06/pexels-john-petalcurin-1603891-2.jpg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    container: {
      backgroundColor: theme.palette.common.white,
      paddingBottom: 72,
    },
    copyContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '30px 0',
    },
    bodyContainer: {
      textAlign: 'center',
      margin: '10px 0 0 0',
    },
    cardBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resourceCard: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      height: 250,
      width: 250,
      backgroundSize: 'cover',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    howToCard: {
      backgroundImage: `url(${howToImgUrl})`,
    },
    caseStudyCard: {
      backgroundImage: `url(${caseStudyImgUrl})`,
    },
    ugcBlogCard: {
      backgroundImage: `url(${ugcBlogImgUrl})`,
    },
  }),
)

function Resources(): React.ReactElement {
  const classes = useStyles()

  return (
    <Box width={800} margin="auto">
      <Box>
        <Box className={classes.copyContainer}>
          <Typography className={classes.bold} variant="h4">
            Hang Tight - Your Data is Loading
          </Typography>
          <Box className={classes.bodyContainer}>
            <Typography variant="body1" color="secondary" display="inline" component="span">
              Data enrichment takes about the same amount of time as a fancy coffee order from your fave barista – look
              for an{' '}
            </Typography>
            <Typography className={classes.bold} variant="body1" color="secondary" display="inline" component="span">
              email in about 15 minutes
            </Typography>
            <Typography variant="body1" color="secondary" display="inline" component="span">
              ! So, while we collect data across your accounts, hang out a bit and take a look at some resources and
              best practices.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.cardBox}>
          <Card
            className={`${classes.resourceCard} ${classes.howToCard}`}
            onClick={() => {
              window.location.href = howToUrl
            }}
          >
            <CardContent>
              <Typography variant="h6">How-to Guides</Typography>
            </CardContent>
          </Card>
          <Card
            className={`${classes.resourceCard} ${classes.caseStudyCard}`}
            onClick={() => {
              window.location.href = caseStudyUrl
            }}
          >
            <Typography variant="h6">Case Studies</Typography>
          </Card>
          <Card
            className={`${classes.resourceCard} ${classes.ugcBlogCard}`}
            onClick={() => {
              window.location.href = ugcBlogUrl
            }}
          >
            <Typography variant="h6">The UGC Blog</Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default Resources
