import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import React, { useEffect } from 'react'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { Field, Form, FormikProvider, useFormik, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import MultiSelect from '../../components/MultiSelect'
import { IntegrationType } from '../../gql-global'
import { EspMessageRewardFragmentFragment } from '../operations/esp-message-reward-fragment.generated'

const espIntegrationTypes: IntegrationType[] = [
  IntegrationType.Mandrill,
  IntegrationType.Sendinblue,
  IntegrationType.Klaviyo,
  IntegrationType.Bluecore,
  IntegrationType.Emarsys,
  IntegrationType.Ometria,
]

interface AddEditEspMessageRewardModalProps {
  espMessageReward: EspMessageRewardFragmentFragment | null
  open: boolean
  onCancel(): void
  onSubmit(formFields: EspMessageFormFields, espRewardId?: string): void
  loading: boolean
  programs?: { id: string; name: string }[]
  integrations?: { id: string; integrationType: IntegrationType; name: string }[]
}

const fieldSchema = {
  name: yup.string().trim().required('Name is required.'),
  integrationId: yup.string().trim().required('Integration is required.'),
  eventId: yup.string().required('Event ID is required.'),
  emailTemplateName: yup.string().trim().required('Email Template Name is required.'),
  discountCodeFieldName: yup.string().trim().required('Discount Code Field Name is required.'),
  templateId: yup.string().trim().required('Template ID is required.'),
  bluecoreCampaignId: yup.string().trim().required('Bluecore Campaign ID is required.'),
  programIds: yup.array().of(yup.string().trim()),
}

export interface EspMessageFormFields {
  name: string
  integrationId: string
  eventId: string
  emailTemplateName: string
  templateId: string
  bluecoreCampaignId: string
  programIds: string[]
}

const useStyles = makeStyles({
  dialogPaper: {
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: { flex: 1, maxWidth: 500, width: '100%', display: 'flex', flexDirection: 'column' },
  formContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    '& p': { margin: 0, fontSize: 12 },
  },
  formField: { marginTop: 25, '&:first-of-type': { margin: 0 } },
  dateFormField: { width: 300 },
  dropdown: { maxWidth: 300, flex: 1 },
  formActions: { paddingBottom: 25 },
  tableContainer: { marginTop: 20 },
})

export function getIntegrationFields(integrationType: IntegrationType): Set<keyof EspMessageFormFields> {
  switch (integrationType) {
    case IntegrationType.Mandrill:
      return new Set(['emailTemplateName'] as const)
    case IntegrationType.Sendinblue:
      return new Set(['templateId'] as const)
    case IntegrationType.Klaviyo:
      return new Set(['eventId'] as const)
    case IntegrationType.Emarsys:
      return new Set(['eventId'] as const)
    case IntegrationType.Ometria:
      return new Set(['eventId'] as const)
    case IntegrationType.Bluecore:
      return new Set(['bluecoreCampaignId'] as const)
  }
  return new Set()
}

const AddEditEspMessageRewardModal = ({
  espMessageReward,
  open,
  onCancel,
  onSubmit,
  programs,
  integrations,
}: AddEditEspMessageRewardModalProps): React.ReactElement => {
  const classes = useStyles()
  const isEditModal = !!espMessageReward
  const disabled = !!espMessageReward && (espMessageReward.rewardBatchJobs?.results || []).length > 0
  const [integrationFields, setIntegrationFields] = React.useState<Set<keyof EspMessageFormFields>>(new Set())

  const handleValidate = async (formValues: EspMessageFormFields) => {
    const fields = ['name', 'integrationId', ...Array.from(integrationFields || [])] as Array<
      keyof EspMessageFormFields
    >
    const schema = yup.object(
      fields.reduce((a, v) => {
        if (fieldSchema[v]) {
          return { ...a, [v]: fieldSchema[v] }
        }
        return a
      }, {}),
    )
    try {
      await schema.validate(formValues)
      return {}
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        return yupToFormErrors(e)
      } else {
        throw e
      }
    }
  }

  const formik = useFormik<EspMessageFormFields>({
    initialValues: {
      name: espMessageReward?.name || '',
      eventId: espMessageReward?.eventId || '',
      emailTemplateName: espMessageReward?.emailTemplateName || '',
      integrationId: espMessageReward?.integration?.id || '',
      templateId: espMessageReward?.templateId || '',
      bluecoreCampaignId: espMessageReward?.bluecoreCampaignId || '',
      programIds: espMessageReward?.programs?.map(p => p.id) || [],
    },
    validate: handleValidate,
    onSubmit: values => {
      onSubmit(values, espMessageReward?.id.toString())
    },
    enableReinitialize: true,
  })
  const { resetForm } = formik

  useEffect(() => {
    const selectedIntegration = !!formik.values.integrationId
      ? integrations?.find(i => i.id === formik.values.integrationId)
      : null

    const integrationFields = (
      !!selectedIntegration ? getIntegrationFields(selectedIntegration?.integrationType) : new Set()
    ) as Set<keyof EspMessageFormFields>

    setIntegrationFields(integrationFields)
  }, [formik.values.integrationId, integrations])

  useEffect(() => {
    resetForm()
  }, [open, resetForm])

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialogPaper }}>
      <Box m={2}>
        <div>
          <IconButton onClick={onCancel}>
            <CrossIcon width={16} height={16} />
          </IconButton>
        </div>
      </Box>
      <Box m={2} display="flex" flexDirection="column" flex={1} alignItems={'center'}>
        <FormikProvider value={formik}>
          <Form className={classes.form} noValidate>
            <DialogTitle>{isEditModal ? '' : 'Create'} Message via Email Service Provider</DialogTitle>
            <DialogContent className={classes.formContent}>
              <Field
                className={classes.formField}
                label="Message name"
                name="name"
                component={TextField}
                placeholder="Enter a unique name to organize your message list"
              />
              <Field
                className={classes.formField}
                style={{ marginTop: 20, width: 300 }}
                label="Message integration"
                name="integrationId"
                component={TextField}
                disabled={disabled}
                select
              >
                {integrations
                  ?.filter(i => espIntegrationTypes.includes(i.integrationType))
                  ?.map(i => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
              </Field>
              {integrationFields.has('eventId') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Message event ID"
                    name="eventId"
                    component={TextField}
                    disabled={disabled}
                    placeholder="Enter ID to trigger a flow in your email service provider"
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    After saving this message, the ID you entered will appear in your email service provider and you can
                    select it as the trigger to your flows
                  </Typography>
                </>
              )}

              {integrationFields.has('emailTemplateName') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Email Template Name"
                    name="emailTemplateName"
                    component={TextField}
                    disabled={disabled}
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    The email template name you entered will be used to trigger the message in your email service
                    provider
                  </Typography>
                </>
              )}

              {integrationFields.has('templateId') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Template Id"
                    name="templateId"
                    disabled={disabled}
                    component={TextField}
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    The template ID you entered will be used to trigger the message in your email service provider
                  </Typography>
                </>
              )}

              {integrationFields.has('bluecoreCampaignId') && (
                <>
                  <Field
                    className={classes.formField}
                    label="Bluecore Campaign ID"
                    name="bluecoreCampaignId"
                    component={TextField}
                    disabled={disabled}
                  />
                  <Typography style={{ maxWidth: 600 }}>
                    The campaign ID you entered will be used to trigger the message in Bluecore
                  </Typography>
                </>
              )}

              <Box mt={10}>
                <Typography variant={'subtitle1'}>Select message recipients</Typography>
                <Box display={'flex'} flexDirection={'row'} gridGap={20}>
                  <MultiSelect
                    className={classes.dropdown}
                    disabled={disabled}
                    renderValue={() => {
                      const programIds = formik.values.programIds
                      if (programIds.length === 0) return <>Select programs</>
                      if (programIds.length === 1) return <>{programs?.find(p => p.id === programIds[0])?.name}</>
                      return <>{formik.values.programIds.length} programs</>
                    }}
                    selectedOptions={formik.values.programIds}
                    menuLabel="Select programs"
                    onApply={(selected: string[]) => {
                      formik.setFieldValue('programIds', selected)
                    }}
                    options={(programs?.map(p => ({ name: p.name, id: p.id })) || []) as any}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className={classes.formActions}>
              <Button onClick={onCancel} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Box>
    </Dialog>
  )
}

export default AddEditEspMessageRewardModal
