import React, { useState, useRef, ChangeEvent } from 'react'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import MenuHeader from '../../MenuHeader'
import { Menu, Button, Box } from '@material-ui/core'
import { createNumberFormatter, NumberFormatTypes } from '../../../utils/number-format'
import { primary } from '../../../loudcrowd-theme'

const useStyles = makeStyles({
  actionButton: {
    margin: '16px 16px 0 0',
  },
  inlineButton: {
    color: primary[500],
    fontSize: 20,
    fontWeight: 400,
    textDecoration: 'underline',
    padding: 0,
    marginBottom: 4,
    minWidth: 0,
    textAlign: 'left',
    height: 'inherit',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  menu: {
    padding: 16,
  },
  subLabel: {
    margin: '12px 4px 0 0',
  },
})

interface NumericalFilterProps {
  value: number
  onDelete?(): void
  onSelectValue(newValue: number): void
  editable?: boolean
  numericFormat?: NumberFormatTypes
  label: string
  min?: number
  max?: number
  defaultValue?: number
  subLabel?: string
}

export function NumericalFilter({
  value,
  onDelete,
  onSelectValue,
  min,
  max,
  label,
  subLabel,
  defaultValue = 0,
  editable = true,
  numericFormat = 'number',
}: NumericalFilterProps): React.ReactElement {
  const numberFormatter = createNumberFormatter({ format: numericFormat })
  const [rangeMenuOpen, setRangeMenuOpen] = useState(false)
  const [newValue, setNewValue] = useState<number>(value ?? defaultValue)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const numberFormatted = numberFormatter(value)

  function handleNumericalSelected(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.currentTarget
    if (!value) return
    const newValue = parseInt(value, 10)
    setNewValue(newValue)
    onSelectValue(newValue)
  }

  function handleOnConfirm(): void {
    onSelectValue(newValue)
    setRangeMenuOpen(false)
  }

  function handleOnDelete(): void {
    setNewValue(defaultValue)
    onDelete && onDelete()
  }

  const classes = useStyles()

  return (
    <>
      {editable ? (
        <Button
          className={classes.inlineButton}
          variant="text"
          onClick={editable ? () => setRangeMenuOpen(true) : undefined}
          ref={buttonRef}
        >
          {numberFormatted}
        </Button>
      ) : (
        <>{numberFormatted}</>
      )}
      <Menu
        open={rangeMenuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setRangeMenuOpen(false)}
      >
        <MenuHeader title={label} />
        <Box className={classes.menu}>
          {subLabel && (
            <Typography variant="body2" className={classes.subLabel}>
              Enter the number of {subLabel}
            </Typography>
          )}
          <TextField
            value={newValue || value}
            onChange={handleNumericalSelected}
            type={numericFormat}
            variant="outlined"
            fullWidth
            disabled={!editable}
            InputProps={{ inputProps: { min, max } }}
          />
          {editable && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button color="primary" className={classes.actionButton} onClick={handleOnDelete}>
                Clear Filter
              </Button>
              <Button color="primary" variant="contained" className={classes.actionButton} onClick={handleOnConfirm}>
                Apply
              </Button>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  )
}
