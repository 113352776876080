import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import MetricIcon from '../components/metric/MetricIcon'
import theme from '../loudcrowd-theme'

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: 12,
      height: 12,
      marginTop: '8px',
    },
    social: {
      marginLeft: '4px',
    },
    container: {
      paddingTop: '16px',
    },
  }),
)

interface SelectedSocialAccountPreviewProps {
  options: (AccountLabel<string> & { isIG: boolean })[]
  selected?: Array<string> | null
}

function SelectedSocialAccountPreview({ options, selected }: SelectedSocialAccountPreviewProps): React.ReactElement {
  const classes = useStyles()
  const selectedOptions = selected?.map(s => {
    return options.find(option => option.id === s)
  })

  const igOptions = selectedOptions?.filter(option => !!option?.isIG).map(option => option?.name)
  const ttOptions = selectedOptions?.filter(option => !!!option?.isIG).map(option => option?.name)

  const igString = igOptions?.join(', ')
  const ttString = ttOptions?.join(', ')

  const mainClass = !!igOptions?.length && !!ttOptions?.length ? undefined : classes.container
  return (
    <Box pl={3} pt={1} className={mainClass}>
      {selected?.length && selected.length > 1 && (
        <Box>
          {igString && (
            <Box color={theme.palette.primary.dark} display="flex">
              <MetricIcon icon={'instagram'} className={classes.icon} />
              <Typography className={classes.social}>{igString}</Typography>
            </Box>
          )}
          {ttString && (
            <Box color={theme.palette.primary.dark} display="flex">
              <MetricIcon icon={'tiktok'} className={classes.icon} />
              <Typography className={classes.social}>{ttString}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default SelectedSocialAccountPreview
