import React, { useState, useEffect, FC } from 'react'
import { useToast } from './ToastProvider'
import { Snackbar, Typography } from '@material-ui/core'
import { Alert as MUIAlert, AlertTitle as MUIAlertTitle } from '@material-ui/lab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export type ToastData = {
  title?: string
  message: string
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined
  autoHideDuration?: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      width: '550px',
    },
    icon: {
      marginRight: theme.spacing(4),
      '& svg': {
        height: 30,
        width: 30,
      },
    },
    title: {
      marginBottom: 0,
    },
  }),
)

export const Toast: FC = () => {
  const classes = useStyles()
  const {
    settings: { title, message, severity = 'error', isOpen, onClose, icon = null, autoHideDuration },
    hideToast,
  } = useToast()
  const [alertState, setState] = useState<ToastData | null>(null)

  useEffect(() => {
    if (isOpen && alertState === null) {
      setState({ severity, title, message: message ?? '' })
    }
  }, [severity, title, message, isOpen, alertState])

  function handleOnExit() {
    setState(null)
  }

  function onAlertClose() {
    hideToast()
    onClose && onClose()
  }

  return (
    <Snackbar
      open={!!isOpen}
      message=""
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
      onClose={onAlertClose}
      TransitionProps={{ onExited: handleOnExit }}
    >
      <MUIAlert
        square
        severity={alertState?.severity}
        elevation={1}
        classes={{
          root: classes.alert,
          icon: classes.icon,
        }}
        icon={icon}
        onClose={onAlertClose}
      >
        {alertState?.title && <MUIAlertTitle className={classes.title}>{alertState?.title}</MUIAlertTitle>}
        <Typography>{alertState?.message}</Typography>
      </MUIAlert>
    </Snackbar>
  )
}
