import React, { MouseEventHandler, useContext, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardProps,
  Checkbox,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { format, formatDistanceToNow } from 'date-fns'
import { numberFormat } from '../../utils/number-format'
import { IgMediaPostType, MentionStatus } from '../../gql-global'
import { ReactComponent as NoMediaImage } from '../../images/no-media.svg'
import { ReactComponent as ExpiredStory } from '../../images/expired-story.svg'
import { mentionStatusLabels } from './TagStatusChip'
import { ReactComponent as VideoStoryIcon } from '../../icons/video-story.svg'
import { ReactComponent as PhotoStoryIcon } from '../../icons/photo-story.svg'
import { ReactComponent as VideoReelsIcon } from '../../icons/Reels.svg'
import { ReactComponent as TiktokIcon } from '../../icons/tiktok_logo_filled.svg'
import { ReactComponent as VideoIcon } from '../../icons/video.svg'
import { ReactComponent as LockIcon } from '../../icons/lock.svg'
import { ReactComponent as MessageIcon } from '../../icons/message_icon_major.svg'
import { ReactComponent as PresentIcon } from '../../icons/present.svg'
import { ReactComponent as EyeIcon } from '../../icons/eye.svg'
import { UpsellModalUpdaterContext } from '../UpsellModal'
import ExpirationDisplay from '../ExpirationDisplay'
import { PostCardFragment } from '../../queries/operations/post-card.generated'
import { isTypeName } from '../../types/utility'
import { ReactComponent as CarouselIcon } from '../../icons/carousel.svg'
import { ReactComponent as InfoIcon } from '../../icons/information-no-halo.svg'
import StoriesAvatarV2 from '../CustomerAvatar/StoriesAvatarV2'

export interface PostCardProps extends Omit<CardProps, 'className'> {
  post?: PostCardFragment | null
  hasCampaigns?: boolean
  loading?: boolean
  handleClick?(): void
  selectable?: boolean
  selected?: boolean
  onSelectChanged?(isSelected: boolean): void
  disabled?: boolean
}

export const cardWidth = 242

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: 406,
      width: cardWidth,
      position: 'relative',
      borderRadius: 8,
    },
    isValidUnAuthedStory: {
      backgroundImage: 'linear-gradient(180deg, #751BCF 0%, #B62586 49.48%, #EFAC59 100%)',
      padding: 4,

      '& $media': {
        height: 238,
      },
    },
    bottomHalf: {
      background: theme.palette.common.white,
      paddingTop: 20,
      minHeight: 160,
      borderRadius: '0 0 6px 6px',
    },
    media: {
      height: 242,
      width: '100%',
      background: theme.palette.common.white,
      borderRadius: '6px 6px 0 0',
    },
    story: {
      objectFit: 'cover',
    },
    blurred: {
      filter: 'blur(15px) opacity(0.8) grayscale(0.3)',
    },
    placeholder: {
      height: 242,
      padding: 16,
    },
    storyError: {
      height: 238,
      '& $placeholder': {
        width: 234,
      },
    },
    expiredStory: {
      padding: 0,
    },
    avatar: {
      height: 32,
      width: 32,
    },
    h7: {
      //MUI doesn't have a h7 whereas the mocks did
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    usernameText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    usernameBox: {
      overflow: 'hidden',
    },
    mediaIcons: { color: theme.palette.common.white },
    watermark: {
      color: '#97b2c2',
      fontSize: 24,
      fontWeight: 400,
      opacity: 0.75,
    },
    upgradeButton: {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      fontWeight: 400,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    overlayShadow: {
      display: 'block',
      position: 'absolute',
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00605304) 6.67%, rgba(0, 0, 0, 0.024857) 13.33%, rgba(0, 0, 0, 0.0571619) 20%, rgba(0, 0, 0, 0.103188) 26.67%, rgba(0, 0, 0, 0.162242) 33.33%, rgba(0, 0, 0, 0.232319) 40%, rgba(0, 0, 0, 0.309884) 46.67%, rgba(0, 0, 0, 0.390116) 53.33%, rgba(0, 0, 0, 0.467681) 60%, rgba(0, 0, 0, 0.537758) 66.67%, rgba(0, 0, 0, 0.596812) 73.33%, rgba(0, 0, 0, 0.642838) 80%, rgba(0, 0, 0, 0.675143) 86.67%, rgba(0, 0, 0, 0.693947) 93.33%, rgba(0, 0, 0, 0.7) 100%)',
      top: 180,
      bottom: 0,
      left: 0,
      right: 0,
    },
    expirationDisplay: {
      margin: '0 0 12px 0',
    },
    cardFloatingIcon: {
      backgroundColor: theme.palette.success.light,
      borderRadius: '50%',
      height: '28px',
      width: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        color: theme.palette.success.dark,
      },
    },
    cardFloatingIconWarning: {
      backgroundColor: theme.palette.warning.light,
      '& svg': {
        color: theme.palette.warning.dark,
      },
    },
    cardFloatingIconError: {
      backgroundColor: theme.palette.error.light,
      '& svg': {
        color: theme.palette.error.dark,
      },
    },
  }),
)

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '1em',
    margin: '0 0 0 4px',
  },
}))

const isVideoTag = (elm: HTMLDivElement | HTMLVideoElement): elm is HTMLVideoElement =>
  (elm as HTMLVideoElement).play !== undefined

function PostCard({
  loading,
  post,
  hasCampaigns = false,
  handleClick = () => null,
  selectable = false,
  selected = false,
  onSelectChanged = () => null,
  disabled = false,
  ...rest
}: PostCardProps): React.ReactElement {
  // shared fields
  const { media, status, wasDirectMessaged, wasRewarded } = post || {}
  const { postedAt, mediaUrl, lcMediaUrl, poster, stats, permalink, lcThumbnailUrl: thumbnailUrl } = media || {}
  const { impressions, engagementRate, emv } = stats || {}
  const { username, avatarUrl } = poster || {}

  // igMedia fields
  const igMedia = media && isTypeName(media, 'IGMedia') ? media : undefined
  const { mediaType = null, postType, isExpired, fromAuthedApi } = igMedia || {}
  let hasFbAuth = false
  if (poster && isTypeName(poster, 'IGUser')) {
    poster.storiesIgSocialAccounts?.forEach(s => {
      hasFbAuth = hasFbAuth || !!s?.fbUsers?.some(u => u.isValid)
    })
  }

  const classes = useStyles()
  const tooltipClasses = useTooltipStyles()
  const setModalOptions = useContext(UpsellModalUpdaterContext)
  const mediaRef = useRef<HTMLImageElement | HTMLVideoElement>(null)

  const [triedVideo, setTriedVideo] = useState<boolean>(false)
  const [mediaTypeState, setMediaType] = useState<string | null>(mediaType || null)
  const [mediaFailed, setMediaFailed] = useState(false)

  const handleCardClicked: MouseEventHandler = e => {
    if (disabled) {
      setModalOptions({
        isOpen: true,
        modalProps: {
          context: { reason: 'LIMIT', limit: 'POSTS' },
          onCancel: () => setModalOptions({ isOpen: false }),
        },
      })
    } else {
      e.preventDefault()
      if (mediaRef.current && mediaRef.current instanceof HTMLVideoElement) {
        mediaRef.current.pause()
      }
      handleClick()
    }
  }

  const mediaClicked: MouseEventHandler = () => {
    if (mediaType === 'VIDEO' && mediaRef.current && isVideoTag(mediaRef.current)) {
      mediaRef.current.paused ? mediaRef.current.play() : mediaRef.current.pause()
    }
  }

  const url = thumbnailUrl || lcMediaUrl || mediaUrl
  const invalidMedia = !url || mediaFailed
  const isStory = postType === 'STORY'
  const isVideo = mediaTypeState === 'VIDEO'
  const isUnAuthedStory = !fromAuthedApi && isStory
  const isValidUnAuthedStory = isUnAuthedStory && !isExpired
  const isExpiredUnAuthedStory = isUnAuthedStory && isExpired

  const invalidMediaState = invalidMedia || isExpiredUnAuthedStory
  const renderVideoEl = (!isStory && isVideo) || (isUnAuthedStory && !triedVideo)

  useEffect(() => {
    if (mediaType !== null && mediaType !== mediaTypeState) {
      setMediaType(mediaType)
    }
  }, [mediaType, mediaTypeState])

  function handleOnMediaLoad(e: React.SyntheticEvent) {
    if (isUnAuthedStory) {
      const tagName = e.currentTarget.tagName
      const mediaType = tagName === 'VIDEO' ? 'VIDEO' : 'IMAGE'
      setMediaType(mediaType)
    }
  }

  function handleOnMediaError() {
    if (!isUnAuthedStory) {
      setMediaFailed(true)
    } else if (isUnAuthedStory) {
      triedVideo ? setMediaFailed(true) : setTriedVideo(true)
    }
  }

  const invalidMediaClassName = isExpiredUnAuthedStory && !mediaFailed ? classes.expiredStory : classes.placeholder
  const mediaContainerClassName = invalidMedia && isValidUnAuthedStory ? classes.storyError : ''

  const mediaClasses = [classes.media]
  if (disabled) {
    mediaClasses.push(classes.blurred)
  }
  if (isStory) {
    mediaClasses.push(classes.story)
  }

  const statusIconColorClass =
    status === MentionStatus.Verified
      ? ''
      : status === MentionStatus.Rejected
        ? classes.cardFloatingIconError
        : classes.cardFloatingIconWarning

  return (
    <Card className={`${classes.card} ${isValidUnAuthedStory ? classes.isValidUnAuthedStory : ''}`} {...rest}>
      <CardActionArea onClick={handleCardClicked}>
        <Box
          position="relative"
          onClick={mediaClicked}
          onContextMenu={e => {
            if (disabled) {
              // if the image is diabled
              e.preventDefault()
            }
          }}
        >
          <Box position="relative" onClick={mediaClicked} className={mediaContainerClassName}>
            {loading && <Skeleton variant="rect" className={classes.media} />}
            {!loading && invalidMediaState && (
              <Box
                className={invalidMediaClassName}
                display="flex"
                flexDirection="column"
                alignItems="center"
                bgcolor="white"
                height={230}
              >
                {isExpiredUnAuthedStory ? (
                  <>
                    <ExpiredStory />
                    <Box position="absolute" top={121}>
                      <Typography className={classes.watermark}> Story Expired</Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <NoMediaImage width={130} height="auto" />
                    <Typography component="p">
                      {mediaType === 'VIDEO' ? 'Video cannot be displayed' : 'Image not found'}
                    </Typography>
                    {media && permalink && (
                      <Button
                        color="primary"
                        href={permalink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => e.stopPropagation()}
                      >
                        View in {media && isTypeName(media, 'TTMedia') ? 'TikTok' : 'Instagram'}
                      </Button>
                    )}
                  </>
                )}
              </Box>
            )}
            {url && !mediaFailed && !isExpiredUnAuthedStory && (
              <>
                <CardMedia
                  component={renderVideoEl ? 'video' : 'img'}
                  src={url}
                  muted
                  className={mediaClasses.join(' ')}
                  onError={handleOnMediaError}
                  onLoadedMetadata={handleOnMediaLoad}
                  onLoad={handleOnMediaLoad}
                />
                <div className={classes.overlayShadow} />
              </>
            )}
            {!loading && disabled && !invalidMediaState && (
              <Box position="absolute" top={'50%'} left={30}>
                <Button
                  variant="contained"
                  className={classes.upgradeButton}
                  startIcon={<LockIcon height={16} width={16} />}
                >
                  Upgrade to unlock
                </Button>
              </Box>
            )}
          </Box>
          <Box position="absolute" top={8} left={8}>
            {!loading && status && status !== MentionStatus.Unverified && (
              <Tooltip classes={tooltipClasses} title={mentionStatusLabels[status]} placement="right">
                <Box mb={1} className={`${classes.cardFloatingIcon} ${statusIconColorClass}`}>
                  <EyeIcon width={20} height={20} />
                </Box>
              </Tooltip>
            )}
            {!loading && wasDirectMessaged && (
              <Tooltip classes={tooltipClasses} title="Messaged" placement="right">
                <Box mb={1} className={classes.cardFloatingIcon}>
                  <MessageIcon width={16} height={16} />
                </Box>
              </Tooltip>
            )}
            {!loading && wasRewarded && (
              <Tooltip classes={tooltipClasses} title="Rewarded" placement="right">
                <Box mb={1} className={classes.cardFloatingIcon}>
                  <PresentIcon width={16} height={16} />
                </Box>
              </Tooltip>
            )}
          </Box>
          {selectable && (
            <Box position="absolute" top={12} right={8}>
              <Checkbox
                checked={selected}
                onChange={e => onSelectChanged(e.target.checked)}
                color="primary"
                onClick={e => e.stopPropagation()}
              />
            </Box>
          )}
          <Box position="absolute" bottom={16} left={20} className={classes.mediaIcons}>
            {postType === IgMediaPostType.Story && mediaTypeState === 'VIDEO' && (
              <VideoStoryIcon width={24} height={24} />
            )}
            {postType === IgMediaPostType.Story && mediaTypeState === 'IMAGE' && (
              <PhotoStoryIcon width={24} height={24} />
            )}
            {postType === IgMediaPostType.Feed && mediaTypeState === 'VIDEO' && <VideoIcon width={24} height={24} />}
            {postType === IgMediaPostType.Reels && mediaTypeState === 'VIDEO' && (
              <VideoReelsIcon width={24} height={24} />
            )}
            {media && 'children' in media && media.children?.length !== 0 && <CarouselIcon width={24} height={24} />}
            {media && isTypeName(media, 'TTMedia') && <TiktokIcon width={24} height={24} />}
          </Box>
        </Box>
        <Box className={classes.bottomHalf}>
          <Box display="flex" flexDirection="row" alignItems="center" mx={5}>
            {loading && <Skeleton variant="circle" className={classes.avatar} />}
            {!loading && <StoriesAvatarV2 avatarUrl={avatarUrl} loading={loading} />}

            <Box className={classes.usernameBox} marginLeft={3} flexGrow={1}>
              <Typography className={classes.usernameText} variant="body2">
                {loading && <Skeleton component="span" />}
                {!loading && username ? username : ''}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" marginTop={4.5} marginLeft={5} marginRight={5}>
            <Box width={122}>
              <Typography variant="body2">Impressions</Typography>
              <Typography className={classes.h7}>
                {loading && <Skeleton component="span" />}
                {!loading && (!!impressions || impressions === 0) && numberFormat(impressions, { format: 'number' })}
                {!loading && !(!!impressions || impressions === 0) && '-'}
              </Typography>
            </Box>
            {postType === IgMediaPostType.Story ? (
              <Box>
                <Typography variant="body2">EMV</Typography>
                <Typography className={classes.h7}>
                  {loading && <Skeleton component="span" />}
                  {!loading && (!!emv || emv === 0) && numberFormat(emv, { format: 'currency' })}
                  {!loading && !(!!emv || emv === 0) && '-'}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="body2">Eng. Rate</Typography>
                <Typography className={classes.h7}>
                  {loading && <Skeleton component="span" />}
                  {!loading &&
                    (!!engagementRate || engagementRate === 0) &&
                    numberFormat(engagementRate, { format: 'percent' })}
                  {!loading && !(!!engagementRate || engagementRate === 0) && '-'}
                </Typography>
              </Box>
            )}
          </Box>

          <Box marginTop={2} mx={5} marginBottom={5}>
            {loading && <Skeleton />}
            {!loading && postedAt && !isUnAuthedStory && (
              <Tooltip title={format(postedAt, 'PPp')} placement="top-start">
                <Typography variant="caption">
                  {formatDistanceToNow(postedAt, { addSuffix: true }).replace('about ', '')}
                </Typography>
              </Tooltip>
            )}
            {!loading && postedAt && isUnAuthedStory && (
              <ExpirationDisplay postedAt={postedAt} className={classes.expirationDisplay}>
                <Box display="flex" alignItems="center">
                  <Tooltip title="Due to Facebook’s regulations, we can’t show stories that have been published more than 24 hours ago.">
                    <InfoIcon width={16} height={16} />
                  </Tooltip>
                </Box>
              </ExpirationDisplay>
            )}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default PostCard
