import React from 'react'
import { ConcreteEventType } from './activity-types'
import { Box, Typography } from '@material-ui/core'
import ActivityListItemContent from './ActivityListItemContent'
import { ReactComponent as NoteIcon } from '../icons/note-major.svg'
import { CUSTOMER_ROUTE } from './routes'
import { isTypeName } from '../types/utility'

interface CustomerNoteActivityListItemProps {
  activity: ConcreteEventType<'AddCustomerNoteEvent' | 'RemoveCustomerNoteEvent'>
  customerId?: string
}

function CustomerNoteActivityListItem({ activity, customerId }: CustomerNoteActivityListItemProps): React.ReactElement {
  const actionType = isTypeName(activity, 'AddCustomerNoteEvent') ? 'Added' : 'Removed'
  return (
    <ActivityListItemContent
      eventAt={activity.eventAt}
      actorName={activity.actor?.email || 'LoudCrowd'}
      action={
        !activity.customerNote.deletedAt
          ? {
              text: actionType === 'Added' ? 'View notes' : '',
              to: actionType === 'Added' ? `${CUSTOMER_ROUTE.path}/${customerId}/notes` : '',
            }
          : undefined
      }
      media={
        <Box m={4} p={3} width={48} height={48} borderRadius={24} bgcolor="warning.light" color="warning.main">
          <NoteIcon width={24} height={24} />
        </Box>
      }
      text={
        <>
          <Typography variant="subtitle1" display="inline">
            {actionType} note
          </Typography>
        </>
      }
    />
  )
}

export default CustomerNoteActivityListItem
