import React, { useState } from 'react'
import ActivityListItemContent from './ActivityListItemContent'
import { Box, Typography } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import { ReactComponent as MessageIcon } from '../icons/message_icon_major.svg'
import MessagePhonePreviewModal from '../components/MessagePhonePreview/MessagePhonePreviewModal'

interface MessageActivityListItemProps {
  activity: ConcreteEventType<'SentMessageCustomerEvent'>
}

function MessageActivityListItem({ activity }: MessageActivityListItemProps): React.ReactElement {
  const [previewOpen, setPreviewOpen] = useState(false)
  const text = !!activity.actor ? 'Manual story reply sent' : 'Auto story reply sent'

  // optional details for message
  // will produce a string " - template - reward" if there is a reward or template
  const detailParts: string[] = []
  if (activity.message.messageTemplate) {
    detailParts.push(activity.message.messageTemplate.name)
  } else {
    detailParts.push('N/A')
  }
  if (activity.message.fulfillment?.reward?.name) {
    detailParts.push(activity.message.fulfillment.reward.name)
  }
  // if there are details add a space to the beginning
  if (detailParts) {
    detailParts.unshift(' ')
  }
  const details = detailParts.join(' - ')

  return (
    <>
      <ActivityListItemContent
        eventAt={activity.eventAt}
        actorName={activity.actor?.email}
        media={
          <Box
            m={4}
            p={2}
            width={48}
            height={48}
            borderRadius={24}
            bgcolor="info.light"
            color="info.main"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MessageIcon width={24} height={24} />
          </Box>
        }
        text={
          <Typography display="inline">
            <Typography variant="subtitle1" component="span">
              {text}
            </Typography>
            <Typography variant="body2" component="span">
              {details}
            </Typography>
          </Typography>
        }
        action={{
          text: 'View Message',
          onAction() {
            setPreviewOpen(true)
          },
        }}
      />
      <MessagePhonePreviewModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        mentionedSocialUser={activity.message.fromSocialAccount.socialUser}
        message={activity.message.messageText}
        createdAt={activity.message?.createdAt}
        media={activity.message.replyMention.media}
      />
    </>
  )
}

export default MessageActivityListItem
