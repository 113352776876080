import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMentionStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  status: Types.MentionStatus;
}>;


export type UpdateMentionStatusMutation = { __typename: 'Mutations', updateMentionStatus?: { __typename: 'UpdateMentionStatus', ok?: boolean | null, mention?: { __typename: 'IGMention', id: string, status: Types.MentionStatus } | { __typename: 'TTMention', id: string, status: Types.MentionStatus } | null } | null };


export const UpdateMentionStatusDocument = gql`
    mutation UpdateMentionStatus($id: ID!, $status: MentionStatus!) {
  updateMentionStatus(id: $id, status: $status) {
    ok
    mention {
      id
      status
    }
  }
}
    `;
export type UpdateMentionStatusMutationFn = Apollo.MutationFunction<UpdateMentionStatusMutation, UpdateMentionStatusMutationVariables>;

/**
 * __useUpdateMentionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMentionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMentionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMentionStatusMutation, { data, loading, error }] = useUpdateMentionStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateMentionStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMentionStatusMutation, UpdateMentionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMentionStatusMutation, UpdateMentionStatusMutationVariables>(UpdateMentionStatusDocument, options);
      }
export type UpdateMentionStatusMutationHookResult = ReturnType<typeof useUpdateMentionStatusMutation>;
export type UpdateMentionStatusMutationResult = Apollo.MutationResult<UpdateMentionStatusMutation>;
export type UpdateMentionStatusMutationOptions = Apollo.BaseMutationOptions<UpdateMentionStatusMutation, UpdateMentionStatusMutationVariables>;