import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessagePreviewUserDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MessagePreviewUserDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null } } | null };


export const MessagePreviewUserDataDocument = gql`
    query MessagePreviewUserData {
  whoami {
    id
    preferences @client {
      id
      selectedSocialAccountId
    }
  }
}
    `;

/**
 * __useMessagePreviewUserDataQuery__
 *
 * To run a query within a React component, call `useMessagePreviewUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagePreviewUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagePreviewUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessagePreviewUserDataQuery(baseOptions?: Apollo.QueryHookOptions<MessagePreviewUserDataQuery, MessagePreviewUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagePreviewUserDataQuery, MessagePreviewUserDataQueryVariables>(MessagePreviewUserDataDocument, options);
      }
export function useMessagePreviewUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagePreviewUserDataQuery, MessagePreviewUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagePreviewUserDataQuery, MessagePreviewUserDataQueryVariables>(MessagePreviewUserDataDocument, options);
        }
export type MessagePreviewUserDataQueryHookResult = ReturnType<typeof useMessagePreviewUserDataQuery>;
export type MessagePreviewUserDataLazyQueryHookResult = ReturnType<typeof useMessagePreviewUserDataLazyQuery>;
export type MessagePreviewUserDataQueryResult = Apollo.QueryResult<MessagePreviewUserDataQuery, MessagePreviewUserDataQueryVariables>;