import { useQueryParams, encodeQueryParams, stringify } from 'use-query-params'

import { SOCIAL_ACCOUNT_SEARCH_PARAM_KEY, socialAccountParam } from './use-social-account-param'

export interface Filters {
  socialAccountKeywords?: string[]
}

interface SocialAccountParams {
  [SOCIAL_ACCOUNT_SEARCH_PARAM_KEY]?: string[]
}

function paramsToFilters(params: SocialAccountParams): Filters {
  return {
    socialAccountKeywords: params[SOCIAL_ACCOUNT_SEARCH_PARAM_KEY] || undefined,
  }
}

function filtersToParams(filters: Filters): SocialAccountParams {
  return {
    [SOCIAL_ACCOUNT_SEARCH_PARAM_KEY]: filters.socialAccountKeywords,
  }
}

const filterParamConfig = {
  [SOCIAL_ACCOUNT_SEARCH_PARAM_KEY]: socialAccountParam,
}

/*
 * encodes account filter parameters into a query string
 * params: optional object of filter parameters
 */
export const encodeFilterParams = (filters: Filters): string => {
  return stringify(encodeQueryParams(filterParamConfig, filtersToParams(filters)))
}

const useFilterParams = (): { filters: Filters; setFilters: (f?: Filters) => void; isDirty: boolean } => {
  const [filterParams, setFilterParams] = useQueryParams(filterParamConfig)
  const filters = paramsToFilters(filterParams)
  return {
    filters,
    setFilters: (f?: Filters) => setFilterParams(f ? filtersToParams(f) : {}, 'replace'),
    isDirty: Object.values(filterParams).some(v => v !== undefined && v !== null),
  }
}

export default useFilterParams
