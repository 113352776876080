import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  noteTab: {
    height: 110,
    borderTop: '1px solid #DAD9E6',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(8),
  },
  notesDiv: {
    display: 'flex',
    marginTop: theme.spacing(8.5),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(11),
  },
  avatar: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
  },
  editDeleteIconContainer: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  iconSpacing: {
    marginRight: theme.spacing(2),
  },
}))

function CustomerNoteListLoading(): React.ReactElement | null {
  const classes = useStyles()
  return (
    <Box className={classes.noteTab}>
      <Box className={classes.notesDiv}>
        <Box>
          <Skeleton className={classes.avatar} variant="circle" />
        </Box>
        <Box className={classes.contentContainer}>
          <Skeleton width={250} variant="text" />
          <Skeleton width={100} variant="text" />
        </Box>
        <Box className={classes.editDeleteIconContainer}>
          <Skeleton width={38} height={38} variant="circle" className={classes.iconSpacing} />
          <Skeleton width={38} height={38} variant="circle" />
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerNoteListLoading
