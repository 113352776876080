export function getCurrencyCodeDescription(currencyCode: string): string {
  switch (currencyCode) {
    case 'AED': {
      return 'United Arab Emirates dirham'
    }
    case 'AFN': {
      return 'Afghan afghani'
    }
    case 'ALL': {
      return 'Albanian lek'
    }
    case 'AMD': {
      return 'Armenian dram'
    }
    case 'ANG': {
      return 'Netherlands Antillean guilder'
    }
    case 'AOA': {
      return 'Angolan kwanza'
    }
    case 'ARS': {
      return 'Argentine peso'
    }
    case 'AUD': {
      return 'Australian dollar'
    }
    case 'AWG': {
      return 'Aruban florin'
    }
    case 'AZN': {
      return 'Azerbaijani manat'
    }
    case 'BAM': {
      return 'Bosnia and Herzegovina convertible mark'
    }
    case 'BBD': {
      return 'Barbados dollar'
    }
    case 'BDT': {
      return 'Bangladeshi taka'
    }
    case 'BGN': {
      return 'Bulgarian lev'
    }
    case 'BHD': {
      return 'Bahraini dinar'
    }
    case 'BIF': {
      return 'Burundian franc'
    }
    case 'BMD': {
      return 'Bermudian dollar'
    }
    case 'BND': {
      return 'Brunei dollar'
    }
    case 'BOB': {
      return 'Boliviano'
    }
    case 'BOV': {
      return 'Bolivian Mvdol (funds code)'
    }
    case 'BRL': {
      return 'Brazilian real'
    }
    case 'BSD': {
      return 'Bahamian dollar'
    }
    case 'BTN': {
      return 'Bhutanese ngultrum'
    }
    case 'BWP': {
      return 'Botswana pula'
    }
    case 'BYN': {
      return 'Belarusian ruble'
    }
    case 'BZD': {
      return 'Belize dollar'
    }
    case 'CAD': {
      return 'Canadian dollar'
    }
    case 'CDF': {
      return 'Congolese franc'
    }
    case 'CHE': {
      return 'WIR euro (complementary currency)'
    }
    case 'CHF': {
      return 'Swiss franc'
    }
    case 'CHW': {
      return 'WIR franc (complementary currency)'
    }
    case 'CLF': {
      return 'Unidad de Fomento (funds code)'
    }
    case 'CLP': {
      return 'Chilean peso'
    }
    case 'COP': {
      return 'Colombian peso'
    }
    case 'COU': {
      return 'Unidad de Valor Real (UVR) (funds code)'
    }
    case 'CRC': {
      return 'Costa Rican colon'
    }
    case 'CUC': {
      return 'Cuban convertible peso'
    }
    case 'CUP': {
      return 'Cuban peso'
    }
    case 'CVE': {
      return 'Cape Verdean escudo'
    }
    case 'CZK': {
      return 'Czech koruna'
    }
    case 'DJF': {
      return 'Djiboutian franc'
    }
    case 'DKK': {
      return 'Danish krone'
    }
    case 'DOP': {
      return 'Dominican peso'
    }
    case 'DZD': {
      return 'Algerian dinar'
    }
    case 'EGP': {
      return 'Egyptian pound'
    }
    case 'ERN': {
      return 'Eritrean nakfa'
    }
    case 'ETB': {
      return 'Ethiopian birr'
    }
    case 'EUR': {
      return 'Euro'
    }
    case 'FJD': {
      return 'Fiji dollar'
    }
    case 'FKP': {
      return 'Falkland Islands pound'
    }
    case 'GBP': {
      return 'Pound sterling'
    }
    case 'GEL': {
      return 'Georgian lari'
    }
    case 'GHS': {
      return 'Ghanaian cedi'
    }
    case 'GIP': {
      return 'Gibraltar pound'
    }
    case 'GMD': {
      return 'Gambian dalasi'
    }
    case 'GNF': {
      return 'Guinean franc'
    }
    case 'GTQ': {
      return 'Guatemalan quetzal'
    }
    case 'GYD': {
      return 'Guyanese dollar'
    }
    case 'HKD': {
      return 'Hong Kong dollar'
    }
    case 'HNL': {
      return 'Honduran lempira'
    }
    case 'HRK': {
      return 'Croatian kuna'
    }
    case 'HTG': {
      return 'Haitian gourde'
    }
    case 'HUF': {
      return 'Hungarian forint'
    }
    case 'IDR': {
      return 'Indonesian rupiah'
    }
    case 'ILS': {
      return 'Israeli new shekel'
    }
    case 'INR': {
      return 'Indian rupee'
    }
    case 'IQD': {
      return 'Iraqi dinar'
    }
    case 'IRR': {
      return 'Iranian rial'
    }
    case 'ISK': {
      return 'Icelandic króna (plural: krónur)'
    }
    case 'JMD': {
      return 'Jamaican dollar'
    }
    case 'JOD': {
      return 'Jordanian dinar'
    }
    case 'JPY': {
      return 'Japanese yen'
    }
    case 'KES': {
      return 'Kenyan shilling'
    }
    case 'KGS': {
      return 'Kyrgyzstani som'
    }
    case 'KHR': {
      return 'Cambodian riel'
    }
    case 'KMF': {
      return 'Comoro franc'
    }
    case 'KPW': {
      return 'North Korean won'
    }
    case 'KRW': {
      return 'South Korean won'
    }
    case 'KWD': {
      return 'Kuwaiti dinar'
    }
    case 'KYD': {
      return 'Cayman Islands dollar'
    }
    case 'KZT': {
      return 'Kazakhstani tenge'
    }
    case 'LAK': {
      return 'Lao kip'
    }
    case 'LBP': {
      return 'Lebanese pound'
    }
    case 'LKR': {
      return 'Sri Lankan rupee'
    }
    case 'LRD': {
      return 'Liberian dollar'
    }
    case 'LSL': {
      return 'Lesotho loti'
    }
    case 'LYD': {
      return 'Libyan dinar'
    }
    case 'MAD': {
      return 'Moroccan dirham'
    }
    case 'MDL': {
      return 'Moldovan leu'
    }
    case 'MGA': {
      return 'Malagasy ariary'
    }
    case 'MKD': {
      return 'Macedonian denar'
    }
    case 'MMK': {
      return 'Myanmar kyat'
    }
    case 'MNT': {
      return 'Mongolian tögrög'
    }
    case 'MOP': {
      return 'Macanese pataca'
    }
    case 'MRU': {
      return 'Mauritanian ouguiya'
    }
    case 'MUR': {
      return 'Mauritian rupee'
    }
    case 'MVR': {
      return 'Maldivian rufiyaa'
    }
    case 'MWK': {
      return 'Malawian kwacha'
    }
    case 'MXN': {
      return 'Mexican peso'
    }
    case 'MXV': {
      return 'Mexican Unidad de Inversion (UDI) (funds code)'
    }
    case 'MYR': {
      return 'Malaysian ringgit'
    }
    case 'MZN': {
      return 'Mozambican metical'
    }
    case 'NAD': {
      return 'Namibian dollar'
    }
    case 'NGN': {
      return 'Nigerian naira'
    }
    case 'NIO': {
      return 'Nicaraguan córdoba'
    }
    case 'NOK': {
      return 'Norwegian krone'
    }
    case 'NPR': {
      return 'Nepalese rupee'
    }
    case 'NZD': {
      return 'New Zealand dollar'
    }
    case 'OMR': {
      return 'Omani rial'
    }
    case 'PAB': {
      return 'Panamanian balboa'
    }
    case 'PEN': {
      return 'Peruvian sol'
    }
    case 'PGK': {
      return 'Papua New Guinean kina'
    }
    case 'PHP': {
      return 'Philippine peso'
    }
    case 'PKR': {
      return 'Pakistani rupee'
    }
    case 'PLN': {
      return 'Polish złoty'
    }
    case 'PYG': {
      return 'Paraguayan guaraní'
    }
    case 'QAR': {
      return 'Qatari riyal'
    }
    case 'RON': {
      return 'Romanian leu'
    }
    case 'RSD': {
      return 'Serbian dinar'
    }
    case 'CNY': {
      return 'Renminbi'
    }
    case 'RUB': {
      return 'Russian ruble'
    }
    case 'RWF': {
      return 'Rwandan franc'
    }
    case 'SAR': {
      return 'Saudi riyal'
    }
    case 'SBD': {
      return 'Solomon Islands dollar'
    }
    case 'SCR': {
      return 'Seychelles rupee'
    }
    case 'SDG': {
      return 'Sudanese pound'
    }
    case 'SEK': {
      return 'Swedish krona (plural: kronor)'
    }
    case 'SGD': {
      return 'Singapore dollar'
    }
    case 'SHP': {
      return 'Saint Helena pound'
    }
    case 'SLE': {
      return 'Sierra Leonean leone (new leone)'
    }
    case 'SLL': {
      return 'Sierra Leonean leone (old leone)'
    }
    case 'SOS': {
      return 'Somali shilling'
    }
    case 'SRD': {
      return 'Surinamese dollar'
    }
    case 'SSP': {
      return 'South Sudanese pound'
    }
    case 'STN': {
      return 'São Tomé and Príncipe dobra'
    }
    case 'SVC': {
      return 'Salvadoran colón'
    }
    case 'SYP': {
      return 'Syrian pound'
    }
    case 'SZL': {
      return 'Swazi lilangeni'
    }
    case 'THB': {
      return 'Thai baht'
    }
    case 'TJS': {
      return 'Tajikistani somoni'
    }
    case 'TMT': {
      return 'Turkmenistan manat'
    }
    case 'TND': {
      return 'Tunisian dinar'
    }
    case 'TOP': {
      return 'Tongan paʻanga'
    }
    case 'TRY': {
      return 'Turkish lira'
    }
    case 'TTD': {
      return 'Trinidad and Tobago dollar'
    }
    case 'TWD': {
      return 'New Taiwan dollar'
    }
    case 'TZS': {
      return 'Tanzanian shilling'
    }
    case 'UAH': {
      return 'Ukrainian hryvnia'
    }
    case 'UGX': {
      return 'Ugandan shilling'
    }
    case 'USD': {
      return 'United States dollar'
    }
    case 'USN': {
      return 'United States dollar (next day) (funds code)'
    }
    case 'UYI': {
      return 'Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)'
    }
    case 'UYU': {
      return 'Uruguayan peso'
    }
    case 'UYW': {
      return 'Unidad previsional'
    }
    case 'UZS': {
      return 'Uzbekistan sum'
    }
    case 'VED': {
      return 'Venezuelan digital bolívar'
    }
    case 'VES': {
      return 'Venezuelan sovereign bolívar'
    }
    case 'VND': {
      return 'Vietnamese đồng'
    }
    case 'VUV': {
      return 'Vanuatu vatu'
    }
    case 'WST': {
      return 'Samoan tala'
    }
    case 'XAF': {
      return 'CFA franc BEAC'
    }
    case 'XAG': {
      return 'Silver (one troy ounce)'
    }
    case 'XAU': {
      return 'Gold (one troy ounce)'
    }
    case 'XBA': {
      return 'European Composite Unit (EURCO) (bond market unit)'
    }
    case 'XBB': {
      return 'European Monetary Unit (E.M.U.-6) (bond market unit)'
    }
    case 'XBC': {
      return 'European Unit of Account 9 (E.U.A.-9) (bond market unit)'
    }
    case 'XBD': {
      return 'European Unit of Account 17 (E.U.A.-17) (bond market unit)'
    }
    case 'XCD': {
      return 'East Caribbean dollar'
    }
    case 'XDR': {
      return 'Special drawing rights'
    }
    case 'XOF': {
      return 'CFA franc BCEAO'
    }
    case 'XPD': {
      return 'Palladium (one troy ounce)'
    }
    case 'XPF': {
      return 'CFP franc (franc Pacifique)'
    }
    case 'XPT': {
      return 'Platinum (one troy ounce)'
    }
    case 'XSU': {
      return 'SUCRE'
    }
    case 'XTS': {
      return 'Code reserved for testing'
    }
    case 'XUA': {
      return 'ADB Unit of Account'
    }
    case 'XXX': {
      return 'No currency'
    }
    case 'YER': {
      return 'Yemeni rial'
    }
    case 'ZAR': {
      return 'South African rand'
    }
    case 'ZMW': {
      return 'Zambian kwacha'
    }
    case 'ZWL': {
      return 'Zimbabwean dollar (fifth)'
    }
    default: {
      return ''
    }
  }
}

export function formatCurrencyCodeDisplay(code: string, character_limit: number): string {
  const description = getCurrencyCodeDescription(code)
  if (description === '') {
    return code
  }
  const formatted = `${code} (${description})`
  if (formatted.length > character_limit) {
    const sub = formatted.substring(0, character_limit)
    const ellipsis = String.fromCharCode(8230)
    return `${sub}${ellipsis}`
  }
  return formatted
}
