import React, { useEffect, useRef, useState } from 'react'
import { Box, CardMedia, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ReactComponent as NoMediaImage } from '../../images/no-media.svg'
import { ReactComponent as PlayButton } from '../../icons/play-button.svg'
import storyMessagePlaceholder from '../../images/story-message-placeholder.png'
import { isTypeName } from '../../types/utility'
import { secondary } from '../../loudcrowd-theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      width: 105,
      height: 215,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    mediaWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      borderRight: `1px solid rgba(0, 0, 0, .12)`,
      marginTop: 6,
    },
    storyMedia: {
      objectFit: 'contain',
      borderRadius: theme.shape.borderRadius,
      width: 105,
      height: 215,
    },
    playButtonContainer: {
      cursor: 'pointer',
      position: 'absolute',
      top: '45%',
      left: '35%',
    },
    skeletonOverlay: {
      width: 105,
      height: 215,
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: theme.shape.borderRadius,
    },
    videoMessageContainer: {
      maxWidth: 300,
    },
    storyBox: {
      width: 105,
      height: 215,
      borderRadius: 20,
      background: 'linear-gradient(90deg, #D610E8 0%, #00ACFF 100%)',
    },
    expiredStoryBox: {
      width: 105,
      height: 215,
      borderRadius: 20,
      border: '1px solid #DAD9E6',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    expiredMediaText: {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
    },
    storyBar: {
      width: 3,
      height: 215,
      backgroundColor: secondary[400],
      marginLeft: 7,
      borderRadius: 2,
    },
  }),
)

interface MessagePhoneMediaType {
  media?: {
    id?: string | null
    lcMediaUrl?: string | null
    mediaUrl?: string | null
    mediaType?: string | null
    postType?: string | null
    fromAuthedApi?: boolean | null
    isExpired?: boolean | null
    permalink?: string | null
    __typename: string
  } | null
}
function MessagePhoneMedia({ media }: MessagePhoneMediaType): React.ReactElement {
  const classes = useStyles()
  const [mediaFailed, setMediaFailed] = useState(false)
  const [loadingMedia, setLoadingMedia] = useState(true)
  const [triedVideo, setTriedVideo] = useState<boolean>(false)
  const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false)

  const mediaType = media?.mediaType || null
  const [mediaTypeState, setMediaType] = useState<string | null>(mediaType || null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const url = media?.lcMediaUrl || media?.mediaUrl || undefined
  const isVideo = mediaTypeState === 'VIDEO'
  const isUnauthedStory = !media?.fromAuthedApi
  const renderVideoEl = (!isUnauthedStory && isVideo) || (isUnauthedStory && !triedVideo)

  const isVideoTag = (elm: HTMLDivElement | HTMLVideoElement): elm is HTMLVideoElement =>
    (elm as HTMLVideoElement).play !== undefined

  useEffect(() => {
    const isIGMedia = media && isTypeName(media, 'IGMedia')
    const isTTMedia = media && isTypeName(media, 'TTMedia')
    if (isIGMedia && mediaType !== null && mediaType !== mediaTypeState) {
      setMediaType(mediaType)
    } else if (isTTMedia && url) {
      const mediaType = url.includes('.mp4') ? 'VIDEO' : 'IMAGE'
      setMediaType(mediaType)
    }
  }, [media, url, mediaType, mediaTypeState])

  function handleOnMediaLoad(e: React.SyntheticEvent) {
    if (isUnauthedStory) {
      const tag = e.currentTarget.tagName
      setMediaType(tag)
    }
    setLoadingMedia(false)
  }

  function handleOnMediaError() {
    if (!isUnauthedStory) {
      setMediaFailed(true)
    } else if (isUnauthedStory) {
      triedVideo ? setMediaFailed(true) : setTriedVideo(true)
    }
    setLoadingMedia(false)
  }

  function handleOnClickPlay(event: React.MouseEvent) {
    event.stopPropagation()
    if (mediaTypeState === 'VIDEO' && videoRef.current && videoRef.current.paused) {
      videoRef.current.play()
      setIsPlayingVideo(true)
    }
  }

  function handleOnPause() {
    if (mediaTypeState === 'VIDEO' && videoRef.current && isVideoTag(videoRef.current)) {
      videoRef.current.pause()
      setIsPlayingVideo(false)
    }
  }

  return (
    <Box className={classes.mediaWrapper}>
      {!media && (
        <Box className={classes.storyBox}>
          <img src={storyMessagePlaceholder} alt="Story Message Placeholder" />
        </Box>
      )}
      {media && media.isExpired && (
        <Box className={classes.expiredStoryBox}>
          <NoMediaImage width="87px" height="111px" />
          <Box>
            <Typography className={classes.expiredMediaText}>Story Expired</Typography>
          </Box>
        </Box>
      )}
      {media && !media.isExpired && (
        <>
          {loadingMedia && <Skeleton className={classes.skeletonOverlay} variant="rect" />}
          <Box className={classes.containerWrapper}>
            {!loadingMedia && mediaFailed && (
              <Box my={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <NoMediaImage width={80} height={120} />
                {isVideo ? (
                  <Box className={classes.videoMessageContainer}>
                    <Typography component="p" variant="body2" align="center">
                      Video cannot be displayed
                    </Typography>
                  </Box>
                ) : (
                  <Typography component="p" variant="body2" align="center">
                    Image not found
                  </Typography>
                )}
              </Box>
            )}
            {!mediaFailed && (
              <CardMedia
                component={renderVideoEl ? 'video' : 'img'}
                className={classes.storyMedia}
                ref={isVideo ? videoRef : undefined}
                src={url}
                controls={isPlayingVideo}
                onPause={handleOnPause}
                onError={handleOnMediaError}
                onLoadedMetadata={handleOnMediaLoad}
                onLoad={handleOnMediaLoad}
              />
            )}
            {!mediaFailed && !loadingMedia && isVideo && !isPlayingVideo && (
              <Box className={classes.playButtonContainer}>
                <PlayButton onClick={handleOnClickPlay} height={40} width={40} />
              </Box>
            )}
          </Box>
        </>
      )}
      <Box className={classes.storyBar} />
    </Box>
  )
}

export default MessagePhoneMedia
