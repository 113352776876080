import React, { useContext, createContext, FC, useState } from 'react'

type ToastSettingsType = {
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined
  title?: string
  message?: string
  isOpen?: boolean
  onClose?(): void
  icon?: React.ReactNode
  autoHideDuration?: number
}

export type ToastContextType = {
  settings: ToastSettingsType
  showToast: (settings: ToastSettingsType) => void
  hideToast: () => void
}

export const ToastContext = createContext<ToastContextType>({
  settings: { isOpen: false },
  showToast: () => null,
  hideToast: () => null,
})

export const ToastProvider: FC = ({ children }) => {
  const [settings, setSettings] = useState<ToastSettingsType>({ isOpen: false })

  function showToast(settings: ToastSettingsType) {
    setSettings({ ...settings, isOpen: true })
  }

  function hideToast() {
    setSettings({ isOpen: false })
  }

  return <ToastContext.Provider value={{ settings, showToast, hideToast }}>{children}</ToastContext.Provider>
}

export const useToast = (): ToastContextType => useContext(ToastContext)
