import React, { useState, useRef } from 'react'
import { differenceInDays, subDays } from 'date-fns'
import { Box, Typography, Button, Menu } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import DashboardOverviewTab from './DashboardOverviewTab'
import DateRangePicker from '../components/DateRangePicker/DateRangePicker'
import metricMap from '../components/metric/metric-map'
import MetricLineChart from '../components/metric/MetricLineChart'
import { getPreviousRangeDates, DateRange } from '../utils/date-range-helper'
import { AllStatsFieldsFragment, useDashboardOverviewQuery } from './operations/query-dashboard-overview.generated'
import ContainerError from '../components/ContainerError'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'
import Switch from '../components/Switch'
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down_minor.svg'
import {
  DateRangeFilter,
  DateTimeRangeFilter,
  DateRangeFilterUnits,
  TimeDimension,
  IgMediaPostType,
  MentionStatus,
  AnySegmentCampaignFilterInput,
  ChallengesFilterInput,
  LabelsFilter,
} from '../gql-global'
import { postStatusLabels, postStatusOptions, postStatusTypesLength, postTypeLabels } from './constants'
import SelectionPickerMenuContent from '../components/SelectionPicker'
import { NetworkStatus } from '@apollo/client'
import { isTypeName } from '../types/utility'
import {
  igMentionTypeOptions,
  IgMentionTypes,
  TtMentionTypes,
  MentionTypes,
  postTypeOptions,
  ttMentionTypeOptions,
  mentionTypesLabels,
} from '../content/constants'
import { makeMentionTypesWhere, postStatusArrayFilterFromSelection } from './utils'
import useHasFeature from '../hooks/useHasFeature'
import DashboardCustomerFilter from './DashboardCustomerFilter'
import { vRefLine } from '../components/charts/types'
import { formatChartMonthDay } from '../components/charts/helpers'
import BulkLabelsFilterMenu from '../components/lists/ListFilters/BulkLabelsFilterMenu'

const useStyles = makeStyles({
  h7: {
    //MUI doesn't have a h7 whereas the mocks did
    fontSize: '1.125rem',
    lineHeight: 1.5,
    fontWeight: 600,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 240,
  },
  filterButton: {
    margin: '0px 8px 8px 0px',
  },
})

interface Tab {
  id: string
  dataKey: Exclude<keyof AllStatsFieldsFragment, '__typename'>
  metricType: keyof typeof metricMap
}

interface CampaignDataLabel extends AccountLabel {
  startAt: Date
}

const OVERVIEW_TABS: readonly [Tab, Tab, Tab, Tab] = [
  {
    id: 'POSTS',
    dataKey: 'posts',
    metricType: 'igPosts',
  },
  {
    id: 'IMPRESSIONS',
    dataKey: 'impressions',
    metricType: 'impressions',
  },
  {
    id: 'ENGAGEMENT',
    dataKey: 'engagement',
    metricType: 'engagement',
  },
  {
    id: 'EMV',
    dataKey: 'emv',
    metricType: 'emv',
  },
] as const

export interface DashboardOverviewBlockProps {
  socialAccountId?: string | null
  dateSelectionCallback(newFilter: DateRangeFilter | DateTimeRangeFilter): void
  realizedDateRange: DateRange
  dateRangeFilter: DateRangeFilter | DateTimeRangeFilter
  segments: AccountLabel[] | null
  campaignData: CampaignDataLabel[] | null
  segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null
  setSegmentCampaignFilter: (segmentCampaignFilter: AnySegmentCampaignFilterInput[] | null) => void
  selectedMentionTypes: Set<MentionTypes> | null
  selectedPostTypes: Set<IgMediaPostType> | null
  onSelectMentionTypes(mentionTypes: Set<MentionTypes>): void
  onSelectPostTypes(postTypes: Set<IgMediaPostType>): void
  selectedPostStatus: Set<MentionStatus> | null
  onSelectPostStatus(postStatuses: Set<MentionStatus>): void
  emvCpmUsd?: number | null
  isInstagramAccount?: boolean | null
  includePremembership: boolean
  onSetIncludePremembership(include: boolean): void
  challengesData: AccountLabel<string>[] | null
  selectedChallenges?: ChallengesFilterInput | null
  onSelectChallenges(challenges: ChallengesFilterInput): void
}

const DashboardOverviewBlock: React.FC<DashboardOverviewBlockProps> = ({
  socialAccountId,
  dateSelectionCallback,
  realizedDateRange,
  dateRangeFilter,
  includePremembership,
  onSetIncludePremembership,
  segments = null,
  campaignData = null,
  selectedPostTypes = null,
  segmentCampaignFilter,
  setSegmentCampaignFilter,
  selectedMentionTypes,
  onSelectMentionTypes,
  onSelectPostTypes,
  selectedPostStatus,
  onSelectPostStatus,
  emvCpmUsd,
  isInstagramAccount,
  challengesData = null,
  selectedChallenges = null,
  onSelectChallenges,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [activeOverviewTab, setActiveOverviewTab] = useState(OVERVIEW_TABS[0])
  const [postTypePickerOpen, setPostTypePickerOpen] = useState(false)
  const [mentionTypePickerOpen, setMentionTypePickerOpen] = useState(false)
  const [postStatusPickerOpen, setPostStatusPickerOpen] = useState(false)
  const [challengesPickerOpen, setChallengesPickerOpen] = useState(false)
  const postTypeButtonRef = useRef<HTMLButtonElement>(null)
  const mentionTypeButtonRef = useRef<HTMLButtonElement>(null)
  const postStatusButtonRef = useRef<HTMLButtonElement>(null)
  const challengesButtonRef = useRef<HTMLButtonElement>(null)
  const previousDateRange = getPreviousRangeDates(dateRangeFilter)
  const dateRangeLength = differenceInDays(realizedDateRange.lt, realizedDateRange.gte)
  const endDate =
    isTypeName(dateRangeFilter, 'AbsoluteDateTimeRangeFilter') ||
    (isTypeName(dateRangeFilter, 'RelativeDateRangeFilter') &&
      dateRangeFilter.value === 1 &&
      dateRangeFilter.unit === DateRangeFilterUnits.Days) ||
    (isTypeName(dateRangeFilter, 'RelativeDateRangeFilter') &&
      dateRangeFilter.value === 3 &&
      dateRangeFilter.unit === DateRangeFilterUnits.Months) ||
    (isTypeName(dateRangeFilter, 'RelativeDateRangeFilter') &&
      dateRangeFilter.value === 6 &&
      dateRangeFilter.unit === DateRangeFilterUnits.Months) ||
    (isTypeName(dateRangeFilter, 'RelativeDateRangeFilter') &&
      dateRangeFilter.value === 12 &&
      dateRangeFilter.unit === DateRangeFilterUnits.Months)
      ? realizedDateRange.lt || new Date()
      : subDays(realizedDateRange.lt || new Date(), 1)
  const timeDimension =
    dateRangeLength < 60 ? TimeDimension.Day : dateRangeLength < 320 ? TimeDimension.Week : TimeDimension.Month
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const campaigns: AccountLabel[] = campaignData
    ? campaignData.map(campaign => ({
        id: campaign.id,
        name: campaign.name,
      }))
    : []

  const challenges: AccountLabel<string>[] = challengesData
    ? challengesData.map(challenge => ({
        id: challenge.id.toString(),
        name: challenge.name || '',
      }))
    : []

  const postTypeLength = 3
  const { loading, data, error, networkStatus } = useDashboardOverviewQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    variables: {
      socialAccountId: socialAccountId || '',
      segmentsCampaigns: segmentCampaignFilter ?? [],
      startDate: realizedDateRange.gte,
      endDate,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      prevEndDate: previousDateRange?.lt,
      prevStartDate: previousDateRange?.gte,
      timeDimension,
      ...makeMentionTypesWhere(selectedMentionTypes, isInstagramAccount ?? false, hasTikTokHashtags),
      tagStatus: postStatusArrayFilterFromSelection(selectedPostStatus),
      postType: Array.from(selectedPostTypes ?? []),
      includePremembership: includePremembership,
      challengeIds: selectedChallenges || {},
    },
  })

  function handleApplyPostTypes(values: Set<IgMediaPostType>): void {
    setPostTypePickerOpen(false)
    onSelectPostTypes(values)
  }

  const { currentPeriodStats, previousPeriodStats, mentionStatsTimeseries } = data?.socialAccount || {}

  const refLines: vRefLine[] = []
  // if filtering by campaign
  const isFilteringCampaign = !!segmentCampaignFilter?.find(afilter => {
    return afilter?.campaigns && afilter.campaigns.length > 0
  })

  if (mentionStatsTimeseries && campaignData) {
    for (let i = 0; i < campaignData.length; i++) {
      const campaign = campaignData[i]
      if (!campaign) {
        continue
      }

      // include this campaign if either...
      // not filtering by campaign
      // or if this campaign exists anywhere in the campaign filter
      const includeCampaign =
        !isFilteringCampaign ||
        !!segmentCampaignFilter?.find(afilter => {
          return !!afilter.campaigns?.find(id => id === campaign.id.toString())
        })

      const datapoint = mentionStatsTimeseries.find(
        stats => stats.startDate <= campaign.startAt && stats.endDate > campaign.startAt,
      )

      // dont include the campaign if the start date is outside the date range picker
      if (!!datapoint && includeCampaign) {
        refLines.push({
          x: {
            startDate: datapoint.startDate,
            endDate: datapoint.endDate,
          },
          topLabel: campaign.name,
          botLabel: `Program Start: ${formatChartMonthDay(campaign.startAt)}`,
        })
      }
    }
  }

  function filterButtonLabel(
    defaultLabel: string,
    filterSelection: string[],
    allOptionsLength: number,
    allOptionsLabel = true,
    useTwoSelectionPlus = true,
  ) {
    let buttonLabel = defaultLabel
    if (!filterSelection.length) {
      return buttonLabel
    } else if (filterSelection[0] && filterSelection.length === 1) {
      buttonLabel = filterSelection[0]
    } else if (useTwoSelectionPlus && filterSelection.length === 2) {
      buttonLabel = `${filterSelection[0]} + ${filterSelection[1]}`
    } else if (filterSelection.length === allOptionsLength) {
      buttonLabel = allOptionsLabel ? `All ${buttonLabel}` : buttonLabel
    } else {
      buttonLabel = `${defaultLabel} (${filterSelection.length})`
    }
    return buttonLabel
  }

  const mentionTypeLength = Object.values(isInstagramAccount ? IgMentionTypes : TtMentionTypes).length
  const mentionTypeButtonLabel = filterButtonLabel(
    'Mentions',
    Array.from(selectedMentionTypes ?? []).map(mt => mentionTypesLabels[mt]),
    mentionTypeLength,
    false,
    false,
  )

  const postStatusButtonLabel = filterButtonLabel(
    'Post Status',
    Array.from(selectedPostStatus ?? []).map(ps => postStatusLabels[ps]),
    postStatusTypesLength,
    false,
    false,
  )

  const postTypeButtonLabel = filterButtonLabel(
    'Posts',
    Array.from(selectedPostTypes ?? []).map(pt => postTypeLabels[pt]),
    postTypeLength,
    false,
    false,
  )

  const challengesButtonLabel = filterButtonLabel(
    'Challenges',
    selectedChallenges?.any?.map(
      challengeId => challenges.find(challenge => challenge.id === challengeId)?.name || '',
    ) || [],
    challenges.length ?? 0,
    false,
    false,
  )

  const defaultLabelValues = {
    any: null,
    none: null,
    all: null,
  }

  const isFilteringByProgram = !!segmentCampaignFilter?.filter(item => !!item?.campaigns?.length).length
  return (
    <Box data-intercom-target="Dashboard Overview Block">
      <Box width="100%" mb={7} display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <DashboardCustomerFilter
            campaigns={campaigns}
            segments={segments}
            segmentCampaignFilter={segmentCampaignFilter}
            setSegmentCampaignFilter={setSegmentCampaignFilter}
          />
          {isInstagramAccount && (
            <Button
              color={selectedPostTypes?.size !== postTypeLength ? 'primary' : undefined}
              data-intercom-target="Dashboard Post Type Picker"
              variant="outlined"
              ref={postTypeButtonRef}
              onClick={() => setPostTypePickerOpen(true)}
              endIcon={<ChevronDownIcon height="16px" width="16px" />}
              className={classes.filterButton}
            >
              <Typography className={classes.h7}>{postTypeButtonLabel}</Typography>
            </Button>
          )}
          {(isInstagramAccount || hasTikTokHashtags) && (
            <Button
              color={selectedMentionTypes?.size !== mentionTypeLength ? 'primary' : undefined}
              data-intercom-target="Dashboard Mention Type Picker"
              variant="outlined"
              ref={mentionTypeButtonRef}
              onClick={() => setMentionTypePickerOpen(true)}
              endIcon={<ChevronDownIcon height="16px" width="16px" />}
              className={classes.filterButton}
            >
              <Typography className={classes.h7}>{mentionTypeButtonLabel}</Typography>
            </Button>
          )}
          <Button
            color={selectedPostStatus?.size !== postStatusTypesLength ? 'primary' : undefined}
            data-intercom-target="Dashboard Post Status Picker"
            variant="outlined"
            ref={postStatusButtonRef}
            onClick={() => setPostStatusPickerOpen(true)}
            endIcon={<ChevronDownIcon height="16px" width="16px" />}
            className={classes.filterButton}
          >
            <Typography className={classes.h7}>{postStatusButtonLabel}</Typography>
          </Button>
          <Button
            color={selectedChallenges?.any?.length ? 'primary' : undefined}
            data-intercom-target="Dashboard Challenges Picker"
            variant="outlined"
            ref={challengesButtonRef}
            onClick={() => setChallengesPickerOpen(true)}
            endIcon={<ChevronDownIcon height="16px" width="16px" />}
            className={classes.filterButton}
          >
            <Typography className={classes.h7}>{challengesButtonLabel}</Typography>
          </Button>
        </Box>
        <Box flexGrow={1} />
        <Box data-intercom-target="Dashboard Date Picker">
          <DateRangePicker onChange={dateSelectionCallback} selectedDateRange={dateRangeFilter} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="h6">UGC Performance</Typography>
        {isFilteringByProgram && (
          <Box display="flex">
            <Switch checked={includePremembership} onChange={() => onSetIncludePremembership(!includePremembership)} />
            <Box ml={3}>
              <Typography noWrap>Include pre-membership activity</Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {OVERVIEW_TABS.map(tab => {
          let metricType = tab.metricType
          if (tab.dataKey === 'posts') {
            metricType = isInstagramAccount ? 'igPosts' : 'ttPosts'
          }
          return (
            <DashboardOverviewTab
              active={activeOverviewTab.id === tab.id}
              onClick={(): void => setActiveOverviewTab(tab)}
              key={tab.id}
              metric={tab.dataKey && currentPeriodStats?.[tab.dataKey]}
              previousMetric={tab.dataKey && previousPeriodStats?.[tab.dataKey]}
              loading={loading && networkStatus !== NetworkStatus.refetch}
              metricType={metricType}
              dateRangeFilter={dateRangeFilter}
              accountEMV={emvCpmUsd}
            />
          )
        })}
      </Box>
      <Box
        height="434px"
        bgcolor={theme.palette.background.paper}
        borderRadius="0 0 8px 8px"
        padding="100px 44px 10px 32px"
      >
        {mentionStatsTimeseries && (
          <MetricLineChart
            metricType={activeOverviewTab.metricType}
            data={networkStatus !== NetworkStatus.refetch ? mentionStatsTimeseries : []}
            dataKey={activeOverviewTab.dataKey}
            vRefLines={refLines}
          />
        )}
        {error && <ContainerError text="Could not load chart" />}
      </Box>
      <Menu
        open={postTypePickerOpen}
        anchorEl={postTypeButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setPostTypePickerOpen(false)}
      >
        <SelectionPickerMenuContent
          onSelectValue={(value: Set<IgMediaPostType>) => handleApplyPostTypes(value)}
          value={selectedPostTypes || undefined}
          setPickerOpen={setPostTypePickerOpen}
          option={{
            name: 'postType',
            type: 'selection',
            label: 'Post Type',
            selectionOptions: postTypeOptions,
          }}
          resetValues={new Set<IgMediaPostType>(Object.values(IgMediaPostType))}
        />
      </Menu>
      <Menu
        open={mentionTypePickerOpen}
        anchorEl={mentionTypeButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMentionTypePickerOpen(false)}
      >
        <SelectionPickerMenuContent
          onSelectValue={onSelectMentionTypes}
          setPickerOpen={setMentionTypePickerOpen}
          value={selectedMentionTypes || undefined}
          option={{
            name: 'mentionType',
            type: 'selection',
            label: 'Mention Type',
            selectionOptions: isInstagramAccount ? igMentionTypeOptions : ttMentionTypeOptions,
          }}
          resetValues={new Set<MentionTypes>(Object.values(isInstagramAccount ? IgMentionTypes : TtMentionTypes))}
        />
      </Menu>
      <Menu
        open={postStatusPickerOpen}
        anchorEl={postStatusButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setPostStatusPickerOpen(false)}
      >
        <SelectionPickerMenuContent
          onSelectValue={onSelectPostStatus}
          setPickerOpen={setPostStatusPickerOpen}
          value={selectedPostStatus || undefined}
          option={{
            name: 'postStatus',
            type: 'selection',
            label: 'Post Status',
            selectionOptions: postStatusOptions,
          }}
          resetValues={new Set<MentionStatus>(Object.values(MentionStatus))}
        />
      </Menu>
      <Menu
        open={challengesPickerOpen}
        anchorEl={challengesButtonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setChallengesPickerOpen(false)}
        variant="selectedMenu"
      >
        <BulkLabelsFilterMenu
          option={{
            name: 'challengeIds',
            type: 'labels',
            label: 'Challenges',
            entity: 'challenge',
            selectionOptions: challenges,
            includeField: 'any',
          }}
          value={
            {
              __typename: 'LabelsFilter',
              ...defaultLabelValues,
              ...(selectedChallenges || {}),
            } as LabelsFilter
          }
          onDelete={() => {
            onSelectChallenges({
              ...defaultLabelValues,
            })
            setChallengesPickerOpen(false)
          }}
          onSelectValue={newValue => {
            const { __typename, ...value } = newValue
            onSelectChallenges(value)
            setChallengesPickerOpen(false)
          }}
          includeField="any"
        />
      </Menu>
    </Box>
  )
}

export default DashboardOverviewBlock
