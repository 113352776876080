import React, { useEffect, useState } from 'react'
import { FormControlLabel, Checkbox, Button } from '@material-ui/core'
import { useUserInfoQuery } from '../queries/operations/user-info.generated'
import { getUserFeatures, userHasFeature } from '../utils/feature-manager'
import { getFeatureConfig } from '../features'
import Page from '../Page'

function FeatureOverride(): React.ReactElement {
  const { data } = useUserInfoQuery()
  const [selectedFeatures, setSelectedFeatures] = useState<Set<string>>(new Set())
  const allFeatures = getFeatureConfig().features

  useEffect(() => {
    const userFeatures = data?.whoami ? getUserFeatures(data?.whoami) : []
    setSelectedFeatures(new Set(userFeatures))
  }, [data?.whoami])

  function handleChange(feature: string, checked: boolean) {
    const featureCopy = new Set(selectedFeatures)
    if (checked) {
      featureCopy.add(feature)
    } else {
      featureCopy.delete(feature)
    }
    setSelectedFeatures(featureCopy)
  }

  function handleSave() {
    if (!data?.whoami) {
      return
    }
    const overrides: Record<string, boolean> = {}
    for (const feature of allFeatures) {
      const naturalFeature = userHasFeature(data.whoami, feature.id, false)
      const selected = selectedFeatures.has(feature.id)
      if (naturalFeature !== selected) {
        overrides[feature.id] = selected
      }
    }
    localStorage.setItem('featureOverrides', JSON.stringify(overrides))
  }

  return (
    <Page>
      {allFeatures.map(feature => (
        <div key={feature.id}>
          <FormControlLabel
            label={feature.id}
            control={
              <Checkbox
                checked={selectedFeatures.has(feature.id)}
                onChange={(_, checked) => handleChange(feature.id, checked)}
              />
            }
          />
        </div>
      ))}
      <Button onClick={handleSave}>Save</Button>
    </Page>
  )
}

export default FeatureOverride
