import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageFormDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.RewardFilterInput>;
}>;


export type MessageFormDataQuery = { __typename: 'Query', socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null, socialUser: { __typename: 'IGUser', id: string, username: string, avatarUrl?: string | null, followerCount?: number | null } } | { __typename: 'TTSocialAccount', id: string, socialUser: { __typename: 'TTUser', id: string, username: string, avatarUrl?: string | null, followerCount?: number | null } }> | null, whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, rewards?: { __typename: 'PagedRewardType', results: Array<{ __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesLevel?: Types.CodesLevel | null } }> } | null } | null } | null };


export const MessageFormDataDocument = gql`
    query MessageFormData($where: RewardFilterInput) {
  socialAccounts {
    id
    socialUser {
      id
      username
      avatarUrl
      followerCount
    }
    ... on IGSocialAccount {
      fbUsers {
        id
        isValid
      }
    }
  }
  whoami {
    id
    account {
      id
      rewards(where: $where) {
        results {
          id
          name
          stats {
            codesLevel
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMessageFormDataQuery__
 *
 * To run a query within a React component, call `useMessageFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageFormDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMessageFormDataQuery(baseOptions?: Apollo.QueryHookOptions<MessageFormDataQuery, MessageFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageFormDataQuery, MessageFormDataQueryVariables>(MessageFormDataDocument, options);
      }
export function useMessageFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageFormDataQuery, MessageFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageFormDataQuery, MessageFormDataQueryVariables>(MessageFormDataDocument, options);
        }
export type MessageFormDataQueryHookResult = ReturnType<typeof useMessageFormDataQuery>;
export type MessageFormDataLazyQueryHookResult = ReturnType<typeof useMessageFormDataLazyQuery>;
export type MessageFormDataQueryResult = Apollo.QueryResult<MessageFormDataQuery, MessageFormDataQueryVariables>;