import React from 'react'
import { Box, Button, CircularProgress, createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { gray } from '../loudcrowd-theme'
import { MentionStatus } from '../gql-global'

interface RuleButtonsProps {
  excludeOfficialReview?: boolean
  onChangeStatus?(status: MentionStatus): void
  currentStatus?: MentionStatus
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: gray,
      padding: '2px 20px',
      height: 30,
      border: '0 none',
      marginRight: 10,
    },
    green: {
      background: theme.palette.success.light,
      color: theme.palette.success.dark,
    },
    red: {
      background: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    orange: {
      background: theme.palette.warning.light,
      color: theme.palette.warning.dark,
    },
  }),
)

interface StatusOptions {
  value: MentionStatus
  name: string
}

export const statusOptions: StatusOptions[] = [
  {
    value: MentionStatus.Verified,
    name: 'Approve',
  },
  {
    value: MentionStatus.Rejected,
    name: 'Reject',
  },
  {
    value: MentionStatus.OfficialReview,
    name: 'Needs Official Review',
  },
]

const RuleButtons: React.FC<RuleButtonsProps> = ({ excludeOfficialReview, onChangeStatus, currentStatus, loading }) => {
  const classes = useStyles()

  const options = excludeOfficialReview
    ? statusOptions.filter(value => value.value !== MentionStatus.OfficialReview)
    : statusOptions

  const backgroundClass = (status: MentionStatus) => {
    if (status === currentStatus) {
      switch (currentStatus) {
        case MentionStatus.Verified:
          return classes.green
        case MentionStatus.Rejected:
          return classes.red
        case MentionStatus.OfficialReview:
          return classes.orange
      }
    }
  }

  return (
    <Box my={3} alignItems="center" display="flex">
      {options.map(option => (
        <Button
          variant="outlined"
          className={`${classes.button} ${backgroundClass(option.value)}`}
          onClick={() => onChangeStatus && onChangeStatus(option.value)}
          key={option.value}
        >
          {option.name}
        </Button>
      ))}
      {loading && <CircularProgress color="inherit" size={15} />}
    </Box>
  )
}

export default RuleButtons
