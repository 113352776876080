import React, { ChangeEvent, useState } from 'react'
import { Box, Button, Paper, Typography, Card, CardMedia } from '@material-ui/core'
import { TextField } from '../../components/TextField/TextField'
import { useLookupIgMediaQuery } from './operations/ig_media.generated'
import { useLookupSocialAccountQuery } from './operations/social_account.generated'
import { useAddStoryMentionMutation } from './operations/add_story_mention.generated'
import withAuthorization from '../../withAuthorization'
import { ADMIN_ADD_STORY_MENTION } from '../routes'
import { isTypeName } from '../../types/utility'

function AddStoryMention(): React.ReactElement {
  const [socialAccountId, setSocialAccountId] = useState<string | null>(null)
  const [igMediaId, setIgMediaId] = useState<string | null>(null)
  const [addStoryMetion] = useAddStoryMentionMutation()
  const [newMentionId, setNewMentionId] = useState<string | null>(null)
  const [error, setError] = useState<boolean>(false)

  const [searchedSocialAccountId, setSearchedSocialAccountId] = useState<string | null>(null)
  const { data: socialAccountData, error: socialAccountError } = useLookupSocialAccountQuery({
    skip: !searchedSocialAccountId,
    variables: { id: searchedSocialAccountId || '' },
  })
  const socialAccount =
    socialAccountData?.socialAccount && isTypeName(socialAccountData.socialAccount, 'IGSocialAccount')
      ? socialAccountData.socialAccount
      : null

  const [searchedIgMediaId, setSearchedIgMediaId] = useState<string | null>(null)
  const { data: igMediaData, error: igMediaError } = useLookupIgMediaQuery({
    skip: !searchedIgMediaId,
    variables: { id: searchedIgMediaId || '' },
  })

  async function searchInputs() {
    setError(false)
    setSearchedSocialAccountId(socialAccountId)
    setSearchedIgMediaId(igMediaId)
  }

  function handleSave() {
    setError(false)
    if (searchedSocialAccountId && searchedIgMediaId) {
      addStoryMetion({
        variables: {
          socialAccountId: searchedSocialAccountId,
          igMediaId: searchedIgMediaId,
        },
      })
        .then(data => {
          setNewMentionId(data?.data?.addStoryMention?.igMention?.id.toString() || 'wierd, this should be an id')
        })
        .catch(() => {
          setError(true)
        })
    }
  }

  const imageUrl = igMediaData?.igMedia?.lcThumbnailUrl || igMediaData?.igMedia?.lcMediaUrl || null
  return (
    <Paper>
      <Box p={5}>
        <Box display="flex" alignItems="center" mb={5}>
          <Box mr={5} width={200}>
            Social Account ID:
          </Box>
          <TextField
            value={socialAccountId}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSocialAccountId(event.target.value)}
            autoFocus
          />
        </Box>
        <Box display="flex" alignItems="center" mb={5}>
          <Box mr={5} width={200}>
            IG Media ID:
          </Box>
          <TextField
            value={igMediaId}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setIgMediaId(event.target.value)}
            autoFocus
          />
        </Box>
        <Box display="flex" alignItems="center" mb={5}>
          <Button color="primary" variant="contained" onClick={() => searchInputs()}>
            Find
          </Button>
        </Box>
      </Box>

      {searchedIgMediaId && socialAccount && (
        <Box p={5}>
          <Box display="flex" justifyContent="space-around">
            <Card>
              <Box p={5}>
                <Typography>Social Account:</Typography>
                <Typography>{socialAccount.socialUser?.username}</Typography>
              </Box>
            </Card>
            <Card>
              <Box>
                Media:
                <Typography>
                  Caption:{igMediaData?.igMedia?.caption || igMediaData?.igMedia ? '**nocaption**' : ''}
                </Typography>
                <Typography>Username: {igMediaData?.igMedia?.poster?.username}</Typography>
                {imageUrl && <CardMedia style={{ height: 300 }} image={imageUrl} />}
              </Box>
            </Card>
          </Box>
          <Button variant="contained" color="primary" disabled={!socialAccount || !igMediaData} onClick={handleSave}>
            Save Mention
          </Button>
        </Box>
      )}
      <Box p={5}>
        {'Result: '}
        {newMentionId && <Typography>new mention Id: {newMentionId}</Typography>}
        {(error || igMediaError || socialAccountError) && (
          <Typography color="error">Error saving mention please drop a note to @engineers in slack.</Typography>
        )}
      </Box>
    </Paper>
  )
}
export default withAuthorization(ADMIN_ADD_STORY_MENTION)(AddStoryMention)
