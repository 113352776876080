import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type MessageFilterFragmentFragment = { __typename: 'ContentFilterType', id: string, name: string, entity: Types.FilterEntity, isSystem: boolean, value?: { __typename: 'ContentFilterValueType', followerCount?: { __typename: 'IntegerRangeFilter', gte?: number | null, lte?: number | null } | null, segments?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, campaigns?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, biography?: { __typename: 'KeywordsFilter', any?: Array<string> | null, none?: Array<string> | null } | null } | null };

export const MessageFilterFragmentFragmentDoc = gql`
    fragment MessageFilterFragment on ContentFilterType {
  id
  name
  entity
  isSystem
  value {
    followerCount {
      gte
      lte
    }
    segments {
      any
      none
    }
    campaigns {
      any
      none
    }
    biography {
      any
      none
    }
  }
}
    `;