// clear analytics/stats fields that filter by segment
// this can be used to invalidate cache for things that
// are filtering by segments, useful for when adding/removing

import { ApolloCache } from '@apollo/client'
import {
  CampaignMembersCampaignDocument,
  CampaignMembersCampaignQuery,
} from '../../campaign/campaign-detail/operations/campaign-members-campaign.generated'
import {
  SocialAccountChallengesDocument,
  SocialAccountChallengesQuery,
} from '../../customer/operations/query-social-account-challenges.generated'
import { IgMediaPostType } from '../../gql-global'
import { AddChallengeFormFields } from '../AddEditChallengeDialog'

// a segment from a customer.
export function clearChallengesAndSavedFilters<T>(
  cache: ApolloCache<T>,
  campaignIds: number[],
  socialAccountIds: string[] | null | undefined,
): void {
  for (const socialAccountId of socialAccountIds ?? []) {
    try {
      const socialAccountChallengesData = cache.readQuery<SocialAccountChallengesQuery>({
        query: SocialAccountChallengesDocument,
        variables: {
          socialAccountId: socialAccountId,
          limit: 200,
        },
      })
      if (socialAccountChallengesData?.socialAccount) {
        const cacheId = cache.identify(socialAccountChallengesData.socialAccount)
        cache.modify({
          id: cacheId,
          fields: {
            challenges(_, { DELETE }) {
              return DELETE
            },
          },
        })
      }
    } catch (err) {}
  }

  try {
    for (let i = 0; i < campaignIds.length; i++) {
      const campaignMembersData = cache.readQuery<CampaignMembersCampaignQuery>({
        query: CampaignMembersCampaignDocument,
        variables: {
          campaignId: `${campaignIds[i]}`,
          challengesLimit: 200,
        },
      })

      if (campaignMembersData?.campaign?.program) {
        const cacheId = cache.identify(campaignMembersData.campaign.program)
        cache.modify({
          id: cacheId,
          fields: {
            challenges(_, { DELETE }) {
              return DELETE
            },
          },
        })
      }
    }
  } catch (err) {}

  cache.gc()
}

export const buildAdditionalCriteria = (values: Partial<AddChallengeFormFields>): string => {
  const { hashtags, keywords, hashtagsCriteriaOperator, keywordsCriteriaOperator } = values
  const formattedHashtags = hashtags?.map(hashtag => (/^#/g.test(hashtag) ? hashtag : `#${hashtag}`).toLowerCase())

  const tags = hashtags?.length ? `${hashtagsCriteriaOperator} hashtags: ${formattedHashtags?.join(', ')}` : ''
  const words = keywords?.length ? `${keywordsCriteriaOperator} keywords: ${keywords?.join(', ')}` : ''

  return `Review story posts to verify presence of: \n${tags}\n${words}`
}

export const challengeNeedCriteria = (
  postTypes?: IgMediaPostType[] | null,
  hashtags?: string[] | null,
  keywords?: string[] | null,
): boolean => !!(postTypes?.includes(IgMediaPostType.Story) && (hashtags?.length || keywords?.length))
