import React, { useState } from 'react'
import useTitle from '../../utils/use-title'
import { useParams } from 'react-router-dom'
import { useAdminSocialAccountQuery } from './operations/query-admin-social-account.generated'
import { useUpdateSocialAccountHashtagMutation } from './operations/update-social-account-hashtag.generated'
import { useCreateSocialAccountHashtagMutation } from './operations/create-social-account-hashtag.generated'
import {
  Paper,
  Box,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  Checkbox,
} from '@material-ui/core'
import Page from '../../Page'
import { isTypeName } from '../../types/utility'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import AddSocialAccountHashtagDialog, { AddSocialAccountHashtagFormFields } from './AddSocialAccountHashtagDialog'
import { formatDistanceToNow } from 'date-fns'
import { SocialAccountHashtagFragmentFragmentDoc } from './operations/social-account-hashtag-fragment.generated'
import { useToast } from '../../components/Alert/ToastProvider'

export interface TikTokSocialAccountRouteParams {
  id: string
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: 1,
      lineHeight: 2,
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
)

const ACTION_UNAVAILABLE_TITLE = 'Action unavailable: An active hashtag already exists.'
const ACTION_UNAVAILABLE_MESSAGE =
  'Only 1 active hashtag is allowed per social account. First disable the active hashtag to enable this one.'
const ACTION_UNAVAILABLE_ALERT = {
  title: ACTION_UNAVAILABLE_TITLE,
  message: ACTION_UNAVAILABLE_MESSAGE,
}

const TikTokSocialAccount: React.FC = () => {
  useTitle('Admin - TikTok Social Account')
  const classes = useStyles()
  const { id } = useParams<TikTokSocialAccountRouteParams>()
  const [addOpen, setAddOpen] = useState(false)
  const { showToast } = useToast()
  const [createSocialAccountHashtag] = useCreateSocialAccountHashtagMutation()
  const [updateSocialAccountHashtag] = useUpdateSocialAccountHashtagMutation()

  const { loading, data: userSocialAccountData, error } = useAdminSocialAccountQuery({
    skip: !id,
    variables: {
      id,
    },
  })

  const socialAccount = userSocialAccountData?.socialAccount
  const socialAccountHashtags =
    (socialAccount && isTypeName(socialAccount, 'TTSocialAccount') && socialAccount.trackedHashtags) || []
  const activeTrackedHashtag =
    (socialAccountHashtags && socialAccountHashtags.find(trackedHashtags => !!trackedHashtags.active)) || false
  const trackedHashtags = (socialAccountHashtags && socialAccountHashtags.map(sah => sah.hashtag)) || []
  function handleAddSocialAccountHashtag(fields: AddSocialAccountHashtagFormFields) {
    setAddOpen(false)
    if (!!fields.active && activeTrackedHashtag) {
      showToast(ACTION_UNAVAILABLE_ALERT)
    } else if (trackedHashtags.includes(fields.name)) {
      showToast({
        title: 'Action unavailable: An hashtag already exists.',
        message: `hashtag #${fields.name} already exists on this social account`,
      })
    } else {
      if (socialAccount) {
        createSocialAccountHashtag({
          variables: {
            socialAccountId: socialAccount.id,
            hashtag: fields.name,
            active: fields.active,
          },
          update: (cache, { data }) => {
            const newTrackedHashtag = data?.createTikTokSocialAccountHashtag?.trackedHashtag
            if (newTrackedHashtag && socialAccount) {
              cache.modify({
                id: cache.identify(socialAccount),
                fields: {
                  trackedHashtags(existingTrackedHashtags) {
                    const newRef = cache.writeFragment({
                      data: newTrackedHashtag,
                      fragment: SocialAccountHashtagFragmentFragmentDoc,
                    })
                    return [newRef, ...existingTrackedHashtags]
                  },
                },
              })
            }
          },
        })
          .then(() => {
            showToast({
              title: 'Success: Adding new social account hashtag',
              message: 'Social account hashtag has been added.',
              severity: 'success',
              autoHideDuration: 5000,
            })
          })
          .catch(() => {
            showToast({
              title: 'Error: Adding new social account hashtag',
              message: 'Something went wrong when trying to add a new hashtag. Please try again.',
            })
          })
      }
    }
  }

  function handleActiveClick(socialAccountHashtagId: string, isActive: boolean) {
    if (isActive && activeTrackedHashtag) {
      showToast(ACTION_UNAVAILABLE_ALERT)
    } else {
      updateSocialAccountHashtag({
        variables: {
          socialAccountHashtagId,
          active: isActive,
        },
      })
        .then(() => {
          showToast({
            title: 'Success: Updating new social account hashtag',
            message: 'Social account hashtag has been updated.',
            severity: 'success',
            autoHideDuration: 5000,
          })
        })
        .catch(e => {
          showToast({
            title: 'Error: Updating new social account hashtag',
            message: e,
          })
        })
    }
  }

  if (loading) return <p>Loading</p>
  if (error || (!loading && !userSocialAccountData)) return <p>Error: {error && error.message}</p>

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h5" className={classes.title}>
              Social Account Hashtags for {socialAccount?.socialUser.username}
            </Typography>
          </Box>
          <Button
            disabled={!!activeTrackedHashtag}
            size="large"
            variant="contained"
            color="primary"
            startIcon={<PlusIcon width={16} height={16} />}
            onClick={() => setAddOpen(true)}
          >
            Add
          </Button>
        </Box>

        <Box mt={6}>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Hashtag</TableCell>
                  <TableCell>Hashtag Id</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {socialAccountHashtags.map(
                  (socialAccountHashtag, i): JSX.Element => (
                    <TableRow key={i} hover>
                      <TableCell>{socialAccountHashtag.hashtag}</TableCell>
                      <TableCell> {socialAccountHashtag.hashtagId}</TableCell>
                      <TableCell>
                        {socialAccountHashtag.createdAt &&
                          formatDistanceToNow(socialAccountHashtag.createdAt, { addSuffix: true })}
                      </TableCell>
                      <TableCell>
                        {socialAccountHashtag.updatedAt &&
                          formatDistanceToNow(socialAccountHashtag.updatedAt, { addSuffix: true })}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          onClick={() => handleActiveClick(socialAccountHashtag.id, !socialAccountHashtag.active)}
                          checked={!!socialAccountHashtag.active}
                          style={{ paddingLeft: 0 }}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <AddSocialAccountHashtagDialog
        open={addOpen}
        onCancel={() => setAddOpen(false)}
        onSubmit={handleAddSocialAccountHashtag}
      />
    </Page>
  )
}

export default TikTokSocialAccount
