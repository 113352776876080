import { useQueryParam, stringify, encodeQueryParams } from 'use-query-params'
import { createFilteredParam } from '../utils/url-param-utils'
import { MentionSort } from '../gql-global'

const SORT_PARAM = 'sort'
const isMentionSort = (str: string): str is MentionSort => Object.values(MentionSort).includes(str as MentionSort)

const sortParam = createFilteredParam((str: string): MentionSort | undefined => (isMentionSort(str) ? str : undefined))

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSortParam = () => useQueryParam(SORT_PARAM, sortParam)

export const encodeSortParam = (sort: MentionSort): string => {
  return stringify(encodeQueryParams({ [SORT_PARAM]: sortParam }, { [SORT_PARAM]: sort }))
}

export default useSortParam
