import React from 'react'
import { numberFormat } from '../utils/number-format'
import { Paper, Typography, Box, Button, createStyles, Theme, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { format } from 'date-fns'
import { dateTypePostfix } from '../utils/date-range-helper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& + &': {
        marginTop: theme.spacing(6),
      },
    },
  }),
)

interface CampaignRowProps {
  id?: number
  loading?: boolean
  name?: string | null
  isActive?: boolean
  participantCount?: number
  posts?: number
  avgEngagementRate?: number
  startAt?: Date | null
}

function CampaignRow({
  id,
  name,
  isActive,
  participantCount,
  posts,
  avgEngagementRate,
  loading = false,
  startAt = null,
}: CampaignRowProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Box p={8} display="flex" alignItems="center" justifyContent="space-between">
        <Box
          ml={5}
          mr={7}
          flexGrow={1}
          display="grid"
          gridTemplateColumns="2fr 1fr 1fr 1fr 1fr"
          alignItems="center"
          gridRowGap={16}
        >
          <div>
            <Typography variant="subtitle1">
              {name}
              {loading && !name && <Skeleton />}
            </Typography>
          </div>
          <Typography color="secondary" variant="subtitle2">
            Members
          </Typography>
          <Typography color="secondary" variant="subtitle2">
            Posts
          </Typography>
          <Typography color="secondary" variant="subtitle2">
            Eng. Rate
          </Typography>
          <Typography color="secondary" variant="subtitle2">
            Start{startAt ? dateTypePostfix(startAt) : ''}
          </Typography>
          <Box display="flex" alignItems="center">
            {!loading && (
              <>
                {isActive && <Box width={8} height={8} borderRadius={4} bgcolor="success.main" mr={2} />}
                <Box fontWeight="fontWeightBold" color={isActive ? 'success.main' : 'secondary.main'}>
                  {isActive ? 'Active' : 'Inactive'}
                </Box>
              </>
            )}
            {loading && <Skeleton width={100} />}
          </Box>
          <div>
            <Typography variant="subtitle1">
              {!loading ? participantCount?.toLocaleString() || 0 : <Skeleton />}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">{!loading ? posts?.toLocaleString() || 0 : <Skeleton />}</Typography>
          </div>
          <div>
            <Typography variant="subtitle1">
              {!loading ? numberFormat(avgEngagementRate || 0, { format: 'percent' }) : <Skeleton />}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1">{!loading && startAt ? format(startAt, 'PP') : <Skeleton />}</Typography>
          </div>
        </Box>
        <div>
          <Button color="primary" variant="contained" disabled={loading} to={`/program/${id}`} component={Link}>
            View Program
          </Button>
        </div>
      </Box>
    </Paper>
  )
}

export default CampaignRow
