import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { SocialAccountHashtagFragmentFragmentDoc } from './social-account-hashtag-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSocialAccountsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  where?: Types.InputMaybe<Types.SocialAccountFilterInput>;
  sortBy?: Types.InputMaybe<Types.SocialAccountSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  platform: Types.SocialPlatformEnum;
}>;


export type AdminSocialAccountsQuery = { __typename: 'Query', socialAccountsPaged?: { __typename: 'PagedSocialAccountType', total: number, cursor?: string | null, results: Array<{ __typename: 'IGSocialAccount', id: string, socialUser: { __typename: 'IGUser', id: string, username: string } } | { __typename: 'TTSocialAccount', activeTrackedHashtag?: string | null, lastHashtagUpdatedAt?: Date | null, id: string, trackedHashtags?: Array<{ __typename: 'SocialAccountHashtagType', id: string, hashtag?: string | null, hashtagId?: string | null, active?: boolean | null, createdAt?: Date | null, updatedAt?: Date | null }> | null, account: { __typename: 'AccountType', id: number, name: string }, socialUser: { __typename: 'TTUser', id: string, username: string } }> } | null };


export const AdminSocialAccountsDocument = gql`
    query AdminSocialAccounts($limit: Int, $cursor: String, $where: SocialAccountFilterInput, $sortBy: SocialAccountSort, $sortDirection: SortDirection, $platform: SocialPlatformEnum!) {
  socialAccountsPaged(
    limit: $limit
    cursor: $cursor
    where: $where
    sortBy: $sortBy
    sortDirection: $sortDirection
    platform: $platform
  ) {
    total
    cursor
    results {
      id
      socialUser {
        id
        username
      }
      ... on TTSocialAccount {
        trackedHashtags {
          ...SocialAccountHashtagFragment
        }
        activeTrackedHashtag
        lastHashtagUpdatedAt
        account {
          id
          name
        }
      }
    }
  }
}
    ${SocialAccountHashtagFragmentFragmentDoc}`;

/**
 * __useAdminSocialAccountsQuery__
 *
 * To run a query within a React component, call `useAdminSocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSocialAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useAdminSocialAccountsQuery(baseOptions: Apollo.QueryHookOptions<AdminSocialAccountsQuery, AdminSocialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSocialAccountsQuery, AdminSocialAccountsQueryVariables>(AdminSocialAccountsDocument, options);
      }
export function useAdminSocialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSocialAccountsQuery, AdminSocialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSocialAccountsQuery, AdminSocialAccountsQueryVariables>(AdminSocialAccountsDocument, options);
        }
export type AdminSocialAccountsQueryHookResult = ReturnType<typeof useAdminSocialAccountsQuery>;
export type AdminSocialAccountsLazyQueryHookResult = ReturnType<typeof useAdminSocialAccountsLazyQuery>;
export type AdminSocialAccountsQueryResult = Apollo.QueryResult<AdminSocialAccountsQuery, AdminSocialAccountsQueryVariables>;