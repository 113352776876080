import React, { useEffect, useState } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

const ShopifyAuth: React.FC = () => {
  // Shopify sends query params with this route when installing the app via shopify
  const [shopifyQuery] = useQueryParams({
    hmac: StringParam,
    host: StringParam,
    shop: StringParam,
    session: StringParam,
    timestamp: StringParam,
  })

  const [authUrl, setAuthUrl] = useState<string | null>(null)
  const { hmac, host, shop, timestamp, session } = shopifyQuery
  useEffect(() => {
    if (hmac && host && shop && timestamp) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/shopify`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          hmac,
          host,
          shop,
          timestamp,
          session: session || undefined,
        }),
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then(data => {
          if (data.auth_url) {
            setAuthUrl(data.auth_url)
          }
        })
    }
  }, [hmac, host, shop, session, timestamp])

  useEffect(() => {
    if (authUrl) {
      window.location.href = authUrl
    }
  }, [authUrl])

  return null
}

export default ShopifyAuth
