import React from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { Formik, Field, Form } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import * as yup from 'yup'

const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Name is required.'),
  active: yup.boolean().required('Name is required.'),
})

export type AddSocialAccountHashtagFormFields = yup.InferType<typeof schema>

interface AddSocialAccountHashtagDialogProps {
  open: boolean
  onCancel(): void
  onSubmit(fields: AddSocialAccountHashtagFormFields): void
}

const defaultFields: AddSocialAccountHashtagFormFields = {
  name: '',
  active: false,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(5),
    },
  }),
)

function AddSocialAccountHashtagDialog({
  open,
  onCancel,
  onSubmit,
}: AddSocialAccountHashtagDialogProps): React.ReactElement {
  const styles = useStyles()
  return (
    <Dialog open={open} onClose={onCancel}>
      <Formik<AddSocialAccountHashtagFormFields>
        initialValues={defaultFields}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form noValidate>
          <DialogTitle>Add Social Account Hashtag</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" minWidth={300}>
              <Field className={styles.field} component={TextField} label="Name" name="name" />
              <Field component={CheckboxWithLabel} type="checkbox" Label={{ label: 'Active' }} name="active" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default AddSocialAccountHashtagDialog
