import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { REVIEW_ROUTE, REVIEW_PROGRAM_ROUTE, REVIEW_CHALLENGE_ROUTE } from './routes'
import ReviewLists from './ReviewLists'
import ReviewProgram from './ReviewProgram'
import ReviewChallenge from './ReviewChallenge'
import withAuthorization from '../withAuthorization'

const Review: React.FC = () => (
  <Switch>
    <Route path={REVIEW_ROUTE.path} exact component={ReviewLists} />
    <Route path={REVIEW_PROGRAM_ROUTE.path} component={ReviewProgram} />
    <Route path={REVIEW_CHALLENGE_ROUTE.path} component={ReviewChallenge} />
  </Switch>
)

export default withAuthorization(REVIEW_ROUTE)(Review)
