import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManualMessageFormDataQueryVariables = Types.Exact<{
  socialAccountId: Types.Scalars['ID'];
  where?: Types.InputMaybe<Types.RewardFilterInput>;
}>;


export type ManualMessageFormDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, rewards?: { __typename: 'PagedRewardType', results: Array<{ __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesLevel?: Types.CodesLevel | null } }> } | null } | null } | null, socialAccount?: { __typename: 'IGSocialAccount', id: string, messageTemplates?: { __typename: 'PagedMessageTemplate', results: Array<{ __typename: 'MessageTemplate', id: string, name: string, template: string, reward?: { __typename: 'RewardType', id: number } | null }> } | null } | { __typename: 'TTSocialAccount', id: string } | null };


export const ManualMessageFormDataDocument = gql`
    query ManualMessageFormData($socialAccountId: ID!, $where: RewardFilterInput) {
  whoami {
    id
    account {
      id
      rewards(where: $where) {
        results {
          id
          name
          stats {
            codesLevel
          }
        }
      }
    }
  }
  socialAccount(id: $socialAccountId) {
    id
    ... on IGSocialAccount {
      messageTemplates(limit: 100, sortBy: RECENTLY_USED, sortDirection: DESC) {
        results {
          id
          name
          template
          reward {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useManualMessageFormDataQuery__
 *
 * To run a query within a React component, call `useManualMessageFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualMessageFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualMessageFormDataQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useManualMessageFormDataQuery(baseOptions: Apollo.QueryHookOptions<ManualMessageFormDataQuery, ManualMessageFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManualMessageFormDataQuery, ManualMessageFormDataQueryVariables>(ManualMessageFormDataDocument, options);
      }
export function useManualMessageFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManualMessageFormDataQuery, ManualMessageFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManualMessageFormDataQuery, ManualMessageFormDataQueryVariables>(ManualMessageFormDataDocument, options);
        }
export type ManualMessageFormDataQueryHookResult = ReturnType<typeof useManualMessageFormDataQuery>;
export type ManualMessageFormDataLazyQueryHookResult = ReturnType<typeof useManualMessageFormDataLazyQuery>;
export type ManualMessageFormDataQueryResult = Apollo.QueryResult<ManualMessageFormDataQuery, ManualMessageFormDataQueryVariables>;