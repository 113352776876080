import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SocialAccountIdsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SocialAccountIdsQuery = { __typename: 'Query', socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> | null };


export const SocialAccountIdsDocument = gql`
    query SocialAccountIds {
  socialAccounts {
    id
  }
}
    `;

/**
 * __useSocialAccountIdsQuery__
 *
 * To run a query within a React component, call `useSocialAccountIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialAccountIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialAccountIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialAccountIdsQuery(baseOptions?: Apollo.QueryHookOptions<SocialAccountIdsQuery, SocialAccountIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialAccountIdsQuery, SocialAccountIdsQueryVariables>(SocialAccountIdsDocument, options);
      }
export function useSocialAccountIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialAccountIdsQuery, SocialAccountIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialAccountIdsQuery, SocialAccountIdsQueryVariables>(SocialAccountIdsDocument, options);
        }
export type SocialAccountIdsQueryHookResult = ReturnType<typeof useSocialAccountIdsQuery>;
export type SocialAccountIdsLazyQueryHookResult = ReturnType<typeof useSocialAccountIdsLazyQuery>;
export type SocialAccountIdsQueryResult = Apollo.QueryResult<SocialAccountIdsQuery, SocialAccountIdsQueryVariables>;