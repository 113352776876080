import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSegmentForCustomerMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  customerIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  socialAccountId?: Types.InputMaybe<Types.Scalars['ID']>;
  where?: Types.InputMaybe<Types.CustomerFilterInput>;
  limit: Types.Scalars['Int'];
  sortBy?: Types.InputMaybe<Types.CustomerSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type CreateSegmentForCustomerMutation = { __typename: 'Mutations', createSegment?: { __typename: 'CreateSegment', ok?: boolean | null, segment?: { __typename: 'SegmentType', id: number, name: string, account?: { __typename: 'AccountType', id: number } | null } | null } | null };


export const CreateSegmentForCustomerDocument = gql`
    mutation CreateSegmentForCustomer($name: String!, $customerIds: [ID!], $socialAccountId: ID, $where: CustomerFilterInput, $limit: Int!, $sortBy: CustomerSort, $sortDirection: SortDirection) {
  createSegment(
    name: $name
    customerIds: $customerIds
    socialAccountId: $socialAccountId
    where: $where
    limit: $limit
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    ok
    segment {
      id
      name
      account {
        id
      }
    }
  }
}
    `;
export type CreateSegmentForCustomerMutationFn = Apollo.MutationFunction<CreateSegmentForCustomerMutation, CreateSegmentForCustomerMutationVariables>;

/**
 * __useCreateSegmentForCustomerMutation__
 *
 * To run a mutation, you first call `useCreateSegmentForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentForCustomerMutation, { data, loading, error }] = useCreateSegmentForCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      customerIds: // value for 'customerIds'
 *      socialAccountId: // value for 'socialAccountId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useCreateSegmentForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSegmentForCustomerMutation, CreateSegmentForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSegmentForCustomerMutation, CreateSegmentForCustomerMutationVariables>(CreateSegmentForCustomerDocument, options);
      }
export type CreateSegmentForCustomerMutationHookResult = ReturnType<typeof useCreateSegmentForCustomerMutation>;
export type CreateSegmentForCustomerMutationResult = Apollo.MutationResult<CreateSegmentForCustomerMutation>;
export type CreateSegmentForCustomerMutationOptions = Apollo.BaseMutationOptions<CreateSegmentForCustomerMutation, CreateSegmentForCustomerMutationVariables>;