import React from 'react'
import { Tooltip, Box, Typography, IconButton, makeStyles, Theme, createStyles } from '@material-ui/core'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { useRewardListQuery, RewardRowFragment } from './operations/rewards.generated'
import RewardListRowV2 from './RewardListRowV2'
import { rewardHasAutoDelivery } from '../../utils/rewards'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      color: theme.palette.secondary.main,
      marginTop: theme.spacing(4),
      textDecoration: 'underline',
    },
  }),
)

interface RewardListProps {
  onSendReward(reward: RewardRowFragment, offline: boolean): void
  onClose?(): void
  canAutoDeliver?: boolean
  canCopyCode?: boolean
}

function RewardList({
  onSendReward,
  onClose = undefined,
  canCopyCode = false,
  canAutoDeliver = false,
}: RewardListProps): React.ReactElement {
  const classes = useStyles()
  const { data, loading } = useRewardListQuery()
  const rewards = data?.whoami?.account?.rewards?.results
  const visibleRewards = rewards?.filter(r => {
    // if the reward ran out of codes, hide
    if (r.hasDiscountCode && !r.stats.codesRemaining) {
      return false
    }
    if (canAutoDeliver && rewardHasAutoDelivery(r)) {
      return true
    }
    return !!(canCopyCode && r.hasDiscountCode)
  })
  const showCloseButton = onClose !== undefined

  return (
    <Box height="100%" display="grid" gridTemplateRows="40px minmax(0, 1fr) 40px">
      <Box mb={6} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" id="send-reward-title">
          Choose reward to send
        </Typography>
        {showCloseButton && (
          <IconButton onClick={() => onClose()} size="small">
            <CrossIcon width={20} height={20} />
          </IconButton>
        )}
      </Box>
      <Box style={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        {loading ? (
          <>
            <RewardListRowV2 loading />
            <RewardListRowV2 loading />
            <RewardListRowV2 loading />
          </>
        ) : visibleRewards?.length ? (
          visibleRewards?.map(r => (
            <RewardListRowV2
              key={r.id}
              reward={r}
              canAutoDeliver={canAutoDeliver}
              canCopyCode={canCopyCode}
              onSend={onSendReward}
            />
          ))
        ) : (
          <Box color="secondary">No rewards available.</Box>
        )}
      </Box>
      {visibleRewards?.length !== rewards?.length && (
        <Tooltip
          placement="top-start"
          title="Rewards that don’t have reward codes (i.e. points) can only be sent to customers with an email address."
        >
          <div className={classes.info}>Why aren’t all of my rewards on this list?</div>
        </Tooltip>
      )}
    </Box>
  )
}

export default RewardList
