import React, { useState } from 'react'
import { Box, createStyles, Link, List, ListItem, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  MentionStatus,
  ProgramRulesStoriesStatusRuleEnum,
  ProgramRulesTagTypeRuleEnum,
  ProgramType,
} from '../gql-global'
import RuleButtons from './RuleButtons'
import { useUpdateMentionStatusMutation } from '../mutations/operations/update-mention-status.generated'
import { useToast } from '../components/Alert/ToastProvider'

interface ProgramRulesProps {
  mention?: { id: string; status: MentionStatus }
  program: ProgramType
  hideButtons?: boolean
  needsRefetch?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.common.white,
      height: '90%',
    },
    ruleLabel: {
      fontWeight: 'bold',
      marginRight: theme.spacing(3),
      minWidth: 170,
    },
  }),
)

const ProgramRules: React.FC<ProgramRulesProps> = ({ program, mention, hideButtons, needsRefetch }) => {
  const hostUrls = (program.hosts?.filter(item => !!item?.url).map(item => item.url) ?? []) as string[]
  const {
    badExampleUrl,
    goodExampleUrl,
    tagLimit,
    tagType,
    storiesStatusRule,
    requiredHashtags,
    miscRules,
    whenInDoubt,
    brandPhoto,
  } = program.contentRules ?? {}

  const styles = useStyles()

  const { showToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [updateMentionStatus] = useUpdateMentionStatusMutation()

  const onChangeStatus = (status: MentionStatus): void => {
    const refetch = needsRefetch ? { awaitRefetchQueries: true, refetchQueries: ['MentionsPager'] } : {}
    if (program.id && mention?.id) {
      setLoading(true)
      updateMentionStatus({ variables: { id: mention?.id, status }, ...refetch })
        .catch(error => {
          showToast({ title: 'Error: Updating Mention Status', message: error.message })
        })
        .finally(() => setLoading(false))
    }
  }

  const mentionStatusMap: Record<string, string> = {
    [MentionStatus.Rejected]: 'Reject',
    [MentionStatus.Verified]: 'Approve',
    [MentionStatus.OfficialReview]: 'Official Review',
  }

  const programRulesTagTypeRuleMap: Record<string, string> = {
    [ProgramRulesTagTypeRuleEnum.Tag]: 'Tag',
    [ProgramRulesTagTypeRuleEnum.Caption]: 'Caption',
    [ProgramRulesTagTypeRuleEnum.TagAndCaption]: 'Tag and Caption',
    [ProgramRulesTagTypeRuleEnum.TagOrCaption]: 'Tag or Caption',
  }

  const programRulesStoriesStatusRuleMap: Record<string, string> = {
    [ProgramRulesStoriesStatusRuleEnum.ApproveAll]: 'Approve All',
    [ProgramRulesStoriesStatusRuleEnum.RejectAll]: 'Reject All',
    [ProgramRulesStoriesStatusRuleEnum.SameRulesApply]: 'Same Rules Apply',
  }

  return (
    <>
      {!hideButtons && (
        <RuleButtons onChangeStatus={onChangeStatus} currentStatus={mention?.status} loading={loading} />
      )}
      <Paper className={styles.container}>
        <Box p={2}>
          {!program.contentRules ? (
            <p>Error program has no rules</p>
          ) : (
            <List>
              <ListItem>
                <Typography className={styles.ruleLabel}>Program Url: </Typography>
                {hostUrls.length <= 1 && (
                  <Typography>
                    {hostUrls.length === 0 && 'N/A'}
                    {hostUrls.length === 1 && hostUrls[0] && (
                      <Link
                        href={hostUrls[0].startsWith('https://') ? hostUrls[0] : `https://${hostUrls[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hostUrls[0]}
                      </Link>
                    )}
                  </Typography>
                )}
                {hostUrls.length > 1 &&
                  hostUrls.map((hostUrl, i) => (
                    <>
                      <Typography>
                        <Link
                          href={hostUrl.startsWith('https://') ? hostUrls[0] : `https://${hostUrls[0]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {hostUrl}
                        </Link>
                      </Typography>
                      {i !== hostUrls.length - 1 && <Typography style={{ marginRight: 5 }}>, </Typography>}
                    </>
                  ))}
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Good Example: </Typography>
                <Typography>
                  {goodExampleUrl ? (
                    <Link href={goodExampleUrl} target="_blank" rel="noopener noreferrer">
                      {goodExampleUrl}
                    </Link>
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Bad Example: </Typography>
                <Typography>
                  {badExampleUrl ? (
                    <Link href={badExampleUrl} target="_blank" rel="noopener noreferrer">
                      {badExampleUrl}
                    </Link>
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Tag Limit: </Typography>
                <Typography>{tagLimit || 'N/A'}</Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Tag Type: </Typography>
                <Typography>{(tagType && programRulesTagTypeRuleMap[tagType]) || 'N/A'}</Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Stories Status Rule:</Typography>
                <Typography>
                  {(storiesStatusRule && programRulesStoriesStatusRuleMap[storiesStatusRule]) || 'N/A'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Required Hashtags: </Typography>
                <Typography>{requiredHashtags ? requiredHashtags.join(', ') : 'N/A'}</Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Misc Rules: </Typography>
                <Typography>{miscRules || 'N/A'}</Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>When In Doubt: </Typography>
                <Typography>{(whenInDoubt && mentionStatusMap[whenInDoubt]) || 'N/A'}</Typography>
              </ListItem>
              <ListItem>
                <Typography className={styles.ruleLabel}>Brand Photo: </Typography>
                {brandPhoto ? (
                  <img src={brandPhoto} style={{ height: 225 }} alt="Brand Logo" />
                ) : (
                  <Typography>N/A</Typography>
                )}
              </ListItem>
            </List>
          )}
        </Box>
      </Paper>
    </>
  )
}

export default ProgramRules
