import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import { MessageTemplateFragmentFragmentDoc } from './message-template-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMessageTemplateMutationVariables = Types.Exact<{
  templateId: Types.Scalars['ID'];
  messageTemplateInput: Types.MessageTemplateInput;
  statsWhere: Types.MessageTemplateStatsFilterInput;
}>;


export type UpdateMessageTemplateMutation = { __typename: 'Mutations', updateMessageTemplate?: { __typename: 'UpdateMessageTemplate', ok: boolean, messageTemplate?: { __typename: 'MessageTemplate', id: string, name: string, template: string, skipSendMessage: Types.SkipMessageTemplateActionEnum, skipActionTimeBuffer?: number | null, customerTriggerQuantity?: number | null, automation?: { __typename: 'Automation', id: string, enabled: boolean, trigger: { __typename: 'MentionedTrigger', id: string, filter?: { __typename: 'ContentFilterType', id: string, name: string, entity: Types.FilterEntity, isSystem: boolean, value?: { __typename: 'ContentFilterValueType', followerCount?: { __typename: 'IntegerRangeFilter', gte?: number | null, lte?: number | null } | null, segments?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, campaigns?: { __typename: 'LabelsFilter', any?: Array<string> | null, none?: Array<string> | null } | null, biography?: { __typename: 'KeywordsFilter', any?: Array<string> | null, none?: Array<string> | null } | null } | null } | null }, action: { __typename: 'AutomationAction', id: string } } | null, stats?: { __typename: 'MessageTemplateStats', totalRecipients: number } | null, reward?: { __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesInCurrentBatch?: number | null, codesRemaining?: number | null, codesLevel?: Types.CodesLevel | null } } | null } | null } | null };


export const UpdateMessageTemplateDocument = gql`
    mutation UpdateMessageTemplate($templateId: ID!, $messageTemplateInput: MessageTemplateInput!, $statsWhere: MessageTemplateStatsFilterInput!) {
  updateMessageTemplate(
    templateId: $templateId
    messageTemplateInput: $messageTemplateInput
  ) {
    ok
    messageTemplate {
      ...MessageTemplateFragment
    }
  }
}
    ${MessageTemplateFragmentFragmentDoc}`;
export type UpdateMessageTemplateMutationFn = Apollo.MutationFunction<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;

/**
 * __useUpdateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateMutation, { data, loading, error }] = useUpdateMessageTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      messageTemplateInput: // value for 'messageTemplateInput'
 *      statsWhere: // value for 'statsWhere'
 *   },
 * });
 */
export function useUpdateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UpdateMessageTemplateDocument, options);
      }
export type UpdateMessageTemplateMutationHookResult = ReturnType<typeof useUpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationResult = Apollo.MutationResult<UpdateMessageTemplateMutation>;
export type UpdateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;