import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import theme from '../../loudcrowd-theme'
import { AccountLabel } from '../LabelMenu/LabelMenu'
import { ReactComponent as CaretIcon } from '../../icons/caret.svg'
import ChallengeFilterMenu from './ChallengeFilterMenu'

const useStyles = makeStyles(() =>
  createStyles({
    baseTextColor: {
      color: theme.palette.primary.dark,
    },
    textGradient: {
      background: 'linear-gradient(170.48deg, #7800F9 28.39%, #F528C8 56.52%, #FFDD55 95%)',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      color: 'transparent',
    },
    container: {
      backgroundImage: 'linear-gradient(117.48deg, #C91CD6 0%, #F528C8 32.52%, #FC9C7E 85.46%)',
      backgroundPosition: 'bottom',
      backgroundColor: 'white',
      backgroundSize: '99% 1.5px',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
    },
    caret: {
      marginLeft: '8px',
      marginTop: '14px',
      color: theme.palette.primary.main,
    },
    caretOpen: {
      transform: 'rotate(180deg)',
    },
  }),
)

interface ChallengeHeaderFilterProps {
  challenges: ReadonlyArray<AccountLabel<string>>
  selected?: string | undefined
  setSelected: (selection: string) => void
}

export default function ChallengeHeaderFilter({
  challenges,
  selected,
  setSelected,
}: ChallengeHeaderFilterProps): React.ReactElement {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const chipRef = useRef<HTMLDivElement>(null)

  const selectedAccountLabel = challenges.find(c => c.id === selected)

  const caretOpenClass = `${classes.caret} ${classes.caretOpen}`

  return (
    <Box>
      <Box display="flex">
        <Typography className={classes.baseTextColor}>Filter by</Typography>
        <Typography>&nbsp;</Typography>
        <Box display="flex" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <Box className={classes.container}>
            <Typography ref={chipRef} className={classes.textGradient}>
              {!!selectedAccountLabel ? selectedAccountLabel.name : 'Challenge'}
            </Typography>
          </Box>
          <CaretIcon className={open ? caretOpenClass : classes.caret} />
        </Box>
      </Box>
      <ChallengeFilterMenu
        open={open}
        setOpen={setOpen}
        challenges={challenges}
        setSelected={setSelected}
        selected={selected}
        currentRef={chipRef.current}
      />
    </Box>
  )
}
