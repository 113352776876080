import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MembersSendRewardMutationVariables = Types.Exact<{
  ambassadorStatsWhere?: Types.InputMaybe<Types.AmbassadorStatsInput>;
  campaignId?: Types.InputMaybe<Types.Scalars['ID']>;
  challengeId?: Types.InputMaybe<Types.Scalars['ID']>;
  customerIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  mentionsWhere?: Types.InputMaybe<Types.CustomerMentionStatsFilters>;
  offlineDelivery: Types.Scalars['Boolean'];
  rewardId: Types.Scalars['ID'];
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  where?: Types.InputMaybe<Types.ParticipantsInput>;
}>;


export type MembersSendRewardMutation = { __typename: 'Mutations', sendFulfillment?: { __typename: 'SendFulfillment', ok: boolean, fulfillments?: Array<{ __typename: 'FulfillmentType', id: string, rewardDiscountCode?: { __typename: 'RewardDiscountCodeType', code: string, id: string } | null }> | null } | null };


export const MembersSendRewardDocument = gql`
    mutation MembersSendReward($ambassadorStatsWhere: AmbassadorStatsInput, $campaignId: ID, $challengeId: ID, $customerIds: [ID!], $limit: Int, $mentionsWhere: CustomerMentionStatsFilters, $offlineDelivery: Boolean!, $rewardId: ID!, $sortBy: ParticipantSort, $sortDirection: SortDirection, $where: ParticipantsInput) {
  sendFulfillment(
    ambassadorStatsWhere: $ambassadorStatsWhere
    campaignId: $campaignId
    challengeId: $challengeId
    customerIds: $customerIds
    limit: $limit
    mentionsWhere: $mentionsWhere
    offlineDelivery: $offlineDelivery
    rewardId: $rewardId
    sortBy: $sortBy
    sortDirection: $sortDirection
    where: $where
  ) {
    fulfillments @include(if: $offlineDelivery) {
      id
      rewardDiscountCode {
        code
        id
      }
    }
    ok
  }
}
    `;
export type MembersSendRewardMutationFn = Apollo.MutationFunction<MembersSendRewardMutation, MembersSendRewardMutationVariables>;

/**
 * __useMembersSendRewardMutation__
 *
 * To run a mutation, you first call `useMembersSendRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembersSendRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membersSendRewardMutation, { data, loading, error }] = useMembersSendRewardMutation({
 *   variables: {
 *      ambassadorStatsWhere: // value for 'ambassadorStatsWhere'
 *      campaignId: // value for 'campaignId'
 *      challengeId: // value for 'challengeId'
 *      customerIds: // value for 'customerIds'
 *      limit: // value for 'limit'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      offlineDelivery: // value for 'offlineDelivery'
 *      rewardId: // value for 'rewardId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMembersSendRewardMutation(baseOptions?: Apollo.MutationHookOptions<MembersSendRewardMutation, MembersSendRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MembersSendRewardMutation, MembersSendRewardMutationVariables>(MembersSendRewardDocument, options);
      }
export type MembersSendRewardMutationHookResult = ReturnType<typeof useMembersSendRewardMutation>;
export type MembersSendRewardMutationResult = Apollo.MutationResult<MembersSendRewardMutation>;
export type MembersSendRewardMutationOptions = Apollo.BaseMutationOptions<MembersSendRewardMutation, MembersSendRewardMutationVariables>;