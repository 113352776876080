import { ApolloLink, NextLink, Operation } from '@apollo/client/link/core'
import { FetchResult } from '@apollo/client/link/core/types'
import { Observable } from '@apollo/client/utilities'

/**
 * ApolloLink middleware for cleaning "__typename" fields from GraphQL operation payloads
 *
 * This is useful when reusing result of previous operations as variables in following operations.
 *
 * https://www.apollographql.com/docs/link/stateless.html#stateless-classes
 */
export default class CleanTypenameLink extends ApolloLink {
  request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
    if (forward) {
      if (operation.variables) {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), this.cleanTypename)
      }
      return forward(operation)
    } else {
      return null
    }
  }

  private cleanTypename(key: string, value: unknown) {
    return key === '__typename' ? undefined : value
  }
}
