import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
export type IgChallengeSaFragment = { __typename: 'IGSocialAccount', id: string, programs?: Array<{ __typename: 'ProgramType', id: string, name: string }> | null, socialUser: { __typename: 'IGUser', id: string, username: string } };

export const IgChallengeSaFragmentDoc = gql`
    fragment IGChallengeSA on IGSocialAccount {
  id
  programs {
    id
    name
  }
  socialUser {
    id
    username
  }
}
    `;