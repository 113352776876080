import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduleEspMessageRewardMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  accountId: Types.Scalars['ID'];
  scheduledDatetime: Types.Scalars['DateTime'];
}>;


export type ScheduleEspMessageRewardMutation = { __typename: 'Mutations', scheduleReward?: { __typename: 'ScheduleReward', ok?: boolean | null, reward?: { __typename: 'RewardType', id: number } | null } | null };


export const ScheduleEspMessageRewardDocument = gql`
    mutation ScheduleEspMessageReward($id: ID!, $accountId: ID!, $scheduledDatetime: DateTime!) {
  scheduleReward(
    id: $id
    accountId: $accountId
    scheduledDatetime: $scheduledDatetime
  ) {
    ok
    reward {
      id
    }
  }
}
    `;
export type ScheduleEspMessageRewardMutationFn = Apollo.MutationFunction<ScheduleEspMessageRewardMutation, ScheduleEspMessageRewardMutationVariables>;

/**
 * __useScheduleEspMessageRewardMutation__
 *
 * To run a mutation, you first call `useScheduleEspMessageRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleEspMessageRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleEspMessageRewardMutation, { data, loading, error }] = useScheduleEspMessageRewardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *      scheduledDatetime: // value for 'scheduledDatetime'
 *   },
 * });
 */
export function useScheduleEspMessageRewardMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleEspMessageRewardMutation, ScheduleEspMessageRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleEspMessageRewardMutation, ScheduleEspMessageRewardMutationVariables>(ScheduleEspMessageRewardDocument, options);
      }
export type ScheduleEspMessageRewardMutationHookResult = ReturnType<typeof useScheduleEspMessageRewardMutation>;
export type ScheduleEspMessageRewardMutationResult = Apollo.MutationResult<ScheduleEspMessageRewardMutation>;
export type ScheduleEspMessageRewardMutationOptions = Apollo.BaseMutationOptions<ScheduleEspMessageRewardMutation, ScheduleEspMessageRewardMutationVariables>;