import React from 'react'
import clsx from 'clsx'
import { Box, Button, Container, createStyles, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import { primary, secondary } from '../../loudcrowd-theme'
import { ReactComponent as CustomerIcon } from '../../icons/people-outlined-cropped.svg'
import { ReactComponent as PresentIcon } from '../../icons/present.svg'
import { ReactComponent as MultiHeartIcon } from '../../icons/multi-hearts.svg'
import { ReactComponent as RedHeartIcon } from '../../icons/red-heart.svg'
import { format, isAfter } from 'date-fns'
import { DateRangeFilter, DateTimeRangeFilter, ProgramTierGroupStatus } from '../../gql-global'
import { Skeleton } from '@material-ui/lab'
import { CampaignProgramActivitiesQuery } from './operations/campaign-program-activities.generated'
import { isTypeName } from '../../types/utility'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: `32px 16px`,
    },
    wrapper: {
      borderLeft: `1px solid ${secondary[400]}`,
      marginLeft: 40,
      paddingLeft: 30,
      display: 'flex',
      position: 'relative',
    },
    wrapperIcon: {
      position: 'absolute',
      left: -18,
      top: '35%',
    },
    outerBox: {
      border: `2px solid ${primary[700]}`,
      borderRadius: 10,
      flex: 1,
      display: 'flex',
      margin: 12,
    },
    infoBox: {
      flex: 1,
      padding: '10px 20px',
      borderRight: `2px solid ${primary[700]}`,
    },
    switch: {
      scale: 0.7,
      marginLeft: -5,
    },
    subtitle2: {
      fontStyle: 'italic',
      fontWeight: 400,
      color: theme.palette.secondary.main,
    },
    borderedCard: {
      backgroundColor: 'rgba(232, 221, 255, 0.35)',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      minWidth: 350,

      '& .separator': {
        width: 1,
        height: '80%',
        backgroundColor: secondary[400],
      },
    },
    archived: {
      borderColor: secondary[500],
      color: secondary[600],
      '& svg path': {
        fill: secondary[500],
      },
      '& p': {
        color: secondary[600],
      },
      '& > div': {
        borderRightColor: secondary[500],
      },
      '& > div + div': {
        background: secondary[200],
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        '& svg path': {
          fill: secondary[600],
        },
      },
    },
  }),
)

export type ProgramTierGroup = NonNullable<
  NonNullable<NonNullable<CampaignProgramActivitiesQuery['campaign']>['program']>['programTierGroups']
>[0]

export type ProgramTier = NonNullable<NonNullable<ProgramTierGroup>['tiers']>[0]

interface ProgramActivityTimelineProps {
  onActivityDialogOpen: (value?: ProgramTierGroup) => void
  programTierGroups: Array<ProgramTierGroup>
  selectedDateRange?: DateRangeFilter | DateTimeRangeFilter
  onSelectedDateRange: (value: DateRangeFilter | DateTimeRangeFilter) => void
  isLoading: boolean
}

function ProgramActivityTimeline({
  onActivityDialogOpen,
  programTierGroups,
  selectedDateRange,
  onSelectedDateRange,
  isLoading,
}: ProgramActivityTimelineProps): React.ReactElement {
  const classes = useStyles()

  const showSocialNetwork = (tiers?: ProgramTier[] | null) => {
    const socialNetworks = Array.from(
      new Set(
        tiers?.flatMap(tier =>
          tier.socialAccounts?.map(sa => (isTypeName(sa, 'IGSocialAccount') ? 'Instagram' : 'TikTok')),
        ),
      ),
    )
    return socialNetworks?.length === 1 ? socialNetworks[0] : ''
  }

  const buildStatusText = (tierGroup: ProgramTierGroup) => {
    const { startAt, updatedAt, status } = tierGroup

    if (!startAt || !updatedAt) return ''

    const formatDate = (date: Date) => format(date, 'MMMM d, yyyy')

    switch (status) {
      case ProgramTierGroupStatus.Active:
        if (isAfter(startAt, updatedAt) && isAfter(startAt, new Date())) {
          return `Scheduled launch: ${formatDate(startAt)}`
        }
        return `Launched: ${formatDate(startAt)}`
      case ProgramTierGroupStatus.Draft:
        return `Edited on ${formatDate(updatedAt)}`
      case ProgramTierGroupStatus.Paused:
        return `Paused on ${formatDate(updatedAt)}`
      case ProgramTierGroupStatus.Archived:
        return `Archived on ${formatDate(updatedAt)}`
    }
  }

  const isArchived = (tierGroup: ProgramTierGroup) => tierGroup.status === ProgramTierGroupStatus.Archived

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={10}>
        {isLoading ? (
          <>
            <Skeleton width={200} height={80} />
            <Skeleton width={200} height={80} />
          </>
        ) : (
          <>
            <Button onClick={() => onActivityDialogOpen()} color="primary" size="large" variant="contained">
              Add New Activity
            </Button>
            <DateRangePicker
              selectedDateRange={selectedDateRange}
              onChange={onSelectedDateRange}
              includeAllTime
              endIcon={<ChevronDownIcon width={16} />}
            />
          </>
        )}
      </Box>
      <Paper>
        <Container className={classes.container}>
          {isLoading &&
            Array.from({ length: 3 }).map((_, index) => (
              <Box key={index} className={classes.wrapper}>
                <Box className={classes.outerBox} borderColor={`${secondary[200]} !important`}>
                  <Box className={classes.infoBox} borderColor={`${secondary[200]} !important`}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Skeleton width="100%" height={40} />
                      <Skeleton width={200} />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Skeleton width="60%" />
                    </Box>
                  </Box>
                  <Box className={classes.borderedCard}>
                    <Box minWidth={120} display="flex" alignItems="center" justifyContent="center" gridGap={5}>
                      <Skeleton width={50} />
                    </Box>
                    <Box className="separator" />
                    <Box minWidth={120} display="flex" alignItems="center" justifyContent="center" gridGap={5}>
                      <Skeleton width={50} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          {programTierGroups.length === 0 && !isLoading && (
            <Box textAlign="center" py={10}>
              <Typography variant="body1" color="secondary">
                No activities found for the selected date range
              </Typography>
            </Box>
          )}
          {programTierGroups.map(tierGroup => (
            <Box key={tierGroup.id} className={classes.wrapper}>
              <Box className={clsx(classes.wrapperIcon, { [classes.archived]: isArchived(tierGroup) })}>
                {tierGroup.tiers?.length === 1 ? <RedHeartIcon /> : <MultiHeartIcon />}
              </Box>
              <Box className={clsx(classes.outerBox, { [classes.archived]: isArchived(tierGroup) })}>
                <Box className={classes.infoBox}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body1" color="primary">
                      <strong>
                        Post {tierGroup.tiers?.map(tier => tier.triggerQuantity).join(' or ')}{' '}
                        {showSocialNetwork(tierGroup.tiers)} post
                        {tierGroup.tiers?.length === 1 && tierGroup.tiers?.[0]?.triggerQuantity === 1 ? '' : 's'}
                        /month
                      </strong>
                    </Typography>
                    <Button onClick={() => onActivityDialogOpen(tierGroup)} color="primary" size="large" variant="text">
                      View Details
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2" className={classes.subtitle2}>
                      {buildStatusText(tierGroup)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.borderedCard}>
                  <Tooltip title="Rewards Sent">
                    <Box minWidth={120} display="flex" alignItems="center" justifyContent="center" gridGap={5}>
                      <PresentIcon fill="currentColor" height={20} width={20} />
                      <Typography variant="h5">{tierGroup.rewardsSent}</Typography>
                    </Box>
                  </Tooltip>
                  <Box className="separator" />
                  <Tooltip title="Members Rewarded">
                    <Box minWidth={120} display="flex" alignItems="center" justifyContent="center" gridGap={5}>
                      <CustomerIcon fill="currentColor" height={24} width={24} />
                      <Typography variant="h5">{tierGroup.membersGifted}</Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ))}
        </Container>
      </Paper>
    </>
  )
}

export default ProgramActivityTimeline
