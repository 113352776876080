import React, { useRef } from 'react'
import {
  Button,
  useTheme,
  Menu,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'

interface ListActionConfig<K extends string> {
  action: K
  label: string
  icon: React.ReactElement
}

interface ListActionMenuProps<K extends string> {
  actions?: ListActionConfig<K>[]
  onSelectAction(action: K): void
}

interface StatusActionsProps<K extends string> extends ListActionMenuProps<K> {
  type?: 'APPROVED' | 'PENDING' | 'REJECTED' | null
  open: boolean
  onCancel(): void
  onOpen(): void
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    menuPaper: {
      minWidth: 170,
    },
    listItemIcon: {
      minWidth: 28,
      color: 'inherit',
    },
    listItem: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    deleteItem: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
)

function StatusActions<K extends string>({
  type,
  open,
  onCancel,
  onOpen,
  actions,
  onSelectAction,
}: StatusActionsProps<K>): React.ReactElement {
  const { palette } = useTheme()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const classes = useStyle()
  if (actions !== undefined) {
    if (type === 'APPROVED') {
      actions = actions.filter(a => a.action !== 'approve')
    } else if (type === 'PENDING') {
      actions = actions.filter(a => a.action !== 'pending')
    } else {
      actions = actions.filter(a => a.action !== 'reject')
    }
  }
  return (
    <>
      <Button
        style={type === 'PENDING' ? { color: palette.purple.main } : { color: palette.error.main }}
        endIcon={<ChevronDownIcon height={16} width={16} />}
        ref={buttonRef}
        onClick={onOpen}
      >
        {type === 'PENDING' ? 'Status' : 'Rejected'}
      </Button>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        onClose={onCancel}
        classes={{ paper: classes.menuPaper }}
      >
        {actions !== undefined &&
          actions.map((a, i) => (
            <MenuItem
              data-intercom-target={i === 0 ? 'Customer Bulk Actions First Item' : undefined}
              key={a.action}
              className={classes.listItem}
              onClick={() => onSelectAction(a.action)}
            >
              <ListItemIcon
                className={classes.listItemIcon}
                style={
                  a.action === 'pending'
                    ? { color: palette.purple.main }
                    : a.action === 'reject'
                    ? { color: palette.error.main }
                    : { color: palette.success.main }
                }
              >
                {a.icon}
              </ListItemIcon>
              <ListItemText
                disableTypography
                style={
                  a.action === 'pending'
                    ? { color: palette.purple.main }
                    : a.action === 'reject'
                    ? { color: palette.error.main }
                    : { color: palette.success.main }
                }
              >
                {a.label}
              </ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default StatusActions
