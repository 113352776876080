import React from 'react'
import { makeStyles, createStyles, Chip, Box, Theme, ChipProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      borderRadius: theme.shape.borderRadius,
    },
    label: {
      paddingRight: theme.spacing(3),
    },
    text: {
      '& + &': {
        marginLeft: theme.spacing(3),
      },
    },
  }),
)

interface FilterChipTextProps {
  bold?: boolean
  text: string
}

export const FilterChipText: React.FC<FilterChipTextProps> = ({ bold, text }) => {
  const classes = useStyles()
  return (
    <Box display="inline" fontWeight={bold ? undefined : 'fontWeightRegular'} className={classes.text}>
      {text}
    </Box>
  )
}

// TODO: children is not supported on Chip component anymore, move to component prop
export type FilterChipProps = Omit<ChipProps, 'children'> & { children?: React.ReactNode }

const FilterChip = React.forwardRef<HTMLDivElement, FilterChipProps>(function FilterChipReffed(
  { onDelete, onClick, children, ...props },
  ref,
) {
  const classes = useStyles()
  return (
    <Chip
      classes={{ label: classes.label }}
      className={classes.chip}
      label={children}
      onDelete={onDelete}
      onClick={onClick}
      ref={ref}
      {...props}
    />
  )
})

export default FilterChip
