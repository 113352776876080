import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Button, Box, Typography, Tooltip } from '@material-ui/core'
import AddLabel from './AddLabel'
import {
  useAccountLabelsQuery,
  AccountLabelsQuery,
  AccountLabelsQueryVariables,
  AccountLabelsDocument,
} from './operations/account-labels.generated'
import { useLabelUserInfoQuery, LabelUserInfoQuery } from './operations/label-user-info.generated'
import { useCreateLabelMutation } from './operations/create-label.generated'
import { useDeleteLabelMutation } from '../../mutations/operations/delete-label.generated'
import Page from '../../Page'
import { LabelSort, SortDirection, LabelFilterInput } from '../../gql-global'
import { NetworkStatus } from '@apollo/client'
import useFilterParams, { Filters } from './use-filter-params'
import { Skeleton } from '@material-ui/lab'
import useTitle from '../../utils/use-title'
import { UpsellModalUpdaterContext } from '../../components/UpsellModal'
import { useToast } from '../../components/Alert/ToastProvider'

const LABEL_PAGE_SIZE = 25

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountFormControl: {
      marginRight: theme.spacing(3),
      minWidth: 200,
      borderRadius: '2rem',
    },
    accountSelect: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      overflow: 'hidden',
    },
    accountInput: {
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      color: 'white',
      borderRadius: '2rem',
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    title: {
      flex: 1,
      lineHeight: 2,
    },
    tableHeaderCell: {
      flex: 1,
      color: theme.palette.secondary.main,
    },
    row: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    rowContainer: {
      display: 'inline-flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    rowCell: {
      color: theme.palette.secondary.dark,
      lineHeight: 2,
    },
    nameCell: {
      color: theme.palette.primary.main,
      flexGrow: 1,
    },
    smallButton: {
      borderRadius: 16,
    },
    largeButton: {
      borderRadius: 20,
    },
    icon: {
      fill: 'white',
    },
  }),
)

export type LabelType = NonNullable<
  NonNullable<NonNullable<AccountLabelsQuery['account']>['labels']>['results']
>[number]

interface LabelRowProps {
  canDelete?: boolean
  label?: LabelType
  handleDelete?: (label: LabelType) => void
  loading?: boolean
}

const LabelRow: React.FC<LabelRowProps> = ({ label, loading = false, handleDelete, canDelete }) => {
  const classes = useStyles()
  const { name, isRejectionReason } = label || {}

  return (
    <Paper className={classes.row}>
      <Box className={classes.rowContainer}>
        {!loading ? (
          <Box className={`${classes.rowCell} ${classes.nameCell}`}>
            {name}
            {isRejectionReason ? ' (Rejection Reason)' : ''}
          </Box>
        ) : (
          <Skeleton width={250} />
        )}
        {!loading ? (
          <Box className={classes.rowCell}>
            {canDelete && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={(): void => label && handleDelete && handleDelete(label)}
                className={classes.smallButton}
              >
                Delete
              </Button>
            )}
          </Box>
        ) : (
          <Skeleton width={65} />
        )}
      </Box>
    </Paper>
  )
}

const useWhereFilters = (filters: Filters): LabelFilterInput => {
  return {
    search: filters.labelKeywords.length > 0 ? { keywords: filters.labelKeywords } : null,
  }
}

const LabelManagement: React.FC = () => {
  useTitle('Labels - Settings')
  const setModalOptions = React.useContext(UpsellModalUpdaterContext)
  const classes = useStyles()
  const [addOpen, setAddOpen] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)
  const { filters } = useFilterParams()
  const whereFilters = useWhereFilters(filters)
  const { showToast } = useToast()

  const { loading: userDataLoading, data: userData } = useLabelUserInfoQuery({
    onCompleted: (data: LabelUserInfoQuery) => {
      if (!selectedAccountId && data?.whoami?.account) setSelectedAccountId(data.whoami.account.id)
    },
  })

  const roles = userData?.whoami?.roles?.map(r => r.name) || []
  const canDelete = roles.some(r => r === 'ADMIN' || r === 'OWNER')

  const {
    loading: labelsLoading,
    error,
    data,
    fetchMore,
    networkStatus,
  } = useAccountLabelsQuery({
    skip: !selectedAccountId,
    variables: {
      accountId: selectedAccountId?.toString() || '',
      limit: LABEL_PAGE_SIZE,
      sortBy: LabelSort.Name,
      sortDirection: SortDirection.Asc,
      where: whereFilters,
    },
  })
  const labelLimit = data?.account?.organization?.labelLimit

  const [createLabel] = useCreateLabelMutation({
    onError: e => {
      showToast({
        title: 'Error: Creating Label',
        message: 'Something went wrong when creating this label, please try again ' + e,
        severity: 'error',
      })
    },
    update(cache, { data: createLabelData }) {
      const newLabelData = createLabelData?.createLabel?.label
      if (!newLabelData || !selectedAccountId) return
      const data = cache.readQuery<AccountLabelsQuery, AccountLabelsQueryVariables>({
        query: AccountLabelsDocument,
        variables: {
          accountId: selectedAccountId.toString(),
          limit: LABEL_PAGE_SIZE,
          sortBy: LabelSort.Name,
          sortDirection: SortDirection.Asc,
          where: whereFilters,
        },
      })

      if (!data?.account?.labels) {
        return
      }

      cache.writeQuery<AccountLabelsQuery, AccountLabelsQueryVariables>({
        query: AccountLabelsDocument,
        variables: {
          accountId: selectedAccountId.toString(),
          limit: LABEL_PAGE_SIZE,
          sortBy: LabelSort.Name,
          sortDirection: SortDirection.Asc,
          where: whereFilters,
        },
        data: {
          ...data,
          account: {
            ...data?.account,
            labels: {
              ...data.account.labels,
              results: data.account.labels.results.concat(newLabelData),
            },
          },
        },
      })
    },
  })

  const [deleteLabel] = useDeleteLabelMutation({
    update(cache, { data: deleteLabelData }) {
      const label = deleteLabelData?.deleteLabel?.label
      const ok = deleteLabelData?.deleteLabel?.ok
      if (!ok || !label) return
      cache.evict({ id: cache.identify(label) })
    },
    onError: e => {
      showToast({
        title: 'Error: Deleting Label',
        message: 'Something went wrong when deleting this label, please try again ' + e,
        severity: 'error',
      })
    },
  })

  const loading = userDataLoading || (labelsLoading && networkStatus !== NetworkStatus.fetchMore)
  if (loading) return <p>Loading</p>
  if (error || (!loading && !data)) return <p>Error: {error && error.message}</p>

  const loadMoreContent = (): void => {
    if (data?.account?.labels?.cursor) {
      void fetchMore({
        variables: {
          cursor: data?.account?.labels?.cursor,
        },
      })
    }
  }

  const hasHitLimit = !!data?.account?.organization?.labelLimit.hasHitLimit
  const handleAdd = (): void => {
    if (hasHitLimit) {
      setModalOptions({
        isOpen: true,
        modalProps: {
          context: { reason: 'LIMIT', limit: 'SEGMENTS' },
          onCancel: () => setModalOptions({ isOpen: false }),
        },
      })
    } else {
      setAddOpen(true)
    }
  }

  const handleClose = (): void => {
    setAddOpen(false)
  }

  const handleSave = async (name: string, isRejectionReason: boolean) => {
    await createLabel({ variables: { name, accountId: `${selectedAccountId}`, isRejectionReason: isRejectionReason } })
    setAddOpen(false)
  }

  const handleDelete = async (label: LabelType) => {
    await deleteLabel({ variables: { id: `${label.id}` } })
  }

  const renderSkeletons = () => {
    const skeletons = []
    for (let i = 0; i < LABEL_PAGE_SIZE; i++) {
      skeletons.push(<LabelRow loading />)
    }
    return <>{skeletons}</>
  }

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" flex={1} mb={4}>
          <Typography variant="h5" className={classes.title}>
            Label Management
          </Typography>
        </Box>
        <Box display="flex" flex={1} alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="subtitle1" display="inline">
              Labels {labelLimit?.used.toLocaleString()}
            </Typography>
            <Typography display="inline">
              {' '}
              of {labelLimit?.isUnlimited ? 'Unlimited' : labelLimit?.limit?.toLocaleString()}
            </Typography>
          </Box>

          <Box>
            <Tooltip title={hasHitLimit ? 'Account has hit label limit.' : ''} placement="top">
              <span>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={handleAdd}
                  className={classes.largeButton}
                >
                  Add Label
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box mt={6}>
          {data?.account?.labels?.results?.map(label => (
            <LabelRow label={label} canDelete={canDelete} handleDelete={handleDelete} key={label.id} />
          ))}
          {networkStatus === NetworkStatus.fetchMore && renderSkeletons()}
          {!loading && data?.account?.labels?.cursor && (
            <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
              <Button variant="outlined" color="primary" size="large" onClick={loadMoreContent}>
                Load more
              </Button>
            </Box>
          )}
        </Box>

        <AddLabel open={addOpen} handleClose={handleClose} handleSave={handleSave} />
      </Box>
    </Page>
  )
}

export default LabelManagement
