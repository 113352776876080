import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  IconButton,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
} from '@material-ui/core'
import { ApolloError, MutationResult } from '@apollo/client'
import PostDetails from '../components/PostDetails/'
import useTitle from '../utils/use-title'
import { ReactComponent as ArrowIcon } from '../icons/arrow-skinny.svg'
import { REVIEW_ROUTE } from './routes'
import { ProgramType } from '../gql-global'
import { ClaimChallengeReviewLockMutation, ClaimProgramReviewLockMutation } from './operations/claim-lock.generated'
import {
  PostDetails_IgMention_Fragment,
  PostDetails_TtMention_Fragment,
} from '../components/PostDetails/operations/post-details.generated'

export interface UseDetailsType {
  program?: ProgramType | null
  mentions?: Array<
    | ({ __typename: 'IGMention' } & PostDetails_IgMention_Fragment)
    | ({ __typename: 'TTMention' } & PostDetails_TtMention_Fragment)
  >
  loading: boolean
  error?: ApolloError
  fetchNext(): void
}

interface ReviewDetailsProps {
  itemId: string
  title: string
  entityType: 'program' | 'challenge'
  pageTitle?: string
  itemQueryResult: UseDetailsType
  mutationResult: MutationResult<ClaimProgramReviewLockMutation | ClaimChallengeReviewLockMutation>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginRight: theme.spacing(5),
    },
    title: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
)

const ReviewDetails: React.FC<ReviewDetailsProps> = props => {
  const { title, entityType, pageTitle, itemId, itemQueryResult, mutationResult } = props
  const { program, mentions, loading, error, fetchNext } = itemQueryResult
  const { loading: locking, error: errorLocking } = mutationResult

  const classes = useStyles()
  const history = useHistory()

  const [updateStatusError, setUpdateStatusError] = useState<ApolloError | undefined>()

  const mention = mentions?.[0]

  useTitle(pageTitle ? `${pageTitle} - Review` : 'Review')

  useEffect(() => {
    setUpdateStatusError(undefined)
  }, [itemId])

  if (loading || locking) return <CircularProgress />
  if (error || !mentions || !program) return <p>Error: {error?.message || 'Unknown issue'}</p>
  if (updateStatusError) return <p>Error: {updateStatusError.message || 'Unknown issue updating mention'}</p>

  if (errorLocking && errorLocking.graphQLErrors[0]?.extensions?.code === 'RESOURCE_LOCKED') {
    return <p>Program already locked</p>
  }
  if (errorLocking) return <p> Error locking program for review</p>

  if (!mention)
    return (
      <p>
        All done! Please go back to Review a different {`${entityType} `}
        <Link to={REVIEW_ROUTE.path}>Back to review lists</Link>
      </p>
    )

  return (
    <Box mb={5}>
      <Box mb={5} display="flex" alignItems="center">
        <IconButton color="secondary" className={classes.backButton} onClick={() => history.goBack()}>
          <ArrowIcon width={20} height={16} />
        </IconButton>
        <Typography variant="h5" className={classes.title} title={title}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Box>
          {!loading && (
            <Paper>
              <PostDetails
                accountId={program?.account?.id?.toString()}
                mention={mention}
                updateMentionStatusSuccessCallback={fetchNext}
                setUpdateStatusError={setUpdateStatusError}
                forReview
              />
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  )
}
export default ReviewDetails
