import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLabelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type UpdateLabelMutation = { __typename: 'Mutations', updateLabel?: { __typename: 'UpdateLabel', ok?: boolean | null, label?: { __typename: 'LabelType', id: number, isRejectionReason: boolean, name: string } | null } | null };


export const UpdateLabelDocument = gql`
    mutation UpdateLabel($id: ID!, $name: String!) {
  updateLabel(id: $id, name: $name) {
    label {
      id
      isRejectionReason
      name
    }
    ok
  }
}
    `;
export type UpdateLabelMutationFn = Apollo.MutationFunction<UpdateLabelMutation, UpdateLabelMutationVariables>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLabelMutation, UpdateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLabelMutation, UpdateLabelMutationVariables>(UpdateLabelDocument, options);
      }
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<UpdateLabelMutation, UpdateLabelMutationVariables>;