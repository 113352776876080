import React, { useState } from 'react'
import {
  Button,
  IconButton,
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Chip,
  Tab,
  Tabs,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg'
import { ReactComponent as CheckIcon } from '../../icons/checkmark.svg'
import { ReactComponent as SparkleIcon } from '../../icons/sparkle.svg'
import { ReactComponent as RocketIcon } from '../../icons/rocket.svg'
import { secondary } from '../../loudcrowd-theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionContainer: {
      border: `1px solid ${secondary[500]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: 28,
    },
    optionContainerHighlighted: {
      border: `1px solid ${secondary[500]}`,
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: 28,
      paddingTop: 4,
    },
    highlightedChip: {
      float: 'left',
      position: 'relative',
      top: -14,
      height: 20,
      fontSize: 12,
      padding: '0 16px',
    },
    roundedRectangleButton: {
      borderRadius: 4,
    },
    lightText: {
      color: theme.palette.secondary.main,
    },
    sparkleIcon: {
      color: theme.palette.warning.main,
      marginTop: 15,
    },
    h7: {
      //MUI doesn't have a h7 whereas the mocks did
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 650,
    },
    checkIcon: { color: 'rgb(53,204,255)', marginRight: 12 },
    darkButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    features: {
      marginTop: 20,
    },
  }),
)

interface TiersProps {
  currentTierId?: string | null
  onCancel(e: React.MouseEvent<HTMLButtonElement>): void
  onClickTier(id: string): void
  alertText: string | null
  isOwner?: boolean | null
}

const measureBasicID = 'prod_K0z1OxwBkwcXgU'
const measurePlusID = 'prod_LEjcDMjh06HGp2'
const measureEnterpriseID = 'prod_K0z1cxGdZzNbrk'
const growthScaleID = 'prod_K0z1x5Q1Zbpdi4'
const growthEnterpriseID = 'prod_K0z1Mxd8QwPBT4'

function Tiers({ alertText, onCancel, currentTierId, isOwner, onClickTier }: TiersProps): React.ReactElement {
  const styles = useStyles()
  const [tabValue, setTabValue] = useState<'MEASURE' | 'GROW'>('GROW')
  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <Box display="flex" flexDirection="row" mt={8} mx={3}>
        <Box visibility="hidden" mr="auto" />
        <Typography variant="h4" display="block">
          {currentTierId ? 'Upgrade your plan today' : 'Purchase LoudCrowd today'}
        </Typography>
        <Box ml="auto">
          <IconButton className={styles.roundedRectangleButton} onClick={onCancel}>
            <CrossIcon width={24} height={24} />
          </IconButton>
        </Box>
      </Box>
      {alertText && (
        <Box width="50%" mx="auto">
          <Alert square severity="info" elevation={0}>
            {alertText}
          </Alert>
        </Box>
      )}
      <Box display="flex" justifyContent="center" mt={5}>
        <Tabs value={tabValue} onChange={(e, val) => setTabValue(val)} indicatorColor="primary">
          <Tab label="Grow" value="GROW" style={{ fontSize: 25 }} />
          <Tab label="Measure" value="MEASURE" style={{ fontSize: 25 }} />
        </Tabs>
      </Box>
      {tabValue === 'GROW' ? (
        <Box display="flex" justifyContent="center" mt={8} mb={4}>
          <Box
            className={styles.optionContainer}
            borderRadius={5}
            width={332}
            mr={5}
            display="flex"
            flexDirection="column"
          >
            <Box mb={2}>
              <Typography className={styles.h7}>Grow</Typography>
            </Box>
            <Box mb={2}>
              <Typography style={{ fontFamily: 'Born-Ready-Slated', fontSize: 45 }} color="primary">
                Scale
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body1">View the impact of your Instagram content in one dashboard.</Typography>
            </Box>
            <Box mb={4} display="flex" flexDirection="row" alignItems="center">
              <SparkleIcon className={styles.sparkleIcon} height={15} width={20} />
              <Box ml={2}>
                <Typography variant="h4" style={{ fontWeight: 650 }}>
                  Let's talk
                </Typography>
              </Box>
            </Box>
            <Typography className={styles.h7}>What's included:</Typography>
            <Box className={styles.features}>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">1 Growth Program</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">3 users</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">1 Instagram account</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited visible posts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited labels + Segments</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Gifts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Klaviyo + eComm Integrations</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Dedicated Client Strategist</Typography>
              </Box>
            </Box>
            <Box flexGrow={1} />
            <Button
              className={`${styles.roundedRectangleButton} ${styles.darkButton}`}
              style={{ marginTop: 20 }}
              variant="contained"
              href="mailto:sales@loudcrowd.com"
              disabled={currentTierId === growthScaleID}
            >
              {currentTierId === growthScaleID ? 'Current Plan' : 'Contact Sales'}
            </Button>
          </Box>
          <Box className={styles.optionContainer} borderRadius={5} width={332} display="flex" flexDirection="column">
            <Box mb={2}>
              <Typography className={styles.h7}>Grow</Typography>
            </Box>
            <Box mb={2}>
              <Typography style={{ fontFamily: 'Born-Ready-Slated', fontSize: 45 }} color="primary">
                Enterprise
              </Typography>
            </Box>
            <Box mb={6} display="flex" alignItems="center">
              <Box mr={1}>
                <Typography variant="body2">Sky's the limit!</Typography>
              </Box>
              <RocketIcon width={18} height={18} />
            </Box>
            <Box mb={4} display="flex" alignItems="center">
              <SparkleIcon className={styles.sparkleIcon} height={15} width={20} />
              <Box ml={2}>
                <Typography variant="h4" style={{ fontWeight: 650 }}>
                  Let's talk
                </Typography>
              </Box>
            </Box>
            <Typography className={styles.h7}>What's included:</Typography>
            <Box className={styles.features}>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Everything in Scale</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Programs</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Users</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Instagram or TikTok accounts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Custom Integrations</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Dedicated Client Strategist</Typography>
              </Box>
            </Box>
            <Box flexGrow={1} minHeight={16} />
            <Button
              className={`${styles.roundedRectangleButton} ${styles.darkButton}`}
              style={{ marginTop: 20 }}
              variant="contained"
              href="mailto:sales@loudcrowd.com"
              disabled={currentTierId === growthEnterpriseID}
            >
              {currentTierId === growthEnterpriseID ? 'Current Plan' : 'Contact Sales'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" mt={8} mb={4}>
          <Box className={styles.optionContainer} width={332} mr={5} display="flex" flexDirection="column">
            <Box mb={2}>
              <Typography className={styles.h7}>Measure</Typography>
            </Box>
            <Box mb={2}>
              <Typography style={{ fontFamily: 'Born-Ready-Slated', fontSize: 45 }} color="primary">
                Basic
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body2">View the impact of your Instagram content in one dashboard.</Typography>
            </Box>
            <Box mb={4} display="flex" alignItems="center">
              <Typography variant="h3">$199</Typography>
              <Typography className={styles.lightText} variant="body2" style={{ marginTop: 20 }}>
                /month
              </Typography>
            </Box>
            <Typography className={styles.h7}>What's included:</Typography>
            <Box className={styles.features}>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">3 users</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">1 Instagram account</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">1,000 Visible Posts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Segments + Labels</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Gifts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Chat based support</Typography>
              </Box>
            </Box>
            <Box flexGrow={1} />
            <Button
              className={`${styles.roundedRectangleButton} ${styles.darkButton}`}
              style={{ marginTop: 20 }}
              variant="contained"
              href="mailto:sales@loudcrowd.com"
              disabled={currentTierId === measureBasicID}
            >
              {currentTierId === measureBasicID ? 'Current Plan' : 'Contact Sales'}
            </Button>
          </Box>
          <Box className={styles.optionContainerHighlighted} width={332} mr={5} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <Chip className={styles.highlightedChip} color="primary" label="MOST POPULAR" />
            </Box>

            <Box mb={2}>
              <Typography className={styles.h7}>Measure</Typography>
            </Box>
            <Box mb={2}>
              <Typography style={{ fontFamily: 'Born-Ready-Slated', fontSize: 45 }} color="primary">
                Plus
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body2">Segment and message your most loyal fans and customers.</Typography>
            </Box>
            <Box mb={4} display="flex" alignItems="center">
              <Typography variant="h3">$499</Typography>
              <Typography className={styles.lightText} variant="body2" style={{ marginTop: 20 }}>
                /month
              </Typography>
            </Box>
            <Typography className={styles.h7}>What's included:</Typography>
            <Box className={styles.features}>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Everything in Basic</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">3 Users</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">1 Instagram account</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">10,000 Visible Posts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Shopify Integration</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Chat, Email, and Phone Support</Typography>
              </Box>
            </Box>
            <Box flexGrow={1} />
            <Button
              className={`${styles.roundedRectangleButton} ${styles.darkButton}`}
              style={{ marginTop: 20 }}
              variant="contained"
              href="mailto:sales@loudcrowd.com"
              disabled={currentTierId === measurePlusID}
            >
              {currentTierId === measurePlusID ? 'Current Plan' : 'Contact Sales'}
            </Button>
          </Box>
          <Box className={styles.optionContainer} width={332} mr={5} display="flex" flexDirection="column">
            <Box mb={2}>
              <Typography className={styles.h7}>Measure</Typography>
            </Box>
            <Box mb={2}>
              <Typography style={{ fontFamily: 'Born-Ready-Slated', fontSize: 45 }} color="primary">
                Enterprise
              </Typography>
            </Box>
            <Box mb={6} display="flex" alignItems="center">
              <Box mr={1}>
                <Typography variant="body2">Sky's the limit!</Typography>
              </Box>
              <RocketIcon width={18} height={18} />
            </Box>
            <Box mb={4} display="flex" alignItems="center">
              <SparkleIcon className={styles.sparkleIcon} height={15} width={20} />
              <Box ml={2}>
                <Typography variant="h4" style={{ fontWeight: 650 }}>
                  Let's talk
                </Typography>
              </Box>
            </Box>
            <Typography className={styles.h7}>What's included:</Typography>
            <Box className={styles.features}>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Everything in Plus</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Users</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Instagram or TikTok accounts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Visible Posts</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Unlimited Integrations</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <CheckIcon className={styles.checkIcon} height={14} width={14} />
                <Typography variant="body2">Dedicated Client Strategist</Typography>
              </Box>
            </Box>
            <Box flexGrow={1} />
            <Button
              className={`${styles.roundedRectangleButton} ${styles.darkButton}`}
              style={{ marginTop: 20 }}
              variant="contained"
              href="mailto:sales@loudcrowd.com"
              disabled={currentTierId === measureEnterpriseID}
            >
              {currentTierId === measureEnterpriseID ? 'Current Plan' : 'Contact Sales'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
export default Tiers
