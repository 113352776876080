import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TogglePauseChallengeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type TogglePauseChallengeMutation = { __typename: 'Mutations', togglePauseChallenge?: { __typename: 'TogglePauseChallenge', ok: boolean, challenge?: { __typename: 'ChallengeType', id: string, name: string, currentStatus: Types.ChallengeStatusEnum } | null } | null };


export const TogglePauseChallengeDocument = gql`
    mutation TogglePauseChallenge($id: ID!) {
  togglePauseChallenge(id: $id) {
    ok
    challenge {
      id
      name
      currentStatus
    }
  }
}
    `;
export type TogglePauseChallengeMutationFn = Apollo.MutationFunction<TogglePauseChallengeMutation, TogglePauseChallengeMutationVariables>;

/**
 * __useTogglePauseChallengeMutation__
 *
 * To run a mutation, you first call `useTogglePauseChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePauseChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePauseChallengeMutation, { data, loading, error }] = useTogglePauseChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTogglePauseChallengeMutation(baseOptions?: Apollo.MutationHookOptions<TogglePauseChallengeMutation, TogglePauseChallengeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePauseChallengeMutation, TogglePauseChallengeMutationVariables>(TogglePauseChallengeDocument, options);
      }
export type TogglePauseChallengeMutationHookResult = ReturnType<typeof useTogglePauseChallengeMutation>;
export type TogglePauseChallengeMutationResult = Apollo.MutationResult<TogglePauseChallengeMutation>;
export type TogglePauseChallengeMutationOptions = Apollo.BaseMutationOptions<TogglePauseChallengeMutation, TogglePauseChallengeMutationVariables>;