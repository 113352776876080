import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserFeatureDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserFeatureDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, roles?: Array<{ __typename: 'RoleType', id: string, name: string }> | null, account?: { __typename: 'AccountType', id: number } | null } | null };


export const UserFeatureDataDocument = gql`
    query UserFeatureData {
  whoami {
    id
    email
    roles {
      id
      name
    }
    account {
      id
    }
  }
}
    `;

/**
 * __useUserFeatureDataQuery__
 *
 * To run a query within a React component, call `useUserFeatureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeatureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeatureDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFeatureDataQuery(baseOptions?: Apollo.QueryHookOptions<UserFeatureDataQuery, UserFeatureDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFeatureDataQuery, UserFeatureDataQueryVariables>(UserFeatureDataDocument, options);
      }
export function useUserFeatureDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFeatureDataQuery, UserFeatureDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFeatureDataQuery, UserFeatureDataQueryVariables>(UserFeatureDataDocument, options);
        }
export type UserFeatureDataQueryHookResult = ReturnType<typeof useUserFeatureDataQuery>;
export type UserFeatureDataLazyQueryHookResult = ReturnType<typeof useUserFeatureDataLazyQuery>;
export type UserFeatureDataQueryResult = Apollo.QueryResult<UserFeatureDataQuery, UserFeatureDataQueryVariables>;