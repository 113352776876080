import React from 'react'
import ActivityListItemContent from './ActivityListItemContent'
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { ConcreteEventType } from './activity-types'
import { ReactComponent as PresentIcon } from '../icons/present.svg'
import { ReactComponent as MailIcon } from '../icons/mail.svg'
import { ReactComponent as TrophyIcon } from '../icons/gradient-trophy-outlined.svg'
import IntegrationLogo from '../components/IntegrationLogo'

const useStyles = makeStyles(() =>
  createStyles({
    integrationLogoWrapper: {
      display: 'inline-block',
      paddingLeft: 10,
      paddingTop: 0,
      marginTop: 3,
      height: 16,
      verticalAlign: 'middle',
      alignItems: 'center',
      justifyContent: 'center',
    },
    integrationLogo: {
      height: '100%',
    },
    challengesGradient: {
      background: 'linear-gradient(170.48deg, #7800F9 28.39%, #F528C8 56.52%, #FFDD55 95%)',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      color: 'transparent',
    },
  }),
)

interface FulfilledTierActivityListItemProps {
  activity: ConcreteEventType<'FulfilledTierCustomerEvent'>
}

function FulfilledTierActivityListItem({ activity }: FulfilledTierActivityListItemProps): React.ReactElement {
  const classes = useStyles()

  let front: JSX.Element = <></>
  if (activity.fulfillment?.reward?.isEspMessage) {
    front = <Typography variant="subtitle1">ESP Message Sent</Typography>
  } else {
    front = <Typography variant="subtitle1">Reward {activity.actor?.email ? 'Sent' : 'Earned'}</Typography>
  }

  const typographyList: React.ReactElement[] = [front]

  if (activity.fulfillment?.programTier?.program?.name) {
    typographyList.push(
      <Typography variant="subtitle1" key="programName">
        {activity.fulfillment.programTier.program.name}
      </Typography>,
    )
  }
  if (activity.fulfillment?.challenge?.name) {
    typographyList.push(
      <Typography className={classes.challengesGradient} variant="subtitle1" key="challengeName">
        {activity.fulfillment?.challenge?.name}
      </Typography>,
    )
  }
  if (activity.fulfillment?.reward?.name) {
    typographyList.push(
      <Typography variant="subtitle1" key="rewardName">
        {activity.fulfillment.reward.name}
      </Typography>,
    )
  }
  if (activity.fulfillment?.rewardDiscountCode) {
    typographyList.push(
      <Typography variant="subtitle1" key="discountCode">
        {activity.fulfillment.rewardDiscountCode.code}
      </Typography>,
    )
  }

  const integrationType = activity.fulfillment?.reward?.integration?.integrationType

  const isChallengeReward = !!activity.fulfillment?.challenge?.name

  let icon: JSX.Element = <></>
  if (isChallengeReward) {
    icon = <TrophyIcon width={48} height={48} />
  } else {
    if (activity.fulfillment?.reward?.isEspMessage) {
      icon = <MailIcon width={24} height={24} />
    } else {
      icon = <PresentIcon width={24} height={24} />
    }
  }

  return (
    <ActivityListItemContent
      eventAt={activity.eventAt}
      actorName={activity.actor?.email}
      media={
        <Box
          m={4}
          p={!isChallengeReward ? 2 : 0}
          width={48}
          height={48}
          borderRadius={24}
          bgcolor={!isChallengeReward ? 'success.light' : undefined}
          color={!isChallengeReward ? 'success.main' : undefined}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </Box>
      }
      text={
        <Typography variant="subtitle1" display="inline">
          <Box display="flex">
            {typographyList.map((t, idx) => {
              return (
                <Box display="flex">
                  {t}
                  {idx < typographyList.length - 1 && <div>&nbsp;-&nbsp;</div>}
                </Box>
              )
            })}

            {!!integrationType && (
              <Typography variant="subtitle1" display="inline">
                &nbsp;-
              </Typography>
            )}
            {!!integrationType && (
              <Box className={classes.integrationLogoWrapper}>
                <IntegrationLogo integrationType={integrationType} className={classes.integrationLogo} />
              </Box>
            )}
          </Box>
        </Typography>
      }
    />
  )
}

export default FulfilledTierActivityListItem
